import { storage, db } from '../../firebase/config'
import _filefy, { CsvBuilder } from 'filefy';

import axios from 'axios'
import { covidApi } from '../../shared/masters/url';

import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Collapse } from 'reactstrap';
import { Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Icons
import FeatherIcon from 'feather-icons-react';

import { AuthContext } from '../../shared/context/auth-context';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';

import { forwardRef } from 'react';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid.png';

import MaterialTable, { MTableToolbar } from 'material-table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import "./ListPolicy.css";
import Menubar from '../components/MenuBar';


// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

import BackgroundSlider from "react-background-slider";

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const currentDate = new Date()

function addYear(date) {
    const copy = new Date(Number(date))
    copy.setYear(date.getFullYear() + 1)
    return copy
}

const newCovDateStart = currentDate.toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
const newCovDateEnd = addYear(currentDate).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear() - 1426;
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
}

const ListPolicyRenew = () => {

    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);;
    const [covidAppList, setCovidAppList] = useState([]);
    const [covidAppSelectId, setcovidAppSelectId] = useState();
    const [covidAppSelect, setcovidAppSelect] = useState();
    const [refresh, setrefresh] = useState(false);
    const [formData, setFormData] = useState({
        POLICY_NUM: "",
        POLICY_NUM_FILE_NAME: "",
        POLICY_NUM_FILE_URL: "",
        POLICY_NUM_FILE_STATUS: "unselect",
        DATE_COV_START: newCovDateStart,
        DATE_COV_END: newCovDateEnd
    });

    const [covidDataIssuerSearchType, setCovidDataIssuerSearchType] = useState("");
    const [covidDataAppDocSearchType, setCovidDataAppDocSearchType] = useState("");
    const [covidDataIdCardSearchType, setCovidDataIdCardSearchType] = useState("");
    const [covidDataAgentDownload, setCovidDataAgentDownload] = useState("");

    const handleIssuerSearchSelected = event => {
        const { name, value } = event.target
        setCovidDataIssuerSearchType(value)
    }

    const handleAppDocSearchSelected = event => {
        const { name, value } = event.target
        setCovidDataAppDocSearchType(value)
    }

    const handleIdCardSearchSelected = event => {
        const { name, value } = event.target
        setCovidDataIdCardSearchType(value)
    }

    const handleAgentDownload = event => {
        const { name, value } = event.target
        setCovidDataAgentDownload(value)
    }

    // Close and open collapse bar
    const [allCol, setAllCol] = useState({
        col1: false,
        col2: false,
    });

    const t_col1 = () => {
        setAllCol({
            col1: !allCol.col1,
            col2: false,
        });
    }
    const t_col2 = () => {
        setAllCol({
            col2: !allCol.col2,
            col1: false,
        });
    }

    // delete file and policy number in web
    const [formCancelPolicyNumDataAndFile, setFormCancelPolicyNumDataAndFile] = useState({
        authorizedCode: "",
        remarkDeleteFileAndNum: ""
    });

    const handleCancelPolicyNumDataAndFileFormChange = event => {
        const { name, value } = event.target

        if (name === "authorizedCode") {
            setFormCancelPolicyNumDataAndFile(prevValue => {
                return {
                    ...prevValue,
                    authorizedCode: value
                }
            });
        }

        if (name === "remarkDeleteFileAndNum") {
            setFormCancelPolicyNumDataAndFile(prevValue => {
                return {
                    ...prevValue,
                    remarkDeleteFileAndNum: value
                }
            });
        }

    }

    const handleCancelPolicyNumDataAndFileSubmit = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            POLICY_NUM: "",
            POLICY_NUM_FILE_NAME: "",
            POLICY_NUM_FILE_URL: "",
            DELETE_POLICY_FILE_DATE: new Date(),
            DELETE_AUTHORIZATION_CODE: formCancelPolicyNumDataAndFile.authorizedCode,
            DELETE_POLICY_FILE_REMARK: formCancelPolicyNumDataAndFile.remarkDeleteFileAndNum
        })
            .then(() => {
                db.collection('deleleFileCovidLog').add({
                    appFormId: covidAppSelect.APP_FORM_ID,
                    appId: covidAppSelectId,
                    deleteFileDate: new Date(),
                    authorizedCode: formCancelPolicyNumDataAndFile.authorizedCode,
                    remarkDeleteFileAndNum: formCancelPolicyNumDataAndFile.remarkDeleteFileAndNum,
                    deleteChannel: "DeleteWithPolicyNum"
                })
                setIsLoading(false);
                setFormCancelPolicyNumDataAndFile(prevValue => {
                    return {
                        ...prevValue,
                        authorizedCode: "",
                        remarkDeleteFileAndNum: ""
                    }
                });
                setCardCovidAppDetailModal(false);
                setrefresh(!refresh);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                setCardCovidAppDetailModal(false);
            })
    }

    useEffect(() => {
        setError(null);
        if (covidDataIssuerSearchType === "TOTAL") {
            if (covidDataAppDocSearchType) {
                if (covidDataIdCardSearchType) {
                    setIsLoading(true);
                    db.collection("covidApplication").where("APP_FORM_ID", "==", covidDataAppDocSearchType).where("NATIONAL_ID", "==", covidDataIdCardSearchType).get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                } else {
                    setIsLoading(true);
                    db.collection("covidApplication").where("APP_FORM_ID", "==", covidDataAppDocSearchType).get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                }
            } else {
                if (covidDataIdCardSearchType) {
                    setIsLoading(true);
                    db.collection("covidApplication").where("NATIONAL_ID", "==", covidDataIdCardSearchType).get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                } else {
                    setIsLoading(true);
                    db.collection("covidApplication").get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                }
            }

        } else {
            if (covidDataAppDocSearchType) {
                if (covidDataIdCardSearchType) {
                    setIsLoading(true);
                    db.collection("covidApplication").where("PLAN_ISSUER_CODE", "==", covidDataIssuerSearchType).where("APP_FORM_ID", "==", covidDataAppDocSearchType).where("NATIONAL_ID", "==", covidDataIdCardSearchType).get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                } else {
                    setIsLoading(true);
                    db.collection("covidApplication").where("PLAN_ISSUER_CODE", "==", covidDataIssuerSearchType).where("APP_FORM_ID", "==", covidDataAppDocSearchType).get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                }
            } else {
                if (covidDataIdCardSearchType) {
                    setIsLoading(true);
                    db.collection("covidApplication").where("PLAN_ISSUER_CODE", "==", covidDataIssuerSearchType).where("NATIONAL_ID", "==", covidDataIdCardSearchType).get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                } else {
                    setIsLoading(true);
                    db.collection("covidApplication").where("PLAN_ISSUER_CODE", "==", covidDataIssuerSearchType).get()
                        .then((snapshot) => {
                            let covidApp = []
                            snapshot.docs.forEach(doc => {
                                covidApp.push(doc.data());
                            })
                            const covidAppFilter = covidApp.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE")
                            setCovidAppList(covidAppFilter);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        });
                }
            }
        }
    }, [refresh]);

    //Model Card Detail Set
    const [cardCovidAppDetailModal, setCardCovidAppDetailModal] = useState(false);
    const openCardCovidAppDetailModal = (appid) => {
        db.collection('covidApplication').where('APP_FORM_ID', '==', appid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setcovidAppSelectId(snapshot.docs[0].id)
                const newDoc = snapshot.docs[0].data()
                let newUrl = ""
                storage.ref("policyfile").child(`${snapshot.docs[0].data().POLICY_NUM.replace(/\//g, "")}.pdf`).getDownloadURL().then((url) => {
                    newUrl = url
                    newDoc.NEW_POLICY_URL = newUrl
                    setcovidAppSelect(newDoc)
                    setIsLoading(false);
                }).catch((error) => {
                    setcovidAppSelect(newDoc)
                    setIsLoading(false);
                });
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setCardCovidAppDetailModal(true);
    };

    const closeCardCovidAppDetailModal = () => {
        setCardCovidAppDetailModal(false);
        setcovidAppSelectId(null);
        setcovidAppSelect(null);
    };

    //  Function to save and send to TVMS and Email Transaction via API
    const handleResendPolicySubmit = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        const sendCovidApiRequest = async () => {
            try {
                if (covidAppSelect.APP_FORM_TYPE === "NEW-GB") {
                    if (!covidAppSelect.POLICY_NUM_FILE_URL) {
                        const response = await axios.post(`${covidApi}/api/covid-application/create-new-paid-without-file-url`,
                            JSON.stringify({
                                POLICY_NUMBER: covidAppSelect.POLICY_NUM,
                                APPL_NUMBER: covidAppSelect.APP_FORM_ID,
                                PRENAME_DESC: covidAppSelect.PRE_NAME,
                                FIRST_NAME: covidAppSelect.FIRST_NAME,
                                LAST_NAME: covidAppSelect.LAST_NAME,
                                ID_CARD: covidAppSelect.NATIONAL_ID,
                                GENDER: covidAppSelect.GENDER,
                                BIRTH_DATE: covidAppSelect.BIRTH_DATE,
                                ADDRESS_DETAIL: covidAppSelect.ADDRESS_DETAIL,
                                PROVINCE_CODE: covidAppSelect.ADDRESS_PROVINCE_ID,
                                PROVINCE_NAME: covidAppSelect.ADDRESS_PROVINCE,
                                DISTRICT_CODE: covidAppSelect.ADDRESS_DISTRICT_ID,
                                DISTRICT_NAME: covidAppSelect.ADDRESS_DISTRICT,
                                SUBDISTRICT_NAME: covidAppSelect.ADDRESS_SUB_DISTRICT,
                                ZIP_CODE: covidAppSelect.ADDRESS_ZIP_CODE,
                                PHONE_NUMBER: covidAppSelect.PHONE_NUM,
                                EMAIL_ADDRESS: covidAppSelect.EMAIL_ADDRESS,
                                APPL_DATE: covidAppSelect.CURRENT_DATE,
                                DATE_COV_START: covidAppSelect.DATE_COV_START,
                                DATE_COV_END: covidAppSelect.DATE_COV_END,
                                BENEFIT_DETAIL: covidAppSelect.BENEFIT_PRE_NAME + covidAppSelect.BENEFIT_FIRST_NAME + covidAppSelect.BENEFIT_LAST_NAME + " " + covidAppSelect.BENEFIT_TYPE,
                                INS_COMPANY_CODE: covidAppSelect.PLAN_ISSUER_CODE,
                                AGENT_CODE: covidAppSelect.AGENT_CODE,
                                PLAN_ID: covidAppSelect.PLAN_ID,
                                PLAN_DESC: covidAppSelect.PLAN_DESC + covidAppSelect.PLAN_ISSUER,
                                REMARK: covidAppSelect.AGENT_REMARK,
                                NET_PREMIUM_AMT: covidAppSelect.NET_PREMIUM_AMT,
                                STAMP_DUTY_AMT: covidAppSelect.STAMP_DUTY_AMT,
                                VAT_AMT: covidAppSelect.VAT_AMT,
                                TOTAL_PREMIUM_AMT: covidAppSelect.TOTAL_PREMIUM,
                                GBREF_NO: covidAppSelect.GBREF_NO,
                                GBPAYMENT_NO: covidAppSelect.GBPAYMENT_NO,
                                GBCURRENCY_CODE: covidAppSelect.GBCURRENCY_CODE,
                                GBRESULT_CODE: covidAppSelect.GBRESULT_CODE,
                                GBTOTAL_AMOUNT: covidAppSelect.GBTOTAL_AMOUNT,
                                GBTHB_AMOUNT: covidAppSelect.GBTHB_AMOUNT
                            }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        setIsLoading(false);
                        alert('การส่งข้อมูลสำเร็จแบบไม่มี URL และไม่ส่งอีเมล - GB : ' + response.data.Result);
                        setCardCovidAppDetailModal(false);
                        setrefresh(!refresh)
                    }
                    if (covidAppSelect.POLICY_NUM_FILE_URL) {
                        const response = await axios.post(`${covidApi}/api/covid-application/create-new-paid`,
                            JSON.stringify({
                                POLICY_NUMBER: covidAppSelect.POLICY_NUM,
                                POLICY_NUMBER_URL: covidAppSelect.POLICY_NUM_FILE_URL,
                                APPL_NUMBER: covidAppSelect.APP_FORM_ID,
                                PRENAME_DESC: covidAppSelect.PRE_NAME,
                                FIRST_NAME: covidAppSelect.FIRST_NAME,
                                LAST_NAME: covidAppSelect.LAST_NAME,
                                ID_CARD: covidAppSelect.NATIONAL_ID,
                                GENDER: covidAppSelect.GENDER,
                                BIRTH_DATE: covidAppSelect.BIRTH_DATE,
                                ADDRESS_DETAIL: covidAppSelect.ADDRESS_DETAIL,
                                PROVINCE_CODE: covidAppSelect.ADDRESS_PROVINCE_ID,
                                PROVINCE_NAME: covidAppSelect.ADDRESS_PROVINCE,
                                DISTRICT_CODE: covidAppSelect.ADDRESS_DISTRICT_ID,
                                DISTRICT_NAME: covidAppSelect.ADDRESS_DISTRICT,
                                SUBDISTRICT_NAME: covidAppSelect.ADDRESS_SUB_DISTRICT,
                                ZIP_CODE: covidAppSelect.ADDRESS_ZIP_CODE,
                                PHONE_NUMBER: covidAppSelect.PHONE_NUM,
                                EMAIL_ADDRESS: covidAppSelect.EMAIL_ADDRESS,
                                APPL_DATE: covidAppSelect.CURRENT_DATE,
                                DATE_COV_START: covidAppSelect.DATE_COV_START,
                                DATE_COV_END: covidAppSelect.DATE_COV_END,
                                BENEFIT_DETAIL: covidAppSelect.BENEFIT_PRE_NAME + covidAppSelect.BENEFIT_FIRST_NAME + covidAppSelect.BENEFIT_LAST_NAME + " " + covidAppSelect.BENEFIT_TYPE,
                                INS_COMPANY_CODE: covidAppSelect.PLAN_ISSUER_CODE,
                                AGENT_CODE: covidAppSelect.AGENT_CODE,
                                PLAN_ID: covidAppSelect.PLAN_ID,
                                PLAN_DESC: covidAppSelect.PLAN_DESC + covidAppSelect.PLAN_ISSUER,
                                REMARK: covidAppSelect.AGENT_REMARK,
                                NET_PREMIUM_AMT: covidAppSelect.NET_PREMIUM_AMT,
                                STAMP_DUTY_AMT: covidAppSelect.STAMP_DUTY_AMT,
                                VAT_AMT: covidAppSelect.VAT_AMT,
                                TOTAL_PREMIUM_AMT: covidAppSelect.TOTAL_PREMIUM,
                                GBREF_NO: covidAppSelect.GBREF_NO,
                                GBPAYMENT_NO: covidAppSelect.GBPAYMENT_NO,
                                GBCURRENCY_CODE: covidAppSelect.GBCURRENCY_CODE,
                                GBRESULT_CODE: covidAppSelect.GBRESULT_CODE,
                                GBTOTAL_AMOUNT: covidAppSelect.GBTOTAL_AMOUNT,
                                GBTHB_AMOUNT: covidAppSelect.GBTHB_AMOUNT
                            }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        setIsLoading(false);
                        alert('การส่งข้อมูลสำเร็จแบบมี URL - GB : ' + response.data.Result);
                        setCardCovidAppDetailModal(false);
                        setrefresh(!refresh)
                    }
                }
                if (covidAppSelect.APP_FORM_TYPE === "NEW") {
                    if (!covidAppSelect.POLICY_NUM_FILE_URL) {
                        const response = await axios.post(`${covidApi}/api/covid-application/create-new-without-file-url`,
                            JSON.stringify({
                                POLICY_NUMBER: covidAppSelect.POLICY_NUM,
                                POLICY_NUMBER_URL: covidAppSelect.POLICY_NUM_FILE_URL,
                                APPL_NUMBER: covidAppSelect.APP_FORM_ID,
                                PRENAME_DESC: covidAppSelect.PRE_NAME,
                                FIRST_NAME: covidAppSelect.FIRST_NAME,
                                LAST_NAME: covidAppSelect.LAST_NAME,
                                ID_CARD: covidAppSelect.NATIONAL_ID,
                                GENDER: covidAppSelect.GENDER,
                                BIRTH_DATE: covidAppSelect.BIRTH_DATE,
                                ADDRESS_DETAIL: covidAppSelect.ADDRESS_DETAIL,
                                PROVINCE_CODE: covidAppSelect.ADDRESS_PROVINCE_ID,
                                PROVINCE_NAME: covidAppSelect.ADDRESS_PROVINCE,
                                DISTRICT_CODE: covidAppSelect.ADDRESS_DISTRICT_ID,
                                DISTRICT_NAME: covidAppSelect.ADDRESS_DISTRICT,
                                SUBDISTRICT_NAME: covidAppSelect.ADDRESS_SUB_DISTRICT,
                                ZIP_CODE: covidAppSelect.ADDRESS_ZIP_CODE,
                                PHONE_NUMBER: covidAppSelect.PHONE_NUM,
                                EMAIL_ADDRESS: covidAppSelect.EMAIL_ADDRESS,
                                APPL_DATE: covidAppSelect.CURRENT_DATE,
                                DATE_COV_START: covidAppSelect.DATE_COV_START,
                                DATE_COV_END: covidAppSelect.DATE_COV_END,
                                BENEFIT_DETAIL: covidAppSelect.BENEFIT_PRE_NAME + covidAppSelect.BENEFIT_FIRST_NAME + covidAppSelect.BENEFIT_LAST_NAME + " " + covidAppSelect.BENEFIT_TYPE,
                                INS_COMPANY_CODE: covidAppSelect.PLAN_ISSUER_CODE,
                                AGENT_CODE: covidAppSelect.AGENT_CODE,
                                PLAN_ID: covidAppSelect.PLAN_ID,
                                PLAN_DESC: covidAppSelect.PLAN_DESC + covidAppSelect.PLAN_ISSUER,
                                REMARK: covidAppSelect.AGENT_REMARK,
                                NET_PREMIUM_AMT: covidAppSelect.NET_PREMIUM_AMT,
                                STAMP_DUTY_AMT: covidAppSelect.STAMP_DUTY_AMT,
                                VAT_AMT: covidAppSelect.VAT_AMT,
                                TOTAL_PREMIUM_AMT: covidAppSelect.TOTAL_PREMIUM
                            }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        setIsLoading(false);
                        alert('การส่งข้อมูลสำเร็จแบบไม่มี URL และไม่ส่งอีเมล - แนปสลิป : ' + response.data.Result);
                        setCardCovidAppDetailModal(false);
                        setrefresh(!refresh)
                    }
                    if (covidAppSelect.POLICY_NUM_FILE_URL) {
                        const response = await axios.post(`${covidApi}/api/covid-application/create-new`,
                            JSON.stringify({
                                POLICY_NUMBER: covidAppSelect.POLICY_NUM,
                                POLICY_NUMBER_URL: covidAppSelect.POLICY_NUM_FILE_URL,
                                APPL_NUMBER: covidAppSelect.APP_FORM_ID,
                                PRENAME_DESC: covidAppSelect.PRE_NAME,
                                FIRST_NAME: covidAppSelect.FIRST_NAME,
                                LAST_NAME: covidAppSelect.LAST_NAME,
                                ID_CARD: covidAppSelect.NATIONAL_ID,
                                GENDER: covidAppSelect.GENDER,
                                BIRTH_DATE: covidAppSelect.BIRTH_DATE,
                                ADDRESS_DETAIL: covidAppSelect.ADDRESS_DETAIL,
                                PROVINCE_CODE: covidAppSelect.ADDRESS_PROVINCE_ID,
                                PROVINCE_NAME: covidAppSelect.ADDRESS_PROVINCE,
                                DISTRICT_CODE: covidAppSelect.ADDRESS_DISTRICT_ID,
                                DISTRICT_NAME: covidAppSelect.ADDRESS_DISTRICT,
                                SUBDISTRICT_NAME: covidAppSelect.ADDRESS_SUB_DISTRICT,
                                ZIP_CODE: covidAppSelect.ADDRESS_ZIP_CODE,
                                PHONE_NUMBER: covidAppSelect.PHONE_NUM,
                                EMAIL_ADDRESS: covidAppSelect.EMAIL_ADDRESS,
                                APPL_DATE: covidAppSelect.CURRENT_DATE,
                                DATE_COV_START: covidAppSelect.DATE_COV_START,
                                DATE_COV_END: covidAppSelect.DATE_COV_END,
                                BENEFIT_DETAIL: covidAppSelect.BENEFIT_PRE_NAME + covidAppSelect.BENEFIT_FIRST_NAME + covidAppSelect.BENEFIT_LAST_NAME + " " + covidAppSelect.BENEFIT_TYPE,
                                INS_COMPANY_CODE: covidAppSelect.PLAN_ISSUER_CODE,
                                AGENT_CODE: covidAppSelect.AGENT_CODE,
                                PLAN_ID: covidAppSelect.PLAN_ID,
                                PLAN_DESC: covidAppSelect.PLAN_DESC + covidAppSelect.PLAN_ISSUER,
                                REMARK: covidAppSelect.AGENT_REMARK,
                                NET_PREMIUM_AMT: covidAppSelect.NET_PREMIUM_AMT,
                                STAMP_DUTY_AMT: covidAppSelect.STAMP_DUTY_AMT,
                                VAT_AMT: covidAppSelect.VAT_AMT,
                                TOTAL_PREMIUM_AMT: covidAppSelect.TOTAL_PREMIUM
                            }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        setIsLoading(false);
                        alert('การส่งข้อมูลสำเร็จแบบมี URL - แนปสลิป : ' + response.data.Result);
                        setCardCovidAppDetailModal(false);
                        setrefresh(!refresh)
                    }
                } else {
                    setIsLoading(false);
                    alert('ไม่สามารถส่งข้อมูลอีกครั้งได้สำหรับช่องทางการสมัครนี้');
                    setCardCovidAppDetailModal(false);
                }
            } catch (err) {
                console.log(err)
                setIsLoading(false);
                alert('เกิดปัญหาในการส่ง API เข้าระบบ TVMS กรุณาลองใหม่อีกครั้ง' + err.response.data.message);
                // setError('เกิดปัญหาในการส่ง API เข้าระบบ TVMS กรุณาลองใหม่อีกครั้ง เช่น เลขที่กรมธรรม์ซ้ำ');
                setCardCovidAppDetailModal(false);
            }
        }
        sendCovidApiRequest()
    }


    const handleAllCsv = (allColumns, allData) => {
        const columns = [
            { title: 'รหัสคำนำหน้าชื่อผู้เอาประกัน', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'ลิงค์บัตรประชาชน', field: 'NATIONAL_ID_ATTACH' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วันเดือนปีเกิด (พ.ศ.)', field: 'BIRTH_DATE' },
            { title: 'วันเริ่มคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'เลขที่บัญชี', field: '' },
            { title: 'ส่วนลด', field: '' },
            { title: 'ส่วนลดอื่นๆ', field: '' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'ลำดับที่ ผู้รับประโยชน์', field: '' },
            { title: 'คำนำหน้าชื่อ ผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ที่อยู่ผู้รับประโยชน์ ', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'ยอดเงินที่ชำระตาม PaySlip (บาท)', field: 'PAYMENT_AMOUNT' },
            { title: 'ลิงค์สลิปยอดจ่าย', field: 'PAYMENT_ATTACH' },
            { title: 'รหัสตัวแทน', field: 'SMK_BROKER_CODE' },
            { title: 'อีเมล์', field: 'EMAIL_ADDRESS' },
            { title: 'วันที่รับแจ้ง', field: 'CURRENT_DATE' },
            { title: 'เลขประจำตัวผู้เสียภาษี ในใบกำกับภาษี', field: 'NATIONAL_ID' },
            { title: 'การรับกรมธรรม์', field: 'SMK_RECIEVED_POLICY' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
            { title: 'ลิงค์ไฟล์กรมธรรม์', field: 'POLICY_NUM_FILE_URL' },
            { title: 'วันที่แนบไฟล์กรมธรรม์', field: 'POLICY_ATTACH_FILE_DATE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'เลขรันจากระบบสำหรับหนังสือคุ้มครอง', field: 'COVERNOTE_RUNNING_NUMBER' },
            { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
            { title: 'ชื่อตัวแทน', field: 'AGENT_NAME' },
            { title: 'โทเคนตัวแทน', field: 'AGENT_TOKEN' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'วันที่ชำระเงิน', field: 'GBPAYMENT_DATE_THAI' },
            { title: 'วันและเวลาที่ชำระเงิน', field: 'GBPAYMENT_DATE' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
            { title: 'เบี้ยสุทธิ', field: 'NET_PREMIUM_AMT' },
            { title: 'อากร', field: 'STAMP_DUTY_AMT' },
            { title: 'Vat', field: 'VAT_AMT' },
            { title: 'เบี้ยรวม', field: 'TOTAL_PREMIUM' },
        ]
        db.collection("covidApplication").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                covidApp = covidApp.filter(cf => cf.APP_FORM_PAYMENT_STATUS !== "INITIALIZE" && cf.APP_FORM_PAYMENT_STATUS !== "CANCEL")
                for (var i in covidApp) {
                    if (covidApp[i].GBPAYMENT_DATE_THAI) {
                        covidApp[i].GBPAYMENT_DATE_THAI = `${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidApp[i].GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`
                    }
                    if (covidApp[i].GBPAYMENT_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].GBPAYMENT_DATE = timeConverter(covidApp[i].GBPAYMENT_DATE)
                    }
                    if (covidApp[i].POLICY_ATTACH_FILE_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].POLICY_ATTACH_FILE_DATE = timeConverter(covidApp[i].POLICY_ATTACH_FILE_DATE)
                    }
                }
                const exportedData = covidApp.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_all_' + newCovDateStart + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    const handleCancelCsv = (allColumns, allData) => {
        const columns = [
            { title: 'รหัสคำนำหน้าชื่อผู้เอาประกัน', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'ลิงค์บัตรประชาชน', field: 'NATIONAL_ID_ATTACH' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วันเดือนปีเกิด (พ.ศ.)', field: 'BIRTH_DATE' },
            { title: 'วันเริ่มคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'เลขที่บัญชี', field: '' },
            { title: 'ส่วนลด', field: '' },
            { title: 'ส่วนลดอื่นๆ', field: '' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'ลำดับที่ ผู้รับประโยชน์', field: '' },
            { title: 'คำนำหน้าชื่อ ผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ที่อยู่ผู้รับประโยชน์ ', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'ยอดเงินที่ชำระตาม PaySlip (บาท)', field: 'PAYMENT_AMOUNT' },
            { title: 'ลิงค์สลิปยอดจ่าย', field: 'PAYMENT_ATTACH' },
            { title: 'รหัสตัวแทน', field: 'SMK_BROKER_CODE' },
            { title: 'อีเมล์', field: 'EMAIL_ADDRESS' },
            { title: 'วันที่รับแจ้ง', field: 'CURRENT_DATE' },
            { title: 'เลขประจำตัวผู้เสียภาษี ในใบกำกับภาษี', field: 'NATIONAL_ID' },
            { title: 'การรับกรมธรรม์', field: 'SMK_RECIEVED_POLICY' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
            { title: 'ลิงค์ไฟล์กรมธรรม์', field: 'POLICY_NUM_FILE_URL' },
            { title: 'วันที่แนบไฟล์กรมธรรม์', field: 'POLICY_ATTACH_FILE_DATE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'เลขรันจากระบบสำหรับหนังสือคุ้มครอง', field: 'COVERNOTE_RUNNING_NUMBER' },
            { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
            { title: 'ชื่อตัวแทน', field: 'AGENT_NAME' },
            { title: 'โทเคนตัวแทน', field: 'AGENT_TOKEN' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'วันที่ชำระเงิน', field: 'GBPAYMENT_DATE_THAI' },
            { title: 'วันและเวลาที่ชำระเงิน', field: 'GBPAYMENT_DATE' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
            { title: 'เบี้ยสุทธิ', field: 'NET_PREMIUM_AMT' },
            { title: 'อากร', field: 'STAMP_DUTY_AMT' },
            { title: 'Vat', field: 'VAT_AMT' },
            { title: 'เบี้ยรวม', field: 'TOTAL_PREMIUM' },
            { title: 'สถานะ', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'หมายเหตุการยกเลิก', field: 'CANCEL_REMARK' },
            { title: 'วันที่ยกเลิก', field: 'CANCEL_DATE' },
        ]
        db.collection("covidApplication").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                covidApp = covidApp.filter(cf => cf.APP_FORM_PAYMENT_STATUS !== "INITIALIZE" && cf.APP_FORM_PAYMENT_STATUS === "CANCEL")
                for (var i in covidApp) {
                    if (covidApp[i].GBPAYMENT_DATE_THAI) {
                        covidApp[i].GBPAYMENT_DATE_THAI = `${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidApp[i].GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`
                    }
                    if (covidApp[i].GBPAYMENT_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].GBPAYMENT_DATE = timeConverter(covidApp[i].GBPAYMENT_DATE)
                    }
                    if (covidApp[i].POLICY_ATTACH_FILE_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].POLICY_ATTACH_FILE_DATE = timeConverter(covidApp[i].POLICY_ATTACH_FILE_DATE)
                    }
                }
                const exportedData = covidApp.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_all_' + newCovDateStart + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    const handleAgentSelectCsv = (agentCode) => {
        const columns = [
            { title: 'รหัสคำนำหน้าชื่อผู้เอาประกัน', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'ลิงค์บัตรประชาชน', field: 'NATIONAL_ID_ATTACH' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วันเดือนปีเกิด (พ.ศ.)', field: 'BIRTH_DATE' },
            { title: 'วันเริ่มคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'วันเริ่มคุ้มครอง (รูปแบบใหม่)', field: 'NEW_FORMAT_DATE_COV_START' },
            { title: 'เลขที่บัญชี', field: '' },
            { title: 'ส่วนลด', field: '' },
            { title: 'ส่วนลดอื่นๆ', field: '' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'ลำดับที่ ผู้รับประโยชน์', field: '' },
            { title: 'คำนำหน้าชื่อ ผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ที่อยู่ผู้รับประโยชน์ ', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'ยอดเงินที่ชำระตาม PaySlip (บาท)', field: 'PAYMENT_AMOUNT' },
            { title: 'ลิงค์สลิปยอดจ่าย', field: 'PAYMENT_ATTACH' },
            { title: 'รหัสตัวแทน', field: 'SMK_BROKER_CODE' },
            { title: 'อีเมล์', field: 'EMAIL_ADDRESS' },
            { title: 'วันที่รับแจ้ง', field: 'CURRENT_DATE' },
            { title: 'เลขประจำตัวผู้เสียภาษี ในใบกำกับภาษี', field: 'NATIONAL_ID' },
            { title: 'การรับกรมธรรม์', field: 'SMK_RECIEVED_POLICY' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
            { title: 'วันที่แนบไฟล์กรมธรรม์', field: 'POLICY_ATTACH_FILE_DATE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'เลขรันจากระบบสำหรับหนังสือคุ้มครอง', field: 'COVERNOTE_RUNNING_NUMBER' },
            { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
            { title: 'ชื่อตัวแทน', field: 'AGENT_NAME' },
            { title: 'โทเคนตัวแทน', field: 'AGENT_TOKEN' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'วันที่ชำระเงิน', field: 'GBPAYMENT_DATE_THAI' },
            { title: 'วันและเวลาที่ชำระเงิน', field: 'GBPAYMENT_DATE' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
            { title: 'เบี้ยสุทธิ', field: 'NET_PREMIUM_AMT' },
            { title: 'อากร', field: 'STAMP_DUTY_AMT' },
            { title: 'Vat', field: 'VAT_AMT' },
            { title: 'เบี้ยรวม', field: 'TOTAL_PREMIUM' },
        ]
        db.collection("covidApplication").where("AGENT_CODE", "==", agentCode).get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                covidApp = covidApp.filter(cf => cf.APP_FORM_PAYMENT_STATUS !== "INITIALIZE" && cf.APP_FORM_PAYMENT_STATUS !== "CANCEL")
                for (var i in covidApp) {
                    if (covidApp[i].GBPAYMENT_DATE_THAI) {
                        covidApp[i].GBPAYMENT_DATE_THAI = `${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidApp[i].GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`
                    }
                    if (covidApp[i].GBPAYMENT_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].GBPAYMENT_DATE = timeConverter(covidApp[i].GBPAYMENT_DATE)
                    }
                    if (covidApp[i].POLICY_ATTACH_FILE_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].POLICY_ATTACH_FILE_DATE = timeConverter(covidApp[i].POLICY_ATTACH_FILE_DATE)
                    }
                    covidApp[i].NEW_FORMAT_DATE_COV_START = "'" + covidApp[i].DATE_COV_START
                }
                const exportedData = covidApp.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_' + agentCode + '_' + newCovDateStart + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    return (
        <React.Fragment>
            <section className="bg-half-100 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <img src={tvinsurelogo} height="120" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="title-heading text-center">
                        <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                            ระบบบริหารงานประกันภัย
                        </h4>
                        <p className="text-center text-white h5 mt-1 mb-5">สำหรับการใช้งานของพนักงานหลังบ้านเท่านั้น</p>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
            <section style={{ margin: "-120px 0 0" }}>
                <Container>
                    <Row className="justify-content-center">
                        {covidAppSelect && !isLoading && <Modal isOpen={cardCovidAppDetailModal} >
                            <ModalHeader toggle={closeCardCovidAppDetailModal}>เลขที่การสมัคร {covidAppSelect.APP_FORM_PAYMENT_STATUS === "CANCEL" ? (<strike className="text-danger">{covidAppSelect.APP_FORM_ID}</strike>) : covidAppSelect.APP_FORM_ID} {covidAppSelect.APP_FORM_PAYMENT_STATUS === "CANCEL" && <span className="text-danger">ยกเลิก</span>}</ModalHeader>
                            <ModalBody className="pb-2">
                                <p># {covidAppSelect.CURRENT_DATE}</p>
                                <div className="row">
                                    <p className="col-12 text-center h5 font-weight-bold">รายละเอียดแผนประกัน</p>
                                    <p className="col-12 text-center font-weight-bold mb-3">{covidAppSelect.PLAN_ID}{covidAppSelect.APP_FORM_TYPE}</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        แผน:
                                    </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_DESC}
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        บริษัทประกัน:
                                    </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_ISSUER}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบี้ยสุทธิ:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.NET_PREMIUM_AMT} บาท
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        อากร:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.STAMP_DUTY_AMT} บาท
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        VAT:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.VAT_AMT} บาท
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ราคาเบี้ยประกันภัย(เบี้ยรวม):
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.TOTAL_PREMIUM} บาท
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้เอาประกัน</p>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่คุ้มครอง (ตามใบคำขอ):
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        ชื่อผู้เอาประกัน:
                                    </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.PRE_NAME} {covidAppSelect.FIRST_NAME} {covidAppSelect.LAST_NAME}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันเดือนปีเกิดผู้เอาประกัน:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BIRTH_DATE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เพศ:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GENDER === "M" ? "ชาย" : "หญิง"}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        บัตรประชาชน:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.NATIONAL_ID_ATTACH && <a className="col-12 text-primary" target="_blank" href={covidAppSelect.NATIONAL_ID_ATTACH}>{covidAppSelect.NATIONAL_ID}</a>}
                                        {!covidAppSelect.NATIONAL_ID_ATTACH && <p className="col-12">{covidAppSelect.NATIONAL_ID}</p>}
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        ที่อยู่:
                                    </div>
                                    <div className="col-9 text-right">
                                        {covidAppSelect.ADDRESS_DETAIL}
                                    </div>
                                    <div className="col-12 text-right mb-2">
                                        {covidAppSelect.ADDRESS_SUB_DISTRICT} - {covidAppSelect.ADDRESS_DISTRICT} - จังหวัด{covidAppSelect.ADDRESS_PROVINCE} - {covidAppSelect.ADDRESS_ZIP_CODE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ชื่อผู้รับประโยชน์:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BENEFIT_PRE_NAME} {covidAppSelect.BENEFIT_FIRST_NAME} {covidAppSelect.BENEFIT_LAST_NAME} ({covidAppSelect.BENEFIT_TYPE})
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบอร์โทรศัพท์:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.PHONE_NUM}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        อีเมล:
                                    </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.EMAIL_ADDRESS}
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้ดูแล</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        ผู้ดูแล:
                                    </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.AGENT_CODE} - {covidAppSelect.AGENT_NAME} {covidAppSelect.AGENT_REMARK}
                                    </div>
                                    {covidAppSelect.PAYMENT_AMOUNT && <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดการชำระเงิน</p>}
                                    {covidAppSelect.GBTOTAL_AMOUNT && <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดการชำระเงิน</p>}
                                    {covidAppSelect.PAYMENT_AMOUNT && covidAppSelect.PAYMENT_ATTACH && <div className="col-6 text-left font-weight-bold">
                                        ยอดชำระเงิน:
                                    </div>}
                                    {covidAppSelect.PAYMENT_AMOUNT && covidAppSelect.PAYMENT_ATTACH && <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.PAYMENT_ATTACH}>{covidAppSelect.PAYMENT_AMOUNT} บาท</a>
                                    </div>}
                                    {covidAppSelect.PAYMENT_DATE && covidAppSelect.PAYMENT_TIME && <div className="col-3 text-left font-weight-bold">
                                        ข้อมูลอื่น ๆ:
                                    </div>}
                                    {covidAppSelect.PAYMENT_DATE && covidAppSelect.PAYMENT_TIME && <div className="col-9 text-right mb-2">
                                        วันที่ชำระ {covidAppSelect.PAYMENT_DATE} - {covidAppSelect.PAYMENT_TIME}
                                    </div>}
                                    {covidAppSelect.APP_FORM_PAYMENT_STATUS && <div className="col-6 text-left font-weight-bold">
                                        สถานะการชำระเงิน:
                                    </div>}
                                    {covidAppSelect.APP_FORM_PAYMENT_STATUS && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.APP_FORM_PAYMENT_STATUS === "COMPLETE" ? "ชำระสำเร็จ" : covidAppSelect.APP_FORM_PAYMENT_STATUS === "INITIALIZE" ? "ชำระไม่สำเร็จ" : covidAppSelect.APP_FORM_PAYMENT_STATUS === "CANCEL" ? "ยกเลิก" : "มีความขัดข้อง"}
                                    </div>}
                                    {covidAppSelect.CANCEL_REMARK && <div className="col-5 text-left font-weight-bold">
                                        หมายเหตุการยกเลิก:
                                    </div>}
                                    {covidAppSelect.CANCEL_REMARK && <div className="col-7 text-right mb-2">
                                        {covidAppSelect.CANCEL_REMARK} {covidAppSelect.CANCEL_AUTHORIZATION_CODE}
                                    </div>}
                                    {covidAppSelect.CANCEL_DATE && <div className="col-6 text-left font-weight-bold">
                                        วันที่ยกเลิก:
                                    </div>}
                                    {covidAppSelect.CANCEL_DATE && <div className="col-6 text-right mb-2">
                                        <p>
                                            {timeConverter(covidAppSelect.CANCEL_DATE)}
                                        </p>
                                    </div>}
                                    {covidAppSelect.APP_FORM_REF_PAYMENT_NO && <div className="col-6 text-left font-weight-bold">
                                        หมายเลขการอ้างอิง TVinsure:
                                    </div>}
                                    {covidAppSelect.APP_FORM_REF_PAYMENT_NO && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.APP_FORM_REF_PAYMENT_NO}
                                    </div>}
                                    {covidAppSelect.GBPAYMENT_NO && <div className="col-6 text-left font-weight-bold">
                                        หมายเลขอ้างอิงการชำระ GB 1:
                                    </div>}
                                    {covidAppSelect.GBPAYMENT_NO && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GBPAYMENT_NO}
                                    </div>}
                                    {covidAppSelect.GBREF_NO && <div className="col-6 text-left font-weight-bold">
                                        หมายเลขอ้างอิงการชำระ GB 2:
                                    </div>}
                                    {covidAppSelect.GBREF_NO && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GBREF_NO}
                                    </div>}
                                    {covidAppSelect.GBTOTAL_AMOUNT && <div className="col-6 text-left font-weight-bold">
                                        ยอดการชำระเงิน:
                                    </div>}
                                    {covidAppSelect.GBTOTAL_AMOUNT && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GBTOTAL_AMOUNT} บาท
                                    </div>}
                                    {/* <div className="col-6 text-left font-weight-bold">
                                    วันที่คุ้มครอง (ตามหน้ากรมธรรม์):
                                </div>
                                {covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                    {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                </div>}
                                {!covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                    {formData.DATE_COV_START} - {formData.DATE_COV_END}
                                </div>} */}
                                    {covidAppSelect.POLICY_NUM && <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดกรมธรรม์</p>}
                                    {covidAppSelect.COVERNOTE_NUM && <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดความคุ้มครอง</p>}
                                    {covidAppSelect.COVERNOTE_NUM && <div className="col-6 text-left font-weight-bold">
                                        เลขความคุ้มครอง:
                                    </div>}
                                    {covidAppSelect.COVERNOTE_NUM && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.PLAN_ISSUER_CODE === "SEI" && covidAppSelect.COVERNOTE_NUM && <Link className="text-danger" to={`/get-covid-cover-note/${covidAppSelect.APP_FORM_ID}/${covidAppSelect.COVERNOTE_NUM}`} target="_blank">{covidAppSelect.COVERNOTE_NUM}</Link>}
                                    </div>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-4 text-left font-weight-bold">
                                        เลขกรมธรรม์:
                                    </div>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-8 text-right mb-2">
                                        {covidAppSelect.POLICY_NUM_FILE_URL && <a className="text-primary" target="_blank" href={covidAppSelect.POLICY_NUM_FILE_URL}>{covidAppSelect.POLICY_NUM}</a>}
                                        {!covidAppSelect.POLICY_NUM_FILE_URL && covidAppSelect.NEW_POLICY_URL && <a className="text-primary" target="_blank" href={covidAppSelect.NEW_POLICY_URL}>{covidAppSelect.POLICY_NUM} <i><FeatherIcon icon="file" className="fea icon-sm icons pl-1 text-danger" /></i></a>}
                                        {!covidAppSelect.POLICY_NUM_FILE_URL && !covidAppSelect.NEW_POLICY_URL && <p className="text-dark"> {covidAppSelect.POLICY_NUM} </p>}
                                    </div>}
                                    {covidAppSelect.POLICY_ATTACH_FILE_DATE && <div className="col-6 text-left font-weight-bold">
                                        วันที่แนบไฟล์กรมธรรม์:
                                    </div>}
                                    {covidAppSelect.POLICY_ATTACH_FILE_DATE && <div className="col-6 text-right mb-2">
                                        <p>
                                            {timeConverter(covidAppSelect.POLICY_ATTACH_FILE_DATE)}
                                        </p>
                                    </div>}
                                    {covidAppSelect.DELETE_POLICY_FILE_DATE && <div className="col-6 text-left font-weight-bold">
                                        วันที่ลบกรมธรรม์ล่าสุด:
                                    </div>}
                                    {covidAppSelect.DELETE_POLICY_FILE_DATE && <div className="col-6 text-right mb-2">
                                        <p>
                                            {timeConverter(covidAppSelect.DELETE_POLICY_FILE_DATE)}
                                        </p>
                                    </div>}
                                    {covidAppSelect.DELETE_POLICY_FILE_REMARK && <div className="col-6 text-left font-weight-bold">
                                        หมายเหตุการลบไฟล์และเลขล่าสุด:
                                    </div>}
                                    {covidAppSelect.DELETE_POLICY_FILE_REMARK && <div className="col-6 text-right mb-2">
                                        <p>
                                            {covidAppSelect.DELETE_POLICY_FILE_REMARK} - {covidAppSelect.DELETE_AUTHORIZATION_CODE}
                                        </p>
                                    </div>}
                                </div>
                                {covidAppSelect.POLICY_NUM && <Card className="border-0 rounded mt-3 mb-2">
                                    <Link
                                        to="#"
                                        onClick={t_col1}
                                        className={
                                            allCol.col1
                                                ? "faq position-relative text-primary"
                                                : "faq position-relative text-dark"
                                        }
                                    >
                                        <CardHeader
                                            className="border-0 bg-light"
                                            id="headingOne"
                                        >
                                            <h6 className="title mb-0">
                                                ต้องการลบไฟล์กรมธรรม์ออกจากระบบ ?
                                                <i
                                                    className={
                                                        allCol.col1
                                                            ? "mdi mdi-chevron-up float-right"
                                                            : "mdi mdi-chevron-down float-right"
                                                    }
                                                ></i>
                                            </h6>
                                        </CardHeader>
                                    </Link>
                                    <Collapse isOpen={allCol.col1}>
                                        <CardBody className="px-2 py-4">
                                            <p className="text-dark font-weight-bold">การลบไฟล์กรมธรรม์ครั้งนี้ไม่ใช่การยกเลิกกรมธรรม์เป็นแค่การนำไฟล์ออกจากระบบ โดยผู้เอาประกันคนดังกล่าวจะไม่สามารถดาวโหลดไฟล์และเข้าถึงเลขกรมธรรม์ดังกล่าวได้ในทุกช่องทาง กรุณาใช้วิจารณญาณและโปรดตรวจสอบให้ดีก่อนการลบไฟล์</p>
                                            <p className="text-dark"><small> 1. ตรวจสอบให้แน่ใจว่าลบในระบบหลักแล้ว TVMS หรือ รายการดังกล่าวไม่ได้กระทบในระบบหลัก เพราะการลบครั้งนี้เป็นแค่การลบในหน้าเว็ปเท่านั้น ไม่ได้เป็นการลบเข้าถึงระบบหลัก ถ้ามีเลขกรมธรรม์ในระบบหลักแล้วและเลขกรมธรรม์นี้ไม่ถูกต้องให้ตั้งสถานะยกเลิกไว้ด้วยหรือทำการถอนประกันก่อนการลบในเว็ป</small></p>
                                            <p className="text-dark"><small> 2. ใส่รายละเอียดการลบไฟล์และกรมธรรม์ทุกครั้ง ห้ามทิ้งไว้เป็นค่าว่างเพื่อที่จะให้คนอื่น ๆ กลับเข้ามาเช็ครายละเอียดได้</small></p>
                                            <p className="text-dark"><small> 3. ใส่รหัสผ่านการอนุมัติรายการให้ถูกต้อง หลังจากนั้นกดลบไฟล์และเลขกรมธรรม์ ระบบจะทำการลบไฟล์และเลขกรมธรรม์ทั้งหมด</small></p>
                                            <AvForm className="mt-2" onValidSubmit={
                                                (event) => {
                                                    if (
                                                        formCancelPolicyNumDataAndFile.authorizedCode === "SUKRITB"
                                                        || formCancelPolicyNumDataAndFile.authorizedCode === "PENNAPA"
                                                        || formCancelPolicyNumDataAndFile.authorizedCode === "JIRAPORN"
                                                        || formCancelPolicyNumDataAndFile.authorizedCode === "PHIKUL"
                                                        || formCancelPolicyNumDataAndFile.authorizedCode === "SATHITA"
                                                        || formCancelPolicyNumDataAndFile.authorizedCode === "SANITA"
                                                    ) {
                                                        const check = window.confirm("ยืนยันการลบไฟล์กรมธรรม์และเลข")
                                                        if (check === true) {
                                                            handleCancelPolicyNumDataAndFileSubmit(event)
                                                        } else {
                                                            return false
                                                        }
                                                    } else {
                                                        alert("ไม่อนุมัติการลบไฟล์กรมธรรม์กรุณาติดต่อผู้ดูแลระบบ")
                                                    }
                                                }
                                            }>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>หมายเหตุการลบไฟล์กรมธรรม์และลบเลข <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่หมายเหตุการลบไฟล์กรมธรรม์และลบเลข"
                                                            validate={{ required: { value: true } }} placeholder="ใส่หมายเหตุการลบไฟล์กรมธรรม์และลบเลข" name="remarkDeleteFileAndNum" onChange={handleCancelPolicyNumDataAndFileFormChange} value={formCancelPolicyNumDataAndFile.remarkDeleteFileAndNum} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>รหัสการอนุมัติการลบไฟล์ <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่รหัสการอนุมัติการลบไฟล์"
                                                            validate={{ required: { value: true } }} placeholder="ใส่หมายใส่รหัสการอนุมัติการลบไฟล์" name="authorizedCode" onChange={handleCancelPolicyNumDataAndFileFormChange} value={formCancelPolicyNumDataAndFile.authorizedCode} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12} className="mb-4 auth-button">
                                                    <Button color="danger" block >ลบไฟล์กรมธรรม์และเลขกรมธรรม์</Button>
                                                </Col>
                                            </AvForm>
                                        </CardBody>
                                    </Collapse>
                                </Card>}

                                {covidAppSelect.POLICY_NUM && covidAppSelect.POLICY_NUM_FILE_URL && <Card className="border-0 rounded mt-3 mb-2">
                                    <Link
                                        to="#"
                                        onClick={t_col2}
                                        className={
                                            allCol.col2
                                                ? "faq position-relative text-primary"
                                                : "faq position-relative text-dark"
                                        }
                                    >
                                        <CardHeader
                                            className="border-0 bg-light"
                                            id="headingOne"
                                        >
                                            <h6 className="title mb-0">
                                                ต้องการส่งข้อมูลกรมธรรม์เข้าระบบอีกครั้ง ?
                                                <i
                                                    className={
                                                        allCol.col2
                                                            ? "mdi mdi-chevron-up float-right"
                                                            : "mdi mdi-chevron-down float-right"
                                                    }
                                                ></i>
                                            </h6>
                                            <p className="mb-0">(เลขกรมธรรม์และแผนประกันถูกต้อง)</p>
                                        </CardHeader>
                                    </Link>
                                    <Collapse isOpen={allCol.col2}>
                                        <CardBody className="px-2 py-4">
                                            <p className="text-dark font-weight-bold">การส่งข้อมูลกรมธรรม์เข้าระบบเนื่องจากระบบเกิดข้อผิดพลาดบางกรณี </p>
                                            <p className="text-dark"><small> ตรวจสอบว่ากรมธรรม์ไม่มีข้อมูลในระบบจริงจากการค้นหาในระบบ TVMS และต้องทราบไว้ว่าการส่งกรมธรรม์เข้าระบบลูกค้าจะได้อีเมลตอบกลับการส่งไฟล์กรมธรรม์ด้วย กรุณาอย่ากดหลายครั้งเนื่องจากอีเมลจะถูกส่งให้ลูกค้าทุกครั้ง</small></p>
                                            <AvForm className="mt-2" onValidSubmit={
                                                (event) => {
                                                    const check = window.confirm("ยืนยันการส่งข้อมูลกรมธรรม์เข้าระบบอีกครั้ง")
                                                    if (check === true) {
                                                        handleResendPolicySubmit(event)
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            }>
                                                <Col lg={12} className="mb-4 auth-button">
                                                    <Button color="danger" block >ส่งข้อมูลกรมธรรม์เข้าระบบอีกครั้ง</Button>
                                                </Col>
                                            </AvForm>
                                        </CardBody>
                                    </Collapse>
                                </Card>}


                            </ModalBody>
                            <ModalFooter className="mx-auto">
                                <Button color="danger" outline onClick={closeCardCovidAppDetailModal}>ย้อนกลับ</Button>
                            </ModalFooter>
                        </Modal>}



                        <Col lg={12} md={10} sm={8}>

                            <Menubar page="list-policy-all" />

                            <Card className="my-3 shadow">
                                <CardBody >
                                    {!isLoading && <h6 className="text-center h4 my-4"> กรอกรหัสตัวแทนและกดดาวโหลดเพื่อเรียกข้อมูลลง Excel</h6>}

                                    <Col md={12}>
                                        <AvForm className="login-form mt-4">
                                            <Row>
                                                <Col md={8}>
                                                    <FormGroup>
                                                        <Label>กรอกเลขรหัสตัวแทนเพื่อดาวโหลดข้อมูล</Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณากรอกเลขรหัสตัวแทนเพื่อดาวโหลดข้อมูล"
                                                            validate={{ required: { value: true } }} placeholder="กรุณากรอกเลขรหัสตัวแทน" name="AgentDownload" onChange={handleAgentDownload} value={covidDataAgentDownload} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>กดดาวโหลดเพื่อเรียก Excel</Label>
                                                        <Button block color="success" onClick={() => handleAgentSelectCsv(covidDataAgentDownload)} disabled={!covidDataAgentDownload}> ดาวโหลด Excel</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </Col>
                                </CardBody>
                            </Card>

                            <Card className="mb-5 shadow">
                                <CardBody >

                                    {error && <div className="row">
                                        <div className="col-xl-12 text-center mt-5">
                                            <div style={{ maxWidth: '100%' }}>
                                                <p className="text-dark font-weight-bold">{error}</p>
                                            </div>
                                        </div>
                                    </div>}

                                    {isLoading && <div className="row">
                                        <div className="col-xl-12 text-center mt-5">
                                            <div style={{ maxWidth: '100%' }}>
                                                <Spinner color="danger" />
                                                <p className="text-dark font-weight-bold mt-5">กำลังโหลดข้อมูล...</p>
                                            </div>
                                        </div>
                                    </div>}

                                    {!isLoading && <h6 className="text-center h4 my-4"> เลือกเงื่อนไขการเรียกข้อมูลและกดค้นหาเพื่อเรียกข้อมูล</h6>}
                                    {!isLoading && covidDataIssuerSearchType && <h6 className="text-center h6 my-4"> คุณกำลังค้นหาข้อมูลจาก {covidDataIssuerSearchType} {covidDataAppDocSearchType && `ใบคำขอเลขที่ ${covidDataAppDocSearchType}`} {covidDataAppDocSearchType && covidDataIdCardSearchType && "และ"}{covidDataIdCardSearchType && `บัตรประชาชน/พาสปอร์ตเลขที่ ${covidDataIdCardSearchType}`}</h6>}
                                    {!isLoading && <AvForm className="login-form mt-4">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>เลขคำขอผู้สมัครประกันภัย</Label>
                                                    <AvField type="text"
                                                        placeholder="กรุณากรอกเลขคำขอ - CV CVGB CVRN" name="AppDocIdSearch" onChange={handleAppDocSearchSelected} value={covidDataAppDocSearchType} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>เลขบัตรประชาชนหรือพาสปอต</Label>
                                                    <AvField type="text"
                                                        placeholder="กรอกเลขบัตรประชาชน หรือ พาสปอร์ตเพื่อค้นหา" name="IdCardSearch" onChange={handleIdCardSearchSelected} value={covidDataIdCardSearchType} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AvForm>}

                                    {!isLoading && <AvForm className="login-form mt-4" onValidSubmit={() => setrefresh(!refresh)}>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลือกบริษัทประกันภัยที่ต้องการดูรายละเอียด</Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกบริษัทประกันภัย"
                                                        validate={{ required: { value: true } }} name="Issuer" onChange={handleIssuerSearchSelected} value={covidDataIssuerSearchType}>
                                                        <option value="" disabled>ค้นหาตามบริษัทประกันภัย</option>
                                                        <option value="AII">เรียกข้อมูลประกันโควิดใหม่เอเชียประกันภัย - AII</option>
                                                        <option value="DHP">เรียกข้อมูลประกันโควิดใหม่ทิพยประกันภัย - DHP</option>
                                                        <option value="SMK">เรียกข้อมูลประกันโควิดใหม่สินมั่นคงประกันภัย - SMK</option>
                                                        <option value="VIB">เรียกข้อมูลประกันโควิดใหม่วิริยะประกันภัย - VIB</option>
                                                        <option value="SEI">เรียกข้อมูลประกันโควิดใหม่อาคเนย์ประกันภัย - SEI</option>
                                                        <option value="TOTAL">เรียกข้อมูลประกันโควิดใหม่ทั้งหมด - TOTAL</option>
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>กดค้นหาเพื่อเรียกข้อมูล</Label>
                                                    <Button type="submit" block color="danger" disabled={isLoading}> ค้นหาข้อมูลใหม่ </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AvForm>}
                                </CardBody>

                            </Card>


                            <MaterialTable
                                icons={tableIcons}
                                columns={[
                                    { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
                                    { title: 'เลขที่การสมัคร', field: 'APP_FORM_ID' },
                                    { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
                                    { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
                                    { title: 'วันที่เริ่มความคุ้มครอง', field: 'DATE_COV_START' },
                                    { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
                                    { title: 'บริษัทประกัน', field: 'PLAN_ISSUER_CODE' },
                                    { title: 'เบี้ยประกันภัย', field: 'TOTAL_PREMIUM' },
                                    { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
                                    { title: 'รหัสอ้างอิง TVinsure', field: 'APP_FORM_REF_PAYMENT_NO' },
                                    { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
                                ]}
                                data={covidAppList}
                                title={isLoading ? "กำลังโหลดข้อมูล..." : covidAppList.length > 0 ? `พบรายการที่ค้นหาทั้งหมด ${covidAppList.length} รายการ` : "กรุณาใส่เงื่อนไขและกดค้นหาเพื่อเรียกข้อมูล"}
                                actions={[
                                    {
                                        icon: () => <ChevronRightIcon />,
                                        tooltip: 'รายละเอียด',
                                        onClick: (event, rowData) => {
                                            openCardCovidAppDetailModal(rowData.APP_FORM_ID);
                                        }
                                    }
                                ]}

                                components={{
                                    Toolbar: props => (
                                        <Container>
                                            <Row>
                                                <Col lg={12}    >
                                                    <MTableToolbar {...props} />
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="btn btn-pills btn-primary mb-3" style={{ float: 'right' }} onClick={() => setrefresh(!refresh)}> ดึงข้อมูลใหม่ </div>
                                                            <div className="btn btn-pills btn-success mb-3 mr-2" style={{ float: 'right' }} onClick={handleAllCsv}> ข้อมูลการสมัครที่ใช้งานทั้งหมด </div>
                                                            <div className="btn btn-pills btn-danger mb-3 mr-2" style={{ float: 'right' }} onClick={handleCancelCsv}> ข้อมูลการสมัครที่ยกเลิกทั้งหมด </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    ),
                                }}

                                options={{
                                    tableLayout: "auto",
                                    exportButton: true,
                                    grouping: true,
                                    headerStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    searchFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    toolbarFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    paging: true,
                                    pageSize: 20,       // make initial page size
                                    emptyRowsWhenPaging: false,   //to make page size not fix (dynamic) in case of less data rows
                                    pageSizeOptions: [5, 10, 20, 50, 100, 200],    // rows selection options
                                }}

                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} จาก {count}',
                                        labelRowsSelect: 'รายการ'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} รายการที่เลือก',
                                        searchPlaceholder: 'ค้นหา'
                                    },
                                    header: {
                                        actions: ''
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'ไม่มีรายการ',
                                        filterRow: {
                                            filterTooltip: 'ตัวกรอง'
                                        }
                                    },
                                    grouping: {
                                        placeholder: 'ลากหัวข้อมาที่นี่เพื่อแบ่งกลุ่ม',
                                        groupedBy: 'แบ่งกลุ่มโดย: '
                                    }
                                }}

                            />
                        </Col>
                        <p className="text-center my-4 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default ListPolicyRenew;
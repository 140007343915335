
// Import Ref Data
import { month, planCovidNewList } from '../../shared/masters/refData'

// PDF Related Modules
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'
import download from '../../shared/context/download'

// get FILES URL routes
import { FILES_URL } from '../../shared/masters/url'

async function GetCovernoteSeiDiagnostic(data) {
    // Fetch an existing PDF document
    const url = `${FILES_URL}/pdf-template/sei-cover-note-diagnostic-new`
    const existingPdfBytes = await fetch(url,).then(res => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    const angsanaUrlFont = `${FILES_URL}/pdf-fonts/angsana-new`
    const angsanaFontBytes = await fetch(angsanaUrlFont).then((res) => res.arrayBuffer());

    pdfDoc.registerFontkit(fontkit);
    const angsananewFont = await pdfDoc.embedFont(angsanaFontBytes);

    const textSize = 12
    const textHeight = angsananewFont.heightAtSize(textSize)

    // Get the All page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()

    // Draw a string of text diagonally across the page

    // ------------------------------------------------- Page 1 ------------------------------------------------
    firstPage.drawText(`${data.COVERNOTE_NUM}`, {
        x: 70,
        y: 756,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.PRE_NAME} ${data.FIRST_NAME} ${data.LAST_NAME}`, {
        x: 175,
        y: 634,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    const fullAdress = `${data.ADDRESS_DETAIL}`

    firstPage.drawText(fullAdress.slice(0, 98), {
        x: 175,
        y: 618,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.ADDRESS_SUB_DISTRICT} ${data.ADDRESS_DISTRICT} จังหวัด${data.ADDRESS_PROVINCE} ${data.ADDRESS_ZIP_CODE}`, {
        x: 175,
        y: 604,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.BENEFIT_PRE_NAME} ${data.BENEFIT_FIRST_NAME} ${data.BENEFIT_LAST_NAME}  (${data.BENEFIT_TYPE})`, {
        x: 175,
        y: 586,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })
    {/* SEI Cov Date depend on payment Date  Only SEI */ }
    firstPage.drawText(`${data.GBPAYMENT_DATE_THAI.split("/")[1]}/${data.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(data.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`, {
        x: 245,
        y: 568,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })
    {/* SEI Cov Date depend on payment Date  Only SEI */ }
    firstPage.drawText(`${data.GBPAYMENT_DATE_THAI.split("/")[1]}/${data.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(data.GBPAYMENT_DATE_THAI.split("/")[2]) + 544}`, {
        x: 375,
        y: 568,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    if (planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID).length > 0) {
        firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_DIAGNOSTIC_PAY_AMT)}`, {
            x: 470,
            y: 534,
            size: textSize,
            font: angsananewFont,
            color: rgb(0, 0, 0),
        })

        firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_PA)}`, {
            x: 470,
            y: 502,
            size: textSize,
            font: angsananewFont,
            color: rgb(0, 0, 0),
        })
    }



    // console.log(month)

    {/* SEI Cov Date depend on payment Date  Only SEI */ }
    firstPage.drawText(`${Number(data.GBPAYMENT_DATE_THAI.split("/")[1])} ${month.filter(m => m.monthValue === data.GBPAYMENT_DATE_THAI.split("/")[0])[0].monthDesc} ${Number(data.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`, {
        x: 285,
        y: 436,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.COVERNOTE_NUM} - ${data.APP_FORM_ID} - ${data.CURRENT_DATE} - ${data.PLAN_ID} - TVinsure.com | ทวีทรัพย์ โบรคเกอร์`, {
        x: 40,
        y: 40,
        size: 9,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
        var year = a.getFullYear() - 1426;
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    firstPage.drawText(`${data.GBPAYMENT_DATE_THAI && "วันที่คุ้มครอง/ชำระเงิน - " + data.GBPAYMENT_DATE_THAI}  วันที่ดาวโหลดเอกสาร ${new Date}`, {
        x: 40,
        y: 30,
        size: 9,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${data.COVERNOTE_NUM}.pdf`, "application/pdf");
}

async function GetCovernoteSeiPlus(data) {
    // Fetch an existing PDF document
    const url = `${FILES_URL}/pdf-template/sei-cover-note-plus-new`
    const existingPdfBytes = await fetch(url,).then(res => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    const angsanaUrlFont = `${FILES_URL}/pdf-fonts/angsana-new`
    const angsanaFontBytes = await fetch(angsanaUrlFont).then((res) => res.arrayBuffer());

    pdfDoc.registerFontkit(fontkit);
    const angsananewFont = await pdfDoc.embedFont(angsanaFontBytes);

    const textSize = 12
    const textHeight = angsananewFont.heightAtSize(textSize)

    // Get the All page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()

    // Draw a string of text diagonally across the page

    // ------------------------------------------------- Page 1 ------------------------------------------------
    firstPage.drawText(`${data.COVERNOTE_NUM}`, {
        x: 70,
        y: 756,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.PRE_NAME} ${data.FIRST_NAME} ${data.LAST_NAME}`, {
        x: 175,
        y: 618,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    const fullAdress = `${data.ADDRESS_DETAIL}`

    firstPage.drawText(fullAdress.slice(0, 98), {
        x: 175,
        y: 602,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.ADDRESS_SUB_DISTRICT} ${data.ADDRESS_DISTRICT} จังหวัด${data.ADDRESS_PROVINCE} ${data.ADDRESS_ZIP_CODE}`, {
        x: 175,
        y: 588,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.BENEFIT_PRE_NAME} ${data.BENEFIT_FIRST_NAME} ${data.BENEFIT_LAST_NAME}  (${data.BENEFIT_TYPE})`, {
        x: 175,
        y: 570,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })
    {/* SEI Cov Date depend on payment Date  Only SEI */ }
    firstPage.drawText(`${data.GBPAYMENT_DATE_THAI.split("/")[1]}/${data.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(data.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`, {
        x: 245,
        y: 552,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })
    {/* SEI Cov Date depend on payment Date  Only SEI */ }
    firstPage.drawText(`${data.GBPAYMENT_DATE_THAI.split("/")[1]}/${data.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(data.GBPAYMENT_DATE_THAI.split("/")[2]) + 544}`, {
        x: 375,
        y: 552,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    if (planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID).length > 0) {
        firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_DIAGNOSTIC_PAY_AMT)}`, {
            x: 485,
            y: 517,
            size: textSize,
            font: angsananewFont,
            color: rgb(0, 0, 0),
        })

        firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_COMA_PAY_AMT)}`, {
            x: 485,
            y: 485,
            size: textSize,
            font: angsananewFont,
            color: rgb(0, 0, 0),
        })

        firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_HOSPITAL_PAY_AMT)}`, {
            x: 485,
            y: 453,
            size: textSize,
            font: angsananewFont,
            color: rgb(0, 0, 0),
        })

        if (planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID).length > 0 && Number(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT) === 0) {
            firstPage.drawText(`ไม่คุ้มครอง`, {
                x: 480,
                y: 415,
                size: textSize - 2,
                font: angsananewFont,
                color: rgb(0, 0, 0),
            })
        }

        if (planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID).length > 0 && Number(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT) > 0) {
            firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT)} บาท/วัน`, {
                x: 480,
                y: 417,
                size: textSize - 2,
                font: angsananewFont,
                color: rgb(0, 0, 0),
            })

            firstPage.drawText(`(สูงสุด ${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_COMPENSTAION_DAY)} วัน)`, {
                x: 478,
                y: 407,
                size: textSize - 2,
                font: angsananewFont,
                color: rgb(0, 0, 0),
            })
        }

        firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_PA)}`, {
            x: 485,
            y: 386,
            size: textSize,
            font: angsananewFont,
            color: rgb(0, 0, 0),
        })

        firstPage.drawText(`${Intl.NumberFormat('th-TH').format(planCovidNewList.filter(pf => pf.PLAN_ID === data.PLAN_ID)[0].PLAN_VACCINE_GIFT)}`, {
            x: 485,
            y: 337,
            size: textSize,
            font: angsananewFont,
            color: rgb(0, 0, 0),
        })
    }



    // console.log(month)

    {/* SEI Cov Date depend on payment Date  Only SEI */ }
    firstPage.drawText(`${Number(data.GBPAYMENT_DATE_THAI.split("/")[1])} ${month.filter(m => m.monthValue === data.GBPAYMENT_DATE_THAI.split("/")[0])[0].monthDesc} ${Number(data.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`, {
        x: 285,
        y: 272,
        size: textSize,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.COVERNOTE_NUM} - ${data.APP_FORM_ID} - ${data.CURRENT_DATE} - ${data.PLAN_ID} - TVinsure.com | ทวีทรัพย์ โบรคเกอร์`, {
        x: 40,
        y: 40,
        size: 9,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
        var year = a.getFullYear() - 1426;
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    firstPage.drawText(`${data.GBPAYMENT_DATE_THAI && "วันที่คุ้มครอง/ชำระเงิน - " + data.GBPAYMENT_DATE_THAI}  วันที่ดาวโหลดเอกสาร ${new Date}`, {
        x: 40,
        y: 30,
        size: 9,
        font: angsananewFont,
        color: rgb(0, 0, 0),
    })

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${data.COVERNOTE_NUM}.pdf`, "application/pdf");
}

export { GetCovernoteSeiDiagnostic, GetCovernoteSeiPlus }
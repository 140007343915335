import _filefy, { CsvBuilder } from 'filefy';

import { storage, db } from '../../firebase/config'
import { Link } from "react-router-dom";
import axios from 'axios'
import { covidApi } from '../../shared/masters/url';

import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Collapse  } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { AuthContext } from '../../shared/context/auth-context';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';

import { forwardRef } from 'react';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid.png';

import MaterialTable, { MTableToolbar } from 'material-table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import "./ListPolicy.css";
import Menubar from '../components/MenuBar';

import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';

// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

import BackgroundSlider from "react-background-slider";

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const currentDate = new Date()

function addYear(date) {
    const copy = new Date(Number(date))
    copy.setYear(date.getFullYear() + 1)
    return copy
}

const newCovDateStart = currentDate.toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
const newCovDateEnd = addYear(currentDate).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })



const ListPolicyNew = () => {

    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);;
    const [covidAppList, setCovidAppList] = useState([]);
    const [covidAppSelectId, setcovidAppSelectId] = useState();
    const [covidAppSelect, setcovidAppSelect] = useState();
    const [refresh, setrefresh] = useState(false);
    const [formData, setFormData] = useState({
        POLICY_NUM: "",
        POLICY_NUM_FILE_NAME: "",
        POLICY_NUM_FILE_URL: "",
        POLICY_NUM_FILE_STATUS: "unselect",
        DATE_COV_START: newCovDateStart,
        DATE_COV_END: newCovDateEnd
    });

    const handleVibCsv = (allColumns, allData) => {
        const columns = [
            { title: 'ลำดับที่', field: 'VIB_NUM' },
            { title: 'รหัสสาขา', field: 'VIB_BRANCH' },
            { title: 'รหัสนายหน้า', field: 'VIB_BROKER' },
            { title: 'วันคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'วันสิ้นสุด', field: 'DATE_COV_END' },
            { title: 'จำนวนวันที่คุ้มครอง', field: 'VIB_COV_PERIOD' },
            { title: 'รหัสผลิตภัณฑ์', field: 'PRODUCT_POLICY' },
            { title: 'ทุนประกันภัยใหญ่', field: 'PRODUCT_SUMINS' },
            { title: 'เบี้ยสุทธิ', field: 'NET_PREMIUM_AMT' },
            { title: 'ภาษี', field: 'VAT_AMT' },
            { title: 'อากร', field: 'STAMP_DUTY_AMT' },
            { title: 'เบี้ยรวม', field: 'TOTAL_PREMIUM' },
            { title: 'คำนำหน้าชื่อ', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วัน/เดือน/ปี เกิด', field: 'BIRTH_DATE' },
            { title: 'อายุ', field: 'VIB_AGE' },
            { title: 'สถานะภาพ', field: 'VIB_STATUS' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'โทรศัพท์บ้าน', field: 'VIB_HOME_PHONE' },
            { title: 'เบอร์ต่อโทรศัพท์บ้าน', field: 'VIB_HOME_PHONE_EXTEND' },
            { title: 'โทรศัพท์ที่ทำงาน', field: 'VIB_OFFICE_PHONE' },
            { title: 'เบอร์ต่อโทรศัพท์ที่ทำงาน', field: 'VIB_OFFICE_PHONE_EXTEND' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'Fax', field: 'VIB_FAX' },
            { title: 'เบอร์ต่อ Fax', field: 'VIB_FAX_EXTEND' },
            { title: 'E-mail Address', field: 'EMAIL_ADDRESS' },
            { title: 'คำนำหน้าชื่อผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },

        ]
        const vibData = covidAppList.filter(rowData => rowData.PLAN_ISSUER_CODE === "VIB");
        for (var i in vibData) {
            const newPhoneNum = "'" + vibData[i].PHONE_NUM
            const newBirthDate = `${vibData[i].BIRTH_DATE.split("/")[0]}/${vibData[i].BIRTH_DATE.split("/")[1]}/${Number(vibData[i].BIRTH_DATE.split("/")[2]) - 543}`
            const newCovStartDate = `${vibData[i].DATE_COV_START.split("/")[0]}/${vibData[i].DATE_COV_START.split("/")[1]}/${Number(vibData[i].DATE_COV_START.split("/")[2]) - 543}`
            const newCovEndDate = `${vibData[i].DATE_COV_END.split("/")[0]}/${vibData[i].DATE_COV_END.split("/")[1]}/${Number(vibData[i].DATE_COV_END.split("/")[2]) - 543}`
            vibData[i].VIB_NUM = Number(i) + 1;
            vibData[i].VIB_BROKER = "'00093";
            vibData[i].PHONE_NUM = newPhoneNum;
            vibData[i].DATE_COV_START = newCovStartDate;
            vibData[i].DATE_COV_END = newCovEndDate;
            vibData[i].BIRTH_DATE = newBirthDate;
        }
        const exportedData = vibData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
        new CsvBuilder('Application_form_vib_' + newCovDateStart + ".csv")
            .setColumns(columns.map(columnDef => columnDef.title))
            .addRows(exportedData)
            .exportFile();
    }

    const handleSmkCsv = (allColumns, allData) => {
        const columns = [
            { title: 'ทุนประกัน', field: 'SMK_SUMINS' },
            { title: 'เบี้ยรวม', field: 'SMK_TOTAL_PREM' },
            { title: 'รหัสคำนำหน้าชื่อผู้เอาประกัน', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วันเดือนปีเกิด (พ.ศ.)', field: 'BIRTH_DATE' },
            { title: 'วันเริ่มคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'เลขที่บัญชี', field: '' },
            { title: 'ส่วนลด', field: '' },
            { title: 'ส่วนลดอื่นๆ', field: '' },
            { title: 'แผนความคุ้มครอง', field: 'SMK_PLAN' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'ลำดับที่ ผู้รับประโยชน์', field: '' },
            { title: 'คำนำหน้าชื่อ ผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ที่อยู่ผู้รับประโยชน์ ', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'วันที่ชำระเงิน(mm/dd/yyyy)', field: '' },
            { title: 'ยอดเงินที่ชำระ(บาท)', field: 'PAYMENT_AMOUNT' },
            { title: 'รหัสตัวแทน', field: 'SMK_BROKER_CODE' },
            { title: 'อีเมล์', field: 'EMAIL_ADDRESS' },
            { title: 'เบอร์มือถือ ส่งกรมธรรม์', field: 'PHONE_NUM' },
            { title: 'เลขที่ใบสั่งซื้อ', field: '' },
            { title: 'วันที่รับแจ้ง', field: 'CURRENT_DATE' },
            { title: 'คำนำหน้าชื่อในใบกำกับภาษี', field: '' },
            { title: 'ชื่อในใบกำกับภาษี', field: '' },
            { title: 'ที่อยู่ในใบกำกับภาษี', field: '' },
            { title: 'แขวง/ตำบล', field: '' },
            { title: 'เขต/อำเภอ', field: '' },
            { title: 'จังหวัด', field: '' },
            { title: 'รหัสไปรษณีย์', field: '' },
            { title: 'ช่องทางการชำระเงิน', field: '' },
            { title: 'เลขประจำตัวผู้เสียภาษี ในใบกำกับภาษี', field: 'NATIONAL_ID' },
            { title: 'การรับกรมธรรม์', field: 'SMK_RECIEVED_POLICY' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
        ]
        const smkData = covidAppList.filter(rowData => rowData.PLAN_ISSUER_CODE === "SMK");
        for (var i in smkData) {
            const newPhoneNum = "'" + smkData[i].PHONE_NUM
            smkData[i].SMK_SUMINS = smkData[i].PLAN_ID === "SMK-450" ? 100000 : smkData[i].PLAN_ID === "SMK-250" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-300" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-500" ? 100000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-250" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-450" ? 100000 : 0;
            smkData[i].SMK_TOTAL_PREM = smkData[i].TOTAL_PREMIUM;
            smkData[i].SMK_PLAN = smkData[i].PLAN_ID === "SMK-450" ? "แผน 2 (สุดคุ้ม)" : smkData[i].PLAN_ID === "SMK-250" ? "แผน 1 (เกินคุ้ม)" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-300" ? "แผน 1 ประกัน Covid 3 in 1" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-500" ? "แผน 2 ประกัน Covid 3 in 1" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-250" ? "แผน 1 ประกัน Covid 3 in 1" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-450" ? "แผน 2 ประกัน Covid 3 in 1" : 0;
            smkData[i].PHONE_NUM = newPhoneNum;
            smkData[i].SMK_BROKER_CODE = 2192000;
            smkData[i].SMK_RECIEVED_POLICY = "C";
            smkData[i].GENDER = smkData[i].GENDER === "M" ? "ชาย" : smkData[i].GENDER === "F" ? "หญิง" : "0";
            smkData[i].ADDRESS_SUB_DISTRICT = smkData[i].ADDRESS_SUB_DISTRICT.replace("แขวง", "").replace("ตำบล", "");
            smkData[i].ADDRESS_DISTRICT = smkData[i].ADDRESS_DISTRICT.replace("เขต", "").replace("อำเภอ", "");
        }
        const exportedData = smkData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
        new CsvBuilder('Application_form_smk_' + newCovDateStart + ".csv")
            .setColumns(columns.map(columnDef => columnDef.title))
            .addRows(exportedData)
            .exportFile();
    }

    useEffect(() => {
        db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW").where("POLICY_NUM", "==", "").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                setIsLoading(false);
                setCovidAppList(covidApp.filter(singleapp => singleapp.APP_FORM_PAYMENT_STATUS !== "CANCEL"));
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
        setFormData({
            POLICY_NUM: "",
            POLICY_NUM_FILE_NAME: "",
            POLICY_NUM_FILE_URL: "",
            POLICY_NUM_FILE_STATUS: "unselect",
            DATE_COV_START: newCovDateStart,
            DATE_COV_END: newCovDateEnd
        });
    }, [refresh]);

    //Model Card Detail Set
    const [cardCovidAppDetailModal, setCardCovidAppDetailModal] = useState(false);
    const openCardCovidAppDetailModal = (appid) => {
        db.collection('covidApplication').where('APP_FORM_ID', '==', appid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setcovidAppSelectId(snapshot.docs[0].id)
                setcovidAppSelect(snapshot.docs[0].data())
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setCardCovidAppDetailModal(true);
    };

    const closeCardCovidAppDetailModal = () => {
        setCardCovidAppDetailModal(false);
        setcovidAppSelectId(null);
        setcovidAppSelect(null);
    };

    // console.log(covidAppList)

    const handleChange = event => {
        const { name, value } = event.target

        if (name === "DATE_COV_START") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    DATE_COV_START: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 543}`,
                    DATE_COV_END: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 544}`
                }
            });
        }

        if (name === "POLICY_NUM") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    POLICY_NUM: value.replace(/\s/g, ""),
                    POLICY_NUM_FILE_NAME: value.replace(/\s/g, "").replace(/\//g, "") + ".pdf"
                }
            });
        }
    }

    const handleValidSubmit = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            POLICY_NUM: formData.POLICY_NUM,
            POLICY_NUM_FILE_NAME: formData.POLICY_NUM_FILE_NAME,
            POLICY_NUM_FILE_URL: formData.POLICY_NUM_FILE_URL,
            DATE_COV_START: formData.DATE_COV_START,
            DATE_COV_END: formData.DATE_COV_END
        })
            .then(() => {
                const sendCovidApiRequest = async () => {
                    try {
                        const response = await axios.post(`${covidApi}/api/covid-application/create-new`,
                            JSON.stringify({
                                POLICY_NUMBER: formData.POLICY_NUM,
                                POLICY_NUMBER_URL: formData.POLICY_NUM_FILE_URL,
                                APPL_NUMBER: covidAppSelect.APP_FORM_ID,
                                PRENAME_DESC: covidAppSelect.PRE_NAME,
                                FIRST_NAME: covidAppSelect.FIRST_NAME,
                                LAST_NAME: covidAppSelect.LAST_NAME,
                                ID_CARD: covidAppSelect.NATIONAL_ID,
                                GENDER: covidAppSelect.GENDER,
                                BIRTH_DATE: covidAppSelect.BIRTH_DATE,
                                ADDRESS_DETAIL: covidAppSelect.ADDRESS_DETAIL,
                                PROVINCE_CODE: covidAppSelect.ADDRESS_PROVINCE_ID,
                                PROVINCE_NAME: covidAppSelect.ADDRESS_PROVINCE,
                                DISTRICT_CODE: covidAppSelect.ADDRESS_DISTRICT_ID,
                                DISTRICT_NAME: covidAppSelect.ADDRESS_DISTRICT,
                                SUBDISTRICT_NAME: covidAppSelect.ADDRESS_SUB_DISTRICT,
                                ZIP_CODE: covidAppSelect.ADDRESS_ZIP_CODE,
                                PHONE_NUMBER: covidAppSelect.PHONE_NUM,
                                EMAIL_ADDRESS: covidAppSelect.EMAIL_ADDRESS,
                                APPL_DATE: covidAppSelect.CURRENT_DATE,
                                DATE_COV_START: formData.DATE_COV_START,
                                DATE_COV_END: formData.DATE_COV_END,
                                BENEFIT_DETAIL: covidAppSelect.BENEFIT_PRE_NAME + covidAppSelect.BENEFIT_FIRST_NAME + covidAppSelect.BENEFIT_LAST_NAME + " " + covidAppSelect.BENEFIT_TYPE,
                                INS_COMPANY_CODE: covidAppSelect.PLAN_ISSUER_CODE,
                                AGENT_CODE: covidAppSelect.AGENT_CODE,
                                PLAN_ID: covidAppSelect.PLAN_ID,
                                PLAN_DESC: covidAppSelect.PLAN_DESC + covidAppSelect.PLAN_ISSUER,
                                REMARK: covidAppSelect.AGENT_REMARK,
                                NET_PREMIUM_AMT: covidAppSelect.NET_PREMIUM_AMT,
                                STAMP_DUTY_AMT: covidAppSelect.STAMP_DUTY_AMT,
                                VAT_AMT: covidAppSelect.VAT_AMT,
                                TOTAL_PREMIUM_AMT: covidAppSelect.TOTAL_PREMIUM
                            }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });

                        setIsLoading(false);
                        setCardCovidAppDetailModal(false);
                        setrefresh(!refresh)
                    } catch (err) {
                        setIsLoading(false);
                        setError('เกิดปัญหาในการส่ง API เข้าระบบ TVMS กรุณาลองใหม่อีกครั้ง เช่น เลขที่กรมธรรม์ซ้ำ');
                        setCardCovidAppDetailModal(false);
                    }
                }
                sendCovidApiRequest()
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            })
    }

    const fileSelectedHandler = (event) => {
        const { files } = event.target
        const uploadTask = storage.ref(`policyfile/${formData.POLICY_NUM_FILE_NAME}`).put(files[0]);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        POLICY_NUM_FILE_STATUS: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                // console.log(error.message);
            },
            () => {
                storage
                    .ref('policyfile')
                    .child(formData.POLICY_NUM_FILE_NAME)
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                POLICY_NUM_FILE_URL: url,
                                POLICY_NUM_FILE_STATUS: "uploaded"
                            }
                        });
                        // console.log(url);
                    });
            })
    }

    // Close and open collapse bar which will use in modal function such as cancel function
    const [allCol, setAllCol] = useState({
        col1: false,
        col2: false,
    });

    const t_col1 = () => {
        setAllCol({
            col1: !allCol.col1,
            col2: false,
        });
    }
    const t_col2 = () => {
        setAllCol({
            col2: !allCol.col2,
            col1: false,
        });
    }

    // Set initail Form Cancel Detail and it state
    const [formCancelData, setCancelData] = useState({
        cancelAuthorizationCode: "",
        remarkCancel: ""
    });

    // Set new Data Form Cancel Detail as it Change
    const handleCancelForm = event => {
        const { name, value } = event.target

        if (name === "cancelAuthorizationCode") {
            setCancelData(prevValue => {
                return {
                    ...prevValue,
                    cancelAuthorizationCode: value
                }
            });
        }

        if (name === "remarkCancel") {
            setCancelData(prevValue => {
                return {
                    ...prevValue,
                    remarkCancel: value
                }
            });
        }
    }


    // Set Form Cancel Detail then Save and Submit Data
    const handleCancelWithNoPolicySubmit = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            APP_FORM_PAYMENT_STATUS: "CANCEL",
            CANCEL_DATE: new Date(),
            CANCEL_REMARK: formCancelData.remarkCancel,
            CANCEL_AUTHORIZATION_CODE: formCancelData.cancelAuthorizationCode
        })
            .then(() => {
                db.collection('cancelCovidLog').add({
                    appFormId: covidAppSelect.APP_FORM_ID,
                    appId: covidAppSelectId,
                    cancelDate: new Date(),
                    cancelRemark: formCancelData.remarkCancel,
                    cancelAuthorizationCode: formCancelData.cancelAuthorizationCode,
                    cancelChannel: "CancelWithOutPolicyNum"
                })
                setIsLoading(false);
                setCancelData(prevValue => {
                    return {
                        ...prevValue,
                        cancelAuthorizationCode: "",
                        remarkCancel: ""
                    }
                });
                setCardCovidAppDetailModal(false);
                setrefresh(!refresh);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                setCardCovidAppDetailModal(false);
            })
    }

    return (
        <React.Fragment>
            <section className="bg-half-100 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <img src={tvinsurelogo} height="120" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="title-heading text-center">
                        <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                            ระบบบริหารงานประกันภัย
                        </h4>
                        <p className="text-center text-white h5 mt-1 mb-5">สำหรับการใช้งานของพนักงานหลังบ้านเท่านั้น</p>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
            <section style={{ margin: "-120px 0 0" }}>

                <Container>
                    <Row className="justify-content-center">
                        {covidAppSelect && !isLoading && <Modal isOpen={cardCovidAppDetailModal} >
                            <ModalHeader toggle={closeCardCovidAppDetailModal}>เลขที่การสมัคร {covidAppSelect.APP_FORM_ID}</ModalHeader>
                            <ModalBody className="pb-2">
                                <p># {covidAppSelect.CURRENT_DATE}</p>
                                <div className="row">
                                    <p className="col-12 text-center h5 font-weight-bold">รายละเอียดแผนประกัน</p>
                                    <p className="col-12 text-center font-weight-bold mb-3">{covidAppSelect.PLAN_ID}{covidAppSelect.APP_FORM_TYPE}</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        แผน:
                                </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_DESC}
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        บริษัทประกัน:
                                </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_ISSUER}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบี้ยสุทธิ:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.NET_PREMIUM_AMT} บาท
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        อากร:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.STAMP_DUTY_AMT} บาท
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        VAT:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.VAT_AMT} บาท
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ราคาเบี้ยประกันภัย(เบี้ยรวม):
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.TOTAL_PREMIUM} บาท
                                </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้เอาประกัน</p>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่คุ้มครอง (ตามใบคำขอ):
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        ชื่อผู้เอาประกัน:
                                </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.PRE_NAME} {covidAppSelect.FIRST_NAME} {covidAppSelect.LAST_NAME}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันเดือนปีเกิดผู้เอาประกัน:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BIRTH_DATE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เพศ:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GENDER === "M" ? "ชาย" : "หญิง"}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        บัตรประชาชน:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.NATIONAL_ID_ATTACH}>{covidAppSelect.NATIONAL_ID}</a>
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        ที่อยู่:
                                </div>
                                    <div className="col-9 text-right">
                                        {covidAppSelect.ADDRESS_DETAIL}
                                    </div>
                                    <div className="col-12 text-right mb-2">
                                        {covidAppSelect.ADDRESS_SUB_DISTRICT} - {covidAppSelect.ADDRESS_DISTRICT} - จังหวัด{covidAppSelect.ADDRESS_PROVINCE} - {covidAppSelect.ADDRESS_ZIP_CODE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ชื่อผู้รับประโยชน์:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BENEFIT_PRE_NAME} {covidAppSelect.BENEFIT_FIRST_NAME} {covidAppSelect.BENEFIT_LAST_NAME} ({covidAppSelect.BENEFIT_TYPE})
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบอร์โทรศัพท์:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.PHONE_NUM}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        อีเมล:
                                </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.EMAIL_ADDRESS}
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้ดูแล</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        ผู้ดูแล:
                                </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.AGENT_CODE} - {covidAppSelect.AGENT_NAME} {covidAppSelect.AGENT_REMARK}
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดการชำระเงิน</p>
                                    {covidAppSelect.PAYMENT_ATTACH && <div className="col-6 text-left font-weight-bold">
                                        ยอดชำระเงิน:
                                </div>}
                                    {covidAppSelect.PAYMENT_ATTACH && <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.PAYMENT_ATTACH}>{covidAppSelect.PAYMENT_AMOUNT} บาท</a>
                                    </div>}
                                    {covidAppSelect.PAYMENT_DATE && <div className="col-3 text-left font-weight-bold">
                                        ข้อมูลอื่น ๆ:
                                </div>}
                                    {covidAppSelect.PAYMENT_DATE && <div className="col-9 text-right mb-2">
                                        วันที่ชำระ {covidAppSelect.PAYMENT_DATE} - {covidAppSelect.PAYMENT_TIME}
                                    </div>}
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่คุ้มครอง (ตามหน้ากรมธรรม์):
                                </div>
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                    </div>}
                                    {!covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        {formData.DATE_COV_START} - {formData.DATE_COV_END}
                                    </div>}
                                    {covidAppSelect.POLICY_NUM && <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดกรมธรรม์</p>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-left font-weight-bold">
                                        เลขกรมธรรม์/เลขความคุ้มครอง:
                                </div>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.POLICY_NUM_FILE_URL}>{covidAppSelect.POLICY_NUM}</a>
                                    </div>}
                                    {!covidAppSelect.POLICY_NUM && <div className="col-12 text-right mb-2">
                                        <AvForm className="mr-3 mt-4" onValidSubmit={handleValidSubmit}>
                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>วันที่เริ่มต้นคุ้มครอง <span className="text-danger">*</span></Label>
                                                        <AvField
                                                            type="date"
                                                            name="DATE_COV_START"
                                                            errorMessage="กรุณากรอกวันที่เริ่มต้นคุ้มครอง"
                                                            validate={{ required: { value: true } }}
                                                            placeholder="วันที่เริ่มต้นคุ้มครอง"
                                                            onChange={handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>เลขกรมธรรม์ <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่เลขกรมธรรม์"
                                                            validate={{ required: { value: true } }} placeholder="ใส่เลขกรมธรรม์" name="POLICY_NUM" onChange={handleChange} value={formData.POLICY_NUM} />
                                                    </FormGroup>
                                                </Col>
                                                {formData.POLICY_NUM_FILE_NAME && formData.POLICY_NUM && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลือกไฟล์กรมธรรม์ <span className="text-danger">*</span></Label>
                                                        <AvField type="file" accept="application/pdf" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบไฟล์กรมธรรม์"
                                                            validate={{ required: { value: true } }} placeholder="ไฟล์บัตรประชาชน" name="POLICY_NUM_FILE" onChange={fileSelectedHandler} />
                                                        {formData.POLICY_NUM_FILE_STATUS === "uploading" && <Button color="danger" disabled={true} block>กำลังอัพโหลดไฟล์กรมธรรม์.....</Button>}
                                                        {formData.POLICY_NUM_FILE_STATUS === "uploaded" && <Button color="success" disabled={true} block>อัพโหลดไฟล์กรมธรรม์เสร็จสิ้น</Button>}
                                                    </FormGroup>
                                                </Col>}
                                                <Col lg={12} className="mb-0 auth-button">
                                                    <Button color="danger" block disabled={formData.POLICY_NUM_FILE_STATUS !== "uploaded"}>ส่งไฟล์กรมธรรม์ให้ลูกค้าและบันทึกลง TVMS</Button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>}
                                </div>

                                {!covidAppSelect.POLICY_NUM && <Card className="border-0 rounded mt-3 mb-2">
                                    <Link
                                        to="#"
                                        onClick={t_col1}
                                        className={
                                            allCol.col1
                                                ? "faq position-relative text-primary"
                                                : "faq position-relative text-dark"
                                        }
                                    >
                                        <CardHeader
                                            className="border-0 bg-light"
                                            id="headingOne"
                                        >
                                            <h6 className="title mb-0">
                                                ต้องการจะยกการสมัครประกันภัย?
                                <i
                                                    className={
                                                        allCol.col1
                                                            ? "mdi mdi-chevron-up float-right"
                                                            : "mdi mdi-chevron-down float-right"
                                                    }
                                                ></i>
                                            </h6>
                                        </CardHeader>
                                    </Link>
                                    <Collapse isOpen={allCol.col1}>
                                        <CardBody className="px-2 py-4">
                                            <p className="text-dark font-weight-bold">การยกเลิกการสมัครประกันภัยเป็นการเปลี่ยนข้อมูลที่ลูกค้าชำระเงินมาแล้วเป็นสถานะยกเลิกและไม่สามารถแนบไฟล์และใส่เลขกรมธรรม์ได้อีก กรุณาใช้วิจารณญาณและโปรดตรวจสอบให้ดีก่อนการยกเลิกการสมัครประกันภัย</p>
                                            <p className="text-dark"><small> 1. ตรวจสอบให้แน่ใจลูกค้ามีความประสงค์ที่จะยกเลิกการสมัครประกันภัย และผู้ที่ทำเรื่องยกเลิกต้องประสานงานกับฝ่ายงานการคืนเงินลูกค้าอย่างถูกต้อง และเก็บเอกสารหลักฐานที่เกี่ยวข้องให้ครบถ้วน เช่น หน้าบัตรประชาชนผู้แจ้งยกเลิกพร้อมลงชื่อแจ้งเหตุผล หน้าสมุดบัญชีเพื่อที่จะทำเงินคืน</small></p>
                                            <p className="text-dark"><small> 2. ใส่รายละเอียดกยกเลิกการสมัครทุกครั้ง ห้ามทิ้งไว้เป็นค่าว่างเพื่อที่จะให้คนอื่น ๆ กลับเข้ามาเช็ครายละเอียดได้</small></p>
                                            <p className="text-dark"><small> 3. ใส่รหัสผ่านการอนุมัติรายการให้ถูกต้อง หลังจากนั้นยกเลิกการสมัครประกันภัย ระบบจะทำเปลี่ยนสถานะการสมัครดังกล่าวเป็นยกเลิก</small></p>
                                            <AvForm className="mr-3 mt-2" onValidSubmit={
                                                (event) => {
                                                    if (
                                                        formCancelData.cancelAuthorizationCode === "SUKRITB"
                                                        || formCancelData.cancelAuthorizationCode === "PENNAPA"
                                                        || formCancelData.cancelAuthorizationCode === "JIRAPORN"
                                                        || formCancelData.cancelAuthorizationCode === "PHIKUL"
                                                    ) {
                                                        const check = window.confirm("ยืนยันการยกเลิกเลขการสมัครประกันภัย")
                                                        if (check === true) {
                                                            handleCancelWithNoPolicySubmit(event)
                                                        } else {
                                                            return false
                                                        }
                                                    } else {
                                                        alert("ไม่อนุมัติการยกเลิการสมัครประกันภัยกรุณาติดต่อผู้ดูแลระบบ")
                                                    }
                                                }
                                            }>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>หมายเหตุการยกเลิกการสมัครประกันภัย <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่หมายเหตุการยกเลิก"
                                                            validate={{ required: { value: true } }} placeholder="ใส่หมายเหตุการยกเลิก" name="remarkCancel" onChange={handleCancelForm} value={formCancelData.remarkCancel} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>รหัสการอนุมัติการยกเลิกการสมัครประกันภัย <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่รหัสการอนุมัติการยกเลิกการสมัครประกันภัย"
                                                            validate={{ required: { value: true } }} placeholder="ใส่รหัสการอนุมัติการยกเลิกการสมัครประกันภัย" name="cancelAuthorizationCode" onChange={handleCancelForm} value={formCancelData.cancelAuthorizationCode} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12} className="mb-4 auth-button">
                                                    <Button color="danger" block >ยกเลิกการสมัครประกันภัย</Button>
                                                </Col>
                                            </AvForm>
                                        </CardBody>
                                    </Collapse>
                                </Card>}

                            </ModalBody>
                            <ModalFooter className="mx-auto">
                                <Button color="danger" outline onClick={closeCardCovidAppDetailModal}>ย้อนกลับ</Button>
                            </ModalFooter>
                        </Modal>}
                        <Col lg={12} md={10} sm={8}>

                            <Menubar page="list-policy-new" />
                            {isLoading && <div className="row">
                                <div className="col-xl-12 text-center mt-5">
                                    <div style={{ maxWidth: '100%' }}>
                                        <p className="text-dark font-weight-bold">กำลังโหลดข้อมูล...</p>
                                    </div>
                                </div>
                            </div>}
                            {error && <div className="row">
                                <div className="col-xl-12 text-center mt-5">
                                    <div style={{ maxWidth: '100%' }}>
                                        <p className="text-dark font-weight-bold">{error}</p>
                                    </div>
                                </div>
                            </div>}
                            <MaterialTable
                                icons={tableIcons}
                                columns={[
                                    { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
                                    { title: 'เลขที่การสมัคร', field: 'APP_FORM_ID' },
                                    { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
                                    { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
                                    { title: 'วันที่เริ่มความคุ้มครอง', field: 'DATE_COV_START' },
                                    { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
                                    { title: 'บริษัทประกัน', field: 'PLAN_ISSUER_CODE', defaultGroupOrder: 0 },
                                    { title: 'เบี้ยประกันภัย', field: 'TOTAL_PREMIUM' },
                                    { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
                                    { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
                                ]}
                                data={covidAppList}
                                title={`รายการสมัครใหม่ที่ยังไม่ได้กรมธรรม์ ${covidAppList.length} รายการ`}
                                actions={[
                                    {
                                        icon: () => <ChevronRightIcon />,
                                        tooltip: 'รายละเอียด',
                                        onClick: (event, rowData) => {
                                            openCardCovidAppDetailModal(rowData.APP_FORM_ID);
                                        }
                                    }
                                ]}

                                components={{
                                    Toolbar: props => (
                                        <Container>
                                            <Row>
                                                <Col lg={12}    >
                                                    <MTableToolbar {...props} />
                                                    <Row>
                                                        <Col md={9}>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleVibCsv}><img src={vibinsurancelogo} alt="VIB" height="30" /> ดาวโหลดงานใหม่แจ้งวิริยะ</div>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleSmkCsv}><img src={smkinsurancelogo} alt="SMK" height="30" /> ดาวโหลดงานใหม่แจ้งสินมั่นคง</div>

                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="btn btn-pills btn-primary mb-3" style={{ float: 'right' }} onClick={() => setrefresh(!refresh)}> ดึงข้อมูลใหม่ </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    ),
                                }}


                                options={{
                                    tableLayout: "auto",
                                    exportButton: true,
                                    grouping: true,
                                    headerStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    searchFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    toolbarFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    paging: true,
                                    pageSize: 20,       // make initial page size
                                    emptyRowsWhenPaging: false,   //to make page size not fix (dynamic) in case of less data rows
                                    pageSizeOptions: [5, 10, 20, 50, 100, 200, 1000],    // rows selection options
                                }}

                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} จาก {count}',
                                        labelRowsSelect: 'รายการ'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} รายการที่เลือก',
                                        searchPlaceholder: 'ค้นหา'
                                    },
                                    header: {
                                        actions: ''
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'ไม่มีรายการ',
                                        filterRow: {
                                            filterTooltip: 'ตัวกรอง'
                                        }
                                    },
                                    grouping: {
                                        placeholder: 'ลากหัวข้อมาที่นี่เพื่อแบ่งกลุ่ม',
                                        groupedBy: 'แบ่งกลุ่มโดย: '
                                    }
                                }}

                            />
                        </Col>
                        <p className="text-center my-4 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default ListPolicyNew;
import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

import BackgroundSlider from "react-background-slider";

import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { premiumSabuyPackage } from '../../shared/masters/refData'

//Import Icons
import FeatherIcon from 'feather-icons-react';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import stysrminsurancelogo from '../../shared/images/tokyo-marine-logo.png';


import './Product.css';

import tvinsurebg from '../../shared/images/bg-services.jpg';
import tvinsureSmeSabaiPackage from '../../shared/images/tvinsure-sme-sabai-package-4.png';
import tvinsureAssetInsOne from '../../shared/images/TVinsure-Benefit-of-Insurance-new.png';
import tvinsureAssetInsTwo from '../../shared/images/TVinsure-Benefit-of-Insurance-home.png';
import tvinsureAssetInsThree from '../../shared/images/TVinsure-Benefit-of-Insurance-consult.png';



const AssetQuoteSmeSabaiDetail = () => {

    const items = [tvinsureAssetInsOne, tvinsureAssetInsTwo, tvinsureAssetInsThree]

    const industryCode = useParams().industrycode;
    const buildingSumins = useParams().suminsdetail.split("-")[0].replace("BUILDING", "");
    const equipSumins = useParams().suminsdetail.split("-")[1].replace("EQUIP", "");
    const furSumins = useParams().suminsdetail.split("-")[2].replace("FUR", "");
    const stockSumins = useParams().suminsdetail.split("-")[3].replace("STOCK", "");
    const otherSumins = useParams().suminsdetail.split("-")[4].replace("OTHER", "");
    const totalSumins = useParams().suminsdetail.split("-")[5].replace("TOTAL", "");

    const [isLoading, setIsLoading] = useState(false);
    const [packageSelect, setPackageSelect] = useState();

    // Get filter Pacakge
    useEffect(() => {
        window.scrollTo(0, 0);
        const filteredPackage = premiumSabuyPackage.filter(p => Math.ceil(Number(totalSumins)) >= Number(p.TOTAL_SUMINS_MIN) && Math.ceil(Number(totalSumins)) <= Number(p.TOTAL_SUMINS_MAX) && p.INDUSTRY_CODE === industryCode)
        function getPackageRearrange() {
            const output = [];
            for (let i = 0; i < filteredPackage.length; i++)
                output.push({
                    id: i,
                    image: tvinsureSmeSabaiPackage,
                    title: "SME Sabai",
                    tag: "สำหรับผู้ประกอบการ",
                    rating: 4,
                    issuerLogo: stysrminsurancelogo,
                    issuerName: "คุ้มภัยโตเกียวมารีน",
                    planName: "SME Sabai จากคุ้มภัยโตเกียวมารีน",
                    planDesc: "ประกันภัยธุรกิจและโรงงานขนาดย่อม",
                    planIndustryType: filteredPackage[i].INDUSTRY_DESC,
                    planTotalSuminsMin: filteredPackage[i].TOTAL_SUMINS_MIN,
                    planTotalSuminsMax: filteredPackage[i].TOTAL_SUMINS_MAX,
                    planNetPrem: filteredPackage[i].PLAN_NET_PREMIUM_AMT,
                    planStampDuty: filteredPackage[i].PLAN_STAMP_DUTY_AMT,
                    planVat: filteredPackage[i].PLAN_VAT_AMT,
                    planTotalPrem: filteredPackage[i].PLAN_TOTAL_PREMIUM,
                    mainCoverageDetail: [
                        {
                            id: 1,
                            title: "ไฟไหม้ ฟ้าผ่า ภัยระเบิด ภัยยวดยานพาหนะ ภัยจากอากาศยาน ภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม) ภัยจากควัน ภัยไฟป่า",
                            subTitle: "",
                            value: Intl.NumberFormat().format(totalSumins) + " บาท",
                            desc: "ความสูญเสีย หรือความเสียหายต่อทรัพย์สินที่เอาประกันภัยอันมีสาเหตุโดยตรงมาจากไฟไหม้ ฟ้าผ่า ภัยระเบิด ภัยยวดยานพาหนะ ภัยจากอากาศยาน ภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม) ภัยจากควัน ภัยไฟป่า และภัยจากอุบัติเหตุอื่นๆ ซึ่งเกิดจากสาเหตุปัจจัยภายนอกที่ไม่ได้ระบุไว้ในข้อยกเว้นของกรมธรรม์ การประกันภัยความเสี่ยงภัยทรัพย์สิน (พร้อมสรรพธุรกิจอุตสาหกรรม) ตามที่ระบุวงเงินคุ้มครองตามจำนวนเงินเอาประกันภัย (วงเงินคุ้มครองเริ่มต้น 500,000 บาท สูงสุด 50,000,000 บาท)"
                        },
                        {
                            id: 2,
                            title: "ภัยจากน้ำท่วม วงเงินคุ้มครองไม่เกิน ",
                            subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                            value: Intl.NumberFormat().format(10000) + " บาท",
                            desc: ""
                        },
                        {
                            id: 3,
                            title: "ภัยลมพายุ วงเงินคุ้มครองไม่เกิน",
                            subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                            value: Intl.NumberFormat().format(totalSumins * 0.5) + " บาท",
                            desc: ""
                        },
                        {
                            id: 4,
                            title: "ภัยแผ่นดินไหว หรือภูเขาไฟระเบิด หรือคลื่นใต้น้ำ หรือสึนามิ วงเงินคุ้มครองไม่เกิน",
                            subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                            value: Intl.NumberFormat().format(totalSumins * 0.5) + " บาท",
                            desc: ""
                        },
                        {
                            id: 5,
                            title: "ภัยจากลูกเห็บ วงเงินคุ้มครองไม่เกิน",
                            subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                            value: Intl.NumberFormat().format(totalSumins * 0.5) + " บาท",
                            desc: ""
                        }
                    ],
                    additionalCoverageDetail: [{
                        id: 1,
                        title: "ประกันภัยโจรกรรม (Burglary Insurance)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(totalSumins) + " บาท",
                        desc: "ประกันภัยโจรกรรม (Burglary Insurance) - ความสูญเสียหรือเสียหายต่อทรัพย์สินที่เอาประกันภัยที่เก็บอยู่ภายในอาคาร สิ่งปลูกสร้าง รวมถึงค่าซ่อมแซมตัวอาคารที่เสียหาย จากการลักทรัพย์ที่ปรากฎร่องรอยการงัดแงะ (ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)"
                    },
                    {
                        id: 2,
                        title: "ประกันภัยโจรกรรม (จร.2) (Burglary Insurance) (BG.2)",
                        subTitle: "(แล้วแต่ว่าจำนวนใดจะน้อยกว่า - ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: "30% ของจำนวนเงินเอาประกันภัย สูงสุดไม่เกิน 1,500,000",
                        desc: "ประกันภัยโจรกรรม (จร.2) (Burglary Insurance) (BG.2) - ความสูญเสียหรือเสียหายต่อทรัพย์สินที่เอาประกันภัยที่เก็บอยู่ภายในอาคาร สิ่งปลูกสร้าง รวมถึงค่าซ่อมแซมตัวอาคารที่เสียหายจากการ ชิงทรัพย์ ปล้นทรัพย์ (แล้วแต่ว่าจำนวนใดจะน้อยกว่าต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)"
                    },
                    {
                        id: 3,
                        title: "ภัยต่อเครื่องอุปกรณ์ไฟฟ้า (Electrical Installation Clause)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(200000) + " บาท",
                        desc: "ภัยต่อเครื่องอุปกรณ์ไฟฟ้า (Electrical Installation Clause) อันเกิดจากการเดินเครื่องเดินกำลัง การเดินลัดวงจรของไฟฟ้า การเผาไหม้ของสายไฟฟ้าในตัวเอง การรั่วของกระแสไฟฟ้าหรือการเดินลัดวงจรของไฟฟ้าเนื่องจากสาเหตุใดๆ ก็ตาม (ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)"
                    },
                    {
                        id: 4.1,
                        title: "การประกันภัยเครื่องจักร (Machinery Insurance) - คุ้มครองเครื่องจักรที่ตั้งอยู่ในสถานที่เอาประกันภัย",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(100000) + " บาท",
                        desc: "คุ้มครองเครื่องจักรที่ตั้งอยู่ในสถานที่เอาประกันภัย จากความเสียหายจากระบบกลไกขัดข้องอันเกิดขึ้นโดยฉับพลัน และไม่อาจคาดการณ์ล่วงหน้าได้"
                    },
                    {
                        id: 4.2,
                        title: "การประกันภัยเครื่องจักร (Machinery Insurance) -  คุ้มครองความเสียหายสำหรับสต็อก",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(100000) + " บาท",
                        desc: "คุ้มครองความเสียหายสำหรับสต็อกที่เก็บไว้ในห้องเย็นหรือตู้แช่แข็งที่คุ้มครองตามเงื่อนไขข้อที่ 4.1"
                    },
                    {
                        id: 5,
                        title: "การประกันภัยสำหรับกระจก (Plate Glass Insurance)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(500000) + " บาท",
                        desc: " คุ้มครองความเสียหายต่อกระจกที่ใช้เป็นส่วนประกอบของอาคารอันเกิดจากอุบัติเหตุใดๆ จนทำให้แตก หรือเสียหายจนใช้การไม่ได้"
                    },
                    {
                        id: 6,
                        title: "การประกันภัยเงิน (ปง.2) Money Insurance (M.2)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(300000) + " บาท",
                        desc: "การประกันภัยเงิน (ปง.2) Money Insurance (M.2) - คุ้มครองการสูญเสียของเงินในสถานประกอบการในเวลาทำการ และสูญเสียเงินในตู้เซฟ/ห้องนิรภัยนอกเวลาทำการ อันเกิดจากการชิงทรัพย์ ปล้นทรัพย์ และที่เกิดจากเหตุการณ์ที่ไม่ได้ระบุในข้อยกเว้น*"
                    },
                    {
                        id: 7,
                        title: "การประกันภัยเครื่องอุปกรณ์คอมพิวเตอร์และอิเล็คทรอนิกส์(Computer Insurance)",
                        value: Intl.NumberFormat().format(50000) + " บาท",
                        desc: "การประกันภัยเครื่องอุปกรณ์คอมพิวเตอร์และอิเล็คทรอนิกส์(Computer Insurance) ยกเว้นโทรศัพท์มือถือ คุ้มครองความเสียหายที่เกิดจากไฟฟ้าลัดวงจร ลักทรัพย์ ในสถานที่เอาประกันภัย"
                    },
                    {
                        id: 8,
                        title: "การประกันภัยป้ายโฆษณาและป้ายนีออน (Advertising and Neon Sign Insurance)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(100000) + " บาท",
                        desc: "การประกันภัยป้ายโฆษณาและป้ายนีออน (Advertising and Neon Sign Insurance) ที่ยึดติดถาวร คุ้มครองการแตกหักหรือเสียหายจากอุบัติเหตุซึ่งเกิดจากเหตุภายนอก อัคคีภัย ฟ้าฝ่าระเบิด และลักทรัพย์ และรวมถึงความรับผิดตามกฏหมายต่อบุคคลภายนอกตามเงื่อนไขของประกันภัยป้ายโฆษณา"
                    },
                    {
                        id: 9,
                        title: "การประกันภัยเงินทดแทนสูญเสียรายได้ (Loss of Income)",
                        subTitle: "(แล้วแต่ว่าจำนวนใดจะน้อยกว่าต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: "10% ของค่าสินไหมทดแทน สูงสุดไม่เกิน 100,000.00",
                        desc: "การประกันภัยเงินทดแทนสูญเสียรายได้ (Loss of Income) คุ้มครองเงินทดแทนการสูญเสียรายได้ ในกรณีทรัพย์สินที่เอา ประกันภัยได้รับความสูญเสียหรือเสียหายจากภัยที่ได้รับความคุ้มครองหลัก (แล้วแต่ว่าจำนวนใดจะน้อยกว่าต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)"
                    },
                    {
                        id: 10,
                        title: "การประกันภัยความรับผิดต่อบุคคลภายนอก (Public Liability Insurance)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: "สูงสุด 1,000,000 แต่ไม่เกินจำนวนเงินเอาประกันภัยในความคุ้มครองหลักข้อ 1",
                        desc: "คุ้มครองการเสียชีวิต ความบาดเจ็บต่อร่างกายหรือความเจ็บป่วยของบุคคลภายนอก รวมทั้งความเสียหายต่อทรัพย์สินของบุคคลดังกล่าวซึ่งเกิดขึ้นภายในอาคารหรือเกิดจากการใช้อาคารนั้น"
                    },
                    {
                        id: 11,
                        title: "การประกันภัยอุบัติเหตุส่วนบุคคล (อบ.1) (Personal Accident Insurance) สูงสุด 3 คน",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(100000) + " บาท",
                        desc: `สำหรับผู้เอาประกันภัย หรือลูกจ้างสูงสุด จำนวน 3 คน (ต้องระบุชื่อก่อนทำประกันภัย) คุ้มครองการเสียชีวิตและทุพพลภาพถาวรสิ้นเชิงเท่านั้นของผู้เอาประกันภัยหรือลูกจ้างจากอุบัติเหตุ (อายุตั้งแต่ 15 ปี - 70 ปี) ภายในอาณาเขตประเทศไทย (3 คนรวม ${Intl.NumberFormat().format(100000)} บาท)`
                    },
                    {
                        id: 12,
                        title: "การประกันภัยความรับผิดของนายจ้าง (Employer's Liability Insurance)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(1500000) + " บาท",
                        desc: ""
                    },
                    {
                        id: 13,
                        title: "คุ้มครองค่าใช้จ่ายเบื้องต้นในการติดตามความสูญเสียหรือ เสียหายจากการรั่วไหลของข้อมูล (Initial cost for Data leakage)",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(5000) + " บาท",
                        desc: "คุ้มครองค่าใช้จ่ายเบื้องต้นในการติดตามความสูญเสียหรือ เสียหายจากการรั่วไหลของข้อมูล (Initial cost for Data leakage) จากการถูกโจรกรรมผ่านการติดต่อสื่อสารด้วยระบบเครือข่ายอินเตอร์เน็ต"
                    },
                    {
                        id: 14,
                        title: "คุ้มครองการให้บริการทำความสะอาดสำหรับฆ่าเชื้อโรคโควิด-19 ในกรณีผู้เอาประกันภัยหรือลูกจ้าง มีผลตรวจพบติดเชื้อไวรัสโควิด-19",
                        subTitle: "(ต่อเหตุการณ์แต่ละครั้ง และตลอดระยะเวลาเอาประกันภัย)",
                        value: Intl.NumberFormat().format(50000) + " บาท",
                        desc: ""
                    }
                    ]
                });

            return output;
        }
        setPackageSelect(getPackageRearrange()[0])
    }, []);



    return (
        <React.Fragment>

            <div className="wrapper">

                <MetaTags>
                    <title>เช็คราคาประกันภัยสำหรับธุรกิจของคุณ </title>
                    <meta name="description" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ" />
                    <meta property="og:description" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureAssetInsOne} />
                </MetaTags>
                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section className="bg-half-90 d-table w-100" >
                    <BackgroundSlider
                        images={items}
                        duration={5}
                        transition={3}
                    />
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="text-center mb-5">
                                    <a href="https://tvinsure.com" className="logo h5">
                                        <img src={tvinsurelogo} height="120" alt="" />
                                    </a>
                                </div>
                                <div className="title-heading text-center">
                                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                        เช็คราคาประกันภัยสำหรับธุรกิจของคุณ
                                </h4>
                                    <p className="text-center text-white h5 mt-1 mb-5">ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section className="section bg-white">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} md={10} sm={8}>
                                <Card style={{ margin: "-180px 0 0" }} className="border-0 rounded shadow-lg">
                                    <CardBody>
                                        <Row>
                                            <Col lg={12} md={12}>
                                                <div className="firm-logo text-center pt-5">
                                                    <img
                                                        className="text-center"
                                                        height={60}
                                                        src={packageSelect && packageSelect.issuerLogo}
                                                        alt={packageSelect && packageSelect.issuerName}
                                                    />
                                                </div>
                                                <h4 className="text-center pt-4"> {packageSelect && packageSelect.planName} </h4>
                                                <p className="text-center pb-5"> {packageSelect && packageSelect.planDesc} </p>
                                            </Col>
                                            <Col lg={9} md={12}>
                                                <p className="text-left px-3">สิ่งปลูกสร้าง (ไม่รวมฐานราก) รวมส่วนปรับปรุง ตกแต่ง ต่อเติม ส่วนควบต่างๆ และระบบสาธารณูปโภค</p>
                                            </Col>
                                            <Col lg={3} md={12}>
                                                <p className="text-md-right px-3 font-weight-bold"> {buildingSumins == 0 ? "ไม่คุ้มครอง" : Intl.NumberFormat().format(buildingSumins) + " บาท"}</p>
                                            </Col>
                                            <Col lg={9} md={12}>
                                                <p className="text-left px-3">เครื่องจักร และอุปกรณ์ส่วนควบต่างๆ เครื่องมือเครื่องใช้</p>
                                            </Col>
                                            <Col lg={3} md={12}>
                                                <p className="text-md-right px-3 font-weight-bold"> {equipSumins == 0 ? "ไม่คุ้มครอง" : Intl.NumberFormat().format(equipSumins) + " บาท"}</p>
                                            </Col>
                                            <Col lg={9} md={12}>
                                                <p className="text-left  px-3">เฟอร์นิเจอร์ เครื่องตกแต่ง ติดตั้งตรึงตรา เครื่องใช้สำนักงาน คอมพิวเตอร์ และอุปกรณ์ส่วนควบ (เฉพาะฮาร์ดแวร์) ระบบเครื่องปรับอากาศ ระบบโทรศัพท์</p>
                                            </Col>
                                            <Col lg={3} md={12}>
                                                <p className="text-md-right px-3 font-weight-bold">{furSumins == 0 ? "ไม่คุ้มครอง" : Intl.NumberFormat().format(furSumins) + " บาท"}</p>
                                            </Col>
                                            <Col lg={9} md={12}>
                                                <p className="text-left  px-3">สต๊อกทุกชนิด สต๊อกวัตถุดิบ สินค้าสำเร็จรูป สินค้ากึ่งสำเร็จรูป สินค้าระหว่างผลิต หีบห่อ บรรจุภัณฑ์ สต๊อกสินค้าระหว่างรอการขนส่ง</p>
                                            </Col>
                                            <Col lg={3} md={12}>
                                                <p className="text-md-right px-3 font-weight-bold"> {stockSumins == 0 ? "ไม่คุ้มครอง" : Intl.NumberFormat().format(stockSumins) + " บาท"}</p>
                                            </Col>
                                            <Col lg={9} md={12}>
                                                <p className="text-left  px-3"> อื่นๆ</p>
                                            </Col>
                                            <Col lg={3} md={12}>
                                                <p className="text-md-right px-3 font-weight-bold"> {otherSumins == 0 ? "ไม่คุ้มครอง" : Intl.NumberFormat().format(otherSumins) + " บาท"}</p>
                                            </Col>
                                            <Col lg={6} md={12}>
                                                <p className="text-left py-2 px-3"> ทุนประกันภัยรวมทั้งหมดของธุรกิจคุณมีมูลค่าเท่ากับ</p>
                                            </Col>
                                            <Col lg={6} md={12}>
                                                <p className="text-md-right py-2 px-3 font-weight-bold"> {Intl.NumberFormat().format(totalSumins)} บาท</p>
                                            </Col>
                                            <Col lg={6} md={12}>
                                                <p className="text-left py-2 px-3"> เบี้ยประกันภัย (เบี้ยรวม)</p>
                                            </Col>
                                            <Col lg={6} md={12}>
                                                <p className="text-md-right py-2 px-3 font-weight-bold"> {packageSelect && Intl.NumberFormat().format(packageSelect.planTotalPrem)} บาท</p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>


                        <Col lg={12} className="mb-4 pb-2 mt-5">
                            <div className="rounded shadow pb-4">
                                <div className="d-flex justify-content-between p-4 bg-light">
                                    <h6 className="mb-0 text-left">ความคุ้มครองและผลประโยชน์</h6>
                                    <h6 className="mb-0 text-right">วงเงินความคุ้มครอง</h6>
                                </div>

                                <h4 className="text-center my-4">ความคุ้มครองหลัก</h4>

                                {packageSelect && packageSelect.mainCoverageDetail.map((detail, key) => (
                                    <div className="px-4" key={detail.id}>
                                        <Row className="d-flex justify-content-between">

                                            <Col lg={9} md={6} xs={12}>
                                                <div className="media align-items-center">
                                                    <div className="commentor-detail pt-3">
                                                        <h6 className="mb-0">{detail.id}) {detail.title}</h6>
                                                        <small className="text-muted">{detail.subTitle}</small>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12}>
                                                <h6 className="text-dark h6 text-md-right pt-3 mb-1">
                                                    {detail.value}
                                                </h6>
                                            </Col>
                                        </Row>
                                        {detail.desc && <div className="mt-1 mb-3">
                                            <p className="text-muted mb-1">
                                                {detail.desc}
                                            </p>
                                        </div>}
                                    </div>
                                ))}

                                {packageSelect && packageSelect.additionalCoverageDetail.length > 0 && <h4 className="text-center my-4">ความคุ้มครองเพิ่มเติม</h4>}

                                {packageSelect && packageSelect.additionalCoverageDetail.map((detail, key) => (
                                    <div className="px-4" key={detail.id}>
                                        <Row className="d-flex justify-content-between">

                                            <Col lg={9} md={6} xs={12}>
                                                <div className="media align-items-center">
                                                    <div className="commentor-detail pt-3">
                                                        <h6 className="mb-0">{detail.id}) {detail.title}</h6>
                                                        <small className="text-muted">{detail.subTitle}</small>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12} className="mb-4">
                                                <h6 className="text-dark h6 text-md-right pt-3 mb-1">
                                                    {detail.value}
                                                </h6>
                                            </Col>
                                        </Row>
                                        {detail.desc && <div className="mt-1 mb-3">
                                            <p className="text-muted mb-0">
                                                {detail.desc}
                                            </p>
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <p className="text-left text-muted my-6 px-5">* ข้อมูลข้างต้นเป็นเพียงการนำเสนอความคุ้มครองเบื้องต้นจากแผนประกันภัย โปรดศึกษารายละเอียด เงื่อนไข ข้อยกเว้นกรมธรรม์ และข้อยกเว้นอื่น ๆ ในแต่ละความคุ้มครองจากกรมธรรม์</p>
                        <div className="text-center px-5 my-5">
                            <Link to={`/asset-quote-all/sme-sabai-package/${industryCode}/BUILDING${buildingSumins}-EQUIP${equipSumins}-FUR${furSumins}-STOCK${stockSumins}-OTHER${otherSumins}-TOTAL${totalSumins}/quotation`}>
                                <Button color="danger" block>จัดทำใบเสนอราคา</Button>
                            </Link>
                        </div>
                        <Row>
                            <Col lg={12}>
                                <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                            </Col>
                            <Col lg={12}>
                                <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


export default AssetQuoteSmeSabaiDetail;
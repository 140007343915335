
// PDF Related Modules
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'
import download from '../../shared/context/download'

// get FILES URL routes
import { FILES_URL } from '../../shared/masters/url'

async function GetQuotationSmeSabai(data) {
    // Fetch an existing PDF document
    const url = `${FILES_URL}/pdf-template/sabai-package-quotaion`
    const existingPdfBytes = await fetch(url,).then(res => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Embed the Helvetica font but brawalia and angsana new is choose as custom fonts
    const browaliaUrlFont = `${FILES_URL}/pdf-fonts/browalia-new-two`
    const browaliaFontBytes = await fetch(browaliaUrlFont).then((res) => res.arrayBuffer());

    const angsanaUrlFont = `${FILES_URL}/pdf-fonts/angsana-new`
    const angsanaFontBytes = await fetch(angsanaUrlFont).then((res) => res.arrayBuffer());

    pdfDoc.registerFontkit(fontkit);
    const browaliaFont = await pdfDoc.embedFont(browaliaFontBytes);
    const angsananewFont = await pdfDoc.embedFont(angsanaFontBytes);

    const textSize = 8
    const textHeight = browaliaFont.heightAtSize(textSize)

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get PNG by use the browser make a fetch() call and use res.arrayBuffer()
    const urlCcheckMark = `${FILES_URL}/pdf-materials/check-mark`
    const checkMarkpngImageBytes = await fetch(urlCcheckMark).then((res) => res.arrayBuffer());

    const checkMarkpngImage = await pdfDoc.embedPng(checkMarkpngImageBytes)

    // Resize the PNG
    const checkMarkpngDims = checkMarkpngImage.scale(0.03)

    // Get the All page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const secondPage = pages[1]
    const thirdPage = pages[2]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()

    // Draw a string of text diagonally across the page

    // ------------------------------------------------- Page 1 ------------------------------------------------
    firstPage.drawText(`# ${data.appid}-${data.currentDate} - TVinsure.com | ทวีทรัพย์ โบรคเกอร์`, {
        x: 370,
        y: 30,
        size: 9,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    if (data.prename && data.fname && data.lname) {
        firstPage.drawText(`${data.prename} ${data.fname} ${data.lname}`, {
            x: width - 449,
            y: 679,
            size: textSize,
            font: browaliaFont,
            color: rgb(0, 0, 0),
        })
    }

    if (data.address && data.districtSub && data.district && data.province && data.zipcode) {
        firstPage.drawText(`${data.address} ${data.districtSub} ${data.district} จังหวัด${data.province} ${data.zipcode}`, {
            x: width - 449,
            y: 662,
            size: textSize,
            font: browaliaFont,
            color: rgb(0, 0, 0),
        })
    }

    if (data.assetAddress) {
        firstPage.drawText(`${data.assetAddress}`, {
            x: width - 449,
            y: 645,
            size: textSize,
            font: browaliaFont,
            color: rgb(0, 0, 0),
        })
    }

    if (data.assetUse) {
        firstPage.drawText(`${data.assetUse}`, {
            x: width - 449,
            y: 628,
            size: textSize,
            font: browaliaFont,
            color: rgb(0, 0, 0),
        })
    }

    firstPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.buildingSumins)}`, {
        x: width - 192 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.buildingSumins)}`, textSize),
        y: 580,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    const equipSumins = Number(data.equipSumins) === 0 ? "ไม่คุ้มครอง" : `${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.equipSumins)}`

    firstPage.drawText(equipSumins, {
        x: width - 192 - browaliaFont.widthOfTextAtSize(equipSumins, textSize),
        y: 558,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    const furSumins = Number(data.furSumins) === 0 ? "ไม่คุ้มครอง" : `${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.furSumins)}`

    firstPage.drawText(furSumins, {
        x: width - 192 - browaliaFont.widthOfTextAtSize(furSumins, textSize),
        y: 547,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    const stockSumins = Number(data.stockSumins) === 0 ? "ไม่คุ้มครอง" : `${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.stockSumins)}`

    firstPage.drawText(stockSumins, {
        x: width - 192 - browaliaFont.widthOfTextAtSize(stockSumins, textSize),
        y: 514,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    const otherSumins = Number(data.otherSumins) === 0 ? "ไม่คุ้มครอง" : `${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.otherSumins)}`

    firstPage.drawText(otherSumins, {
        x: width - 192 - browaliaFont.widthOfTextAtSize(otherSumins, textSize),
        y: 482,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.totalSumins)}`, {
        x: width - 192 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.totalSumins)}`, textSize),
        y: 471,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins) * 0.5)}`, {
        x: width - 202 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins) * 0.5)}`, textSize),
        y: 395,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins) * 0.5)}`, {
        x: width - 202 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins) * 0.5)}`, textSize),
        y: 384,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins) * 0.5)}`, {
        x: width - 202 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins) * 0.5)}`, textSize),
        y: 370,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins))}`, {
        x: width - 202 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.totalSumins))}`, textSize),
        y: 348,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    // ------------------------------------------------- Page 2 ------------------------------------------------
    secondPage.drawText(`# ${data.appid}-${data.currentDate} - TVinsure.com | ทวีทรัพย์ โบรคเกอร์`, {
        x: 370,
        y: 30,
        size: 9,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    // ------------------------------------------------- Page 3 ------------------------------------------------
    thirdPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planNetPrem)}`, {
        x: width - 210 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planNetPrem)}`, textSize),
        y: 418,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planStampDuty)}`, {
        x: width - 210 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planStampDuty)}`, textSize),
        y: 407,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planVat)}`, {
        x: width - 210 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planVat)}`, textSize),
        y: 396,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawText(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planTotalPrem)}`, {
        x: width - 210 - browaliaFont.widthOfTextAtSize(`${Intl.NumberFormat('th-TH', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.planTotalPrem)}`, textSize),
        y: 385,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    const saveDate = data.currentDate

    function addDays(date, days) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    }

    const saveDateFormat = new Date(Number(saveDate.split("/")[2]) - 543, Number(saveDate.split("/")[1]) - 1, Number(saveDate.split("/")[0]))

    // console.log(saveDate);
    // console.log(saveDateFormat);

    const saveDatePlusThirty = addDays(saveDateFormat, 30).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

    // console.log(addDays(saveDateFormat, 30));

    thirdPage.drawText(`${saveDate}`, {
        x: width - 450,
        y: 356,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawText(`${saveDatePlusThirty}`, {
        x: width - 340,
        y: 345,
        size: textSize,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawText(`# ${data.appid}-${data.currentDate} - TVinsure.com | ทวีทรัพย์ โบรคเกอร์`, {
        x: 370,
        y: 30,
        size: 9,
        font: browaliaFont,
        color: rgb(0, 0, 0),
    })



    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${data.appid}.pdf`, "application/pdf");
}

export { GetQuotationSmeSabai }
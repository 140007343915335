import React, { useCallback, useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import { AuthContext } from './shared/context/auth-context';


// Active Pages

import PageSignup from './auth/pages/PageSignup';
import PageLogin from './auth/pages/PageLogin';

import Maintance from './product/pages/Maintance';

import CovidAffiliatePage from './product/pages/CovidAffiliatePage.js';
import CovidShopProductList from './product/pages/CovidShopProductList.js';
import CovidApplicationNew from './product/pages/CovidApplicationNew.js';
import CovidApplicationNewWithGbDetail from './product/pages/CovidApplicationNewWithGbDetail.js';
import CovidApplicationNewWithGbPayment from './product/pages/CovidApplicationNewWithGbPayment.js';
import CovidEndorsement from './product/pages/CovidEndorsement.js';
import CovidCoverNoteResult from './product/pages/CovidCoverNoteResult.js';

// Services Page
import GetPolicy from './service/pages/GetPolicy';
import GetCovidAppDetail from './service/pages/GetCovidAppDetail';
import GetCovidAppDetailResult from './service/pages/GetCovidAppDetailResult';
import GetCovidIdSearch from './service/pages/GetCovidIdSearch';
import GetCovidIdSearchGroupCompany from './service/pages/GetCovidIdSearchGroupCompany.js';

// CRM Page
import DealMotorContact from './product/pages/DealMotorContact.js';
import DealResult from './product/pages/DealResult.js';
import TicketCreate from './product/pages/TicketCreate.js';
import TicketResult from './product/pages/TicketResult.js';

// Management Page
import ListPolicyInitGb from './admin/pages/ListPolicyInitGb';
import ListPolicyPaidGb from './admin/pages/ListPolicyPaidGb';
import ListPolicyNew from './admin/pages/ListPolicyNew';
import ListPolicyRenew from './admin/pages/ListPolicyRenew';
import ListPolicyAll from './admin/pages/ListPolicyAll';
import EndorsementList from './admin/pages/EndorsementList';
import ListAffiliateFirePackage from './admin/pages/ListAffiliateFirePackage';

// Affiliate Page
import AffiliateMainPage from './affiliate/pages/AffiliateMainPage.js'
import AffiliateFirePackageQuote from './affiliate/pages/AffiliateFirePackageQuote.js'
import AffiliateFirePackageApplication from './affiliate/pages/AffiliateFirePackageApplication.js'
import AffiliateFirePackageResult from './affiliate/pages/AffiliateFirePackageResult.js'

// import AffiliateMotorPackQuote from './affiliate/pages/AffiliateMotorPackQuote.js'


// Development Page Asset insure
import AssetQuoteAll from './product/pages/AssetQuoteAll.js'
import AssetQuoteSmeSabaiDetail from './product/pages/AssetQuoteSmeSabaiDetail.js'
import AssetQuoteSmeSabaiQuotation from './product/pages/AssetQuoteSmeSabaiQuotation.js'
import AssetQuoteSmeSabaiResult from './product/pages/AssetQuoteSmeSabaiResult.js'

// Development Page Health insure
import HealthApplicationAll from './product/pages/HealthApplicationAll.js';
import HealthApplicationProductGroup from './product/pages/HealthApplicationProductGroup.js';
import HealthApplicationInfo from './product/pages/HealthApplicationInfo.js';
import HealthApplicationQuestion from './product/pages/HealthApplicationQuestion.js';
import HealthApplicationGetResult from './product/pages/HealthApplicationGetResult.js';
import HealthApplicationGetPolicy from './product/pages/HealthApplicationGetPolicy.js';


// Archrived Pages

// import AsiaPlanOne from './product/pages/AsiaPlanOne.js'
// import TipPlanOne from './product/pages/TipPlanOne.js'
// import TipPlanTwo from './product/pages/TipPlanTwo.js'
// import SeiPlanAll from './product/pages/SeiPlanAll.js'
// import AsiaPlanTwo from './product/pages/AsiaPlanTwo.js'
// import AsiaPlanThree from './product/pages/AsiaPlanThree.js'

// import CovidAllPlanRenew from './product/pages/CovidAllPlanRenew.js';
// import CovidApplicationRenew from './product/pages/CovidApplicationRenew.js';


import Topbar from './shared/navigation/Topbar';
import Footer from './shared/navigation/Footer';
import tvinsureLoading from './shared/images/newbrands/loading-trans.gif';

// Import Css
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';

// Lazy loading for pages

// Motor Package
const AffiliateMotorPackQuote = React.lazy(() => import('./affiliate/pages/AffiliateMotorPackQuote.js'));
const AffiliateMotorPackList = React.lazy(() => import('./affiliate/pages/AffiliateMotorPackList.js'));
const AffiliateMotorPackageApplicationInfo = React.lazy(() => import('./affiliate/pages/AffiliateMotorPackageApplicationInfo.js'));
const AffiliateMotorPackageApplicationCoverage = React.lazy(() => import('./affiliate/pages/AffiliateMotorPackageApplicationCoverage.js'));
const AffiliateMotorPackageApplicationQrPayment = React.lazy(() => import('./affiliate/pages/AffiliateMotorPackageApplicationQrPayment.js'));
const AffiliateMotorPackageResult = React.lazy(() => import('./affiliate/pages/AffiliateMotorPackageResult.js'));

// Health Package

const AffiliateHealthPackQuote = React.lazy(() => import('./affiliate/pages/AffiliateHealthPackQuote.js'));
const AffiliateHealthPackList = React.lazy(() => import('./affiliate/pages/AffiliateHealthPackList.js'));

// Wrapped component

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return (
    <React.Fragment>
      <Topbar />
      <WrappedComponent />
      <Footer />
    </React.Fragment>
  );
}

function App() {

  // setting login context
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState(false);
  const [token, setToken] = useState(false);

  const login = useCallback((email, token) => {
    setEmail(email);
    setToken(token);
    localStorage.setItem('userData', JSON.stringify({ email: email, token: token }));
    setIsLoggedIn(true);
  }, [])

  const logout = useCallback(() => {
    setEmail(null);
    setToken(null);
    localStorage.removeItem('userData');
    setIsLoggedIn(false);
  }, [])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && storedData.token) {
      login(storedData.email, storedData.token)
    }
  }, [login]);

  // console.log(isLoggedIn);
  // console.log(token);

  const Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          {/* <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div> */}
          <div>
            {tvinsureLoading &&
              <img src={tvinsureLoading} style={
                {
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  display: "table-cell",
                  verticalAlign: "middle",
                  minHeight: "150px",
                  minWidth: "150px",
                }
              } alt="กำลังโหลด..." />
            }
          </div>
        </div>
      </div>
    );
  }

  let routes;

  if (token) {
    routes = (
      <Switch>

        {/* Production Pages */}

        {/* Covid Application */}

        {/* Covid Renew Slip */}

        {/* Archrived this page */}

        {/* <Route path="/application-all-plan-renew" exact={true} >
          <CovidAllPlanRenew />
        </Route>
        <Route path="/application-form-renew/:planid" exact={true} >
          <CovidApplicationRenew />
        </Route> */}

        <Route path="/endorsement-form" exact={true} >
          <CovidEndorsement />
        </Route>
        <Route path="/get-policy" exact={true} >
          <GetPolicy />
        </Route>
        <Route path="/get-covid-app-detail" exact={true} >
          <GetCovidAppDetail />
        </Route>
        <Route path="/get-covid-app-detail/:appid" exact={true} >
          <GetCovidAppDetailResult />
        </Route>
        <Route path="/get-covid-app-by-id" exact={true} >
          <GetCovidIdSearch />
        </Route>
        <Route path="/get-covid-app-group-company/:partnerid" exact={true} >
          <GetCovidIdSearchGroupCompany />
        </Route>


        {/* Covid New Silp */}

        <Route path="/application-form/:planid/payslip" exact={true} >
          <CovidApplicationNew />
        </Route>

        {/* Deal Contact */}
        <Route path="/deal/type/motor" exact={true} >
          <DealMotorContact />
        </Route>
        <Route path="/deal/:dealid" exact={true} >
          <DealResult />
        </Route>
        <Route path="/ticket/type/:tickettype" exact={true} >
          <TicketCreate />
        </Route>
        <Route path="/ticket/:ticketid" exact={true} >
          <TicketResult />
        </Route>


        {/* GB Payment Apply New */}
        <Route path="/gen-shop-covid" exact={true} >
          <CovidAffiliatePage />
        </Route>
        <Route path="/shop-covid-product-list/:agenttoken" exact={true} >
          <CovidShopProductList />
        </Route>
        <Route path="/application-form/:planid" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbDetail />
        </Route>
        {/* <Route path="/application-form/vip-channel/:planid/:activecheck" exact={true} >
          <CovidApplicationNewWithGbDetail />
        </Route> */}
        <Route path="/application-form/:planid/detail" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbDetail />
        </Route>
        <Route path="/application-form/:planid/detail/:agenttoken" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbDetail />
        </Route>
        <Route path="/application-form/payment/:appformid" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbPayment />
        </Route>
        <Route path="/get-covid-cover-note/:appid/:covernoteid" exact={true} >
          <CovidCoverNoteResult />
        </Route>

        {/* Affiliate Pages */}

        {/* <Route path="/aff-main/gen" exact={true} >
          <AffiliateMainPage />
        </Route>

        <Route path="/aff-fire-package/home/quote" exact={true} >
          <AffiliateFirePackageQuote />
        </Route>
        <Route path="/aff-fire-package/home/apply" exact={true} >
          <AffiliateFirePackageApplication />
        </Route>
        <Route path="/aff-fire-package/home/result" exact={true} >
          <AffiliateFirePackageResult />
        </Route> */}


        {/* <Route path="/aff-motor-package/vib/quote" exact={true}>
          <AffiliateMotorPackQuote />
        </Route> */}

        <Route path="/aff-motor-package/vib/quote/:type" exact={true}>
          {withLayout(AffiliateMotorPackQuote)}
        </Route>
        <Route path="/aff-motor-package/vib/list/:type" exact={true}>
          {withLayout(AffiliateMotorPackList)}
        </Route>
        <Route path="/aff-motor-package/vib/apply/info" exact={true}>
          {withLayout(AffiliateMotorPackageApplicationInfo)}
        </Route>
        <Route path="/aff-motor-package/vib/apply/cov" exact={true}>
          {withLayout(AffiliateMotorPackageApplicationCoverage)}
        </Route>
        <Route path="/aff-motor-package/vib/apply/payment" exact={true}>
          {withLayout(AffiliateMotorPackageApplicationQrPayment)}
        </Route>
        <Route path="/aff-motor-package/vib/result" exact={true}>
          {withLayout(AffiliateMotorPackageResult)}
        </Route>

        <Route path="/aff-health-package/all/quote" exact={true}>
          {withLayout(AffiliateHealthPackQuote)}
        </Route>
        <Route path="/aff-health-package/all/list" exact={true}>
          {withLayout(AffiliateHealthPackList)}
        </Route>



        {/* Development Pages */}

        {/* Asset Quote */}

        <Route path="/asset-quote-all" exact={true} >
          <AssetQuoteAll />
        </Route>
        <Route path="/asset-quote-all/sme-sabai-package/:industrycode/:suminsdetail/detail" exact={true} >
          <AssetQuoteSmeSabaiDetail />
        </Route>
        <Route path="/asset-quote-all/sme-sabai-package/:industrycode/:suminsdetail/quotation" exact={true} >
          <AssetQuoteSmeSabaiQuotation />
        </Route>
        <Route path="/asset-quote-all/sme-sabai-package/:quotationid" exact={true} >
          <AssetQuoteSmeSabaiResult />
        </Route>

        {/* Management Page */}
        <Route path="/list-policy-init-gb" exact={true} >
          <ListPolicyInitGb />
        </Route>
        <Route path="/list-policy-paid-gb" exact={true} >
          <ListPolicyPaidGb />
        </Route>
        <Route path="/list-policy-new" exact={true} >
          <ListPolicyNew />
        </Route>
        <Route path="/list-policy-renew" exact={true} >
          <ListPolicyRenew />
        </Route>
        <Route path="/list-policy-all" exact={true} >
          <ListPolicyAll />
        </Route>
        <Route path="/endorsement-list" exact={true} >
          <EndorsementList />
        </Route>

        <Route path="/aff-fire-pack-list" exact={true} >
          <ListAffiliateFirePackage />
        </Route>



        <Redirect to="/list-policy-paid-gb" />
      </Switch>);

  } if (!isLoggedIn) {
    routes = (
      <Switch>
        {/* <Topbar /> */}

        {/* Archrived Page */}

        {/* <Route path="/application-form-AII-500" exact={true} >
          <AsiaPlanOne />
        </Route>
        <Route path="/application-form-AII-365" exact={true} >
          <AsiaPlanTwo />
        </Route>
        <Route path="/application-form-AII-1825" exact={true} >
          <AsiaPlanThree />
        </Route>
        <Route path="/application-form-DHP-450" exact={true} >
          <TipPlanOne />
        </Route>
        <Route path="/application-form-DHP-850" exact={true} >
          <TipPlanTwo />
        </Route>
        <Route path="/application-form-SEI-ALL" exact={true} >
          <SeiPlanAll />
        </Route> */}

        {/* Covid Application */}

        {/* Covid Renew Slip */}

        {/* Archrived page */}

        {/* <Route path="/application-all-plan-renew" exact={true} >
          <CovidAllPlanRenew />
        </Route>
        <Route path="/application-form-renew/:planid" exact={true} >
          <CovidApplicationRenew />
        </Route> */}

        {/* Covid New Silp */}

        <Route path="/application-form/:planid/payslip" exact={true} >
          <CovidApplicationNew />
        </Route>

        {/* Deal Contact */}
        <Route path="/deal/type/:dealtype" exact={true} >
          <DealMotorContact />
        </Route>
        <Route path="/deal/:dealid" exact={true} >
          <DealResult />
        </Route>
        <Route path="/ticket/type/:tickettype" exact={true} >
          <TicketCreate />
        </Route>
        <Route path="/ticket/:ticketid" exact={true} >
          <TicketResult />
        </Route>

        {/* Get Policy File and App Status */}

        <Route path="/endorsement-form" exact={true} >
          <CovidEndorsement />
        </Route>
        <Route path="/get-policy" exact={true} >
          <GetPolicy />
        </Route>
        <Route path="/get-covid-app-detail" exact={true} >
          <GetCovidAppDetail />
        </Route>
        <Route path="/get-covid-app-detail/:appid" exact={true} >
          <GetCovidAppDetailResult />
        </Route>
        <Route path="/get-covid-app-by-id" exact={true} >
          <GetCovidIdSearch />
        </Route>
        <Route path="/get-covid-app-group-company/:partnerid" exact={true} >
          <GetCovidIdSearchGroupCompany />
        </Route>

        {/* New Covid with GB Payment */}

        <Route path="/gen-shop-covid" exact={true} >
          <CovidAffiliatePage />
        </Route>
        <Route path="/shop-covid-product-list/:agenttoken" exact={true} >
          <CovidShopProductList />
        </Route>
        {/* <Route path="/application-form/vip-channel/:planid/:activecheck" exact={true} >
          <CovidApplicationNewWithGbDetail />
        </Route> */}
        <Route path="/application-form/:planid" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbDetail />
        </Route>
        <Route path="/application-form/:planid/detail" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbDetail />
        </Route>
        <Route path="/application-form/:planid/detail/:agenttoken" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbDetail />
        </Route>
        <Route path="/application-form/payment/:appformid" exact={true} >
          {/* <Maintance /> */}
          <CovidApplicationNewWithGbPayment />
        </Route>
        <Route path="/get-covid-cover-note/:appid/:covernoteid" exact={true} >
          <CovidCoverNoteResult />
        </Route>

        {/* Affiliate Pages */}
        {/* <Route path="/aff-main/gen" exact={true} >
          <AffiliateMainPage />
        </Route>
        <Route path="/aff-fire-package/home/quote" exact={true} >
          <AffiliateFirePackageQuote />
        </Route>
        <Route path="/aff-fire-package/home/apply" exact={true} >
          <AffiliateFirePackageApplication />
        </Route>
        <Route path="/aff-fire-package/home/result" exact={true} >
          <AffiliateFirePackageResult />
        </Route> */}


        <Route path="/aff-motor-package/vib/quote/:type" exact={true}>
          {withLayout(AffiliateMotorPackQuote)}
        </Route>
        <Route path="/aff-motor-package/vib/list/:type" exact={true}>
          {withLayout(AffiliateMotorPackList)}
        </Route>
        <Route path="/aff-motor-package/vib/apply/info" exact={true}>
          {withLayout(AffiliateMotorPackageApplicationInfo)}
        </Route>
        <Route path="/aff-motor-package/vib/apply/cov" exact={true}>
          {withLayout(AffiliateMotorPackageApplicationCoverage)}
        </Route>
        <Route path="/aff-motor-package/vib/apply/payment" exact={true}>
          {withLayout(AffiliateMotorPackageApplicationQrPayment)}
        </Route>
        <Route path="/aff-motor-package/vib/result" exact={true}>
          {withLayout(AffiliateMotorPackageResult)}
        </Route>

        <Route path="/aff-health-package/all/quote" exact={true}>
          {withLayout(AffiliateHealthPackQuote)}
        </Route>
        <Route path="/aff-health-package/all/list" exact={true}>
          {withLayout(AffiliateHealthPackList)}
        </Route>


        {/* Asset Quote */}

        <Route path="/asset-quote-all" exact={true} >
          <AssetQuoteAll />
        </Route>
        <Route path="/asset-quote-all/sme-sabai-package/:industrycode/:suminsdetail/detail" exact={true} >
          <AssetQuoteSmeSabaiDetail />
        </Route>
        <Route path="/asset-quote-all/sme-sabai-package/:industrycode/:suminsdetail/quotation" exact={true} >
          <AssetQuoteSmeSabaiQuotation />
        </Route>
        <Route path="/asset-quote-all/sme-sabai-package/:quotationid" exact={true} >
          <AssetQuoteSmeSabaiResult />
        </Route>

        {/* Health Quote */}

        <Route path="/application-form-health" exact={true} >
          <HealthApplicationAll />
        </Route>
        <Route path="/application-form-health/:productGroup" exact={true} >
          <HealthApplicationProductGroup />
        </Route>
        <Route path="/application-form-health/:planid/:idcardnumber/info" exact={true} >
          <HealthApplicationInfo />
        </Route>
        <Route path="/application-form-health/:planid/:idcardnumber/question" exact={true} >
          <HealthApplicationQuestion />
        </Route>
        <Route path="/application-form-health/:planid/:idcardnumber/get-result" exact={true} >
          <HealthApplicationGetResult />
        </Route>
        <Route path="/application-form-health/:planid/:idcardnumber/get-policy" exact={true} >
          <HealthApplicationGetPolicy />
        </Route>
        <Route path="/covid-check-status" exact={true} >
          <GetCovidIdSearch />
        </Route>
        <Route path="/login" exact={true} >
          <PageLogin />
        </Route>
        <Route path="/signup" exact={true} >
          <PageSignup />
        </Route>

        <Redirect to="/aff-motor-package/vib/quote/typeall?carUse=110" />
      </Switch>
    )
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn: !!token, email: email, token: token, login: login, logout: logout }}>
      <Router>
        <Suspense fallback={Loader()}>
          {routes}
        </Suspense>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

// React Basic and Bootstrap
import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { storage, db } from '../../firebase/config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import recoveryimg from '../../shared/images/Aff-Asset-Quote-gen-Shop-02102564.png';

import { covidApi } from '../../shared/masters/url';

var uniqid = require('uniqid');

const AffiliateMainPage = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [agentData, setAgentData] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    const sendAgentRequest = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${covidApi}/api/masters/agent`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setAgentData(response.data)
        setIsLoading(false);
      } catch (err) {
        setError(err.message || 'Something went wrong with Agent API, please try again.');
        setIsLoading(false);
      }
    }
    sendAgentRequest()
  }, []);

  const [formData, setFormData] = useState({
    agentCode: "",
    agentCheck: "uncheck",
  });

  const handleChange = event => {
    const { name, value } = event.target

    if (name === "agentCode") {
      setFormData(prevValue => {
        return {
          ...prevValue,
          agentCode: value,
          agentCheck: "uncheck"
        }
      });
    }
  }

  //Handle Check Agent
  const handleCheckAgent = () => {
    const agentFilter = agentData.filter(a => a.AGENT_CODE === formData.agentCode)
    if (agentFilter.length > 0) {
      const token = "T" + agentFilter[0].AGENT_CODE + uniqid.time().toUpperCase()
      const requestSubmitFormData = () => {
        setIsLoading(true);
        db.collection('affiliateTokenGen').add({
          tokenid: token,
          currenDate: new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }),
          agentCode: agentFilter[0].AGENT_CODE,
          agentName: agentFilter[0].AGENT_NAME_T,
          agentCheck: "success",
          linkShop: `https://product.tvinsure.com/aff-fire-package/home/quote?token=${token}`
        }).then(() => {
          setFormData(prevValue => {
            return {
              ...prevValue,
              tokenid: token,
              currenDate: new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }),
              agentCode: agentFilter[0].AGENT_CODE,
              agentName: agentFilter[0].AGENT_NAME_T,
              agentCheck: "success",
              linkShop: `https://product.tvinsure.com/aff-fire-package/home/quote?token=${token}`
            }
          });
        })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
      requestSubmitFormData()
    }
    if (agentFilter.length === 0) {
      setFormData(prevValue => {
        return {
          ...prevValue,
          agentCode: "",
          agentName: "กรุณาระบุผู้แจ้งงาน",
          agentCheck: "fail"
        }
      });
    }
  }

  // handle Copy Link
  const handleCopyLink = () => {
    setFormData(prevValue => {
      return {
        ...prevValue,
        isCopyLink: true
      }
    })
    alert("คัดลอกลิงค์เรียบร้อย: " + formData.linkShop);
  }


  // console.log(formData);

  return (
    <React.Fragment>
      <div className="back-to-home rounded d-none d-sm-block">
        <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
          <i>
            <FeatherIcon icon="home" className="icons" />
          </i>
        </a>
      </div>
      <section className="bg-home d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg="7" md="6">
              <div className="mr-lg-5 shadow-lg">
                <img
                  src={recoveryimg}
                  className="img-fluid d-block mx-auto"
                  alt=""
                />
              </div>
            </Col>
            <Col lg="5" md="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <Card className="login_page shadow-lg rounded border-0">
                <CardBody>
                  {formData.agentCheck !== "success" && <h4 className="card-title text-center">สร้างลิงค์หน้าร้านของคุณ</h4>}
                  {formData.agentCheck === "success" && <h4 className="card-title text-center text-success">สร้างลิงค์หน้าร้านสำเร็จ</h4>}
                  <AvForm className="login-form mt-4">
                    <Row>
                      {formData.agentCheck !== "success" && <Col lg="12">
                        <p className="text-muted">
                          ท่านผู้แนะนำสามารถกรอกรหัสผู้แนะนำของตนเองเพื่อสร้างลิงค์และแชร์หน้าร้านค้าของท่านเองโดยผู้ที่ซื้อผ่านหน้าร้านค้านี้จะถูกระบุเป็นลูกค้าภายใต้การแนะนำของท่านทั้งหมด
                        </p>
                        <FormGroup className="position-relative">
                          <Label for="agentCode">
                            รหัสผู้แนะนำของท่าน{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="position-relative">
                          </div>
                          <AvField
                            type="text"
                            className="form-control"
                            name="agentCode"
                            id="agentCode"
                            placeholder="กรุณากรอกรหัสผู้แนะนำของท่าน"
                            required
                            errorMessage="กรุณากรอกรหัสผู้แนะนำของท่าน"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "กรุณากรอกรหัสผู้แนะนำของท่าน",
                              }
                            }}
                            value={formData.agentCode}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>}
                      {formData.agentCheck !== "success" && <Col lg="12">
                        <Button color="primary" block onClick={handleCheckAgent}>
                          สร้างลิงค์หน้าร้านค้า
                        </Button>
                      </Col>}
                      {formData.agentCheck !== "success" && <div className="mx-auto">
                        <p className="mb-0 mt-3">
                          <small className="text-dark mr-2">
                            หากท่านยังไม่มีรหัสผู้แนะนำ ?
                          </small>{" "}
                          <a
                            href="https://info.tvinsure.com/how-to-register/"
                            className="text-primary font-weight-bold"
                          >
                            สามารถสมัครได้ที่นี่
                          </a>
                        </p>
                      </div>}
                      {formData.agentCheck === "success" && <Col lg="12">
                        <FormGroup className="position-relative">
                          <Label for="linkShop">
                            ลิงค์หน้าร้านค้าของท่านมีรายละเอียดตามด้านล่าง{" "}
                          </Label>
                          <div className="position-relative">
                          </div>
                          <AvField
                            type="text"
                            className="form-control"
                            name="linkShop"
                            id="linkShop"
                            required
                            value={formData.linkShop}
                            disabled
                          />
                        </FormGroup>
                      </Col>}
                      {formData.agentCheck === "success" && <Col lg="12">
                        <CopyToClipboard text={formData.linkShop}
                          onCopy={handleCopyLink}>
                          <Button color="primary" block>
                            {formData.isCopyLink === true ? "คัดลอกลิงค์เรียบร้อย" : "กดเพื่อคัดลอกลิงค์"}
                          </Button>
                        </CopyToClipboard>
                      </Col>}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default AffiliateMainPage;

import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { db } from '../../firebase/config'

import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { preName, day, month, year, CovStartDateZero, CovEndDateZero, HealthplanList, Occupation } from '../../shared/masters/refData'



//Import Icons
import FeatherIcon from 'feather-icons-react';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import nkiinsurancelogo from '../../shared/images/NKI_LOGO.png';

import './Product.css';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid2.jpg';

const HealthApplicationAll = () => {

    let history = useHistory();

    const planId = useParams().planid;

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [allApplictionData, setAllApplicationData] = useState();
    const [allApplictionPlan, setAllApplicationPlan] = useState();
    const [allApplictionProductGroup, setAllApplicationProductGroup] = useState();
    const [error, setError] = useState(null);

    //contain all form data
    const [formData, setFormData] = useState({
        NATIONAL_ID: "",
        ID_CARD_STATUS_CHECK: "uncheck"
    });

    // Get API Province and DistrictSub and Agent
    useEffect(() => {

    }, []);


    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    const handleValidSubmit = (event) => {
        event.preventDefault();
        setIsSubmit(true);
        db.collection('healthApplication').where('NATIONAL_ID', '==', formData.NATIONAL_ID).get().then((snapshot) => {

            function getApplicationData() {
                const output = [];
                for (let i = 0; i < snapshot.docs.length; i++)
                    output.push({
                        id: snapshot.docs[i].id,
                        data: snapshot.docs[i].data()
                    });
                return output;
            }
            setAllApplicationData(getApplicationData())

            function getApplicationPlan() {
                const output = [];
                for (let i = 0; i < snapshot.docs.length; i++)
                    output.push(snapshot.docs[i].data().PLAN_ID);
                return output;
            }
            setAllApplicationPlan(getApplicationPlan())

            function getApplicationProductGroup() {
                const output = [];
                for (let i = 0; i < snapshot.docs.length; i++)
                    output.push(snapshot.docs[i].data().PRODUCT_GROUP);
                return output;
            }
            setAllApplicationProductGroup(getApplicationProductGroup())

            setIsLoading(false);
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    ID_CARD_STATUS_CHECK: "checked"
                }
            });
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            setErrorModal(true);
        })
    }

    // console.log(allApplictionData);
    // console.log(allApplictionPlan);
    // console.log(allApplictionProductGroup);

    // console.log(formData);

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    return (
        <React.Fragment>
            {error && !isLoading && (<div className="justify-content-center">
                <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                    <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                    <ModalBody className="pb-2">
                        <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                        <p className="mb-2">{error}</p>
                        <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                        <ul className="mt-1">
                            <li>Line: @tvinsure</li>
                            <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>{' '}
                        {/* <Button color="danger" onClick={toggle}>Cancel</Button> */}
                    </ModalFooter>
                </Modal>
            </div>)}

            <section className="bg-auth bg-color" style={{ background: `url(${tvinsurecovid}) center center`, minHeight: "720px", backgroundSize: "contain", backgroundSize: "" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>

                            <div className="text-center mb-5">
                                <span>
                                    <img src={tvinsurelogo} alt="TVinsure" height="80" />
                                </span>
                            </div>

                            <h4 className="text-center h3 mt-5 mb-1 ">ค้นหาประกันภัยที่เหมาะกับคุณ</h4>
                            <p className="text-center mt-1 mb-5">กรอกข้อมูลเลขบัตรประชาชนเพื่อดำเนินการต่อไป</p>

                            <Card>
                                <CardBody>

                                    <AvForm className="login-form mt-2" onValidSubmit={handleValidSubmit}>
                                        <Row>
                                            <Col lg={10} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกเลขบัตรประชาชน"
                                                        validate={{
                                                            // required: { value: true }
                                                            pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' }
                                                        }, { required: { value: true } }} placeholder="เลขบัตรประชาชน 13 หลัก" name="NATIONAL_ID" value={formData.NATIONAL_ID} onChange={handleChange} />

                                                </FormGroup>
                                            </Col>
                                            <Col lg={2} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                    <Button className="mb-2" color="danger" block>{formData.agentCheck === "success" ? "ตรวจสอบแล้ว" : "ตรวจสอบ"}
                                                    </Button>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                            {allApplictionData && allApplictionData.length > 0 && <h4 className="text-center h4 mt-5 mb-2 ">ยินดีต้อนรับ {allApplictionData[0].data.PRE_NAME}{allApplictionData[0].data.FIRST_NAME} {allApplictionData[0].data.LAST_NAME}</h4>}
                            {allApplictionData && allApplictionData.length > 0 && <p className="text-center mt-1 mb-5">คุณสามารถดำเนินการต่อในแผนประกันคุณสมัครไว้ ตามข้อมูลด้านล่าง</p>}
                            
                            {allApplictionData && allApplictionData.length === 0 && <h4 className="text-center h4 mt-5 mb-2 ">คุณยังไม่ได้สมัครประกันภัยกับทางเรา</h4>}
                            {allApplictionData && allApplictionData.length === 0 && <p className="text-center mt-1 mb-5">คุณสามารถเลือกประกันภัยที่เหมาะสมกับคุณได้ตามแผนประกันภัยด้านล่าง เราคัดสรรสิ่งที่เหมาะสมที่สุดมาให้กับคุณ</p>}

                            {formData.ID_CARD_STATUS_CHECK === "checked" && <Row className="my-3">
                                {HealthplanList.map(p => (
                                    <Col lg={4}>
                                        <Card className="my-2 border-0 rounded shadow product-card">
                                            <CardBody >
                                                <h6 className="text-center mt-3">{p.PLAN_ID}</h6>
                                                <div className="text-center mb-3">
                                                    <span>
                                                        {p.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={p.PLAN_ISSUER_CODE} height="60" />}
                                                        {p.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={p.PLAN_ISSUER_CODE} height="60" />}
                                                        {p.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={p.PLAN_ISSUER_CODE} height="60" />}
                                                        {p.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={p.PLAN_ISSUER_CODE} height="60" />}
                                                        {p.PLAN_ISSUER_CODE === "NKI" && <img src={nkiinsurancelogo} alt={p.PLAN_ISSUER_CODE} height="60" />}
                                                    </span>
                                                </div>
                                                <h6 className="text-center mt-3">{p.PLAN_DESC}</h6>
                                                <p className="text-center mt-3">{p.PLAN_COV_TITLE}</p>
                                                <Link to={`/application-form-health/${p.PLAN_ID}/${formData.NATIONAL_ID}/info`}>
                                                    <Button
                                                        color={allApplictionPlan.includes(p.PLAN_ID) ? "success" : "danger"}
                                                        block
                                                        className="mx-2" key={p.PLAN_ID} id={p.PLAN_ID}
                                                        outline={allApplictionProductGroup.includes(p.PRODUCT_GROUP) && !allApplictionPlan.includes(p.PLAN_ID)}
                                                        disabled={allApplictionProductGroup.includes(p.PRODUCT_GROUP) && !allApplictionPlan.includes(p.PLAN_ID)}>
                                                        {allApplictionPlan.includes(p.PLAN_ID) ? "ดำเนินการต่อ" : allApplictionProductGroup.includes(p.PRODUCT_GROUP) ? "ไม่สามารถสมัครประกันภัยได้" : "สมัครประกันภัย"}
                                                    </Button>
                                                </Link>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                                )}
                            </Row>}

                            {/* แสดงข้อมูลด้านล่างเกี่ยวกับบริษัท */}
                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default HealthApplicationAll;
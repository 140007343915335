import { storage, db } from '../../firebase/config'
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BackgroundSlider from "react-background-slider";

//Import Icons
import FeatherIcon from 'feather-icons-react';
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid.png';


// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const GetPolicy = () => {

    const [formData, setFormData] = useState({
        policyNum: "",
        policyCheck: "uncheck",
        policyUrl: ""
    })

    const [policyLength, setpolicyLength] = useState(null)

    function onResolve(foundURL) {
        // console.log(foundURL);
        setFormData(prevValue => {
            return {
                ...prevValue,
                policyCheck: "found",
                policyUrl: foundURL
            }
        });
    }

    function onReject(error) {
        // console.log(error);
        setFormData(prevValue => {
            return {
                ...prevValue,
                policyCheck: "notFound"
            }
        });
    }

    function OnRecheck() {
        storage.ref("policyfile").child(`${formData.policyNum}.PDF`).getDownloadURL().then(onResolve, onReject);
    }

    // console.log(formData);

    const handleClick = () => {
        storage.ref("policyfile").child(`${formData.policyNum}.pdf`).getDownloadURL().then(onResolve, OnRecheck);
    }

    const handleChange = event => {
        const { name, value } = event.target

        setFormData(prevValue => {
            return {
                ...prevValue,
                policyCheck: "uncheck",
                [name]: value.replace(/\//g, "")
            }
        });
    }

    useEffect(() => {
        storage.ref("policyfile").listAll().then(function (res) {
            setpolicyLength(res.items.length)
            // console.log(res.items.length);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="back-to-home rounded d-none d-sm-block">
                <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                    <i>
                        <FeatherIcon icon="home" className="icons" />
                    </i>
                </a>
            </div>
            <section className="bg-half-90 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                            </div>
                            <div className="title-heading text-center">
                                <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                    ดาวโหลดไฟล์กรมธรรม์/เลขคุ้มครองที่ได้รับ
                                </h4>
                                <p className="text-center text-white h5 mt-1 mb-5">ประกันภัยไวรัสโคโรนา (ประกันภัยใหม่)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
            <section style={{ margin: "-120px 0 0" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>
                            <Card>
                                <CardBody className="shadow-lg">
                                    <h4 className="text-center mt-4 mb-3">สำหรับการสมัครประกันภัยโคโรนาไวรัสใหม่เท่านั้น</h4>
                                    <h5 className="text-left">ขั้นตอนการค้นหากรมธรรม์มีดังนี้</h5>
                                    <h6 className="text-left"> - กรอกเลขกรมธรรม์ หรือ เลขความคุ้มครองที่ได้รับทางอีเมล หรือไลน์</h6>
                                    <h6 className="text-left"> - กดปุ่มตรวจสอบเลขกรมธรรม์ หรือ เลขความคุ้มครองที่ได้กรอกไว้</h6>
                                    <h6 className="text-left"> - ระบบจะแสดงปุ่ม "ดาวโหลดเลย!" สามารถให้ผู้ใช้งานกดดาวโหลดได้เลย</h6>
                                    <h6 className="text-left"> - หากระบบขึ้นว่าไม่พบกรมธรรม์ ลองตรวจสอบเลขกรมธรรม์อีกครั้ง หรือกรุณาติดต่อทีมงานเพื่อดำเนินการต่อไป</h6>
                                    <AvForm className="login-form mt-4 mb-3">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลขกรมธรรม์/เลขคุ้มครองที่ได้รับ <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกเลขกรมธรรม์/เลขคุ้มครองที่ได้รับเพื่อดาวโหลดไฟล์"
                                                        validate={{ required: { value: true } }} placeholder="เลขกรมธรรม์/เลขคุ้มครองที่ได้รับ" name="policyNum" onChange={handleChange} value={formData.policyNum} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                    <Button className="mb-2" color={formData.policyCheck === "uncheck" ? "danger" : formData.policyCheck === "found" ? "success" : "danger"}
                                                        outline={formData.policyCheck === "uncheck"} block
                                                        disabled={!formData.policyNum}
                                                        onClick={handleClick}>{formData.policyCheck === "uncheck" ? "กดเพื่อดาวโหลดไฟล์" : formData.policyCheck === "found" ? "พบไฟล์กรมธรรม์" : "ไม่พบไฟล์กรมธรรม์"}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            {formData.policyCheck === "found" && formData.policyUrl && <Col xs={12} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-dark mr-2 h6 mt-2">กดเพื่อดาวโหลดไฟล์กรมธรรม์หรือไฟล์เอกสารคุ้มครอง</small><a href={`${formData.policyUrl}`} target="_blank" className="font-weight-bold h5 text-success">ดาวโหลดเลย!</a></p>
                                            </Col>}
                                            {formData.policyCheck === "notFound" && <Col xs={12} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-danger mr-2 h6 mt-2">ไม่พบไฟล์กรมธรรม์หรือไฟล์เอกสารคุ้มครอง</small></p>
                                                <p className="mt-4 mb-3 text-left">* หากเกิดข้อผิดพลาด หรือ ไม่พบไฟล์กรมธรรม์ประกันภัยสามารถติดต่อทีมงานได้ตามช่องทางด้านล่างเพื่อจัดส่งทางช่องทางอื่นเพิ่มเติม</p>
                                                <ul className="mt-1 text-left">
                                                    <li>Line: @tvinsure</li>
                                                    <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                                                </ul>
                                            </Col>}
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-5 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted mb-5">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default GetPolicy;
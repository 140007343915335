import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BackgroundSlider from "react-background-slider";

// Import Firebase config
import { db } from '../../firebase/config'

//Import Icons
import FeatherIcon from 'feather-icons-react';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import './Product.css';


// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

var uniqid = require('uniqid');

const CovidEndorsement = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState();

    //contain all form data
    const [formData, setFormData] = useState({
        docid: "CVED-" + uniqid.time().toUpperCase()
    });

    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    const handleValidSubmit = (event, values) => {
        event.preventDefault();
        setIsSubmit(true);
        const requestSubmitFormData = () => {
            setIsLoading(true);
            db.collection('covidEndorsement').add(formData).then(() => {
                db.collection('covidEndorsement').where('docid', '==', formData.docid).get().then((snapshot) => {
                    if (snapshot.docs && snapshot.docs.length > 0) {
                        setResponseData(snapshot.docs[0].data())
                        setIsLoading(false);
                        setSuccessModal(true);
                    } else {
                        setIsLoading(false);
                        setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
                        setErrorModal(true);
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                    setErrorModal(true);
                })
            })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                    setErrorModal(true);
                });
        }
        requestSubmitFormData()
    }

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        setIsSubmit(false);
        window.location.reload()
    };

    // console.log(formData);
    // console.log(responseData);

    return (
        <React.Fragment>
            {error && !isLoading && (<div className="justify-content-center">
                <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                    <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                    <ModalBody className="pb-2">
                        <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                        <p className="mb-2">{error}</p>
                        <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                        <ul className="mt-1">
                            <li>Line: @tvinsure</li>
                            <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>{' '}
                        {/* <Button color="danger" onClick={toggle}>Cancel</Button> */}
                    </ModalFooter>
                </Modal>
            </div>)}

            {responseData && !isLoading && (<div className="justify-content-center">
                <Modal isOpen={successModal} >
                    <ModalHeader toggle={toggleSuccessModal}>การแจ้งสลักหลัง/แก้ไขข้อมูลประกันภัยสำเร็จ <i><FeatherIcon icon="check" className="fea icon-md icons text-success" /></i></ModalHeader>
                    <ModalBody className="pb-2">
                        <p className="mb-3 text-center h5">สรุปข้อมูลการสลักหลังดังนี้</p>
                        <p className="mb-3 text-center h6">หมายเลขการสมัคร: {responseData.docid}</p>                        
                        <div className="row">
                            <div className="col-7 text-left">
                                <p className="mb-2 h6"> เลขกรมธรรม์</p>
                            </div>
                            <div className="col-5 text-right">
                                <p>{responseData.policyNum} </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-left">
                                <p className="mb-2 h6"> รายละเอียดการสลักหลัง</p>
                            </div>
                            <div className="col-12 text-left">
                                <p>{responseData.endorsementDetail}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left">
                                <p className="mb-2 h6"> เบอร์โทรศัพท์มือถือ</p>
                            </div>
                            <div className="col-6 text-right">
                                <p>{responseData.tel} </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 text-left">
                                <p className="mb-2 h6"> อีเมล</p>
                            </div>
                            <div className="col-9 text-right">
                                <p>{responseData.email} </p>
                            </div>
                        </div>
                        <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด หรือ ไม่ได้รับกรมธรรม์ประกันภัยสามารถติดต่อได้ตามช่องทางด้านล่าง</p>
                        <ul className="mt-1">
                            <li>Line: @tvinsure</li>
                            <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                        </ul>
                        <p className="text-left"># {responseData.APP_FORM_ID}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={toggleSuccessModal}>แจ้งสลักหลังเพิ่มเติม</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>)}

            <div className="back-to-home rounded d-none d-sm-block">
                <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                    <i>
                        <FeatherIcon icon="home" className="icons" />
                    </i>
                </a>
            </div>

            <section className="bg-half-100 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                            </div>
                            <div className="title-heading text-center">
                                <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                    แจ้งสลักหลัง/แก้ไขข้อมูลประกันภัยไวรัสโคโรนา
                                </h4>
                                <p className="text-center text-white h5 mt-1 mb-5">ผู้ต้องการแจ้งสลักหลัง/แก้ไขข้อมูลประกันภัยกรุณากรอกรายละเอียดให้ครบถ้วนทาง TVinsure จะเป็นผู้ทำหน้าประสานงานกับบริษัทประกันให้กับลูกค้า</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>

            <section style={{ margin: "-120px 0 0" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>
                            <Card className="shadow-lg">
                                <CardBody>
                                    <AvForm className="login-form mt-2" onValidSubmit={handleValidSubmit}>
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="text-center mt-5 mb-5">ข้อมูลการสลักหลังกรมธรรม์</h4>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลขกรมธรรม์/เลขความคุ้มครอง/เลขคำขอประกันภัย <span className="text-danger">*</span></Label>
                                                    <AvField type="text" className="form-control"
                                                        errorMessage="กรุณาเลขกรมธรรม์/เลขความคุ้มครอง"
                                                        validate={{ required: { value: true } }} placeholder="เลขกรมธรรม์/เลขความคุ้มครอง" name="policyNum" onChange={handleChange} value={formData.policyNum} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>รายละเอียดการสลักหลัง <span className="text-danger">*</span></Label>
                                                    <AvField type="map" className="form-control"
                                                        errorMessage="กรุณากรอกรายละเอียดการสลักหลัง"
                                                        validate={{ required: { value: true } }} placeholder="รายละเอียดการสลักหลัง เช่น เปลี่ยนชื่อจาก นายทดสอบ เป็น นายแก้ไข เนื่องจาก สะกดผิดตอนคีย์" name="endorsementDetail" onChange={handleChange} value={formData.endorsementDetail} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <h4 className="text-center mt-5 mb-5">ข้อมูลการติดต่อ</h4>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>หมายเลขโทรศัพท์มือถือ <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="smartphone" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง"
                                                        validate={{
                                                            pattern: { value: '^[0][689][0-9]-?[0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9]$' },
                                                            required: { value: true }
                                                        }} placeholder="หมายเลขโทรศัพท์มือถือ" name="tel" onChange={handleChange} value={formData.tel} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>อีเมล <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                    <AvField type="email" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกอีเมลที่ถูกต้อง"
                                                        validate={{
                                                            required: { value: true },
                                                            email: { value: true }
                                                        }} placeholder="อีเมล" name="email" onChange={handleChange} value={formData.email} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={12} className="mb-0 auth-button">
                                                {!isSubmit && <Button color="danger" type="submit" block disabled={formData.idcardfileurlloadfinish && formData.paymentfileurlloadfinish !== "uploaded"}>ส่งข้อมูลการสลักหลัง</Button>}
                                                {isSubmit && <Button color="danger" block disabled>กำลังส่งข้อมูลการสลักหลัง</Button>}
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default CovidEndorsement;
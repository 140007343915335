var moment = require('moment');

moment().format();

const preName = [
  {
    PRE_NAME_CODE: "001",
    PRE_NAME: "นาย",
    GENDER: "M"
  },
  {
    PRE_NAME_CODE: "002",
    PRE_NAME: "นาง",
    GENDER: "F"
  },
  {
    PRE_NAME_CODE: "003",
    PRE_NAME: "นางสาว",
    GENDER: "F"
  },
  {
    PRE_NAME_CODE: "203",
    PRE_NAME: "เด็กชาย",
    GENDER: "M"
  },
  {
    PRE_NAME_CODE: "204",
    PRE_NAME: "เด็กหญิง",
    GENDER: "F"
  },
  {
    PRE_NAME_CODE: "194",
    PRE_NAME: "Mr.",
    GENDER: "M"
  },
  {
    PRE_NAME_CODE: "196",
    PRE_NAME: "Miss",
    GENDER: "F"
  },
  ,
  {
    PRE_NAME_CODE: "929",
    PRE_NAME: "Mrs.",
    GENDER: "F"
  },
  {
    PRE_NAME_CODE: "238",
    PRE_NAME: "Master",
    GENDER: "M"
  }
]

const benefitType = ["ตนเอง", "ทายาทโดยธรรม", "สามี", "ภรรยา", "บิดา", "มารดา", "พี่ชาย", "น้องชาย", "พี่สาว", "น้องสาว", "บุตรชาย", "บุตรสาว"]


const day = [

  {
    dayDesc: "1",
    dayValue: "01"
  },
  {
    dayDesc: "2",
    dayValue: "02"
  },
  {
    dayDesc: "3",
    dayValue: "03"
  },
  {
    dayDesc: "4",
    dayValue: "04"
  },
  {
    dayDesc: "5",
    dayValue: "05"
  },
  {
    dayDesc: "6",
    dayValue: "06"
  },
  {
    dayDesc: "7",
    dayValue: "07"
  },
  {
    dayDesc: "8",
    dayValue: "08"
  },
  {
    dayDesc: "9",
    dayValue: "09"
  },
  {
    dayDesc: "10",
    dayValue: "10"
  },
  {
    dayDesc: "11",
    dayValue: "11"
  },
  {
    dayDesc: "12",
    dayValue: "12"
  },
  {
    dayDesc: "13",
    dayValue: "13"
  },
  {
    dayDesc: "14",
    dayValue: "14"
  },
  {
    dayDesc: "15",
    dayValue: "15"
  },
  {
    dayDesc: "16",
    dayValue: "16"
  },
  {
    dayDesc: "17",
    dayValue: "17"
  },
  {
    dayDesc: "18",
    dayValue: "18"
  },
  {
    dayDesc: "19",
    dayValue: "19"
  },
  {
    dayDesc: "20",
    dayValue: "20"
  },
  {
    dayDesc: "21",
    dayValue: "21"
  },
  {
    dayDesc: "22",
    dayValue: "22"
  },
  {
    dayDesc: "23",
    dayValue: "23"
  },
  {
    dayDesc: "24",
    dayValue: "24"
  },
  {
    dayDesc: "25",
    dayValue: "25"
  },
  {
    dayDesc: "26",
    dayValue: "26"
  },
  {
    dayDesc: "27",
    dayValue: "27"
  },
  {
    dayDesc: "28",
    dayValue: "28"
  },
  {
    dayDesc: "29",
    dayValue: "29"
  },
  {
    dayDesc: "30",
    dayValue: "30"
  },
  {
    dayDesc: "31",
    dayValue: "31"
  }
]

const year = []

const getYearBirthday = () => {
  const currenDate = new Date().getFullYear()
  var i;
  for (i = currenDate - 99; i < currenDate + 1; i++) {
    year.push({ TH_YEAR: i + 543, EN_YEAR: i })
  }
}

getYearBirthday()

const yearMoreThanfifteen = []

const getYearMoreThanfifteen = () => {
  const currenDate = new Date().getFullYear()
  var i;
  for (i = currenDate - 99; i < currenDate - 14; i++) {
    yearMoreThanfifteen.push({ TH_YEAR: i + 543, EN_YEAR: i })
  }
}

// console.log(yearMoreThanfifteen);

getYearMoreThanfifteen()

const yearLessThanfifteen = []

const getYearLessThanfifteen = () => {
  const currenDate = new Date().getFullYear()
  var i;
  for (i = currenDate - 15; i < currenDate + 1; i++) {
    yearLessThanfifteen.push({ TH_YEAR: i + 543, EN_YEAR: i })
  }
}

getYearLessThanfifteen()

// console.log(yearLessThanfifteen);


const getCustomYear = (start, end) => {
  const customYear = []
  const currenDate = new Date().getFullYear()
  var i;
  for (i = currenDate - end; i < currenDate - start; i++) {
    customYear.push({ TH_YEAR: i + 543, EN_YEAR: i })
  }
  return customYear
}

// console.log(yearMoreThanfifteen);

const month = [
  {
    monthDesc: "มกราคม",
    monthValue: "01"
  },
  {
    monthDesc: "กุมภาพันธ์",
    monthValue: "02"
  },
  {
    monthDesc: "มีนาคม",
    monthValue: "03"
  },
  {
    monthDesc: "เมษายน",
    monthValue: "04"
  },
  {
    monthDesc: "พฤษภาคม",
    monthValue: "05"
  },
  {
    monthDesc: "มิถุนายน",
    monthValue: "06"
  },
  {
    monthDesc: "กรกฎาคม",
    monthValue: "07"
  },
  {
    monthDesc: "สิงหาคม",
    monthValue: "08"
  },
  {
    monthDesc: "กันยายน",
    monthValue: "09"
  },
  {
    monthDesc: "กันยายน",
    monthValue: "09"
  },
  {
    monthDesc: "ตุลาคม",
    monthValue: "10"
  },
  {
    monthDesc: "พฤศจิกายน",
    monthValue: "11"
  },
  {
    monthDesc: "ธันวาคม",
    monthValue: "12"
  },
]



const CovStartDate = () => {

  const currentDay = new Date().getDay()

  function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

  const date = new Date();

  const todayDate = moment().startOf('day');
  const selectDate = moment('2021-02-25').startOf('day');

  // Holiday 13 14 15
  const selectDateForSixteenthAprilOne = moment('2021-04-12').startOf('day');
  const selectDateForSixteenthAprilTwo = moment('2021-04-13').startOf('day');
  const selectDateForSixteenthAprilThree = moment('2021-04-14').startOf('day');
  const selectDateForSixteenthAprilFour = moment('2021-04-15').startOf('day');
  const dateSixteenthAprilStart = new Date(2021, 3, 16)
  const dateCovStartSixteenthApril = dateSixteenthAprilStart.toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  const dateCovStartPlusFour = addDays(date, 4).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
  const dateCovStartPlusThree = addDays(date, 3).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
  const dateCovStartPlusTwo = addDays(date, 2).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
  const dateCovStartPlusOne = addDays(date, 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  const timeRef = 16

  function addDaysAfterTimeRef(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
      copy.setDate(date.getDate() + days)
      return copy
    } else {
      return copy
    }
  }

  const dateFinalForAll = addDaysAfterTimeRef(date, 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  // Holiday One Day

  if (todayDate.isSame(selectDate)) {
    return dateCovStartPlusFour
  }

  // Holiday 13 14 15

  if
    (todayDate.isSame(selectDateForSixteenthAprilOne)) {
    return dateCovStartSixteenthApril
  }

  if
    (todayDate.isSame(selectDateForSixteenthAprilTwo)) {
    return dateCovStartSixteenthApril
  }

  if
    (todayDate.isSame(selectDateForSixteenthAprilThree)) {
    return dateCovStartSixteenthApril
  }

  if
    (todayDate.isSame(selectDateForSixteenthAprilFour)) {
    return dateCovStartSixteenthApril
  }


  // Friday to Monday
  if (currentDay === 5) {
    return dateFinalForAll
  }

  // Saturday to Monday
  if (currentDay === 6) {
    return dateFinalForAll
  }

  // Everyday Plus One (Sunday to Monday)
  return dateFinalForAll
}

const CovEndDate = () => {

  const currentDay = new Date().getDay()

  function addDaysAndOneYear(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    copy.setYear(date.getFullYear() + 1)
    return copy
  }

  const date = new Date();

  const todayDate = moment().startOf('day');
  const selectDate = moment('2021-02-25').startOf('day');

  // Holiday 13 14 15
  const selectDateForSixteenthAprilOne = moment('2021-04-12').startOf('day');
  const selectDateForSixteenthAprilTwo = moment('2021-04-13').startOf('day');
  const selectDateForSixteenthAprilThree = moment('2021-04-14').startOf('day');
  const selectDateForSixteenthAprilFour = moment('2021-04-15').startOf('day');
  const dateSixteenthAprilEnd = new Date(2022, 3, 16)
  const dateCovEndSixteenthApril = dateSixteenthAprilEnd.toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  const dateCovEndPlusFour = addDaysAndOneYear(date, 4).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
  const dateCovEndPlusThree = addDaysAndOneYear(date, 3).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
  const dateCovEndPlusTwo = addDaysAndOneYear(date, 2).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
  const dateCovEndPlusOne = addDaysAndOneYear(date, 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  const timeRef = 16

  function addDaysAndOneYearAfterTimeRef(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
      copy.setDate(date.getDate() + days)
      copy.setYear(date.getFullYear() + 1)
      return copy
    } else {
      copy.setYear(date.getFullYear() + 1)
      return copy
    }
  }

  const dateFinalForAll = addDaysAndOneYearAfterTimeRef(date, 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  // Holiday One Day
  if (todayDate.isSame(selectDate)) {
    return dateCovEndPlusFour
  }

  // Holiday 13 14 15
  if
    (todayDate.isSame(selectDateForSixteenthAprilOne)) {
    return dateCovEndSixteenthApril
  }

  // Saturday to Monday
  if
    (todayDate.isSame(selectDateForSixteenthAprilTwo)) {
    return dateCovEndSixteenthApril
  }

  if
    (todayDate.isSame(selectDateForSixteenthAprilThree)) {
    return dateCovEndSixteenthApril
  }

  if
    (todayDate.isSame(selectDateForSixteenthAprilFour)) {
    return dateCovEndSixteenthApril
  }

  // Friday to Monday
  if (currentDay === 5) {
    return dateFinalForAll
  }

  // Saturday to Monday
  if (currentDay === 6) {
    return dateFinalForAll
  }

  // Everyday Plus One (Sunday to Monday)
  return dateFinalForAll
}

const Occupation = [
  {
    Code: 'OCC000025',
    Occupation: 'Computer Operator'
  },
  {
    Code: 'OCC000013',
    Occupation: 'Consultant'
  },
  {
    Code: 'OCC000043',
    Occupation: 'Land Agent'
  },
  {
    Code: 'OCC000010',
    Occupation: 'Supervisor/หัวหน้าหน่วย'
  },
  {
    Code: 'OCC000186',
    Occupation: 'กรรมการ'
  },
  {
    Code: 'OCC000031',
    Occupation: 'ข้าราชการ'
  },
  {
    Code: 'OCC000032',
    Occupation: 'ข้าราชการรัฐวิสาหกิจ'
  },
  {
    Code: 'OCC000028',
    Occupation: 'ครูอาจารย์'
  },
  {
    Code: 'OCC000180',
    Occupation: 'คอมพิวเตอร์'
  },
  {
    Code: 'OCC000068',
    Occupation: 'แคชเชียร์'
  },
  {
    Code: 'OCC000053',
    Occupation: 'เจ้าของกิจการ'
  },
  {
    Code: 'OCC000188',
    Occupation: 'เจ้าของธุรกิจสินค้าออนไลน์'
  },
  {
    Code: 'OCC000055',
    Occupation: 'เจ้าของร้าน'
  },
  {
    Code: 'OCC000063',
    Occupation: 'ช่างถ่ายภาพยนตร์'
  },
  {
    Code: 'OCC000059',
    Occupation: 'ช่างทำขนม'
  },
  {
    Code: 'OCC000135',
    Occupation: 'ช่างเทคนิค'
  },
  {
    Code: 'OCC000057',
    Occupation: 'ช่างเสริมสวย'
  },
  {
    Code: 'OCC000183',
    Occupation: 'ชาวนา'
  },
  {
    Code: 'OCC000102',
    Occupation: 'ชาวนา-ชาวสวน เกษตรกร'
  },
  {
    Code: 'OCC000178',
    Occupation: 'ชาวไร่'
  },
  {
    Code: 'OCC000184',
    Occupation: 'ชาวสวน'
  },
  {
    Code: 'OCC000017',
    Occupation: 'ทนายความ'
  },
  {
    Code: 'OCC000181',
    Occupation: 'ที่ปรึกษา'
  },
  {
    Code: 'OCC000182',
    Occupation: 'ที่ปรึกษากฎหมาย'
  },
  {
    Code: 'OCC000016',
    Occupation: 'นักกฏหมาย'
  },
  {
    Code: 'OCC000027',
    Occupation: 'นักธุรกิจ'
  },
  {
    Code: 'OCC000020',
    Occupation: 'นักบัญชี'
  },
  {
    Code: 'OCC000064',
    Occupation: 'นักเรียน/นักศึกษา'
  },
  {
    Code: 'OCC000189',
    Occupation: 'โปรแกรมเมอร์'
  },
  {
    Code: 'OCC000007',
    Occupation: 'ผู้จัดการ'
  },
  {
    Code: 'OCC000009',
    Occupation: 'ผู้ช่วยผู้จัดการ'
  },
  {
    Code: 'OCC000179',
    Occupation: 'ผู้ช่วยผู้อำนวยการ'
  },
  {
    Code: 'OCC000011',
    Occupation: 'ผู้ช่วยหัวหน้าหน่วย'
  },
  {
    Code: 'OCC000060',
    Occupation: 'ผู้สื่อข่าว'
  },
  {
    Code: 'OCC000026',
    Occupation: 'พนักงาน'
  },
  {
    Code: 'OCC000071',
    Occupation: 'พนักงานขาย'
  },
  {
    Code: 'OCC000050',
    Occupation: 'พยาบาล (ยกเว้นโรงพยาบาลโรคจิต หรือโรงพยาบาลสัตว์)'
  },
  {
    Code: 'OCC000187',
    Occupation: 'พระสงฆ์'
  },
  {
    Code: 'OCC000113',
    Occupation: 'พ่อครัว,แม่ครัว'
  },
  {
    Code: 'OCC000112',
    Occupation: 'พ่อค้าในตลาดสด (ไม่เกี่ยวกับการฆ่าสัตว์)'
  },
  {
    Code: 'OCC000045',
    Occupation: 'แพทย์'
  },
  {
    Code: 'OCC000073',
    Occupation: 'มัคคุเทศก์/Guide'
  },
  {
    Code: 'OCC000185',
    Occupation: 'มัณฑนากร'
  },
  {
    Code: 'OCC000190',
    Occupation: 'แม่ค้า/พ่อค้าในตลาดนัด'
  },
  {
    Code: 'OCC000008',
    Occupation: 'รองผู้จัดการ'
  },
  {
    Code: 'OCC000105',
    Occupation: 'รับจ้าง'
  },
  {
    Code: 'OCC000104',
    Occupation: 'ลูกจ้าง'
  },
  {
    Code: 'OCC000012',
    Occupation: 'เลขานุการ'
  },
  {
    Code: 'OCC000070',
    Occupation: 'ศิลปิน'
  },
  {
    Code: 'OCC000040',
    Occupation: 'สถาปนิก'
  },
  {
    Code: 'OCC000114',
    Occupation: 'อื่นๆ'
  }
]

const planRenewList = [{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-250",
  PLAN_DESC: "แผน 1 (เกินคุ้ม) 199 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 50,000 บาท (แผนต่ออายุ)",
  PLAN_NET_PREMIUM_AMT: "184.98",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "13.02",
  PLAN_TOTAL_PREMIUM: "199",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-450",
  PLAN_DESC: "แผน 2 (สุดคุ้ม) 399 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 100,000 บาท (แผนต่ออายุ)",
  PLAN_NET_PREMIUM_AMT: "370.90",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "26.10",
  PLAN_TOTAL_PREMIUM: "399",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "AII",
  PLAN_ISSUER: "เอเชียประกันภัย",
  PLAN_ID: "ASIA-500",
  PLAN_DESC: "เอเชียประกันภัยแผน 500 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "ตรวจพบ รับ 50,000 บาท โคม่า 500,000 บาท ชดเชย 1000 บาทต่อวัน",
  PLAN_NET_PREMIUM_AMT: "465.29",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "32.71",
  PLAN_TOTAL_PREMIUM: "500",
  PRODUCT_GROUP: "4-09",
  PRODUCT_POLICY: "10-44",
  PRODUCT_PACKAGE: "COVIDHIB2",
  BROKER_CODE: "AA11139"
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-250-N2",
  PLAN_DESC: "ทิพยประกันภัยแผน 200 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "ค่ารักษา 50,000 บาท โคม่า 100,000 บาท",
  PLAN_NET_PREMIUM_AMT: "185.92",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "13.08",
  PLAN_TOTAL_PREMIUM: "200",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-450-N3",
  PLAN_DESC: "ทิพยประกันภัยแผน 360 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "ค่ารักษา 50,000 บาท โคม่า 500,000 บาท",
  PLAN_NET_PREMIUM_AMT: "334.45",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "23.55",
  PLAN_TOTAL_PREMIUM: "360",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-850-N4",
  PLAN_DESC: "ทิพยประกันภัยแผน 680 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "ค่ารักษา 100,000 บาท โคม่า 1,000,000 บาท",
  PLAN_NET_PREMIUM_AMT: "632.51",
  PLAN_STAMP_DUTY_AMT: "3",
  PLAN_VAT_AMT: "44.49",
  PLAN_TOTAL_PREMIUM: "680",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "VIB",
  PLAN_ISSUER: "วิริยะประกันภัย",
  PLAN_ID: "VIB-SHIELD-299",
  PLAN_DESC: "Covid-19 Shield 1 แผน 299 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 30,000 บาท ชดเชย 300 บาท/ต่อวัน ไม่เกิน 4,200 บาท และอุบัติเหตุ (อ.บ.1) 100,000 บาท",
  PLAN_NET_PREMIUM_AMT: "297.00",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "299",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "VIB",
  PLAN_ISSUER: "วิริยะประกันภัย",
  PLAN_ID: "VIB-SHIELD-389",
  PLAN_DESC: "Covid-19 Shield 2 แผน 389 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 50,000 บาท ชดเชย 300 บาท/ต่อวัน ไม่เกิน 4,200 บาท และอุบัติเหตุ (อ.บ.1) 100,000 บาท",
  PLAN_NET_PREMIUM_AMT: "387.00",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "389",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "VIB",
  PLAN_ISSUER: "วิริยะประกันภัย",
  PLAN_ID: "VIB-SHIELD-599",
  PLAN_DESC: "Covid-19 Shield 3 แผน 599 บาท (ต่ออายุ)",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 100,000 บาท ชดเชย 300 บาท/ต่อวัน ไม่เกิน 4,200 บาท และอุบัติเหตุ (อ.บ.1) 100,000 บาท",
  PLAN_NET_PREMIUM_AMT: "596.00",
  PLAN_STAMP_DUTY_AMT: "3",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "599",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
}]

const CovStartDateZero = () => {

  function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

  const date = new Date();

  const datePlusZero = addDays(date, 0).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  return datePlusZero
}

const CovEndDateZero = () => {


  function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    copy.setYear(date.getFullYear() + 1)
    return copy
  }

  const date = new Date();
  const datePlusZero = addDays(date, 0).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

  return datePlusZero
}

const CovStartDateEngZero = () => {

  function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }

  const date = new Date();

  const datePlusZero = addDays(date, 0).toLocaleDateString('en-GB')

  return datePlusZero
}

const CovEndDateEngZero = () => {


  function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    copy.setYear(date.getFullYear() + 1)
    return copy
  }

  const date = new Date();
  const datePlusZero = addDays(date, 0).toLocaleDateString('en-GB')

  return datePlusZero
}


const HealthplanList = [{
  PLAN_ISSUER_CODE: "NKI",
  PLAN_ISSUER: "นวกิจประกันภัย",
  PLAN_ID: "NKI-VAC-199",
  PLAN_DESC: "แพ้วัคซีนโควิด-19 แผน 1 ราคา 199 บาท",
  PLAN_COV_TITLE: "โคม่า 100,000 บาท ค่ารักษาผู้ป่วยใน 20,000 บาท ค่าชดเชยปลอบขวัญ (IPD) ไม่น้อยกว่า 5 วัน 7,000 บาท",
  PLAN_NET_PREMIUM_AMT: "184.98",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "13.02",
  PLAN_TOTAL_PREMIUM: "199",
  PLAN_PRODUCT_TYPE: "HS0",
  PRODUCT_GROUP: "NKI-VAC",
  PRODUCT_GROUP_DESC: "ประกันแพ้วัคซีนโควิด-19",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "NKI",
  PLAN_ISSUER: "นวกิจประกันภัย",
  PLAN_ID: "NKI-VAC-399",
  PLAN_DESC: "แพ้วัคซีนโควิด-19 แผน 1 ราคา 399",
  PLAN_COV_TITLE: "โคม่า 200,000 บาท ค่ารักษาผู้ป่วยใน 40,000 บาท ค่าชดเชยปลอบขวัญ (IPD) ไม่น้อยกว่า 5 วัน 14,000 บาท",
  PLAN_NET_PREMIUM_AMT: "370.90",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "26.10",
  PLAN_TOTAL_PREMIUM: "399",
  PLAN_PRODUCT_TYPE: "HS0",
  PRODUCT_GROUP: "NKI-VAC",
  PRODUCT_GROUP_DESC: "ประกันแพ้วัคซีนโควิด-19",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
}]


const buildingType = [
  {
    BUILDING_CODE: "B1",
    BUILDING_TYPE: "อาคารบ้านไม้ 1 ชั้น (บ้านเดี่ยว)",
    LOW_PRICE: "8381",
    MID_PRICE: "9843",
    HIGH_PRICE: "12036",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B2",
    BUILDING_TYPE: "อาคารครึ่งตึกครึ่งไม้ 2 ชั้น (บ้านเดี่ยว)",
    LOW_PRICE: "8326",
    MID_PRICE: "10869",
    HIGH_PRICE: "14418",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B3",
    BUILDING_TYPE: "อาคารบ้านพักอาศัย 1 ชั้น (บ้านเดี่ยว  ค.ส.ล.)",
    LOW_PRICE: "9186",
    MID_PRICE: "10589",
    HIGH_PRICE: "13042",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B4",
    BUILDING_TYPE: "อาคารบ้านพักอาศัย 2 ชั้น (บ้านเดี่ยว  ค.ส.ล.)",
    LOW_PRICE: "8094",
    MID_PRICE: "12052",
    HIGH_PRICE: "15163",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B5",
    BUILDING_TYPE: "อาคารบ้านพักอาศัย 3 ชั้น (บ้านเดี่ยว  ค.ส.ล.)",
    LOW_PRICE: "7665",
    MID_PRICE: "11519",
    HIGH_PRICE: "14246",
    BUILDING_LEVEL: "3",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B6",
    BUILDING_TYPE: "อาคารบ้านพักใต้ถุนสูง (บ้านเดี่ยว  ค.ส.ล.)",
    LOW_PRICE: "8407",
    MID_PRICE: "12011",
    HIGH_PRICE: "14246",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B7",
    BUILDING_TYPE: "อาคารบ้านไม้ 1 ชั้น  (บ้านสำเร็จรูป)",
    LOW_PRICE: "14156",
    MID_PRICE: "17517",
    HIGH_PRICE: "19228",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B8",
    BUILDING_TYPE: "อาคารบ้านไม้ 2 ชั้น  (บ้านสำเร็จรูป)",
    LOW_PRICE: "11868",
    MID_PRICE: "16955",
    HIGH_PRICE: "18400",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B9",
    BUILDING_TYPE: "อาคารบ้านไม้ใต้ถุนสูง (บ้านสำเร็จรูป)",
    LOW_PRICE: "12731",
    MID_PRICE: "18197",
    HIGH_PRICE: "19974",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B10",
    BUILDING_TYPE: "อาคารบ้านพักอาศัย 1 ชั้น (บ้านสำเร็จรูป  ค.ส.ล.)",
    LOW_PRICE: "9319",
    MID_PRICE: "11734",
    HIGH_PRICE: "13003",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B11",
    BUILDING_TYPE: "อาคารพักอาศัย 4-5  ชั้น (อพาร์ทเมนต์)",
    LOW_PRICE: "8011",
    MID_PRICE: "10431",
    HIGH_PRICE: "11838",
    BUILDING_LEVEL: "4",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B12",
    BUILDING_TYPE: "อาคารพักอาศัย 6-9  ชั้น (อพาร์ทเมนต์)",
    LOW_PRICE: "8329",
    MID_PRICE: "12467",
    HIGH_PRICE: "14945",
    BUILDING_LEVEL: "6",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B13",
    BUILDING_TYPE: "อาคารบ้านแฝดชั้นเดียว",
    LOW_PRICE: "8092",
    MID_PRICE: "10055",
    HIGH_PRICE: "11282",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B14",
    BUILDING_TYPE: "อาคารบ้านแฝด 2 ชั้นขึ้นไป",
    LOW_PRICE: "7737",
    MID_PRICE: "8857",
    HIGH_PRICE: "9985",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B15",
    BUILDING_TYPE: "อาคารห้องแถวครึ่งตึกครึ่งไม้ 2 ชั้น",
    LOW_PRICE: "4902",
    MID_PRICE: "5882",
    HIGH_PRICE: "7058",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B16",
    BUILDING_TYPE: "ทาวเฮ้าส์ 1 ชั้น",
    LOW_PRICE: "7954",
    MID_PRICE: "9202",
    HIGH_PRICE: "10338",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B17",
    BUILDING_TYPE: "ทาวเฮ้าส์ 2 ชั้น",
    LOW_PRICE: "7954",
    MID_PRICE: "8880",
    HIGH_PRICE: "9822",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B18",
    BUILDING_TYPE: "ทาวเฮ้าส์ 3 ชั้น",
    LOW_PRICE: "7324",
    MID_PRICE: "8758",
    HIGH_PRICE: "10205",
    BUILDING_LEVEL: "3",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B19",
    BUILDING_TYPE: "ทาวเฮ้าส์ 4 ชั้น",
    LOW_PRICE: "5531",
    MID_PRICE: "7011",
    HIGH_PRICE: "7979",
    BUILDING_LEVEL: "4",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B20",
    BUILDING_TYPE: "ทาวเฮ้าส์ 5 ชั้น",
    LOW_PRICE: "6474",
    MID_PRICE: "9933",
    HIGH_PRICE: "11089",
    BUILDING_LEVEL: "5",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B21",
    BUILDING_TYPE: "อาคารพาณิชย์ 2 ชั้น",
    LOW_PRICE: "6462",
    MID_PRICE: "7920",
    HIGH_PRICE: "11089",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B22",
    BUILDING_TYPE: "อาคารพาณิชย์ 3 ชั้น",
    LOW_PRICE: "6016",
    MID_PRICE: "7485",
    HIGH_PRICE: "9081",
    BUILDING_LEVEL: "3",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B23",
    BUILDING_TYPE: "อาคารพาณิชย์ 4 ชั้น",
    LOW_PRICE: "5502",
    MID_PRICE: "6710",
    HIGH_PRICE: "8052",
    BUILDING_LEVEL: "4",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B24",
    BUILDING_TYPE: "อาคารพาณิชย์ 5 ชั้น",
    LOW_PRICE: "5902",
    MID_PRICE: "7340",
    HIGH_PRICE: "8221",
    BUILDING_LEVEL: "5",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B25",
    BUILDING_TYPE: "อาคารสำนักงาน 2-3 ชั้น",
    LOW_PRICE: "8726",
    MID_PRICE: "11335",
    HIGH_PRICE: "14623",
    BUILDING_LEVEL: "2",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B26",
    BUILDING_TYPE: "อาคารสำนักงาน 4-5 ชั้น",
    LOW_PRICE: "8536",
    MID_PRICE: "11217",
    HIGH_PRICE: "14623",
    BUILDING_LEVEL: "4",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B27",
    BUILDING_TYPE: "อาคารสำนักงาน 6-9 ชั้น",
    LOW_PRICE: "8342",
    MID_PRICE: "10773",
    HIGH_PRICE: "14163",
    BUILDING_LEVEL: "6",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B28",
    BUILDING_TYPE: "อาคารสำนักงาน 10-20 ชั้น",
    LOW_PRICE: "18396",
    MID_PRICE: "24164",
    HIGH_PRICE: "29934",
    BUILDING_LEVEL: "10",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B29",
    BUILDING_TYPE: "อาคารสำนักงาน 21-35 ชั้น",
    LOW_PRICE: "18396",
    MID_PRICE: "24164",
    HIGH_PRICE: "29934",
    BUILDING_LEVEL: "21",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B30",
    BUILDING_TYPE: "อาคารคอนโดมิเนียม ขนาดใหญ่ พื้นที่ 10,000  ตรม. (อาคารชุด) ตั้งแต่ 10 ชั้นขึ้นไป",
    LOW_PRICE: "15887",
    MID_PRICE: "20320",
    HIGH_PRICE: "24753",
    BUILDING_LEVEL: "10",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B31",
    BUILDING_TYPE: "อาคารที่จอดรถ 1-2 ชั้นบนดิน",
    LOW_PRICE: "7269",
    MID_PRICE: "7809",
    HIGH_PRICE: "8296",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B32",
    BUILDING_TYPE: "ห้างสรรพสินค้า 1-3 ชั้น",
    LOW_PRICE: "11480",
    MID_PRICE: "12253",
    HIGH_PRICE: "14376",
    BUILDING_LEVEL: "1",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B33",
    BUILDING_TYPE: "ห้างสรรพสินค้า สูงเกิน 3 ชั้น",
    LOW_PRICE: "11840",
    MID_PRICE: "13243",
    HIGH_PRICE: "17042",
    BUILDING_LEVEL: "3",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B34",
    BUILDING_TYPE: "อาคารโรงงานอุตสาหกรรมขนาดย่อม",
    LOW_PRICE: "5903",
    MID_PRICE: "7125",
    HIGH_PRICE: "8435",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B35",
    BUILDING_TYPE: "อาคารโรงงานอุตสาหกรรมขนาดใหญ่ พื้นที่ 10,000  ตรม. ขึ้นไป",
    LOW_PRICE: "8725",
    MID_PRICE: "10063",
    HIGH_PRICE: "12283",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "N"
  },
  {
    BUILDING_CODE: "B36",
    BUILDING_TYPE: "อาคารโรงสีเก่า",
    LOW_PRICE: "2314",
    MID_PRICE: "2825",
    HIGH_PRICE: "3092",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B37",
    BUILDING_TYPE: "อาคารโรงสีใหม่",
    LOW_PRICE: "4045",
    MID_PRICE: "5663",
    HIGH_PRICE: "8501",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B38",
    BUILDING_TYPE: "อาคารโกดังเก็บของ (ช่วงเสาไม่เกิน 6 ม.)",
    LOW_PRICE: "3614",
    MID_PRICE: "4155",
    HIGH_PRICE: "4775",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "Y"
  },
  {
    BUILDING_CODE: "B39",
    BUILDING_TYPE: "อาคารโกดังเก็บของ (ช่วงเสาเกินกว่า 6 ม.)",
    LOW_PRICE: "4583",
    MID_PRICE: "6332",
    HIGH_PRICE: "7005",
    BUILDING_LEVEL: "",
    IS_SME_SABAI_OK: "Y"
  }
]

const businessAndIndustrySabuyPackage = [
  {
    BUSINESS_CODE: "SNB1",
    BUSINESS_DESC: "ร้านขายดอกไม้สดและเทียม",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB2",
    BUSINESS_DESC: "ร้านขายกล้องถ่ายรูป",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB3",
    BUSINESS_DESC: "ร้านขายเครื่องจักรกล",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB4",
    BUSINESS_DESC: "ร้านซุปเปอร์มาเก็ตขนาดเล็ก/มินิมาร์ททั่วไป ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB5",
    BUSINESS_DESC: "ร้านขายของชำ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB6",
    BUSINESS_DESC: "ร้านขายของชำร่วย",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB7",
    BUSINESS_DESC: "ร้านขายเครื่องเฟอร์นิเจอร์ตกแต่งบ้าน",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB8",
    BUSINESS_DESC: "ร้านขายเครื่องใช้ไฟฟ้า",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB9",
    BUSINESS_DESC: "ร้านขายเครื่องดนตรี",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB10",
    BUSINESS_DESC: "ร้านขายแบตเตอรี่รถยนต์ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB11",
    BUSINESS_DESC: "ร้านขายประดับยนต์",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB12",
    BUSINESS_DESC: "ร้านขายวัสดุอุปกรณ์ก่อสร้าง (ยกเว้นขายไม้)",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB13",
    BUSINESS_DESC: "ร้านขายเครื่องทำน้ำแข็ง",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB14",
    BUSINESS_DESC: "ร้านขายเครื่องทำความเย็น",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB15",
    BUSINESS_DESC: "ร้านขายของเด็กเล่น",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB16",
    BUSINESS_DESC: "ร้านขายเครื่องสุขภัณฑ์ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB17",
    BUSINESS_DESC: "ร้านขายเครื่องใช้พลาสติก ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB18",
    BUSINESS_DESC: "ร้านขายเครื่องหนัง รองเท้า ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB19",
    BUSINESS_DESC: "ร้านขายโคมไฟประดับบ้าน",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB20",
    BUSINESS_DESC: "ร้านขายผักและผลไม้  (ไม่รวมสต็อกผักและผลไม้)",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB21",
    BUSINESS_DESC: "ร้านขายเสื้อผ้า ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB22",
    BUSINESS_DESC: "ร้ายขายหนังสือ/หนังสือพิมพ์ (ไม่รวมสต็อกหนังสือ)",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB23",
    BUSINESS_DESC: "ร้านขายยา ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB24",
    BUSINESS_DESC: "ร้านค้าปลีก/ส่ง",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB25",
    BUSINESS_DESC: "ร้านขายแว่นตา ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB26",
    BUSINESS_DESC: "ร้านขายกระเป๋าถือ/กระเป๋าเดินทาง ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB27",
    BUSINESS_DESC: "ร้านขายอาหารสัตว์ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB28",
    BUSINESS_DESC: "ร้านขายอาหารแช่แข็ง ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB29",
    BUSINESS_DESC: "ร้านขายคอมพิวเตอร์",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB30",
    BUSINESS_DESC: "ร้านขายเกมส์",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB31",
    BUSINESS_DESC: "ร้านขายนาฬิกา ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB32",
    BUSINESS_DESC: "ร้านขายเครื่องมืออุปกรณ์โลหะภัณฑ์ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB33",
    BUSINESS_DESC: "ร้านขายจักรยาน/จักรยานยนต์ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB34",
    BUSINESS_DESC: "ร้านขายสังฆภัณฑ์ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB35",
    BUSINESS_DESC: "ร้านขายอุปกรณ์กีฬา ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB36",
    BUSINESS_DESC: "ร้านขายอุปกรณ์เครื่องเขียน ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB37",
    BUSINESS_DESC: "ร้านค้าในห้างสรรพสินค้า หรือศูนย์การค้า ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB38",
    BUSINESS_DESC: "ร้านถ่ายเอกสาร ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB39",
    BUSINESS_DESC: "ร้านเสริมสวย/ตัดผม ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB40",
    BUSINESS_DESC: "ร้านทำเล็บ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB41",
    BUSINESS_DESC: "สปา/ ร้านนวดแผนโบราณ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB42",
    BUSINESS_DESC: "โรงรับจำนำ (ไม่รวมสต็อก)",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB43",
    BUSINESS_DESC: "ร้านซ่อมและซ่อมแซมเสื้อผ้า ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB44",
    BUSINESS_DESC: "ร้านซักรีดหรือซักแห้ง ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB45",
    BUSINESS_DESC: "ร้านซ่อมเครื่องใช้ไฟฟ้า ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB46",
    BUSINESS_DESC: "ร้านถ่ายรูป/สตูดิโอถ่ายภาพ ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB47",
    BUSINESS_DESC: "ร้านทำป้ายโฆษณา",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SNB48",
    BUSINESS_DESC: "โรงแรม (อายุอาคารไม่เกิน 10 ปี) ",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    BUSINESS_CODE: "SER49",
    BUSINESS_DESC: "คลินิกเวชกรรม",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER50",
    BUSINESS_DESC: "คลินิกทันตกรรม ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER51",
    BUSINESS_DESC: "คลินิกเสริมความงาม ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER52",
    BUSINESS_DESC: "คลินิกรักษาสัตว์",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER53",
    BUSINESS_DESC: "อพาร์ทเม้นท์/หอพัก/บ้านเช่า ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER54",
    BUSINESS_DESC: "โรงเรียน สถานศึกษา ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER55",
    BUSINESS_DESC: "สถาบันกวดวิชา",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER56",
    BUSINESS_DESC: "สถาบันสอนดนตรี ศิลปะ ภาษา ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER57",
    BUSINESS_DESC: "สถาบันสอนกีฬา ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER58",
    BUSINESS_DESC: "บ้านพักตากอากาศ ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER59",
    BUSINESS_DESC: "สมาคมหรือสโมสร (ไม่รวมถึงไนต์คลับหรือคาบาเรท) ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER60",
    BUSINESS_DESC: "สำนักงานทั่วไป ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER61",
    BUSINESS_DESC: "สำนักงานบัญชี ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER62",
    BUSINESS_DESC: "สำนักงานทนายความ ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER63",
    BUSINESS_DESC: "สำนักงานจัดหางาน ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER64",
    BUSINESS_DESC: "สำนักงานตัวแทน/นายหน้า ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER65",
    BUSINESS_DESC: "สำนักงานขายตั๋ว ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER66",
    BUSINESS_DESC: "สำนักงานแปลเอกสารหรือบริการล่าม ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER67",
    BUSINESS_DESC: "สำนักงานรับทำโฆษณา ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER68",
    BUSINESS_DESC: "สำนักงานรับออกแบบบ้าน/อาคาร/ตกแต่งภายใน ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER69",
    BUSINESS_DESC: "มูลนิธิ ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER70",
    BUSINESS_DESC: "สถานรับเลี้ยงเด็ก ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "SER71",
    BUSINESS_DESC: "ฟิตเนสเซ็นเตอร์ ",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    BUSINESS_CODE: "FNB72",
    BUSINESS_DESC: "ร้านอาหาร",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    BUSINESS_CODE: "FNB73",
    BUSINESS_DESC: "ร้านขายเครื่องดื่ม ",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    BUSINESS_CODE: "FNB74",
    BUSINESS_DESC: "ร้านเบเกอรี่ ",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    BUSINESS_CODE: "FNB75",
    BUSINESS_DESC: "ร้านไอศครีม ",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    BUSINESS_CODE: "FNB76",
    BUSINESS_DESC: "ร้านขายขนมหวาน",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    BUSINESS_CODE: "FAC77",
    BUSINESS_DESC: "โรงพิมพ์",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC78",
    BUSINESS_DESC: "โรงงานทำอิฐบล๊อก กระเบื้อง",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC79",
    BUSINESS_DESC: "โรงงานทำสบู่ ",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC80",
    BUSINESS_DESC: "โรงงานทำลูกชิ้น และไส้กรอก",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC81",
    BUSINESS_DESC: "โรงงานทำเส้นหมี่",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC82",
    BUSINESS_DESC: "โรงงานทำซ๊อส น้ำปลา",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC83",
    BUSINESS_DESC: "โรงงานทำน้ำดื่ม",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC84",
    BUSINESS_DESC: "โกดังที่เก็บไม่เก็บสินค้าอันตราย (1006A)",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC85",
    BUSINESS_DESC: "โรงงานทำไอศครีม",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC86",
    BUSINESS_DESC: "โรงงานทำน้ำแข็ง",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC87",
    BUSINESS_DESC: "โรงงานทำนมและเนย",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC88",
    BUSINESS_DESC: "โรงงานทำซิปโลหะ",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC89",
    BUSINESS_DESC: "โรงงานทำขนมปัง",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC90",
    BUSINESS_DESC: "โรงงานทำขนมหวานและลูกกวาด",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    BUSINESS_CODE: "FAC91",
    BUSINESS_DESC: "โรงงานผลิตภัณฑ์จากโลหะ และอลูมิเนียม",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  }
]

const premiumSabuyPackage = [
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "0",
    TOTAL_SUMINS_MAX: "500000",
    PLAN_NET_PREMIUM_AMT: "2131.19",
    PLAN_STAMP_DUTY_AMT: "9.00",
    PLAN_VAT_AMT: "149.81",
    PLAN_TOTAL_PREMIUM: "2290.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "100000",
    TOTAL_SUMINS_MAX: "600000",
    PLAN_NET_PREMIUM_AMT: "2303.08",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "161.92",
    PLAN_TOTAL_PREMIUM: "2475.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "600001",
    TOTAL_SUMINS_MAX: "700000",
    PLAN_NET_PREMIUM_AMT: "2475.98",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "174.02",
    PLAN_TOTAL_PREMIUM: "2660.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "700001",
    TOTAL_SUMINS_MAX: "800000",
    PLAN_NET_PREMIUM_AMT: "2647.88",
    PLAN_STAMP_DUTY_AMT: "11.00",
    PLAN_VAT_AMT: "186.12",
    PLAN_TOTAL_PREMIUM: "2845.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "800001",
    TOTAL_SUMINS_MAX: "900000",
    PLAN_NET_PREMIUM_AMT: "2819.78",
    PLAN_STAMP_DUTY_AMT: "12.00",
    PLAN_VAT_AMT: "198.22",
    PLAN_TOTAL_PREMIUM: "3030.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "900001",
    TOTAL_SUMINS_MAX: "1000000",
    PLAN_NET_PREMIUM_AMT: "2992.67",
    PLAN_STAMP_DUTY_AMT: "12.00",
    PLAN_VAT_AMT: "210.33",
    PLAN_TOTAL_PREMIUM: "3215.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1000001",
    TOTAL_SUMINS_MAX: "1100000",
    PLAN_NET_PREMIUM_AMT: "3164.57",
    PLAN_STAMP_DUTY_AMT: "13.00",
    PLAN_VAT_AMT: "222.43",
    PLAN_TOTAL_PREMIUM: "3400.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1100001",
    TOTAL_SUMINS_MAX: "1200000",
    PLAN_NET_PREMIUM_AMT: "3336.47",
    PLAN_STAMP_DUTY_AMT: "14.00",
    PLAN_VAT_AMT: "234.53",
    PLAN_TOTAL_PREMIUM: "3585.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1200001",
    TOTAL_SUMINS_MAX: "1300000",
    PLAN_NET_PREMIUM_AMT: "3508.36",
    PLAN_STAMP_DUTY_AMT: "15.00",
    PLAN_VAT_AMT: "246.64",
    PLAN_TOTAL_PREMIUM: "3770.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1300001",
    TOTAL_SUMINS_MAX: "1400000",
    PLAN_NET_PREMIUM_AMT: "3681.26",
    PLAN_STAMP_DUTY_AMT: "15.00",
    PLAN_VAT_AMT: "258.74",
    PLAN_TOTAL_PREMIUM: "3955.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1400001",
    TOTAL_SUMINS_MAX: "1500000",
    PLAN_NET_PREMIUM_AMT: "3853.16",
    PLAN_STAMP_DUTY_AMT: "16.00",
    PLAN_VAT_AMT: "270.84",
    PLAN_TOTAL_PREMIUM: "4140.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1500001",
    TOTAL_SUMINS_MAX: "1600000",
    PLAN_NET_PREMIUM_AMT: "4025.06",
    PLAN_STAMP_DUTY_AMT: "17.00",
    PLAN_VAT_AMT: "282.94",
    PLAN_TOTAL_PREMIUM: "4325.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1600001",
    TOTAL_SUMINS_MAX: "1700000",
    PLAN_NET_PREMIUM_AMT: "4197.95",
    PLAN_STAMP_DUTY_AMT: "17.00",
    PLAN_VAT_AMT: "295.05",
    PLAN_TOTAL_PREMIUM: "4510.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1700001",
    TOTAL_SUMINS_MAX: "1800000",
    PLAN_NET_PREMIUM_AMT: "4369.85",
    PLAN_STAMP_DUTY_AMT: "18.00",
    PLAN_VAT_AMT: "307.15",
    PLAN_TOTAL_PREMIUM: "4695.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1800001",
    TOTAL_SUMINS_MAX: "1900000",
    PLAN_NET_PREMIUM_AMT: "4541.75",
    PLAN_STAMP_DUTY_AMT: "19.00",
    PLAN_VAT_AMT: "319.25",
    PLAN_TOTAL_PREMIUM: "4880.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1900001",
    TOTAL_SUMINS_MAX: "2000000",
    PLAN_NET_PREMIUM_AMT: "4714.64",
    PLAN_STAMP_DUTY_AMT: "19.00",
    PLAN_VAT_AMT: "331.36",
    PLAN_TOTAL_PREMIUM: "5065.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2000001",
    TOTAL_SUMINS_MAX: "2100000",
    PLAN_NET_PREMIUM_AMT: "4886.54",
    PLAN_STAMP_DUTY_AMT: "20.00",
    PLAN_VAT_AMT: "343.46",
    PLAN_TOTAL_PREMIUM: "5250.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2100001",
    TOTAL_SUMINS_MAX: "2200000",
    PLAN_NET_PREMIUM_AMT: "5058.44",
    PLAN_STAMP_DUTY_AMT: "21.00",
    PLAN_VAT_AMT: "355.56",
    PLAN_TOTAL_PREMIUM: "5435.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2200001",
    TOTAL_SUMINS_MAX: "2300000",
    PLAN_NET_PREMIUM_AMT: "5231.34",
    PLAN_STAMP_DUTY_AMT: "21.00",
    PLAN_VAT_AMT: "367.66",
    PLAN_TOTAL_PREMIUM: "5620.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2300001",
    TOTAL_SUMINS_MAX: "2400000",
    PLAN_NET_PREMIUM_AMT: "5403.23",
    PLAN_STAMP_DUTY_AMT: "22.00",
    PLAN_VAT_AMT: "379.77",
    PLAN_TOTAL_PREMIUM: "5805.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2400001",
    TOTAL_SUMINS_MAX: "2500000",
    PLAN_NET_PREMIUM_AMT: "5575.13",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "391.87",
    PLAN_TOTAL_PREMIUM: "5990.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2500001",
    TOTAL_SUMINS_MAX: "2600000",
    PLAN_NET_PREMIUM_AMT: "5748.03",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "403.97",
    PLAN_TOTAL_PREMIUM: "6175.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2600001",
    TOTAL_SUMINS_MAX: "2700000",
    PLAN_NET_PREMIUM_AMT: "5919.92",
    PLAN_STAMP_DUTY_AMT: "24.00",
    PLAN_VAT_AMT: "416.08",
    PLAN_TOTAL_PREMIUM: "6360.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2700001",
    TOTAL_SUMINS_MAX: "2800000",
    PLAN_NET_PREMIUM_AMT: "6091.82",
    PLAN_STAMP_DUTY_AMT: "25.00",
    PLAN_VAT_AMT: "428.18",
    PLAN_TOTAL_PREMIUM: "6545.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2800001",
    TOTAL_SUMINS_MAX: "2900000",
    PLAN_NET_PREMIUM_AMT: "6263.72",
    PLAN_STAMP_DUTY_AMT: "26.00",
    PLAN_VAT_AMT: "440.28",
    PLAN_TOTAL_PREMIUM: "6730.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2900001",
    TOTAL_SUMINS_MAX: "3000000",
    PLAN_NET_PREMIUM_AMT: "6436.62",
    PLAN_STAMP_DUTY_AMT: "26.00",
    PLAN_VAT_AMT: "452.38",
    PLAN_TOTAL_PREMIUM: "6915.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3000001",
    TOTAL_SUMINS_MAX: "3100000",
    PLAN_NET_PREMIUM_AMT: "6608.51",
    PLAN_STAMP_DUTY_AMT: "27.00",
    PLAN_VAT_AMT: "464.49",
    PLAN_TOTAL_PREMIUM: "7100.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3100001",
    TOTAL_SUMINS_MAX: "3200000",
    PLAN_NET_PREMIUM_AMT: "6780.41",
    PLAN_STAMP_DUTY_AMT: "28.00",
    PLAN_VAT_AMT: "476.59",
    PLAN_TOTAL_PREMIUM: "7285.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3200001",
    TOTAL_SUMINS_MAX: "3300000",
    PLAN_NET_PREMIUM_AMT: "6953.31",
    PLAN_STAMP_DUTY_AMT: "28.00",
    PLAN_VAT_AMT: "488.69",
    PLAN_TOTAL_PREMIUM: "7470.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3300001",
    TOTAL_SUMINS_MAX: "3400000",
    PLAN_NET_PREMIUM_AMT: "7125.21",
    PLAN_STAMP_DUTY_AMT: "29.00",
    PLAN_VAT_AMT: "500.79",
    PLAN_TOTAL_PREMIUM: "7655.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3400001",
    TOTAL_SUMINS_MAX: "3500000",
    PLAN_NET_PREMIUM_AMT: "7297.10",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "512.90",
    PLAN_TOTAL_PREMIUM: "7840.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3500001",
    TOTAL_SUMINS_MAX: "3600000",
    PLAN_NET_PREMIUM_AMT: "7470.00",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "525.00",
    PLAN_TOTAL_PREMIUM: "8025.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3600001",
    TOTAL_SUMINS_MAX: "3700000",
    PLAN_NET_PREMIUM_AMT: "7641.90",
    PLAN_STAMP_DUTY_AMT: "31.00",
    PLAN_VAT_AMT: "537.10",
    PLAN_TOTAL_PREMIUM: "8210.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3700001",
    TOTAL_SUMINS_MAX: "3800000",
    PLAN_NET_PREMIUM_AMT: "7813.79",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "549.21",
    PLAN_TOTAL_PREMIUM: "8395.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3800001",
    TOTAL_SUMINS_MAX: "3900000",
    PLAN_NET_PREMIUM_AMT: "7986.69",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "561.31",
    PLAN_TOTAL_PREMIUM: "8580.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3900001",
    TOTAL_SUMINS_MAX: "4000000",
    PLAN_NET_PREMIUM_AMT: "8158.59",
    PLAN_STAMP_DUTY_AMT: "33.00",
    PLAN_VAT_AMT: "573.41",
    PLAN_TOTAL_PREMIUM: "8765.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4000001",
    TOTAL_SUMINS_MAX: "4100000",
    PLAN_NET_PREMIUM_AMT: "8330.49",
    PLAN_STAMP_DUTY_AMT: "34.00",
    PLAN_VAT_AMT: "585.51",
    PLAN_TOTAL_PREMIUM: "8950.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4100001",
    TOTAL_SUMINS_MAX: "4200000",
    PLAN_NET_PREMIUM_AMT: "8502.38",
    PLAN_STAMP_DUTY_AMT: "35.00",
    PLAN_VAT_AMT: "597.62",
    PLAN_TOTAL_PREMIUM: "9135.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4200001",
    TOTAL_SUMINS_MAX: "4300000",
    PLAN_NET_PREMIUM_AMT: "8675.28",
    PLAN_STAMP_DUTY_AMT: "35.00",
    PLAN_VAT_AMT: "609.72",
    PLAN_TOTAL_PREMIUM: "9320.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4300001",
    TOTAL_SUMINS_MAX: "4400000",
    PLAN_NET_PREMIUM_AMT: "8847.18",
    PLAN_STAMP_DUTY_AMT: "36.00",
    PLAN_VAT_AMT: "621.82",
    PLAN_TOTAL_PREMIUM: "9505.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4400001",
    TOTAL_SUMINS_MAX: "4500000",
    PLAN_NET_PREMIUM_AMT: "9019.07",
    PLAN_STAMP_DUTY_AMT: "37.00",
    PLAN_VAT_AMT: "633.93",
    PLAN_TOTAL_PREMIUM: "9690.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4500001",
    TOTAL_SUMINS_MAX: "4600000",
    PLAN_NET_PREMIUM_AMT: "9191.97",
    PLAN_STAMP_DUTY_AMT: "37.00",
    PLAN_VAT_AMT: "646.03",
    PLAN_TOTAL_PREMIUM: "9875.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4600001",
    TOTAL_SUMINS_MAX: "4700000",
    PLAN_NET_PREMIUM_AMT: "9363.87",
    PLAN_STAMP_DUTY_AMT: "38.00",
    PLAN_VAT_AMT: "658.13",
    PLAN_TOTAL_PREMIUM: "10060.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4700001",
    TOTAL_SUMINS_MAX: "4800000",
    PLAN_NET_PREMIUM_AMT: "9535.77",
    PLAN_STAMP_DUTY_AMT: "39.00",
    PLAN_VAT_AMT: "670.23",
    PLAN_TOTAL_PREMIUM: "10245.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4800001",
    TOTAL_SUMINS_MAX: "4900000",
    PLAN_NET_PREMIUM_AMT: "9708.66",
    PLAN_STAMP_DUTY_AMT: "39.00",
    PLAN_VAT_AMT: "682.34",
    PLAN_TOTAL_PREMIUM: "10430.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4900001",
    TOTAL_SUMINS_MAX: "5000000",
    PLAN_NET_PREMIUM_AMT: "9880.56",
    PLAN_STAMP_DUTY_AMT: "40.00",
    PLAN_VAT_AMT: "694.44",
    PLAN_TOTAL_PREMIUM: "10615.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5000001",
    TOTAL_SUMINS_MAX: "5100000",
    PLAN_NET_PREMIUM_AMT: "10052.46",
    PLAN_STAMP_DUTY_AMT: "41.00",
    PLAN_VAT_AMT: "706.54",
    PLAN_TOTAL_PREMIUM: "10800.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5100001",
    TOTAL_SUMINS_MAX: "5200000",
    PLAN_NET_PREMIUM_AMT: "10225.35",
    PLAN_STAMP_DUTY_AMT: "41.00",
    PLAN_VAT_AMT: "718.65",
    PLAN_TOTAL_PREMIUM: "10985.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5200001",
    TOTAL_SUMINS_MAX: "5300000",
    PLAN_NET_PREMIUM_AMT: "10397.25",
    PLAN_STAMP_DUTY_AMT: "42.00",
    PLAN_VAT_AMT: "730.75",
    PLAN_TOTAL_PREMIUM: "11170.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5300001",
    TOTAL_SUMINS_MAX: "5400000",
    PLAN_NET_PREMIUM_AMT: "10569.15",
    PLAN_STAMP_DUTY_AMT: "43.00",
    PLAN_VAT_AMT: "742.85",
    PLAN_TOTAL_PREMIUM: "11355.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5400001",
    TOTAL_SUMINS_MAX: "5500000",
    PLAN_NET_PREMIUM_AMT: "10742.05",
    PLAN_STAMP_DUTY_AMT: "43.00",
    PLAN_VAT_AMT: "754.95",
    PLAN_TOTAL_PREMIUM: "11540.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5500001",
    TOTAL_SUMINS_MAX: "5600000",
    PLAN_NET_PREMIUM_AMT: "10913.94",
    PLAN_STAMP_DUTY_AMT: "44.00",
    PLAN_VAT_AMT: "767.06",
    PLAN_TOTAL_PREMIUM: "11725.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5600001",
    TOTAL_SUMINS_MAX: "5700000",
    PLAN_NET_PREMIUM_AMT: "11085.84",
    PLAN_STAMP_DUTY_AMT: "45.00",
    PLAN_VAT_AMT: "779.16",
    PLAN_TOTAL_PREMIUM: "11910.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5700001",
    TOTAL_SUMINS_MAX: "5800000",
    PLAN_NET_PREMIUM_AMT: "11257.74",
    PLAN_STAMP_DUTY_AMT: "46.00",
    PLAN_VAT_AMT: "791.26",
    PLAN_TOTAL_PREMIUM: "12095.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5800001",
    TOTAL_SUMINS_MAX: "5900000",
    PLAN_NET_PREMIUM_AMT: "11430.64",
    PLAN_STAMP_DUTY_AMT: "46.00",
    PLAN_VAT_AMT: "803.36",
    PLAN_TOTAL_PREMIUM: "12280.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5900001",
    TOTAL_SUMINS_MAX: "6000000",
    PLAN_NET_PREMIUM_AMT: "11602.53",
    PLAN_STAMP_DUTY_AMT: "47.00",
    PLAN_VAT_AMT: "815.47",
    PLAN_TOTAL_PREMIUM: "12465.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6000001",
    TOTAL_SUMINS_MAX: "6100000",
    PLAN_NET_PREMIUM_AMT: "11774.43",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "827.57",
    PLAN_TOTAL_PREMIUM: "12650.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6100001",
    TOTAL_SUMINS_MAX: "6200000",
    PLAN_NET_PREMIUM_AMT: "11947.33",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "839.67",
    PLAN_TOTAL_PREMIUM: "12835.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6200001",
    TOTAL_SUMINS_MAX: "6300000",
    PLAN_NET_PREMIUM_AMT: "12119.22",
    PLAN_STAMP_DUTY_AMT: "49.00",
    PLAN_VAT_AMT: "851.78",
    PLAN_TOTAL_PREMIUM: "13020.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6300001",
    TOTAL_SUMINS_MAX: "6400000",
    PLAN_NET_PREMIUM_AMT: "12291.12",
    PLAN_STAMP_DUTY_AMT: "50.00",
    PLAN_VAT_AMT: "863.88",
    PLAN_TOTAL_PREMIUM: "13205.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6400001",
    TOTAL_SUMINS_MAX: "6500000",
    PLAN_NET_PREMIUM_AMT: "12464.02",
    PLAN_STAMP_DUTY_AMT: "50.00",
    PLAN_VAT_AMT: "875.98",
    PLAN_TOTAL_PREMIUM: "13390.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6500001",
    TOTAL_SUMINS_MAX: "6600000",
    PLAN_NET_PREMIUM_AMT: "12635.92",
    PLAN_STAMP_DUTY_AMT: "51.00",
    PLAN_VAT_AMT: "888.08",
    PLAN_TOTAL_PREMIUM: "13575.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6600001",
    TOTAL_SUMINS_MAX: "6700000",
    PLAN_NET_PREMIUM_AMT: "12807.81",
    PLAN_STAMP_DUTY_AMT: "52.00",
    PLAN_VAT_AMT: "900.19",
    PLAN_TOTAL_PREMIUM: "13760.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6700001",
    TOTAL_SUMINS_MAX: "6800000",
    PLAN_NET_PREMIUM_AMT: "12980.71",
    PLAN_STAMP_DUTY_AMT: "52.00",
    PLAN_VAT_AMT: "912.29",
    PLAN_TOTAL_PREMIUM: "13945.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6800001",
    TOTAL_SUMINS_MAX: "6900000",
    PLAN_NET_PREMIUM_AMT: "13152.61",
    PLAN_STAMP_DUTY_AMT: "53.00",
    PLAN_VAT_AMT: "924.39",
    PLAN_TOTAL_PREMIUM: "14130.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6900001",
    TOTAL_SUMINS_MAX: "7000000",
    PLAN_NET_PREMIUM_AMT: "13324.50",
    PLAN_STAMP_DUTY_AMT: "54.00",
    PLAN_VAT_AMT: "936.50",
    PLAN_TOTAL_PREMIUM: "14315.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7000001",
    TOTAL_SUMINS_MAX: "7100000",
    PLAN_NET_PREMIUM_AMT: "13497.40",
    PLAN_STAMP_DUTY_AMT: "54.00",
    PLAN_VAT_AMT: "948.60",
    PLAN_TOTAL_PREMIUM: "14500.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7100001",
    TOTAL_SUMINS_MAX: "7200000",
    PLAN_NET_PREMIUM_AMT: "13669.30",
    PLAN_STAMP_DUTY_AMT: "55.00",
    PLAN_VAT_AMT: "960.70",
    PLAN_TOTAL_PREMIUM: "14685.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7200001",
    TOTAL_SUMINS_MAX: "7300000",
    PLAN_NET_PREMIUM_AMT: "13841.20",
    PLAN_STAMP_DUTY_AMT: "56.00",
    PLAN_VAT_AMT: "972.80",
    PLAN_TOTAL_PREMIUM: "14870.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7300001",
    TOTAL_SUMINS_MAX: "7400000",
    PLAN_NET_PREMIUM_AMT: "14013.09",
    PLAN_STAMP_DUTY_AMT: "57.00",
    PLAN_VAT_AMT: "984.91",
    PLAN_TOTAL_PREMIUM: "15055.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7400001",
    TOTAL_SUMINS_MAX: "7500000",
    PLAN_NET_PREMIUM_AMT: "14185.99",
    PLAN_STAMP_DUTY_AMT: "57.00",
    PLAN_VAT_AMT: "997.01",
    PLAN_TOTAL_PREMIUM: "15240.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7500001",
    TOTAL_SUMINS_MAX: "7600000",
    PLAN_NET_PREMIUM_AMT: "14357.89",
    PLAN_STAMP_DUTY_AMT: "58.00",
    PLAN_VAT_AMT: "1009.11",
    PLAN_TOTAL_PREMIUM: "15425.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7600001",
    TOTAL_SUMINS_MAX: "7700000",
    PLAN_NET_PREMIUM_AMT: "14529.78",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1021.22",
    PLAN_TOTAL_PREMIUM: "15610.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7700001",
    TOTAL_SUMINS_MAX: "7800000",
    PLAN_NET_PREMIUM_AMT: "14702.68",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1033.32",
    PLAN_TOTAL_PREMIUM: "15795.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7800001",
    TOTAL_SUMINS_MAX: "7900000",
    PLAN_NET_PREMIUM_AMT: "14874.58",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1045.42",
    PLAN_TOTAL_PREMIUM: "15980.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7900001",
    TOTAL_SUMINS_MAX: "8000000",
    PLAN_NET_PREMIUM_AMT: "15046.48",
    PLAN_STAMP_DUTY_AMT: "61.00",
    PLAN_VAT_AMT: "1057.52",
    PLAN_TOTAL_PREMIUM: "16165.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8000001",
    TOTAL_SUMINS_MAX: "8100000",
    PLAN_NET_PREMIUM_AMT: "15219.37",
    PLAN_STAMP_DUTY_AMT: "61.00",
    PLAN_VAT_AMT: "1069.63",
    PLAN_TOTAL_PREMIUM: "16350.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8100001",
    TOTAL_SUMINS_MAX: "8200000",
    PLAN_NET_PREMIUM_AMT: "15391.27",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1081.73",
    PLAN_TOTAL_PREMIUM: "16535.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8200001",
    TOTAL_SUMINS_MAX: "8300000",
    PLAN_NET_PREMIUM_AMT: "15563.17",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1093.83",
    PLAN_TOTAL_PREMIUM: "16720.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8300001",
    TOTAL_SUMINS_MAX: "8400000",
    PLAN_NET_PREMIUM_AMT: "15736.07",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1105.93",
    PLAN_TOTAL_PREMIUM: "16905.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8400001",
    TOTAL_SUMINS_MAX: "8500000",
    PLAN_NET_PREMIUM_AMT: "15907.96",
    PLAN_STAMP_DUTY_AMT: "64.00",
    PLAN_VAT_AMT: "1118.04",
    PLAN_TOTAL_PREMIUM: "17090.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8500001",
    TOTAL_SUMINS_MAX: "8600000",
    PLAN_NET_PREMIUM_AMT: "16079.86",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1130.14",
    PLAN_TOTAL_PREMIUM: "17275.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8600001",
    TOTAL_SUMINS_MAX: "8700000",
    PLAN_NET_PREMIUM_AMT: "16251.76",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1142.24",
    PLAN_TOTAL_PREMIUM: "17460.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8700001",
    TOTAL_SUMINS_MAX: "8800000",
    PLAN_NET_PREMIUM_AMT: "16424.65",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1154.35",
    PLAN_TOTAL_PREMIUM: "17645.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8800001",
    TOTAL_SUMINS_MAX: "8900000",
    PLAN_NET_PREMIUM_AMT: "16596.55",
    PLAN_STAMP_DUTY_AMT: "67.00",
    PLAN_VAT_AMT: "1166.45",
    PLAN_TOTAL_PREMIUM: "17830.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8900001",
    TOTAL_SUMINS_MAX: "9000000",
    PLAN_NET_PREMIUM_AMT: "16768.45",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1178.55",
    PLAN_TOTAL_PREMIUM: "18015.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9000001",
    TOTAL_SUMINS_MAX: "9100000",
    PLAN_NET_PREMIUM_AMT: "16941.35",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1190.65",
    PLAN_TOTAL_PREMIUM: "18200.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9100001",
    TOTAL_SUMINS_MAX: "9200000",
    PLAN_NET_PREMIUM_AMT: "17113.24",
    PLAN_STAMP_DUTY_AMT: "69.00",
    PLAN_VAT_AMT: "1202.76",
    PLAN_TOTAL_PREMIUM: "18385.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9200001",
    TOTAL_SUMINS_MAX: "9300000",
    PLAN_NET_PREMIUM_AMT: "17285.14",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1214.86",
    PLAN_TOTAL_PREMIUM: "18570.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9300001",
    TOTAL_SUMINS_MAX: "9400000",
    PLAN_NET_PREMIUM_AMT: "17458.04",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1226.96",
    PLAN_TOTAL_PREMIUM: "18755.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9400001",
    TOTAL_SUMINS_MAX: "9500000",
    PLAN_NET_PREMIUM_AMT: "17629.93",
    PLAN_STAMP_DUTY_AMT: "71.00",
    PLAN_VAT_AMT: "1239.07",
    PLAN_TOTAL_PREMIUM: "18940.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9500001",
    TOTAL_SUMINS_MAX: "9600000",
    PLAN_NET_PREMIUM_AMT: "17801.83",
    PLAN_STAMP_DUTY_AMT: "72.00",
    PLAN_VAT_AMT: "1251.17",
    PLAN_TOTAL_PREMIUM: "19125.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9600001",
    TOTAL_SUMINS_MAX: "9700000",
    PLAN_NET_PREMIUM_AMT: "17974.73",
    PLAN_STAMP_DUTY_AMT: "72.00",
    PLAN_VAT_AMT: "1263.27",
    PLAN_TOTAL_PREMIUM: "19310.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9700001",
    TOTAL_SUMINS_MAX: "9800000",
    PLAN_NET_PREMIUM_AMT: "18146.63",
    PLAN_STAMP_DUTY_AMT: "73.00",
    PLAN_VAT_AMT: "1275.37",
    PLAN_TOTAL_PREMIUM: "19495.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9800001",
    TOTAL_SUMINS_MAX: "9900000",
    PLAN_NET_PREMIUM_AMT: "18318.52",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1287.48",
    PLAN_TOTAL_PREMIUM: "19680.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9900001",
    TOTAL_SUMINS_MAX: "10000000",
    PLAN_NET_PREMIUM_AMT: "18492.35",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1299.65",
    PLAN_TOTAL_PREMIUM: "19866.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10000001",
    TOTAL_SUMINS_MAX: "10100000",
    PLAN_NET_PREMIUM_AMT: "19528.48",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1372.52",
    PLAN_TOTAL_PREMIUM: "20980.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10100001",
    TOTAL_SUMINS_MAX: "10200000",
    PLAN_NET_PREMIUM_AMT: "19626.61",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1379.39",
    PLAN_TOTAL_PREMIUM: "21085.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10200001",
    TOTAL_SUMINS_MAX: "10300000",
    PLAN_NET_PREMIUM_AMT: "19724.74",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1386.26",
    PLAN_TOTAL_PREMIUM: "21190.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10300001",
    TOTAL_SUMINS_MAX: "10400000",
    PLAN_NET_PREMIUM_AMT: "19821.87",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1393.13",
    PLAN_TOTAL_PREMIUM: "21295.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10400001",
    TOTAL_SUMINS_MAX: "10500000",
    PLAN_NET_PREMIUM_AMT: "19920.00",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1400.00",
    PLAN_TOTAL_PREMIUM: "21400.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10500001",
    TOTAL_SUMINS_MAX: "10600000",
    PLAN_NET_PREMIUM_AMT: "20017.13",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1406.87",
    PLAN_TOTAL_PREMIUM: "21505.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10600001",
    TOTAL_SUMINS_MAX: "10700000",
    PLAN_NET_PREMIUM_AMT: "20115.26",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1413.74",
    PLAN_TOTAL_PREMIUM: "21610.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10700001",
    TOTAL_SUMINS_MAX: "10800000",
    PLAN_NET_PREMIUM_AMT: "20213.39",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1420.61",
    PLAN_TOTAL_PREMIUM: "21715.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10800001",
    TOTAL_SUMINS_MAX: "10900000",
    PLAN_NET_PREMIUM_AMT: "20310.52",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1427.48",
    PLAN_TOTAL_PREMIUM: "21820.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10900001",
    TOTAL_SUMINS_MAX: "11000000",
    PLAN_NET_PREMIUM_AMT: "20408.65",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1434.35",
    PLAN_TOTAL_PREMIUM: "21925.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11000001",
    TOTAL_SUMINS_MAX: "11100000",
    PLAN_NET_PREMIUM_AMT: "20505.78",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1441.22",
    PLAN_TOTAL_PREMIUM: "22030.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11100001",
    TOTAL_SUMINS_MAX: "11200000",
    PLAN_NET_PREMIUM_AMT: "20603.92",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1448.08",
    PLAN_TOTAL_PREMIUM: "22135.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11200001",
    TOTAL_SUMINS_MAX: "11300000",
    PLAN_NET_PREMIUM_AMT: "20702.05",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1454.95",
    PLAN_TOTAL_PREMIUM: "22240.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11300001",
    TOTAL_SUMINS_MAX: "11400000",
    PLAN_NET_PREMIUM_AMT: "20799.18",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1461.82",
    PLAN_TOTAL_PREMIUM: "22345.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11400001",
    TOTAL_SUMINS_MAX: "11500000",
    PLAN_NET_PREMIUM_AMT: "20897.31",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1468.69",
    PLAN_TOTAL_PREMIUM: "22450.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11500001",
    TOTAL_SUMINS_MAX: "11600000",
    PLAN_NET_PREMIUM_AMT: "20995.44",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1475.56",
    PLAN_TOTAL_PREMIUM: "22555.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11600001",
    TOTAL_SUMINS_MAX: "11700000",
    PLAN_NET_PREMIUM_AMT: "21092.57",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1482.43",
    PLAN_TOTAL_PREMIUM: "22660.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11700001",
    TOTAL_SUMINS_MAX: "11800000",
    PLAN_NET_PREMIUM_AMT: "21190.70",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1489.30",
    PLAN_TOTAL_PREMIUM: "22765.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11800001",
    TOTAL_SUMINS_MAX: "11900000",
    PLAN_NET_PREMIUM_AMT: "21287.83",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1496.17",
    PLAN_TOTAL_PREMIUM: "22870.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11900001",
    TOTAL_SUMINS_MAX: "12000000",
    PLAN_NET_PREMIUM_AMT: "21385.96",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1503.04",
    PLAN_TOTAL_PREMIUM: "22975.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12000001",
    TOTAL_SUMINS_MAX: "12100000",
    PLAN_NET_PREMIUM_AMT: "21484.09",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1509.91",
    PLAN_TOTAL_PREMIUM: "23080.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12100001",
    TOTAL_SUMINS_MAX: "12200000",
    PLAN_NET_PREMIUM_AMT: "21581.22",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1516.78",
    PLAN_TOTAL_PREMIUM: "23185.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12200001",
    TOTAL_SUMINS_MAX: "12300000",
    PLAN_NET_PREMIUM_AMT: "21679.35",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1523.65",
    PLAN_TOTAL_PREMIUM: "23290.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12300001",
    TOTAL_SUMINS_MAX: "12400000",
    PLAN_NET_PREMIUM_AMT: "21776.49",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1530.51",
    PLAN_TOTAL_PREMIUM: "23395.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12400001",
    TOTAL_SUMINS_MAX: "12500000",
    PLAN_NET_PREMIUM_AMT: "21874.62",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1537.38",
    PLAN_TOTAL_PREMIUM: "23500.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12500001",
    TOTAL_SUMINS_MAX: "12600000",
    PLAN_NET_PREMIUM_AMT: "21972.75",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1544.25",
    PLAN_TOTAL_PREMIUM: "23605.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12600001",
    TOTAL_SUMINS_MAX: "12700000",
    PLAN_NET_PREMIUM_AMT: "22069.88",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1551.12",
    PLAN_TOTAL_PREMIUM: "23710.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12700001",
    TOTAL_SUMINS_MAX: "12800000",
    PLAN_NET_PREMIUM_AMT: "22168.01",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1557.99",
    PLAN_TOTAL_PREMIUM: "23815.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12800001",
    TOTAL_SUMINS_MAX: "12900000",
    PLAN_NET_PREMIUM_AMT: "22265.14",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1564.86",
    PLAN_TOTAL_PREMIUM: "23920.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12900001",
    TOTAL_SUMINS_MAX: "13000000",
    PLAN_NET_PREMIUM_AMT: "22363.27",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1571.73",
    PLAN_TOTAL_PREMIUM: "24025.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13000001",
    TOTAL_SUMINS_MAX: "13100000",
    PLAN_NET_PREMIUM_AMT: "22461.40",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1578.60",
    PLAN_TOTAL_PREMIUM: "24130.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13100001",
    TOTAL_SUMINS_MAX: "13200000",
    PLAN_NET_PREMIUM_AMT: "22558.53",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1585.47",
    PLAN_TOTAL_PREMIUM: "24235.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13200001",
    TOTAL_SUMINS_MAX: "13300000",
    PLAN_NET_PREMIUM_AMT: "22656.66",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1592.34",
    PLAN_TOTAL_PREMIUM: "24340.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13300001",
    TOTAL_SUMINS_MAX: "13400000",
    PLAN_NET_PREMIUM_AMT: "22753.79",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1599.21",
    PLAN_TOTAL_PREMIUM: "24445.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13400001",
    TOTAL_SUMINS_MAX: "13500000",
    PLAN_NET_PREMIUM_AMT: "22851.92",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1606.08",
    PLAN_TOTAL_PREMIUM: "24550.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13500001",
    TOTAL_SUMINS_MAX: "13600000",
    PLAN_NET_PREMIUM_AMT: "22950.06",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1612.94",
    PLAN_TOTAL_PREMIUM: "24655.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13600001",
    TOTAL_SUMINS_MAX: "13700000",
    PLAN_NET_PREMIUM_AMT: "23047.19",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1619.81",
    PLAN_TOTAL_PREMIUM: "24760.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13700001",
    TOTAL_SUMINS_MAX: "13800000",
    PLAN_NET_PREMIUM_AMT: "23145.32",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1626.68",
    PLAN_TOTAL_PREMIUM: "24865.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13800001",
    TOTAL_SUMINS_MAX: "13900000",
    PLAN_NET_PREMIUM_AMT: "23243.45",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1633.55",
    PLAN_TOTAL_PREMIUM: "24970.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13900001",
    TOTAL_SUMINS_MAX: "14000000",
    PLAN_NET_PREMIUM_AMT: "23340.58",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1640.42",
    PLAN_TOTAL_PREMIUM: "25075.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14000001",
    TOTAL_SUMINS_MAX: "14100000",
    PLAN_NET_PREMIUM_AMT: "23438.71",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1647.29",
    PLAN_TOTAL_PREMIUM: "25180.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14100001",
    TOTAL_SUMINS_MAX: "14200000",
    PLAN_NET_PREMIUM_AMT: "23535.84",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1654.16",
    PLAN_TOTAL_PREMIUM: "25285.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14200001",
    TOTAL_SUMINS_MAX: "14300000",
    PLAN_NET_PREMIUM_AMT: "23633.97",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1661.03",
    PLAN_TOTAL_PREMIUM: "25390.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14300001",
    TOTAL_SUMINS_MAX: "14400000",
    PLAN_NET_PREMIUM_AMT: "23732.10",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1667.90",
    PLAN_TOTAL_PREMIUM: "25495.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14400001",
    TOTAL_SUMINS_MAX: "14500000",
    PLAN_NET_PREMIUM_AMT: "23829.23",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1674.77",
    PLAN_TOTAL_PREMIUM: "25600.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14500001",
    TOTAL_SUMINS_MAX: "14600000",
    PLAN_NET_PREMIUM_AMT: "23927.36",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1681.64",
    PLAN_TOTAL_PREMIUM: "25705.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14600001",
    TOTAL_SUMINS_MAX: "14700000",
    PLAN_NET_PREMIUM_AMT: "24024.49",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1688.51",
    PLAN_TOTAL_PREMIUM: "25810.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14700001",
    TOTAL_SUMINS_MAX: "14800000",
    PLAN_NET_PREMIUM_AMT: "24122.63",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1695.37",
    PLAN_TOTAL_PREMIUM: "25915.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14800001",
    TOTAL_SUMINS_MAX: "14900000",
    PLAN_NET_PREMIUM_AMT: "24220.76",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1702.24",
    PLAN_TOTAL_PREMIUM: "26020.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14900001",
    TOTAL_SUMINS_MAX: "15000000",
    PLAN_NET_PREMIUM_AMT: "24317.89",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1709.11",
    PLAN_TOTAL_PREMIUM: "26125.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15000001",
    TOTAL_SUMINS_MAX: "15100000",
    PLAN_NET_PREMIUM_AMT: "24416.02",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1715.98",
    PLAN_TOTAL_PREMIUM: "26230.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15100001",
    TOTAL_SUMINS_MAX: "15200000",
    PLAN_NET_PREMIUM_AMT: "24513.15",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1722.85",
    PLAN_TOTAL_PREMIUM: "26335.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15200001",
    TOTAL_SUMINS_MAX: "15300000",
    PLAN_NET_PREMIUM_AMT: "24611.28",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1729.72",
    PLAN_TOTAL_PREMIUM: "26440.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15300001",
    TOTAL_SUMINS_MAX: "15400000",
    PLAN_NET_PREMIUM_AMT: "24709.41",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1736.59",
    PLAN_TOTAL_PREMIUM: "26545.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15400001",
    TOTAL_SUMINS_MAX: "15500000",
    PLAN_NET_PREMIUM_AMT: "24806.54",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1743.46",
    PLAN_TOTAL_PREMIUM: "26650.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15500001",
    TOTAL_SUMINS_MAX: "15600000",
    PLAN_NET_PREMIUM_AMT: "24904.67",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1750.33",
    PLAN_TOTAL_PREMIUM: "26755.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15600001",
    TOTAL_SUMINS_MAX: "15700000",
    PLAN_NET_PREMIUM_AMT: "25001.80",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1757.20",
    PLAN_TOTAL_PREMIUM: "26860.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15700001",
    TOTAL_SUMINS_MAX: "15800000",
    PLAN_NET_PREMIUM_AMT: "25099.93",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1764.07",
    PLAN_TOTAL_PREMIUM: "26965.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15800001",
    TOTAL_SUMINS_MAX: "15900000",
    PLAN_NET_PREMIUM_AMT: "25198.07",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1770.93",
    PLAN_TOTAL_PREMIUM: "27070.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15900001",
    TOTAL_SUMINS_MAX: "16000000",
    PLAN_NET_PREMIUM_AMT: "25295.20",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1777.80",
    PLAN_TOTAL_PREMIUM: "27175.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16000001",
    TOTAL_SUMINS_MAX: "16100000",
    PLAN_NET_PREMIUM_AMT: "25393.33",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1784.67",
    PLAN_TOTAL_PREMIUM: "27280.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16100001",
    TOTAL_SUMINS_MAX: "16200000",
    PLAN_NET_PREMIUM_AMT: "25491.46",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1791.54",
    PLAN_TOTAL_PREMIUM: "27385.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16200001",
    TOTAL_SUMINS_MAX: "16300000",
    PLAN_NET_PREMIUM_AMT: "25588.59",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1798.41",
    PLAN_TOTAL_PREMIUM: "27490.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16300001",
    TOTAL_SUMINS_MAX: "16400000",
    PLAN_NET_PREMIUM_AMT: "25686.72",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1805.28",
    PLAN_TOTAL_PREMIUM: "27595.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16400001",
    TOTAL_SUMINS_MAX: "16500000",
    PLAN_NET_PREMIUM_AMT: "25783.85",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1812.15",
    PLAN_TOTAL_PREMIUM: "27700.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16500001",
    TOTAL_SUMINS_MAX: "16600000",
    PLAN_NET_PREMIUM_AMT: "25881.98",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1819.02",
    PLAN_TOTAL_PREMIUM: "27805.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16600001",
    TOTAL_SUMINS_MAX: "16700000",
    PLAN_NET_PREMIUM_AMT: "25980.11",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1825.89",
    PLAN_TOTAL_PREMIUM: "27910.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16700001",
    TOTAL_SUMINS_MAX: "16800000",
    PLAN_NET_PREMIUM_AMT: "26077.24",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1832.76",
    PLAN_TOTAL_PREMIUM: "28015.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16800001",
    TOTAL_SUMINS_MAX: "16900000",
    PLAN_NET_PREMIUM_AMT: "26175.37",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1839.63",
    PLAN_TOTAL_PREMIUM: "28120.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16900001",
    TOTAL_SUMINS_MAX: "17000000",
    PLAN_NET_PREMIUM_AMT: "26272.50",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1846.50",
    PLAN_TOTAL_PREMIUM: "28225.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17000001",
    TOTAL_SUMINS_MAX: "17100000",
    PLAN_NET_PREMIUM_AMT: "26370.64",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1853.36",
    PLAN_TOTAL_PREMIUM: "28330.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17100001",
    TOTAL_SUMINS_MAX: "17200000",
    PLAN_NET_PREMIUM_AMT: "26468.77",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1860.23",
    PLAN_TOTAL_PREMIUM: "28435.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17200001",
    TOTAL_SUMINS_MAX: "17300000",
    PLAN_NET_PREMIUM_AMT: "26565.90",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1867.10",
    PLAN_TOTAL_PREMIUM: "28540.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17300001",
    TOTAL_SUMINS_MAX: "17400000",
    PLAN_NET_PREMIUM_AMT: "26664.03",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1873.97",
    PLAN_TOTAL_PREMIUM: "28645.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17400001",
    TOTAL_SUMINS_MAX: "17500000",
    PLAN_NET_PREMIUM_AMT: "26761.16",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1880.84",
    PLAN_TOTAL_PREMIUM: "28750.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17500001",
    TOTAL_SUMINS_MAX: "17600000",
    PLAN_NET_PREMIUM_AMT: "26859.29",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1887.71",
    PLAN_TOTAL_PREMIUM: "28855.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17600001",
    TOTAL_SUMINS_MAX: "17700000",
    PLAN_NET_PREMIUM_AMT: "26957.42",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1894.58",
    PLAN_TOTAL_PREMIUM: "28960.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17700001",
    TOTAL_SUMINS_MAX: "17800000",
    PLAN_NET_PREMIUM_AMT: "27054.55",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1901.45",
    PLAN_TOTAL_PREMIUM: "29065.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17800001",
    TOTAL_SUMINS_MAX: "17900000",
    PLAN_NET_PREMIUM_AMT: "27152.68",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1908.32",
    PLAN_TOTAL_PREMIUM: "29170.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17900001",
    TOTAL_SUMINS_MAX: "18000000",
    PLAN_NET_PREMIUM_AMT: "27249.81",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1915.19",
    PLAN_TOTAL_PREMIUM: "29275.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18000001",
    TOTAL_SUMINS_MAX: "18100000",
    PLAN_NET_PREMIUM_AMT: "27347.94",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1922.06",
    PLAN_TOTAL_PREMIUM: "29380.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18100001",
    TOTAL_SUMINS_MAX: "18200000",
    PLAN_NET_PREMIUM_AMT: "27446.07",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1928.93",
    PLAN_TOTAL_PREMIUM: "29485.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18200001",
    TOTAL_SUMINS_MAX: "18300000",
    PLAN_NET_PREMIUM_AMT: "27543.21",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1935.79",
    PLAN_TOTAL_PREMIUM: "29590.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18300001",
    TOTAL_SUMINS_MAX: "18400000",
    PLAN_NET_PREMIUM_AMT: "27641.34",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1942.66",
    PLAN_TOTAL_PREMIUM: "29695.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18400001",
    TOTAL_SUMINS_MAX: "18500000",
    PLAN_NET_PREMIUM_AMT: "27739.47",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1949.53",
    PLAN_TOTAL_PREMIUM: "29800.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18500001",
    TOTAL_SUMINS_MAX: "18600000",
    PLAN_NET_PREMIUM_AMT: "27836.60",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1956.40",
    PLAN_TOTAL_PREMIUM: "29905.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18600001",
    TOTAL_SUMINS_MAX: "18700000",
    PLAN_NET_PREMIUM_AMT: "27934.73",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1963.27",
    PLAN_TOTAL_PREMIUM: "30010.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18700001",
    TOTAL_SUMINS_MAX: "18800000",
    PLAN_NET_PREMIUM_AMT: "28031.86",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1970.14",
    PLAN_TOTAL_PREMIUM: "30115.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18800001",
    TOTAL_SUMINS_MAX: "18900000",
    PLAN_NET_PREMIUM_AMT: "28129.99",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1977.01",
    PLAN_TOTAL_PREMIUM: "30220.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18900001",
    TOTAL_SUMINS_MAX: "19000000",
    PLAN_NET_PREMIUM_AMT: "28228.12",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1983.88",
    PLAN_TOTAL_PREMIUM: "30325.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19000001",
    TOTAL_SUMINS_MAX: "19100000",
    PLAN_NET_PREMIUM_AMT: "28325.25",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1990.75",
    PLAN_TOTAL_PREMIUM: "30430.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19100001",
    TOTAL_SUMINS_MAX: "19200000",
    PLAN_NET_PREMIUM_AMT: "28423.38",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1997.62",
    PLAN_TOTAL_PREMIUM: "30535.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19200001",
    TOTAL_SUMINS_MAX: "19300000",
    PLAN_NET_PREMIUM_AMT: "28520.51",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2004.49",
    PLAN_TOTAL_PREMIUM: "30640.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19300001",
    TOTAL_SUMINS_MAX: "19400000",
    PLAN_NET_PREMIUM_AMT: "28618.64",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2011.36",
    PLAN_TOTAL_PREMIUM: "30745.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19400001",
    TOTAL_SUMINS_MAX: "19500000",
    PLAN_NET_PREMIUM_AMT: "28716.78",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2018.22",
    PLAN_TOTAL_PREMIUM: "30850.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19500001",
    TOTAL_SUMINS_MAX: "19600000",
    PLAN_NET_PREMIUM_AMT: "28813.91",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2025.09",
    PLAN_TOTAL_PREMIUM: "30955.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19600001",
    TOTAL_SUMINS_MAX: "19700000",
    PLAN_NET_PREMIUM_AMT: "28912.04",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2031.96",
    PLAN_TOTAL_PREMIUM: "31060.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19700001",
    TOTAL_SUMINS_MAX: "19800000",
    PLAN_NET_PREMIUM_AMT: "29009.17",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2038.83",
    PLAN_TOTAL_PREMIUM: "31165.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19800001",
    TOTAL_SUMINS_MAX: "19900000",
    PLAN_NET_PREMIUM_AMT: "29107.30",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2045.70",
    PLAN_TOTAL_PREMIUM: "31270.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19900001",
    TOTAL_SUMINS_MAX: "20000000",
    PLAN_NET_PREMIUM_AMT: "29205.43",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2052.57",
    PLAN_TOTAL_PREMIUM: "31375.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20000001",
    TOTAL_SUMINS_MAX: "20100000",
    PLAN_NET_PREMIUM_AMT: "29302.56",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2059.44",
    PLAN_TOTAL_PREMIUM: "31480.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20100001",
    TOTAL_SUMINS_MAX: "20200000",
    PLAN_NET_PREMIUM_AMT: "29400.69",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2066.31",
    PLAN_TOTAL_PREMIUM: "31585.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20200001",
    TOTAL_SUMINS_MAX: "20300000",
    PLAN_NET_PREMIUM_AMT: "29498.82",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2073.18",
    PLAN_TOTAL_PREMIUM: "31690.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20300001",
    TOTAL_SUMINS_MAX: "20400000",
    PLAN_NET_PREMIUM_AMT: "29595.95",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2080.05",
    PLAN_TOTAL_PREMIUM: "31795.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20400001",
    TOTAL_SUMINS_MAX: "20500000",
    PLAN_NET_PREMIUM_AMT: "29694.08",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2086.92",
    PLAN_TOTAL_PREMIUM: "31900.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20500001",
    TOTAL_SUMINS_MAX: "20600000",
    PLAN_NET_PREMIUM_AMT: "29791.21",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2093.79",
    PLAN_TOTAL_PREMIUM: "32005.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20600001",
    TOTAL_SUMINS_MAX: "20700000",
    PLAN_NET_PREMIUM_AMT: "29889.35",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2100.65",
    PLAN_TOTAL_PREMIUM: "32110.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20700001",
    TOTAL_SUMINS_MAX: "20800000",
    PLAN_NET_PREMIUM_AMT: "29987.48",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2107.52",
    PLAN_TOTAL_PREMIUM: "32215.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20800001",
    TOTAL_SUMINS_MAX: "20900000",
    PLAN_NET_PREMIUM_AMT: "30084.61",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2114.39",
    PLAN_TOTAL_PREMIUM: "32320.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20900001",
    TOTAL_SUMINS_MAX: "21000000",
    PLAN_NET_PREMIUM_AMT: "30182.74",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2121.26",
    PLAN_TOTAL_PREMIUM: "32425.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21000001",
    TOTAL_SUMINS_MAX: "21100000",
    PLAN_NET_PREMIUM_AMT: "30279.87",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2128.13",
    PLAN_TOTAL_PREMIUM: "32530.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21100001",
    TOTAL_SUMINS_MAX: "21200000",
    PLAN_NET_PREMIUM_AMT: "30378.00",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2135.00",
    PLAN_TOTAL_PREMIUM: "32635.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21200001",
    TOTAL_SUMINS_MAX: "21300000",
    PLAN_NET_PREMIUM_AMT: "30476.13",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2141.87",
    PLAN_TOTAL_PREMIUM: "32740.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21300001",
    TOTAL_SUMINS_MAX: "21400000",
    PLAN_NET_PREMIUM_AMT: "30573.26",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2148.74",
    PLAN_TOTAL_PREMIUM: "32845.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21400001",
    TOTAL_SUMINS_MAX: "21500000",
    PLAN_NET_PREMIUM_AMT: "30671.39",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2155.61",
    PLAN_TOTAL_PREMIUM: "32950.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21500001",
    TOTAL_SUMINS_MAX: "21600000",
    PLAN_NET_PREMIUM_AMT: "30768.52",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2162.48",
    PLAN_TOTAL_PREMIUM: "33055.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21600001",
    TOTAL_SUMINS_MAX: "21700000",
    PLAN_NET_PREMIUM_AMT: "30866.65",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2169.35",
    PLAN_TOTAL_PREMIUM: "33160.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21700001",
    TOTAL_SUMINS_MAX: "21800000",
    PLAN_NET_PREMIUM_AMT: "30964.78",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2176.22",
    PLAN_TOTAL_PREMIUM: "33265.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21800001",
    TOTAL_SUMINS_MAX: "21900000",
    PLAN_NET_PREMIUM_AMT: "31061.92",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2183.08",
    PLAN_TOTAL_PREMIUM: "33370.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21900001",
    TOTAL_SUMINS_MAX: "22000000",
    PLAN_NET_PREMIUM_AMT: "31160.05",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2189.95",
    PLAN_TOTAL_PREMIUM: "33475.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22000001",
    TOTAL_SUMINS_MAX: "22100000",
    PLAN_NET_PREMIUM_AMT: "31257.18",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2196.82",
    PLAN_TOTAL_PREMIUM: "33580.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22100001",
    TOTAL_SUMINS_MAX: "22200000",
    PLAN_NET_PREMIUM_AMT: "31355.31",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2203.69",
    PLAN_TOTAL_PREMIUM: "33685.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22200001",
    TOTAL_SUMINS_MAX: "22300000",
    PLAN_NET_PREMIUM_AMT: "31453.44",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2210.56",
    PLAN_TOTAL_PREMIUM: "33790.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22300001",
    TOTAL_SUMINS_MAX: "22400000",
    PLAN_NET_PREMIUM_AMT: "31550.57",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2217.43",
    PLAN_TOTAL_PREMIUM: "33895.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22400001",
    TOTAL_SUMINS_MAX: "22500000",
    PLAN_NET_PREMIUM_AMT: "31648.70",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2224.30",
    PLAN_TOTAL_PREMIUM: "34000.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22500001",
    TOTAL_SUMINS_MAX: "22600000",
    PLAN_NET_PREMIUM_AMT: "31746.83",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2231.17",
    PLAN_TOTAL_PREMIUM: "34105.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22600001",
    TOTAL_SUMINS_MAX: "22700000",
    PLAN_NET_PREMIUM_AMT: "31843.96",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2238.04",
    PLAN_TOTAL_PREMIUM: "34210.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22700001",
    TOTAL_SUMINS_MAX: "22800000",
    PLAN_NET_PREMIUM_AMT: "31942.09",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2244.91",
    PLAN_TOTAL_PREMIUM: "34315.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22800001",
    TOTAL_SUMINS_MAX: "22900000",
    PLAN_NET_PREMIUM_AMT: "32039.22",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2251.78",
    PLAN_TOTAL_PREMIUM: "34420.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22900001",
    TOTAL_SUMINS_MAX: "23000000",
    PLAN_NET_PREMIUM_AMT: "32137.35",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2258.65",
    PLAN_TOTAL_PREMIUM: "34525.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23000001",
    TOTAL_SUMINS_MAX: "23100000",
    PLAN_NET_PREMIUM_AMT: "32235.49",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2265.51",
    PLAN_TOTAL_PREMIUM: "34630.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23100001",
    TOTAL_SUMINS_MAX: "23200000",
    PLAN_NET_PREMIUM_AMT: "32332.62",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2272.38",
    PLAN_TOTAL_PREMIUM: "34735.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23200001",
    TOTAL_SUMINS_MAX: "23300000",
    PLAN_NET_PREMIUM_AMT: "32430.75",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2279.25",
    PLAN_TOTAL_PREMIUM: "34840.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23300001",
    TOTAL_SUMINS_MAX: "23400000",
    PLAN_NET_PREMIUM_AMT: "32527.88",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2286.12",
    PLAN_TOTAL_PREMIUM: "34945.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23400001",
    TOTAL_SUMINS_MAX: "23500000",
    PLAN_NET_PREMIUM_AMT: "32626.01",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2292.99",
    PLAN_TOTAL_PREMIUM: "35050.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23500001",
    TOTAL_SUMINS_MAX: "23600000",
    PLAN_NET_PREMIUM_AMT: "32724.14",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2299.86",
    PLAN_TOTAL_PREMIUM: "35155.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23600001",
    TOTAL_SUMINS_MAX: "23700000",
    PLAN_NET_PREMIUM_AMT: "32821.27",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2306.73",
    PLAN_TOTAL_PREMIUM: "35260.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23700001",
    TOTAL_SUMINS_MAX: "23800000",
    PLAN_NET_PREMIUM_AMT: "32919.40",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2313.60",
    PLAN_TOTAL_PREMIUM: "35365.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23800001",
    TOTAL_SUMINS_MAX: "23900000",
    PLAN_NET_PREMIUM_AMT: "33016.53",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2320.47",
    PLAN_TOTAL_PREMIUM: "35470.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23900001",
    TOTAL_SUMINS_MAX: "24000000",
    PLAN_NET_PREMIUM_AMT: "33114.66",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2327.34",
    PLAN_TOTAL_PREMIUM: "35575.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24000001",
    TOTAL_SUMINS_MAX: "24100000",
    PLAN_NET_PREMIUM_AMT: "33212.79",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2334.21",
    PLAN_TOTAL_PREMIUM: "35680.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24100001",
    TOTAL_SUMINS_MAX: "24200000",
    PLAN_NET_PREMIUM_AMT: "33309.92",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2341.08",
    PLAN_TOTAL_PREMIUM: "35785.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24200001",
    TOTAL_SUMINS_MAX: "24300000",
    PLAN_NET_PREMIUM_AMT: "33408.06",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2347.94",
    PLAN_TOTAL_PREMIUM: "35890.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24300001",
    TOTAL_SUMINS_MAX: "24400000",
    PLAN_NET_PREMIUM_AMT: "33505.19",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2354.81",
    PLAN_TOTAL_PREMIUM: "35995.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24400001",
    TOTAL_SUMINS_MAX: "24500000",
    PLAN_NET_PREMIUM_AMT: "33603.32",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2361.68",
    PLAN_TOTAL_PREMIUM: "36100.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24500001",
    TOTAL_SUMINS_MAX: "24600000",
    PLAN_NET_PREMIUM_AMT: "33701.45",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2368.55",
    PLAN_TOTAL_PREMIUM: "36205.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24600001",
    TOTAL_SUMINS_MAX: "24700000",
    PLAN_NET_PREMIUM_AMT: "33798.58",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2375.42",
    PLAN_TOTAL_PREMIUM: "36310.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24700001",
    TOTAL_SUMINS_MAX: "24800000",
    PLAN_NET_PREMIUM_AMT: "33896.71",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2382.29",
    PLAN_TOTAL_PREMIUM: "36415.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24800001",
    TOTAL_SUMINS_MAX: "24900000",
    PLAN_NET_PREMIUM_AMT: "33994.84",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2389.16",
    PLAN_TOTAL_PREMIUM: "36520.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24900001",
    TOTAL_SUMINS_MAX: "25000000",
    PLAN_NET_PREMIUM_AMT: "34091.97",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2396.03",
    PLAN_TOTAL_PREMIUM: "36625.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25000001",
    TOTAL_SUMINS_MAX: "25100000",
    PLAN_NET_PREMIUM_AMT: "34190.10",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2402.90",
    PLAN_TOTAL_PREMIUM: "36730.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25100001",
    TOTAL_SUMINS_MAX: "25200000",
    PLAN_NET_PREMIUM_AMT: "34287.23",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2409.77",
    PLAN_TOTAL_PREMIUM: "36835.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25200001",
    TOTAL_SUMINS_MAX: "25300000",
    PLAN_NET_PREMIUM_AMT: "34385.36",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2416.64",
    PLAN_TOTAL_PREMIUM: "36940.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25300001",
    TOTAL_SUMINS_MAX: "25400000",
    PLAN_NET_PREMIUM_AMT: "34483.49",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2423.51",
    PLAN_TOTAL_PREMIUM: "37045.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25400001",
    TOTAL_SUMINS_MAX: "25500000",
    PLAN_NET_PREMIUM_AMT: "34580.63",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2430.37",
    PLAN_TOTAL_PREMIUM: "37150.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25500001",
    TOTAL_SUMINS_MAX: "25600000",
    PLAN_NET_PREMIUM_AMT: "34678.76",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2437.24",
    PLAN_TOTAL_PREMIUM: "37255.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25600001",
    TOTAL_SUMINS_MAX: "25700000",
    PLAN_NET_PREMIUM_AMT: "34775.89",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2444.11",
    PLAN_TOTAL_PREMIUM: "37360.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25700001",
    TOTAL_SUMINS_MAX: "25800000",
    PLAN_NET_PREMIUM_AMT: "34874.02",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2450.98",
    PLAN_TOTAL_PREMIUM: "37465.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25800001",
    TOTAL_SUMINS_MAX: "25900000",
    PLAN_NET_PREMIUM_AMT: "34972.15",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2457.85",
    PLAN_TOTAL_PREMIUM: "37570.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25900001",
    TOTAL_SUMINS_MAX: "26000000",
    PLAN_NET_PREMIUM_AMT: "35069.28",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2464.72",
    PLAN_TOTAL_PREMIUM: "37675.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26000001",
    TOTAL_SUMINS_MAX: "26100000",
    PLAN_NET_PREMIUM_AMT: "35167.41",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2471.59",
    PLAN_TOTAL_PREMIUM: "37780.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26100001",
    TOTAL_SUMINS_MAX: "26200000",
    PLAN_NET_PREMIUM_AMT: "35264.54",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2478.46",
    PLAN_TOTAL_PREMIUM: "37885.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26200001",
    TOTAL_SUMINS_MAX: "26300000",
    PLAN_NET_PREMIUM_AMT: "35362.67",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2485.33",
    PLAN_TOTAL_PREMIUM: "37990.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26300001",
    TOTAL_SUMINS_MAX: "26400000",
    PLAN_NET_PREMIUM_AMT: "35460.80",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2492.20",
    PLAN_TOTAL_PREMIUM: "38095.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26400001",
    TOTAL_SUMINS_MAX: "26500000",
    PLAN_NET_PREMIUM_AMT: "35557.93",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2499.07",
    PLAN_TOTAL_PREMIUM: "38200.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26500001",
    TOTAL_SUMINS_MAX: "26600000",
    PLAN_NET_PREMIUM_AMT: "35656.07",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2505.93",
    PLAN_TOTAL_PREMIUM: "38305.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26600001",
    TOTAL_SUMINS_MAX: "26700000",
    PLAN_NET_PREMIUM_AMT: "35753.20",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2512.80",
    PLAN_TOTAL_PREMIUM: "38410.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26700001",
    TOTAL_SUMINS_MAX: "26800000",
    PLAN_NET_PREMIUM_AMT: "35851.33",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2519.67",
    PLAN_TOTAL_PREMIUM: "38515.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26800001",
    TOTAL_SUMINS_MAX: "26900000",
    PLAN_NET_PREMIUM_AMT: "35949.46",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2526.54",
    PLAN_TOTAL_PREMIUM: "38620.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26900001",
    TOTAL_SUMINS_MAX: "27000000",
    PLAN_NET_PREMIUM_AMT: "36046.59",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2533.41",
    PLAN_TOTAL_PREMIUM: "38725.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27000001",
    TOTAL_SUMINS_MAX: "27100000",
    PLAN_NET_PREMIUM_AMT: "36144.72",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2540.28",
    PLAN_TOTAL_PREMIUM: "38830.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27100001",
    TOTAL_SUMINS_MAX: "27200000",
    PLAN_NET_PREMIUM_AMT: "36242.85",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2547.15",
    PLAN_TOTAL_PREMIUM: "38935.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27200001",
    TOTAL_SUMINS_MAX: "27300000",
    PLAN_NET_PREMIUM_AMT: "36339.98",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2554.02",
    PLAN_TOTAL_PREMIUM: "39040.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27300001",
    TOTAL_SUMINS_MAX: "27400000",
    PLAN_NET_PREMIUM_AMT: "36438.11",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2560.89",
    PLAN_TOTAL_PREMIUM: "39145.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27400001",
    TOTAL_SUMINS_MAX: "27500000",
    PLAN_NET_PREMIUM_AMT: "36535.24",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2567.76",
    PLAN_TOTAL_PREMIUM: "39250.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27500001",
    TOTAL_SUMINS_MAX: "27600000",
    PLAN_NET_PREMIUM_AMT: "36633.37",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2574.63",
    PLAN_TOTAL_PREMIUM: "39355.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27600001",
    TOTAL_SUMINS_MAX: "27700000",
    PLAN_NET_PREMIUM_AMT: "36731.50",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2581.50",
    PLAN_TOTAL_PREMIUM: "39460.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27700001",
    TOTAL_SUMINS_MAX: "27800000",
    PLAN_NET_PREMIUM_AMT: "36828.64",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2588.36",
    PLAN_TOTAL_PREMIUM: "39565.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27800001",
    TOTAL_SUMINS_MAX: "27900000",
    PLAN_NET_PREMIUM_AMT: "36926.77",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2595.23",
    PLAN_TOTAL_PREMIUM: "39670.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27900001",
    TOTAL_SUMINS_MAX: "28000000",
    PLAN_NET_PREMIUM_AMT: "37023.90",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2602.10",
    PLAN_TOTAL_PREMIUM: "39775.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28000001",
    TOTAL_SUMINS_MAX: "28100000",
    PLAN_NET_PREMIUM_AMT: "37122.03",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2608.97",
    PLAN_TOTAL_PREMIUM: "39880.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28100001",
    TOTAL_SUMINS_MAX: "28200000",
    PLAN_NET_PREMIUM_AMT: "37220.16",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2615.84",
    PLAN_TOTAL_PREMIUM: "39985.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28200001",
    TOTAL_SUMINS_MAX: "28300000",
    PLAN_NET_PREMIUM_AMT: "37317.29",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2622.71",
    PLAN_TOTAL_PREMIUM: "40090.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28300001",
    TOTAL_SUMINS_MAX: "28400000",
    PLAN_NET_PREMIUM_AMT: "37415.42",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2629.58",
    PLAN_TOTAL_PREMIUM: "40195.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28400001",
    TOTAL_SUMINS_MAX: "28500000",
    PLAN_NET_PREMIUM_AMT: "37512.55",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2636.45",
    PLAN_TOTAL_PREMIUM: "40300.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28500001",
    TOTAL_SUMINS_MAX: "28600000",
    PLAN_NET_PREMIUM_AMT: "37610.68",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2643.32",
    PLAN_TOTAL_PREMIUM: "40405.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28600001",
    TOTAL_SUMINS_MAX: "28700000",
    PLAN_NET_PREMIUM_AMT: "37708.81",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2650.19",
    PLAN_TOTAL_PREMIUM: "40510.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28700001",
    TOTAL_SUMINS_MAX: "28800000",
    PLAN_NET_PREMIUM_AMT: "37805.94",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2657.06",
    PLAN_TOTAL_PREMIUM: "40615.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28800001",
    TOTAL_SUMINS_MAX: "28900000",
    PLAN_NET_PREMIUM_AMT: "37904.07",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2663.93",
    PLAN_TOTAL_PREMIUM: "40720.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28900001",
    TOTAL_SUMINS_MAX: "29000000",
    PLAN_NET_PREMIUM_AMT: "38001.21",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2670.79",
    PLAN_TOTAL_PREMIUM: "40825.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29000001",
    TOTAL_SUMINS_MAX: "29100000",
    PLAN_NET_PREMIUM_AMT: "38099.34",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2677.66",
    PLAN_TOTAL_PREMIUM: "40930.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29100001",
    TOTAL_SUMINS_MAX: "29200000",
    PLAN_NET_PREMIUM_AMT: "38197.47",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2684.53",
    PLAN_TOTAL_PREMIUM: "41035.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29200001",
    TOTAL_SUMINS_MAX: "29300000",
    PLAN_NET_PREMIUM_AMT: "38294.60",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2691.40",
    PLAN_TOTAL_PREMIUM: "41140.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29300001",
    TOTAL_SUMINS_MAX: "29400000",
    PLAN_NET_PREMIUM_AMT: "38392.73",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2698.27",
    PLAN_TOTAL_PREMIUM: "41245.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29400001",
    TOTAL_SUMINS_MAX: "29500000",
    PLAN_NET_PREMIUM_AMT: "38490.86",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2705.14",
    PLAN_TOTAL_PREMIUM: "41350.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29500001",
    TOTAL_SUMINS_MAX: "29600000",
    PLAN_NET_PREMIUM_AMT: "38587.99",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2712.01",
    PLAN_TOTAL_PREMIUM: "41455.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29600001",
    TOTAL_SUMINS_MAX: "29700000",
    PLAN_NET_PREMIUM_AMT: "38686.12",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2718.88",
    PLAN_TOTAL_PREMIUM: "41560.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29700001",
    TOTAL_SUMINS_MAX: "29800000",
    PLAN_NET_PREMIUM_AMT: "38783.25",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2725.75",
    PLAN_TOTAL_PREMIUM: "41665.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29800001",
    TOTAL_SUMINS_MAX: "29900000",
    PLAN_NET_PREMIUM_AMT: "38881.38",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2732.62",
    PLAN_TOTAL_PREMIUM: "41770.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29900001",
    TOTAL_SUMINS_MAX: "30000000",
    PLAN_NET_PREMIUM_AMT: "38979.51",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2739.49",
    PLAN_TOTAL_PREMIUM: "41875.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30000001",
    TOTAL_SUMINS_MAX: "30100000",
    PLAN_NET_PREMIUM_AMT: "39076.64",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2746.36",
    PLAN_TOTAL_PREMIUM: "41980.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30100001",
    TOTAL_SUMINS_MAX: "30200000",
    PLAN_NET_PREMIUM_AMT: "39174.78",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2753.22",
    PLAN_TOTAL_PREMIUM: "42085.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30200001",
    TOTAL_SUMINS_MAX: "30300000",
    PLAN_NET_PREMIUM_AMT: "39271.91",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2760.09",
    PLAN_TOTAL_PREMIUM: "42190.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30300001",
    TOTAL_SUMINS_MAX: "30400000",
    PLAN_NET_PREMIUM_AMT: "39370.04",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2766.96",
    PLAN_TOTAL_PREMIUM: "42295.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30400001",
    TOTAL_SUMINS_MAX: "30500000",
    PLAN_NET_PREMIUM_AMT: "39468.17",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2773.83",
    PLAN_TOTAL_PREMIUM: "42400.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30500001",
    TOTAL_SUMINS_MAX: "30600000",
    PLAN_NET_PREMIUM_AMT: "39565.30",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2780.70",
    PLAN_TOTAL_PREMIUM: "42505.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30600001",
    TOTAL_SUMINS_MAX: "30700000",
    PLAN_NET_PREMIUM_AMT: "39663.43",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2787.57",
    PLAN_TOTAL_PREMIUM: "42610.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30700001",
    TOTAL_SUMINS_MAX: "30800000",
    PLAN_NET_PREMIUM_AMT: "39760.56",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2794.44",
    PLAN_TOTAL_PREMIUM: "42715.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30800001",
    TOTAL_SUMINS_MAX: "30900000",
    PLAN_NET_PREMIUM_AMT: "39858.69",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2801.31",
    PLAN_TOTAL_PREMIUM: "42820.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30900001",
    TOTAL_SUMINS_MAX: "31000000",
    PLAN_NET_PREMIUM_AMT: "39956.82",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2808.18",
    PLAN_TOTAL_PREMIUM: "42925.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31000001",
    TOTAL_SUMINS_MAX: "31100000",
    PLAN_NET_PREMIUM_AMT: "40053.95",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2815.05",
    PLAN_TOTAL_PREMIUM: "43030.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31100001",
    TOTAL_SUMINS_MAX: "31200000",
    PLAN_NET_PREMIUM_AMT: "40152.08",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2821.92",
    PLAN_TOTAL_PREMIUM: "43135.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31200001",
    TOTAL_SUMINS_MAX: "31300000",
    PLAN_NET_PREMIUM_AMT: "40249.21",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2828.79",
    PLAN_TOTAL_PREMIUM: "43240.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31300001",
    TOTAL_SUMINS_MAX: "31400000",
    PLAN_NET_PREMIUM_AMT: "40347.35",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2835.65",
    PLAN_TOTAL_PREMIUM: "43345.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31400001",
    TOTAL_SUMINS_MAX: "31500000",
    PLAN_NET_PREMIUM_AMT: "40445.48",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2842.52",
    PLAN_TOTAL_PREMIUM: "43450.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31500001",
    TOTAL_SUMINS_MAX: "31600000",
    PLAN_NET_PREMIUM_AMT: "40542.61",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2849.39",
    PLAN_TOTAL_PREMIUM: "43555.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31600001",
    TOTAL_SUMINS_MAX: "31700000",
    PLAN_NET_PREMIUM_AMT: "40640.74",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2856.26",
    PLAN_TOTAL_PREMIUM: "43660.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31700001",
    TOTAL_SUMINS_MAX: "31800000",
    PLAN_NET_PREMIUM_AMT: "40738.87",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2863.13",
    PLAN_TOTAL_PREMIUM: "43765.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31800001",
    TOTAL_SUMINS_MAX: "31900000",
    PLAN_NET_PREMIUM_AMT: "40836.00",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2870.00",
    PLAN_TOTAL_PREMIUM: "43870.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31900001",
    TOTAL_SUMINS_MAX: "32000000",
    PLAN_NET_PREMIUM_AMT: "40934.13",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2876.87",
    PLAN_TOTAL_PREMIUM: "43975.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32000001",
    TOTAL_SUMINS_MAX: "32100000",
    PLAN_NET_PREMIUM_AMT: "41031.26",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2883.74",
    PLAN_TOTAL_PREMIUM: "44080.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32100001",
    TOTAL_SUMINS_MAX: "32200000",
    PLAN_NET_PREMIUM_AMT: "41129.39",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2890.61",
    PLAN_TOTAL_PREMIUM: "44185.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32200001",
    TOTAL_SUMINS_MAX: "32300000",
    PLAN_NET_PREMIUM_AMT: "41227.52",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2897.48",
    PLAN_TOTAL_PREMIUM: "44290.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32300001",
    TOTAL_SUMINS_MAX: "32400000",
    PLAN_NET_PREMIUM_AMT: "41324.65",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2904.35",
    PLAN_TOTAL_PREMIUM: "44395.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32400001",
    TOTAL_SUMINS_MAX: "32500000",
    PLAN_NET_PREMIUM_AMT: "41422.78",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2911.22",
    PLAN_TOTAL_PREMIUM: "44500.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32500001",
    TOTAL_SUMINS_MAX: "32600000",
    PLAN_NET_PREMIUM_AMT: "41519.92",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2918.08",
    PLAN_TOTAL_PREMIUM: "44605.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32600001",
    TOTAL_SUMINS_MAX: "32700000",
    PLAN_NET_PREMIUM_AMT: "41618.05",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2924.95",
    PLAN_TOTAL_PREMIUM: "44710.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32700001",
    TOTAL_SUMINS_MAX: "32800000",
    PLAN_NET_PREMIUM_AMT: "41716.18",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2931.82",
    PLAN_TOTAL_PREMIUM: "44815.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32800001",
    TOTAL_SUMINS_MAX: "32900000",
    PLAN_NET_PREMIUM_AMT: "41813.31",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2938.69",
    PLAN_TOTAL_PREMIUM: "44920.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32900001",
    TOTAL_SUMINS_MAX: "33000000",
    PLAN_NET_PREMIUM_AMT: "41911.44",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2945.56",
    PLAN_TOTAL_PREMIUM: "45025.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33000001",
    TOTAL_SUMINS_MAX: "33100000",
    PLAN_NET_PREMIUM_AMT: "42008.57",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2952.43",
    PLAN_TOTAL_PREMIUM: "45130.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33100001",
    TOTAL_SUMINS_MAX: "33200000",
    PLAN_NET_PREMIUM_AMT: "42106.70",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2959.30",
    PLAN_TOTAL_PREMIUM: "45235.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33200001",
    TOTAL_SUMINS_MAX: "33300000",
    PLAN_NET_PREMIUM_AMT: "42204.83",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2966.17",
    PLAN_TOTAL_PREMIUM: "45340.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33300001",
    TOTAL_SUMINS_MAX: "33400000",
    PLAN_NET_PREMIUM_AMT: "42301.96",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2973.04",
    PLAN_TOTAL_PREMIUM: "45445.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33400001",
    TOTAL_SUMINS_MAX: "33500000",
    PLAN_NET_PREMIUM_AMT: "42400.09",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2979.91",
    PLAN_TOTAL_PREMIUM: "45550.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33500001",
    TOTAL_SUMINS_MAX: "33600000",
    PLAN_NET_PREMIUM_AMT: "42498.22",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2986.78",
    PLAN_TOTAL_PREMIUM: "45655.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33600001",
    TOTAL_SUMINS_MAX: "33700000",
    PLAN_NET_PREMIUM_AMT: "42595.35",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2993.65",
    PLAN_TOTAL_PREMIUM: "45760.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33700001",
    TOTAL_SUMINS_MAX: "33800000",
    PLAN_NET_PREMIUM_AMT: "42693.49",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "3000.51",
    PLAN_TOTAL_PREMIUM: "45865.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33800001",
    TOTAL_SUMINS_MAX: "33900000",
    PLAN_NET_PREMIUM_AMT: "42790.62",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3007.38",
    PLAN_TOTAL_PREMIUM: "45970.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33900001",
    TOTAL_SUMINS_MAX: "34000000",
    PLAN_NET_PREMIUM_AMT: "42888.75",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3014.25",
    PLAN_TOTAL_PREMIUM: "46075.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34000001",
    TOTAL_SUMINS_MAX: "34100000",
    PLAN_NET_PREMIUM_AMT: "42986.88",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3021.12",
    PLAN_TOTAL_PREMIUM: "46180.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34100001",
    TOTAL_SUMINS_MAX: "34200000",
    PLAN_NET_PREMIUM_AMT: "43084.01",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3027.99",
    PLAN_TOTAL_PREMIUM: "46285.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34200001",
    TOTAL_SUMINS_MAX: "34300000",
    PLAN_NET_PREMIUM_AMT: "43182.14",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3034.86",
    PLAN_TOTAL_PREMIUM: "46390.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34300001",
    TOTAL_SUMINS_MAX: "34400000",
    PLAN_NET_PREMIUM_AMT: "43279.27",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3041.73",
    PLAN_TOTAL_PREMIUM: "46495.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34400001",
    TOTAL_SUMINS_MAX: "34500000",
    PLAN_NET_PREMIUM_AMT: "43377.40",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3048.60",
    PLAN_TOTAL_PREMIUM: "46600.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34500001",
    TOTAL_SUMINS_MAX: "34600000",
    PLAN_NET_PREMIUM_AMT: "43475.53",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3055.47",
    PLAN_TOTAL_PREMIUM: "46705.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34600001",
    TOTAL_SUMINS_MAX: "34700000",
    PLAN_NET_PREMIUM_AMT: "43572.66",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3062.34",
    PLAN_TOTAL_PREMIUM: "46810.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34700001",
    TOTAL_SUMINS_MAX: "34800000",
    PLAN_NET_PREMIUM_AMT: "43670.79",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3069.21",
    PLAN_TOTAL_PREMIUM: "46915.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34800001",
    TOTAL_SUMINS_MAX: "34900000",
    PLAN_NET_PREMIUM_AMT: "43767.92",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3076.08",
    PLAN_TOTAL_PREMIUM: "47020.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34900001",
    TOTAL_SUMINS_MAX: "35000000",
    PLAN_NET_PREMIUM_AMT: "43866.06",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3082.94",
    PLAN_TOTAL_PREMIUM: "47125.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35000001",
    TOTAL_SUMINS_MAX: "35100000",
    PLAN_NET_PREMIUM_AMT: "43964.19",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3089.81",
    PLAN_TOTAL_PREMIUM: "47230.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35100001",
    TOTAL_SUMINS_MAX: "35200000",
    PLAN_NET_PREMIUM_AMT: "44061.32",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3096.68",
    PLAN_TOTAL_PREMIUM: "47335.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35200001",
    TOTAL_SUMINS_MAX: "35300000",
    PLAN_NET_PREMIUM_AMT: "44159.45",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3103.55",
    PLAN_TOTAL_PREMIUM: "47440.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35300001",
    TOTAL_SUMINS_MAX: "35400000",
    PLAN_NET_PREMIUM_AMT: "44256.58",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3110.42",
    PLAN_TOTAL_PREMIUM: "47545.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35400001",
    TOTAL_SUMINS_MAX: "35500000",
    PLAN_NET_PREMIUM_AMT: "44354.71",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3117.29",
    PLAN_TOTAL_PREMIUM: "47650.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35500001",
    TOTAL_SUMINS_MAX: "35600000",
    PLAN_NET_PREMIUM_AMT: "44452.84",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3124.16",
    PLAN_TOTAL_PREMIUM: "47755.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35600001",
    TOTAL_SUMINS_MAX: "35700000",
    PLAN_NET_PREMIUM_AMT: "44549.97",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3131.03",
    PLAN_TOTAL_PREMIUM: "47860.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35700001",
    TOTAL_SUMINS_MAX: "35800000",
    PLAN_NET_PREMIUM_AMT: "44648.10",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3137.90",
    PLAN_TOTAL_PREMIUM: "47965.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35800001",
    TOTAL_SUMINS_MAX: "35900000",
    PLAN_NET_PREMIUM_AMT: "44746.23",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3144.77",
    PLAN_TOTAL_PREMIUM: "48070.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35900001",
    TOTAL_SUMINS_MAX: "36000000",
    PLAN_NET_PREMIUM_AMT: "44843.36",
    PLAN_STAMP_DUTY_AMT: "180.00",
    PLAN_VAT_AMT: "3151.64",
    PLAN_TOTAL_PREMIUM: "48175.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36000001",
    TOTAL_SUMINS_MAX: "36100000",
    PLAN_NET_PREMIUM_AMT: "44941.49",
    PLAN_STAMP_DUTY_AMT: "180.00",
    PLAN_VAT_AMT: "3158.51",
    PLAN_TOTAL_PREMIUM: "48280.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36100001",
    TOTAL_SUMINS_MAX: "36200000",
    PLAN_NET_PREMIUM_AMT: "45038.63",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3165.37",
    PLAN_TOTAL_PREMIUM: "48385.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36200001",
    TOTAL_SUMINS_MAX: "36300000",
    PLAN_NET_PREMIUM_AMT: "45136.76",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3172.24",
    PLAN_TOTAL_PREMIUM: "48490.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36300001",
    TOTAL_SUMINS_MAX: "36400000",
    PLAN_NET_PREMIUM_AMT: "45234.89",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3179.11",
    PLAN_TOTAL_PREMIUM: "48595.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36400001",
    TOTAL_SUMINS_MAX: "36500000",
    PLAN_NET_PREMIUM_AMT: "45332.02",
    PLAN_STAMP_DUTY_AMT: "182.00",
    PLAN_VAT_AMT: "3185.98",
    PLAN_TOTAL_PREMIUM: "48700.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36500001",
    TOTAL_SUMINS_MAX: "36600000",
    PLAN_NET_PREMIUM_AMT: "45430.15",
    PLAN_STAMP_DUTY_AMT: "182.00",
    PLAN_VAT_AMT: "3192.85",
    PLAN_TOTAL_PREMIUM: "48805.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36600001",
    TOTAL_SUMINS_MAX: "36700000",
    PLAN_NET_PREMIUM_AMT: "45527.28",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3199.72",
    PLAN_TOTAL_PREMIUM: "48910.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36700001",
    TOTAL_SUMINS_MAX: "36800000",
    PLAN_NET_PREMIUM_AMT: "45625.41",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3206.59",
    PLAN_TOTAL_PREMIUM: "49015.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36800001",
    TOTAL_SUMINS_MAX: "36900000",
    PLAN_NET_PREMIUM_AMT: "45723.54",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3213.46",
    PLAN_TOTAL_PREMIUM: "49120.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36900001",
    TOTAL_SUMINS_MAX: "37000000",
    PLAN_NET_PREMIUM_AMT: "45820.67",
    PLAN_STAMP_DUTY_AMT: "184.00",
    PLAN_VAT_AMT: "3220.33",
    PLAN_TOTAL_PREMIUM: "49225.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37000001",
    TOTAL_SUMINS_MAX: "37100000",
    PLAN_NET_PREMIUM_AMT: "45918.80",
    PLAN_STAMP_DUTY_AMT: "184.00",
    PLAN_VAT_AMT: "3227.20",
    PLAN_TOTAL_PREMIUM: "49330.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37100001",
    TOTAL_SUMINS_MAX: "37200000",
    PLAN_NET_PREMIUM_AMT: "46015.93",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3234.07",
    PLAN_TOTAL_PREMIUM: "49435.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37200001",
    TOTAL_SUMINS_MAX: "37300000",
    PLAN_NET_PREMIUM_AMT: "46114.07",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3240.93",
    PLAN_TOTAL_PREMIUM: "49540.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37300001",
    TOTAL_SUMINS_MAX: "37400000",
    PLAN_NET_PREMIUM_AMT: "46212.20",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3247.80",
    PLAN_TOTAL_PREMIUM: "49645.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37400001",
    TOTAL_SUMINS_MAX: "37500000",
    PLAN_NET_PREMIUM_AMT: "46309.33",
    PLAN_STAMP_DUTY_AMT: "186.00",
    PLAN_VAT_AMT: "3254.67",
    PLAN_TOTAL_PREMIUM: "49750.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37500001",
    TOTAL_SUMINS_MAX: "37600000",
    PLAN_NET_PREMIUM_AMT: "46407.46",
    PLAN_STAMP_DUTY_AMT: "186.00",
    PLAN_VAT_AMT: "3261.54",
    PLAN_TOTAL_PREMIUM: "49855.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37600001",
    TOTAL_SUMINS_MAX: "37700000",
    PLAN_NET_PREMIUM_AMT: "46504.59",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3268.41",
    PLAN_TOTAL_PREMIUM: "49960.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37700001",
    TOTAL_SUMINS_MAX: "37800000",
    PLAN_NET_PREMIUM_AMT: "46602.72",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3275.28",
    PLAN_TOTAL_PREMIUM: "50065.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37800001",
    TOTAL_SUMINS_MAX: "37900000",
    PLAN_NET_PREMIUM_AMT: "46700.85",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3282.15",
    PLAN_TOTAL_PREMIUM: "50170.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37900001",
    TOTAL_SUMINS_MAX: "38000000",
    PLAN_NET_PREMIUM_AMT: "46797.98",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3289.02",
    PLAN_TOTAL_PREMIUM: "50275.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38000001",
    TOTAL_SUMINS_MAX: "38100000",
    PLAN_NET_PREMIUM_AMT: "46896.11",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3295.89",
    PLAN_TOTAL_PREMIUM: "50380.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38100001",
    TOTAL_SUMINS_MAX: "38200000",
    PLAN_NET_PREMIUM_AMT: "46994.24",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3302.76",
    PLAN_TOTAL_PREMIUM: "50485.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38200001",
    TOTAL_SUMINS_MAX: "38300000",
    PLAN_NET_PREMIUM_AMT: "47091.37",
    PLAN_STAMP_DUTY_AMT: "189.00",
    PLAN_VAT_AMT: "3309.63",
    PLAN_TOTAL_PREMIUM: "50590.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38300001",
    TOTAL_SUMINS_MAX: "38400000",
    PLAN_NET_PREMIUM_AMT: "47189.50",
    PLAN_STAMP_DUTY_AMT: "189.00",
    PLAN_VAT_AMT: "3316.50",
    PLAN_TOTAL_PREMIUM: "50695.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38400001",
    TOTAL_SUMINS_MAX: "38500000",
    PLAN_NET_PREMIUM_AMT: "47286.64",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3323.36",
    PLAN_TOTAL_PREMIUM: "50800.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38500001",
    TOTAL_SUMINS_MAX: "38600000",
    PLAN_NET_PREMIUM_AMT: "47384.77",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3330.23",
    PLAN_TOTAL_PREMIUM: "50905.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38600001",
    TOTAL_SUMINS_MAX: "38700000",
    PLAN_NET_PREMIUM_AMT: "47482.90",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3337.10",
    PLAN_TOTAL_PREMIUM: "51010.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38700001",
    TOTAL_SUMINS_MAX: "38800000",
    PLAN_NET_PREMIUM_AMT: "47580.03",
    PLAN_STAMP_DUTY_AMT: "191.00",
    PLAN_VAT_AMT: "3343.97",
    PLAN_TOTAL_PREMIUM: "51115.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38800001",
    TOTAL_SUMINS_MAX: "38900000",
    PLAN_NET_PREMIUM_AMT: "47678.16",
    PLAN_STAMP_DUTY_AMT: "191.00",
    PLAN_VAT_AMT: "3350.84",
    PLAN_TOTAL_PREMIUM: "51220.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38900001",
    TOTAL_SUMINS_MAX: "39000000",
    PLAN_NET_PREMIUM_AMT: "47775.29",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3357.71",
    PLAN_TOTAL_PREMIUM: "51325.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39000001",
    TOTAL_SUMINS_MAX: "39100000",
    PLAN_NET_PREMIUM_AMT: "47873.42",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3364.58",
    PLAN_TOTAL_PREMIUM: "51430.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39100001",
    TOTAL_SUMINS_MAX: "39200000",
    PLAN_NET_PREMIUM_AMT: "47971.55",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3371.45",
    PLAN_TOTAL_PREMIUM: "51535.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39200001",
    TOTAL_SUMINS_MAX: "39300000",
    PLAN_NET_PREMIUM_AMT: "48068.68",
    PLAN_STAMP_DUTY_AMT: "193.00",
    PLAN_VAT_AMT: "3378.32",
    PLAN_TOTAL_PREMIUM: "51640.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39300001",
    TOTAL_SUMINS_MAX: "39400000",
    PLAN_NET_PREMIUM_AMT: "48166.81",
    PLAN_STAMP_DUTY_AMT: "193.00",
    PLAN_VAT_AMT: "3385.19",
    PLAN_TOTAL_PREMIUM: "51745.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39400001",
    TOTAL_SUMINS_MAX: "39500000",
    PLAN_NET_PREMIUM_AMT: "48263.94",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3392.06",
    PLAN_TOTAL_PREMIUM: "51850.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39500001",
    TOTAL_SUMINS_MAX: "39600000",
    PLAN_NET_PREMIUM_AMT: "48362.07",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3398.93",
    PLAN_TOTAL_PREMIUM: "51955.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39600001",
    TOTAL_SUMINS_MAX: "39700000",
    PLAN_NET_PREMIUM_AMT: "48460.21",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3405.79",
    PLAN_TOTAL_PREMIUM: "52060.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39700001",
    TOTAL_SUMINS_MAX: "39800000",
    PLAN_NET_PREMIUM_AMT: "48557.34",
    PLAN_STAMP_DUTY_AMT: "195.00",
    PLAN_VAT_AMT: "3412.66",
    PLAN_TOTAL_PREMIUM: "52165.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39800001",
    TOTAL_SUMINS_MAX: "39900000",
    PLAN_NET_PREMIUM_AMT: "48655.47",
    PLAN_STAMP_DUTY_AMT: "195.00",
    PLAN_VAT_AMT: "3419.53",
    PLAN_TOTAL_PREMIUM: "52270.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39900001",
    TOTAL_SUMINS_MAX: "40000000",
    PLAN_NET_PREMIUM_AMT: "48752.60",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3426.40",
    PLAN_TOTAL_PREMIUM: "52375.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40000001",
    TOTAL_SUMINS_MAX: "40100000",
    PLAN_NET_PREMIUM_AMT: "48850.73",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3433.27",
    PLAN_TOTAL_PREMIUM: "52480.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40100001",
    TOTAL_SUMINS_MAX: "40200000",
    PLAN_NET_PREMIUM_AMT: "48948.86",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3440.14",
    PLAN_TOTAL_PREMIUM: "52585.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40200001",
    TOTAL_SUMINS_MAX: "40300000",
    PLAN_NET_PREMIUM_AMT: "49045.99",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3447.01",
    PLAN_TOTAL_PREMIUM: "52690.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40300001",
    TOTAL_SUMINS_MAX: "40400000",
    PLAN_NET_PREMIUM_AMT: "49144.12",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3453.88",
    PLAN_TOTAL_PREMIUM: "52795.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40400001",
    TOTAL_SUMINS_MAX: "40500000",
    PLAN_NET_PREMIUM_AMT: "49242.25",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3460.75",
    PLAN_TOTAL_PREMIUM: "52900.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40500001",
    TOTAL_SUMINS_MAX: "40600000",
    PLAN_NET_PREMIUM_AMT: "49339.38",
    PLAN_STAMP_DUTY_AMT: "198.00",
    PLAN_VAT_AMT: "3467.62",
    PLAN_TOTAL_PREMIUM: "53005.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40600001",
    TOTAL_SUMINS_MAX: "40700000",
    PLAN_NET_PREMIUM_AMT: "49437.51",
    PLAN_STAMP_DUTY_AMT: "198.00",
    PLAN_VAT_AMT: "3474.49",
    PLAN_TOTAL_PREMIUM: "53110.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40700001",
    TOTAL_SUMINS_MAX: "40800000",
    PLAN_NET_PREMIUM_AMT: "49534.64",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3481.36",
    PLAN_TOTAL_PREMIUM: "53215.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40800001",
    TOTAL_SUMINS_MAX: "40900000",
    PLAN_NET_PREMIUM_AMT: "49632.78",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3488.22",
    PLAN_TOTAL_PREMIUM: "53320.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40900001",
    TOTAL_SUMINS_MAX: "41000000",
    PLAN_NET_PREMIUM_AMT: "49730.91",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3495.09",
    PLAN_TOTAL_PREMIUM: "53425.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41000001",
    TOTAL_SUMINS_MAX: "41100000",
    PLAN_NET_PREMIUM_AMT: "49828.04",
    PLAN_STAMP_DUTY_AMT: "200.00",
    PLAN_VAT_AMT: "3501.96",
    PLAN_TOTAL_PREMIUM: "53530.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41100001",
    TOTAL_SUMINS_MAX: "41200000",
    PLAN_NET_PREMIUM_AMT: "49926.17",
    PLAN_STAMP_DUTY_AMT: "200.00",
    PLAN_VAT_AMT: "3508.83",
    PLAN_TOTAL_PREMIUM: "53635.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41200001",
    TOTAL_SUMINS_MAX: "41300000",
    PLAN_NET_PREMIUM_AMT: "50023.30",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3515.70",
    PLAN_TOTAL_PREMIUM: "53740.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41300001",
    TOTAL_SUMINS_MAX: "41400000",
    PLAN_NET_PREMIUM_AMT: "50121.43",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3522.57",
    PLAN_TOTAL_PREMIUM: "53845.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41400001",
    TOTAL_SUMINS_MAX: "41500000",
    PLAN_NET_PREMIUM_AMT: "50219.56",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3529.44",
    PLAN_TOTAL_PREMIUM: "53950.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41500001",
    TOTAL_SUMINS_MAX: "41600000",
    PLAN_NET_PREMIUM_AMT: "50316.69",
    PLAN_STAMP_DUTY_AMT: "202.00",
    PLAN_VAT_AMT: "3536.31",
    PLAN_TOTAL_PREMIUM: "54055.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41600001",
    TOTAL_SUMINS_MAX: "41700000",
    PLAN_NET_PREMIUM_AMT: "50414.82",
    PLAN_STAMP_DUTY_AMT: "202.00",
    PLAN_VAT_AMT: "3543.18",
    PLAN_TOTAL_PREMIUM: "54160.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41700001",
    TOTAL_SUMINS_MAX: "41800000",
    PLAN_NET_PREMIUM_AMT: "50511.95",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3550.05",
    PLAN_TOTAL_PREMIUM: "54265.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41800001",
    TOTAL_SUMINS_MAX: "41900000",
    PLAN_NET_PREMIUM_AMT: "50610.08",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3556.92",
    PLAN_TOTAL_PREMIUM: "54370.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41900001",
    TOTAL_SUMINS_MAX: "42000000",
    PLAN_NET_PREMIUM_AMT: "50708.21",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3563.79",
    PLAN_TOTAL_PREMIUM: "54475.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42000001",
    TOTAL_SUMINS_MAX: "42100000",
    PLAN_NET_PREMIUM_AMT: "50805.35",
    PLAN_STAMP_DUTY_AMT: "204.00",
    PLAN_VAT_AMT: "3570.65",
    PLAN_TOTAL_PREMIUM: "54580.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42100001",
    TOTAL_SUMINS_MAX: "42200000",
    PLAN_NET_PREMIUM_AMT: "50903.48",
    PLAN_STAMP_DUTY_AMT: "204.00",
    PLAN_VAT_AMT: "3577.52",
    PLAN_TOTAL_PREMIUM: "54685.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42200001",
    TOTAL_SUMINS_MAX: "42300000",
    PLAN_NET_PREMIUM_AMT: "51000.61",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3584.39",
    PLAN_TOTAL_PREMIUM: "54790.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42300001",
    TOTAL_SUMINS_MAX: "42400000",
    PLAN_NET_PREMIUM_AMT: "51098.74",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3591.26",
    PLAN_TOTAL_PREMIUM: "54895.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42400001",
    TOTAL_SUMINS_MAX: "42500000",
    PLAN_NET_PREMIUM_AMT: "51196.87",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3598.13",
    PLAN_TOTAL_PREMIUM: "55000.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42500001",
    TOTAL_SUMINS_MAX: "42600000",
    PLAN_NET_PREMIUM_AMT: "51294.00",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3605.00",
    PLAN_TOTAL_PREMIUM: "55105.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42600001",
    TOTAL_SUMINS_MAX: "42700000",
    PLAN_NET_PREMIUM_AMT: "51392.13",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3611.87",
    PLAN_TOTAL_PREMIUM: "55210.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42700001",
    TOTAL_SUMINS_MAX: "42800000",
    PLAN_NET_PREMIUM_AMT: "51490.26",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3618.74",
    PLAN_TOTAL_PREMIUM: "55315.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42800001",
    TOTAL_SUMINS_MAX: "42900000",
    PLAN_NET_PREMIUM_AMT: "51587.39",
    PLAN_STAMP_DUTY_AMT: "207.00",
    PLAN_VAT_AMT: "3625.61",
    PLAN_TOTAL_PREMIUM: "55420.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42900001",
    TOTAL_SUMINS_MAX: "43000000",
    PLAN_NET_PREMIUM_AMT: "51685.52",
    PLAN_STAMP_DUTY_AMT: "207.00",
    PLAN_VAT_AMT: "3632.48",
    PLAN_TOTAL_PREMIUM: "55525.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43000001",
    TOTAL_SUMINS_MAX: "43100000",
    PLAN_NET_PREMIUM_AMT: "51782.65",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3639.35",
    PLAN_TOTAL_PREMIUM: "55630.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43100001",
    TOTAL_SUMINS_MAX: "43200000",
    PLAN_NET_PREMIUM_AMT: "51880.78",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3646.22",
    PLAN_TOTAL_PREMIUM: "55735.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43200001",
    TOTAL_SUMINS_MAX: "43300000",
    PLAN_NET_PREMIUM_AMT: "51978.92",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3653.08",
    PLAN_TOTAL_PREMIUM: "55840.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43300001",
    TOTAL_SUMINS_MAX: "43400000",
    PLAN_NET_PREMIUM_AMT: "52076.05",
    PLAN_STAMP_DUTY_AMT: "209.00",
    PLAN_VAT_AMT: "3659.95",
    PLAN_TOTAL_PREMIUM: "55945.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43400001",
    TOTAL_SUMINS_MAX: "43500000",
    PLAN_NET_PREMIUM_AMT: "52174.18",
    PLAN_STAMP_DUTY_AMT: "209.00",
    PLAN_VAT_AMT: "3666.82",
    PLAN_TOTAL_PREMIUM: "56050.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43500001",
    TOTAL_SUMINS_MAX: "43600000",
    PLAN_NET_PREMIUM_AMT: "52271.31",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3673.69",
    PLAN_TOTAL_PREMIUM: "56155.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43600001",
    TOTAL_SUMINS_MAX: "43700000",
    PLAN_NET_PREMIUM_AMT: "52369.44",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3680.56",
    PLAN_TOTAL_PREMIUM: "56260.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43700001",
    TOTAL_SUMINS_MAX: "43800000",
    PLAN_NET_PREMIUM_AMT: "52467.57",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3687.43",
    PLAN_TOTAL_PREMIUM: "56365.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43800001",
    TOTAL_SUMINS_MAX: "43900000",
    PLAN_NET_PREMIUM_AMT: "52564.70",
    PLAN_STAMP_DUTY_AMT: "211.00",
    PLAN_VAT_AMT: "3694.30",
    PLAN_TOTAL_PREMIUM: "56470.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43900001",
    TOTAL_SUMINS_MAX: "44000000",
    PLAN_NET_PREMIUM_AMT: "52662.83",
    PLAN_STAMP_DUTY_AMT: "211.00",
    PLAN_VAT_AMT: "3701.17",
    PLAN_TOTAL_PREMIUM: "56575.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44000001",
    TOTAL_SUMINS_MAX: "44100000",
    PLAN_NET_PREMIUM_AMT: "52759.96",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3708.04",
    PLAN_TOTAL_PREMIUM: "56680.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44100001",
    TOTAL_SUMINS_MAX: "44200000",
    PLAN_NET_PREMIUM_AMT: "52858.09",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3714.91",
    PLAN_TOTAL_PREMIUM: "56785.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44200001",
    TOTAL_SUMINS_MAX: "44300000",
    PLAN_NET_PREMIUM_AMT: "52956.22",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3721.78",
    PLAN_TOTAL_PREMIUM: "56890.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44300001",
    TOTAL_SUMINS_MAX: "44400000",
    PLAN_NET_PREMIUM_AMT: "53053.35",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3728.65",
    PLAN_TOTAL_PREMIUM: "56995.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44400001",
    TOTAL_SUMINS_MAX: "44500000",
    PLAN_NET_PREMIUM_AMT: "53151.49",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3735.51",
    PLAN_TOTAL_PREMIUM: "57100.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44500001",
    TOTAL_SUMINS_MAX: "44600000",
    PLAN_NET_PREMIUM_AMT: "53249.62",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3742.38",
    PLAN_TOTAL_PREMIUM: "57205.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44600001",
    TOTAL_SUMINS_MAX: "44700000",
    PLAN_NET_PREMIUM_AMT: "53346.75",
    PLAN_STAMP_DUTY_AMT: "214.00",
    PLAN_VAT_AMT: "3749.25",
    PLAN_TOTAL_PREMIUM: "57310.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44700001",
    TOTAL_SUMINS_MAX: "44800000",
    PLAN_NET_PREMIUM_AMT: "53444.88",
    PLAN_STAMP_DUTY_AMT: "214.00",
    PLAN_VAT_AMT: "3756.12",
    PLAN_TOTAL_PREMIUM: "57415.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44800001",
    TOTAL_SUMINS_MAX: "44900000",
    PLAN_NET_PREMIUM_AMT: "53542.01",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3762.99",
    PLAN_TOTAL_PREMIUM: "57520.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44900001",
    TOTAL_SUMINS_MAX: "45000000",
    PLAN_NET_PREMIUM_AMT: "53640.14",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3769.86",
    PLAN_TOTAL_PREMIUM: "57625.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45000001",
    TOTAL_SUMINS_MAX: "45100000",
    PLAN_NET_PREMIUM_AMT: "53738.27",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3776.73",
    PLAN_TOTAL_PREMIUM: "57730.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45100001",
    TOTAL_SUMINS_MAX: "45200000",
    PLAN_NET_PREMIUM_AMT: "53835.40",
    PLAN_STAMP_DUTY_AMT: "216.00",
    PLAN_VAT_AMT: "3783.60",
    PLAN_TOTAL_PREMIUM: "57835.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45200001",
    TOTAL_SUMINS_MAX: "45300000",
    PLAN_NET_PREMIUM_AMT: "53933.53",
    PLAN_STAMP_DUTY_AMT: "216.00",
    PLAN_VAT_AMT: "3790.47",
    PLAN_TOTAL_PREMIUM: "57940.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45300001",
    TOTAL_SUMINS_MAX: "45400000",
    PLAN_NET_PREMIUM_AMT: "54030.66",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3797.34",
    PLAN_TOTAL_PREMIUM: "58045.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45400001",
    TOTAL_SUMINS_MAX: "45500000",
    PLAN_NET_PREMIUM_AMT: "54128.79",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3804.21",
    PLAN_TOTAL_PREMIUM: "58150.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45500001",
    TOTAL_SUMINS_MAX: "45600000",
    PLAN_NET_PREMIUM_AMT: "54226.92",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3811.08",
    PLAN_TOTAL_PREMIUM: "58255.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45600001",
    TOTAL_SUMINS_MAX: "45700000",
    PLAN_NET_PREMIUM_AMT: "54324.06",
    PLAN_STAMP_DUTY_AMT: "218.00",
    PLAN_VAT_AMT: "3817.94",
    PLAN_TOTAL_PREMIUM: "58360.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45700001",
    TOTAL_SUMINS_MAX: "45800000",
    PLAN_NET_PREMIUM_AMT: "54422.19",
    PLAN_STAMP_DUTY_AMT: "218.00",
    PLAN_VAT_AMT: "3824.81",
    PLAN_TOTAL_PREMIUM: "58465.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45800001",
    TOTAL_SUMINS_MAX: "45900000",
    PLAN_NET_PREMIUM_AMT: "54519.32",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3831.68",
    PLAN_TOTAL_PREMIUM: "58570.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45900001",
    TOTAL_SUMINS_MAX: "46000000",
    PLAN_NET_PREMIUM_AMT: "54617.45",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3838.55",
    PLAN_TOTAL_PREMIUM: "58675.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46000001",
    TOTAL_SUMINS_MAX: "46100000",
    PLAN_NET_PREMIUM_AMT: "54715.58",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3845.42",
    PLAN_TOTAL_PREMIUM: "58780.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46100001",
    TOTAL_SUMINS_MAX: "46200000",
    PLAN_NET_PREMIUM_AMT: "54812.71",
    PLAN_STAMP_DUTY_AMT: "220.00",
    PLAN_VAT_AMT: "3852.29",
    PLAN_TOTAL_PREMIUM: "58885.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46200001",
    TOTAL_SUMINS_MAX: "46300000",
    PLAN_NET_PREMIUM_AMT: "54910.84",
    PLAN_STAMP_DUTY_AMT: "220.00",
    PLAN_VAT_AMT: "3859.16",
    PLAN_TOTAL_PREMIUM: "58990.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46300001",
    TOTAL_SUMINS_MAX: "46400000",
    PLAN_NET_PREMIUM_AMT: "55007.97",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3866.03",
    PLAN_TOTAL_PREMIUM: "59095.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46400001",
    TOTAL_SUMINS_MAX: "46500000",
    PLAN_NET_PREMIUM_AMT: "55106.10",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3872.90",
    PLAN_TOTAL_PREMIUM: "59200.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46500001",
    TOTAL_SUMINS_MAX: "46600000",
    PLAN_NET_PREMIUM_AMT: "55204.23",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3879.77",
    PLAN_TOTAL_PREMIUM: "59305.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46600001",
    TOTAL_SUMINS_MAX: "46700000",
    PLAN_NET_PREMIUM_AMT: "55301.36",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3886.64",
    PLAN_TOTAL_PREMIUM: "59410.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46700001",
    TOTAL_SUMINS_MAX: "46800000",
    PLAN_NET_PREMIUM_AMT: "55399.49",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3893.51",
    PLAN_TOTAL_PREMIUM: "59515.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46800001",
    TOTAL_SUMINS_MAX: "46900000",
    PLAN_NET_PREMIUM_AMT: "55497.63",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3900.37",
    PLAN_TOTAL_PREMIUM: "59620.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46900001",
    TOTAL_SUMINS_MAX: "47000000",
    PLAN_NET_PREMIUM_AMT: "55594.76",
    PLAN_STAMP_DUTY_AMT: "223.00",
    PLAN_VAT_AMT: "3907.24",
    PLAN_TOTAL_PREMIUM: "59725.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47000001",
    TOTAL_SUMINS_MAX: "47100000",
    PLAN_NET_PREMIUM_AMT: "55692.89",
    PLAN_STAMP_DUTY_AMT: "223.00",
    PLAN_VAT_AMT: "3914.11",
    PLAN_TOTAL_PREMIUM: "59830.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47100001",
    TOTAL_SUMINS_MAX: "47200000",
    PLAN_NET_PREMIUM_AMT: "55790.02",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3920.98",
    PLAN_TOTAL_PREMIUM: "59935.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47200001",
    TOTAL_SUMINS_MAX: "47300000",
    PLAN_NET_PREMIUM_AMT: "55888.15",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3927.85",
    PLAN_TOTAL_PREMIUM: "60040.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47300001",
    TOTAL_SUMINS_MAX: "47400000",
    PLAN_NET_PREMIUM_AMT: "55986.28",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3934.72",
    PLAN_TOTAL_PREMIUM: "60145.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47400001",
    TOTAL_SUMINS_MAX: "47500000",
    PLAN_NET_PREMIUM_AMT: "56083.41",
    PLAN_STAMP_DUTY_AMT: "225.00",
    PLAN_VAT_AMT: "3941.59",
    PLAN_TOTAL_PREMIUM: "60250.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47500001",
    TOTAL_SUMINS_MAX: "47600000",
    PLAN_NET_PREMIUM_AMT: "56181.54",
    PLAN_STAMP_DUTY_AMT: "225.00",
    PLAN_VAT_AMT: "3948.46",
    PLAN_TOTAL_PREMIUM: "60355.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47600001",
    TOTAL_SUMINS_MAX: "47700000",
    PLAN_NET_PREMIUM_AMT: "56278.67",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3955.33",
    PLAN_TOTAL_PREMIUM: "60460.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47700001",
    TOTAL_SUMINS_MAX: "47800000",
    PLAN_NET_PREMIUM_AMT: "56376.80",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3962.20",
    PLAN_TOTAL_PREMIUM: "60565.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47800001",
    TOTAL_SUMINS_MAX: "47900000",
    PLAN_NET_PREMIUM_AMT: "56474.93",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3969.07",
    PLAN_TOTAL_PREMIUM: "60670.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47900001",
    TOTAL_SUMINS_MAX: "48000000",
    PLAN_NET_PREMIUM_AMT: "56572.07",
    PLAN_STAMP_DUTY_AMT: "227.00",
    PLAN_VAT_AMT: "3975.93",
    PLAN_TOTAL_PREMIUM: "60775.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48000001",
    TOTAL_SUMINS_MAX: "48100000",
    PLAN_NET_PREMIUM_AMT: "56670.20",
    PLAN_STAMP_DUTY_AMT: "227.00",
    PLAN_VAT_AMT: "3982.80",
    PLAN_TOTAL_PREMIUM: "60880.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48100001",
    TOTAL_SUMINS_MAX: "48200000",
    PLAN_NET_PREMIUM_AMT: "56767.33",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "3989.67",
    PLAN_TOTAL_PREMIUM: "60985.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48200001",
    TOTAL_SUMINS_MAX: "48300000",
    PLAN_NET_PREMIUM_AMT: "56865.46",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "3996.54",
    PLAN_TOTAL_PREMIUM: "61090.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48300001",
    TOTAL_SUMINS_MAX: "48400000",
    PLAN_NET_PREMIUM_AMT: "56963.59",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "4003.41",
    PLAN_TOTAL_PREMIUM: "61195.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48400001",
    TOTAL_SUMINS_MAX: "48500000",
    PLAN_NET_PREMIUM_AMT: "57060.72",
    PLAN_STAMP_DUTY_AMT: "229.00",
    PLAN_VAT_AMT: "4010.28",
    PLAN_TOTAL_PREMIUM: "61300.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48500001",
    TOTAL_SUMINS_MAX: "48600000",
    PLAN_NET_PREMIUM_AMT: "57158.85",
    PLAN_STAMP_DUTY_AMT: "229.00",
    PLAN_VAT_AMT: "4017.15",
    PLAN_TOTAL_PREMIUM: "61405.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48600001",
    TOTAL_SUMINS_MAX: "48700000",
    PLAN_NET_PREMIUM_AMT: "57255.98",
    PLAN_STAMP_DUTY_AMT: "230.00",
    PLAN_VAT_AMT: "4024.02",
    PLAN_TOTAL_PREMIUM: "61510.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48700001",
    TOTAL_SUMINS_MAX: "48800000",
    PLAN_NET_PREMIUM_AMT: "57354.11",
    PLAN_STAMP_DUTY_AMT: "230.00",
    PLAN_VAT_AMT: "4030.89",
    PLAN_TOTAL_PREMIUM: "61615.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48800001",
    TOTAL_SUMINS_MAX: "48900000",
    PLAN_NET_PREMIUM_AMT: "57452.24",
    PLAN_STAMP_DUTY_AMT: "230.00",
    PLAN_VAT_AMT: "4037.76",
    PLAN_TOTAL_PREMIUM: "61720.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48900001",
    TOTAL_SUMINS_MAX: "49000000",
    PLAN_NET_PREMIUM_AMT: "57549.37",
    PLAN_STAMP_DUTY_AMT: "231.00",
    PLAN_VAT_AMT: "4044.63",
    PLAN_TOTAL_PREMIUM: "61825.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49000001",
    TOTAL_SUMINS_MAX: "49100000",
    PLAN_NET_PREMIUM_AMT: "57647.50",
    PLAN_STAMP_DUTY_AMT: "231.00",
    PLAN_VAT_AMT: "4051.50",
    PLAN_TOTAL_PREMIUM: "61930.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49100001",
    TOTAL_SUMINS_MAX: "49200000",
    PLAN_NET_PREMIUM_AMT: "57745.64",
    PLAN_STAMP_DUTY_AMT: "231.00",
    PLAN_VAT_AMT: "4058.36",
    PLAN_TOTAL_PREMIUM: "62035.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49200001",
    TOTAL_SUMINS_MAX: "49300000",
    PLAN_NET_PREMIUM_AMT: "57842.77",
    PLAN_STAMP_DUTY_AMT: "232.00",
    PLAN_VAT_AMT: "4065.23",
    PLAN_TOTAL_PREMIUM: "62140.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49300001",
    TOTAL_SUMINS_MAX: "49400000",
    PLAN_NET_PREMIUM_AMT: "57940.90",
    PLAN_STAMP_DUTY_AMT: "232.00",
    PLAN_VAT_AMT: "4072.10",
    PLAN_TOTAL_PREMIUM: "62245.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49400001",
    TOTAL_SUMINS_MAX: "49500000",
    PLAN_NET_PREMIUM_AMT: "58038.03",
    PLAN_STAMP_DUTY_AMT: "233.00",
    PLAN_VAT_AMT: "4078.97",
    PLAN_TOTAL_PREMIUM: "62350.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49500001",
    TOTAL_SUMINS_MAX: "49600000",
    PLAN_NET_PREMIUM_AMT: "58136.16",
    PLAN_STAMP_DUTY_AMT: "233.00",
    PLAN_VAT_AMT: "4085.84",
    PLAN_TOTAL_PREMIUM: "62455.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49600001",
    TOTAL_SUMINS_MAX: "49700000",
    PLAN_NET_PREMIUM_AMT: "58234.29",
    PLAN_STAMP_DUTY_AMT: "233.00",
    PLAN_VAT_AMT: "4092.71",
    PLAN_TOTAL_PREMIUM: "62560.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49700001",
    TOTAL_SUMINS_MAX: "49800000",
    PLAN_NET_PREMIUM_AMT: "58331.42",
    PLAN_STAMP_DUTY_AMT: "234.00",
    PLAN_VAT_AMT: "4099.58",
    PLAN_TOTAL_PREMIUM: "62665.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49800001",
    TOTAL_SUMINS_MAX: "49900000",
    PLAN_NET_PREMIUM_AMT: "58429.55",
    PLAN_STAMP_DUTY_AMT: "234.00",
    PLAN_VAT_AMT: "4106.45",
    PLAN_TOTAL_PREMIUM: "62770.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-50000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49900001",
    TOTAL_SUMINS_MAX: "50000000",
    PLAN_NET_PREMIUM_AMT: "58526.68",
    PLAN_STAMP_DUTY_AMT: "235.00",
    PLAN_VAT_AMT: "4113.32",
    PLAN_TOTAL_PREMIUM: "62875.00",
    INDUSTRY_CODE: "FAC",
    INDUSTRY_DESC: "Factory"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "0",
    TOTAL_SUMINS_MAX: "500000",
    PLAN_NET_PREMIUM_AMT: "2103.79",
    PLAN_STAMP_DUTY_AMT: "9.00",
    PLAN_VAT_AMT: "147.91",
    PLAN_TOTAL_PREMIUM: "2260.70",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "500001",
    TOTAL_SUMINS_MAX: "600000",
    PLAN_NET_PREMIUM_AMT: "2225.59",
    PLAN_STAMP_DUTY_AMT: "9.00",
    PLAN_VAT_AMT: "156.42",
    PLAN_TOTAL_PREMIUM: "2391.01",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "600001",
    TOTAL_SUMINS_MAX: "700000",
    PLAN_NET_PREMIUM_AMT: "2346.38",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "164.95",
    PLAN_TOTAL_PREMIUM: "2521.33",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "700001",
    TOTAL_SUMINS_MAX: "800000",
    PLAN_NET_PREMIUM_AMT: "2468.17",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "173.47",
    PLAN_TOTAL_PREMIUM: "2651.64",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "800001",
    TOTAL_SUMINS_MAX: "900000",
    PLAN_NET_PREMIUM_AMT: "2588.96",
    PLAN_STAMP_DUTY_AMT: "11.00",
    PLAN_VAT_AMT: "182.00",
    PLAN_TOTAL_PREMIUM: "2781.96",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "900001",
    TOTAL_SUMINS_MAX: "1000000",
    PLAN_NET_PREMIUM_AMT: "2710.75",
    PLAN_STAMP_DUTY_AMT: "11.00",
    PLAN_VAT_AMT: "190.52",
    PLAN_TOTAL_PREMIUM: "2912.27",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1000001",
    TOTAL_SUMINS_MAX: "1100000",
    PLAN_NET_PREMIUM_AMT: "2869.92",
    PLAN_STAMP_DUTY_AMT: "12.00",
    PLAN_VAT_AMT: "201.73",
    PLAN_TOTAL_PREMIUM: "3083.65",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1100001",
    TOTAL_SUMINS_MAX: "1200000",
    PLAN_NET_PREMIUM_AMT: "3029.09",
    PLAN_STAMP_DUTY_AMT: "13.00",
    PLAN_VAT_AMT: "212.95",
    PLAN_TOTAL_PREMIUM: "3255.04",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1200001",
    TOTAL_SUMINS_MAX: "1300000",
    PLAN_NET_PREMIUM_AMT: "3189.26",
    PLAN_STAMP_DUTY_AMT: "13.00",
    PLAN_VAT_AMT: "224.16",
    PLAN_TOTAL_PREMIUM: "3426.42",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1300001",
    TOTAL_SUMINS_MAX: "1400000",
    PLAN_NET_PREMIUM_AMT: "3348.43",
    PLAN_STAMP_DUTY_AMT: "14.00",
    PLAN_VAT_AMT: "235.37",
    PLAN_TOTAL_PREMIUM: "3597.80",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1400001",
    TOTAL_SUMINS_MAX: "1500000",
    PLAN_NET_PREMIUM_AMT: "3507.60",
    PLAN_STAMP_DUTY_AMT: "15.00",
    PLAN_VAT_AMT: "246.58",
    PLAN_TOTAL_PREMIUM: "3769.18",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1500001",
    TOTAL_SUMINS_MAX: "1600000",
    PLAN_NET_PREMIUM_AMT: "3667.96",
    PLAN_STAMP_DUTY_AMT: "15.00",
    PLAN_VAT_AMT: "257.81",
    PLAN_TOTAL_PREMIUM: "3940.77",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1600001",
    TOTAL_SUMINS_MAX: "1700000",
    PLAN_NET_PREMIUM_AMT: "3827.32",
    PLAN_STAMP_DUTY_AMT: "16.00",
    PLAN_VAT_AMT: "269.03",
    PLAN_TOTAL_PREMIUM: "4112.35",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1700001",
    TOTAL_SUMINS_MAX: "1800000",
    PLAN_NET_PREMIUM_AMT: "3987.68",
    PLAN_STAMP_DUTY_AMT: "16.00",
    PLAN_VAT_AMT: "280.26",
    PLAN_TOTAL_PREMIUM: "4283.94",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1800001",
    TOTAL_SUMINS_MAX: "1900000",
    PLAN_NET_PREMIUM_AMT: "4147.04",
    PLAN_STAMP_DUTY_AMT: "17.00",
    PLAN_VAT_AMT: "291.48",
    PLAN_TOTAL_PREMIUM: "4455.52",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1900001",
    TOTAL_SUMINS_MAX: "2000000",
    PLAN_NET_PREMIUM_AMT: "4306.40",
    PLAN_STAMP_DUTY_AMT: "18.00",
    PLAN_VAT_AMT: "302.71",
    PLAN_TOTAL_PREMIUM: "4627.11",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2000001",
    TOTAL_SUMINS_MAX: "2100000",
    PLAN_NET_PREMIUM_AMT: "4466.38",
    PLAN_STAMP_DUTY_AMT: "18.00",
    PLAN_VAT_AMT: "313.91",
    PLAN_TOTAL_PREMIUM: "4798.29",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2100001",
    TOTAL_SUMINS_MAX: "2200000",
    PLAN_NET_PREMIUM_AMT: "4625.36",
    PLAN_STAMP_DUTY_AMT: "19.00",
    PLAN_VAT_AMT: "325.11",
    PLAN_TOTAL_PREMIUM: "4969.47",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2200001",
    TOTAL_SUMINS_MAX: "2300000",
    PLAN_NET_PREMIUM_AMT: "4784.34",
    PLAN_STAMP_DUTY_AMT: "20.00",
    PLAN_VAT_AMT: "336.30",
    PLAN_TOTAL_PREMIUM: "5140.64",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2300001",
    TOTAL_SUMINS_MAX: "2400000",
    PLAN_NET_PREMIUM_AMT: "4944.32",
    PLAN_STAMP_DUTY_AMT: "20.00",
    PLAN_VAT_AMT: "347.50",
    PLAN_TOTAL_PREMIUM: "5311.82",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2400001",
    TOTAL_SUMINS_MAX: "2500000",
    PLAN_NET_PREMIUM_AMT: "5103.30",
    PLAN_STAMP_DUTY_AMT: "21.00",
    PLAN_VAT_AMT: "358.70",
    PLAN_TOTAL_PREMIUM: "5483.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2500001",
    TOTAL_SUMINS_MAX: "2600000",
    PLAN_NET_PREMIUM_AMT: "5262.47",
    PLAN_STAMP_DUTY_AMT: "22.00",
    PLAN_VAT_AMT: "369.91",
    PLAN_TOTAL_PREMIUM: "5654.38",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2600001",
    TOTAL_SUMINS_MAX: "2700000",
    PLAN_NET_PREMIUM_AMT: "5422.64",
    PLAN_STAMP_DUTY_AMT: "22.00",
    PLAN_VAT_AMT: "381.12",
    PLAN_TOTAL_PREMIUM: "5825.76",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2700001",
    TOTAL_SUMINS_MAX: "2800000",
    PLAN_NET_PREMIUM_AMT: "5581.81",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "392.34",
    PLAN_TOTAL_PREMIUM: "5997.15",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2800001",
    TOTAL_SUMINS_MAX: "2900000",
    PLAN_NET_PREMIUM_AMT: "5741.98",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "403.55",
    PLAN_TOTAL_PREMIUM: "6168.53",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2900001",
    TOTAL_SUMINS_MAX: "3000000",
    PLAN_NET_PREMIUM_AMT: "5901.15",
    PLAN_STAMP_DUTY_AMT: "24.00",
    PLAN_VAT_AMT: "414.76",
    PLAN_TOTAL_PREMIUM: "6339.91",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3000001",
    TOTAL_SUMINS_MAX: "3100000",
    PLAN_NET_PREMIUM_AMT: "6060.32",
    PLAN_STAMP_DUTY_AMT: "25.00",
    PLAN_VAT_AMT: "425.97",
    PLAN_TOTAL_PREMIUM: "6511.29",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3100001",
    TOTAL_SUMINS_MAX: "3200000",
    PLAN_NET_PREMIUM_AMT: "6220.49",
    PLAN_STAMP_DUTY_AMT: "25.00",
    PLAN_VAT_AMT: "437.18",
    PLAN_TOTAL_PREMIUM: "6682.67",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3200001",
    TOTAL_SUMINS_MAX: "3300000",
    PLAN_NET_PREMIUM_AMT: "6379.66",
    PLAN_STAMP_DUTY_AMT: "26.00",
    PLAN_VAT_AMT: "448.40",
    PLAN_TOTAL_PREMIUM: "6854.06",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3300001",
    TOTAL_SUMINS_MAX: "3400000",
    PLAN_NET_PREMIUM_AMT: "6538.83",
    PLAN_STAMP_DUTY_AMT: "27.00",
    PLAN_VAT_AMT: "459.61",
    PLAN_TOTAL_PREMIUM: "7025.44",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3400001",
    TOTAL_SUMINS_MAX: "3500000",
    PLAN_NET_PREMIUM_AMT: "6699.00",
    PLAN_STAMP_DUTY_AMT: "27.00",
    PLAN_VAT_AMT: "470.82",
    PLAN_TOTAL_PREMIUM: "7196.82",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3500001",
    TOTAL_SUMINS_MAX: "3600000",
    PLAN_NET_PREMIUM_AMT: "6858.17",
    PLAN_STAMP_DUTY_AMT: "28.00",
    PLAN_VAT_AMT: "482.03",
    PLAN_TOTAL_PREMIUM: "7368.20",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3600001",
    TOTAL_SUMINS_MAX: "3700000",
    PLAN_NET_PREMIUM_AMT: "7017.34",
    PLAN_STAMP_DUTY_AMT: "29.00",
    PLAN_VAT_AMT: "493.24",
    PLAN_TOTAL_PREMIUM: "7539.58",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3700001",
    TOTAL_SUMINS_MAX: "3800000",
    PLAN_NET_PREMIUM_AMT: "7177.51",
    PLAN_STAMP_DUTY_AMT: "29.00",
    PLAN_VAT_AMT: "504.46",
    PLAN_TOTAL_PREMIUM: "7710.97",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3800001",
    TOTAL_SUMINS_MAX: "3900000",
    PLAN_NET_PREMIUM_AMT: "7336.68",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "515.67",
    PLAN_TOTAL_PREMIUM: "7882.35",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3900001",
    TOTAL_SUMINS_MAX: "4000000",
    PLAN_NET_PREMIUM_AMT: "7496.85",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "526.88",
    PLAN_TOTAL_PREMIUM: "8053.73",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4000001",
    TOTAL_SUMINS_MAX: "4100000",
    PLAN_NET_PREMIUM_AMT: "7656.02",
    PLAN_STAMP_DUTY_AMT: "31.00",
    PLAN_VAT_AMT: "538.09",
    PLAN_TOTAL_PREMIUM: "8225.11",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4100001",
    TOTAL_SUMINS_MAX: "4200000",
    PLAN_NET_PREMIUM_AMT: "7815.19",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "549.30",
    PLAN_TOTAL_PREMIUM: "8396.49",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4200001",
    TOTAL_SUMINS_MAX: "4300000",
    PLAN_NET_PREMIUM_AMT: "7975.36",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "560.52",
    PLAN_TOTAL_PREMIUM: "8567.88",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4300001",
    TOTAL_SUMINS_MAX: "4400000",
    PLAN_NET_PREMIUM_AMT: "8134.53",
    PLAN_STAMP_DUTY_AMT: "33.00",
    PLAN_VAT_AMT: "571.73",
    PLAN_TOTAL_PREMIUM: "8739.26",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4400001",
    TOTAL_SUMINS_MAX: "4500000",
    PLAN_NET_PREMIUM_AMT: "8293.70",
    PLAN_STAMP_DUTY_AMT: "34.00",
    PLAN_VAT_AMT: "582.94",
    PLAN_TOTAL_PREMIUM: "8910.64",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4500001",
    TOTAL_SUMINS_MAX: "4600000",
    PLAN_NET_PREMIUM_AMT: "8453.87",
    PLAN_STAMP_DUTY_AMT: "34.00",
    PLAN_VAT_AMT: "594.15",
    PLAN_TOTAL_PREMIUM: "9082.02",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4600001",
    TOTAL_SUMINS_MAX: "4700000",
    PLAN_NET_PREMIUM_AMT: "8613.04",
    PLAN_STAMP_DUTY_AMT: "35.00",
    PLAN_VAT_AMT: "605.36",
    PLAN_TOTAL_PREMIUM: "9253.40",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4700001",
    TOTAL_SUMINS_MAX: "4800000",
    PLAN_NET_PREMIUM_AMT: "8772.21",
    PLAN_STAMP_DUTY_AMT: "36.00",
    PLAN_VAT_AMT: "616.57",
    PLAN_TOTAL_PREMIUM: "9424.78",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4800001",
    TOTAL_SUMINS_MAX: "4900000",
    PLAN_NET_PREMIUM_AMT: "8932.38",
    PLAN_STAMP_DUTY_AMT: "36.00",
    PLAN_VAT_AMT: "627.79",
    PLAN_TOTAL_PREMIUM: "9596.17",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4900001",
    TOTAL_SUMINS_MAX: "5000000",
    PLAN_NET_PREMIUM_AMT: "9091.55",
    PLAN_STAMP_DUTY_AMT: "37.00",
    PLAN_VAT_AMT: "639.00",
    PLAN_TOTAL_PREMIUM: "9767.55",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5000001",
    TOTAL_SUMINS_MAX: "5100000",
    PLAN_NET_PREMIUM_AMT: "9449.27",
    PLAN_STAMP_DUTY_AMT: "38.00",
    PLAN_VAT_AMT: "664.11",
    PLAN_TOTAL_PREMIUM: "10151.38",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5100001",
    TOTAL_SUMINS_MAX: "5200000",
    PLAN_NET_PREMIUM_AMT: "9805.99",
    PLAN_STAMP_DUTY_AMT: "40.00",
    PLAN_VAT_AMT: "689.22",
    PLAN_TOTAL_PREMIUM: "10535.21",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5200001",
    TOTAL_SUMINS_MAX: "5300000",
    PLAN_NET_PREMIUM_AMT: "10163.71",
    PLAN_STAMP_DUTY_AMT: "41.00",
    PLAN_VAT_AMT: "714.33",
    PLAN_TOTAL_PREMIUM: "10919.04",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5300001",
    TOTAL_SUMINS_MAX: "5400000",
    PLAN_NET_PREMIUM_AMT: "10520.43",
    PLAN_STAMP_DUTY_AMT: "43.00",
    PLAN_VAT_AMT: "739.44",
    PLAN_TOTAL_PREMIUM: "11302.87",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5400001",
    TOTAL_SUMINS_MAX: "5500000",
    PLAN_NET_PREMIUM_AMT: "10878.15",
    PLAN_STAMP_DUTY_AMT: "44.00",
    PLAN_VAT_AMT: "764.55",
    PLAN_TOTAL_PREMIUM: "11686.70",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5500001",
    TOTAL_SUMINS_MAX: "5600000",
    PLAN_NET_PREMIUM_AMT: "11053.47",
    PLAN_STAMP_DUTY_AMT: "45.00",
    PLAN_VAT_AMT: "776.89",
    PLAN_TOTAL_PREMIUM: "11875.36",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5600001",
    TOTAL_SUMINS_MAX: "5700000",
    PLAN_NET_PREMIUM_AMT: "11229.79",
    PLAN_STAMP_DUTY_AMT: "45.00",
    PLAN_VAT_AMT: "789.24",
    PLAN_TOTAL_PREMIUM: "12064.03",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5700001",
    TOTAL_SUMINS_MAX: "5800000",
    PLAN_NET_PREMIUM_AMT: "11405.11",
    PLAN_STAMP_DUTY_AMT: "46.00",
    PLAN_VAT_AMT: "801.58",
    PLAN_TOTAL_PREMIUM: "12252.69",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5800001",
    TOTAL_SUMINS_MAX: "5900000",
    PLAN_NET_PREMIUM_AMT: "11580.43",
    PLAN_STAMP_DUTY_AMT: "47.00",
    PLAN_VAT_AMT: "813.92",
    PLAN_TOTAL_PREMIUM: "12441.35",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5900001",
    TOTAL_SUMINS_MAX: "6000000",
    PLAN_NET_PREMIUM_AMT: "11755.75",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "826.26",
    PLAN_TOTAL_PREMIUM: "12630.01",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6000001",
    TOTAL_SUMINS_MAX: "6100000",
    PLAN_NET_PREMIUM_AMT: "11931.88",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "838.59",
    PLAN_TOTAL_PREMIUM: "12818.47",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6100001",
    TOTAL_SUMINS_MAX: "6200000",
    PLAN_NET_PREMIUM_AMT: "12107.01",
    PLAN_STAMP_DUTY_AMT: "49.00",
    PLAN_VAT_AMT: "850.92",
    PLAN_TOTAL_PREMIUM: "13006.93",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6200001",
    TOTAL_SUMINS_MAX: "6300000",
    PLAN_NET_PREMIUM_AMT: "12282.14",
    PLAN_STAMP_DUTY_AMT: "50.00",
    PLAN_VAT_AMT: "863.25",
    PLAN_TOTAL_PREMIUM: "13195.39",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6300001",
    TOTAL_SUMINS_MAX: "6400000",
    PLAN_NET_PREMIUM_AMT: "12458.27",
    PLAN_STAMP_DUTY_AMT: "50.00",
    PLAN_VAT_AMT: "875.58",
    PLAN_TOTAL_PREMIUM: "13383.85",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6400001",
    TOTAL_SUMINS_MAX: "6500000",
    PLAN_NET_PREMIUM_AMT: "12633.40",
    PLAN_STAMP_DUTY_AMT: "51.00",
    PLAN_VAT_AMT: "887.91",
    PLAN_TOTAL_PREMIUM: "13572.31",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6500001",
    TOTAL_SUMINS_MAX: "6600000",
    PLAN_NET_PREMIUM_AMT: "12808.53",
    PLAN_STAMP_DUTY_AMT: "52.00",
    PLAN_VAT_AMT: "900.24",
    PLAN_TOTAL_PREMIUM: "13760.77",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6600001",
    TOTAL_SUMINS_MAX: "6700000",
    PLAN_NET_PREMIUM_AMT: "12984.66",
    PLAN_STAMP_DUTY_AMT: "52.00",
    PLAN_VAT_AMT: "912.57",
    PLAN_TOTAL_PREMIUM: "13949.23",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6700001",
    TOTAL_SUMINS_MAX: "6800000",
    PLAN_NET_PREMIUM_AMT: "13159.79",
    PLAN_STAMP_DUTY_AMT: "53.00",
    PLAN_VAT_AMT: "924.90",
    PLAN_TOTAL_PREMIUM: "14137.69",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6800001",
    TOTAL_SUMINS_MAX: "6900000",
    PLAN_NET_PREMIUM_AMT: "13334.92",
    PLAN_STAMP_DUTY_AMT: "54.00",
    PLAN_VAT_AMT: "937.22",
    PLAN_TOTAL_PREMIUM: "14326.14",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6900001",
    TOTAL_SUMINS_MAX: "7000000",
    PLAN_NET_PREMIUM_AMT: "13510.05",
    PLAN_STAMP_DUTY_AMT: "55.00",
    PLAN_VAT_AMT: "949.55",
    PLAN_TOTAL_PREMIUM: "14514.60",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7000001",
    TOTAL_SUMINS_MAX: "7100000",
    PLAN_NET_PREMIUM_AMT: "13673.26",
    PLAN_STAMP_DUTY_AMT: "55.00",
    PLAN_VAT_AMT: "960.98",
    PLAN_TOTAL_PREMIUM: "14689.24",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7100001",
    TOTAL_SUMINS_MAX: "7200000",
    PLAN_NET_PREMIUM_AMT: "13835.47",
    PLAN_STAMP_DUTY_AMT: "56.00",
    PLAN_VAT_AMT: "972.40",
    PLAN_TOTAL_PREMIUM: "14863.87",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7200001",
    TOTAL_SUMINS_MAX: "7300000",
    PLAN_NET_PREMIUM_AMT: "13998.68",
    PLAN_STAMP_DUTY_AMT: "56.00",
    PLAN_VAT_AMT: "983.83",
    PLAN_TOTAL_PREMIUM: "15038.51",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7300001",
    TOTAL_SUMINS_MAX: "7400000",
    PLAN_NET_PREMIUM_AMT: "14160.89",
    PLAN_STAMP_DUTY_AMT: "57.00",
    PLAN_VAT_AMT: "995.25",
    PLAN_TOTAL_PREMIUM: "15213.14",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7400001",
    TOTAL_SUMINS_MAX: "7500000",
    PLAN_NET_PREMIUM_AMT: "14323.10",
    PLAN_STAMP_DUTY_AMT: "58.00",
    PLAN_VAT_AMT: "1006.68",
    PLAN_TOTAL_PREMIUM: "15387.78",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7500001",
    TOTAL_SUMINS_MAX: "7600000",
    PLAN_NET_PREMIUM_AMT: "14484.41",
    PLAN_STAMP_DUTY_AMT: "58.00",
    PLAN_VAT_AMT: "1017.97",
    PLAN_TOTAL_PREMIUM: "15560.38",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7600001",
    TOTAL_SUMINS_MAX: "7700000",
    PLAN_NET_PREMIUM_AMT: "14644.72",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1029.26",
    PLAN_TOTAL_PREMIUM: "15732.98",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7700001",
    TOTAL_SUMINS_MAX: "7800000",
    PLAN_NET_PREMIUM_AMT: "14805.03",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1040.55",
    PLAN_TOTAL_PREMIUM: "15905.58",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7800001",
    TOTAL_SUMINS_MAX: "7900000",
    PLAN_NET_PREMIUM_AMT: "14966.34",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1051.84",
    PLAN_TOTAL_PREMIUM: "16078.18",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7900001",
    TOTAL_SUMINS_MAX: "8000000",
    PLAN_NET_PREMIUM_AMT: "15126.65",
    PLAN_STAMP_DUTY_AMT: "61.00",
    PLAN_VAT_AMT: "1063.14",
    PLAN_TOTAL_PREMIUM: "16250.79",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8000001",
    TOTAL_SUMINS_MAX: "8100000",
    PLAN_NET_PREMIUM_AMT: "15300.26",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1075.36",
    PLAN_TOTAL_PREMIUM: "16437.62",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8100001",
    TOTAL_SUMINS_MAX: "8200000",
    PLAN_NET_PREMIUM_AMT: "15474.87",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1087.58",
    PLAN_TOTAL_PREMIUM: "16624.45",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8200001",
    TOTAL_SUMINS_MAX: "8300000",
    PLAN_NET_PREMIUM_AMT: "15648.48",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1099.80",
    PLAN_TOTAL_PREMIUM: "16811.28",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8300001",
    TOTAL_SUMINS_MAX: "8400000",
    PLAN_NET_PREMIUM_AMT: "15822.09",
    PLAN_STAMP_DUTY_AMT: "64.00",
    PLAN_VAT_AMT: "1112.03",
    PLAN_TOTAL_PREMIUM: "16998.12",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8400001",
    TOTAL_SUMINS_MAX: "8500000",
    PLAN_NET_PREMIUM_AMT: "15996.70",
    PLAN_STAMP_DUTY_AMT: "64.00",
    PLAN_VAT_AMT: "1124.25",
    PLAN_TOTAL_PREMIUM: "17184.95",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8500001",
    TOTAL_SUMINS_MAX: "8600000",
    PLAN_NET_PREMIUM_AMT: "16154.73",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1135.38",
    PLAN_TOTAL_PREMIUM: "17355.11",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8600001",
    TOTAL_SUMINS_MAX: "8700000",
    PLAN_NET_PREMIUM_AMT: "16312.76",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1146.51",
    PLAN_TOTAL_PREMIUM: "17525.27",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8700001",
    TOTAL_SUMINS_MAX: "8800000",
    PLAN_NET_PREMIUM_AMT: "16471.79",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1157.65",
    PLAN_TOTAL_PREMIUM: "17695.44",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8800001",
    TOTAL_SUMINS_MAX: "8900000",
    PLAN_NET_PREMIUM_AMT: "16629.82",
    PLAN_STAMP_DUTY_AMT: "67.00",
    PLAN_VAT_AMT: "1168.78",
    PLAN_TOTAL_PREMIUM: "17865.60",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8900001",
    TOTAL_SUMINS_MAX: "9000000",
    PLAN_NET_PREMIUM_AMT: "16787.85",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1179.91",
    PLAN_TOTAL_PREMIUM: "18035.76",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9000001",
    TOTAL_SUMINS_MAX: "9100000",
    PLAN_NET_PREMIUM_AMT: "16961.70",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1192.08",
    PLAN_TOTAL_PREMIUM: "18221.78",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9100001",
    TOTAL_SUMINS_MAX: "9200000",
    PLAN_NET_PREMIUM_AMT: "17134.55",
    PLAN_STAMP_DUTY_AMT: "69.00",
    PLAN_VAT_AMT: "1204.25",
    PLAN_TOTAL_PREMIUM: "18407.80",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9200001",
    TOTAL_SUMINS_MAX: "9300000",
    PLAN_NET_PREMIUM_AMT: "17307.40",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1216.42",
    PLAN_TOTAL_PREMIUM: "18593.82",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9300001",
    TOTAL_SUMINS_MAX: "9400000",
    PLAN_NET_PREMIUM_AMT: "17481.25",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1228.59",
    PLAN_TOTAL_PREMIUM: "18779.84",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9400001",
    TOTAL_SUMINS_MAX: "9500000",
    PLAN_NET_PREMIUM_AMT: "17654.10",
    PLAN_STAMP_DUTY_AMT: "71.00",
    PLAN_VAT_AMT: "1240.76",
    PLAN_TOTAL_PREMIUM: "18965.86",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9500001",
    TOTAL_SUMINS_MAX: "9600000",
    PLAN_NET_PREMIUM_AMT: "17826.76",
    PLAN_STAMP_DUTY_AMT: "72.00",
    PLAN_VAT_AMT: "1252.91",
    PLAN_TOTAL_PREMIUM: "19151.67",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9600001",
    TOTAL_SUMINS_MAX: "9700000",
    PLAN_NET_PREMIUM_AMT: "18000.00",
    PLAN_STAMP_DUTY_AMT: "72.00",
    PLAN_VAT_AMT: "1265.49",
    PLAN_TOTAL_PREMIUM: "19337.49",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9700001",
    TOTAL_SUMINS_MAX: "9800000",
    PLAN_NET_PREMIUM_AMT: "18173.08",
    PLAN_STAMP_DUTY_AMT: "73.00",
    PLAN_VAT_AMT: "1277.23",
    PLAN_TOTAL_PREMIUM: "19523.31",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9800001",
    TOTAL_SUMINS_MAX: "9900000",
    PLAN_NET_PREMIUM_AMT: "18345.74",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1289.38",
    PLAN_TOTAL_PREMIUM: "19709.12",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9900001",
    TOTAL_SUMINS_MAX: "10000000",
    PLAN_NET_PREMIUM_AMT: "18518.40",
    PLAN_STAMP_DUTY_AMT: "75.00",
    PLAN_VAT_AMT: "1301.54",
    PLAN_TOTAL_PREMIUM: "19894.94",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10000001",
    TOTAL_SUMINS_MAX: "10100000",
    PLAN_NET_PREMIUM_AMT: "19436.02",
    PLAN_STAMP_DUTY_AMT: "78.00",
    PLAN_VAT_AMT: "1365.98",
    PLAN_TOTAL_PREMIUM: "20880.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10100001",
    TOTAL_SUMINS_MAX: "10200000",
    PLAN_NET_PREMIUM_AMT: "19533.15",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1372.85",
    PLAN_TOTAL_PREMIUM: "20985.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10200001",
    TOTAL_SUMINS_MAX: "10300000",
    PLAN_NET_PREMIUM_AMT: "19631.28",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1379.72",
    PLAN_TOTAL_PREMIUM: "21090.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10300001",
    TOTAL_SUMINS_MAX: "10400000",
    PLAN_NET_PREMIUM_AMT: "19729.41",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1386.59",
    PLAN_TOTAL_PREMIUM: "21195.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10400001",
    TOTAL_SUMINS_MAX: "10500000",
    PLAN_NET_PREMIUM_AMT: "19826.54",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1393.46",
    PLAN_TOTAL_PREMIUM: "21300.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10500001",
    TOTAL_SUMINS_MAX: "10600000",
    PLAN_NET_PREMIUM_AMT: "19924.67",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1400.33",
    PLAN_TOTAL_PREMIUM: "21405.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10600001",
    TOTAL_SUMINS_MAX: "10700000",
    PLAN_NET_PREMIUM_AMT: "20021.80",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1407.20",
    PLAN_TOTAL_PREMIUM: "21510.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10700001",
    TOTAL_SUMINS_MAX: "10800000",
    PLAN_NET_PREMIUM_AMT: "20119.93",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1414.07",
    PLAN_TOTAL_PREMIUM: "21615.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10800001",
    TOTAL_SUMINS_MAX: "10900000",
    PLAN_NET_PREMIUM_AMT: "20218.07",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1420.93",
    PLAN_TOTAL_PREMIUM: "21720.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10900001",
    TOTAL_SUMINS_MAX: "11000000",
    PLAN_NET_PREMIUM_AMT: "20315.20",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1427.80",
    PLAN_TOTAL_PREMIUM: "21825.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11000001",
    TOTAL_SUMINS_MAX: "11100000",
    PLAN_NET_PREMIUM_AMT: "20413.33",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1434.67",
    PLAN_TOTAL_PREMIUM: "21930.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11100001",
    TOTAL_SUMINS_MAX: "11200000",
    PLAN_NET_PREMIUM_AMT: "20510.46",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1441.54",
    PLAN_TOTAL_PREMIUM: "22035.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11200001",
    TOTAL_SUMINS_MAX: "11300000",
    PLAN_NET_PREMIUM_AMT: "20608.59",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1448.41",
    PLAN_TOTAL_PREMIUM: "22140.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11300001",
    TOTAL_SUMINS_MAX: "11400000",
    PLAN_NET_PREMIUM_AMT: "20706.72",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1455.28",
    PLAN_TOTAL_PREMIUM: "22245.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11400001",
    TOTAL_SUMINS_MAX: "11500000",
    PLAN_NET_PREMIUM_AMT: "20803.85",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1462.15",
    PLAN_TOTAL_PREMIUM: "22350.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11500001",
    TOTAL_SUMINS_MAX: "11600000",
    PLAN_NET_PREMIUM_AMT: "20901.98",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1469.02",
    PLAN_TOTAL_PREMIUM: "22455.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11600001",
    TOTAL_SUMINS_MAX: "11700000",
    PLAN_NET_PREMIUM_AMT: "20999.99",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1476.01",
    PLAN_TOTAL_PREMIUM: "22560.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11700001",
    TOTAL_SUMINS_MAX: "11800000",
    PLAN_NET_PREMIUM_AMT: "21097.24",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1482.76",
    PLAN_TOTAL_PREMIUM: "22665.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11800001",
    TOTAL_SUMINS_MAX: "11900000",
    PLAN_NET_PREMIUM_AMT: "21195.37",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1489.63",
    PLAN_TOTAL_PREMIUM: "22770.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11900001",
    TOTAL_SUMINS_MAX: "12000000",
    PLAN_NET_PREMIUM_AMT: "21292.50",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1496.50",
    PLAN_TOTAL_PREMIUM: "22875.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12000001",
    TOTAL_SUMINS_MAX: "12100000",
    PLAN_NET_PREMIUM_AMT: "21390.64",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1503.36",
    PLAN_TOTAL_PREMIUM: "22980.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12100001",
    TOTAL_SUMINS_MAX: "12200000",
    PLAN_NET_PREMIUM_AMT: "21488.77",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1510.23",
    PLAN_TOTAL_PREMIUM: "23085.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12200001",
    TOTAL_SUMINS_MAX: "12300000",
    PLAN_NET_PREMIUM_AMT: "21585.90",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1517.10",
    PLAN_TOTAL_PREMIUM: "23190.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12300001",
    TOTAL_SUMINS_MAX: "12400000",
    PLAN_NET_PREMIUM_AMT: "21684.03",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1523.97",
    PLAN_TOTAL_PREMIUM: "23295.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12400001",
    TOTAL_SUMINS_MAX: "12500000",
    PLAN_NET_PREMIUM_AMT: "21781.16",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1530.84",
    PLAN_TOTAL_PREMIUM: "23400.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12500001",
    TOTAL_SUMINS_MAX: "12600000",
    PLAN_NET_PREMIUM_AMT: "21879.29",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1537.71",
    PLAN_TOTAL_PREMIUM: "23505.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12600001",
    TOTAL_SUMINS_MAX: "12700000",
    PLAN_NET_PREMIUM_AMT: "21977.42",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1544.58",
    PLAN_TOTAL_PREMIUM: "23610.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12700001",
    TOTAL_SUMINS_MAX: "12800000",
    PLAN_NET_PREMIUM_AMT: "22074.55",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1551.45",
    PLAN_TOTAL_PREMIUM: "23715.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12800001",
    TOTAL_SUMINS_MAX: "12900000",
    PLAN_NET_PREMIUM_AMT: "22172.68",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1558.32",
    PLAN_TOTAL_PREMIUM: "23820.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12900001",
    TOTAL_SUMINS_MAX: "13000000",
    PLAN_NET_PREMIUM_AMT: "22269.81",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1565.19",
    PLAN_TOTAL_PREMIUM: "23925.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13000001",
    TOTAL_SUMINS_MAX: "13100000",
    PLAN_NET_PREMIUM_AMT: "22367.94",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1572.06",
    PLAN_TOTAL_PREMIUM: "24030.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13100001",
    TOTAL_SUMINS_MAX: "13200000",
    PLAN_NET_PREMIUM_AMT: "22466.07",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1578.93",
    PLAN_TOTAL_PREMIUM: "24135.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13200001",
    TOTAL_SUMINS_MAX: "13300000",
    PLAN_NET_PREMIUM_AMT: "22563.21",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1585.79",
    PLAN_TOTAL_PREMIUM: "24240.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13300001",
    TOTAL_SUMINS_MAX: "13400000",
    PLAN_NET_PREMIUM_AMT: "22661.34",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1592.66",
    PLAN_TOTAL_PREMIUM: "24345.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13400001",
    TOTAL_SUMINS_MAX: "13500000",
    PLAN_NET_PREMIUM_AMT: "22758.47",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1599.53",
    PLAN_TOTAL_PREMIUM: "24450.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13500001",
    TOTAL_SUMINS_MAX: "13600000",
    PLAN_NET_PREMIUM_AMT: "22856.60",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1606.40",
    PLAN_TOTAL_PREMIUM: "24555.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13600001",
    TOTAL_SUMINS_MAX: "13700000",
    PLAN_NET_PREMIUM_AMT: "22954.73",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1613.27",
    PLAN_TOTAL_PREMIUM: "24660.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13700001",
    TOTAL_SUMINS_MAX: "13800000",
    PLAN_NET_PREMIUM_AMT: "23051.86",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1620.14",
    PLAN_TOTAL_PREMIUM: "24765.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13800001",
    TOTAL_SUMINS_MAX: "13900000",
    PLAN_NET_PREMIUM_AMT: "23149.99",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1627.01",
    PLAN_TOTAL_PREMIUM: "24870.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13900001",
    TOTAL_SUMINS_MAX: "14000000",
    PLAN_NET_PREMIUM_AMT: "23248.12",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1633.88",
    PLAN_TOTAL_PREMIUM: "24975.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14000001",
    TOTAL_SUMINS_MAX: "14100000",
    PLAN_NET_PREMIUM_AMT: "23345.25",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1640.75",
    PLAN_TOTAL_PREMIUM: "25080.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14100001",
    TOTAL_SUMINS_MAX: "14200000",
    PLAN_NET_PREMIUM_AMT: "23443.38",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1647.62",
    PLAN_TOTAL_PREMIUM: "25185.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14200001",
    TOTAL_SUMINS_MAX: "14300000",
    PLAN_NET_PREMIUM_AMT: "23540.51",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1654.49",
    PLAN_TOTAL_PREMIUM: "25290.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14300001",
    TOTAL_SUMINS_MAX: "14400000",
    PLAN_NET_PREMIUM_AMT: "23638.64",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1661.36",
    PLAN_TOTAL_PREMIUM: "25395.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14400001",
    TOTAL_SUMINS_MAX: "14500000",
    PLAN_NET_PREMIUM_AMT: "23736.78",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1668.22",
    PLAN_TOTAL_PREMIUM: "25500.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14500001",
    TOTAL_SUMINS_MAX: "14600000",
    PLAN_NET_PREMIUM_AMT: "23833.91",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1675.09",
    PLAN_TOTAL_PREMIUM: "25605.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14600001",
    TOTAL_SUMINS_MAX: "14700000",
    PLAN_NET_PREMIUM_AMT: "23932.04",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1681.96",
    PLAN_TOTAL_PREMIUM: "25710.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14700001",
    TOTAL_SUMINS_MAX: "14800000",
    PLAN_NET_PREMIUM_AMT: "24029.17",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1688.83",
    PLAN_TOTAL_PREMIUM: "25815.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14800001",
    TOTAL_SUMINS_MAX: "14900000",
    PLAN_NET_PREMIUM_AMT: "24127.30",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1695.70",
    PLAN_TOTAL_PREMIUM: "25920.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14900001",
    TOTAL_SUMINS_MAX: "15000000",
    PLAN_NET_PREMIUM_AMT: "24225.43",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1702.57",
    PLAN_TOTAL_PREMIUM: "26025.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15000001",
    TOTAL_SUMINS_MAX: "15100000",
    PLAN_NET_PREMIUM_AMT: "24322.56",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1709.44",
    PLAN_TOTAL_PREMIUM: "26130.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15100001",
    TOTAL_SUMINS_MAX: "15200000",
    PLAN_NET_PREMIUM_AMT: "24420.69",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1716.31",
    PLAN_TOTAL_PREMIUM: "26235.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15200001",
    TOTAL_SUMINS_MAX: "15300000",
    PLAN_NET_PREMIUM_AMT: "24517.82",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1723.18",
    PLAN_TOTAL_PREMIUM: "26340.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15300001",
    TOTAL_SUMINS_MAX: "15400000",
    PLAN_NET_PREMIUM_AMT: "24615.95",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1730.05",
    PLAN_TOTAL_PREMIUM: "26445.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15400001",
    TOTAL_SUMINS_MAX: "15500000",
    PLAN_NET_PREMIUM_AMT: "24714.08",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1736.92",
    PLAN_TOTAL_PREMIUM: "26550.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15500001",
    TOTAL_SUMINS_MAX: "15600000",
    PLAN_NET_PREMIUM_AMT: "24811.21",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1743.79",
    PLAN_TOTAL_PREMIUM: "26655.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15600001",
    TOTAL_SUMINS_MAX: "15700000",
    PLAN_NET_PREMIUM_AMT: "24909.35",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1750.65",
    PLAN_TOTAL_PREMIUM: "26760.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15700001",
    TOTAL_SUMINS_MAX: "15800000",
    PLAN_NET_PREMIUM_AMT: "25006.48",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1757.52",
    PLAN_TOTAL_PREMIUM: "26865.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15800001",
    TOTAL_SUMINS_MAX: "15900000",
    PLAN_NET_PREMIUM_AMT: "25104.61",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1764.39",
    PLAN_TOTAL_PREMIUM: "26970.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15900001",
    TOTAL_SUMINS_MAX: "16000000",
    PLAN_NET_PREMIUM_AMT: "25202.74",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1771.26",
    PLAN_TOTAL_PREMIUM: "27075.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16000001",
    TOTAL_SUMINS_MAX: "16100000",
    PLAN_NET_PREMIUM_AMT: "25299.87",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1778.13",
    PLAN_TOTAL_PREMIUM: "27180.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16100001",
    TOTAL_SUMINS_MAX: "16200000",
    PLAN_NET_PREMIUM_AMT: "25398.00",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1785.00",
    PLAN_TOTAL_PREMIUM: "27285.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16200001",
    TOTAL_SUMINS_MAX: "16300000",
    PLAN_NET_PREMIUM_AMT: "25496.13",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1791.87",
    PLAN_TOTAL_PREMIUM: "27390.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16300001",
    TOTAL_SUMINS_MAX: "16400000",
    PLAN_NET_PREMIUM_AMT: "25593.26",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1798.74",
    PLAN_TOTAL_PREMIUM: "27495.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16400001",
    TOTAL_SUMINS_MAX: "16500000",
    PLAN_NET_PREMIUM_AMT: "25691.39",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1805.61",
    PLAN_TOTAL_PREMIUM: "27600.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16500001",
    TOTAL_SUMINS_MAX: "16600000",
    PLAN_NET_PREMIUM_AMT: "25788.52",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1812.48",
    PLAN_TOTAL_PREMIUM: "27705.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16600001",
    TOTAL_SUMINS_MAX: "16700000",
    PLAN_NET_PREMIUM_AMT: "25886.65",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1819.35",
    PLAN_TOTAL_PREMIUM: "27810.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16700001",
    TOTAL_SUMINS_MAX: "16800000",
    PLAN_NET_PREMIUM_AMT: "25984.78",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1826.22",
    PLAN_TOTAL_PREMIUM: "27915.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16800001",
    TOTAL_SUMINS_MAX: "16900000",
    PLAN_NET_PREMIUM_AMT: "26081.92",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1833.08",
    PLAN_TOTAL_PREMIUM: "28020.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16900001",
    TOTAL_SUMINS_MAX: "17000000",
    PLAN_NET_PREMIUM_AMT: "26180.05",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1839.95",
    PLAN_TOTAL_PREMIUM: "28125.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17000001",
    TOTAL_SUMINS_MAX: "17100000",
    PLAN_NET_PREMIUM_AMT: "26277.18",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1846.82",
    PLAN_TOTAL_PREMIUM: "28230.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17100001",
    TOTAL_SUMINS_MAX: "17200000",
    PLAN_NET_PREMIUM_AMT: "26375.31",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1853.69",
    PLAN_TOTAL_PREMIUM: "28335.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17200001",
    TOTAL_SUMINS_MAX: "17300000",
    PLAN_NET_PREMIUM_AMT: "26473.44",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1860.56",
    PLAN_TOTAL_PREMIUM: "28440.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17300001",
    TOTAL_SUMINS_MAX: "17400000",
    PLAN_NET_PREMIUM_AMT: "26570.57",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1867.43",
    PLAN_TOTAL_PREMIUM: "28545.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17400001",
    TOTAL_SUMINS_MAX: "17500000",
    PLAN_NET_PREMIUM_AMT: "26668.70",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1874.30",
    PLAN_TOTAL_PREMIUM: "28650.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17500001",
    TOTAL_SUMINS_MAX: "17600000",
    PLAN_NET_PREMIUM_AMT: "26765.83",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1881.17",
    PLAN_TOTAL_PREMIUM: "28755.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17600001",
    TOTAL_SUMINS_MAX: "17700000",
    PLAN_NET_PREMIUM_AMT: "26863.96",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1888.04",
    PLAN_TOTAL_PREMIUM: "28860.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17700001",
    TOTAL_SUMINS_MAX: "17800000",
    PLAN_NET_PREMIUM_AMT: "26962.09",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1894.91",
    PLAN_TOTAL_PREMIUM: "28965.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17800001",
    TOTAL_SUMINS_MAX: "17900000",
    PLAN_NET_PREMIUM_AMT: "27059.22",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1901.78",
    PLAN_TOTAL_PREMIUM: "29070.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17900001",
    TOTAL_SUMINS_MAX: "18000000",
    PLAN_NET_PREMIUM_AMT: "27157.35",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1908.65",
    PLAN_TOTAL_PREMIUM: "29175.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18000001",
    TOTAL_SUMINS_MAX: "18100000",
    PLAN_NET_PREMIUM_AMT: "27254.49",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1915.51",
    PLAN_TOTAL_PREMIUM: "29280.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18100001",
    TOTAL_SUMINS_MAX: "18200000",
    PLAN_NET_PREMIUM_AMT: "27352.62",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1922.38",
    PLAN_TOTAL_PREMIUM: "29385.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18200001",
    TOTAL_SUMINS_MAX: "18300000",
    PLAN_NET_PREMIUM_AMT: "27450.75",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1929.25",
    PLAN_TOTAL_PREMIUM: "29490.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18300001",
    TOTAL_SUMINS_MAX: "18400000",
    PLAN_NET_PREMIUM_AMT: "27547.88",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1936.12",
    PLAN_TOTAL_PREMIUM: "29595.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18400001",
    TOTAL_SUMINS_MAX: "18500000",
    PLAN_NET_PREMIUM_AMT: "27646.01",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1942.99",
    PLAN_TOTAL_PREMIUM: "29700.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18500001",
    TOTAL_SUMINS_MAX: "18600000",
    PLAN_NET_PREMIUM_AMT: "27744.14",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1949.86",
    PLAN_TOTAL_PREMIUM: "29805.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18600001",
    TOTAL_SUMINS_MAX: "18700000",
    PLAN_NET_PREMIUM_AMT: "27841.27",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1956.73",
    PLAN_TOTAL_PREMIUM: "29910.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18700001",
    TOTAL_SUMINS_MAX: "18800000",
    PLAN_NET_PREMIUM_AMT: "27939.40",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1963.60",
    PLAN_TOTAL_PREMIUM: "30015.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18800001",
    TOTAL_SUMINS_MAX: "18900000",
    PLAN_NET_PREMIUM_AMT: "28036.53",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1970.47",
    PLAN_TOTAL_PREMIUM: "30120.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18900001",
    TOTAL_SUMINS_MAX: "19000000",
    PLAN_NET_PREMIUM_AMT: "28134.66",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1977.34",
    PLAN_TOTAL_PREMIUM: "30225.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19000001",
    TOTAL_SUMINS_MAX: "19100000",
    PLAN_NET_PREMIUM_AMT: "28232.79",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1984.21",
    PLAN_TOTAL_PREMIUM: "30330.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19100001",
    TOTAL_SUMINS_MAX: "19200000",
    PLAN_NET_PREMIUM_AMT: "28329.92",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1991.08",
    PLAN_TOTAL_PREMIUM: "30435.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19200001",
    TOTAL_SUMINS_MAX: "19300000",
    PLAN_NET_PREMIUM_AMT: "28428.06",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1997.94",
    PLAN_TOTAL_PREMIUM: "30540.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19300001",
    TOTAL_SUMINS_MAX: "19400000",
    PLAN_NET_PREMIUM_AMT: "28525.19",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2004.81",
    PLAN_TOTAL_PREMIUM: "30645.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19400001",
    TOTAL_SUMINS_MAX: "19500000",
    PLAN_NET_PREMIUM_AMT: "28623.32",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2011.68",
    PLAN_TOTAL_PREMIUM: "30750.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19500001",
    TOTAL_SUMINS_MAX: "19600000",
    PLAN_NET_PREMIUM_AMT: "28721.45",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2018.55",
    PLAN_TOTAL_PREMIUM: "30855.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19600001",
    TOTAL_SUMINS_MAX: "19700000",
    PLAN_NET_PREMIUM_AMT: "28818.58",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2025.42",
    PLAN_TOTAL_PREMIUM: "30960.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19700001",
    TOTAL_SUMINS_MAX: "19800000",
    PLAN_NET_PREMIUM_AMT: "28916.71",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2032.29",
    PLAN_TOTAL_PREMIUM: "31065.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19800001",
    TOTAL_SUMINS_MAX: "19900000",
    PLAN_NET_PREMIUM_AMT: "29013.84",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2039.16",
    PLAN_TOTAL_PREMIUM: "31170.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19900001",
    TOTAL_SUMINS_MAX: "20000000",
    PLAN_NET_PREMIUM_AMT: "29111.97",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2046.03",
    PLAN_TOTAL_PREMIUM: "31275.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20000001",
    TOTAL_SUMINS_MAX: "20100000",
    PLAN_NET_PREMIUM_AMT: "29210.10",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2052.90",
    PLAN_TOTAL_PREMIUM: "31380.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20100001",
    TOTAL_SUMINS_MAX: "20200000",
    PLAN_NET_PREMIUM_AMT: "29307.23",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2059.77",
    PLAN_TOTAL_PREMIUM: "31485.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20200001",
    TOTAL_SUMINS_MAX: "20300000",
    PLAN_NET_PREMIUM_AMT: "29405.36",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2066.64",
    PLAN_TOTAL_PREMIUM: "31590.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20300001",
    TOTAL_SUMINS_MAX: "20400000",
    PLAN_NET_PREMIUM_AMT: "29502.49",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2073.51",
    PLAN_TOTAL_PREMIUM: "31695.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20400001",
    TOTAL_SUMINS_MAX: "20500000",
    PLAN_NET_PREMIUM_AMT: "29600.63",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2080.37",
    PLAN_TOTAL_PREMIUM: "31800.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20500001",
    TOTAL_SUMINS_MAX: "20600000",
    PLAN_NET_PREMIUM_AMT: "29698.76",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2087.24",
    PLAN_TOTAL_PREMIUM: "31905.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20600001",
    TOTAL_SUMINS_MAX: "20700000",
    PLAN_NET_PREMIUM_AMT: "29795.89",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2094.11",
    PLAN_TOTAL_PREMIUM: "32010.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20700001",
    TOTAL_SUMINS_MAX: "20800000",
    PLAN_NET_PREMIUM_AMT: "29894.02",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2100.98",
    PLAN_TOTAL_PREMIUM: "32115.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20800001",
    TOTAL_SUMINS_MAX: "20900000",
    PLAN_NET_PREMIUM_AMT: "29992.15",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2107.85",
    PLAN_TOTAL_PREMIUM: "32220.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20900001",
    TOTAL_SUMINS_MAX: "21000000",
    PLAN_NET_PREMIUM_AMT: "30089.28",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2114.72",
    PLAN_TOTAL_PREMIUM: "32325.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21000001",
    TOTAL_SUMINS_MAX: "21100000",
    PLAN_NET_PREMIUM_AMT: "30187.41",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2121.59",
    PLAN_TOTAL_PREMIUM: "32430.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21100001",
    TOTAL_SUMINS_MAX: "21200000",
    PLAN_NET_PREMIUM_AMT: "30284.54",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2128.46",
    PLAN_TOTAL_PREMIUM: "32535.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21200001",
    TOTAL_SUMINS_MAX: "21300000",
    PLAN_NET_PREMIUM_AMT: "30382.67",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2135.33",
    PLAN_TOTAL_PREMIUM: "32640.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21300001",
    TOTAL_SUMINS_MAX: "21400000",
    PLAN_NET_PREMIUM_AMT: "30480.80",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2142.20",
    PLAN_TOTAL_PREMIUM: "32745.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21400001",
    TOTAL_SUMINS_MAX: "21500000",
    PLAN_NET_PREMIUM_AMT: "30577.93",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2149.07",
    PLAN_TOTAL_PREMIUM: "32850.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21500001",
    TOTAL_SUMINS_MAX: "21600000",
    PLAN_NET_PREMIUM_AMT: "30676.07",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2155.93",
    PLAN_TOTAL_PREMIUM: "32955.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21600001",
    TOTAL_SUMINS_MAX: "21700000",
    PLAN_NET_PREMIUM_AMT: "30773.20",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2162.80",
    PLAN_TOTAL_PREMIUM: "33060.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21700001",
    TOTAL_SUMINS_MAX: "21800000",
    PLAN_NET_PREMIUM_AMT: "30871.33",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2169.67",
    PLAN_TOTAL_PREMIUM: "33165.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21800001",
    TOTAL_SUMINS_MAX: "21900000",
    PLAN_NET_PREMIUM_AMT: "30969.46",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2176.54",
    PLAN_TOTAL_PREMIUM: "33270.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21900001",
    TOTAL_SUMINS_MAX: "22000000",
    PLAN_NET_PREMIUM_AMT: "31066.59",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2183.41",
    PLAN_TOTAL_PREMIUM: "33375.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22000001",
    TOTAL_SUMINS_MAX: "22100000",
    PLAN_NET_PREMIUM_AMT: "31164.72",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2190.28",
    PLAN_TOTAL_PREMIUM: "33480.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22100001",
    TOTAL_SUMINS_MAX: "22200000",
    PLAN_NET_PREMIUM_AMT: "31261.85",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2197.15",
    PLAN_TOTAL_PREMIUM: "33585.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22200001",
    TOTAL_SUMINS_MAX: "22300000",
    PLAN_NET_PREMIUM_AMT: "31359.98",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2204.02",
    PLAN_TOTAL_PREMIUM: "33690.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22300001",
    TOTAL_SUMINS_MAX: "22400000",
    PLAN_NET_PREMIUM_AMT: "31458.11",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2210.89",
    PLAN_TOTAL_PREMIUM: "33795.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22400001",
    TOTAL_SUMINS_MAX: "22500000",
    PLAN_NET_PREMIUM_AMT: "31555.24",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2217.76",
    PLAN_TOTAL_PREMIUM: "33900.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22500001",
    TOTAL_SUMINS_MAX: "22600000",
    PLAN_NET_PREMIUM_AMT: "31653.37",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2224.63",
    PLAN_TOTAL_PREMIUM: "34005.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22600001",
    TOTAL_SUMINS_MAX: "22700000",
    PLAN_NET_PREMIUM_AMT: "31750.50",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2231.50",
    PLAN_TOTAL_PREMIUM: "34110.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22700001",
    TOTAL_SUMINS_MAX: "22800000",
    PLAN_NET_PREMIUM_AMT: "31848.64",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2238.36",
    PLAN_TOTAL_PREMIUM: "34215.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22800001",
    TOTAL_SUMINS_MAX: "22900000",
    PLAN_NET_PREMIUM_AMT: "31946.77",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2245.23",
    PLAN_TOTAL_PREMIUM: "34320.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22900001",
    TOTAL_SUMINS_MAX: "23000000",
    PLAN_NET_PREMIUM_AMT: "32043.90",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2252.10",
    PLAN_TOTAL_PREMIUM: "34425.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23000001",
    TOTAL_SUMINS_MAX: "23100000",
    PLAN_NET_PREMIUM_AMT: "32142.03",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2258.97",
    PLAN_TOTAL_PREMIUM: "34530.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23100001",
    TOTAL_SUMINS_MAX: "23200000",
    PLAN_NET_PREMIUM_AMT: "32240.16",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2265.84",
    PLAN_TOTAL_PREMIUM: "34635.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23200001",
    TOTAL_SUMINS_MAX: "23300000",
    PLAN_NET_PREMIUM_AMT: "32337.29",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2272.71",
    PLAN_TOTAL_PREMIUM: "34740.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23300001",
    TOTAL_SUMINS_MAX: "23400000",
    PLAN_NET_PREMIUM_AMT: "32435.42",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2279.58",
    PLAN_TOTAL_PREMIUM: "34845.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23400001",
    TOTAL_SUMINS_MAX: "23500000",
    PLAN_NET_PREMIUM_AMT: "32532.55",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2286.45",
    PLAN_TOTAL_PREMIUM: "34950.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23500001",
    TOTAL_SUMINS_MAX: "23600000",
    PLAN_NET_PREMIUM_AMT: "32630.68",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2293.32",
    PLAN_TOTAL_PREMIUM: "35055.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23600001",
    TOTAL_SUMINS_MAX: "23700000",
    PLAN_NET_PREMIUM_AMT: "32728.81",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2300.19",
    PLAN_TOTAL_PREMIUM: "35160.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23700001",
    TOTAL_SUMINS_MAX: "23800000",
    PLAN_NET_PREMIUM_AMT: "32825.94",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2307.06",
    PLAN_TOTAL_PREMIUM: "35265.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23800001",
    TOTAL_SUMINS_MAX: "23900000",
    PLAN_NET_PREMIUM_AMT: "32924.07",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2313.93",
    PLAN_TOTAL_PREMIUM: "35370.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23900001",
    TOTAL_SUMINS_MAX: "24000000",
    PLAN_NET_PREMIUM_AMT: "33021.21",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2320.79",
    PLAN_TOTAL_PREMIUM: "35475.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24000001",
    TOTAL_SUMINS_MAX: "24100000",
    PLAN_NET_PREMIUM_AMT: "33119.34",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2327.66",
    PLAN_TOTAL_PREMIUM: "35580.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24100001",
    TOTAL_SUMINS_MAX: "24200000",
    PLAN_NET_PREMIUM_AMT: "33217.47",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2334.53",
    PLAN_TOTAL_PREMIUM: "35685.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24200001",
    TOTAL_SUMINS_MAX: "24300000",
    PLAN_NET_PREMIUM_AMT: "33314.60",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2341.40",
    PLAN_TOTAL_PREMIUM: "35790.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24300001",
    TOTAL_SUMINS_MAX: "24400000",
    PLAN_NET_PREMIUM_AMT: "33412.73",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2348.27",
    PLAN_TOTAL_PREMIUM: "35895.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24400001",
    TOTAL_SUMINS_MAX: "24500000",
    PLAN_NET_PREMIUM_AMT: "33509.86",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2355.14",
    PLAN_TOTAL_PREMIUM: "36000.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24500001",
    TOTAL_SUMINS_MAX: "24600000",
    PLAN_NET_PREMIUM_AMT: "33607.99",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2362.01",
    PLAN_TOTAL_PREMIUM: "36105.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24600001",
    TOTAL_SUMINS_MAX: "24700000",
    PLAN_NET_PREMIUM_AMT: "33706.12",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2368.88",
    PLAN_TOTAL_PREMIUM: "36210.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24700001",
    TOTAL_SUMINS_MAX: "24800000",
    PLAN_NET_PREMIUM_AMT: "33803.25",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2375.75",
    PLAN_TOTAL_PREMIUM: "36315.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24800001",
    TOTAL_SUMINS_MAX: "24900000",
    PLAN_NET_PREMIUM_AMT: "33901.38",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2382.62",
    PLAN_TOTAL_PREMIUM: "36420.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24900001",
    TOTAL_SUMINS_MAX: "25000000",
    PLAN_NET_PREMIUM_AMT: "33999.51",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2389.49",
    PLAN_TOTAL_PREMIUM: "36525.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25000001",
    TOTAL_SUMINS_MAX: "25100000",
    PLAN_NET_PREMIUM_AMT: "34096.64",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2396.36",
    PLAN_TOTAL_PREMIUM: "36630.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25100001",
    TOTAL_SUMINS_MAX: "25200000",
    PLAN_NET_PREMIUM_AMT: "34194.78",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2403.22",
    PLAN_TOTAL_PREMIUM: "36735.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25200001",
    TOTAL_SUMINS_MAX: "25300000",
    PLAN_NET_PREMIUM_AMT: "34291.91",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2410.09",
    PLAN_TOTAL_PREMIUM: "36840.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25300001",
    TOTAL_SUMINS_MAX: "25400000",
    PLAN_NET_PREMIUM_AMT: "34390.04",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2416.96",
    PLAN_TOTAL_PREMIUM: "36945.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25400001",
    TOTAL_SUMINS_MAX: "25500000",
    PLAN_NET_PREMIUM_AMT: "34488.17",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2423.83",
    PLAN_TOTAL_PREMIUM: "37050.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25500001",
    TOTAL_SUMINS_MAX: "25600000",
    PLAN_NET_PREMIUM_AMT: "34585.30",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2430.70",
    PLAN_TOTAL_PREMIUM: "37155.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25600001",
    TOTAL_SUMINS_MAX: "25700000",
    PLAN_NET_PREMIUM_AMT: "34683.43",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2437.57",
    PLAN_TOTAL_PREMIUM: "37260.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25700001",
    TOTAL_SUMINS_MAX: "25800000",
    PLAN_NET_PREMIUM_AMT: "34780.56",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2444.44",
    PLAN_TOTAL_PREMIUM: "37365.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25800001",
    TOTAL_SUMINS_MAX: "25900000",
    PLAN_NET_PREMIUM_AMT: "34878.69",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2451.31",
    PLAN_TOTAL_PREMIUM: "37470.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25900001",
    TOTAL_SUMINS_MAX: "26000000",
    PLAN_NET_PREMIUM_AMT: "34976.82",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2458.18",
    PLAN_TOTAL_PREMIUM: "37575.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26000001",
    TOTAL_SUMINS_MAX: "26100000",
    PLAN_NET_PREMIUM_AMT: "35073.95",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2465.05",
    PLAN_TOTAL_PREMIUM: "37680.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26100001",
    TOTAL_SUMINS_MAX: "26200000",
    PLAN_NET_PREMIUM_AMT: "35172.08",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2471.92",
    PLAN_TOTAL_PREMIUM: "37785.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26200001",
    TOTAL_SUMINS_MAX: "26300000",
    PLAN_NET_PREMIUM_AMT: "35269.21",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2478.79",
    PLAN_TOTAL_PREMIUM: "37890.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26300001",
    TOTAL_SUMINS_MAX: "26400000",
    PLAN_NET_PREMIUM_AMT: "35367.35",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2485.65",
    PLAN_TOTAL_PREMIUM: "37995.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26400001",
    TOTAL_SUMINS_MAX: "26500000",
    PLAN_NET_PREMIUM_AMT: "35465.48",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2492.52",
    PLAN_TOTAL_PREMIUM: "38100.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26500001",
    TOTAL_SUMINS_MAX: "26600000",
    PLAN_NET_PREMIUM_AMT: "35562.61",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2499.39",
    PLAN_TOTAL_PREMIUM: "38205.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26600001",
    TOTAL_SUMINS_MAX: "26700000",
    PLAN_NET_PREMIUM_AMT: "35660.74",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2506.26",
    PLAN_TOTAL_PREMIUM: "38310.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26700001",
    TOTAL_SUMINS_MAX: "26800000",
    PLAN_NET_PREMIUM_AMT: "35757.87",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2513.13",
    PLAN_TOTAL_PREMIUM: "38415.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26800001",
    TOTAL_SUMINS_MAX: "26900000",
    PLAN_NET_PREMIUM_AMT: "35856.00",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2520.00",
    PLAN_TOTAL_PREMIUM: "38520.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26900001",
    TOTAL_SUMINS_MAX: "27000000",
    PLAN_NET_PREMIUM_AMT: "35954.13",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2526.87",
    PLAN_TOTAL_PREMIUM: "38625.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27000001",
    TOTAL_SUMINS_MAX: "27100000",
    PLAN_NET_PREMIUM_AMT: "36051.26",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2533.74",
    PLAN_TOTAL_PREMIUM: "38730.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27100001",
    TOTAL_SUMINS_MAX: "27200000",
    PLAN_NET_PREMIUM_AMT: "36149.39",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2540.61",
    PLAN_TOTAL_PREMIUM: "38835.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27200001",
    TOTAL_SUMINS_MAX: "27300000",
    PLAN_NET_PREMIUM_AMT: "36247.52",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2547.48",
    PLAN_TOTAL_PREMIUM: "38940.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27300001",
    TOTAL_SUMINS_MAX: "27400000",
    PLAN_NET_PREMIUM_AMT: "36344.65",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2554.35",
    PLAN_TOTAL_PREMIUM: "39045.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27400001",
    TOTAL_SUMINS_MAX: "27500000",
    PLAN_NET_PREMIUM_AMT: "36442.78",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2561.22",
    PLAN_TOTAL_PREMIUM: "39150.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27500001",
    TOTAL_SUMINS_MAX: "27600000",
    PLAN_NET_PREMIUM_AMT: "36539.92",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2568.08",
    PLAN_TOTAL_PREMIUM: "39255.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27600001",
    TOTAL_SUMINS_MAX: "27700000",
    PLAN_NET_PREMIUM_AMT: "36638.05",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2574.95",
    PLAN_TOTAL_PREMIUM: "39360.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27700001",
    TOTAL_SUMINS_MAX: "27800000",
    PLAN_NET_PREMIUM_AMT: "36736.18",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2581.82",
    PLAN_TOTAL_PREMIUM: "39465.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27800001",
    TOTAL_SUMINS_MAX: "27900000",
    PLAN_NET_PREMIUM_AMT: "36833.31",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2588.69",
    PLAN_TOTAL_PREMIUM: "39570.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27900001",
    TOTAL_SUMINS_MAX: "28000000",
    PLAN_NET_PREMIUM_AMT: "36931.44",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2595.56",
    PLAN_TOTAL_PREMIUM: "39675.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28000001",
    TOTAL_SUMINS_MAX: "28100000",
    PLAN_NET_PREMIUM_AMT: "37028.57",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2602.43",
    PLAN_TOTAL_PREMIUM: "39780.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28100001",
    TOTAL_SUMINS_MAX: "28200000",
    PLAN_NET_PREMIUM_AMT: "37126.70",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2609.30",
    PLAN_TOTAL_PREMIUM: "39885.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28200001",
    TOTAL_SUMINS_MAX: "28300000",
    PLAN_NET_PREMIUM_AMT: "37224.83",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2616.17",
    PLAN_TOTAL_PREMIUM: "39990.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28300001",
    TOTAL_SUMINS_MAX: "28400000",
    PLAN_NET_PREMIUM_AMT: "37321.96",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2623.04",
    PLAN_TOTAL_PREMIUM: "40095.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28400001",
    TOTAL_SUMINS_MAX: "28500000",
    PLAN_NET_PREMIUM_AMT: "37420.09",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2629.91",
    PLAN_TOTAL_PREMIUM: "40200.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28500001",
    TOTAL_SUMINS_MAX: "28600000",
    PLAN_NET_PREMIUM_AMT: "37517.22",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2636.78",
    PLAN_TOTAL_PREMIUM: "40305.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28600001",
    TOTAL_SUMINS_MAX: "28700000",
    PLAN_NET_PREMIUM_AMT: "37615.35",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2643.65",
    PLAN_TOTAL_PREMIUM: "40410.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28700001",
    TOTAL_SUMINS_MAX: "28800000",
    PLAN_NET_PREMIUM_AMT: "37713.49",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2650.51",
    PLAN_TOTAL_PREMIUM: "40515.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28800001",
    TOTAL_SUMINS_MAX: "28900000",
    PLAN_NET_PREMIUM_AMT: "37810.62",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2657.38",
    PLAN_TOTAL_PREMIUM: "40620.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28900001",
    TOTAL_SUMINS_MAX: "29000000",
    PLAN_NET_PREMIUM_AMT: "37908.75",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2664.25",
    PLAN_TOTAL_PREMIUM: "40725.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29000001",
    TOTAL_SUMINS_MAX: "29100000",
    PLAN_NET_PREMIUM_AMT: "38005.88",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2671.12",
    PLAN_TOTAL_PREMIUM: "40830.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29100001",
    TOTAL_SUMINS_MAX: "29200000",
    PLAN_NET_PREMIUM_AMT: "38104.01",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2677.99",
    PLAN_TOTAL_PREMIUM: "40935.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29200001",
    TOTAL_SUMINS_MAX: "29300000",
    PLAN_NET_PREMIUM_AMT: "38202.14",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2684.86",
    PLAN_TOTAL_PREMIUM: "41040.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29300001",
    TOTAL_SUMINS_MAX: "29400000",
    PLAN_NET_PREMIUM_AMT: "38299.27",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2691.73",
    PLAN_TOTAL_PREMIUM: "41145.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29400001",
    TOTAL_SUMINS_MAX: "29500000",
    PLAN_NET_PREMIUM_AMT: "38397.40",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2698.60",
    PLAN_TOTAL_PREMIUM: "41250.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29500001",
    TOTAL_SUMINS_MAX: "29600000",
    PLAN_NET_PREMIUM_AMT: "38495.53",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2705.47",
    PLAN_TOTAL_PREMIUM: "41355.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29600001",
    TOTAL_SUMINS_MAX: "29700000",
    PLAN_NET_PREMIUM_AMT: "38592.66",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2712.34",
    PLAN_TOTAL_PREMIUM: "41460.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29700001",
    TOTAL_SUMINS_MAX: "29800000",
    PLAN_NET_PREMIUM_AMT: "38690.79",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2719.21",
    PLAN_TOTAL_PREMIUM: "41565.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29800001",
    TOTAL_SUMINS_MAX: "29900000",
    PLAN_NET_PREMIUM_AMT: "38787.92",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2726.08",
    PLAN_TOTAL_PREMIUM: "41670.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29900001",
    TOTAL_SUMINS_MAX: "30000000",
    PLAN_NET_PREMIUM_AMT: "38886.06",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2732.94",
    PLAN_TOTAL_PREMIUM: "41775.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30000001",
    TOTAL_SUMINS_MAX: "30100000",
    PLAN_NET_PREMIUM_AMT: "38984.19",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2739.81",
    PLAN_TOTAL_PREMIUM: "41880.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30100001",
    TOTAL_SUMINS_MAX: "30200000",
    PLAN_NET_PREMIUM_AMT: "39081.32",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2746.68",
    PLAN_TOTAL_PREMIUM: "41985.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30200001",
    TOTAL_SUMINS_MAX: "30300000",
    PLAN_NET_PREMIUM_AMT: "39179.45",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2753.55",
    PLAN_TOTAL_PREMIUM: "42090.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30300001",
    TOTAL_SUMINS_MAX: "30400000",
    PLAN_NET_PREMIUM_AMT: "39276.58",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2760.42",
    PLAN_TOTAL_PREMIUM: "42195.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30400001",
    TOTAL_SUMINS_MAX: "30500000",
    PLAN_NET_PREMIUM_AMT: "39374.71",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2767.29",
    PLAN_TOTAL_PREMIUM: "42300.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30500001",
    TOTAL_SUMINS_MAX: "30600000",
    PLAN_NET_PREMIUM_AMT: "39472.84",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2774.16",
    PLAN_TOTAL_PREMIUM: "42405.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30600001",
    TOTAL_SUMINS_MAX: "30700000",
    PLAN_NET_PREMIUM_AMT: "39569.97",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2781.03",
    PLAN_TOTAL_PREMIUM: "42510.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30700001",
    TOTAL_SUMINS_MAX: "30800000",
    PLAN_NET_PREMIUM_AMT: "39668.10",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2787.90",
    PLAN_TOTAL_PREMIUM: "42615.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30800001",
    TOTAL_SUMINS_MAX: "30900000",
    PLAN_NET_PREMIUM_AMT: "39765.23",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2794.77",
    PLAN_TOTAL_PREMIUM: "42720.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30900001",
    TOTAL_SUMINS_MAX: "31000000",
    PLAN_NET_PREMIUM_AMT: "39863.36",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2801.64",
    PLAN_TOTAL_PREMIUM: "42825.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31000001",
    TOTAL_SUMINS_MAX: "31100000",
    PLAN_NET_PREMIUM_AMT: "39961.49",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2808.51",
    PLAN_TOTAL_PREMIUM: "42930.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31100001",
    TOTAL_SUMINS_MAX: "31200000",
    PLAN_NET_PREMIUM_AMT: "40058.63",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2815.37",
    PLAN_TOTAL_PREMIUM: "43035.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31200001",
    TOTAL_SUMINS_MAX: "31300000",
    PLAN_NET_PREMIUM_AMT: "40156.76",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2822.24",
    PLAN_TOTAL_PREMIUM: "43140.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31300001",
    TOTAL_SUMINS_MAX: "31400000",
    PLAN_NET_PREMIUM_AMT: "40253.89",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2829.11",
    PLAN_TOTAL_PREMIUM: "43245.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31400001",
    TOTAL_SUMINS_MAX: "31500000",
    PLAN_NET_PREMIUM_AMT: "40352.02",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2835.98",
    PLAN_TOTAL_PREMIUM: "43350.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31500001",
    TOTAL_SUMINS_MAX: "31600000",
    PLAN_NET_PREMIUM_AMT: "40450.15",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2842.85",
    PLAN_TOTAL_PREMIUM: "43455.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31600001",
    TOTAL_SUMINS_MAX: "31700000",
    PLAN_NET_PREMIUM_AMT: "40547.28",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2849.72",
    PLAN_TOTAL_PREMIUM: "43560.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31700001",
    TOTAL_SUMINS_MAX: "31800000",
    PLAN_NET_PREMIUM_AMT: "40645.41",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2856.59",
    PLAN_TOTAL_PREMIUM: "43665.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31800001",
    TOTAL_SUMINS_MAX: "31900000",
    PLAN_NET_PREMIUM_AMT: "40743.54",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2863.46",
    PLAN_TOTAL_PREMIUM: "43770.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31900001",
    TOTAL_SUMINS_MAX: "32000000",
    PLAN_NET_PREMIUM_AMT: "40840.67",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2870.33",
    PLAN_TOTAL_PREMIUM: "43875.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32000001",
    TOTAL_SUMINS_MAX: "32100000",
    PLAN_NET_PREMIUM_AMT: "40938.80",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2877.20",
    PLAN_TOTAL_PREMIUM: "43980.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32100001",
    TOTAL_SUMINS_MAX: "32200000",
    PLAN_NET_PREMIUM_AMT: "41035.93",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2884.07",
    PLAN_TOTAL_PREMIUM: "44085.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32200001",
    TOTAL_SUMINS_MAX: "32300000",
    PLAN_NET_PREMIUM_AMT: "41134.07",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2890.93",
    PLAN_TOTAL_PREMIUM: "44190.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32300001",
    TOTAL_SUMINS_MAX: "32400000",
    PLAN_NET_PREMIUM_AMT: "41232.20",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2897.80",
    PLAN_TOTAL_PREMIUM: "44295.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32400001",
    TOTAL_SUMINS_MAX: "32500000",
    PLAN_NET_PREMIUM_AMT: "41329.33",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2904.67",
    PLAN_TOTAL_PREMIUM: "44400.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32500001",
    TOTAL_SUMINS_MAX: "32600000",
    PLAN_NET_PREMIUM_AMT: "41427.46",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2911.54",
    PLAN_TOTAL_PREMIUM: "44505.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32600001",
    TOTAL_SUMINS_MAX: "32700000",
    PLAN_NET_PREMIUM_AMT: "41524.59",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2918.41",
    PLAN_TOTAL_PREMIUM: "44610.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32700001",
    TOTAL_SUMINS_MAX: "32800000",
    PLAN_NET_PREMIUM_AMT: "41622.72",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2925.28",
    PLAN_TOTAL_PREMIUM: "44715.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32800001",
    TOTAL_SUMINS_MAX: "32900000",
    PLAN_NET_PREMIUM_AMT: "41720.85",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2932.15",
    PLAN_TOTAL_PREMIUM: "44820.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32900001",
    TOTAL_SUMINS_MAX: "33000000",
    PLAN_NET_PREMIUM_AMT: "41817.98",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2939.02",
    PLAN_TOTAL_PREMIUM: "44925.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33000001",
    TOTAL_SUMINS_MAX: "33100000",
    PLAN_NET_PREMIUM_AMT: "41916.11",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2945.89",
    PLAN_TOTAL_PREMIUM: "45030.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33100001",
    TOTAL_SUMINS_MAX: "33200000",
    PLAN_NET_PREMIUM_AMT: "42013.24",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2952.76",
    PLAN_TOTAL_PREMIUM: "45135.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33200001",
    TOTAL_SUMINS_MAX: "33300000",
    PLAN_NET_PREMIUM_AMT: "42111.37",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2959.63",
    PLAN_TOTAL_PREMIUM: "45240.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33300001",
    TOTAL_SUMINS_MAX: "33400000",
    PLAN_NET_PREMIUM_AMT: "42209.50",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2966.50",
    PLAN_TOTAL_PREMIUM: "45345.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33400001",
    TOTAL_SUMINS_MAX: "33500000",
    PLAN_NET_PREMIUM_AMT: "42306.64",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2973.36",
    PLAN_TOTAL_PREMIUM: "45450.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33500001",
    TOTAL_SUMINS_MAX: "33600000",
    PLAN_NET_PREMIUM_AMT: "42404.77",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2980.23",
    PLAN_TOTAL_PREMIUM: "45555.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33600001",
    TOTAL_SUMINS_MAX: "33700000",
    PLAN_NET_PREMIUM_AMT: "42501.90",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2987.10",
    PLAN_TOTAL_PREMIUM: "45660.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33700001",
    TOTAL_SUMINS_MAX: "33800000",
    PLAN_NET_PREMIUM_AMT: "42600.03",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2993.97",
    PLAN_TOTAL_PREMIUM: "45765.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33800001",
    TOTAL_SUMINS_MAX: "33900000",
    PLAN_NET_PREMIUM_AMT: "42698.16",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "3000.84",
    PLAN_TOTAL_PREMIUM: "45870.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33900001",
    TOTAL_SUMINS_MAX: "34000000",
    PLAN_NET_PREMIUM_AMT: "42795.29",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3007.71",
    PLAN_TOTAL_PREMIUM: "45975.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34000001",
    TOTAL_SUMINS_MAX: "34100000",
    PLAN_NET_PREMIUM_AMT: "42893.42",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3014.58",
    PLAN_TOTAL_PREMIUM: "46080.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34100001",
    TOTAL_SUMINS_MAX: "34200000",
    PLAN_NET_PREMIUM_AMT: "42991.55",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3021.45",
    PLAN_TOTAL_PREMIUM: "46185.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34200001",
    TOTAL_SUMINS_MAX: "34300000",
    PLAN_NET_PREMIUM_AMT: "43088.68",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3028.32",
    PLAN_TOTAL_PREMIUM: "46290.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34300001",
    TOTAL_SUMINS_MAX: "34400000",
    PLAN_NET_PREMIUM_AMT: "43186.81",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3035.19",
    PLAN_TOTAL_PREMIUM: "46395.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34400001",
    TOTAL_SUMINS_MAX: "34500000",
    PLAN_NET_PREMIUM_AMT: "43283.94",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3042.06",
    PLAN_TOTAL_PREMIUM: "46500.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34500001",
    TOTAL_SUMINS_MAX: "34600000",
    PLAN_NET_PREMIUM_AMT: "43382.07",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3048.93",
    PLAN_TOTAL_PREMIUM: "46605.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34600001",
    TOTAL_SUMINS_MAX: "34700000",
    PLAN_NET_PREMIUM_AMT: "43480.21",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3055.79",
    PLAN_TOTAL_PREMIUM: "46710.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34700001",
    TOTAL_SUMINS_MAX: "34800000",
    PLAN_NET_PREMIUM_AMT: "43577.34",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3062.66",
    PLAN_TOTAL_PREMIUM: "46815.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34800001",
    TOTAL_SUMINS_MAX: "34900000",
    PLAN_NET_PREMIUM_AMT: "43675.47",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3069.53",
    PLAN_TOTAL_PREMIUM: "46920.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34900001",
    TOTAL_SUMINS_MAX: "35000000",
    PLAN_NET_PREMIUM_AMT: "43772.60",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3076.40",
    PLAN_TOTAL_PREMIUM: "47025.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35000001",
    TOTAL_SUMINS_MAX: "35100000",
    PLAN_NET_PREMIUM_AMT: "43870.73",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3083.27",
    PLAN_TOTAL_PREMIUM: "47130.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35100001",
    TOTAL_SUMINS_MAX: "35200000",
    PLAN_NET_PREMIUM_AMT: "43968.86",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3090.14",
    PLAN_TOTAL_PREMIUM: "47235.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35200001",
    TOTAL_SUMINS_MAX: "35300000",
    PLAN_NET_PREMIUM_AMT: "44065.99",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3097.01",
    PLAN_TOTAL_PREMIUM: "47340.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35300001",
    TOTAL_SUMINS_MAX: "35400000",
    PLAN_NET_PREMIUM_AMT: "44164.12",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3103.88",
    PLAN_TOTAL_PREMIUM: "47445.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35400001",
    TOTAL_SUMINS_MAX: "35500000",
    PLAN_NET_PREMIUM_AMT: "44261.25",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3110.75",
    PLAN_TOTAL_PREMIUM: "47550.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35500001",
    TOTAL_SUMINS_MAX: "35600000",
    PLAN_NET_PREMIUM_AMT: "44359.38",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3117.62",
    PLAN_TOTAL_PREMIUM: "47655.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35600001",
    TOTAL_SUMINS_MAX: "35700000",
    PLAN_NET_PREMIUM_AMT: "44457.51",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3124.49",
    PLAN_TOTAL_PREMIUM: "47760.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35700001",
    TOTAL_SUMINS_MAX: "35800000",
    PLAN_NET_PREMIUM_AMT: "44554.64",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3131.36",
    PLAN_TOTAL_PREMIUM: "47865.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35800001",
    TOTAL_SUMINS_MAX: "35900000",
    PLAN_NET_PREMIUM_AMT: "44652.78",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3138.22",
    PLAN_TOTAL_PREMIUM: "47970.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35900001",
    TOTAL_SUMINS_MAX: "36000000",
    PLAN_NET_PREMIUM_AMT: "44749.91",
    PLAN_STAMP_DUTY_AMT: "180.00",
    PLAN_VAT_AMT: "3145.09",
    PLAN_TOTAL_PREMIUM: "48075.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36000001",
    TOTAL_SUMINS_MAX: "36100000",
    PLAN_NET_PREMIUM_AMT: "44848.04",
    PLAN_STAMP_DUTY_AMT: "180.00",
    PLAN_VAT_AMT: "3151.96",
    PLAN_TOTAL_PREMIUM: "48180.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36100001",
    TOTAL_SUMINS_MAX: "36200000",
    PLAN_NET_PREMIUM_AMT: "44946.17",
    PLAN_STAMP_DUTY_AMT: "180.00",
    PLAN_VAT_AMT: "3158.83",
    PLAN_TOTAL_PREMIUM: "48285.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36200001",
    TOTAL_SUMINS_MAX: "36300000",
    PLAN_NET_PREMIUM_AMT: "45043.30",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3165.70",
    PLAN_TOTAL_PREMIUM: "48390.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36300001",
    TOTAL_SUMINS_MAX: "36400000",
    PLAN_NET_PREMIUM_AMT: "45141.43",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3172.57",
    PLAN_TOTAL_PREMIUM: "48495.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36400001",
    TOTAL_SUMINS_MAX: "36500000",
    PLAN_NET_PREMIUM_AMT: "45239.56",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3179.44",
    PLAN_TOTAL_PREMIUM: "48600.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36500001",
    TOTAL_SUMINS_MAX: "36600000",
    PLAN_NET_PREMIUM_AMT: "45336.69",
    PLAN_STAMP_DUTY_AMT: "182.00",
    PLAN_VAT_AMT: "3186.31",
    PLAN_TOTAL_PREMIUM: "48705.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36600001",
    TOTAL_SUMINS_MAX: "36700000",
    PLAN_NET_PREMIUM_AMT: "45434.82",
    PLAN_STAMP_DUTY_AMT: "182.00",
    PLAN_VAT_AMT: "3193.18",
    PLAN_TOTAL_PREMIUM: "48810.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36700001",
    TOTAL_SUMINS_MAX: "36800000",
    PLAN_NET_PREMIUM_AMT: "45531.95",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3200.05",
    PLAN_TOTAL_PREMIUM: "48915.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36800001",
    TOTAL_SUMINS_MAX: "36900000",
    PLAN_NET_PREMIUM_AMT: "45630.08",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3206.92",
    PLAN_TOTAL_PREMIUM: "49020.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36900001",
    TOTAL_SUMINS_MAX: "37000000",
    PLAN_NET_PREMIUM_AMT: "45728.21",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3213.79",
    PLAN_TOTAL_PREMIUM: "49125.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37000001",
    TOTAL_SUMINS_MAX: "37100000",
    PLAN_NET_PREMIUM_AMT: "45825.35",
    PLAN_STAMP_DUTY_AMT: "184.00",
    PLAN_VAT_AMT: "3220.65",
    PLAN_TOTAL_PREMIUM: "49230.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37100001",
    TOTAL_SUMINS_MAX: "37200000",
    PLAN_NET_PREMIUM_AMT: "45923.48",
    PLAN_STAMP_DUTY_AMT: "184.00",
    PLAN_VAT_AMT: "3227.52",
    PLAN_TOTAL_PREMIUM: "49335.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37200001",
    TOTAL_SUMINS_MAX: "37300000",
    PLAN_NET_PREMIUM_AMT: "46020.61",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3234.39",
    PLAN_TOTAL_PREMIUM: "49440.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37300001",
    TOTAL_SUMINS_MAX: "37400000",
    PLAN_NET_PREMIUM_AMT: "46118.74",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3241.26",
    PLAN_TOTAL_PREMIUM: "49545.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37400001",
    TOTAL_SUMINS_MAX: "37500000",
    PLAN_NET_PREMIUM_AMT: "46216.87",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3248.13",
    PLAN_TOTAL_PREMIUM: "49650.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37500001",
    TOTAL_SUMINS_MAX: "37600000",
    PLAN_NET_PREMIUM_AMT: "46314.00",
    PLAN_STAMP_DUTY_AMT: "186.00",
    PLAN_VAT_AMT: "3255.00",
    PLAN_TOTAL_PREMIUM: "49755.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37600001",
    TOTAL_SUMINS_MAX: "37700000",
    PLAN_NET_PREMIUM_AMT: "46412.13",
    PLAN_STAMP_DUTY_AMT: "186.00",
    PLAN_VAT_AMT: "3261.87",
    PLAN_TOTAL_PREMIUM: "49860.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37700001",
    TOTAL_SUMINS_MAX: "37800000",
    PLAN_NET_PREMIUM_AMT: "46509.26",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3268.74",
    PLAN_TOTAL_PREMIUM: "49965.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37800001",
    TOTAL_SUMINS_MAX: "37900000",
    PLAN_NET_PREMIUM_AMT: "46607.39",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3275.61",
    PLAN_TOTAL_PREMIUM: "50070.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37900001",
    TOTAL_SUMINS_MAX: "38000000",
    PLAN_NET_PREMIUM_AMT: "46705.52",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3282.48",
    PLAN_TOTAL_PREMIUM: "50175.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38000001",
    TOTAL_SUMINS_MAX: "38100000",
    PLAN_NET_PREMIUM_AMT: "46802.65",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3289.35",
    PLAN_TOTAL_PREMIUM: "50280.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38100001",
    TOTAL_SUMINS_MAX: "38200000",
    PLAN_NET_PREMIUM_AMT: "46900.78",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3296.22",
    PLAN_TOTAL_PREMIUM: "50385.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38200001",
    TOTAL_SUMINS_MAX: "38300000",
    PLAN_NET_PREMIUM_AMT: "46998.92",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3303.08",
    PLAN_TOTAL_PREMIUM: "50490.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38300001",
    TOTAL_SUMINS_MAX: "38400000",
    PLAN_NET_PREMIUM_AMT: "47096.05",
    PLAN_STAMP_DUTY_AMT: "189.00",
    PLAN_VAT_AMT: "3309.95",
    PLAN_TOTAL_PREMIUM: "50595.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38400001",
    TOTAL_SUMINS_MAX: "38500000",
    PLAN_NET_PREMIUM_AMT: "47194.18",
    PLAN_STAMP_DUTY_AMT: "189.00",
    PLAN_VAT_AMT: "3316.82",
    PLAN_TOTAL_PREMIUM: "50700.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38500001",
    TOTAL_SUMINS_MAX: "38600000",
    PLAN_NET_PREMIUM_AMT: "47291.31",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3323.69",
    PLAN_TOTAL_PREMIUM: "50805.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38600001",
    TOTAL_SUMINS_MAX: "38700000",
    PLAN_NET_PREMIUM_AMT: "47389.44",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3330.56",
    PLAN_TOTAL_PREMIUM: "50910.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38700001",
    TOTAL_SUMINS_MAX: "38800000",
    PLAN_NET_PREMIUM_AMT: "47487.57",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3337.43",
    PLAN_TOTAL_PREMIUM: "51015.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38800001",
    TOTAL_SUMINS_MAX: "38900000",
    PLAN_NET_PREMIUM_AMT: "47584.70",
    PLAN_STAMP_DUTY_AMT: "191.00",
    PLAN_VAT_AMT: "3344.30",
    PLAN_TOTAL_PREMIUM: "51120.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38900001",
    TOTAL_SUMINS_MAX: "39000000",
    PLAN_NET_PREMIUM_AMT: "47682.83",
    PLAN_STAMP_DUTY_AMT: "191.00",
    PLAN_VAT_AMT: "3351.17",
    PLAN_TOTAL_PREMIUM: "51225.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39000001",
    TOTAL_SUMINS_MAX: "39100000",
    PLAN_NET_PREMIUM_AMT: "47779.96",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3358.04",
    PLAN_TOTAL_PREMIUM: "51330.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39100001",
    TOTAL_SUMINS_MAX: "39200000",
    PLAN_NET_PREMIUM_AMT: "47878.09",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3364.91",
    PLAN_TOTAL_PREMIUM: "51435.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39200001",
    TOTAL_SUMINS_MAX: "39300000",
    PLAN_NET_PREMIUM_AMT: "47976.22",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3371.78",
    PLAN_TOTAL_PREMIUM: "51540.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39300001",
    TOTAL_SUMINS_MAX: "39400000",
    PLAN_NET_PREMIUM_AMT: "48073.35",
    PLAN_STAMP_DUTY_AMT: "193.00",
    PLAN_VAT_AMT: "3378.65",
    PLAN_TOTAL_PREMIUM: "51645.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39400001",
    TOTAL_SUMINS_MAX: "39500000",
    PLAN_NET_PREMIUM_AMT: "48171.49",
    PLAN_STAMP_DUTY_AMT: "193.00",
    PLAN_VAT_AMT: "3385.51",
    PLAN_TOTAL_PREMIUM: "51750.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39500001",
    TOTAL_SUMINS_MAX: "39600000",
    PLAN_NET_PREMIUM_AMT: "48268.62",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3392.38",
    PLAN_TOTAL_PREMIUM: "51855.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39600001",
    TOTAL_SUMINS_MAX: "39700000",
    PLAN_NET_PREMIUM_AMT: "48366.75",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3399.25",
    PLAN_TOTAL_PREMIUM: "51960.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39700001",
    TOTAL_SUMINS_MAX: "39800000",
    PLAN_NET_PREMIUM_AMT: "48464.88",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3406.12",
    PLAN_TOTAL_PREMIUM: "52065.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39800001",
    TOTAL_SUMINS_MAX: "39900000",
    PLAN_NET_PREMIUM_AMT: "48562.01",
    PLAN_STAMP_DUTY_AMT: "195.00",
    PLAN_VAT_AMT: "3412.99",
    PLAN_TOTAL_PREMIUM: "52170.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39900001",
    TOTAL_SUMINS_MAX: "40000000",
    PLAN_NET_PREMIUM_AMT: "48660.14",
    PLAN_STAMP_DUTY_AMT: "195.00",
    PLAN_VAT_AMT: "3419.86",
    PLAN_TOTAL_PREMIUM: "52275.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40000001",
    TOTAL_SUMINS_MAX: "40100000",
    PLAN_NET_PREMIUM_AMT: "48757.27",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3426.73",
    PLAN_TOTAL_PREMIUM: "52380.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40100001",
    TOTAL_SUMINS_MAX: "40200000",
    PLAN_NET_PREMIUM_AMT: "48855.40",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3433.60",
    PLAN_TOTAL_PREMIUM: "52485.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40200001",
    TOTAL_SUMINS_MAX: "40300000",
    PLAN_NET_PREMIUM_AMT: "48953.53",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3440.47",
    PLAN_TOTAL_PREMIUM: "52590.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40300001",
    TOTAL_SUMINS_MAX: "40400000",
    PLAN_NET_PREMIUM_AMT: "49050.66",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3447.34",
    PLAN_TOTAL_PREMIUM: "52695.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40400001",
    TOTAL_SUMINS_MAX: "40500000",
    PLAN_NET_PREMIUM_AMT: "49148.79",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3454.21",
    PLAN_TOTAL_PREMIUM: "52800.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40500001",
    TOTAL_SUMINS_MAX: "40600000",
    PLAN_NET_PREMIUM_AMT: "49246.92",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3461.08",
    PLAN_TOTAL_PREMIUM: "52905.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40600001",
    TOTAL_SUMINS_MAX: "40700000",
    PLAN_NET_PREMIUM_AMT: "49344.06",
    PLAN_STAMP_DUTY_AMT: "198.00",
    PLAN_VAT_AMT: "3467.94",
    PLAN_TOTAL_PREMIUM: "53010.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40700001",
    TOTAL_SUMINS_MAX: "40800000",
    PLAN_NET_PREMIUM_AMT: "49442.19",
    PLAN_STAMP_DUTY_AMT: "198.00",
    PLAN_VAT_AMT: "3474.81",
    PLAN_TOTAL_PREMIUM: "53115.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40800001",
    TOTAL_SUMINS_MAX: "40900000",
    PLAN_NET_PREMIUM_AMT: "49539.32",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3481.68",
    PLAN_TOTAL_PREMIUM: "53220.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40900001",
    TOTAL_SUMINS_MAX: "41000000",
    PLAN_NET_PREMIUM_AMT: "49637.45",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3488.55",
    PLAN_TOTAL_PREMIUM: "53325.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41000001",
    TOTAL_SUMINS_MAX: "41100000",
    PLAN_NET_PREMIUM_AMT: "49735.58",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3495.42",
    PLAN_TOTAL_PREMIUM: "53430.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41100001",
    TOTAL_SUMINS_MAX: "41200000",
    PLAN_NET_PREMIUM_AMT: "49832.71",
    PLAN_STAMP_DUTY_AMT: "200.00",
    PLAN_VAT_AMT: "3502.29",
    PLAN_TOTAL_PREMIUM: "53535.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41200001",
    TOTAL_SUMINS_MAX: "41300000",
    PLAN_NET_PREMIUM_AMT: "49930.84",
    PLAN_STAMP_DUTY_AMT: "200.00",
    PLAN_VAT_AMT: "3509.16",
    PLAN_TOTAL_PREMIUM: "53640.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41300001",
    TOTAL_SUMINS_MAX: "41400000",
    PLAN_NET_PREMIUM_AMT: "50027.97",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3516.03",
    PLAN_TOTAL_PREMIUM: "53745.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41400001",
    TOTAL_SUMINS_MAX: "41500000",
    PLAN_NET_PREMIUM_AMT: "50126.10",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3522.90",
    PLAN_TOTAL_PREMIUM: "53850.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41500001",
    TOTAL_SUMINS_MAX: "41600000",
    PLAN_NET_PREMIUM_AMT: "50224.23",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3529.77",
    PLAN_TOTAL_PREMIUM: "53955.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41600001",
    TOTAL_SUMINS_MAX: "41700000",
    PLAN_NET_PREMIUM_AMT: "50321.36",
    PLAN_STAMP_DUTY_AMT: "202.00",
    PLAN_VAT_AMT: "3536.64",
    PLAN_TOTAL_PREMIUM: "54060.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41700001",
    TOTAL_SUMINS_MAX: "41800000",
    PLAN_NET_PREMIUM_AMT: "50419.49",
    PLAN_STAMP_DUTY_AMT: "202.00",
    PLAN_VAT_AMT: "3543.51",
    PLAN_TOTAL_PREMIUM: "54165.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41800001",
    TOTAL_SUMINS_MAX: "41900000",
    PLAN_NET_PREMIUM_AMT: "50516.63",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3550.37",
    PLAN_TOTAL_PREMIUM: "54270.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41900001",
    TOTAL_SUMINS_MAX: "42000000",
    PLAN_NET_PREMIUM_AMT: "50614.76",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3557.24",
    PLAN_TOTAL_PREMIUM: "54375.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42000001",
    TOTAL_SUMINS_MAX: "42100000",
    PLAN_NET_PREMIUM_AMT: "50712.89",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3564.11",
    PLAN_TOTAL_PREMIUM: "54480.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42100001",
    TOTAL_SUMINS_MAX: "42200000",
    PLAN_NET_PREMIUM_AMT: "50810.02",
    PLAN_STAMP_DUTY_AMT: "204.00",
    PLAN_VAT_AMT: "3570.98",
    PLAN_TOTAL_PREMIUM: "54585.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42200001",
    TOTAL_SUMINS_MAX: "42300000",
    PLAN_NET_PREMIUM_AMT: "50908.15",
    PLAN_STAMP_DUTY_AMT: "204.00",
    PLAN_VAT_AMT: "3577.85",
    PLAN_TOTAL_PREMIUM: "54690.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42300001",
    TOTAL_SUMINS_MAX: "42400000",
    PLAN_NET_PREMIUM_AMT: "51005.28",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3584.72",
    PLAN_TOTAL_PREMIUM: "54795.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42400001",
    TOTAL_SUMINS_MAX: "42500000",
    PLAN_NET_PREMIUM_AMT: "51103.41",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3591.59",
    PLAN_TOTAL_PREMIUM: "54900.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42500001",
    TOTAL_SUMINS_MAX: "42600000",
    PLAN_NET_PREMIUM_AMT: "51201.54",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3598.46",
    PLAN_TOTAL_PREMIUM: "55005.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42600001",
    TOTAL_SUMINS_MAX: "42700000",
    PLAN_NET_PREMIUM_AMT: "51298.67",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3605.33",
    PLAN_TOTAL_PREMIUM: "55110.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42700001",
    TOTAL_SUMINS_MAX: "42800000",
    PLAN_NET_PREMIUM_AMT: "51396.80",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3612.20",
    PLAN_TOTAL_PREMIUM: "55215.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42800001",
    TOTAL_SUMINS_MAX: "42900000",
    PLAN_NET_PREMIUM_AMT: "51494.93",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3619.07",
    PLAN_TOTAL_PREMIUM: "55320.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42900001",
    TOTAL_SUMINS_MAX: "43000000",
    PLAN_NET_PREMIUM_AMT: "51592.07",
    PLAN_STAMP_DUTY_AMT: "207.00",
    PLAN_VAT_AMT: "3625.93",
    PLAN_TOTAL_PREMIUM: "55425.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43000001",
    TOTAL_SUMINS_MAX: "43100000",
    PLAN_NET_PREMIUM_AMT: "51690.20",
    PLAN_STAMP_DUTY_AMT: "207.00",
    PLAN_VAT_AMT: "3632.80",
    PLAN_TOTAL_PREMIUM: "55530.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43100001",
    TOTAL_SUMINS_MAX: "43200000",
    PLAN_NET_PREMIUM_AMT: "51787.33",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3639.67",
    PLAN_TOTAL_PREMIUM: "55635.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43200001",
    TOTAL_SUMINS_MAX: "43300000",
    PLAN_NET_PREMIUM_AMT: "51885.46",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3646.54",
    PLAN_TOTAL_PREMIUM: "55740.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43300001",
    TOTAL_SUMINS_MAX: "43400000",
    PLAN_NET_PREMIUM_AMT: "51983.59",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3653.41",
    PLAN_TOTAL_PREMIUM: "55845.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43400001",
    TOTAL_SUMINS_MAX: "43500000",
    PLAN_NET_PREMIUM_AMT: "52080.72",
    PLAN_STAMP_DUTY_AMT: "209.00",
    PLAN_VAT_AMT: "3660.28",
    PLAN_TOTAL_PREMIUM: "55950.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43500001",
    TOTAL_SUMINS_MAX: "43600000",
    PLAN_NET_PREMIUM_AMT: "52178.85",
    PLAN_STAMP_DUTY_AMT: "209.00",
    PLAN_VAT_AMT: "3667.15",
    PLAN_TOTAL_PREMIUM: "56055.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43600001",
    TOTAL_SUMINS_MAX: "43700000",
    PLAN_NET_PREMIUM_AMT: "52275.98",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3674.02",
    PLAN_TOTAL_PREMIUM: "56160.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43700001",
    TOTAL_SUMINS_MAX: "43800000",
    PLAN_NET_PREMIUM_AMT: "52374.11",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3680.89",
    PLAN_TOTAL_PREMIUM: "56265.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43800001",
    TOTAL_SUMINS_MAX: "43900000",
    PLAN_NET_PREMIUM_AMT: "52472.24",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3687.76",
    PLAN_TOTAL_PREMIUM: "56370.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43900001",
    TOTAL_SUMINS_MAX: "44000000",
    PLAN_NET_PREMIUM_AMT: "52569.37",
    PLAN_STAMP_DUTY_AMT: "211.00",
    PLAN_VAT_AMT: "3694.63",
    PLAN_TOTAL_PREMIUM: "56475.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44000001",
    TOTAL_SUMINS_MAX: "44100000",
    PLAN_NET_PREMIUM_AMT: "52667.50",
    PLAN_STAMP_DUTY_AMT: "211.00",
    PLAN_VAT_AMT: "3701.50",
    PLAN_TOTAL_PREMIUM: "56580.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44100001",
    TOTAL_SUMINS_MAX: "44200000",
    PLAN_NET_PREMIUM_AMT: "52764.64",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3708.36",
    PLAN_TOTAL_PREMIUM: "56685.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44200001",
    TOTAL_SUMINS_MAX: "44300000",
    PLAN_NET_PREMIUM_AMT: "52862.77",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3715.23",
    PLAN_TOTAL_PREMIUM: "56790.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44300001",
    TOTAL_SUMINS_MAX: "44400000",
    PLAN_NET_PREMIUM_AMT: "52960.90",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3722.10",
    PLAN_TOTAL_PREMIUM: "56895.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44400001",
    TOTAL_SUMINS_MAX: "44500000",
    PLAN_NET_PREMIUM_AMT: "53058.03",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3728.97",
    PLAN_TOTAL_PREMIUM: "57000.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44500001",
    TOTAL_SUMINS_MAX: "44600000",
    PLAN_NET_PREMIUM_AMT: "53156.16",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3735.84",
    PLAN_TOTAL_PREMIUM: "57105.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44600001",
    TOTAL_SUMINS_MAX: "44700000",
    PLAN_NET_PREMIUM_AMT: "53253.29",
    PLAN_STAMP_DUTY_AMT: "214.00",
    PLAN_VAT_AMT: "3742.71",
    PLAN_TOTAL_PREMIUM: "57210.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44700001",
    TOTAL_SUMINS_MAX: "44800000",
    PLAN_NET_PREMIUM_AMT: "53351.42",
    PLAN_STAMP_DUTY_AMT: "214.00",
    PLAN_VAT_AMT: "3749.58",
    PLAN_TOTAL_PREMIUM: "57315.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44800001",
    TOTAL_SUMINS_MAX: "44900000",
    PLAN_NET_PREMIUM_AMT: "53449.55",
    PLAN_STAMP_DUTY_AMT: "214.00",
    PLAN_VAT_AMT: "3756.45",
    PLAN_TOTAL_PREMIUM: "57420.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44900001",
    TOTAL_SUMINS_MAX: "45000000",
    PLAN_NET_PREMIUM_AMT: "53546.68",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3763.32",
    PLAN_TOTAL_PREMIUM: "57525.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45000001",
    TOTAL_SUMINS_MAX: "45100000",
    PLAN_NET_PREMIUM_AMT: "53644.81",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3770.19",
    PLAN_TOTAL_PREMIUM: "57630.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45100001",
    TOTAL_SUMINS_MAX: "45200000",
    PLAN_NET_PREMIUM_AMT: "53742.94",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3777.06",
    PLAN_TOTAL_PREMIUM: "57735.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45200001",
    TOTAL_SUMINS_MAX: "45300000",
    PLAN_NET_PREMIUM_AMT: "53840.07",
    PLAN_STAMP_DUTY_AMT: "216.00",
    PLAN_VAT_AMT: "3783.93",
    PLAN_TOTAL_PREMIUM: "57840.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45300001",
    TOTAL_SUMINS_MAX: "45400000",
    PLAN_NET_PREMIUM_AMT: "53938.21",
    PLAN_STAMP_DUTY_AMT: "216.00",
    PLAN_VAT_AMT: "3790.79",
    PLAN_TOTAL_PREMIUM: "57945.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45400001",
    TOTAL_SUMINS_MAX: "45500000",
    PLAN_NET_PREMIUM_AMT: "54035.34",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3797.66",
    PLAN_TOTAL_PREMIUM: "58050.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45500001",
    TOTAL_SUMINS_MAX: "45600000",
    PLAN_NET_PREMIUM_AMT: "54133.47",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3804.53",
    PLAN_TOTAL_PREMIUM: "58155.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45600001",
    TOTAL_SUMINS_MAX: "45700000",
    PLAN_NET_PREMIUM_AMT: "54231.60",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3811.40",
    PLAN_TOTAL_PREMIUM: "58260.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45700001",
    TOTAL_SUMINS_MAX: "45800000",
    PLAN_NET_PREMIUM_AMT: "54328.73",
    PLAN_STAMP_DUTY_AMT: "218.00",
    PLAN_VAT_AMT: "3818.27",
    PLAN_TOTAL_PREMIUM: "58365.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45800001",
    TOTAL_SUMINS_MAX: "45900000",
    PLAN_NET_PREMIUM_AMT: "54426.86",
    PLAN_STAMP_DUTY_AMT: "218.00",
    PLAN_VAT_AMT: "3825.14",
    PLAN_TOTAL_PREMIUM: "58470.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45900001",
    TOTAL_SUMINS_MAX: "46000000",
    PLAN_NET_PREMIUM_AMT: "54523.99",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3832.01",
    PLAN_TOTAL_PREMIUM: "58575.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46000001",
    TOTAL_SUMINS_MAX: "46100000",
    PLAN_NET_PREMIUM_AMT: "54622.12",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3838.88",
    PLAN_TOTAL_PREMIUM: "58680.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46100001",
    TOTAL_SUMINS_MAX: "46200000",
    PLAN_NET_PREMIUM_AMT: "54720.25",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3845.75",
    PLAN_TOTAL_PREMIUM: "58785.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46200001",
    TOTAL_SUMINS_MAX: "46300000",
    PLAN_NET_PREMIUM_AMT: "54817.38",
    PLAN_STAMP_DUTY_AMT: "220.00",
    PLAN_VAT_AMT: "3852.62",
    PLAN_TOTAL_PREMIUM: "58890.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46300001",
    TOTAL_SUMINS_MAX: "46400000",
    PLAN_NET_PREMIUM_AMT: "54915.51",
    PLAN_STAMP_DUTY_AMT: "220.00",
    PLAN_VAT_AMT: "3859.49",
    PLAN_TOTAL_PREMIUM: "58995.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46400001",
    TOTAL_SUMINS_MAX: "46500000",
    PLAN_NET_PREMIUM_AMT: "55012.64",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3866.36",
    PLAN_TOTAL_PREMIUM: "59100.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46500001",
    TOTAL_SUMINS_MAX: "46600000",
    PLAN_NET_PREMIUM_AMT: "55110.78",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3873.22",
    PLAN_TOTAL_PREMIUM: "59205.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46600001",
    TOTAL_SUMINS_MAX: "46700000",
    PLAN_NET_PREMIUM_AMT: "55208.91",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3880.09",
    PLAN_TOTAL_PREMIUM: "59310.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46700001",
    TOTAL_SUMINS_MAX: "46800000",
    PLAN_NET_PREMIUM_AMT: "55306.04",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3886.96",
    PLAN_TOTAL_PREMIUM: "59415.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46800001",
    TOTAL_SUMINS_MAX: "46900000",
    PLAN_NET_PREMIUM_AMT: "55404.17",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3893.83",
    PLAN_TOTAL_PREMIUM: "59520.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46900001",
    TOTAL_SUMINS_MAX: "47000000",
    PLAN_NET_PREMIUM_AMT: "55501.30",
    PLAN_STAMP_DUTY_AMT: "223.00",
    PLAN_VAT_AMT: "3900.70",
    PLAN_TOTAL_PREMIUM: "59625.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47000001",
    TOTAL_SUMINS_MAX: "47100000",
    PLAN_NET_PREMIUM_AMT: "55599.43",
    PLAN_STAMP_DUTY_AMT: "223.00",
    PLAN_VAT_AMT: "3907.57",
    PLAN_TOTAL_PREMIUM: "59730.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47100001",
    TOTAL_SUMINS_MAX: "47200000",
    PLAN_NET_PREMIUM_AMT: "55697.56",
    PLAN_STAMP_DUTY_AMT: "223.00",
    PLAN_VAT_AMT: "3914.44",
    PLAN_TOTAL_PREMIUM: "59835.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47200001",
    TOTAL_SUMINS_MAX: "47300000",
    PLAN_NET_PREMIUM_AMT: "55794.69",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3921.31",
    PLAN_TOTAL_PREMIUM: "59940.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47300001",
    TOTAL_SUMINS_MAX: "47400000",
    PLAN_NET_PREMIUM_AMT: "55892.82",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3928.18",
    PLAN_TOTAL_PREMIUM: "60045.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47400001",
    TOTAL_SUMINS_MAX: "47500000",
    PLAN_NET_PREMIUM_AMT: "55990.95",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3935.05",
    PLAN_TOTAL_PREMIUM: "60150.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47500001",
    TOTAL_SUMINS_MAX: "47600000",
    PLAN_NET_PREMIUM_AMT: "56088.08",
    PLAN_STAMP_DUTY_AMT: "225.00",
    PLAN_VAT_AMT: "3941.92",
    PLAN_TOTAL_PREMIUM: "60255.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47600001",
    TOTAL_SUMINS_MAX: "47700000",
    PLAN_NET_PREMIUM_AMT: "56186.21",
    PLAN_STAMP_DUTY_AMT: "225.00",
    PLAN_VAT_AMT: "3948.79",
    PLAN_TOTAL_PREMIUM: "60360.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47700001",
    TOTAL_SUMINS_MAX: "47800000",
    PLAN_NET_PREMIUM_AMT: "56283.35",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3955.65",
    PLAN_TOTAL_PREMIUM: "60465.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47800001",
    TOTAL_SUMINS_MAX: "47900000",
    PLAN_NET_PREMIUM_AMT: "56381.48",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3962.52",
    PLAN_TOTAL_PREMIUM: "60570.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47900001",
    TOTAL_SUMINS_MAX: "48000000",
    PLAN_NET_PREMIUM_AMT: "56479.61",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3969.39",
    PLAN_TOTAL_PREMIUM: "60675.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48000001",
    TOTAL_SUMINS_MAX: "48100000",
    PLAN_NET_PREMIUM_AMT: "56576.74",
    PLAN_STAMP_DUTY_AMT: "227.00",
    PLAN_VAT_AMT: "3976.26",
    PLAN_TOTAL_PREMIUM: "60780.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48100001",
    TOTAL_SUMINS_MAX: "48200000",
    PLAN_NET_PREMIUM_AMT: "56674.87",
    PLAN_STAMP_DUTY_AMT: "227.00",
    PLAN_VAT_AMT: "3983.13",
    PLAN_TOTAL_PREMIUM: "60885.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48200001",
    TOTAL_SUMINS_MAX: "48300000",
    PLAN_NET_PREMIUM_AMT: "56772.00",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "3990.00",
    PLAN_TOTAL_PREMIUM: "60990.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48300001",
    TOTAL_SUMINS_MAX: "48400000",
    PLAN_NET_PREMIUM_AMT: "56870.13",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "3996.87",
    PLAN_TOTAL_PREMIUM: "61095.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48400001",
    TOTAL_SUMINS_MAX: "48500000",
    PLAN_NET_PREMIUM_AMT: "56968.26",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "4003.74",
    PLAN_TOTAL_PREMIUM: "61200.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48500001",
    TOTAL_SUMINS_MAX: "48600000",
    PLAN_NET_PREMIUM_AMT: "57065.39",
    PLAN_STAMP_DUTY_AMT: "229.00",
    PLAN_VAT_AMT: "4010.61",
    PLAN_TOTAL_PREMIUM: "61305.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48600001",
    TOTAL_SUMINS_MAX: "48700000",
    PLAN_NET_PREMIUM_AMT: "57163.52",
    PLAN_STAMP_DUTY_AMT: "229.00",
    PLAN_VAT_AMT: "4017.48",
    PLAN_TOTAL_PREMIUM: "61410.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48700001",
    TOTAL_SUMINS_MAX: "48800000",
    PLAN_NET_PREMIUM_AMT: "57260.65",
    PLAN_STAMP_DUTY_AMT: "230.00",
    PLAN_VAT_AMT: "4024.35",
    PLAN_TOTAL_PREMIUM: "61515.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48800001",
    TOTAL_SUMINS_MAX: "48900000",
    PLAN_NET_PREMIUM_AMT: "57358.78",
    PLAN_STAMP_DUTY_AMT: "230.00",
    PLAN_VAT_AMT: "4031.22",
    PLAN_TOTAL_PREMIUM: "61620.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48900001",
    TOTAL_SUMINS_MAX: "49000000",
    PLAN_NET_PREMIUM_AMT: "57456.92",
    PLAN_STAMP_DUTY_AMT: "230.00",
    PLAN_VAT_AMT: "4038.08",
    PLAN_TOTAL_PREMIUM: "61725.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49000001",
    TOTAL_SUMINS_MAX: "49100000",
    PLAN_NET_PREMIUM_AMT: "57554.05",
    PLAN_STAMP_DUTY_AMT: "231.00",
    PLAN_VAT_AMT: "4044.95",
    PLAN_TOTAL_PREMIUM: "61830.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49100001",
    TOTAL_SUMINS_MAX: "49200000",
    PLAN_NET_PREMIUM_AMT: "57652.18",
    PLAN_STAMP_DUTY_AMT: "231.00",
    PLAN_VAT_AMT: "4051.82",
    PLAN_TOTAL_PREMIUM: "61935.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49200001",
    TOTAL_SUMINS_MAX: "49300000",
    PLAN_NET_PREMIUM_AMT: "57749.25",
    PLAN_STAMP_DUTY_AMT: "232.00",
    PLAN_VAT_AMT: "4058.75",
    PLAN_TOTAL_PREMIUM: "62040.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49300001",
    TOTAL_SUMINS_MAX: "49400000",
    PLAN_NET_PREMIUM_AMT: "57847.44",
    PLAN_STAMP_DUTY_AMT: "232.00",
    PLAN_VAT_AMT: "4065.56",
    PLAN_TOTAL_PREMIUM: "62145.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49400001",
    TOTAL_SUMINS_MAX: "49500000",
    PLAN_NET_PREMIUM_AMT: "57945.57",
    PLAN_STAMP_DUTY_AMT: "232.00",
    PLAN_VAT_AMT: "4072.43",
    PLAN_TOTAL_PREMIUM: "62250.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49500001",
    TOTAL_SUMINS_MAX: "49600000",
    PLAN_NET_PREMIUM_AMT: "58042.70",
    PLAN_STAMP_DUTY_AMT: "233.00",
    PLAN_VAT_AMT: "4079.30",
    PLAN_TOTAL_PREMIUM: "62355.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49600001",
    TOTAL_SUMINS_MAX: "49700000",
    PLAN_NET_PREMIUM_AMT: "58140.83",
    PLAN_STAMP_DUTY_AMT: "233.00",
    PLAN_VAT_AMT: "4086.17",
    PLAN_TOTAL_PREMIUM: "62460.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49700001",
    TOTAL_SUMINS_MAX: "49800000",
    PLAN_NET_PREMIUM_AMT: "58238.96",
    PLAN_STAMP_DUTY_AMT: "233.00",
    PLAN_VAT_AMT: "4093.04",
    PLAN_TOTAL_PREMIUM: "62565.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49800001",
    TOTAL_SUMINS_MAX: "49900000",
    PLAN_NET_PREMIUM_AMT: "58336.09",
    PLAN_STAMP_DUTY_AMT: "234.00",
    PLAN_VAT_AMT: "4099.91",
    PLAN_TOTAL_PREMIUM: "62670.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-50000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49900001",
    TOTAL_SUMINS_MAX: "50000000",
    PLAN_NET_PREMIUM_AMT: "58434.22",
    PLAN_STAMP_DUTY_AMT: "234.00",
    PLAN_VAT_AMT: "4106.78",
    PLAN_TOTAL_PREMIUM: "62775.00",
    INDUSTRY_CODE: "SNB",
    INDUSTRY_DESC: "Shop and Beauty"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "0",
    TOTAL_SUMINS_MAX: "500000",
    PLAN_NET_PREMIUM_AMT: "1957.55",
    PLAN_STAMP_DUTY_AMT: "8.00",
    PLAN_VAT_AMT: "137.59",
    PLAN_TOTAL_PREMIUM: "2103.14",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "500001",
    TOTAL_SUMINS_MAX: "600000",
    PLAN_NET_PREMIUM_AMT: "2080.81",
    PLAN_STAMP_DUTY_AMT: "9.00",
    PLAN_VAT_AMT: "146.29",
    PLAN_TOTAL_PREMIUM: "2236.10",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "600001",
    TOTAL_SUMINS_MAX: "700000",
    PLAN_NET_PREMIUM_AMT: "2205.07",
    PLAN_STAMP_DUTY_AMT: "9.00",
    PLAN_VAT_AMT: "154.98",
    PLAN_TOTAL_PREMIUM: "2369.05",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "700001",
    TOTAL_SUMINS_MAX: "800000",
    PLAN_NET_PREMIUM_AMT: "2328.33",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "163.68",
    PLAN_TOTAL_PREMIUM: "2502.01",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "800001",
    TOTAL_SUMINS_MAX: "900000",
    PLAN_NET_PREMIUM_AMT: "2452.59",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "172.38",
    PLAN_TOTAL_PREMIUM: "2634.97",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "900001",
    TOTAL_SUMINS_MAX: "1000000",
    PLAN_NET_PREMIUM_AMT: "2575.85",
    PLAN_STAMP_DUTY_AMT: "11.00",
    PLAN_VAT_AMT: "181.08",
    PLAN_TOTAL_PREMIUM: "2767.93",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1000001",
    TOTAL_SUMINS_MAX: "1100000",
    PLAN_NET_PREMIUM_AMT: "2700.49",
    PLAN_STAMP_DUTY_AMT: "11.00",
    PLAN_VAT_AMT: "189.80",
    PLAN_TOTAL_PREMIUM: "2901.29",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1100001",
    TOTAL_SUMINS_MAX: "1200000",
    PLAN_NET_PREMIUM_AMT: "2824.13",
    PLAN_STAMP_DUTY_AMT: "12.00",
    PLAN_VAT_AMT: "198.53",
    PLAN_TOTAL_PREMIUM: "3034.66",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1200001",
    TOTAL_SUMINS_MAX: "1300000",
    PLAN_NET_PREMIUM_AMT: "2948.77",
    PLAN_STAMP_DUTY_AMT: "12.00",
    PLAN_VAT_AMT: "207.25",
    PLAN_TOTAL_PREMIUM: "3168.02",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1300001",
    TOTAL_SUMINS_MAX: "1400000",
    PLAN_NET_PREMIUM_AMT: "3072.41",
    PLAN_STAMP_DUTY_AMT: "13.00",
    PLAN_VAT_AMT: "215.98",
    PLAN_TOTAL_PREMIUM: "3301.39",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1400001",
    TOTAL_SUMINS_MAX: "1500000",
    PLAN_NET_PREMIUM_AMT: "3197.05",
    PLAN_STAMP_DUTY_AMT: "13.00",
    PLAN_VAT_AMT: "224.70",
    PLAN_TOTAL_PREMIUM: "3434.75",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1500001",
    TOTAL_SUMINS_MAX: "1600000",
    PLAN_NET_PREMIUM_AMT: "3320.31",
    PLAN_STAMP_DUTY_AMT: "14.00",
    PLAN_VAT_AMT: "233.40",
    PLAN_TOTAL_PREMIUM: "3567.71",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1600001",
    TOTAL_SUMINS_MAX: "1700000",
    PLAN_NET_PREMIUM_AMT: "3444.57",
    PLAN_STAMP_DUTY_AMT: "14.00",
    PLAN_VAT_AMT: "242.10",
    PLAN_TOTAL_PREMIUM: "3700.67",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1700001",
    TOTAL_SUMINS_MAX: "1800000",
    PLAN_NET_PREMIUM_AMT: "3567.83",
    PLAN_STAMP_DUTY_AMT: "15.00",
    PLAN_VAT_AMT: "250.80",
    PLAN_TOTAL_PREMIUM: "3833.63",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1800001",
    TOTAL_SUMINS_MAX: "1900000",
    PLAN_NET_PREMIUM_AMT: "3692.09",
    PLAN_STAMP_DUTY_AMT: "15.00",
    PLAN_VAT_AMT: "259.50",
    PLAN_TOTAL_PREMIUM: "3966.59",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1900001",
    TOTAL_SUMINS_MAX: "2000000",
    PLAN_NET_PREMIUM_AMT: "3815.35",
    PLAN_STAMP_DUTY_AMT: "16.00",
    PLAN_VAT_AMT: "268.19",
    PLAN_TOTAL_PREMIUM: "4099.54",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2000001",
    TOTAL_SUMINS_MAX: "2100000",
    PLAN_NET_PREMIUM_AMT: "3939.61",
    PLAN_STAMP_DUTY_AMT: "16.00",
    PLAN_VAT_AMT: "276.89",
    PLAN_TOTAL_PREMIUM: "4232.50",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2100001",
    TOTAL_SUMINS_MAX: "2200000",
    PLAN_NET_PREMIUM_AMT: "4062.87",
    PLAN_STAMP_DUTY_AMT: "17.00",
    PLAN_VAT_AMT: "285.59",
    PLAN_TOTAL_PREMIUM: "4365.46",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2200001",
    TOTAL_SUMINS_MAX: "2300000",
    PLAN_NET_PREMIUM_AMT: "4187.13",
    PLAN_STAMP_DUTY_AMT: "17.00",
    PLAN_VAT_AMT: "294.29",
    PLAN_TOTAL_PREMIUM: "4498.42",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2300001",
    TOTAL_SUMINS_MAX: "2400000",
    PLAN_NET_PREMIUM_AMT: "4310.39",
    PLAN_STAMP_DUTY_AMT: "18.00",
    PLAN_VAT_AMT: "302.99",
    PLAN_TOTAL_PREMIUM: "4631.38",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2400001",
    TOTAL_SUMINS_MAX: "2500000",
    PLAN_NET_PREMIUM_AMT: "4434.65",
    PLAN_STAMP_DUTY_AMT: "18.00",
    PLAN_VAT_AMT: "311.69",
    PLAN_TOTAL_PREMIUM: "4764.34",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2500001",
    TOTAL_SUMINS_MAX: "2600000",
    PLAN_NET_PREMIUM_AMT: "4558.10",
    PLAN_STAMP_DUTY_AMT: "19.00",
    PLAN_VAT_AMT: "320.40",
    PLAN_TOTAL_PREMIUM: "4897.50",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2600001",
    TOTAL_SUMINS_MAX: "2700000",
    PLAN_NET_PREMIUM_AMT: "4682.55",
    PLAN_STAMP_DUTY_AMT: "19.00",
    PLAN_VAT_AMT: "329.11",
    PLAN_TOTAL_PREMIUM: "5030.66",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2700001",
    TOTAL_SUMINS_MAX: "2800000",
    PLAN_NET_PREMIUM_AMT: "4806.00",
    PLAN_STAMP_DUTY_AMT: "20.00",
    PLAN_VAT_AMT: "337.82",
    PLAN_TOTAL_PREMIUM: "5163.82",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2800001",
    TOTAL_SUMINS_MAX: "2900000",
    PLAN_NET_PREMIUM_AMT: "4930.45",
    PLAN_STAMP_DUTY_AMT: "20.00",
    PLAN_VAT_AMT: "346.53",
    PLAN_TOTAL_PREMIUM: "5296.98",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2900001",
    TOTAL_SUMINS_MAX: "3000000",
    PLAN_NET_PREMIUM_AMT: "5053.90",
    PLAN_STAMP_DUTY_AMT: "21.00",
    PLAN_VAT_AMT: "355.24",
    PLAN_TOTAL_PREMIUM: "5430.14",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3000001",
    TOTAL_SUMINS_MAX: "3100000",
    PLAN_NET_PREMIUM_AMT: "5177.97",
    PLAN_STAMP_DUTY_AMT: "21.00",
    PLAN_VAT_AMT: "363.93",
    PLAN_TOTAL_PREMIUM: "5562.90",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3100001",
    TOTAL_SUMINS_MAX: "3200000",
    PLAN_NET_PREMIUM_AMT: "5301.04",
    PLAN_STAMP_DUTY_AMT: "22.00",
    PLAN_VAT_AMT: "372.61",
    PLAN_TOTAL_PREMIUM: "5695.65",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3200001",
    TOTAL_SUMINS_MAX: "3300000",
    PLAN_NET_PREMIUM_AMT: "5425.11",
    PLAN_STAMP_DUTY_AMT: "22.00",
    PLAN_VAT_AMT: "381.30",
    PLAN_TOTAL_PREMIUM: "5828.41",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3300001",
    TOTAL_SUMINS_MAX: "3400000",
    PLAN_NET_PREMIUM_AMT: "5548.18",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "389.98",
    PLAN_TOTAL_PREMIUM: "5961.16",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3400001",
    TOTAL_SUMINS_MAX: "3500000",
    PLAN_NET_PREMIUM_AMT: "5672.25",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "398.67",
    PLAN_TOTAL_PREMIUM: "6093.92",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3500001",
    TOTAL_SUMINS_MAX: "3600000",
    PLAN_NET_PREMIUM_AMT: "5795.89",
    PLAN_STAMP_DUTY_AMT: "24.00",
    PLAN_VAT_AMT: "407.39",
    PLAN_TOTAL_PREMIUM: "6227.28",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3600001",
    TOTAL_SUMINS_MAX: "3700000",
    PLAN_NET_PREMIUM_AMT: "5920.53",
    PLAN_STAMP_DUTY_AMT: "24.00",
    PLAN_VAT_AMT: "416.12",
    PLAN_TOTAL_PREMIUM: "6360.65",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3700001",
    TOTAL_SUMINS_MAX: "3800000",
    PLAN_NET_PREMIUM_AMT: "6044.17",
    PLAN_STAMP_DUTY_AMT: "25.00",
    PLAN_VAT_AMT: "424.84",
    PLAN_TOTAL_PREMIUM: "6494.01",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3800001",
    TOTAL_SUMINS_MAX: "3900000",
    PLAN_NET_PREMIUM_AMT: "6168.81",
    PLAN_STAMP_DUTY_AMT: "25.00",
    PLAN_VAT_AMT: "433.57",
    PLAN_TOTAL_PREMIUM: "6627.38",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3900001",
    TOTAL_SUMINS_MAX: "4000000",
    PLAN_NET_PREMIUM_AMT: "6292.45",
    PLAN_STAMP_DUTY_AMT: "26.00",
    PLAN_VAT_AMT: "442.29",
    PLAN_TOTAL_PREMIUM: "6760.74",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4000001",
    TOTAL_SUMINS_MAX: "4100000",
    PLAN_NET_PREMIUM_AMT: "6416.90",
    PLAN_STAMP_DUTY_AMT: "26.00",
    PLAN_VAT_AMT: "451.00",
    PLAN_TOTAL_PREMIUM: "6893.90",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4100001",
    TOTAL_SUMINS_MAX: "4200000",
    PLAN_NET_PREMIUM_AMT: "6540.35",
    PLAN_STAMP_DUTY_AMT: "27.00",
    PLAN_VAT_AMT: "459.71",
    PLAN_TOTAL_PREMIUM: "7027.06",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4200001",
    TOTAL_SUMINS_MAX: "4300000",
    PLAN_NET_PREMIUM_AMT: "6664.80",
    PLAN_STAMP_DUTY_AMT: "27.00",
    PLAN_VAT_AMT: "468.43",
    PLAN_TOTAL_PREMIUM: "7160.23",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4300001",
    TOTAL_SUMINS_MAX: "4400000",
    PLAN_NET_PREMIUM_AMT: "6788.25",
    PLAN_STAMP_DUTY_AMT: "28.00",
    PLAN_VAT_AMT: "477.14",
    PLAN_TOTAL_PREMIUM: "7293.39",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4400001",
    TOTAL_SUMINS_MAX: "4500000",
    PLAN_NET_PREMIUM_AMT: "6912.70",
    PLAN_STAMP_DUTY_AMT: "28.00",
    PLAN_VAT_AMT: "485.85",
    PLAN_TOTAL_PREMIUM: "7426.55",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4500001",
    TOTAL_SUMINS_MAX: "4600000",
    PLAN_NET_PREMIUM_AMT: "7035.77",
    PLAN_STAMP_DUTY_AMT: "29.00",
    PLAN_VAT_AMT: "494.53",
    PLAN_TOTAL_PREMIUM: "7559.30",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4600001",
    TOTAL_SUMINS_MAX: "4700000",
    PLAN_NET_PREMIUM_AMT: "7159.84",
    PLAN_STAMP_DUTY_AMT: "29.00",
    PLAN_VAT_AMT: "503.22",
    PLAN_TOTAL_PREMIUM: "7692.06",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4700001",
    TOTAL_SUMINS_MAX: "4800000",
    PLAN_NET_PREMIUM_AMT: "7282.91",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "511.90",
    PLAN_TOTAL_PREMIUM: "7824.81",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4800001",
    TOTAL_SUMINS_MAX: "4900000",
    PLAN_NET_PREMIUM_AMT: "7406.98",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "520.59",
    PLAN_TOTAL_PREMIUM: "7957.57",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4900001",
    TOTAL_SUMINS_MAX: "5000000",
    PLAN_NET_PREMIUM_AMT: "7530.05",
    PLAN_STAMP_DUTY_AMT: "31.00",
    PLAN_VAT_AMT: "529.27",
    PLAN_TOTAL_PREMIUM: "8090.32",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5000001",
    TOTAL_SUMINS_MAX: "5100000",
    PLAN_NET_PREMIUM_AMT: "7654.50",
    PLAN_STAMP_DUTY_AMT: "31.00",
    PLAN_VAT_AMT: "537.99",
    PLAN_TOTAL_PREMIUM: "8223.49",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5100001",
    TOTAL_SUMINS_MAX: "5200000",
    PLAN_NET_PREMIUM_AMT: "7777.95",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "546.70",
    PLAN_TOTAL_PREMIUM: "8356.65",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5200001",
    TOTAL_SUMINS_MAX: "5300000",
    PLAN_NET_PREMIUM_AMT: "7902.40",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "555.41",
    PLAN_TOTAL_PREMIUM: "8489.81",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5300001",
    TOTAL_SUMINS_MAX: "5400000",
    PLAN_NET_PREMIUM_AMT: "8025.85",
    PLAN_STAMP_DUTY_AMT: "33.00",
    PLAN_VAT_AMT: "564.12",
    PLAN_TOTAL_PREMIUM: "8622.97",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5400001",
    TOTAL_SUMINS_MAX: "5500000",
    PLAN_NET_PREMIUM_AMT: "8150.30",
    PLAN_STAMP_DUTY_AMT: "33.00",
    PLAN_VAT_AMT: "572.83",
    PLAN_TOTAL_PREMIUM: "8756.13",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5500001",
    TOTAL_SUMINS_MAX: "5600000",
    PLAN_NET_PREMIUM_AMT: "8273.37",
    PLAN_STAMP_DUTY_AMT: "34.00",
    PLAN_VAT_AMT: "581.52",
    PLAN_TOTAL_PREMIUM: "8888.89",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5600001",
    TOTAL_SUMINS_MAX: "5700000",
    PLAN_NET_PREMIUM_AMT: "8397.44",
    PLAN_STAMP_DUTY_AMT: "34.00",
    PLAN_VAT_AMT: "590.20",
    PLAN_TOTAL_PREMIUM: "9021.64",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5700001",
    TOTAL_SUMINS_MAX: "5800000",
    PLAN_NET_PREMIUM_AMT: "8520.51",
    PLAN_STAMP_DUTY_AMT: "35.00",
    PLAN_VAT_AMT: "598.89",
    PLAN_TOTAL_PREMIUM: "9154.40",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5800001",
    TOTAL_SUMINS_MAX: "5900000",
    PLAN_NET_PREMIUM_AMT: "8644.58",
    PLAN_STAMP_DUTY_AMT: "35.00",
    PLAN_VAT_AMT: "607.57",
    PLAN_TOTAL_PREMIUM: "9287.15",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5900001",
    TOTAL_SUMINS_MAX: "6000000",
    PLAN_NET_PREMIUM_AMT: "8767.65",
    PLAN_STAMP_DUTY_AMT: "36.00",
    PLAN_VAT_AMT: "616.26",
    PLAN_TOTAL_PREMIUM: "9419.91",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6000001",
    TOTAL_SUMINS_MAX: "6100000",
    PLAN_NET_PREMIUM_AMT: "8892.29",
    PLAN_STAMP_DUTY_AMT: "36.00",
    PLAN_VAT_AMT: "624.98",
    PLAN_TOTAL_PREMIUM: "9553.27",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6100001",
    TOTAL_SUMINS_MAX: "6200000",
    PLAN_NET_PREMIUM_AMT: "9015.93",
    PLAN_STAMP_DUTY_AMT: "37.00",
    PLAN_VAT_AMT: "633.71",
    PLAN_TOTAL_PREMIUM: "9686.64",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6200001",
    TOTAL_SUMINS_MAX: "6300000",
    PLAN_NET_PREMIUM_AMT: "9140.57",
    PLAN_STAMP_DUTY_AMT: "37.00",
    PLAN_VAT_AMT: "642.43",
    PLAN_TOTAL_PREMIUM: "9820.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6300001",
    TOTAL_SUMINS_MAX: "6400000",
    PLAN_NET_PREMIUM_AMT: "9264.21",
    PLAN_STAMP_DUTY_AMT: "38.00",
    PLAN_VAT_AMT: "651.15",
    PLAN_TOTAL_PREMIUM: "9953.36",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6400001",
    TOTAL_SUMINS_MAX: "6500000",
    PLAN_NET_PREMIUM_AMT: "9388.85",
    PLAN_STAMP_DUTY_AMT: "38.00",
    PLAN_VAT_AMT: "659.88",
    PLAN_TOTAL_PREMIUM: "10086.73",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6500001",
    TOTAL_SUMINS_MAX: "6600000",
    PLAN_NET_PREMIUM_AMT: "9512.30",
    PLAN_STAMP_DUTY_AMT: "39.00",
    PLAN_VAT_AMT: "668.59",
    PLAN_TOTAL_PREMIUM: "10219.89",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6600001",
    TOTAL_SUMINS_MAX: "6700000",
    PLAN_NET_PREMIUM_AMT: "9636.75",
    PLAN_STAMP_DUTY_AMT: "39.00",
    PLAN_VAT_AMT: "677.30",
    PLAN_TOTAL_PREMIUM: "10353.05",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6700001",
    TOTAL_SUMINS_MAX: "6800000",
    PLAN_NET_PREMIUM_AMT: "9760.20",
    PLAN_STAMP_DUTY_AMT: "40.00",
    PLAN_VAT_AMT: "686.01",
    PLAN_TOTAL_PREMIUM: "10486.21",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6800001",
    TOTAL_SUMINS_MAX: "6900000",
    PLAN_NET_PREMIUM_AMT: "9884.65",
    PLAN_STAMP_DUTY_AMT: "40.00",
    PLAN_VAT_AMT: "694.73",
    PLAN_TOTAL_PREMIUM: "10619.38",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6900001",
    TOTAL_SUMINS_MAX: "7000000",
    PLAN_NET_PREMIUM_AMT: "10008.10",
    PLAN_STAMP_DUTY_AMT: "41.00",
    PLAN_VAT_AMT: "703.44",
    PLAN_TOTAL_PREMIUM: "10752.54",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7000001",
    TOTAL_SUMINS_MAX: "7100000",
    PLAN_NET_PREMIUM_AMT: "10132.17",
    PLAN_STAMP_DUTY_AMT: "41.00",
    PLAN_VAT_AMT: "712.12",
    PLAN_TOTAL_PREMIUM: "10885.29",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7100001",
    TOTAL_SUMINS_MAX: "7200000",
    PLAN_NET_PREMIUM_AMT: "10255.24",
    PLAN_STAMP_DUTY_AMT: "42.00",
    PLAN_VAT_AMT: "720.81",
    PLAN_TOTAL_PREMIUM: "11018.05",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7200001",
    TOTAL_SUMINS_MAX: "7300000",
    PLAN_NET_PREMIUM_AMT: "10379.31",
    PLAN_STAMP_DUTY_AMT: "42.00",
    PLAN_VAT_AMT: "729.49",
    PLAN_TOTAL_PREMIUM: "11150.80",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7300001",
    TOTAL_SUMINS_MAX: "7400000",
    PLAN_NET_PREMIUM_AMT: "10502.38",
    PLAN_STAMP_DUTY_AMT: "43.00",
    PLAN_VAT_AMT: "738.18",
    PLAN_TOTAL_PREMIUM: "11283.56",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7400001",
    TOTAL_SUMINS_MAX: "7500000",
    PLAN_NET_PREMIUM_AMT: "10626.45",
    PLAN_STAMP_DUTY_AMT: "43.00",
    PLAN_VAT_AMT: "746.86",
    PLAN_TOTAL_PREMIUM: "11416.31",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7500001",
    TOTAL_SUMINS_MAX: "7600000",
    PLAN_NET_PREMIUM_AMT: "10749.90",
    PLAN_STAMP_DUTY_AMT: "44.00",
    PLAN_VAT_AMT: "755.57",
    PLAN_TOTAL_PREMIUM: "11549.47",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7600001",
    TOTAL_SUMINS_MAX: "7700000",
    PLAN_NET_PREMIUM_AMT: "10874.35",
    PLAN_STAMP_DUTY_AMT: "44.00",
    PLAN_VAT_AMT: "764.28",
    PLAN_TOTAL_PREMIUM: "11682.63",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7700001",
    TOTAL_SUMINS_MAX: "7800000",
    PLAN_NET_PREMIUM_AMT: "10998.80",
    PLAN_STAMP_DUTY_AMT: "44.00",
    PLAN_VAT_AMT: "773.00",
    PLAN_TOTAL_PREMIUM: "11815.80",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7800001",
    TOTAL_SUMINS_MAX: "7900000",
    PLAN_NET_PREMIUM_AMT: "11122.25",
    PLAN_STAMP_DUTY_AMT: "45.00",
    PLAN_VAT_AMT: "781.71",
    PLAN_TOTAL_PREMIUM: "11948.96",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7900001",
    TOTAL_SUMINS_MAX: "8000000",
    PLAN_NET_PREMIUM_AMT: "11246.70",
    PLAN_STAMP_DUTY_AMT: "45.00",
    PLAN_VAT_AMT: "790.42",
    PLAN_TOTAL_PREMIUM: "12082.12",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8000001",
    TOTAL_SUMINS_MAX: "8100000",
    PLAN_NET_PREMIUM_AMT: "11369.96",
    PLAN_STAMP_DUTY_AMT: "46.00",
    PLAN_VAT_AMT: "799.12",
    PLAN_TOTAL_PREMIUM: "12215.08",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8100001",
    TOTAL_SUMINS_MAX: "8200000",
    PLAN_NET_PREMIUM_AMT: "11494.22",
    PLAN_STAMP_DUTY_AMT: "46.00",
    PLAN_VAT_AMT: "807.82",
    PLAN_TOTAL_PREMIUM: "12348.04",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8200001",
    TOTAL_SUMINS_MAX: "8300000",
    PLAN_NET_PREMIUM_AMT: "11617.48",
    PLAN_STAMP_DUTY_AMT: "47.00",
    PLAN_VAT_AMT: "816.51",
    PLAN_TOTAL_PREMIUM: "12480.99",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8300001",
    TOTAL_SUMINS_MAX: "8400000",
    PLAN_NET_PREMIUM_AMT: "11741.74",
    PLAN_STAMP_DUTY_AMT: "47.00",
    PLAN_VAT_AMT: "825.21",
    PLAN_TOTAL_PREMIUM: "12613.95",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8400001",
    TOTAL_SUMINS_MAX: "8500000",
    PLAN_NET_PREMIUM_AMT: "11865.00",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "833.91",
    PLAN_TOTAL_PREMIUM: "12746.91",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8500001",
    TOTAL_SUMINS_MAX: "8600000",
    PLAN_NET_PREMIUM_AMT: "11989.45",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "842.62",
    PLAN_TOTAL_PREMIUM: "12880.07",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8600001",
    TOTAL_SUMINS_MAX: "8700000",
    PLAN_NET_PREMIUM_AMT: "12112.90",
    PLAN_STAMP_DUTY_AMT: "49.00",
    PLAN_VAT_AMT: "851.33",
    PLAN_TOTAL_PREMIUM: "13013.23",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8700001",
    TOTAL_SUMINS_MAX: "8800000",
    PLAN_NET_PREMIUM_AMT: "12237.35",
    PLAN_STAMP_DUTY_AMT: "49.00",
    PLAN_VAT_AMT: "860.04",
    PLAN_TOTAL_PREMIUM: "13146.39",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8800001",
    TOTAL_SUMINS_MAX: "8900000",
    PLAN_NET_PREMIUM_AMT: "12360.80",
    PLAN_STAMP_DUTY_AMT: "50.00",
    PLAN_VAT_AMT: "868.76",
    PLAN_TOTAL_PREMIUM: "13279.56",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8900001",
    TOTAL_SUMINS_MAX: "9000000",
    PLAN_NET_PREMIUM_AMT: "12485.25",
    PLAN_STAMP_DUTY_AMT: "50.00",
    PLAN_VAT_AMT: "877.47",
    PLAN_TOTAL_PREMIUM: "13412.72",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9000001",
    TOTAL_SUMINS_MAX: "9100000",
    PLAN_NET_PREMIUM_AMT: "12608.70",
    PLAN_STAMP_DUTY_AMT: "51.00",
    PLAN_VAT_AMT: "886.18",
    PLAN_TOTAL_PREMIUM: "13545.88",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9100001",
    TOTAL_SUMINS_MAX: "9200000",
    PLAN_NET_PREMIUM_AMT: "12733.15",
    PLAN_STAMP_DUTY_AMT: "51.00",
    PLAN_VAT_AMT: "894.89",
    PLAN_TOTAL_PREMIUM: "13679.04",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9200001",
    TOTAL_SUMINS_MAX: "9300000",
    PLAN_NET_PREMIUM_AMT: "12856.60",
    PLAN_STAMP_DUTY_AMT: "52.00",
    PLAN_VAT_AMT: "903.60",
    PLAN_TOTAL_PREMIUM: "13812.20",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9300001",
    TOTAL_SUMINS_MAX: "9400000",
    PLAN_NET_PREMIUM_AMT: "12981.05",
    PLAN_STAMP_DUTY_AMT: "52.00",
    PLAN_VAT_AMT: "912.31",
    PLAN_TOTAL_PREMIUM: "13945.36",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9400001",
    TOTAL_SUMINS_MAX: "9500000",
    PLAN_NET_PREMIUM_AMT: "13104.50",
    PLAN_STAMP_DUTY_AMT: "53.00",
    PLAN_VAT_AMT: "921.03",
    PLAN_TOTAL_PREMIUM: "14078.53",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9500001",
    TOTAL_SUMINS_MAX: "9600000",
    PLAN_NET_PREMIUM_AMT: "13228.76",
    PLAN_STAMP_DUTY_AMT: "53.00",
    PLAN_VAT_AMT: "929.72",
    PLAN_TOTAL_PREMIUM: "14211.48",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9600001",
    TOTAL_SUMINS_MAX: "9700000",
    PLAN_NET_PREMIUM_AMT: "13352.02",
    PLAN_STAMP_DUTY_AMT: "54.00",
    PLAN_VAT_AMT: "938.42",
    PLAN_TOTAL_PREMIUM: "14344.44",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9700001",
    TOTAL_SUMINS_MAX: "9800000",
    PLAN_NET_PREMIUM_AMT: "13476.28",
    PLAN_STAMP_DUTY_AMT: "54.00",
    PLAN_VAT_AMT: "947.12",
    PLAN_TOTAL_PREMIUM: "14477.40",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9800001",
    TOTAL_SUMINS_MAX: "9900000",
    PLAN_NET_PREMIUM_AMT: "13599.54",
    PLAN_STAMP_DUTY_AMT: "55.00",
    PLAN_VAT_AMT: "955.82",
    PLAN_TOTAL_PREMIUM: "14610.36",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9900001",
    TOTAL_SUMINS_MAX: "10000000",
    PLAN_NET_PREMIUM_AMT: "13723.80",
    PLAN_STAMP_DUTY_AMT: "55.00",
    PLAN_VAT_AMT: "964.52",
    PLAN_TOTAL_PREMIUM: "14743.32",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10000001",
    TOTAL_SUMINS_MAX: "10100000",
    PLAN_NET_PREMIUM_AMT: "14595.21",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1025.79",
    PLAN_TOTAL_PREMIUM: "15680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10100001",
    TOTAL_SUMINS_MAX: "10200000",
    PLAN_NET_PREMIUM_AMT: "14669.97",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1031.03",
    PLAN_TOTAL_PREMIUM: "15760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10200001",
    TOTAL_SUMINS_MAX: "10300000",
    PLAN_NET_PREMIUM_AMT: "14744.74",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1036.26",
    PLAN_TOTAL_PREMIUM: "15840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10300001",
    TOTAL_SUMINS_MAX: "10400000",
    PLAN_NET_PREMIUM_AMT: "14818.50",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1041.50",
    PLAN_TOTAL_PREMIUM: "15920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10400001",
    TOTAL_SUMINS_MAX: "10500000",
    PLAN_NET_PREMIUM_AMT: "14893.27",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1046.73",
    PLAN_TOTAL_PREMIUM: "16000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10500001",
    TOTAL_SUMINS_MAX: "10600000",
    PLAN_NET_PREMIUM_AMT: "14968.04",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1051.96",
    PLAN_TOTAL_PREMIUM: "16080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10600001",
    TOTAL_SUMINS_MAX: "10700000",
    PLAN_NET_PREMIUM_AMT: "15041.80",
    PLAN_STAMP_DUTY_AMT: "61.00",
    PLAN_VAT_AMT: "1057.20",
    PLAN_TOTAL_PREMIUM: "16160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10700001",
    TOTAL_SUMINS_MAX: "10800000",
    PLAN_NET_PREMIUM_AMT: "15116.57",
    PLAN_STAMP_DUTY_AMT: "61.00",
    PLAN_VAT_AMT: "1062.43",
    PLAN_TOTAL_PREMIUM: "16240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10800001",
    TOTAL_SUMINS_MAX: "10900000",
    PLAN_NET_PREMIUM_AMT: "15191.34",
    PLAN_STAMP_DUTY_AMT: "61.00",
    PLAN_VAT_AMT: "1067.66",
    PLAN_TOTAL_PREMIUM: "16320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10900001",
    TOTAL_SUMINS_MAX: "11000000",
    PLAN_NET_PREMIUM_AMT: "15265.10",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1072.90",
    PLAN_TOTAL_PREMIUM: "16400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11000001",
    TOTAL_SUMINS_MAX: "11100000",
    PLAN_NET_PREMIUM_AMT: "15339.87",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1078.13",
    PLAN_TOTAL_PREMIUM: "16480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11100001",
    TOTAL_SUMINS_MAX: "11200000",
    PLAN_NET_PREMIUM_AMT: "15414.64",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1083.36",
    PLAN_TOTAL_PREMIUM: "16560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11200001",
    TOTAL_SUMINS_MAX: "11300000",
    PLAN_NET_PREMIUM_AMT: "15489.40",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1088.60",
    PLAN_TOTAL_PREMIUM: "16640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11300001",
    TOTAL_SUMINS_MAX: "11400000",
    PLAN_NET_PREMIUM_AMT: "15563.17",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1093.83",
    PLAN_TOTAL_PREMIUM: "16720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11400001",
    TOTAL_SUMINS_MAX: "11500000",
    PLAN_NET_PREMIUM_AMT: "15637.93",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1099.07",
    PLAN_TOTAL_PREMIUM: "16800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11500001",
    TOTAL_SUMINS_MAX: "11600000",
    PLAN_NET_PREMIUM_AMT: "15712.70",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1104.30",
    PLAN_TOTAL_PREMIUM: "16880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11600001",
    TOTAL_SUMINS_MAX: "11700000",
    PLAN_NET_PREMIUM_AMT: "15786.47",
    PLAN_STAMP_DUTY_AMT: "64.00",
    PLAN_VAT_AMT: "1109.53",
    PLAN_TOTAL_PREMIUM: "16960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11700001",
    TOTAL_SUMINS_MAX: "11800000",
    PLAN_NET_PREMIUM_AMT: "15861.23",
    PLAN_STAMP_DUTY_AMT: "64.00",
    PLAN_VAT_AMT: "1114.77",
    PLAN_TOTAL_PREMIUM: "17040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11800001",
    TOTAL_SUMINS_MAX: "11900000",
    PLAN_NET_PREMIUM_AMT: "15936.00",
    PLAN_STAMP_DUTY_AMT: "64.00",
    PLAN_VAT_AMT: "1120.00",
    PLAN_TOTAL_PREMIUM: "17120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11900001",
    TOTAL_SUMINS_MAX: "12000000",
    PLAN_NET_PREMIUM_AMT: "16009.77",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1125.23",
    PLAN_TOTAL_PREMIUM: "17200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12000001",
    TOTAL_SUMINS_MAX: "12100000",
    PLAN_NET_PREMIUM_AMT: "16084.53",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1130.47",
    PLAN_TOTAL_PREMIUM: "17280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12100001",
    TOTAL_SUMINS_MAX: "12200000",
    PLAN_NET_PREMIUM_AMT: "16159.30",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1135.70",
    PLAN_TOTAL_PREMIUM: "17360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12200001",
    TOTAL_SUMINS_MAX: "12300000",
    PLAN_NET_PREMIUM_AMT: "16234.07",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1140.93",
    PLAN_TOTAL_PREMIUM: "17440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12300001",
    TOTAL_SUMINS_MAX: "12400000",
    PLAN_NET_PREMIUM_AMT: "16307.83",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1146.17",
    PLAN_TOTAL_PREMIUM: "17520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12400001",
    TOTAL_SUMINS_MAX: "12500000",
    PLAN_NET_PREMIUM_AMT: "16382.60",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1151.40",
    PLAN_TOTAL_PREMIUM: "17600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12500001",
    TOTAL_SUMINS_MAX: "12600000",
    PLAN_NET_PREMIUM_AMT: "16457.36",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1156.64",
    PLAN_TOTAL_PREMIUM: "17680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12600001",
    TOTAL_SUMINS_MAX: "12700000",
    PLAN_NET_PREMIUM_AMT: "16531.13",
    PLAN_STAMP_DUTY_AMT: "67.00",
    PLAN_VAT_AMT: "1161.87",
    PLAN_TOTAL_PREMIUM: "17760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12700001",
    TOTAL_SUMINS_MAX: "12800000",
    PLAN_NET_PREMIUM_AMT: "16605.90",
    PLAN_STAMP_DUTY_AMT: "67.00",
    PLAN_VAT_AMT: "1167.10",
    PLAN_TOTAL_PREMIUM: "17840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12800001",
    TOTAL_SUMINS_MAX: "12900000",
    PLAN_NET_PREMIUM_AMT: "16680.66",
    PLAN_STAMP_DUTY_AMT: "67.00",
    PLAN_VAT_AMT: "1172.34",
    PLAN_TOTAL_PREMIUM: "17920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12900001",
    TOTAL_SUMINS_MAX: "13000000",
    PLAN_NET_PREMIUM_AMT: "16754.43",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1177.57",
    PLAN_TOTAL_PREMIUM: "18000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13000001",
    TOTAL_SUMINS_MAX: "13100000",
    PLAN_NET_PREMIUM_AMT: "16829.20",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1182.80",
    PLAN_TOTAL_PREMIUM: "18080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13100001",
    TOTAL_SUMINS_MAX: "13200000",
    PLAN_NET_PREMIUM_AMT: "16903.96",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1188.04",
    PLAN_TOTAL_PREMIUM: "18160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13200001",
    TOTAL_SUMINS_MAX: "13300000",
    PLAN_NET_PREMIUM_AMT: "16978.73",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1193.27",
    PLAN_TOTAL_PREMIUM: "18240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13300001",
    TOTAL_SUMINS_MAX: "13400000",
    PLAN_NET_PREMIUM_AMT: "17052.49",
    PLAN_STAMP_DUTY_AMT: "69.00",
    PLAN_VAT_AMT: "1198.51",
    PLAN_TOTAL_PREMIUM: "18320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13400001",
    TOTAL_SUMINS_MAX: "13500000",
    PLAN_NET_PREMIUM_AMT: "17127.26",
    PLAN_STAMP_DUTY_AMT: "69.00",
    PLAN_VAT_AMT: "1203.74",
    PLAN_TOTAL_PREMIUM: "18400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13500001",
    TOTAL_SUMINS_MAX: "13600000",
    PLAN_NET_PREMIUM_AMT: "17202.03",
    PLAN_STAMP_DUTY_AMT: "69.00",
    PLAN_VAT_AMT: "1208.97",
    PLAN_TOTAL_PREMIUM: "18480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13600001",
    TOTAL_SUMINS_MAX: "13700000",
    PLAN_NET_PREMIUM_AMT: "17275.79",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1214.21",
    PLAN_TOTAL_PREMIUM: "18560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13700001",
    TOTAL_SUMINS_MAX: "13800000",
    PLAN_NET_PREMIUM_AMT: "17350.56",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1219.44",
    PLAN_TOTAL_PREMIUM: "18640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13800001",
    TOTAL_SUMINS_MAX: "13900000",
    PLAN_NET_PREMIUM_AMT: "17425.33",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1224.67",
    PLAN_TOTAL_PREMIUM: "18720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13900001",
    TOTAL_SUMINS_MAX: "14000000",
    PLAN_NET_PREMIUM_AMT: "17499.09",
    PLAN_STAMP_DUTY_AMT: "71.00",
    PLAN_VAT_AMT: "1229.91",
    PLAN_TOTAL_PREMIUM: "18800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14000001",
    TOTAL_SUMINS_MAX: "14100000",
    PLAN_NET_PREMIUM_AMT: "17573.86",
    PLAN_STAMP_DUTY_AMT: "71.00",
    PLAN_VAT_AMT: "1235.14",
    PLAN_TOTAL_PREMIUM: "18880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14100001",
    TOTAL_SUMINS_MAX: "14200000",
    PLAN_NET_PREMIUM_AMT: "17648.63",
    PLAN_STAMP_DUTY_AMT: "71.00",
    PLAN_VAT_AMT: "1240.37",
    PLAN_TOTAL_PREMIUM: "18960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14200001",
    TOTAL_SUMINS_MAX: "14300000",
    PLAN_NET_PREMIUM_AMT: "17723.39",
    PLAN_STAMP_DUTY_AMT: "71.00",
    PLAN_VAT_AMT: "1245.61",
    PLAN_TOTAL_PREMIUM: "19040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14300001",
    TOTAL_SUMINS_MAX: "14400000",
    PLAN_NET_PREMIUM_AMT: "17797.16",
    PLAN_STAMP_DUTY_AMT: "72.00",
    PLAN_VAT_AMT: "1250.84",
    PLAN_TOTAL_PREMIUM: "19120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14400001",
    TOTAL_SUMINS_MAX: "14500000",
    PLAN_NET_PREMIUM_AMT: "17871.92",
    PLAN_STAMP_DUTY_AMT: "72.00",
    PLAN_VAT_AMT: "1256.08",
    PLAN_TOTAL_PREMIUM: "19200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14500001",
    TOTAL_SUMINS_MAX: "14600000",
    PLAN_NET_PREMIUM_AMT: "17946.69",
    PLAN_STAMP_DUTY_AMT: "72.00",
    PLAN_VAT_AMT: "1261.31",
    PLAN_TOTAL_PREMIUM: "19280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14600001",
    TOTAL_SUMINS_MAX: "14700000",
    PLAN_NET_PREMIUM_AMT: "18020.46",
    PLAN_STAMP_DUTY_AMT: "73.00",
    PLAN_VAT_AMT: "1266.54",
    PLAN_TOTAL_PREMIUM: "19360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14700001",
    TOTAL_SUMINS_MAX: "14800000",
    PLAN_NET_PREMIUM_AMT: "18095.22",
    PLAN_STAMP_DUTY_AMT: "73.00",
    PLAN_VAT_AMT: "1271.78",
    PLAN_TOTAL_PREMIUM: "19440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14800001",
    TOTAL_SUMINS_MAX: "14900000",
    PLAN_NET_PREMIUM_AMT: "18169.99",
    PLAN_STAMP_DUTY_AMT: "73.00",
    PLAN_VAT_AMT: "1277.01",
    PLAN_TOTAL_PREMIUM: "19520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14900001",
    TOTAL_SUMINS_MAX: "15000000",
    PLAN_NET_PREMIUM_AMT: "18244.76",
    PLAN_STAMP_DUTY_AMT: "73.00",
    PLAN_VAT_AMT: "1282.24",
    PLAN_TOTAL_PREMIUM: "19600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15000001",
    TOTAL_SUMINS_MAX: "15100000",
    PLAN_NET_PREMIUM_AMT: "18318.52",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1287.48",
    PLAN_TOTAL_PREMIUM: "19680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15100001",
    TOTAL_SUMINS_MAX: "15200000",
    PLAN_NET_PREMIUM_AMT: "18393.29",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1292.71",
    PLAN_TOTAL_PREMIUM: "19760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15200001",
    TOTAL_SUMINS_MAX: "15300000",
    PLAN_NET_PREMIUM_AMT: "18468.06",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1297.94",
    PLAN_TOTAL_PREMIUM: "19840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15300001",
    TOTAL_SUMINS_MAX: "15400000",
    PLAN_NET_PREMIUM_AMT: "18541.82",
    PLAN_STAMP_DUTY_AMT: "75.00",
    PLAN_VAT_AMT: "1303.18",
    PLAN_TOTAL_PREMIUM: "19920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15400001",
    TOTAL_SUMINS_MAX: "15500000",
    PLAN_NET_PREMIUM_AMT: "18616.59",
    PLAN_STAMP_DUTY_AMT: "75.00",
    PLAN_VAT_AMT: "1308.41",
    PLAN_TOTAL_PREMIUM: "20000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15500001",
    TOTAL_SUMINS_MAX: "15600000",
    PLAN_NET_PREMIUM_AMT: "18691.35",
    PLAN_STAMP_DUTY_AMT: "75.00",
    PLAN_VAT_AMT: "1313.65",
    PLAN_TOTAL_PREMIUM: "20080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15600001",
    TOTAL_SUMINS_MAX: "15700000",
    PLAN_NET_PREMIUM_AMT: "18765.12",
    PLAN_STAMP_DUTY_AMT: "76.00",
    PLAN_VAT_AMT: "1318.88",
    PLAN_TOTAL_PREMIUM: "20160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15700001",
    TOTAL_SUMINS_MAX: "15800000",
    PLAN_NET_PREMIUM_AMT: "18839.89",
    PLAN_STAMP_DUTY_AMT: "76.00",
    PLAN_VAT_AMT: "1324.11",
    PLAN_TOTAL_PREMIUM: "20240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15800001",
    TOTAL_SUMINS_MAX: "15900000",
    PLAN_NET_PREMIUM_AMT: "18914.65",
    PLAN_STAMP_DUTY_AMT: "76.00",
    PLAN_VAT_AMT: "1329.35",
    PLAN_TOTAL_PREMIUM: "20320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15900001",
    TOTAL_SUMINS_MAX: "16000000",
    PLAN_NET_PREMIUM_AMT: "18989.42",
    PLAN_STAMP_DUTY_AMT: "76.00",
    PLAN_VAT_AMT: "1334.58",
    PLAN_TOTAL_PREMIUM: "20400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16000001",
    TOTAL_SUMINS_MAX: "16100000",
    PLAN_NET_PREMIUM_AMT: "19063.19",
    PLAN_STAMP_DUTY_AMT: "77.00",
    PLAN_VAT_AMT: "1339.81",
    PLAN_TOTAL_PREMIUM: "20480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16100001",
    TOTAL_SUMINS_MAX: "16200000",
    PLAN_NET_PREMIUM_AMT: "19137.95",
    PLAN_STAMP_DUTY_AMT: "77.00",
    PLAN_VAT_AMT: "1345.05",
    PLAN_TOTAL_PREMIUM: "20560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16200001",
    TOTAL_SUMINS_MAX: "16300000",
    PLAN_NET_PREMIUM_AMT: "19212.72",
    PLAN_STAMP_DUTY_AMT: "77.00",
    PLAN_VAT_AMT: "1350.28",
    PLAN_TOTAL_PREMIUM: "20640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16300001",
    TOTAL_SUMINS_MAX: "16400000",
    PLAN_NET_PREMIUM_AMT: "19286.49",
    PLAN_STAMP_DUTY_AMT: "78.00",
    PLAN_VAT_AMT: "1355.51",
    PLAN_TOTAL_PREMIUM: "20720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16400001",
    TOTAL_SUMINS_MAX: "16500000",
    PLAN_NET_PREMIUM_AMT: "19361.25",
    PLAN_STAMP_DUTY_AMT: "78.00",
    PLAN_VAT_AMT: "1360.75",
    PLAN_TOTAL_PREMIUM: "20800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16500001",
    TOTAL_SUMINS_MAX: "16600000",
    PLAN_NET_PREMIUM_AMT: "19436.02",
    PLAN_STAMP_DUTY_AMT: "78.00",
    PLAN_VAT_AMT: "1365.98",
    PLAN_TOTAL_PREMIUM: "20880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16600001",
    TOTAL_SUMINS_MAX: "16700000",
    PLAN_NET_PREMIUM_AMT: "19509.78",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1371.22",
    PLAN_TOTAL_PREMIUM: "20960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16700001",
    TOTAL_SUMINS_MAX: "16800000",
    PLAN_NET_PREMIUM_AMT: "19584.55",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1376.45",
    PLAN_TOTAL_PREMIUM: "21040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16800001",
    TOTAL_SUMINS_MAX: "16900000",
    PLAN_NET_PREMIUM_AMT: "19659.32",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1381.68",
    PLAN_TOTAL_PREMIUM: "21120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16900001",
    TOTAL_SUMINS_MAX: "17000000",
    PLAN_NET_PREMIUM_AMT: "19734.08",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1386.92",
    PLAN_TOTAL_PREMIUM: "21200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17000001",
    TOTAL_SUMINS_MAX: "17100000",
    PLAN_NET_PREMIUM_AMT: "19807.85",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1392.15",
    PLAN_TOTAL_PREMIUM: "21280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17100001",
    TOTAL_SUMINS_MAX: "17200000",
    PLAN_NET_PREMIUM_AMT: "19882.62",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1397.38",
    PLAN_TOTAL_PREMIUM: "21360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17200001",
    TOTAL_SUMINS_MAX: "17300000",
    PLAN_NET_PREMIUM_AMT: "19957.38",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1402.62",
    PLAN_TOTAL_PREMIUM: "21440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17300001",
    TOTAL_SUMINS_MAX: "17400000",
    PLAN_NET_PREMIUM_AMT: "20031.15",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1407.85",
    PLAN_TOTAL_PREMIUM: "21520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17400001",
    TOTAL_SUMINS_MAX: "17500000",
    PLAN_NET_PREMIUM_AMT: "20105.92",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1413.08",
    PLAN_TOTAL_PREMIUM: "21600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17500001",
    TOTAL_SUMINS_MAX: "17600000",
    PLAN_NET_PREMIUM_AMT: "20180.68",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1418.32",
    PLAN_TOTAL_PREMIUM: "21680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17600001",
    TOTAL_SUMINS_MAX: "17700000",
    PLAN_NET_PREMIUM_AMT: "20254.45",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1423.55",
    PLAN_TOTAL_PREMIUM: "21760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17700001",
    TOTAL_SUMINS_MAX: "17800000",
    PLAN_NET_PREMIUM_AMT: "20329.21",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1428.79",
    PLAN_TOTAL_PREMIUM: "21840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17800001",
    TOTAL_SUMINS_MAX: "17900000",
    PLAN_NET_PREMIUM_AMT: "20403.98",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1434.02",
    PLAN_TOTAL_PREMIUM: "21920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17900001",
    TOTAL_SUMINS_MAX: "18000000",
    PLAN_NET_PREMIUM_AMT: "20478.75",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1439.25",
    PLAN_TOTAL_PREMIUM: "22000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18000001",
    TOTAL_SUMINS_MAX: "18100000",
    PLAN_NET_PREMIUM_AMT: "20552.51",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1444.49",
    PLAN_TOTAL_PREMIUM: "22080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18100001",
    TOTAL_SUMINS_MAX: "18200000",
    PLAN_NET_PREMIUM_AMT: "20627.28",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1449.72",
    PLAN_TOTAL_PREMIUM: "22160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18200001",
    TOTAL_SUMINS_MAX: "18300000",
    PLAN_NET_PREMIUM_AMT: "20702.05",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1454.95",
    PLAN_TOTAL_PREMIUM: "22240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18300001",
    TOTAL_SUMINS_MAX: "18400000",
    PLAN_NET_PREMIUM_AMT: "20775.81",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1460.19",
    PLAN_TOTAL_PREMIUM: "22320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18400001",
    TOTAL_SUMINS_MAX: "18500000",
    PLAN_NET_PREMIUM_AMT: "20850.58",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1465.42",
    PLAN_TOTAL_PREMIUM: "22400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18500001",
    TOTAL_SUMINS_MAX: "18600000",
    PLAN_NET_PREMIUM_AMT: "20925.35",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1470.65",
    PLAN_TOTAL_PREMIUM: "22480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18600001",
    TOTAL_SUMINS_MAX: "18700000",
    PLAN_NET_PREMIUM_AMT: "20999.11",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1475.89",
    PLAN_TOTAL_PREMIUM: "22560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18700001",
    TOTAL_SUMINS_MAX: "18800000",
    PLAN_NET_PREMIUM_AMT: "21073.88",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1481.12",
    PLAN_TOTAL_PREMIUM: "22640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18800001",
    TOTAL_SUMINS_MAX: "18900000",
    PLAN_NET_PREMIUM_AMT: "21148.64",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1486.36",
    PLAN_TOTAL_PREMIUM: "22720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18900001",
    TOTAL_SUMINS_MAX: "19000000",
    PLAN_NET_PREMIUM_AMT: "21223.41",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1491.59",
    PLAN_TOTAL_PREMIUM: "22800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19000001",
    TOTAL_SUMINS_MAX: "19100000",
    PLAN_NET_PREMIUM_AMT: "21297.18",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1496.82",
    PLAN_TOTAL_PREMIUM: "22880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19100001",
    TOTAL_SUMINS_MAX: "19200000",
    PLAN_NET_PREMIUM_AMT: "21371.94",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1502.06",
    PLAN_TOTAL_PREMIUM: "22960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19200001",
    TOTAL_SUMINS_MAX: "19300000",
    PLAN_NET_PREMIUM_AMT: "21446.71",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1507.29",
    PLAN_TOTAL_PREMIUM: "23040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19300001",
    TOTAL_SUMINS_MAX: "19400000",
    PLAN_NET_PREMIUM_AMT: "21520.48",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1512.52",
    PLAN_TOTAL_PREMIUM: "23120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19400001",
    TOTAL_SUMINS_MAX: "19500000",
    PLAN_NET_PREMIUM_AMT: "21595.24",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1517.76",
    PLAN_TOTAL_PREMIUM: "23200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19500001",
    TOTAL_SUMINS_MAX: "19600000",
    PLAN_NET_PREMIUM_AMT: "21670.01",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1522.99",
    PLAN_TOTAL_PREMIUM: "23280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19600001",
    TOTAL_SUMINS_MAX: "19700000",
    PLAN_NET_PREMIUM_AMT: "21744.78",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1528.22",
    PLAN_TOTAL_PREMIUM: "23360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19700001",
    TOTAL_SUMINS_MAX: "19800000",
    PLAN_NET_PREMIUM_AMT: "21818.54",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1533.46",
    PLAN_TOTAL_PREMIUM: "23440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19800001",
    TOTAL_SUMINS_MAX: "19900000",
    PLAN_NET_PREMIUM_AMT: "21893.31",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1538.69",
    PLAN_TOTAL_PREMIUM: "23520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19900001",
    TOTAL_SUMINS_MAX: "20000000",
    PLAN_NET_PREMIUM_AMT: "21968.07",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1543.93",
    PLAN_TOTAL_PREMIUM: "23600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20000001",
    TOTAL_SUMINS_MAX: "20100000",
    PLAN_NET_PREMIUM_AMT: "22041.84",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1549.16",
    PLAN_TOTAL_PREMIUM: "23680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20100001",
    TOTAL_SUMINS_MAX: "20200000",
    PLAN_NET_PREMIUM_AMT: "22116.61",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1554.39",
    PLAN_TOTAL_PREMIUM: "23760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20200001",
    TOTAL_SUMINS_MAX: "20300000",
    PLAN_NET_PREMIUM_AMT: "22191.37",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1559.63",
    PLAN_TOTAL_PREMIUM: "23840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20300001",
    TOTAL_SUMINS_MAX: "20400000",
    PLAN_NET_PREMIUM_AMT: "22265.14",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1564.86",
    PLAN_TOTAL_PREMIUM: "23920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20400001",
    TOTAL_SUMINS_MAX: "20500000",
    PLAN_NET_PREMIUM_AMT: "22339.91",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1570.09",
    PLAN_TOTAL_PREMIUM: "24000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20500001",
    TOTAL_SUMINS_MAX: "20600000",
    PLAN_NET_PREMIUM_AMT: "22414.67",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1575.33",
    PLAN_TOTAL_PREMIUM: "24080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20600001",
    TOTAL_SUMINS_MAX: "20700000",
    PLAN_NET_PREMIUM_AMT: "22489.44",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1580.56",
    PLAN_TOTAL_PREMIUM: "24160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20700001",
    TOTAL_SUMINS_MAX: "20800000",
    PLAN_NET_PREMIUM_AMT: "22563.21",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1585.79",
    PLAN_TOTAL_PREMIUM: "24240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20800001",
    TOTAL_SUMINS_MAX: "20900000",
    PLAN_NET_PREMIUM_AMT: "22637.97",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1591.03",
    PLAN_TOTAL_PREMIUM: "24320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20900001",
    TOTAL_SUMINS_MAX: "21000000",
    PLAN_NET_PREMIUM_AMT: "22712.74",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1596.26",
    PLAN_TOTAL_PREMIUM: "24400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21000001",
    TOTAL_SUMINS_MAX: "21100000",
    PLAN_NET_PREMIUM_AMT: "22786.50",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1601.50",
    PLAN_TOTAL_PREMIUM: "24480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21100001",
    TOTAL_SUMINS_MAX: "21200000",
    PLAN_NET_PREMIUM_AMT: "22861.27",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1606.73",
    PLAN_TOTAL_PREMIUM: "24560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21200001",
    TOTAL_SUMINS_MAX: "21300000",
    PLAN_NET_PREMIUM_AMT: "22936.04",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1611.96",
    PLAN_TOTAL_PREMIUM: "24640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21300001",
    TOTAL_SUMINS_MAX: "21400000",
    PLAN_NET_PREMIUM_AMT: "23009.80",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1617.20",
    PLAN_TOTAL_PREMIUM: "24720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21400001",
    TOTAL_SUMINS_MAX: "21500000",
    PLAN_NET_PREMIUM_AMT: "23084.57",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1622.43",
    PLAN_TOTAL_PREMIUM: "24800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21500001",
    TOTAL_SUMINS_MAX: "21600000",
    PLAN_NET_PREMIUM_AMT: "23159.34",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1627.66",
    PLAN_TOTAL_PREMIUM: "24880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21600001",
    TOTAL_SUMINS_MAX: "21700000",
    PLAN_NET_PREMIUM_AMT: "23234.10",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1632.90",
    PLAN_TOTAL_PREMIUM: "24960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21700001",
    TOTAL_SUMINS_MAX: "21800000",
    PLAN_NET_PREMIUM_AMT: "23307.87",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1638.13",
    PLAN_TOTAL_PREMIUM: "25040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21800001",
    TOTAL_SUMINS_MAX: "21900000",
    PLAN_NET_PREMIUM_AMT: "23382.64",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1643.36",
    PLAN_TOTAL_PREMIUM: "25120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21900001",
    TOTAL_SUMINS_MAX: "22000000",
    PLAN_NET_PREMIUM_AMT: "23457.40",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1648.60",
    PLAN_TOTAL_PREMIUM: "25200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22000001",
    TOTAL_SUMINS_MAX: "22100000",
    PLAN_NET_PREMIUM_AMT: "23531.17",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1653.83",
    PLAN_TOTAL_PREMIUM: "25280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22100001",
    TOTAL_SUMINS_MAX: "22200000",
    PLAN_NET_PREMIUM_AMT: "23605.93",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1659.07",
    PLAN_TOTAL_PREMIUM: "25360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22200001",
    TOTAL_SUMINS_MAX: "22300000",
    PLAN_NET_PREMIUM_AMT: "23680.70",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1664.30",
    PLAN_TOTAL_PREMIUM: "25440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22300001",
    TOTAL_SUMINS_MAX: "22400000",
    PLAN_NET_PREMIUM_AMT: "23754.47",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1669.53",
    PLAN_TOTAL_PREMIUM: "25520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22400001",
    TOTAL_SUMINS_MAX: "22500000",
    PLAN_NET_PREMIUM_AMT: "23829.23",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1674.77",
    PLAN_TOTAL_PREMIUM: "25600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22500001",
    TOTAL_SUMINS_MAX: "22600000",
    PLAN_NET_PREMIUM_AMT: "23904.00",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1680.00",
    PLAN_TOTAL_PREMIUM: "25680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22600001",
    TOTAL_SUMINS_MAX: "22700000",
    PLAN_NET_PREMIUM_AMT: "23978.77",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1685.23",
    PLAN_TOTAL_PREMIUM: "25760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22700001",
    TOTAL_SUMINS_MAX: "22800000",
    PLAN_NET_PREMIUM_AMT: "24052.53",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1690.47",
    PLAN_TOTAL_PREMIUM: "25840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22800001",
    TOTAL_SUMINS_MAX: "22900000",
    PLAN_NET_PREMIUM_AMT: "24127.30",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1695.70",
    PLAN_TOTAL_PREMIUM: "25920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22900001",
    TOTAL_SUMINS_MAX: "23000000",
    PLAN_NET_PREMIUM_AMT: "24202.07",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1700.93",
    PLAN_TOTAL_PREMIUM: "26000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23000001",
    TOTAL_SUMINS_MAX: "23100000",
    PLAN_NET_PREMIUM_AMT: "24275.83",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1706.17",
    PLAN_TOTAL_PREMIUM: "26080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23100001",
    TOTAL_SUMINS_MAX: "23200000",
    PLAN_NET_PREMIUM_AMT: "24350.60",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1711.40",
    PLAN_TOTAL_PREMIUM: "26160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23200001",
    TOTAL_SUMINS_MAX: "23300000",
    PLAN_NET_PREMIUM_AMT: "24425.36",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1716.64",
    PLAN_TOTAL_PREMIUM: "26240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23300001",
    TOTAL_SUMINS_MAX: "23400000",
    PLAN_NET_PREMIUM_AMT: "24499.13",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1721.87",
    PLAN_TOTAL_PREMIUM: "26320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23400001",
    TOTAL_SUMINS_MAX: "23500000",
    PLAN_NET_PREMIUM_AMT: "24573.90",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1727.10",
    PLAN_TOTAL_PREMIUM: "26400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23500001",
    TOTAL_SUMINS_MAX: "23600000",
    PLAN_NET_PREMIUM_AMT: "24648.66",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1732.34",
    PLAN_TOTAL_PREMIUM: "26480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23600001",
    TOTAL_SUMINS_MAX: "23700000",
    PLAN_NET_PREMIUM_AMT: "24723.43",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1737.57",
    PLAN_TOTAL_PREMIUM: "26560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23700001",
    TOTAL_SUMINS_MAX: "23800000",
    PLAN_NET_PREMIUM_AMT: "24797.20",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1742.80",
    PLAN_TOTAL_PREMIUM: "26640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23800001",
    TOTAL_SUMINS_MAX: "23900000",
    PLAN_NET_PREMIUM_AMT: "24871.96",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1748.04",
    PLAN_TOTAL_PREMIUM: "26720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23900001",
    TOTAL_SUMINS_MAX: "24000000",
    PLAN_NET_PREMIUM_AMT: "24946.73",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1753.27",
    PLAN_TOTAL_PREMIUM: "26800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24000001",
    TOTAL_SUMINS_MAX: "24100000",
    PLAN_NET_PREMIUM_AMT: "25020.49",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1758.51",
    PLAN_TOTAL_PREMIUM: "26880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24100001",
    TOTAL_SUMINS_MAX: "24200000",
    PLAN_NET_PREMIUM_AMT: "25095.26",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1763.74",
    PLAN_TOTAL_PREMIUM: "26960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24200001",
    TOTAL_SUMINS_MAX: "24300000",
    PLAN_NET_PREMIUM_AMT: "25170.03",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1768.97",
    PLAN_TOTAL_PREMIUM: "27040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24300001",
    TOTAL_SUMINS_MAX: "24400000",
    PLAN_NET_PREMIUM_AMT: "25244.79",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1774.21",
    PLAN_TOTAL_PREMIUM: "27120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24400001",
    TOTAL_SUMINS_MAX: "24500000",
    PLAN_NET_PREMIUM_AMT: "25318.56",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1779.44",
    PLAN_TOTAL_PREMIUM: "27200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24500001",
    TOTAL_SUMINS_MAX: "24600000",
    PLAN_NET_PREMIUM_AMT: "25393.33",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1784.67",
    PLAN_TOTAL_PREMIUM: "27280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24600001",
    TOTAL_SUMINS_MAX: "24700000",
    PLAN_NET_PREMIUM_AMT: "25468.09",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1789.91",
    PLAN_TOTAL_PREMIUM: "27360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24700001",
    TOTAL_SUMINS_MAX: "24800000",
    PLAN_NET_PREMIUM_AMT: "25541.86",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1795.14",
    PLAN_TOTAL_PREMIUM: "27440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24800001",
    TOTAL_SUMINS_MAX: "24900000",
    PLAN_NET_PREMIUM_AMT: "25616.63",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1800.37",
    PLAN_TOTAL_PREMIUM: "27520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24900001",
    TOTAL_SUMINS_MAX: "25000000",
    PLAN_NET_PREMIUM_AMT: "25691.39",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1805.61",
    PLAN_TOTAL_PREMIUM: "27600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25000001",
    TOTAL_SUMINS_MAX: "25100000",
    PLAN_NET_PREMIUM_AMT: "25765.16",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1810.84",
    PLAN_TOTAL_PREMIUM: "27680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25100001",
    TOTAL_SUMINS_MAX: "25200000",
    PLAN_NET_PREMIUM_AMT: "25839.92",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1816.08",
    PLAN_TOTAL_PREMIUM: "27760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25200001",
    TOTAL_SUMINS_MAX: "25300000",
    PLAN_NET_PREMIUM_AMT: "25914.69",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1821.31",
    PLAN_TOTAL_PREMIUM: "27840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25300001",
    TOTAL_SUMINS_MAX: "25400000",
    PLAN_NET_PREMIUM_AMT: "25989.46",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1826.54",
    PLAN_TOTAL_PREMIUM: "27920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25400001",
    TOTAL_SUMINS_MAX: "25500000",
    PLAN_NET_PREMIUM_AMT: "26063.22",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1831.78",
    PLAN_TOTAL_PREMIUM: "28000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25500001",
    TOTAL_SUMINS_MAX: "25600000",
    PLAN_NET_PREMIUM_AMT: "26137.99",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1837.01",
    PLAN_TOTAL_PREMIUM: "28080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25600001",
    TOTAL_SUMINS_MAX: "25700000",
    PLAN_NET_PREMIUM_AMT: "26212.76",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1842.24",
    PLAN_TOTAL_PREMIUM: "28160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25700001",
    TOTAL_SUMINS_MAX: "25800000",
    PLAN_NET_PREMIUM_AMT: "26286.52",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1847.48",
    PLAN_TOTAL_PREMIUM: "28240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25800001",
    TOTAL_SUMINS_MAX: "25900000",
    PLAN_NET_PREMIUM_AMT: "26361.29",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1852.71",
    PLAN_TOTAL_PREMIUM: "28320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25900001",
    TOTAL_SUMINS_MAX: "26000000",
    PLAN_NET_PREMIUM_AMT: "26436.06",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1857.94",
    PLAN_TOTAL_PREMIUM: "28400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26000001",
    TOTAL_SUMINS_MAX: "26100000",
    PLAN_NET_PREMIUM_AMT: "26509.82",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1863.18",
    PLAN_TOTAL_PREMIUM: "28480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26100001",
    TOTAL_SUMINS_MAX: "26200000",
    PLAN_NET_PREMIUM_AMT: "26584.59",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1868.41",
    PLAN_TOTAL_PREMIUM: "28560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26200001",
    TOTAL_SUMINS_MAX: "26300000",
    PLAN_NET_PREMIUM_AMT: "26659.35",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1873.65",
    PLAN_TOTAL_PREMIUM: "28640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26300001",
    TOTAL_SUMINS_MAX: "26400000",
    PLAN_NET_PREMIUM_AMT: "26734.12",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1878.88",
    PLAN_TOTAL_PREMIUM: "28720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26400001",
    TOTAL_SUMINS_MAX: "26500000",
    PLAN_NET_PREMIUM_AMT: "26807.89",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1884.11",
    PLAN_TOTAL_PREMIUM: "28800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26500001",
    TOTAL_SUMINS_MAX: "26600000",
    PLAN_NET_PREMIUM_AMT: "26882.65",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1889.35",
    PLAN_TOTAL_PREMIUM: "28880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26600001",
    TOTAL_SUMINS_MAX: "26700000",
    PLAN_NET_PREMIUM_AMT: "26957.42",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1894.58",
    PLAN_TOTAL_PREMIUM: "28960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26700001",
    TOTAL_SUMINS_MAX: "26800000",
    PLAN_NET_PREMIUM_AMT: "27031.19",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1899.81",
    PLAN_TOTAL_PREMIUM: "29040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26800001",
    TOTAL_SUMINS_MAX: "26900000",
    PLAN_NET_PREMIUM_AMT: "27105.95",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1905.05",
    PLAN_TOTAL_PREMIUM: "29120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26900001",
    TOTAL_SUMINS_MAX: "27000000",
    PLAN_NET_PREMIUM_AMT: "27180.72",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1910.28",
    PLAN_TOTAL_PREMIUM: "29200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27000001",
    TOTAL_SUMINS_MAX: "27100000",
    PLAN_NET_PREMIUM_AMT: "27254.49",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1915.51",
    PLAN_TOTAL_PREMIUM: "29280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27100001",
    TOTAL_SUMINS_MAX: "27200000",
    PLAN_NET_PREMIUM_AMT: "27329.25",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1920.75",
    PLAN_TOTAL_PREMIUM: "29360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27200001",
    TOTAL_SUMINS_MAX: "27300000",
    PLAN_NET_PREMIUM_AMT: "27404.02",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1925.98",
    PLAN_TOTAL_PREMIUM: "29440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27300001",
    TOTAL_SUMINS_MAX: "27400000",
    PLAN_NET_PREMIUM_AMT: "27478.78",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1931.22",
    PLAN_TOTAL_PREMIUM: "29520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27400001",
    TOTAL_SUMINS_MAX: "27500000",
    PLAN_NET_PREMIUM_AMT: "27552.55",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1936.45",
    PLAN_TOTAL_PREMIUM: "29600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27500001",
    TOTAL_SUMINS_MAX: "27600000",
    PLAN_NET_PREMIUM_AMT: "27627.32",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1941.68",
    PLAN_TOTAL_PREMIUM: "29680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27600001",
    TOTAL_SUMINS_MAX: "27700000",
    PLAN_NET_PREMIUM_AMT: "27702.08",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1946.92",
    PLAN_TOTAL_PREMIUM: "29760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27700001",
    TOTAL_SUMINS_MAX: "27800000",
    PLAN_NET_PREMIUM_AMT: "27775.85",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1952.15",
    PLAN_TOTAL_PREMIUM: "29840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27800001",
    TOTAL_SUMINS_MAX: "27900000",
    PLAN_NET_PREMIUM_AMT: "27850.62",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1957.38",
    PLAN_TOTAL_PREMIUM: "29920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27900001",
    TOTAL_SUMINS_MAX: "28000000",
    PLAN_NET_PREMIUM_AMT: "27925.38",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1962.62",
    PLAN_TOTAL_PREMIUM: "30000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28000001",
    TOTAL_SUMINS_MAX: "28100000",
    PLAN_NET_PREMIUM_AMT: "27999.15",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1967.85",
    PLAN_TOTAL_PREMIUM: "30080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28100001",
    TOTAL_SUMINS_MAX: "28200000",
    PLAN_NET_PREMIUM_AMT: "28073.92",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1973.08",
    PLAN_TOTAL_PREMIUM: "30160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28200001",
    TOTAL_SUMINS_MAX: "28300000",
    PLAN_NET_PREMIUM_AMT: "28148.68",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1978.32",
    PLAN_TOTAL_PREMIUM: "30240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28300001",
    TOTAL_SUMINS_MAX: "28400000",
    PLAN_NET_PREMIUM_AMT: "28223.45",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1983.55",
    PLAN_TOTAL_PREMIUM: "30320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28400001",
    TOTAL_SUMINS_MAX: "28500000",
    PLAN_NET_PREMIUM_AMT: "28297.21",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1988.79",
    PLAN_TOTAL_PREMIUM: "30400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28500001",
    TOTAL_SUMINS_MAX: "28600000",
    PLAN_NET_PREMIUM_AMT: "28371.98",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1994.02",
    PLAN_TOTAL_PREMIUM: "30480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28600001",
    TOTAL_SUMINS_MAX: "28700000",
    PLAN_NET_PREMIUM_AMT: "28446.75",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1999.25",
    PLAN_TOTAL_PREMIUM: "30560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28700001",
    TOTAL_SUMINS_MAX: "28800000",
    PLAN_NET_PREMIUM_AMT: "28520.51",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2004.49",
    PLAN_TOTAL_PREMIUM: "30640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28800001",
    TOTAL_SUMINS_MAX: "28900000",
    PLAN_NET_PREMIUM_AMT: "28595.28",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2009.72",
    PLAN_TOTAL_PREMIUM: "30720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28900001",
    TOTAL_SUMINS_MAX: "29000000",
    PLAN_NET_PREMIUM_AMT: "28670.05",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2014.95",
    PLAN_TOTAL_PREMIUM: "30800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29000001",
    TOTAL_SUMINS_MAX: "29100000",
    PLAN_NET_PREMIUM_AMT: "28744.81",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2020.19",
    PLAN_TOTAL_PREMIUM: "30880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29100001",
    TOTAL_SUMINS_MAX: "29200000",
    PLAN_NET_PREMIUM_AMT: "28818.58",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2025.42",
    PLAN_TOTAL_PREMIUM: "30960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29200001",
    TOTAL_SUMINS_MAX: "29300000",
    PLAN_NET_PREMIUM_AMT: "28893.35",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2030.65",
    PLAN_TOTAL_PREMIUM: "31040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29300001",
    TOTAL_SUMINS_MAX: "29400000",
    PLAN_NET_PREMIUM_AMT: "28968.11",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2035.89",
    PLAN_TOTAL_PREMIUM: "31120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29400001",
    TOTAL_SUMINS_MAX: "29500000",
    PLAN_NET_PREMIUM_AMT: "29041.88",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2041.12",
    PLAN_TOTAL_PREMIUM: "31200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29500001",
    TOTAL_SUMINS_MAX: "29600000",
    PLAN_NET_PREMIUM_AMT: "29116.64",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2046.36",
    PLAN_TOTAL_PREMIUM: "31280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29600001",
    TOTAL_SUMINS_MAX: "29700000",
    PLAN_NET_PREMIUM_AMT: "29191.41",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2051.59",
    PLAN_TOTAL_PREMIUM: "31360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29700001",
    TOTAL_SUMINS_MAX: "29800000",
    PLAN_NET_PREMIUM_AMT: "29265.18",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2056.82",
    PLAN_TOTAL_PREMIUM: "31440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29800001",
    TOTAL_SUMINS_MAX: "29900000",
    PLAN_NET_PREMIUM_AMT: "29339.94",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2062.06",
    PLAN_TOTAL_PREMIUM: "31520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29900001",
    TOTAL_SUMINS_MAX: "30000000",
    PLAN_NET_PREMIUM_AMT: "29414.71",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2067.29",
    PLAN_TOTAL_PREMIUM: "31600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30000001",
    TOTAL_SUMINS_MAX: "30100000",
    PLAN_NET_PREMIUM_AMT: "29489.48",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2072.52",
    PLAN_TOTAL_PREMIUM: "31680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30100001",
    TOTAL_SUMINS_MAX: "30200000",
    PLAN_NET_PREMIUM_AMT: "29563.24",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2077.76",
    PLAN_TOTAL_PREMIUM: "31760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30200001",
    TOTAL_SUMINS_MAX: "30300000",
    PLAN_NET_PREMIUM_AMT: "29638.01",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2082.99",
    PLAN_TOTAL_PREMIUM: "31840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30300001",
    TOTAL_SUMINS_MAX: "30400000",
    PLAN_NET_PREMIUM_AMT: "29712.78",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2088.22",
    PLAN_TOTAL_PREMIUM: "31920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30400001",
    TOTAL_SUMINS_MAX: "30500000",
    PLAN_NET_PREMIUM_AMT: "29786.54",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2093.46",
    PLAN_TOTAL_PREMIUM: "32000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30500001",
    TOTAL_SUMINS_MAX: "30600000",
    PLAN_NET_PREMIUM_AMT: "29861.31",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2098.69",
    PLAN_TOTAL_PREMIUM: "32080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30600001",
    TOTAL_SUMINS_MAX: "30700000",
    PLAN_NET_PREMIUM_AMT: "29936.07",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2103.93",
    PLAN_TOTAL_PREMIUM: "32160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30700001",
    TOTAL_SUMINS_MAX: "30800000",
    PLAN_NET_PREMIUM_AMT: "30009.84",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2109.16",
    PLAN_TOTAL_PREMIUM: "32240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30800001",
    TOTAL_SUMINS_MAX: "30900000",
    PLAN_NET_PREMIUM_AMT: "30084.61",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2114.39",
    PLAN_TOTAL_PREMIUM: "32320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30900001",
    TOTAL_SUMINS_MAX: "31000000",
    PLAN_NET_PREMIUM_AMT: "30159.37",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2119.63",
    PLAN_TOTAL_PREMIUM: "32400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31000001",
    TOTAL_SUMINS_MAX: "31100000",
    PLAN_NET_PREMIUM_AMT: "30234.14",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2124.86",
    PLAN_TOTAL_PREMIUM: "32480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31100001",
    TOTAL_SUMINS_MAX: "31200000",
    PLAN_NET_PREMIUM_AMT: "30307.91",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2130.09",
    PLAN_TOTAL_PREMIUM: "32560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31200001",
    TOTAL_SUMINS_MAX: "31300000",
    PLAN_NET_PREMIUM_AMT: "30382.67",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2135.33",
    PLAN_TOTAL_PREMIUM: "32640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31300001",
    TOTAL_SUMINS_MAX: "31400000",
    PLAN_NET_PREMIUM_AMT: "30457.44",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2140.56",
    PLAN_TOTAL_PREMIUM: "32720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31400001",
    TOTAL_SUMINS_MAX: "31500000",
    PLAN_NET_PREMIUM_AMT: "30531.21",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2145.79",
    PLAN_TOTAL_PREMIUM: "32800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31500001",
    TOTAL_SUMINS_MAX: "31600000",
    PLAN_NET_PREMIUM_AMT: "30605.97",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2151.03",
    PLAN_TOTAL_PREMIUM: "32880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31600001",
    TOTAL_SUMINS_MAX: "31700000",
    PLAN_NET_PREMIUM_AMT: "30680.74",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2156.26",
    PLAN_TOTAL_PREMIUM: "32960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31700001",
    TOTAL_SUMINS_MAX: "31800000",
    PLAN_NET_PREMIUM_AMT: "30754.50",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2161.50",
    PLAN_TOTAL_PREMIUM: "33040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31800001",
    TOTAL_SUMINS_MAX: "31900000",
    PLAN_NET_PREMIUM_AMT: "30829.27",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2166.73",
    PLAN_TOTAL_PREMIUM: "33120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31900001",
    TOTAL_SUMINS_MAX: "32000000",
    PLAN_NET_PREMIUM_AMT: "30904.04",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2171.96",
    PLAN_TOTAL_PREMIUM: "33200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32000001",
    TOTAL_SUMINS_MAX: "32100000",
    PLAN_NET_PREMIUM_AMT: "30978.80",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2177.20",
    PLAN_TOTAL_PREMIUM: "33280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32100001",
    TOTAL_SUMINS_MAX: "32200000",
    PLAN_NET_PREMIUM_AMT: "31052.57",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2182.43",
    PLAN_TOTAL_PREMIUM: "33360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32200001",
    TOTAL_SUMINS_MAX: "32300000",
    PLAN_NET_PREMIUM_AMT: "31127.34",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2187.66",
    PLAN_TOTAL_PREMIUM: "33440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32300001",
    TOTAL_SUMINS_MAX: "32400000",
    PLAN_NET_PREMIUM_AMT: "31202.10",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2192.90",
    PLAN_TOTAL_PREMIUM: "33520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32400001",
    TOTAL_SUMINS_MAX: "32500000",
    PLAN_NET_PREMIUM_AMT: "31275.87",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2198.13",
    PLAN_TOTAL_PREMIUM: "33600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32500001",
    TOTAL_SUMINS_MAX: "32600000",
    PLAN_NET_PREMIUM_AMT: "31350.64",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2203.36",
    PLAN_TOTAL_PREMIUM: "33680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32600001",
    TOTAL_SUMINS_MAX: "32700000",
    PLAN_NET_PREMIUM_AMT: "31425.40",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2208.60",
    PLAN_TOTAL_PREMIUM: "33760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32700001",
    TOTAL_SUMINS_MAX: "32800000",
    PLAN_NET_PREMIUM_AMT: "31499.17",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2213.83",
    PLAN_TOTAL_PREMIUM: "33840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32800001",
    TOTAL_SUMINS_MAX: "32900000",
    PLAN_NET_PREMIUM_AMT: "31573.93",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2219.07",
    PLAN_TOTAL_PREMIUM: "33920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32900001",
    TOTAL_SUMINS_MAX: "33000000",
    PLAN_NET_PREMIUM_AMT: "31648.70",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2224.30",
    PLAN_TOTAL_PREMIUM: "34000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33000001",
    TOTAL_SUMINS_MAX: "33100000",
    PLAN_NET_PREMIUM_AMT: "31723.47",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2229.53",
    PLAN_TOTAL_PREMIUM: "34080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33100001",
    TOTAL_SUMINS_MAX: "33200000",
    PLAN_NET_PREMIUM_AMT: "31797.23",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2234.77",
    PLAN_TOTAL_PREMIUM: "34160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33200001",
    TOTAL_SUMINS_MAX: "33300000",
    PLAN_NET_PREMIUM_AMT: "31872.00",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2240.00",
    PLAN_TOTAL_PREMIUM: "34240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33300001",
    TOTAL_SUMINS_MAX: "33400000",
    PLAN_NET_PREMIUM_AMT: "31946.77",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2245.23",
    PLAN_TOTAL_PREMIUM: "34320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33400001",
    TOTAL_SUMINS_MAX: "33500000",
    PLAN_NET_PREMIUM_AMT: "32020.53",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2250.47",
    PLAN_TOTAL_PREMIUM: "34400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33500001",
    TOTAL_SUMINS_MAX: "33600000",
    PLAN_NET_PREMIUM_AMT: "32095.30",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2255.70",
    PLAN_TOTAL_PREMIUM: "34480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33600001",
    TOTAL_SUMINS_MAX: "33700000",
    PLAN_NET_PREMIUM_AMT: "32170.07",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2260.93",
    PLAN_TOTAL_PREMIUM: "34560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33700001",
    TOTAL_SUMINS_MAX: "33800000",
    PLAN_NET_PREMIUM_AMT: "32244.83",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2266.17",
    PLAN_TOTAL_PREMIUM: "34640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33800001",
    TOTAL_SUMINS_MAX: "33900000",
    PLAN_NET_PREMIUM_AMT: "32318.60",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2271.40",
    PLAN_TOTAL_PREMIUM: "34720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33900001",
    TOTAL_SUMINS_MAX: "34000000",
    PLAN_NET_PREMIUM_AMT: "32393.36",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2276.64",
    PLAN_TOTAL_PREMIUM: "34800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34000001",
    TOTAL_SUMINS_MAX: "34100000",
    PLAN_NET_PREMIUM_AMT: "32468.13",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2281.87",
    PLAN_TOTAL_PREMIUM: "34880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34100001",
    TOTAL_SUMINS_MAX: "34200000",
    PLAN_NET_PREMIUM_AMT: "32541.90",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2287.10",
    PLAN_TOTAL_PREMIUM: "34960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34200001",
    TOTAL_SUMINS_MAX: "34300000",
    PLAN_NET_PREMIUM_AMT: "32616.66",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2292.34",
    PLAN_TOTAL_PREMIUM: "35040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34300001",
    TOTAL_SUMINS_MAX: "34400000",
    PLAN_NET_PREMIUM_AMT: "32691.43",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2297.57",
    PLAN_TOTAL_PREMIUM: "35120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34400001",
    TOTAL_SUMINS_MAX: "34500000",
    PLAN_NET_PREMIUM_AMT: "32765.20",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2302.80",
    PLAN_TOTAL_PREMIUM: "35200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34500001",
    TOTAL_SUMINS_MAX: "34600000",
    PLAN_NET_PREMIUM_AMT: "32839.96",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2308.04",
    PLAN_TOTAL_PREMIUM: "35280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34600001",
    TOTAL_SUMINS_MAX: "34700000",
    PLAN_NET_PREMIUM_AMT: "32914.73",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2313.27",
    PLAN_TOTAL_PREMIUM: "35360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34700001",
    TOTAL_SUMINS_MAX: "34800000",
    PLAN_NET_PREMIUM_AMT: "32989.49",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2318.51",
    PLAN_TOTAL_PREMIUM: "35440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34800001",
    TOTAL_SUMINS_MAX: "34900000",
    PLAN_NET_PREMIUM_AMT: "33063.26",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2323.74",
    PLAN_TOTAL_PREMIUM: "35520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34900001",
    TOTAL_SUMINS_MAX: "35000000",
    PLAN_NET_PREMIUM_AMT: "33138.03",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2328.97",
    PLAN_TOTAL_PREMIUM: "35600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35000001",
    TOTAL_SUMINS_MAX: "35100000",
    PLAN_NET_PREMIUM_AMT: "33212.79",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2334.21",
    PLAN_TOTAL_PREMIUM: "35680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35100001",
    TOTAL_SUMINS_MAX: "35200000",
    PLAN_NET_PREMIUM_AMT: "33286.56",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2339.44",
    PLAN_TOTAL_PREMIUM: "35760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35200001",
    TOTAL_SUMINS_MAX: "35300000",
    PLAN_NET_PREMIUM_AMT: "33361.33",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2344.67",
    PLAN_TOTAL_PREMIUM: "35840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35300001",
    TOTAL_SUMINS_MAX: "35400000",
    PLAN_NET_PREMIUM_AMT: "33436.09",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2349.91",
    PLAN_TOTAL_PREMIUM: "35920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35400001",
    TOTAL_SUMINS_MAX: "35500000",
    PLAN_NET_PREMIUM_AMT: "33509.86",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2355.14",
    PLAN_TOTAL_PREMIUM: "36000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35500001",
    TOTAL_SUMINS_MAX: "35600000",
    PLAN_NET_PREMIUM_AMT: "33584.63",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2360.37",
    PLAN_TOTAL_PREMIUM: "36080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35600001",
    TOTAL_SUMINS_MAX: "35700000",
    PLAN_NET_PREMIUM_AMT: "33659.39",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2365.61",
    PLAN_TOTAL_PREMIUM: "36160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35700001",
    TOTAL_SUMINS_MAX: "35800000",
    PLAN_NET_PREMIUM_AMT: "33734.16",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2370.84",
    PLAN_TOTAL_PREMIUM: "36240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35800001",
    TOTAL_SUMINS_MAX: "35900000",
    PLAN_NET_PREMIUM_AMT: "33807.92",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2376.08",
    PLAN_TOTAL_PREMIUM: "36320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35900001",
    TOTAL_SUMINS_MAX: "36000000",
    PLAN_NET_PREMIUM_AMT: "33882.69",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2381.31",
    PLAN_TOTAL_PREMIUM: "36400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36000001",
    TOTAL_SUMINS_MAX: "36100000",
    PLAN_NET_PREMIUM_AMT: "33957.46",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2386.54",
    PLAN_TOTAL_PREMIUM: "36480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36100001",
    TOTAL_SUMINS_MAX: "36200000",
    PLAN_NET_PREMIUM_AMT: "34031.22",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2391.78",
    PLAN_TOTAL_PREMIUM: "36560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36200001",
    TOTAL_SUMINS_MAX: "36300000",
    PLAN_NET_PREMIUM_AMT: "34105.99",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2397.01",
    PLAN_TOTAL_PREMIUM: "36640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36300001",
    TOTAL_SUMINS_MAX: "36400000",
    PLAN_NET_PREMIUM_AMT: "34180.76",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2402.24",
    PLAN_TOTAL_PREMIUM: "36720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36400001",
    TOTAL_SUMINS_MAX: "36500000",
    PLAN_NET_PREMIUM_AMT: "34254.52",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2407.48",
    PLAN_TOTAL_PREMIUM: "36800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36500001",
    TOTAL_SUMINS_MAX: "36600000",
    PLAN_NET_PREMIUM_AMT: "34329.29",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2412.71",
    PLAN_TOTAL_PREMIUM: "36880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36600001",
    TOTAL_SUMINS_MAX: "36700000",
    PLAN_NET_PREMIUM_AMT: "34404.06",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2417.94",
    PLAN_TOTAL_PREMIUM: "36960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36700001",
    TOTAL_SUMINS_MAX: "36800000",
    PLAN_NET_PREMIUM_AMT: "34478.82",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2423.18",
    PLAN_TOTAL_PREMIUM: "37040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36800001",
    TOTAL_SUMINS_MAX: "36900000",
    PLAN_NET_PREMIUM_AMT: "34552.59",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2428.41",
    PLAN_TOTAL_PREMIUM: "37120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36900001",
    TOTAL_SUMINS_MAX: "37000000",
    PLAN_NET_PREMIUM_AMT: "34627.35",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2433.65",
    PLAN_TOTAL_PREMIUM: "37200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37000001",
    TOTAL_SUMINS_MAX: "37100000",
    PLAN_NET_PREMIUM_AMT: "34702.12",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2438.88",
    PLAN_TOTAL_PREMIUM: "37280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37100001",
    TOTAL_SUMINS_MAX: "37200000",
    PLAN_NET_PREMIUM_AMT: "34775.89",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2444.11",
    PLAN_TOTAL_PREMIUM: "37360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37200001",
    TOTAL_SUMINS_MAX: "37300000",
    PLAN_NET_PREMIUM_AMT: "34850.65",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2449.35",
    PLAN_TOTAL_PREMIUM: "37440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37300001",
    TOTAL_SUMINS_MAX: "37400000",
    PLAN_NET_PREMIUM_AMT: "34925.42",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2454.58",
    PLAN_TOTAL_PREMIUM: "37520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37400001",
    TOTAL_SUMINS_MAX: "37500000",
    PLAN_NET_PREMIUM_AMT: "34999.19",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2459.81",
    PLAN_TOTAL_PREMIUM: "37600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37500001",
    TOTAL_SUMINS_MAX: "37600000",
    PLAN_NET_PREMIUM_AMT: "35073.95",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2465.05",
    PLAN_TOTAL_PREMIUM: "37680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37600001",
    TOTAL_SUMINS_MAX: "37700000",
    PLAN_NET_PREMIUM_AMT: "35148.72",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2470.28",
    PLAN_TOTAL_PREMIUM: "37760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37700001",
    TOTAL_SUMINS_MAX: "37800000",
    PLAN_NET_PREMIUM_AMT: "35223.49",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2475.51",
    PLAN_TOTAL_PREMIUM: "37840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37800001",
    TOTAL_SUMINS_MAX: "37900000",
    PLAN_NET_PREMIUM_AMT: "35297.25",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2480.75",
    PLAN_TOTAL_PREMIUM: "37920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37900001",
    TOTAL_SUMINS_MAX: "38000000",
    PLAN_NET_PREMIUM_AMT: "35372.02",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2485.98",
    PLAN_TOTAL_PREMIUM: "38000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38000001",
    TOTAL_SUMINS_MAX: "38100000",
    PLAN_NET_PREMIUM_AMT: "35446.78",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2491.22",
    PLAN_TOTAL_PREMIUM: "38080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38100001",
    TOTAL_SUMINS_MAX: "38200000",
    PLAN_NET_PREMIUM_AMT: "35520.55",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2496.45",
    PLAN_TOTAL_PREMIUM: "38160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38200001",
    TOTAL_SUMINS_MAX: "38300000",
    PLAN_NET_PREMIUM_AMT: "35595.32",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2501.68",
    PLAN_TOTAL_PREMIUM: "38240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38300001",
    TOTAL_SUMINS_MAX: "38400000",
    PLAN_NET_PREMIUM_AMT: "35670.08",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2506.92",
    PLAN_TOTAL_PREMIUM: "38320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38400001",
    TOTAL_SUMINS_MAX: "38500000",
    PLAN_NET_PREMIUM_AMT: "35744.85",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2512.15",
    PLAN_TOTAL_PREMIUM: "38400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38500001",
    TOTAL_SUMINS_MAX: "38600000",
    PLAN_NET_PREMIUM_AMT: "35818.62",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2517.38",
    PLAN_TOTAL_PREMIUM: "38480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38600001",
    TOTAL_SUMINS_MAX: "38700000",
    PLAN_NET_PREMIUM_AMT: "35893.38",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2522.62",
    PLAN_TOTAL_PREMIUM: "38560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38700001",
    TOTAL_SUMINS_MAX: "38800000",
    PLAN_NET_PREMIUM_AMT: "35968.15",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2527.85",
    PLAN_TOTAL_PREMIUM: "38640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38800001",
    TOTAL_SUMINS_MAX: "38900000",
    PLAN_NET_PREMIUM_AMT: "36041.92",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2533.08",
    PLAN_TOTAL_PREMIUM: "38720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38900001",
    TOTAL_SUMINS_MAX: "39000000",
    PLAN_NET_PREMIUM_AMT: "36116.68",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2538.32",
    PLAN_TOTAL_PREMIUM: "38800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39000001",
    TOTAL_SUMINS_MAX: "39100000",
    PLAN_NET_PREMIUM_AMT: "36191.45",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2543.55",
    PLAN_TOTAL_PREMIUM: "38880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39100001",
    TOTAL_SUMINS_MAX: "39200000",
    PLAN_NET_PREMIUM_AMT: "36265.21",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2548.79",
    PLAN_TOTAL_PREMIUM: "38960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39200001",
    TOTAL_SUMINS_MAX: "39300000",
    PLAN_NET_PREMIUM_AMT: "36339.98",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2554.02",
    PLAN_TOTAL_PREMIUM: "39040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39300001",
    TOTAL_SUMINS_MAX: "39400000",
    PLAN_NET_PREMIUM_AMT: "36414.75",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2559.25",
    PLAN_TOTAL_PREMIUM: "39120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39400001",
    TOTAL_SUMINS_MAX: "39500000",
    PLAN_NET_PREMIUM_AMT: "36489.51",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2564.49",
    PLAN_TOTAL_PREMIUM: "39200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39500001",
    TOTAL_SUMINS_MAX: "39600000",
    PLAN_NET_PREMIUM_AMT: "36563.28",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2569.72",
    PLAN_TOTAL_PREMIUM: "39280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39600001",
    TOTAL_SUMINS_MAX: "39700000",
    PLAN_NET_PREMIUM_AMT: "36638.05",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2574.95",
    PLAN_TOTAL_PREMIUM: "39360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39700001",
    TOTAL_SUMINS_MAX: "39800000",
    PLAN_NET_PREMIUM_AMT: "36712.81",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2580.19",
    PLAN_TOTAL_PREMIUM: "39440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39800001",
    TOTAL_SUMINS_MAX: "39900000",
    PLAN_NET_PREMIUM_AMT: "36786.58",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2585.42",
    PLAN_TOTAL_PREMIUM: "39520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39900001",
    TOTAL_SUMINS_MAX: "40000000",
    PLAN_NET_PREMIUM_AMT: "36861.35",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2590.65",
    PLAN_TOTAL_PREMIUM: "39600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40000001",
    TOTAL_SUMINS_MAX: "40100000",
    PLAN_NET_PREMIUM_AMT: "36936.11",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2595.89",
    PLAN_TOTAL_PREMIUM: "39680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40100001",
    TOTAL_SUMINS_MAX: "40200000",
    PLAN_NET_PREMIUM_AMT: "37009.88",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2601.12",
    PLAN_TOTAL_PREMIUM: "39760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40200001",
    TOTAL_SUMINS_MAX: "40300000",
    PLAN_NET_PREMIUM_AMT: "37084.64",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2606.36",
    PLAN_TOTAL_PREMIUM: "39840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40300001",
    TOTAL_SUMINS_MAX: "40400000",
    PLAN_NET_PREMIUM_AMT: "37159.41",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2611.59",
    PLAN_TOTAL_PREMIUM: "39920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40400001",
    TOTAL_SUMINS_MAX: "40500000",
    PLAN_NET_PREMIUM_AMT: "37234.18",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2616.82",
    PLAN_TOTAL_PREMIUM: "40000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40500001",
    TOTAL_SUMINS_MAX: "40600000",
    PLAN_NET_PREMIUM_AMT: "37307.94",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2622.06",
    PLAN_TOTAL_PREMIUM: "40080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40600001",
    TOTAL_SUMINS_MAX: "40700000",
    PLAN_NET_PREMIUM_AMT: "37382.71",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2627.29",
    PLAN_TOTAL_PREMIUM: "40160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40700001",
    TOTAL_SUMINS_MAX: "40800000",
    PLAN_NET_PREMIUM_AMT: "37457.48",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2632.52",
    PLAN_TOTAL_PREMIUM: "40240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40800001",
    TOTAL_SUMINS_MAX: "40900000",
    PLAN_NET_PREMIUM_AMT: "37531.24",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2637.76",
    PLAN_TOTAL_PREMIUM: "40320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40900001",
    TOTAL_SUMINS_MAX: "41000000",
    PLAN_NET_PREMIUM_AMT: "37606.01",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2642.99",
    PLAN_TOTAL_PREMIUM: "40400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41000001",
    TOTAL_SUMINS_MAX: "41100000",
    PLAN_NET_PREMIUM_AMT: "37680.78",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2648.22",
    PLAN_TOTAL_PREMIUM: "40480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41100001",
    TOTAL_SUMINS_MAX: "41200000",
    PLAN_NET_PREMIUM_AMT: "37754.54",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2653.46",
    PLAN_TOTAL_PREMIUM: "40560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41200001",
    TOTAL_SUMINS_MAX: "41300000",
    PLAN_NET_PREMIUM_AMT: "37829.31",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2658.69",
    PLAN_TOTAL_PREMIUM: "40640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41300001",
    TOTAL_SUMINS_MAX: "41400000",
    PLAN_NET_PREMIUM_AMT: "37904.07",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2663.93",
    PLAN_TOTAL_PREMIUM: "40720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41400001",
    TOTAL_SUMINS_MAX: "41500000",
    PLAN_NET_PREMIUM_AMT: "37978.84",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2669.16",
    PLAN_TOTAL_PREMIUM: "40800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41500001",
    TOTAL_SUMINS_MAX: "41600000",
    PLAN_NET_PREMIUM_AMT: "38052.61",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2674.39",
    PLAN_TOTAL_PREMIUM: "40880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41600001",
    TOTAL_SUMINS_MAX: "41700000",
    PLAN_NET_PREMIUM_AMT: "38127.37",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2679.63",
    PLAN_TOTAL_PREMIUM: "40960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41700001",
    TOTAL_SUMINS_MAX: "41800000",
    PLAN_NET_PREMIUM_AMT: "38202.14",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2684.86",
    PLAN_TOTAL_PREMIUM: "41040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41800001",
    TOTAL_SUMINS_MAX: "41900000",
    PLAN_NET_PREMIUM_AMT: "38275.91",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2690.09",
    PLAN_TOTAL_PREMIUM: "41120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41900001",
    TOTAL_SUMINS_MAX: "42000000",
    PLAN_NET_PREMIUM_AMT: "38350.67",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2695.33",
    PLAN_TOTAL_PREMIUM: "41200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42000001",
    TOTAL_SUMINS_MAX: "42100000",
    PLAN_NET_PREMIUM_AMT: "38425.44",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2700.56",
    PLAN_TOTAL_PREMIUM: "41280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42100001",
    TOTAL_SUMINS_MAX: "42200000",
    PLAN_NET_PREMIUM_AMT: "38499.21",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2705.79",
    PLAN_TOTAL_PREMIUM: "41360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42200001",
    TOTAL_SUMINS_MAX: "42300000",
    PLAN_NET_PREMIUM_AMT: "38573.97",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2711.03",
    PLAN_TOTAL_PREMIUM: "41440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42300001",
    TOTAL_SUMINS_MAX: "42400000",
    PLAN_NET_PREMIUM_AMT: "38648.74",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2716.26",
    PLAN_TOTAL_PREMIUM: "41520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42400001",
    TOTAL_SUMINS_MAX: "42500000",
    PLAN_NET_PREMIUM_AMT: "38723.50",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2721.50",
    PLAN_TOTAL_PREMIUM: "41600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42500001",
    TOTAL_SUMINS_MAX: "42600000",
    PLAN_NET_PREMIUM_AMT: "38797.27",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2726.73",
    PLAN_TOTAL_PREMIUM: "41680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42600001",
    TOTAL_SUMINS_MAX: "42700000",
    PLAN_NET_PREMIUM_AMT: "38872.04",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2731.96",
    PLAN_TOTAL_PREMIUM: "41760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42700001",
    TOTAL_SUMINS_MAX: "42800000",
    PLAN_NET_PREMIUM_AMT: "38946.80",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2737.20",
    PLAN_TOTAL_PREMIUM: "41840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42800001",
    TOTAL_SUMINS_MAX: "42900000",
    PLAN_NET_PREMIUM_AMT: "39020.57",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2742.43",
    PLAN_TOTAL_PREMIUM: "41920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42900001",
    TOTAL_SUMINS_MAX: "43000000",
    PLAN_NET_PREMIUM_AMT: "39095.34",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2747.66",
    PLAN_TOTAL_PREMIUM: "42000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43000001",
    TOTAL_SUMINS_MAX: "43100000",
    PLAN_NET_PREMIUM_AMT: "39170.10",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2752.90",
    PLAN_TOTAL_PREMIUM: "42080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43100001",
    TOTAL_SUMINS_MAX: "43200000",
    PLAN_NET_PREMIUM_AMT: "39244.87",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2758.13",
    PLAN_TOTAL_PREMIUM: "42160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43200001",
    TOTAL_SUMINS_MAX: "43300000",
    PLAN_NET_PREMIUM_AMT: "39318.64",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2763.36",
    PLAN_TOTAL_PREMIUM: "42240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43300001",
    TOTAL_SUMINS_MAX: "43400000",
    PLAN_NET_PREMIUM_AMT: "39393.40",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2768.60",
    PLAN_TOTAL_PREMIUM: "42320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43400001",
    TOTAL_SUMINS_MAX: "43500000",
    PLAN_NET_PREMIUM_AMT: "39468.17",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2773.83",
    PLAN_TOTAL_PREMIUM: "42400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43500001",
    TOTAL_SUMINS_MAX: "43600000",
    PLAN_NET_PREMIUM_AMT: "39541.93",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2779.07",
    PLAN_TOTAL_PREMIUM: "42480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43600001",
    TOTAL_SUMINS_MAX: "43700000",
    PLAN_NET_PREMIUM_AMT: "39616.70",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2784.30",
    PLAN_TOTAL_PREMIUM: "42560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43700001",
    TOTAL_SUMINS_MAX: "43800000",
    PLAN_NET_PREMIUM_AMT: "39691.47",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2789.53",
    PLAN_TOTAL_PREMIUM: "42640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43800001",
    TOTAL_SUMINS_MAX: "43900000",
    PLAN_NET_PREMIUM_AMT: "39765.23",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2794.77",
    PLAN_TOTAL_PREMIUM: "42720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43900001",
    TOTAL_SUMINS_MAX: "44000000",
    PLAN_NET_PREMIUM_AMT: "39840.00",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2800.00",
    PLAN_TOTAL_PREMIUM: "42800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44000001",
    TOTAL_SUMINS_MAX: "44100000",
    PLAN_NET_PREMIUM_AMT: "39914.77",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2805.23",
    PLAN_TOTAL_PREMIUM: "42880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44100001",
    TOTAL_SUMINS_MAX: "44200000",
    PLAN_NET_PREMIUM_AMT: "39989.53",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2810.47",
    PLAN_TOTAL_PREMIUM: "42960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44200001",
    TOTAL_SUMINS_MAX: "44300000",
    PLAN_NET_PREMIUM_AMT: "40063.30",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2815.70",
    PLAN_TOTAL_PREMIUM: "43040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44300001",
    TOTAL_SUMINS_MAX: "44400000",
    PLAN_NET_PREMIUM_AMT: "40138.07",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2820.93",
    PLAN_TOTAL_PREMIUM: "43120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44400001",
    TOTAL_SUMINS_MAX: "44500000",
    PLAN_NET_PREMIUM_AMT: "40212.83",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2826.17",
    PLAN_TOTAL_PREMIUM: "43200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44500001",
    TOTAL_SUMINS_MAX: "44600000",
    PLAN_NET_PREMIUM_AMT: "40286.60",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2831.40",
    PLAN_TOTAL_PREMIUM: "43280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44600001",
    TOTAL_SUMINS_MAX: "44700000",
    PLAN_NET_PREMIUM_AMT: "40361.36",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2836.64",
    PLAN_TOTAL_PREMIUM: "43360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44700001",
    TOTAL_SUMINS_MAX: "44800000",
    PLAN_NET_PREMIUM_AMT: "40436.13",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2841.87",
    PLAN_TOTAL_PREMIUM: "43440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44800001",
    TOTAL_SUMINS_MAX: "44900000",
    PLAN_NET_PREMIUM_AMT: "40509.90",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2847.10",
    PLAN_TOTAL_PREMIUM: "43520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44900001",
    TOTAL_SUMINS_MAX: "45000000",
    PLAN_NET_PREMIUM_AMT: "40584.66",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2852.34",
    PLAN_TOTAL_PREMIUM: "43600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45000001",
    TOTAL_SUMINS_MAX: "45100000",
    PLAN_NET_PREMIUM_AMT: "40659.43",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2857.57",
    PLAN_TOTAL_PREMIUM: "43680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45100001",
    TOTAL_SUMINS_MAX: "45200000",
    PLAN_NET_PREMIUM_AMT: "40734.20",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2862.80",
    PLAN_TOTAL_PREMIUM: "43760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45200001",
    TOTAL_SUMINS_MAX: "45300000",
    PLAN_NET_PREMIUM_AMT: "40807.96",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2868.04",
    PLAN_TOTAL_PREMIUM: "43840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45300001",
    TOTAL_SUMINS_MAX: "45400000",
    PLAN_NET_PREMIUM_AMT: "40882.73",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2873.27",
    PLAN_TOTAL_PREMIUM: "43920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45400001",
    TOTAL_SUMINS_MAX: "45500000",
    PLAN_NET_PREMIUM_AMT: "40957.49",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2878.51",
    PLAN_TOTAL_PREMIUM: "44000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45500001",
    TOTAL_SUMINS_MAX: "45600000",
    PLAN_NET_PREMIUM_AMT: "41031.26",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2883.74",
    PLAN_TOTAL_PREMIUM: "44080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45600001",
    TOTAL_SUMINS_MAX: "45700000",
    PLAN_NET_PREMIUM_AMT: "41106.03",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2888.97",
    PLAN_TOTAL_PREMIUM: "44160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45700001",
    TOTAL_SUMINS_MAX: "45800000",
    PLAN_NET_PREMIUM_AMT: "41180.79",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2894.21",
    PLAN_TOTAL_PREMIUM: "44240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45800001",
    TOTAL_SUMINS_MAX: "45900000",
    PLAN_NET_PREMIUM_AMT: "41254.56",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2899.44",
    PLAN_TOTAL_PREMIUM: "44320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45900001",
    TOTAL_SUMINS_MAX: "46000000",
    PLAN_NET_PREMIUM_AMT: "41329.33",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2904.67",
    PLAN_TOTAL_PREMIUM: "44400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46000001",
    TOTAL_SUMINS_MAX: "46100000",
    PLAN_NET_PREMIUM_AMT: "41404.09",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2909.91",
    PLAN_TOTAL_PREMIUM: "44480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46100001",
    TOTAL_SUMINS_MAX: "46200000",
    PLAN_NET_PREMIUM_AMT: "41478.86",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2915.14",
    PLAN_TOTAL_PREMIUM: "44560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46200001",
    TOTAL_SUMINS_MAX: "46300000",
    PLAN_NET_PREMIUM_AMT: "41552.63",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2920.37",
    PLAN_TOTAL_PREMIUM: "44640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46300001",
    TOTAL_SUMINS_MAX: "46400000",
    PLAN_NET_PREMIUM_AMT: "41627.39",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2925.61",
    PLAN_TOTAL_PREMIUM: "44720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46400001",
    TOTAL_SUMINS_MAX: "46500000",
    PLAN_NET_PREMIUM_AMT: "41702.16",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2930.84",
    PLAN_TOTAL_PREMIUM: "44800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46500001",
    TOTAL_SUMINS_MAX: "46600000",
    PLAN_NET_PREMIUM_AMT: "41775.92",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2936.08",
    PLAN_TOTAL_PREMIUM: "44880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46600001",
    TOTAL_SUMINS_MAX: "46700000",
    PLAN_NET_PREMIUM_AMT: "41850.69",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2941.31",
    PLAN_TOTAL_PREMIUM: "44960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46700001",
    TOTAL_SUMINS_MAX: "46800000",
    PLAN_NET_PREMIUM_AMT: "41925.46",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2946.54",
    PLAN_TOTAL_PREMIUM: "45040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46800001",
    TOTAL_SUMINS_MAX: "46900000",
    PLAN_NET_PREMIUM_AMT: "41999.22",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2951.78",
    PLAN_TOTAL_PREMIUM: "45120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46900001",
    TOTAL_SUMINS_MAX: "47000000",
    PLAN_NET_PREMIUM_AMT: "42073.99",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2957.01",
    PLAN_TOTAL_PREMIUM: "45200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47000001",
    TOTAL_SUMINS_MAX: "47100000",
    PLAN_NET_PREMIUM_AMT: "42148.76",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2962.24",
    PLAN_TOTAL_PREMIUM: "45280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47100001",
    TOTAL_SUMINS_MAX: "47200000",
    PLAN_NET_PREMIUM_AMT: "42223.52",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2967.48",
    PLAN_TOTAL_PREMIUM: "45360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47200001",
    TOTAL_SUMINS_MAX: "47300000",
    PLAN_NET_PREMIUM_AMT: "42297.29",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2972.71",
    PLAN_TOTAL_PREMIUM: "45440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47300001",
    TOTAL_SUMINS_MAX: "47400000",
    PLAN_NET_PREMIUM_AMT: "42372.06",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2977.94",
    PLAN_TOTAL_PREMIUM: "45520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47400001",
    TOTAL_SUMINS_MAX: "47500000",
    PLAN_NET_PREMIUM_AMT: "42446.82",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2983.18",
    PLAN_TOTAL_PREMIUM: "45600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47500001",
    TOTAL_SUMINS_MAX: "47600000",
    PLAN_NET_PREMIUM_AMT: "42520.59",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2988.41",
    PLAN_TOTAL_PREMIUM: "45680.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47600001",
    TOTAL_SUMINS_MAX: "47700000",
    PLAN_NET_PREMIUM_AMT: "42595.35",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2993.65",
    PLAN_TOTAL_PREMIUM: "45760.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47700001",
    TOTAL_SUMINS_MAX: "47800000",
    PLAN_NET_PREMIUM_AMT: "42670.12",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2998.88",
    PLAN_TOTAL_PREMIUM: "45840.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47800001",
    TOTAL_SUMINS_MAX: "47900000",
    PLAN_NET_PREMIUM_AMT: "42744.89",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "3004.11",
    PLAN_TOTAL_PREMIUM: "45920.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47900001",
    TOTAL_SUMINS_MAX: "48000000",
    PLAN_NET_PREMIUM_AMT: "42818.65",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3009.35",
    PLAN_TOTAL_PREMIUM: "46000.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48000001",
    TOTAL_SUMINS_MAX: "48100000",
    PLAN_NET_PREMIUM_AMT: "42893.42",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3014.58",
    PLAN_TOTAL_PREMIUM: "46080.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48100001",
    TOTAL_SUMINS_MAX: "48200000",
    PLAN_NET_PREMIUM_AMT: "42968.19",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3019.81",
    PLAN_TOTAL_PREMIUM: "46160.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48200001",
    TOTAL_SUMINS_MAX: "48300000",
    PLAN_NET_PREMIUM_AMT: "43041.95",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3025.05",
    PLAN_TOTAL_PREMIUM: "46240.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48300001",
    TOTAL_SUMINS_MAX: "48400000",
    PLAN_NET_PREMIUM_AMT: "43116.72",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3030.28",
    PLAN_TOTAL_PREMIUM: "46320.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48400001",
    TOTAL_SUMINS_MAX: "48500000",
    PLAN_NET_PREMIUM_AMT: "43191.49",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3035.51",
    PLAN_TOTAL_PREMIUM: "46400.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48500001",
    TOTAL_SUMINS_MAX: "48600000",
    PLAN_NET_PREMIUM_AMT: "43265.25",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3040.75",
    PLAN_TOTAL_PREMIUM: "46480.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48600001",
    TOTAL_SUMINS_MAX: "48700000",
    PLAN_NET_PREMIUM_AMT: "43340.02",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3045.98",
    PLAN_TOTAL_PREMIUM: "46560.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48700001",
    TOTAL_SUMINS_MAX: "48800000",
    PLAN_NET_PREMIUM_AMT: "43414.78",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3051.22",
    PLAN_TOTAL_PREMIUM: "46640.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48800001",
    TOTAL_SUMINS_MAX: "48900000",
    PLAN_NET_PREMIUM_AMT: "43489.55",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3056.45",
    PLAN_TOTAL_PREMIUM: "46720.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48900001",
    TOTAL_SUMINS_MAX: "49000000",
    PLAN_NET_PREMIUM_AMT: "43563.32",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3061.68",
    PLAN_TOTAL_PREMIUM: "46800.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49000001",
    TOTAL_SUMINS_MAX: "49100000",
    PLAN_NET_PREMIUM_AMT: "43638.08",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3066.92",
    PLAN_TOTAL_PREMIUM: "46880.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49100001",
    TOTAL_SUMINS_MAX: "49200000",
    PLAN_NET_PREMIUM_AMT: "43712.85",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3072.15",
    PLAN_TOTAL_PREMIUM: "46960.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49200001",
    TOTAL_SUMINS_MAX: "49300000",
    PLAN_NET_PREMIUM_AMT: "43786.62",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3077.38",
    PLAN_TOTAL_PREMIUM: "47040.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49300001",
    TOTAL_SUMINS_MAX: "49400000",
    PLAN_NET_PREMIUM_AMT: "43861.38",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3082.62",
    PLAN_TOTAL_PREMIUM: "47120.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49400001",
    TOTAL_SUMINS_MAX: "49500000",
    PLAN_NET_PREMIUM_AMT: "43936.15",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3087.85",
    PLAN_TOTAL_PREMIUM: "47200.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49500001",
    TOTAL_SUMINS_MAX: "49600000",
    PLAN_NET_PREMIUM_AMT: "44009.92",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3093.08",
    PLAN_TOTAL_PREMIUM: "47280.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49600001",
    TOTAL_SUMINS_MAX: "49700000",
    PLAN_NET_PREMIUM_AMT: "44084.68",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3098.32",
    PLAN_TOTAL_PREMIUM: "47360.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49700001",
    TOTAL_SUMINS_MAX: "49800000",
    PLAN_NET_PREMIUM_AMT: "44159.45",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3103.55",
    PLAN_TOTAL_PREMIUM: "47440.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49800001",
    TOTAL_SUMINS_MAX: "49900000",
    PLAN_NET_PREMIUM_AMT: "44234.21",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3108.79",
    PLAN_TOTAL_PREMIUM: "47520.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-50000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49900001",
    TOTAL_SUMINS_MAX: "50000000",
    PLAN_NET_PREMIUM_AMT: "44307.98",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3114.02",
    PLAN_TOTAL_PREMIUM: "47600.00",
    INDUSTRY_CODE: "SER",
    INDUSTRY_DESC: "Service"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "0",
    TOTAL_SUMINS_MAX: "500000",
    PLAN_NET_PREMIUM_AMT: "2080.05",
    PLAN_STAMP_DUTY_AMT: "9.00",
    PLAN_VAT_AMT: "146.23",
    PLAN_TOTAL_PREMIUM: "2235.28",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "500001",
    TOTAL_SUMINS_MAX: "600000",
    PLAN_NET_PREMIUM_AMT: "2201.84",
    PLAN_STAMP_DUTY_AMT: "9.00",
    PLAN_VAT_AMT: "154.76",
    PLAN_TOTAL_PREMIUM: "2365.60",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "600001",
    TOTAL_SUMINS_MAX: "700000",
    PLAN_NET_PREMIUM_AMT: "2322.63",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "163.28",
    PLAN_TOTAL_PREMIUM: "2495.91",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "700001",
    TOTAL_SUMINS_MAX: "800000",
    PLAN_NET_PREMIUM_AMT: "2444.42",
    PLAN_STAMP_DUTY_AMT: "10.00",
    PLAN_VAT_AMT: "171.81",
    PLAN_TOTAL_PREMIUM: "2626.23",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "800001",
    TOTAL_SUMINS_MAX: "900000",
    PLAN_NET_PREMIUM_AMT: "2565.21",
    PLAN_STAMP_DUTY_AMT: "11.00",
    PLAN_VAT_AMT: "180.33",
    PLAN_TOTAL_PREMIUM: "2756.54",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "900001",
    TOTAL_SUMINS_MAX: "1000000",
    PLAN_NET_PREMIUM_AMT: "2687.00",
    PLAN_STAMP_DUTY_AMT: "11.00",
    PLAN_VAT_AMT: "188.86",
    PLAN_TOTAL_PREMIUM: "2886.86",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1000001",
    TOTAL_SUMINS_MAX: "1100000",
    PLAN_NET_PREMIUM_AMT: "2846.17",
    PLAN_STAMP_DUTY_AMT: "12.00",
    PLAN_VAT_AMT: "200.07",
    PLAN_TOTAL_PREMIUM: "3058.24",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1100001",
    TOTAL_SUMINS_MAX: "1200000",
    PLAN_NET_PREMIUM_AMT: "3005.34",
    PLAN_STAMP_DUTY_AMT: "13.00",
    PLAN_VAT_AMT: "211.28",
    PLAN_TOTAL_PREMIUM: "3229.62",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1200001",
    TOTAL_SUMINS_MAX: "1300000",
    PLAN_NET_PREMIUM_AMT: "3165.51",
    PLAN_STAMP_DUTY_AMT: "13.00",
    PLAN_VAT_AMT: "222.50",
    PLAN_TOTAL_PREMIUM: "3401.01",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1300001",
    TOTAL_SUMINS_MAX: "1400000",
    PLAN_NET_PREMIUM_AMT: "3324.68",
    PLAN_STAMP_DUTY_AMT: "14.00",
    PLAN_VAT_AMT: "233.71",
    PLAN_TOTAL_PREMIUM: "3572.39",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1400001",
    TOTAL_SUMINS_MAX: "1500000",
    PLAN_NET_PREMIUM_AMT: "3484.85",
    PLAN_STAMP_DUTY_AMT: "14.00",
    PLAN_VAT_AMT: "244.92",
    PLAN_TOTAL_PREMIUM: "3743.77",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1500001",
    TOTAL_SUMINS_MAX: "1600000",
    PLAN_NET_PREMIUM_AMT: "3644.21",
    PLAN_STAMP_DUTY_AMT: "15.00",
    PLAN_VAT_AMT: "256.14",
    PLAN_TOTAL_PREMIUM: "3915.35",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1600001",
    TOTAL_SUMINS_MAX: "1700000",
    PLAN_NET_PREMIUM_AMT: "3803.57",
    PLAN_STAMP_DUTY_AMT: "16.00",
    PLAN_VAT_AMT: "267.37",
    PLAN_TOTAL_PREMIUM: "4086.94",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1700001",
    TOTAL_SUMINS_MAX: "1800000",
    PLAN_NET_PREMIUM_AMT: "3963.93",
    PLAN_STAMP_DUTY_AMT: "16.00",
    PLAN_VAT_AMT: "278.60",
    PLAN_TOTAL_PREMIUM: "4258.53",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-1900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1800001",
    TOTAL_SUMINS_MAX: "1900000",
    PLAN_NET_PREMIUM_AMT: "4123.29",
    PLAN_STAMP_DUTY_AMT: "17.00",
    PLAN_VAT_AMT: "289.82",
    PLAN_TOTAL_PREMIUM: "4430.11",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "1900001",
    TOTAL_SUMINS_MAX: "2000000",
    PLAN_NET_PREMIUM_AMT: "4282.65",
    PLAN_STAMP_DUTY_AMT: "18.00",
    PLAN_VAT_AMT: "301.05",
    PLAN_TOTAL_PREMIUM: "4601.70",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2000001",
    TOTAL_SUMINS_MAX: "2100000",
    PLAN_NET_PREMIUM_AMT: "4442.63",
    PLAN_STAMP_DUTY_AMT: "18.00",
    PLAN_VAT_AMT: "312.24",
    PLAN_TOTAL_PREMIUM: "4772.87",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2100001",
    TOTAL_SUMINS_MAX: "2200000",
    PLAN_NET_PREMIUM_AMT: "4601.61",
    PLAN_STAMP_DUTY_AMT: "19.00",
    PLAN_VAT_AMT: "323.44",
    PLAN_TOTAL_PREMIUM: "4944.05",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2200001",
    TOTAL_SUMINS_MAX: "2300000",
    PLAN_NET_PREMIUM_AMT: "4760.59",
    PLAN_STAMP_DUTY_AMT: "20.00",
    PLAN_VAT_AMT: "334.64",
    PLAN_TOTAL_PREMIUM: "5115.23",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2300001",
    TOTAL_SUMINS_MAX: "2400000",
    PLAN_NET_PREMIUM_AMT: "4920.57",
    PLAN_STAMP_DUTY_AMT: "20.00",
    PLAN_VAT_AMT: "345.84",
    PLAN_TOTAL_PREMIUM: "5286.41",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2400001",
    TOTAL_SUMINS_MAX: "2500000",
    PLAN_NET_PREMIUM_AMT: "5079.55",
    PLAN_STAMP_DUTY_AMT: "21.00",
    PLAN_VAT_AMT: "357.04",
    PLAN_TOTAL_PREMIUM: "5457.59",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2500001",
    TOTAL_SUMINS_MAX: "2600000",
    PLAN_NET_PREMIUM_AMT: "5239.72",
    PLAN_STAMP_DUTY_AMT: "21.00",
    PLAN_VAT_AMT: "368.25",
    PLAN_TOTAL_PREMIUM: "5628.97",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2600001",
    TOTAL_SUMINS_MAX: "2700000",
    PLAN_NET_PREMIUM_AMT: "5398.89",
    PLAN_STAMP_DUTY_AMT: "22.00",
    PLAN_VAT_AMT: "379.46",
    PLAN_TOTAL_PREMIUM: "5800.35",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2700001",
    TOTAL_SUMINS_MAX: "2800000",
    PLAN_NET_PREMIUM_AMT: "5558.06",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "390.67",
    PLAN_TOTAL_PREMIUM: "5971.73",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-2900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2800001",
    TOTAL_SUMINS_MAX: "2900000",
    PLAN_NET_PREMIUM_AMT: "5718.23",
    PLAN_STAMP_DUTY_AMT: "23.00",
    PLAN_VAT_AMT: "401.89",
    PLAN_TOTAL_PREMIUM: "6143.12",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "2900001",
    TOTAL_SUMINS_MAX: "3000000",
    PLAN_NET_PREMIUM_AMT: "5877.40",
    PLAN_STAMP_DUTY_AMT: "24.00",
    PLAN_VAT_AMT: "413.10",
    PLAN_TOTAL_PREMIUM: "6314.50",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3000001",
    TOTAL_SUMINS_MAX: "3100000",
    PLAN_NET_PREMIUM_AMT: "6036.57",
    PLAN_STAMP_DUTY_AMT: "25.00",
    PLAN_VAT_AMT: "424.31",
    PLAN_TOTAL_PREMIUM: "6485.88",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3100001",
    TOTAL_SUMINS_MAX: "3200000",
    PLAN_NET_PREMIUM_AMT: "6196.74",
    PLAN_STAMP_DUTY_AMT: "25.00",
    PLAN_VAT_AMT: "435.52",
    PLAN_TOTAL_PREMIUM: "6657.26",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3200001",
    TOTAL_SUMINS_MAX: "3300000",
    PLAN_NET_PREMIUM_AMT: "6355.91",
    PLAN_STAMP_DUTY_AMT: "26.00",
    PLAN_VAT_AMT: "446.73",
    PLAN_TOTAL_PREMIUM: "6828.64",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3300001",
    TOTAL_SUMINS_MAX: "3400000",
    PLAN_NET_PREMIUM_AMT: "6515.08",
    PLAN_STAMP_DUTY_AMT: "27.00",
    PLAN_VAT_AMT: "457.95",
    PLAN_TOTAL_PREMIUM: "7000.03",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3400001",
    TOTAL_SUMINS_MAX: "3500000",
    PLAN_NET_PREMIUM_AMT: "6675.25",
    PLAN_STAMP_DUTY_AMT: "27.00",
    PLAN_VAT_AMT: "469.16",
    PLAN_TOTAL_PREMIUM: "7171.41",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3500001",
    TOTAL_SUMINS_MAX: "3600000",
    PLAN_NET_PREMIUM_AMT: "6834.42",
    PLAN_STAMP_DUTY_AMT: "28.00",
    PLAN_VAT_AMT: "480.37",
    PLAN_TOTAL_PREMIUM: "7342.79",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3600001",
    TOTAL_SUMINS_MAX: "3700000",
    PLAN_NET_PREMIUM_AMT: "6994.59",
    PLAN_STAMP_DUTY_AMT: "28.00",
    PLAN_VAT_AMT: "491.58",
    PLAN_TOTAL_PREMIUM: "7514.17",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3700001",
    TOTAL_SUMINS_MAX: "3800000",
    PLAN_NET_PREMIUM_AMT: "7153.76",
    PLAN_STAMP_DUTY_AMT: "29.00",
    PLAN_VAT_AMT: "502.79",
    PLAN_TOTAL_PREMIUM: "7685.55",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-3900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3800001",
    TOTAL_SUMINS_MAX: "3900000",
    PLAN_NET_PREMIUM_AMT: "7312.93",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "514.01",
    PLAN_TOTAL_PREMIUM: "7856.94",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "3900001",
    TOTAL_SUMINS_MAX: "4000000",
    PLAN_NET_PREMIUM_AMT: "7473.10",
    PLAN_STAMP_DUTY_AMT: "30.00",
    PLAN_VAT_AMT: "525.22",
    PLAN_TOTAL_PREMIUM: "8028.32",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4000001",
    TOTAL_SUMINS_MAX: "4100000",
    PLAN_NET_PREMIUM_AMT: "7632.27",
    PLAN_STAMP_DUTY_AMT: "31.00",
    PLAN_VAT_AMT: "536.43",
    PLAN_TOTAL_PREMIUM: "8199.70",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4100001",
    TOTAL_SUMINS_MAX: "4200000",
    PLAN_NET_PREMIUM_AMT: "7791.44",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "547.64",
    PLAN_TOTAL_PREMIUM: "8371.08",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4200001",
    TOTAL_SUMINS_MAX: "4300000",
    PLAN_NET_PREMIUM_AMT: "7951.61",
    PLAN_STAMP_DUTY_AMT: "32.00",
    PLAN_VAT_AMT: "558.85",
    PLAN_TOTAL_PREMIUM: "8542.46",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4300001",
    TOTAL_SUMINS_MAX: "4400000",
    PLAN_NET_PREMIUM_AMT: "8110.78",
    PLAN_STAMP_DUTY_AMT: "33.00",
    PLAN_VAT_AMT: "570.06",
    PLAN_TOTAL_PREMIUM: "8713.84",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4400001",
    TOTAL_SUMINS_MAX: "4500000",
    PLAN_NET_PREMIUM_AMT: "8269.95",
    PLAN_STAMP_DUTY_AMT: "34.00",
    PLAN_VAT_AMT: "581.28",
    PLAN_TOTAL_PREMIUM: "8885.23",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4500001",
    TOTAL_SUMINS_MAX: "4600000",
    PLAN_NET_PREMIUM_AMT: "8430.12",
    PLAN_STAMP_DUTY_AMT: "34.00",
    PLAN_VAT_AMT: "592.49",
    PLAN_TOTAL_PREMIUM: "9056.61",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4600001",
    TOTAL_SUMINS_MAX: "4700000",
    PLAN_NET_PREMIUM_AMT: "8589.29",
    PLAN_STAMP_DUTY_AMT: "35.00",
    PLAN_VAT_AMT: "603.70",
    PLAN_TOTAL_PREMIUM: "9227.99",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4700001",
    TOTAL_SUMINS_MAX: "4800000",
    PLAN_NET_PREMIUM_AMT: "8749.46",
    PLAN_STAMP_DUTY_AMT: "35.00",
    PLAN_VAT_AMT: "614.91",
    PLAN_TOTAL_PREMIUM: "9399.37",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-4900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4800001",
    TOTAL_SUMINS_MAX: "4900000",
    PLAN_NET_PREMIUM_AMT: "8908.63",
    PLAN_STAMP_DUTY_AMT: "36.00",
    PLAN_VAT_AMT: "626.12",
    PLAN_TOTAL_PREMIUM: "9570.75",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "4900001",
    TOTAL_SUMINS_MAX: "5000000",
    PLAN_NET_PREMIUM_AMT: "9067.80",
    PLAN_STAMP_DUTY_AMT: "37.00",
    PLAN_VAT_AMT: "637.34",
    PLAN_TOTAL_PREMIUM: "9742.14",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5000001",
    TOTAL_SUMINS_MAX: "5100000",
    PLAN_NET_PREMIUM_AMT: "9334.89",
    PLAN_STAMP_DUTY_AMT: "38.00",
    PLAN_VAT_AMT: "656.10",
    PLAN_TOTAL_PREMIUM: "10028.99",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5100001",
    TOTAL_SUMINS_MAX: "5200000",
    PLAN_NET_PREMIUM_AMT: "9601.98",
    PLAN_STAMP_DUTY_AMT: "39.00",
    PLAN_VAT_AMT: "674.87",
    PLAN_TOTAL_PREMIUM: "10315.85",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5200001",
    TOTAL_SUMINS_MAX: "5300000",
    PLAN_NET_PREMIUM_AMT: "9869.07",
    PLAN_STAMP_DUTY_AMT: "40.00",
    PLAN_VAT_AMT: "693.63",
    PLAN_TOTAL_PREMIUM: "10602.70",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5300001",
    TOTAL_SUMINS_MAX: "5400000",
    PLAN_NET_PREMIUM_AMT: "10136.16",
    PLAN_STAMP_DUTY_AMT: "41.00",
    PLAN_VAT_AMT: "712.40",
    PLAN_TOTAL_PREMIUM: "10889.56",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5400001",
    TOTAL_SUMINS_MAX: "5500000",
    PLAN_NET_PREMIUM_AMT: "10403.25",
    PLAN_STAMP_DUTY_AMT: "42.00",
    PLAN_VAT_AMT: "731.17",
    PLAN_TOTAL_PREMIUM: "11176.42",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5500001",
    TOTAL_SUMINS_MAX: "5600000",
    PLAN_NET_PREMIUM_AMT: "10554.63",
    PLAN_STAMP_DUTY_AMT: "43.00",
    PLAN_VAT_AMT: "741.83",
    PLAN_TOTAL_PREMIUM: "11339.46",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5600001",
    TOTAL_SUMINS_MAX: "5700000",
    PLAN_NET_PREMIUM_AMT: "10707.00",
    PLAN_STAMP_DUTY_AMT: "43.00",
    PLAN_VAT_AMT: "752.51",
    PLAN_TOTAL_PREMIUM: "11502.51",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5700001",
    TOTAL_SUMINS_MAX: "5800000",
    PLAN_NET_PREMIUM_AMT: "10858.39",
    PLAN_STAMP_DUTY_AMT: "44.00",
    PLAN_VAT_AMT: "763.17",
    PLAN_TOTAL_PREMIUM: "11665.56",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-5900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5800001",
    TOTAL_SUMINS_MAX: "5900000",
    PLAN_NET_PREMIUM_AMT: "11009.77",
    PLAN_STAMP_DUTY_AMT: "45.00",
    PLAN_VAT_AMT: "773.83",
    PLAN_TOTAL_PREMIUM: "11828.60",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "5900001",
    TOTAL_SUMINS_MAX: "6000000",
    PLAN_NET_PREMIUM_AMT: "11162.15",
    PLAN_STAMP_DUTY_AMT: "45.00",
    PLAN_VAT_AMT: "784.50",
    PLAN_TOTAL_PREMIUM: "11991.65",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6000001",
    TOTAL_SUMINS_MAX: "6100000",
    PLAN_NET_PREMIUM_AMT: "11313.34",
    PLAN_STAMP_DUTY_AMT: "46.00",
    PLAN_VAT_AMT: "795.15",
    PLAN_TOTAL_PREMIUM: "12154.49",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6100001",
    TOTAL_SUMINS_MAX: "6200000",
    PLAN_NET_PREMIUM_AMT: "11465.53",
    PLAN_STAMP_DUTY_AMT: "46.00",
    PLAN_VAT_AMT: "805.81",
    PLAN_TOTAL_PREMIUM: "12317.34",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6200001",
    TOTAL_SUMINS_MAX: "6300000",
    PLAN_NET_PREMIUM_AMT: "11616.72",
    PLAN_STAMP_DUTY_AMT: "47.00",
    PLAN_VAT_AMT: "816.46",
    PLAN_TOTAL_PREMIUM: "12480.18",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6300001",
    TOTAL_SUMINS_MAX: "6400000",
    PLAN_NET_PREMIUM_AMT: "11767.91",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "827.11",
    PLAN_TOTAL_PREMIUM: "12643.02",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6400001",
    TOTAL_SUMINS_MAX: "6500000",
    PLAN_NET_PREMIUM_AMT: "11920.10",
    PLAN_STAMP_DUTY_AMT: "48.00",
    PLAN_VAT_AMT: "837.77",
    PLAN_TOTAL_PREMIUM: "12805.87",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6500001",
    TOTAL_SUMINS_MAX: "6600000",
    PLAN_NET_PREMIUM_AMT: "12081.55",
    PLAN_STAMP_DUTY_AMT: "49.00",
    PLAN_VAT_AMT: "849.14",
    PLAN_TOTAL_PREMIUM: "12979.69",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6600001",
    TOTAL_SUMINS_MAX: "6700000",
    PLAN_NET_PREMIUM_AMT: "12244.00",
    PLAN_STAMP_DUTY_AMT: "49.00",
    PLAN_VAT_AMT: "860.51",
    PLAN_TOTAL_PREMIUM: "13153.51",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6700001",
    TOTAL_SUMINS_MAX: "6800000",
    PLAN_NET_PREMIUM_AMT: "12405.45",
    PLAN_STAMP_DUTY_AMT: "50.00",
    PLAN_VAT_AMT: "871.88",
    PLAN_TOTAL_PREMIUM: "13327.33",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-6900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6800001",
    TOTAL_SUMINS_MAX: "6900000",
    PLAN_NET_PREMIUM_AMT: "12566.90",
    PLAN_STAMP_DUTY_AMT: "51.00",
    PLAN_VAT_AMT: "883.25",
    PLAN_TOTAL_PREMIUM: "13501.15",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "6900001",
    TOTAL_SUMINS_MAX: "7000000",
    PLAN_NET_PREMIUM_AMT: "12729.35",
    PLAN_STAMP_DUTY_AMT: "51.00",
    PLAN_VAT_AMT: "894.62",
    PLAN_TOTAL_PREMIUM: "13674.97",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7000001",
    TOTAL_SUMINS_MAX: "7100000",
    PLAN_NET_PREMIUM_AMT: "12891.18",
    PLAN_STAMP_DUTY_AMT: "52.00",
    PLAN_VAT_AMT: "906.02",
    PLAN_TOTAL_PREMIUM: "13849.20",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7100001",
    TOTAL_SUMINS_MAX: "7200000",
    PLAN_NET_PREMIUM_AMT: "13053.01",
    PLAN_STAMP_DUTY_AMT: "53.00",
    PLAN_VAT_AMT: "917.42",
    PLAN_TOTAL_PREMIUM: "14023.43",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7200001",
    TOTAL_SUMINS_MAX: "7300000",
    PLAN_NET_PREMIUM_AMT: "13215.84",
    PLAN_STAMP_DUTY_AMT: "53.00",
    PLAN_VAT_AMT: "928.82",
    PLAN_TOTAL_PREMIUM: "14197.66",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7300001",
    TOTAL_SUMINS_MAX: "7400000",
    PLAN_NET_PREMIUM_AMT: "13377.67",
    PLAN_STAMP_DUTY_AMT: "54.00",
    PLAN_VAT_AMT: "940.22",
    PLAN_TOTAL_PREMIUM: "14371.89",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7400001",
    TOTAL_SUMINS_MAX: "7500000",
    PLAN_NET_PREMIUM_AMT: "13539.50",
    PLAN_STAMP_DUTY_AMT: "55.00",
    PLAN_VAT_AMT: "951.62",
    PLAN_TOTAL_PREMIUM: "14546.12",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7500001",
    TOTAL_SUMINS_MAX: "7600000",
    PLAN_NET_PREMIUM_AMT: "13701.95",
    PLAN_STAMP_DUTY_AMT: "55.00",
    PLAN_VAT_AMT: "962.99",
    PLAN_TOTAL_PREMIUM: "14719.94",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7600001",
    TOTAL_SUMINS_MAX: "7700000",
    PLAN_NET_PREMIUM_AMT: "13863.40",
    PLAN_STAMP_DUTY_AMT: "56.00",
    PLAN_VAT_AMT: "974.36",
    PLAN_TOTAL_PREMIUM: "14893.76",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7700001",
    TOTAL_SUMINS_MAX: "7800000",
    PLAN_NET_PREMIUM_AMT: "14024.85",
    PLAN_STAMP_DUTY_AMT: "57.00",
    PLAN_VAT_AMT: "985.73",
    PLAN_TOTAL_PREMIUM: "15067.58",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-7900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7800001",
    TOTAL_SUMINS_MAX: "7900000",
    PLAN_NET_PREMIUM_AMT: "14187.30",
    PLAN_STAMP_DUTY_AMT: "57.00",
    PLAN_VAT_AMT: "997.10",
    PLAN_TOTAL_PREMIUM: "15241.40",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "7900001",
    TOTAL_SUMINS_MAX: "8000000",
    PLAN_NET_PREMIUM_AMT: "14348.75",
    PLAN_STAMP_DUTY_AMT: "58.00",
    PLAN_VAT_AMT: "1008.47",
    PLAN_TOTAL_PREMIUM: "15415.22",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8000001",
    TOTAL_SUMINS_MAX: "8100000",
    PLAN_NET_PREMIUM_AMT: "14510.39",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1019.86",
    PLAN_TOTAL_PREMIUM: "15589.25",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8100001",
    TOTAL_SUMINS_MAX: "8200000",
    PLAN_NET_PREMIUM_AMT: "14673.03",
    PLAN_STAMP_DUTY_AMT: "59.00",
    PLAN_VAT_AMT: "1031.24",
    PLAN_TOTAL_PREMIUM: "15763.27",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8200001",
    TOTAL_SUMINS_MAX: "8300000",
    PLAN_NET_PREMIUM_AMT: "14834.67",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1042.63",
    PLAN_TOTAL_PREMIUM: "15937.30",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8300001",
    TOTAL_SUMINS_MAX: "8400000",
    PLAN_NET_PREMIUM_AMT: "14997.31",
    PLAN_STAMP_DUTY_AMT: "60.00",
    PLAN_VAT_AMT: "1054.01",
    PLAN_TOTAL_PREMIUM: "16111.32",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8400001",
    TOTAL_SUMINS_MAX: "8500000",
    PLAN_NET_PREMIUM_AMT: "15158.95",
    PLAN_STAMP_DUTY_AMT: "61.00",
    PLAN_VAT_AMT: "1065.40",
    PLAN_TOTAL_PREMIUM: "16285.35",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8500001",
    TOTAL_SUMINS_MAX: "8600000",
    PLAN_NET_PREMIUM_AMT: "15294.75",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1074.97",
    PLAN_TOTAL_PREMIUM: "16431.72",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8600001",
    TOTAL_SUMINS_MAX: "8700000",
    PLAN_NET_PREMIUM_AMT: "15431.55",
    PLAN_STAMP_DUTY_AMT: "62.00",
    PLAN_VAT_AMT: "1084.55",
    PLAN_TOTAL_PREMIUM: "16578.10",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8700001",
    TOTAL_SUMINS_MAX: "8800000",
    PLAN_NET_PREMIUM_AMT: "15567.35",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1094.12",
    PLAN_TOTAL_PREMIUM: "16724.47",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-8900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8800001",
    TOTAL_SUMINS_MAX: "8900000",
    PLAN_NET_PREMIUM_AMT: "15704.15",
    PLAN_STAMP_DUTY_AMT: "63.00",
    PLAN_VAT_AMT: "1103.70",
    PLAN_TOTAL_PREMIUM: "16870.85",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "8900001",
    TOTAL_SUMINS_MAX: "9000000",
    PLAN_NET_PREMIUM_AMT: "15839.95",
    PLAN_STAMP_DUTY_AMT: "64.00",
    PLAN_VAT_AMT: "1113.28",
    PLAN_TOTAL_PREMIUM: "17017.23",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9000001",
    TOTAL_SUMINS_MAX: "9100000",
    PLAN_NET_PREMIUM_AMT: "16000.26",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1124.57",
    PLAN_TOTAL_PREMIUM: "17189.83",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9100001",
    TOTAL_SUMINS_MAX: "9200000",
    PLAN_NET_PREMIUM_AMT: "16161.57",
    PLAN_STAMP_DUTY_AMT: "65.00",
    PLAN_VAT_AMT: "1135.86",
    PLAN_TOTAL_PREMIUM: "17362.43",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9200001",
    TOTAL_SUMINS_MAX: "9300000",
    PLAN_NET_PREMIUM_AMT: "16321.88",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1147.15",
    PLAN_TOTAL_PREMIUM: "17535.03",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9300001",
    TOTAL_SUMINS_MAX: "9400000",
    PLAN_NET_PREMIUM_AMT: "16483.19",
    PLAN_STAMP_DUTY_AMT: "66.00",
    PLAN_VAT_AMT: "1158.44",
    PLAN_TOTAL_PREMIUM: "17707.63",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9400001",
    TOTAL_SUMINS_MAX: "9500000",
    PLAN_NET_PREMIUM_AMT: "16643.50",
    PLAN_STAMP_DUTY_AMT: "67.00",
    PLAN_VAT_AMT: "1169.74",
    PLAN_TOTAL_PREMIUM: "17880.24",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9500001",
    TOTAL_SUMINS_MAX: "9600000",
    PLAN_NET_PREMIUM_AMT: "16803.81",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1181.03",
    PLAN_TOTAL_PREMIUM: "18052.84",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9600001",
    TOTAL_SUMINS_MAX: "9700000",
    PLAN_NET_PREMIUM_AMT: "16965.12",
    PLAN_STAMP_DUTY_AMT: "68.00",
    PLAN_VAT_AMT: "1192.32",
    PLAN_TOTAL_PREMIUM: "18225.44",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9700001",
    TOTAL_SUMINS_MAX: "9800000",
    PLAN_NET_PREMIUM_AMT: "17125.43",
    PLAN_STAMP_DUTY_AMT: "69.00",
    PLAN_VAT_AMT: "1203.61",
    PLAN_TOTAL_PREMIUM: "18398.04",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-9900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9800001",
    TOTAL_SUMINS_MAX: "9900000",
    PLAN_NET_PREMIUM_AMT: "17285.74",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1214.90",
    PLAN_TOTAL_PREMIUM: "18570.64",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "9900001",
    TOTAL_SUMINS_MAX: "10000000",
    PLAN_NET_PREMIUM_AMT: "17447.05",
    PLAN_STAMP_DUTY_AMT: "70.00",
    PLAN_VAT_AMT: "1226.19",
    PLAN_TOTAL_PREMIUM: "18743.24",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10000001",
    TOTAL_SUMINS_MAX: "10100000",
    PLAN_NET_PREMIUM_AMT: "18226.07",
    PLAN_STAMP_DUTY_AMT: "73.00",
    PLAN_VAT_AMT: "1280.93",
    PLAN_TOTAL_PREMIUM: "19580.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10100001",
    TOTAL_SUMINS_MAX: "10200000",
    PLAN_NET_PREMIUM_AMT: "18323.20",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1287.80",
    PLAN_TOTAL_PREMIUM: "19685.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10200001",
    TOTAL_SUMINS_MAX: "10300000",
    PLAN_NET_PREMIUM_AMT: "18421.33",
    PLAN_STAMP_DUTY_AMT: "74.00",
    PLAN_VAT_AMT: "1294.67",
    PLAN_TOTAL_PREMIUM: "19790.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10300001",
    TOTAL_SUMINS_MAX: "10400000",
    PLAN_NET_PREMIUM_AMT: "18518.46",
    PLAN_STAMP_DUTY_AMT: "75.00",
    PLAN_VAT_AMT: "1301.54",
    PLAN_TOTAL_PREMIUM: "19895.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10400001",
    TOTAL_SUMINS_MAX: "10500000",
    PLAN_NET_PREMIUM_AMT: "18616.59",
    PLAN_STAMP_DUTY_AMT: "75.00",
    PLAN_VAT_AMT: "1308.41",
    PLAN_TOTAL_PREMIUM: "20000.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10500001",
    TOTAL_SUMINS_MAX: "10600000",
    PLAN_NET_PREMIUM_AMT: "18714.72",
    PLAN_STAMP_DUTY_AMT: "75.00",
    PLAN_VAT_AMT: "1315.28",
    PLAN_TOTAL_PREMIUM: "20105.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10600001",
    TOTAL_SUMINS_MAX: "10700000",
    PLAN_NET_PREMIUM_AMT: "18811.85",
    PLAN_STAMP_DUTY_AMT: "76.00",
    PLAN_VAT_AMT: "1322.15",
    PLAN_TOTAL_PREMIUM: "20210.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10700001",
    TOTAL_SUMINS_MAX: "10800000",
    PLAN_NET_PREMIUM_AMT: "18909.98",
    PLAN_STAMP_DUTY_AMT: "76.00",
    PLAN_VAT_AMT: "1329.02",
    PLAN_TOTAL_PREMIUM: "20315.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-10900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10800001",
    TOTAL_SUMINS_MAX: "10900000",
    PLAN_NET_PREMIUM_AMT: "19007.11",
    PLAN_STAMP_DUTY_AMT: "77.00",
    PLAN_VAT_AMT: "1335.89",
    PLAN_TOTAL_PREMIUM: "20420.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "10900001",
    TOTAL_SUMINS_MAX: "11000000",
    PLAN_NET_PREMIUM_AMT: "19105.24",
    PLAN_STAMP_DUTY_AMT: "77.00",
    PLAN_VAT_AMT: "1342.76",
    PLAN_TOTAL_PREMIUM: "20525.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11000001",
    TOTAL_SUMINS_MAX: "11100000",
    PLAN_NET_PREMIUM_AMT: "19203.37",
    PLAN_STAMP_DUTY_AMT: "77.00",
    PLAN_VAT_AMT: "1349.63",
    PLAN_TOTAL_PREMIUM: "20630.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11100001",
    TOTAL_SUMINS_MAX: "11200000",
    PLAN_NET_PREMIUM_AMT: "19300.50",
    PLAN_STAMP_DUTY_AMT: "78.00",
    PLAN_VAT_AMT: "1356.50",
    PLAN_TOTAL_PREMIUM: "20735.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11200001",
    TOTAL_SUMINS_MAX: "11300000",
    PLAN_NET_PREMIUM_AMT: "19398.64",
    PLAN_STAMP_DUTY_AMT: "78.00",
    PLAN_VAT_AMT: "1363.36",
    PLAN_TOTAL_PREMIUM: "20840.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11300001",
    TOTAL_SUMINS_MAX: "11400000",
    PLAN_NET_PREMIUM_AMT: "19496.77",
    PLAN_STAMP_DUTY_AMT: "78.00",
    PLAN_VAT_AMT: "1370.23",
    PLAN_TOTAL_PREMIUM: "20945.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11400001",
    TOTAL_SUMINS_MAX: "11500000",
    PLAN_NET_PREMIUM_AMT: "19593.90",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1377.10",
    PLAN_TOTAL_PREMIUM: "21050.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11500001",
    TOTAL_SUMINS_MAX: "11600000",
    PLAN_NET_PREMIUM_AMT: "19692.03",
    PLAN_STAMP_DUTY_AMT: "79.00",
    PLAN_VAT_AMT: "1383.97",
    PLAN_TOTAL_PREMIUM: "21155.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11600001",
    TOTAL_SUMINS_MAX: "11700000",
    PLAN_NET_PREMIUM_AMT: "19789.16",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1390.84",
    PLAN_TOTAL_PREMIUM: "21260.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11700001",
    TOTAL_SUMINS_MAX: "11800000",
    PLAN_NET_PREMIUM_AMT: "19887.29",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1397.71",
    PLAN_TOTAL_PREMIUM: "21365.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-11900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11800001",
    TOTAL_SUMINS_MAX: "11900000",
    PLAN_NET_PREMIUM_AMT: "19985.42",
    PLAN_STAMP_DUTY_AMT: "80.00",
    PLAN_VAT_AMT: "1404.58",
    PLAN_TOTAL_PREMIUM: "21470.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "11900001",
    TOTAL_SUMINS_MAX: "12000000",
    PLAN_NET_PREMIUM_AMT: "20082.55",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1411.45",
    PLAN_TOTAL_PREMIUM: "21575.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12000001",
    TOTAL_SUMINS_MAX: "12100000",
    PLAN_NET_PREMIUM_AMT: "20180.68",
    PLAN_STAMP_DUTY_AMT: "81.00",
    PLAN_VAT_AMT: "1418.32",
    PLAN_TOTAL_PREMIUM: "21680.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12100001",
    TOTAL_SUMINS_MAX: "12200000",
    PLAN_NET_PREMIUM_AMT: "20277.81",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1425.19",
    PLAN_TOTAL_PREMIUM: "21785.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12200001",
    TOTAL_SUMINS_MAX: "12300000",
    PLAN_NET_PREMIUM_AMT: "20375.94",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1432.06",
    PLAN_TOTAL_PREMIUM: "21890.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12300001",
    TOTAL_SUMINS_MAX: "12400000",
    PLAN_NET_PREMIUM_AMT: "20474.07",
    PLAN_STAMP_DUTY_AMT: "82.00",
    PLAN_VAT_AMT: "1438.93",
    PLAN_TOTAL_PREMIUM: "21995.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12400001",
    TOTAL_SUMINS_MAX: "12500000",
    PLAN_NET_PREMIUM_AMT: "20571.21",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1445.79",
    PLAN_TOTAL_PREMIUM: "22100.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12500001",
    TOTAL_SUMINS_MAX: "12600000",
    PLAN_NET_PREMIUM_AMT: "20669.34",
    PLAN_STAMP_DUTY_AMT: "83.00",
    PLAN_VAT_AMT: "1452.66",
    PLAN_TOTAL_PREMIUM: "22205.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12600001",
    TOTAL_SUMINS_MAX: "12700000",
    PLAN_NET_PREMIUM_AMT: "20766.47",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1459.53",
    PLAN_TOTAL_PREMIUM: "22310.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12700001",
    TOTAL_SUMINS_MAX: "12800000",
    PLAN_NET_PREMIUM_AMT: "20864.60",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1466.40",
    PLAN_TOTAL_PREMIUM: "22415.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-12900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12800001",
    TOTAL_SUMINS_MAX: "12900000",
    PLAN_NET_PREMIUM_AMT: "20962.73",
    PLAN_STAMP_DUTY_AMT: "84.00",
    PLAN_VAT_AMT: "1473.27",
    PLAN_TOTAL_PREMIUM: "22520.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "12900001",
    TOTAL_SUMINS_MAX: "13000000",
    PLAN_NET_PREMIUM_AMT: "21059.86",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1480.14",
    PLAN_TOTAL_PREMIUM: "22625.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13000001",
    TOTAL_SUMINS_MAX: "13100000",
    PLAN_NET_PREMIUM_AMT: "21157.99",
    PLAN_STAMP_DUTY_AMT: "85.00",
    PLAN_VAT_AMT: "1487.01",
    PLAN_TOTAL_PREMIUM: "22730.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13100001",
    TOTAL_SUMINS_MAX: "13200000",
    PLAN_NET_PREMIUM_AMT: "21255.12",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1493.88",
    PLAN_TOTAL_PREMIUM: "22835.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13200001",
    TOTAL_SUMINS_MAX: "13300000",
    PLAN_NET_PREMIUM_AMT: "21353.25",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1500.75",
    PLAN_TOTAL_PREMIUM: "22940.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13300001",
    TOTAL_SUMINS_MAX: "13400000",
    PLAN_NET_PREMIUM_AMT: "21451.38",
    PLAN_STAMP_DUTY_AMT: "86.00",
    PLAN_VAT_AMT: "1507.62",
    PLAN_TOTAL_PREMIUM: "23045.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13400001",
    TOTAL_SUMINS_MAX: "13500000",
    PLAN_NET_PREMIUM_AMT: "21548.51",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1514.49",
    PLAN_TOTAL_PREMIUM: "23150.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13500001",
    TOTAL_SUMINS_MAX: "13600000",
    PLAN_NET_PREMIUM_AMT: "21646.64",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1521.36",
    PLAN_TOTAL_PREMIUM: "23255.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13600001",
    TOTAL_SUMINS_MAX: "13700000",
    PLAN_NET_PREMIUM_AMT: "21744.78",
    PLAN_STAMP_DUTY_AMT: "87.00",
    PLAN_VAT_AMT: "1528.22",
    PLAN_TOTAL_PREMIUM: "23360.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13700001",
    TOTAL_SUMINS_MAX: "13800000",
    PLAN_NET_PREMIUM_AMT: "21841.91",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1535.09",
    PLAN_TOTAL_PREMIUM: "23465.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-13900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13800001",
    TOTAL_SUMINS_MAX: "13900000",
    PLAN_NET_PREMIUM_AMT: "21940.04",
    PLAN_STAMP_DUTY_AMT: "88.00",
    PLAN_VAT_AMT: "1541.96",
    PLAN_TOTAL_PREMIUM: "23570.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "13900001",
    TOTAL_SUMINS_MAX: "14000000",
    PLAN_NET_PREMIUM_AMT: "22037.17",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1548.83",
    PLAN_TOTAL_PREMIUM: "23675.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14000001",
    TOTAL_SUMINS_MAX: "14100000",
    PLAN_NET_PREMIUM_AMT: "22135.30",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1555.70",
    PLAN_TOTAL_PREMIUM: "23780.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14100001",
    TOTAL_SUMINS_MAX: "14200000",
    PLAN_NET_PREMIUM_AMT: "22233.43",
    PLAN_STAMP_DUTY_AMT: "89.00",
    PLAN_VAT_AMT: "1562.57",
    PLAN_TOTAL_PREMIUM: "23885.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14200001",
    TOTAL_SUMINS_MAX: "14300000",
    PLAN_NET_PREMIUM_AMT: "22330.56",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1569.44",
    PLAN_TOTAL_PREMIUM: "23990.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14300001",
    TOTAL_SUMINS_MAX: "14400000",
    PLAN_NET_PREMIUM_AMT: "22428.69",
    PLAN_STAMP_DUTY_AMT: "90.00",
    PLAN_VAT_AMT: "1576.31",
    PLAN_TOTAL_PREMIUM: "24095.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14400001",
    TOTAL_SUMINS_MAX: "14500000",
    PLAN_NET_PREMIUM_AMT: "22525.82",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1583.18",
    PLAN_TOTAL_PREMIUM: "24200.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14500001",
    TOTAL_SUMINS_MAX: "14600000",
    PLAN_NET_PREMIUM_AMT: "22623.95",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1590.05",
    PLAN_TOTAL_PREMIUM: "24305.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14600001",
    TOTAL_SUMINS_MAX: "14700000",
    PLAN_NET_PREMIUM_AMT: "22722.08",
    PLAN_STAMP_DUTY_AMT: "91.00",
    PLAN_VAT_AMT: "1596.92",
    PLAN_TOTAL_PREMIUM: "24410.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14700001",
    TOTAL_SUMINS_MAX: "14800000",
    PLAN_NET_PREMIUM_AMT: "22819.21",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1603.79",
    PLAN_TOTAL_PREMIUM: "24515.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-14900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14800001",
    TOTAL_SUMINS_MAX: "14900000",
    PLAN_NET_PREMIUM_AMT: "22917.35",
    PLAN_STAMP_DUTY_AMT: "92.00",
    PLAN_VAT_AMT: "1610.65",
    PLAN_TOTAL_PREMIUM: "24620.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "14900001",
    TOTAL_SUMINS_MAX: "15000000",
    PLAN_NET_PREMIUM_AMT: "23014.48",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1617.52",
    PLAN_TOTAL_PREMIUM: "24725.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15000001",
    TOTAL_SUMINS_MAX: "15100000",
    PLAN_NET_PREMIUM_AMT: "23112.61",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1624.39",
    PLAN_TOTAL_PREMIUM: "24830.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15100001",
    TOTAL_SUMINS_MAX: "15200000",
    PLAN_NET_PREMIUM_AMT: "23210.74",
    PLAN_STAMP_DUTY_AMT: "93.00",
    PLAN_VAT_AMT: "1631.26",
    PLAN_TOTAL_PREMIUM: "24935.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15200001",
    TOTAL_SUMINS_MAX: "15300000",
    PLAN_NET_PREMIUM_AMT: "23307.87",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1638.13",
    PLAN_TOTAL_PREMIUM: "25040.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15300001",
    TOTAL_SUMINS_MAX: "15400000",
    PLAN_NET_PREMIUM_AMT: "23406.00",
    PLAN_STAMP_DUTY_AMT: "94.00",
    PLAN_VAT_AMT: "1645.00",
    PLAN_TOTAL_PREMIUM: "25145.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15400001",
    TOTAL_SUMINS_MAX: "15500000",
    PLAN_NET_PREMIUM_AMT: "23503.13",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1651.87",
    PLAN_TOTAL_PREMIUM: "25250.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15500001",
    TOTAL_SUMINS_MAX: "15600000",
    PLAN_NET_PREMIUM_AMT: "23601.26",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1658.74",
    PLAN_TOTAL_PREMIUM: "25355.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15600001",
    TOTAL_SUMINS_MAX: "15700000",
    PLAN_NET_PREMIUM_AMT: "23699.39",
    PLAN_STAMP_DUTY_AMT: "95.00",
    PLAN_VAT_AMT: "1665.61",
    PLAN_TOTAL_PREMIUM: "25460.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15700001",
    TOTAL_SUMINS_MAX: "15800000",
    PLAN_NET_PREMIUM_AMT: "23796.52",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1672.48",
    PLAN_TOTAL_PREMIUM: "25565.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-15900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15800001",
    TOTAL_SUMINS_MAX: "15900000",
    PLAN_NET_PREMIUM_AMT: "23894.65",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1679.35",
    PLAN_TOTAL_PREMIUM: "25670.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "15900001",
    TOTAL_SUMINS_MAX: "16000000",
    PLAN_NET_PREMIUM_AMT: "23992.78",
    PLAN_STAMP_DUTY_AMT: "96.00",
    PLAN_VAT_AMT: "1686.22",
    PLAN_TOTAL_PREMIUM: "25775.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16000001",
    TOTAL_SUMINS_MAX: "16100000",
    PLAN_NET_PREMIUM_AMT: "24089.92",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1693.08",
    PLAN_TOTAL_PREMIUM: "25880.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16100001",
    TOTAL_SUMINS_MAX: "16200000",
    PLAN_NET_PREMIUM_AMT: "24188.05",
    PLAN_STAMP_DUTY_AMT: "97.00",
    PLAN_VAT_AMT: "1699.95",
    PLAN_TOTAL_PREMIUM: "25985.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16200001",
    TOTAL_SUMINS_MAX: "16300000",
    PLAN_NET_PREMIUM_AMT: "24285.18",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1706.82",
    PLAN_TOTAL_PREMIUM: "26090.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16300001",
    TOTAL_SUMINS_MAX: "16400000",
    PLAN_NET_PREMIUM_AMT: "24383.31",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1713.69",
    PLAN_TOTAL_PREMIUM: "26195.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16400001",
    TOTAL_SUMINS_MAX: "16500000",
    PLAN_NET_PREMIUM_AMT: "24481.44",
    PLAN_STAMP_DUTY_AMT: "98.00",
    PLAN_VAT_AMT: "1720.56",
    PLAN_TOTAL_PREMIUM: "26300.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16500001",
    TOTAL_SUMINS_MAX: "16600000",
    PLAN_NET_PREMIUM_AMT: "24578.57",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1727.43",
    PLAN_TOTAL_PREMIUM: "26405.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16600001",
    TOTAL_SUMINS_MAX: "16700000",
    PLAN_NET_PREMIUM_AMT: "24676.70",
    PLAN_STAMP_DUTY_AMT: "99.00",
    PLAN_VAT_AMT: "1734.30",
    PLAN_TOTAL_PREMIUM: "26510.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16700001",
    TOTAL_SUMINS_MAX: "16800000",
    PLAN_NET_PREMIUM_AMT: "24773.83",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1741.17",
    PLAN_TOTAL_PREMIUM: "26615.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-16900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16800001",
    TOTAL_SUMINS_MAX: "16900000",
    PLAN_NET_PREMIUM_AMT: "24871.96",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1748.04",
    PLAN_TOTAL_PREMIUM: "26720.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "16900001",
    TOTAL_SUMINS_MAX: "17000000",
    PLAN_NET_PREMIUM_AMT: "24970.09",
    PLAN_STAMP_DUTY_AMT: "100.00",
    PLAN_VAT_AMT: "1754.91",
    PLAN_TOTAL_PREMIUM: "26825.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17000001",
    TOTAL_SUMINS_MAX: "17100000",
    PLAN_NET_PREMIUM_AMT: "25067.22",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1761.78",
    PLAN_TOTAL_PREMIUM: "26930.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17100001",
    TOTAL_SUMINS_MAX: "17200000",
    PLAN_NET_PREMIUM_AMT: "25165.35",
    PLAN_STAMP_DUTY_AMT: "101.00",
    PLAN_VAT_AMT: "1768.65",
    PLAN_TOTAL_PREMIUM: "27035.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17200001",
    TOTAL_SUMINS_MAX: "17300000",
    PLAN_NET_PREMIUM_AMT: "25262.49",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1775.51",
    PLAN_TOTAL_PREMIUM: "27140.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17300001",
    TOTAL_SUMINS_MAX: "17400000",
    PLAN_NET_PREMIUM_AMT: "25360.62",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1782.38",
    PLAN_TOTAL_PREMIUM: "27245.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17400001",
    TOTAL_SUMINS_MAX: "17500000",
    PLAN_NET_PREMIUM_AMT: "25458.75",
    PLAN_STAMP_DUTY_AMT: "102.00",
    PLAN_VAT_AMT: "1789.25",
    PLAN_TOTAL_PREMIUM: "27350.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17500001",
    TOTAL_SUMINS_MAX: "17600000",
    PLAN_NET_PREMIUM_AMT: "25555.88",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1796.12",
    PLAN_TOTAL_PREMIUM: "27455.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17600001",
    TOTAL_SUMINS_MAX: "17700000",
    PLAN_NET_PREMIUM_AMT: "25654.01",
    PLAN_STAMP_DUTY_AMT: "103.00",
    PLAN_VAT_AMT: "1802.99",
    PLAN_TOTAL_PREMIUM: "27560.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17700001",
    TOTAL_SUMINS_MAX: "17800000",
    PLAN_NET_PREMIUM_AMT: "25751.14",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1809.86",
    PLAN_TOTAL_PREMIUM: "27665.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-17900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17800001",
    TOTAL_SUMINS_MAX: "17900000",
    PLAN_NET_PREMIUM_AMT: "25849.27",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1816.73",
    PLAN_TOTAL_PREMIUM: "27770.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "17900001",
    TOTAL_SUMINS_MAX: "18000000",
    PLAN_NET_PREMIUM_AMT: "25947.40",
    PLAN_STAMP_DUTY_AMT: "104.00",
    PLAN_VAT_AMT: "1823.60",
    PLAN_TOTAL_PREMIUM: "27875.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18000001",
    TOTAL_SUMINS_MAX: "18100000",
    PLAN_NET_PREMIUM_AMT: "26044.53",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1830.47",
    PLAN_TOTAL_PREMIUM: "27980.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18100001",
    TOTAL_SUMINS_MAX: "18200000",
    PLAN_NET_PREMIUM_AMT: "26142.66",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1837.34",
    PLAN_TOTAL_PREMIUM: "28085.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18200001",
    TOTAL_SUMINS_MAX: "18300000",
    PLAN_NET_PREMIUM_AMT: "26240.79",
    PLAN_STAMP_DUTY_AMT: "105.00",
    PLAN_VAT_AMT: "1844.21",
    PLAN_TOTAL_PREMIUM: "28190.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18300001",
    TOTAL_SUMINS_MAX: "18400000",
    PLAN_NET_PREMIUM_AMT: "26337.92",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1851.08",
    PLAN_TOTAL_PREMIUM: "28295.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18400001",
    TOTAL_SUMINS_MAX: "18500000",
    PLAN_NET_PREMIUM_AMT: "26436.06",
    PLAN_STAMP_DUTY_AMT: "106.00",
    PLAN_VAT_AMT: "1857.94",
    PLAN_TOTAL_PREMIUM: "28400.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18500001",
    TOTAL_SUMINS_MAX: "18600000",
    PLAN_NET_PREMIUM_AMT: "26533.19",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1864.81",
    PLAN_TOTAL_PREMIUM: "28505.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18600001",
    TOTAL_SUMINS_MAX: "18700000",
    PLAN_NET_PREMIUM_AMT: "26631.32",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1871.68",
    PLAN_TOTAL_PREMIUM: "28610.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18700001",
    TOTAL_SUMINS_MAX: "18800000",
    PLAN_NET_PREMIUM_AMT: "26729.45",
    PLAN_STAMP_DUTY_AMT: "107.00",
    PLAN_VAT_AMT: "1878.55",
    PLAN_TOTAL_PREMIUM: "28715.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-18900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18800001",
    TOTAL_SUMINS_MAX: "18900000",
    PLAN_NET_PREMIUM_AMT: "26826.58",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1885.42",
    PLAN_TOTAL_PREMIUM: "28820.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "18900001",
    TOTAL_SUMINS_MAX: "19000000",
    PLAN_NET_PREMIUM_AMT: "26924.71",
    PLAN_STAMP_DUTY_AMT: "108.00",
    PLAN_VAT_AMT: "1892.29",
    PLAN_TOTAL_PREMIUM: "28925.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19000001",
    TOTAL_SUMINS_MAX: "19100000",
    PLAN_NET_PREMIUM_AMT: "27021.84",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1899.16",
    PLAN_TOTAL_PREMIUM: "29030.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19100001",
    TOTAL_SUMINS_MAX: "19200000",
    PLAN_NET_PREMIUM_AMT: "27119.97",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1906.03",
    PLAN_TOTAL_PREMIUM: "29135.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19200001",
    TOTAL_SUMINS_MAX: "19300000",
    PLAN_NET_PREMIUM_AMT: "27218.10",
    PLAN_STAMP_DUTY_AMT: "109.00",
    PLAN_VAT_AMT: "1912.90",
    PLAN_TOTAL_PREMIUM: "29240.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19300001",
    TOTAL_SUMINS_MAX: "19400000",
    PLAN_NET_PREMIUM_AMT: "27315.23",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1919.77",
    PLAN_TOTAL_PREMIUM: "29345.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19400001",
    TOTAL_SUMINS_MAX: "19500000",
    PLAN_NET_PREMIUM_AMT: "27413.36",
    PLAN_STAMP_DUTY_AMT: "110.00",
    PLAN_VAT_AMT: "1926.64",
    PLAN_TOTAL_PREMIUM: "29450.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19500001",
    TOTAL_SUMINS_MAX: "19600000",
    PLAN_NET_PREMIUM_AMT: "27510.49",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1933.51",
    PLAN_TOTAL_PREMIUM: "29555.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19600001",
    TOTAL_SUMINS_MAX: "19700000",
    PLAN_NET_PREMIUM_AMT: "27608.63",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1940.37",
    PLAN_TOTAL_PREMIUM: "29660.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19700001",
    TOTAL_SUMINS_MAX: "19800000",
    PLAN_NET_PREMIUM_AMT: "27706.76",
    PLAN_STAMP_DUTY_AMT: "111.00",
    PLAN_VAT_AMT: "1947.24",
    PLAN_TOTAL_PREMIUM: "29765.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-19900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19800001",
    TOTAL_SUMINS_MAX: "19900000",
    PLAN_NET_PREMIUM_AMT: "27803.89",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1954.11",
    PLAN_TOTAL_PREMIUM: "29870.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "19900001",
    TOTAL_SUMINS_MAX: "20000000",
    PLAN_NET_PREMIUM_AMT: "27902.02",
    PLAN_STAMP_DUTY_AMT: "112.00",
    PLAN_VAT_AMT: "1960.98",
    PLAN_TOTAL_PREMIUM: "29975.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20000001",
    TOTAL_SUMINS_MAX: "20100000",
    PLAN_NET_PREMIUM_AMT: "27999.15",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1967.85",
    PLAN_TOTAL_PREMIUM: "30080.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20100001",
    TOTAL_SUMINS_MAX: "20200000",
    PLAN_NET_PREMIUM_AMT: "28097.28",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1974.72",
    PLAN_TOTAL_PREMIUM: "30185.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20200001",
    TOTAL_SUMINS_MAX: "20300000",
    PLAN_NET_PREMIUM_AMT: "28195.41",
    PLAN_STAMP_DUTY_AMT: "113.00",
    PLAN_VAT_AMT: "1981.59",
    PLAN_TOTAL_PREMIUM: "30290.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20300001",
    TOTAL_SUMINS_MAX: "20400000",
    PLAN_NET_PREMIUM_AMT: "28292.54",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1988.46",
    PLAN_TOTAL_PREMIUM: "30395.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20400001",
    TOTAL_SUMINS_MAX: "20500000",
    PLAN_NET_PREMIUM_AMT: "28390.67",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "1995.33",
    PLAN_TOTAL_PREMIUM: "30500.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20500001",
    TOTAL_SUMINS_MAX: "20600000",
    PLAN_NET_PREMIUM_AMT: "28488.80",
    PLAN_STAMP_DUTY_AMT: "114.00",
    PLAN_VAT_AMT: "2002.20",
    PLAN_TOTAL_PREMIUM: "30605.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20600001",
    TOTAL_SUMINS_MAX: "20700000",
    PLAN_NET_PREMIUM_AMT: "28585.93",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2009.07",
    PLAN_TOTAL_PREMIUM: "30710.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20700001",
    TOTAL_SUMINS_MAX: "20800000",
    PLAN_NET_PREMIUM_AMT: "28684.07",
    PLAN_STAMP_DUTY_AMT: "115.00",
    PLAN_VAT_AMT: "2015.93",
    PLAN_TOTAL_PREMIUM: "30815.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-20900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20800001",
    TOTAL_SUMINS_MAX: "20900000",
    PLAN_NET_PREMIUM_AMT: "28781.20",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2022.80",
    PLAN_TOTAL_PREMIUM: "30920.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "20900001",
    TOTAL_SUMINS_MAX: "21000000",
    PLAN_NET_PREMIUM_AMT: "28879.33",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2029.67",
    PLAN_TOTAL_PREMIUM: "31025.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21000001",
    TOTAL_SUMINS_MAX: "21100000",
    PLAN_NET_PREMIUM_AMT: "28977.46",
    PLAN_STAMP_DUTY_AMT: "116.00",
    PLAN_VAT_AMT: "2036.54",
    PLAN_TOTAL_PREMIUM: "31130.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21100001",
    TOTAL_SUMINS_MAX: "21200000",
    PLAN_NET_PREMIUM_AMT: "29074.59",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2043.41",
    PLAN_TOTAL_PREMIUM: "31235.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21200001",
    TOTAL_SUMINS_MAX: "21300000",
    PLAN_NET_PREMIUM_AMT: "29172.72",
    PLAN_STAMP_DUTY_AMT: "117.00",
    PLAN_VAT_AMT: "2050.28",
    PLAN_TOTAL_PREMIUM: "31340.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21300001",
    TOTAL_SUMINS_MAX: "21400000",
    PLAN_NET_PREMIUM_AMT: "29269.85",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2057.15",
    PLAN_TOTAL_PREMIUM: "31445.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21400001",
    TOTAL_SUMINS_MAX: "21500000",
    PLAN_NET_PREMIUM_AMT: "29367.98",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2064.02",
    PLAN_TOTAL_PREMIUM: "31550.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21500001",
    TOTAL_SUMINS_MAX: "21600000",
    PLAN_NET_PREMIUM_AMT: "29466.11",
    PLAN_STAMP_DUTY_AMT: "118.00",
    PLAN_VAT_AMT: "2070.89",
    PLAN_TOTAL_PREMIUM: "31655.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21600001",
    TOTAL_SUMINS_MAX: "21700000",
    PLAN_NET_PREMIUM_AMT: "29563.24",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2077.76",
    PLAN_TOTAL_PREMIUM: "31760.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21700001",
    TOTAL_SUMINS_MAX: "21800000",
    PLAN_NET_PREMIUM_AMT: "29661.37",
    PLAN_STAMP_DUTY_AMT: "119.00",
    PLAN_VAT_AMT: "2084.63",
    PLAN_TOTAL_PREMIUM: "31865.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-21900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21800001",
    TOTAL_SUMINS_MAX: "21900000",
    PLAN_NET_PREMIUM_AMT: "29758.50",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2091.50",
    PLAN_TOTAL_PREMIUM: "31970.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "21900001",
    TOTAL_SUMINS_MAX: "22000000",
    PLAN_NET_PREMIUM_AMT: "29856.64",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2098.36",
    PLAN_TOTAL_PREMIUM: "32075.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22000001",
    TOTAL_SUMINS_MAX: "22100000",
    PLAN_NET_PREMIUM_AMT: "29954.77",
    PLAN_STAMP_DUTY_AMT: "120.00",
    PLAN_VAT_AMT: "2105.23",
    PLAN_TOTAL_PREMIUM: "32180.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22100001",
    TOTAL_SUMINS_MAX: "22200000",
    PLAN_NET_PREMIUM_AMT: "30051.90",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2112.10",
    PLAN_TOTAL_PREMIUM: "32285.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22200001",
    TOTAL_SUMINS_MAX: "22300000",
    PLAN_NET_PREMIUM_AMT: "30150.03",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2118.97",
    PLAN_TOTAL_PREMIUM: "32390.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22300001",
    TOTAL_SUMINS_MAX: "22400000",
    PLAN_NET_PREMIUM_AMT: "30248.16",
    PLAN_STAMP_DUTY_AMT: "121.00",
    PLAN_VAT_AMT: "2125.84",
    PLAN_TOTAL_PREMIUM: "32495.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22400001",
    TOTAL_SUMINS_MAX: "22500000",
    PLAN_NET_PREMIUM_AMT: "30345.29",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2132.71",
    PLAN_TOTAL_PREMIUM: "32600.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22500001",
    TOTAL_SUMINS_MAX: "22600000",
    PLAN_NET_PREMIUM_AMT: "30443.42",
    PLAN_STAMP_DUTY_AMT: "122.00",
    PLAN_VAT_AMT: "2139.58",
    PLAN_TOTAL_PREMIUM: "32705.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22600001",
    TOTAL_SUMINS_MAX: "22700000",
    PLAN_NET_PREMIUM_AMT: "30540.55",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2146.45",
    PLAN_TOTAL_PREMIUM: "32810.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22700001",
    TOTAL_SUMINS_MAX: "22800000",
    PLAN_NET_PREMIUM_AMT: "30638.68",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2153.32",
    PLAN_TOTAL_PREMIUM: "32915.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-22900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22800001",
    TOTAL_SUMINS_MAX: "22900000",
    PLAN_NET_PREMIUM_AMT: "30736.81",
    PLAN_STAMP_DUTY_AMT: "123.00",
    PLAN_VAT_AMT: "2160.19",
    PLAN_TOTAL_PREMIUM: "33020.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "22900001",
    TOTAL_SUMINS_MAX: "23000000",
    PLAN_NET_PREMIUM_AMT: "30833.94",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2167.06",
    PLAN_TOTAL_PREMIUM: "33125.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23000001",
    TOTAL_SUMINS_MAX: "23100000",
    PLAN_NET_PREMIUM_AMT: "30932.07",
    PLAN_STAMP_DUTY_AMT: "124.00",
    PLAN_VAT_AMT: "2173.93",
    PLAN_TOTAL_PREMIUM: "33230.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23100001",
    TOTAL_SUMINS_MAX: "23200000",
    PLAN_NET_PREMIUM_AMT: "31029.21",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2180.79",
    PLAN_TOTAL_PREMIUM: "33335.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23200001",
    TOTAL_SUMINS_MAX: "23300000",
    PLAN_NET_PREMIUM_AMT: "31127.34",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2187.66",
    PLAN_TOTAL_PREMIUM: "33440.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23300001",
    TOTAL_SUMINS_MAX: "23400000",
    PLAN_NET_PREMIUM_AMT: "31225.47",
    PLAN_STAMP_DUTY_AMT: "125.00",
    PLAN_VAT_AMT: "2194.53",
    PLAN_TOTAL_PREMIUM: "33545.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23400001",
    TOTAL_SUMINS_MAX: "23500000",
    PLAN_NET_PREMIUM_AMT: "31322.60",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2201.40",
    PLAN_TOTAL_PREMIUM: "33650.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23500001",
    TOTAL_SUMINS_MAX: "23600000",
    PLAN_NET_PREMIUM_AMT: "31420.73",
    PLAN_STAMP_DUTY_AMT: "126.00",
    PLAN_VAT_AMT: "2208.27",
    PLAN_TOTAL_PREMIUM: "33755.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23600001",
    TOTAL_SUMINS_MAX: "23700000",
    PLAN_NET_PREMIUM_AMT: "31517.86",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2215.14",
    PLAN_TOTAL_PREMIUM: "33860.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23700001",
    TOTAL_SUMINS_MAX: "23800000",
    PLAN_NET_PREMIUM_AMT: "31615.99",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2222.01",
    PLAN_TOTAL_PREMIUM: "33965.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-23900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23800001",
    TOTAL_SUMINS_MAX: "23900000",
    PLAN_NET_PREMIUM_AMT: "31714.12",
    PLAN_STAMP_DUTY_AMT: "127.00",
    PLAN_VAT_AMT: "2228.88",
    PLAN_TOTAL_PREMIUM: "34070.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "23900001",
    TOTAL_SUMINS_MAX: "24000000",
    PLAN_NET_PREMIUM_AMT: "31811.25",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2235.75",
    PLAN_TOTAL_PREMIUM: "34175.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24000001",
    TOTAL_SUMINS_MAX: "24100000",
    PLAN_NET_PREMIUM_AMT: "31909.38",
    PLAN_STAMP_DUTY_AMT: "128.00",
    PLAN_VAT_AMT: "2242.62",
    PLAN_TOTAL_PREMIUM: "34280.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24100001",
    TOTAL_SUMINS_MAX: "24200000",
    PLAN_NET_PREMIUM_AMT: "32006.51",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2249.49",
    PLAN_TOTAL_PREMIUM: "34385.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24200001",
    TOTAL_SUMINS_MAX: "24300000",
    PLAN_NET_PREMIUM_AMT: "32104.64",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2256.36",
    PLAN_TOTAL_PREMIUM: "34490.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24300001",
    TOTAL_SUMINS_MAX: "24400000",
    PLAN_NET_PREMIUM_AMT: "32202.78",
    PLAN_STAMP_DUTY_AMT: "129.00",
    PLAN_VAT_AMT: "2263.22",
    PLAN_TOTAL_PREMIUM: "34595.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24400001",
    TOTAL_SUMINS_MAX: "24500000",
    PLAN_NET_PREMIUM_AMT: "32299.91",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2270.09",
    PLAN_TOTAL_PREMIUM: "34700.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24500001",
    TOTAL_SUMINS_MAX: "24600000",
    PLAN_NET_PREMIUM_AMT: "32398.04",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2276.96",
    PLAN_TOTAL_PREMIUM: "34805.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24600001",
    TOTAL_SUMINS_MAX: "24700000",
    PLAN_NET_PREMIUM_AMT: "32496.17",
    PLAN_STAMP_DUTY_AMT: "130.00",
    PLAN_VAT_AMT: "2283.83",
    PLAN_TOTAL_PREMIUM: "34910.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24700001",
    TOTAL_SUMINS_MAX: "24800000",
    PLAN_NET_PREMIUM_AMT: "32593.30",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2290.70",
    PLAN_TOTAL_PREMIUM: "35015.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-24900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24800001",
    TOTAL_SUMINS_MAX: "24900000",
    PLAN_NET_PREMIUM_AMT: "32691.43",
    PLAN_STAMP_DUTY_AMT: "131.00",
    PLAN_VAT_AMT: "2297.57",
    PLAN_TOTAL_PREMIUM: "35120.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "24900001",
    TOTAL_SUMINS_MAX: "25000000",
    PLAN_NET_PREMIUM_AMT: "32788.56",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2304.44",
    PLAN_TOTAL_PREMIUM: "35225.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25000001",
    TOTAL_SUMINS_MAX: "25100000",
    PLAN_NET_PREMIUM_AMT: "32886.69",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2311.31",
    PLAN_TOTAL_PREMIUM: "35330.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25100001",
    TOTAL_SUMINS_MAX: "25200000",
    PLAN_NET_PREMIUM_AMT: "32984.82",
    PLAN_STAMP_DUTY_AMT: "132.00",
    PLAN_VAT_AMT: "2318.18",
    PLAN_TOTAL_PREMIUM: "35435.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25200001",
    TOTAL_SUMINS_MAX: "25300000",
    PLAN_NET_PREMIUM_AMT: "33081.95",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2325.05",
    PLAN_TOTAL_PREMIUM: "35540.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25300001",
    TOTAL_SUMINS_MAX: "25400000",
    PLAN_NET_PREMIUM_AMT: "33180.08",
    PLAN_STAMP_DUTY_AMT: "133.00",
    PLAN_VAT_AMT: "2331.92",
    PLAN_TOTAL_PREMIUM: "35645.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25400001",
    TOTAL_SUMINS_MAX: "25500000",
    PLAN_NET_PREMIUM_AMT: "33277.21",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2338.79",
    PLAN_TOTAL_PREMIUM: "35750.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25500001",
    TOTAL_SUMINS_MAX: "25600000",
    PLAN_NET_PREMIUM_AMT: "33375.35",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2345.65",
    PLAN_TOTAL_PREMIUM: "35855.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25600001",
    TOTAL_SUMINS_MAX: "25700000",
    PLAN_NET_PREMIUM_AMT: "33473.48",
    PLAN_STAMP_DUTY_AMT: "134.00",
    PLAN_VAT_AMT: "2352.52",
    PLAN_TOTAL_PREMIUM: "35960.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25700001",
    TOTAL_SUMINS_MAX: "25800000",
    PLAN_NET_PREMIUM_AMT: "33570.61",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2359.39",
    PLAN_TOTAL_PREMIUM: "36065.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-25900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25800001",
    TOTAL_SUMINS_MAX: "25900000",
    PLAN_NET_PREMIUM_AMT: "33668.74",
    PLAN_STAMP_DUTY_AMT: "135.00",
    PLAN_VAT_AMT: "2366.26",
    PLAN_TOTAL_PREMIUM: "36170.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "25900001",
    TOTAL_SUMINS_MAX: "26000000",
    PLAN_NET_PREMIUM_AMT: "33765.87",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2373.13",
    PLAN_TOTAL_PREMIUM: "36275.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26000001",
    TOTAL_SUMINS_MAX: "26100000",
    PLAN_NET_PREMIUM_AMT: "33864.00",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2380.00",
    PLAN_TOTAL_PREMIUM: "36380.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26100001",
    TOTAL_SUMINS_MAX: "26200000",
    PLAN_NET_PREMIUM_AMT: "33962.13",
    PLAN_STAMP_DUTY_AMT: "136.00",
    PLAN_VAT_AMT: "2386.87",
    PLAN_TOTAL_PREMIUM: "36485.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26200001",
    TOTAL_SUMINS_MAX: "26300000",
    PLAN_NET_PREMIUM_AMT: "34059.26",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2393.74",
    PLAN_TOTAL_PREMIUM: "36590.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26300001",
    TOTAL_SUMINS_MAX: "26400000",
    PLAN_NET_PREMIUM_AMT: "34157.39",
    PLAN_STAMP_DUTY_AMT: "137.00",
    PLAN_VAT_AMT: "2400.61",
    PLAN_TOTAL_PREMIUM: "36695.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26400001",
    TOTAL_SUMINS_MAX: "26500000",
    PLAN_NET_PREMIUM_AMT: "34254.52",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2407.48",
    PLAN_TOTAL_PREMIUM: "36800.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26500001",
    TOTAL_SUMINS_MAX: "26600000",
    PLAN_NET_PREMIUM_AMT: "34352.65",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2414.35",
    PLAN_TOTAL_PREMIUM: "36905.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26600001",
    TOTAL_SUMINS_MAX: "26700000",
    PLAN_NET_PREMIUM_AMT: "34450.78",
    PLAN_STAMP_DUTY_AMT: "138.00",
    PLAN_VAT_AMT: "2421.22",
    PLAN_TOTAL_PREMIUM: "37010.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26700001",
    TOTAL_SUMINS_MAX: "26800000",
    PLAN_NET_PREMIUM_AMT: "34547.92",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2428.08",
    PLAN_TOTAL_PREMIUM: "37115.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-26900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26800001",
    TOTAL_SUMINS_MAX: "26900000",
    PLAN_NET_PREMIUM_AMT: "34646.05",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2434.95",
    PLAN_TOTAL_PREMIUM: "37220.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "26900001",
    TOTAL_SUMINS_MAX: "27000000",
    PLAN_NET_PREMIUM_AMT: "34744.18",
    PLAN_STAMP_DUTY_AMT: "139.00",
    PLAN_VAT_AMT: "2441.82",
    PLAN_TOTAL_PREMIUM: "37325.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27000001",
    TOTAL_SUMINS_MAX: "27100000",
    PLAN_NET_PREMIUM_AMT: "34841.31",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2448.69",
    PLAN_TOTAL_PREMIUM: "37430.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27100001",
    TOTAL_SUMINS_MAX: "27200000",
    PLAN_NET_PREMIUM_AMT: "34939.44",
    PLAN_STAMP_DUTY_AMT: "140.00",
    PLAN_VAT_AMT: "2455.56",
    PLAN_TOTAL_PREMIUM: "37535.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27200001",
    TOTAL_SUMINS_MAX: "27300000",
    PLAN_NET_PREMIUM_AMT: "35036.57",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2462.43",
    PLAN_TOTAL_PREMIUM: "37640.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27300001",
    TOTAL_SUMINS_MAX: "27400000",
    PLAN_NET_PREMIUM_AMT: "35134.70",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2469.30",
    PLAN_TOTAL_PREMIUM: "37745.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27400001",
    TOTAL_SUMINS_MAX: "27500000",
    PLAN_NET_PREMIUM_AMT: "35232.83",
    PLAN_STAMP_DUTY_AMT: "141.00",
    PLAN_VAT_AMT: "2476.17",
    PLAN_TOTAL_PREMIUM: "37850.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27500001",
    TOTAL_SUMINS_MAX: "27600000",
    PLAN_NET_PREMIUM_AMT: "35329.96",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2483.04",
    PLAN_TOTAL_PREMIUM: "37955.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27600001",
    TOTAL_SUMINS_MAX: "27700000",
    PLAN_NET_PREMIUM_AMT: "35428.09",
    PLAN_STAMP_DUTY_AMT: "142.00",
    PLAN_VAT_AMT: "2489.91",
    PLAN_TOTAL_PREMIUM: "38060.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27700001",
    TOTAL_SUMINS_MAX: "27800000",
    PLAN_NET_PREMIUM_AMT: "35525.22",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2496.78",
    PLAN_TOTAL_PREMIUM: "38165.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-27900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27800001",
    TOTAL_SUMINS_MAX: "27900000",
    PLAN_NET_PREMIUM_AMT: "35623.35",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2503.65",
    PLAN_TOTAL_PREMIUM: "38270.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "27900001",
    TOTAL_SUMINS_MAX: "28000000",
    PLAN_NET_PREMIUM_AMT: "35721.49",
    PLAN_STAMP_DUTY_AMT: "143.00",
    PLAN_VAT_AMT: "2510.51",
    PLAN_TOTAL_PREMIUM: "38375.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28000001",
    TOTAL_SUMINS_MAX: "28100000",
    PLAN_NET_PREMIUM_AMT: "35818.62",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2517.38",
    PLAN_TOTAL_PREMIUM: "38480.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28100001",
    TOTAL_SUMINS_MAX: "28200000",
    PLAN_NET_PREMIUM_AMT: "35916.75",
    PLAN_STAMP_DUTY_AMT: "144.00",
    PLAN_VAT_AMT: "2524.25",
    PLAN_TOTAL_PREMIUM: "38585.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28200001",
    TOTAL_SUMINS_MAX: "28300000",
    PLAN_NET_PREMIUM_AMT: "36013.88",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2531.12",
    PLAN_TOTAL_PREMIUM: "38690.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28300001",
    TOTAL_SUMINS_MAX: "28400000",
    PLAN_NET_PREMIUM_AMT: "36112.01",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2537.99",
    PLAN_TOTAL_PREMIUM: "38795.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28400001",
    TOTAL_SUMINS_MAX: "28500000",
    PLAN_NET_PREMIUM_AMT: "36210.14",
    PLAN_STAMP_DUTY_AMT: "145.00",
    PLAN_VAT_AMT: "2544.86",
    PLAN_TOTAL_PREMIUM: "38900.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28500001",
    TOTAL_SUMINS_MAX: "28600000",
    PLAN_NET_PREMIUM_AMT: "36307.27",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2551.73",
    PLAN_TOTAL_PREMIUM: "39005.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28600001",
    TOTAL_SUMINS_MAX: "28700000",
    PLAN_NET_PREMIUM_AMT: "36405.40",
    PLAN_STAMP_DUTY_AMT: "146.00",
    PLAN_VAT_AMT: "2558.60",
    PLAN_TOTAL_PREMIUM: "39110.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28700001",
    TOTAL_SUMINS_MAX: "28800000",
    PLAN_NET_PREMIUM_AMT: "36502.53",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2565.47",
    PLAN_TOTAL_PREMIUM: "39215.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-28900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28800001",
    TOTAL_SUMINS_MAX: "28900000",
    PLAN_NET_PREMIUM_AMT: "36600.66",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2572.34",
    PLAN_TOTAL_PREMIUM: "39320.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "28900001",
    TOTAL_SUMINS_MAX: "29000000",
    PLAN_NET_PREMIUM_AMT: "36698.79",
    PLAN_STAMP_DUTY_AMT: "147.00",
    PLAN_VAT_AMT: "2579.21",
    PLAN_TOTAL_PREMIUM: "39425.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29000001",
    TOTAL_SUMINS_MAX: "29100000",
    PLAN_NET_PREMIUM_AMT: "36795.92",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2586.08",
    PLAN_TOTAL_PREMIUM: "39530.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29100001",
    TOTAL_SUMINS_MAX: "29200000",
    PLAN_NET_PREMIUM_AMT: "36894.06",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2592.94",
    PLAN_TOTAL_PREMIUM: "39635.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29200001",
    TOTAL_SUMINS_MAX: "29300000",
    PLAN_NET_PREMIUM_AMT: "36992.19",
    PLAN_STAMP_DUTY_AMT: "148.00",
    PLAN_VAT_AMT: "2599.81",
    PLAN_TOTAL_PREMIUM: "39740.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29300001",
    TOTAL_SUMINS_MAX: "29400000",
    PLAN_NET_PREMIUM_AMT: "37089.32",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2606.68",
    PLAN_TOTAL_PREMIUM: "39845.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29400001",
    TOTAL_SUMINS_MAX: "29500000",
    PLAN_NET_PREMIUM_AMT: "37187.45",
    PLAN_STAMP_DUTY_AMT: "149.00",
    PLAN_VAT_AMT: "2613.55",
    PLAN_TOTAL_PREMIUM: "39950.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29500001",
    TOTAL_SUMINS_MAX: "29600000",
    PLAN_NET_PREMIUM_AMT: "37284.58",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2620.42",
    PLAN_TOTAL_PREMIUM: "40055.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29600001",
    TOTAL_SUMINS_MAX: "29700000",
    PLAN_NET_PREMIUM_AMT: "37382.71",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2627.29",
    PLAN_TOTAL_PREMIUM: "40160.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29700001",
    TOTAL_SUMINS_MAX: "29800000",
    PLAN_NET_PREMIUM_AMT: "37480.84",
    PLAN_STAMP_DUTY_AMT: "150.00",
    PLAN_VAT_AMT: "2634.16",
    PLAN_TOTAL_PREMIUM: "40265.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-29900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29800001",
    TOTAL_SUMINS_MAX: "29900000",
    PLAN_NET_PREMIUM_AMT: "37577.97",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2641.03",
    PLAN_TOTAL_PREMIUM: "40370.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "29900001",
    TOTAL_SUMINS_MAX: "30000000",
    PLAN_NET_PREMIUM_AMT: "37676.10",
    PLAN_STAMP_DUTY_AMT: "151.00",
    PLAN_VAT_AMT: "2647.90",
    PLAN_TOTAL_PREMIUM: "40475.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30000001",
    TOTAL_SUMINS_MAX: "30100000",
    PLAN_NET_PREMIUM_AMT: "37773.23",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2654.77",
    PLAN_TOTAL_PREMIUM: "40580.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30100001",
    TOTAL_SUMINS_MAX: "30200000",
    PLAN_NET_PREMIUM_AMT: "37871.36",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2661.64",
    PLAN_TOTAL_PREMIUM: "40685.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30200001",
    TOTAL_SUMINS_MAX: "30300000",
    PLAN_NET_PREMIUM_AMT: "37969.49",
    PLAN_STAMP_DUTY_AMT: "152.00",
    PLAN_VAT_AMT: "2668.51",
    PLAN_TOTAL_PREMIUM: "40790.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30300001",
    TOTAL_SUMINS_MAX: "30400000",
    PLAN_NET_PREMIUM_AMT: "38066.63",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2675.37",
    PLAN_TOTAL_PREMIUM: "40895.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30400001",
    TOTAL_SUMINS_MAX: "30500000",
    PLAN_NET_PREMIUM_AMT: "38164.76",
    PLAN_STAMP_DUTY_AMT: "153.00",
    PLAN_VAT_AMT: "2682.24",
    PLAN_TOTAL_PREMIUM: "41000.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30500001",
    TOTAL_SUMINS_MAX: "30600000",
    PLAN_NET_PREMIUM_AMT: "38261.89",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2689.11",
    PLAN_TOTAL_PREMIUM: "41105.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30600001",
    TOTAL_SUMINS_MAX: "30700000",
    PLAN_NET_PREMIUM_AMT: "38360.02",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2695.98",
    PLAN_TOTAL_PREMIUM: "41210.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30700001",
    TOTAL_SUMINS_MAX: "30800000",
    PLAN_NET_PREMIUM_AMT: "38458.15",
    PLAN_STAMP_DUTY_AMT: "154.00",
    PLAN_VAT_AMT: "2702.85",
    PLAN_TOTAL_PREMIUM: "41315.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-30900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30800001",
    TOTAL_SUMINS_MAX: "30900000",
    PLAN_NET_PREMIUM_AMT: "38555.28",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2709.72",
    PLAN_TOTAL_PREMIUM: "41420.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "30900001",
    TOTAL_SUMINS_MAX: "31000000",
    PLAN_NET_PREMIUM_AMT: "38653.41",
    PLAN_STAMP_DUTY_AMT: "155.00",
    PLAN_VAT_AMT: "2716.59",
    PLAN_TOTAL_PREMIUM: "41525.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31000001",
    TOTAL_SUMINS_MAX: "31100000",
    PLAN_NET_PREMIUM_AMT: "38750.54",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2723.46",
    PLAN_TOTAL_PREMIUM: "41630.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31100001",
    TOTAL_SUMINS_MAX: "31200000",
    PLAN_NET_PREMIUM_AMT: "38848.67",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2730.33",
    PLAN_TOTAL_PREMIUM: "41735.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31200001",
    TOTAL_SUMINS_MAX: "31300000",
    PLAN_NET_PREMIUM_AMT: "38946.80",
    PLAN_STAMP_DUTY_AMT: "156.00",
    PLAN_VAT_AMT: "2737.20",
    PLAN_TOTAL_PREMIUM: "41840.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31300001",
    TOTAL_SUMINS_MAX: "31400000",
    PLAN_NET_PREMIUM_AMT: "39043.93",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2744.07",
    PLAN_TOTAL_PREMIUM: "41945.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31400001",
    TOTAL_SUMINS_MAX: "31500000",
    PLAN_NET_PREMIUM_AMT: "39142.07",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2750.93",
    PLAN_TOTAL_PREMIUM: "42050.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31500001",
    TOTAL_SUMINS_MAX: "31600000",
    PLAN_NET_PREMIUM_AMT: "39240.20",
    PLAN_STAMP_DUTY_AMT: "157.00",
    PLAN_VAT_AMT: "2757.80",
    PLAN_TOTAL_PREMIUM: "42155.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31600001",
    TOTAL_SUMINS_MAX: "31700000",
    PLAN_NET_PREMIUM_AMT: "39337.33",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2764.67",
    PLAN_TOTAL_PREMIUM: "42260.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31700001",
    TOTAL_SUMINS_MAX: "31800000",
    PLAN_NET_PREMIUM_AMT: "39435.46",
    PLAN_STAMP_DUTY_AMT: "158.00",
    PLAN_VAT_AMT: "2771.54",
    PLAN_TOTAL_PREMIUM: "42365.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-31900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31800001",
    TOTAL_SUMINS_MAX: "31900000",
    PLAN_NET_PREMIUM_AMT: "39532.59",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2778.41",
    PLAN_TOTAL_PREMIUM: "42470.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "31900001",
    TOTAL_SUMINS_MAX: "32000000",
    PLAN_NET_PREMIUM_AMT: "39630.72",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2785.28",
    PLAN_TOTAL_PREMIUM: "42575.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32000001",
    TOTAL_SUMINS_MAX: "32100000",
    PLAN_NET_PREMIUM_AMT: "39728.85",
    PLAN_STAMP_DUTY_AMT: "159.00",
    PLAN_VAT_AMT: "2792.15",
    PLAN_TOTAL_PREMIUM: "42680.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32100001",
    TOTAL_SUMINS_MAX: "32200000",
    PLAN_NET_PREMIUM_AMT: "39825.98",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2799.02",
    PLAN_TOTAL_PREMIUM: "42785.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32200001",
    TOTAL_SUMINS_MAX: "32300000",
    PLAN_NET_PREMIUM_AMT: "39924.11",
    PLAN_STAMP_DUTY_AMT: "160.00",
    PLAN_VAT_AMT: "2805.89",
    PLAN_TOTAL_PREMIUM: "42890.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32300001",
    TOTAL_SUMINS_MAX: "32400000",
    PLAN_NET_PREMIUM_AMT: "40021.24",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2812.76",
    PLAN_TOTAL_PREMIUM: "42995.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32400001",
    TOTAL_SUMINS_MAX: "32500000",
    PLAN_NET_PREMIUM_AMT: "40119.37",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2819.63",
    PLAN_TOTAL_PREMIUM: "43100.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32500001",
    TOTAL_SUMINS_MAX: "32600000",
    PLAN_NET_PREMIUM_AMT: "40217.50",
    PLAN_STAMP_DUTY_AMT: "161.00",
    PLAN_VAT_AMT: "2826.50",
    PLAN_TOTAL_PREMIUM: "43205.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32600001",
    TOTAL_SUMINS_MAX: "32700000",
    PLAN_NET_PREMIUM_AMT: "40314.64",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2833.36",
    PLAN_TOTAL_PREMIUM: "43310.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32700001",
    TOTAL_SUMINS_MAX: "32800000",
    PLAN_NET_PREMIUM_AMT: "40412.77",
    PLAN_STAMP_DUTY_AMT: "162.00",
    PLAN_VAT_AMT: "2840.23",
    PLAN_TOTAL_PREMIUM: "43415.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-32900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32800001",
    TOTAL_SUMINS_MAX: "32900000",
    PLAN_NET_PREMIUM_AMT: "40509.90",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2847.10",
    PLAN_TOTAL_PREMIUM: "43520.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "32900001",
    TOTAL_SUMINS_MAX: "33000000",
    PLAN_NET_PREMIUM_AMT: "40608.03",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2853.97",
    PLAN_TOTAL_PREMIUM: "43625.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33000001",
    TOTAL_SUMINS_MAX: "33100000",
    PLAN_NET_PREMIUM_AMT: "40706.16",
    PLAN_STAMP_DUTY_AMT: "163.00",
    PLAN_VAT_AMT: "2860.84",
    PLAN_TOTAL_PREMIUM: "43730.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33100001",
    TOTAL_SUMINS_MAX: "33200000",
    PLAN_NET_PREMIUM_AMT: "40803.29",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2867.71",
    PLAN_TOTAL_PREMIUM: "43835.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33200001",
    TOTAL_SUMINS_MAX: "33300000",
    PLAN_NET_PREMIUM_AMT: "40901.42",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2874.58",
    PLAN_TOTAL_PREMIUM: "43940.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33300001",
    TOTAL_SUMINS_MAX: "33400000",
    PLAN_NET_PREMIUM_AMT: "40999.55",
    PLAN_STAMP_DUTY_AMT: "164.00",
    PLAN_VAT_AMT: "2881.45",
    PLAN_TOTAL_PREMIUM: "44045.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33400001",
    TOTAL_SUMINS_MAX: "33500000",
    PLAN_NET_PREMIUM_AMT: "41096.68",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2888.32",
    PLAN_TOTAL_PREMIUM: "44150.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33500001",
    TOTAL_SUMINS_MAX: "33600000",
    PLAN_NET_PREMIUM_AMT: "41194.81",
    PLAN_STAMP_DUTY_AMT: "165.00",
    PLAN_VAT_AMT: "2895.19",
    PLAN_TOTAL_PREMIUM: "44255.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33600001",
    TOTAL_SUMINS_MAX: "33700000",
    PLAN_NET_PREMIUM_AMT: "41291.94",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2902.06",
    PLAN_TOTAL_PREMIUM: "44360.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33700001",
    TOTAL_SUMINS_MAX: "33800000",
    PLAN_NET_PREMIUM_AMT: "41390.07",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2908.93",
    PLAN_TOTAL_PREMIUM: "44465.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-33900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33800001",
    TOTAL_SUMINS_MAX: "33900000",
    PLAN_NET_PREMIUM_AMT: "41488.21",
    PLAN_STAMP_DUTY_AMT: "166.00",
    PLAN_VAT_AMT: "2915.79",
    PLAN_TOTAL_PREMIUM: "44570.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "33900001",
    TOTAL_SUMINS_MAX: "34000000",
    PLAN_NET_PREMIUM_AMT: "41585.34",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2922.66",
    PLAN_TOTAL_PREMIUM: "44675.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34000001",
    TOTAL_SUMINS_MAX: "34100000",
    PLAN_NET_PREMIUM_AMT: "41683.47",
    PLAN_STAMP_DUTY_AMT: "167.00",
    PLAN_VAT_AMT: "2929.53",
    PLAN_TOTAL_PREMIUM: "44780.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34100001",
    TOTAL_SUMINS_MAX: "34200000",
    PLAN_NET_PREMIUM_AMT: "41780.60",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2936.40",
    PLAN_TOTAL_PREMIUM: "44885.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34200001",
    TOTAL_SUMINS_MAX: "34300000",
    PLAN_NET_PREMIUM_AMT: "41878.73",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2943.27",
    PLAN_TOTAL_PREMIUM: "44990.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34300001",
    TOTAL_SUMINS_MAX: "34400000",
    PLAN_NET_PREMIUM_AMT: "41976.86",
    PLAN_STAMP_DUTY_AMT: "168.00",
    PLAN_VAT_AMT: "2950.14",
    PLAN_TOTAL_PREMIUM: "45095.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34400001",
    TOTAL_SUMINS_MAX: "34500000",
    PLAN_NET_PREMIUM_AMT: "42073.99",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2957.01",
    PLAN_TOTAL_PREMIUM: "45200.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34500001",
    TOTAL_SUMINS_MAX: "34600000",
    PLAN_NET_PREMIUM_AMT: "42172.12",
    PLAN_STAMP_DUTY_AMT: "169.00",
    PLAN_VAT_AMT: "2963.88",
    PLAN_TOTAL_PREMIUM: "45305.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34600001",
    TOTAL_SUMINS_MAX: "34700000",
    PLAN_NET_PREMIUM_AMT: "42269.25",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2970.75",
    PLAN_TOTAL_PREMIUM: "45410.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34700001",
    TOTAL_SUMINS_MAX: "34800000",
    PLAN_NET_PREMIUM_AMT: "42367.38",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2977.62",
    PLAN_TOTAL_PREMIUM: "45515.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-34900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34800001",
    TOTAL_SUMINS_MAX: "34900000",
    PLAN_NET_PREMIUM_AMT: "42465.51",
    PLAN_STAMP_DUTY_AMT: "170.00",
    PLAN_VAT_AMT: "2984.49",
    PLAN_TOTAL_PREMIUM: "45620.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "34900001",
    TOTAL_SUMINS_MAX: "35000000",
    PLAN_NET_PREMIUM_AMT: "42562.64",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2991.36",
    PLAN_TOTAL_PREMIUM: "45725.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35000001",
    TOTAL_SUMINS_MAX: "35100000",
    PLAN_NET_PREMIUM_AMT: "42660.78",
    PLAN_STAMP_DUTY_AMT: "171.00",
    PLAN_VAT_AMT: "2998.22",
    PLAN_TOTAL_PREMIUM: "45830.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35100001",
    TOTAL_SUMINS_MAX: "35200000",
    PLAN_NET_PREMIUM_AMT: "42757.91",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3005.09",
    PLAN_TOTAL_PREMIUM: "45935.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35200001",
    TOTAL_SUMINS_MAX: "35300000",
    PLAN_NET_PREMIUM_AMT: "42856.04",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3011.96",
    PLAN_TOTAL_PREMIUM: "46040.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35300001",
    TOTAL_SUMINS_MAX: "35400000",
    PLAN_NET_PREMIUM_AMT: "42954.17",
    PLAN_STAMP_DUTY_AMT: "172.00",
    PLAN_VAT_AMT: "3018.83",
    PLAN_TOTAL_PREMIUM: "46145.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35400001",
    TOTAL_SUMINS_MAX: "35500000",
    PLAN_NET_PREMIUM_AMT: "43051.30",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3025.70",
    PLAN_TOTAL_PREMIUM: "46250.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35500001",
    TOTAL_SUMINS_MAX: "35600000",
    PLAN_NET_PREMIUM_AMT: "43149.43",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3032.57",
    PLAN_TOTAL_PREMIUM: "46355.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35600001",
    TOTAL_SUMINS_MAX: "35700000",
    PLAN_NET_PREMIUM_AMT: "43247.56",
    PLAN_STAMP_DUTY_AMT: "173.00",
    PLAN_VAT_AMT: "3039.44",
    PLAN_TOTAL_PREMIUM: "46460.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35700001",
    TOTAL_SUMINS_MAX: "35800000",
    PLAN_NET_PREMIUM_AMT: "43344.69",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3046.31",
    PLAN_TOTAL_PREMIUM: "46565.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-35900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35800001",
    TOTAL_SUMINS_MAX: "35900000",
    PLAN_NET_PREMIUM_AMT: "43442.82",
    PLAN_STAMP_DUTY_AMT: "174.00",
    PLAN_VAT_AMT: "3053.18",
    PLAN_TOTAL_PREMIUM: "46670.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "35900001",
    TOTAL_SUMINS_MAX: "36000000",
    PLAN_NET_PREMIUM_AMT: "43539.95",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3060.05",
    PLAN_TOTAL_PREMIUM: "46775.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36000001",
    TOTAL_SUMINS_MAX: "36100000",
    PLAN_NET_PREMIUM_AMT: "43638.08",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3066.92",
    PLAN_TOTAL_PREMIUM: "46880.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36100001",
    TOTAL_SUMINS_MAX: "36200000",
    PLAN_NET_PREMIUM_AMT: "43736.21",
    PLAN_STAMP_DUTY_AMT: "175.00",
    PLAN_VAT_AMT: "3073.79",
    PLAN_TOTAL_PREMIUM: "46985.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36200001",
    TOTAL_SUMINS_MAX: "36300000",
    PLAN_NET_PREMIUM_AMT: "43833.35",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3080.65",
    PLAN_TOTAL_PREMIUM: "47090.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36300001",
    TOTAL_SUMINS_MAX: "36400000",
    PLAN_NET_PREMIUM_AMT: "43931.48",
    PLAN_STAMP_DUTY_AMT: "176.00",
    PLAN_VAT_AMT: "3087.52",
    PLAN_TOTAL_PREMIUM: "47195.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36400001",
    TOTAL_SUMINS_MAX: "36500000",
    PLAN_NET_PREMIUM_AMT: "44028.61",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3094.39",
    PLAN_TOTAL_PREMIUM: "47300.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36500001",
    TOTAL_SUMINS_MAX: "36600000",
    PLAN_NET_PREMIUM_AMT: "44126.74",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3101.26",
    PLAN_TOTAL_PREMIUM: "47405.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36600001",
    TOTAL_SUMINS_MAX: "36700000",
    PLAN_NET_PREMIUM_AMT: "44224.87",
    PLAN_STAMP_DUTY_AMT: "177.00",
    PLAN_VAT_AMT: "3108.13",
    PLAN_TOTAL_PREMIUM: "47510.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36700001",
    TOTAL_SUMINS_MAX: "36800000",
    PLAN_NET_PREMIUM_AMT: "44322.00",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3115.00",
    PLAN_TOTAL_PREMIUM: "47615.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-36900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36800001",
    TOTAL_SUMINS_MAX: "36900000",
    PLAN_NET_PREMIUM_AMT: "44420.13",
    PLAN_STAMP_DUTY_AMT: "178.00",
    PLAN_VAT_AMT: "3121.87",
    PLAN_TOTAL_PREMIUM: "47720.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "36900001",
    TOTAL_SUMINS_MAX: "37000000",
    PLAN_NET_PREMIUM_AMT: "44517.26",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3128.74",
    PLAN_TOTAL_PREMIUM: "47825.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37000001",
    TOTAL_SUMINS_MAX: "37100000",
    PLAN_NET_PREMIUM_AMT: "44615.39",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3135.61",
    PLAN_TOTAL_PREMIUM: "47930.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37100001",
    TOTAL_SUMINS_MAX: "37200000",
    PLAN_NET_PREMIUM_AMT: "44713.52",
    PLAN_STAMP_DUTY_AMT: "179.00",
    PLAN_VAT_AMT: "3142.48",
    PLAN_TOTAL_PREMIUM: "48035.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37200001",
    TOTAL_SUMINS_MAX: "37300000",
    PLAN_NET_PREMIUM_AMT: "44810.65",
    PLAN_STAMP_DUTY_AMT: "180.00",
    PLAN_VAT_AMT: "3149.35",
    PLAN_TOTAL_PREMIUM: "48140.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37300001",
    TOTAL_SUMINS_MAX: "37400000",
    PLAN_NET_PREMIUM_AMT: "44908.78",
    PLAN_STAMP_DUTY_AMT: "180.00",
    PLAN_VAT_AMT: "3156.22",
    PLAN_TOTAL_PREMIUM: "48245.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37400001",
    TOTAL_SUMINS_MAX: "37500000",
    PLAN_NET_PREMIUM_AMT: "45005.92",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3163.08",
    PLAN_TOTAL_PREMIUM: "48350.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37500001",
    TOTAL_SUMINS_MAX: "37600000",
    PLAN_NET_PREMIUM_AMT: "45104.05",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3169.95",
    PLAN_TOTAL_PREMIUM: "48455.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37600001",
    TOTAL_SUMINS_MAX: "37700000",
    PLAN_NET_PREMIUM_AMT: "45202.18",
    PLAN_STAMP_DUTY_AMT: "181.00",
    PLAN_VAT_AMT: "3176.82",
    PLAN_TOTAL_PREMIUM: "48560.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37700001",
    TOTAL_SUMINS_MAX: "37800000",
    PLAN_NET_PREMIUM_AMT: "45299.31",
    PLAN_STAMP_DUTY_AMT: "182.00",
    PLAN_VAT_AMT: "3183.69",
    PLAN_TOTAL_PREMIUM: "48665.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-37900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37800001",
    TOTAL_SUMINS_MAX: "37900000",
    PLAN_NET_PREMIUM_AMT: "45397.44",
    PLAN_STAMP_DUTY_AMT: "182.00",
    PLAN_VAT_AMT: "3190.56",
    PLAN_TOTAL_PREMIUM: "48770.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "37900001",
    TOTAL_SUMINS_MAX: "38000000",
    PLAN_NET_PREMIUM_AMT: "45495.57",
    PLAN_STAMP_DUTY_AMT: "182.00",
    PLAN_VAT_AMT: "3197.43",
    PLAN_TOTAL_PREMIUM: "48875.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38000001",
    TOTAL_SUMINS_MAX: "38100000",
    PLAN_NET_PREMIUM_AMT: "45592.70",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3204.30",
    PLAN_TOTAL_PREMIUM: "48980.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38100001",
    TOTAL_SUMINS_MAX: "38200000",
    PLAN_NET_PREMIUM_AMT: "45690.83",
    PLAN_STAMP_DUTY_AMT: "183.00",
    PLAN_VAT_AMT: "3211.17",
    PLAN_TOTAL_PREMIUM: "49085.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38200001",
    TOTAL_SUMINS_MAX: "38300000",
    PLAN_NET_PREMIUM_AMT: "45787.96",
    PLAN_STAMP_DUTY_AMT: "184.00",
    PLAN_VAT_AMT: "3218.04",
    PLAN_TOTAL_PREMIUM: "49190.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38300001",
    TOTAL_SUMINS_MAX: "38400000",
    PLAN_NET_PREMIUM_AMT: "45886.09",
    PLAN_STAMP_DUTY_AMT: "184.00",
    PLAN_VAT_AMT: "3224.91",
    PLAN_TOTAL_PREMIUM: "49295.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38400001",
    TOTAL_SUMINS_MAX: "38500000",
    PLAN_NET_PREMIUM_AMT: "45984.22",
    PLAN_STAMP_DUTY_AMT: "184.00",
    PLAN_VAT_AMT: "3231.78",
    PLAN_TOTAL_PREMIUM: "49400.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38500001",
    TOTAL_SUMINS_MAX: "38600000",
    PLAN_NET_PREMIUM_AMT: "46081.35",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3238.65",
    PLAN_TOTAL_PREMIUM: "49505.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38600001",
    TOTAL_SUMINS_MAX: "38700000",
    PLAN_NET_PREMIUM_AMT: "46179.49",
    PLAN_STAMP_DUTY_AMT: "185.00",
    PLAN_VAT_AMT: "3245.51",
    PLAN_TOTAL_PREMIUM: "49610.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38700001",
    TOTAL_SUMINS_MAX: "38800000",
    PLAN_NET_PREMIUM_AMT: "46276.62",
    PLAN_STAMP_DUTY_AMT: "186.00",
    PLAN_VAT_AMT: "3252.38",
    PLAN_TOTAL_PREMIUM: "49715.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-38900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38800001",
    TOTAL_SUMINS_MAX: "38900000",
    PLAN_NET_PREMIUM_AMT: "46374.75",
    PLAN_STAMP_DUTY_AMT: "186.00",
    PLAN_VAT_AMT: "3259.25",
    PLAN_TOTAL_PREMIUM: "49820.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "38900001",
    TOTAL_SUMINS_MAX: "39000000",
    PLAN_NET_PREMIUM_AMT: "46472.88",
    PLAN_STAMP_DUTY_AMT: "186.00",
    PLAN_VAT_AMT: "3266.12",
    PLAN_TOTAL_PREMIUM: "49925.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39000001",
    TOTAL_SUMINS_MAX: "39100000",
    PLAN_NET_PREMIUM_AMT: "46570.01",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3272.99",
    PLAN_TOTAL_PREMIUM: "50030.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39100001",
    TOTAL_SUMINS_MAX: "39200000",
    PLAN_NET_PREMIUM_AMT: "46668.14",
    PLAN_STAMP_DUTY_AMT: "187.00",
    PLAN_VAT_AMT: "3279.86",
    PLAN_TOTAL_PREMIUM: "50135.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39200001",
    TOTAL_SUMINS_MAX: "39300000",
    PLAN_NET_PREMIUM_AMT: "46765.27",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3286.73",
    PLAN_TOTAL_PREMIUM: "50240.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39300001",
    TOTAL_SUMINS_MAX: "39400000",
    PLAN_NET_PREMIUM_AMT: "46863.40",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3293.60",
    PLAN_TOTAL_PREMIUM: "50345.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39400001",
    TOTAL_SUMINS_MAX: "39500000",
    PLAN_NET_PREMIUM_AMT: "46961.53",
    PLAN_STAMP_DUTY_AMT: "188.00",
    PLAN_VAT_AMT: "3300.47",
    PLAN_TOTAL_PREMIUM: "50450.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39500001",
    TOTAL_SUMINS_MAX: "39600000",
    PLAN_NET_PREMIUM_AMT: "47058.66",
    PLAN_STAMP_DUTY_AMT: "189.00",
    PLAN_VAT_AMT: "3307.34",
    PLAN_TOTAL_PREMIUM: "50555.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39600001",
    TOTAL_SUMINS_MAX: "39700000",
    PLAN_NET_PREMIUM_AMT: "47156.79",
    PLAN_STAMP_DUTY_AMT: "189.00",
    PLAN_VAT_AMT: "3314.21",
    PLAN_TOTAL_PREMIUM: "50660.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39700001",
    TOTAL_SUMINS_MAX: "39800000",
    PLAN_NET_PREMIUM_AMT: "47253.92",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3321.08",
    PLAN_TOTAL_PREMIUM: "50765.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-39900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39800001",
    TOTAL_SUMINS_MAX: "39900000",
    PLAN_NET_PREMIUM_AMT: "47352.06",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3327.94",
    PLAN_TOTAL_PREMIUM: "50870.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "39900001",
    TOTAL_SUMINS_MAX: "40000000",
    PLAN_NET_PREMIUM_AMT: "47450.19",
    PLAN_STAMP_DUTY_AMT: "190.00",
    PLAN_VAT_AMT: "3334.81",
    PLAN_TOTAL_PREMIUM: "50975.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40000001",
    TOTAL_SUMINS_MAX: "40100000",
    PLAN_NET_PREMIUM_AMT: "47547.32",
    PLAN_STAMP_DUTY_AMT: "191.00",
    PLAN_VAT_AMT: "3341.68",
    PLAN_TOTAL_PREMIUM: "51080.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40100001",
    TOTAL_SUMINS_MAX: "40200000",
    PLAN_NET_PREMIUM_AMT: "47645.45",
    PLAN_STAMP_DUTY_AMT: "191.00",
    PLAN_VAT_AMT: "3348.55",
    PLAN_TOTAL_PREMIUM: "51185.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40200001",
    TOTAL_SUMINS_MAX: "40300000",
    PLAN_NET_PREMIUM_AMT: "47743.58",
    PLAN_STAMP_DUTY_AMT: "191.00",
    PLAN_VAT_AMT: "3355.42",
    PLAN_TOTAL_PREMIUM: "51290.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40300001",
    TOTAL_SUMINS_MAX: "40400000",
    PLAN_NET_PREMIUM_AMT: "47840.71",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3362.29",
    PLAN_TOTAL_PREMIUM: "51395.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40400001",
    TOTAL_SUMINS_MAX: "40500000",
    PLAN_NET_PREMIUM_AMT: "47938.84",
    PLAN_STAMP_DUTY_AMT: "192.00",
    PLAN_VAT_AMT: "3369.16",
    PLAN_TOTAL_PREMIUM: "51500.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40500001",
    TOTAL_SUMINS_MAX: "40600000",
    PLAN_NET_PREMIUM_AMT: "48035.97",
    PLAN_STAMP_DUTY_AMT: "193.00",
    PLAN_VAT_AMT: "3376.03",
    PLAN_TOTAL_PREMIUM: "51605.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40600001",
    TOTAL_SUMINS_MAX: "40700000",
    PLAN_NET_PREMIUM_AMT: "48134.10",
    PLAN_STAMP_DUTY_AMT: "193.00",
    PLAN_VAT_AMT: "3382.90",
    PLAN_TOTAL_PREMIUM: "51710.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40700001",
    TOTAL_SUMINS_MAX: "40800000",
    PLAN_NET_PREMIUM_AMT: "48232.23",
    PLAN_STAMP_DUTY_AMT: "193.00",
    PLAN_VAT_AMT: "3389.77",
    PLAN_TOTAL_PREMIUM: "51815.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-40900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40800001",
    TOTAL_SUMINS_MAX: "40900000",
    PLAN_NET_PREMIUM_AMT: "48329.36",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3396.64",
    PLAN_TOTAL_PREMIUM: "51920.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "40900001",
    TOTAL_SUMINS_MAX: "41000000",
    PLAN_NET_PREMIUM_AMT: "48427.49",
    PLAN_STAMP_DUTY_AMT: "194.00",
    PLAN_VAT_AMT: "3403.51",
    PLAN_TOTAL_PREMIUM: "52025.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41000001",
    TOTAL_SUMINS_MAX: "41100000",
    PLAN_NET_PREMIUM_AMT: "48524.63",
    PLAN_STAMP_DUTY_AMT: "195.00",
    PLAN_VAT_AMT: "3410.37",
    PLAN_TOTAL_PREMIUM: "52130.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41100001",
    TOTAL_SUMINS_MAX: "41200000",
    PLAN_NET_PREMIUM_AMT: "48622.76",
    PLAN_STAMP_DUTY_AMT: "195.00",
    PLAN_VAT_AMT: "3417.24",
    PLAN_TOTAL_PREMIUM: "52235.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41200001",
    TOTAL_SUMINS_MAX: "41300000",
    PLAN_NET_PREMIUM_AMT: "48720.89",
    PLAN_STAMP_DUTY_AMT: "195.00",
    PLAN_VAT_AMT: "3424.11",
    PLAN_TOTAL_PREMIUM: "52340.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41300001",
    TOTAL_SUMINS_MAX: "41400000",
    PLAN_NET_PREMIUM_AMT: "48818.02",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3430.98",
    PLAN_TOTAL_PREMIUM: "52445.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41400001",
    TOTAL_SUMINS_MAX: "41500000",
    PLAN_NET_PREMIUM_AMT: "48916.15",
    PLAN_STAMP_DUTY_AMT: "196.00",
    PLAN_VAT_AMT: "3437.85",
    PLAN_TOTAL_PREMIUM: "52550.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41500001",
    TOTAL_SUMINS_MAX: "41600000",
    PLAN_NET_PREMIUM_AMT: "49013.28",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3444.72",
    PLAN_TOTAL_PREMIUM: "52655.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41600001",
    TOTAL_SUMINS_MAX: "41700000",
    PLAN_NET_PREMIUM_AMT: "49111.41",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3451.59",
    PLAN_TOTAL_PREMIUM: "52760.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41700001",
    TOTAL_SUMINS_MAX: "41800000",
    PLAN_NET_PREMIUM_AMT: "49209.54",
    PLAN_STAMP_DUTY_AMT: "197.00",
    PLAN_VAT_AMT: "3458.46",
    PLAN_TOTAL_PREMIUM: "52865.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-41900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41800001",
    TOTAL_SUMINS_MAX: "41900000",
    PLAN_NET_PREMIUM_AMT: "49306.67",
    PLAN_STAMP_DUTY_AMT: "198.00",
    PLAN_VAT_AMT: "3465.33",
    PLAN_TOTAL_PREMIUM: "52970.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "41900001",
    TOTAL_SUMINS_MAX: "42000000",
    PLAN_NET_PREMIUM_AMT: "49404.80",
    PLAN_STAMP_DUTY_AMT: "198.00",
    PLAN_VAT_AMT: "3472.20",
    PLAN_TOTAL_PREMIUM: "53075.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42000001",
    TOTAL_SUMINS_MAX: "42100000",
    PLAN_NET_PREMIUM_AMT: "49501.93",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3479.07",
    PLAN_TOTAL_PREMIUM: "53180.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42100001",
    TOTAL_SUMINS_MAX: "42200000",
    PLAN_NET_PREMIUM_AMT: "49600.07",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3485.93",
    PLAN_TOTAL_PREMIUM: "53285.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42200001",
    TOTAL_SUMINS_MAX: "42300000",
    PLAN_NET_PREMIUM_AMT: "49698.20",
    PLAN_STAMP_DUTY_AMT: "199.00",
    PLAN_VAT_AMT: "3492.80",
    PLAN_TOTAL_PREMIUM: "53390.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42300001",
    TOTAL_SUMINS_MAX: "42400000",
    PLAN_NET_PREMIUM_AMT: "49795.33",
    PLAN_STAMP_DUTY_AMT: "200.00",
    PLAN_VAT_AMT: "3499.67",
    PLAN_TOTAL_PREMIUM: "53495.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42400001",
    TOTAL_SUMINS_MAX: "42500000",
    PLAN_NET_PREMIUM_AMT: "49893.46",
    PLAN_STAMP_DUTY_AMT: "200.00",
    PLAN_VAT_AMT: "3506.54",
    PLAN_TOTAL_PREMIUM: "53600.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42500001",
    TOTAL_SUMINS_MAX: "42600000",
    PLAN_NET_PREMIUM_AMT: "49991.59",
    PLAN_STAMP_DUTY_AMT: "200.00",
    PLAN_VAT_AMT: "3513.41",
    PLAN_TOTAL_PREMIUM: "53705.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42600001",
    TOTAL_SUMINS_MAX: "42700000",
    PLAN_NET_PREMIUM_AMT: "50088.72",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3520.28",
    PLAN_TOTAL_PREMIUM: "53810.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42700001",
    TOTAL_SUMINS_MAX: "42800000",
    PLAN_NET_PREMIUM_AMT: "50186.85",
    PLAN_STAMP_DUTY_AMT: "201.00",
    PLAN_VAT_AMT: "3527.15",
    PLAN_TOTAL_PREMIUM: "53915.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-42900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42800001",
    TOTAL_SUMINS_MAX: "42900000",
    PLAN_NET_PREMIUM_AMT: "50283.98",
    PLAN_STAMP_DUTY_AMT: "202.00",
    PLAN_VAT_AMT: "3534.02",
    PLAN_TOTAL_PREMIUM: "54020.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "42900001",
    TOTAL_SUMINS_MAX: "43000000",
    PLAN_NET_PREMIUM_AMT: "50382.11",
    PLAN_STAMP_DUTY_AMT: "202.00",
    PLAN_VAT_AMT: "3540.89",
    PLAN_TOTAL_PREMIUM: "54125.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43000001",
    TOTAL_SUMINS_MAX: "43100000",
    PLAN_NET_PREMIUM_AMT: "50480.24",
    PLAN_STAMP_DUTY_AMT: "202.00",
    PLAN_VAT_AMT: "3547.76",
    PLAN_TOTAL_PREMIUM: "54230.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43100001",
    TOTAL_SUMINS_MAX: "43200000",
    PLAN_NET_PREMIUM_AMT: "50577.37",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3554.63",
    PLAN_TOTAL_PREMIUM: "54335.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43200001",
    TOTAL_SUMINS_MAX: "43300000",
    PLAN_NET_PREMIUM_AMT: "50675.50",
    PLAN_STAMP_DUTY_AMT: "203.00",
    PLAN_VAT_AMT: "3561.50",
    PLAN_TOTAL_PREMIUM: "54440.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43300001",
    TOTAL_SUMINS_MAX: "43400000",
    PLAN_NET_PREMIUM_AMT: "50772.64",
    PLAN_STAMP_DUTY_AMT: "204.00",
    PLAN_VAT_AMT: "3568.36",
    PLAN_TOTAL_PREMIUM: "54545.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43400001",
    TOTAL_SUMINS_MAX: "43500000",
    PLAN_NET_PREMIUM_AMT: "50870.77",
    PLAN_STAMP_DUTY_AMT: "204.00",
    PLAN_VAT_AMT: "3575.23",
    PLAN_TOTAL_PREMIUM: "54650.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43500001",
    TOTAL_SUMINS_MAX: "43600000",
    PLAN_NET_PREMIUM_AMT: "50968.90",
    PLAN_STAMP_DUTY_AMT: "204.00",
    PLAN_VAT_AMT: "3582.10",
    PLAN_TOTAL_PREMIUM: "54755.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43600001",
    TOTAL_SUMINS_MAX: "43700000",
    PLAN_NET_PREMIUM_AMT: "51066.03",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3588.97",
    PLAN_TOTAL_PREMIUM: "54860.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43700001",
    TOTAL_SUMINS_MAX: "43800000",
    PLAN_NET_PREMIUM_AMT: "51164.16",
    PLAN_STAMP_DUTY_AMT: "205.00",
    PLAN_VAT_AMT: "3595.84",
    PLAN_TOTAL_PREMIUM: "54965.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-43900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43800001",
    TOTAL_SUMINS_MAX: "43900000",
    PLAN_NET_PREMIUM_AMT: "51261.29",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3602.71",
    PLAN_TOTAL_PREMIUM: "55070.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "43900001",
    TOTAL_SUMINS_MAX: "44000000",
    PLAN_NET_PREMIUM_AMT: "51359.42",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3609.58",
    PLAN_TOTAL_PREMIUM: "55175.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44000001",
    TOTAL_SUMINS_MAX: "44100000",
    PLAN_NET_PREMIUM_AMT: "51457.55",
    PLAN_STAMP_DUTY_AMT: "206.00",
    PLAN_VAT_AMT: "3616.45",
    PLAN_TOTAL_PREMIUM: "55280.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44100001",
    TOTAL_SUMINS_MAX: "44200000",
    PLAN_NET_PREMIUM_AMT: "51554.68",
    PLAN_STAMP_DUTY_AMT: "207.00",
    PLAN_VAT_AMT: "3623.32",
    PLAN_TOTAL_PREMIUM: "55385.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44200001",
    TOTAL_SUMINS_MAX: "44300000",
    PLAN_NET_PREMIUM_AMT: "51652.81",
    PLAN_STAMP_DUTY_AMT: "207.00",
    PLAN_VAT_AMT: "3630.19",
    PLAN_TOTAL_PREMIUM: "55490.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44300001",
    TOTAL_SUMINS_MAX: "44400000",
    PLAN_NET_PREMIUM_AMT: "51749.94",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3637.06",
    PLAN_TOTAL_PREMIUM: "55595.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44400001",
    TOTAL_SUMINS_MAX: "44500000",
    PLAN_NET_PREMIUM_AMT: "51848.07",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3643.93",
    PLAN_TOTAL_PREMIUM: "55700.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44500001",
    TOTAL_SUMINS_MAX: "44600000",
    PLAN_NET_PREMIUM_AMT: "51946.21",
    PLAN_STAMP_DUTY_AMT: "208.00",
    PLAN_VAT_AMT: "3650.79",
    PLAN_TOTAL_PREMIUM: "55805.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44600001",
    TOTAL_SUMINS_MAX: "44700000",
    PLAN_NET_PREMIUM_AMT: "52043.34",
    PLAN_STAMP_DUTY_AMT: "209.00",
    PLAN_VAT_AMT: "3657.66",
    PLAN_TOTAL_PREMIUM: "55910.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44700001",
    TOTAL_SUMINS_MAX: "44800000",
    PLAN_NET_PREMIUM_AMT: "52141.47",
    PLAN_STAMP_DUTY_AMT: "209.00",
    PLAN_VAT_AMT: "3664.53",
    PLAN_TOTAL_PREMIUM: "56015.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-44900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44800001",
    TOTAL_SUMINS_MAX: "44900000",
    PLAN_NET_PREMIUM_AMT: "52239.60",
    PLAN_STAMP_DUTY_AMT: "209.00",
    PLAN_VAT_AMT: "3671.40",
    PLAN_TOTAL_PREMIUM: "56120.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "44900001",
    TOTAL_SUMINS_MAX: "45000000",
    PLAN_NET_PREMIUM_AMT: "52336.73",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3678.27",
    PLAN_TOTAL_PREMIUM: "56225.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45000001",
    TOTAL_SUMINS_MAX: "45100000",
    PLAN_NET_PREMIUM_AMT: "52434.86",
    PLAN_STAMP_DUTY_AMT: "210.00",
    PLAN_VAT_AMT: "3685.14",
    PLAN_TOTAL_PREMIUM: "56330.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45100001",
    TOTAL_SUMINS_MAX: "45200000",
    PLAN_NET_PREMIUM_AMT: "52531.99",
    PLAN_STAMP_DUTY_AMT: "211.00",
    PLAN_VAT_AMT: "3692.01",
    PLAN_TOTAL_PREMIUM: "56435.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45200001",
    TOTAL_SUMINS_MAX: "45300000",
    PLAN_NET_PREMIUM_AMT: "52630.12",
    PLAN_STAMP_DUTY_AMT: "211.00",
    PLAN_VAT_AMT: "3698.88",
    PLAN_TOTAL_PREMIUM: "56540.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45300001",
    TOTAL_SUMINS_MAX: "45400000",
    PLAN_NET_PREMIUM_AMT: "52728.25",
    PLAN_STAMP_DUTY_AMT: "211.00",
    PLAN_VAT_AMT: "3705.75",
    PLAN_TOTAL_PREMIUM: "56645.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45400001",
    TOTAL_SUMINS_MAX: "45500000",
    PLAN_NET_PREMIUM_AMT: "52825.38",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3712.62",
    PLAN_TOTAL_PREMIUM: "56750.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45500001",
    TOTAL_SUMINS_MAX: "45600000",
    PLAN_NET_PREMIUM_AMT: "52923.51",
    PLAN_STAMP_DUTY_AMT: "212.00",
    PLAN_VAT_AMT: "3719.49",
    PLAN_TOTAL_PREMIUM: "56855.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45600001",
    TOTAL_SUMINS_MAX: "45700000",
    PLAN_NET_PREMIUM_AMT: "53020.64",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3726.36",
    PLAN_TOTAL_PREMIUM: "56960.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45700001",
    TOTAL_SUMINS_MAX: "45800000",
    PLAN_NET_PREMIUM_AMT: "53118.78",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3733.22",
    PLAN_TOTAL_PREMIUM: "57065.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-45900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45800001",
    TOTAL_SUMINS_MAX: "45900000",
    PLAN_NET_PREMIUM_AMT: "53216.91",
    PLAN_STAMP_DUTY_AMT: "213.00",
    PLAN_VAT_AMT: "3740.09",
    PLAN_TOTAL_PREMIUM: "57170.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "45900001",
    TOTAL_SUMINS_MAX: "46000000",
    PLAN_NET_PREMIUM_AMT: "53314.04",
    PLAN_STAMP_DUTY_AMT: "214.00",
    PLAN_VAT_AMT: "3746.96",
    PLAN_TOTAL_PREMIUM: "57275.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46000001",
    TOTAL_SUMINS_MAX: "46100000",
    PLAN_NET_PREMIUM_AMT: "53412.17",
    PLAN_STAMP_DUTY_AMT: "214.00",
    PLAN_VAT_AMT: "3753.83",
    PLAN_TOTAL_PREMIUM: "57380.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46100001",
    TOTAL_SUMINS_MAX: "46200000",
    PLAN_NET_PREMIUM_AMT: "53509.30",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3760.70",
    PLAN_TOTAL_PREMIUM: "57485.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46200001",
    TOTAL_SUMINS_MAX: "46300000",
    PLAN_NET_PREMIUM_AMT: "53607.43",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3767.57",
    PLAN_TOTAL_PREMIUM: "57590.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46300001",
    TOTAL_SUMINS_MAX: "46400000",
    PLAN_NET_PREMIUM_AMT: "53705.56",
    PLAN_STAMP_DUTY_AMT: "215.00",
    PLAN_VAT_AMT: "3774.44",
    PLAN_TOTAL_PREMIUM: "57695.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46400001",
    TOTAL_SUMINS_MAX: "46500000",
    PLAN_NET_PREMIUM_AMT: "53802.69",
    PLAN_STAMP_DUTY_AMT: "216.00",
    PLAN_VAT_AMT: "3781.31",
    PLAN_TOTAL_PREMIUM: "57800.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46500001",
    TOTAL_SUMINS_MAX: "46600000",
    PLAN_NET_PREMIUM_AMT: "53900.82",
    PLAN_STAMP_DUTY_AMT: "216.00",
    PLAN_VAT_AMT: "3788.18",
    PLAN_TOTAL_PREMIUM: "57905.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46600001",
    TOTAL_SUMINS_MAX: "46700000",
    PLAN_NET_PREMIUM_AMT: "53998.95",
    PLAN_STAMP_DUTY_AMT: "216.00",
    PLAN_VAT_AMT: "3795.05",
    PLAN_TOTAL_PREMIUM: "58010.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46700001",
    TOTAL_SUMINS_MAX: "46800000",
    PLAN_NET_PREMIUM_AMT: "54096.08",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3801.92",
    PLAN_TOTAL_PREMIUM: "58115.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-46900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46800001",
    TOTAL_SUMINS_MAX: "46900000",
    PLAN_NET_PREMIUM_AMT: "54194.21",
    PLAN_STAMP_DUTY_AMT: "217.00",
    PLAN_VAT_AMT: "3808.79",
    PLAN_TOTAL_PREMIUM: "58220.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "46900001",
    TOTAL_SUMINS_MAX: "47000000",
    PLAN_NET_PREMIUM_AMT: "54291.35",
    PLAN_STAMP_DUTY_AMT: "218.00",
    PLAN_VAT_AMT: "3815.65",
    PLAN_TOTAL_PREMIUM: "58325.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47000001",
    TOTAL_SUMINS_MAX: "47100000",
    PLAN_NET_PREMIUM_AMT: "54389.48",
    PLAN_STAMP_DUTY_AMT: "218.00",
    PLAN_VAT_AMT: "3822.52",
    PLAN_TOTAL_PREMIUM: "58430.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47100001",
    TOTAL_SUMINS_MAX: "47200000",
    PLAN_NET_PREMIUM_AMT: "54487.61",
    PLAN_STAMP_DUTY_AMT: "218.00",
    PLAN_VAT_AMT: "3829.39",
    PLAN_TOTAL_PREMIUM: "58535.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47200001",
    TOTAL_SUMINS_MAX: "47300000",
    PLAN_NET_PREMIUM_AMT: "54584.74",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3836.26",
    PLAN_TOTAL_PREMIUM: "58640.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47300001",
    TOTAL_SUMINS_MAX: "47400000",
    PLAN_NET_PREMIUM_AMT: "54682.87",
    PLAN_STAMP_DUTY_AMT: "219.00",
    PLAN_VAT_AMT: "3843.13",
    PLAN_TOTAL_PREMIUM: "58745.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47400001",
    TOTAL_SUMINS_MAX: "47500000",
    PLAN_NET_PREMIUM_AMT: "54780.00",
    PLAN_STAMP_DUTY_AMT: "220.00",
    PLAN_VAT_AMT: "3850.00",
    PLAN_TOTAL_PREMIUM: "58850.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47500001",
    TOTAL_SUMINS_MAX: "47600000",
    PLAN_NET_PREMIUM_AMT: "54878.13",
    PLAN_STAMP_DUTY_AMT: "220.00",
    PLAN_VAT_AMT: "3856.87",
    PLAN_TOTAL_PREMIUM: "58955.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47600001",
    TOTAL_SUMINS_MAX: "47700000",
    PLAN_NET_PREMIUM_AMT: "54976.26",
    PLAN_STAMP_DUTY_AMT: "220.00",
    PLAN_VAT_AMT: "3863.74",
    PLAN_TOTAL_PREMIUM: "59060.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47700001",
    TOTAL_SUMINS_MAX: "47800000",
    PLAN_NET_PREMIUM_AMT: "55073.39",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3870.61",
    PLAN_TOTAL_PREMIUM: "59165.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-47900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47800001",
    TOTAL_SUMINS_MAX: "47900000",
    PLAN_NET_PREMIUM_AMT: "55171.52",
    PLAN_STAMP_DUTY_AMT: "221.00",
    PLAN_VAT_AMT: "3877.48",
    PLAN_TOTAL_PREMIUM: "59270.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "47900001",
    TOTAL_SUMINS_MAX: "48000000",
    PLAN_NET_PREMIUM_AMT: "55268.65",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3884.35",
    PLAN_TOTAL_PREMIUM: "59375.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48000001",
    TOTAL_SUMINS_MAX: "48100000",
    PLAN_NET_PREMIUM_AMT: "55366.78",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3891.22",
    PLAN_TOTAL_PREMIUM: "59480.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48100001",
    TOTAL_SUMINS_MAX: "48200000",
    PLAN_NET_PREMIUM_AMT: "55464.92",
    PLAN_STAMP_DUTY_AMT: "222.00",
    PLAN_VAT_AMT: "3898.08",
    PLAN_TOTAL_PREMIUM: "59585.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48200001",
    TOTAL_SUMINS_MAX: "48300000",
    PLAN_NET_PREMIUM_AMT: "55562.05",
    PLAN_STAMP_DUTY_AMT: "223.00",
    PLAN_VAT_AMT: "3904.95",
    PLAN_TOTAL_PREMIUM: "59690.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48300001",
    TOTAL_SUMINS_MAX: "48400000",
    PLAN_NET_PREMIUM_AMT: "55660.18",
    PLAN_STAMP_DUTY_AMT: "223.00",
    PLAN_VAT_AMT: "3911.82",
    PLAN_TOTAL_PREMIUM: "59795.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48400001",
    TOTAL_SUMINS_MAX: "48500000",
    PLAN_NET_PREMIUM_AMT: "55757.31",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3918.69",
    PLAN_TOTAL_PREMIUM: "59900.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48500001",
    TOTAL_SUMINS_MAX: "48600000",
    PLAN_NET_PREMIUM_AMT: "55855.44",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3925.56",
    PLAN_TOTAL_PREMIUM: "60005.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48600001",
    TOTAL_SUMINS_MAX: "48700000",
    PLAN_NET_PREMIUM_AMT: "55953.57",
    PLAN_STAMP_DUTY_AMT: "224.00",
    PLAN_VAT_AMT: "3932.43",
    PLAN_TOTAL_PREMIUM: "60110.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48700001",
    TOTAL_SUMINS_MAX: "48800000",
    PLAN_NET_PREMIUM_AMT: "56050.70",
    PLAN_STAMP_DUTY_AMT: "225.00",
    PLAN_VAT_AMT: "3939.30",
    PLAN_TOTAL_PREMIUM: "60215.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-48900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48800001",
    TOTAL_SUMINS_MAX: "48900000",
    PLAN_NET_PREMIUM_AMT: "56148.83",
    PLAN_STAMP_DUTY_AMT: "225.00",
    PLAN_VAT_AMT: "3946.17",
    PLAN_TOTAL_PREMIUM: "60320.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "48900001",
    TOTAL_SUMINS_MAX: "49000000",
    PLAN_NET_PREMIUM_AMT: "56246.96",
    PLAN_STAMP_DUTY_AMT: "225.00",
    PLAN_VAT_AMT: "3953.04",
    PLAN_TOTAL_PREMIUM: "60425.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49100000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49000001",
    TOTAL_SUMINS_MAX: "49100000",
    PLAN_NET_PREMIUM_AMT: "56344.09",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3959.91",
    PLAN_TOTAL_PREMIUM: "60530.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49200000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49100001",
    TOTAL_SUMINS_MAX: "49200000",
    PLAN_NET_PREMIUM_AMT: "56442.22",
    PLAN_STAMP_DUTY_AMT: "226.00",
    PLAN_VAT_AMT: "3966.78",
    PLAN_TOTAL_PREMIUM: "60635.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49300000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49200001",
    TOTAL_SUMINS_MAX: "49300000",
    PLAN_NET_PREMIUM_AMT: "56539.35",
    PLAN_STAMP_DUTY_AMT: "227.00",
    PLAN_VAT_AMT: "3973.65",
    PLAN_TOTAL_PREMIUM: "60740.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49400000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49300001",
    TOTAL_SUMINS_MAX: "49400000",
    PLAN_NET_PREMIUM_AMT: "56637.49",
    PLAN_STAMP_DUTY_AMT: "227.00",
    PLAN_VAT_AMT: "3980.51",
    PLAN_TOTAL_PREMIUM: "60845.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49500000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49400001",
    TOTAL_SUMINS_MAX: "49500000",
    PLAN_NET_PREMIUM_AMT: "56735.62",
    PLAN_STAMP_DUTY_AMT: "227.00",
    PLAN_VAT_AMT: "3987.38",
    PLAN_TOTAL_PREMIUM: "60950.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49600000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49500001",
    TOTAL_SUMINS_MAX: "49600000",
    PLAN_NET_PREMIUM_AMT: "56832.75",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "3994.25",
    PLAN_TOTAL_PREMIUM: "61055.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49700000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49600001",
    TOTAL_SUMINS_MAX: "49700000",
    PLAN_NET_PREMIUM_AMT: "56930.88",
    PLAN_STAMP_DUTY_AMT: "228.00",
    PLAN_VAT_AMT: "4001.12",
    PLAN_TOTAL_PREMIUM: "61160.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49800000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49700001",
    TOTAL_SUMINS_MAX: "49800000",
    PLAN_NET_PREMIUM_AMT: "57028.01",
    PLAN_STAMP_DUTY_AMT: "229.00",
    PLAN_VAT_AMT: "4007.99",
    PLAN_TOTAL_PREMIUM: "61265.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-49900000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49800001",
    TOTAL_SUMINS_MAX: "49900000",
    PLAN_NET_PREMIUM_AMT: "57126.14",
    PLAN_STAMP_DUTY_AMT: "229.00",
    PLAN_VAT_AMT: "4014.86",
    PLAN_TOTAL_PREMIUM: "61370.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  },
  {
    PLAN_PREMIUM_CODE: "SME-SABAI-50000000",
    PLAN_TYPE_ID: "SME-SABAI",
    TOTAL_SUMINS_MIN: "49900001",
    TOTAL_SUMINS_MAX: "50000000",
    PLAN_NET_PREMIUM_AMT: "57224.27",
    PLAN_STAMP_DUTY_AMT: "229.00",
    PLAN_VAT_AMT: "4021.73",
    PLAN_TOTAL_PREMIUM: "61475.00",
    INDUSTRY_CODE: "FNB",
    INDUSTRY_DESC: "Food and Beverage"
  }
]

const planCovidNewList = [{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "TEST-SMK",
  PLAN_DESC: "แผน 1 (เกินคุ้ม) ทดสอบ",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 50,000 บาท ทดสอบ",
  PLAN_NET_PREMIUM_AMT: "184.98",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "13.02",
  PLAN_TOTAL_PREMIUM: "0.5",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-250-450",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "TEST-SEI",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน 260 บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 50,000 บาท และอุบัติเหตุ (อ.บ.1) 50,000 บาท",
  PLAN_NET_PREMIUM_AMT: "240.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "0.5",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_PA: "50000",
  PLAN_PREFIX_COVERNOTE: "MI",
  PRODUCT_STATUS: "ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-260-519-778-1037",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-260",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน 260 บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 50,000 บาท และอุบัติเหตุ (อ.บ.1) 50,000 บาท",
  PLAN_NET_PREMIUM_AMT: "240.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "260",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: false,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "50000",
  PLAN_PREFIX_COVERNOTE: "MI",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-260-519-778-1037",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-519",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน 519 บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 100,000 บาท และอุบัติเหตุ (อ.บ.1) 100,000 บาท",
  PLAN_NET_PREMIUM_AMT: "499.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "519",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: false,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "100000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "100000",
  PLAN_PREFIX_COVERNOTE: "MI",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-260-519-778-1037",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-778",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน 778 บาท ตรวจพบรับ 150,000 บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 150,000 บาท และอุบัติเหตุ (อ.บ.1) 150,000 บาท",
  PLAN_NET_PREMIUM_AMT: "758.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "778",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: false,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "150000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "150000",
  PLAN_PREFIX_COVERNOTE: "MI",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-260-519-778-1037",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-1037",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน 1,037 บาท ตรวจพบรับ 200,000 บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 200,000 บาท และอุบัติเหตุ (อ.บ.1) 200,000 บาท",
  PLAN_NET_PREMIUM_AMT: "1017.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "1037",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: false,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "200000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "200000",
  PLAN_PREFIX_COVERNOTE: "MI",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-260-519-778-1037",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-PLUS-369",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน PLUS 369 บาท (แผน 1)",
  PLAN_COV_TITLE: "ตรวจเจอรับเลย 50,000 บาท โคม่า 100,000 บาท ค่ารักษา 10,000 บาท อุบัติเหตุทั่วไป 10,000 บาท และเงินปลอบขวัญแพ้วัคซีน 50,000 บาท",
  PLAN_NET_PREMIUM_AMT: "349.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "369",
  PLAN_SCORE: "3",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "10000",
  PLAN_COMA_PAY_AMT: "100000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_GIFT: "50000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "10000",
  PLAN_PREFIX_COVERNOTE: "CP643",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-PLUS-369-639-799-1369",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-PLUS-639",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน PLUS 639 บาท (แผน 2)",
  PLAN_COV_TITLE: "ตรวจเจอรับเลย 50,000 บาท โคม่า 500,000 บาท ค่ารักษา 50,000 บาท อุบัติเหตุทั่วไป 50,000 บาท และเงินปลอบขวัญแพ้วัคซีน 50,000 บาท",
  PLAN_NET_PREMIUM_AMT: "619.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "639",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "50000",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_GIFT: "50000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "50000",
  PLAN_PREFIX_COVERNOTE: "CP643",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-PLUS-369-639-799-1369",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-PLUS-799",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน PLUS 799 บาท (แผน 3)",
  PLAN_COV_TITLE: "ตรวจเจอรับเลย 50,000 บาท โคม่า 500,000 บาท ค่ารักษา 50,000 บาท อุบัติเหตุทั่วไป 10,000 บาท ชดเชยรายได้ 500 บาท/วัน (สูงสุด 7,500 บาท) และเงินปลอบขวัญแพ้วัคซีน 50,000 บาท",
  PLAN_NET_PREMIUM_AMT: "779.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "799",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "50000",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_COMPENSTAION_DAY: "15",
  PLAN_COMPENSTAION_TOTAL_AMT: "7500",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_GIFT: "50000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "10000",
  PLAN_PREFIX_COVERNOTE: "CP643",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-PLUS-369-639-799-1369",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SEI",
  PLAN_ISSUER: "อาคเนย์ประกันภัย",
  PLAN_ID: "SEI-PLUS-1369",
  PLAN_DESC: "อาคเนย์ประกันภัยแผน PLUS 1,369 บาท (แผน 4)",
  PLAN_COV_TITLE: "ตรวจเจอรับเลย 50,000 บาท โคม่า 1,000,000 บาท ค่ารักษา 100,000 บาท อุบัติเหตุทั่วไป 50,000 บาท ชดเชยรายได้ 1,000 บาท/วัน (สูงสุด 15,000 บาท) และเงินปลอบขวัญแพ้วัคซีน 50,000 บาท",
  PLAN_NET_PREMIUM_AMT: "1349.00",
  PLAN_STAMP_DUTY_AMT: "20",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "1369",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "100000",
  PLAN_COMA_PAY_AMT: "1000000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "1000",
  PLAN_COMPENSTAION_DAY: "15",
  PLAN_COMPENSTAION_TOTAL_AMT: "15000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_GIFT: "50000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "50000",
  PLAN_PREFIX_COVERNOTE: "CP643",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SEI-PLUS-369-639-799-1369",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-250",
  PLAN_DESC: "แผน 1 (เกินคุ้ม)",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 50,000 บาท",
  PLAN_NET_PREMIUM_AMT: "184.98",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "13.02",
  PLAN_TOTAL_PREMIUM: "199",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-250-450",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-450",
  PLAN_DESC: "แผน 2 (สุดคุ้ม)",
  PLAN_COV_TITLE: "เจอ จ่าย จบ 100,000 บาท",
  PLAN_NET_PREMIUM_AMT: "370.90",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "26.10",
  PLAN_TOTAL_PREMIUM: "399",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_DIAGNOSTIC: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "100000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-250-450",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-3-IN-1-300",
  PLAN_DESC: "3 in 1 แผน 1 (300 บาท) อายุ 1-14 ปี",
  PLAN_COV_TITLE: "ค่ารักษา COVID-19 50,000 บาท ค่ารักษา 9 โรคฮิต* กรณี IPD ตามจริงไม่เกิน 10,000 บาท เงินชดเชย 1,000 บาท/วัน",
  PLAN_NET_PREMIUM_AMT: "278.37",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "19.63",
  PLAN_TOTAL_PREMIUM: "300",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_AGE_CONDITION: "1-14 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "50000",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "1000",
  PLAN_COMPENSTAION_DAY: "30",
  PLAN_COMPENSTAION_TOTAL_AMT: "30000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-3-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-3-IN-1-500",
  PLAN_DESC: "3 in 1 แผน 2 (500 บาท) อายุ 1-14 ปี",
  PLAN_COV_TITLE: "ค่ารักษา COVID-19 100,000 บาท ค่ารักษา 9 โรคฮิต* กรณี IPD ตามจริงไม่เกิน 20,000 บาท เงินชดเชย 1,000 บาท/วัน",
  PLAN_NET_PREMIUM_AMT: "465.29",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "32.71",
  PLAN_TOTAL_PREMIUM: "500",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_AGE_CONDITION: "1-14 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "100000",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "1000",
  PLAN_COMPENSTAION_DAY: "30",
  PLAN_COMPENSTAION_TOTAL_AMT: "30000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-3-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-3-IN-1-250",
  PLAN_DESC: "3 in 1 แผน 1 (250 บาท) อายุ 15-99 ปี",
  PLAN_COV_TITLE: "ค่ารักษา COVID-19 50,000 บาท ค่ารักษา 9 โรคฮิต* กรณี IPD ตามจริงไม่เกิน 10,000 บาท เงินชดเชย 1,000 บาท/วัน",
  PLAN_NET_PREMIUM_AMT: "232.64",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "16.36",
  PLAN_TOTAL_PREMIUM: "250",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_AGE_CONDITION: "15-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "50000",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "1000",
  PLAN_COMPENSTAION_DAY: "30",
  PLAN_COMPENSTAION_TOTAL_AMT: "30000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-3-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-3-IN-1-450",
  PLAN_DESC: "3 in 1 แผน 2 (450 บาท) อายุ 15-99 ปี",
  PLAN_COV_TITLE: "ค่ารักษา COVID-19 100,000 บาท ค่ารักษา 9 โรคฮิต* กรณี IPD ตามจริงไม่เกิน 20,000 บาท เงินชดเชย 1,000 บาท/วัน",
  PLAN_NET_PREMIUM_AMT: "418.56",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "29.44",
  PLAN_TOTAL_PREMIUM: "450",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_AGE_CONDITION: "15-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "100000",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "1000",
  PLAN_COMPENSTAION_DAY: "30",
  PLAN_COMPENSTAION_TOTAL_AMT: "30000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-3-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-2-IN-1-329",
  PLAN_DESC: "ประกันภัยโควิด 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 1 ราคา 329	บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 50,000 บาท รวมแพ้วัคซีน",
  PLAN_COV_DETAIL: "เจอ จ่าย 50,000 บาท รักษาพยาบาลกรณีผู้ป่วยใน (IPD) จากการแพ้วัคซีนโควิด 50,000 บาท  ค่าห้อง ค่าอาหาร และค่าบริการพยาบาล (ไม่เกินวันละ) 1,500 บาท โคม่าจากการได้รับวัคซีน 500,000 บาท ค่าชดเชยรายวัน จากการแพ้วัคซีนโควิด (วันละ 500 บาท) (สูงสุด 30 วันต่อปี) ค่าปลอบขวัญ กรณีรักษาตัวเป็นผู้ป่วยในโรงพยาบาล เกินกว่า 5 วัน จากการแพ้วัคซีนโควิด 25,000 บาท ",
  PLAN_NET_PREMIUM_AMT: "305.48",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "21.52",
  PLAN_TOTAL_PREMIUM: "329",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "50000",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_VACCINE_COMPENSTAION_DAY: "30",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "15000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-2-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-2-IN-1-529",
  PLAN_DESC: "ประกันภัยโควิด 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 2 ราคา 529	บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 100,000 บาท รวมแพ้วัคซีน",
  PLAN_COV_DETAIL: "เจอ จ่าย 100,000 บาท รักษาพยาบาลกรณีผู้ป่วยใน (IPD) จากการแพ้วัคซีนโควิด 50,000 บาท  ค่าห้อง ค่าอาหาร และค่าบริการพยาบาล (ไม่เกินวันละ) 1,500 บาท โคม่าจากการได้รับวัคซีน 500,000 บาท ค่าชดเชยรายวัน จากการแพ้วัคซีนโควิด (วันละ 500 บาท) (สูงสุด 30 วันต่อปี) ค่าปลอบขวัญ กรณีรักษาตัวเป็นผู้ป่วยในโรงพยาบาล เกินกว่า 5 วัน จากการแพ้วัคซีนโควิด 25,000 บาท ",
  PLAN_NET_PREMIUM_AMT: "492.39",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "34.61",
  PLAN_TOTAL_PREMIUM: "529",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_DIAGNOSTIC: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "100000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "50000",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_VACCINE_COMPENSTAION_DAY: "30",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "15000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-2-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-2-IN-1-489",
  PLAN_DESC: "ประกันภัยโควิด 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 3 ราคา 489	บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 50,000 บาท รวมแพ้วัคซีน (OPD)",
  PLAN_COV_DETAIL: "เจอ จ่าย 50,000 บาท รักษาพยาบาลกรณีผู้ป่วยใน (IPD) จากการแพ้วัคซีนโควิด 50,000 บาท  ค่าห้อง ค่าอาหาร และค่าบริการพยาบาล (ไม่เกินวันละ) 1,500 บาท รักษาพยาบาลกรณีผู้ป่วยนอก (OPD) จากการแพ้วัคซีนโควิด (ครั้งละไม่เกิน 500 บาท) โคม่าจากการได้รับวัคซีน 500,000 บาท ค่าชดเชยรายวัน จากการแพ้วัคซีนโควิด (วันละ 500 บาท) (สูงสุด 30 วันต่อปี) ค่าปลอบขวัญ กรณีรักษาตัวเป็นผู้ป่วยในโรงพยาบาล เกินกว่า 5 วัน จากการแพ้วัคซีนโควิด 25,000 บาท ",
  PLAN_NET_PREMIUM_AMT: "455.01",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "31.99",
  PLAN_TOTAL_PREMIUM: "489",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "50000",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_VACCINE_COMPENSTAION_DAY: "30",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "15000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-2-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "SMK",
  PLAN_ISSUER: "สินมั่นคงประกันภัย",
  PLAN_ID: "SMK-COVID-2-IN-1-689",
  PLAN_DESC: "ประกันภัยโควิด 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 4 ราคา 689	บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 100,000 บาท รวมแพ้วัคซีน (OPD)",
  PLAN_COV_DETAIL: "เจอ จ่าย 100,000 บาท รักษาพยาบาลกรณีผู้ป่วยใน (IPD) จากการแพ้วัคซีนโควิด 50,000 บาท  ค่าห้อง ค่าอาหาร และค่าบริการพยาบาล (ไม่เกินวันละ) 1,500 บาท รักษาพยาบาลกรณีผู้ป่วยนอก (OPD) จากการแพ้วัคซีนโควิด (ครั้งละไม่เกิน 500 บาท) โคม่าจากการได้รับวัคซีน 500,000 บาท ค่าชดเชยรายวัน จากการแพ้วัคซีนโควิด (วันละ 500 บาท) (สูงสุด 30 วันต่อปี) ค่าปลอบขวัญ กรณีรักษาตัวเป็นผู้ป่วยในโรงพยาบาล เกินกว่า 5 วัน จากการแพ้วัคซีนโควิด 25,000 บาท ",
  PLAN_NET_PREMIUM_AMT: "640.93",
  PLAN_STAMP_DUTY_AMT: "3",
  PLAN_VAT_AMT: "45.07",
  PLAN_TOTAL_PREMIUM: "689",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_DIAGNOSTIC: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "100000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "50000",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_VACCINE_COMPENSTAION_DAY: "30",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "15000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SMK-2-IN-1",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "AII",
  PLAN_ISSUER: "เอเชียประกันภัย",
  PLAN_ID: "ASIA-500",
  PLAN_DESC: "เอเชียประกันภัยแผน 500 บาท",
  PLAN_COV_TITLE: "ตรวจพบ รับ 50,000 บาท โคม่า 500,000 บาท ค่าชดเชย 50,000 บาท (1,000 บาท/วัน สูงสุดไม่เกิน 50 วัน) แถมฟรีแพ้วัคซีน",
  PLAN_COV_DETAIL: "กรณีแพ้วัคซีน ชดเชยผู้ป่วยใน IPD สูงสุด 30,000 บาท และโคม่า 500,000 บาท",
  PLAN_NET_PREMIUM_AMT: "465.29",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "32.71",
  PLAN_TOTAL_PREMIUM: "500",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "1000",
  PLAN_COMPENSTAION_DAY: "50",
  PLAN_COMPENSTAION_TOTAL_AMT: "50000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "1000",
  PLAN_VACCINE_COMPENSTAION_DAY: "30",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "30000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "4-09",
  PRODUCT_POLICY: "10-44",
  PRODUCT_PACKAGE: "COVIDHIB2",
  BROKER_CODE: "AA11139"
},
{
  PLAN_ISSUER_CODE: "AII",
  PLAN_ISSUER: "เอเชียประกันภัย",
  PLAN_ID: "ASIA-365",
  PLAN_DESC: "เอเชียประกันภัยแผน 365 บาท",
  PLAN_COV_TITLE: "ค่ารักษา 50,000 บาท โคม่า 500,000 บาท ค่าชดเชย 25,000 บาท (500 บาท/วัน สูงสุดไม่เกิน 50 วัน) แถมฟรีแพ้วัคซีน",
  PLAN_COV_DETAIL: "กรณีแพ้วัคซีน ชดเชยผู้ป่วยใน IPD สูงสุด 15,000 บาท และโคม่า 500,000 บาท",
  PLAN_NET_PREMIUM_AMT: "339.12",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "23.88",
  PLAN_TOTAL_PREMIUM: "365",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "50000",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_COMPENSTAION_DAY: "50",
  PLAN_COMPENSTAION_TOTAL_AMT: "25000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_VACCINE_COMPENSTAION_DAY: "30",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "15000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "4-09",
  PRODUCT_POLICY: "10-43",
  PRODUCT_PACKAGE: "COVID365",
  BROKER_CODE: "AA11139"
},
{
  PLAN_ISSUER_CODE: "AII",
  PLAN_ISSUER: "เอเชียประกันภัย",
  PLAN_ID: "ASIA-1825",
  PLAN_DESC: "เอเชียประกันภัยแผน 1825 บาท",
  PLAN_COV_TITLE: "ค่ารักษา 250,000 บาท โคม่า 2,500,000 บาท ค่าชดเชย 125,000 บาท (2,500 บาท/วัน สูงสุดไม่เกิน 50 วัน) แถมฟรีแพ้วัคซีน",
  PLAN_COV_DETAIL: "กรณีแพ้วัคซีน ชดเชยผู้ป่วยใน IPD สูงสุด 75,000 บาท และโคม่า  2,500,000 บาท",
  PLAN_NET_PREMIUM_AMT: "1698.61",
  PLAN_STAMP_DUTY_AMT: "7",
  PLAN_VAT_AMT: "119.39",
  PLAN_TOTAL_PREMIUM: "1825",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "2500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "2500",
  PLAN_COMPENSTAION_DAY: "50",
  PLAN_COMPENSTAION_TOTAL_AMT: "125000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "2500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "2500",
  PLAN_VACCINE_COMPENSTAION_DAY: "30",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "75000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "4-09",
  PRODUCT_POLICY: "10-42",
  PRODUCT_PACKAGE: "COVID1825",
  BROKER_CODE: "AA11139"
},
{
  PLAN_ISSUER_CODE: "AII",
  PLAN_ISSUER: "เอเชียประกันภัย",
  PLAN_ID: "AII-COVID-333",
  PLAN_DESC: "เอเชียประกันภัยแผน 333 บาท",
  PLAN_COV_TITLE: "โคม่าจากการติดเชื้อและแพ้วัคซีน 200,000 บาท ขยายความคุ้มครองอุบัติเหตุ 100,000 บาท",
  PLAN_NET_PREMIUM_AMT: "309.22",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "21.78",
  PLAN_TOTAL_PREMIUM: "333",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: false,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "200000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "AII",
  PLAN_ISSUER: "เอเชียประกันภัย",
  PLAN_ID: "AII-COVID-555",
  PLAN_DESC: "เอเชียประกันภัยแผน 555 บาท",
  PLAN_COV_TITLE: "โคม่าจากการติดเชื้อและแพ้วัคซีน 500,000 บาท ค่าชดเชย 25,000 บาท (500 บาท/วัน สูงสุดไม่เกิน 50 วัน) กรณีติดเชื้อและแพ้วัคซีน",
  PLAN_COV_DETAIL: "หมายเหตุ: ไม่มีความคุ้มครองในส่วนของ Home Isolation",
  PLAN_NET_PREMIUM_AMT: "515.7",
  PLAN_STAMP_DUTY_AMT: "3",
  PLAN_VAT_AMT: "36.3",
  PLAN_TOTAL_PREMIUM: "555",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: false,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "18-70 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_COMPENSTAION_DAY: "50",
  PLAN_COMPENSTAION_TOTAL_AMT: "25000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_VACCINE_COMPENSTAION_DAY: "50",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "25000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-450-N3",
  PLAN_DESC: "ทิพยประกันภัยแผน 450 บาท",
  PLAN_COV_TITLE: "ค่ารักษา 50,000 บาท โคม่า 500,000 บาท ทั้งจากการติดเชื้อและแพ้วัคซีน",
  PLAN_NET_PREMIUM_AMT: "418.56",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "29.44",
  PLAN_TOTAL_PREMIUM: "450",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "50000",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "50000",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "10000",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-450-850",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-850-N4",
  PLAN_DESC: "ทิพยประกันภัยแผน 850 บาท",
  PLAN_COV_TITLE: "ค่ารักษา 100,000 บาท โคม่า 1,000,000 บาท ทั้งจากการติดเชื้อและแพ้วัคซีน",
  PLAN_NET_PREMIUM_AMT: "790.39",
  PLAN_STAMP_DUTY_AMT: "4",
  PLAN_VAT_AMT: "55.61",
  PLAN_TOTAL_PREMIUM: "850",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_HOSPITAL: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "100000",
  PLAN_COMA_PAY_AMT: "1000000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "100000",
  PLAN_VACCINE_COMA_PAY_AMT: "1000000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "10000",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-450-850",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-450-TYPE-A",
  PLAN_DESC: "(Type A) ทิพยประกันภัยแผน 450 บาท",
  PLAN_COV_TITLE: "แบบมีค่ารักษาพยาบาล (Type A) ค่ารักษา 50,000 บาท โคม่า 500,000 บาท ทั้งจากการติดเชื้อและแพ้วัคซีน (ไม่สามารถเลือกค่าชดเชยได้)",
  PLAN_COV_DETAIL: "ความคุ้มครองค่าใช้จ่ายในการตรวจเชื้อของคนในครอบครัว 10,000 บาท",
  PLAN_NET_PREMIUM_AMT: "418.56",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "29.44",
  PLAN_TOTAL_PREMIUM: "450",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "50000",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "50000",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "10000",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-450-850",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-850-TYPE-A",
  PLAN_DESC: "(Type A) ทิพยประกันภัยแผน 850 บาท",
  PLAN_COV_TITLE: "แบบมีค่ารักษาพยาบาล (Type A) ค่ารักษา 100,000 บาท โคม่า 1,000,000 บาท ทั้งจากการติดเชื้อและแพ้วัคซีน (ไม่สามารถเลือกค่าชดเชยได้)",
  PLAN_COV_DETAIL: "ความคุ้มครองค่าใช้จ่ายในการตรวจเชื้อของคนในครอบครัว 10,000 บาท",
  PLAN_NET_PREMIUM_AMT: "790.39",
  PLAN_STAMP_DUTY_AMT: "4",
  PLAN_VAT_AMT: "55.61",
  PLAN_TOTAL_PREMIUM: "850",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_HOSPITAL: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "100000",
  PLAN_COMA_PAY_AMT: "1000000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "100000",
  PLAN_VACCINE_COMA_PAY_AMT: "1000000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "10000",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-450-850",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-450-TYPE-C",
  PLAN_DESC: "(Type C) ทิพยประกันภัยแผน 450 บาท",
  PLAN_COV_TITLE: "แบบมีค่าชดเชยการนอนโรงพยาบาล (Type C) ค่าชดเชย 2,500 บาท สูงสุด 20 วัน โคม่า 500,000 บาท ทั้งจากการติดเชื้อและแพ้วัคซีน (ไม่สามารถเลือกค่ารักษาได้)",
  PLAN_COV_DETAIL: "ความคุ้มครองค่าใช้จ่ายในการตรวจเชื้อของคนในครอบครัว 10,000 บาท",
  PLAN_NET_PREMIUM_AMT: "418.56",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "29.44",
  PLAN_TOTAL_PREMIUM: "450",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: false,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "500000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "2500",
  PLAN_COMPENSTAION_DAY: "20",
  PLAN_COMPENSTAION_TOTAL_AMT: "50000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "2500",
  PLAN_VACCINE_COMPENSTAION_DAY: "20",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "50000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "10000",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-450-850",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-850-TYPE-C",
  PLAN_DESC: "(Type C) ทิพยประกันภัยแผน 850 บาท ",
  PLAN_COV_TITLE: "แบบมีค่าชดเชยการนอนโรงพยาบาล (Type C) ค่าชดเชย 5,000 บาท สูงสุด 20 วัน โคม่า 1,000,000 บาท ทั้งจากการติดเชื้อและแพ้วัคซีน (ไม่สามารถเลือกค่ารักษาได้)",
  PLAN_COV_DETAIL: "ความคุ้มครองค่าใช้จ่ายในการตรวจเชื้อของคนในครอบครัว 10,000 บาท",
  PLAN_NET_PREMIUM_AMT: "790.39",
  PLAN_STAMP_DUTY_AMT: "4",
  PLAN_VAT_AMT: "55.61",
  PLAN_TOTAL_PREMIUM: "850",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_HOSPITAL: false,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "1000000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "5000",
  PLAN_COMPENSTAION_DAY: "20",
  PLAN_COMPENSTAION_TOTAL_AMT: "100000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "1000000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "5000",
  PLAN_VACCINE_COMPENSTAION_DAY: "20",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "100000",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "10000",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-450-850",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-1950-IS",
  PLAN_DESC: "ทิพยประกันภัยแผน IS 1,950 บาท",
  PLAN_COV_TITLE: "โคม่า 2,000,000 บาท ค่ารักษา 30,000 บาท เงินชดเชยรายเดือนกรณีผู้ป่วยในและพักฟื้น 30,000 บาทต่อเดือน  คุ้มครองแพ้วัคซีนและค่าใช้จ่ายพบแพทย์หลังติดเชื้อ",
  PLAN_COV_DETAIL: "เงินชดเชยรายเดือนกรณีผู้ป่วยในและพักฟื้น 30,000 บาทต่อเดือนสูงสุด 120,000 บาท คุ้มครองค่าใช้จ่ายอื่น ๆ เพื่อพบแพทย์หลังการติดเชื้อ Covid-19 สูงสุด 50,000 บาท (ครั้งละ 10,000 บาท) คุ้มครองโคม่ากรณีแพ้วัคซีน 2,000,000 บาท",
  PLAN_NET_PREMIUM_AMT: "1814.43",
  PLAN_STAMP_DUTY_AMT: "8",
  PLAN_VAT_AMT: "127.57",
  PLAN_TOTAL_PREMIUM: "1950",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "30000",
  PLAN_COMA_PAY_AMT: "2000000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "30000",
  PLAN_VACCINE_COMA_PAY_AMT: "2000000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "30000",
  PLAN_FOLLOW_UP_DOCTOR: "10000",
  PLAN_SEARCH_OTHER: "10000",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-IS",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-70-V1",
  PLAN_DESC: "ทิพยประกันภัยแพ้วัคซีน COVID-19 แผน V1",
  PLAN_COV_TITLE: "โคม่า 500,000 บาท ค่ารักษาสำหรับผู้ป่วยใน 50,000 บาท จากการแพ้วัคซีน COVID-19",
  PLAN_COV_DETAIL: "",
  PLAN_NET_PREMIUM_AMT: "64.42",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "4.58",
  PLAN_TOTAL_PREMIUM: "70",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: false,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "50000",
  PLAN_VACCINE_COMA_PAY_AMT: "500000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-VAC",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "DHP",
  PLAN_ISSUER: "ทิพยประกันภัย",
  PLAN_ID: "DHP-120-V2",
  PLAN_DESC: "ทิพยประกันภัยแพ้วัคซีน COVID-19 แผน V2",
  PLAN_COV_TITLE: "โคม่า 1,000,000 บาท ค่ารักษาสำหรับผู้ป่วยใน 100,000 บาท จากการแพ้วัคซีน COVID-19",
  PLAN_COV_DETAIL: "",
  PLAN_NET_PREMIUM_AMT: "111.15",
  PLAN_STAMP_DUTY_AMT: "1",
  PLAN_VAT_AMT: "7.85",
  PLAN_TOTAL_PREMIUM: "120",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: false,
  PLAN_COMPENSATION: false,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_COMPENSTAION_DAY: "0",
  PLAN_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "100000",
  PLAN_VACCINE_COMA_PAY_AMT: "1000000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "0",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "DHP-VAC",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "VIB",
  PLAN_ISSUER: "วิริยะประกันภัย",
  PLAN_ID: "VIB-PROTECT-439",
  PLAN_DESC: "PROTECT แผน 439 บาท",
  PLAN_COV_TITLE: "ค่ารักษา 30,000 บาท โคม่า 300,000 บาท ชดเชย 500 บาท/ต่อวัน ไม่เกิน 7,000 บาท และอุบัติเหตุ (อ.บ.1) 100,000 บาท รับฟรีแพ้วัคซีน",
  PLAN_COV_DETAIL: "ความคุ้มครองกรณีแพ้วัคซีนโควิด (โคม่า) 200,000 บาท",
  PLAN_NET_PREMIUM_AMT: "437.00",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "439",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-75 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "0",
  PLAN_HOSPITAL_PAY_AMT: "30000",
  PLAN_COMA_PAY_AMT: "300000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_COMPENSTAION_DAY: "14",
  PLAN_COMPENSTAION_TOTAL_AMT: "7000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "200000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "100000",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "VIB-COVID",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "VIB-PROTECT",
  BROKER_CODE: ""
}
  ,
{
  PLAN_ISSUER_CODE: "VIB",
  PLAN_ISSUER: "วิริยะประกันภัย",
  PLAN_ID: "VIB-PROTECT-PLUS-549",
  PLAN_DESC: "PROTECT PLUS แผน 549 บาท",
  PLAN_COV_TITLE: "ค่ารักษา 30,000 บาท โคม่า 300,000 บาท เจอ จ่าย 10,000 บาท ชดเชย 500 บาท/ต่อวัน ไม่เกิน 7,000 บาท และอุบัติเหตุ (อ.บ.1) 10,000 บาท",
  PLAN_NET_PREMIUM_AMT: "546.00",
  PLAN_STAMP_DUTY_AMT: "3",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "549",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_HOSPITAL: true,
  PLAN_COMPENSATION: true,
  PLAN_AGE_CONDITION: "1-75 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "10000",
  PLAN_HOSPITAL_PAY_AMT: "30000",
  PLAN_COMA_PAY_AMT: "300000",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_COMPENSTAION_DAY: "14",
  PLAN_COMPENSTAION_TOTAL_AMT: "7000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "10000",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "VIB-COVID",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "VIB-PROTECT-PLUS",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "VIB",
  PLAN_ISSUER: "วิริยะประกันภัย",
  PLAN_ID: "VIB-SUPER-SHIELD-399",
  PLAN_DESC: "Super Shield 1 แผน 399 บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 50,000 บาท ชดเชย 500 บาท/ต่อวัน ไม่เกิน 7,000 บาท และอุบัติเหตุ (อ.บ.1) 50,000 บาท รับฟรีแพ้วัคซีน",
  PLAN_COV_DETAIL: "รับฟรี ความคุ้มครองกรณีแพ้วัคซีนโควิด (โคม่า) 200,000 บาท",
  PLAN_NET_PREMIUM_AMT: "397.00",
  PLAN_STAMP_DUTY_AMT: "2",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "399",
  PLAN_SCORE: "4",
  PLAN_IS_GOOD_SELLING: false,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "50000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_COMPENSTAION_DAY: "14",
  PLAN_COMPENSTAION_TOTAL_AMT: "7000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "200000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "50000",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "VIB-COVID",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SUPER-SHIELD",
  BROKER_CODE: ""
},
{
  PLAN_ISSUER_CODE: "VIB",
  PLAN_ISSUER: "วิริยะประกันภัย",
  PLAN_ID: "VIB-SUPER-SHIELD-599",
  PLAN_DESC: "Super Shield 2 แผน 599 บาท",
  PLAN_COV_TITLE: "เจอ จ่าย 100,000 บาท ชดเชย 500 บาท/ต่อวัน ไม่เกิน 7,000 บาท และอุบัติเหตุ (อ.บ.1) 50,000 บาท รับฟรีแพ้วัคซีน",
  PLAN_COV_DETAIL: "รับฟรี ความคุ้มครองกรณีแพ้วัคซีนโควิด (โคม่า) 300,000 บาท",
  PLAN_NET_PREMIUM_AMT: "596.00",
  PLAN_STAMP_DUTY_AMT: "3",
  PLAN_VAT_AMT: "0",
  PLAN_TOTAL_PREMIUM: "599",
  PLAN_SCORE: "5",
  PLAN_IS_GOOD_SELLING: true,
  PLAN_DIAGNOSTIC: true,
  PLAN_COMPENSATION: true,
  PLAN_ALLERGYVACCINE: true,
  PLAN_AGE_CONDITION: "1-99 ปี",
  PLAN_DIAGNOSTIC_PAY_AMT: "100000",
  PLAN_HOSPITAL_PAY_AMT: "0",
  PLAN_COMA_PAY_AMT: "0",
  PLAN_COMPENSTAION_PER_DAY_PAY_AMT: "500",
  PLAN_COMPENSTAION_DAY: "14",
  PLAN_COMPENSTAION_TOTAL_AMT: "7000",
  PLAN_VACCINE_HOSPITAL_PAY_AMT: "0",
  PLAN_VACCINE_COMA_PAY_AMT: "300000",
  PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: "0",
  PLAN_VACCINE_COMPENSTAION_DAY: "0",
  PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: "0",
  PLAN_COMPENSTAION_MONTH: "0",
  PLAN_FOLLOW_UP_DOCTOR: "0",
  PLAN_SEARCH_OTHER: "0",
  PLAN_PA: "50000",
  PLAN_PREFIX_COVERNOTE: "",
  PRODUCT_STATUS: "NON-ACTIVE",
  PRODUCT_GROUP: "VIB-COVID",
  PRODUCT_POLICY: "",
  PRODUCT_PACKAGE: "SUPER-SHIELD",
  BROKER_CODE: ""
}]

export { preName, benefitType, day, month, year, yearMoreThanfifteen, yearLessThanfifteen, getCustomYear, CovStartDate, CovEndDate, Occupation, planRenewList, CovEndDateZero, CovStartDateZero, CovEndDateEngZero, CovStartDateEngZero, HealthplanList, buildingType, businessAndIndustrySabuyPackage, premiumSabuyPackage, planCovidNewList }
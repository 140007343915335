import React, { useState, useEffect } from 'react';
import { useHistory, Link, useLocation } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import { storage, db } from '../../firebase/config'

import BackgroundSlider from "react-background-slider";

// Import images
import tvinsureAssetInsOne from '../../shared/images/TVinsure-Benefit-of-Insurance-new.png';
import tvinsureAssetInsTwo from '../../shared/images/TVinsure-Benefit-of-Insurance-home.png';
import tvinsureAssetInsThree from '../../shared/images/TVinsure-Benefit-of-Insurance-consult.png';

//API Module
import axios from 'axios'

import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Button, Table, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { preName, day, month, year, CovStartDateZero, CovEndDateZero, HealthplanList, Occupation, businessAndIndustrySabuyPackage, } from '../../shared/masters/refData'

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Issuer Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import dhpinsurancelogo from '../../shared/images/issuers/DHP.png';
import bkiinsurancelogo from '../../shared/images/issuers/BKI.png';

//Import CSS
import './Affiliate.css';

//Import Data
import { buildingType } from '../../shared/masters/archrived/fireMasterInfo.js'
import { firePackageData } from '../../shared/masters/archrived/firePackageData.js'

//Import get API Data
import { MASTERS_URL } from '../../shared/masters/url';


//Get Unique Id Module
var uniqid = require('uniqid');

const AffiliateFirePackageApplication = () => {

    // init use History
    let history = useHistory();
    const items = [tvinsureAssetInsOne, tvinsureAssetInsTwo, tvinsureAssetInsThree]

    // Use query string to get data
    const { search } = useLocation()
    const { packcode, buildingcode, buildingwidth, buildinglength, buildinglevel, buildingsumins, fursumins, totalsumins, token } = queryString.parse(search)

    // set and filter the building type
    let buildingtype = ""
    if (buildingcode && buildingType.filter(t => t.BUILDING_CODE === buildingcode)[0].BUILDING_TYPE) {
        buildingtype = buildingType.filter(t => t.BUILDING_CODE === buildingcode)[0].BUILDING_TYPE
    }

    // Set State for all data
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [provinceData, setProvinceData] = useState();
    const [districtData, setDistrictData] = useState();
    const [districtSubData, setDistrictSubData] = useState();
    const [districtSubFilter, setDistrictSubFilter] = useState();
    const [agentData, setAgentData] = useState();
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        appType: "NEW",
        appStatus: "apply",
        appStatusValue: 1,
        currentDate: new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        cardType: "nationalidcard",
        idcardfilename: "",
        idcardfileurl: "",
        idcardfileurlloadfinish: "unselect",
        idCardCheck: "uncheck",
    });

    // console.log(formData);

    // Get filter Pacakge
    useEffect(() => {
        window.scrollTo(0, 0);
        const filteredPackage = firePackageData.filter(p => packcode === p.PACK_CODE)
        setFormData(prevValue => {
            return {
                ...prevValue,
                appid: "FRP-" + filteredPackage[0].PACK_TYPE + "-" + uniqid.time().toUpperCase(),
                title: filteredPackage[0].PACK_TITLE,
                tag: filteredPackage[0].PACK_TAG,
                rating: Number(filteredPackage[0].PACK_RATING),
                issuerLogo: handleSelectedIssuerLogo(filteredPackage[0].PACK_ISSUER),
                issuerCode: filteredPackage[0].PACK_ISSUER,
                issuerName: filteredPackage[0].PACK_ISSUER_DESC,
                planCode: filteredPackage[0].PACK_CODE,
                planName: filteredPackage[0].PACK_NAME,
                planDesc: filteredPackage[0].PACK_DESC,
                planYear: filteredPackage[0].PRM_YEAR,
                planTotalSuminsMin: Number(filteredPackage[0].SUMINS_COV),
                planTotalSuminsMax: Number(filteredPackage[0].SUMINS_COV),
                planNetPrem: Number(filteredPackage[0].NET_PREMIUM),
                planStampDuty: filteredPackage[0].STAMP_DUTY,
                planVat: filteredPackage[0].VAT_AMT,
                planTotalPrem: filteredPackage[0].TOTAL_PREMIUM,
                buildingSumins: buildingsumins,
                furSumins: fursumins,
                totalSumins: totalsumins,
                buildingcode: buildingcode,
                buildingtype: buildingtype,
                buildingwidth: buildingwidth,
                buildinglength: buildinglength,
                buildinglevel: buildinglevel
            }
        });
        const sendProvinceRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${MASTERS_URL}/province`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setProvinceData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendProvinceRequest()
        const sendDistrictSubRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${MASTERS_URL}/districtSub`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setDistrictSubData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendDistrictSubRequest()
        const sendAgentRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${MASTERS_URL}/agent`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setAgentData(response.data)
                setIsLoading(false);
                if (token) {
                    db.collection("affiliateTokenGen").where("tokenid", "==", token).get()
                        .then((snapshot) => {
                            // console.log(snapshot.docs);
                            if (snapshot.docs.length > 0) {
                                const agentFilter = response.data.filter(a => a.AGENT_CODE === snapshot.docs[0].data().agentCode)
                                if (agentFilter.length > 0) {
                                    setFormData(prevValue => {
                                        return {
                                            ...prevValue,
                                            agentName: agentFilter[0].AGENT_NAME_T,
                                            agentCheck: "success",
                                            tokenid: snapshot.docs[0].data().tokenid ? snapshot.docs[0].data().tokenid : "",
                                            agentCode: snapshot.docs[0].data().agentCode ? snapshot.docs[0].data().agentCode : ""
                                        }
                                    });
                                }
                                setIsLoading(false);
                            }
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            console.log(error || "เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง");
                        });
                }
            } catch (err) {
                setError(err.message || 'Something went wrong with Agent API, please try again.');
                setIsLoading(false);
            }
        }
        sendAgentRequest()
    }, []);

    const handleSelectedIssuerLogo = (IssuerCode) => {
        if (IssuerCode === "DHP") {
            return dhpinsurancelogo
        } if (IssuerCode === "BKI") {
            return bkiinsurancelogo
        }
    }

    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        //province must have value
        if (name === "province" && value) {
            const provinceCode = provinceData.filter(p => p.PROV_NAME_T === value)[0].PROVINCE_CODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    province: value,
                    provinceid: provinceCode,
                    district: "",
                    districtid: "",
                    districtSub: "",
                    districtSubid: ""
                }
            });
            const sendDistrictRequest = async () => {
                setIsLoading(true);
                try {
                    const response = await axios.post(`${MASTERS_URL}/district`,
                        JSON.stringify({
                            PROVINCE_CODE: provinceCode
                        }),
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    setDistrictData(response.data)
                    setIsLoading(false);
                } catch (err) {
                    setIsLoading(false);
                    setError(err.message || 'Something went wrong with District API, please try again.');
                }
            }
            sendDistrictRequest()
        }

        //district must have value
        if (name === "district" && value) {
            const districtCode = districtData.filter(d => d.DISTRICT_NAME_T === value)[0].DISTRICT_CODE
            const districtSubFilter = districtSubData.filter(ds => ds.DISTRICT_NAME_T === value)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    district: value,
                    districtid: districtCode,
                    districtSub: "",
                    districtSubid: ""
                }
            });
            setDistrictSubFilter(districtSubFilter)
        }

        //districtSub must have value
        if (name === "districtSub" && value) {
            const districtSubCode = districtSubFilter.filter(ds => ds.DISTRICT_NAME_T_SUB === value)[0].DISTRICT_CODE_SUB
            const zipcode = districtSubData.filter(ds => ds.DISTRICT_CODE_SUB === districtSubCode)[0].ZIPCODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    districtSub: value,
                    districtSubid: districtSubCode,
                    zipcode: zipcode
                }
            });
        }

        if (name === "isAssetTheSame" & value === "Y") {
            if (formData.address && formData.districtSub && formData.district && formData.province && formData.zipcode) {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        assetAddress: `${formData.address} ${formData.districtSub} ${formData.district} จังหวัด${formData.province} ${formData.zipcode}`,
                    }
                });
            }
        }

        if (name === "agentCode") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: value,
                    agentCheck: "uncheck"
                }
            });
        }

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    //เช็คข้อมูลผู้ขอข้อเสนอ
    const handleCheckAgent = () => {
        const agentFilter = agentData.filter(a => a.AGENT_CODE === formData.agentCode)
        if (agentFilter.length > 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentName: agentFilter[0].AGENT_NAME_T,
                    agentCheck: "success"
                }
            });
        }
        if (agentFilter.length === 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: "",
                    agentName: "กรุณาระบุผู้แจ้งงาน",
                    agentCheck: "fail"
                }
            });
        }
    }

    const handleValidSubmit = (event) => {
        event.preventDefault();
        setIsSubmit(true);
        const requestSubmitFormData = () => {
            setIsLoading(true);
            db.collection('affiliateFireApplication').add(formData
            ).then(() => {
                history.push(`/aff-fire-package/home/result?applicationid=${formData.appid}`)
            })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                    setErrorModal(true);
                });
        }
        requestSubmitFormData()
    }

    //Select file function
    const fileSelectedHandler = (event) => {
        const { name, files } = event.target

        // const fileMaxSize = 1
        // const fileMaxSize = 2097152
        const fileMaxSize = 10485760


        if (files[0].size > fileMaxSize) {
            alert("ไฟล์ของคุณมีขนาดเกิน 10 MB กรุณาเลือกไฟล์ใหม่");
            window.location.reload()
        }

        if (files[0].size < fileMaxSize) {
            if (name === "idcardfile") {
                fileIdCardUploadHandler(files[0])
            }
        }
    }

    //Upload Id Card File
    const fileIdCardUploadHandler = (file) => {
        const uploadTask = storage.ref(`idcardfile/${formData.appid + "-IDCARD"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        idcardfileurlloadfinish: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
                // console.log(error.message);
            },
            () => {
                storage
                    .ref('idcardfile')
                    .child(formData.appid + "-IDCARD")
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                idcardfilename: formData.appid + "-IDCARD",
                                idcardfileurl: url,
                                idcardfileurlloadfinish: "uploaded"
                            }
                        });
                        // console.log(url);
                    });
            })
        // console.log(event.target.files);
    }


    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    return (
        <React.Fragment>
            <div className="wrapper">

                <MetaTags>
                    <title>เช็คราคาประกันภัยบ้านอยู่อาศัย </title>
                    <meta name="description" content="เช็คราคาประกันภัยบ้านอยู่อาศัย ไม่ต้องโทรก็เช็คเองได้ TVinsure.com" />
                    <meta property="og:title" content="เช็คราคาประกันภัยบ้านอยู่อาศัย" />
                    <meta property="og:description" content="เช็คราคาประกันภัยบ้านอยู่อาศัย ไม่ต้องโทรก็เช็คเองได้ TVinsure.com" />
                    <meta property="og:image" content={tvinsureAssetInsOne} />
                </MetaTags>

                {error && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                        <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                            <p className="mb-2">{error}</p>
                            <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </div>)}

                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section className="d-table w-100">
                    <BackgroundSlider
                        images={items}
                        duration={5}
                        transition={3}
                    />
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-3 justify-content-center">
                            <div className="col-12">
                                <div className="text-center mb-3">
                                    <a href="https://tvinsure.com" className="logo h5">
                                        <img src={tvinsurelogo} height="120" alt="" />
                                    </a>
                                </div>
                                <div className="title-heading text-center">
                                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                        เช็คราคาประกันภัยบ้านอยู่อาศัย
                                    </h4>
                                    <p className="text-center text-white h5 mt-1 mb-5">สะดวกเช็คง่าย ให้เราช่วยเลือกประกันภัยที่คุ้มค่าที่สุดสำหรับคุณ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section className="section bg-white">
                    <Container>

                        <section className="section justify-content-center" style={{ margin: "-120px 0 0" }}  >
                            <Container>
                                <Row>
                                    <Col lg={5} md={6} className=" mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <div className="rounded shadow-lg p-4 sticky-bar">
                                            <div className="firm-logo text-center pt-2">
                                                <img
                                                    className="text-center"
                                                    height={60}
                                                    src={formData && formData.issuerLogo}
                                                    alt={formData && formData.issuerName}
                                                />
                                            </div>
                                            <div className="mb-1 mt-4 text-center">
                                                <h4 className="pt-2"> {formData && formData.planName} </h4>
                                                <p className="pb-2"> {formData && formData.planDesc} </p>
                                            </div>

                                            <div className="table-responsive">
                                                <Table className="table-center table-padding mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="h6 border-0">ทุนประกันภัยรวม</td>
                                                            <td className="text-right font-weight-bold border-0">
                                                                {Intl.NumberFormat().format(totalsumins)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="h6">เบี้ยสุทธิ</td>
                                                            <td className="text-right font-weight-bold"> {Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(formData.planNetPrem)} </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="h6">อากร</td>
                                                            <td className="text-right font-weight-bold"> {Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(formData.planStampDuty)} </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="h6">ภาษี</td>
                                                            <td className="text-right font-weight-bold"> {Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(formData.planVat)} </td>
                                                        </tr>
                                                        <tr className="bg-light">
                                                            <td className="h6 font-weight-bold">เบี้ยประกันภัย (เบี้ยรวม)</td>
                                                            <td className="text-right text-primary h6 font-weight-bold"> {Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(formData.planTotalPrem)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <div className="mb-1 mt-4 text-center text-primary">
                                                    <Link to={'/aff-fire-package/home/quote' + `${token ? `&token=${token}` : ""}`} className="text-muted h6">
                                                        <Button outline color="primary">
                                                            ย้อนกลับเพื่อดูรายละเอียดแผน
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={7} md={6}>
                                        <AvForm className="mt-4" onValidSubmit={handleValidSubmit}>

                                            <div className="rounded shadow-lg p-4 mb-5">
                                                <h5 className="mb-3">รายละเอียดของทรัพย์สิน: </h5>
                                                <Row className="mt-2">
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">ประเภทสิ่งปลูกสร้าง: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">{buildingtype}</small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">ประเภทวัสดุ: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">ปูน 100%</small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">ความกว้าง: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">{buildingwidth} เมตร</small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">ความยาว: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">{buildinglength} เมตร</small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">จำนวนชั้น: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">{buildinglevel} ชั้น</small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">ทุนตัวอาคาร: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">{Intl.NumberFormat().format(buildingsumins)} บาท</small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">ทุนเฟอร์นิเจอร์: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">{Intl.NumberFormat().format(fursumins)} บาท</small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-left d-block font-weight-bold">ทุนประกันภัยรวม: </small>
                                                    </Col>
                                                    <Col md={6} xs={6}>
                                                        <small className="text-right d-block">{Intl.NumberFormat().format(totalsumins)} บาท</small>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="rounded shadow-lg p-4">
                                                <h5 className="mb-3">รายละเอียดของผู้เอาประกัน: </h5>

                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>คำนำหน้าชื่อ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกคำนำหน้าชื่อ"
                                                                validate={{ required: { value: true } }} placeholder="คำนำหน้าชื่อ" name="prename" onChange={handleChange}>
                                                                <option value="" selected>คำนำหน้าชื่อ</option>
                                                                {preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ชื่อ <span className="text-danger">*</span></Label>
                                                            <AvField type="text" className="form-control"
                                                                errorMessage="กรุณากรอกชื่อ"
                                                                validate={{ required: { value: true } }} placeholder="ชื่อ" name="fname" onChange={handleChange} value={formData.fname} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>นามสกุล <span className="text-danger">*</span></Label>
                                                            <AvField type="text" className="form-control"
                                                                errorMessage="กรุณากรอกนามสกุล"
                                                                validate={{ required: { value: true } }} placeholder="นามสกุล" name="lname" onChange={handleChange} value={formData.lname} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>

                                                        <ul className="list-unstyled mt-0 mb-3">
                                                            <li>
                                                                <FormGroup className="mb-0">
                                                                    <Label>ประเภทบัตร</Label>
                                                                    <CustomInput
                                                                        type="radio"
                                                                        label="บัตรประชาชน (ID Card)"
                                                                        id="nationalidcard"
                                                                        defaultChecked
                                                                        name="cardType"
                                                                        value="nationalidcard"
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li className="mt-2">
                                                                <FormGroup className="mb-0">
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="passport"
                                                                        label="พาสปอร์ต (Passport)"
                                                                        name="cardType"
                                                                        value="passport"
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                        </ul>
                                                    </Col>

                                                    {formData.cardType === "nationalidcard" && <Col md={8}>
                                                        <FormGroup className="position-relative">
                                                            <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง"
                                                                validate={{
                                                                    required: { value: true },
                                                                    pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' }
                                                                }} placeholder="เลขบัตรประชาชน 13 หลัก" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                        </FormGroup>
                                                    </Col>}
                                                    {formData.cardType === "passport" && <Col md={8}>
                                                        <FormGroup className="position-relative">
                                                            <Label>พาสปอร์ต (Passport) <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกเลขพาสปอร์ต(Passport)"
                                                                validate={{ required: { value: true } }} placeholder="เลขพาสปอร์ต(Passport)" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                        </FormGroup>
                                                    </Col>}
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>เลือกไฟล์บัตรประชาชน/พาสปอร์ต <span className="text-danger">*</span></Label>
                                                            {formData.idcardfileurlloadfinish === "uploading" && <small className="text-primary"> กำลังอัพโหลด</small>}
                                                            {formData.idcardfileurlloadfinish === "uploaded" && <small className="text-success">  {formData.idcardfileurlloadfinish === "uploaded" && <a className="text-success" target="_blank" href={formData.idcardfileurl}>อัพโหลดเสร็จสิ้น </a>}</small>}
                                                            <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                            <AvField type="file" accept="image/*" className="form-control pl-5"
                                                                errorMessage="กรุณาแนบไฟล์บัตรประชาชน/พาสปอร์ต"
                                                                placeholder="ไฟล์บัตรประชาชน/พาสปอร์ต" name="idcardfile" onChange={fileSelectedHandler} />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ที่อยู่ <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="map" className="fea icon-sm icons" /></i>
                                                            <AvField type="map" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกที่อยู่"
                                                                validate={{ required: { value: true } }} placeholder="ที่อยู่ เช่น เลขที่บ้าน ชั้น ตึก ถนน ตรอก ซอย" name="address" onChange={handleChange} value={formData.address} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>จังหวัด <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกจังหวัด"
                                                                validate={{ required: { value: true } }} placeholder="จังหวัด" name="province" onChange={handleChange} disabled={!provinceData}>
                                                                <option value="" selected>จังหวัด</option>
                                                                {provinceData && provinceData.map(province => (<option value={province.PROV_NAME_T} >{province.PROV_NAME_T}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>อำเภอ/เขต <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกอำเภอ/เขต"
                                                                validate={{ required: { value: true } }} placeholder="อำเภอ/เขต" name="district" onChange={handleChange} disabled={!districtData && !districtSubData}>
                                                                <option value="" selected>อำเภอ/เขต</option>
                                                                {districtData && districtData.map(district => (<option value={district.DISTRICT_NAME_T}>{district.DISTRICT_NAME_T}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ตำบล/แขวง <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกตำบล/แขวง"
                                                                validate={{ required: { value: true } }} placeholder="ตำบล/แขวง" name="districtSub" onChange={handleChange} disabled={!districtSubFilter}>
                                                                <option value="" selected>ตำบล/แขวง</option>
                                                                {districtSubFilter && districtSubFilter.map(districtSub => (<option value={districtSub.DISTRICT_NAME_T_SUB}>{districtSub.DISTRICT_NAME_T_SUB}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>รหัสไปรษณีย์</Label>
                                                            <AvField type="text" className="form-control"
                                                                placeholder="รหัสไปรษณีย์" name="zipcode" value={formData.zipcode} disabled>{formData.zipcode}</AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>หมายเลขโทรศัพท์มือถือ <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="smartphone" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง"
                                                                validate={{
                                                                    pattern: { value: '^[0][689][0-9]-?[0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9]$' },
                                                                    required: { value: true }
                                                                }} placeholder="หมายเลขโทรศัพท์มือถือ" name="tel" onChange={handleChange} value={formData.tel} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>อีเมล <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                            <AvField type="email" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกอีเมลที่ถูกต้อง"
                                                                validate={{
                                                                    required: { value: true },
                                                                    email: { value: true }
                                                                }} placeholder="อีเมล" name="email" onChange={handleChange} value={formData.email} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                            </div>

                                            <div className="rounded shadow-lg p-4 mt-5">
                                                <h5 className="mb-3">รายละเอียดของที่อยู่เอาประกัน: </h5>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup className="mb-3 mt-2">
                                                            <CustomInput
                                                                type="checkbox"
                                                                label="ที่อยู่เดียวกันกับผู้เอาประกันภัย"
                                                                id="isAssetTheSame"
                                                                name="isAssetTheSame"
                                                                value="Y"
                                                                onChange={handleChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>เลขรหัสประจำบ้าน(ทะเบียนบ้าน 11 หลัก)  <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกเลขบัตรเลขรหัสประจำบ้าน"
                                                                validate={{
                                                                    required: { value: true },
                                                                    pattern: { value: '^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$' }
                                                                }} placeholder="เลขรหัสประจำบ้าน 11 หลัก" name="assetIdCard" onChange={handleChange} value={formData.assetIdCard} />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ที่อยู่ทรัพย์สินเอาประกันภัย <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="map" className="fea icon-sm icons" /></i>
                                                            <AvField type="textarea" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกที่อยู่ทรัพย์สินเอาประกันภัย"
                                                                validate={{ required: { value: true } }} placeholder="ที่อยู่ เช่น เลขที่บ้าน ชั้น ตึก ถนน ตรอก ซอย แขวง/ตำบล เขต/อำเภอ จังหวัด รหัสไปรษณีย์" name="assetAddress" onChange={handleChange} value={formData.assetAddress} />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                            </div>

                                            <div className="rounded shadow-lg p-4 mt-5">
                                                <h5 className="mb-3">รายละเอียดผู้ดูแล: </h5>
                                                <Row>
                                                    {formData.agentCheck !== "success" && <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>รหัสผู้ดูแล <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกรหัสผู้ดูแล"
                                                                validate={{ required: { value: true } }} placeholder="รหัสผู้ดูแล" name="agentCode" onChange={handleChange} value={formData.agentCode} />
                                                        </FormGroup>
                                                    </Col>}
                                                    {formData.agentCheck !== "success" && <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                            <Button className="mb-2" color={formData.agentCheck === "success" ? "success" : "danger"}
                                                                outline={formData.agentCheck !== "success" && true} block
                                                                disabled={!formData.agentCode}
                                                                onClick={handleCheckAgent}>{formData.agentCheck === "success" ? "ตรวจสอบแล้ว" : "ตรวจสอบ"}
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>}
                                                    <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label className="text-center">ชื่อผู้ดูแล</Label>
                                                            <i><FeatherIcon icon="headphones" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                placeholder="ชื่อผู้ดูแล" name="agentName" value={formData.agentName} disabled>{formData.agentName}</AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>หมายเหตุผู้ดูแล</Label>
                                                            <AvField type="text" className="form-control"
                                                                placeholder="หมายเหตุผู้ดูแล" name="agentRemark" onChange={handleChange} value={formData.agentRemark} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="mt-4 pt-2">
                                                {!isSubmit && formData.agentCheck !== "success" && <Button
                                                    type="submit"
                                                    className="btn btn-block btn-primary"
                                                    disabled
                                                >
                                                    กรุณาตรวจสอบข้อมูลผู้ดูแล
                                                </Button>}
                                                {!isSubmit && formData.agentCheck === "success" && <Button
                                                    type="submit"
                                                    className="btn btn-block btn-primary"
                                                    disabled={formData.idcardfileurlloadfinish !== "uploaded"}
                                                >
                                                    สมัครประกันภัย
                                                </Button>}
                                                {isSubmit && <Button
                                                    type="submit"
                                                    className="btn btn-block btn-primary"
                                                    disabled
                                                >
                                                    กำลังสมัครประกันภัย
                                                </Button>}
                                            </div>

                                        </AvForm>
                                    </Col>

                                </Row>
                            </Container>
                        </section>

                        <Row>
                            <Col lg={12}>
                                <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                            </Col>
                            <Col lg={12}>
                                <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


export default AffiliateFirePackageApplication;
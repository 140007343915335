// React Basic and Firebase
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { db } from '../../firebase/config'

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import tvinsurelogo from "../../shared/images/newbrands/tvinsure-main-logo.png";
import tvinsureSmeSabaiPackage from '../../shared/images/tvinsure-sme-sabai-package-4.png';

//Import dowloand quotation module
import { GetQuotationSmeSabai } from '../components/GetQuotationSmeSabai.js'

const AssetQuoteSmeSabaiResult = () => {
    const quotationid = useParams().quotationid;

    const [isLoading, setIsLoading] = useState(false);
    const [quotationData, setquotationData] = useState();
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent and get record detail of health application
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        db.collection('fireQuotation').where('appid', '==', quotationid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setquotationData(snapshot.docs[0].data())
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการดังกล่าวได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setIsLoading(false);
    }, []);

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>เช็คราคาประกันภัยสำหรับธุรกิจของคุณ </title>
                    <meta name="description" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ" />
                    <meta property="og:description" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureSmeSabaiPackage} />
                </MetaTags>
                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section
                    className="bg-home d-flex align-items-center"
                    style={{ backgroundImage: `url(${tvinsureSmeSabaiPackage})` }}
                >
                    <div className="bg-overlay"></div>
                    <Container>
                        {!isLoading && <Row className="justify-content-center">
                            <Col lg={8} md={12} className="text-center">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                                {quotationData && <h4 className="display-4 font-weight-bold text-white title-dark mb-3 mt-4">
                                    ข้อเสนอการเช็คราคาประกันภัยสำหรับธุรกิจของคุณ
                                </h4>}
                                {!quotationData && <h4 className="h1 font-weight-bold text-white title-dark mb-3 mt-4">
                                    ไม่พบข้อเสนอดังกล่าว หรือไม่สามารถออกข้อเสนอได้ รบกวนลองใหม่อีกครั้ง
                                </h4>}
                                <p className="text-center text-white h5 mt-1 ">ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้</p>
                                {quotationData && <p className="text-center text-white h6 mt-2">เลขที่ข้อเสนอ {quotationData.appid}</p>}
                                {quotationData && <p className="text-center text-white h6 mt-2 mb-3">ผู้เอาประกันภัย {quotationData.prename} {quotationData.fname} {quotationData.lname}</p>}
                            </Col>
                            {quotationData && <Col md={12} className="text-center mb-2">
                                <Button className="btn btn-primary mt-2" onClick={() => GetQuotationSmeSabai(quotationData)}>
                                    <i className="mdi mdi-download"></i> ดาวโหลดเลย
                            </Button>
                            </Col>}
                            {!quotationData && <Col md={12} className="text-center mb-2">
                                <Link to="/asset-quote-all">
                                    <Button className="btn btn-primary mt-2">
                                        <i className="mdi mdi-redo-variant"></i> คำนวณเบี้ยอีกครั้ง
                            </Button>
                                </Link>
                            </Col>}
                        </Row>}

                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}
export default AssetQuoteSmeSabaiResult;

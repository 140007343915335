// React Basic and Firebase
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { db } from '../../firebase/config'

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import tvinsurelogo from "../../shared/images/newbrands/tvinsure-main-logo.png";
import tvinsureSmeSabaiPackage from '../../shared/images/tvinsure-sme-sabai-package-4.png';


const DealResult = () => {
    const dealid = useParams().dealid;

    const [isLoading, setIsLoading] = useState(false);
    const [dealData, setDealData] = useState();
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent and get record detail of health application
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        db.collection('deal').where('dealId', '==', dealid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                let dataLoaded = snapshot.docs[0].data()
                let string = '';
                dataLoaded.insType.forEach(item => string += ", " + item.value);
                dataLoaded.insTypeDesc = string
                setDealData(dataLoaded)
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการดังกล่าวได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setIsLoading(false);
    }, []);

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>เช็คราคาประกันภัยรถยนต์ </title>
                    <meta name="description" content="เช็คราคาประกันภัยรถยนต์ ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="เช็คราคาประกันภัยรถยนต์" />
                    <meta property="og:description" content="เช็คราคาประกันภัยรถยนต์ ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureSmeSabaiPackage} />
                </MetaTags>
                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section
                    className="bg-home d-flex align-items-center"
                    style={{ backgroundImage: `url(${tvinsureSmeSabaiPackage})` }}
                >
                    <div className="bg-overlay"></div>
                    <Container>
                        {!isLoading && <Row className="justify-content-center">
                            <Col lg={8} md={12} className="text-center">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                                {dealData && <h4 className="display-4 font-weight-bold text-white title-dark mb-3 mt-4">
                                    เช็คราคาประกันภัยรถยนต์
                                </h4>}
                                {!dealData && <h4 className="h1 font-weight-bold text-white title-dark mb-3 mt-4">
                                    ไม่พบข้อมูลการส่งขอเบี้ยประกันภัยดังกล่าว รบกวนลองใหม่อีกครั้ง
                                </h4>}
                                <p className="text-center text-white h5 mt-1 ">เช็คราคาประกันภัยรถยนต์ </p>
                                {dealData && <p className="text-center text-white h6 mt-2">เลขที่ขอราคา {dealData.dealId}</p>}
                                {dealData && dealData.insTypeDesc && <p className="text-center text-white h6 mt-2">ประเภทประกันที่ต้องการ: {dealData.insTypeDesc}</p>}
                                {/* Only Car Description is Shown */}
                                {dealData && dealData.carMake && <p className="text-center text-white h6 mt-2 mb-3">รถยนต์ {dealData.carMake} {dealData.carModel} ปี {dealData.carYear}</p>}
                                {dealData && dealData.carPlate && <p className="text-center text-white h6 mt-2 mb-3">ทะเบียน: {dealData.carPlate}</p>}
                                {dealData && dealData.carDescription && <p className="text-center text-white h6 mt-2 mb-3">รายละเอียด: {dealData.carDescription}</p>}

                                {dealData && <p className="text-center text-white h6 mt-2 mb-3">ตัวแทน/ผู้ดูแล:  {dealData.agentCode} {dealData.agentName} {dealData.agentRemark}</p>}
                            </Col>
                            {dealData && <Col md={12} className="text-center mb-2">
                                <a href={dealData.oldpolicyfileurl} target="_blank" ><Button className="btn btn-primary mt-2">
                                    <i className="mdi mdi-download"></i> หน้าตารางกรมธรรม์เดิม
                                </Button></a>
                            </Col>}
                            {!dealData && <Col md={12} className="text-center mb-2">
                                <a href="www.tvinsure.com" target="_blank" >
                                    <Button className="btn btn-primary mt-2">
                                        <i className="mdi mdi-redo-variant"></i> กลับสู่หน้าหลัก
                                    </Button>
                                </a>
                            </Col>}
                        </Row>}

                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}
export default DealResult;

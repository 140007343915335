import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Icons
import FeatherIcon from 'feather-icons-react';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import { AuthContext } from '../../shared/context/auth-context';
import { USERS_URL } from '../../shared/masters/url';
import './PageAuth.css';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid.png';

const PageLogin = (props) => {

    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const authSubmitHandler = async event => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch(`${USERS_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password
                })
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message);
            }
            setIsLoading(false);
            auth.login(responseData.email, responseData.token);
        } catch (err) {
            setIsLoading(false);
            setError(err.message || 'Something went wrong, please try again.');
            setErrorModal(true);
        }
    };

    const handleChange = event => {
        const { name, value } = event.target
        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    // console.log(formData);
    // console.log(error);

    const [errormodal, setErrorModal] = useState(false);

    const toggleErrorModal = () => setErrorModal(!errormodal);

    return (
        <React.Fragment>
            {error && !isLoading && (<div className="justify-content-center">
                <Modal isOpen={errormodal} toggle={toggleErrorModal} >
                    <ModalHeader toggle={toggleErrorModal}>ไม่สามารถเข้าระบบได้</ModalHeader>
                    <ModalBody className="pb-2">
                        <p className="mb-2">อีเมล หรือ รหัสผ่านผิดพลาด กรุณากรอกใหม่อีกครั้ง</p>
                        <p className="mb-2">{error}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>{' '}
                        {/* <Button color="danger" onClick={toggle}>Cancel</Button> */}
                    </ModalFooter>
                </Modal>
            </div>)}
            {/* <div className="back-to-home rounded d-none d-sm-block">
                    <Link to="index" className="btn btn-icon btn-soft-primary"><i><FeatherIcon icon="home" className="icons" /></i></Link>
                </div> */}

            <section
                className="bg-home d-flex align-items-center"
                style={{ backgroundImage: `url(${tvinsurecovid})` }}
                >
                <div className=""></div>
                <Container className="container-auth">
                    <Row className="justify-content-center">
                        <Col lg={5} md={8}>
                            <Card>
                                <CardBody>
                                    <div className="text-center mb-3">
                                        <Link to="/">
                                            <span>
                                                <img src={tvinsurelogo} alt="TVinsure" height="60" />
                                            </span>
                                        </Link>
                                    </div>
                                    <h4 className="text-center">เข้าสู่ระบบบริหารงาน COVID - ใหม่</h4>
                                    <AvForm className="login-form mt-4" onValidSubmit={authSubmitHandler}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>อีเมล <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                    <AvField type="email" className="form-control pl-5"
                                                        errorMessage="อีเมลอยู่ในรูปแบบที่ไม่ถูกต้อง"
                                                        validate={{
                                                            required: { value: true },
                                                            email: { value: true }
                                                        }} placeholder="อีเมล" name="email" onChange={handleChange} value={formData.email} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>รหัสผ่าน <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="lock" className="fea icon-sm icons" /></i>
                                                    <AvField type="password" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกรหัสผ่าน"
                                                        validate={{ required: { value: true } }} name="password" placeholder="รหัสผ่าน" onChange={handleChange} value={formData.password} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={12} className="mb-0 auth-button">
                                                <Button type="submit" color="danger" block>เข้าสู่ระบบ</Button>
                                            </Col>
                                            {/* <Col lg={12} className="mt-4 text-center">
                                                    <h6>Or Signup With</h6>
                                                    <ul className="list-unstyled social-icon mb-0 mt-3">
                                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i><FeatherIcon icon="facebook" className="fea icon-sm fea-social" /></i></Link></li>
                                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i><FeatherIcon icon="github" className="fea icon-sm fea-social" /></i></Link></li>
                                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i><FeatherIcon icon="twitter" className="fea icon-sm fea-social" /></i></Link></li>
                                                        <li className="list-inline-item"><Link to="#" className="rounded"><i><FeatherIcon icon="gitlab" className="fea icon-sm fea-social" /></i></Link></li>
                                                    </ul>
                                                </Col> */}
                                            {/* <Col xs={6} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-dark mr-2 h6 mt-2">ย้อนกลับ...</small><Link to="/" className="font-weight-bold h5 text-danger">หน้าหลัก</Link></p>
                                            </Col>
                                            <Col xs={6} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-dark mr-2 h6 mt-2">ยังไม่มีบัญชี...</small> <Link to="/signup" className="font-weight-bold h5 text-danger">สมัครได้ที่นี่</Link></p>
                                            </Col> */}
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <p className="text-center mt-4 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default PageLogin;
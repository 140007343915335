import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { storage, db } from '../../firebase/config'
import { preName, benefitType, day, month, year, CovStartDateZero, CovEndDateZero, HealthplanList } from '../../shared/masters/refData'


import { getResultNkiVac } from '../components/GetResultNkiVac.js'

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Import Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import nkiinsurancelogo from '../../shared/images/NKI_LOGO.png';

// Import Progressbar
import progressbar1 from '../../shared/images/ProgressBar1.png';
import progressbar2 from '../../shared/images/ProgressBar2.png';
import progressbar3 from '../../shared/images/ProgressBar3.png';
import progressbar4 from '../../shared/images/ProgressBar4.png';

import './Product.css';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid2.jpg';

const HealthApplicationResult = () => {

    let history = useHistory();

    const planId = useParams().planid;
    const idCardNumber = useParams().idcardnumber;

    // filter planId from the params
    const planDetail = HealthplanList.filter(p => p.PLAN_ID === planId)[0]

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [applicationData, setApplicationData] = useState();
    const [applicationId, setApplicationId] = useState();
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent and get record detail of health application
    useEffect(() => {

        db.collection('healthApplication').where('NATIONAL_ID', '==', idCardNumber).where('PLAN_ID', '==', planId).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length === 0) {
                history.push(`/application-form-health/${planId}/${idCardNumber}/info`)
            }
            if (snapshot.docs && snapshot.docs.length > 0) {

                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 1) {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/question`)
                }
                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 2) {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/get-result`)
                }
                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 3) {
                    setApplicationId(snapshot.docs[0].id)
                    setApplicationData(snapshot.docs[0].data())
                    setIsLoading(false);
                    setSuccessModal(true);
                }
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการดังกล่าวได้ กรุณาลองใหม่อีกครั้ง');
                setErrorModal(true);
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            setErrorModal(true);
        })

        window.scrollTo(0, 0);

    }, []);

    // console.log(questionformData)

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        setIsSubmit(false);
        window.location.reload()
    };

    // console.log(formData);
    // console.log(responseData);

    return (
        <React.Fragment>
            {error && !isLoading && (<div className="justify-content-center">
                <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                    <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                    <ModalBody className="pb-2">
                        <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                        <p className="mb-2">{error}</p>
                        <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                        <ul className="mt-1">
                            <li>Line: @tvinsure</li>
                            <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>
                    </ModalFooter>
                </Modal>
            </div>)}

            <section className="bg-auth bg-color" style={{ background: `url(${tvinsurecovid}) center center`, minHeight: "720px", backgroundSize: "contain", backgroundSize: "" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>

                            <Card>
                                <CardBody>
                                    <div className="text-center mb-3">
                                        <span>
                                            <img src={tvinsurelogo} alt="TVinsure" height="60" />
                                        </span>
                                    </div>
                                    <h4 className="text-center mt-5">แผนประกันจาก{planDetail.PLAN_ISSUER}</h4>
                                    <div className="text-center mb-3">
                                        <span>
                                            {planDetail.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                            {planDetail.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "NKI" && <img src={nkiinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                        </span>
                                    </div>
                                    {/* <p className="text-muted text-center mt-2"> # {planDetail.PLAN_ID}</p> */}
                                    <h4 className="text-center mt-2"> {planDetail.PLAN_DESC}</h4>
                                    <h4 className="text-center mt-2 mb-5"> {planDetail.PLAN_COV_TITLE}</h4>
                                    <div className="text-center mb-3 px-5">
                                        <ProgressBar
                                            percent={100}
                                            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                        >
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar1}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar2}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar3}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="60"
                                                        src={progressbar4}
                                                    />
                                                )}
                                            </Step>
                                        </ProgressBar>
                                    </div>

                                    <Row>
                                        <Col md={12}>
                                            <h4 className="text-center h2 mt-5 mb-1 ">ขั้นตอนที่ 4</h4>
                                            <h4 className="text-center text-success h3 mt-1 mb-2">สมัครประกันภัยเสร็จสิ้น</h4>
                                            <p className="text-center mt-1 mb-2">ทางเจ้าหน้าที่จะรีบดำเนินการออกกรมธรรม์อย่างเร็วที่สุด</p>
                                        </Col>
                                        <Col md={12}>
                                            <h4 className="text-center mt-2 mb-5">รายละเอียดการสมัครประกันภัย</h4>
                                        </Col>
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">หมายเลขใบคำขอเอาประกัน</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center">{applicationData.APP_FORM_ID}</p>
                                        </Col>}                                        
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">ไฟล์บัตรประชาชน(อัพโหลด)</p>
                                        </Col>}
                                        {applicationData && <Col md={6} className="text-center font-weight-bold">
                                            {/* <a href={applicationData.NATIONAL_ID_ATTACH} target="_blank" className="text-danger">{applicationData.NATIONAL_ID_FILE_NAME}</a> */}
                                            <a href={applicationData.NATIONAL_ID_ATTACH} target="_blank" className="text-danger">ดาวโหลดไฟล์บัตรประชาชน</a>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">ไฟล์ใบคำขอเอาประกัน(อัพโหลด)</p>
                                        </Col>}
                                        {applicationData && <Col md={6} className="text-center font-weight-bold">
                                            {/* <a href={applicationData.APPLICATION_ATTACH} target="_blank" className="text-danger">{applicationData.APPLICATION_FILE_NAME}</a> */}
                                            <a href={applicationData.APPLICATION_ATTACH} target="_blank" className="text-danger">ดาวโหลดไฟล์ใบคำขอเอาประกัน</a>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">ไฟล์หลักฐานการชำระเงิน(อัพโหลด)</p>
                                        </Col>}
                                        {applicationData && <Col md={6} className="text-center font-weight-bold mb-5">
                                            {/* <a href={applicationData.PAYMENT_ATTACH} target="_blank" className="text-danger">{applicationData.PAYMENT_FILE_NAME}</a> */}
                                            <a href={applicationData.PAYMENT_ATTACH} target="_blank" className="text-danger">ดาวโหลดไฟล์หลักฐานการชำระเงิน</a>
                                        </Col>}
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <h4 className="text-center mt-2 mb-5">รายละเอียดกรมธรรม์ประกันภัย</h4>
                                        </Col>
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">เลขกรมธรรม์ประกันภัย</p>
                                        </Col>}
                                        {applicationData && <Col md={6} className="text-center font-weight-bold">
                                            {applicationData.POLICY_NUM && <a href={applicationData.PAYMENT_ATTACH} target="_blank" className="text-danger"> 174-32-54698-1-2</a>}
                                            {!applicationData.POLICY_NUM &&<p className="text-center text-danger">ยังไม่ได้รับเลขกรมธรรม์</p>}
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">วันคุ้มครอง (เริ่มต้น-สิ้นสุด)</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center">{applicationData.DATE_COV_START} - {applicationData.DATE_COV_END}</p>
                                        </Col>}                                        
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">ชื่อผู้เอาประกันภัย</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center">{applicationData.PRE_NAME} {applicationData.FIRST_NAME} {applicationData.LAST_NAME}</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">เลขบัตรประชาชน</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center mb-5">{applicationData.NATIONAL_ID} </p>
                                        </Col>}

                                    </Row>

                                </CardBody>
                            </Card>
                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default HealthApplicationResult;
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Collapse, CustomInput } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import BackgroundSlider from "react-background-slider";

import { storage, db } from '../../firebase/config'
import { preName, benefitType, day, month, year, yearMoreThanfifteen, yearLessThanfifteen, CovStartDate, CovEndDate, planCovidNewList } from '../../shared/masters/refData'

import { covidApi } from '../../shared/masters/url';


//Import Icons
import FeatherIcon from 'feather-icons-react';


// Import Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import seiinsurancelogo from '../../shared/images/SEI_LOGO.jpg';

// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

// Import css
import './Product.css';

// Import components
import CovidCondition from '../components/CovidCondition';

var uniqid = require('uniqid');

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const CovidApplicationNew = () => {

    const planId = useParams().planid;
    // console.log(planId);
    // const planDetail = planCovidNewList.filter(p => p.PLAN_ID === planId && p.PRODUCT_STATUS === "ACTIVE")[0]
    const planDetail = planCovidNewList.filter(p => p.PLAN_ID === planId)[0]

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [responseData, setResponseData] = useState();
    const [provinceData, setProvinceData] = useState();
    const [districtData, setDistrictData] = useState();
    const [districtSubData, setDistrictSubData] = useState();
    const [districtSubFilter, setDistrictSubFilter] = useState();
    const [agentData, setAgentData] = useState();
    const [error, setError] = useState(null);

    const benefitTypeDhp = benefitType.filter(b => b !== "ตนเอง");

    // Get API Province and DistrictSub and Agent
    useEffect(() => {
        const sendProvinceRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/province`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setProvinceData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendProvinceRequest()
        const sendDistrictSubRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/districtSub`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setDistrictSubData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendDistrictSubRequest()
        const sendAgentRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/agent`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setAgentData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Agent API, please try again.');
                setIsLoading(false);
            }
        }
        sendAgentRequest()
    }, []);

    //contain all form data
    const [formData, setFormData] = useState({
        appid: "CV-" + uniqid.time().toUpperCase(),
        appType: "NEW",
        birthDateDay: "",
        birthDateMonth: "",
        birthDateYear: "",
        prename: "",
        fname: "",
        lname: "",
        gender: "",
        cardType: "nationalidcard",
        idcard: "",
        idcardfilename: "",
        idcardfileurl: "",
        idcardfileurlloadfinish: "unselect",
        address: "",
        province: "",
        provinceid: "",
        district: "",
        districtid: "",
        districtSub: "",
        districtSubid: "",
        benefitType: "",
        benefitPreName: "",
        benefitFirstName: "",
        benefitLastName: "",
        covStartDate: CovStartDate(),
        covEndDate: CovEndDate(),
        tel: "",
        email: "",
        currenDate: new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        agentCode: "",
        agentCheck: "uncheck",
        paymentAmount: "",
        paymentDate: "",
        paymentTime: "",
        paymentfilename: "",
        paymentfileurl: "",
        paymentfileurlloadfinish: "unselect",
        IsConditionOk: false
    });

    // console.log(formData);

    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        if (name === "isConditionOk") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    isConditionOk: true
                }
            });
        }

        if (name === "prename" && value) {
            const gender = preName.filter(n => n.PRE_NAME === value)[0].GENDER
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    prename: value,
                    gender: gender
                }
            });
        }

        if (name === "benefitPreName" && value) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    benefitPreName: value
                }
            });
        }


        //province must have value
        if (name === "province" && value) {
            const provinceCode = provinceData.filter(p => p.PROV_NAME_T === value)[0].PROVINCE_CODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    province: value,
                    provinceid: provinceCode,
                    district: "",
                    districtid: "",
                    districtSub: "",
                    districtSubid: ""
                }
            });
            const sendDistrictRequest = async () => {
                setIsLoading(true);
                try {
                    const response = await axios.post(`${covidApi}/api/masters/district`,
                        JSON.stringify({
                            PROVINCE_CODE: provinceCode
                        }),
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    setDistrictData(response.data)
                    setIsLoading(false);
                } catch (err) {
                    setIsLoading(false);
                    setError(err.message || 'Something went wrong with District API, please try again.');
                }
            }
            sendDistrictRequest()
        }

        //district must have value
        if (name === "district" && value) {
            const districtCode = districtData.filter(d => d.DISTRICT_NAME_T === value)[0].DISTRICT_CODE
            const districtSubFilter = districtSubData.filter(ds => ds.DISTRICT_NAME_T === value)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    district: value,
                    districtid: districtCode,
                    districtSub: "",
                    districtSubid: ""
                }
            });
            setDistrictSubFilter(districtSubFilter)
        }

        //districtSub must have value
        if (name === "districtSub" && value) {
            const districtSubCode = districtSubFilter.filter(ds => ds.DISTRICT_NAME_T_SUB === value)[0].DISTRICT_CODE_SUB
            const zipCode = districtSubData.filter(ds => ds.DISTRICT_CODE_SUB === districtSubCode)[0].ZIPCODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    districtSub: value,
                    districtSubid: districtSubCode,
                    zipcode: zipCode
                }
            });
        }

        if (name === "benefitType" && value === "ตนเอง") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    benefitType: value,
                    benefitPreName: formData.prename,
                    benefitFirstName: formData.fname,
                    benefitLastName: formData.lname
                }
            });
        }

        if (name === "agentCode") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: value,
                    agentCheck: "uncheck"
                }
            });
        }

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    //Handle Check Agent
    const handleCheckAgent = () => {
        const agentFilter = agentData.filter(a => a.AGENT_CODE === formData.agentCode)
        if (agentFilter.length > 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentName: agentFilter[0].AGENT_NAME_T,
                    agentCheck: "success"
                }
            });
        }
        if (agentFilter.length === 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: "",
                    agentName: "กรุณาระบุผู้แจ้งงาน",
                    agentCheck: "fail"
                }
            });
        }
    }

    //Select file function
    const fileSelectedHandler = (event) => {
        const { name, files } = event.target

        const fileMaxSize = 2097152

        if (files[0].size > fileMaxSize) {
            alert("ไฟล์ของคุณมีขนาดเกิน 2 MB กรุณาเลือกไฟล์ใหม่");
            window.location.reload()
        }

        if (files[0].size < fileMaxSize) {
            if (name === "idcardfile") {
                fileIdCardUploadHandler(files[0])
            }

            if (name === "paymentfile") {
                filePaymentUploadHandler(files[0])
            }
        }

        // if (name === "idcardfile") {
        //     fileIdCardUploadHandler(files[0])
        // }

        // if (name === "paymentfile") {
        //     filePaymentUploadHandler(files[0])
        // }
        // console.log(name);
        // console.log(files);
    }

    //Upload Id Card File
    const fileIdCardUploadHandler = (file) => {
        const uploadTask = storage.ref(`idcardfile/${formData.appid + "-IDCARD"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        idcardfileurlloadfinish: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
                // console.log(error.message);
            },
            () => {
                storage
                    .ref('idcardfile')
                    .child(formData.appid + "-IDCARD")
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                idcardfilename: formData.appid + "-IDCARD",
                                idcardfileurl: url,
                                idcardfileurlloadfinish: "uploaded"
                            }
                        });
                        // console.log(url);
                    });
            })
        // console.log(event.target.files);
    }

    const filePaymentUploadHandler = (file) => {
        const uploadTask = storage.ref(`paymentfile/${formData.appid + "-PAYSLIP"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        paymentfileurlloadfinish: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
            },
            () => {
                storage
                    .ref('paymentfile')
                    .child(formData.appid + "-PAYSLIP")
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                paymentfilename: formData.appid + "-PAYSLIP",
                                paymentfileurl: url,
                                paymentfileurlloadfinish: "uploaded"
                            }
                        });
                    });
            })
    }

    const handleValidSubmit = (event, values) => {
        event.preventDefault();
        setIsSubmit(true);
        const requestSubmitFormData = () => {
            setIsLoading(true);
            db.collection('covidApplication').add({
                APP_FORM_ID: formData.appid,
                APP_FORM_TYPE: formData.appType,
                PRE_NAME: formData.prename,
                FIRST_NAME: formData.fname,
                LAST_NAME: formData.lname,
                NATIONAL_ID: formData.idcard.replace(/-/g, ""),
                NATIONAL_ID_FILE_NAME: formData.idcardfilename,
                NATIONAL_ID_ATTACH: formData.idcardfileurl,
                GENDER: formData.gender,
                BIRTH_DATE: `${formData.birthDateDay}/${formData.birthDateMonth}/${formData.birthDateYear}`,
                ADDRESS_DETAIL: formData.address,
                ADDRESS_PROVINCE_ID: formData.provinceid,
                ADDRESS_PROVINCE: formData.province,
                ADDRESS_DISTRICT_ID: formData.districtid,
                ADDRESS_DISTRICT: formData.district,
                ADDRESS_SUB_DISTRICT_ID: formData.districtSubid,
                ADDRESS_SUB_DISTRICT: formData.districtSub,
                ADDRESS_ZIP_CODE: formData.zipcode,
                PHONE_NUM: formData.tel.replace(/-/g, ""),
                EMAIL_ADDRESS: formData.email,
                DATE_COV_START: formData.covStartDate,
                DATE_COV_END: formData.covEndDate,
                BENEFIT_PRE_NAME: formData.benefitPreName,
                BENEFIT_FIRST_NAME: formData.benefitFirstName,
                BENEFIT_LAST_NAME: formData.benefitLastName,
                BENEFIT_TYPE: formData.benefitType,
                PLAN_ID: planDetail.PLAN_ID,
                PLAN_DESC: planDetail.PLAN_DESC + " " + planDetail.PLAN_COV_TITLE,
                PLAN_ISSUER_CODE: planDetail.PLAN_ISSUER_CODE,
                PLAN_ISSUER: planDetail.PLAN_ISSUER,
                NET_PREMIUM_AMT: planDetail.PLAN_NET_PREMIUM_AMT,
                STAMP_DUTY_AMT: planDetail.PLAN_STAMP_DUTY_AMT,
                VAT_AMT: planDetail.PLAN_VAT_AMT,
                TOTAL_PREMIUM: planDetail.PLAN_TOTAL_PREMIUM,
                AGENT_CODE: formData.agentCode,
                AGENT_NAME: formData.agentName,
                AGENT_REMARK: formData.agentRemark || "",
                PAYMENT_FILE_NAME: formData.paymentfilename,
                PAYMENT_ATTACH: formData.paymentfileurl,
                PAYMENT_DATE: `${formData.paymentDate.split("-")[2]}/${formData.paymentDate.split("-")[1]}/${formData.paymentDate.split("-")[0]}`,
                PAYMENT_TIME: formData.paymentTime,
                PAYMENT_AMOUNT: formData.paymentAmount,
                CURRENT_DATE: formData.currenDate,
                PRODUCT_GROUP: planDetail.PRODUCT_GROUP,
                PRODUCT_POLICY: planDetail.PRODUCT_POLICY,
                PRODUCT_PACKAGE: planDetail.PRODUCT_PACKAGE,
                BROKER_CODE: planDetail.BROKER_CODE,
                POLICY_NUM: ""
            }).then(() => {
                db.collection('covidApplication').where('APP_FORM_ID', '==', formData.appid).get().then((snapshot) => {
                    if (snapshot.docs && snapshot.docs.length > 0) {
                        const resonseDataFirebase = snapshot.docs[0].data()
                        setResponseData(resonseDataFirebase)
                        setIsLoading(false);
                        setSuccessModal(true);
                        return resonseDataFirebase
                    } else {
                        setIsLoading(false);
                        setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
                        setErrorModal(true);
                    }
                }).then((resonseDataFirebase) => {
                    const sendCovidApiRequest = async () => {
                        try {
                            const response = await axios.post(`${covidApi}/api/covid-application/apply/payslip`,
                                JSON.stringify(
                                    {
                                        APPL_NUMBER: resonseDataFirebase.APP_FORM_ID,
                                        PRENAME_DESC: resonseDataFirebase.PRE_NAME,
                                        FIRST_NAME: resonseDataFirebase.FIRST_NAME,
                                        LAST_NAME: resonseDataFirebase.LAST_NAME,
                                        ID_CARD: resonseDataFirebase.NATIONAL_ID,
                                        GENDER: resonseDataFirebase.GENDER === "M" ? "ชาย" : resonseDataFirebase.GENDER === "F" ? "หญิง" : "",
                                        BIRTH_DATE: resonseDataFirebase.BIRTH_DATE,
                                        ADDRESS_DETAIL: resonseDataFirebase.ADDRESS_DETAIL,
                                        PROVINCE_NAME: resonseDataFirebase.ADDRESS_PROVINCE,
                                        DISTRICT_NAME: resonseDataFirebase.ADDRESS_PROVINCE,
                                        SUBDISTRICT_NAME: resonseDataFirebase.ADDRESS_SUB_DISTRICT,
                                        ZIP_CODE: resonseDataFirebase.ADDRESS_ZIP_CODE,
                                        EMAIL_ADDRESS: resonseDataFirebase.EMAIL_ADDRESS,
                                        DATE_COV_START: resonseDataFirebase.DATE_COV_START,
                                        DATE_COV_END: resonseDataFirebase.DATE_COV_END,
                                        BENEFIT_DETAIL: `${resonseDataFirebase.BENEFIT_PRE_NAME} ${resonseDataFirebase.BENEFIT_FIRST_NAME} ${resonseDataFirebase.BENEFIT_LAST_NAME} (${resonseDataFirebase.BENEFIT_TYPE})`,
                                        AGENT_CODE: resonseDataFirebase.AGENT_CODE,
                                        PLAN_ISSUER: resonseDataFirebase.PLAN_ISSUER,
                                        PLAN_DESC: resonseDataFirebase.PLAN_DESC,
                                        TOTAL_PREMIUM_AMT: resonseDataFirebase.TOTAL_PREMIUM
                                    }
                                ),
                                {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                            console.log(response)
                            setIsLoading(false);
                        } catch (err) {
                            setIsLoading(false);
                            console.log(err)
                        }
                    }
                    sendCovidApiRequest()
                })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                        setErrorModal(true);
                    })
            })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                    setErrorModal(true);
                });
        }
        requestSubmitFormData()
    }

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        setIsSubmit(false);
        window.location.reload()
    };

    return (
        <React.Fragment>
            <div className="wrapper">

                <MetaTags>
                    <title>ประกันภัยไวรัสโคโรนา - TVinsure</title>
                    <meta name="description" content="ประกันไวรัสโคโรนา TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:description" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureCovidInsOne} />
                </MetaTags>

                {error && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                        <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                            <p className="mb-2">{error}</p>
                            <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>
                        </ModalFooter>
                    </Modal>
                </div>)}

                {responseData && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={successModal} >
                        <ModalHeader toggle={toggleSuccessModal}>สมัครแผนประกันภัยโคโรนาไวรัสสำเร็จ <i><FeatherIcon icon="check" className="fea icon-md icons text-success" /></i></ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-3 text-center h5">สรุปข้อมูลการสมัครทั้งหมดดังนี้</p>
                            <p className="mb-3 text-center h6">หมายเลขการสมัคร: {responseData.APP_FORM_ID}</p>
                            <p className="mb-3 text-center h6">{responseData.PLAN_ID}</p>
                            <p className="mb-3 text-center h6">{responseData.PLAN_DESC}</p>
                            <div className="row">
                                <div className="col-3 text-left">
                                    <p className="mb-2 h6"> วันที่คุ้มครอง</p>
                                </div>
                                <div className="col-9 text-right">
                                    <p>{responseData.DATE_COV_START} - {responseData.DATE_COV_END} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> ชื่อ-นามสกุล</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{responseData.PRE_NAME} {responseData.FIRST_NAME} {responseData.LAST_NAME} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> วันเดือนปีเกิดผู้เอาประกันภัย</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{responseData.BIRTH_DATE}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7 text-left">
                                    <p className="mb-2 h6"> เพศ</p>
                                </div>
                                <div className="col-5 text-right">
                                    <p>{responseData.GENDER === 'M' ? "ชาย" : 'หญิง'} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7 text-left">
                                    <p className="mb-2 h6"> เลขที่บัตรประจำตัวประชาชน</p>
                                </div>
                                <div className="col-5 text-right">
                                    <p>{responseData.NATIONAL_ID} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 text-left">
                                    <p className="mb-2 h6"> ที่อยู่</p>
                                </div>
                                <div className="col-10 text-right">
                                    <p>{responseData.ADDRESS_DETAIL}</p>
                                </div>
                                <div className="col-12 text-right">
                                    <p>{responseData.ADDRESS_SUB_DISTRICT} {responseData.ADDRESS_DISTRICT} {responseData.ADDRESS_PROVINCE} {responseData.ADDRESS_ZIP_CODE}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 text-left">
                                    <p className="mb-2 h6"> ผู้รับประโยชน์</p>
                                </div>
                                <div className="col-8 text-right">
                                    <p>{responseData.BENEFIT_TYPE} - {responseData.BENEFIT_PRE_NAME} {responseData.BENEFIT_FIRST_NAME} {responseData.BENEFIT_LAST_NAME}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> เบอร์โทรศัพท์มือถือ</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{responseData.PHONE_NUM} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-left">
                                    <p className="mb-2 h6"> อีเมล</p>
                                </div>
                                <div className="col-9 text-right">
                                    <p>{responseData.EMAIL_ADDRESS} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> บริษัทประกันภัย</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{responseData.PLAN_ISSUER_CODE} - {responseData.PLAN_ISSUER}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> เบี้ยประกันภัย (เบี้ยรวม)</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{responseData.TOTAL_PREMIUM} บาท</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-left">
                                    <p className="mb-2 h6"> ผู้แจ้งงาน</p>
                                </div>
                                <div className="col-9 text-right">
                                    <p>{responseData.AGENT_CODE} - {responseData.AGENT_NAME} {responseData.AGENT_REMARK}</p>
                                </div>
                            </div>
                            <p className="mb-4 text-center h5">ขอขอบคุณที่ท่านสมัครแผนประกันภัยโคโรนาไวรัสกับเรา</p>
                            <p className="mb-3 text-left"><small className="text-dark">* วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {CovStartDate()} - {CovEndDate()} การสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</small></p>
                            <p className="mb-3 text-left"><small className="text-dark">** กรมธรรม์ประกันภัยมีระยะเวลารอคอย 14 วัน (นับตั้งแต่วันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ)และความคุ้มครองต้องผ่านการพิจารณาจากบริษัทประกันภัย  และ ตามเงื่อนไขการรับประกันของประกันภัยนั้นๆ</small></p>
                            <p className="mb-3 text-left">- ทีมงานทำการตรวจสอบหลักฐานการชำระเงินทุกวันและจะทยอยส่งกรมธรรม์ตามข้อมูลการติดต่อที่ส่งมา</p>
                            <p className="mt-4 mb-3">- หากเกิดข้อผิดพลาด หรือ ไม่ได้รับกรมธรรม์ประกันภัยสามารถติดต่อได้ตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                            <p className="text-left"># {responseData.APP_FORM_ID}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleSuccessModal}>สมัครแผนประกันเพิ่มเติม</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </div>)}

                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section className="bg-half-100 d-table w-100" >
                    <BackgroundSlider
                        images={items}
                        duration={5}
                        transition={3}
                    />
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="text-center mb-5">
                                    <a href="https://tvinsure.com" className="logo h5">
                                        <img src={tvinsurelogo} height="120" alt="" />
                                    </a>
                                </div>
                                <div className="title-heading text-center">
                                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                        ประกันภัยไวรัสโคโรนา (ประกันภัยใหม่)
                                </h4>
                                    <p className="text-center text-white h5 mt-1 mb-5">ทุกแผนประกันภัยมีระยะรอคอย 14 วัน ทั้ง ค่ารักษา / เจอ จ่าย จบ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section style={{ margin: "-120px 0 0" }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} md={10} sm={8}>
                                <Card>

                                    {!planDetail && <CardBody className="shadow-lg">
                                        <h6 className="text-center mt-2 mb-3">ไม่พบแผนประกันที่ต้องการสมัคร หรือ แผนประกันนั้นปิดการขายแล้ว</h6>
                                        <p className="text-center mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                                        <p className="text-center  mb-3">Line: @tvinsure หรือ โทรศัพท์: 0-2266-373-1 ถึง 5</p>
                                    </CardBody>}

                                    {planDetail && <CardBody className="shadow-lg">
                                        {/* <h4 className="text-center mt-5">{planDetail.PLAN_ISSUER}</h4> */}
                                        <div className="text-center mt-5 mb-3">
                                            <span>
                                                {planDetail.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                                {planDetail.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                                {planDetail.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                                {planDetail.PLAN_ISSUER_CODE === "SEI" && <img src={seiinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                            </span>
                                        </div>
                                        {/* <p className="text-muted text-center mt-2"> # {planDetail.PLAN_ID}</p> */}
                                        <h4 className="text-center mt-2"> {planDetail.PLAN_DESC}</h4>
                                        <h6 className="text-center mt-2 mb-5"> {planDetail.PLAN_COV_TITLE}</h6>
                                        <AvForm className="login-form mt-4" onValidSubmit={handleValidSubmit}>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>วันเริ่มต้นความคุ้มครอง (อัตโนมัติ)</Label>
                                                        <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="วันเริ่มต้นความคุ้มครอง" name="covStartDate" value={formData.covStartDate} disabled>{formData.covStartDate}</AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>วันสิ้นสุดความคุ้มครอง (อัตโนมัติ)</Label>
                                                        <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="วันสิ้นสุดความคุ้มครอง" name="covEndDate" value={formData.covEndDate} disabled>{formData.covEndDate}</AvField>
                                                    </FormGroup>
                                                </Col>
                                                <p className="px-3 text-left"><small className="text-dark">** วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {CovStartDate()} - {CovEndDate()} การสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</small></p>
                                                <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-5">ข้อมูลผู้เอาประกันภัย</h4>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>วันเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกวันเกิดผู้เอาประกัน"
                                                            validate={{ required: { value: true } }} placeholder="วันเกิดผู้เอาประกัน" name="birthDateDay" onChange={handleChange}>
                                                            <option value="" selected>วันเกิดผู้เอาประกัน</option>
                                                            {day && day.map(day => (<option value={day.dayValue}>{day.dayDesc}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เดือนเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกเดือนเกิดผู้เอาประกัน"
                                                            validate={{ required: { value: true } }} placeholder="เดือนเกิดผู้เอาประกัน" name="birthDateMonth" onChange={handleChange}>
                                                            <option value="" selected>เดือนเกิดผู้เอาประกัน</option>
                                                            {month && month.map(month => (<option value={month.monthValue}>{month.monthDesc}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                {
                                                    planDetail.PLAN_ID === "SMK-COVID-3-IN-1-300" &&
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" onChange={handleChange}>
                                                                <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                {yearLessThanfifteen && yearLessThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                }
                                                {
                                                    planDetail.PLAN_ID === "SMK-COVID-3-IN-1-500" &&
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" onChange={handleChange}>
                                                                <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                {yearLessThanfifteen && yearLessThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                }
                                                {
                                                    planDetail.PLAN_ID === "SMK-COVID-3-IN-1-250" &&
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" onChange={handleChange}>
                                                                <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                {yearMoreThanfifteen && yearMoreThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                }
                                                {
                                                    planDetail.PLAN_ID === "SMK-COVID-3-IN-1-450" &&
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" onChange={handleChange}>
                                                                <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                {yearMoreThanfifteen && yearMoreThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                }
                                                {
                                                    planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-250" &&
                                                    planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-450" &&
                                                    planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-300" &&
                                                    planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-500" &&
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" onChange={handleChange}>
                                                                <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                {year && year.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                }
                                                <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>คำนำหน้าชื่อ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกคำนำหน้าชื่อ"
                                                            validate={{ required: { value: true } }} placeholder="คำนำหน้าชื่อ" name="prename" onChange={handleChange}>
                                                            <option value="" selected>คำนำหน้าชื่อ</option>
                                                            {preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ชื่อ <span className="text-danger">*</span></Label>
                                                        <AvField type="text" className="form-control"
                                                            errorMessage="กรุณากรอกชื่อ"
                                                            validate={{ required: { value: true } }} placeholder="ชื่อ" name="fname" onChange={handleChange} value={formData.fname} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>นามสกุล <span className="text-danger">*</span></Label>
                                                        <AvField type="text" className="form-control"
                                                            errorMessage="กรุณากรอกนามสกุล"
                                                            validate={{ required: { value: true } }} placeholder="นามสกุล" name="lname" onChange={handleChange} value={formData.lname} />
                                                    </FormGroup>
                                                </Col>
                                                {planDetail.PLAN_ISSUER_CODE !== "DHP" && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง"
                                                            validate={{
                                                                pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' },
                                                                required: { value: true }
                                                            }} placeholder="เลขบัตรประชาชน 13 หลัก" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ประเภทบัตร <span className="text-danger">*</span></Label>
                                                        <AvRadioGroup name="cardType" required inline errorMessage="กรุณาเลือกประเภทบัตร" onChange={handleChange} value={formData.cardType}>
                                                            <AvRadio label="บัตรประชาชน (ID Card)" value="nationalidcard" />
                                                            <AvRadio label="พาสปอร์ต (Passport)" value="passport" />
                                                        </AvRadioGroup>
                                                    </FormGroup>
                                                </Col>}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && formData.cardType === "nationalidcard" && <Col md={8}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง"
                                                            validate={{
                                                                pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' },
                                                                required: { value: true }
                                                            }} placeholder="เลขบัตรประชาชน 13 หลัก" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && formData.cardType === "passport" && <Col md={8}>
                                                    <FormGroup className="position-relative">
                                                        <Label>พาสปอร์ต (Passport) <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขพาสปอร์ต(Passport)"
                                                            validate={{ required: { value: true } }} placeholder="เลขพาสปอร์ต(Passport)" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>}
                                                {planDetail.PLAN_ISSUER_CODE !== "DHP" && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลือกไฟล์บัตรประชาชน <span className="text-danger">*</span></Label>
                                                        {formData.idcardfileurlloadfinish === "uploading" && <small className="text-primary"> กำลังอัพโหลด</small>}
                                                        {formData.idcardfileurlloadfinish === "uploaded" && <small className="text-success"> อัพโหลดเสร็จสิ้น</small>}
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="file" accept="image/*" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบไฟล์บัตรประชาชน"
                                                            validate={{ required: { value: true } }} placeholder="ไฟล์บัตรประชาชน" name="idcardfile" onChange={fileSelectedHandler} />
                                                    </FormGroup>
                                                </Col>}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลือกไฟล์บัตรประชาชน/พาสปอร์ต<span className="text-danger">*</span></Label>
                                                        {formData.idcardfileurlloadfinish === "uploading" && <small className="text-primary"> กำลังอัพโหลด</small>}
                                                        {formData.idcardfileurlloadfinish === "uploaded" && <small className="text-success"> อัพโหลดเสร็จสิ้น</small>}
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="file" accept="image/*" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบไฟล์บัตรประชาชน/พาสปอร์ต"
                                                            validate={{ required: { value: true } }} placeholder="ไฟล์บัตรประชาชน/พาสปอร์ต" name="idcardfile" onChange={fileSelectedHandler} />
                                                    </FormGroup>
                                                </Col>}
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ที่อยู่ <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="map" className="fea icon-sm icons" /></i>
                                                        <AvField type="map" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกที่อยู่"
                                                            validate={{ required: { value: true } }} placeholder="ที่อยู่ เช่น เลขที่บ้าน ชั้น ตึก ถนน ตรอก ซอย" name="address" onChange={handleChange} value={formData.address} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>จังหวัด <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกจังหวัด"
                                                            validate={{ required: { value: true } }} placeholder="จังหวัด" name="province" onChange={handleChange} disabled={!provinceData}>
                                                            <option value="" selected>จังหวัด</option>
                                                            {provinceData && provinceData.map(province => (<option value={province.PROV_NAME_T} >{province.PROV_NAME_T}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>อำเภอ/เขต <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกอำเภอ/เขต"
                                                            validate={{ required: { value: true } }} placeholder="อำเภอ/เขต" name="district" onChange={handleChange} disabled={!districtData && !districtSubData}>
                                                            <option value="" selected>อำเภอ/เขต</option>
                                                            {districtData && districtData.map(district => (<option value={district.DISTRICT_NAME_T}>{district.DISTRICT_NAME_T}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ตำบล/แขวง <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกตำบล/แขวง"
                                                            validate={{ required: { value: true } }} placeholder="ตำบล/แขวง" name="districtSub" onChange={handleChange} disabled={!districtSubFilter}>
                                                            <option value="" selected>ตำบล/แขวง</option>
                                                            {districtSubFilter && districtSubFilter.map(districtSub => (<option value={districtSub.DISTRICT_NAME_T_SUB}>{districtSub.DISTRICT_NAME_T_SUB}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>รหัสไปรษณีย์</Label>
                                                        <AvField type="text" className="form-control"
                                                            placeholder="รหัสไปรษณีย์" name="zipcode" value={formData.zipcode} disabled>{formData.zipcode}</AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ผู้รับประโยชน์ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาระบุผู้รับประโยชน์"
                                                            validate={{ required: { value: true } }} placeholder="ผู้รับประโยชน์" name="benefitType" onChange={handleChange}>
                                                            <option value="" selected>ผู้รับประโยชน์</option>
                                                            {planDetail.PLAN_ISSUER_CODE === "DHP" && benefitTypeDhp && benefitTypeDhp.map(bt => (<option value={bt}>{bt}</option>))}
                                                            {planDetail.PLAN_ISSUER_CODE === "SMK" && benefitTypeDhp && benefitTypeDhp.map(bt => (<option value={bt}>{bt}</option>))}
                                                            {planDetail.PLAN_ISSUER_CODE !== "SMK" && planDetail.PLAN_ISSUER_CODE !== "DHP" && benefitType && benefitType.map(bt => (<option value={bt}>{bt}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>คำนำหน้าชื่อผู้รับประโยชน์ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกคำนำหน้าชื่อผู้รับประโยชน์"
                                                            validate={{ required: { value: true } }} placeholder="คำนำหน้าชื่อ" name="benefitPreName" onChange={handleChange} value={formData.benefitPreName}>
                                                            <option value="">คำนำหน้าชื่อผู้รับประโยชน์</option>
                                                            {preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ชื่อผู้รับประโยชน์ <span className="text-danger">*</span></Label>
                                                        <AvField type="text" className="form-control"
                                                            errorMessage="กรุณากรอกชื่อผู้รับประโยชน์"
                                                            validate={{ required: { value: true } }} placeholder="ชื่อผู้รับประโยชน์" name="benefitFirstName" onChange={handleChange} value={formData.benefitFirstName} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>นามสกุลผู้รับประโยชน์ <span className="text-danger">*</span></Label>
                                                        <AvField type="text" className="form-control"
                                                            errorMessage="กรุณากรอกนามสกุลผู้รับประโยชน์"
                                                            validate={{ required: { value: true } }} placeholder="นามสกุลผู้รับประโยชน์" name="benefitLastName" onChange={handleChange} value={formData.benefitLastName} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-5">ข้อมูลการติดต่อและจัดส่งกรมธรรม์</h4>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>หมายเลขโทรศัพท์มือถือ <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="smartphone" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง"
                                                            validate={{
                                                                pattern: { value: '^[0][689][0-9]-?[0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9]$' },
                                                                required: { value: true }
                                                            }} placeholder="หมายเลขโทรศัพท์มือถือ" name="tel" onChange={handleChange} value={formData.tel} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>อีเมล <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                        <AvField type="email" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกอีเมลที่ถูกต้อง"
                                                            validate={{
                                                                required: { value: true },
                                                                email: { value: true }
                                                            }} placeholder="อีเมล" name="email" onChange={handleChange} value={formData.email} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-5">ข้อมูลรายละเอียดผู้แจ้งงาน</h4>
                                                    <p className="text-center mt-5 mb-5">หากใครไม่ทราบรหัสตัวแทนของตนเอง สามารถสอบถามทีมงาน TVinsure เข้ามาได้เลยผ่านไลน์ @tvinsure หรือเบอร์โทรศัพท์ 0-2266-373-1 ถึง 5</p>
                                                </Col>
                                                <Col lg={2} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>รหัสผู้แจ้งงาน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกรหัสผู้แจ้งงาน"
                                                            validate={{ required: { value: true } }} placeholder="รหัสผู้แจ้งงาน" name="agentCode" onChange={handleChange} value={formData.agentCode} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                        <Button className="mb-2" color={formData.agentCheck === "success" ? "success" : "danger"}
                                                            outline={formData.agentCheck !== "success" && true} block
                                                            disabled={!formData.agentCode}
                                                            onClick={handleCheckAgent}>{formData.agentCheck === "success" ? "ตรวจสอบแล้ว" : "ตรวจสอบ"}
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={5} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label className="text-center">ชื่อผู้แจ้งงาน</Label>
                                                        <i><FeatherIcon icon="headphones" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="ชื่อผู้แจ้งงาน" name="agentName" value={formData.agentName} disabled>{formData.agentName}</AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>หมายเหตุผู้แจ้งงาน</Label>
                                                        <AvField type="text" className="form-control"
                                                            placeholder="หมายเหตุผู้แจ้งงาน" name="agentRemark" onChange={handleChange} value={formData.agentRemark} />
                                                    </FormGroup>
                                                </Col>
                                                {formData.agentCheck === "success" && <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-3">รายละเอียดการชำระเงิน</h4>
                                                    <h4 className="text-center mt-1 mb-3">สำหรับ {formData.agentName}</h4>
                                                    <h6 className="text-center">ยอดที่ต้องชำระสำหรับ {planDetail.PLAN_DESC + " " + planDetail.PLAN_COV_TITLE}</h6>
                                                    <p className="text-center">บัญชีธนาคารกรุงศรี (ออมทรัพย์) 125-9-43316-9 | บจก. ทวีทรัพย์โบรคเกอร์</p>
                                                </Col>}
                                                {formData.agentCheck === "success" && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>สรุปยอดเงินรวมที่ต้องชำระ(บาท)</Label>
                                                        <i><FeatherIcon icon="dollar-sign" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="ยอดเงินรวมที่ต้องชำระ" name="paymentTotal" value={planDetail.PLAN_TOTAL_PREMIUM} disabled>{planDetail.PLAN_TOTAL_PREMIUM}</AvField>
                                                    </FormGroup>
                                                </Col>}
                                                {formData.agentCheck === "success" && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลือกไฟล์การหลักฐานการโอนเงิน <span className="text-danger">*</span></Label>
                                                        {formData.paymentfileurlloadfinish === "uploading" && <small className="text-primary"> กำลังอัพโหลด</small>}
                                                        {formData.paymentfileurlloadfinish === "uploaded" && <small className="text-success"> อัพโหลดเสร็จสิ้น</small>}
                                                        <i><FeatherIcon icon="clipboard" className="fea icon-sm icons" /></i>
                                                        <AvField type="file" accept="image/*" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบไฟล์หลักฐานการโอนเงิน"
                                                            validate={{ required: { value: true } }} placeholder="หลักฐานการโอนเงิน" name="paymentfile" onChange={fileSelectedHandler} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.agentCheck === "success" && <Col md={4}>
                                                    <FormGroup>
                                                        <Label>วันที่ทำรายการโอนเงิน <span className="text-danger">*</span></Label>
                                                        <AvField
                                                            type="date"
                                                            name="paymentDate"
                                                            errorMessage="กรุณากรอกวันที่ทำรายการโอนเงิน"
                                                            validate={{ required: { value: true } }}
                                                            placeholder="วันที่ทำรายการโอนเงิน"
                                                            onChange={handleChange}
                                                            value={formData.paymentDate}
                                                        />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.agentCheck === "success" && <Col md={4}>
                                                    <FormGroup>
                                                        <Label>เวลาทำรายการโอนเงิน <span className="text-danger">*</span></Label>
                                                        <AvField
                                                            type="time"
                                                            name="paymentTime"
                                                            errorMessage="กรุณากรอกเวลาทำรายการโอนเงิน"
                                                            validate={{ required: { value: true } }}
                                                            placeholder="เวลาที่ทำรายการโอนเงิน"
                                                            onChange={handleChange}
                                                            value={formData.paymentTime}
                                                        />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.agentCheck === "success" && <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ยอดเงินโอน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="dollar-sign" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="ยอดเงินโอนไม่ถูกต้อง"
                                                            validate={{ match: { value: 'paymentTotal' }, required: { value: true } }} placeholder="ยอดเงินโอน" name="paymentAmount" onChange={handleChange} value={formData.paymentAmount} />
                                                    </FormGroup>
                                                </Col>}

                                                <Col md={12}>
                                                    <CovidCondition
                                                        covStartDate={CovStartDate()}
                                                        covEndDate={CovEndDate()}
                                                        planId={planDetail.PLAN_ID}
                                                        isClosed={false}
                                                    />
                                                </Col>

                                                <Col md={12}>
                                                    <div className="form-check form-check-inline my-2">
                                                        <FormGroup>
                                                            <CustomInput
                                                                type="checkbox"
                                                                label=" ข้าพเจ้า ได้อ่านเงื่อนไขข้อตกลงและนโยบายการการรับประกันภัยแล้ว ข้าพเจ้ายินยอมให้บริษัทฯ จัดเก็บ ใช้ และเปิดเผยข้อเท็จจริงเกี่ยวกับสุขภาพและข้อมูลข้าพเจ้าต่อสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.) เพื่อประโยชน์ในการกำกับดูแลธุรกิจประกันภัย (บริษัทขอสงวนสิทธิ์การคุ้มครอง กรณีที่ท่านกรอกข้อมูลไม่ครบถ้วนตามที่กำหนด)"
                                                                name="isConditionOk"
                                                                id="isConditionOk"
                                                                value={true}
                                                                disabled={formData.isConditionOk}
                                                                onChange={handleChange}
                                                            />

                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg={12} className="mb-0 auth-button">
                                                    {!isSubmit && <Button color="danger" type="submit" block disabled={formData.idcardfileurlloadfinish !== "uploaded" || formData.paymentfileurlloadfinish !== "uploaded" || !formData.isConditionOk}>สมัครประกันภัย</Button>}
                                                    {isSubmit && <Button color="success" block disabled>กำลังสมัครประกันภัย</Button>}
                                                </Col>
                                                <Col xs={12} className="text-center">
                                                    <p className="mb-0 mt-3"><small className="text-dark mr-2 h6 mt-2">กลับไปเลือกแบบประกันภัยจาก</small><Button onClick={() => {
                                                        const check = window.confirm("ยืนยันการย้อนกลับไปเลือกแบบประกันภัยจากบริษัทอื่น")
                                                        if (check === true) {
                                                            window.location.href = "https://info.tvinsure.com/covid-19/";
                                                        } else {
                                                            return false
                                                        }
                                                    }} className="font-weight-bold h5 text-danger" outline color="danger">บริษัทประกันภัยอื่น ๆ</Button></p>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>}
                                </Card>
                                <Row>
                                    <Col lg={12}>
                                        <p className="text-center mt-5 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                    </Col>
                                    <Col lg={12}>
                                        <p className="text-center text-muted mb-5">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


export default CovidApplicationNew;
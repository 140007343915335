// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import maintenanceImg from "../../shared/images/TVinsure-Covid-1.png";
import logolight from "../../shared/images/newbrands/tvinsure-main-logo.png";

//CountDown
import Countdown from "react-countdown-now";

// Random component
const Completionist = () => <span className="text-white ">... กำลังเตรียมการเปิดระบบ ... </span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <React.Fragment>
        <div id="clock" className="mb-4 overflow-hidden text-center">
          <p className="mb-0 ml-3 mr-3 d-inline-block">
            <span id="hours" className="d-block position-relative">
              {hours}
            </span>
            <span className="indicator position-relative d-block">ชั่วโมง</span>
          </p>
          <p className="mb-0 ml-3 mr-3 d-inline-block mt-sm-30">
            <span id="minutes" className="d-block position-relative">
              {minutes}
            </span>
            <span className="indicator position-relative d-block">นาที</span>
          </p>
          <p className="mb-0 ml-3 mr-3 d-inline-block mt-sm-30">
            <span id="seconds" className="d-block position-relative">
              {seconds}
            </span>
            <span className="indicator position-relative d-block">วินาที</span>
          </p>
        </div>
      </React.Fragment>
    );
  }
};

class PageMaintenance extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="back-to-home rounded d-none d-sm-block">
          <a href="https://tvinsure.com/" className="btn btn-icon btn-soft-primary">
            <i>
              <FeatherIcon icon="home" className="icons" />
            </i>
          </a>
        </div>

        <section
          className="bg-home d-flex align-items-center"
          style={{ backgroundImage: `url(${maintenanceImg})` }}
        >
          <div className="bg-overlay"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={12} className="text-center">
                <Link to="#" className="logo h5">
                  <img src={logolight} height="80" alt="" />
                </Link>
                <div className="text-uppercase text-white title-dark mt-2 mb-4 maintenance font-weight-bold">
                  ปิดปรับปรุงระบบ
                </div>
                <p className="text-white mx-auto para-dark font-weight-bold">
                  ทาง TVinsure ขอปิดปรับปรุงระบบเพื่อให้ลูกค้าได้รับการบริการที่สะดวกสะบายมากขึ้น รบกวนทำรายการมาใหม่อีกครั้งหลังจากระบบเปิดทำการ ขออภัยในความไม่สะดวก
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <div className="text-center">
                  <Countdown
                    date={new Date(2021, 3, 26, 15, 0, 0, 0)}
                    renderer={renderer}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <a href="https://tvinsure.com/" className="btn btn-primary mt-4">
                  <i className="mdi mdi-backup-restore"></i> กลับหน้าหลัก
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageMaintenance;

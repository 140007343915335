// Import General React Asset
import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Collapse } from 'reactstrap';
import { Spinner } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// Get Import CSV Module
import _filefy, { CsvBuilder } from 'filefy';

// Get Firebase Config
import { storage, db } from '../../firebase/config'

// Get Axios Which Will use to call Backend API
import axios from 'axios'

// Get Endpoint to call Backend API
import { covidApi } from '../../shared/masters/url';

// Import Custom AuthContext
import { AuthContext } from '../../shared/context/auth-context';

// Import Tvinsure Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';

// Import ForwardRef Which will use in table workspace
import { forwardRef } from 'react';

// Set Covid Background But didn't Use it anymore
import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid.png';

// Import Which will use in table
import MaterialTable, { MTableToolbar } from 'material-table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

// Import Menu Bar
import Menubar from '../components/MenuBar';

// Import Logo Which will use in button export CSV
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import seiinsurancelogo from '../../shared/images/SEI.jpg';
import gblogo from '../../shared/images/gbp-logo.png';
import genshoplogo from '../../shared/images/products/COVID_GEN_LINK.png';

// Import images Which will use in Cover Web Slider
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

// Import Module Which will use in Cover Web Slider
import BackgroundSlider from "react-background-slider";

// Import CSS
import "./ListPolicy.css";

// Set Image Item Which will use in Cover Web Slider
const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

// Set Icon Which will use in table
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

// Get today date
const currentDate = new Date()

// function to add 1 Year
function addYear(date) {
    const copy = new Date(Number(date))
    copy.setYear(date.getFullYear() + 1)
    return copy
}

// get new coverage date which is to day date and today date plus one year to add automatically to form which will use in the API
const newCovDateStart = currentDate.toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
const newCovDateEnd = addYear(currentDate).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

const ListPolicyRenew = () => {

    // Set Auth Context but 13052564 doesn't use it any more
    const auth = useContext(AuthContext);

    // Set Loading and Set Error when it occur
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Set All data of covid customer
    const [covidAppList, setCovidAppList] = useState([]);

    // Set Covid Select and set Id and Set Data
    const [covidAppSelectId, setcovidAppSelectId] = useState();
    const [covidAppSelect, setcovidAppSelect] = useState();

    // Refresh to get new data
    const [refresh, setrefresh] = useState(false);

    // Set initial data to save and send to TVMS and Email Transaction via API
    const [formData, setFormData] = useState({
        POLICY_NUM: "",
        POLICY_NUM_FILE_NAME: "",
        POLICY_NUM_FILE_URL: "",
        POLICY_NUM_FILE_STATUS: "unselect",
        DATE_COV_START: newCovDateStart,
        DATE_COV_END: newCovDateEnd
    });

    // Set data as change to save and send to TVMS and Email Transaction via API
    const handleChange = event => {
        const { name, value } = event.target

        if (name === "DATE_COV_START") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    DATE_COV_START: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 543}`,
                    DATE_COV_END: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 544}`
                }
            });
        }

        if (name === "POLICY_NUM") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    POLICY_NUM: value.replace(/\s/g, ""),
                    POLICY_NUM_FILE_NAME: value.replace(/\s/g, "").replace(/\//g, "") + ".pdf"
                }
            });
        }
    }

    //  Function to save and send to TVMS and Email Transaction via API
    const handleValidSubmit = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            POLICY_NUM: formData.POLICY_NUM,
            POLICY_NUM_FILE_NAME: formData.POLICY_NUM_FILE_NAME,
            POLICY_NUM_FILE_URL: formData.POLICY_NUM_FILE_URL,
            DATE_COV_START: formData.DATE_COV_START,
            DATE_COV_END: formData.DATE_COV_END,
            POLICY_ATTACH_FILE_DATE: new Date()
        })
            .then(() => {
                const sendCovidApiRequest = async () => {
                    try {
                        const response = await axios.post(`${covidApi}/api/covid-application/create-new-paid`,
                            JSON.stringify({
                                POLICY_NUMBER: formData.POLICY_NUM,
                                POLICY_NUMBER_URL: formData.POLICY_NUM_FILE_URL,
                                APPL_NUMBER: covidAppSelect.APP_FORM_ID,
                                PRENAME_DESC: covidAppSelect.PRE_NAME,
                                FIRST_NAME: covidAppSelect.FIRST_NAME,
                                LAST_NAME: covidAppSelect.LAST_NAME,
                                ID_CARD: covidAppSelect.NATIONAL_ID,
                                GENDER: covidAppSelect.GENDER,
                                BIRTH_DATE: covidAppSelect.BIRTH_DATE,
                                ADDRESS_DETAIL: covidAppSelect.ADDRESS_DETAIL,
                                PROVINCE_CODE: covidAppSelect.ADDRESS_PROVINCE_ID,
                                PROVINCE_NAME: covidAppSelect.ADDRESS_PROVINCE,
                                DISTRICT_CODE: covidAppSelect.ADDRESS_DISTRICT_ID,
                                DISTRICT_NAME: covidAppSelect.ADDRESS_DISTRICT,
                                SUBDISTRICT_NAME: covidAppSelect.ADDRESS_SUB_DISTRICT,
                                ZIP_CODE: covidAppSelect.ADDRESS_ZIP_CODE,
                                PHONE_NUMBER: covidAppSelect.PHONE_NUM,
                                EMAIL_ADDRESS: covidAppSelect.EMAIL_ADDRESS,
                                APPL_DATE: covidAppSelect.CURRENT_DATE,
                                DATE_COV_START: formData.DATE_COV_START,
                                DATE_COV_END: formData.DATE_COV_END,
                                BENEFIT_DETAIL: covidAppSelect.BENEFIT_PRE_NAME + covidAppSelect.BENEFIT_FIRST_NAME + covidAppSelect.BENEFIT_LAST_NAME + " " + covidAppSelect.BENEFIT_TYPE,
                                INS_COMPANY_CODE: covidAppSelect.PLAN_ISSUER_CODE,
                                AGENT_CODE: covidAppSelect.AGENT_CODE,
                                PLAN_ID: covidAppSelect.PLAN_ID,
                                PLAN_DESC: covidAppSelect.PLAN_DESC + covidAppSelect.PLAN_ISSUER,
                                REMARK: covidAppSelect.AGENT_REMARK,
                                NET_PREMIUM_AMT: covidAppSelect.NET_PREMIUM_AMT,
                                STAMP_DUTY_AMT: covidAppSelect.STAMP_DUTY_AMT,
                                VAT_AMT: covidAppSelect.VAT_AMT,
                                TOTAL_PREMIUM_AMT: covidAppSelect.TOTAL_PREMIUM,
                                GBREF_NO: covidAppSelect.GBREF_NO,
                                GBPAYMENT_NO: covidAppSelect.GBPAYMENT_NO,
                                GBCURRENCY_CODE: covidAppSelect.GBCURRENCY_CODE,
                                GBRESULT_CODE: covidAppSelect.GBRESULT_CODE,
                                GBTOTAL_AMOUNT: covidAppSelect.GBTOTAL_AMOUNT,
                                GBTHB_AMOUNT: covidAppSelect.GBTHB_AMOUNT
                            }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        setIsLoading(false);
                        setCardCovidAppDetailModal(false);
                        setrefresh(!refresh)
                    } catch (err) {
                        setIsLoading(false);
                        setError('เกิดปัญหาในการส่ง API เข้าระบบ TVMS กรุณาลองใหม่อีกครั้ง เช่น เลขที่กรมธรรม์ซ้ำ');
                        setCardCovidAppDetailModal(false);
                    }
                }
                sendCovidApiRequest()
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            })
    }

    //  Set Selected file to save and send to TVMS and Email Transaction via API
    const fileSelectedHandler = (event) => {
        const { files } = event.target
        const uploadTask = storage.ref(`policyfile/${formData.POLICY_NUM_FILE_NAME}`).put(files[0]);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        POLICY_NUM_FILE_STATUS: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                // console.log(error.message);
            },
            () => {
                storage
                    .ref('policyfile')
                    .child(formData.POLICY_NUM_FILE_NAME)
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                POLICY_NUM_FILE_URL: url,
                                POLICY_NUM_FILE_STATUS: "uploaded"
                            }
                        });
                        // console.log(url);
                    });
            })
    }

    // Close and open collapse bar which will use in modal function such as cancel function
    const [allCol, setAllCol] = useState({
        col1: false,
        col2: false,
        col3: false,
    });

    const t_col1 = () => {
        setAllCol({
            col1: !allCol.col1,
            col2: false,
            col3: false,
        });
    }
    const t_col2 = () => {
        setAllCol({
            col2: !allCol.col2,
            col1: false,
            col3: false,
        });
    }
    const t_col3 = () => {
        setAllCol({
            col2: false,
            col1: false,
            col3: !allCol.col3,
        });
    }

    // Set initail Form Cancel Detail and it state
    const [formCancelData, setCancelData] = useState({
        cancelAuthorizationCode: "",
        remarkCancel: ""
    });

    // Set new Data Form Cancel Detail as it Change
    const handleCancelForm = event => {
        const { name, value } = event.target

        if (name === "cancelAuthorizationCode") {
            setCancelData(prevValue => {
                return {
                    ...prevValue,
                    cancelAuthorizationCode: value
                }
            });
        }

        if (name === "remarkCancel") {
            setCancelData(prevValue => {
                return {
                    ...prevValue,
                    remarkCancel: value
                }
            });
        }
    }


    // Set Form Cancel Detail then Save and Submit Data
    const handleCancelWithNoPolicySubmit = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            APP_FORM_PAYMENT_STATUS: "CANCEL",
            CANCEL_DATE: new Date(),
            CANCEL_REMARK: formCancelData.remarkCancel,
            CANCEL_AUTHORIZATION_CODE: formCancelData.cancelAuthorizationCode
        })
            .then(() => {
                db.collection('cancelCovidLog').add({
                    appFormId: covidAppSelect.APP_FORM_ID,
                    appId: covidAppSelectId,
                    cancelDate: new Date(),
                    cancelRemark: formCancelData.remarkCancel,
                    cancelAuthorizationCode: formCancelData.cancelAuthorizationCode,
                    cancelChannel: "CancelWithOutPolicyNum"
                })
                setIsLoading(false);
                setCancelData(prevValue => {
                    return {
                        ...prevValue,
                        cancelAuthorizationCode: "",
                        remarkCancel: ""
                    }
                });
                setAllCol({
                    col1: false,
                    col2: false,
                    col3: false,
                });
                setCardCovidAppDetailModal(false);
                setrefresh(!refresh);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                setCardCovidAppDetailModal(false);
            })
    }

    // Set initail Form Problem Detail and it state
    const [formProblemData, setProblemData] = useState({
        problemStatus: "",
        remarkProblem: ""
    });

    // Set new Data Problem Detail as it Change
    const handleProblemFormChange = event => {
        const { name, value } = event.target

        if (name === "problemStatus") {
            setProblemData(prevValue => {
                return {
                    ...prevValue,
                    problemStatus: value
                }
            });
        }

        if (name === "remarkProblem") {
            setProblemData(prevValue => {
                return {
                    ...prevValue,
                    remarkProblem: value
                }
            });
        }
    }

    const handlePolicyProblemSubmit = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            PROBLEM_DATE: new Date(),
            PROBLEM_STATUS: formProblemData.problemStatus,
            PROBLEM_REMARK: formProblemData.remarkProblem
        })
            .then(() => {
                setIsLoading(false);
                setProblemData({
                    problemStatus: "",
                    remarkProblem: ""
                });
                setrefresh(!refresh);
                setAllCol({
                    col1: false,
                    col2: false,
                    col3: false,
                });
                alert("บันทึกรายการติดปัญหาสำเร็จ");
                setCardCovidAppDetailModal(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                setCardCovidAppDetailModal(false);
            })
    }

    const handlePolicyCancelProblemPolicy = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            PROBLEM_DATE: new Date(),
            PROBLEM_STATUS: "",
            PROBLEM_REMARK: ""
        })
            .then(() => {
                setIsLoading(false);
                setProblemData({
                    problemStatus: "",
                    remarkProblem: ""
                });
                setrefresh(!refresh);
                setAllCol({
                    col1: false,
                    col2: false,
                    col3: false,
                });
                alert("ยกเลิกรายการติดปัญหาสำเร็จ");
                setCardCovidAppDetailModal(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการบันทึก กรุณาลองใหม่อีกครั้ง');
                setCardCovidAppDetailModal(false);
            })
    }

    // Get Search Issuer Type from the form
    const [covidDataIssuerSearchType, setCovidDataIssuerSearchType] = useState("");

    // Set Search Type to selected value
    const handleIssuerSearchSelected = event => {
        const { name, value } = event.target
        setCovidDataIssuerSearchType(value)
    }

    // Get Search Issuer Type from the form
    const [covidDataPaymentDateSearch, setCovidDataPaymentDateSearch] = useState("");

    // Set Search Type to selected value
    const handleCovidDataPaymentDateSearch = event => {
        const { name, value } = event.target
        setCovidDataPaymentDateSearch(value)
    }

    // console.log(covidDataPaymentDateSearch);

    // Get Data Unfinish Work Which will be presented in workspace
    const [covidUnfinish, setCovidUnfinish] = useState();

    // const [covidAsiaUnfinish, setCovidAsiaUnfinish] = useState();
    // const [covidDhpUnfinish, setCovidDhpUnfinish] = useState();
    // const [covidSmkUnfinish, setCovidSmkUnfinish] = useState();
    // const [covidVibUnfinish, setCovidVibUnfinish] = useState();
    // const [covidSeiUnfinish, setCovidSeiUnfinish] = useState();

    // Set initial data to save and send to TVMS but No Email Transaction
    const [formDataNoUrl, setFormDataNoUrl] = useState({
        POLICY_NUM: "",
        DATE_COV_START: newCovDateStart,
        DATE_COV_END: newCovDateEnd
    });

    // Set data as change to save and send to TVMS but No Email Transaction
    const handleChangeNoUrl = event => {
        const { name, value } = event.target

        if (name === "DATE_COV_START") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    DATE_COV_START: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 543}`,
                    DATE_COV_END: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 544}`
                }
            });
        }

        if (name === "POLICY_NUM") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    POLICY_NUM: value.replace(/\s/g, ""),
                }
            });
        }
    }

    //  Function to save and send to TVMS No Email Transaction
    const handleValidSubmitNoUrl = (event, values) => {
        event.preventDefault();
        setIsLoading(true);
        db.collection('covidApplication').doc(covidAppSelectId).update({
            POLICY_NUM: formData.POLICY_NUM,
            DATE_COV_START: formData.DATE_COV_START,
            DATE_COV_END: formData.DATE_COV_END,
            POLICY_ATTACH_FILE_DATE: new Date()
        })
            .then(() => {
                const sendCovidApiRequest = async () => {
                    try {
                        const response = await axios.post(`${covidApi}/api/covid-application/create-new-paid-without-file-url`,
                            JSON.stringify({
                                POLICY_NUMBER: formData.POLICY_NUM,
                                APPL_NUMBER: covidAppSelect.APP_FORM_ID,
                                PRENAME_DESC: covidAppSelect.PRE_NAME,
                                FIRST_NAME: covidAppSelect.FIRST_NAME,
                                LAST_NAME: covidAppSelect.LAST_NAME,
                                ID_CARD: covidAppSelect.NATIONAL_ID,
                                GENDER: covidAppSelect.GENDER,
                                BIRTH_DATE: covidAppSelect.BIRTH_DATE,
                                ADDRESS_DETAIL: covidAppSelect.ADDRESS_DETAIL,
                                PROVINCE_CODE: covidAppSelect.ADDRESS_PROVINCE_ID,
                                PROVINCE_NAME: covidAppSelect.ADDRESS_PROVINCE,
                                DISTRICT_CODE: covidAppSelect.ADDRESS_DISTRICT_ID,
                                DISTRICT_NAME: covidAppSelect.ADDRESS_DISTRICT,
                                SUBDISTRICT_NAME: covidAppSelect.ADDRESS_SUB_DISTRICT,
                                ZIP_CODE: covidAppSelect.ADDRESS_ZIP_CODE,
                                PHONE_NUMBER: covidAppSelect.PHONE_NUM,
                                EMAIL_ADDRESS: covidAppSelect.EMAIL_ADDRESS,
                                APPL_DATE: covidAppSelect.CURRENT_DATE,
                                DATE_COV_START: formData.DATE_COV_START,
                                DATE_COV_END: formData.DATE_COV_END,
                                BENEFIT_DETAIL: covidAppSelect.BENEFIT_PRE_NAME + covidAppSelect.BENEFIT_FIRST_NAME + covidAppSelect.BENEFIT_LAST_NAME + " " + covidAppSelect.BENEFIT_TYPE,
                                INS_COMPANY_CODE: covidAppSelect.PLAN_ISSUER_CODE,
                                AGENT_CODE: covidAppSelect.AGENT_CODE,
                                PLAN_ID: covidAppSelect.PLAN_ID,
                                PLAN_DESC: covidAppSelect.PLAN_DESC + covidAppSelect.PLAN_ISSUER,
                                REMARK: covidAppSelect.AGENT_REMARK,
                                NET_PREMIUM_AMT: covidAppSelect.NET_PREMIUM_AMT,
                                STAMP_DUTY_AMT: covidAppSelect.STAMP_DUTY_AMT,
                                VAT_AMT: covidAppSelect.VAT_AMT,
                                TOTAL_PREMIUM_AMT: covidAppSelect.TOTAL_PREMIUM,
                                GBREF_NO: covidAppSelect.GBREF_NO,
                                GBPAYMENT_NO: covidAppSelect.GBPAYMENT_NO,
                                GBCURRENCY_CODE: covidAppSelect.GBCURRENCY_CODE,
                                GBRESULT_CODE: covidAppSelect.GBRESULT_CODE,
                                GBTOTAL_AMOUNT: covidAppSelect.GBTOTAL_AMOUNT,
                                GBTHB_AMOUNT: covidAppSelect.GBTHB_AMOUNT
                            }),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                        setIsLoading(false);
                        alert('บันทึกข้อมูลสำเร็จ : ' + response.data.Result);
                        setAllCol({
                            col1: false,
                            col2: false,
                            col3: false,
                        });
                        setCardCovidAppDetailModal(false);
                        setrefresh(!refresh)
                    } catch (err) {
                        setIsLoading(false);
                        alert('เกิดปัญหาในการส่ง API เข้าระบบ TVMS กรุณาลองใหม่อีกครั้ง : ' + err.response.message);
                        setAllCol({
                            col1: false,
                            col2: false,
                            col3: false,
                        });
                        setCardCovidAppDetailModal(false);
                    }
                }
                sendCovidApiRequest()
            })
            .catch((error) => {
                setIsLoading(false);
                alert('เกิดปัญหาในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง');
            })
    }

    // Get Data from Condition fillter Issuer and Set Unfinish work to the Covid Unfinish State
    useEffect(() => {
        setError(null);
        setIsLoading(true);
        if (covidDataIssuerSearchType) {
            if (covidDataPaymentDateSearch) {
                db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE").where("POLICY_NUM", "==", "").where("PLAN_ISSUER_CODE", "==", covidDataIssuerSearchType).where("GBPAYMENT_DATE_THAI", "==", `${covidDataPaymentDateSearch.split("-")[1]}/${covidDataPaymentDateSearch.split("-")[2]}/${covidDataPaymentDateSearch.split("-")[0]}`).get()
                    .then((snapshot) => {
                        let covidApp = []
                        snapshot.docs.forEach(doc => {
                            const newDoc = doc.data()
                            newDoc.NEW_PAYMENT_DATE = doc.data().GBPAYMENT_DATE_THAI ? `${doc.data().GBPAYMENT_DATE_THAI.split("/")[1]}/${doc.data().GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(doc.data().GBPAYMENT_DATE_THAI.split("/")[2]) + 543}` : ""
                            newDoc.PROBLEM_STATUS_TYPE = doc.data().PROBLEM_STATUS ? "รายการติดปัญหารอการแก้ไข" : "รายการสำเร็จรอการออกกรมธรรม์"
                            covidApp.push(newDoc);
                        })
                        const covidAppfilterAll = covidApp.filter(app => app.PLAN_ISSUER_CODE === covidDataIssuerSearchType)
                        let covidfilterAllDate = []
                        covidAppfilterAll.forEach(app => {
                            covidfilterAllDate.push(`${app.PROBLEM_STATUS ? "รายการติดปัญหารอการแก้ไข" : "รายการสำเร็จรอการออกกรมธรรม์"} ${app.GBPAYMENT_DATE_THAI ? " วันที่ชำระเงิน " + app.NEW_PAYMENT_DATE : ""} วันที่สมัครเริ่มต้น ${app.CURRENT_DATE} แผน ${app.PLAN_ID}`);
                        })
                        var countsfilterAllDate = {};
                        covidfilterAllDate.sort().forEach(function (x) { countsfilterAllDate[x] = (countsfilterAllDate[x] || 0) + 1; });
                        setCovidUnfinish(countsfilterAllDate)
                        setIsLoading(false);
                        setCovidAppList(covidApp);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                    });
                setFormData({
                    POLICY_NUM: "",
                    POLICY_NUM_FILE_NAME: "",
                    POLICY_NUM_FILE_URL: "",
                    POLICY_NUM_FILE_STATUS: "unselect",
                    DATE_COV_START: newCovDateStart,
                    DATE_COV_END: newCovDateEnd
                })
            } else {
                db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE").where("POLICY_NUM", "==", "").where("PLAN_ISSUER_CODE", "==", covidDataIssuerSearchType).get()
                    .then((snapshot) => {
                        let covidApp = []
                        snapshot.docs.forEach(doc => {
                            const newDoc = doc.data()
                            newDoc.NEW_PAYMENT_DATE = doc.data().GBPAYMENT_DATE_THAI ? `${doc.data().GBPAYMENT_DATE_THAI.split("/")[1]}/${doc.data().GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(doc.data().GBPAYMENT_DATE_THAI.split("/")[2]) + 543}` : "ไม่มีข้อมูลวันที่ชำระเงิน"
                            newDoc.PROBLEM_STATUS_TYPE = doc.data().PROBLEM_STATUS ? "รายการติดปัญหารอการแก้ไข" : "รายการสำเร็จรอการออกกรมธรรม์"
                            covidApp.push(newDoc);
                        })
                        const covidAppfilterAll = covidApp.filter(app => app.PLAN_ISSUER_CODE === covidDataIssuerSearchType)
                        let covidfilterAllDate = []
                        covidAppfilterAll.forEach(app => {
                            covidfilterAllDate.push(`${app.PROBLEM_STATUS ? "รายการติดปัญหารอการแก้ไข" : "รายการสำเร็จรอการออกกรมธรรม์"} ${app.GBPAYMENT_DATE_THAI ? " วันที่ชำระเงิน " + app.NEW_PAYMENT_DATE : ""} วันที่สมัครเริ่มต้น ${app.CURRENT_DATE} แผน ${app.PLAN_ID}`);
                        })
                        var countsfilterAllDate = {};
                        covidfilterAllDate.sort().forEach(function (x) { countsfilterAllDate[x] = (countsfilterAllDate[x] || 0) + 1; });
                        setCovidUnfinish(countsfilterAllDate)
                        setIsLoading(false);
                        setCovidAppList(covidApp);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                    });
                setFormData({
                    POLICY_NUM: "",
                    POLICY_NUM_FILE_NAME: "",
                    POLICY_NUM_FILE_URL: "",
                    POLICY_NUM_FILE_STATUS: "unselect",
                    DATE_COV_START: newCovDateStart,
                    DATE_COV_END: newCovDateEnd
                })
            }
        } else {
            setIsLoading(false);
        }
    }, [refresh]);

    //Model Card Detail Set when it has been select
    const [cardCovidAppDetailModal, setCardCovidAppDetailModal] = useState(false);
    const openCardCovidAppDetailModal = (appid) => {
        db.collection('covidApplication').where('APP_FORM_ID', '==', appid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setcovidAppSelectId(snapshot.docs[0].id)
                const newData = snapshot.docs[0].data()
                newData.NEW_PAYMENT_DATE = snapshot.docs[0].data().GBPAYMENT_DATE_THAI ? `${snapshot.docs[0].data().GBPAYMENT_DATE_THAI.split("/")[1]}/${snapshot.docs[0].data().GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(snapshot.docs[0].data().GBPAYMENT_DATE_THAI.split("/")[2]) + 543}` : ""
                setcovidAppSelect(newData)
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setCardCovidAppDetailModal(true);
    };

    // Set all data to none when it close model
    const closeCardCovidAppDetailModal = () => {
        setCardCovidAppDetailModal(false);
        setcovidAppSelectId(null);
        setcovidAppSelect(null);
    };

    // console.log(covidAppList)

    const handleVibCsv = (allColumns, allData) => {
        const columns = [
            { title: 'ลำดับที่', field: 'VIB_NUM' },
            { title: 'รหัสสาขา', field: 'VIB_BRANCH' },
            { title: 'รหัสนายหน้า', field: 'VIB_BROKER' },
            { title: 'วันที่สมัครเริ่มต้น', field: 'CURRENT_DATE_START' },
            { title: 'วันที่สมัครสิ้นสุด', field: 'CURRENT_DATE_END' },
            { title: 'วันที่ชำระเงิน', field: 'GBPAYMENT_DATE_THAI' },
            { title: 'วันและเวลาที่ชำระเงิน', field: 'GBPAYMENT_DATE' },
            { title: 'วันคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'วันสิ้นสุด', field: 'DATE_COV_END' },
            { title: 'จำนวนวันที่คุ้มครอง', field: 'VIB_COV_PERIOD' },
            { title: 'รหัสผลิตภัณฑ์', field: 'PRODUCT_POLICY' },
            { title: 'ทุนประกันภัยใหญ่', field: 'PRODUCT_SUMINS' },
            { title: 'เบี้ยสุทธิ', field: 'NET_PREMIUM_AMT' },
            { title: 'ภาษี', field: 'VAT_AMT' },
            { title: 'อากร', field: 'STAMP_DUTY_AMT' },
            { title: 'เบี้ยรวม', field: 'TOTAL_PREMIUM' },
            { title: 'คำนำหน้าชื่อ', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วัน/เดือน/ปี เกิด', field: 'BIRTH_DATE' },
            { title: 'อายุ', field: 'VIB_AGE' },
            { title: 'สถานะภาพ', field: 'VIB_STATUS' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'โทรศัพท์บ้าน', field: 'VIB_HOME_PHONE' },
            { title: 'เบอร์ต่อโทรศัพท์บ้าน', field: 'VIB_HOME_PHONE_EXTEND' },
            { title: 'โทรศัพท์ที่ทำงาน', field: 'VIB_OFFICE_PHONE' },
            { title: 'เบอร์ต่อโทรศัพท์ที่ทำงาน', field: 'VIB_OFFICE_PHONE_EXTEND' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'Fax', field: 'VIB_FAX' },
            { title: 'เบอร์ต่อ Fax', field: 'VIB_FAX_EXTEND' },
            { title: 'E-mail Address', field: 'EMAIL_ADDRESS' },
            { title: 'คำนำหน้าชื่อผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'หมายเลขการสมัคร', field: 'APP_FORM_ID' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
        ]
        db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE").where("POLICY_NUM", "==", "").where("PLAN_ISSUER_CODE", "==", "VIB").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                const vibData = covidApp.filter(rowData => rowData.PLAN_ISSUER_CODE === "VIB");
                for (var i in vibData) {
                    const newPhoneNum = "'" + vibData[i].PHONE_NUM
                    const newBirthDate = `${vibData[i].BIRTH_DATE.split("/")[0]}/${vibData[i].BIRTH_DATE.split("/")[1]}/${Number(vibData[i].BIRTH_DATE.split("/")[2]) - 543}`
                    const newCovCurrentStartDate = `${vibData[i].CURRENT_DATE.split("/")[0]}/${vibData[i].CURRENT_DATE.split("/")[1]}/${Number(vibData[i].CURRENT_DATE.split("/")[2]) - 543}`
                    const newCovCurrentEndDate = `${vibData[i].CURRENT_DATE.split("/")[0]}/${vibData[i].CURRENT_DATE.split("/")[1]}/${Number(vibData[i].CURRENT_DATE.split("/")[2]) - 542}`
                    const newCovStartDate = `${vibData[i].DATE_COV_START.split("/")[0]}/${vibData[i].DATE_COV_START.split("/")[1]}/${Number(vibData[i].DATE_COV_START.split("/")[2]) - 543}`
                    const newCovEndDate = `${vibData[i].DATE_COV_END.split("/")[0]}/${vibData[i].DATE_COV_END.split("/")[1]}/${Number(vibData[i].DATE_COV_END.split("/")[2]) - 543}`
                    vibData[i].VIB_NUM = Number(i) + 1;
                    vibData[i].VIB_BROKER = "'00093";
                    vibData[i].PHONE_NUM = newPhoneNum;
                    vibData[i].CURRENT_DATE_START = newCovCurrentStartDate;
                    vibData[i].CURRENT_DATE_END = newCovCurrentEndDate;
                    vibData[i].DATE_COV_START = newCovStartDate;
                    vibData[i].DATE_COV_END = newCovEndDate;
                    vibData[i].BIRTH_DATE = newBirthDate;
                    vibData[i].ADDRESS_SUB_DISTRICT = vibData[i].ADDRESS_SUB_DISTRICT.replace("แขวง", "").replace("ตำบล", "");
                    vibData[i].ADDRESS_DISTRICT = vibData[i].ADDRESS_DISTRICT.replace("เขต", "").replace("อำเภอ", "");
                    if (vibData[i].GBPAYMENT_DATE_THAI) {
                        vibData[i].GBPAYMENT_DATE_THAI = `${vibData[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${vibData[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(vibData[i].GBPAYMENT_DATE_THAI.split("/")[2])}`
                    }
                    if (vibData[i].GBPAYMENT_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426 - 543;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        vibData[i].GBPAYMENT_DATE = timeConverter(vibData[i].GBPAYMENT_DATE)
                    }
                }
                const exportedData = vibData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_vib_' + newCovDateStart + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    const handleSmkCsv = (allColumns, allData) => {
        const columns = [
            { title: 'ทุนประกัน', field: 'SMK_SUMINS' },
            { title: 'เบี้ยรวม', field: 'SMK_TOTAL_PREM' },
            { title: 'รหัสคำนำหน้าชื่อผู้เอาประกัน', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วันเดือนปีเกิด (พ.ศ.)', field: 'BIRTH_DATE' },
            { title: 'วันเริ่มคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'เลขที่บัญชี', field: '' },
            { title: 'ส่วนลด', field: '' },
            { title: 'ส่วนลดอื่นๆ', field: '' },
            { title: 'แผนความคุ้มครอง', field: 'SMK_PLAN' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'ลำดับที่ ผู้รับประโยชน์', field: '' },
            { title: 'คำนำหน้าชื่อ ผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ที่อยู่ผู้รับประโยชน์ ', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'วันที่ชำระเงิน(mm/dd/yyyy)', field: '' },
            { title: 'ยอดเงินที่ชำระ(บาท)', field: 'SMK_TOTAL_PREM' },
            { title: 'รหัสตัวแทน', field: 'SMK_BROKER_CODE' },
            { title: 'อีเมล์', field: 'EMAIL_ADDRESS' },
            { title: 'เบอร์มือถือ ส่งกรมธรรม์', field: 'PHONE_NUM' },
            { title: 'เลขที่ใบสั่งซื้อ', field: '' },
            { title: 'วันที่รับแจ้ง', field: 'CURRENT_DATE' },
            { title: 'คำนำหน้าชื่อในใบกำกับภาษี', field: '' },
            { title: 'ชื่อในใบกำกับภาษี', field: '' },
            { title: 'ที่อยู่ในใบกำกับภาษี', field: '' },
            { title: 'แขวง/ตำบล', field: '' },
            { title: 'เขต/อำเภอ', field: '' },
            { title: 'จังหวัด', field: '' },
            { title: 'รหัสไปรษณีย์', field: '' },
            { title: 'ช่องทางการชำระเงิน', field: '' },
            { title: 'เลขประจำตัวผู้เสียภาษี ในใบกำกับภาษี', field: 'NATIONAL_ID' },
            { title: 'การรับกรมธรรม์', field: 'SMK_RECIEVED_POLICY' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
        ]
        db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE").where("POLICY_NUM", "==", "").where("PLAN_ISSUER_CODE", "==", "SMK").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                const smkData = covidApp.filter(rowData => rowData.PLAN_ISSUER_CODE === "SMK");
                for (var i in smkData) {
                    const newPhoneNum = "'" + smkData[i].PHONE_NUM
                    smkData[i].SMK_SUMINS = smkData[i].PLAN_ID === "SMK-450" ? 100000 : smkData[i].PLAN_ID === "SMK-250" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-300" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-500" ? 100000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-250" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-450" ? 100000 : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-329" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-529" ? 100000 : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-489" ? 50000 : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-689" ? 100000 : 0;
                    smkData[i].SMK_TOTAL_PREM = smkData[i].TOTAL_PREMIUM;
                    smkData[i].SMK_PLAN = smkData[i].PLAN_ID === "SMK-450" ? "แผน 2 (สุดคุ้ม)" : smkData[i].PLAN_ID === "SMK-250" ? "แผน 1 (เกินคุ้ม)" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-300" ? "แผน 1 ประกัน Covid 3 in 1" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-500" ? "แผน 2 ประกัน Covid 3 in 1" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-250" ? "แผน 1 ประกัน Covid 3 in 1" : smkData[i].PLAN_ID === "SMK-COVID-3-IN-1-450" ? "แผน 2 ประกัน Covid 3 in 1" : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-329" ? "แผน 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 1" : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-529" ? "แผน 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 2" : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-489" ? "แผน 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 3" : smkData[i].PLAN_ID === "SMK-COVID-2-IN-1-689" ? "แผน 2 in 1 (เจอ จ่าย จบ รวมแพ้วัคซีน) แผน 4" : 0;
                    smkData[i].PHONE_NUM = newPhoneNum;
                    smkData[i].SMK_BROKER_CODE = 2192000;
                    smkData[i].SMK_RECIEVED_POLICY = "C";
                    smkData[i].GENDER = smkData[i].GENDER === "M" ? "ชาย" : smkData[i].GENDER === "F" ? "หญิง" : "0";
                    smkData[i].ADDRESS_SUB_DISTRICT = smkData[i].ADDRESS_SUB_DISTRICT.replace("แขวง", "").replace("ตำบล", "");
                    smkData[i].ADDRESS_DISTRICT = smkData[i].ADDRESS_DISTRICT.replace("เขต", "").replace("อำเภอ", "");
                }
                const exportedData = smkData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_smk_' + newCovDateStart + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    const handleSeiDiagnosticCsv = (allColumns, allData) => {

        // Check Age function using DD/MM/YYYY format like using apply date
        function getAge(dateofBirth, nowDate) {
            // console.log(dateofBirth);
            // console.log(nowDate);
            var today = new Date(Number(nowDate.split("/")[2]), Number(nowDate.split("/")[1] - 1), Number(nowDate.split("/")[0]));
            var birthDate = new Date(Number(dateofBirth.split("/")[2]), Number(dateofBirth.split("/")[1]) - 1, Number(dateofBirth.split("/")[0]));
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        const columns = [
            { title: 'เลขอัตโนมัติ', field: 'COVERNOTE_RUNNING_NUMBER' },
            { title: 'เลขคำนำหน้าหนังสือคุ้มครอง', field: 'PLAN_PREFIX_COVERNOTE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'วันที่สมัครเริ่มต้น', field: 'CURRENT_DATE_START' },
            { title: 'วันที่สมัครสิ้นสุด', field: 'CURRENT_DATE_END' },
            { title: 'วันที่ชำระเงิน', field: 'GBPAYMENT_DATE_THAI' },
            { title: 'วันและเวลาที่ชำระเงิน', field: 'GBPAYMENT_DATE' },
            { title: 'วันคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'วันสิ้นสุด', field: 'DATE_COV_END' },
            { title: 'คำนำหน้าชื่อ', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'ที่อยู่ 1', field: 'ADDRESS_DETAIL_ONE' },
            { title: 'ที่อยู่ 2', field: 'ADDRESS_DETAIL_TWO' },
            { title: 'ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'สัญชาติ', field: 'SEI_NATIONAL' },
            { title: 'ประเทศ', field: 'SEI_COUNTRY' },
            { title: 'ประเภทบัตรคนไทย ใส่ R ', field: 'SEI_CARD_TYPE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'วัน/เดือน/ปี เกิด', field: 'NEW_BIRTH_DATE' },
            { title: 'อายุ', field: 'AGE' },
            { title: 'รหัสตัวแทน', field: 'AGENT_ID' },
            { title: 'รหัสแผน', field: 'PLAN_DATA' },
            { title: 'อาชีพ', field: 'OCCUPATION' },
            { title: 'ผู้รับประโยชน์', field: 'BENEFIT_DETAIL' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'Genpage1', field: 'BRANCH_TELLER' },
            { title: 'Genpage2', field: 'OCCUPATION' },
            { title: 'AgentType', field: 'AGENT_TYPE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'E-mail Address', field: 'EMAIL_ADDRESS' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'หมายเลขการสมัคร', field: 'APP_FORM_ID' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
            { title: 'หมายเหตุ 5', field: 'AGENT_CODE' },
        ]
        db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE").where("POLICY_NUM", "==", "").where("PLAN_ISSUER_CODE", "==", "SEI").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                covidApp.sort((a, b) => (a.COVERNOTE_RUNNING_NUMBER > b.COVERNOTE_RUNNING_NUMBER) ? 1 : -1)
                const SeiData = covidApp.filter(rowData => rowData.PLAN_ISSUER_CODE === "SEI" && rowData.PRODUCT_PACKAGE === "SEI-260-519-778-1037");
                for (var i in SeiData) {
                    const regexSubDistrict = /\sแขวง.+|\sเเขวง.+|\sตำบล.+|\sเขต.+|\sอำเภอ.+|\sจังหวัด.+|\sขว\..+|\sต\..+|\sข\..+|\sอ\..+|\sจ\..+/gi;
                    const newAddressfull = SeiData[i].ADDRESS_DETAIL.replace(regexSubDistrict, "")
                    const newPhoneNum = "'" + SeiData[i].PHONE_NUM
                    const newBirthDate = `'${SeiData[i].BIRTH_DATE.split("/")[0]}-${SeiData[i].BIRTH_DATE.split("/")[1]}-${Number(SeiData[i].BIRTH_DATE.split("/")[2]) - 543}`
                    const newCovCurrentStartDate = `'${SeiData[i].CURRENT_DATE.split("/")[0]}-${SeiData[i].CURRENT_DATE.split("/")[1]}-${Number(SeiData[i].CURRENT_DATE.split("/")[2]) - 543}`
                    const newCovCurrentEndDate = `'${SeiData[i].CURRENT_DATE.split("/")[0]}-${SeiData[i].CURRENT_DATE.split("/")[1]}-${Number(SeiData[i].CURRENT_DATE.split("/")[2]) - 542}`
                    const newCovStartDate = `'${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}-${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}-${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2])}`
                    const newCovEndDate = `'${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}-${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}-${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2]) + 1}`
                    SeiData[i].CURRENT_DATE_START = newCovCurrentStartDate;
                    SeiData[i].CURRENT_DATE_END = newCovCurrentEndDate;
                    SeiData[i].DATE_COV_START = newCovStartDate;
                    SeiData[i].DATE_COV_END = newCovEndDate;
                    SeiData[i].ADDRESS_DETAIL_ONE = newAddressfull.slice(0, 119);
                    SeiData[i].ADDRESS_DETAIL_TWO = newAddressfull.slice(120, 239);
                    SeiData[i].ADDRESS_SUB_DISTRICT = SeiData[i].ADDRESS_SUB_DISTRICT.replace("แขวง", "").replace("ตำบล", "");
                    SeiData[i].ADDRESS_DISTRICT = SeiData[i].ADDRESS_DISTRICT.replace("เขต", "").replace("อำเภอ", "");
                    SeiData[i].PHONE_NUM = newPhoneNum;
                    SeiData[i].SEI_NATIONAL = "THA";
                    SeiData[i].SEI_COUNTRY = "THA";
                    SeiData[i].SEI_CARD_TYPE = "R";
                    SeiData[i].NEW_BIRTH_DATE = newBirthDate;
                    SeiData[i].AGE = getAge(`${SeiData[i].BIRTH_DATE.split("/")[0]}/${SeiData[i].BIRTH_DATE.split("/")[1]}/${Number(SeiData[i].BIRTH_DATE.split("/")[2]) - 543}`, `${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2])}`);
                    SeiData[i].AGENT_ID = "10119795";
                    SeiData[i].PLAN_DATA = SeiData[i].PLAN_ID === "SEI-260" ? "CV04" : SeiData[i].PLAN_ID === "SEI-519" ? "CV01" : "0";
                    SeiData[i].BENEFIT_DETAIL = SeiData[i].BENEFIT_PRE_NAME + " " + SeiData[i].BENEFIT_FIRST_NAME + " " + SeiData[i].BENEFIT_LAST_NAME;
                    SeiData[i].BRANCH_TELLER = "บริษัท ทวีทรัพย์ โบรคเกอร์ จำกัด";
                    SeiData[i].AGENT_TYPE = "Broker";
                    if (SeiData[i].GBPAYMENT_DATE_THAI) {
                        SeiData[i].GBPAYMENT_DATE_THAI = `${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2])}`
                    }
                    if (SeiData[i].GBPAYMENT_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426 - 543;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        SeiData[i].GBPAYMENT_DATE = timeConverter(SeiData[i].GBPAYMENT_DATE)
                    }
                }
                const exportedData = SeiData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_sei_diagnostic_' + new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }) + "_" + covidApp[covidApp.length - 1].COVERNOTE_RUNNING_NUMBER + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    const handleSeiPlusCsv = (allColumns, allData) => {

        // Check Age function using DD/MM/YYYY format like using apply date
        function getAge(dateofBirth, nowDate) {
            // console.log(dateofBirth);
            // console.log(nowDate);
            var today = new Date(Number(nowDate.split("/")[2]), Number(nowDate.split("/")[1] - 1), Number(nowDate.split("/")[0]));
            var birthDate = new Date(Number(dateofBirth.split("/")[2]), Number(dateofBirth.split("/")[1]) - 1, Number(dateofBirth.split("/")[0]));
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        const columns = [
            { title: 'เลขอัตโนมัติ', field: 'COVERNOTE_RUNNING_NUMBER' },
            { title: 'เลขคำนำหน้าหนังสือคุ้มครอง', field: 'PLAN_PREFIX_COVERNOTE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'วันที่สมัครเริ่มต้น', field: 'CURRENT_DATE_START' },
            { title: 'วันที่สมัครสิ้นสุด', field: 'CURRENT_DATE_END' },
            { title: 'วันที่ชำระเงิน', field: 'GBPAYMENT_DATE_THAI' },
            { title: 'วันและเวลาที่ชำระเงิน', field: 'GBPAYMENT_DATE' },
            { title: 'วันคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'วันสิ้นสุด', field: 'DATE_COV_END' },
            { title: 'คำนำหน้าชื่อ', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'ที่อยู่ 1', field: 'ADDRESS_DETAIL_ONE' },
            { title: 'ที่อยู่ 2', field: 'ADDRESS_DETAIL_TWO' },
            { title: 'ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'สัญชาติ', field: 'SEI_NATIONAL' },
            { title: 'ประเทศ', field: 'SEI_COUNTRY' },
            { title: 'ประเภทบัตรคนไทย ใส่ R ', field: 'SEI_CARD_TYPE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'วัน/เดือน/ปี เกิด', field: 'NEW_BIRTH_DATE' },
            { title: 'อายุ', field: 'AGE' },
            { title: 'รหัสตัวแทน', field: 'AGENT_ID' },
            { title: 'รหัสแผน', field: 'PLAN_DATA' },
            { title: 'อาชีพ', field: 'OCCUPATION' },
            { title: 'ผู้รับประโยชน์', field: 'BENEFIT_DETAIL' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'Genpage1', field: 'BRANCH_TELLER' },
            { title: 'Genpage2', field: 'OCCUPATION' },
            { title: 'AgentType', field: 'AGENT_TYPE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'E-mail Address', field: 'EMAIL_ADDRESS' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'หมายเลขการสมัคร', field: 'APP_FORM_ID' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงินอ้างอิง', field: 'GBTHB_AMOUNT' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
            { title: 'หมายเหตุ 5', field: 'AGENT_CODE' },
        ]
        db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE").where("POLICY_NUM", "==", "").where("PLAN_ISSUER_CODE", "==", "SEI").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                covidApp.sort((a, b) => (a.COVERNOTE_RUNNING_NUMBER > b.COVERNOTE_RUNNING_NUMBER) ? 1 : -1)
                const SeiData = covidApp.filter(rowData => rowData.PLAN_ISSUER_CODE === "SEI" && rowData.PRODUCT_PACKAGE === "SEI-PLUS-369-639-799-1369");
                for (var i in SeiData) {
                    const regexSubDistrict = /\sแขวง.+|\sเเขวง.+|\sตำบล.+|\sเขต.+|\sอำเภอ.+|\sจังหวัด.+|\sขว\..+|\sต\..+|\sข\..+|\sอ\..+|\sจ\..+/gi;
                    const newAddressfull = SeiData[i].ADDRESS_DETAIL.replace(regexSubDistrict, "")
                    const newPhoneNum = "'" + SeiData[i].PHONE_NUM
                    const newBirthDate = `'${SeiData[i].BIRTH_DATE.split("/")[0]}-${SeiData[i].BIRTH_DATE.split("/")[1]}-${Number(SeiData[i].BIRTH_DATE.split("/")[2]) - 543}`
                    const newCovCurrentStartDate = `'${SeiData[i].CURRENT_DATE.split("/")[0]}-${SeiData[i].CURRENT_DATE.split("/")[1]}-${Number(SeiData[i].CURRENT_DATE.split("/")[2]) - 543}`
                    const newCovCurrentEndDate = `'${SeiData[i].CURRENT_DATE.split("/")[0]}-${SeiData[i].CURRENT_DATE.split("/")[1]}-${Number(SeiData[i].CURRENT_DATE.split("/")[2]) - 542}`
                    const newCovStartDate = `'${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}-${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}-${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2])}`
                    const newCovEndDate = `'${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}-${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}-${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2]) + 1}`
                    SeiData[i].CURRENT_DATE_START = newCovCurrentStartDate;
                    SeiData[i].CURRENT_DATE_END = newCovCurrentEndDate;
                    SeiData[i].DATE_COV_START = newCovStartDate;
                    SeiData[i].DATE_COV_END = newCovEndDate;
                    SeiData[i].ADDRESS_DETAIL_ONE = newAddressfull.slice(0, 119);
                    SeiData[i].ADDRESS_DETAIL_TWO = newAddressfull.slice(120, 239);
                    SeiData[i].ADDRESS_SUB_DISTRICT = SeiData[i].ADDRESS_SUB_DISTRICT.replace("แขวง", "").replace("ตำบล", "");
                    SeiData[i].ADDRESS_DISTRICT = SeiData[i].ADDRESS_DISTRICT.replace("เขต", "").replace("อำเภอ", "");
                    SeiData[i].PHONE_NUM = newPhoneNum;
                    SeiData[i].SEI_NATIONAL = "THA";
                    SeiData[i].SEI_COUNTRY = "THA";
                    SeiData[i].SEI_CARD_TYPE = "R";
                    SeiData[i].NEW_BIRTH_DATE = newBirthDate;
                    SeiData[i].AGE = getAge(`${SeiData[i].BIRTH_DATE.split("/")[0]}/${SeiData[i].BIRTH_DATE.split("/")[1]}/${Number(SeiData[i].BIRTH_DATE.split("/")[2]) - 543}`, `${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2])}`);
                    SeiData[i].AGENT_ID = "10119795";
                    SeiData[i].PLAN_DATA = SeiData[i].PLAN_ID === "SEI-PLUS-369" ? "CC01" : SeiData[i].PLAN_ID === "SEI-PLUS-639" ? "CC02" : SeiData[i].PLAN_ID === "SEI-PLUS-799" ? "CC03" : SeiData[i].PLAN_ID === "SEI-PLUS-1369" ? "CC04" : "0";
                    SeiData[i].BENEFIT_DETAIL = SeiData[i].BENEFIT_PRE_NAME + " " + SeiData[i].BENEFIT_FIRST_NAME + " " + SeiData[i].BENEFIT_LAST_NAME;
                    SeiData[i].BRANCH_TELLER = "บริษัท ทวีทรัพย์ โบรคเกอร์ จำกัด";
                    SeiData[i].AGENT_TYPE = "Broker";
                    if (SeiData[i].GBPAYMENT_DATE_THAI) {
                        SeiData[i].GBPAYMENT_DATE_THAI = `${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${SeiData[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(SeiData[i].GBPAYMENT_DATE_THAI.split("/")[2])}`
                    }
                    if (SeiData[i].GBPAYMENT_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426 - 543;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        SeiData[i].GBPAYMENT_DATE = timeConverter(SeiData[i].GBPAYMENT_DATE)
                    }
                }
                const exportedData = SeiData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_sei_diagnostic_' + new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }) + "_" + covidApp[covidApp.length - 1].COVERNOTE_RUNNING_NUMBER + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
                alert("ดาวโหลดไฟล์สำเร็จ");
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                alert("ดาวโหลดไฟล์ไม่สำเร็จ");
            });
    }

    const handleGbCsv = (allColumns, allData) => {
        const columns = [
            { title: 'รหัสชำระเงิน', field: 'gbpReferenceNo' },
            { title: 'รหัสอ้างอิง', field: 'referenceNo' },
            { title: 'ช่องทางการชำระเงิน', field: 'paymentType' },
            { title: 'ผลการชำระ', field: 'resultCode' },
            { title: 'ยอดการชำระ', field: 'thbAmount' },
            { title: 'เลชคำขอ', field: 'merchantDefined1' },
            { title: 'บริษัทประกัน', field: 'merchantDefined2' },
            { title: 'บัตรประชาชน', field: 'merchantDefined3' },
            { title: 'รหัสตัวแทน', field: 'merchantDefined4' },
            { title: 'รายละเอียด', field: 'detail' }
        ]
        db.collection("paymentGbLog").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                const exportedData = covidApp.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_gb_' + newCovDateStart + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    const handleGenShopCsv = (allColumns, allData) => {
        const columns = [
            { title: 'รหัสตัวแทน', field: 'agentCode' },
            { title: 'ชื่อตัวแทน', field: 'agentName' },
            { title: 'รหัสโทเคน', field: 'tokenid' },
            { title: 'วันที่สร้างลิงค์', field: 'currenDate' },
            { title: 'จำนวนคนดูหน้าร้าน', field: 'shopView' },
            { title: 'จำนวนคนเลือกซื้อหน้าสินค้า', field: 'productView' },
            { title: 'จำนวนคนชื้อ', field: 'buyView' },
            { title: 'ลิงค์หน้าร้าน', field: 'linkShop' },
        ]
        db.collection("genCovidShopToken").get()
            .then((snapshot) => {
                let shopCovidApp = []
                snapshot.docs.forEach(doc => {
                    shopCovidApp.push(doc.data());
                })
                db.collection("covidApplication")
                    .where("APP_FORM_TYPE", "==", "NEW-GB")
                    .where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE")
                    .get()
                    .then((snapshot) => {
                        let covidApp = []
                        snapshot.docs.forEach(doc => {
                            if (doc.data().AGENT_TOKEN && doc.data().AGENT_TOKEN !== "") {
                                covidApp.push(doc.data().AGENT_TOKEN);
                            }
                        })
                        var counts = {};
                        covidApp.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                        // console.log(covidApp);
                        // console.log(counts);
                        // console.log(counts["T6400385KNTQ3FCF"]);                        
                        for (var i in shopCovidApp) {
                            shopCovidApp[i].shopView = shopCovidApp[i].shopView ? shopCovidApp[i].shopView : 0;
                            shopCovidApp[i].productView = shopCovidApp[i].productView ? shopCovidApp[i].shopView : 0;
                            shopCovidApp[i].buyView = counts[shopCovidApp[i].tokenid] ? counts[shopCovidApp[i].tokenid] : 0;
                        }
                        const exportedData = shopCovidApp.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                        new CsvBuilder('Application_form_genshop_' + newCovDateStart + ".csv")
                            .setColumns(columns.map(columnDef => columnDef.title))
                            .addRows(exportedData)
                            .exportFile();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }

    const handleAllCsv = (allColumns, allData) => {
        const columns = [
            { title: 'รหัสคำนำหน้าชื่อผู้เอาประกัน', field: 'PRE_NAME' },
            { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
            { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
            { title: 'ที่อยู่', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
            { title: 'เพศ ', field: 'GENDER' },
            { title: 'วันเดือนปีเกิด (พ.ศ.)', field: 'BIRTH_DATE' },
            { title: 'วันเริ่มคุ้มครอง', field: 'DATE_COV_START' },
            { title: 'เลขที่บัญชี', field: '' },
            { title: 'ส่วนลด', field: '' },
            { title: 'ส่วนลดอื่นๆ', field: '' },
            { title: 'โทรศัพท์มือถือ', field: 'PHONE_NUM' },
            { title: 'ลำดับที่ ผู้รับประโยชน์', field: '' },
            { title: 'คำนำหน้าชื่อ ผู้รับประโยชน์', field: 'BENEFIT_PRE_NAME' },
            { title: 'ชื่อผู้รับประโยชน์', field: 'BENEFIT_FIRST_NAME' },
            { title: 'นามสกุลผู้รับประโยชน์', field: 'BENEFIT_LAST_NAME' },
            { title: 'ที่อยู่ผู้รับประโยชน์ ', field: 'ADDRESS_DETAIL' },
            { title: 'แขวง/ตำบล', field: 'ADDRESS_SUB_DISTRICT' },
            { title: 'เขต/อำเภอ', field: 'ADDRESS_DISTRICT' },
            { title: 'จังหวัด', field: 'ADDRESS_PROVINCE' },
            { title: 'รหัสไปรษณีย์', field: 'ADDRESS_ZIP_CODE' },
            { title: 'ความสัมพันธ์', field: 'BENEFIT_TYPE' },
            { title: 'ยอดเงินที่ชำระ(บาท)', field: 'PAYMENT_AMOUNT' },
            { title: 'รหัสตัวแทน', field: 'SMK_BROKER_CODE' },
            { title: 'อีเมล์', field: 'EMAIL_ADDRESS' },
            { title: 'วันที่รับแจ้ง', field: 'CURRENT_DATE' },
            { title: 'เลขประจำตัวผู้เสียภาษี ในใบกำกับภาษี', field: 'NATIONAL_ID' },
            { title: 'การรับกรมธรรม์', field: 'SMK_RECIEVED_POLICY' },
            { title: 'หมายเหตุการแจ้งงาน', field: 'AGENT_REMARK' },
            { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
            { title: 'สถานะการสมัคร', field: 'APP_FORM_PAYMENT_STATUS' },
            { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
            { title: 'วันที่แนบไฟล์กรมธรรม์', field: 'POLICY_ATTACH_FILE_DATE' },
            { title: 'เลขหนังสือคุ้มครอง', field: 'COVERNOTE_NUM' },
            { title: 'เลขรันจากระบบสำหรับหนังสือคุ้มครอง', field: 'COVERNOTE_RUNNING_NUMBER' },
            { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
            { title: 'ชื่อตัวแทน', field: 'AGENT_NAME' },
            { title: 'โทเคนตัวแทน', field: 'AGENT_TOKEN' },
            { title: 'หมายเลขอ้างอิง 1', field: 'GBPAYMENT_NO' },
            { title: 'หมายเลขอ้างอิง 2', field: 'GBREF_NO' },
            { title: 'ยอดชำระเงิน', field: 'GBTHB_AMOUNT' },
            { title: 'วันที่ชำระเงิน', field: 'GBPAYMENT_DATE_THAI' },
            { title: 'วันและเวลาที่ชำระเงิน', field: 'GBPAYMENT_DATE' },
            { title: 'หมายเหตุ 1', field: 'APP_FORM_ID' },
            { title: 'หมายเหตุ 2', field: 'PLAN_ISSUER_CODE' },
            { title: 'หมายเหตุ 3', field: 'PLAN_ID' },
            { title: 'หมายเหตุ 4', field: 'PLAN_DESC' },
        ]
        db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "COMPLETE").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                for (var i in covidApp) {
                    if (covidApp[i].GBPAYMENT_DATE_THAI) {
                        covidApp[i].GBPAYMENT_DATE_THAI = `${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[1]}/${covidApp[i].GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidApp[i].GBPAYMENT_DATE_THAI.split("/")[2]) + 543}`
                    }
                    if (covidApp[i].GBPAYMENT_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].GBPAYMENT_DATE = timeConverter(covidApp[i].GBPAYMENT_DATE)
                    }
                    if (covidApp[i].POLICY_ATTACH_FILE_DATE) {
                        function timeConverter(UNIX_timestamp) {
                            var a = new Date(UNIX_timestamp * 1000);
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var year = a.getFullYear() - 1426;
                            var month = months[a.getMonth()];
                            var date = a.getDate();
                            var hour = a.getHours();
                            var min = a.getMinutes();
                            var sec = a.getSeconds();
                            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
                            return time;
                        }
                        covidApp[i].POLICY_ATTACH_FILE_DATE = timeConverter(covidApp[i].POLICY_ATTACH_FILE_DATE)
                    }
                }
                const exportedData = covidApp.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
                new CsvBuilder('Application_form_all_' + newCovDateStart + ".csv")
                    .setColumns(columns.map(columnDef => columnDef.title))
                    .addRows(exportedData)
                    .exportFile();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }



    // console.log("render")

    return (
        <React.Fragment>
            <section className="bg-half-100 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <img src={tvinsurelogo} height="120" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="title-heading text-center">
                        <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                            ระบบบริหารงานประกันภัย
                        </h4>
                        <p className="text-center text-white h5 mt-1 mb-5">สำหรับการใช้งานของพนักงานหลังบ้านเท่านั้น</p>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
            <section style={{ margin: "-120px 0 0" }}>
                <Container>
                    <Row className="justify-content-center">
                        {covidAppSelect && !isLoading && <Modal isOpen={cardCovidAppDetailModal} >
                            <ModalHeader toggle={closeCardCovidAppDetailModal}>เลขที่การสมัคร {covidAppSelect.PROBLEM_STATUS ? (<strike className="text-danger">{covidAppSelect.APP_FORM_ID}</strike>) : covidAppSelect.APP_FORM_ID} {covidAppSelect.PROBLEM_STATUS && <span className="text-danger">รายการติดปัญหา</span>}</ModalHeader>
                            <ModalBody className="pb-2">
                                <p># วันที่สมัคร {covidAppSelect.CURRENT_DATE} - วันที่ชำระเงิน GB {covidAppSelect.NEW_PAYMENT_DATE}</p>
                                <div className="row">
                                    <p className="col-12 text-center h5 font-weight-bold">รายละเอียดแผนประกัน</p>
                                    <p className="col-12 text-center font-weight-bold mb-3">{covidAppSelect.PLAN_ID}{covidAppSelect.APP_FORM_TYPE}</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        แผน:
                                    </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_DESC}
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        บริษัทประกัน:
                                    </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_ISSUER}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบี้ยสุทธิ:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.NET_PREMIUM_AMT} บาท
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        อากร:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.STAMP_DUTY_AMT} บาท
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        VAT:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.VAT_AMT} บาท
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ราคาเบี้ยประกันภัย(เบี้ยรวม):
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.TOTAL_PREMIUM} บาท
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้เอาประกัน</p>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่คุ้มครอง (ตามใบคำขอ):
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        ชื่อผู้เอาประกัน:
                                    </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.PRE_NAME} {covidAppSelect.FIRST_NAME} {covidAppSelect.LAST_NAME}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันเดือนปีเกิดผู้เอาประกัน:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BIRTH_DATE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เพศ:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GENDER === "M" ? "ชาย" : "หญิง"}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        บัตรประชาชน:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.NATIONAL_ID_ATTACH}>{covidAppSelect.NATIONAL_ID}</a>
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        ที่อยู่:
                                    </div>
                                    <div className="col-9 text-right">
                                        {covidAppSelect.ADDRESS_DETAIL}
                                    </div>
                                    <div className="col-12 text-right mb-2">
                                        {covidAppSelect.ADDRESS_SUB_DISTRICT} - {covidAppSelect.ADDRESS_DISTRICT} - จังหวัด{covidAppSelect.ADDRESS_PROVINCE} - {covidAppSelect.ADDRESS_ZIP_CODE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ชื่อผู้รับประโยชน์:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BENEFIT_PRE_NAME} {covidAppSelect.BENEFIT_FIRST_NAME} {covidAppSelect.BENEFIT_LAST_NAME} ({covidAppSelect.BENEFIT_TYPE})
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบอร์โทรศัพท์:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.PHONE_NUM}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        อีเมล:
                                    </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.EMAIL_ADDRESS}
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้ดูแล</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        ผู้ดูแล:
                                    </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.AGENT_CODE} - {covidAppSelect.AGENT_NAME} {covidAppSelect.AGENT_REMARK}
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดการชำระเงิน</p>
                                    <div className="col-6 text-left font-weight-bold">
                                        สถานะการชำระเงิน:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.APP_FORM_PAYMENT_STATUS === "COMPLETE" ? "ชำระสำเร็จ" : covidAppSelect.APP_FORM_PAYMENT_STATUS === "INITIALIZE" ? "ชำระไม่สำเร็จ" : covidAppSelect.APP_FORM_PAYMENT_STATUS === "CANCEL" ? "ยกเลิก" : "มีความขัดข้อง"}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่ชำระเงิน:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.NEW_PAYMENT_DATE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        หมายเลขการอ้างอิง TVinsure:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.APP_FORM_REF_PAYMENT_NO}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        หมายเลขอ้างอิงการชำระ GB 1:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GBPAYMENT_NO}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        หมายเลขอ้างอิงการชำระ GB 2:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GBREF_NO}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ยอดการชำระเงิน:
                                    </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GBTOTAL_AMOUNT} บาท
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่คุ้มครอง (ตามหน้ากรมธรรม์):
                                    </div>
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                    </div>}
                                    {!covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        {formData.DATE_COV_START} - {formData.DATE_COV_END}
                                    </div>}
                                    {covidAppSelect.POLICY_NUM && <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดกรมธรรม์</p>}
                                    {covidAppSelect.COVERNOTE_NUM && <div className="col-6 text-left font-weight-bold">
                                        เลขความคุ้มครอง:
                                    </div>}
                                    {covidAppSelect.COVERNOTE_NUM && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.PLAN_ISSUER_CODE === "SEI" && covidAppSelect.COVERNOTE_NUM && <Link className="text-danger" to={`/get-covid-cover-note/${covidAppSelect.APP_FORM_ID}/${covidAppSelect.COVERNOTE_NUM}`} target="_blank">{covidAppSelect.COVERNOTE_NUM}</Link>}
                                    </div>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-left font-weight-bold">
                                        เลขกรมธรรม์:
                                    </div>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.POLICY_NUM_FILE_URL}>{covidAppSelect.POLICY_NUM}</a>
                                    </div>}
                                    {!covidAppSelect.POLICY_NUM && covidAppSelect.APP_FORM_PAYMENT_STATUS !== "CANCEL" && <div className="col-12 text-right mb-2">
                                        <AvForm className="mr-3 mt-4" onValidSubmit={handleValidSubmit}>
                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>วันที่เริ่มต้นคุ้มครอง <span className="text-danger">*</span></Label>
                                                        <AvField
                                                            type="date"
                                                            name="DATE_COV_START"
                                                            errorMessage="กรุณากรอกวันที่เริ่มต้นคุ้มครอง"
                                                            validate={{ required: { value: true } }}
                                                            placeholder="วันที่เริ่มต้นคุ้มครอง"
                                                            onChange={handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>เลขกรมธรรม์ <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่เลขกรมธรรม์"
                                                            validate={{ required: { value: true } }} placeholder="ใส่เลขกรมธรรม์" name="POLICY_NUM" onChange={handleChange} value={formData.POLICY_NUM} />
                                                    </FormGroup>
                                                </Col>
                                                {formData.POLICY_NUM_FILE_NAME && formData.POLICY_NUM && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลือกไฟล์กรมธรรม์ <span className="text-danger">*</span></Label>
                                                        <AvField type="file" accept="application/pdf" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบไฟล์กรมธรรม์"
                                                            validate={{ required: { value: true } }} placeholder="ไฟล์บัตรประชาชน" name="POLICY_NUM_FILE" onChange={fileSelectedHandler} />
                                                        {formData.POLICY_NUM_FILE_STATUS === "uploading" && <Button color="danger" disabled={true} block>กำลังอัพโหลดไฟล์กรมธรรม์.....</Button>}
                                                        {formData.POLICY_NUM_FILE_STATUS === "uploaded" && <Button color="success" disabled={true} block>อัพโหลดไฟล์กรมธรรม์เสร็จสิ้น</Button>}
                                                    </FormGroup>
                                                </Col>}
                                                <Col lg={12} className="mb-0 auth-button">
                                                    <Button color="danger" block disabled={formData.POLICY_NUM_FILE_STATUS !== "uploaded"}>ส่งไฟล์กรมธรรม์ให้ลูกค้าและบันทึกลง TVMS</Button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>}
                                </div>

                                {!covidAppSelect.POLICY_NUM && <Card className="border-0 rounded mt-3 mb-2">
                                    <Link
                                        to="#"
                                        onClick={t_col1}
                                        className={
                                            allCol.col1
                                                ? "faq position-relative text-primary"
                                                : "faq position-relative text-dark"
                                        }
                                    >
                                        <CardHeader
                                            className="border-0 bg-light"
                                            id="headingOne"
                                        >
                                            <h6 className="title mb-0">
                                                ต้องการจะยกการสมัครประกันภัย?
                                                <i
                                                    className={
                                                        allCol.col1
                                                            ? "mdi mdi-chevron-up float-right"
                                                            : "mdi mdi-chevron-down float-right"
                                                    }
                                                ></i>
                                            </h6>
                                        </CardHeader>
                                    </Link>
                                    <Collapse isOpen={allCol.col1}>
                                        <CardBody className="px-2 py-4">
                                            <p className="text-dark font-weight-bold">การยกเลิกการสมัครประกันภัยเป็นการเปลี่ยนข้อมูลที่ลูกค้าชำระเงินมาแล้วเป็นสถานะยกเลิกและไม่สามารถแนบไฟล์และใส่เลขกรมธรรม์ได้อีก กรุณาใช้วิจารณญาณและโปรดตรวจสอบให้ดีก่อนการยกเลิกการสมัครประกันภัย</p>
                                            <p className="text-dark"><small> 1. ตรวจสอบให้แน่ใจลูกค้ามีความประสงค์ที่จะยกเลิกการสมัครประกันภัย และผู้ที่ทำเรื่องยกเลิกต้องประสานงานกับฝ่ายงานการคืนเงินลูกค้าอย่างถูกต้อง และเก็บเอกสารหลักฐานที่เกี่ยวข้องให้ครบถ้วน เช่น หน้าบัตรประชาชนผู้แจ้งยกเลิกพร้อมลงชื่อแจ้งเหตุผล หน้าสมุดบัญชีเพื่อที่จะทำเงินคืน</small></p>
                                            <p className="text-dark"><small> 2. ใส่รายละเอียดกยกเลิกการสมัครทุกครั้ง ห้ามทิ้งไว้เป็นค่าว่างเพื่อที่จะให้คนอื่น ๆ กลับเข้ามาเช็ครายละเอียดได้</small></p>
                                            <p className="text-dark"><small> 3. ใส่รหัสผ่านการอนุมัติรายการให้ถูกต้อง หลังจากนั้นยกเลิกการสมัครประกันภัย ระบบจะทำเปลี่ยนสถานะการสมัครดังกล่าวเป็นยกเลิก</small></p>
                                            <AvForm className="mt-2" onValidSubmit={
                                                (event) => {
                                                    if (
                                                        formCancelData.cancelAuthorizationCode === "SUKRITB"
                                                        || formCancelData.cancelAuthorizationCode === "PENNAPA"
                                                        || formCancelData.cancelAuthorizationCode === "JIRAPORN"
                                                        || formCancelData.cancelAuthorizationCode === "PHIKUL"
                                                        || formCancelData.cancelAuthorizationCode === "SATHITA"
                                                        || formCancelData.cancelAuthorizationCode === "SANITA"
                                                    ) {
                                                        const check = window.confirm("ยืนยันการยกเลิกเลขการสมัครประกันภัย")
                                                        if (check === true) {
                                                            handleCancelWithNoPolicySubmit(event)
                                                        } else {
                                                            return false
                                                        }
                                                    } else {
                                                        alert("ไม่อนุมัติการยกเลิการสมัครประกันภัยกรุณาติดต่อผู้ดูแลระบบ")
                                                    }
                                                }
                                            }>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>หมายเหตุการยกเลิกการสมัครประกันภัย <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่หมายเหตุการยกเลิก"
                                                            validate={{ required: { value: true } }} placeholder="ใส่หมายเหตุการยกเลิก" name="remarkCancel" onChange={handleCancelForm} value={formCancelData.remarkCancel} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>รหัสการอนุมัติการยกเลิกการสมัครประกันภัย <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="กรุณาใส่รหัสการอนุมัติการยกเลิกการสมัครประกันภัย"
                                                            validate={{ required: { value: true } }} placeholder="ใส่รหัสการอนุมัติการยกเลิกการสมัครประกันภัย" name="cancelAuthorizationCode" onChange={handleCancelForm} value={formCancelData.cancelAuthorizationCode} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12} className="mb-4 auth-button">
                                                    <Button color="danger" block >ยกเลิกการสมัครประกันภัย</Button>
                                                </Col>
                                            </AvForm>
                                        </CardBody>
                                    </Collapse>
                                </Card>}

                                {!covidAppSelect.POLICY_NUM && <Card className="border-0 rounded mt-3 mb-2">
                                    <Link
                                        to="#"
                                        onClick={t_col2}
                                        className={
                                            allCol.col2
                                                ? "faq position-relative text-primary"
                                                : "faq position-relative text-dark"
                                        }
                                    >
                                        <CardHeader
                                            className="border-0 bg-light"
                                            id="headingOne"
                                        >
                                            <h6 className="title mb-0">
                                                เคสดังกล่าวติดปัญหาหรือไม่?
                                                <i
                                                    className={
                                                        allCol.col2
                                                            ? "mdi mdi-chevron-up float-right"
                                                            : "mdi mdi-chevron-down float-right"
                                                    }
                                                ></i>
                                            </h6>
                                        </CardHeader>
                                    </Link>
                                    <Collapse isOpen={allCol.col2}>
                                        <CardBody className="px-2 py-4">

                                            {covidAppSelect.PROBLEM_STATUS && <div className="row mb-4 ">
                                                <div className="col-12 text-left ml-3 font-weight-bold">
                                                    สถานะการติดปัญหาล่าสุด:
                                                </div>
                                                <div className="col-12 text-left ml-4 mb-2">
                                                    {covidAppSelect.PROBLEM_STATUS}
                                                </div>
                                                <div className="col-12 text-left ml-3 font-weight-bold">
                                                    หมายเหตุการติดปัญหา:
                                                </div>
                                                <div className="col-12 text-left ml-4 mb-2">
                                                    {covidAppSelect.PROBLEM_REMARK}
                                                </div>
                                            </div>}
                                            <p className="text-dark font-weight-bold">เคสดังกล่าวติดปัญหาเนื่องจากไฟล์มีปัญหา ออกกรมธรรม์ซ้ำกับนายหน้าอื่น มีความคุ้มครองเกินกำหนด หรือการชำระเงินมีปัญหา</p>
                                            <p className="text-dark"><small> 1. ใส่สถานะการแจ้งการติดปัญหาว่าทำไปถึงขั้นไหนแล้ว เช่น ออกกรมธรรม์ไม่ได้ แจ้งลูกค้าแล้ว แจ้งประกันไปแล้ว</small></p>
                                            <p className="text-dark"><small> 2. ใส่รายละเอียดกการแจ้งการติดปัญหาทุกครั้ง ห้ามทิ้งไว้เป็นค่าว่างเพื่อที่จะให้คนอื่น ๆ กลับเข้ามาเช็ครายละเอียดได้</small></p>
                                            <p className="text-dark"><small> 3. กดปุ่มบันทึกรายการติดปัญหาเพื่อบันทึกรายการที่เกี่ยวข้อง</small></p>
                                            {covidAppSelect.PROBLEM_STATUS && <p className="text-dark"><small> ** สำหรับรายการที่ใส่รายการติดปัญหาแล้วสามารถยกเลิกได้จากการกดปุ่ม "ยกเลิกรายการติดปัญหา"</small></p>}
                                            <AvForm className="mt-2" onValidSubmit={
                                                (event) => {
                                                    const check = window.confirm("ยืนยันการแจ้งการติดปัญหา")
                                                    if (check === true) {
                                                        handlePolicyProblemSubmit(event)
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            }>
                                                <Col lg={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>สถานะรายการติดปัญหา<span className="text-danger">*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกบริษัทประกันภัย"
                                                            validate={{ required: { value: true } }} name="problemStatus" onChange={handleProblemFormChange} value={formProblemData.problemStatus}>
                                                            <option value="" disabled>สถานะรายการติดปัญหา</option>
                                                            <option value="ไม่สามารถออกกรมธรรม์ได้">ไม่สามารถออกกรมธรรม์ได้</option>
                                                            <option value="ออกกรมธรรม์แล้วแต่รายการติดปัญหา">ออกกรมธรรม์แล้วแต่รายการติดปัญหา</option>
                                                            <option value="แจ้งลูกค้า/ตัวแทนแล้วแต่ยังไม่แจ้งประกัน">แจ้งลูกค้า/ตัวแทนแล้วแต่ยังไม่แจ้งประกัน</option>
                                                            <option value="แจ้งประกันและลูกค้า/ตัวแทนแล้ว">แจ้งประกันและลูกค้า/ตัวแทนแล้ว</option>
                                                            <option value="รอกการติดต่อจากประกัน">รอกการติดต่อจากประกัน</option>
                                                            <option value="บันทึกลงระบบประกันแล้วแต่ยังไม่ได้เลขกรมธรรม์">บันทึกลงระบบประกันแล้วแต่ยังไม่ได้เลขกรมธรรม์</option>
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Label>หมายเหตุการแจ้งรายการติดปัญหา <span className="text-danger">*</span></Label>
                                                        <AvField type="text"
                                                            errorMessage="หมายเหตุการแจ้งรายการติดปัญหา"
                                                            validate={{ required: { value: true } }} placeholder="ใส่มายเหตุการแจ้งรายการติดปัญหา" name="remarkProblem" onChange={handleProblemFormChange} value={formCancelData.remarkProblem} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={12} className="mb-4 auth-button">
                                                    <Button color="danger" block >บันทึกรายการติดปัญหา</Button>
                                                </Col>
                                            </AvForm>
                                            {covidAppSelect.PROBLEM_STATUS && <Col lg={12} className="mb-4 auth-button">
                                                <Button color="success" block onClick={
                                                    (event) => {
                                                        const check = window.confirm("ยืนยันการยกเลิกรายการติดปัญหา")
                                                        if (check === true) {
                                                            handlePolicyCancelProblemPolicy(event)
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                }>ยกเลิกรายการติดปัญหา</Button>
                                            </Col>}
                                        </CardBody>
                                    </Collapse>
                                </Card>}


                                {!covidAppSelect.POLICY_NUM && <Card className="border-0 rounded mt-3 mb-2">
                                    <Link
                                        to="#"
                                        onClick={t_col3}
                                        className={
                                            allCol.col3
                                                ? "faq position-relative text-primary"
                                                : "faq position-relative text-dark"
                                        }
                                    >
                                        <CardHeader
                                            className="border-0 bg-light"
                                            id="headingOne"
                                        >
                                            <h6 className="title mb-0">
                                                ต้องการบันทึกกรมธรรม์โดยไม่มีไฟล์ใช่หรือไม่?
                                                <i
                                                    className={
                                                        allCol.col3
                                                            ? "mdi mdi-chevron-up float-right"
                                                            : "mdi mdi-chevron-down float-right"
                                                    }
                                                ></i>
                                            </h6>
                                        </CardHeader>
                                    </Link>
                                    <Collapse isOpen={allCol.col3}>
                                        <CardBody className="px-2 py-4">

                                            <p className="text-dark font-weight-bold">บันทึกกรมธรรมโดยไม่ต้องแนบไฟล์ด้วยการกรองข้อมูลตามด้านล่าง</p>
                                            <AvForm className="mr-3 mt-4" onValidSubmit={
                                                (event) => {
                                                    const check = window.confirm("ยืนยันการบันทึกกรมธรรม์ลงระบบโดยไม่มีไฟล์")
                                                    if (check === true) {
                                                        handleValidSubmitNoUrl(event)
                                                    } else {
                                                        return false
                                                    }
                                                }
                                            }>
                                                <Row>
                                                    <Col lg={12}>
                                                        <FormGroup>
                                                            <Label>วันที่เริ่มต้นคุ้มครอง <span className="text-danger">*</span></Label>
                                                            <AvField
                                                                type="date"
                                                                name="DATE_COV_START"
                                                                errorMessage="กรุณากรอกวันที่เริ่มต้นคุ้มครอง"
                                                                validate={{ required: { value: true } }}
                                                                placeholder="วันที่เริ่มต้นคุ้มครอง"
                                                                onChange={handleChangeNoUrl}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <FormGroup>
                                                            <Label>เลขกรมธรรม์ <span className="text-danger">*</span></Label>
                                                            <AvField type="text"
                                                                errorMessage="กรุณาใส่เลขกรมธรรม์"
                                                                validate={{ required: { value: true } }} placeholder="ใส่เลขกรมธรรม์" name="POLICY_NUM" onChange={handleChangeNoUrl} value={formDataNoUrl.POLICY_NUM} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={12} className="mb-0 auth-button">
                                                        <Button color="danger" block>บันทึกเลขกรมธรรม์และวันคุ้มครองลง TVMS</Button>
                                                    </Col>
                                                </Row>
                                            </AvForm>

                                        </CardBody>
                                    </Collapse>
                                </Card>}


                            </ModalBody>
                            <ModalFooter className="mx-auto">
                                <Button color="danger" outline onClick={closeCardCovidAppDetailModal}>ย้อนกลับ</Button>
                            </ModalFooter>
                        </Modal>}
                        <Col lg={12} md={10} sm={8}>

                            <Menubar page="list-policy-paid-gb" />

                            <Card className="mb-5 shadow">
                                <CardBody >
                                    {error && <div className="row">
                                        <div className="col-xl-12 text-center mt-5">
                                            <div style={{ maxWidth: '100%' }}>
                                                <p className="text-dark font-weight-bold">{error}</p>
                                            </div>
                                        </div>
                                    </div>}

                                    {isLoading && <div className="row">
                                        <div className="col-xl-12 text-center mt-5">
                                            <div style={{ maxWidth: '100%' }}>
                                                <Spinner color="danger" />
                                                <p className="text-dark font-weight-bold mt-5">กำลังโหลดข้อมูล...</p>
                                            </div>
                                        </div>
                                    </div>}

                                    {!isLoading && <h6 className="text-center h4 my-4"> เลือกเงื่อนไขการเรียกข้อมูลและกดค้นหาเพื่อเรียกข้อมูล</h6>}
                                    {!isLoading && covidDataIssuerSearchType && <h6 className="text-center h6 my-4"> คุณกำลังค้นหาข้อมูลจาก {covidDataIssuerSearchType} ...</h6>}
                                    {!isLoading && <AvForm className="login-form mt-4" onValidSubmit={() => setrefresh(!refresh)} >
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลือกบริษัทประกันภัยที่ต้องการดูรายละเอียด<span className="text-danger">*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกบริษัทประกันภัย"
                                                        validate={{ required: { value: true } }} name="Issuer" onChange={handleIssuerSearchSelected} value={covidDataIssuerSearchType}>
                                                        <option value="" disabled>ค้นหาตามบริษัทประกันภัย</option>
                                                        <option value="AII">รายละเอียดกรมธรรม์ที่ต้องออกแบ่งตามวันคุ้มครอง (งานออกใหม่เอเชียประกันภัย) - AII</option>
                                                        <option value="DHP">รายละเอียดกรมธรรม์ที่ต้องออกแบ่งตามวันคุ้มครอง (งานออกใหม่ทิพยประกันภัย) - DHP</option>
                                                        <option value="SMK">รายละเอียดกรมธรรม์ที่ต้องออกแบ่งตามวันคุ้มครอง (งานออกใหม่สินมั่นคงประกันภัย) - SMK</option>
                                                        <option value="VIB">รายละเอียดกรมธรรม์ที่ต้องออกแบ่งตามวันคุ้มครอง (งานออกใหม่วิริยะประกันภัย) - VIB</option>
                                                        <option value="SEI">รายละเอียดกรมธรรม์ที่ต้องออกแบ่งตามวันคุ้มครอง (งานออกใหม่อาคเนย์ประกันภัย) - SEI</option>
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>ค้นหาตามวันที่ชำระเงิน </Label>
                                                    <AvField
                                                        type="date"
                                                        name="paymentDate"
                                                        errorMessage="กรุณากรอกวันที่ชำระเงินที่ต้องการค้นหา"
                                                        // validate={{ required: { value: true } }}
                                                        placeholder="กรุณากรอกวันที่ชำระเงินที่ต้องการค้นหา"
                                                        onChange={handleCovidDataPaymentDateSearch}
                                                        value={covidDataPaymentDateSearch}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="position-relative">
                                                    <Label>กดค้นหาเพื่อเรียกข้อมูล</Label>
                                                    <Button type="submit" block color="danger" disabled={isLoading}> ค้นหาข้อมูลใหม่ </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AvForm>}

                                    {!isLoading && covidUnfinish && <div className="text-left">
                                        {covidUnfinish && <ul>
                                            {Object.keys(covidUnfinish).map(function (keyName, keyIndex) {
                                                return (
                                                    <li key={keyName}>
                                                        {keyName}  จำนวนรวมทั้งหมด {covidUnfinish[keyName]} รายการ
                                                    </li>
                                                )
                                            })}
                                        </ul>}
                                    </div>}
                                </CardBody>
                            </Card>

                            <MaterialTable
                                icons={tableIcons}
                                columns={[
                                    { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
                                    { title: 'เลขที่การสมัคร', field: 'APP_FORM_ID' },
                                    { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
                                    { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
                                    { title: 'วันที่ชำระเงิน', field: 'NEW_PAYMENT_DATE', defaultGroupOrder: 2 },
                                    { title: 'วันที่สมัครประกันภัย', field: 'CURRENT_DATE', defaultGroupOrder: 3 },
                                    { title: 'วันที่เริ่มความคุ้มครอง', field: 'DATE_COV_START' },
                                    { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
                                    { title: 'รหัสอ้างอิงการชำระ', field: 'GBREF_NO' },
                                    { title: 'สถานะรายการสมัคร', field: 'PROBLEM_STATUS_TYPE', defaultGroupOrder: 0 },
                                    { title: 'บริษัทประกัน', field: 'PLAN_ISSUER_CODE', defaultGroupOrder: 1 },
                                    { title: 'แผนประกันภัย', field: 'PLAN_ID', defaultGroupOrder: 4 },
                                    { title: 'เบี้ยประกันภัย', field: 'TOTAL_PREMIUM' },
                                    { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
                                    { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
                                ]}
                                data={covidAppList}
                                title={isLoading ? "กำลังโหลดข้อมูล..." : covidAppList.length > 0 ? `สมัครใหม่ชำระแล้ว (GB) และยังไม่ได้กรมธรรม์ ${covidAppList.length} รายการ` : "กรุณาใส่เงื่อนไขและกดค้นหาเพื่อเรียกข้อมูล"}
                                actions={[
                                    {
                                        icon: () => <ChevronRightIcon />,
                                        tooltip: 'รายละเอียด',
                                        onClick: (event, rowData) => {
                                            openCardCovidAppDetailModal(rowData.APP_FORM_ID);
                                        }
                                    }
                                ]}

                                components={{
                                    Toolbar: props => (
                                        <Container>
                                            <Row>
                                                <Col lg={12}    >
                                                    <MTableToolbar {...props} />
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="btn btn-pills btn-primary mb-3 mr-2" style={{ float: 'right' }} onClick={() => setrefresh(!refresh)}> ดึงข้อมูลใหม่ </div>
                                                            <div className="btn btn-pills btn-success mb-3 mr-2" style={{ float: 'right' }} onClick={handleAllCsv}> ข้อมูลการสมัครทั้งหมด </div>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleGbCsv}><img src={gblogo} alt="GB" height="30" /> ดาวโหลดการชำระ GB</div>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleGenShopCsv}><img src={genshoplogo} alt="SHOP" height="30" /> ดาวโหลดข้อมูล Gen Shop</div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleVibCsv}><img src={vibinsurancelogo} alt="VIB" height="30" /> ดาวโหลดแจ้งวิริยะ</div>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleSmkCsv}><img src={smkinsurancelogo} alt="SMK" height="30" /> ดาวโหลดแจ้งสินมั่นคง</div>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleSeiDiagnosticCsv}><img src={seiinsurancelogo} alt="SEI" height="30" /> แจ้งอาคเนย์ - เจอจ่ายจบ</div>
                                                            <div className="btn btn-pills btn-light mb-3 mr-2" style={{ float: 'left' }} onClick={handleSeiPlusCsv}><img src={seiinsurancelogo} alt="SEI" height="30" /> แจ้งอาคเนย์ - Plus</div>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    ),
                                }}


                                options={{
                                    tableLayout: "auto",
                                    exportButton: true,
                                    grouping: true,
                                    headerStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    searchFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    toolbarFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    paging: true,
                                    pageSize: 20,       // make initial page size
                                    emptyRowsWhenPaging: false,   //to make page size not fix (dynamic) in case of less data rows
                                    pageSizeOptions: [5, 10, 20, 50, 100, 200, 1000],    // rows selection options
                                }}

                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} จาก {count}',
                                        labelRowsSelect: 'รายการ'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} รายการที่เลือก',
                                        searchPlaceholder: 'ค้นหา'
                                    },
                                    header: {
                                        actions: ''
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'ไม่มีรายการ',
                                        filterRow: {
                                            filterTooltip: 'ตัวกรอง'
                                        }
                                    },
                                    grouping: {
                                        placeholder: 'ลากหัวข้อมาที่นี่เพื่อแบ่งกลุ่ม',
                                        groupedBy: 'แบ่งกลุ่มโดย: '
                                    }
                                }}

                            />
                        </Col>
                        <p className="text-center my-4 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                    </Row>
                </Container>
            </section>
        </React.Fragment >
    );
}


export default ListPolicyRenew;
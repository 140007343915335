import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';

import { planCovidNewList } from '../../shared/masters/refData'

const timeRef = 18

function addDaysOnly(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
        copy.setDate(date.getDate() + days)
        return copy
    } else {
        return copy
    }
}

function addDaysAndOneYear(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
        copy.setDate(date.getDate() + days)
        copy.setYear(date.getFullYear() + 1)
        return copy
    } else {
        copy.setYear(date.getFullYear() + 1)
        return copy
    }
}

const CovidApplicationNew = ({ covStartDate, covEndDate, planId, isClosed }) => {

    const [allCol, setAllCol] = useState({
        col1: !isClosed,
        col2: !isClosed,
    });

    const t_col1 = () => {
        setAllCol({
            col1: !allCol.col1,
            col2: false,
        });
    }
    const t_col2 = () => {
        setAllCol({
            col2: !allCol.col2,
            col1: false,
        });
    }

    const planDetail = planCovidNewList.filter(p => p.PLAN_ID === planId)[0]
    return (

        <div className="faq-content mt-4">
            <div className="accordion" id="accordionExample">
                <Card className="border-0 rounded mb-2">
                    <Link
                        to="#"
                        onClick={t_col1}
                        className={
                            allCol.col1
                                ? "faq position-relative text-primary"
                                : "faq position-relative text-dark"
                        }
                    >
                        <CardHeader
                            className="border-0 bg-light p-3 pr-5"
                            id="headingOne"
                        >
                            <h6 className="title mb-0">
                                เงื่อนไขการรับประกันภัยจาก{planDetail.PLAN_ISSUER}
                                <i
                                    className={
                                        allCol.col1
                                            ? "mdi mdi-chevron-up float-right"
                                            : "mdi mdi-chevron-down float-right"
                                    }
                                ></i>
                            </h6>
                        </CardHeader>
                    </Link>
                    <Collapse isOpen={allCol.col1}>
                        <CardBody className="px-2 py-4">
                            {planDetail.PRODUCT_PACKAGE === "SEI-PLUS-369-639-799-1369" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้ขอเอาประกันภัยต้องมีอายุ 1-99 ปี (คำนวณอายุ ปี พ.ศ. ปัจจุบัน – ปี พ.ศ. เกิด)</li>
                                <li>สามารถซื้อกรมธรรม์ประกันภัยได้คนละ 1 ฉบับ ต่อ 1 ท่านเท่านั้น สำหรับแผนประกันภัยโควิด-19 พลัส</li>
                                <li>สัญชาติไทยและอาศัยอยู่ในประเทศไทยเท่านั้น กรณีที่ไม่ใช่สัญชาติไทย ผู้ขอเอาประกันภัยจะต้องพำนักอยู่ในประเทศไทยมาแล้ว เป็นระยะเวลาติดต่อกันอย่างน้อย 6 เดือน ก่อนวันที่กรมธรรม์ประกันภัยเริ่มมีผลบังตับ และมีใบอนุญาตทำงาน (Work Permit)</li>
                                <li>กรมธรรม์ประกันภัยฉบับนี้ไม่คุ้มครองกรณีที่ผู้ขอเอาประกันภัยเดินทางไปยังประเทศ หรือเขตบริหารพิเศษ หรือเมืองที่กระทรวงสาธารณสุขได้ประกาศเป็นพื้นที่เสี่ยงต่อการติดเชื้อไวรัส COVID-19 ภายใน 1 เดือน ก่อนวันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ</li>
                                <li>กรมธรรม์ประกันภัยฉบับนี้มีระยะเวลารอคอย 14 วัน (นับตั้งแต่วันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ)</li>
                                <li>บริษัทรับประกันภัย ขอสงวนสิทธ์ไม่รับประกันภัยผู้ที่ติดเชื้อมาก่อนการทำประกันภัย หรือต้องสงสัยว่าติดเชื้อ COVID-19 โดยถูกกักตัวเพื่อรอดูอาการ หรือถูกตรวจสุขภาพ หรือเพื่อการรักษาให้หายจากการเจ็บป่วยในเวลาต่อมา</li>
                                <li>รับประกันภัยภายใต้เงื่อนไขกรมธรรม์ประกันภัยอุบัติเหตุและการเจ็บป่วยด้วยโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19)</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "SEI-260-519-778-1037" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้ขอเอาประกันภัยต้องมีอายุ 1-99 ปี</li>
                                <li>ไม่รับประกันภัยผู้ที่ติดเชื้อมาก่อนการทำประกันภัย หรือต้องสงสัยว่าติดเชื้อ COVID-19 โดยถูกกักตัวเพื่อรอดูอาการ หรือถูกตรวจสุขภาพ หรือเพื่อการรักษาให้หายจากการเจ็บป่วยในเวลาต่อมา</li>
                                <li>ผู้ขอเอาประกันภัย 1 คน สามารถซื้อกรมธรรม์ประกันภัยได้ 1 ฉบับ</li>
                                <li>บริษัทฯ จะไม่คุ้มครองกรณีที่ผู้ขอเอาประกันภัยเดินทางไปยังประเทศ หรือเขตบริหารพิเศษ หรือเมืองที่กระทรวงสาธารณสุขได้ประกาศเป็นพื้นที่เสี่ยงต่อการติดเชื้อไวรัส COVID-19 ภายใน 1 เดือน ก่อนวันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ รวมถึงแผนการเดินทางไปยังประเทศ หรือเชตบริหารพิเศษ หรือเมืองที่กระทรวงสาธารณสุขได้ประกาศเป็นพื้นที่เสี่ยงต่อการติดเชื้อไวรัส COVID-19 ในอีก 3 เดือน นับตั้งแต่วันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ รายชื่อประเทศที่กระทรวงสาธารณสุขได้ประกาศเพิ่มเติมให้เป็นพื้นที่เสี่ยงต่อการติดเชื้อไวรัส COVID-19 ได้ และรวมถึงประเทศอื่นๆ ที่กระทรวงสาธารณสุขได้มีการประกาศเพิ่มเติมให้เป็นพื้นที่เสียงต่อ COVID-19</li>
                                <li>มีระยะเวลารอคอย 14 วัน (นับตั้งแต่วันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ)</li>
                                <li>สงวนสิทธิ์สำหรับผู้เอาประกันภัยที่ถือสัญชาติไทยและอาศัยอยู่ในประเทศไทยเท่านั้น</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "SMK-2-IN-1" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยและพำนักอยู่ในประเทศไทย และอายุ 1-99 ปี</li>
                                <li>ผู้ขอเอาประกันภัยสามารถทำประกันภัยได้เพียง 1 ฉบับต่อ 1 ท่านเท่านั้น</li>
                                <li>ผู้ขอเอาประกันภัยต้องไม่เป็นผู้ป่วยจากเชื้อไวรัสโคโรนา หรือไม่ถูกกักตัวโดยภาครัฐเนื่องจากต้องสงสัยว่าติดเชื้อไวรัสโคโรนา ก่อนทำประกันภัย</li>
                                <li>ผู้ขอเอาประกันต้องมีร่างกายแข็งแรงสมบูรณ์ ไม่ได้ป่วยเป็นโรคหัวใจ โรคไต โรคปอด โรคเอดส์ โรคมะเร็ง หรือผู้ป่วยติดเตียง </li>
                                <li>ผู้ขอเอาประกันภัยไม่ได้มีอาชีพเป็นแพทย์ หรือบุคลากรทางการแพทย์ หรือนักบิน หรือผู้ที่ทำงานเกี่ยวข้องกับสนามบิน เครื่องบิน หรือเรือเดินทะเล</li>
                                <li>บริษัทขอสงวนสิทธิ์ไม่รับประกันภัยแบบกลุ่มหรือองค์กร (ไม่เกิน 20 คน)</li>
                                <li>การประกันภัยนี้ไม่คุ้มครองผู้เอาประกันภัยที่จะเดินทางไปต่างประเทศในอีก 3 เดือนนับจากวันที่เริ่มคุ้มครอง</li>
                                <li>การประกันภัยนี้มีระยะเวลารอคอย (waiting period) โรคติดเชื้อไวรัสโคโรนา 14 วัน สำหรับกรมธรรม์ปีแรก</li>
                                <li>การประกันภัยนี้มีระยะเวลารอคอย (waiting period) การคุ้มครองการแพ้วัคซีน ไม่มี</li>
                                <li>โรคอื่นๆ ได้แก่ โรคไข้หวัดใหญ่ โรคมือ เท้า ปาก โรคตาแดง/โรคเยื่อบุตาอักเสบ โรคไข้เลือดออก/โรคไข้เดงกี่ โรคคาวาซากิ โรคชิคุนกุนยา/โรคไข้ปวดข้อยุงลาย โรคมาลาเรีย/โรคไข้จับสั่น โรคไข้สมองอักเสบ โรคติดเชื้อไวรัสซิกา</li>
                                <li>การประกันภัยนี้คุ้มครองเฉพาะการติดเชื้อไวรัสโคโรนาที่เกิดขึ้นในประเทศไทยเท่านั้น</li>
                                <li>ผู้ขอเอาประกันภัยทำความเข้าใจในรายละเอียดความคุ้มครองและเงื่อนไขก่อนตัดสินใจทำประกัน</li>
                                <li>ผู้เอาประกันต้องกรอกใบคำขอเอาประกันภัยให้ครบถ้วน พร้อมแนบบัตรประชาชน</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "SMK-3-IN-1" && <ul className="text-dark mb-0 faq-ans">
                                {planDetail.PLAN_ID === "SMK-COVID-3-IN-1-300" && <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยและพำนักอยู่ในประเทศไทย และอายุ 1-14 ปี</li>}
                                {planDetail.PLAN_ID === "SMK-COVID-3-IN-1-500" && <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยและพำนักอยู่ในประเทศไทย และอายุ 1-14 ปี</li>}
                                {planDetail.PLAN_ID === "SMK-COVID-3-IN-1-250" && <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยและพำนักอยู่ในประเทศไทย และอายุ 15-99 ปี</li>}
                                {planDetail.PLAN_ID === "SMK-COVID-3-IN-1-450" && <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยและพำนักอยู่ในประเทศไทย และอายุ 15-99 ปี</li>}
                                <li>ผู้ขอเอาประกันภัยสามารถทำประกันภัยได้เพียง 1 ฉบับต่อ 1 ท่านเท่านั้น</li>
                                <li>ผู้ขอเอาประกันภัยต้องไม่เป็นผู้ป่วยจากเชื้อไวรัสโคโรนา หรือไม่ถูกกักตัวโดยภาครัฐเนื่องจากต้องสงสัยว่าติดเชื้อไวรัสโคโรนา ก่อนทำประกันภัย</li>
                                <li>ผู้ขอเอาประกันต้องมีร่างกายแข็งแรงสมบูรณ์ ไม่ได้ป่วยเป็นโรคหัวใจ โรคไต โรคปอด โรคเอดส์ โรคมะเร็ง หรือผู้ป่วยติดเตียง</li>
                                <li>ผู้ขอเอาประกันภัยไม่ได้มีอาชีพเป็นแพทย์ หรือบุคลากรทางการแพทย์ หรือนักบิน หรือผู้ที่ทำงานเกี่ยวข้องกับสนามบิน เครื่องบิน หรือเรือเดินทะเล</li>
                                <li>บริษัทขอสงวนสิทธิ์ไม่รับประกันภัยแบบกลุ่มหรือองค์กร (ไม่เกิน 20 คน)</li>
                                <li>การประกันภัยนี้ไม่คุ้มครองผู้เอาประกันภัยที่จะเดินทางไปต่างประเทศในอีก 3 เดือนนับจากวันที่เริ่มคุ้มครอง</li>
                                <li>การประกันภัยนี้มีระยะเวลารอคอย (waiting period) โรคติดเชื้อไวรัสโคโรนา 14 วัน สำหรับกรมธรรม์ปีแรก</li>
                                <li>การประกันภัยนี้มีระยะเวลารอคอย (waiting period) โรคอื่นๆ ที่ได้รับความคุ้มครอง  30 วัน สำหรับกรมธรรม์ปีแรก</li>
                                <li>โรคอื่นๆ ได้แก่ โรคไข้หวัดใหญ่ โรคมือ เท้า ปาก โรคตาแดง/โรคเยื่อบุตาอักเสบ โรคไข้เลือดออก/โรคไข้เดงกี่ โรคคาวาซากิ โรคชิคุนกุนยา/โรคไข้ปวดข้อยุงลาย โรคมาลาเรีย/โรคไข้จับสั่น โรคไข้สมองอักเสบ โรคติดเชื้อไวรัสซิกา</li>
                                <li>การประกันภัยนี้คุ้มครองเฉพาะการติดเชื้อไวรัสโคโรนาที่เกิดขึ้นในประเทศไทยเท่านั้น</li>
                                <li>ผู้ขอเอาประกันภัยทำความเข้าใจในรายละเอียดความคุ้มครองและเงื่อนไขก่อนตัดสินใจทำประกัน</li>
                                <li>ผู้เอาประกันต้องกรอกใบคำขอเอาประกันภัยให้ครบถ้วน พร้อมแนบบัตรประชาชน</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "SMK-250-450" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยและพำนักอยู่ในประเทศไทย และอายุ 1-99 ปี</li>
                                <li>ผู้ขอเอาประกันภัยสามารถทำประกันภัยได้เพียง 1 ฉบับต่อ 1 ท่านเท่านั้น</li>
                                <li>ผู้ขอเอาประกันภัยต้องไม่เป็นผู้ป่วยจากเชื้อไวรัสโคโรนา หรือไม่ถูกกักตัวโดยภาครัฐเนื่องจากต้องสงสัยว่าติดเชื้อไวรัสโคโรนา ก่อนทำประกันภัย</li>
                                <li>ผู้ขอเอาประกันต้องมีร่างกายแข็งแรงสมบูรณ์ ไม่ได้ป่วยเป็นโรคหัวใจ โรคไต โรคปอด โรคเอดส์ โรคมะเร็ง หรือผู้ป่วยติดเตียง</li>
                                <li>ผู้ขอเอาประกันภัยไม่ได้มีอาชีพเป็นแพทย์ หรือบุคลากรทางการแพทย์ หรือนักบิน หรือผู้ที่ทำงานเกี่ยวข้องกับสนามบิน เครื่องบิน หรือเรือเดินทะเล</li>
                                <li>บริษัทขอสงวนสิทธิ์ไม่รับประกันภัยแบบกลุ่มหรือองค์กร (ไม่เกิน 20 คน)</li>
                                <li>การประกันภัยนี้ไม่คุ้มครองผู้เอาประกันภัยที่จะเดินทางไปต่างประเทศในอีก 3 เดือนนับจากวันที่เริ่มคุ้มครอง</li>
                                <li>การประกันภัยนี้มีระยะเวลารอคอย (waiting period)  14 วันสำหรับกรมธรรม์ปีแรก</li>
                                <li>การประกันภัยนี้คุ้มครองเฉพาะการติดเชื้อไวรัสโคโรนาที่เกิดขึ้นในประเทศไทยเท่านั้น</li>
                                <li>ผู้ขอเอาประกันภัยทำความเข้าใจในรายละเอียดความคุ้มครองและเงื่อนไขก่อนตัดสินใจทำประกัน</li>
                                <li>ผู้เอาประกันต้องกรอกใบคำขอเอาประกันภัยให้ครบถ้วน พร้อมแนบบัตรประชาชน</li>
                            </ul>}
                            {planDetail.PLAN_ID === "AII-COVID-555" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยเท่านั้น และมีอายุ 18-70 ปี</li>
                                <li>สามารถซื้อแผนประกันโควิด ทุกแผนของ บมจ. เอเชียประกันภัย 1950 ได้ 1 คน / 1 กรมธรรม์</li>
                                <li>ต้องไม่เป็นหรือเคยเป็นผู้ติดเชื้อไวรัสโคโรนามาก่อนการทำประกันภัย</li>
                                <li>ผู้เอาประกันภัยต้องมีร่างกายที่แข็งแรงสมบูรณ์ ไม่ติดเชื้อหรือถูกสงสัยว่าติดเชื้อไวรัสโคโรนามาก่อนการทำประกันภัย ไม่พิการ ไม่มีโรคประจำตัวเรื้อรัง ไม่เป็นโรคร้ายแรง ไม่เป็นผู้วิกลจริต และไม่เป็นผู้ป่วยติดเตียง</li>
                                <li>การประกันภัยนี้มีระยะเวลารอคอย (waiting period)  14 วันนับจากวันและเวลาในกรมธรรม์ประกันภัยที่มีผลบังคับเป็นครั้งแรกตามที่ระบุไว้ในตารางกรมธรรม์ประกันภัย</li>
                                <li>ผู้เอาประกันที่เดินทางกลับจากต่างประเทศ สามารถซื้อประกันได้หลังจาก 14 วัน นับจากวันที่ลงตราผ่านพิธีการตรวจคนเข้าเมืองของประเทศไทย</li>
                            </ul>}
                            {planDetail.PLAN_ID === "AII-COVID-333" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้ขอเอาประกันภัยต้องมีสัญชาติไทยเท่านั้น และมีอายุ 1-99 ปี</li>
                                <li>สามารถซื้อแผนประกันโควิด ทุกแผนของ บมจ. เอเชียประกันภัย 1950 ได้ 1 คน / 1 กรมธรรม์</li>
                                <li>ต้องไม่เป็นหรือเคยเป็นผู้ติดเชื้อไวรัสโคโรนามาก่อนการทำประกันภัย</li>
                                <li>ผู้เอาประกันภัยต้องมีร่างกายที่แข็งแรงสมบูรณ์ ไม่ติดเชื้อหรือถูกสงสัยว่าติดเชื้อไวรัสโคโรนามาก่อนการทำประกันภัย (ถูกสงสัยว่าติดเชื้อ หมายถึง การที่ผู้เอาประกันภัยได้รับการตรวจสอบ และ/หรือ ตรวจสุขภาพมาแล้วโดยแพทย์และถูกกักตัวไว้เพื่อดูอาการ และ/หรือเพื่อการรักษาให้หายจากการเจ็บป่วยในเวลาต่อมา)</li>
                                <li>การประกันภัยนี้มีระยะเวลารอคอย (waiting period)  14 วันนับจากวันและเวลาในกรมธรรม์ประกันภัยที่มีผลบังคับเป็นครั้งแรกตามที่ระบุไว้ในตารางกรมธรรม์ประกันภัย</li>
                                <li>ผู้เอาประกันที่เดินทางกลับจากต่างประเทศ สามารถซื้อประกันได้หลังจาก 14 วัน นับจากวันที่ลงตราผ่านพิธีการตรวจคนเข้าเมืองของประเทศไทย</li>
                            </ul>}
                            {planDetail.PRODUCT_GROUP === "4-09" && <p>*ภาวะโคม่า หมายถึง การสลบหรือหมดความรู้สึกที่ได้รับวินิจฉัยโดยอายุรแพทย์หรือประสาทศัลยแพทย์ และตรวจพบลักษณะต่อไปนี้ทุกข้อ</p>}
                            {planDetail.PRODUCT_GROUP === "4-09" && <ul>
                                <li>ต้องอาศัยเครื่องช่วยชีวิต</li>
                                <li>ไม่มีการตอบสนองต่อสิ่งเร้าภายนอกอย่างน้อย 96 ชั่วโมง</li>
                                <li>สมองถูกทำลายอย่างถาวร ไม่สามารถปฏิบัติกิจวัตรประจำภายหลัง 30 วันจากวันที่สลบหรือหมดความรู้สึก</li>
                            </ul>}
                            {planDetail.PRODUCT_GROUP === "4-09" && <p>หมายเหตุ:</p>}
                            {planDetail.PRODUCT_GROUP === "4-09" && <ul className="text-dark mb-0 faq-ans">
                                <li>ระยะเวลาคุ้มครอง 1 ปี</li>
                                <li>ผู้เอาประกันจะต้องมีสัญชาติไทยเท่านั้น และมีอายุระหว่าง 1 ถึง 99 ปีบริบูรณ์</li>
                                <li>ผู้เอาประกันภัยสามารถทำประกันภัย COVID ของบริษัทได้ไม่เกิน คนละ 2 กธ และทุนประกันภัยรวมกันไม่เกิน 3,000,000 บาท</li>
                                <li>ผู้เอาประกันภัยต้องมีร่างกายที่แข็งแรงสมบูรณ์ ไม่ติดเชื้อหรือถูกสงสัยว่าติดเชื้อไวรัสโคโรนามาก่อนทำประกันกัย (ถูกสงสัยว่าติดเชื้อ หมายถึง การที่ผู้เอาประกันภัยได้รับการตรวจสอบ และ/หรือ ตรวจสุขภาพมาแล้วโดยแพทย์และถูกกักตัวไว้เพื่อดูอาการ และ/หรือ เพื่อรักษาให้หายจากการเจ็บป่วยในเวลาต่อมา)</li>
                                <li>ระยะเวลารอคอย (Waiting Period) 14 วัน นับจากวันที่กรมธรรม์ประกันภัยมีผลบังคับเป็นครั้งแรกตามที่ระบุไว้ในตารางกรมธรรม์ประกันภัย</li>
                                <li>ผู้เอาประกันภัยเดินทางกลับจากต่างประเทศ สามารถซื้อประกันได้หลังจาก 14 วัน นับจากวันที่ลงตราผ่านพิธีการตรวจคนเข้าเมืองของประเทศไทย</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "DHP-450-850" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้เอาประกันภัยอายุระหว่าง 1 ปี – 99 ปี (ปี พ.ศ. ปัจจุบัน ลบด้วยปี พ.ศ. เกิด) ณ วันที่ทำประกันภัย</li>
                                <li>กรมธรรม์ประกันภัยนี้ไม่คุ้มครองผู้ที่ติดเชื้อ หรือเป็นผู้ที่สงสัยว่าจะติดเชื้อ “โรคติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV))” (รวมถึงภาวะแทรกซ้อน) ที่เป็นอยู่ก่อนการเอาประกันภัย และยังมิได้รักษาให้หายขาด</li>
                                <li>*การเจ็บป่วยด้วยภาวะโคม่า หรือ ภาวะสมองตายและระบบประสาทล้มเหลว หรือ การเจ็บป่วยระยะสุดท้ายซึ่งเป็นการเจ็บป่วยรุนแรงที่แพทย์ลงความเห็นว่าไม่มีวิธีการรักษาให้หายได้ และ/หรือ เป็นสาเหตุทำให้เสียชีวิตโดยมีสาเหตุหลักมาจากติดเชื้อไวรัสโคโรนา</li>
                                <li>ผู้เอาประกันภัย 1 คน ต่อ ทุนประกันภัย แบบค่ารักษา ทุนประกันภัยต่อคนไม่เกิน 1,000,000 บาท และ/หรือ ค่ารักษาพยาบาลไม่เกิน 100,000 บาท</li>
                                <li>ไม่คุ้มครองการติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV) ภายในระยะเวลา 14 วัน (Waiting Period) นับจากวันที่กรมธรรม์ประกันภัยมีผลบังคับครั้งแรก</li>
                                <li>ผลประโยชน์และความคุ้มครองเป็นไปตามข้อกำหนดและเงื่อนไขกรมธรรม์ประกันภัยคุ้มครองการติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV))</li>
                                <li>ขณะซื้อกรมธรรม์ประกันภัยส่วนบุคคลคุ้มครองการติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV)) ผู้เอาประกันภัยต้องพำนักอาศัยอยู่ในประเทศไทย (กรณีชาวต่างชาติต้องมี Passport และ Work permit หรือมีการพำนักอยู่ในประเทศไทยมากกว่า 6 เดือนขึ้นไป)</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "DHP-IS" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้เอาประกันภัยอายุระหว่าง 1 ปี – 99 ปี (ปี พ.ศ. ปัจจุบัน ลบด้วยปี พ.ศ. เกิด) ณ วันที่ทำประกันภัย</li>
                                <li>กรมธรรม์ประกันภัยนี้ไม่คุ้มครองผู้ที่ติดเชื้อ หรือเป็นผู้ที่สงสัยว่าจะติดเชื้อ “โรคติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV))” (รวมถึงภาวะแทรกซ้อน) ที่เป็นอยู่ก่อนการเอาประกันภัย และยังมิได้รักษาให้หายขาด</li>
                                <li>*การเจ็บป่วยด้วยภาวะโคม่า หรือ ภาวะสมองตายและระบบประสาทล้มเหลว หรือ การเจ็บป่วยระยะสุดท้ายซึ่งเป็นการเจ็บป่วยรุนแรงที่แพทย์ลงความเห็นว่าไม่มีวิธีการรักษาให้หายได้ และ/หรือ เป็นสาเหตุทำให้เสียชีวิตโดยมีสาเหตุหลักมาจากติดเชื้อไวรัสโคโรนา</li>
                                <li>แผน IS ผู้เอาประกันภัยภาคสมัครใจ 1 คน สามารถซื้อแผนนี้ได้สูงสุด 1 กรมธรรม์</li>
                                <li>ไม่คุ้มครองการติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV) ภายในระยะเวลา 14 วัน (Waiting Period) นับจากวันที่กรมธรรม์ประกันภัยมีผลบังคับครั้งแรก</li>
                                <li>ผลประโยชน์และความคุ้มครองเป็นไปตามข้อกำหนดและเงื่อนไขกรมธรรม์ประกันภัยคุ้มครองการติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV))</li>
                                <li>ขณะซื้อกรมธรรม์ประกันภัยส่วนบุคคลคุ้มครองการติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV)) ผู้เอาประกันภัยต้องพำนักอาศัยอยู่ในประเทศไทย (กรณีชาวต่างชาติต้องมี Passport และ Work permit หรือมีการพำนักอยู่ในประเทศไทยมากกว่า 6 เดือนขึ้นไป)</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "DHP-VAC" && <ul className="text-dark mb-0 faq-ans">
                                <li>ผู้เอาประกันภัยอายุระหว่าง 1 ปี - 99 ปี (ปี พ.ศ. ปัจจุบัน ลบด้วยปี พ.ศ. เกิด) ณ วันที่ทำประกันภัย</li>
                                <li>กรมธรรม์ประกันภัยนี้ให้การคุ้มครองภาวะแทรกซ้อน หรือผลข้างเคียงจากการฉีดวัคซีนเพื่อป้องกันโรคติดเชื้อไวรัส โคโรนา 2019 (COVID-19) เป็นระยะเวลา 90 วันนับจากวันที่ได้รับการฉีดวัคซีน โดยต้องเป็นวัคซีนที่รับการ รับรอง หรือการอนุมัติจากสำนักงานคณะกรรมการอาหารและยา (Food and Drug Administration : FDA) และทำหัตถการโดยแพทย์ หรือพยาบาล ที่โรงพยาบาลหรือสถานพยาบาลเวชกรรม</li>
                                <li>ผู้เอาประกันภัย 1 คน ต่อ 1 กรมธรรม์</li>
                                <li>การเจ็บป่วยด้วยภาวะโคม่า (Coma) อันเนื่องมาจากภาวะแทรกซ้อน หรือผลข้างเคียง จากการฉีดวัคซีนเพื่อป้องกัน โรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19) ที่แพทย์ลงความเห็นว่าไม่มีวิธีการรักษาให้หายได้ และ/หรือ เป็นสาเหตุทำให้เสียชีวิตโดยมีสาเหตุหลักมาการฉีดวัคซีน</li>
                                <li>ไม่คุ้มครองภาวะแทรกซ้อนหรือผลข้างเคียงจากการฉีดวัคซีนเพื่อป้องกันโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19) ก่อนวันที่กรมธรรม์ประกันภัยนี้เริ่มมีผลบังคับเป็นครั้งแรก</li>
                                <li> ผลประโยชน์และความคุ้มครองเป็นไปตามข้อกำหนดและเงื่อนไขกรมธรรม์ประกันภัยคุ้มครองการติดเชื้อไวรัสโคโรนา (Coronavirus (2019-nCoV)) ส่วนบุคคล</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "VIB-PROTECT" && <ul className="text-dark mb-0 faq-ans">
                                <li className="text-danger">ผู้เอาประกันภัยต้องมีสุขภาพร่างกายสมบูรณ์แข็งแรง ไม่มีส่วนใดส่วนหนึ่งพิการหรือทุพพลภาพ และไม่มีโรคประจำตัวเรื้อรัง โรคร้ายแรง ไม่เป็นผู้วิกลจริต ไม่มีอาการหรือโรคที่เกี่ยวเนื่องกับภาวะทางจิต โรคพิษสุราเรื้อรัง และไม่เป็นผู้ติดสารเสพติด ( โรคประจำตัวเรื้อรัง โรคร้ายแรง เช่น เบาหวาน หัวใจ ไตเรื้อรัง ปอดอุดกั้นเรื้อรัง โรคตับ ภูมิคุ้มกันบกพร่อง มะเร็ง เป็นต้น)</li>
                                {/* <li>สงวนสิทธิ์คนพิการไม่สามารถซื้อประกันโควิดได้</li> */}
                                <li className="text-success">รับประกันผู้พิการ (คนพิการสามารถซื้อประกันโควิดได้)</li>
                                <li>ไม่รับประกันบุคคลากรทางการแพทย์ และพนักงานท่าอากาศยาน</li>
                                <li>ไม่คุ้มครองการเจ็บป่วยโดยโรคที่มีสาเหตุหรือภาวะแทรกช้อนที่เกิดจากการติดเชื้อไวรัสโคโรนา (โควิด-19) ที่เกิดขึ้นในระยะเวลารอคอย 14 วันแรก นับจากวันที่กรมธรรม์เริ่มต้นความคุ้มครอง</li>
                                <li>ไม่คุ้มครองการเจ็บป่วยโดยโรคที่มีสาหตุหรือภาวะแทรกช้อนที่เกิดจากการติดเชื้อไวรัสโคโรนา (โควิด-19) ที่เกิดขึ้นก่อนวันที่กรมธรรม์เริ่มมีผลบังคับ (Pre-Existing)</li>
                                <li>ข้อตกลงคุ้มครองกรณีการเสียชีวิตจากอุบัติหตุจะไม่มีระยะเวลารอคอย</li>
                                <li>สำหรับการเรียกร้องตามข้อตกลงความคุ้มครองที่ท่านเลือกซื้อ ท่านสามารถนำส่งเอกสารเรียกร้องค่าสินไหมกับทางบริษัทเพื่อพิจารณาความคุ้มครองตามเงื่อนไขกรมธรรม์</li>
                                <li>ผู้ซื้อควรทำความเข้าใจในรายละเอียดความคุ้มครอง และเงื่อนไขก่อนตัดสินใจทำประกันทุกครั้ง</li>
                                <hr />
                                <li>ช่วงอายุรับประกันภัยตั้งแต่ 15 วัน – 75 ปี</li>
                                <li>ผู้เอาประกันภัยต้องไม่มีการเดินทางเข้าหรือออกนอกราชอาณาจักรไทยเป็นระยะเวลา 14 วัน ก่อนวันที่ซื้อกรมธรรม์</li>
                                <li>สงวนสิทธิ์รับประกันภัยเฉพาะผู้ที่มีสัญชาติไทยและต้องพำนักอาศัยอยู่ในประเทศไทยเท่านั้น</li>
                                <li>สงวนสิทธิ์ไม่รับประกันภัยสำหรับผู้ที่มี หรือเคยมี หรือเคยได้รับการวินิจฉัยว่ามีการเจ็บป่วยด้วยโรคติดเชื้อไวรัสโคโรนา 2019 (โควิด-19) (รวมถึงภาวะแทรกช้อน) ที่เกิดขึ้นก่อนการเอาประกันภัย</li>
                                <li>แผนประกันโควิด โปรเทค (Covid Protect) สงวนสิทธิ์การซื้อ 1 ท่าน ต่อ 1 กรมธรรม์ โดยสามารถซื้อร่วมกับแผนประกันโควิด ชีลด์ ได้</li>
                                <li>กรณีตรวจพบการทำประกันภัยไม่ตรงตามเงื่อนไข บริษัทฯ ขอสงวนสิทธิ์ในการยกเลิกกรมธรรมโดยมีการคืนเบี้ยประกันภัยเต็มจำนวน</li>
                                <li>ได้รับกรมธรรม์ทางอีเมลของผู้เอาประกันที่ได้ระบุไว้ ภายใน 30 วันทำการ</li>
                                <li>ระยะเวลารอคอย 14 วัน</li>
                                <li>มอบความคุ้มครองเพิ่มเติม 200,000 บาท สำหรับการแพ้วัคซีน</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "VIB-PROTECT-PLUS" && <ul className="text-dark mb-0 faq-ans">
                                <li className="text-danger">ผู้เอาประกันภัยต้องมีสุขภาพร่างกายสมบูรณ์แข็งแรง ไม่มีส่วนใดส่วนหนึ่งพิการหรือทุพพลภาพ และไม่มีโรคประจำตัวเรื้อรัง โรคร้ายแรง ไม่เป็นผู้วิกลจริต ไม่มีอาการหรือโรคที่เกี่ยวเนื่องกับภาวะทางจิต โรคพิษสุราเรื้อรัง และไม่เป็นผู้ติดสารเสพติด ( โรคประจำตัวเรื้อรัง โรคร้ายแรง เช่น เบาหวาน หัวใจ ไตเรื้อรัง ปอดอุดกั้นเรื้อรัง โรคตับ ภูมิคุ้มกันบกพร่อง มะเร็ง เป็นต้น)</li>
                                {/* <li>สงวนสิทธิ์คนพิการไม่สามารถซื้อประกันโควิดได้</li> */}
                                <li className="text-success">รับประกันผู้พิการ (คนพิการสามารถซื้อประกันโควิดได้)</li>
                                <li>ไม่รับประกันบุคคลากรทางการแพทย์ และพนักงานท่าอากาศยาน</li>
                                <li>ไม่คุ้มครองการเจ็บป่วยโดยโรคที่มีสาเหตุหรือภาวะแทรกช้อนที่เกิดจากการติดเชื้อไวรัสโคโรนา (โควิด-19) ที่เกิดขึ้นในระยะเวลารอคอย 14 วันแรก นับจากวันที่กรมธรรม์เริ่มต้นความคุ้มครอง</li>
                                <li>ไม่คุ้มครองการเจ็บป่วยโดยโรคที่มีสาหตุหรือภาวะแทรกช้อนที่เกิดจากการติดเชื้อไวรัสโคโรนา (โควิด-19) ที่เกิดขึ้นก่อนวันที่กรมธรรม์เริ่มมีผลบังคับ (Pre-Existing)</li>
                                <li>ข้อตกลงคุ้มครองกรณีการเสียชีวิตจากอุบัติหตุจะไม่มีระยะเวลารอคอย</li>
                                <li>สำหรับการเรียกร้องตามข้อตกลงความคุ้มครองที่ท่านเลือกซื้อ ท่านสามารถนำส่งเอกสารเรียกร้องค่าสินไหมกับทางบริษัทเพื่อพิจารณาความคุ้มครองตามเงื่อนไขกรมธรรม์</li>
                                <li>ผู้ซื้อควรทำความเข้าใจในรายละเอียดความคุ้มครอง และเงื่อนไขก่อนตัดสินใจทำประกันทุกครั้ง</li>
                                <hr />
                                <li>ช่วงอายุรับประกันภัยตั้งแต่ 15 วัน – 75 ปี</li>
                                <li>ผู้เอาประกันภัยต้องไม่มีการเดินทางเข้าหรือออกนอกราชอาณาจักรไทยเป็นระยะเวลา 14 วัน ก่อนวันที่ซื้อกรมธรรม์</li>
                                <li>สงวนสิทธิ์รับประกันภัยเฉพาะผู้ที่มีสัญชาติไทยและต้องพำนักอาศัยอยู่ในประเทศไทยเท่านั้น</li>
                                <li>สงวนสิทธิ์ไม่รับประกันภัยสำหรับผู้ที่มี หรือเคยมี หรือเคยได้รับการวินิจฉัยว่ามีการเจ็บป่วยด้วยโรคติดเชื้อไวรัสโคโรนา 2019 (โควิด-19) (รวมถึงภาวะแทรกช้อน) ที่เกิดขึ้นก่อนการเอาประกันภัย</li>
                                <li>แผนประกันโควิด โปรเทค พลัส (Covid Protect Plus) สงวนสิทธิ์การซื้อ 1 ท่าน ต่อ 1 กรมธรรม์ โดยสามารถซื้อร่วมกับแผนประกันโควิด ชีลด์ , โควิด โปรเทค ได้</li>
                                <li>กรณีตรวจพบการทำประกันภัยไม่ตรงตามเงื่อนไข บริษัทฯ ขอสงวนสิทธิ์ในการยกเลิกกรมธรรมโดยมีการคืนเบี้ยประกันภัยเต็มจำนวน</li>
                                <li>ได้รับกรมธรรม์ทางอีเมลของผู้เอาประกันที่ได้ระบุไว้ ภายใน 30 วันทำการ</li>
                                <li>ระยะเวลารอคอย 14 วัน</li>
                            </ul>}
                            {planDetail.PRODUCT_PACKAGE === "SUPER-SHIELD" && <ul className="text-dark mb-0 faq-ans">
                                {/* <li>ผู้เอาประกันภัยต้องมีสุขภาพร่างกายสมบูรณ์แข็งแรง ไม่มีส่วนใดส่วนหนึ่งพิการหรือทุพพลภาพ และไม่มีโรคประจำตัวเรื้อรัง โรคร้ายแรง ไม่เป็นผู้วิกลจริต ไม่มีอาการหรือโรคที่เกี่ยวเนื่องกับภาวะทางจิต โรคพิษสุราเรื้อรัง และไม่เป็นผู้ติดสารเสพติด ( โรคประจำตัวเรื้อรัง โรคร้ายแรง เช่น เบาหวาน หัวใจ ไตเรื้อรัง ปอดอุดกั้นเรื้อรัง โรคตับ ภูมิคุ้มกันบกพร่อง มะเร็ง เป็นต้น)</li> */}
                                <li className="text-success">ไม่จำกัดโรคประจำตัวใดๆ</li>
                                <li className="text-success">รับประกันผู้พิการ (คนพิการสามารถซื้อประกันโควิดได้)</li>
                                {/* <li>สงวนสิทธิ์คนพิการไม่สามารถซื้อประกัน    โควิดได้</li> */}
                                <li className="text-danger">ไม่รับประกันภัย กลุ่มอาชีพพิเศษ ดังนี้ บุคคลากรทางการแพทย์, แพทย์, พยาบาล เจ้าหน้าที่หรือบุคคลที่ปฎิบัติงานในสถานพยาบาล</li>
                                <li>จำกัดการซื้อ 1 คนต่อ 1 กรมธรรม์ ขอสงวนสิทธิ์สำหรับผู้เอาประกันภัยที่ยังไม่เคยมีประกันภัยไวรัสโคโรนา 2019 (COVID-19) กับบริษัท วิริยะประกันภัย จำกัด (มหาชน) เท่านั้น</li>
                                <li>ระยะเวลารอคอย 14 วัน นับจากวันที่กรมธรรม์เริ่มมีผลบังคับเป็นครั้งแรก</li>
                                <li>สภาพที่เป็นมาก่อนการเอาประกันภัยจะไม่ได้รับความคุ้มครอง</li>
                                <li>ผู้ซื้อควรทำความเข้าใจในรายละเอียดความคุ้มครอง และเงื่อนไขก่อนตัดสินใจทำประกันทุกครั้ง</li>
                                <hr />
                                <li>ช่วงอายุรับประกันภัยตั้งแต่ 15 วัน – 99 ปี</li>
                                <li>ผู้เอาประกันต้องอาศัยอยู่ในประเทศไทยติดต่อกันเป็นเวลาอย่างน้อย 30 วันก่อนวันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ</li>
                                <li>สงวนสิทธิ์รับประกันภัยเฉพาะผู้ที่มีสัญชาติไทยและต้องพำนักอาศัยอยู่ในประเทศไทยเท่านั้น</li>
                                <li>ไม่รับประกันภัย กลุ่มอาชีพพิเศษ ดังนี้ บุคคลากรทางการแพทย์ (แพทย์, พยาบาล หรือเจ้าหน้าที่ที่ปฏิบัติงานในสถานพยาบาล) และพนักงานบนเรือทุกประเภท</li>
                                {/* <li>ผู้เอาประกันภัยต้องมีสุขภาพร่างกายสมบูรณ์แข็งแรง ไม่มีส่วนใดส่วนหนึ่งพิการหรือทุพพลภาพ และไม่มีโรคประจำตัวเรื้อรัง โรคร้ายแรง ไม่เป็นผู้วิกลจริต ไม่มีอาการหรือโรคที่เกี่ยวเนื่องกับภาวะทางจิต โรคพิษสุราเรื้อรัง และไม่เป็นผู้ติดสารเสพติด</li> */}
                                <li>ผู้เอาประกันภัยสามารถซื้อแผนประกันภัยโควิด-19 ซูเปอร์ ชีลด์ ได้ 1 คนต่อกรมธรรม์ เท่านั้น และต้องไม่เคยมีแผนประกันไวรัสโคโรนา 2019 กับ บริษัท วิริยะประกันภัย จำกัด (มหาชน)</li>
                                <li>บริษัทฯ ขอสงวนสิทธิ์ยกเลิกกรมธรรม์พร้อมคืนเบี้ยประกันภัยเต็มจำนวน กรณีที่ไม่เข้าเงื่อนไขการรับประกันภัย</li>
                                <li>ได้รับกรมธรรม์ทางอีเมลของผู้เอาประกันที่ได้ระบุไว้ ภายใน 30 วันทำการ</li>
                                {planDetail.PLAN_ID === "VIB-SUPER-SHIELD-399" && <li>เพิ่มความคุ้มครองการเจ็บป่วยระยะสุดท้ายจากการแพ้วัคซีนโควิด 19 สูงสุด 200,000 บาท</li>}
                                {planDetail.PLAN_ID === "VIB-SUPER-SHIELD-599" && <li>เพิ่มความคุ้มครองการเจ็บป่วยระยะสุดท้ายจากการแพ้วัคซีนโควิด 19 สูงสุด 300,000 บาท</li>}
                            </ul>}
                        </CardBody>
                    </Collapse>
                </Card>
                <Card className="border-0 rounded mb-2">
                    <Link
                        to="#"
                        onClick={t_col2}
                        className={
                            allCol.col2
                                ? "faq position-relative text-primary"
                                : "faq position-relative text-dark"
                        }
                    >
                        <CardHeader
                            className="border-0 bg-light p-3 pr-5"
                            id="headingTwo"
                        >
                            <h6 className="title mb-0">
                                เงื่อนไขการส่งใบคำขอประกันภัยผ่าน TVinsure (บริษัท ทวีทรัพย์ โบรคเกอร์ จำกัด)
                                <i
                                    className={
                                        allCol.col2
                                            ? "mdi mdi-chevron-up float-right"
                                            : "mdi mdi-chevron-down float-right"
                                    }
                                ></i>
                            </h6>
                        </CardHeader>
                    </Link>
                    <Collapse isOpen={allCol.col2}>
                        <CardBody className="px-2 py-4">
                            <ul className="text-dark mb-0 faq-ans">
                                <li>การซื้อผ่านระบบจะเสร็จสมบูรณ์ต่อเมื่อการชำระเงินเสร็จสิ้น ท่านจะได้รับเลขคำขอประกันภัยตอบกลับ และไฟล์กรมธรรม์ประกันภัยจะจัดส่งให้กับท่านทางอีเมลที่ลงทะเบียนไว้ โดยรายละเอียดความคุ้มครองและเงื่อนไขความคุ้มครอง จะถูกระบุไว้ตามเอกสารกรมธรรม์</li>
                                {planDetail.PLAN_ISSUER_CODE !== "SEI" && <li>วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {covStartDate} - {covEndDate} การสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</li>}
                                {planDetail.PLAN_ISSUER_CODE === "SEI" && <li>วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {addDaysOnly(new Date(), 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })} - {addDaysAndOneYear(new Date(), 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })} การสั่งซื้อประกัน COVID-19 กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์ภายหลังชำระเงินเสร็จสิ้นเท่านั้น โดยการสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</li>}
                                <li>กรมธรรม์ประกันภัยมีระยะเวลารอคอย 14 วัน (นับตั้งแต่วันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ) และความคุ้มครองต้องผ่านการพิจารณาจากบริษัทประกันภัย  และ ตามเงื่อนไขการรับประกันของประกันภัยนั้นๆ</li>
                                <li>TVinsure - บริษัท ทวีทรัพย์ โบรคเกอร์ จำกัด ("บริษัทฯ") เป็นเพียงนายหน้าผู้ชี้ช่องให้ทำประกันภัยเท่านั้น การรับประกันภัยเป็นไปตามเงื่อนไขและหลักเกณฑ์ของบริษัทประกันภัย ผู้ซื้อควรทำความเข้าใจรายละเอียดความคุ้มครองและเงื่อนไขก่อนตัดสินใจทำประกันภัยทุกครั้ง</li>
                                <li>บริษัทฯ ขอสงวนสิทธิ์ยกเลิกกรมธรรม์พร้อมคืนเบี้ยประกันภัยเต็มจำนวน กรณีไม่เข้าเงื่อนไขการรับประกันภัย</li>
                                <li>โปรดศึกษาความคุ้มครองก่อนตัดสินใจทำประกัน บริษัทประกันจะเป็นผู้พิจารณารับประกันภัย</li>
                                <li>{planDetail.PLAN_ISSUER} เป็นผู้รับประกันภัย บริษัทรับประกันภัยอาจมีการสอบถามข้อมูลเพิ่มเติม เพื่อประกอบการพิจารณารับประกันภัย</li>
                            </ul>
                        </CardBody>
                    </Collapse>
                </Card>

            </div>
        </div>
    );
}


export default CovidApplicationNew;
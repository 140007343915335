import React, { useContext } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';


const Menubar = ({ page }) => {
    const auth = useContext(AuthContext);

    return (
        <Card className="mb-4 shadow">
            <CardBody>
                {/* <div className="text-center">
                    <span>
                        <img src={tvinsurelogo} alt="TVinsure" height="60" />
                    </span>
                </div>
                <h3 className="text-center mt-2">ระบบบริหารงานประกันภัย</h3> */}
                {auth.isLoggedIn && <h5 className="text-center mt-2 mb-4">บัญชีผู้ใช้งาน: {auth.email}{auth.isLoggedIn && 
                     <span className="btn btn-outline-danger btn-sm ml-2" onClick={() => {
                        const check = window.confirm("ยืนยันการออกจากระบบ")
                        if (check === true) {
                            auth.logout();
                        } else {
                            return false
                        }
                    }} outline block color="danger"> ออกจากระบบ</span>
                }</h5>}
                <Row className="mb-4">
                    <Col md={4} sm={6} className="text-center mt-2">
                        <Link to="/list-policy-init-gb"> <Button block color="primary" outline={page !== "list-policy-init-gb" && true}>สมัครใหม่ชำระไม่สำเร็จ (GB)</Button></Link>
                    </Col>
                    <Col md={4} sm={6} className="text-center mt-2">
                        <Link to="/list-policy-paid-gb"> <Button block color="primary" outline={page !== "list-policy-paid-gb" && true}>สมัครใหม่ชำระแล้ว (GB)</Button></Link>
                    </Col>
                    <Col md={4} sm={6} className="text-center mt-2">
                        <Link to="/list-policy-new"> <Button block color="primary" outline={page !== "list-policy-new" && true}>สมัครใหม่ (Pay Slip)</Button></Link>
                    </Col>
                    <Col md={4} sm={6} className="text-center mt-2 text-dark ">
                        <Link to="/list-policy-renew"><Button block color="primary" outline={page !== "list-policy-renew" && true}>ต่ออายุ (Pay Slip)</Button></Link>
                    </Col>
                    <Col md={4} sm={6} className="text-center mt-2">
                        <Link to="/list-policy-all"> <Button block color="primary" outline={page !== "list-policy-all" && true}>รายการทั้งหมด</Button></Link>
                    </Col>
                    <Col md={4} sm={6} className="text-center mt-2">
                        <Link to="/endorsement-list"> <Button block color="primary" outline={page !== "endorsement-list" && true}>รายการสลักหลัง</Button></Link>
                    </Col>
                    <Col md={4} sm={6} className="text-center mt-2">
                        <Link to="/aff-fire-pack-list"> <Button block color="primary" outline={page !== "aff-fire-pack-list" && true}>คำขอประกันอัคคีภัย</Button></Link>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}


export default Menubar;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Images
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import tvinsurePattern from "../images/newbrands/tvinsure-pattern-05.png";
import tvinsurepayment from '../../shared/images/Full-Payment.png';
import tvinsurepcidss from '../../shared/images/pci-dss.png';
import tvinsurepromptpay from '../../shared/images/promptpay.png';



class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grid1: [
        { title: "ประกันรถยนต์", link: "/aff-motor-package/vib/quote/typeall?carUse=110" },
        { title: "ประกันรถยนต์ ชั้น 2+", link: "/aff-motor-package/vib/quote/typetwoplus?carUse=110" },
        { title: "ประกันรถยนต์ ชั้น 3+", link: "/aff-motor-package/vib/quote/typethreeplus?carUse=110" },
        { title: "ประกันรถยนต์ ชั้น 3", link: "/aff-motor-package/vib/quote/typethree?carUse=110" },
      ],
      grid2: [
        { title: "ประกันสุขภาพ", link: "/aff-health-package/all/quote" },
        { title: "ประกันอุบัติเหตุ", link: "/aff-pa-package/all/quote" },
        // { title: "ประกันโควิด", link: "/aff-covid-package/all/quote" }
      ],
      grid3: [
        { title: "ประกันบ้านที่อยู่อาศัย", link: "/aff-health-package/all/quote" },
        { title: "ประกันธุรกิจ SME", link: "/aff-health-package/all/quote" }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <footer className={this.props.isLight ? "footer bg-light" : "footer"}>
          <Container>
            <Row>
              <Col
                lg="5"
                xs="12"
                className="mb-0 mb-md-0 pb-0 pb-md-2"
                name="footercolumn"
              >
                <Link to="/" className="logo-footer">
                  <img
                    src={this.props.isLight ? tvinsurelogo : tvinsurelogo}
                    height="80"
                    alt=""
                  />
                </Link>
                <h6 className="px-4 mt-2 mb-0 text-dark">
                  Protection Made Just For You
                </h6>
                <p className="px-4 mt-2 mb-4 text-dark">
                  แฟลตฟอร์มประกันภัยยุคใหม่
                </p>
                <p className="px-4 mb-0 text-dark">
                  บริษัท ทวีทรัพย์โบรคเกอร์ จำกัด
                </p>
                <p className="px-4 mb-0 text-dark">
                  <small> 204/11 ถนนสุรวงศ์ แขวงสี่พระยา เขตบางรัก กรุงเทพมหานคร 10500</small>
                </p>
                <p className="px-4 mb-0 text-dark">
                  <small>ใบอนุญาตินายหน้าประกันวินาศภัยเลขที่ ว00288/2534</small>
                </p>
                <ul className={this.props.isLight ? "list-unstyled social-icon social mb-0 m t-4 px-4" : "list-unstyled social-icon foot-social-icon mb-0 mt-4 px-4"}>
                  <li className="list-inline-item me-1 mr-2">
                    <a href="https://www.facebook.com/tvinsure" target="_blank" className="rounded">
                      <FeatherIcon
                        icon="facebook"
                        className="fea icon-sm fea-social"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item me-1">
                    <a href="https://www.linkedin.com/company/tvinsure" target="_blank" className="rounded">
                      <FeatherIcon
                        icon="linkedin"
                        className="fea icon-sm fea-social"
                      />
                    </a>
                  </li>
                </ul>
              </Col>

              <Col
                lg="2"
                md="4"
                xs="12"
                className="mt-4 mt-sm-4 pt-2 pt-sm-0 px-3"
                name="footercolumn"
              >
                <h6
                  className="text-dark"
                >
                  ประกันรถยนต์
                </h6>
                <ul className="list-unstyled footer-list mt-3">
                  {this.state.grid1.map((grid, key) => (
                    <li key={key}>
                      <Link
                        to={grid.link}
                        className={
                          this.props.isLight ? "text-dark" : "text-dark"
                        }
                      >
                        <i className="mdi mdi-chevron-right me-1"></i>{" "}
                        {grid.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>

              <Col
                lg="2"
                md="4"
                xs="12"
                className="mt-4 mt-sm-4 pt-2 pt-sm-0 px-3"
                name="footercolumn"
              >
                <h6
                  className="text-dark"
                >
                  ประกันภัยส่วนบุคคล
                </h6>
                <ul className="list-unstyled footer-list mt-4">
                  {this.state.grid2.map((grid, key) => (
                    <li key={key}>
                      <Link
                        to={grid.link}
                        className={
                          this.props.isLight ? "text-dark" : "text-dark"
                        }
                      >
                        <i className="mdi mdi-chevron-right me-1"></i>
                        {grid.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>

              <Col
                lg="3"
                md="4"
                xs="12"
                className="mt-4 mt-sm-4 pt-2 pt-sm-0 px-3"
                name="footercolumn"
              >
                <h6
                  className="text-dark"
                >
                  ประกันภัยทรัพย์สินและธุรกิจ
                </h6>
                <ul className="list-unstyled footer-list mt-4">
                  {this.state.grid3.map((grid, key) => (
                    <li key={key}>
                      <Link
                        to={grid.link}
                        className={
                          this.props.isLight ? "text-dark" : "text-dark"
                        }
                      >
                        <i className="mdi mdi-chevron-right me-1"></i>
                        {grid.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>

            </Row>
          </Container>
        </footer>
        <footer className="footer footer-bar">
          <Container className="text-center">
            <Row className="align-items-center">
              <Col sm="6">
                <div className="text-sm-left text-dark">
                  <p className="mb-0">
                    © {new Date().getFullYear()} TVinsure.com developed by{" "}
                    {" "}
                    <a
                      href="https://quriousman.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary font-weight-bold"
                    >
                      Tirkus - Quriousman
                    </a>
                  </p>
                </div>
              </Col>

              <Col sm="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul className="list-unstyled text-sm-right mb-0">
                  <li className="list-inline-item me-1">
                    <Link to="">
                      <img
                        src={tvinsurepayment}
                        height="40"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item ml-1">
                    <Link to="">
                      <img
                        src={tvinsurepromptpay}
                        height="30"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li className="list-inline-item ml-3">
                    <Link to="">
                      <img
                        src={tvinsurepcidss}
                        height="30"
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;

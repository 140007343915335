import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
// import { BrowserRouter as Redirect } from 'react-router-dom';
import { useParams } from "react-router-dom";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { storage, db } from '../../firebase/config'
import { preName, benefitType, day, month, year, CovStartDateZero, CovEndDateZero, HealthplanList, Occupation } from '../../shared/masters/refData'

import { covidApi } from '../../shared/masters/url';


//Import Icons
import FeatherIcon from 'feather-icons-react';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import nkiinsurancelogo from '../../shared/images/NKI_LOGO.png';


import progressbar1 from '../../shared/images/ProgressBar1.png';
import progressbar2 from '../../shared/images/ProgressBar2.png';
import progressbar3 from '../../shared/images/ProgressBar3.png';
import progressbar4 from '../../shared/images/ProgressBar4.png';

import './Product.css';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid2.jpg';

var uniqid = require('uniqid');


const HealthApplicationInfo = () => {

    let history = useHistory();

    const planId = useParams().planid;
    const idCardNumber = useParams().idcardnumber;
    const planDetail = HealthplanList.filter(p => p.PLAN_ID === planId)[0]

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [provinceData, setProvinceData] = useState();
    const [districtData, setDistrictData] = useState();
    const [districtSubData, setDistrictSubData] = useState();
    const [districtSubFilter, setDistrictSubFilter] = useState();
    const [agentData, setAgentData] = useState();
    const [error, setError] = useState(null);

    //contain all form data
    const [formData, setFormData] = useState({
        appid: "HSA" + planDetail.PLAN_ID + uniqid.time().toUpperCase(),
        appType: "NEW",
        appStatus: "finishPersonInfo",
        appStatusValue: 1,
        birthDateDay: "",
        birthDateMonth: "",
        birthDateYear: "",
        prename: "",
        fname: "",
        lname: "",
        gender: "",
        Occupation: "",
        cardType: "nationalidcard",
        idcard: idCardNumber,
        address: "",
        province: "",
        provinceid: "",
        district: "",
        districtid: "",
        districtSub: "",
        districtSubid: "",
        benefitType: "",
        benefitPreName: "",
        benefitFirstName: "",
        benefitLastName: "",
        covStartDate: CovStartDateZero(),
        covEndDate: CovEndDateZero(),
        tel: "",
        email: "",
        currenDate: new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        agentCode: "",
        agentCheck: "uncheck",
        paymentAmount: "",
        paymentDate: "",
        paymentTime: "",
        paymentfilename: "",
        paymentfileurl: "",
        paymentfileurlloadfinish: "unselect"
    });

    const benefitTypeDhp = benefitType.filter(b => b !== "ตนเอง");

    // Get API Province and DistrictSub and Agent
    useEffect(() => {

        db.collection('healthApplication').where('NATIONAL_ID', '==', idCardNumber).where('PLAN_ID', '==', planId).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 1) {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/question`)
                }

                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 2) {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/get-result`)
                }

                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 3) {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/get-policy`)
                }
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            setErrorModal(true);
        })

        const sendProvinceRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/province`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setProvinceData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendProvinceRequest()
        const sendDistrictSubRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/districtSub`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setDistrictSubData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendDistrictSubRequest()
        const sendAgentRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/agent`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setAgentData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Agent API, please try again.');
                setIsLoading(false);
            }
        }
        sendAgentRequest()
    }, []);


    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        if (name === "Occupation" && value === "อื่นๆ") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    Occupation: ""
                }
            });
        }

        if (name === "prename" && value) {
            const gender = preName.filter(n => n.PRE_NAME === value)[0].GENDER
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    prename: value,
                    gender: gender
                }
            });
        }

        if (name === "benefitPreName" && value) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    benefitPreName: value
                }
            });
        }


        //province must have value
        if (name === "province" && value) {
            const provinceCode = provinceData.filter(p => p.PROV_NAME_T === value)[0].PROVINCE_CODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    province: value,
                    provinceid: provinceCode,
                    district: "",
                    districtid: "",
                    districtSub: "",
                    districtSubid: ""
                }
            });
            const sendDistrictRequest = async () => {
                setIsLoading(true);
                try {
                    const response = await axios.post(`${covidApi}/api/masters/district`,
                        JSON.stringify({
                            PROVINCE_CODE: provinceCode
                        }),
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    setDistrictData(response.data)
                    setIsLoading(false);
                } catch (err) {
                    setIsLoading(false);
                    setError(err.message || 'Something went wrong with District API, please try again.');
                }
            }
            sendDistrictRequest()
        }

        //district must have value
        if (name === "district" && value) {
            const districtCode = districtData.filter(d => d.DISTRICT_NAME_T === value)[0].DISTRICT_CODE
            const districtSubFilter = districtSubData.filter(ds => ds.DISTRICT_NAME_T === value)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    district: value,
                    districtid: districtCode,
                    districtSub: "",
                    districtSubid: ""
                }
            });
            setDistrictSubFilter(districtSubFilter)
        }

        //districtSub must have value
        if (name === "districtSub" && value) {
            const districtSubCode = districtSubFilter.filter(ds => ds.DISTRICT_NAME_T_SUB === value)[0].DISTRICT_CODE_SUB
            const zipCode = districtSubData.filter(ds => ds.DISTRICT_CODE_SUB === districtSubCode)[0].ZIPCODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    districtSub: value,
                    districtSubid: districtSubCode,
                    zipcode: zipCode
                }
            });
        }

        if (name === "benefitType" && value === "ตนเอง") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    benefitType: value,
                    benefitPreName: formData.prename,
                    benefitFirstName: formData.fname,
                    benefitLastName: formData.lname
                }
            });
        }

        if (name === "agentCode") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: value,
                    agentCheck: "uncheck"
                }
            });
        }

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    //Handle Check Agent
    const handleCheckAgent = () => {
        const agentFilter = agentData.filter(a => a.AGENT_CODE === formData.agentCode)
        if (agentFilter.length > 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentName: agentFilter[0].AGENT_NAME_T,
                    agentCheck: "success"
                }
            });
        }
        if (agentFilter.length === 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: "",
                    agentName: "กรุณาระบุผู้แจ้งงาน",
                    agentCheck: "fail"
                }
            });
        }
    }


    const handleValidSubmit = (event, values) => {
        event.preventDefault();
        setIsSubmit(true);
        const requestSubmitFormData = () => {
            setIsLoading(true);
            db.collection('healthApplication').add({
                APP_FORM_ID: formData.appid,
                APP_FORM_TYPE: formData.appType,
                APP_FORM_STATUS: formData.appStatus,
                APP_FORM_STATUS_VALUE: formData.appStatusValue,
                PRE_NAME: formData.prename,
                FIRST_NAME: formData.fname,
                LAST_NAME: formData.lname,
                NATIONAL_ID: formData.idcard.replace(/-/g, ""),
                GENDER: formData.gender,
                OCCUPATION: formData.Occupation,
                BIRTH_DATE: `${formData.birthDateDay}/${formData.birthDateMonth}/${formData.birthDateYear}`,
                ADDRESS_DETAIL: formData.address,
                ADDRESS_PROVINCE_ID: formData.provinceid,
                ADDRESS_PROVINCE: formData.province,
                ADDRESS_DISTRICT_ID: formData.districtid,
                ADDRESS_DISTRICT: formData.district,
                ADDRESS_SUB_DISTRICT_ID: formData.districtSubid,
                ADDRESS_SUB_DISTRICT: formData.districtSub,
                ADDRESS_ZIP_CODE: formData.zipcode,
                PHONE_NUM: formData.tel.replace(/-/g, ""),
                EMAIL_ADDRESS: formData.email,
                DATE_COV_START: formData.covStartDate,
                DATE_COV_END: formData.covEndDate,
                BENEFIT_PRE_NAME: formData.benefitPreName,
                BENEFIT_FIRST_NAME: formData.benefitFirstName,
                BENEFIT_LAST_NAME: formData.benefitLastName,
                BENEFIT_TYPE: formData.benefitType,
                PLAN_ID: planDetail.PLAN_ID,
                PLAN_DESC: planDetail.PLAN_DESC + " " + planDetail.PLAN_COV_TITLE,
                PLAN_ISSUER_CODE: planDetail.PLAN_ISSUER_CODE,
                PLAN_ISSUER: planDetail.PLAN_ISSUER,
                PLAN_PRODUCT_TYPE: planDetail.PLAN_PRODUCT_TYPE,
                NET_PREMIUM_AMT: planDetail.PLAN_NET_PREMIUM_AMT,
                STAMP_DUTY_AMT: planDetail.PLAN_STAMP_DUTY_AMT,
                VAT_AMT: planDetail.PLAN_VAT_AMT,
                TOTAL_PREMIUM: planDetail.PLAN_TOTAL_PREMIUM,
                AGENT_CODE: formData.agentCode,
                AGENT_NAME: formData.agentName,
                AGENT_REMARK: formData.agentRemark || "",
                CURRENT_DATE: formData.currenDate,
                PRODUCT_GROUP: planDetail.PRODUCT_GROUP,
                PRODUCT_POLICY: planDetail.PRODUCT_POLICY,
                PRODUCT_PACKAGE: planDetail.PRODUCT_PACKAGE,
                BROKER_CODE: planDetail.BROKER_CODE,
                POLICY_NUM: ""
            })
                .then(() => {
                    history.push(`/application-form-health/${planDetail.PLAN_ID}/${idCardNumber}/question`)
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                    setErrorModal(true);
                });
        }
        requestSubmitFormData()
    }

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        setIsSubmit(false);
        window.location.reload()
    };

    // console.log(formData);
    // console.log(responseData);

    return (
        <React.Fragment>
            {error && !isLoading && (<div className="justify-content-center">
                <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                    <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                    <ModalBody className="pb-2">
                        <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                        <p className="mb-2">{error}</p>
                        <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                        <ul className="mt-1">
                            <li>Line: @tvinsure</li>
                            <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>{' '}
                        {/* <Button color="danger" onClick={toggle}>Cancel</Button> */}
                    </ModalFooter>
                </Modal>
            </div>)}

            <section className="bg-auth bg-color" style={{ background: `url(${tvinsurecovid}) center center`, minHeight: "720px", backgroundSize: "contain", backgroundSize: "" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>

                            <Card>
                                <CardBody>
                                    <div className="text-center mb-3">
                                        <span>
                                            <img src={tvinsurelogo} alt="TVinsure" height="60" />
                                        </span>
                                    </div>
                                    <h4 className="text-center mt-5">แผนประกันจาก{planDetail.PLAN_ISSUER}</h4>
                                    <div className="text-center mb-3">
                                        <span>
                                            {planDetail.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                            {planDetail.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "NKI" && <img src={nkiinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                        </span>
                                    </div>
                                    {/* <p className="text-muted text-center mt-2"> # {planDetail.PLAN_ID}</p> */}
                                    <h4 className="text-center mt-2"> {planDetail.PLAN_DESC}</h4>
                                    <h4 className="text-center mt-2 mb-5"> {planDetail.PLAN_COV_TITLE}</h4>
                                    <div className="text-center mb-3 px-5">
                                        <ProgressBar
                                            percent={0}
                                            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                        >
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="50"
                                                        src={progressbar1}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar2}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar3}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar4}
                                                    />
                                                )}
                                            </Step>
                                        </ProgressBar>
                                    </div>

                                    <AvForm className="login-form mt-4" onValidSubmit={handleValidSubmit}>
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="text-center h2 mt-5 mb-1 ">ขั้นตอนที่ 1</h4>
                                                <p className="text-center mt-1 mb-2">กรอกข้อมูลที่เกี่ยวข้องกับการประกันภัย</p>
                                            </Col>
                                            <Col md={12}>
                                                <h4 className="text-center mt-2 mb-5">ข้อมูลความคุ้มครอง</h4>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>วันเริ่มต้นความคุ้มครอง</Label>
                                                    <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                    <AvField className="form-control pl-5" name="covStartDate" placeholder="วันเริ่มต้นความคุ้มครอง" type="text" onChange={handleChange} value={formData.covStartDate} validate={{ dateRange: { format: 'DD/MM/YYYY', start: { value: "01/01/2564" }, end: { value: "01/01/2565" } } }} title="Use DD/MM/YYYY" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>วันสิ้นสุดความคุ้มครอง</Label>
                                                    <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                    <AvField className="form-control pl-5" name="covEndDate" placeholder="วันสิ้นสุดความคุ้มครอง" type="text" onChange={handleChange} value={formData.covEndDate} validate={{ dateRange: { format: 'DD/MM/YYYY', start: { value: "01/01/2565" }, end: { value: "01/01/2566" } } }} title="Use DD/MM/YYYY" />
                                                </FormGroup>
                                            </Col>

                                            <Col md={12}>
                                                <h4 className="text-center mt-5 mb-5">ข้อมูลผู้เอาประกันภัย</h4>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="position-relative">
                                                    <Label>วันเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกวันเกิดผู้เอาประกัน"
                                                        validate={{ required: { value: true } }} placeholder="วันเกิดผู้เอาประกัน" name="birthDateDay" onChange={handleChange}>
                                                        <option value="" selected>วันเกิดผู้เอาประกัน</option>
                                                        {day && day.map(day => (<option value={day.dayValue}>{day.dayDesc}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="position-relative">
                                                    <Label>เดือนเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกเดือนเกิดผู้เอาประกัน"
                                                        validate={{ required: { value: true } }} placeholder="เดือนเกิดผู้เอาประกัน" name="birthDateMonth" onChange={handleChange}>
                                                        <option value="" selected>เดือนเกิดผู้เอาประกัน</option>
                                                        {month && month.map(month => (<option value={month.monthValue}>{month.monthDesc}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="position-relative">
                                                    <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                        validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" onChange={handleChange}>
                                                        <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                        {year && year.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="position-relative">
                                                    <Label>คำนำหน้าชื่อ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกคำนำหน้าชื่อ"
                                                        validate={{ required: { value: true } }} placeholder="คำนำหน้าชื่อ" name="prename" onChange={handleChange}>
                                                        <option value="" selected>คำนำหน้าชื่อ</option>
                                                        {preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="position-relative">
                                                    <Label>ชื่อ <span className="text-danger">*</span></Label>
                                                    <AvField type="text" className="form-control"
                                                        errorMessage="กรุณากรอกชื่อ"
                                                        validate={{ required: { value: true } }} placeholder="ชื่อ" name="fname" onChange={handleChange} value={formData.fname} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="position-relative">
                                                    <Label>นามสกุล <span className="text-danger">*</span></Label>
                                                    <AvField type="text" className="form-control"
                                                        errorMessage="กรุณากรอกนามสกุล"
                                                        validate={{ required: { value: true } }} placeholder="นามสกุล" name="lname" onChange={handleChange} value={formData.lname} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง"
                                                        validate={{
                                                            // required: { value: true }
                                                            pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' }
                                                        }} placeholder="เลขบัตรประชาชน 13 หลัก" name="idcard" value={formData.idcard} disabled />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>ที่อยู่ <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="map" className="fea icon-sm icons" /></i>
                                                    <AvField type="map" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกที่อยู่"
                                                        validate={{ required: { value: true } }} placeholder="ที่อยู่ เช่น เลขที่บ้าน ชั้น ตึก ถนน ตรอก ซอย" name="address" onChange={handleChange} value={formData.address} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>จังหวัด <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกจังหวัด"
                                                        validate={{ required: { value: true } }} placeholder="จังหวัด" name="province" onChange={handleChange} disabled={!provinceData}>
                                                        <option value="" selected>จังหวัด</option>
                                                        {provinceData && provinceData.map(province => (<option value={province.PROV_NAME_T} >{province.PROV_NAME_T}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>อำเภอ/เขต <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกอำเภอ/เขต"
                                                        validate={{ required: { value: true } }} placeholder="อำเภอ/เขต" name="district" onChange={handleChange} disabled={!districtData && !districtSubData}>
                                                        <option value="" selected>อำเภอ/เขต</option>
                                                        {districtData && districtData.map(district => (<option value={district.DISTRICT_NAME_T}>{district.DISTRICT_NAME_T}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>ตำบล/แขวง <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกตำบล/แขวง"
                                                        validate={{ required: { value: true } }} placeholder="ตำบล/แขวง" name="districtSub" onChange={handleChange} disabled={!districtSubFilter}>
                                                        <option value="" selected>ตำบล/แขวง</option>
                                                        {districtSubFilter && districtSubFilter.map(districtSub => (<option value={districtSub.DISTRICT_NAME_T_SUB}>{districtSub.DISTRICT_NAME_T_SUB}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>รหัสไปรษณีย์</Label>
                                                    <AvField type="text" className="form-control"
                                                        placeholder="รหัสไปรษณีย์" name="zipcode" value={formData.zipcode} disabled>{formData.zipcode}</AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>ผู้รับประโยชน์ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาระบุผู้รับประโยชน์"
                                                        validate={{ required: { value: true } }} placeholder="ผู้รับประโยชน์" name="benefitType" onChange={handleChange}>
                                                        <option value="" selected>ผู้รับประโยชน์</option>
                                                        {planDetail.PLAN_ISSUER_CODE === "DHP" && benefitTypeDhp && benefitTypeDhp.map(bt => (<option value={bt}>{bt}</option>))}
                                                        {planDetail.PLAN_ISSUER_CODE === "SMK" && benefitTypeDhp && benefitTypeDhp.map(bt => (<option value={bt}>{bt}</option>))}
                                                        {planDetail.PLAN_ISSUER_CODE !== "SMK" && planDetail.PLAN_ISSUER_CODE !== "DHP" && benefitType && benefitType.map(bt => (<option value={bt}>{bt}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>คำนำหน้าชื่อผู้รับประโยชน์ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกคำนำหน้าชื่อผู้รับประโยชน์"
                                                        validate={{ required: { value: true } }} placeholder="คำนำหน้าชื่อ" name="benefitPreName" onChange={handleChange} value={formData.benefitPreName}>
                                                        <option value="">คำนำหน้าชื่อผู้รับประโยชน์</option>
                                                        {preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>ชื่อผู้รับประโยชน์ <span className="text-danger">*</span></Label>
                                                    <AvField type="text" className="form-control"
                                                        errorMessage="กรุณากรอกชื่อผู้รับประโยชน์"
                                                        validate={{ required: { value: true } }} placeholder="ชื่อผู้รับประโยชน์" name="benefitFirstName" onChange={handleChange} value={formData.benefitFirstName} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>นามสกุลผู้รับประโยชน์ <span className="text-danger">*</span></Label>
                                                    <AvField type="text" className="form-control"
                                                        errorMessage="กรุณากรอกนามสกุลผู้รับประโยชน์"
                                                        validate={{ required: { value: true } }} placeholder="นามสกุลผู้รับประโยชน์" name="benefitLastName" onChange={handleChange} value={formData.benefitLastName} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>อาชีพ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                    <AvField type="select" className="form-control"
                                                        errorMessage="กรุณาเลือกอาชีพ"
                                                        validate={{ required: { value: true } }} name="Occupation" onChange={handleChange} value={formData.Occupation}>
                                                        <option value="" selected>กรุณาเลือกอาชีพ</option>
                                                        {Occupation && Occupation.map(job => (<option value={job.Occupation}>{job.Occupation}</option>))}
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <h4 className="text-center mt-5 mb-5">ข้อมูลการติดต่อ</h4>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>หมายเลขโทรศัพท์มือถือ <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="smartphone" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง"
                                                        validate={{
                                                            pattern: { value: '^[0][689][0-9]-?[0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9]$' }, required: { value: true }
                                                        }} placeholder="หมายเลขโทรศัพท์มือถือ" name="tel" onChange={handleChange} value={formData.tel} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>อีเมล <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                    <AvField type="email" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกอีเมลที่ถูกต้อง"
                                                        validate={{
                                                            required: { value: true },
                                                            email: { value: true }
                                                        }} placeholder="อีเมล" name="email" onChange={handleChange} value={formData.email} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <h4 className="text-center mt-5 mb-5">ข้อมูลรายละเอียดผู้แจ้งงาน</h4>
                                                <p className="text-center mt-5 mb-5">หากใครไม่ทราบรหัสตัวแทนของตนเอง สามารถสอบถามทีมงาน TVinsure เข้ามาได้เลยผ่านไลน์ @tvinsure หรือเบอร์โทรศัพท์ 0-2266-373-1 ถึง 5</p>
                                            </Col>
                                            <Col lg={2} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>รหัสผู้แจ้งงาน <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกรหัสผู้แจ้งงาน"
                                                        validate={{ required: { value: true } }} placeholder="รหัสผู้แจ้งงาน" name="agentCode" onChange={handleChange} value={formData.agentCode} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                    <Button className="mb-2" color={formData.agentCheck === "success" ? "success" : "danger"}
                                                        outline={formData.agentCheck !== "success" && true} block
                                                        disabled={!formData.agentCode}
                                                        onClick={handleCheckAgent}>{formData.agentCheck === "success" ? "ตรวจสอบแล้ว" : "ตรวจสอบ"}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={5} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label className="text-center">ชื่อผู้แจ้งงาน</Label>
                                                    <i><FeatherIcon icon="headphones" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        placeholder="ชื่อผู้แจ้งงาน" name="agentName" value={formData.agentName} disabled>{formData.agentName}</AvField>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>หมายเหตุผู้แจ้งงาน</Label>
                                                    <AvField type="text" className="form-control"
                                                        placeholder="หมายเหตุผู้แจ้งงาน" name="agentRemark" onChange={handleChange} value={formData.agentRemark} />
                                                </FormGroup>
                                            </Col>
                                            {formData.agentCheck === "success" && <Col md={12}>
                                                <h4 className="text-center mt-2 mb-3">รายละเอียดแผนประกันภัย</h4>
                                                <h4 className="text-center mt-1 mb-3">สำหรับ {formData.prename + " " + formData.fname + " " + formData.lname}</h4>
                                                <h6 className="text-center mt-1 mb-3">แจ้งงานโดย {formData.agentName}</h6>
                                                <h6 className="text-center mb-5">{planDetail.PLAN_DESC + " " + planDetail.PLAN_COV_TITLE}</h6>
                                            </Col>}
                                            <Col lg={12} className="mb-0 auth-button">
                                                {!isSubmit && <Button color="danger" type="submit" block disabled={formData.agentCheck !== "success"}>ดำเนินการต่อไป</Button>}
                                                {isSubmit && <Button color="success" block disabled>ดำเนินการต่อไป</Button>}
                                            </Col>
                                            {/* <Col xs={12} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-dark mr-2 h6 mt-2">กลับไปเลือกแบบประกันภัยจาก</small><Button onClick={() => {
                                                    const check = window.confirm("ยืนยันการย้อนกลับไปเลือกแบบประกันภัยจากบริษัทอื่น")
                                                    if (check === true) {
                                                        window.location.href = "https://info.tvinsure.com/covid-19/";
                                                    } else {
                                                        return false
                                                    }
                                                }} className="font-weight-bold h5 text-danger" outline color="danger">บริษัทประกันภัยอื่น ๆ</Button></p>
                                            </Col> */}
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default HealthApplicationInfo;
// React Basic and Firebase
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { db } from '../../firebase/config'

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import tvinsurelogo from "../../shared/images/newbrands/tvinsure-main-logo.png";
import tvinsureSmeSabaiPackage from '../../shared/images/tvinsure-sme-sabai-package-4.png';


const TicketResult = () => {
    const ticketid = useParams().ticketid;

    const [isLoading, setIsLoading] = useState(false);
    const [ticketData, setDealData] = useState();
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent and get record detail of health application
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        db.collection('ticket').where('ticketId', '==', ticketid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                let dataLoaded = snapshot.docs[0].data()
                setDealData(dataLoaded)
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการดังกล่าวได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setIsLoading(false);
    }, []);

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>ติดต่อแจ้งหัวข้องานบริการ </title>
                    <meta name="description" content="ติดต่อแจ้งหัวข้องานบริการ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="ติดต่อแจ้งหัวข้องานบริการ" />
                    <meta property="og:description" content="ติดต่อแจ้งหัวข้องานบริการ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureSmeSabaiPackage} />
                </MetaTags>
                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section
                    className="bg-home d-flex align-items-center"
                    style={{ backgroundImage: `url(${tvinsureSmeSabaiPackage})` }}
                >
                    <div className="bg-overlay"></div>
                    <Container>
                        {!isLoading && <Row className="justify-content-center">
                            <Col lg={8} md={12} className="text-center">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                                {ticketData && <h4 className="display-4 font-weight-bold text-white title-dark mb-3 mt-4">
                                    ติดต่อแจ้งหัวข้องานบริการ
                                </h4>}
                                {!ticketData && <h4 className="h1 font-weight-bold text-white title-dark mb-3 mt-4">
                                    ไม่พบข้อมูลการส่งหัวข้อการบริการดังกล่าว รบกวนลองใหม่อีกครั้ง
                                </h4>}
                                {ticketData && ticketData.ticketTypeName && <p className="text-center text-white h5 mt-1 ">ติดต่อแจ้งหัวข้องานบริการ {ticketData.ticketTypeName} </p>}
                                {ticketData && ticketData.ticketId && <p className="text-center text-white h6 mt-2">เลขที่การแจ้งงานบริการ {ticketData.ticketId}</p>}
                                {ticketData && ticketData.ticketSubject && <p className="text-center text-white h6 mt-2 mb-3">หัวข้อการแจ้ง {ticketData.ticketSubject}</p>}
                                {ticketData && ticketData.ticketDescription && <p className="text-center text-white h6 mt-2 mb-3">รายละเอียด: {ticketData.ticketDescription} </p>}
                                {ticketData && ticketData.fname && <p className="text-center text-white h6 mt-2 mb-3">ผู้ติดต่อ: {ticketData.fname} {ticketData.lname} {ticketData.tel} {ticketData.email} </p>}
                                {ticketData && ticketData.agentCode && <p className="text-center text-white h6 mt-2 mb-3">ตัวแทน/ผู้ดูแล:  {ticketData.agentCode} {ticketData.agentName} {ticketData.agentRemark}</p>}
                            </Col>
                            {ticketData && ticketData.relatedfileoneurl && <Col md={12} className="text-center mb-2">
                                <a href={ticketData.relatedfileoneurl} target="_blank" ><Button className="btn btn-primary mt-2">
                                    <i className="mdi mdi-download"></i> ไฟล์ที่เกี่ยวข้อง
                                </Button></a>
                            </Col>}
                            {!ticketData && <Col md={12} className="text-center mb-2">
                                <a href="https://www.tvinsure.com" target="_blank" >
                                    <Button className="btn btn-primary mt-2">
                                        <i className="mdi mdi-redo-variant"></i> กลับสู่หน้าหลัก
                                    </Button>
                                </a>
                            </Col>}
                        </Row>}

                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}
export default TicketResult;

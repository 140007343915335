import React from 'react';
import { forwardRef } from 'react';
import { Col } from 'reactstrap';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { dhpHospitalData } from '../../shared/masters/hospitalDhpCovidData'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const CovidClaimDoc = ({ IssuerCode, IssuerDetail }) => {
    return (
        <Col xs={12} className="text-center mt-5 mb-3">
            {IssuerCode === "DHP" && <p className="mt-3 mb-5 text-center h5">ข้อมูลการเรียกร้องค่าสินไหมทดแทน (เคลมประกัน) {IssuerDetail}</p>}
            {IssuerCode === "DHP" && <ul className="text-dark text-left mb-0 faq-ans">
                <li>ขั้นตอนการเรียกร้องค่าสินไหมจากทิพยประกันภัย <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/HOW-TO-CLAIM-DHP.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>เอกสารที่ต้องใช้ในการร้องค่าสินไหมจากทิพยประกันภัย (ภาษาไทย) <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/DHP-CLAIM-DOC-TH.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>เอกสารที่ต้องใช้ในการร้องค่าสินไหมจากทิพยประกันภัย  (ภาษาอังกฤษ) - DHIPAYA CLAIM RELATED DOCUMENTS - ENG <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/DHP-CLAIM-DOC-ENG.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>แบบฟอร์มเรียกร้องค่าสินไหมทดแทน (ภาษาไทย) <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/A01-DHP-CLAIM-FORM-TH.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>แบบฟอร์มเรียกร้องค่าสินไหมทดแทน (ภาษาอังกฤษ) - DHIPAYA CLAIM FORM - ENG <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/A01-DHP-CLAIM-FORM-ENG.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
            </ul>}
            {IssuerCode === "SMK" && <p className="mt-3 mb-5 text-center h5">ข้อมูลการเรียกร้องค่าสินไหมทดแทน (เคลมประกัน) {IssuerDetail}</p>}
            {IssuerCode === "SMK" && <ul className="text-dark text-left mb-0 faq-ans">
                <li> ตั้งแต่วันที่ 9 สิงหาคม 2564 เป็นต้นไป บริษัทสินมั่นคงประกันภัย ขอเรียนให้ท่านทราบว่ามีการเปลี่ยนแปลงวิธีการเรียกร้องค่าสินไหมทดแทน Covid โดยท่านสามารถดูรายละเอียด ได้ตามนี้ <a href="https://www.smk.co.th/upload/FAQ/clm_covid_1101021.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
            </ul>}
            {IssuerCode === "AII" && <p className="mt-3 mb-5 text-center h5">ข้อมูลการเรียกร้องค่าสินไหมทดแทน (เคลมประกัน) {IssuerDetail}</p>}
            {IssuerCode === "AII" && <ul className="text-dark text-left mb-0 faq-ans">
                <li>เอกสารที่ต้องใช้ในการร้องค่าสินไหมจากเอเชียประกันภัย (ภาษาไทย) <a href="https://info.tvinsure.com/wp-content/uploads/2021/07/COVID_ASIA_CLAIM_OVERVIEW.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>แบบฟอร์มเคลมแพ้วัคซีนโควิดเอเชีย <a href="https://info.tvinsure.com/wp-content/uploads/2021/07/แบบฟอร์มเคลมแพ้วัคซีนโควิดเอเชีย.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>แบบฟอร์มเคลมการติดเชื้อโควิดเอเชีย <a href="https://info.tvinsure.com/wp-content/uploads/2021/07/แบบฟอร์มเคลมโควิดเอเชีย.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
            </ul>}
            {IssuerCode === "VIB" && <p className="mt-3 mb-5 text-center h5">ข้อมูลการเรียกร้องค่าสินไหมทดแทน (เคลมประกัน) {IssuerDetail}</p>}
            {IssuerCode === "VIB" && <ul className="text-dark text-left mb-0 faq-ans">
                <li>คำแนะนำและเอกสารที่ต้องเตรียมในการเคลมประกันโคโรนาไวรัสจากวิริยะประกันภัย <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/VIB-Claim-Recommendation.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>บริการการเคลมประกันโคโรนาไวรัสจากวิริยะประกันภัย <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/CLAIM-SERVICE-VIB.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>แบบฟอร์มเรียกร้องค่าสินไหมทดแทน (ภาษาไทย) <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/VIB-CLAIM-FORM-TH.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
            </ul>}
            {IssuerCode === "SEI" && <p className="mt-3 mb-5 text-center h5">ข้อมูลการเรียกร้องค่าสินไหมทดแทน (เคลมประกัน) {IssuerDetail}</p>}
            {IssuerCode === "SEI" && <ul className="text-dark text-left mb-0 faq-ans">
                <li>เอกสารที่ต้องเตรียมในการเคลมประกันโคโรนาไวรัสจากอาคเนย์ประกันภัย <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/SEI-CLAIM-DOC.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
                <li>แบบฟอร์มเรียกร้องค่าสินไหมทดแทน (ภาษาไทย) <a href="https://info.tvinsure.com/wp-content/uploads/2021/05/SEI-CLAIM-FORM.pdf" target="_blank" className="text-danger"> <u> ดาวโหลดที่นี่</u></a></li>
            </ul>}

            {IssuerCode === "DHP" && <div className="mt-5 mb-2">
                <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'ชื่อโรงพยาบาล', field: 'HospitalName' },
                        { title: 'เบอร์โทรศัพท์', field: 'HospitalTel' },
                        { title: 'เว็ปไซต์โรงพยาบาล', field: 'HospitalWebsite' },
                        { title: 'ที่อยู่', field: 'HospitalAddress' },
                        { title: 'ตำบล/แขวง', field: 'HospitalSubDistrict' },
                        { title: 'อำเภอ/เขต', field: 'HospitalDistrict' },
                        { title: 'จังหวัด', field: 'HospitalProvince', defaultGroupOrder: 0 },
                        { title: 'ประเภทโรงพยาบาล', field: 'HospitalType', defaultGroupOrder: 1 },
                    ]}
                    data={dhpHospitalData.filter(dhphos => dhphos.HospitalType !== "คลินิกทันตกรรม")}
                    title={`โรงพยาบาลในเครือของทิพยประกันภัยจำนวน ${dhpHospitalData.filter(dhphos => dhphos.HospitalType !== "คลินิกทันตกรรม").length} โรงพยาบาล`}

                    options={{
                        tableLayout: "auto",
                        exportButton: false,
                        grouping: true,
                        headerStyle: {
                            fontFamily: 'Kanit, sans-serif'
                        },
                        searchFieldStyle: {
                            fontFamily: 'Kanit, sans-serif'
                        },
                        toolbarFieldStyle: {
                            fontFamily: 'Kanit, sans-serif'
                        },
                        paging: true,
                        pageSize: 5,       // make initial page size
                        emptyRowsWhenPaging: false,   //to make page size not fix (dynamic) in case of less data rows
                        pageSizeOptions: [5, 10, 20]    // rows selection options
                    }}

                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} จาก {count}',
                            labelRowsSelect: 'รายการ'
                        },
                        toolbar: {
                            nRowsSelected: '{0} รายการที่เลือก',
                            searchPlaceholder: 'ค้นหา'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: 'ไม่มีรายการ',
                            filterRow: {
                                filterTooltip: 'ตัวกรอง'
                            }
                        },
                        grouping: {
                            placeholder: 'ลากหัวข้อมาที่นี่เพื่อแบ่งกลุ่ม',
                            groupedBy: 'แบ่งกลุ่มโดย: '
                        }
                    }}

                />
            </div>
            }

        </Col>
    );
}


export default CovidClaimDoc;
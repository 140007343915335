const carMake = [
  {
    MAKE_NAME: "TOYOTA",
    label: "TOYOTA",
    value: "TOYOTA"
  },
  {
    MAKE_NAME: "HONDA",
    label: "HONDA",
    value: "HONDA"
  },
  {
    MAKE_NAME: "NISSAN",
    label: "NISSAN",
    value: "NISSAN"
  },
  {
    MAKE_NAME: "MITSUBISHI",
    label: "MITSUBISHI",
    value: "MITSUBISHI"
  },
  {
    MAKE_NAME: "ISUZU",
    label: "ISUZU",
    value: "ISUZU"
  },
  {
    MAKE_NAME: "MAZDA",
    label: "MAZDA",
    value: "MAZDA"
  },
  {
    MAKE_NAME: "BMW",
    label: "BMW",
    value: "BMW"
  },
  {
    MAKE_NAME: "BENZ",
    label: "BENZ",
    value: "BENZ"
  },
  {
    MAKE_NAME: "VOLVO",
    label: "VOLVO",
    value: "VOLVO"
  },
  {
    MAKE_NAME: "PEUGEOT",
    label: "PEUGEOT",
    value: "PEUGEOT"
  },
  {
    MAKE_NAME: "HYUNDAI",
    label: "HYUNDAI",
    value: "HYUNDAI"
  },
  {
    MAKE_NAME: "VOLKSWAGEN",
    label: "VOLKSWAGEN",
    value: "VOLKSWAGEN"
  },
  {
    MAKE_NAME: "FORD",
    label: "FORD",
    value: "FORD"
  },
  {
    MAKE_NAME: "OPEL",
    label: "OPEL",
    value: "OPEL"
  },
  {
    MAKE_NAME: "CITROEN",
    label: "CITROEN",
    value: "CITROEN"
  },
  {
    MAKE_NAME: "DAEWOO",
    label: "DAEWOO",
    value: "DAEWOO"
  },
  {
    MAKE_NAME: "DAIHATSU",
    label: "DAIHATSU",
    value: "DAIHATSU"
  },
  {
    MAKE_NAME: "FIAT",
    label: "FIAT",
    value: "FIAT"
  },
  {
    MAKE_NAME: "SUBARU",
    label: "SUBARU",
    value: "SUBARU"
  },
  {
    MAKE_NAME: "SUZUKI",
    label: "SUZUKI",
    value: "SUZUKI"
  },
  {
    MAKE_NAME: "AUDI",
    label: "AUDI",
    value: "AUDI"
  },
  {
    MAKE_NAME: "SAAB",
    label: "SAAB",
    value: "SAAB"
  },
  {
    MAKE_NAME: "ROVER",
    label: "ROVER",
    value: "ROVER"
  },
  {
    MAKE_NAME: "LAND ROVER",
    label: "LAND ROVER",
    value: "LAND ROVER"
  },
  {
    MAKE_NAME: "RENAULT",
    label: "RENAULT",
    value: "RENAULT"
  },
  {
    MAKE_NAME: "HOLDEN",
    label: "HOLDEN",
    value: "HOLDEN"
  },
  {
    MAKE_NAME: "AC",
    label: "AC",
    value: "AC"
  },
  {
    MAKE_NAME: "ALFA",
    label: "ALFA",
    value: "ALFA"
  },
  {
    MAKE_NAME: "ASTON MARTIN",
    label: "ASTON MARTIN",
    value: "ASTON MARTIN"
  },
  {
    MAKE_NAME: "BENTLEY",
    label: "BENTLEY",
    value: "BENTLEY"
  },
  {
    MAKE_NAME: "CADILLAC",
    label: "CADILLAC",
    value: "CADILLAC"
  },
  {
    MAKE_NAME: "CHEVROLET",
    label: "CHEVROLET",
    value: "CHEVROLET"
  },
  {
    MAKE_NAME: "CHRYSLER",
    label: "CHRYSLER",
    value: "CHRYSLER"
  },
  {
    MAKE_NAME: "DAIMLER",
    label: "DAIMLER",
    value: "DAIMLER"
  },
  {
    MAKE_NAME: "FERRARI",
    label: "FERRARI",
    value: "FERRARI"
  },
  {
    MAKE_NAME: "JAGUAR",
    label: "JAGUAR",
    value: "JAGUAR"
  },
  {
    MAKE_NAME: "KIA",
    label: "KIA",
    value: "KIA"
  },
  {
    MAKE_NAME: "LAMBORGHINI",
    label: "LAMBORGHINI",
    value: "LAMBORGHINI"
  },
  {
    MAKE_NAME: "LANCIA",
    label: "LANCIA",
    value: "LANCIA"
  },
  {
    MAKE_NAME: "LEXUS",
    label: "LEXUS",
    value: "LEXUS"
  },
  {
    MAKE_NAME: "LOTUS",
    label: "LOTUS",
    value: "LOTUS"
  },
  {
    MAKE_NAME: "MASERATI",
    label: "MASERATI",
    value: "MASERATI"
  },
  {
    MAKE_NAME: "PORSCHE",
    label: "PORSCHE",
    value: "PORSCHE"
  },
  {
    MAKE_NAME: "ROLLS-ROYCE",
    label: "ROLLS-ROYCE",
    value: "ROLLS-ROYCE"
  },
  {
    MAKE_NAME: "SSANG YONG",
    label: "SSANG YONG",
    value: "SSANG YONG"
  },
  {
    MAKE_NAME: "SEAT",
    label: "SEAT",
    value: "SEAT"
  },
  {
    MAKE_NAME: "HILLMAN",
    label: "HILLMAN",
    value: "HILLMAN"
  },
  {
    MAKE_NAME: "JEEP",
    label: "JEEP",
    value: "JEEP"
  },
  {
    MAKE_NAME: "DATSUN",
    label: "DATSUN",
    value: "DATSUN"
  },
  {
    MAKE_NAME: "AUSTIN",
    label: "AUSTIN",
    value: "AUSTIN"
  },
  {
    MAKE_NAME: "HINO",
    label: "HINO",
    value: "HINO"
  },
  {
    MAKE_NAME: "DODGE",
    label: "DODGE",
    value: "DODGE"
  },
  {
    MAKE_NAME: "DAF",
    label: "DAF",
    value: "DAF"
  },
  {
    MAKE_NAME: "CHEETAH",
    label: "CHEETAH",
    value: "CHEETAH"
  },
  {
    MAKE_NAME: "รถพ่วง",
    label: "รถพ่วง",
    value: "รถพ่วง"
  },
  {
    MAKE_NAME: "AGRICUTURE TRUCK",
    label: "AGRICUTURE TRUCK",
    value: "AGRICUTURE TRUCK"
  },
  {
    MAKE_NAME: "YAMAHA",
    label: "YAMAHA",
    value: "YAMAHA"
  },
  {
    MAKE_NAME: "KAWASAKI",
    label: "KAWASAKI",
    value: "KAWASAKI"
  },
  {
    MAKE_NAME: "STATEMAN",
    label: "STATEMAN",
    value: "STATEMAN"
  },
  {
    MAKE_NAME: "VESPA",
    label: "VESPA",
    value: "VESPA"
  },
  {
    MAKE_NAME: "PIAGGIO",
    label: "PIAGGIO",
    value: "PIAGGIO"
  },
  {
    MAKE_NAME: "HARLEY DAVIDSON",
    label: "HARLEY DAVIDSON",
    value: "HARLEY DAVIDSON"
  },
  {
    MAKE_NAME: "BAJAJ",
    label: "BAJAJ",
    value: "BAJAJ"
  },
  {
    MAKE_NAME: "SIMCA",
    label: "SIMCA",
    value: "SIMCA"
  },
  {
    MAKE_NAME: "BACO",
    label: "BACO",
    value: "BACO"
  },
  {
    MAKE_NAME: "OLDSMOBILE",
    label: "OLDSMOBILE",
    value: "OLDSMOBILE"
  },
  {
    MAKE_NAME: "สามล้อเครื่อง",
    label: "สามล้อเครื่อง",
    value: "สามล้อเครื่อง"
  },
  {
    MAKE_NAME: "CATER PILLAR",
    label: "CATER PILLAR",
    value: "CATER PILLAR"
  },
  {
    MAKE_NAME: "KOMATSU",
    label: "KOMATSU",
    value: "KOMATSU"
  },
  {
    MAKE_NAME: "SAKAI",
    label: "SAKAI",
    value: "SAKAI"
  },
  {
    MAKE_NAME: "TRACTOR",
    label: "TRACTOR",
    value: "TRACTOR"
  },
  {
    MAKE_NAME: "DYNAPAC",
    label: "DYNAPAC",
    value: "DYNAPAC"
  },
  {
    MAKE_NAME: "STRIDE",
    label: "STRIDE",
    value: "STRIDE"
  },
  {
    MAKE_NAME: "SCOOTER",
    label: "SCOOTER",
    value: "SCOOTER"
  },
  {
    MAKE_NAME: "KUBOTA",
    label: "KUBOTA",
    value: "KUBOTA"
  },
  {
    MAKE_NAME: "PREMAS",
    label: "PREMAS",
    value: "PREMAS"
  },
  {
    MAKE_NAME: "BRIDGE STONE",
    label: "BRIDGE STONE",
    value: "BRIDGE STONE"
  },
  {
    MAKE_NAME: "SCANIA",
    label: "SCANIA",
    value: "SCANIA"
  },
  {
    MAKE_NAME: "CAGIVA",
    label: "CAGIVA",
    value: "CAGIVA"
  },
  {
    MAKE_NAME: "JAFLIN",
    label: "JAFLIN",
    value: "JAFLIN"
  },
  {
    MAKE_NAME: "VMC",
    label: "VMC",
    value: "VMC"
  },
  {
    MAKE_NAME: "IWAFUJI",
    label: "IWAFUJI",
    value: "IWAFUJI"
  },
  {
    MAKE_NAME: "YANMA",
    label: "YANMA",
    value: "YANMA"
  },
  {
    MAKE_NAME: "SINGER",
    label: "SINGER",
    value: "SINGER"
  },
  {
    MAKE_NAME: "PONTIAC",
    label: "PONTIAC",
    value: "PONTIAC"
  },
  {
    MAKE_NAME: "BUCHING",
    label: "BUCHING",
    value: "BUCHING"
  },
  {
    MAKE_NAME: "MG",
    label: "MG",
    value: "MG"
  },
  {
    MAKE_NAME: "INTERNATIONAL",
    label: "INTERNATIONAL",
    value: "INTERNATIONAL"
  },
  {
    MAKE_NAME: "KATO",
    label: "KATO",
    value: "KATO"
  },
  {
    MAKE_NAME: "TADANO",
    label: "TADANO",
    value: "TADANO"
  },
  {
    MAKE_NAME: "KOBELCO",
    label: "KOBELCO",
    value: "KOBELCO"
  },
  {
    MAKE_NAME: "HITACHI",
    label: "HITACHI",
    value: "HITACHI"
  },
  {
    MAKE_NAME: "CORD",
    label: "CORD",
    value: "CORD"
  },
  {
    MAKE_NAME: "CAT",
    label: "CAT",
    value: "CAT"
  },
  {
    MAKE_NAME: "CATER INGERSOILL-RAND",
    label: "CATER INGERSOILL-RAND",
    value: "CATER INGERSOILL-RAND"
  },
  {
    MAKE_NAME: "CARD",
    label: "CARD",
    value: "CARD"
  },
  {
    MAKE_NAME: "LAMBRETTA",
    label: "LAMBRETTA",
    value: "LAMBRETTA"
  },
  {
    MAKE_NAME: "VALIANT",
    label: "VALIANT",
    value: "VALIANT"
  },
  {
    MAKE_NAME: "M.Z.",
    label: "M.Z.",
    value: "M.Z."
  },
  {
    MAKE_NAME: "TRIUMPH",
    label: "TRIUMPH",
    value: "TRIUMPH"
  },
  {
    MAKE_NAME: "NICE",
    label: "NICE",
    value: "NICE"
  },
  {
    MAKE_NAME: "BUICK",
    label: "BUICK",
    value: "BUICK"
  },
  {
    MAKE_NAME: "RANGE ROVER",
    label: "RANGE ROVER",
    value: "RANGE ROVER"
  },
  {
    MAKE_NAME: "YEMINI",
    label: "YEMINI",
    value: "YEMINI"
  },
  {
    MAKE_NAME: "JUPITER",
    label: "JUPITER",
    value: "JUPITER"
  },
  {
    MAKE_NAME: "SUMITOMO",
    label: "SUMITOMO",
    value: "SUMITOMO"
  },
  {
    MAKE_NAME: "SKODA",
    label: "SKODA",
    value: "SKODA"
  },
  {
    MAKE_NAME: "ASIA COMBI",
    label: "ASIA COMBI",
    value: "ASIA COMBI"
  },
  {
    MAKE_NAME: "JCB",
    label: "JCB",
    value: "JCB"
  },
  {
    MAKE_NAME: "WATT",
    label: "WATT",
    value: "WATT"
  },
  {
    MAKE_NAME: "INGERSOLL RAND",
    label: "INGERSOLL RAND",
    value: "INGERSOLL RAND"
  },
  {
    MAKE_NAME: "ELECTRIC MOTORCYCLE",
    label: "ELECTRIC MOTORCYCLE",
    value: "ELECTRIC MOTORCYCLE"
  },
  {
    MAKE_NAME: "A.J.S.",
    label: "A.J.S.",
    value: "A.J.S."
  },
  {
    MAKE_NAME: "SOKON",
    label: "SOKON",
    value: "SOKON"
  },
  {
    MAKE_NAME: "PRINCE",
    label: "PRINCE",
    value: "PRINCE"
  },
  {
    MAKE_NAME: "NARMADA",
    label: "NARMADA",
    value: "NARMADA"
  },
  {
    MAKE_NAME: "TUKTUK",
    label: "TUKTUK",
    value: "TUKTUK"
  },
  {
    MAKE_NAME: "PLYMOUTH PROWLER",
    label: "PLYMOUTH PROWLER",
    value: "PLYMOUTH PROWLER"
  },
  {
    MAKE_NAME: "PECASSO",
    label: "PECASSO",
    value: "PECASSO"
  },
  {
    MAKE_NAME: "RIAL",
    label: "RIAL",
    value: "RIAL"
  },
  {
    MAKE_NAME: "MZ",
    label: "MZ",
    value: "MZ"
  },
  {
    MAKE_NAME: "CHEVY VAN",
    label: "CHEVY VAN",
    value: "CHEVY VAN"
  },
  {
    MAKE_NAME: "SHIBULA",
    label: "SHIBULA",
    value: "SHIBULA"
  },
  {
    MAKE_NAME: "M0RRIS",
    label: "M0RRIS",
    value: "M0RRIS"
  },
  {
    MAKE_NAME: "INTER",
    label: "INTER",
    value: "INTER"
  },
  {
    MAKE_NAME: "WATANABE",
    label: "WATANABE",
    value: "WATANABE"
  },
  {
    MAKE_NAME: "RILEY",
    label: "RILEY",
    value: "RILEY"
  },
  {
    MAKE_NAME: "MOTOATT",
    label: "MOTOATT",
    value: "MOTOATT"
  },
  {
    MAKE_NAME: "BOMAG",
    label: "BOMAG",
    value: "BOMAG"
  },
  {
    MAKE_NAME: "NIKATA",
    label: "NIKATA",
    value: "NIKATA"
  },
  {
    MAKE_NAME: "MAXGI RUDOY",
    label: "MAXGI RUDOY",
    value: "MAXGI RUDOY"
  },
  {
    MAKE_NAME: "HAMM",
    label: "HAMM",
    value: "HAMM"
  },
  {
    MAKE_NAME: "รถบดถนน",
    label: "รถบดถนน",
    value: "รถบดถนน"
  },
  {
    MAKE_NAME: "CANTER",
    label: "CANTER",
    value: "CANTER"
  },
  {
    MAKE_NAME: "PERGIBCH",
    label: "PERGIBCH",
    value: "PERGIBCH"
  },
  {
    MAKE_NAME: "GRAND",
    label: "GRAND",
    value: "GRAND"
  },
  {
    MAKE_NAME: "LML",
    label: "LML",
    value: "LML"
  },
  {
    MAKE_NAME: "LEYLAND",
    label: "LEYLAND",
    value: "LEYLAND"
  },
  {
    MAKE_NAME: "ROYAL ENFIELD",
    label: "ROYAL ENFIELD",
    value: "ROYAL ENFIELD"
  },
  {
    MAKE_NAME: "VAUXHALL",
    label: "VAUXHALL",
    value: "VAUXHALL"
  },
  {
    MAKE_NAME: "B S A",
    label: "B S A",
    value: "B S A"
  },
  {
    MAKE_NAME: "DUCATI",
    label: "DUCATI",
    value: "DUCATI"
  },
  {
    MAKE_NAME: "FARGO",
    label: "FARGO",
    value: "FARGO"
  },
  {
    MAKE_NAME: "ARMSTRONG",
    label: "ARMSTRONG",
    value: "ARMSTRONG"
  },
  {
    MAKE_NAME: "MASLY",
    label: "MASLY",
    value: "MASLY"
  },
  {
    MAKE_NAME: "TATA",
    label: "TATA",
    value: "TATA"
  },
  {
    MAKE_NAME: "NIVA",
    label: "NIVA",
    value: "NIVA"
  },
  {
    MAKE_NAME: "WOLSELEY",
    label: "WOLSELEY",
    value: "WOLSELEY"
  },
  {
    MAKE_NAME: "COMBI",
    label: "COMBI",
    value: "COMBI"
  },
  {
    MAKE_NAME: "KEDONG",
    label: "KEDONG",
    value: "KEDONG"
  },
  {
    MAKE_NAME: "FAR",
    label: "FAR",
    value: "FAR"
  },
  {
    MAKE_NAME: "TOYOPET",
    label: "TOYOPET",
    value: "TOYOPET"
  },
  {
    MAKE_NAME: "PROTON",
    label: "PROTON",
    value: "PROTON"
  },
  {
    MAKE_NAME: "ALLUS",
    label: "ALLUS",
    value: "ALLUS"
  },
  {
    MAKE_NAME: "BORGWARD HANZA",
    label: "BORGWARD HANZA",
    value: "BORGWARD HANZA"
  },
  {
    MAKE_NAME: "WILLSSAINTE CLAIRE",
    label: "WILLSSAINTE CLAIRE",
    value: "WILLSSAINTE CLAIRE"
  },
  {
    MAKE_NAME: "RUF",
    label: "RUF",
    value: "RUF"
  },
  {
    MAKE_NAME: "BARBER",
    label: "BARBER",
    value: "BARBER"
  },
  {
    MAKE_NAME: "JOHN DEERE",
    label: "JOHN DEERE",
    value: "JOHN DEERE"
  },
  {
    MAKE_NAME: "MNNCK",
    label: "MNNCK",
    value: "MNNCK"
  },
  {
    MAKE_NAME: "GMC",
    label: "GMC",
    value: "GMC"
  },
  {
    MAKE_NAME: "ACURA",
    label: "ACURA",
    value: "ACURA"
  },
  {
    MAKE_NAME: "BERTONE",
    label: "BERTONE",
    value: "BERTONE"
  },
  {
    MAKE_NAME: "CATERA",
    label: "CATERA",
    value: "CATERA"
  },
  {
    MAKE_NAME: "EAGLE",
    label: "EAGLE",
    value: "EAGLE"
  },
  {
    MAKE_NAME: "GM",
    label: "GM",
    value: "GM"
  },
  {
    MAKE_NAME: "REO",
    label: "REO",
    value: "REO"
  },
  {
    MAKE_NAME: "HUMMER",
    label: "HUMMER",
    value: "HUMMER"
  },
  {
    MAKE_NAME: "INFINITI",
    label: "INFINITI",
    value: "INFINITI"
  },
  {
    MAKE_NAME: "ITALDESIGN",
    label: "ITALDESIGN",
    value: "ITALDESIGN"
  },
  {
    MAKE_NAME: "LINCON",
    label: "LINCON",
    value: "LINCON"
  },
  {
    MAKE_NAME: "MCLAREN",
    label: "MCLAREN",
    value: "MCLAREN"
  },
  {
    MAKE_NAME: "MERCURY",
    label: "MERCURY",
    value: "MERCURY"
  },
  {
    MAKE_NAME: "MORGAN",
    label: "MORGAN",
    value: "MORGAN"
  },
  {
    MAKE_NAME: "SATURN",
    label: "SATURN",
    value: "SATURN"
  },
  {
    MAKE_NAME: "SHADA",
    label: "SHADA",
    value: "SHADA"
  },
  {
    MAKE_NAME: "TVR",
    label: "TVR",
    value: "TVR"
  },
  {
    MAKE_NAME: "YULON",
    label: "YULON",
    value: "YULON"
  },
  {
    MAKE_NAME: "ZAGATO",
    label: "ZAGATO",
    value: "ZAGATO"
  },
  {
    MAKE_NAME: "KTM",
    label: "KTM",
    value: "KTM"
  },
  {
    MAKE_NAME: "SAMSUNG",
    label: "SAMSUNG",
    value: "SAMSUNG"
  },
  {
    MAKE_NAME: "DE MAC",
    label: "DE MAC",
    value: "DE MAC"
  },
  {
    MAKE_NAME: "BABERGREEN",
    label: "BABERGREEN",
    value: "BABERGREEN"
  },
  {
    MAKE_NAME: "BEIJINE",
    label: "BEIJINE",
    value: "BEIJINE"
  },
  {
    MAKE_NAME: "BITELLI",
    label: "BITELLI",
    value: "BITELLI"
  },
  {
    MAKE_NAME: "GAGIVA",
    label: "GAGIVA",
    value: "GAGIVA"
  },
  {
    MAKE_NAME: "PERODUA",
    label: "PERODUA",
    value: "PERODUA"
  },
  {
    MAKE_NAME: "BOSS",
    label: "BOSS",
    value: "BOSS"
  },
  {
    MAKE_NAME: "MGF",
    label: "MGF",
    value: "MGF"
  },
  {
    MAKE_NAME: "PLATINUM",
    label: "PLATINUM",
    value: "PLATINUM"
  },
  {
    MAKE_NAME: "MAN",
    label: "MAN",
    value: "MAN"
  },
  {
    MAKE_NAME: "TR",
    label: "TR",
    value: "TR"
  },
  {
    MAKE_NAME: "OTTAWA",
    label: "OTTAWA",
    value: "OTTAWA"
  },
  {
    MAKE_NAME: "COMMINS",
    label: "COMMINS",
    value: "COMMINS"
  },
  {
    MAKE_NAME: "MASSCYFERCUSON",
    label: "MASSCYFERCUSON",
    value: "MASSCYFERCUSON"
  },
  {
    MAKE_NAME: "SUNBEAM",
    label: "SUNBEAM",
    value: "SUNBEAM"
  },
  {
    MAKE_NAME: "AMMANN",
    label: "AMMANN",
    value: "AMMANN"
  },
  {
    MAKE_NAME: "FESER",
    label: "FESER",
    value: "FESER"
  },
  {
    MAKE_NAME: "APRILIA",
    label: "APRILIA",
    value: "APRILIA"
  },
  {
    MAKE_NAME: "KANCIL",
    label: "KANCIL",
    value: "KANCIL"
  },
  {
    MAKE_NAME: "HUTSON",
    label: "HUTSON",
    value: "HUTSON"
  },
  {
    MAKE_NAME: "TIGER",
    label: "TIGER",
    value: "TIGER"
  },
  {
    MAKE_NAME: "MARCOS",
    label: "MARCOS",
    value: "MARCOS"
  },
  {
    MAKE_NAME: "CHEPPEL",
    label: "CHEPPEL",
    value: "CHEPPEL"
  },
  {
    MAKE_NAME: "JRD",
    label: "JRD",
    value: "JRD"
  },
  {
    MAKE_NAME: "NEOPLAN",
    label: "NEOPLAN",
    value: "NEOPLAN"
  },
  {
    MAKE_NAME: "DEUTZ",
    label: "DEUTZ",
    value: "DEUTZ"
  },
  {
    MAKE_NAME: "KANUCH",
    label: "KANUCH",
    value: "KANUCH"
  },
  {
    MAKE_NAME: "GEO",
    label: "GEO",
    value: "GEO"
  },
  {
    MAKE_NAME: "IVECO",
    label: "IVECO",
    value: "IVECO"
  },
  {
    MAKE_NAME: "DONGFENG",
    label: "DONGFENG",
    value: "DONGFENG"
  },
  {
    MAKE_NAME: "WULING",
    label: "WULING",
    value: "WULING"
  },
  {
    MAKE_NAME: "VOGELE",
    label: "VOGELE",
    value: "VOGELE"
  },
  {
    MAKE_NAME: "GARUDA",
    label: "GARUDA",
    value: "GARUDA"
  },
  {
    MAKE_NAME: "CHANG JAING",
    label: "CHANG JAING",
    value: "CHANG JAING"
  },
  {
    MAKE_NAME: "CASE",
    label: "CASE",
    value: "CASE"
  },
  {
    MAKE_NAME: "LIFAN",
    label: "LIFAN",
    value: "LIFAN"
  },
  {
    MAKE_NAME: "BMC",
    label: "BMC",
    value: "BMC"
  },
  {
    MAKE_NAME: "PEGASUS",
    label: "PEGASUS",
    value: "PEGASUS"
  },
  {
    MAKE_NAME: "ATV",
    label: "ATV",
    value: "ATV"
  },
  {
    MAKE_NAME: "VIBROMAX",
    label: "VIBROMAX",
    value: "VIBROMAX"
  },
  {
    MAKE_NAME: "GOLDEN DRAGON",
    label: "GOLDEN DRAGON",
    value: "GOLDEN DRAGON"
  },
  {
    MAKE_NAME: "LONDON CAB",
    label: "LONDON CAB",
    value: "LONDON CAB"
  },
  {
    MAKE_NAME: "INDIAN",
    label: "INDIAN",
    value: "INDIAN"
  },
  {
    MAKE_NAME: "BUELL",
    label: "BUELL",
    value: "BUELL"
  },
  {
    MAKE_NAME: "JOYNER",
    label: "JOYNER",
    value: "JOYNER"
  },
  {
    MAKE_NAME: "HARDE",
    label: "HARDE",
    value: "HARDE"
  },
  {
    MAKE_NAME: "STIE",
    label: "STIE",
    value: "STIE"
  },
  {
    MAKE_NAME: "STEYR-PUCH",
    label: "STEYR-PUCH",
    value: "STEYR-PUCH"
  },
  {
    MAKE_NAME: "MINI",
    label: "MINI",
    value: "MINI"
  },
  {
    MAKE_NAME: "FAW",
    label: "FAW",
    value: "FAW"
  },
  {
    MAKE_NAME: "BOBKAD",
    label: "BOBKAD",
    value: "BOBKAD"
  },
  {
    MAKE_NAME: "VIVACE",
    label: "VIVACE",
    value: "VIVACE"
  },
  {
    MAKE_NAME: "NEW HOLLAND",
    label: "NEW HOLLAND",
    value: "NEW HOLLAND"
  },
  {
    MAKE_NAME: "T-UNITED",
    label: "T-UNITED",
    value: "T-UNITED"
  },
  {
    MAKE_NAME: "TWINNER",
    label: "TWINNER",
    value: "TWINNER"
  },
  {
    MAKE_NAME: "SAMMITR",
    label: "SAMMITR",
    value: "SAMMITR"
  },
  {
    MAKE_NAME: "RYUKA",
    label: "RYUKA",
    value: "RYUKA"
  },
  {
    MAKE_NAME: "M BIKE",
    label: "M BIKE",
    value: "M BIKE"
  },
  {
    MAKE_NAME: "HERCUUS",
    label: "HERCUUS",
    value: "HERCUUS"
  },
  {
    MAKE_NAME: "CHERDCHAI",
    label: "CHERDCHAI",
    value: "CHERDCHAI"
  },
  {
    MAKE_NAME: "SON",
    label: "SON",
    value: "SON"
  },
  {
    MAKE_NAME: "MONIKA",
    label: "MONIKA",
    value: "MONIKA"
  },
  {
    MAKE_NAME: "MITSUOKA",
    label: "MITSUOKA",
    value: "MITSUOKA"
  },
  {
    MAKE_NAME: "BEAT ATV P110",
    label: "BEAT ATV P110",
    value: "BEAT ATV P110"
  },
  {
    MAKE_NAME: "DFM",
    label: "DFM",
    value: "DFM"
  },
  {
    MAKE_NAME: "HIGER",
    label: "HIGER",
    value: "HIGER"
  },
  {
    MAKE_NAME: "EUROTRAC",
    label: "EUROTRAC",
    value: "EUROTRAC"
  },
  {
    MAKE_NAME: "MAXWELL",
    label: "MAXWELL",
    value: "MAXWELL"
  },
  {
    MAKE_NAME: "LAND INI",
    label: "LAND INI",
    value: "LAND INI"
  },
  {
    MAKE_NAME: "ARIEL",
    label: "ARIEL",
    value: "ARIEL"
  },
  {
    MAKE_NAME: "SPYKER",
    label: "SPYKER",
    value: "SPYKER"
  },
  {
    MAKE_NAME: "NAZA",
    label: "NAZA",
    value: "NAZA"
  },
  {
    MAKE_NAME: "โร่เลช",
    label: "โร่เลช",
    value: "โร่เลช"
  },
  {
    MAKE_NAME: "STALLION",
    label: "STALLION",
    value: "STALLION"
  },
  {
    MAKE_NAME: "BOBCAT",
    label: "BOBCAT",
    value: "BOBCAT"
  },
  {
    MAKE_NAME: "POLARSUN",
    label: "POLARSUN",
    value: "POLARSUN"
  },
  {
    MAKE_NAME: "SONDABUS",
    label: "SONDABUS",
    value: "SONDABUS"
  },
  {
    MAKE_NAME: "SKYWING",
    label: "SKYWING",
    value: "SKYWING"
  },
  {
    MAKE_NAME: "KENWORTH",
    label: "KENWORTH",
    value: "KENWORTH"
  },
  {
    MAKE_NAME: "JIALING",
    label: "JIALING",
    value: "JIALING"
  },
  {
    MAKE_NAME: "CHERY",
    label: "CHERY",
    value: "CHERY"
  },
  {
    MAKE_NAME: "TRAILER",
    label: "TRAILER",
    value: "TRAILER"
  },
  {
    MAKE_NAME: "AICHI",
    label: "AICHI",
    value: "AICHI"
  },
  {
    MAKE_NAME: "RADICAL",
    label: "RADICAL",
    value: "RADICAL"
  },
  {
    MAKE_NAME: "WILLYS",
    label: "WILLYS",
    value: "WILLYS"
  },
  {
    MAKE_NAME: "TEREX",
    label: "TEREX",
    value: "TEREX"
  },
  {
    MAKE_NAME: "HKMCO",
    label: "HKMCO",
    value: "HKMCO"
  },
  {
    MAKE_NAME: "SINOTRUK",
    label: "SINOTRUK",
    value: "SINOTRUK"
  },
  {
    MAKE_NAME: "RETRO",
    label: "RETRO",
    value: "RETRO"
  },
  {
    MAKE_NAME: "R-REX",
    label: "R-REX",
    value: "R-REX"
  },
  {
    MAKE_NAME: "CHAMP",
    label: "CHAMP",
    value: "CHAMP"
  },
  {
    MAKE_NAME: "MAYBACH",
    label: "MAYBACH",
    value: "MAYBACH"
  },
  {
    MAKE_NAME: "CMI",
    label: "CMI",
    value: "CMI"
  },
  {
    MAKE_NAME: "MOTOGUZZI",
    label: "MOTOGUZZI",
    value: "MOTOGUZZI"
  },
  {
    MAKE_NAME: "PANUS",
    label: "PANUS",
    value: "PANUS"
  },
  {
    MAKE_NAME: "GUSTO",
    label: "GUSTO",
    value: "GUSTO"
  },
  {
    MAKE_NAME: "WESTFIELD",
    label: "WESTFIELD",
    value: "WESTFIELD"
  },
  {
    MAKE_NAME: "TESLA",
    label: "TESLA",
    value: "TESLA"
  },
  {
    MAKE_NAME: "SHACMAN",
    label: "SHACMAN",
    value: "SHACMAN"
  },
  {
    MAKE_NAME: "KEEWAY",
    label: "KEEWAY",
    value: "KEEWAY"
  },
  {
    MAKE_NAME: "VOLGA",
    label: "VOLGA",
    value: "VOLGA"
  },
  {
    MAKE_NAME: "RAMBLER",
    label: "RAMBLER",
    value: "RAMBLER"
  },
  {
    MAKE_NAME: "DEVA",
    label: "DEVA",
    value: "DEVA"
  },
  {
    MAKE_NAME: "T-REX",
    label: "T-REX",
    value: "T-REX"
  },
  {
    MAKE_NAME: "ZERO",
    label: "ZERO",
    value: "ZERO"
  },
  {
    MAKE_NAME: "SANY",
    label: "SANY",
    value: "SANY"
  },
  {
    MAKE_NAME: "TCX",
    label: "TCX",
    value: "TCX"
  },
  {
    MAKE_NAME: "DAYUN",
    label: "DAYUN",
    value: "DAYUN"
  },
  {
    MAKE_NAME: "MITSUOKA",
    label: "MITSUOKA",
    value: "MITSUOKA"
  },
  {
    MAKE_NAME: "JOYLONG",
    label: "JOYLONG",
    value: "JOYLONG"
  },
  {
    MAKE_NAME: "DFSK",
    label: "DFSK",
    value: "DFSK"
  },
  {
    MAKE_NAME: "SUNLONG",
    label: "SUNLONG",
    value: "SUNLONG"
  },
  {
    MAKE_NAME: "FOTON",
    label: "FOTON",
    value: "FOTON"
  },
  {
    MAKE_NAME: "SUNTA",
    label: "SUNTA",
    value: "SUNTA"
  },
  {
    MAKE_NAME: "GTS",
    label: "GTS",
    value: "GTS"
  },
  {
    MAKE_NAME: "MV AGUSTA",
    label: "MV AGUSTA",
    value: "MV AGUSTA"
  },
  {
    MAKE_NAME: "MAKEERUS",
    label: "MAKEERUS",
    value: "MAKEERUS"
  },
  {
    MAKE_NAME: "SUMOTA",
    label: "SUMOTA",
    value: "SUMOTA"
  },
  {
    MAKE_NAME: "VICTORY",
    label: "VICTORY",
    value: "VICTORY"
  },
  {
    MAKE_NAME: "HENGTONG",
    label: "HENGTONG",
    value: "HENGTONG"
  },
  {
    MAKE_NAME: "KARRY",
    label: "KARRY",
    value: "KARRY"
  },
  {
    MAKE_NAME: "SERPOLLET",
    label: "SERPOLLET",
    value: "SERPOLLET"
  },
  {
    MAKE_NAME: "YAXING",
    label: "YAXING",
    value: "YAXING"
  },
  {
    MAKE_NAME: "BENELLI",
    label: "BENELLI",
    value: "BENELLI"
  },
  {
    MAKE_NAME: "BEIBEN",
    label: "BEIBEN",
    value: "BEIBEN"
  },
  {
    MAKE_NAME: "CATERHAM",
    label: "CATERHAM",
    value: "CATERHAM"
  },
  {
    MAKE_NAME: "PAGANI",
    label: "PAGANI",
    value: "PAGANI"
  },
  {
    MAKE_NAME: "YANGTSE",
    label: "YANGTSE",
    value: "YANGTSE"
  },
  {
    MAKE_NAME: "STERY",
    label: "STERY",
    value: "STERY"
  },
  {
    MAKE_NAME: "JMC",
    label: "JMC",
    value: "JMC"
  },
  {
    MAKE_NAME: "CAMC",
    label: "CAMC",
    value: "CAMC"
  },
  {
    MAKE_NAME: "FUWA",
    label: "FUWA",
    value: "FUWA"
  },
  {
    MAKE_NAME: "TPM",
    label: "TPM",
    value: "TPM"
  },
  {
    MAKE_NAME: "LIUGONG",
    label: "LIUGONG",
    value: "LIUGONG"
  },
  {
    MAKE_NAME: "YUTONG",
    label: "YUTONG",
    value: "YUTONG"
  },
  {
    MAKE_NAME: "V13R",
    label: "V13R",
    value: "V13R"
  },
  {
    MAKE_NAME: "BYD",
    label: "BYD",
    value: "BYD"
  },
  {
    MAKE_NAME: "MAXUS",
    label: "MAXUS",
    value: "MAXUS"
  },
  {
    MAKE_NAME: "UD",
    label: "UD",
    value: "UD"
  },
  {
    MAKE_NAME: "FUSO",
    label: "FUSO",
    value: "FUSO"
  },
  {
    MAKE_NAME: "POLARIS",
    label: "POLARIS",
    value: "POLARIS"
  },
  {
    MAKE_NAME: "KIOTI",
    label: "KIOTI",
    value: "KIOTI"
  },
  {
    MAKE_NAME: "CAN AM",
    label: "CAN AM",
    value: "CAN AM"
  },
  {
    MAKE_NAME: "ZOOMLION",
    label: "ZOOMLION",
    value: "ZOOMLION"
  },
  {
    MAKE_NAME: "MAHINDRA",
    label: "MAHINDRA",
    value: "MAHINDRA"
  },
  {
    MAKE_NAME: "SEM",
    label: "SEM",
    value: "SEM"
  },
  {
    MAKE_NAME: "HAIMA",
    label: "HAIMA",
    value: "HAIMA"
  },
  {
    MAKE_NAME: "CHANGAN",
    label: "CHANGAN",
    value: "CHANGAN"
  },
  {
    MAKE_NAME: "TOYOTRON",
    label: "TOYOTRON",
    value: "TOYOTRON"
  },
  {
    MAKE_NAME: "DONGYU",
    label: "DONGYU",
    value: "DONGYU"
  },
  {
    MAKE_NAME: "KINGLONG",
    label: "KINGLONG",
    value: "KINGLONG"
  },
  {
    MAKE_NAME: "CXT",
    label: "CXT",
    value: "CXT"
  },
  {
    MAKE_NAME: "SCOMADI",
    label: "SCOMADI",
    value: "SCOMADI"
  },
  {
    MAKE_NAME: "RAGE",
    label: "RAGE",
    value: "RAGE"
  },
  {
    MAKE_NAME: "JAVAN",
    label: "JAVAN",
    value: "JAVAN"
  },
  {
    MAKE_NAME: "V-TWIN",
    label: "V-TWIN",
    value: "V-TWIN"
  },
  {
    MAKE_NAME: "HUSQVARNA",
    label: "HUSQVARNA",
    value: "HUSQVARNA"
  },
  {
    MAKE_NAME: "AM GENERAL",
    label: "AM GENERAL",
    value: "AM GENERAL"
  },
  {
    MAKE_NAME: "DEMON",
    label: "DEMON",
    value: "DEMON"
  },
  {
    MAKE_NAME: "GPX",
    label: "GPX",
    value: "GPX"
  },
  {
    MAKE_NAME: "DOOSAN",
    label: "DOOSAN",
    value: "DOOSAN"
  },
  {
    MAKE_NAME: "BIMOTA",
    label: "BIMOTA",
    value: "BIMOTA"
  },
  {
    MAKE_NAME: "DEBERTI",
    label: "DEBERTI",
    value: "DEBERTI"
  },
  {
    MAKE_NAME: "STALLIONS",
    label: "STALLIONS",
    value: "STALLIONS"
  },
  {
    MAKE_NAME: "LINGONG",
    label: "LINGONG",
    value: "LINGONG"
  },
  {
    MAKE_NAME: "SYM",
    label: "SYM",
    value: "SYM"
  },
  {
    MAKE_NAME: "ROADTEX",
    label: "ROADTEX",
    value: "ROADTEX"
  },
  {
    MAKE_NAME: "ZENOS",
    label: "ZENOS",
    value: "ZENOS"
  },
  {
    MAKE_NAME: "COMARTH",
    label: "COMARTH",
    value: "COMARTH"
  },
  {
    MAKE_NAME: "CALLILATER",
    label: "CALLILATER",
    value: "CALLILATER"
  },
  {
    MAKE_NAME: "JBC",
    label: "JBC",
    value: "JBC"
  },
  {
    MAKE_NAME: "URAL",
    label: "URAL",
    value: "URAL"
  },
  {
    MAKE_NAME: "BCM",
    label: "BCM",
    value: "BCM"
  },
  {
    MAKE_NAME: "WIRTGEN",
    label: "WIRTGEN",
    value: "WIRTGEN"
  },
  {
    MAKE_NAME: "HANTA",
    label: "HANTA",
    value: "HANTA"
  },
  {
    MAKE_NAME: "NORTON",
    label: "NORTON",
    value: "NORTON"
  },
  {
    MAKE_NAME: "BOBBER",
    label: "BOBBER",
    value: "BOBBER"
  },
  {
    MAKE_NAME: "SHANTUI",
    label: "SHANTUI",
    value: "SHANTUI"
  },
  {
    MAKE_NAME: "ALLARD",
    label: "ALLARD",
    value: "ALLARD"
  },
  {
    MAKE_NAME: "JAC",
    label: "JAC",
    value: "JAC"
  },
  {
    MAKE_NAME: "JANSSEN",
    label: "JANSSEN",
    value: "JANSSEN"
  },
  {
    MAKE_NAME: "SMITH MOTORSPORT",
    label: "SMITH MOTORSPORT",
    value: "SMITH MOTORSPORT"
  },
  {
    MAKE_NAME: "ABG",
    label: "ABG",
    value: "ABG"
  },
  {
    MAKE_NAME: "THAINA",
    label: "THAINA",
    value: "THAINA"
  },
  {
    MAKE_NAME: "SPCN",
    label: "SPCN",
    value: "SPCN"
  },
  {
    MAKE_NAME: "VELOCETTE",
    label: "VELOCETTE",
    value: "VELOCETTE"
  },
  {
    MAKE_NAME: "VINCENET",
    label: "VINCENET",
    value: "VINCENET"
  },
  {
    MAKE_NAME: "PANTHER",
    label: "PANTHER",
    value: "PANTHER"
  },
  {
    MAKE_NAME: "ZONGSHINE",
    label: "ZONGSHINE",
    value: "ZONGSHINE"
  },
  {
    MAKE_NAME: "MOTO PARILLA",
    label: "MOTO PARILLA",
    value: "MOTO PARILLA"
  },
  {
    MAKE_NAME: "LIEBHERR",
    label: "LIEBHERR",
    value: "LIEBHERR"
  },
  {
    MAKE_NAME: "RELY",
    label: "RELY",
    value: "RELY"
  },
  {
    MAKE_NAME: "HANWAY",
    label: "HANWAY",
    value: "HANWAY"
  },
  {
    MAKE_NAME: "DOUZ",
    label: "DOUZ",
    value: "DOUZ"
  },
  {
    MAKE_NAME: "RABA",
    label: "RABA",
    value: "RABA"
  },
  {
    MAKE_NAME: "NKT",
    label: "NKT",
    value: "NKT"
  },
  {
    MAKE_NAME: "VIBRATING ROLLER",
    label: "VIBRATING ROLLER",
    value: "VIBRATING ROLLER"
  },
  {
    MAKE_NAME: "SANIT",
    label: "SANIT",
    value: "SANIT"
  },
  {
    MAKE_NAME: "DENWAY",
    label: "DENWAY",
    value: "DENWAY"
  },
  {
    MAKE_NAME: "ISEKI",
    label: "ISEKI",
    value: "ISEKI"
  },
  {
    MAKE_NAME: "FARM MAC",
    label: "FARM MAC",
    value: "FARM MAC"
  },
  {
    MAKE_NAME: "SKYWELL",
    label: "SKYWELL",
    value: "SKYWELL"
  },
  {
    MAKE_NAME: "CONFEDERATE",
    label: "CONFEDERATE",
    value: "CONFEDERATE"
  },
  {
    MAKE_NAME: "CFMOTO",
    label: "CFMOTO",
    value: "CFMOTO"
  },
  {
    MAKE_NAME: "HUMBER",
    label: "HUMBER",
    value: "HUMBER"
  },
  {
    MAKE_NAME: "NIU",
    label: "NIU",
    value: "NIU"
  },
  {
    MAKE_NAME: "LEABON",
    label: "LEABON",
    value: "LEABON"
  },
  {
    MAKE_NAME: "STAR8-V",
    label: "STAR8-V",
    value: "STAR8-V"
  },
  {
    MAKE_NAME: "CHANGLIN",
    label: "CHANGLIN",
    value: "CHANGLIN"
  },
  {
    MAKE_NAME: "TCM",
    label: "TCM",
    value: "TCM"
  },
  {
    MAKE_NAME: "ANYUAN",
    label: "ANYUAN",
    value: "ANYUAN"
  },
  {
    MAKE_NAME: "DKW",
    label: "DKW",
    value: "DKW"
  },
  {
    MAKE_NAME: "ECOOTER",
    label: "ECOOTER",
    value: "ECOOTER"
  },
  {
    MAKE_NAME: "GROVE",
    label: "GROVE",
    value: "GROVE"
  },
  {
    MAKE_NAME: "ROYAL ALLOY",
    label: "ROYAL ALLOY",
    value: "ROYAL ALLOY"
  },
  {
    MAKE_NAME: "DECO",
    label: "DECO",
    value: "DECO"
  },
  {
    MAKE_NAME: "ANKAI",
    label: "ANKAI",
    value: "ANKAI"
  },
  {
    MAKE_NAME: "OLDSCHOOL",
    label: "OLDSCHOOL",
    value: "OLDSCHOOL"
  },
  {
    MAKE_NAME: "THACO",
    label: "THACO",
    value: "THACO"
  },
  {
    MAKE_NAME: "ZHONGTHONG",
    label: "ZHONGTHONG",
    value: "ZHONGTHONG"
  },
  {
    MAKE_NAME: "PEDA",
    label: "PEDA",
    value: "PEDA"
  },
  {
    MAKE_NAME: "SBY",
    label: "SBY",
    value: "SBY"
  },
  {
    MAKE_NAME: "ULTIMA",
    label: "ULTIMA",
    value: "ULTIMA"
  },
  {
    MAKE_NAME: "U-EINFLY",
    label: "U-EINFLY",
    value: "U-EINFLY"
  },
  {
    MAKE_NAME: "SEV",
    label: "SEV",
    value: "SEV"
  },
  {
    MAKE_NAME: "SONTES",
    label: "SONTES",
    value: "SONTES"
  },
  {
    MAKE_NAME: "LVNENG",
    label: "LVNENG",
    value: "LVNENG"
  },
  {
    MAKE_NAME: "STEYR",
    label: "STEYR",
    value: "STEYR"
  },
  {
    MAKE_NAME: "FOMM",
    label: "FOMM",
    value: "FOMM"
  },
  {
    MAKE_NAME: "HIDROMEX",
    label: "HIDROMEX",
    value: "HIDROMEX"
  },
  {
    MAKE_NAME: "SIAM",
    label: "SIAM",
    value: "SIAM"
  },
  {
    MAKE_NAME: "EXCALIBUR",
    label: "EXCALIBUR",
    value: "EXCALIBUR"
  }
]

const carModel = [
  {
    SERIAL: "1000000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TOYOTA",
    label: "TOYOTA",
    value: "TOYOTA"
  },
  {
    SERIAL: "1010000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA",
    label: "COROLLA",
    value: "COROLLA"
  },
  {
    SERIAL: "1020000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA DXI 1.3 (EE101)",
    label: "COROLLA DXI 1.3 (EE101)",
    value: "COROLLA DXI 1.3 (EE101)"
  },
  {
    SERIAL: "1030000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXi 1.3 M (EE101)",
    label: "COROLLA GXi 1.3 M (EE101)",
    value: "COROLLA GXi 1.3 M (EE101)"
  },
  {
    SERIAL: "1040000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXi 1.3 A (EE101)",
    label: "COROLLA GXi 1.3 A (EE101)",
    value: "COROLLA GXi 1.3 A (EE101)"
  },
  {
    SERIAL: "1050000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GLI 1.3 (EE101)",
    label: "COROLLA GLI 1.3 (EE101)",
    value: "COROLLA GLI 1.3 (EE101)"
  },
  {
    SERIAL: "1060000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA XL 1.3 (EE100)",
    label: "COROLLA XL 1.3 (EE100)",
    value: "COROLLA XL 1.3 (EE100)"
  },
  {
    SERIAL: "1070000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GL 1.3 (EE100)",
    label: "COROLLA GL 1.3 (EE100)",
    value: "COROLLA GL 1.3 (EE100)"
  },
  {
    SERIAL: "1080000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA (KE30)",
    label: "COROLLA (KE30)",
    value: "COROLLA (KE30)"
  },
  {
    SERIAL: "1090000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GL 1.3 (EE90)",
    label: "COROLLA GL 1.3 (EE90)",
    value: "COROLLA GL 1.3 (EE90)"
  },
  {
    SERIAL: "1100000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA XL 1.3 (EE90)",
    label: "COROLLA XL 1.3 (EE90)",
    value: "COROLLA XL 1.3 (EE90)"
  },
  {
    SERIAL: "1110000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA DXi 1.5 M (AE110)",
    label: "COROLLA DXi 1.5 M (AE110)",
    value: "COROLLA DXi 1.5 M (AE110)"
  },
  {
    SERIAL: "1120000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXi 1.5 M (AE110)",
    label: "COROLLA GXi 1.5 M (AE110)",
    value: "COROLLA GXi 1.5 M (AE110)"
  },
  {
    SERIAL: "1130000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXi 1.5 A (AE110)",
    label: "COROLLA GXi 1.5 A (AE110)",
    value: "COROLLA GXi 1.5 A (AE110)"
  },
  {
    SERIAL: "1140000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXi 1.6 M (AE111)",
    label: "COROLLA GXi 1.6 M (AE111)",
    value: "COROLLA GXi 1.6 M (AE111)"
  },
  {
    SERIAL: "1150000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GLi 1.6 M (AE101)",
    label: "COROLLA GLi 1.6 M (AE101)",
    value: "COROLLA GLi 1.6 M (AE101)"
  },
  {
    SERIAL: "1160000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GLi 1.6 A. (AE101)",
    label: "COROLLA GLi 1.6 A. (AE101)",
    value: "COROLLA GLi 1.6 A. (AE101)"
  },
  {
    SERIAL: "1170000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA 1.6 (AE92)",
    label: "COROLLA 1.6 (AE92)",
    value: "COROLLA 1.6 (AE92)"
  },
  {
    SERIAL: "1190000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA SEG 1.6 A ABS (AE111)",
    label: "COROLLA SEG 1.6 A ABS (AE111)",
    value: "COROLLA SEG 1.6 A ABS (AE111)"
  },
  {
    SERIAL: "1200000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA 1.8",
    label: "CORONA 1.8",
    value: "CORONA 1.8"
  },
  {
    SERIAL: "1210000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA GLi 1.6 M (AT190)",
    label: "CORONA GLi 1.6 M (AT190)",
    value: "CORONA GLi 1.6 M (AT190)"
  },
  {
    SERIAL: "1220000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA GLi 1.6 A (AT190)",
    label: "CORONA GLi 1.6 A (AT190)",
    value: "CORONA GLi 1.6 A (AT190)"
  },
  {
    SERIAL: "1230000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA GL 1.6 (AT171)",
    label: "CORONA GL 1.6 (AT171)",
    value: "CORONA GL 1.6 (AT171)"
  },
  {
    SERIAL: "1240000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA (ST171) 2.0",
    label: "CORONA (ST171) 2.0",
    value: "CORONA (ST171) 2.0"
  },
  {
    SERIAL: "1250000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA GLi 2.0 M (ST191)",
    label: "CORONA GLi 2.0 M (ST191)",
    value: "CORONA GLi 2.0 M (ST191)"
  },
  {
    SERIAL: "1260000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA GLi 2.0 M ABS (ST191)",
    label: "CORONA GLi 2.0 M ABS (ST191)",
    value: "CORONA GLi 2.0 M ABS (ST191)"
  },
  {
    SERIAL: "1270000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA GLi 2.0 A ABS (ST191)",
    label: "CORONA GLi 2.0 A ABS (ST191)",
    value: "CORONA GLi 2.0 A ABS (ST191)"
  },
  {
    SERIAL: "1280000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "PASEO 1.5 M",
    label: "PASEO 1.5 M",
    value: "PASEO 1.5 M"
  },
  {
    SERIAL: "1300000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY",
    label: "CAMRY",
    value: "CAMRY"
  },
  {
    SERIAL: "1310000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.2 M ABS",
    label: "CAMRY 2.2 M ABS",
    value: "CAMRY 2.2 M ABS"
  },
  {
    SERIAL: "1320000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.2 A ABS",
    label: "CAMRY 2.2 A ABS",
    value: "CAMRY 2.2 A ABS"
  },
  {
    SERIAL: "1330000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 3.0 V6 A",
    label: "CAMRY 3.0 V6 A",
    value: "CAMRY 3.0 V6 A"
  },
  {
    SERIAL: "1340000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELICA 2.0 GT4",
    label: "CELICA 2.0 GT4",
    value: "CELICA 2.0 GT4"
  },
  {
    SERIAL: "1350000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELICA 2.2 M",
    label: "CELICA 2.2 M",
    value: "CELICA 2.2 M"
  },
  {
    SERIAL: "1380000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "MR 2",
    label: "MR 2",
    value: "MR 2"
  },
  {
    SERIAL: "1400000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SERA 1.5",
    label: "SERA 1.5",
    value: "SERA 1.5"
  },
  {
    SERIAL: "1410000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "4 RUNNER 3.0 V6",
    label: "4 RUNNER 3.0 V6",
    value: "4 RUNNER 3.0 V6"
  },
  {
    SERIAL: "1420000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRANVIA 2.7",
    label: "GRANVIA 2.7",
    value: "GRANVIA 2.7"
  },
  {
    SERIAL: "1430000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRANVIA 3.4",
    label: "GRANVIA 3.4",
    value: "GRANVIA 3.4"
  },
  {
    SERIAL: "1440000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER 70 4.2 DIESEL",
    label: "LAND CRUISER 70 4.2 DIESEL",
    value: "LAND CRUISER 70 4.2 DIESEL"
  },
  {
    SERIAL: "1450000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER 80 4.2 DIESEL",
    label: "LAND CRUISER 80 4.2 DIESEL",
    value: "LAND CRUISER 80 4.2 DIESEL"
  },
  {
    SERIAL: "1460000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER 80 4.5 BENZIN",
    label: "LAND CRUISER 80 4.5 BENZIN",
    value: "LAND CRUISER 80 4.5 BENZIN"
  },
  {
    SERIAL: "1470000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "RAV 4 2.0 M",
    label: "RAV 4 2.0 M",
    value: "RAV 4 2.0 M"
  },
  {
    SERIAL: "1480000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "RAV 4 2.0 A ABS",
    label: "RAV 4 2.0 A ABS",
    value: "RAV 4 2.0 A ABS"
  },
  {
    SERIAL: "1490000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN ROYAL",
    label: "CROWN ROYAL",
    value: "CROWN ROYAL"
  },
  {
    SERIAL: "1500000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN 2.8 DELUXE",
    label: "CROWN 2.8 DELUXE",
    value: "CROWN 2.8 DELUXE"
  },
  {
    SERIAL: "1540000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "STARLET 1.3",
    label: "STARLET 1.3",
    value: "STARLET 1.3"
  },
  {
    SERIAL: "1550000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "STARLET GT 1.3",
    label: "STARLET GT 1.3",
    value: "STARLET GT 1.3"
  },
  {
    SERIAL: "1560000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRANVIA 3.0",
    label: "GRANVIA 3.0",
    value: "GRANVIA 3.0"
  },
  {
    SERIAL: "1570000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX EXTRACAB",
    label: "HILUX EXTRACAB",
    value: "HILUX EXTRACAB"
  },
  {
    SERIAL: "1580000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX MIGHTY-X 2.5",
    label: "HILUX MIGHTY-X 2.5",
    value: "HILUX MIGHTY-X 2.5"
  },
  {
    SERIAL: "1590000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX LUXURY 2.5",
    label: "HILUX LUXURY 2.5",
    value: "HILUX LUXURY 2.5"
  },
  {
    SERIAL: "1600000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX EXTRACAB 2.5 POWER",
    label: "HILUX EXTRACAB 2.5 POWER",
    value: "HILUX EXTRACAB 2.5 POWER"
  },
  {
    SERIAL: "1610000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX EXTRACAB 4*4",
    label: "HILUX EXTRACAB 4*4",
    value: "HILUX EXTRACAB 4*4"
  },
  {
    SERIAL: "1620000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX DOUBLE CAB 2.5",
    label: "HILUX DOUBLE CAB 2.5",
    value: "HILUX DOUBLE CAB 2.5"
  },
  {
    SERIAL: "1630000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX BENZIN 2.5",
    label: "HILUX BENZIN 2.5",
    value: "HILUX BENZIN 2.5"
  },
  {
    SERIAL: "1640000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SUPRA 3.0",
    label: "SUPRA 3.0",
    value: "SUPRA 3.0"
  },
  {
    SERIAL: "1670000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESTIMA",
    label: "ESTIMA",
    value: "ESTIMA"
  },
  {
    SERIAL: "1680000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LITEACE",
    label: "LITEACE",
    value: "LITEACE"
  },
  {
    SERIAL: "1690000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE",
    label: "HIACE",
    value: "HIACE"
  },
  {
    SERIAL: "1700000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER STANDARD ROOF",
    label: "COMMUTER STANDARD ROOF",
    value: "COMMUTER STANDARD ROOF"
  },
  {
    SERIAL: "1710000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 3.4",
    label: "LAND CRUISER PRADO 3.4",
    value: "LAND CRUISER PRADO 3.4"
  },
  {
    SERIAL: "1720000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA EXSIOR 1.6 GXi (M)",
    label: "CORONA EXSIOR 1.6 GXi (M)",
    value: "CORONA EXSIOR 1.6 GXi (M)"
  },
  {
    SERIAL: "1730000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA EXSIOR SEG 2.0 (M)",
    label: "CORONA EXSIOR SEG 2.0 (M)",
    value: "CORONA EXSIOR SEG 2.0 (M)"
  },
  {
    SERIAL: "1740000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "4 WD",
    label: "4 WD",
    value: "4 WD"
  },
  {
    SERIAL: "1750000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER VX100",
    label: "LAND CRUISER VX100",
    value: "LAND CRUISER VX100"
  },
  {
    SERIAL: "1760000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER VX100 CYGNUS",
    label: "LAND CRUISER VX100 CYGNUS",
    value: "LAND CRUISER VX100 CYGNUS"
  },
  {
    SERIAL: "1780000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELICA (M)",
    label: "CELICA (M)",
    value: "CELICA (M)"
  },
  {
    SERIAL: "1800000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SURF",
    label: "SURF",
    value: "SURF"
  },
  {
    SERIAL: "1810000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "IPSUM 2.0",
    label: "IPSUM 2.0",
    value: "IPSUM 2.0"
  },
  {
    SERIAL: "1820000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA 1.5",
    label: "SOLUNA 1.5",
    value: "SOLUNA 1.5"
  },
  {
    SERIAL: "1830000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA XLi (M)",
    label: "SOLUNA XLi (M)",
    value: "SOLUNA XLi (M)"
  },
  {
    SERIAL: "1840000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA XLi A",
    label: "SOLUNA XLi A",
    value: "SOLUNA XLi A"
  },
  {
    SERIAL: "1850000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA GLi",
    label: "SOLUNA GLi",
    value: "SOLUNA GLi"
  },
  {
    SERIAL: "1860000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA GLi A",
    label: "SOLUNA GLi A",
    value: "SOLUNA GLi A"
  },
  {
    SERIAL: "1870000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX MIGHTY-X CAB LUXURY SGL 2.8",
    label: "HILUX MIGHTY-X CAB LUXURY SGL 2.8",
    value: "HILUX MIGHTY-X CAB LUXURY SGL 2.8"
  },
  {
    SERIAL: "1880000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX MIGHTY-X EXTRACAB SGL 2.8",
    label: "HILUX MIGHTY-X EXTRACAB SGL 2.8",
    value: "HILUX MIGHTY-X EXTRACAB SGL 2.8"
  },
  {
    SERIAL: "1900000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX MIGHTY-X CAB PLUS 2.8",
    label: "HILUX MIGHTY-X CAB PLUS 2.8",
    value: "HILUX MIGHTY-X CAB PLUS 2.8"
  },
  {
    SERIAL: "1920000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESTIMA",
    label: "ESTIMA",
    value: "ESTIMA"
  },
  {
    SERIAL: "1930000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SUPRA",
    label: "SUPRA",
    value: "SUPRA"
  },
  {
    SERIAL: "1940000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOARER",
    label: "SOARER",
    value: "SOARER"
  },
  {
    SERIAL: "1950000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SUPER WAGON",
    label: "SUPER WAGON",
    value: "SUPER WAGON"
  },
  {
    SERIAL: "1960000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SUPER CUSTOM",
    label: "SUPER CUSTOM",
    value: "SUPER CUSTOM"
  },
  {
    SERIAL: "1970000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.2 (CBU)",
    label: "CAMRY 2.2 (CBU)",
    value: "CAMRY 2.2 (CBU)"
  },
  {
    SERIAL: "1980000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 3.0 (CBU)",
    label: "CAMRY 3.0 (CBU)",
    value: "CAMRY 3.0 (CBU)"
  },
  {
    SERIAL: "1990000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.2 SEG (CKD)",
    label: "CAMRY 2.2 SEG (CKD)",
    value: "CAMRY 2.2 SEG (CKD)"
  },
  {
    SERIAL: "01AA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU61",
    label: "DYNA BU61",
    value: "DYNA BU61"
  },
  {
    SERIAL: "01AC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU87",
    label: "DYNA BU87",
    value: "DYNA BU87"
  },
  {
    SERIAL: "01AE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU101",
    label: "DYNA BU101",
    value: "DYNA BU101"
  },
  {
    SERIAL: "01AG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU211",
    label: "DYNA BU211",
    value: "DYNA BU211"
  },
  {
    SERIAL: "01AH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CRESSIDA",
    label: "CRESSIDA",
    value: "CRESSIDA"
  },
  {
    SERIAL: "01AI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXI 1.5",
    label: "COROLLA GXI 1.5",
    value: "COROLLA GXI 1.5"
  },
  {
    SERIAL: "01AK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXI 1.6 (HITOUQ)",
    label: "COROLLA GXI 1.6 (HITOUQ)",
    value: "COROLLA GXI 1.6 (HITOUQ)"
  },
  {
    SERIAL: "01AL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXI 1.6 A (HITOUQ)",
    label: "COROLLA GXI 1.6 A (HITOUQ)",
    value: "COROLLA GXI 1.6 A (HITOUQ)"
  },
  {
    SERIAL: "01AM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA SEG 1.8 (HITOUQ)",
    label: "COROLLA SEG 1.8 (HITOUQ)",
    value: "COROLLA SEG 1.8 (HITOUQ)"
  },
  {
    SERIAL: "01AN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA SEG AUTO 1.8 (HITOUQ)",
    label: "COROLLA SEG AUTO 1.8 (HITOUQ)",
    value: "COROLLA SEG AUTO 1.8 (HITOUQ)"
  },
  {
    SERIAL: "01AR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA 300",
    label: "DYNA 300",
    value: "DYNA 300"
  },
  {
    SERIAL: "01AT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BUILT",
    label: "DYNA BUILT",
    value: "DYNA BUILT"
  },
  {
    SERIAL: "01AV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0",
    label: "CAMRY 2.0",
    value: "CAMRY 2.0"
  },
  {
    SERIAL: "01AW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER 2.4",
    label: "TIGER 2.4",
    value: "TIGER 2.4"
  },
  {
    SERIAL: "01AX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER 2.4 GL",
    label: "TIGER 2.4 GL",
    value: "TIGER 2.4 GL"
  },
  {
    SERIAL: "01AY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER 2.4 GL POWER",
    label: "TIGER 2.4 GL POWER",
    value: "TIGER 2.4 GL POWER"
  },
  {
    SERIAL: "01AZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER 3.0 GL POWER",
    label: "TIGER 3.0 GL POWER",
    value: "TIGER 3.0 GL POWER"
  },
  {
    SERIAL: "01BA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 2.4 GL",
    label: "TIGER EXTRACAB 2.4 GL",
    value: "TIGER EXTRACAB 2.4 GL"
  },
  {
    SERIAL: "01BB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 2.4 SGL",
    label: "TIGER EXTRACAB 2.4 SGL",
    value: "TIGER EXTRACAB 2.4 SGL"
  },
  {
    SERIAL: "01BC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER DOUBLECAB 3.0 4WD",
    label: "TIGER DOUBLECAB 3.0 4WD",
    value: "TIGER DOUBLECAB 3.0 4WD"
  },
  {
    SERIAL: "01BD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 3.0 M",
    label: "TIGER EXTRACAB 3.0 M",
    value: "TIGER EXTRACAB 3.0 M"
  },
  {
    SERIAL: "01BE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 3.0 SGL A",
    label: "TIGER EXTRACAB 3.0 SGL A",
    value: "TIGER EXTRACAB 3.0 SGL A"
  },
  {
    SERIAL: "01BF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 4WD 3.0 GL",
    label: "TIGER EXTRACAB 4WD 3.0 GL",
    value: "TIGER EXTRACAB 4WD 3.0 GL"
  },
  {
    SERIAL: "01BG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 4WD 3.0 G",
    label: "TIGER EXTRACAB 4WD 3.0 G",
    value: "TIGER EXTRACAB 4WD 3.0 G"
  },
  {
    SERIAL: "01BH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 4WD 3.0 SR5",
    label: "TIGER EXTRACAB 4WD 3.0 SR5",
    value: "TIGER EXTRACAB 4WD 3.0 SR5"
  },
  {
    SERIAL: "01BI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX",
    label: "HILUX",
    value: "HILUX"
  },
  {
    SERIAL: "01BJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER PRERUNNER",
    label: "SPORT RIDER PRERUNNER",
    value: "SPORT RIDER PRERUNNER"
  },
  {
    SERIAL: "01BK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER (SR5) 4WD",
    label: "SPORT RIDER (SR5) 4WD",
    value: "SPORT RIDER (SR5) 4WD"
  },
  {
    SERIAL: "01BL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER (SR5 LIMITED) 4WD",
    label: "SPORT RIDER (SR5 LIMITED) 4WD",
    value: "SPORT RIDER (SR5 LIMITED) 4WD"
  },
  {
    SERIAL: "01BM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "STATION WAGON",
    label: "STATION WAGON",
    value: "STATION WAGON"
  },
  {
    SERIAL: "01BN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ARISTO",
    label: "ARISTO",
    value: "ARISTO"
  },
  {
    SERIAL: "01BO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA XLi (M)",
    label: "SOLUNA XLi (M)",
    value: "SOLUNA XLi (M)"
  },
  {
    SERIAL: "01BP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HARRIER FOUR 3.0(4WD)",
    label: "HARRIER FOUR 3.0(4WD)",
    value: "HARRIER FOUR 3.0(4WD)"
  },
  {
    SERIAL: "01BQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GAIA",
    label: "GAIA",
    value: "GAIA"
  },
  {
    SERIAL: "01BV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA MINOR CHANGE 1.5 GLI M",
    label: "SOLUNA MINOR CHANGE 1.5 GLI M",
    value: "SOLUNA MINOR CHANGE 1.5 GLI M"
  },
  {
    SERIAL: "01BW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA MINOR CHANGE 1.5 GLI A",
    label: "SOLUNA MINOR CHANGE 1.5 GLI A",
    value: "SOLUNA MINOR CHANGE 1.5 GLI A"
  },
  {
    SERIAL: "01BX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA 1.5 SLI M",
    label: "SOLUNA 1.5 SLI M",
    value: "SOLUNA 1.5 SLI M"
  },
  {
    SERIAL: "01BY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA MINOR CHANGE 1.5 SLI A",
    label: "SOLUNA MINOR CHANGE 1.5 SLI A",
    value: "SOLUNA MINOR CHANGE 1.5 SLI A"
  },
  {
    SERIAL: "01BZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 3.4",
    label: "LAND CRUISER PRADO 3.4",
    value: "LAND CRUISER PRADO 3.4"
  },
  {
    SERIAL: "01CB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA GXI SALOON 1.5",
    label: "COROLLA GXI SALOON 1.5",
    value: "COROLLA GXI SALOON 1.5"
  },
  {
    SERIAL: "01CC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA EXSIOR 2.0 SEG (A)",
    label: "CORONA EXSIOR 2.0 SEG (A)",
    value: "CORONA EXSIOR 2.0 SEG (A)"
  },
  {
    SERIAL: "01CD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN HARDTOP 2.0",
    label: "CROWN HARDTOP 2.0",
    value: "CROWN HARDTOP 2.0"
  },
  {
    SERIAL: "01CE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN HARDTOP 3.0",
    label: "CROWN HARDTOP 3.0",
    value: "CROWN HARDTOP 3.0"
  },
  {
    SERIAL: "01CF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN 3.0 (A) NEW",
    label: "CROWN 3.0 (A) NEW",
    value: "CROWN 3.0 (A) NEW"
  },
  {
    SERIAL: "01CH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRANVIA 3.4 2WD",
    label: "GRANVIA 3.4 2WD",
    value: "GRANVIA 3.4 2WD"
  },
  {
    SERIAL: "01CI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRANVIA 3.4 4WD",
    label: "GRANVIA 3.4 4WD",
    value: "GRANVIA 3.4 4WD"
  },
  {
    SERIAL: "01CJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE GL",
    label: "HIACE GL",
    value: "HIACE GL"
  },
  {
    SERIAL: "01CL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA V-VERSION GLI (A)",
    label: "SOLUNA V-VERSION GLI (A)",
    value: "SOLUNA V-VERSION GLI (A)"
  },
  {
    SERIAL: "01CN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELICA 2.2 A",
    label: "CELICA 2.2 A",
    value: "CELICA 2.2 A"
  },
  {
    SERIAL: "01CO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER 3.0 HIGHROOF",
    label: "COMMUTER 3.0 HIGHROOF",
    value: "COMMUTER 3.0 HIGHROOF"
  },
  {
    SERIAL: "01CR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA EXSIOR 1.6 GXI (A)",
    label: "CORONA EXSIOR 1.6 GXI (A)",
    value: "CORONA EXSIOR 1.6 GXI (A)"
  },
  {
    SERIAL: "01CS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA EXSIOR 2.0 SEG (M)",
    label: "CORONA EXSIOR 2.0 SEG (M)",
    value: "CORONA EXSIOR 2.0 SEG (M)"
  },
  {
    SERIAL: "01CT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN NEW ROYAL SALOON",
    label: "CROWN NEW ROYAL SALOON",
    value: "CROWN NEW ROYAL SALOON"
  },
  {
    SERIAL: "01CV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA V-VERSION GLI A",
    label: "SOLUNA V-VERSION GLI A",
    value: "SOLUNA V-VERSION GLI A"
  },
  {
    SERIAL: "01CW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER G LIMITED 3.0 4WD",
    label: "SPORT RIDER G LIMITED 3.0 4WD",
    value: "SPORT RIDER G LIMITED 3.0 4WD"
  },
  {
    SERIAL: "01CX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER DOUBLECAB 2WD (2.5)",
    label: "TIGER DOUBLECAB 2WD (2.5)",
    value: "TIGER DOUBLECAB 2WD (2.5)"
  },
  {
    SERIAL: "01CY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER DOUBLECAB 4WD (3.0)",
    label: "TIGER DOUBLECAB 4WD (3.0)",
    value: "TIGER DOUBLECAB 4WD (3.0)"
  },
  {
    SERIAL: "01DA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRANVIA 3.4 (2WD) NEW",
    label: "GRANVIA 3.4 (2WD) NEW",
    value: "GRANVIA 3.4 (2WD) NEW"
  },
  {
    SERIAL: "01DB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRANVIA 3.4 (4WD) NEW",
    label: "GRANVIA 3.4 (4WD) NEW",
    value: "GRANVIA 3.4 (4WD) NEW"
  },
  {
    SERIAL: "01DC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELICA 1.8 (A)",
    label: "CELICA 1.8 (A)",
    value: "CELICA 1.8 (A)"
  },
  {
    SERIAL: "01DD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELICA 1.8 (M)",
    label: "CELICA 1.8 (M)",
    value: "CELICA 1.8 (M)"
  },
  {
    SERIAL: "01DE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.6 J (M)",
    label: "COROLLA ALTIS 1.6 J (M)",
    value: "COROLLA ALTIS 1.6 J (M)"
  },
  {
    SERIAL: "01DF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.6 J (A)",
    label: "COROLLA ALTIS 1.6 J (A)",
    value: "COROLLA ALTIS 1.6 J (A)"
  },
  {
    SERIAL: "01DG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.6 E (M)",
    label: "COROLLA ALTIS 1.6 E (M)",
    value: "COROLLA ALTIS 1.6 E (M)"
  },
  {
    SERIAL: "01DH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.6 E (A)",
    label: "COROLLA ALTIS 1.6 E (A)",
    value: "COROLLA ALTIS 1.6 E (A)"
  },
  {
    SERIAL: "01DI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.8 E (A)",
    label: "COROLLA ALTIS 1.8 E (A)",
    value: "COROLLA ALTIS 1.8 E (A)"
  },
  {
    SERIAL: "01DJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.8 G (A)",
    label: "COROLLA ALTIS 1.8 G (A)",
    value: "COROLLA ALTIS 1.8 G (A)"
  },
  {
    SERIAL: "01DK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS LIMO",
    label: "COROLLA ALTIS LIMO",
    value: "COROLLA ALTIS LIMO"
  },
  {
    SERIAL: "01DL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT CRUISER 4D 4WD. 3.0",
    label: "SPORT CRUISER 4D 4WD. 3.0",
    value: "SPORT CRUISER 4D 4WD. 3.0"
  },
  {
    SERIAL: "01DM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT CRUISER 2.5",
    label: "SPORT CRUISER 2.5",
    value: "SPORT CRUISER 2.5"
  },
  {
    SERIAL: "01DN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT CRUISER E 4D 2.5",
    label: "SPORT CRUISER E 4D 2.5",
    value: "SPORT CRUISER E 4D 2.5"
  },
  {
    SERIAL: "01DO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT CRUISER 4D 4WD. 2.5",
    label: "SPORT CRUISER 4D 4WD. 2.5",
    value: "SPORT CRUISER 4D 4WD. 2.5"
  },
  {
    SERIAL: "01DP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER 3.0",
    label: "SPORT RIDER 3.0",
    value: "SPORT RIDER 3.0"
  },
  {
    SERIAL: "01DQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER 2.5",
    label: "SPORT RIDER 2.5",
    value: "SPORT RIDER 2.5"
  },
  {
    SERIAL: "01DR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT CRUISER 4D 2.4",
    label: "SPORT CRUISER 4D 2.4",
    value: "SPORT CRUISER 4D 2.4"
  },
  {
    SERIAL: "01DS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 4WD 2.5 (2KD)",
    label: "TIGER EXTRACAB 4WD 2.5 (2KD)",
    value: "TIGER EXTRACAB 4WD 2.5 (2KD)"
  },
  {
    SERIAL: "01DT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER 2.5",
    label: "TIGER 2.5",
    value: "TIGER 2.5"
  },
  {
    SERIAL: "01DU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 4.0",
    label: "LAND CRUISER PRADO 4.0",
    value: "LAND CRUISER PRADO 4.0"
  },
  {
    SERIAL: "01DV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT CRUISER 4D 3.0",
    label: "SPORT CRUISER 4D 3.0",
    value: "SPORT CRUISER 4D 3.0"
  },
  {
    SERIAL: "01DW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER EXTRACAB 2.5 E",
    label: "TIGER EXTRACAB 2.5 E",
    value: "TIGER EXTRACAB 2.5 E"
  },
  {
    SERIAL: "01DX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 E AUTO (NEW)",
    label: "CAMRY 2.0 E AUTO (NEW)",
    value: "CAMRY 2.0 E AUTO (NEW)"
  },
  {
    SERIAL: "01DY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.4 G AUTO",
    label: "CAMRY 2.4 G AUTO",
    value: "CAMRY 2.4 G AUTO"
  },
  {
    SERIAL: "01DZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.4 Q AUTO",
    label: "CAMRY 2.4 Q AUTO",
    value: "CAMRY 2.4 Q AUTO"
  },
  {
    SERIAL: "01EA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GRAND WAGON",
    label: "GRAND WAGON",
    value: "GRAND WAGON"
  },
  {
    SERIAL: "01EB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU30",
    label: "DYNA BU30",
    value: "DYNA BU30"
  },
  {
    SERIAL: "01EC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "BU90",
    label: "BU90",
    value: "BU90"
  },
  {
    SERIAL: "01ED0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "BU85",
    label: "BU85",
    value: "BU85"
  },
  {
    SERIAL: "01EE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VOXY",
    label: "VOXY",
    value: "VOXY"
  },
  {
    SERIAL: "01EF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "RAV 4 2.0 (4WD)",
    label: "RAV 4 2.0 (4WD)",
    value: "RAV 4 2.0 (4WD)"
  },
  {
    SERIAL: "01EH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELSIOR",
    label: "CELSIOR",
    value: "CELSIOR"
  },
  {
    SERIAL: "01EK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "6000 KG.",
    label: "6000 KG.",
    value: "6000 KG."
  },
  {
    SERIAL: "01EL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 3.0",
    label: "ALPHARD 3.0",
    value: "ALPHARD 3.0"
  },
  {
    SERIAL: "01EM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REGIUS",
    label: "REGIUS",
    value: "REGIUS"
  },
  {
    SERIAL: "01EN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS J (M)",
    label: "SOLUNA VIOS J (M)",
    value: "SOLUNA VIOS J (M)"
  },
  {
    SERIAL: "01EO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS J (A)",
    label: "SOLUNA VIOS J (A)",
    value: "SOLUNA VIOS J (A)"
  },
  {
    SERIAL: "01EP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS E (M)",
    label: "SOLUNA VIOS E (M)",
    value: "SOLUNA VIOS E (M)"
  },
  {
    SERIAL: "01EQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS E (M) AIRBAG ABS",
    label: "SOLUNA VIOS E (M) AIRBAG ABS",
    value: "SOLUNA VIOS E (M) AIRBAG ABS"
  },
  {
    SERIAL: "01ER0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS E (A) AIRBAG ABS",
    label: "SOLUNA VIOS E (A) AIRBAG ABS",
    value: "SOLUNA VIOS E (A) AIRBAG ABS"
  },
  {
    SERIAL: "01ES0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS S (A) AIRBAG ABS",
    label: "SOLUNA VIOS S (A) AIRBAG ABS",
    value: "SOLUNA VIOS S (A) AIRBAG ABS"
  },
  {
    SERIAL: "01ET0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS E (A)",
    label: "SOLUNA VIOS E (A)",
    value: "SOLUNA VIOS E (A)"
  },
  {
    SERIAL: "01EU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX DOUBLECAB 4WD",
    label: "HILUX DOUBLECAB 4WD",
    value: "HILUX DOUBLECAB 4WD"
  },
  {
    SERIAL: "01EW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HARRIER AIR-S 3.0 G(RX300)",
    label: "HARRIER AIR-S 3.0 G(RX300)",
    value: "HARRIER AIR-S 3.0 G(RX300)"
  },
  {
    SERIAL: "01EX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.8 S SPORT (A)",
    label: "COROLLA ALTIS 1.8 S SPORT (A)",
    value: "COROLLA ALTIS 1.8 S SPORT (A)"
  },
  {
    SERIAL: "01EY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS SPORTY",
    label: "SOLUNA VIOS SPORTY",
    value: "SOLUNA VIOS SPORTY"
  },
  {
    SERIAL: "01EZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA SPORTY",
    label: "SOLUNA SPORTY",
    value: "SOLUNA SPORTY"
  },
  {
    SERIAL: "01FA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER 3.0(1KD)",
    label: "SPORT RIDER 3.0(1KD)",
    value: "SPORT RIDER 3.0(1KD)"
  },
  {
    SERIAL: "01FB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "WISH S",
    label: "WISH S",
    value: "WISH S"
  },
  {
    SERIAL: "01FC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "WISH Q",
    label: "WISH Q",
    value: "WISH Q"
  },
  {
    SERIAL: "01FD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "WISH Q LIMITED",
    label: "WISH Q LIMITED",
    value: "WISH Q LIMITED"
  },
  {
    SERIAL: "01FE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT CRUISER GRAND CARRYBOY 4WD",
    label: "SPORT CRUISER GRAND CARRYBOY 4WD",
    value: "SPORT CRUISER GRAND CARRYBOY 4WD"
  },
  {
    SERIAL: "01FF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SPORT RIDER 2.5 S 4WD",
    label: "SPORT RIDER 2.5 S 4WD",
    value: "SPORT RIDER 2.5 S 4WD"
  },
  {
    SERIAL: "01FG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA 12 ตัน (MR032BUM)",
    label: "DYNA 12 ตัน (MR032BUM)",
    value: "DYNA 12 ตัน (MR032BUM)"
  },
  {
    SERIAL: "01FH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 (A)",
    label: "CAMRY 2.0 (A)",
    value: "CAMRY 2.0 (A)"
  },
  {
    SERIAL: "01FI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESTIMA LUCIDA",
    label: "ESTIMA LUCIDA",
    value: "ESTIMA LUCIDA"
  },
  {
    SERIAL: "01FJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HARRIER 2.4",
    label: "HARRIER 2.4",
    value: "HARRIER 2.4"
  },
  {
    SERIAL: "01FK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.3 E (M)",
    label: "AVANZA 1.3 E (M)",
    value: "AVANZA 1.3 E (M)"
  },
  {
    SERIAL: "01FL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.3 E (A)",
    label: "AVANZA 1.3 E (A)",
    value: "AVANZA 1.3 E (A)"
  },
  {
    SERIAL: "01FM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.3 E (A) LIMITED",
    label: "AVANZA 1.3 E (A) LIMITED",
    value: "AVANZA 1.3 E (A) LIMITED"
  },
  {
    SERIAL: "01FN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.3 S (A)",
    label: "AVANZA 1.3 S (A)",
    value: "AVANZA 1.3 S (A)"
  },
  {
    SERIAL: "01FO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.4 Q DVD",
    label: "CAMRY 2.4 Q DVD",
    value: "CAMRY 2.4 Q DVD"
  },
  {
    SERIAL: "01FP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.4 Q DVD NAVIGATOR",
    label: "CAMRY 2.4 Q DVD NAVIGATOR",
    value: "CAMRY 2.4 Q DVD NAVIGATOR"
  },
  {
    SERIAL: "01FQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.4",
    label: "ALPHARD 2.4",
    value: "ALPHARD 2.4"
  },
  {
    SERIAL: "01FR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD ECO 2.5 J",
    label: "VIGO STANDARD ECO 2.5 J",
    value: "VIGO STANDARD ECO 2.5 J"
  },
  {
    SERIAL: "01FS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD 3.0 J",
    label: "VIGO STANDARD 3.0 J",
    value: "VIGO STANDARD 3.0 J"
  },
  {
    SERIAL: "01FT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD 2.5 J",
    label: "VIGO STANDARD 2.5 J",
    value: "VIGO STANDARD 2.5 J"
  },
  {
    SERIAL: "01FU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 2.5 J",
    label: "VIGO EXTRACAB 2.5 J",
    value: "VIGO EXTRACAB 2.5 J"
  },
  {
    SERIAL: "01FV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 2.5 E",
    label: "VIGO EXTRACAB 2.5 E",
    value: "VIGO EXTRACAB 2.5 E"
  },
  {
    SERIAL: "01FW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 2.5 E 4WD",
    label: "VIGO EXTRACAB 2.5 E 4WD",
    value: "VIGO EXTRACAB 2.5 E 4WD"
  },
  {
    SERIAL: "01FX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 3.0 E",
    label: "VIGO EXTRACAB 3.0 E",
    value: "VIGO EXTRACAB 3.0 E"
  },
  {
    SERIAL: "01FY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 3.0 G 4WD",
    label: "VIGO EXTRACAB 3.0 G 4WD",
    value: "VIGO EXTRACAB 3.0 G 4WD"
  },
  {
    SERIAL: "01FZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 2.5 E 4WD",
    label: "VIGO DOUBLECAB 2.5 E 4WD",
    value: "VIGO DOUBLECAB 2.5 E 4WD"
  },
  {
    SERIAL: "01GA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN 2.8",
    label: "CROWN 2.8",
    value: "CROWN 2.8"
  },
  {
    SERIAL: "01GB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.4 HYBRID",
    label: "ALPHARD 2.4 HYBRID",
    value: "ALPHARD 2.4 HYBRID"
  },
  {
    SERIAL: "01GC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "TIGER CAB 2.5 J",
    label: "TIGER CAB 2.5 J",
    value: "TIGER CAB 2.5 J"
  },
  {
    SERIAL: "01GD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "WISH S LIMITED",
    label: "WISH S LIMITED",
    value: "WISH S LIMITED"
  },
  {
    SERIAL: "01GG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.4 G (A)",
    label: "CAMRY 2.4 G (A)",
    value: "CAMRY 2.4 G (A)"
  },
  {
    SERIAL: "01GH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.4 V",
    label: "CAMRY 2.4 V",
    value: "CAMRY 2.4 V"
  },
  {
    SERIAL: "01GI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.4 V DVD&NAVIGATOR",
    label: "CAMRY 2.4 V DVD&NAVIGATOR",
    value: "CAMRY 2.4 V DVD&NAVIGATOR"
  },
  {
    SERIAL: "01GK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 3.0 V 4WD AUTO",
    label: "FORTUNER 3.0 V 4WD AUTO",
    value: "FORTUNER 3.0 V 4WD AUTO"
  },
  {
    SERIAL: "01GL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESTIMA HYBRID",
    label: "ESTIMA HYBRID",
    value: "ESTIMA HYBRID"
  },
  {
    SERIAL: "01GM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 J (M)",
    label: "AVANZA 1.5 J (M)",
    value: "AVANZA 1.5 J (M)"
  },
  {
    SERIAL: "01GN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 E (A)",
    label: "AVANZA 1.5 E (A)",
    value: "AVANZA 1.5 E (A)"
  },
  {
    SERIAL: "01GO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 S (A)",
    label: "AVANZA 1.5 S (A)",
    value: "AVANZA 1.5 S (A)"
  },
  {
    SERIAL: "01GP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 3.5",
    label: "CAMRY 3.5",
    value: "CAMRY 3.5"
  },
  {
    SERIAL: "01GQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN SALOON",
    label: "CROWN SALOON",
    value: "CROWN SALOON"
  },
  {
    SERIAL: "01GT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER 2.5 D4D HIGH ROOF",
    label: "COMMUTER 2.5 D4D HIGH ROOF",
    value: "COMMUTER 2.5 D4D HIGH ROOF"
  },
  {
    SERIAL: "01GU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "01GV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESTIMA 2.4 G.AUTO",
    label: "ESTIMA 2.4 G.AUTO",
    value: "ESTIMA 2.4 G.AUTO"
  },
  {
    SERIAL: "01GW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 2.5 G",
    label: "VIGO EXTRACAB 2.5 G",
    value: "VIGO EXTRACAB 2.5 G"
  },
  {
    SERIAL: "01GX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.J (M)",
    label: "SOLUNA VIOS.J (M)",
    value: "SOLUNA VIOS.J (M)"
  },
  {
    SERIAL: "01GZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.J (A)",
    label: "SOLUNA VIOS.J (A)",
    value: "SOLUNA VIOS.J (A)"
  },
  {
    SERIAL: "01HA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.E (M)",
    label: "SOLUNA VIOS.E (M)",
    value: "SOLUNA VIOS.E (M)"
  },
  {
    SERIAL: "01HB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.E (A)",
    label: "SOLUNA VIOS.E (A)",
    value: "SOLUNA VIOS.E (A)"
  },
  {
    SERIAL: "01HC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.G (A)",
    label: "SOLUNA VIOS.G (A)",
    value: "SOLUNA VIOS.G (A)"
  },
  {
    SERIAL: "01HD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO BUILT",
    label: "VIGO BUILT",
    value: "VIGO BUILT"
  },
  {
    SERIAL: "01HE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA (EE80)",
    label: "COROLLA (EE80)",
    value: "COROLLA (EE80)"
  },
  {
    SERIAL: "01HF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 3.0 G",
    label: "VIGO EXTRACAB 3.0 G",
    value: "VIGO EXTRACAB 3.0 G"
  },
  {
    SERIAL: "01HG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.7 (4*2) BENZINE",
    label: "FORTUNER 2.7 (4*2) BENZINE",
    value: "FORTUNER 2.7 (4*2) BENZINE"
  },
  {
    SERIAL: "01HH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER SMART 3.0 A",
    label: "FORTUNER SMART 3.0 A",
    value: "FORTUNER SMART 3.0 A"
  },
  {
    SERIAL: "01HL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VENTURY MAJESTY",
    label: "VENTURY MAJESTY",
    value: "VENTURY MAJESTY"
  },
  {
    SERIAL: "01HN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA 2.0 V BENZINE",
    label: "INNOVA 2.0 V BENZINE",
    value: "INNOVA 2.0 V BENZINE"
  },
  {
    SERIAL: "01HO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE ECONOMIC 2.5",
    label: "HIACE ECONOMIC 2.5",
    value: "HIACE ECONOMIC 2.5"
  },
  {
    SERIAL: "01HQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS S LIMITED",
    label: "YARIS S LIMITED",
    value: "YARIS S LIMITED"
  },
  {
    SERIAL: "01HR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS G LIMITED",
    label: "YARIS G LIMITED",
    value: "YARIS G LIMITED"
  },
  {
    SERIAL: "01HS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS E LIMITED",
    label: "YARIS E LIMITED",
    value: "YARIS E LIMITED"
  },
  {
    SERIAL: "01HT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 3.0 TRD SPORTIVO",
    label: "FORTUNER 3.0 TRD SPORTIVO",
    value: "FORTUNER 3.0 TRD SPORTIVO"
  },
  {
    SERIAL: "01HU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY HYBRID 2.4 CD",
    label: "CAMRY HYBRID 2.4 CD",
    value: "CAMRY HYBRID 2.4 CD"
  },
  {
    SERIAL: "01HV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY HYBRID 2.4 DVD",
    label: "CAMRY HYBRID 2.4 DVD",
    value: "CAMRY HYBRID 2.4 DVD"
  },
  {
    SERIAL: "01HW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY HYBRID NAVIGATOR 2.4",
    label: "CAMRY HYBRID NAVIGATOR 2.4",
    value: "CAMRY HYBRID NAVIGATOR 2.4"
  },
  {
    SERIAL: "01HX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB PRERUNNER 2.5 E",
    label: "VIGO SMARTCAB PRERUNNER 2.5 E",
    value: "VIGO SMARTCAB PRERUNNER 2.5 E"
  },
  {
    SERIAL: "01HY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB PRERUNNER 2.5 E ABS",
    label: "VIGO SMARTCAB PRERUNNER 2.5 E ABS",
    value: "VIGO SMARTCAB PRERUNNER 2.5 E ABS"
  },
  {
    SERIAL: "01HZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 2.5 E A",
    label: "VIGO DOUBLECAB PRERUNNER 2.5 E A",
    value: "VIGO DOUBLECAB PRERUNNER 2.5 E A"
  },
  {
    SERIAL: "01IA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 2.5 J",
    label: "VIGO DOUBLECAB 2.5 J",
    value: "VIGO DOUBLECAB 2.5 J"
  },
  {
    SERIAL: "01IB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 2.5 E",
    label: "VIGO DOUBLECAB 2.5 E",
    value: "VIGO DOUBLECAB 2.5 E"
  },
  {
    SERIAL: "01IC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 3.0 G",
    label: "VIGO DOUBLECAB 3.0 G",
    value: "VIGO DOUBLECAB 3.0 G"
  },
  {
    SERIAL: "01ID0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 3.0 E 4WD",
    label: "VIGO DOUBLECAB 3.0 E 4WD",
    value: "VIGO DOUBLECAB 3.0 E 4WD"
  },
  {
    SERIAL: "01IE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 3.0 G 4WD",
    label: "VIGO DOUBLECAB 3.0 G 4WD",
    value: "VIGO DOUBLECAB 3.0 G 4WD"
  },
  {
    SERIAL: "01IF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "STATION WAGON",
    label: "STATION WAGON",
    value: "STATION WAGON"
  },
  {
    SERIAL: "01IG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA 1.6 (AT171)",
    label: "CORONA 1.6 (AT171)",
    value: "CORONA 1.6 (AT171)"
  },
  {
    SERIAL: "01IH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA 2.0 E BENZIN",
    label: "INNOVA 2.0 E BENZIN",
    value: "INNOVA 2.0 E BENZIN"
  },
  {
    SERIAL: "01II0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA 2.0 G BENZIN",
    label: "INNOVA 2.0 G BENZIN",
    value: "INNOVA 2.0 G BENZIN"
  },
  {
    SERIAL: "01IJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA 2.5 G DIESEL",
    label: "INNOVA 2.5 G DIESEL",
    value: "INNOVA 2.5 G DIESEL"
  },
  {
    SERIAL: "01IK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA 2.5 V DIESEL",
    label: "INNOVA 2.5 V DIESEL",
    value: "INNOVA 2.5 V DIESEL"
  },
  {
    SERIAL: "01IL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.7 V BENZINE 4*4",
    label: "FORTUNER 2.7 V BENZINE 4*4",
    value: "FORTUNER 2.7 V BENZINE 4*4"
  },
  {
    SERIAL: "01IM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 3.0 G DIESEL M",
    label: "FORTUNER 3.0 G DIESEL M",
    value: "FORTUNER 3.0 G DIESEL M"
  },
  {
    SERIAL: "01IN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 3.0 V 2WD AUTO (DIESEL)",
    label: "FORTUNER 3.0 V 2WD AUTO (DIESEL)",
    value: "FORTUNER 3.0 V 2WD AUTO (DIESEL)"
  },
  {
    SERIAL: "01IO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA DX 1.5",
    label: "COROLLA DX 1.5",
    value: "COROLLA DX 1.5"
  },
  {
    SERIAL: "01IP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 3.0",
    label: "LAND CRUISER PRADO 3.0",
    value: "LAND CRUISER PRADO 3.0"
  },
  {
    SERIAL: "01IR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LITEACE",
    label: "LITEACE",
    value: "LITEACE"
  },
  {
    SERIAL: "01IS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX HERO",
    label: "HILUX HERO",
    value: "HILUX HERO"
  },
  {
    SERIAL: "01IT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA 2.0",
    label: "CORONA 2.0",
    value: "CORONA 2.0"
  },
  {
    SERIAL: "01IU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX VAN",
    label: "HILUX VAN",
    value: "HILUX VAN"
  },
  {
    SERIAL: "01IV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "STATION WAGON",
    label: "STATION WAGON",
    value: "STATION WAGON"
  },
  {
    SERIAL: "01IW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER",
    label: "LAND CRUISER",
    value: "LAND CRUISER"
  },
  {
    SERIAL: "01IX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU61",
    label: "DYNA BU61",
    value: "DYNA BU61"
  },
  {
    SERIAL: "01IY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA",
    label: "DYNA",
    value: "DYNA"
  },
  {
    SERIAL: "01IZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER STANDARD ROOF (Y.2005)",
    label: "COMMUTER STANDARD ROOF (Y.2005)",
    value: "COMMUTER STANDARD ROOF (Y.2005)"
  },
  {
    SERIAL: "01JA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE GL",
    label: "HIACE GL",
    value: "HIACE GL"
  },
  {
    SERIAL: "01JB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 2.7 G",
    label: "VIGO EXTRACAB 2.7 G",
    value: "VIGO EXTRACAB 2.7 G"
  },
  {
    SERIAL: "01JC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 4*4 BENZIN 2.7",
    label: "VIGO EXTRACAB 4*4 BENZIN 2.7",
    value: "VIGO EXTRACAB 4*4 BENZIN 2.7"
  },
  {
    SERIAL: "01JD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 4WD BENZIN 2.7",
    label: "VIGO DOUBLECAB 4WD BENZIN 2.7",
    value: "VIGO DOUBLECAB 4WD BENZIN 2.7"
  },
  {
    SERIAL: "01JE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CORONA",
    label: "CORONA",
    value: "CORONA"
  },
  {
    SERIAL: "01JF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER(UNICEF)",
    label: "LAND CRUISER(UNICEF)",
    value: "LAND CRUISER(UNICEF)"
  },
  {
    SERIAL: "01JG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU30",
    label: "DYNA BU30",
    value: "DYNA BU30"
  },
  {
    SERIAL: "01JH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX DOUBLECAB",
    label: "HILUX DOUBLECAB",
    value: "HILUX DOUBLECAB"
  },
  {
    SERIAL: "01JK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VENTURY 2.7 G (ตู้)",
    label: "VENTURY 2.7 G (ตู้)",
    value: "VENTURY 2.7 G (ตู้)"
  },
  {
    SERIAL: "01JL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VENTURY 2.7 V (ตู้)",
    label: "VENTURY 2.7 V (ตู้)",
    value: "VENTURY 2.7 V (ตู้)"
  },
  {
    SERIAL: "01JM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA MINOR CHANGE 1.5 SLI",
    label: "SOLUNA MINOR CHANGE 1.5 SLI",
    value: "SOLUNA MINOR CHANGE 1.5 SLI"
  },
  {
    SERIAL: "01JN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB PRERUNNER",
    label: "VIGO EXTRACAB PRERUNNER",
    value: "VIGO EXTRACAB PRERUNNER"
  },
  {
    SERIAL: "01JO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER",
    label: "VIGO DOUBLECAB PRERUNNER",
    value: "VIGO DOUBLECAB PRERUNNER"
  },
  {
    SERIAL: "01JP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 2.7",
    label: "LAND CRUISER PRADO 2.7",
    value: "LAND CRUISER PRADO 2.7"
  },
  {
    SERIAL: "01JQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REGIUS",
    label: "REGIUS",
    value: "REGIUS"
  },
  {
    SERIAL: "01JS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CELICA",
    label: "CELICA",
    value: "CELICA"
  },
  {
    SERIAL: "01JT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "RAV4",
    label: "RAV4",
    value: "RAV4"
  },
  {
    SERIAL: "01JU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "STARLET",
    label: "STARLET",
    value: "STARLET"
  },
  {
    SERIAL: "01JV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS E",
    label: "YARIS E",
    value: "YARIS E"
  },
  {
    SERIAL: "01JW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS G",
    label: "YARIS G",
    value: "YARIS G"
  },
  {
    SERIAL: "01JX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS S",
    label: "YARIS S",
    value: "YARIS S"
  },
  {
    SERIAL: "01JY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 G",
    label: "CAMRY 2.0 G",
    value: "CAMRY 2.0 G"
  },
  {
    SERIAL: "01JZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.6 (G)",
    label: "COROLLA ALTIS 1.6 (G)",
    value: "COROLLA ALTIS 1.6 (G)"
  },
  {
    SERIAL: "01KB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE GL (นำเข้าอิสระ)",
    label: "HIACE GL (นำเข้าอิสระ)",
    value: "HIACE GL (นำเข้าอิสระ)"
  },
  {
    SERIAL: "01KC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 2.5 G D4D",
    label: "VIGO DOUBLECAB 2.5 G D4D",
    value: "VIGO DOUBLECAB 2.5 G D4D"
  },
  {
    SERIAL: "01KD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS J 1.5 A",
    label: "YARIS J 1.5 A",
    value: "YARIS J 1.5 A"
  },
  {
    SERIAL: "01KE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS J 1.5 M",
    label: "YARIS J 1.5 M",
    value: "YARIS J 1.5 M"
  },
  {
    SERIAL: "01KF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 J (M)",
    label: "COROLLA ALTIS.1.6 J (M)",
    value: "COROLLA ALTIS.1.6 J (M)"
  },
  {
    SERIAL: "01KG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 J (A)",
    label: "COROLLA ALTIS.1.6 J (A)",
    value: "COROLLA ALTIS.1.6 J (A)"
  },
  {
    SERIAL: "01KH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 E (M)",
    label: "COROLLA ALTIS.1.6 E (M)",
    value: "COROLLA ALTIS.1.6 E (M)"
  },
  {
    SERIAL: "01KI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 E (A)",
    label: "COROLLA ALTIS.1.6 E (A)",
    value: "COROLLA ALTIS.1.6 E (A)"
  },
  {
    SERIAL: "01KJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 G (A)",
    label: "COROLLA ALTIS.1.6 G (A)",
    value: "COROLLA ALTIS.1.6 G (A)"
  },
  {
    SERIAL: "01KK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 E (A)",
    label: "COROLLA ALTIS.1.8 E (A)",
    value: "COROLLA ALTIS.1.8 E (A)"
  },
  {
    SERIAL: "01KL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 G (A)",
    label: "COROLLA ALTIS.1.8 G (A)",
    value: "COROLLA ALTIS.1.8 G (A)"
  },
  {
    SERIAL: "01KM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER AX200",
    label: "LAND CRUISER AX200",
    value: "LAND CRUISER AX200"
  },
  {
    SERIAL: "01KN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.S LIMITED",
    label: "SOLUNA VIOS.S LIMITED",
    value: "SOLUNA VIOS.S LIMITED"
  },
  {
    SERIAL: "01KO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.G LIMITED",
    label: "SOLUNA VIOS.G LIMITED",
    value: "SOLUNA VIOS.G LIMITED"
  },
  {
    SERIAL: "01KP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "MRS",
    label: "MRS",
    value: "MRS"
  },
  {
    SERIAL: "01KQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS TRD SPORTIVO AUTO",
    label: "YARIS TRD SPORTIVO AUTO",
    value: "YARIS TRD SPORTIVO AUTO"
  },
  {
    SERIAL: "01KR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "PORTE",
    label: "PORTE",
    value: "PORTE"
  },
  {
    SERIAL: "01KS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS.ES A",
    label: "SOLUNA VIOS.ES A",
    value: "SOLUNA VIOS.ES A"
  },
  {
    SERIAL: "01KT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HARRIER 2.4",
    label: "HARRIER 2.4",
    value: "HARRIER 2.4"
  },
  {
    SERIAL: "01KU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD 2.5 J POWER",
    label: "VIGO STANDARD 2.5 J POWER",
    value: "VIGO STANDARD 2.5 J POWER"
  },
  {
    SERIAL: "01KV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO EXTRACAB 2.5 J POWER",
    label: "VIGO EXTRACAB 2.5 J POWER",
    value: "VIGO EXTRACAB 2.5 J POWER"
  },
  {
    SERIAL: "01KW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 2.5 E",
    label: "VIGO SMARTCAB 2.5 E",
    value: "VIGO SMARTCAB 2.5 E"
  },
  {
    SERIAL: "01KX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 2.5 G",
    label: "VIGO SMARTCAB 2.5 G",
    value: "VIGO SMARTCAB 2.5 G"
  },
  {
    SERIAL: "01KY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 2.7 G AUTO",
    label: "VIGO SMARTCAB 2.7 G AUTO",
    value: "VIGO SMARTCAB 2.7 G AUTO"
  },
  {
    SERIAL: "01KZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 3.0 E",
    label: "VIGO SMARTCAB 3.0 E",
    value: "VIGO SMARTCAB 3.0 E"
  },
  {
    SERIAL: "01LA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 3.0 G",
    label: "VIGO SMARTCAB 3.0 G",
    value: "VIGO SMARTCAB 3.0 G"
  },
  {
    SERIAL: "01LB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB PRERUNNER 3.0 G",
    label: "VIGO SMARTCAB PRERUNNER 3.0 G",
    value: "VIGO SMARTCAB PRERUNNER 3.0 G"
  },
  {
    SERIAL: "01LC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB PRERUNNER 3.0 E ABS",
    label: "VIGO SMARTCAB PRERUNNER 3.0 E ABS",
    value: "VIGO SMARTCAB PRERUNNER 3.0 E ABS"
  },
  {
    SERIAL: "01LD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 2.5 E 4WD",
    label: "VIGO SMARTCAB 2.5 E 4WD",
    value: "VIGO SMARTCAB 2.5 E 4WD"
  },
  {
    SERIAL: "01LE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 3.0 E 4WD",
    label: "VIGO SMARTCAB 3.0 E 4WD",
    value: "VIGO SMARTCAB 3.0 E 4WD"
  },
  {
    SERIAL: "01LF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 3.0 G 4WD",
    label: "VIGO SMARTCAB 3.0 G 4WD",
    value: "VIGO SMARTCAB 3.0 G 4WD"
  },
  {
    SERIAL: "01LG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 2.5 G",
    label: "VIGO DOUBLECAB 2.5 G",
    value: "VIGO DOUBLECAB 2.5 G"
  },
  {
    SERIAL: "01LH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 3.0 G AUTO",
    label: "VIGO DOUBLECAB 3.0 G AUTO",
    value: "VIGO DOUBLECAB 3.0 G AUTO"
  },
  {
    SERIAL: "01LI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 3.0 E",
    label: "VIGO DOUBLECAB PRERUNNER 3.0 E",
    value: "VIGO DOUBLECAB PRERUNNER 3.0 E"
  },
  {
    SERIAL: "01LJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 3.0 E ABS",
    label: "VIGO DOUBLECAB 3.0 E ABS",
    value: "VIGO DOUBLECAB 3.0 E ABS"
  },
  {
    SERIAL: "01LK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB 3.0 G AUTO 4WD",
    label: "VIGO DOUBLECAB 3.0 G AUTO 4WD",
    value: "VIGO DOUBLECAB 3.0 G AUTO 4WD"
  },
  {
    SERIAL: "01LL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VANGARD 2.4",
    label: "VANGARD 2.4",
    value: "VANGARD 2.4"
  },
  {
    SERIAL: "01LM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 2.5 E M",
    label: "VIGO DOUBLECAB PRERUNNER 2.5 E M",
    value: "VIGO DOUBLECAB PRERUNNER 2.5 E M"
  },
  {
    SERIAL: "01LN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.LIMO CNG 1.6 M",
    label: "COROLLA ALTIS.LIMO CNG 1.6 M",
    value: "COROLLA ALTIS.LIMO CNG 1.6 M"
  },
  {
    SERIAL: "01LO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "BB 1.5",
    label: "BB 1.5",
    value: "BB 1.5"
  },
  {
    SERIAL: "01LP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 3.5 V.",
    label: "ALPHARD 3.5 V.",
    value: "ALPHARD 3.5 V."
  },
  {
    SERIAL: "01LQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 3.5 Q",
    label: "ALPHARD 3.5 Q",
    value: "ALPHARD 3.5 Q"
  },
  {
    SERIAL: "01LR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA (BUILT)",
    label: "DYNA (BUILT)",
    value: "DYNA (BUILT)"
  },
  {
    SERIAL: "01LS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE 2.4",
    label: "VELLFIRE 2.4",
    value: "VELLFIRE 2.4"
  },
  {
    SERIAL: "01LT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU61",
    label: "DYNA BU61",
    value: "DYNA BU61"
  },
  {
    SERIAL: "01LU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.2.0 G",
    label: "COROLLA ALTIS.2.0 G",
    value: "COROLLA ALTIS.2.0 G"
  },
  {
    SERIAL: "01LV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.2.0 V",
    label: "COROLLA ALTIS.2.0 V",
    value: "COROLLA ALTIS.2.0 V"
  },
  {
    SERIAL: "01LW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.2.0 NAVIGATOR",
    label: "COROLLA ALTIS.2.0 NAVIGATOR",
    value: "COROLLA ALTIS.2.0 NAVIGATOR"
  },
  {
    SERIAL: "01LX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 SS",
    label: "COROLLA ALTIS.1.6 SS",
    value: "COROLLA ALTIS.1.6 SS"
  },
  {
    SERIAL: "01LY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "BY87 (BUILT)",
    label: "BY87 (BUILT)",
    value: "BY87 (BUILT)"
  },
  {
    SERIAL: "01LZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "RZY220 (รถประกอบ)",
    label: "RZY220 (รถประกอบ)",
    value: "RZY220 (รถประกอบ)"
  },
  {
    SERIAL: "01MA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 G EXTREMO A",
    label: "CAMRY 2.0 G EXTREMO A",
    value: "CAMRY 2.0 G EXTREMO A"
  },
  {
    SERIAL: "01MB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DA115",
    label: "DA115",
    value: "DA115"
  },
  {
    SERIAL: "01MC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB E BENZIN 2.7",
    label: "VIGO DOUBLECAB E BENZIN 2.7",
    value: "VIGO DOUBLECAB E BENZIN 2.7"
  },
  {
    SERIAL: "01MD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.5 G(MT)",
    label: "FORTUNER 2.5 G(MT)",
    value: "FORTUNER 2.5 G(MT)"
  },
  {
    SERIAL: "01ME0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN 2.5",
    label: "CROWN 2.5",
    value: "CROWN 2.5"
  },
  {
    SERIAL: "01MF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS. 1.6 CNG A",
    label: "COROLLA ALTIS. 1.6 CNG A",
    value: "COROLLA ALTIS. 1.6 CNG A"
  },
  {
    SERIAL: "01MG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AXIO X 1.5",
    label: "AXIO X 1.5",
    value: "AXIO X 1.5"
  },
  {
    SERIAL: "01MH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LANDCRUISER PRADO TZ 3.4",
    label: "LANDCRUISER PRADO TZ 3.4",
    value: "LANDCRUISER PRADO TZ 3.4"
  },
  {
    SERIAL: "01MI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE 3.5",
    label: "VELLFIRE 3.5",
    value: "VELLFIRE 3.5"
  },
  {
    SERIAL: "01MK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YY211 (BUILT)",
    label: "YY211 (BUILT)",
    value: "YY211 (BUILT)"
  },
  {
    SERIAL: "01ML0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "PRIUS",
    label: "PRIUS",
    value: "PRIUS"
  },
  {
    SERIAL: "01MM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 2.7",
    label: "LAND CRUISER PRADO 2.7",
    value: "LAND CRUISER PRADO 2.7"
  },
  {
    SERIAL: "01MN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 3.0",
    label: "LAND CRUISER PRADO 3.0",
    value: "LAND CRUISER PRADO 3.0"
  },
  {
    SERIAL: "01MO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "KLUGER 3.0",
    label: "KLUGER 3.0",
    value: "KLUGER 3.0"
  },
  {
    SERIAL: "01MP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER VX200 DIESEL 4.5",
    label: "LAND CRUISER VX200 DIESEL 4.5",
    value: "LAND CRUISER VX200 DIESEL 4.5"
  },
  {
    SERIAL: "01MQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER VX200 BENZINE 4.6",
    label: "LAND CRUISER VX200 BENZINE 4.6",
    value: "LAND CRUISER VX200 BENZINE 4.6"
  },
  {
    SERIAL: "01MR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD 2.7 J",
    label: "VIGO STANDARD 2.7 J",
    value: "VIGO STANDARD 2.7 J"
  },
  {
    SERIAL: "01MS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD 3.0 J (Y.2010)",
    label: "VIGO STANDARD 3.0 J (Y.2010)",
    value: "VIGO STANDARD 3.0 J (Y.2010)"
  },
  {
    SERIAL: "01MT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS. 1.6 CNG M",
    label: "COROLLA ALTIS. 1.6 CNG M",
    value: "COROLLA ALTIS. 1.6 CNG M"
  },
  {
    SERIAL: "01MU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "PRIUS 1.8L STANDARD",
    label: "PRIUS 1.8L STANDARD",
    value: "PRIUS 1.8L STANDARD"
  },
  {
    SERIAL: "01MV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "PRIUS 1.8L TOP",
    label: "PRIUS 1.8L TOP",
    value: "PRIUS 1.8L TOP"
  },
  {
    SERIAL: "01MW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "PRIUS 1.8L TRD SPORTIVO",
    label: "PRIUS 1.8L TRD SPORTIVO",
    value: "PRIUS 1.8L TRD SPORTIVO"
  },
  {
    SERIAL: "01MX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 2.5 J",
    label: "VIGO SMARTCAB 2.5 J",
    value: "VIGO SMARTCAB 2.5 J"
  },
  {
    SERIAL: "01MY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE ECONOMY 2.5",
    label: "HIACE ECONOMY 2.5",
    value: "HIACE ECONOMY 2.5"
  },
  {
    SERIAL: "01MZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA 2.0 G EXCLUSIVE",
    label: "INNOVA 2.0 G EXCLUSIVE",
    value: "INNOVA 2.0 G EXCLUSIVE"
  },
  {
    SERIAL: "01NA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE 2.4 Z PLATINUM AUTO",
    label: "VELLFIRE 2.4 Z PLATINUM AUTO",
    value: "VELLFIRE 2.4 Z PLATINUM AUTO"
  },
  {
    SERIAL: "01NB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 3.0 (ประมูล)",
    label: "ALPHARD 3.0 (ประมูล)",
    value: "ALPHARD 3.0 (ประมูล)"
  },
  {
    SERIAL: "01ND0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "NOAH",
    label: "NOAH",
    value: "NOAH"
  },
  {
    SERIAL: "01NE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "XZU307 (BUILT)",
    label: "XZU307 (BUILT)",
    value: "XZU307 (BUILT)"
  },
  {
    SERIAL: "01NF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER (CBU)",
    label: "COMMUTER (CBU)",
    value: "COMMUTER (CBU)"
  },
  {
    SERIAL: "01NG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FJ CRUISER 4.0",
    label: "FJ CRUISER 4.0",
    value: "FJ CRUISER 4.0"
  },
  {
    SERIAL: "01NH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. SPORTIVO",
    label: "SOLUNA VIOS. SPORTIVO",
    value: "SOLUNA VIOS. SPORTIVO"
  },
  {
    SERIAL: "01NI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LANDCRUISER 200 ZX 4.7",
    label: "LANDCRUISER 200 ZX 4.7",
    value: "LANDCRUISER 200 ZX 4.7"
  },
  {
    SERIAL: "01NJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA DL",
    label: "COROLLA DL",
    value: "COROLLA DL"
  },
  {
    SERIAL: "01NK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALTEZZA",
    label: "ALTEZZA",
    value: "ALTEZZA"
  },
  {
    SERIAL: "01NL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 3.0 G A NAVIGA TOR",
    label: "VIGO DOUBLECAB PRERUNNER 3.0 G A NAVIGA TOR",
    value: "VIGO DOUBLECAB PRERUNNER 3.0 G A NAVIGA TOR"
  },
  {
    SERIAL: "01NM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 3.4",
    label: "LAND CRUISER PRADO 3.4",
    value: "LAND CRUISER PRADO 3.4"
  },
  {
    SERIAL: "01NN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER 2.7 CNG (CBU)",
    label: "COMMUTER 2.7 CNG (CBU)",
    value: "COMMUTER 2.7 CNG (CBU)"
  },
  {
    SERIAL: "01NO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LANDCRUISER XJ 4.0",
    label: "LANDCRUISER XJ 4.0",
    value: "LANDCRUISER XJ 4.0"
  },
  {
    SERIAL: "01NP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD CAB 2.7",
    label: "VIGO STANDARD CAB 2.7",
    value: "VIGO STANDARD CAB 2.7"
  },
  {
    SERIAL: "01NQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VITZ",
    label: "VITZ",
    value: "VITZ"
  },
  {
    SERIAL: "01NR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 G (Y.2012)",
    label: "CAMRY 2.5 G (Y.2012)",
    value: "CAMRY 2.5 G (Y.2012)"
  },
  {
    SERIAL: "01NS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE HYBRID 2.4",
    label: "VELLFIRE HYBRID 2.4",
    value: "VELLFIRE HYBRID 2.4"
  },
  {
    SERIAL: "01NT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.7",
    label: "FORTUNER 2.7",
    value: "FORTUNER 2.7"
  },
  {
    SERIAL: "01NU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER AX200 4.5 DIESEL",
    label: "LAND CRUISER AX200 4.5 DIESEL",
    value: "LAND CRUISER AX200 4.5 DIESEL"
  },
  {
    SERIAL: "01NV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 G",
    label: "CAMRY 2.0 G",
    value: "CAMRY 2.0 G"
  },
  {
    SERIAL: "01NW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY HYBRID 2.5 V CD",
    label: "CAMRY HYBRID 2.5 V CD",
    value: "CAMRY HYBRID 2.5 V CD"
  },
  {
    SERIAL: "01NX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY HYBRID 2.5 L NAVIGATOR",
    label: "CAMRY HYBRID 2.5 L NAVIGATOR",
    value: "CAMRY HYBRID 2.5 L NAVIGATOR"
  },
  {
    SERIAL: "01NY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB CHAMP 3.0 4WD",
    label: "VIGO DOUBLECAB CHAMP 3.0 4WD",
    value: "VIGO DOUBLECAB CHAMP 3.0 4WD"
  },
  {
    SERIAL: "01NZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FT-86 2.0 HIGH A",
    label: "FT-86 2.0 HIGH A",
    value: "FT-86 2.0 HIGH A"
  },
  {
    SERIAL: "01OA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "RAV4",
    label: "RAV4",
    value: "RAV4"
  },
  {
    SERIAL: "01OB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO STANDARD CNG 2.7",
    label: "VIGO STANDARD CNG 2.7",
    value: "VIGO STANDARD CNG 2.7"
  },
  {
    SERIAL: "01OC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB CNG 2.7",
    label: "VIGO SMARTCAB CNG 2.7",
    value: "VIGO SMARTCAB CNG 2.7"
  },
  {
    SERIAL: "01OD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 G",
    label: "AVANZA 1.5 G",
    value: "AVANZA 1.5 G"
  },
  {
    SERIAL: "01OF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "GT86 2.0",
    label: "GT86 2.0",
    value: "GT86 2.0"
  },
  {
    SERIAL: "01OG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. J",
    label: "SOLUNA VIOS. J",
    value: "SOLUNA VIOS. J"
  },
  {
    SERIAL: "01OH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "XZU421 (BUILT)",
    label: "XZU421 (BUILT)",
    value: "XZU421 (BUILT)"
  },
  {
    SERIAL: "01OI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 2.7 J",
    label: "VIGO SMARTCAB 2.7 J",
    value: "VIGO SMARTCAB 2.7 J"
  },
  {
    SERIAL: "01OJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "IQ 1.0",
    label: "IQ 1.0",
    value: "IQ 1.0"
  },
  {
    SERIAL: "01OK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 3.0 TRDSPORTIVO V 4WD.",
    label: "FORTUNER 3.0 TRDSPORTIVO V 4WD.",
    value: "FORTUNER 3.0 TRDSPORTIVO V 4WD."
  },
  {
    SERIAL: "01OL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FT86 2.0 STD M",
    label: "FT86 2.0 STD M",
    value: "FT86 2.0 STD M"
  },
  {
    SERIAL: "01OM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FT86 2.0 STD A",
    label: "FT86 2.0 STD A",
    value: "FT86 2.0 STD A"
  },
  {
    SERIAL: "01ON0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.5 G(AT) 2WD.",
    label: "FORTUNER 2.5 G(AT) 2WD.",
    value: "FORTUNER 2.5 G(AT) 2WD."
  },
  {
    SERIAL: "01OO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DYNA BU141",
    label: "DYNA BU141",
    value: "DYNA BU141"
  },
  {
    SERIAL: "01OP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LY230",
    label: "LY230",
    value: "LY230"
  },
  {
    SERIAL: "01OQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS RS",
    label: "YARIS RS",
    value: "YARIS RS"
  },
  {
    SERIAL: "01OR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "BZU300",
    label: "BZU300",
    value: "BZU300"
  },
  {
    SERIAL: "01OS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. S",
    label: "SOLUNA VIOS. S",
    value: "SOLUNA VIOS. S"
  },
  {
    SERIAL: "01OT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. G",
    label: "SOLUNA VIOS. G",
    value: "SOLUNA VIOS. G"
  },
  {
    SERIAL: "01OU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. E AUTO",
    label: "SOLUNA VIOS. E AUTO",
    value: "SOLUNA VIOS. E AUTO"
  },
  {
    SERIAL: "01OV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. E M",
    label: "SOLUNA VIOS. E M",
    value: "SOLUNA VIOS. E M"
  },
  {
    SERIAL: "01OW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. J AUTO",
    label: "SOLUNA VIOS. J AUTO",
    value: "SOLUNA VIOS. J AUTO"
  },
  {
    SERIAL: "01OX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. J M",
    label: "SOLUNA VIOS. J M",
    value: "SOLUNA VIOS. J M"
  },
  {
    SERIAL: "01OY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE 2.4 Z GS",
    label: "VELLFIRE 2.4 Z GS",
    value: "VELLFIRE 2.4 Z GS"
  },
  {
    SERIAL: "01OZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 TRD",
    label: "COROLLA ALTIS.1.8 TRD",
    value: "COROLLA ALTIS.1.8 TRD"
  },
  {
    SERIAL: "01PA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER 3.0 HIGH ROOF",
    label: "COMMUTER 3.0 HIGH ROOF",
    value: "COMMUTER 3.0 HIGH ROOF"
  },
  {
    SERIAL: "01PB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 J ECO AUTO",
    label: "YARIS 1.2 J ECO AUTO",
    value: "YARIS 1.2 J ECO AUTO"
  },
  {
    SERIAL: "01PC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 J AUTO",
    label: "YARIS 1.2 J AUTO",
    value: "YARIS 1.2 J AUTO"
  },
  {
    SERIAL: "01PD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 E AUTO",
    label: "YARIS 1.2 E AUTO",
    value: "YARIS 1.2 E AUTO"
  },
  {
    SERIAL: "01PE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 G AUTO",
    label: "YARIS 1.2 G AUTO",
    value: "YARIS 1.2 G AUTO"
  },
  {
    SERIAL: "01PF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 2.5 E M ABS",
    label: "VIGO DOUBLECAB PRERUNNER 2.5 E M ABS",
    value: "VIGO DOUBLECAB PRERUNNER 2.5 E M ABS"
  },
  {
    SERIAL: "01PG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO LC5 3.0",
    label: "LAND CRUISER PRADO LC5 3.0",
    value: "LAND CRUISER PRADO LC5 3.0"
  },
  {
    SERIAL: "01PH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 G EXTREMO (Y.2013)",
    label: "CAMRY 2.0 G EXTREMO (Y.2013)",
    value: "CAMRY 2.0 G EXTREMO (Y.2013)"
  },
  {
    SERIAL: "01PI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY HYBRID 2.5 V DVD",
    label: "CAMRY HYBRID 2.5 V DVD",
    value: "CAMRY HYBRID 2.5 V DVD"
  },
  {
    SERIAL: "01PJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 2.5 G M",
    label: "VIGO DOUBLECAB PRERUNNER 2.5 G M",
    value: "VIGO DOUBLECAB PRERUNNER 2.5 G M"
  },
  {
    SERIAL: "01PK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 3.0 G M",
    label: "VIGO DOUBLECAB PRERUNNER 3.0 G M",
    value: "VIGO DOUBLECAB PRERUNNER 3.0 G M"
  },
  {
    SERIAL: "01PL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB PRERUNNER 2.5 G",
    label: "VIGO SMARTCAB PRERUNNER 2.5 G",
    value: "VIGO SMARTCAB PRERUNNER 2.5 G"
  },
  {
    SERIAL: "01PN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 J M",
    label: "COROLLA ALTIS.1.6 J M",
    value: "COROLLA ALTIS.1.6 J M"
  },
  {
    SERIAL: "01PO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 J CNG",
    label: "COROLLA ALTIS.1.6 J CNG",
    value: "COROLLA ALTIS.1.6 J CNG"
  },
  {
    SERIAL: "01PP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 E CNG",
    label: "COROLLA ALTIS.1.6 E CNG",
    value: "COROLLA ALTIS.1.6 E CNG"
  },
  {
    SERIAL: "01PQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.6 G A",
    label: "COROLLA ALTIS.1.6 G A",
    value: "COROLLA ALTIS.1.6 G A"
  },
  {
    SERIAL: "01PR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 E A",
    label: "COROLLA ALTIS.1.8 E A",
    value: "COROLLA ALTIS.1.8 E A"
  },
  {
    SERIAL: "01PS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 S A (ESPORT)",
    label: "COROLLA ALTIS.1.8 S A (ESPORT)",
    value: "COROLLA ALTIS.1.8 S A (ESPORT)"
  },
  {
    SERIAL: "01PT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 G A",
    label: "COROLLA ALTIS.1.8 G A",
    value: "COROLLA ALTIS.1.8 G A"
  },
  {
    SERIAL: "01PU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 V NAVIGATOR",
    label: "COROLLA ALTIS.1.8 V NAVIGATOR",
    value: "COROLLA ALTIS.1.8 V NAVIGATOR"
  },
  {
    SERIAL: "01PV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.5 V",
    label: "FORTUNER 2.5 V",
    value: "FORTUNER 2.5 V"
  },
  {
    SERIAL: "01PW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 ESPORT",
    label: "COROLLA ALTIS.1.8 ESPORT",
    value: "COROLLA ALTIS.1.8 ESPORT"
  },
  {
    SERIAL: "01PX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB PRERUNNER 2.5 TRD SPORTIV",
    label: "VIGO SMARTCAB PRERUNNER 2.5 TRD SPORTIV",
    value: "VIGO SMARTCAB PRERUNNER 2.5 TRD SPORTIV"
  },
  {
    SERIAL: "01PY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HARRIER 2.0",
    label: "HARRIER 2.0",
    value: "HARRIER 2.0"
  },
  {
    SERIAL: "01PZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS TRD SPORTIVO",
    label: "SOLUNA VIOS TRD SPORTIVO",
    value: "SOLUNA VIOS TRD SPORTIVO"
  },
  {
    SERIAL: "01QA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.4 V",
    label: "ALPHARD 2.4 V",
    value: "ALPHARD 2.4 V"
  },
  {
    SERIAL: "01QB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS. 1.8 G NAVIGATOR",
    label: "COROLLA ALTIS. 1.8 G NAVIGATOR",
    value: "COROLLA ALTIS. 1.8 G NAVIGATOR"
  },
  {
    SERIAL: "01QC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 3.0 V 4WD DVD NAVIGATOR",
    label: "FORTUNER 3.0 V 4WD DVD NAVIGATOR",
    value: "FORTUNER 3.0 V 4WD DVD NAVIGATOR"
  },
  {
    SERIAL: "01QD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE ECONOMY 3.0",
    label: "HIACE ECONOMY 3.0",
    value: "HIACE ECONOMY 3.0"
  },
  {
    SERIAL: "01QE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE GL 3.0",
    label: "HIACE GL 3.0",
    value: "HIACE GL 3.0"
  },
  {
    SERIAL: "01QF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO SMARTCAB 2.5 E TRD SPORTIVO",
    label: "VIGO SMARTCAB 2.5 E TRD SPORTIVO",
    value: "VIGO SMARTCAB 2.5 E TRD SPORTIVO"
  },
  {
    SERIAL: "01QG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER TRD SPORTIVO 2.5 E",
    label: "VIGO DOUBLECAB PRERUNNER TRD SPORTIVO 2.5 E",
    value: "VIGO DOUBLECAB PRERUNNER TRD SPORTIVO 2.5 E"
  },
  {
    SERIAL: "01QH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO DOUBLECAB PRERUNNER 2.5 G A NAVIGA TOR",
    label: "VIGO DOUBLECAB PRERUNNER 2.5 G A NAVIGA TOR",
    value: "VIGO DOUBLECAB PRERUNNER 2.5 G A NAVIGA TOR"
  },
  {
    SERIAL: "01QI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HARRIER 2.5 HYBRID",
    label: "HARRIER 2.5 HYBRID",
    value: "HARRIER 2.5 HYBRID"
  },
  {
    SERIAL: "01QJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA XLI 1.3",
    label: "COROLLA XLI 1.3",
    value: "COROLLA XLI 1.3"
  },
  {
    SERIAL: "01QK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. S LIMITED",
    label: "SOLUNA VIOS. S LIMITED",
    value: "SOLUNA VIOS. S LIMITED"
  },
  {
    SERIAL: "01QL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 TRD SPORTIVO",
    label: "YARIS 1.2 TRD SPORTIVO",
    value: "YARIS 1.2 TRD SPORTIVO"
  },
  {
    SERIAL: "01QM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 S TOURING",
    label: "AVANZA 1.5 S TOURING",
    value: "AVANZA 1.5 S TOURING"
  },
  {
    SERIAL: "01QN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VENTURY 3.0 G",
    label: "VENTURY 3.0 G",
    value: "VENTURY 3.0 G"
  },
  {
    SERIAL: "01QO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE 3.0 GL",
    label: "HIACE 3.0 GL",
    value: "HIACE 3.0 GL"
  },
  {
    SERIAL: "01QP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 HV",
    label: "ALPHARD 2.5 HV",
    value: "ALPHARD 2.5 HV"
  },
  {
    SERIAL: "01QQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 G",
    label: "CAMRY 2.0 G",
    value: "CAMRY 2.0 G"
  },
  {
    SERIAL: "01QR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 G EXTREMO (Y.2015)",
    label: "CAMRY 2.0 G EXTREMO (Y.2015)",
    value: "CAMRY 2.0 G EXTREMO (Y.2015)"
  },
  {
    SERIAL: "01QS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 G (Y.2015)",
    label: "CAMRY 2.5 G (Y.2015)",
    value: "CAMRY 2.5 G (Y.2015)"
  },
  {
    SERIAL: "01QT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 HYBRID CD",
    label: "CAMRY 2.5 HYBRID CD",
    value: "CAMRY 2.5 HYBRID CD"
  },
  {
    SERIAL: "01QU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 HYBRID DVD NAVIGATOR",
    label: "CAMRY 2.5 HYBRID DVD NAVIGATOR",
    value: "CAMRY 2.5 HYBRID DVD NAVIGATOR"
  },
  {
    SERIAL: "01QV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 HYBRID PREMIUM",
    label: "CAMRY 2.5 HYBRID PREMIUM",
    value: "CAMRY 2.5 HYBRID PREMIUM"
  },
  {
    SERIAL: "01QW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE 2.5",
    label: "VELLFIRE 2.5",
    value: "VELLFIRE 2.5"
  },
  {
    SERIAL: "01QX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD 2.4 J PLUS",
    label: "REVO STANDARD 2.4 J PLUS",
    value: "REVO STANDARD 2.4 J PLUS"
  },
  {
    SERIAL: "01QY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD 2.4 J",
    label: "REVO STANDARD 2.4 J",
    value: "REVO STANDARD 2.4 J"
  },
  {
    SERIAL: "01QZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD 2.8 J PLUS",
    label: "REVO STANDARD 2.8 J PLUS",
    value: "REVO STANDARD 2.8 J PLUS"
  },
  {
    SERIAL: "01RA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANARD 2.7 J",
    label: "REVO STANARD 2.7 J",
    value: "REVO STANARD 2.7 J"
  },
  {
    SERIAL: "01RB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD 2.8 J 4WD",
    label: "REVO STANDARD 2.8 J 4WD",
    value: "REVO STANDARD 2.8 J 4WD"
  },
  {
    SERIAL: "01RC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.4 J PLUS",
    label: "REVO SMART CAB 2.4 J PLUS",
    value: "REVO SMART CAB 2.4 J PLUS"
  },
  {
    SERIAL: "01RD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.4 E",
    label: "REVO SMART CAB 2.4 E",
    value: "REVO SMART CAB 2.4 E"
  },
  {
    SERIAL: "01RE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.4 G",
    label: "REVO SMART CAB 2.4 G",
    value: "REVO SMART CAB 2.4 G"
  },
  {
    SERIAL: "01RF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.7 J PLUS",
    label: "REVO SMART CAB 2.7 J PLUS",
    value: "REVO SMART CAB 2.7 J PLUS"
  },
  {
    SERIAL: "01RG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 E M",
    label: "REVO SMART CAB PRERUNNER 2.4 E M",
    value: "REVO SMART CAB PRERUNNER 2.4 E M"
  },
  {
    SERIAL: "01RH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 E A",
    label: "REVO SMART CAB PRERUNNER 2.4 E A",
    value: "REVO SMART CAB PRERUNNER 2.4 E A"
  },
  {
    SERIAL: "01RI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 G M",
    label: "REVO SMART CAB PRERUNNER 2.4 G M",
    value: "REVO SMART CAB PRERUNNER 2.4 G M"
  },
  {
    SERIAL: "01RJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 G A",
    label: "REVO SMART CAB PRERUNNER 2.4 G A",
    value: "REVO SMART CAB PRERUNNER 2.4 G A"
  },
  {
    SERIAL: "01RK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.8 G",
    label: "REVO SMART CAB PRERUNNER 2.8 G",
    value: "REVO SMART CAB PRERUNNER 2.8 G"
  },
  {
    SERIAL: "01RL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.4 E 4WD",
    label: "REVO SMART CAB 2.4 E 4WD",
    value: "REVO SMART CAB 2.4 E 4WD"
  },
  {
    SERIAL: "01RM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.8 G 4WD",
    label: "REVO SMART CAB 2.8 G 4WD",
    value: "REVO SMART CAB 2.8 G 4WD"
  },
  {
    SERIAL: "01RN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.4 J PLUS",
    label: "REVO DOUBLE CAB 2.4 J PLUS",
    value: "REVO DOUBLE CAB 2.4 J PLUS"
  },
  {
    SERIAL: "01RO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.4 E",
    label: "REVO DOUBLE CAB 2.4 E",
    value: "REVO DOUBLE CAB 2.4 E"
  },
  {
    SERIAL: "01RP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.4 J",
    label: "REVO SMART CAB 2.4 J",
    value: "REVO SMART CAB 2.4 J"
  },
  {
    SERIAL: "01RQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 ENTRY M",
    label: "REVO SMART CAB PRERUNNER 2.4 ENTRY M",
    value: "REVO SMART CAB PRERUNNER 2.4 ENTRY M"
  },
  {
    SERIAL: "01RR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 HYBRID (GREY)",
    label: "ALPHARD 2.5 HYBRID (GREY)",
    value: "ALPHARD 2.5 HYBRID (GREY)"
  },
  {
    SERIAL: "01RS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.7 E",
    label: "REVO DOUBLE CAB 2.7 E",
    value: "REVO DOUBLE CAB 2.7 E"
  },
  {
    SERIAL: "01RT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 E M",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 E M",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 E M"
  },
  {
    SERIAL: "01RU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 E A",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 E A",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 E A"
  },
  {
    SERIAL: "01RV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 G M",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 G M",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 G M"
  },
  {
    SERIAL: "01RW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 G A",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 G A",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 G A"
  },
  {
    SERIAL: "01RX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 J PLUS",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 J PLUS",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 J PLUS"
  },
  {
    SERIAL: "01RY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.8 G A",
    label: "REVO DOUBLE CAB PRERUNNER 2.8 G A",
    value: "REVO DOUBLE CAB PRERUNNER 2.8 G A"
  },
  {
    SERIAL: "01RZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.4 E 4WD",
    label: "REVO DOUBLE CAB 2.4 E 4WD",
    value: "REVO DOUBLE CAB 2.4 E 4WD"
  },
  {
    SERIAL: "01SA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.8 G M 4WD",
    label: "REVO DOUBLE CAB 2.8 G M 4WD",
    value: "REVO DOUBLE CAB 2.8 G M 4WD"
  },
  {
    SERIAL: "01SB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.8 G A 4WD",
    label: "REVO DOUBLE CAB 2.8 G A 4WD",
    value: "REVO DOUBLE CAB 2.8 G A 4WD"
  },
  {
    SERIAL: "01SC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 J PLUS",
    label: "REVO SMART CAB PRERUNNER 2.4 J PLUS",
    value: "REVO SMART CAB PRERUNNER 2.4 J PLUS"
  },
  {
    SERIAL: "01SD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.7 E",
    label: "REVO SMART CAB PRERUNNER 2.7 E",
    value: "REVO SMART CAB PRERUNNER 2.7 E"
  },
  {
    SERIAL: "01SE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.7 E A",
    label: "REVO DOUBLE CAB PRERUNNER 2.7 E A",
    value: "REVO DOUBLE CAB PRERUNNER 2.7 E A"
  },
  {
    SERIAL: "01SF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 G PLUS A",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 G PLUS A",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 G PLUS A"
  },
  {
    SERIAL: "01SG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LANDCRUISER ZX BRUNO CROSS 4.6",
    label: "LANDCRUISER ZX BRUNO CROSS 4.6",
    value: "LANDCRUISER ZX BRUNO CROSS 4.6"
  },
  {
    SERIAL: "01SH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.4 V 2WD",
    label: "FORTUNER 2.4 V 2WD",
    value: "FORTUNER 2.4 V 2WD"
  },
  {
    SERIAL: "01SI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.4 G 2WD",
    label: "FORTUNER 2.4 G 2WD",
    value: "FORTUNER 2.4 G 2WD"
  },
  {
    SERIAL: "01SJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.7 V 2WD",
    label: "FORTUNER 2.7 V 2WD",
    value: "FORTUNER 2.7 V 2WD"
  },
  {
    SERIAL: "01SK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.8 V 2WD",
    label: "FORTUNER 2.8 V 2WD",
    value: "FORTUNER 2.8 V 2WD"
  },
  {
    SERIAL: "01SL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.8 V 4WD",
    label: "FORTUNER 2.8 V 4WD",
    value: "FORTUNER 2.8 V 4WD"
  },
  {
    SERIAL: "01SM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LANDCRUISER 70 VAN 4.0",
    label: "LANDCRUISER 70 VAN 4.0",
    value: "LANDCRUISER 70 VAN 4.0"
  },
  {
    SERIAL: "01SN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VOXY 2.0",
    label: "VOXY 2.0",
    value: "VOXY 2.0"
  },
  {
    SERIAL: "01SO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VENTURY 3.0 V",
    label: "VENTURY 3.0 V",
    value: "VENTURY 3.0 V"
  },
  {
    SERIAL: "01SP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "01SQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "16000",
    label: "16000",
    value: "16000"
  },
  {
    SERIAL: "01SR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 GF HYBRID (HERTZ)",
    label: "ALPHARD 2.5 GF HYBRID (HERTZ)",
    value: "ALPHARD 2.5 GF HYBRID (HERTZ)"
  },
  {
    SERIAL: "01SS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 G HYBRID (HERTZ)",
    label: "ALPHARD 2.5 G HYBRID (HERTZ)",
    value: "ALPHARD 2.5 G HYBRID (HERTZ)"
  },
  {
    SERIAL: "01ST0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESQUIRE 1.8 HYBRID",
    label: "ESQUIRE 1.8 HYBRID",
    value: "ESQUIRE 1.8 HYBRID"
  },
  {
    SERIAL: "01SU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 SC",
    label: "ALPHARD 2.5 SC",
    value: "ALPHARD 2.5 SC"
  },
  {
    SERIAL: "01SV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 3.5 HYBRID EXECUTIVE LOUNGE",
    label: "ALPHARD 3.5 HYBRID EXECUTIVE LOUNGE",
    value: "ALPHARD 3.5 HYBRID EXECUTIVE LOUNGE"
  },
  {
    SERIAL: "01SW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD WELCAB 2.5",
    label: "ALPHARD WELCAB 2.5",
    value: "ALPHARD WELCAB 2.5"
  },
  {
    SERIAL: "01SX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.8 TRD SPORTIVO 2WD",
    label: "FORTUNER 2.8 TRD SPORTIVO 2WD",
    value: "FORTUNER 2.8 TRD SPORTIVO 2WD"
  },
  {
    SERIAL: "01SY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.8 TRD SPORTIVO 4WD",
    label: "FORTUNER 2.8 TRD SPORTIVO 4WD",
    value: "FORTUNER 2.8 TRD SPORTIVO 4WD"
  },
  {
    SERIAL: "01SZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS ESPORT NURBURGING 1.8 A",
    label: "COROLLA ALTIS ESPORT NURBURGING 1.8 A",
    value: "COROLLA ALTIS ESPORT NURBURGING 1.8 A"
  },
  {
    SERIAL: "01TA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 E A",
    label: "AVANZA 1.5 E A",
    value: "AVANZA 1.5 E A"
  },
  {
    SERIAL: "01TB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 S A",
    label: "AVANZA 1.5 S A",
    value: "AVANZA 1.5 S A"
  },
  {
    SERIAL: "01TC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLECAB PRERUNNER SPORTIVO 2.4 A",
    label: "REVO DOUBLECAB PRERUNNER SPORTIVO 2.4 A",
    value: "REVO DOUBLECAB PRERUNNER SPORTIVO 2.4 A"
  },
  {
    SERIAL: "01TD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLECAB PRERUNNER SPORTIVO 2.4",
    label: "REVO DOUBLECAB PRERUNNER SPORTIVO 2.4",
    value: "REVO DOUBLECAB PRERUNNER SPORTIVO 2.4"
  },
  {
    SERIAL: "01TE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMARTCAB PRERUNNER TRD SPORTIVO2.4",
    label: "REVO SMARTCAB PRERUNNER TRD SPORTIVO2.4",
    value: "REVO SMARTCAB PRERUNNER TRD SPORTIVO2.4"
  },
  {
    SERIAL: "01TF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMARTCAB PRERUNNER TRDSPORTIVO2.4A",
    label: "REVO SMARTCAB PRERUNNER TRDSPORTIVO2.4A",
    value: "REVO SMARTCAB PRERUNNER TRDSPORTIVO2.4A"
  },
  {
    SERIAL: "01TG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB TRDSPORTIVO 2.4",
    label: "REVO SMART CAB TRDSPORTIVO 2.4",
    value: "REVO SMART CAB TRDSPORTIVO 2.4"
  },
  {
    SERIAL: "01TI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 G ESPORT",
    label: "CAMRY 2.5 G ESPORT",
    value: "CAMRY 2.5 G ESPORT"
  },
  {
    SERIAL: "01TJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS EXCLUSIVE",
    label: "SOLUNA VIOS EXCLUSIVE",
    value: "SOLUNA VIOS EXCLUSIVE"
  },
  {
    SERIAL: "01TK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SIENTA 1.5 V",
    label: "SIENTA 1.5 V",
    value: "SIENTA 1.5 V"
  },
  {
    SERIAL: "01TL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SIENTA 1.5 G",
    label: "SIENTA 1.5 G",
    value: "SIENTA 1.5 G"
  },
  {
    SERIAL: "01TM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 E PLUS M",
    label: "REVO SMART CAB PRERUNNER 2.4 E PLUS M",
    value: "REVO SMART CAB PRERUNNER 2.4 E PLUS M"
  },
  {
    SERIAL: "01TN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.4 E PLUS M 4WD",
    label: "REVO SMART CAB 2.4 E PLUS M 4WD",
    value: "REVO SMART CAB 2.4 E PLUS M 4WD"
  },
  {
    SERIAL: "01TO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 E PLUS",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 E PLUS",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 E PLUS"
  },
  {
    SERIAL: "01TP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.4 E PLUS 4WD",
    label: "REVO DOUBLE CAB 2.4 E PLUS 4WD",
    value: "REVO DOUBLE CAB 2.4 E PLUS 4WD"
  },
  {
    SERIAL: "01TQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 2.8",
    label: "LAND CRUISER PRADO 2.8",
    value: "LAND CRUISER PRADO 2.8"
  },
  {
    SERIAL: "01TR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA CRYSTA 2.0 E M",
    label: "INNOVA CRYSTA 2.0 E M",
    value: "INNOVA CRYSTA 2.0 E M"
  },
  {
    SERIAL: "01TS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA CRYSTA 2.8 G A",
    label: "INNOVA CRYSTA 2.8 G A",
    value: "INNOVA CRYSTA 2.8 G A"
  },
  {
    SERIAL: "01TT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA CRYSTA 2.8 V A",
    label: "INNOVA CRYSTA 2.8 V A",
    value: "INNOVA CRYSTA 2.8 V A"
  },
  {
    SERIAL: "01TU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA",
    label: "AVANZA",
    value: "AVANZA"
  },
  {
    SERIAL: "01TV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "AVANZA 1.5 E M",
    label: "AVANZA 1.5 E M",
    value: "AVANZA 1.5 E M"
  },
  {
    SERIAL: "01TW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CROWN",
    label: "CROWN",
    value: "CROWN"
  },
  {
    SERIAL: "01TX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VIGO",
    label: "VIGO",
    value: "VIGO"
  },
  {
    SERIAL: "01TY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS",
    label: "YARIS",
    value: "YARIS"
  },
  {
    SERIAL: "01TZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA",
    label: "SOLUNA",
    value: "SOLUNA"
  },
  {
    SERIAL: "01UA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY AU 2.5",
    label: "CAMRY AU 2.5",
    value: "CAMRY AU 2.5"
  },
  {
    SERIAL: "01UB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESQUIRE 2.0 BENZINE",
    label: "ESQUIRE 2.0 BENZINE",
    value: "ESQUIRE 2.0 BENZINE"
  },
  {
    SERIAL: "01UC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 G (HERTZ)",
    label: "ALPHARD 2.5 G (HERTZ)",
    value: "ALPHARD 2.5 G (HERTZ)"
  },
  {
    SERIAL: "01UD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COASTER - BUS 20 SEATS",
    label: "COASTER - BUS 20 SEATS",
    value: "COASTER - BUS 20 SEATS"
  },
  {
    SERIAL: "01UE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "DUET",
    label: "DUET",
    value: "DUET"
  },
  {
    SERIAL: "01UF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "NOAH",
    label: "NOAH",
    value: "NOAH"
  },
  {
    SERIAL: "01UG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 J",
    label: "YARIS ATIV 1.2 J",
    value: "YARIS ATIV 1.2 J"
  },
  {
    SERIAL: "01UH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 E",
    label: "YARIS ATIV 1.2 E",
    value: "YARIS ATIV 1.2 E"
  },
  {
    SERIAL: "01UI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 G",
    label: "YARIS ATIV 1.2 G",
    value: "YARIS ATIV 1.2 G"
  },
  {
    SERIAL: "01UJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 S",
    label: "YARIS ATIV 1.2 S",
    value: "YARIS ATIV 1.2 S"
  },
  {
    SERIAL: "01UK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "2000",
    label: "2000",
    value: "2000"
  },
  {
    SERIAL: "01UL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE",
    label: "HIACE",
    value: "HIACE"
  },
  {
    SERIAL: "01UM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SURF SSR",
    label: "SURF SSR",
    value: "SURF SSR"
  },
  {
    SERIAL: "01UN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "C-HR 1.8 ENTRY",
    label: "C-HR 1.8 ENTRY",
    value: "C-HR 1.8 ENTRY"
  },
  {
    SERIAL: "01UO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "C-HR 1.8 MID",
    label: "C-HR 1.8 MID",
    value: "C-HR 1.8 MID"
  },
  {
    SERIAL: "01UP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "C-HR 1.8 HV MID",
    label: "C-HR 1.8 HV MID",
    value: "C-HR 1.8 HV MID"
  },
  {
    SERIAL: "01UQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "C-HR 1.8 HV HI",
    label: "C-HR 1.8 HV HI",
    value: "C-HR 1.8 HV HI"
  },
  {
    SERIAL: "01UR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 J ECO",
    label: "YARIS ATIV 1.2 J ECO",
    value: "YARIS ATIV 1.2 J ECO"
  },
  {
    SERIAL: "01US0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS. 1.8 ESPORT OPTION",
    label: "COROLLA ALTIS. 1.8 ESPORT OPTION",
    value: "COROLLA ALTIS. 1.8 ESPORT OPTION"
  },
  {
    SERIAL: "01UT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "NOAH 1.8",
    label: "NOAH 1.8",
    value: "NOAH 1.8"
  },
  {
    SERIAL: "01UU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD VIP 3.5",
    label: "ALPHARD VIP 3.5",
    value: "ALPHARD VIP 3.5"
  },
  {
    SERIAL: "01UV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS. 1.8 S",
    label: "COROLLA ALTIS. 1.8 S",
    value: "COROLLA ALTIS. 1.8 S"
  },
  {
    SERIAL: "01UW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.4 V 4WD",
    label: "FORTUNER 2.4 V 4WD",
    value: "FORTUNER 2.4 V 4WD"
  },
  {
    SERIAL: "01UX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS. 1.8 V NAVIGATOR TELEMATI C",
    label: "COROLLA ALTIS. 1.8 V NAVIGATOR TELEMATI C",
    value: "COROLLA ALTIS. 1.8 V NAVIGATOR TELEMATI C"
  },
  {
    SERIAL: "01UY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 HYBRID NAVIGATOR TELEMATIC",
    label: "CAMRY 2.5 HYBRID NAVIGATOR TELEMATIC",
    value: "CAMRY 2.5 HYBRID NAVIGATOR TELEMATIC"
  },
  {
    SERIAL: "01UZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY HYBRID 2.5 PREMIUM TELEMATIC",
    label: "CAMRY HYBRID 2.5 PREMIUM TELEMATIC",
    value: "CAMRY HYBRID 2.5 PREMIUM TELEMATIC"
  },
  {
    SERIAL: "01VA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS. GT STREET 1.5",
    label: "SOLUNA VIOS. GT STREET 1.5",
    value: "SOLUNA VIOS. GT STREET 1.5"
  },
  {
    SERIAL: "01VB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.0 G",
    label: "CAMRY 2.0 G",
    value: "CAMRY 2.0 G"
  },
  {
    SERIAL: "01VC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 G",
    label: "CAMRY 2.5 G",
    value: "CAMRY 2.5 G"
  },
  {
    SERIAL: "01VD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 HV",
    label: "CAMRY 2.5 HV",
    value: "CAMRY 2.5 HV"
  },
  {
    SERIAL: "01VE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "CAMRY 2.5 HV PREMIUM",
    label: "CAMRY 2.5 HV PREMIUM",
    value: "CAMRY 2.5 HV PREMIUM"
  },
  {
    SERIAL: "01VF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD CHASSIS",
    label: "REVO STANDARD CHASSIS",
    value: "REVO STANDARD CHASSIS"
  },
  {
    SERIAL: "01VG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 G+",
    label: "YARIS 1.2 G+",
    value: "YARIS 1.2 G+"
  },
  {
    SERIAL: "01VH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 S+",
    label: "YARIS 1.2 S+",
    value: "YARIS 1.2 S+"
  },
  {
    SERIAL: "01VI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LAND CRUISER PRADO 4.8",
    label: "LAND CRUISER PRADO 4.8",
    value: "LAND CRUISER PRADO 4.8"
  },
  {
    SERIAL: "01VJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "LANDCRUISER SAHARA 4.5D",
    label: "LANDCRUISER SAHARA 4.5D",
    value: "LANDCRUISER SAHARA 4.5D"
  },
  {
    SERIAL: "01VK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 GF HYBRID",
    label: "ALPHARD 2.5 GF HYBRID",
    value: "ALPHARD 2.5 GF HYBRID"
  },
  {
    SERIAL: "01VL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV S+ 1.2 A",
    label: "YARIS ATIV S+ 1.2 A",
    value: "YARIS ATIV S+ 1.2 A"
  },
  {
    SERIAL: "01VM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS.1.8 S A",
    label: "COROLLA ALTIS.1.8 S A",
    value: "COROLLA ALTIS.1.8 S A"
  },
  {
    SERIAL: "01VN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS. 1.8 V NAVIGATOR TELEMATI CS",
    label: "COROLLA ALTIS. 1.8 V NAVIGATOR TELEMATI CS",
    value: "COROLLA ALTIS. 1.8 V NAVIGATOR TELEMATI CS"
  },
  {
    SERIAL: "01VO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS ENTRY 1.5",
    label: "SOLUNA VIOS ENTRY 1.5",
    value: "SOLUNA VIOS ENTRY 1.5"
  },
  {
    SERIAL: "01VP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS MID 1.5",
    label: "SOLUNA VIOS MID 1.5",
    value: "SOLUNA VIOS MID 1.5"
  },
  {
    SERIAL: "01VQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "SOLUNA VIOS HIGH 1.5",
    label: "SOLUNA VIOS HIGH 1.5",
    value: "SOLUNA VIOS HIGH 1.5"
  },
  {
    SERIAL: "01VR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB Z EDITION 2.4 J PLUS",
    label: "REVO SMART CAB Z EDITION 2.4 J PLUS",
    value: "REVO SMART CAB Z EDITION 2.4 J PLUS"
  },
  {
    SERIAL: "01VS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB Z EDITION 2.4 J PLUS A",
    label: "REVO SMART CAB Z EDITION 2.4 J PLUS A",
    value: "REVO SMART CAB Z EDITION 2.4 J PLUS A"
  },
  {
    SERIAL: "01VT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB Z EDITION 2.4 E",
    label: "REVO SMART CAB Z EDITION 2.4 E",
    value: "REVO SMART CAB Z EDITION 2.4 E"
  },
  {
    SERIAL: "01VU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB Z EDITION 2.4 J PLUS",
    label: "REVO DOUBLE CAB Z EDITION 2.4 J PLUS",
    value: "REVO DOUBLE CAB Z EDITION 2.4 J PLUS"
  },
  {
    SERIAL: "01VV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB Z EDITION 2.4 J PLUS A",
    label: "REVO DOUBLE CAB Z EDITION 2.4 J PLUS A",
    value: "REVO DOUBLE CAB Z EDITION 2.4 J PLUS A"
  },
  {
    SERIAL: "01VW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB Z EDITION 2.4 E",
    label: "REVO DOUBLE CAB Z EDITION 2.4 E",
    value: "REVO DOUBLE CAB Z EDITION 2.4 E"
  },
  {
    SERIAL: "01VX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "MAJESTY STANDARD",
    label: "MAJESTY STANDARD",
    value: "MAJESTY STANDARD"
  },
  {
    SERIAL: "01VY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "MAJESTY PREMIUM",
    label: "MAJESTY PREMIUM",
    value: "MAJESTY PREMIUM"
  },
  {
    SERIAL: "01VZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "MAJESTY GRANDE",
    label: "MAJESTY GRANDE",
    value: "MAJESTY GRANDE"
  },
  {
    SERIAL: "01WA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE 3.5 ZG",
    label: "VELLFIRE 3.5 ZG",
    value: "VELLFIRE 3.5 ZG"
  },
  {
    SERIAL: "01WB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE 2.8 ECO",
    label: "HIACE 2.8 ECO",
    value: "HIACE 2.8 ECO"
  },
  {
    SERIAL: "01WC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE 2.8 GL",
    label: "HIACE 2.8 GL",
    value: "HIACE 2.8 GL"
  },
  {
    SERIAL: "01WD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 HIGH",
    label: "YARIS 1.2 HIGH",
    value: "YARIS 1.2 HIGH"
  },
  {
    SERIAL: "01WE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 MID",
    label: "YARIS 1.2 MID",
    value: "YARIS 1.2 MID"
  },
  {
    SERIAL: "01WF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 ENTRY",
    label: "YARIS 1.2 ENTRY",
    value: "YARIS 1.2 ENTRY"
  },
  {
    SERIAL: "01WG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 HIGH",
    label: "YARIS ATIV 1.2 HIGH",
    value: "YARIS ATIV 1.2 HIGH"
  },
  {
    SERIAL: "01WH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 MID",
    label: "YARIS ATIV 1.2 MID",
    value: "YARIS ATIV 1.2 MID"
  },
  {
    SERIAL: "01WI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 ENTRY",
    label: "YARIS ATIV 1.2 ENTRY",
    value: "YARIS ATIV 1.2 ENTRY"
  },
  {
    SERIAL: "01WJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 X (HERTZ)",
    label: "ALPHARD 2.5 X (HERTZ)",
    value: "ALPHARD 2.5 X (HERTZ)"
  },
  {
    SERIAL: "01WO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS LIMO 1.6",
    label: "COROLLA ALTIS LIMO 1.6",
    value: "COROLLA ALTIS LIMO 1.6"
  },
  {
    SERIAL: "01WP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS GR SPORT 1.8",
    label: "COROLLA ALTIS GR SPORT 1.8",
    value: "COROLLA ALTIS GR SPORT 1.8"
  },
  {
    SERIAL: "01WQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS HYBRID ENTRY 1.8",
    label: "COROLLA ALTIS HYBRID ENTRY 1.8",
    value: "COROLLA ALTIS HYBRID ENTRY 1.8"
  },
  {
    SERIAL: "01WR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS HYBRID MID 1.8",
    label: "COROLLA ALTIS HYBRID MID 1.8",
    value: "COROLLA ALTIS HYBRID MID 1.8"
  },
  {
    SERIAL: "01WS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS HYBRID HIGH 1.8",
    label: "COROLLA ALTIS HYBRID HIGH 1.8",
    value: "COROLLA ALTIS HYBRID HIGH 1.8"
  },
  {
    SERIAL: "01WT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.6 LIMO",
    label: "COROLLA ALTIS 1.6 LIMO",
    value: "COROLLA ALTIS 1.6 LIMO"
  },
  {
    SERIAL: "01WU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS 1.6 G",
    label: "COROLLA ALTIS 1.6 G",
    value: "COROLLA ALTIS 1.6 G"
  },
  {
    SERIAL: "01WY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "VELLFIRE 2.5 HV EXECUTIVE LOUNGE",
    label: "VELLFIRE 2.5 HV EXECUTIVE LOUNGE",
    value: "VELLFIRE 2.5 HV EXECUTIVE LOUNGE"
  },
  {
    SERIAL: "01XA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO 2.4 J SAMMITR BODY",
    label: "REVO 2.4 J SAMMITR BODY",
    value: "REVO 2.4 J SAMMITR BODY"
  },
  {
    SERIAL: "01XD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB Z EDITION 2.4 E PLUS M",
    label: "REVO DOUBLE CAB Z EDITION 2.4 E PLUS M",
    value: "REVO DOUBLE CAB Z EDITION 2.4 E PLUS M"
  },
  {
    SERIAL: "01XE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COMMUTER 2.8",
    label: "COMMUTER 2.8",
    value: "COMMUTER 2.8"
  },
  {
    SERIAL: "01XF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 E PLUS A",
    label: "REVO SMART CAB PRERUNNER 2.4 E PLUS A",
    value: "REVO SMART CAB PRERUNNER 2.4 E PLUS A"
  },
  {
    SERIAL: "01XG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.8 LEGENDER 2 WD AT",
    label: "FORTUNER 2.8 LEGENDER 2 WD AT",
    value: "FORTUNER 2.8 LEGENDER 2 WD AT"
  },
  {
    SERIAL: "01XH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.8 LEGENDER 4 WD AT",
    label: "FORTUNER 2.8 LEGENDER 4 WD AT",
    value: "FORTUNER 2.8 LEGENDER 4 WD AT"
  },
  {
    SERIAL: "01XI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.4 LEGENDER 2 WD AT",
    label: "FORTUNER 2.4 LEGENDER 2 WD AT",
    value: "FORTUNER 2.4 LEGENDER 2 WD AT"
  },
  {
    SERIAL: "01XJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "FORTUNER 2.4 LEGENDER 4 WD AT",
    label: "FORTUNER 2.4 LEGENDER 4 WD AT",
    value: "FORTUNER 2.4 LEGENDER 4 WD AT"
  },
  {
    SERIAL: "01XK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB Z EDITION 4X2 2.4 MID M",
    label: "REVO SMART CAB Z EDITION 4X2 2.4 MID M",
    value: "REVO SMART CAB Z EDITION 4X2 2.4 MID M"
  },
  {
    SERIAL: "01XL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 ENTRY A",
    label: "REVO SMART CAB PRERUNNER 2.4 ENTRY A",
    value: "REVO SMART CAB PRERUNNER 2.4 ENTRY A"
  },
  {
    SERIAL: "01XM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB Z EDITION 2.4 MID A",
    label: "REVO DOUBLE CAB Z EDITION 2.4 MID A",
    value: "REVO DOUBLE CAB Z EDITION 2.4 MID A"
  },
  {
    SERIAL: "01XN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 HIGH A",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 HIGH A",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 HIGH A"
  },
  {
    SERIAL: "01XO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 HIGH A",
    label: "REVO SMART CAB PRERUNNER 2.4 HIGH A",
    value: "REVO SMART CAB PRERUNNER 2.4 HIGH A"
  },
  {
    SERIAL: "01XP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 MID A",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 MID A",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 MID A"
  },
  {
    SERIAL: "01XQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNER 2.4 MID A",
    label: "REVO SMART CAB PRERUNER 2.4 MID A",
    value: "REVO SMART CAB PRERUNER 2.4 MID A"
  },
  {
    SERIAL: "01XR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD CAB 2.4 ENTRY M",
    label: "REVO STANDARD CAB 2.4 ENTRY M",
    value: "REVO STANDARD CAB 2.4 ENTRY M"
  },
  {
    SERIAL: "01XS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD CAB 2.4 ENTRY CHASIS",
    label: "REVO STANDARD CAB 2.4 ENTRY CHASIS",
    value: "REVO STANDARD CAB 2.4 ENTRY CHASIS"
  },
  {
    SERIAL: "01XT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD CAB 2.4 ENTRY CHASIS A",
    label: "REVO STANDARD CAB 2.4 ENTRY CHASIS A",
    value: "REVO STANDARD CAB 2.4 ENTRY CHASIS A"
  },
  {
    SERIAL: "01XU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD CAB 2.4 ENTRY A",
    label: "REVO STANDARD CAB 2.4 ENTRY A",
    value: "REVO STANDARD CAB 2.4 ENTRY A"
  },
  {
    SERIAL: "01XV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD CAB 2.8 ENTRY",
    label: "REVO STANDARD CAB 2.8 ENTRY",
    value: "REVO STANDARD CAB 2.8 ENTRY"
  },
  {
    SERIAL: "01XW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 ENTRY M",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 ENTRY M",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 ENTRY M"
  },
  {
    SERIAL: "01XX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 ENTRY A",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 ENTRY A",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 ENTRY A"
  },
  {
    SERIAL: "01XY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 MID M",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 MID M",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 MID M"
  },
  {
    SERIAL: "01XZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 2.4 HIGH M",
    label: "REVO DOUBLE CAB PRERUNNER 2.4 HIGH M",
    value: "REVO DOUBLE CAB PRERUNNER 2.4 HIGH M"
  },
  {
    SERIAL: "01YA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB PRERUNNER 4WD 2.4 MID M",
    label: "REVO DOUBLE CAB PRERUNNER 4WD 2.4 MID M",
    value: "REVO DOUBLE CAB PRERUNNER 4WD 2.4 MID M"
  },
  {
    SERIAL: "01YB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLECAB PRERUNNER 4WD 2.8 HIGH M",
    label: "REVO DOUBLECAB PRERUNNER 4WD 2.8 HIGH M",
    value: "REVO DOUBLECAB PRERUNNER 4WD 2.8 HIGH M"
  },
  {
    SERIAL: "01YC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLECAB PRERUNNER 4WD 2.8 HIGH A",
    label: "REVO DOUBLECAB PRERUNNER 4WD 2.8 HIGH A",
    value: "REVO DOUBLECAB PRERUNNER 4WD 2.8 HIGH A"
  },
  {
    SERIAL: "01YD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 MID M",
    label: "REVO SMART CAB PRERUNNER 2.4 MID M",
    value: "REVO SMART CAB PRERUNNER 2.4 MID M"
  },
  {
    SERIAL: "01YE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 2.4 HIGH M",
    label: "REVO SMART CAB PRERUNNER 2.4 HIGH M",
    value: "REVO SMART CAB PRERUNNER 2.4 HIGH M"
  },
  {
    SERIAL: "01YF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 4WD 2.4 MID M",
    label: "REVO SMART CAB PRERUNNER 4WD 2.4 MID M",
    value: "REVO SMART CAB PRERUNNER 4WD 2.4 MID M"
  },
  {
    SERIAL: "01YG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB PRERUNNER 4WD 2.8 HIGH M",
    label: "REVO SMART CAB PRERUNNER 4WD 2.8 HIGH M",
    value: "REVO SMART CAB PRERUNNER 4WD 2.8 HIGH M"
  },
  {
    SERIAL: "01YH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB Z EDITION 2.4 ENTRY M",
    label: "REVO DOUBLE CAB Z EDITION 2.4 ENTRY M",
    value: "REVO DOUBLE CAB Z EDITION 2.4 ENTRY M"
  },
  {
    SERIAL: "01YI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB Z EDITION 2.4 ENTRY M",
    label: "REVO SMART CAB Z EDITION 2.4 ENTRY M",
    value: "REVO SMART CAB Z EDITION 2.4 ENTRY M"
  },
  {
    SERIAL: "01YJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB Z EDITION 2.4 MID M",
    label: "REVO SMART CAB Z EDITION 2.4 MID M",
    value: "REVO SMART CAB Z EDITION 2.4 MID M"
  },
  {
    SERIAL: "01YK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB Z EDITION 2.4 MID A",
    label: "REVO SMART CAB Z EDITION 2.4 MID A",
    value: "REVO SMART CAB Z EDITION 2.4 MID A"
  },
  {
    SERIAL: "01YL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.8 HIGH M 4WD",
    label: "REVO DOUBLE CAB 2.8 HIGH M 4WD",
    value: "REVO DOUBLE CAB 2.8 HIGH M 4WD"
  },
  {
    SERIAL: "01YM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB Z EDITION 2.4 MID M",
    label: "REVO DOUBLE CAB Z EDITION 2.4 MID M",
    value: "REVO DOUBLE CAB Z EDITION 2.4 MID M"
  },
  {
    SERIAL: "01YN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO DOUBLE CAB 2.4 MID M 4WD",
    label: "REVO DOUBLE CAB 2.4 MID M 4WD",
    value: "REVO DOUBLE CAB 2.4 MID M 4WD"
  },
  {
    SERIAL: "01YO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 A",
    label: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 A",
    value: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 A"
  },
  {
    SERIAL: "01YP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO ROCCO SMART CAB PRERUNNER 2.4 A",
    label: "REVO ROCCO SMART CAB PRERUNNER 2.4 A",
    value: "REVO ROCCO SMART CAB PRERUNNER 2.4 A"
  },
  {
    SERIAL: "01YQ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO ROCCO DOUBLE CAB 2.8 4WD A",
    label: "REVO ROCCO DOUBLE CAB 2.8 4WD A",
    value: "REVO ROCCO DOUBLE CAB 2.8 4WD A"
  },
  {
    SERIAL: "01YR0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO ROCCO SMART CAB 4WD 2.8 A",
    label: "REVO ROCCO SMART CAB 4WD 2.8 A",
    value: "REVO ROCCO SMART CAB 4WD 2.8 A"
  },
  {
    SERIAL: "01YS0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA CROSS 1.8 SPORT",
    label: "COROLLA CROSS 1.8 SPORT",
    value: "COROLLA CROSS 1.8 SPORT"
  },
  {
    SERIAL: "01YT0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA CROSS 1.8 HYBRID SMART",
    label: "COROLLA CROSS 1.8 HYBRID SMART",
    value: "COROLLA CROSS 1.8 HYBRID SMART"
  },
  {
    SERIAL: "01YU0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA CROSS 1.8 HYBRID PREMIUM",
    label: "COROLLA CROSS 1.8 HYBRID PREMIUM",
    value: "COROLLA CROSS 1.8 HYBRID PREMIUM"
  },
  {
    SERIAL: "01YV0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA CROSS 1.8 HYBRID PREMIUM SAFETY",
    label: "COROLLA CROSS 1.8 HYBRID PREMIUM SAFETY",
    value: "COROLLA CROSS 1.8 HYBRID PREMIUM SAFETY"
  },
  {
    SERIAL: "01YW0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HILUX",
    label: "HILUX",
    value: "HILUX"
  },
  {
    SERIAL: "01YX0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 A",
    label: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 A",
    value: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 A"
  },
  {
    SERIAL: "01YY0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ESTIMA AERAS 2.4",
    label: "ESTIMA AERAS 2.4",
    value: "ESTIMA AERAS 2.4"
  },
  {
    SERIAL: "01YZ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO STANDARD CAB 2.8 ENTRY 4WD",
    label: "REVO STANDARD CAB 2.8 ENTRY 4WD",
    value: "REVO STANDARD CAB 2.8 ENTRY 4WD"
  },
  {
    SERIAL: "01ZA0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.4 MID 4WD",
    label: "REVO SMART CAB 2.4 MID 4WD",
    value: "REVO SMART CAB 2.4 MID 4WD"
  },
  {
    SERIAL: "01ZB0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO SMART CAB 2.8 HIGH 4WD",
    label: "REVO SMART CAB 2.8 HIGH 4WD",
    value: "REVO SMART CAB 2.8 HIGH 4WD"
  },
  {
    SERIAL: "01ZC0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 SPORT",
    label: "YARIS ATIV 1.2 SPORT",
    value: "YARIS ATIV 1.2 SPORT"
  },
  {
    SERIAL: "01ZD0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS ATIV 1.2 SPORT PREMIUM",
    label: "YARIS ATIV 1.2 SPORT PREMIUM",
    value: "YARIS ATIV 1.2 SPORT PREMIUM"
  },
  {
    SERIAL: "01ZE0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 SPORT",
    label: "YARIS 1.2 SPORT",
    value: "YARIS 1.2 SPORT"
  },
  {
    SERIAL: "01ZF0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "YARIS 1.2 SPORT PREMIUM",
    label: "YARIS 1.2 SPORT PREMIUM",
    value: "YARIS 1.2 SPORT PREMIUM"
  },
  {
    SERIAL: "01ZG0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO ROCCO SMART CAB 2.8 G 4WD",
    label: "REVO ROCCO SMART CAB 2.8 G 4WD",
    value: "REVO ROCCO SMART CAB 2.8 G 4WD"
  },
  {
    SERIAL: "01ZH0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 G",
    label: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 G",
    value: "REVO ROCCO DOUBLE CAB PRERUNNER 2.4 G"
  },
  {
    SERIAL: "01ZI0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA CRYSTA 2.0 ENTRY",
    label: "INNOVA CRYSTA 2.0 ENTRY",
    value: "INNOVA CRYSTA 2.0 ENTRY"
  },
  {
    SERIAL: "01ZJ0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA CRYSTA 2.8",
    label: "INNOVA CRYSTA 2.8",
    value: "INNOVA CRYSTA 2.8"
  },
  {
    SERIAL: "01ZK0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "INNOVA CRYSTA 2.8 PREMIUM",
    label: "INNOVA CRYSTA 2.8 PREMIUM",
    value: "INNOVA CRYSTA 2.8 PREMIUM"
  },
  {
    SERIAL: "01ZL0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "ALPHARD 2.5 SRC HYBRID (HERTZ)",
    label: "ALPHARD 2.5 SRC HYBRID (HERTZ)",
    value: "ALPHARD 2.5 SRC HYBRID (HERTZ)"
  },
  {
    SERIAL: "01ZM0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "HIACE MAJESTY",
    label: "HIACE MAJESTY",
    value: "HIACE MAJESTY"
  },
  {
    SERIAL: "01ZN0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS HYBRID SMART 1.8",
    label: "COROLLA ALTIS HYBRID SMART 1.8",
    value: "COROLLA ALTIS HYBRID SMART 1.8"
  },
  {
    SERIAL: "01ZO0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS HYBRID PREMIUM 1.8",
    label: "COROLLA ALTIS HYBRID PREMIUM 1.8",
    value: "COROLLA ALTIS HYBRID PREMIUM 1.8"
  },
  {
    SERIAL: "01ZP0000026",
    MAKE_NAME: "TOYOTA",
    MODEL_NAME: "COROLLA ALTIS HYBRID PREMIUM SAFETY 1.8",
    label: "COROLLA ALTIS HYBRID PREMIUM SAFETY 1.8",
    value: "COROLLA ALTIS HYBRID PREMIUM SAFETY 1.8"
  },
  {
    SERIAL: "2000000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "HONDA",
    label: "HONDA",
    value: "HONDA"
  },
  {
    SERIAL: "2010000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC",
    label: "CIVIC",
    value: "CIVIC"
  },
  {
    SERIAL: "2020000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.5 LX 3Dr.",
    label: "CIVIC 1.5 LX 3Dr.",
    value: "CIVIC 1.5 LX 3Dr."
  },
  {
    SERIAL: "2030000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.5 EX 3Dr.",
    label: "CIVIC 1.5 EX 3Dr.",
    value: "CIVIC 1.5 EX 3Dr."
  },
  {
    SERIAL: "2040000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.5 LX",
    label: "CIVIC 1.5 LX",
    value: "CIVIC 1.5 LX"
  },
  {
    SERIAL: "2050000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.5 EX",
    label: "CIVIC 1.5 EX",
    value: "CIVIC 1.5 EX"
  },
  {
    SERIAL: "2060000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 LX-S",
    label: "CIVIC 1.6 LX-S",
    value: "CIVIC 1.6 LX-S"
  },
  {
    SERIAL: "2070000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 LXI (Y.1996)",
    label: "CIVIC 1.6 LXI (Y.1996)",
    value: "CIVIC 1.6 LXI (Y.1996)"
  },
  {
    SERIAL: "2080000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 EXI",
    label: "CIVIC 1.6 EXI",
    value: "CIVIC 1.6 EXI"
  },
  {
    SERIAL: "2090000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 LXI",
    label: "CIVIC 1.6 LXI",
    value: "CIVIC 1.6 LXI"
  },
  {
    SERIAL: "2100000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 EXI",
    label: "CIVIC 1.6 EXI",
    value: "CIVIC 1.6 EXI"
  },
  {
    SERIAL: "2110000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 VTi-L",
    label: "CIVIC 1.6 VTi-L",
    value: "CIVIC 1.6 VTi-L"
  },
  {
    SERIAL: "2120000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 VTi-E",
    label: "CIVIC 1.6 VTi-E",
    value: "CIVIC 1.6 VTi-E"
  },
  {
    SERIAL: "2130000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 VTi-LA ABS",
    label: "CIVIC 1.6 VTi-LA ABS",
    value: "CIVIC 1.6 VTi-LA ABS"
  },
  {
    SERIAL: "2140000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 VTi-EA ABS",
    label: "CIVIC 1.6 VTi-EA ABS",
    value: "CIVIC 1.6 VTi-EA ABS"
  },
  {
    SERIAL: "2150000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.3 LI",
    label: "CITY 1.3 LI",
    value: "CITY 1.3 LI"
  },
  {
    SERIAL: "2160000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.3 LXI",
    label: "CITY 1.3 LXI",
    value: "CITY 1.3 LXI"
  },
  {
    SERIAL: "2170000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.3 EXI",
    label: "CITY 1.3 EXI",
    value: "CITY 1.3 EXI"
  },
  {
    SERIAL: "2180000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD",
    label: "ACCORD",
    value: "ACCORD"
  },
  {
    SERIAL: "2190000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 LX",
    label: "ACCORD 2.0 LX",
    value: "ACCORD 2.0 LX"
  },
  {
    SERIAL: "2200000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 EX",
    label: "ACCORD 2.0 EX",
    value: "ACCORD 2.0 EX"
  },
  {
    SERIAL: "2210000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 LXI",
    label: "ACCORD 2.0 LXI",
    value: "ACCORD 2.0 LXI"
  },
  {
    SERIAL: "2220000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 EXI",
    label: "ACCORD 2.0 EXI",
    value: "ACCORD 2.0 EXI"
  },
  {
    SERIAL: "2230000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.2 LXI",
    label: "ACCORD 2.2 LXI",
    value: "ACCORD 2.2 LXI"
  },
  {
    SERIAL: "2240000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.2 EXI",
    label: "ACCORD 2.2 EXI",
    value: "ACCORD 2.2 EXI"
  },
  {
    SERIAL: "2250000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.2 VTI-L",
    label: "ACCORD 2.2 VTI-L",
    value: "ACCORD 2.2 VTI-L"
  },
  {
    SERIAL: "2260000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.2 VTI-E",
    label: "ACCORD 2.2 VTI-E",
    value: "ACCORD 2.2 VTI-E"
  },
  {
    SERIAL: "2270000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.2 VTI-S",
    label: "ACCORD 2.2 VTI-S",
    value: "ACCORD 2.2 VTI-S"
  },
  {
    SERIAL: "2280000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STEP WAGON",
    label: "STEP WAGON",
    value: "STEP WAGON"
  },
  {
    SERIAL: "2290000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "INTEGRA 1.8 L",
    label: "INTEGRA 1.8 L",
    value: "INTEGRA 1.8 L"
  },
  {
    SERIAL: "2310000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "PRELUDE 2.2 L",
    label: "PRELUDE 2.2 L",
    value: "PRELUDE 2.2 L"
  },
  {
    SERIAL: "2320000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "PRELUDE 2.2 L A",
    label: "PRELUDE 2.2 L A",
    value: "PRELUDE 2.2 L A"
  },
  {
    SERIAL: "2330000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "LEGEND 3.2 L V6",
    label: "LEGEND 3.2 L V6",
    value: "LEGEND 3.2 L V6"
  },
  {
    SERIAL: "2340000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NSX 3.0L VTEC",
    label: "NSX 3.0L VTEC",
    value: "NSX 3.0L VTEC"
  },
  {
    SERIAL: "2370000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ODYSSEY",
    label: "ODYSSEY",
    value: "ODYSSEY"
  },
  {
    SERIAL: "2380000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VIGOR 3.0",
    label: "VIGOR 3.0",
    value: "VIGOR 3.0"
  },
  {
    SERIAL: "2390000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "TOURMASTER LXS",
    label: "TOURMASTER LXS",
    value: "TOURMASTER LXS"
  },
  {
    SERIAL: "2400000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "PRELUDE",
    label: "PRELUDE",
    value: "PRELUDE"
  },
  {
    SERIAL: "2410000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.0",
    label: "CR-V 2.0",
    value: "CR-V 2.0"
  },
  {
    SERIAL: "2420000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "TOURMASTER LX",
    label: "TOURMASTER LX",
    value: "TOURMASTER LX"
  },
  {
    SERIAL: "2450000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY S(ABS) 1.3",
    label: "CITY S(ABS) 1.3",
    value: "CITY S(ABS) 1.3"
  },
  {
    SERIAL: "2460000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 LXi",
    label: "CITY 1.5 LXi",
    value: "CITY 1.5 LXi"
  },
  {
    SERIAL: "2470000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 EXi",
    label: "CITY 1.5 EXi",
    value: "CITY 1.5 EXi"
  },
  {
    SERIAL: "2480000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 LXiA (AIRBAG)",
    label: "CITY 1.5 LXiA (AIRBAG)",
    value: "CITY 1.5 LXiA (AIRBAG)"
  },
  {
    SERIAL: "2490000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 EXiA(AIRBAG)",
    label: "CITY 1.5 EXiA(AIRBAG)",
    value: "CITY 1.5 EXiA(AIRBAG)"
  },
  {
    SERIAL: "2500000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 LXi AS (AIRBAG ABS)",
    label: "CITY 1.5 LXi AS (AIRBAG ABS)",
    value: "CITY 1.5 LXi AS (AIRBAG ABS)"
  },
  {
    SERIAL: "2510000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 EXi AS (AIRBAG ABS)",
    label: "CITY 1.5 EXi AS (AIRBAG ABS)",
    value: "CITY 1.5 EXi AS (AIRBAG ABS)"
  },
  {
    SERIAL: "2520000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.3 VTL",
    label: "ACCORD 2.3 VTL",
    value: "ACCORD 2.3 VTL"
  },
  {
    SERIAL: "2530000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.3 VTE",
    label: "ACCORD 2.3 VTE",
    value: "ACCORD 2.3 VTE"
  },
  {
    SERIAL: "2540000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 3.0 V.6",
    label: "ACCORD 3.0 V.6",
    value: "ACCORD 3.0 V.6"
  },
  {
    SERIAL: "2550000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY",
    label: "CITY",
    value: "CITY"
  },
  {
    SERIAL: "2570000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 COUPE",
    label: "CIVIC 1.6 COUPE",
    value: "CIVIC 1.6 COUPE"
  },
  {
    SERIAL: "2600000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.3 VTIA (LEATHER)",
    label: "ACCORD 2.3 VTIA (LEATHER)",
    value: "ACCORD 2.3 VTIA (LEATHER)"
  },
  {
    SERIAL: "2610000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z LI",
    label: "CITY TYPE Z LI",
    value: "CITY TYPE Z LI"
  },
  {
    SERIAL: "2620000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z EXI MT",
    label: "CITY TYPE Z EXI MT",
    value: "CITY TYPE Z EXI MT"
  },
  {
    SERIAL: "2630000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z EXI AT",
    label: "CITY TYPE Z EXI AT",
    value: "CITY TYPE Z EXI AT"
  },
  {
    SERIAL: "2640000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z VTI MT",
    label: "CITY TYPE Z VTI MT",
    value: "CITY TYPE Z VTI MT"
  },
  {
    SERIAL: "2650000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z VTI AT",
    label: "CITY TYPE Z VTI AT",
    value: "CITY TYPE Z VTI AT"
  },
  {
    SERIAL: "2660000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 SI (EXECUTIVE)",
    label: "CIVIC 1.8 SI (EXECUTIVE)",
    value: "CIVIC 1.8 SI (EXECUTIVE)"
  },
  {
    SERIAL: "2670000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 COUPE VTEC LEV",
    label: "CIVIC 1.6 COUPE VTEC LEV",
    value: "CIVIC 1.6 COUPE VTEC LEV"
  },
  {
    SERIAL: "2680000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 VTEC LEV MT 1.6",
    label: "CIVIC 1.6 VTEC LEV MT 1.6",
    value: "CIVIC 1.6 VTEC LEV MT 1.6"
  },
  {
    SERIAL: "2690000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 VTEC LEV AT",
    label: "CIVIC 1.6 VTEC LEV AT",
    value: "CIVIC 1.6 VTEC LEV AT"
  },
  {
    SERIAL: "2700000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.6 VTEC LEV AT ABS",
    label: "CIVIC 1.6 VTEC LEV AT ABS",
    value: "CIVIC 1.6 VTEC LEV AT ABS"
  },
  {
    SERIAL: "2720000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.3 EXI",
    label: "ACCORD 2.3 EXI",
    value: "ACCORD 2.3 EXI"
  },
  {
    SERIAL: "2730000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z 1.5 MILLENNIUM VERSION LXI",
    label: "CITY TYPE Z 1.5 MILLENNIUM VERSION LXI",
    value: "CITY TYPE Z 1.5 MILLENNIUM VERSION LXI"
  },
  {
    SERIAL: "2740000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z 1.5 MILLENNIUM VERSION EXI",
    label: "CITY TYPE Z 1.5 MILLENNIUM VERSION EXI",
    value: "CITY TYPE Z 1.5 MILLENNIUM VERSION EXI"
  },
  {
    SERIAL: "2750000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "S2000 (2.0) L",
    label: "S2000 (2.0) L",
    value: "S2000 (2.0) L"
  },
  {
    SERIAL: "2760000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.3",
    label: "CITY 1.3",
    value: "CITY 1.3"
  },
  {
    SERIAL: "2770000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 LI",
    label: "CITY 1.5 LI",
    value: "CITY 1.5 LI"
  },
  {
    SERIAL: "2780000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY TYPE Z SPROT EXI",
    label: "CITY TYPE Z SPROT EXI",
    value: "CITY TYPE Z SPROT EXI"
  },
  {
    SERIAL: "2790000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "LEGEND 3.5L V6",
    label: "LEGEND 3.5L V6",
    value: "LEGEND 3.5L V6"
  },
  {
    SERIAL: "2800000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ODYSSEY 2.3 VTEC",
    label: "ODYSSEY 2.3 VTEC",
    value: "ODYSSEY 2.3 VTEC"
  },
  {
    SERIAL: "2810000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC VTEC AUTO",
    label: "CIVIC VTEC AUTO",
    value: "CIVIC VTEC AUTO"
  },
  {
    SERIAL: "2830000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC DIMENSION (M)",
    label: "CIVIC DIMENSION (M)",
    value: "CIVIC DIMENSION (M)"
  },
  {
    SERIAL: "2840000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC DIMENSION (A)",
    label: "CIVIC DIMENSION (A)",
    value: "CIVIC DIMENSION (A)"
  },
  {
    SERIAL: "2850000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC DIMENSTION (VTEC) M.",
    label: "CIVIC DIMENSTION (VTEC) M.",
    value: "CIVIC DIMENSTION (VTEC) M."
  },
  {
    SERIAL: "2860000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC DIMENSION(VTEC) A",
    label: "CIVIC DIMENSION(VTEC) A",
    value: "CIVIC DIMENSION(VTEC) A"
  },
  {
    SERIAL: "2870000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC DIMENSION(VTEC)A. ABS",
    label: "CIVIC DIMENSION(VTEC)A. ABS",
    value: "CIVIC DIMENSION(VTEC)A. ABS"
  },
  {
    SERIAL: "2880000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC DIMENSION (VTEC)A. ASL",
    label: "CIVIC DIMENSION (VTEC)A. ASL",
    value: "CIVIC DIMENSION (VTEC)A. ASL"
  },
  {
    SERIAL: "2890000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.0",
    label: "CR-V 2.0",
    value: "CR-V 2.0"
  },
  {
    SERIAL: "2900000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STREED",
    label: "STREED",
    value: "STREED"
  },
  {
    SERIAL: "2910000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STREAM",
    label: "STREAM",
    value: "STREAM"
  },
  {
    SERIAL: "2920000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY A (M)",
    label: "CITY A (M)",
    value: "CITY A (M)"
  },
  {
    SERIAL: "2930000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY A (A)",
    label: "CITY A (A)",
    value: "CITY A (A)"
  },
  {
    SERIAL: "2940000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY A (M) AIRBAG ABS",
    label: "CITY A (M) AIRBAG ABS",
    value: "CITY A (M) AIRBAG ABS"
  },
  {
    SERIAL: "2950000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY A (A) AIRBAG ABS",
    label: "CITY A (A) AIRBAG ABS",
    value: "CITY A (A) AIRBAG ABS"
  },
  {
    SERIAL: "2960000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY S (M)",
    label: "CITY S (M)",
    value: "CITY S (M)"
  },
  {
    SERIAL: "2970000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY S (A)",
    label: "CITY S (A)",
    value: "CITY S (A)"
  },
  {
    SERIAL: "2980000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY S (M) AIRBAG ABS",
    label: "CITY S (M) AIRBAG ABS",
    value: "CITY S (M) AIRBAG ABS"
  },
  {
    SERIAL: "2990000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY S (A) AIRBAG ABS",
    label: "CITY S (A) AIRBAG ABS",
    value: "CITY S (A) AIRBAG ABS"
  },
  {
    SERIAL: "02AA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY E (M) AIRBAG ABS",
    label: "CITY E (M) AIRBAG ABS",
    value: "CITY E (M) AIRBAG ABS"
  },
  {
    SERIAL: "02AB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY E (A) AIRBAG ABS",
    label: "CITY E (A) AIRBAG ABS",
    value: "CITY E (A) AIRBAG ABS"
  },
  {
    SERIAL: "02AC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 S",
    label: "ACCORD 2.4 S",
    value: "ACCORD 2.4 S"
  },
  {
    SERIAL: "02AD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 E",
    label: "ACCORD 2.4 E",
    value: "ACCORD 2.4 E"
  },
  {
    SERIAL: "02AE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 EL",
    label: "ACCORD 2.4 EL",
    value: "ACCORD 2.4 EL"
  },
  {
    SERIAL: "02AF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 3.0 L",
    label: "ACCORD 3.0 L",
    value: "ACCORD 3.0 L"
  },
  {
    SERIAL: "02AG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "LEGEND 3.5 (NEW)",
    label: "LEGEND 3.5 (NEW)",
    value: "LEGEND 3.5 (NEW)"
  },
  {
    SERIAL: "02AH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 2.0 EXCITES",
    label: "CIVIC 2.0 EXCITES",
    value: "CIVIC 2.0 EXCITES"
  },
  {
    SERIAL: "02AI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "TENA",
    label: "TENA",
    value: "TENA"
  },
  {
    SERIAL: "02AJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NICE",
    label: "NICE",
    value: "NICE"
  },
  {
    SERIAL: "02AK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 125",
    label: "WAVE 125",
    value: "WAVE 125"
  },
  {
    SERIAL: "02AL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NSR",
    label: "NSR",
    value: "NSR"
  },
  {
    SERIAL: "02AM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S (M)",
    label: "JAZZ S (M)",
    value: "JAZZ S (M)"
  },
  {
    SERIAL: "02AN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S (A)",
    label: "JAZZ S (A)",
    value: "JAZZ S (A)"
  },
  {
    SERIAL: "02AO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S (M)(AS)",
    label: "JAZZ S (M)(AS)",
    value: "JAZZ S (M)(AS)"
  },
  {
    SERIAL: "02AP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S (A)(AS)",
    label: "JAZZ S (A)(AS)",
    value: "JAZZ S (A)(AS)"
  },
  {
    SERIAL: "02AQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ E (M)",
    label: "JAZZ E (M)",
    value: "JAZZ E (M)"
  },
  {
    SERIAL: "02AR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ E (A)",
    label: "JAZZ E (A)",
    value: "JAZZ E (A)"
  },
  {
    SERIAL: "02AS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ E (M)(AS)",
    label: "JAZZ E (M)(AS)",
    value: "JAZZ E (M)(AS)"
  },
  {
    SERIAL: "02AU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "DREAM",
    label: "DREAM",
    value: "DREAM"
  },
  {
    SERIAL: "02AV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NOVA SONIC",
    label: "NOVA SONIC",
    value: "NOVA SONIC"
  },
  {
    SERIAL: "02AW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STREAM (Y.2004)",
    label: "STREAM (Y.2004)",
    value: "STREAM (Y.2004)"
  },
  {
    SERIAL: "02AX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY VTEC (M)",
    label: "CITY VTEC (M)",
    value: "CITY VTEC (M)"
  },
  {
    SERIAL: "02AY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY VTEC E AT",
    label: "CITY VTEC E AT",
    value: "CITY VTEC E AT"
  },
  {
    SERIAL: "02AZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY VTEC (M) AIRBAG ABS",
    label: "CITY VTEC (M) AIRBAG ABS",
    value: "CITY VTEC (M) AIRBAG ABS"
  },
  {
    SERIAL: "02BA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY VTEC (A) AIRBAG ABS",
    label: "CITY VTEC (A) AIRBAG ABS",
    value: "CITY VTEC (A) AIRBAG ABS"
  },
  {
    SERIAL: "02BB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY SPORT S (A)",
    label: "CITY SPORT S (A)",
    value: "CITY SPORT S (A)"
  },
  {
    SERIAL: "02BC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD SPORT",
    label: "ACCORD SPORT",
    value: "ACCORD SPORT"
  },
  {
    SERIAL: "02BD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ODYSSEY (NEW)",
    label: "ODYSSEY (NEW)",
    value: "ODYSSEY (NEW)"
  },
  {
    SERIAL: "02BF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ELYSION",
    label: "ELYSION",
    value: "ELYSION"
  },
  {
    SERIAL: "02BG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ (SPORT)",
    label: "JAZZ (SPORT)",
    value: "JAZZ (SPORT)"
  },
  {
    SERIAL: "02BH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ VTEC V (M)",
    label: "JAZZ VTEC V (M)",
    value: "JAZZ VTEC V (M)"
  },
  {
    SERIAL: "02BI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ VTEC V (A)",
    label: "JAZZ VTEC V (A)",
    value: "JAZZ VTEC V (A)"
  },
  {
    SERIAL: "02BJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ VTEC V (A) ABS",
    label: "JAZZ VTEC V (A) ABS",
    value: "JAZZ VTEC V (A) ABS"
  },
  {
    SERIAL: "02BK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY VTEC E (M) ABS",
    label: "CITY VTEC E (M) ABS",
    value: "CITY VTEC E (M) ABS"
  },
  {
    SERIAL: "02BL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY SPORT VTEC (M)",
    label: "CITY SPORT VTEC (M)",
    value: "CITY SPORT VTEC (M)"
  },
  {
    SERIAL: "02BM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY SPORT VTEC (A)",
    label: "CITY SPORT VTEC (A)",
    value: "CITY SPORT VTEC (A)"
  },
  {
    SERIAL: "02BN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY SPORT VTEC (A) ABS",
    label: "CITY SPORT VTEC (A) ABS",
    value: "CITY SPORT VTEC (A) ABS"
  },
  {
    SERIAL: "02BP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V PRESTIGE 2.0",
    label: "CR-V PRESTIGE 2.0",
    value: "CR-V PRESTIGE 2.0"
  },
  {
    SERIAL: "02BQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 E",
    label: "CR-V 2.4 E",
    value: "CR-V 2.4 E"
  },
  {
    SERIAL: "02BR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.3",
    label: "CITY 1.3",
    value: "CITY 1.3"
  },
  {
    SERIAL: "02BS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "DREAM",
    label: "DREAM",
    value: "DREAM"
  },
  {
    SERIAL: "02BT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 S",
    label: "ACCORD 2.0 S",
    value: "ACCORD 2.0 S"
  },
  {
    SERIAL: "02BU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 E",
    label: "ACCORD 2.0 E",
    value: "ACCORD 2.0 E"
  },
  {
    SERIAL: "02BV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 1000",
    label: "CBR 1000",
    value: "CBR 1000"
  },
  {
    SERIAL: "02BW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX (EV)",
    label: "CITY 1.5 ZX (EV)",
    value: "CITY 1.5 ZX (EV)"
  },
  {
    SERIAL: "02BX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX (SV) AT",
    label: "CITY 1.5 ZX (SV) AT",
    value: "CITY 1.5 ZX (SV) AT"
  },
  {
    SERIAL: "02BY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX (SV) MT",
    label: "CITY 1.5 ZX (SV) MT",
    value: "CITY 1.5 ZX (SV) MT"
  },
  {
    SERIAL: "02BZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX (V) AT",
    label: "CITY 1.5 ZX (V) AT",
    value: "CITY 1.5 ZX (V) AT"
  },
  {
    SERIAL: "02CA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX (V) MT",
    label: "CITY 1.5 ZX (V) MT",
    value: "CITY 1.5 ZX (V) MT"
  },
  {
    SERIAL: "02CB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX (A) AT",
    label: "CITY 1.5 ZX (A) AT",
    value: "CITY 1.5 ZX (A) AT"
  },
  {
    SERIAL: "02CC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX (A) MT",
    label: "CITY 1.5 ZX (A) MT",
    value: "CITY 1.5 ZX (A) MT"
  },
  {
    SERIAL: "02CD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ COOL EV AT AS",
    label: "JAZZ COOL EV AT AS",
    value: "JAZZ COOL EV AT AS"
  },
  {
    SERIAL: "02CE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 S (M)",
    label: "CIVIC 1.8 S (M)",
    value: "CIVIC 1.8 S (M)"
  },
  {
    SERIAL: "02CF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 S (AT)",
    label: "CIVIC 1.8 S (AT)",
    value: "CIVIC 1.8 S (AT)"
  },
  {
    SERIAL: "02CG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 S (AT)AS",
    label: "CIVIC 1.8 S (AT)AS",
    value: "CIVIC 1.8 S (AT)AS"
  },
  {
    SERIAL: "02CH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 E (M) AS",
    label: "CIVIC 1.8 E (M) AS",
    value: "CIVIC 1.8 E (M) AS"
  },
  {
    SERIAL: "02CI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 E (A) AS",
    label: "CIVIC 1.8 E (A) AS",
    value: "CIVIC 1.8 E (A) AS"
  },
  {
    SERIAL: "02CJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 2.0 E (A) AS",
    label: "CIVIC 2.0 E (A) AS",
    value: "CIVIC 2.0 E (A) AS"
  },
  {
    SERIAL: "02CK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 2.0 EL (A) AS",
    label: "CIVIC 2.0 EL (A) AS",
    value: "CIVIC 2.0 EL (A) AS"
  },
  {
    SERIAL: "02CL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VTR1000SP2",
    label: "VTR1000SP2",
    value: "VTR1000SP2"
  },
  {
    SERIAL: "02CM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 900 HORNET",
    label: "CB 900 HORNET",
    value: "CB 900 HORNET"
  },
  {
    SERIAL: "02CN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CLICK",
    label: "CLICK",
    value: "CLICK"
  },
  {
    SERIAL: "02CO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "SHADOW",
    label: "SHADOW",
    value: "SHADOW"
  },
  {
    SERIAL: "02CQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NOVA SONIC",
    label: "NOVA SONIC",
    value: "NOVA SONIC"
  },
  {
    SERIAL: "02CR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.2",
    label: "ACCORD 2.2",
    value: "ACCORD 2.2"
  },
  {
    SERIAL: "02CS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "AIR BLADE",
    label: "AIR BLADE",
    value: "AIR BLADE"
  },
  {
    SERIAL: "02CT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.0 S 4*2",
    label: "CR-V 2.0 S 4*2",
    value: "CR-V 2.0 S 4*2"
  },
  {
    SERIAL: "02CU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.0 E 4*4",
    label: "CR-V 2.0 E 4*4",
    value: "CR-V 2.0 E 4*4"
  },
  {
    SERIAL: "02CW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 EL 4*4",
    label: "CR-V 2.4 EL 4*4",
    value: "CR-V 2.4 EL 4*4"
  },
  {
    SERIAL: "02CY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX SV AT AS",
    label: "CITY 1.5 ZX SV AT AS",
    value: "CITY 1.5 ZX SV AT AS"
  },
  {
    SERIAL: "02CZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ SV VTEC (A) AS",
    label: "JAZZ SV VTEC (A) AS",
    value: "JAZZ SV VTEC (A) AS"
  },
  {
    SERIAL: "02DA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VTX 1800",
    label: "VTX 1800",
    value: "VTX 1800"
  },
  {
    SERIAL: "02DB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STEP WAGON",
    label: "STEP WAGON",
    value: "STEP WAGON"
  },
  {
    SERIAL: "02DC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY ZX SPORT SV AT AS",
    label: "CITY ZX SPORT SV AT AS",
    value: "CITY ZX SPORT SV AT AS"
  },
  {
    SERIAL: "02DD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR",
    label: "CBR",
    value: "CBR"
  },
  {
    SERIAL: "02DE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 E (Y.2007)",
    label: "ACCORD 2.0 E (Y.2007)",
    value: "ACCORD 2.0 E (Y.2007)"
  },
  {
    SERIAL: "02DF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 E (Y.2007)",
    label: "ACCORD 2.4 E (Y.2007)",
    value: "ACCORD 2.4 E (Y.2007)"
  },
  {
    SERIAL: "02DG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 EL (Y.2007)",
    label: "ACCORD 2.4 EL (Y.2007)",
    value: "ACCORD 2.4 EL (Y.2007)"
  },
  {
    SERIAL: "02DH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 EL NAVIGATOR",
    label: "ACCORD 2.4 EL NAVIGATOR",
    value: "ACCORD 2.4 EL NAVIGATOR"
  },
  {
    SERIAL: "02DI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 3.5 V6",
    label: "ACCORD 3.5 V6",
    value: "ACCORD 3.5 V6"
  },
  {
    SERIAL: "02DJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S MT VTEC ABS",
    label: "JAZZ S MT VTEC ABS",
    value: "JAZZ S MT VTEC ABS"
  },
  {
    SERIAL: "02DK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S AT VTEC ABS",
    label: "JAZZ S AT VTEC ABS",
    value: "JAZZ S AT VTEC ABS"
  },
  {
    SERIAL: "02DL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ V AT VTEC ABS",
    label: "JAZZ V AT VTEC ABS",
    value: "JAZZ V AT VTEC ABS"
  },
  {
    SERIAL: "02DM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ V AT VTEC ABS AIRBAG",
    label: "JAZZ V AT VTEC ABS AIRBAG",
    value: "JAZZ V AT VTEC ABS AIRBAG"
  },
  {
    SERIAL: "02DN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ SV AT VTEC ABS AIRBAG",
    label: "JAZZ SV AT VTEC ABS AIRBAG",
    value: "JAZZ SV AT VTEC ABS AIRBAG"
  },
  {
    SERIAL: "02DO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CROSSROAD 2.0",
    label: "CROSSROAD 2.0",
    value: "CROSSROAD 2.0"
  },
  {
    SERIAL: "02DQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC EXI A/T",
    label: "CIVIC EXI A/T",
    value: "CIVIC EXI A/T"
  },
  {
    SERIAL: "02DR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.S (M)",
    label: "CITY.S (M)",
    value: "CITY.S (M)"
  },
  {
    SERIAL: "02DS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.S (A)",
    label: "CITY.S (A)",
    value: "CITY.S (A)"
  },
  {
    SERIAL: "02DT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.V (A) ABS",
    label: "CITY.V (A) ABS",
    value: "CITY.V (A) ABS"
  },
  {
    SERIAL: "02DU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.V (A) AS",
    label: "CITY.V (A) AS",
    value: "CITY.V (A) AS"
  },
  {
    SERIAL: "02DV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.SV AT (AS)",
    label: "CITY.SV AT (AS)",
    value: "CITY.SV AT (AS)"
  },
  {
    SERIAL: "02DW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 1300",
    label: "CB 1300",
    value: "CB 1300"
  },
  {
    SERIAL: "02DX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "DREAM",
    label: "DREAM",
    value: "DREAM"
  },
  {
    SERIAL: "02DY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VALKYRIE",
    label: "VALKYRIE",
    value: "VALKYRIE"
  },
  {
    SERIAL: "02DZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 1300",
    label: "CB 1300",
    value: "CB 1300"
  },
  {
    SERIAL: "02EA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FREED S A",
    label: "FREED S A",
    value: "FREED S A"
  },
  {
    SERIAL: "02EB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FREED E A",
    label: "FREED E A",
    value: "FREED E A"
  },
  {
    SERIAL: "02EC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FREED E SPORT A",
    label: "FREED E SPORT A",
    value: "FREED E SPORT A"
  },
  {
    SERIAL: "02ED0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FREED E NAVIGATOR A",
    label: "FREED E NAVIGATOR A",
    value: "FREED E NAVIGATOR A"
  },
  {
    SERIAL: "02EE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "GOLD WING GL1800",
    label: "GOLD WING GL1800",
    value: "GOLD WING GL1800"
  },
  {
    SERIAL: "02EF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NT700 V BASE",
    label: "NT700 V BASE",
    value: "NT700 V BASE"
  },
  {
    SERIAL: "02EG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ST1300 BASE",
    label: "ST1300 BASE",
    value: "ST1300 BASE"
  },
  {
    SERIAL: "02EH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FURY VT1300",
    label: "FURY VT1300",
    value: "FURY VT1300"
  },
  {
    SERIAL: "02EI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "INTERSTATE BASE",
    label: "INTERSTATE BASE",
    value: "INTERSTATE BASE"
  },
  {
    SERIAL: "02EJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VTX 1800",
    label: "VTX 1800",
    value: "VTX 1800"
  },
  {
    SERIAL: "02EK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VTX 1300 - BUILT",
    label: "VTX 1300 - BUILT",
    value: "VTX 1300 - BUILT"
  },
  {
    SERIAL: "02EM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ODYSSEY 2.4 E",
    label: "ODYSSEY 2.4 E",
    value: "ODYSSEY 2.4 E"
  },
  {
    SERIAL: "02EN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 125",
    label: "WAVE 125",
    value: "WAVE 125"
  },
  {
    SERIAL: "02EO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STEP WAGON",
    label: "STEP WAGON",
    value: "STEP WAGON"
  },
  {
    SERIAL: "02EP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 EL 4*4 ASL SAB",
    label: "CR-V 2.4 EL 4*4 ASL SAB",
    value: "CR-V 2.4 EL 4*4 ASL SAB"
  },
  {
    SERIAL: "02EQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VALKYRIE",
    label: "VALKYRIE",
    value: "VALKYRIE"
  },
  {
    SERIAL: "02ER0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 EL 4*2",
    label: "CR-V 2.4 EL 4*2",
    value: "CR-V 2.4 EL 4*2"
  },
  {
    SERIAL: "02ES0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO S 1.2 M",
    label: "BRIO S 1.2 M",
    value: "BRIO S 1.2 M"
  },
  {
    SERIAL: "02ET0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO V 1.2 M",
    label: "BRIO V 1.2 M",
    value: "BRIO V 1.2 M"
  },
  {
    SERIAL: "02EU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO V 1.2 A",
    label: "BRIO V 1.2 A",
    value: "BRIO V 1.2 A"
  },
  {
    SERIAL: "02EV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "SCOOPY I",
    label: "SCOOPY I",
    value: "SCOOPY I"
  },
  {
    SERIAL: "02EW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "PCX 125",
    label: "PCX 125",
    value: "PCX 125"
  },
  {
    SERIAL: "02EX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 ZX AT",
    label: "CITY 1.5 ZX AT",
    value: "CITY 1.5 ZX AT"
  },
  {
    SERIAL: "02EY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ V ACTIVE PLUS",
    label: "JAZZ V ACTIVE PLUS",
    value: "JAZZ V ACTIVE PLUS"
  },
  {
    SERIAL: "02EZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 EL",
    label: "ACCORD 2.0 EL",
    value: "ACCORD 2.0 EL"
  },
  {
    SERIAL: "02FA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 EL NAVIGATOR",
    label: "ACCORD 2.0 EL NAVIGATOR",
    value: "ACCORD 2.0 EL NAVIGATOR"
  },
  {
    SERIAL: "02FB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 600",
    label: "CBR 600",
    value: "CBR 600"
  },
  {
    SERIAL: "02FC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "DN-01",
    label: "DN-01",
    value: "DN-01"
  },
  {
    SERIAL: "02FD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FURY VT1300 ABS",
    label: "FURY VT1300 ABS",
    value: "FURY VT1300 ABS"
  },
  {
    SERIAL: "02FE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 1000",
    label: "CBR 1000",
    value: "CBR 1000"
  },
  {
    SERIAL: "02FF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 S MT",
    label: "CIVIC 1.8 S MT",
    value: "CIVIC 1.8 S MT"
  },
  {
    SERIAL: "02FG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 S AT",
    label: "CIVIC 1.8 S AT",
    value: "CIVIC 1.8 S AT"
  },
  {
    SERIAL: "02FH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 E AT",
    label: "CIVIC 1.8 E AT",
    value: "CIVIC 1.8 E AT"
  },
  {
    SERIAL: "02FI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 E AT NAVIGATOR",
    label: "CIVIC 1.8 E AT NAVIGATOR",
    value: "CIVIC 1.8 E AT NAVIGATOR"
  },
  {
    SERIAL: "02FJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 2.0 EL NAVIGATOR",
    label: "CIVIC 2.0 EL NAVIGATOR",
    value: "CIVIC 2.0 EL NAVIGATOR"
  },
  {
    SERIAL: "02FK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "INSIGHT HYBRID",
    label: "INSIGHT HYBRID",
    value: "INSIGHT HYBRID"
  },
  {
    SERIAL: "02FL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NC700 X",
    label: "NC700 X",
    value: "NC700 X"
  },
  {
    SERIAL: "02FM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 E AT NAVIGATOR MODULO",
    label: "CIVIC 1.8 E AT NAVIGATOR MODULO",
    value: "CIVIC 1.8 E AT NAVIGATOR MODULO"
  },
  {
    SERIAL: "02FN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STEP WAGON SPADA 2.0",
    label: "STEP WAGON SPADA 2.0",
    value: "STEP WAGON SPADA 2.0"
  },
  {
    SERIAL: "02FO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ HYBRID 1.3",
    label: "JAZZ HYBRID 1.3",
    value: "JAZZ HYBRID 1.3"
  },
  {
    SERIAL: "02FQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY S 1.5 CNG",
    label: "CITY S 1.5 CNG",
    value: "CITY S 1.5 CNG"
  },
  {
    SERIAL: "02FR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 V CNG",
    label: "CITY 1.5 V CNG",
    value: "CITY 1.5 V CNG"
  },
  {
    SERIAL: "02FS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ZOOMER-X",
    label: "ZOOMER-X",
    value: "ZOOMER-X"
  },
  {
    SERIAL: "02FT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.0 S",
    label: "CR-V 2.0 S",
    value: "CR-V 2.0 S"
  },
  {
    SERIAL: "02FU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.0 E 4WD.",
    label: "CR-V 2.0 E 4WD.",
    value: "CR-V 2.0 E 4WD."
  },
  {
    SERIAL: "02FV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 EL 4*2 (Y.2012)",
    label: "CR-V 2.4 EL 4*2 (Y.2012)",
    value: "CR-V 2.4 EL 4*2 (Y.2012)"
  },
  {
    SERIAL: "02FW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VFR 1200 F",
    label: "VFR 1200 F",
    value: "VFR 1200 F"
  },
  {
    SERIAL: "02FX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 1000 RR",
    label: "CBR 1000 RR",
    value: "CBR 1000 RR"
  },
  {
    SERIAL: "02FY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "INTEGRA (MOTORCYCLE)",
    label: "INTEGRA (MOTORCYCLE)",
    value: "INTEGRA (MOTORCYCLE)"
  },
  {
    SERIAL: "02FZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-Z 1.5 JP HYBRID",
    label: "CR-Z 1.5 JP HYBRID",
    value: "CR-Z 1.5 JP HYBRID"
  },
  {
    SERIAL: "02GA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO AMAZE 1.2 S MT",
    label: "BRIO AMAZE 1.2 S MT",
    value: "BRIO AMAZE 1.2 S MT"
  },
  {
    SERIAL: "02GB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO AMAZE 1.2 S AT",
    label: "BRIO AMAZE 1.2 S AT",
    value: "BRIO AMAZE 1.2 S AT"
  },
  {
    SERIAL: "02GC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO AMAZE 1.2 V MT",
    label: "BRIO AMAZE 1.2 V MT",
    value: "BRIO AMAZE 1.2 V MT"
  },
  {
    SERIAL: "02GD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO AMAZE 1.2 V AT",
    label: "BRIO AMAZE 1.2 V AT",
    value: "BRIO AMAZE 1.2 V AT"
  },
  {
    SERIAL: "02GE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO S 1.2 A",
    label: "BRIO S 1.2 A",
    value: "BRIO S 1.2 A"
  },
  {
    SERIAL: "02GG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 EL 4*4 (Y.2012)",
    label: "CR-V 2.4 EL 4*4 (Y.2012)",
    value: "CR-V 2.4 EL 4*4 (Y.2012)"
  },
  {
    SERIAL: "02GH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FREED SE 1.5",
    label: "FREED SE 1.5",
    value: "FREED SE 1.5"
  },
  {
    SERIAL: "02GI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FREED EL 1.5",
    label: "FREED EL 1.5",
    value: "FREED EL 1.5"
  },
  {
    SERIAL: "02GJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FORZA",
    label: "FORZA",
    value: "FORZA"
  },
  {
    SERIAL: "02GK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC HYBRID 1.5",
    label: "CIVIC HYBRID 1.5",
    value: "CIVIC HYBRID 1.5"
  },
  {
    SERIAL: "02GL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC HYBRID 1.5 NAVIGATOR",
    label: "CIVIC HYBRID 1.5 NAVIGATOR",
    value: "CIVIC HYBRID 1.5 NAVIGATOR"
  },
  {
    SERIAL: "02GM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 EL (Y.2013)",
    label: "ACCORD 2.0 EL (Y.2013)",
    value: "ACCORD 2.0 EL (Y.2013)"
  },
  {
    SERIAL: "02GN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 EL NAVIGATOR (Y.2013)",
    label: "ACCORD 2.0 EL NAVIGATOR (Y.2013)",
    value: "ACCORD 2.0 EL NAVIGATOR (Y.2013)"
  },
  {
    SERIAL: "02GO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 EL (Y.2013)",
    label: "ACCORD 2.4 EL (Y.2013)",
    value: "ACCORD 2.4 EL (Y.2013)"
  },
  {
    SERIAL: "02GP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 EL NAVIGATOR (Y.2013)",
    label: "ACCORD 2.4 EL NAVIGATOR (Y.2013)",
    value: "ACCORD 2.4 EL NAVIGATOR (Y.2013)"
  },
  {
    SERIAL: "02GQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.4 TECH",
    label: "ACCORD 2.4 TECH",
    value: "ACCORD 2.4 TECH"
  },
  {
    SERIAL: "02GR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 100",
    label: "WAVE 100",
    value: "WAVE 100"
  },
  {
    SERIAL: "02GS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-Z",
    label: "CR-Z",
    value: "CR-Z"
  },
  {
    SERIAL: "02GT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "PHANTOM",
    label: "PHANTOM",
    value: "PHANTOM"
  },
  {
    SERIAL: "02GU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 VTEC MODULO",
    label: "CITY 1.5 VTEC MODULO",
    value: "CITY 1.5 VTEC MODULO"
  },
  {
    SERIAL: "02GV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CLICK 125I",
    label: "CLICK 125I",
    value: "CLICK 125I"
  },
  {
    SERIAL: "02GW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO V LIMITED 1.2 A",
    label: "BRIO V LIMITED 1.2 A",
    value: "BRIO V LIMITED 1.2 A"
  },
  {
    SERIAL: "02GY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 110",
    label: "WAVE 110",
    value: "WAVE 110"
  },
  {
    SERIAL: "02GZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 110",
    label: "WAVE 110",
    value: "WAVE 110"
  },
  {
    SERIAL: "02HA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 100",
    label: "WAVE 100",
    value: "WAVE 100"
  },
  {
    SERIAL: "02HB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 V AUTO MODULO",
    label: "CITY 1.5 V AUTO MODULO",
    value: "CITY 1.5 V AUTO MODULO"
  },
  {
    SERIAL: "02HC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 V AUTO MODULO",
    label: "CIVIC 1.8 V AUTO MODULO",
    value: "CIVIC 1.8 V AUTO MODULO"
  },
  {
    SERIAL: "02HD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ 1.5 V MODULO",
    label: "JAZZ 1.5 V MODULO",
    value: "JAZZ 1.5 V MODULO"
  },
  {
    SERIAL: "02HE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ODYSSEY 2.4 EL",
    label: "ODYSSEY 2.4 EL",
    value: "ODYSSEY 2.4 EL"
  },
  {
    SERIAL: "02HF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 110 ELECTRIC START",
    label: "WAVE 110 ELECTRIC START",
    value: "WAVE 110 ELECTRIC START"
  },
  {
    SERIAL: "02HG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.S 1.5 M",
    label: "CITY.S 1.5 M",
    value: "CITY.S 1.5 M"
  },
  {
    SERIAL: "02HH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.S 1.5 A",
    label: "CITY.S 1.5 A",
    value: "CITY.S 1.5 A"
  },
  {
    SERIAL: "02HI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.V 1.5 A",
    label: "CITY.V 1.5 A",
    value: "CITY.V 1.5 A"
  },
  {
    SERIAL: "02HJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.V+ 1.5 A",
    label: "CITY.V+ 1.5 A",
    value: "CITY.V+ 1.5 A"
  },
  {
    SERIAL: "02HK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.SV 1.5 A",
    label: "CITY.SV 1.5 A",
    value: "CITY.SV 1.5 A"
  },
  {
    SERIAL: "02HL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.SV+ 1.5 A",
    label: "CITY.SV+ 1.5 A",
    value: "CITY.SV+ 1.5 A"
  },
  {
    SERIAL: "02HM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "GOLDWING F6B",
    label: "GOLDWING F6B",
    value: "GOLDWING F6B"
  },
  {
    SERIAL: "02HN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 ES",
    label: "CIVIC 1.8 ES",
    value: "CIVIC 1.8 ES"
  },
  {
    SERIAL: "02HO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 2.0 ES NAVIGATOR",
    label: "CIVIC 2.0 ES NAVIGATOR",
    value: "CIVIC 2.0 ES NAVIGATOR"
  },
  {
    SERIAL: "02HP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S 1.5 M",
    label: "JAZZ S 1.5 M",
    value: "JAZZ S 1.5 M"
  },
  {
    SERIAL: "02HQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ S 1.5 A",
    label: "JAZZ S 1.5 A",
    value: "JAZZ S 1.5 A"
  },
  {
    SERIAL: "02HR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ V 1.5 A",
    label: "JAZZ V 1.5 A",
    value: "JAZZ V 1.5 A"
  },
  {
    SERIAL: "02HS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ V+ 1.5 A",
    label: "JAZZ V+ 1.5 A",
    value: "JAZZ V+ 1.5 A"
  },
  {
    SERIAL: "02HT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ SV 1.5 A",
    label: "JAZZ SV 1.5 A",
    value: "JAZZ SV 1.5 A"
  },
  {
    SERIAL: "02HU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ SV+ 1.5 A",
    label: "JAZZ SV+ 1.5 A",
    value: "JAZZ SV+ 1.5 A"
  },
  {
    SERIAL: "02HV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 E (Y.2010)",
    label: "ACCORD 2.0 E (Y.2010)",
    value: "ACCORD 2.0 E (Y.2010)"
  },
  {
    SERIAL: "02HW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.S CNG 1.5 M",
    label: "CITY.S CNG 1.5 M",
    value: "CITY.S CNG 1.5 M"
  },
  {
    SERIAL: "02HX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.S CNG 1.5 A",
    label: "CITY.S CNG 1.5 A",
    value: "CITY.S CNG 1.5 A"
  },
  {
    SERIAL: "02HY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.V CNG 1.5 A",
    label: "CITY.V CNG 1.5 A",
    value: "CITY.V CNG 1.5 A"
  },
  {
    SERIAL: "02HZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MOBILIO S - MT",
    label: "MOBILIO S - MT",
    value: "MOBILIO S - MT"
  },
  {
    SERIAL: "02IA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MOBILIO S - AT",
    label: "MOBILIO S - AT",
    value: "MOBILIO S - AT"
  },
  {
    SERIAL: "02IB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MOBILIO V - AT",
    label: "MOBILIO V - AT",
    value: "MOBILIO V - AT"
  },
  {
    SERIAL: "02IC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MOBILIO RS - AT",
    label: "MOBILIO RS - AT",
    value: "MOBILIO RS - AT"
  },
  {
    SERIAL: "02IE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 HYBRID",
    label: "ACCORD 2.0 HYBRID",
    value: "ACCORD 2.0 HYBRID"
  },
  {
    SERIAL: "02IF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 HYBRID TECH",
    label: "ACCORD 2.0 HYBRID TECH",
    value: "ACCORD 2.0 HYBRID TECH"
  },
  {
    SERIAL: "02IG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 110I MANUAL START",
    label: "WAVE 110I MANUAL START",
    value: "WAVE 110I MANUAL START"
  },
  {
    SERIAL: "02IH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "HR-V 1.8 S",
    label: "HR-V 1.8 S",
    value: "HR-V 1.8 S"
  },
  {
    SERIAL: "02II0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "HR-V 1.8 E",
    label: "HR-V 1.8 E",
    value: "HR-V 1.8 E"
  },
  {
    SERIAL: "02IJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "HR-V 1.8 EL",
    label: "HR-V 1.8 EL",
    value: "HR-V 1.8 EL"
  },
  {
    SERIAL: "02IK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "GOLDWING F6C",
    label: "GOLDWING F6C",
    value: "GOLDWING F6C"
  },
  {
    SERIAL: "02IL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VFR 1200 X",
    label: "VFR 1200 X",
    value: "VFR 1200 X"
  },
  {
    SERIAL: "02IM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 1000 RR SP",
    label: "CBR 1000 RR SP",
    value: "CBR 1000 RR SP"
  },
  {
    SERIAL: "02IN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 1000 RR",
    label: "CBR 1000 RR",
    value: "CBR 1000 RR"
  },
  {
    SERIAL: "02IQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 600 RR",
    label: "CBR 600 RR",
    value: "CBR 600 RR"
  },
  {
    SERIAL: "02IR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NM4",
    label: "NM4",
    value: "NM4"
  },
  {
    SERIAL: "02IS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BR-V 1.5 V (5SEAT)",
    label: "BR-V 1.5 V (5SEAT)",
    value: "BR-V 1.5 V (5SEAT)"
  },
  {
    SERIAL: "02IT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BR-V 1.5 SV (7SEAT)",
    label: "BR-V 1.5 SV (7SEAT)",
    value: "BR-V 1.5 SV (7SEAT)"
  },
  {
    SERIAL: "02IU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 650",
    label: "CBR 650",
    value: "CBR 650"
  },
  {
    SERIAL: "02IV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 E",
    label: "CIVIC 1.8 E",
    value: "CIVIC 1.8 E"
  },
  {
    SERIAL: "02IW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.8 EL",
    label: "CIVIC 1.8 EL",
    value: "CIVIC 1.8 EL"
  },
  {
    SERIAL: "02IX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.5 TURBO",
    label: "CIVIC 1.5 TURBO",
    value: "CIVIC 1.5 TURBO"
  },
  {
    SERIAL: "02IY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.5 TURBO RS",
    label: "CIVIC 1.5 TURBO RS",
    value: "CIVIC 1.5 TURBO RS"
  },
  {
    SERIAL: "02IZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 650F",
    label: "CB 650F",
    value: "CB 650F"
  },
  {
    SERIAL: "02JA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CTX700",
    label: "CTX700",
    value: "CTX700"
  },
  {
    SERIAL: "02JB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "HR-V 1.8 E LIMITED",
    label: "HR-V 1.8 E LIMITED",
    value: "HR-V 1.8 E LIMITED"
  },
  {
    SERIAL: "02JC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 E",
    label: "ACCORD 2.0 E",
    value: "ACCORD 2.0 E"
  },
  {
    SERIAL: "02JD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "PCX 150",
    label: "PCX 150",
    value: "PCX 150"
  },
  {
    SERIAL: "02JE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 1100",
    label: "CB 1100",
    value: "CB 1100"
  },
  {
    SERIAL: "02JF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 500 XA",
    label: "CB 500 XA",
    value: "CB 500 XA"
  },
  {
    SERIAL: "02JG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "AFRICA TWIN CRF1000L MT",
    label: "AFRICA TWIN CRF1000L MT",
    value: "AFRICA TWIN CRF1000L MT"
  },
  {
    SERIAL: "02JH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "AFRICA TWIN CRF1000L DCT",
    label: "AFRICA TWIN CRF1000L DCT",
    value: "AFRICA TWIN CRF1000L DCT"
  },
  {
    SERIAL: "02JI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO AMAZE 1.2 SV A",
    label: "BRIO AMAZE 1.2 SV A",
    value: "BRIO AMAZE 1.2 SV A"
  },
  {
    SERIAL: "02JJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 500F",
    label: "CB 500F",
    value: "CB 500F"
  },
  {
    SERIAL: "02JK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 650 F",
    label: "CBR 650 F",
    value: "CBR 650 F"
  },
  {
    SERIAL: "02JL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "110 CC",
    label: "110 CC",
    value: "110 CC"
  },
  {
    SERIAL: "02JM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "125 CC",
    label: "125 CC",
    value: "125 CC"
  },
  {
    SERIAL: "02JN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "02JO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.0 SE 4WD",
    label: "CR-V 2.0 SE 4WD",
    value: "CR-V 2.0 SE 4WD"
  },
  {
    SERIAL: "02JP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "02JQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CRF250L",
    label: "CRF250L",
    value: "CRF250L"
  },
  {
    SERIAL: "02JR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CTX1300",
    label: "CTX1300",
    value: "CTX1300"
  },
  {
    SERIAL: "02JS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 650FA",
    label: "CB 650FA",
    value: "CB 650FA"
  },
  {
    SERIAL: "02JT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 300R",
    label: "CBR 300R",
    value: "CBR 300R"
  },
  {
    SERIAL: "02JU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "INTEGRA S",
    label: "INTEGRA S",
    value: "INTEGRA S"
  },
  {
    SERIAL: "02JW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V",
    label: "CR-V",
    value: "CR-V"
  },
  {
    SERIAL: "02JX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ",
    label: "JAZZ",
    value: "JAZZ"
  },
  {
    SERIAL: "02JY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC 1.5 TURBO HATCHBACK",
    label: "CIVIC 1.5 TURBO HATCHBACK",
    value: "CIVIC 1.5 TURBO HATCHBACK"
  },
  {
    SERIAL: "02JZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 E BENZINE",
    label: "CR-V 2.4 E BENZINE",
    value: "CR-V 2.4 E BENZINE"
  },
  {
    SERIAL: "02KA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 EL BENZINE 4WD",
    label: "CR-V 2.4 EL BENZINE 4WD",
    value: "CR-V 2.4 EL BENZINE 4WD"
  },
  {
    SERIAL: "02KB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 1.6 E DIESEL TURBO",
    label: "CR-V 1.6 E DIESEL TURBO",
    value: "CR-V 1.6 E DIESEL TURBO"
  },
  {
    SERIAL: "02KC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 1.6 EL DIESEL TURBO 4WD",
    label: "CR-V 1.6 EL DIESEL TURBO 4WD",
    value: "CR-V 1.6 EL DIESEL TURBO 4WD"
  },
  {
    SERIAL: "02KD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "X-ADV",
    label: "X-ADV",
    value: "X-ADV"
  },
  {
    SERIAL: "02KE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "100 CC",
    label: "100 CC",
    value: "100 CC"
  },
  {
    SERIAL: "02KF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "02KG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ RS",
    label: "JAZZ RS",
    value: "JAZZ RS"
  },
  {
    SERIAL: "02KH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "JAZZ RS+",
    label: "JAZZ RS+",
    value: "JAZZ RS+"
  },
  {
    SERIAL: "02KI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR",
    label: "CBR",
    value: "CBR"
  },
  {
    SERIAL: "02KJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "200 CC",
    label: "200 CC",
    value: "200 CC"
  },
  {
    SERIAL: "02KK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "OVER 200 CC",
    label: "OVER 200 CC",
    value: "OVER 200 CC"
  },
  {
    SERIAL: "02KL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 500 R",
    label: "CBR 500 R",
    value: "CBR 500 R"
  },
  {
    SERIAL: "02KM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "SUPER CUB 110",
    label: "SUPER CUB 110",
    value: "SUPER CUB 110"
  },
  {
    SERIAL: "02KN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "REBEL 300",
    label: "REBEL 300",
    value: "REBEL 300"
  },
  {
    SERIAL: "02KO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MSX 125",
    label: "MSX 125",
    value: "MSX 125"
  },
  {
    SERIAL: "02KP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY.S 1.5 AUTO",
    label: "CITY.S 1.5 AUTO",
    value: "CITY.S 1.5 AUTO"
  },
  {
    SERIAL: "02KQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 250 R",
    label: "CBR 250 R",
    value: "CBR 250 R"
  },
  {
    SERIAL: "02KR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 150R EXMOTION",
    label: "CB 150R EXMOTION",
    value: "CB 150R EXMOTION"
  },
  {
    SERIAL: "02KS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MOOVE",
    label: "MOOVE",
    value: "MOOVE"
  },
  {
    SERIAL: "02KT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "VFR 1200XDS",
    label: "VFR 1200XDS",
    value: "VFR 1200XDS"
  },
  {
    SERIAL: "02KU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NOVA SONIC RS",
    label: "NOVA SONIC RS",
    value: "NOVA SONIC RS"
  },
  {
    SERIAL: "02KV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 500X",
    label: "CB 500X",
    value: "CB 500X"
  },
  {
    SERIAL: "02KW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CRF1000L (AFRICAN TWIN)",
    label: "CRF1000L (AFRICAN TWIN)",
    value: "CRF1000L (AFRICAN TWIN)"
  },
  {
    SERIAL: "02KX0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MSX 125",
    label: "MSX 125",
    value: "MSX 125"
  },
  {
    SERIAL: "02KY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 300F",
    label: "CB 300F",
    value: "CB 300F"
  },
  {
    SERIAL: "02KZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 125I",
    label: "WAVE 125I",
    value: "WAVE 125I"
  },
  {
    SERIAL: "02LA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "SPACY I",
    label: "SPACY I",
    value: "SPACY I"
  },
  {
    SERIAL: "02LB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "HR-V 1.8 RS",
    label: "HR-V 1.8 RS",
    value: "HR-V 1.8 RS"
  },
  {
    SERIAL: "02LC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 500 FA",
    label: "CB 500 FA",
    value: "CB 500 FA"
  },
  {
    SERIAL: "02LD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BRIO AMAZE BLACK SPORT 1.2",
    label: "BRIO AMAZE BLACK SPORT 1.2",
    value: "BRIO AMAZE BLACK SPORT 1.2"
  },
  {
    SERIAL: "02LE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "BR-V V+ 1.5",
    label: "BR-V V+ 1.5",
    value: "BR-V V+ 1.5"
  },
  {
    SERIAL: "02LF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 1000R",
    label: "CB 1000R",
    value: "CB 1000R"
  },
  {
    SERIAL: "02LG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CIVIC TYPE R 2.0",
    label: "CIVIC TYPE R 2.0",
    value: "CIVIC TYPE R 2.0"
  },
  {
    SERIAL: "02LH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "MONKEY 125",
    label: "MONKEY 125",
    value: "MONKEY 125"
  },
  {
    SERIAL: "02LI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "WAVE 100 S",
    label: "WAVE 100 S",
    value: "WAVE 100 S"
  },
  {
    SERIAL: "02LJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "2000",
    label: "2000",
    value: "2000"
  },
  {
    SERIAL: "02LK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "OVER 2000",
    label: "OVER 2000",
    value: "OVER 2000"
  },
  {
    SERIAL: "02LL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "REBEL 500",
    label: "REBEL 500",
    value: "REBEL 500"
  },
  {
    SERIAL: "02LM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 300R",
    label: "CB 300R",
    value: "CB 300R"
  },
  {
    SERIAL: "02LN0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 S",
    label: "CR-V 2.4 S",
    value: "CR-V 2.4 S"
  },
  {
    SERIAL: "02LO0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CR-V 2.4 ES",
    label: "CR-V 2.4 ES",
    value: "CR-V 2.4 ES"
  },
  {
    SERIAL: "02LP0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 1.5 TURBO EL",
    label: "ACCORD 1.5 TURBO EL",
    value: "ACCORD 1.5 TURBO EL"
  },
  {
    SERIAL: "02LQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 E HYBRID",
    label: "ACCORD 2.0 E HYBRID",
    value: "ACCORD 2.0 E HYBRID"
  },
  {
    SERIAL: "02LR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 1000 RA",
    label: "CBR 1000 RA",
    value: "CBR 1000 RA"
  },
  {
    SERIAL: "02LS0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 150",
    label: "CBR 150",
    value: "CBR 150"
  },
  {
    SERIAL: "02LT0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ACCORD 2.0 HYBRID TECH",
    label: "ACCORD 2.0 HYBRID TECH",
    value: "ACCORD 2.0 HYBRID TECH"
  },
  {
    SERIAL: "02LU0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR650 R",
    label: "CBR650 R",
    value: "CBR650 R"
  },
  {
    SERIAL: "02LV0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NMAX",
    label: "NMAX",
    value: "NMAX"
  },
  {
    SERIAL: "02LW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB400 - BUILT",
    label: "CB400 - BUILT",
    value: "CB400 - BUILT"
  },
  {
    SERIAL: "02LY0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "STEP WAGON SPADA 2.0 HYBRID",
    label: "STEP WAGON SPADA 2.0 HYBRID",
    value: "STEP WAGON SPADA 2.0 HYBRID"
  },
  {
    SERIAL: "02LZ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "ADV150",
    label: "ADV150",
    value: "ADV150"
  },
  {
    SERIAL: "02MA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.0 S TURBO",
    label: "CITY 1.0 S TURBO",
    value: "CITY 1.0 S TURBO"
  },
  {
    SERIAL: "02MB0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.0 V TURBO",
    label: "CITY 1.0 V TURBO",
    value: "CITY 1.0 V TURBO"
  },
  {
    SERIAL: "02MC0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.0 SV TURBO",
    label: "CITY 1.0 SV TURBO",
    value: "CITY 1.0 SV TURBO"
  },
  {
    SERIAL: "02MD0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.0 RS TURBO",
    label: "CITY 1.0 RS TURBO",
    value: "CITY 1.0 RS TURBO"
  },
  {
    SERIAL: "02ME0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "AFRICA TWIN CRF1100L",
    label: "AFRICA TWIN CRF1100L",
    value: "AFRICA TWIN CRF1100L"
  },
  {
    SERIAL: "02MF0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "AFRICA TWIN CRF1100L ADVENTURE SPORT",
    label: "AFRICA TWIN CRF1100L ADVENTURE SPORT",
    value: "AFRICA TWIN CRF1100L ADVENTURE SPORT"
  },
  {
    SERIAL: "02MG0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 250 RR",
    label: "CBR 250 RR",
    value: "CBR 250 RR"
  },
  {
    SERIAL: "02MH0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CBR 150R",
    label: "CBR 150R",
    value: "CBR 150R"
  },
  {
    SERIAL: "02MI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "250 CC",
    label: "250 CC",
    value: "250 CC"
  },
  {
    SERIAL: "02MJ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.5 HEV (HYBRID)",
    label: "CITY 1.5 HEV (HYBRID)",
    value: "CITY 1.5 HEV (HYBRID)"
  },
  {
    SERIAL: "02MK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.0 HATCHBACK S+",
    label: "CITY 1.0 HATCHBACK S+",
    value: "CITY 1.0 HATCHBACK S+"
  },
  {
    SERIAL: "02ML0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.0 HATCHBACK SV",
    label: "CITY 1.0 HATCHBACK SV",
    value: "CITY 1.0 HATCHBACK SV"
  },
  {
    SERIAL: "02MM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CITY 1.0 HATCHBACK RS",
    label: "CITY 1.0 HATCHBACK RS",
    value: "CITY 1.0 HATCHBACK RS"
  },
  {
    SERIAL: "02OA0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "GOLDWING TOUR",
    label: "GOLDWING TOUR",
    value: "GOLDWING TOUR"
  },
  {
    SERIAL: "02OI0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CB 900",
    label: "CB 900",
    value: "CB 900"
  },
  {
    SERIAL: "02PK0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FORZA 300",
    label: "FORZA 300",
    value: "FORZA 300"
  },
  {
    SERIAL: "02PL0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FORZA 350",
    label: "FORZA 350",
    value: "FORZA 350"
  },
  {
    SERIAL: "02PM0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "FORZA 350",
    label: "FORZA 350",
    value: "FORZA 350"
  },
  {
    SERIAL: "02QE0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "PCX 160",
    label: "PCX 160",
    value: "PCX 160"
  },
  {
    SERIAL: "02QQ0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "N BOX",
    label: "N BOX",
    value: "N BOX"
  },
  {
    SERIAL: "02QW0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "NC750 X",
    label: "NC750 X",
    value: "NC750 X"
  },
  {
    SERIAL: "02RR0000026",
    MAKE_NAME: "HONDA",
    MODEL_NAME: "CLICK 150I",
    label: "CLICK 150I",
    value: "CLICK 150I"
  },
  {
    SERIAL: "3000000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NISSAN",
    label: "NISSAN",
    value: "NISSAN"
  },
  {
    SERIAL: "3010000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY",
    label: "SUNNY",
    value: "SUNNY"
  },
  {
    SERIAL: "3020000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY 1.3",
    label: "SUNNY 1.3",
    value: "SUNNY 1.3"
  },
  {
    SERIAL: "3040000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY 1.5 M",
    label: "SUNNY 1.5 M",
    value: "SUNNY 1.5 M"
  },
  {
    SERIAL: "3050000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY EX SALOON 1.5 A",
    label: "SUNNY EX SALOON 1.5 A",
    value: "SUNNY EX SALOON 1.5 A"
  },
  {
    SERIAL: "3060000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY 1.6 M",
    label: "SUNNY 1.6 M",
    value: "SUNNY 1.6 M"
  },
  {
    SERIAL: "3070000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY SUPER SALOON 1.6 A",
    label: "SUNNY SUPER SALOON 1.6 A",
    value: "SUNNY SUPER SALOON 1.6 A"
  },
  {
    SERIAL: "3080000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SENTRA 1.4",
    label: "SENTRA 1.4",
    value: "SENTRA 1.4"
  },
  {
    SERIAL: "3090000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SENTRA 1.6",
    label: "SENTRA 1.6",
    value: "SENTRA 1.6"
  },
  {
    SERIAL: "3110000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SENTRA SALOON EX 1.6",
    label: "SENTRA SALOON EX 1.6",
    value: "SENTRA SALOON EX 1.6"
  },
  {
    SERIAL: "3130000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "STANZA FX 1.6",
    label: "STANZA FX 1.6",
    value: "STANZA FX 1.6"
  },
  {
    SERIAL: "3160000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PRESEA 1.8 A",
    label: "PRESEA 1.8 A",
    value: "PRESEA 1.8 A"
  },
  {
    SERIAL: "3170000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PRESEA 1.6",
    label: "PRESEA 1.6",
    value: "PRESEA 1.6"
  },
  {
    SERIAL: "3180000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEDRIC",
    label: "CEDRIC",
    value: "CEDRIC"
  },
  {
    SERIAL: "3190000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO 2.0 V6 24V",
    label: "CEFIRO 2.0 V6 24V",
    value: "CEFIRO 2.0 V6 24V"
  },
  {
    SERIAL: "3200000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO 3.0 V6 24V",
    label: "CEFIRO 3.0 V6 24V",
    value: "CEFIRO 3.0 V6 24V"
  },
  {
    SERIAL: "3210000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO 2.0 12V",
    label: "CEFIRO 2.0 12V",
    value: "CEFIRO 2.0 12V"
  },
  {
    SERIAL: "3220000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO 2.0 24V",
    label: "CEFIRO 2.0 24V",
    value: "CEFIRO 2.0 24V"
  },
  {
    SERIAL: "3230000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PRESIDENT JS ABS",
    label: "PRESIDENT JS ABS",
    value: "PRESIDENT JS ABS"
  },
  {
    SERIAL: "3240000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PRESIDENT",
    label: "PRESIDENT",
    value: "PRESIDENT"
  },
  {
    SERIAL: "3250000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "200 SX 1.8",
    label: "200 SX 1.8",
    value: "200 SX 1.8"
  },
  {
    SERIAL: "3290000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "300 ZX A ABS",
    label: "300 ZX A ABS",
    value: "300 ZX A ABS"
  },
  {
    SERIAL: "3320000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BLUEBIRD ATTESA 2.0",
    label: "BLUEBIRD ATTESA 2.0",
    value: "BLUEBIRD ATTESA 2.0"
  },
  {
    SERIAL: "3330000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BLUEBIRD SSS 2.0",
    label: "BLUEBIRD SSS 2.0",
    value: "BLUEBIRD SSS 2.0"
  },
  {
    SERIAL: "3360000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NV SGX 1.6",
    label: "NV SGX 1.6",
    value: "NV SGX 1.6"
  },
  {
    SERIAL: "3370000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NV SLX 1.6",
    label: "NV SLX 1.6",
    value: "NV SLX 1.6"
  },
  {
    SERIAL: "3380000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NX COUPE 1.6",
    label: "NX COUPE 1.6",
    value: "NX COUPE 1.6"
  },
  {
    SERIAL: "3390000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SERENA 2.0",
    label: "SERENA 2.0",
    value: "SERENA 2.0"
  },
  {
    SERIAL: "3400000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TERRANO II 2.4 A",
    label: "TERRANO II 2.4 A",
    value: "TERRANO II 2.4 A"
  },
  {
    SERIAL: "3410000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN",
    label: "URVAN",
    value: "URVAN"
  },
  {
    SERIAL: "3420000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "VANNETTE",
    label: "VANNETTE",
    value: "VANNETTE"
  },
  {
    SERIAL: "3430000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NEW NV B QUEEN CAB 1.6 SLX M",
    label: "NEW NV B QUEEN CAB 1.6 SLX M",
    value: "NEW NV B QUEEN CAB 1.6 SLX M"
  },
  {
    SERIAL: "3450000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NV PICK UP LX",
    label: "NV PICK UP LX",
    value: "NV PICK UP LX"
  },
  {
    SERIAL: "3460000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M SINGLE CAB",
    label: "BIG M SINGLE CAB",
    value: "BIG M SINGLE CAB"
  },
  {
    SERIAL: "3470000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M KING CAB 2.5GL",
    label: "BIG M KING CAB 2.5GL",
    value: "BIG M KING CAB 2.5GL"
  },
  {
    SERIAL: "3480000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M PREMIUMCAB DX 2.7",
    label: "BIG M PREMIUMCAB DX 2.7",
    value: "BIG M PREMIUMCAB DX 2.7"
  },
  {
    SERIAL: "3490000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M KINGCAB DX 2.7",
    label: "BIG M KINGCAB DX 2.7",
    value: "BIG M KINGCAB DX 2.7"
  },
  {
    SERIAL: "3510000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M PREMIUM CAB BENZIN 2.0",
    label: "BIG M PREMIUM CAB BENZIN 2.0",
    value: "BIG M PREMIUM CAB BENZIN 2.0"
  },
  {
    SERIAL: "3540000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "VAN 5Dr.",
    label: "VAN 5Dr.",
    value: "VAN 5Dr."
  },
  {
    SERIAL: "3570000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "STATION WAGON",
    label: "STATION WAGON",
    value: "STATION WAGON"
  },
  {
    SERIAL: "3590000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M KING CAB 4WD 3.0",
    label: "BIG M KING CAB 4WD 3.0",
    value: "BIG M KING CAB 4WD 3.0"
  },
  {
    SERIAL: "3600000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M",
    label: "BIG M",
    value: "BIG M"
  },
  {
    SERIAL: "3620000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PRIMERA",
    label: "PRIMERA",
    value: "PRIMERA"
  },
  {
    SERIAL: "3650000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CD12M",
    label: "CD12M",
    value: "CD12M"
  },
  {
    SERIAL: "3660000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CDA12",
    label: "CDA12",
    value: "CDA12"
  },
  {
    SERIAL: "3690000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CPA87",
    label: "CPA87",
    value: "CPA87"
  },
  {
    SERIAL: "3700000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CW30",
    label: "CW30",
    value: "CW30"
  },
  {
    SERIAL: "3710000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CW41",
    label: "CW41",
    value: "CW41"
  },
  {
    SERIAL: "3730000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWA12",
    label: "CWA12",
    value: "CWA12"
  },
  {
    SERIAL: "3740000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM430",
    label: "CWM430",
    value: "CWM430"
  },
  {
    SERIAL: "3750000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "UG780",
    label: "UG780",
    value: "UG780"
  },
  {
    SERIAL: "3840000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "STANDARD CAB",
    label: "STANDARD CAB",
    value: "STANDARD CAB"
  },
  {
    SERIAL: "3850000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NEW NV B QUEEN CAB 1.6 SLX A",
    label: "NEW NV B QUEEN CAB 1.6 SLX A",
    value: "NEW NV B QUEEN CAB 1.6 SLX A"
  },
  {
    SERIAL: "3860000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY (MINOR CHANGE) 1.6 M",
    label: "SUNNY (MINOR CHANGE) 1.6 M",
    value: "SUNNY (MINOR CHANGE) 1.6 M"
  },
  {
    SERIAL: "3870000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY (MINOR CHANGE) 1.6 A",
    label: "SUNNY (MINOR CHANGE) 1.6 A",
    value: "SUNNY (MINOR CHANGE) 1.6 A"
  },
  {
    SERIAL: "3880000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY (MINOR CHANGE)1.6 A ABS",
    label: "SUNNY (MINOR CHANGE)1.6 A ABS",
    value: "SUNNY (MINOR CHANGE)1.6 A ABS"
  },
  {
    SERIAL: "3890000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO 2.0 A",
    label: "CEFIRO 2.0 A",
    value: "CEFIRO 2.0 A"
  },
  {
    SERIAL: "3900000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO 3.0 A",
    label: "CEFIRO 3.0 A",
    value: "CEFIRO 3.0 A"
  },
  {
    SERIAL: "3930000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M KINGCAB DX 2.7",
    label: "BIG M KINGCAB DX 2.7",
    value: "BIG M KINGCAB DX 2.7"
  },
  {
    SERIAL: "3940000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M DOUBLE CAB",
    label: "BIG M DOUBLE CAB",
    value: "BIG M DOUBLE CAB"
  },
  {
    SERIAL: "3970000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M KINGCAB SUPER GL 2.7",
    label: "BIG M KINGCAB SUPER GL 2.7",
    value: "BIG M KINGCAB SUPER GL 2.7"
  },
  {
    SERIAL: "03AA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FREELIFE (4D)",
    label: "FREELIFE (4D)",
    value: "FREELIFE (4D)"
  },
  {
    SERIAL: "03AD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "12ตัน",
    label: "12ตัน",
    value: "12ตัน"
  },
  {
    SERIAL: "03AE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA JM 2.3",
    label: "TEANA JM 2.3",
    value: "TEANA JM 2.3"
  },
  {
    SERIAL: "03AI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BLUEBIRD 2.0 A",
    label: "BLUEBIRD 2.0 A",
    value: "BLUEBIRD 2.0 A"
  },
  {
    SERIAL: "03AJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BLUEBIRD SSS A",
    label: "BLUEBIRD SSS A",
    value: "BLUEBIRD SSS A"
  },
  {
    SERIAL: "03AL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO 3.0 ABS A",
    label: "CEFIRO 3.0 ABS A",
    value: "CEFIRO 3.0 ABS A"
  },
  {
    SERIAL: "03AN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SENTRA 1.5 A",
    label: "SENTRA 1.5 A",
    value: "SENTRA 1.5 A"
  },
  {
    SERIAL: "03AO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SENTRA 1.6 A",
    label: "SENTRA 1.6 A",
    value: "SENTRA 1.6 A"
  },
  {
    SERIAL: "03AP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY 1.5 EX SALOON A",
    label: "SUNNY 1.5 EX SALOON A",
    value: "SUNNY 1.5 EX SALOON A"
  },
  {
    SERIAL: "03AQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY SUPERSALOON ABS 1.6",
    label: "SUNNY SUPERSALOON ABS 1.6",
    value: "SUNNY SUPERSALOON ABS 1.6"
  },
  {
    SERIAL: "03AR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY NEO GL(A) 1.6",
    label: "SUNNY NEO GL(A) 1.6",
    value: "SUNNY NEO GL(A) 1.6"
  },
  {
    SERIAL: "03AS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY NEO GL(M) 1.6",
    label: "SUNNY NEO GL(M) 1.6",
    value: "SUNNY NEO GL(M) 1.6"
  },
  {
    SERIAL: "03AT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY SUPER NEO (A) 1.6",
    label: "SUNNY SUPER NEO (A) 1.6",
    value: "SUNNY SUPER NEO (A) 1.6"
  },
  {
    SERIAL: "03AV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER SINGLE 2.7",
    label: "BIG M FRONTIER SINGLE 2.7",
    value: "BIG M FRONTIER SINGLE 2.7"
  },
  {
    SERIAL: "03AW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER KINGCAB 2.7",
    label: "BIG M FRONTIER KINGCAB 2.7",
    value: "BIG M FRONTIER KINGCAB 2.7"
  },
  {
    SERIAL: "03AX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 2.7",
    label: "BIG M FRONTIER DOUBLECAB 2.7",
    value: "BIG M FRONTIER DOUBLECAB 2.7"
  },
  {
    SERIAL: "03AY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 4WD 3.0",
    label: "BIG M FRONTIER DOUBLECAB 4WD 3.0",
    value: "BIG M FRONTIER DOUBLECAB 4WD 3.0"
  },
  {
    SERIAL: "03AZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER KING CAB 4WD 3.0",
    label: "BIG M FRONTIER KING CAB 4WD 3.0",
    value: "BIG M FRONTIER KING CAB 4WD 3.0"
  },
  {
    SERIAL: "03BA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "XCITER 2WD",
    label: "XCITER 2WD",
    value: "XCITER 2WD"
  },
  {
    SERIAL: "03BB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN 2.7",
    label: "URVAN 2.7",
    value: "URVAN 2.7"
  },
  {
    SERIAL: "03BC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NV WING ROAD",
    label: "NV WING ROAD",
    value: "NV WING ROAD"
  },
  {
    SERIAL: "03BD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER KINGCAB 3.0 4WD",
    label: "BIG M FRONTIER KINGCAB 3.0 4WD",
    value: "BIG M FRONTIER KINGCAB 3.0 4WD"
  },
  {
    SERIAL: "03BE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SILVIA",
    label: "SILVIA",
    value: "SILVIA"
  },
  {
    SERIAL: "03BF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY ALMERA SX",
    label: "SUNNY ALMERA SX",
    value: "SUNNY ALMERA SX"
  },
  {
    SERIAL: "03BG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY ALMERA YOUNG",
    label: "SUNNY ALMERA YOUNG",
    value: "SUNNY ALMERA YOUNG"
  },
  {
    SERIAL: "03BH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO EXCIMO V6 2.0",
    label: "CEFIRO EXCIMO V6 2.0",
    value: "CEFIRO EXCIMO V6 2.0"
  },
  {
    SERIAL: "03BI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO EXECUTIVE V6 2.0",
    label: "CEFIRO EXECUTIVE V6 2.0",
    value: "CEFIRO EXECUTIVE V6 2.0"
  },
  {
    SERIAL: "03BJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CEFIRO BROUGHAM VIT 3.0",
    label: "CEFIRO BROUGHAM VIT 3.0",
    value: "CEFIRO BROUGHAM VIT 3.0"
  },
  {
    SERIAL: "03BK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER KINGCAB ZDI 3.0",
    label: "BIG M FRONTIER KINGCAB ZDI 3.0",
    value: "BIG M FRONTIER KINGCAB ZDI 3.0"
  },
  {
    SERIAL: "03BL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 3.0",
    label: "BIG M FRONTIER DOUBLECAB 3.0",
    value: "BIG M FRONTIER DOUBLECAB 3.0"
  },
  {
    SERIAL: "03BM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 4WD 3.0 ABS",
    label: "BIG M FRONTIER DOUBLECAB 4WD 3.0 ABS",
    value: "BIG M FRONTIER DOUBLECAB 4WD 3.0 ABS"
  },
  {
    SERIAL: "03BO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA JK 2.3",
    label: "TEANA JK 2.3",
    value: "TEANA JK 2.3"
  },
  {
    SERIAL: "03BP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PKB214E",
    label: "PKB214E",
    value: "PKB214E"
  },
  {
    SERIAL: "03BQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PKB214G",
    label: "PKB214G",
    value: "PKB214G"
  },
  {
    SERIAL: "03BR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PKB214N",
    label: "PKB214N",
    value: "PKB214N"
  },
  {
    SERIAL: "03BS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CDA214M",
    label: "CDA214M",
    value: "CDA214M"
  },
  {
    SERIAL: "03BT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CDA214S",
    label: "CDA214S",
    value: "CDA214S"
  },
  {
    SERIAL: "03BU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWA214M",
    label: "CWA214M",
    value: "CWA214M"
  },
  {
    SERIAL: "03BV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM454M-6",
    label: "CWM454M-6",
    value: "CWM454M-6"
  },
  {
    SERIAL: "03BW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM454M-12",
    label: "CWM454M-12",
    value: "CWM454M-12"
  },
  {
    SERIAL: "03BX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM454H",
    label: "CWM454H",
    value: "CWM454H"
  },
  {
    SERIAL: "03BY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM454HT-6",
    label: "CWM454HT-6",
    value: "CWM454HT-6"
  },
  {
    SERIAL: "03BZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM454HT-7",
    label: "CWM454HT-7",
    value: "CWM454HT-7"
  },
  {
    SERIAL: "03CA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN 3.0 HIGHROOF",
    label: "URVAN 3.0 HIGHROOF",
    value: "URVAN 3.0 HIGHROOF"
  },
  {
    SERIAL: "03CB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER CAB 2.5 AX-L",
    label: "BIG M FRONTIER CAB 2.5 AX-L",
    value: "BIG M FRONTIER CAB 2.5 AX-L"
  },
  {
    SERIAL: "03CD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIGM DOUBLECAB 4WD 2.5",
    label: "BIGM DOUBLECAB 4WD 2.5",
    value: "BIGM DOUBLECAB 4WD 2.5"
  },
  {
    SERIAL: "03CE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWA12",
    label: "CWA12",
    value: "CWA12"
  },
  {
    SERIAL: "03CF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER SINGLE 2.5",
    label: "BIG M FRONTIER SINGLE 2.5",
    value: "BIG M FRONTIER SINGLE 2.5"
  },
  {
    SERIAL: "03CG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLE CAB 2.5",
    label: "BIG M FRONTIER DOUBLE CAB 2.5",
    value: "BIG M FRONTIER DOUBLE CAB 2.5"
  },
  {
    SERIAL: "03CH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER CAB 2.5 AL",
    label: "BIG M FRONTIER CAB 2.5 AL",
    value: "BIG M FRONTIER CAB 2.5 AL"
  },
  {
    SERIAL: "03CI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER CAB 2.5 AX-L SUPER",
    label: "BIG M FRONTIER CAB 2.5 AX-L SUPER",
    value: "BIG M FRONTIER CAB 2.5 AX-L SUPER"
  },
  {
    SERIAL: "03CJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 2.5 AX-L",
    label: "BIG M FRONTIER DOUBLECAB 2.5 AX-L",
    value: "BIG M FRONTIER DOUBLECAB 2.5 AX-L"
  },
  {
    SERIAL: "03CK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 2.5 AX-L SUPER",
    label: "BIG M FRONTIER DOUBLECAB 2.5 AX-L SUPER",
    value: "BIG M FRONTIER DOUBLECAB 2.5 AX-L SUPER"
  },
  {
    SERIAL: "03CL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 2.5 AL",
    label: "BIG M FRONTIER DOUBLECAB 2.5 AL",
    value: "BIG M FRONTIER DOUBLECAB 2.5 AL"
  },
  {
    SERIAL: "03CM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BIG M FRONTIER DOUBLECAB 4WD 3.0",
    label: "BIG M FRONTIER DOUBLECAB 4WD 3.0",
    value: "BIG M FRONTIER DOUBLECAB 4WD 3.0"
  },
  {
    SERIAL: "03CN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.6 S MANUAL",
    label: "TIIDA 1.6 S MANUAL",
    value: "TIIDA 1.6 S MANUAL"
  },
  {
    SERIAL: "03CO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.6 S AUTO",
    label: "TIIDA 1.6 S AUTO",
    value: "TIIDA 1.6 S AUTO"
  },
  {
    SERIAL: "03CP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.6 M MANUAL",
    label: "TIIDA 1.6 M MANUAL",
    value: "TIIDA 1.6 M MANUAL"
  },
  {
    SERIAL: "03CQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.6 G AUTO",
    label: "TIIDA 1.6 G AUTO",
    value: "TIIDA 1.6 G AUTO"
  },
  {
    SERIAL: "03CR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.6 S AUTO AIRBAG SPORTY",
    label: "TIIDA 1.6 S AUTO AIRBAG SPORTY",
    value: "TIIDA 1.6 S AUTO AIRBAG SPORTY"
  },
  {
    SERIAL: "03CS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.6 S AUTO AIRBAG LUXURY",
    label: "TIIDA 1.6 S AUTO AIRBAG LUXURY",
    value: "TIIDA 1.6 S AUTO AIRBAG LUXURY"
  },
  {
    SERIAL: "03CT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.8 G AUTO",
    label: "TIIDA 1.8 G AUTO",
    value: "TIIDA 1.8 G AUTO"
  },
  {
    SERIAL: "03CU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.8 S AUTO ABS AIRBAG SPORTY",
    label: "TIIDA 1.8 S AUTO ABS AIRBAG SPORTY",
    value: "TIIDA 1.8 S AUTO ABS AIRBAG SPORTY"
  },
  {
    SERIAL: "03CV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.8 S AUTO ABS AIRBAG LUXURY",
    label: "TIIDA 1.8 S AUTO ABS AIRBAG LUXURY",
    value: "TIIDA 1.8 S AUTO ABS AIRBAG LUXURY"
  },
  {
    SERIAL: "03CW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 5D 1.6 S AUTO",
    label: "TIIDA 5D 1.6 S AUTO",
    value: "TIIDA 5D 1.6 S AUTO"
  },
  {
    SERIAL: "03CX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 5D 1.6 G AUTO",
    label: "TIIDA 5D 1.6 G AUTO",
    value: "TIIDA 5D 1.6 G AUTO"
  },
  {
    SERIAL: "03CZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 5D 1.6 H AUTO ABS AIRBAG LUXURY",
    label: "TIIDA 5D 1.6 H AUTO ABS AIRBAG LUXURY",
    value: "TIIDA 5D 1.6 H AUTO ABS AIRBAG LUXURY"
  },
  {
    SERIAL: "03DA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 5D 1.8 G AUTO",
    label: "TIIDA 5D 1.8 G AUTO",
    value: "TIIDA 5D 1.8 G AUTO"
  },
  {
    SERIAL: "03DB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 5D 1.8 G AUTO ABS AIRBAG SPORTY",
    label: "TIIDA 5D 1.8 G AUTO ABS AIRBAG SPORTY",
    value: "TIIDA 5D 1.8 G AUTO ABS AIRBAG SPORTY"
  },
  {
    SERIAL: "03DC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 5D 1.8 G AUTO ABS AIRBAG LUXURY",
    label: "TIIDA 5D 1.8 G AUTO ABS AIRBAG LUXURY",
    value: "TIIDA 5D 1.8 G AUTO ABS AIRBAG LUXURY"
  },
  {
    SERIAL: "03DD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB SE M.",
    label: "FRONTIER NAVARA KINGCAB SE M.",
    value: "FRONTIER NAVARA KINGCAB SE M."
  },
  {
    SERIAL: "03DE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB CARIBRE LE A",
    label: "FRONTIER NAVARA KINGCAB CARIBRE LE A",
    value: "FRONTIER NAVARA KINGCAB CARIBRE LE A"
  },
  {
    SERIAL: "03DF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB LE A.",
    label: "FRONTIER NAVARA KINGCAB LE A.",
    value: "FRONTIER NAVARA KINGCAB LE A."
  },
  {
    SERIAL: "03DG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB 4WD SE M.",
    label: "FRONTIER NAVARA KINGCAB 4WD SE M.",
    value: "FRONTIER NAVARA KINGCAB 4WD SE M."
  },
  {
    SERIAL: "03DH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB 4WD LE M.",
    label: "FRONTIER NAVARA KINGCAB 4WD LE M.",
    value: "FRONTIER NAVARA KINGCAB 4WD LE M."
  },
  {
    SERIAL: "03DI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB SE M.",
    label: "FRONTIER NAVARA DOUBLECAB SE M.",
    value: "FRONTIER NAVARA DOUBLECAB SE M."
  },
  {
    SERIAL: "03DJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB LE M",
    label: "FRONTIER NAVARA DOUBLECAB LE M",
    value: "FRONTIER NAVARA DOUBLECAB LE M"
  },
  {
    SERIAL: "03DK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB LE A.",
    label: "FRONTIER NAVARA DOUBLECAB LE A.",
    value: "FRONTIER NAVARA DOUBLECAB LE A."
  },
  {
    SERIAL: "03DL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB 4WD SE M.",
    label: "FRONTIER NAVARA DOUBLECAB 4WD SE M.",
    value: "FRONTIER NAVARA DOUBLECAB 4WD SE M."
  },
  {
    SERIAL: "03DM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB 4WD LE M.",
    label: "FRONTIER NAVARA DOUBLECAB 4WD LE M.",
    value: "FRONTIER NAVARA DOUBLECAB 4WD LE M."
  },
  {
    SERIAL: "03DN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB 4WD LE A.",
    label: "FRONTIER NAVARA DOUBLECAB 4WD LE A.",
    value: "FRONTIER NAVARA DOUBLECAB 4WD LE A."
  },
  {
    SERIAL: "03DO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CW30M",
    label: "CW30M",
    value: "CW30M"
  },
  {
    SERIAL: "03DP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "350 Z",
    label: "350 Z",
    value: "350 Z"
  },
  {
    SERIAL: "03DQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MURANO TP 2.5",
    label: "MURANO TP 2.5",
    value: "MURANO TP 2.5"
  },
  {
    SERIAL: "03DR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PKB214 (BUILT TO 10 WHEEL)",
    label: "PKB214 (BUILT TO 10 WHEEL)",
    value: "PKB214 (BUILT TO 10 WHEEL)"
  },
  {
    SERIAL: "03DS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "N2F23 (BULT)",
    label: "N2F23 (BULT)",
    value: "N2F23 (BULT)"
  },
  {
    SERIAL: "03DT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY NEO 1.8",
    label: "SUNNY NEO 1.8",
    value: "SUNNY NEO 1.8"
  },
  {
    SERIAL: "03DU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA SINGLE CAB XE 2.5 T",
    label: "FRONTIER NAVARA SINGLE CAB XE 2.5 T",
    value: "FRONTIER NAVARA SINGLE CAB XE 2.5 T"
  },
  {
    SERIAL: "03DV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA 1.6 M AUTO",
    label: "TIIDA 1.6 M AUTO",
    value: "TIIDA 1.6 M AUTO"
  },
  {
    SERIAL: "03DW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ELGRAND 3.2",
    label: "ELGRAND 3.2",
    value: "ELGRAND 3.2"
  },
  {
    SERIAL: "03DX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO B 1.6 M.",
    label: "TIIDA LATIO B 1.6 M.",
    value: "TIIDA LATIO B 1.6 M."
  },
  {
    SERIAL: "03DY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO B 1.6 A",
    label: "TIIDA LATIO B 1.6 A",
    value: "TIIDA LATIO B 1.6 A"
  },
  {
    SERIAL: "03DZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO S 1.6 A",
    label: "TIIDA LATIO S 1.6 A",
    value: "TIIDA LATIO S 1.6 A"
  },
  {
    SERIAL: "03EA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO M 1.6 A",
    label: "TIIDA LATIO M 1.6 A",
    value: "TIIDA LATIO M 1.6 A"
  },
  {
    SERIAL: "03EB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO G 1.6 A.",
    label: "TIIDA LATIO G 1.6 A.",
    value: "TIIDA LATIO G 1.6 A."
  },
  {
    SERIAL: "03EC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB BLACK LTD 4WD A.",
    label: "FRONTIER NAVARA DOUBLECAB BLACK LTD 4WD A.",
    value: "FRONTIER NAVARA DOUBLECAB BLACK LTD 4WD A."
  },
  {
    SERIAL: "03ED0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB BLACK LTD 4*4M",
    label: "FRONTIER NAVARA DOUBLECAB BLACK LTD 4*4M",
    value: "FRONTIER NAVARA DOUBLECAB BLACK LTD 4*4M"
  },
  {
    SERIAL: "03EE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB BLACK LTD M",
    label: "FRONTIER NAVARA DOUBLECAB BLACK LTD M",
    value: "FRONTIER NAVARA DOUBLECAB BLACK LTD M"
  },
  {
    SERIAL: "03EF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB BLACK LTD A",
    label: "FRONTIER NAVARA DOUBLECAB BLACK LTD A",
    value: "FRONTIER NAVARA DOUBLECAB BLACK LTD A"
  },
  {
    SERIAL: "03EG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO S 1.6 M.",
    label: "TIIDA LATIO S 1.6 M.",
    value: "TIIDA LATIO S 1.6 M."
  },
  {
    SERIAL: "03EH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO P 1.6 M",
    label: "TIIDA LATIO P 1.6 M",
    value: "TIIDA LATIO P 1.6 M"
  },
  {
    SERIAL: "03EI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA LATIO PL 1.6 M",
    label: "TIIDA LATIO PL 1.6 M",
    value: "TIIDA LATIO PL 1.6 M"
  },
  {
    SERIAL: "03EJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB CALIBRE LE M",
    label: "FRONTIER NAVARA KINGCAB CALIBRE LE M",
    value: "FRONTIER NAVARA KINGCAB CALIBRE LE M"
  },
  {
    SERIAL: "03EK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CW50GT",
    label: "CW50GT",
    value: "CW50GT"
  },
  {
    SERIAL: "03EL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB CALIBRE LE M",
    label: "FRONTIER NAVARA DOUBLECAB CALIBRE LE M",
    value: "FRONTIER NAVARA DOUBLECAB CALIBRE LE M"
  },
  {
    SERIAL: "03EM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PKB211",
    label: "PKB211",
    value: "PKB211"
  },
  {
    SERIAL: "03EN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB CARIBRE LE AT",
    label: "FRONTIER NAVARA DOUBLECAB CARIBRE LE AT",
    value: "FRONTIER NAVARA DOUBLECAB CARIBRE LE AT"
  },
  {
    SERIAL: "03EO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SKYLINE GT-R",
    label: "SKYLINE GT-R",
    value: "SKYLINE GT-R"
  },
  {
    SERIAL: "03EP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 250 XV V.6",
    label: "TEANA 250 XV V.6",
    value: "TEANA 250 XV V.6"
  },
  {
    SERIAL: "03EQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 250 XV SPORT SUNROOF NAVIGATOR",
    label: "TEANA 250 XV SPORT SUNROOF NAVIGATOR",
    value: "TEANA 250 XV SPORT SUNROOF NAVIGATOR"
  },
  {
    SERIAL: "03ER0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 250 XV V.6 NAVIGATOR",
    label: "TEANA 250 XV V.6 NAVIGATOR",
    value: "TEANA 250 XV V.6 NAVIGATOR"
  },
  {
    SERIAL: "03ES0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 250 XV V.6 SUNROOF NAVIGATOR",
    label: "TEANA 250 XV V.6 SUNROOF NAVIGATOR",
    value: "TEANA 250 XV V.6 SUNROOF NAVIGATOR"
  },
  {
    SERIAL: "03ET0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 200 XE",
    label: "TEANA 200 XE",
    value: "TEANA 200 XE"
  },
  {
    SERIAL: "03EU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 200 XL",
    label: "TEANA 200 XL",
    value: "TEANA 200 XL"
  },
  {
    SERIAL: "03EV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER SINGLECAB 2.5 COMMONRAIL",
    label: "FRONTIER SINGLECAB 2.5 COMMONRAIL",
    value: "FRONTIER SINGLECAB 2.5 COMMONRAIL"
  },
  {
    SERIAL: "03EW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER SINGLECAB P.2.5 COMMONRAIL",
    label: "FRONTIER SINGLECAB P.2.5 COMMONRAIL",
    value: "FRONTIER SINGLECAB P.2.5 COMMONRAIL"
  },
  {
    SERIAL: "03EX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA SINGLECAB 2.5",
    label: "FRONTIER NAVARA SINGLECAB 2.5",
    value: "FRONTIER NAVARA SINGLECAB 2.5"
  },
  {
    SERIAL: "03EZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA 4D CARIBRE LE LTD AT",
    label: "FRONTIER NAVARA 4D CARIBRE LE LTD AT",
    value: "FRONTIER NAVARA 4D CARIBRE LE LTD AT"
  },
  {
    SERIAL: "03FA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 2.0",
    label: "X-TRAIL 2.0",
    value: "X-TRAIL 2.0"
  },
  {
    SERIAL: "03FB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FIGARO",
    label: "FIGARO",
    value: "FIGARO"
  },
  {
    SERIAL: "03FD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH S (M)",
    label: "MARCH S (M)",
    value: "MARCH S (M)"
  },
  {
    SERIAL: "03FE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH E (M)",
    label: "MARCH E (M)",
    value: "MARCH E (M)"
  },
  {
    SERIAL: "03FF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH E XTRONIC CVT",
    label: "MARCH E XTRONIC CVT",
    value: "MARCH E XTRONIC CVT"
  },
  {
    SERIAL: "03FG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH EL",
    label: "MARCH EL",
    value: "MARCH EL"
  },
  {
    SERIAL: "03FH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH V XTRONIC CVT",
    label: "MARCH V XTRONIC CVT",
    value: "MARCH V XTRONIC CVT"
  },
  {
    SERIAL: "03FI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH VL XTRONIC CVT",
    label: "MARCH VL XTRONIC CVT",
    value: "MARCH VL XTRONIC CVT"
  },
  {
    SERIAL: "03FJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FN1K (BUILT)",
    label: "FN1K (BUILT)",
    value: "FN1K (BUILT)"
  },
  {
    SERIAL: "03FK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CUBE 1.5",
    label: "CUBE 1.5",
    value: "CUBE 1.5"
  },
  {
    SERIAL: "03FL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "JUKE 1.5 (CBU)",
    label: "JUKE 1.5 (CBU)",
    value: "JUKE 1.5 (CBU)"
  },
  {
    SERIAL: "03FM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SKYLINE 350 GT",
    label: "SKYLINE 350 GT",
    value: "SKYLINE 350 GT"
  },
  {
    SERIAL: "03FO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CUBE (BUILT)",
    label: "CUBE (BUILT)",
    value: "CUBE (BUILT)"
  },
  {
    SERIAL: "03FQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SKYLINE 3.5",
    label: "SKYLINE 3.5",
    value: "SKYLINE 3.5"
  },
  {
    SERIAL: "03FR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "LEAF",
    label: "LEAF",
    value: "LEAF"
  },
  {
    SERIAL: "03FS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ELGRAND 2.5",
    label: "ELGRAND 2.5",
    value: "ELGRAND 2.5"
  },
  {
    SERIAL: "03FT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SERENA",
    label: "SERENA",
    value: "SERENA"
  },
  {
    SERIAL: "03FU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CG520WZ (BUILT)",
    label: "CG520WZ (BUILT)",
    value: "CG520WZ (BUILT)"
  },
  {
    SERIAL: "03FV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 S MT",
    label: "ALMERA 1.2 S MT",
    value: "ALMERA 1.2 S MT"
  },
  {
    SERIAL: "03FW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 E MT",
    label: "ALMERA 1.2 E MT",
    value: "ALMERA 1.2 E MT"
  },
  {
    SERIAL: "03FX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 E AT",
    label: "ALMERA 1.2 E AT",
    value: "ALMERA 1.2 E AT"
  },
  {
    SERIAL: "03FY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 ES CVT",
    label: "ALMERA 1.2 ES CVT",
    value: "ALMERA 1.2 ES CVT"
  },
  {
    SERIAL: "03FZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 V AT",
    label: "ALMERA 1.2 V AT",
    value: "ALMERA 1.2 V AT"
  },
  {
    SERIAL: "03GA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 VL AT",
    label: "ALMERA 1.2 VL AT",
    value: "ALMERA 1.2 VL AT"
  },
  {
    SERIAL: "03GB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SILVIA S15",
    label: "SILVIA S15",
    value: "SILVIA S15"
  },
  {
    SERIAL: "03GC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM431 (BUILT)",
    label: "CWM431 (BUILT)",
    value: "CWM431 (BUILT)"
  },
  {
    SERIAL: "03GD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM430 (BUILT)",
    label: "CWM430 (BUILT)",
    value: "CWM430 (BUILT)"
  },
  {
    SERIAL: "03GE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CK31P (BUILT)",
    label: "CK31P (BUILT)",
    value: "CK31P (BUILT)"
  },
  {
    SERIAL: "03GF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CK551B",
    label: "CK551B",
    value: "CK551B"
  },
  {
    SERIAL: "03GG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY S 1.6 M",
    label: "SYLPHY S 1.6 M",
    value: "SYLPHY S 1.6 M"
  },
  {
    SERIAL: "03GH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY S 1.6 A",
    label: "SYLPHY S 1.6 A",
    value: "SYLPHY S 1.6 A"
  },
  {
    SERIAL: "03GI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ELGRAND 2.5",
    label: "ELGRAND 2.5",
    value: "ELGRAND 2.5"
  },
  {
    SERIAL: "03GJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY E 1.6 A",
    label: "SYLPHY E 1.6 A",
    value: "SYLPHY E 1.6 A"
  },
  {
    SERIAL: "03GK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "GT-R 3.8",
    label: "GT-R 3.8",
    value: "GT-R 3.8"
  },
  {
    SERIAL: "03GL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY V 1.6 A",
    label: "SYLPHY V 1.6 A",
    value: "SYLPHY V 1.6 A"
  },
  {
    SERIAL: "03GM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY V 1.8 A",
    label: "SYLPHY V 1.8 A",
    value: "SYLPHY V 1.8 A"
  },
  {
    SERIAL: "03GN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY V NAVIGATOR 1.8 A",
    label: "SYLPHY V NAVIGATOR 1.8 A",
    value: "SYLPHY V NAVIGATOR 1.8 A"
  },
  {
    SERIAL: "03GO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "GT-R 3.8",
    label: "GT-R 3.8",
    value: "GT-R 3.8"
  },
  {
    SERIAL: "03GP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM273",
    label: "CWM273",
    value: "CWM273"
  },
  {
    SERIAL: "03GQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 2.0 V",
    label: "X-TRAIL 2.0 V",
    value: "X-TRAIL 2.0 V"
  },
  {
    SERIAL: "03GR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PULSAR 1.6 S",
    label: "PULSAR 1.6 S",
    value: "PULSAR 1.6 S"
  },
  {
    SERIAL: "03GS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PULSAR 1.6 V",
    label: "PULSAR 1.6 V",
    value: "PULSAR 1.6 V"
  },
  {
    SERIAL: "03GT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PULSAR 1.6 SV",
    label: "PULSAR 1.6 SV",
    value: "PULSAR 1.6 SV"
  },
  {
    SERIAL: "03GU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PULSAR 1.8 V",
    label: "PULSAR 1.8 V",
    value: "PULSAR 1.8 V"
  },
  {
    SERIAL: "03GV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PULSAR 1.8 V SUNROOF NAVIGATOR",
    label: "PULSAR 1.8 V SUNROOF NAVIGATOR",
    value: "PULSAR 1.8 V SUNROOF NAVIGATOR"
  },
  {
    SERIAL: "03GW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BUS 40 SEAT",
    label: "BUS 40 SEAT",
    value: "BUS 40 SEAT"
  },
  {
    SERIAL: "03GX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.0 XE",
    label: "TEANA 2.0 XE",
    value: "TEANA 2.0 XE"
  },
  {
    SERIAL: "03GY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SKYLINE CROSSOVER 3.7",
    label: "SKYLINE CROSSOVER 3.7",
    value: "SKYLINE CROSSOVER 3.7"
  },
  {
    SERIAL: "03GZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.0 XL",
    label: "TEANA 2.0 XL",
    value: "TEANA 2.0 XL"
  },
  {
    SERIAL: "03HA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.0 XL NAVIGATOR",
    label: "TEANA 2.0 XL NAVIGATOR",
    value: "TEANA 2.0 XL NAVIGATOR"
  },
  {
    SERIAL: "03HB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.5 XV SUNROOF",
    label: "TEANA 2.5 XV SUNROOF",
    value: "TEANA 2.5 XV SUNROOF"
  },
  {
    SERIAL: "03HC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.5 XV SUNROOF NAVIGATOR",
    label: "TEANA 2.5 XV SUNROOF NAVIGATOR",
    value: "TEANA 2.5 XV SUNROOF NAVIGATOR"
  },
  {
    SERIAL: "03HD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "JUKE 1.6 E",
    label: "JUKE 1.6 E",
    value: "JUKE 1.6 E"
  },
  {
    SERIAL: "03HE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "JUKE 1.6 V",
    label: "JUKE 1.6 V",
    value: "JUKE 1.6 V"
  },
  {
    SERIAL: "03HF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CW30 (UNIQUE)",
    label: "CW30 (UNIQUE)",
    value: "CW30 (UNIQUE)"
  },
  {
    SERIAL: "03HG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "LIVINA V 1.6 A",
    label: "LIVINA V 1.6 A",
    value: "LIVINA V 1.6 A"
  },
  {
    SERIAL: "03HH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "LIVINA E 1.6 M",
    label: "LIVINA E 1.6 M",
    value: "LIVINA E 1.6 M"
  },
  {
    SERIAL: "03HI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "LIVINA E 1.6 A",
    label: "LIVINA E 1.6 A",
    value: "LIVINA E 1.6 A"
  },
  {
    SERIAL: "03HJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CD45",
    label: "CD45",
    value: "CD45"
  },
  {
    SERIAL: "03HK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CW30 - หัวลาก",
    label: "CW30 - หัวลาก",
    value: "CW30 - หัวลาก"
  },
  {
    SERIAL: "03HL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 EL",
    label: "ALMERA 1.2 EL",
    value: "ALMERA 1.2 EL"
  },
  {
    SERIAL: "03HM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB 2.5 A GT",
    label: "FRONTIER NAVARA KINGCAB 2.5 A GT",
    value: "FRONTIER NAVARA KINGCAB 2.5 A GT"
  },
  {
    SERIAL: "03HN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB CALIBRE 2.5 SE",
    label: "FRONTIER NAVARA KINGCAB CALIBRE 2.5 SE",
    value: "FRONTIER NAVARA KINGCAB CALIBRE 2.5 SE"
  },
  {
    SERIAL: "03HO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB GT 2.5 M",
    label: "FRONTIER NAVARA KINGCAB GT 2.5 M",
    value: "FRONTIER NAVARA KINGCAB GT 2.5 M"
  },
  {
    SERIAL: "03HP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB LE 2.5 M",
    label: "FRONTIER NAVARA KINGCAB LE 2.5 M",
    value: "FRONTIER NAVARA KINGCAB LE 2.5 M"
  },
  {
    SERIAL: "03HQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB SE 2.5 M CNG",
    label: "FRONTIER NAVARA KINGCAB SE 2.5 M CNG",
    value: "FRONTIER NAVARA KINGCAB SE 2.5 M CNG"
  },
  {
    SERIAL: "03HR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SILVIA S14",
    label: "SILVIA S14",
    value: "SILVIA S14"
  },
  {
    SERIAL: "03HS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN 2.5",
    label: "URVAN 2.5",
    value: "URVAN 2.5"
  },
  {
    SERIAL: "03HT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB SV CALIBRE 2.5M",
    label: "FRONTIER NAVARA KINGCAB SV CALIBRE 2.5M",
    value: "FRONTIER NAVARA KINGCAB SV CALIBRE 2.5M"
  },
  {
    SERIAL: "03HU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB SV 2.5 A",
    label: "FRONTIER NAVARA KINGCAB SV 2.5 A",
    value: "FRONTIER NAVARA KINGCAB SV 2.5 A"
  },
  {
    SERIAL: "03HV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB SV LE 4WD 2.5M",
    label: "FRONTIER NAVARA KINGCAB SV LE 4WD 2.5M",
    value: "FRONTIER NAVARA KINGCAB SV LE 4WD 2.5M"
  },
  {
    SERIAL: "03HW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA KINGCAB XE 2.5 M",
    label: "FRONTIER NAVARA KINGCAB XE 2.5 M",
    value: "FRONTIER NAVARA KINGCAB XE 2.5 M"
  },
  {
    SERIAL: "03HX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA SINGLECAB XE 2.5 CNG",
    label: "FRONTIER NAVARA SINGLECAB XE 2.5 CNG",
    value: "FRONTIER NAVARA SINGLECAB XE 2.5 CNG"
  },
  {
    SERIAL: "03HY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH VL SPORT 1.2 A",
    label: "MARCH VL SPORT 1.2 A",
    value: "MARCH VL SPORT 1.2 A"
  },
  {
    SERIAL: "03HZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 200 XL SPORT 2.0",
    label: "TEANA 200 XL SPORT 2.0",
    value: "TEANA 200 XL SPORT 2.0"
  },
  {
    SERIAL: "03IA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 200 XL SPORT NAVIGATOR 2.0",
    label: "TEANA 200 XL SPORT NAVIGATOR 2.0",
    value: "TEANA 200 XL SPORT NAVIGATOR 2.0"
  },
  {
    SERIAL: "03IB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN 2.5 M CNG",
    label: "URVAN 2.5 M CNG",
    value: "URVAN 2.5 M CNG"
  },
  {
    SERIAL: "03IC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN 2.5 A CNG",
    label: "URVAN 2.5 A CNG",
    value: "URVAN 2.5 A CNG"
  },
  {
    SERIAL: "03ID0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA 4D SV CALIBRE 2.5 SE",
    label: "FRONTIER NAVARA 4D SV CALIBRE 2.5 SE",
    value: "FRONTIER NAVARA 4D SV CALIBRE 2.5 SE"
  },
  {
    SERIAL: "03IE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA 4D SV CALIBRE 2.5 LE A",
    label: "FRONTIER NAVARA 4D SV CALIBRE 2.5 LE A",
    value: "FRONTIER NAVARA 4D SV CALIBRE 2.5 LE A"
  },
  {
    SERIAL: "03IF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB GT 2.5 M",
    label: "FRONTIER NAVARA DOUBLECAB GT 2.5 M",
    value: "FRONTIER NAVARA DOUBLECAB GT 2.5 M"
  },
  {
    SERIAL: "03IG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM430N (BUILT)",
    label: "CWM430N (BUILT)",
    value: "CWM430N (BUILT)"
  },
  {
    SERIAL: "03IH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB GT 2.5 A",
    label: "FRONTIER NAVARA DOUBLECAB GT 2.5 A",
    value: "FRONTIER NAVARA DOUBLECAB GT 2.5 A"
  },
  {
    SERIAL: "03II0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER NAVARA DOUBLECAB SV LE 4WD 2.5",
    label: "FRONTIER NAVARA DOUBLECAB SV LE 4WD 2.5",
    value: "FRONTIER NAVARA DOUBLECAB SV LE 4WD 2.5"
  },
  {
    SERIAL: "03IJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CD10K",
    label: "CD10K",
    value: "CD10K"
  },
  {
    SERIAL: "03IK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 2.0 S",
    label: "X-TRAIL 2.0 S",
    value: "X-TRAIL 2.0 S"
  },
  {
    SERIAL: "03IL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 2.0 E",
    label: "X-TRAIL 2.0 E",
    value: "X-TRAIL 2.0 E"
  },
  {
    SERIAL: "03IM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 4WD 2.0 V",
    label: "X-TRAIL 4WD 2.0 V",
    value: "X-TRAIL 4WD 2.0 V"
  },
  {
    SERIAL: "03IN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 4WD 2.5 V",
    label: "X-TRAIL 4WD 2.5 V",
    value: "X-TRAIL 4WD 2.5 V"
  },
  {
    SERIAL: "03IO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CBFB7F (BUILT)",
    label: "CBFB7F (BUILT)",
    value: "CBFB7F (BUILT)"
  },
  {
    SERIAL: "03IP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM430 (BUILT)",
    label: "CWM430 (BUILT)",
    value: "CWM430 (BUILT)"
  },
  {
    SERIAL: "03IQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "JUKE 1.6 S",
    label: "JUKE 1.6 S",
    value: "JUKE 1.6 S"
  },
  {
    SERIAL: "03IR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "LEAF",
    label: "LEAF",
    value: "LEAF"
  },
  {
    SERIAL: "03IT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB PRO4X 2.3",
    label: "NP300 NAVARA DOUBLE CAB PRO4X 2.3",
    value: "NP300 NAVARA DOUBLE CAB PRO4X 2.3"
  },
  {
    SERIAL: "03IU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB PRO2X 2.3",
    label: "NP300 NAVARA DOUBLE CAB PRO2X 2.3",
    value: "NP300 NAVARA DOUBLE CAB PRO2X 2.3"
  },
  {
    SERIAL: "03IY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB SL 2.5 MT",
    label: "NP300 NAVARA KING CAB SL 2.5 MT",
    value: "NP300 NAVARA KING CAB SL 2.5 MT"
  },
  {
    SERIAL: "03JA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 VL SPORTECH",
    label: "ALMERA 1.2 VL SPORTECH",
    value: "ALMERA 1.2 VL SPORTECH"
  },
  {
    SERIAL: "03JB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH E MT LIMITED EDITION",
    label: "MARCH E MT LIMITED EDITION",
    value: "MARCH E MT LIMITED EDITION"
  },
  {
    SERIAL: "03JC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH E CVT LIMITED EDITION",
    label: "MARCH E CVT LIMITED EDITION",
    value: "MARCH E CVT LIMITED EDITION"
  },
  {
    SERIAL: "03JD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH EL CVT SMART EDITION",
    label: "MARCH EL CVT SMART EDITION",
    value: "MARCH EL CVT SMART EDITION"
  },
  {
    SERIAL: "03JE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH EL CVT LIMITED EDITION",
    label: "MARCH EL CVT LIMITED EDITION",
    value: "MARCH EL CVT LIMITED EDITION"
  },
  {
    SERIAL: "03JF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PULSAR 1.6 DIG TURBO",
    label: "PULSAR 1.6 DIG TURBO",
    value: "PULSAR 1.6 DIG TURBO"
  },
  {
    SERIAL: "03JG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "PULSAR 1.6 SMART EDITION",
    label: "PULSAR 1.6 SMART EDITION",
    value: "PULSAR 1.6 SMART EDITION"
  },
  {
    SERIAL: "03JH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY 1.6 SMART EDITION",
    label: "SYLPHY 1.6 SMART EDITION",
    value: "SYLPHY 1.6 SMART EDITION"
  },
  {
    SERIAL: "03JI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 E SPORTECH",
    label: "ALMERA 1.2 E SPORTECH",
    value: "ALMERA 1.2 E SPORTECH"
  },
  {
    SERIAL: "03JJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 SINGLE CAB CHASIS 2.5 M",
    label: "NP300 SINGLE CAB CHASIS 2.5 M",
    value: "NP300 SINGLE CAB CHASIS 2.5 M"
  },
  {
    SERIAL: "03JK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 SINGLE CAB S 2.5 BENZINE",
    label: "NP300 SINGLE CAB S 2.5 BENZINE",
    value: "NP300 SINGLE CAB S 2.5 BENZINE"
  },
  {
    SERIAL: "03JL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 SINGLE CAB S 2.5 DIESEL",
    label: "NP300 SINGLE CAB S 2.5 DIESEL",
    value: "NP300 SINGLE CAB S 2.5 DIESEL"
  },
  {
    SERIAL: "03JM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 SINGLE CAB SL 2.5 DIESEL",
    label: "NP300 SINGLE CAB SL 2.5 DIESEL",
    value: "NP300 SINGLE CAB SL 2.5 DIESEL"
  },
  {
    SERIAL: "03JN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TERRA 2.3 V",
    label: "TERRA 2.3 V",
    value: "TERRA 2.3 V"
  },
  {
    SERIAL: "03JO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TERRA 2.3 VL",
    label: "TERRA 2.3 VL",
    value: "TERRA 2.3 VL"
  },
  {
    SERIAL: "03JP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TERRA 2.3 VL 4WD",
    label: "TERRA 2.3 VL 4WD",
    value: "TERRA 2.3 VL 4WD"
  },
  {
    SERIAL: "03JS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FAIRLADY 370Z",
    label: "FAIRLADY 370Z",
    value: "FAIRLADY 370Z"
  },
  {
    SERIAL: "03JV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB E MT",
    label: "NP300 NAVARA KING CAB E MT",
    value: "NP300 NAVARA KING CAB E MT"
  },
  {
    SERIAL: "03KB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB CALIBRE V A",
    label: "NP300 NAVARA KING CAB CALIBRE V A",
    value: "NP300 NAVARA KING CAB CALIBRE V A"
  },
  {
    SERIAL: "03KH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "03KR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "UA50 (BUILT) BUS",
    label: "UA50 (BUILT) BUS",
    value: "UA50 (BUILT) BUS"
  },
  {
    SERIAL: "03KT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH",
    label: "MARCH",
    value: "MARCH"
  },
  {
    SERIAL: "03KV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB 4WD VL MT",
    label: "NP300 NAVARA DOUBLE CAB 4WD VL MT",
    value: "NP300 NAVARA DOUBLE CAB 4WD VL MT"
  },
  {
    SERIAL: "03LA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA NISMO 1.2 E",
    label: "ALMERA NISMO 1.2 E",
    value: "ALMERA NISMO 1.2 E"
  },
  {
    SERIAL: "03LB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA NISMO 1.2 EL",
    label: "ALMERA NISMO 1.2 EL",
    value: "ALMERA NISMO 1.2 EL"
  },
  {
    SERIAL: "03LC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA NISMO 1.2 VL",
    label: "ALMERA NISMO 1.2 VL",
    value: "ALMERA NISMO 1.2 VL"
  },
  {
    SERIAL: "03LD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 KING CAB CALIBRE SPORTECH 2.5 EL",
    label: "NP300 KING CAB CALIBRE SPORTECH 2.5 EL",
    value: "NP300 KING CAB CALIBRE SPORTECH 2.5 EL"
  },
  {
    SERIAL: "03LE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 EL",
    label: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 EL",
    value: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 EL"
  },
  {
    SERIAL: "03LF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 V",
    label: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 V",
    value: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 V"
  },
  {
    SERIAL: "03LG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 VL",
    label: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 VL",
    value: "NP300 DOUBLECAB CALIBRE SPORTECH 2.5 VL"
  },
  {
    SERIAL: "03LH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 DOUBLECAB 4WD SPORTECH 2.5 VL",
    label: "NP300 DOUBLECAB 4WD SPORTECH 2.5 VL",
    value: "NP300 DOUBLECAB 4WD SPORTECH 2.5 VL"
  },
  {
    SERIAL: "03LI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "JUKE 1.6 TOKYO EDITION",
    label: "JUKE 1.6 TOKYO EDITION",
    value: "JUKE 1.6 TOKYO EDITION"
  },
  {
    SERIAL: "03LJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY SV 1.8 A",
    label: "SYLPHY SV 1.8 A",
    value: "SYLPHY SV 1.8 A"
  },
  {
    SERIAL: "03LK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY 1.6 A DIG TURBO",
    label: "SYLPHY 1.6 A DIG TURBO",
    value: "SYLPHY 1.6 A DIG TURBO"
  },
  {
    SERIAL: "03MA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MARCH EL SPORT",
    label: "MARCH EL SPORT",
    value: "MARCH EL SPORT"
  },
  {
    SERIAL: "03MB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY E 1.6 A CNG",
    label: "SYLPHY E 1.6 A CNG",
    value: "SYLPHY E 1.6 A CNG"
  },
  {
    SERIAL: "03MC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SYLPHY SV 1.6 A",
    label: "SYLPHY SV 1.6 A",
    value: "SYLPHY SV 1.6 A"
  },
  {
    SERIAL: "03MD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB CARIBRE E MT",
    label: "NP300 NAVARA DOUBLE CAB CARIBRE E MT",
    value: "NP300 NAVARA DOUBLE CAB CARIBRE E MT"
  },
  {
    SERIAL: "03ME0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB CARIBRE EL MT",
    label: "NP300 NAVARA DOUBLE CAB CARIBRE EL MT",
    value: "NP300 NAVARA DOUBLE CAB CARIBRE EL MT"
  },
  {
    SERIAL: "03MF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB CARIBRE EL AT",
    label: "NP300 NAVARA DOUBLE CAB CARIBRE EL AT",
    value: "NP300 NAVARA DOUBLE CAB CARIBRE EL AT"
  },
  {
    SERIAL: "03MH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB CARIBRE VL MT",
    label: "NP300 NAVARA DOUBLE CAB CARIBRE VL MT",
    value: "NP300 NAVARA DOUBLE CAB CARIBRE VL MT"
  },
  {
    SERIAL: "03MI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB 4WD S MT",
    label: "NP300 NAVARA DOUBLE CAB 4WD S MT",
    value: "NP300 NAVARA DOUBLE CAB 4WD S MT"
  },
  {
    SERIAL: "03MJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB 4WD VL A",
    label: "NP300 NAVARA DOUBLE CAB 4WD VL A",
    value: "NP300 NAVARA DOUBLE CAB 4WD VL A"
  },
  {
    SERIAL: "03MP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN 3.0 STANDARDROOF",
    label: "URVAN 3.0 STANDARDROOF",
    value: "URVAN 3.0 STANDARDROOF"
  },
  {
    SERIAL: "03MQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY NEO VIP SPEC V 1.6",
    label: "SUNNY NEO VIP SPEC V 1.6",
    value: "SUNNY NEO VIP SPEC V 1.6"
  },
  {
    SERIAL: "03MR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "XCITER 4WD",
    label: "XCITER 4WD",
    value: "XCITER 4WD"
  },
  {
    SERIAL: "03MS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CW66",
    label: "CW66",
    value: "CW66"
  },
  {
    SERIAL: "03MT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "เกิน 12 ตัน",
    label: "เกิน 12 ตัน",
    value: "เกิน 12 ตัน"
  },
  {
    SERIAL: "03MU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "350 Z",
    label: "350 Z",
    value: "350 Z"
  },
  {
    SERIAL: "03MV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM454",
    label: "CWM454",
    value: "CWM454"
  },
  {
    SERIAL: "03MW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY SUPER NEO 1.8",
    label: "SUNNY SUPER NEO 1.8",
    value: "SUNNY SUPER NEO 1.8"
  },
  {
    SERIAL: "03MX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SUNNY VIP NEO 1.8 (AIRBAG คู่)",
    label: "SUNNY VIP NEO 1.8 (AIRBAG คู่)",
    value: "SUNNY VIP NEO 1.8 (AIRBAG คู่)"
  },
  {
    SERIAL: "03MY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ELGRAND",
    label: "ELGRAND",
    value: "ELGRAND"
  },
  {
    SERIAL: "03MZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWM430 (BUILT)",
    label: "CWM430 (BUILT)",
    value: "CWM430 (BUILT)"
  },
  {
    SERIAL: "03NB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN",
    label: "URVAN",
    value: "URVAN"
  },
  {
    SERIAL: "03NC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "URVAN",
    label: "URVAN",
    value: "URVAN"
  },
  {
    SERIAL: "03ND0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SENTRA",
    label: "SENTRA",
    value: "SENTRA"
  },
  {
    SERIAL: "03NE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB S MT",
    label: "NP300 NAVARA KING CAB S MT",
    value: "NP300 NAVARA KING CAB S MT"
  },
  {
    SERIAL: "03NG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB V MT",
    label: "NP300 NAVARA KING CAB V MT",
    value: "NP300 NAVARA KING CAB V MT"
  },
  {
    SERIAL: "03NH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB CALIBRE S MT",
    label: "NP300 NAVARA KING CAB CALIBRE S MT",
    value: "NP300 NAVARA KING CAB CALIBRE S MT"
  },
  {
    SERIAL: "03NI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB CALIBRE E MT",
    label: "NP300 NAVARA KING CAB CALIBRE E MT",
    value: "NP300 NAVARA KING CAB CALIBRE E MT"
  },
  {
    SERIAL: "03NJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB CALIBRE EL MT",
    label: "NP300 NAVARA KING CAB CALIBRE EL MT",
    value: "NP300 NAVARA KING CAB CALIBRE EL MT"
  },
  {
    SERIAL: "03NK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB CARIBRE V AT",
    label: "NP300 NAVARA DOUBLE CAB CARIBRE V AT",
    value: "NP300 NAVARA DOUBLE CAB CARIBRE V AT"
  },
  {
    SERIAL: "03NL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB V 4WD MT",
    label: "NP300 NAVARA KING CAB V 4WD MT",
    value: "NP300 NAVARA KING CAB V 4WD MT"
  },
  {
    SERIAL: "03NM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB S MT",
    label: "NP300 NAVARA DOUBLE CAB S MT",
    value: "NP300 NAVARA DOUBLE CAB S MT"
  },
  {
    SERIAL: "03NN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB E MT",
    label: "NP300 NAVARA DOUBLE CAB E MT",
    value: "NP300 NAVARA DOUBLE CAB E MT"
  },
  {
    SERIAL: "03NO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB CARIBRE S MT",
    label: "NP300 NAVARA DOUBLE CAB CARIBRE S MT",
    value: "NP300 NAVARA DOUBLE CAB CARIBRE S MT"
  },
  {
    SERIAL: "03NP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "JP252S (BUS)",
    label: "JP252S (BUS)",
    value: "JP252S (BUS)"
  },
  {
    SERIAL: "03NQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "OVER 2000",
    label: "OVER 2000",
    value: "OVER 2000"
  },
  {
    SERIAL: "03NS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL (COMFORT) 2.5",
    label: "X-TRAIL (COMFORT) 2.5",
    value: "X-TRAIL (COMFORT) 2.5"
  },
  {
    SERIAL: "03NT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL (LUXURY) 2.5",
    label: "X-TRAIL (LUXURY) 2.5",
    value: "X-TRAIL (LUXURY) 2.5"
  },
  {
    SERIAL: "03NV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "MURANO 3.5",
    label: "MURANO 3.5",
    value: "MURANO 3.5"
  },
  {
    SERIAL: "03NW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.0 JK",
    label: "TEANA 2.0 JK",
    value: "TEANA 2.0 JK"
  },
  {
    SERIAL: "03NX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "K2F23 (BUILT)",
    label: "K2F23 (BUILT)",
    value: "K2F23 (BUILT)"
  },
  {
    SERIAL: "03NY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA JS 2.3",
    label: "TEANA JS 2.3",
    value: "TEANA JS 2.3"
  },
  {
    SERIAL: "03OA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SERENA 2.0",
    label: "SERENA 2.0",
    value: "SERENA 2.0"
  },
  {
    SERIAL: "03OB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "UD GME11 (JRCZM) ลากจูง",
    label: "UD GME11 (JRCZM) ลากจูง",
    value: "UD GME11 (JRCZM) ลากจูง"
  },
  {
    SERIAL: "03OC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "JPCZM30D2ET (หัวลาก)",
    label: "JPCZM30D2ET (หัวลาก)",
    value: "JPCZM30D2ET (หัวลาก)"
  },
  {
    SERIAL: "03OD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ACURA NSX 3.2",
    label: "ACURA NSX 3.2",
    value: "ACURA NSX 3.2"
  },
  {
    SERIAL: "03OE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL HYBRID 2.0 S",
    label: "X-TRAIL HYBRID 2.0 S",
    value: "X-TRAIL HYBRID 2.0 S"
  },
  {
    SERIAL: "03OF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL HYBRID 2.0 E",
    label: "X-TRAIL HYBRID 2.0 E",
    value: "X-TRAIL HYBRID 2.0 E"
  },
  {
    SERIAL: "03OG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL HYBRID 2.0 V 4WD",
    label: "X-TRAIL HYBRID 2.0 V 4WD",
    value: "X-TRAIL HYBRID 2.0 V 4WD"
  },
  {
    SERIAL: "03OH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "GWE11",
    label: "GWE11",
    value: "GWE11"
  },
  {
    SERIAL: "03OI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CWE08",
    label: "CWE08",
    value: "CWE08"
  },
  {
    SERIAL: "03OK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NOTE 1.2 V",
    label: "NOTE 1.2 V",
    value: "NOTE 1.2 V"
  },
  {
    SERIAL: "03OL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NOTE 1.2 VL",
    label: "NOTE 1.2 VL",
    value: "NOTE 1.2 VL"
  },
  {
    SERIAL: "03OM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA",
    label: "ALMERA",
    value: "ALMERA"
  },
  {
    SERIAL: "03ON0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "FRONTIER",
    label: "FRONTIER",
    value: "FRONTIER"
  },
  {
    SERIAL: "03OO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA",
    label: "TEANA",
    value: "TEANA"
  },
  {
    SERIAL: "03OP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TIIDA",
    label: "TIIDA",
    value: "TIIDA"
  },
  {
    SERIAL: "03OQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "CK60BT (BUILT)",
    label: "CK60BT (BUILT)",
    value: "CK60BT (BUILT)"
  },
  {
    SERIAL: "03OR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "03OS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "GT-R 3.8",
    label: "GT-R 3.8",
    value: "GT-R 3.8"
  },
  {
    SERIAL: "03OT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "03OU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ELGRAND 3.5",
    label: "ELGRAND 3.5",
    value: "ELGRAND 3.5"
  },
  {
    SERIAL: "03OV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA KING CAB CARIBRE EL SPORTE CH",
    label: "NP300 NAVARA KING CAB CARIBRE EL SPORTE CH",
    value: "NP300 NAVARA KING CAB CARIBRE EL SPORTE CH"
  },
  {
    SERIAL: "03OW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA SINGLE CAB 4WD SL 2.5 M",
    label: "NP300 NAVARA SINGLE CAB 4WD SL 2.5 M",
    value: "NP300 NAVARA SINGLE CAB 4WD SL 2.5 M"
  },
  {
    SERIAL: "03OX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA SINGLE CAB SWB 2.5",
    label: "NP300 NAVARA SINGLE CAB SWB 2.5",
    value: "NP300 NAVARA SINGLE CAB SWB 2.5"
  },
  {
    SERIAL: "03OY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 KING CAB CARIBRE BLACK EDITION E2 .5",
    label: "NP300 KING CAB CARIBRE BLACK EDITION E2 .5",
    value: "NP300 KING CAB CARIBRE BLACK EDITION E2 .5"
  },
  {
    SERIAL: "03OZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "GT-R",
    label: "GT-R",
    value: "GT-R"
  },
  {
    SERIAL: "03PA0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.0 XE",
    label: "TEANA 2.0 XE",
    value: "TEANA 2.0 XE"
  },
  {
    SERIAL: "03PB0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.0 XL",
    label: "TEANA 2.0 XL",
    value: "TEANA 2.0 XL"
  },
  {
    SERIAL: "03PC0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.0 XL NAVIGATOR",
    label: "TEANA 2.0 XL NAVIGATOR",
    value: "TEANA 2.0 XL NAVIGATOR"
  },
  {
    SERIAL: "03PD0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TEANA 2.5 XV NAVIGATOR",
    label: "TEANA 2.5 XV NAVIGATOR",
    value: "TEANA 2.5 XV NAVIGATOR"
  },
  {
    SERIAL: "03PE0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.2 V SPORTECH",
    label: "ALMERA 1.2 V SPORTECH",
    value: "ALMERA 1.2 V SPORTECH"
  },
  {
    SERIAL: "03PF0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 2.5 S",
    label: "X-TRAIL 2.5 S",
    value: "X-TRAIL 2.5 S"
  },
  {
    SERIAL: "03PG0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 2.5 V",
    label: "X-TRAIL 2.5 V",
    value: "X-TRAIL 2.5 V"
  },
  {
    SERIAL: "03PH0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL 2.5 VL 4WD",
    label: "X-TRAIL 2.5 VL 4WD",
    value: "X-TRAIL 2.5 VL 4WD"
  },
  {
    SERIAL: "03PI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL HYBRID 2.0 VL 4WD",
    label: "X-TRAIL HYBRID 2.0 VL 4WD",
    value: "X-TRAIL HYBRID 2.0 VL 4WD"
  },
  {
    SERIAL: "03PJ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NOTE 1.2 E",
    label: "NOTE 1.2 E",
    value: "NOTE 1.2 E"
  },
  {
    SERIAL: "03PK0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "03PL0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.0 S",
    label: "ALMERA 1.0 S",
    value: "ALMERA 1.0 S"
  },
  {
    SERIAL: "03PM0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.0 E",
    label: "ALMERA 1.0 E",
    value: "ALMERA 1.0 E"
  },
  {
    SERIAL: "03PN0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.0 EL",
    label: "ALMERA 1.0 EL",
    value: "ALMERA 1.0 EL"
  },
  {
    SERIAL: "03PO0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.0 V",
    label: "ALMERA 1.0 V",
    value: "ALMERA 1.0 V"
  },
  {
    SERIAL: "03PP0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "ALMERA 1.0 VL",
    label: "ALMERA 1.0 VL",
    value: "ALMERA 1.0 VL"
  },
  {
    SERIAL: "03PQ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "X-TRAIL HYBRID 2.0 V 4WD",
    label: "X-TRAIL HYBRID 2.0 V 4WD",
    value: "X-TRAIL HYBRID 2.0 V 4WD"
  },
  {
    SERIAL: "03PR0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "KICKS (EV) E",
    label: "KICKS (EV) E",
    value: "KICKS (EV) E"
  },
  {
    SERIAL: "03PS0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "KICKS (EV) S",
    label: "KICKS (EV) S",
    value: "KICKS (EV) S"
  },
  {
    SERIAL: "03PT0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "KICKS (EV) V",
    label: "KICKS (EV) V",
    value: "KICKS (EV) V"
  },
  {
    SERIAL: "03PU0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "KICKS (EV) VL",
    label: "KICKS (EV) VL",
    value: "KICKS (EV) VL"
  },
  {
    SERIAL: "03PV0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB CARIBRE BLACK E DITION E 2.5",
    label: "NP300 NAVARA DOUBLE CAB CARIBRE BLACK E DITION E 2.5",
    value: "NP300 NAVARA DOUBLE CAB CARIBRE BLACK E DITION E 2.5"
  },
  {
    SERIAL: "03PW0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "SAFARI",
    label: "SAFARI",
    value: "SAFARI"
  },
  {
    SERIAL: "03PX0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "KICKS (EV) VL PREMIER EDITION",
    label: "KICKS (EV) VL PREMIER EDITION",
    value: "KICKS (EV) VL PREMIER EDITION"
  },
  {
    SERIAL: "03PY0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB N-TREK WARRIOR",
    label: "NP300 NAVARA DOUBLE CAB N-TREK WARRIOR",
    value: "NP300 NAVARA DOUBLE CAB N-TREK WARRIOR"
  },
  {
    SERIAL: "03PZ0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "NP300 NAVARA DOUBLE CAB N-TREK WARRIOR4 *4",
    label: "NP300 NAVARA DOUBLE CAB N-TREK WARRIOR4 *4",
    value: "NP300 NAVARA DOUBLE CAB N-TREK WARRIOR4 *4"
  },
  {
    SERIAL: "03QI0000026",
    MAKE_NAME: "NISSAN",
    MODEL_NAME: "GT-R NISMO 3.8",
    label: "GT-R NISMO 3.8",
    value: "GT-R NISMO 3.8"
  },
  {
    SERIAL: "4000000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MITSUBISHI",
    label: "MITSUBISHI",
    value: "MITSUBISHI"
  },
  {
    SERIAL: "4020000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "CHAMP 1.3",
    label: "CHAMP 1.3",
    value: "CHAMP 1.3"
  },
  {
    SERIAL: "4040000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "CHAMP 1.5 4Dr.",
    label: "CHAMP 1.5 4Dr.",
    value: "CHAMP 1.5 4Dr."
  },
  {
    SERIAL: "4050000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLX 1.5 M",
    label: "LANCER GLX 1.5 M",
    value: "LANCER GLX 1.5 M"
  },
  {
    SERIAL: "4060000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER 1.3 EL",
    label: "LANCER 1.3 EL",
    value: "LANCER 1.3 EL"
  },
  {
    SERIAL: "4070000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi 1.5 M",
    label: "LANCER GLXi 1.5 M",
    value: "LANCER GLXi 1.5 M"
  },
  {
    SERIAL: "4080000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi 1.5 A",
    label: "LANCER GLXi 1.5 A",
    value: "LANCER GLXi 1.5 A"
  },
  {
    SERIAL: "4090000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi 1.6 M",
    label: "LANCER GLXi 1.6 M",
    value: "LANCER GLXi 1.6 M"
  },
  {
    SERIAL: "4100000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi 1.6 A",
    label: "LANCER GLXi 1.6 A",
    value: "LANCER GLXi 1.6 A"
  },
  {
    SERIAL: "4110000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GTi 1.8",
    label: "LANCER GTi 1.8",
    value: "LANCER GTi 1.8"
  },
  {
    SERIAL: "4120000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ASTI 1.8 M",
    label: "ASTI 1.8 M",
    value: "ASTI 1.8 M"
  },
  {
    SERIAL: "4130000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ASTI 1.8 A",
    label: "ASTI 1.8 A",
    value: "ASTI 1.8 A"
  },
  {
    SERIAL: "4140000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "COLT GLXi 1.6",
    label: "COLT GLXi 1.6",
    value: "COLT GLXi 1.6"
  },
  {
    SERIAL: "4150000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "GALANT",
    label: "GALANT",
    value: "GALANT"
  },
  {
    SERIAL: "4160000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ULTIMA 16V 2.0 M",
    label: "ULTIMA 16V 2.0 M",
    value: "ULTIMA 16V 2.0 M"
  },
  {
    SERIAL: "4170000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ULTIMA 16V 2.0 A",
    label: "ULTIMA 16V 2.0 A",
    value: "ULTIMA 16V 2.0 A"
  },
  {
    SERIAL: "4180000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ULTIMA 24V ABS M",
    label: "ULTIMA 24V ABS M",
    value: "ULTIMA 24V ABS M"
  },
  {
    SERIAL: "4200000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ULTIMA 24V A",
    label: "ULTIMA 24V A",
    value: "ULTIMA 24V A"
  },
  {
    SERIAL: "4210000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "GALANT GLSi 2.0",
    label: "GALANT GLSi 2.0",
    value: "GALANT GLSi 2.0"
  },
  {
    SERIAL: "4220000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "GALANT GTi 2.0",
    label: "GALANT GTi 2.0",
    value: "GALANT GTi 2.0"
  },
  {
    SERIAL: "4230000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "GALANT ROYAL 1.8",
    label: "GALANT ROYAL 1.8",
    value: "GALANT ROYAL 1.8"
  },
  {
    SERIAL: "4240000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "GALANT ROYAL 2.0",
    label: "GALANT ROYAL 2.0",
    value: "GALANT ROYAL 2.0"
  },
  {
    SERIAL: "4250000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "GTO 3000",
    label: "GTO 3000",
    value: "GTO 3000"
  },
  {
    SERIAL: "4270000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 2.5",
    label: "PAJERO 2.5",
    value: "PAJERO 2.5"
  },
  {
    SERIAL: "4280000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO V6 A",
    label: "PAJERO V6 A",
    value: "PAJERO V6 A"
  },
  {
    SERIAL: "4310000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "SPACE WAGON 1.8 A",
    label: "SPACE WAGON 1.8 A",
    value: "SPACE WAGON 1.8 A"
  },
  {
    SERIAL: "4320000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "SPACE WAGON 1.8 M",
    label: "SPACE WAGON 1.8 M",
    value: "SPACE WAGON 1.8 M"
  },
  {
    SERIAL: "4330000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "L300",
    label: "L300",
    value: "L300"
  },
  {
    SERIAL: "4340000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "CYCLONE",
    label: "CYCLONE",
    value: "CYCLONE"
  },
  {
    SERIAL: "4350000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "AEROBODY DIESEL",
    label: "AEROBODY DIESEL",
    value: "AEROBODY DIESEL"
  },
  {
    SERIAL: "4360000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "AEROBODY TURBO",
    label: "AEROBODY TURBO",
    value: "AEROBODY TURBO"
  },
  {
    SERIAL: "4370000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA",
    label: "STRADA",
    value: "STRADA"
  },
  {
    SERIAL: "4380000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA SINGLE CAB 2.5",
    label: "STRADA SINGLE CAB 2.5",
    value: "STRADA SINGLE CAB 2.5"
  },
  {
    SERIAL: "4390000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA MEGA CAB 2.5D GL",
    label: "STRADA MEGA CAB 2.5D GL",
    value: "STRADA MEGA CAB 2.5D GL"
  },
  {
    SERIAL: "4400000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA MEGACAB 4WD",
    label: "STRADA MEGACAB 4WD",
    value: "STRADA MEGACAB 4WD"
  },
  {
    SERIAL: "4410000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA MEGA CAB 2.5 GLX",
    label: "STRADA MEGA CAB 2.5 GLX",
    value: "STRADA MEGA CAB 2.5 GLX"
  },
  {
    SERIAL: "4430000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi 1.5 M (Y.1996)",
    label: "LANCER GLXi 1.5 M (Y.1996)",
    value: "LANCER GLXi 1.5 M (Y.1996)"
  },
  {
    SERIAL: "4440000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi1.5 A",
    label: "LANCER GLXi1.5 A",
    value: "LANCER GLXi1.5 A"
  },
  {
    SERIAL: "4450000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi LIMITED 1.5 M",
    label: "LANCER GLXi LIMITED 1.5 M",
    value: "LANCER GLXi LIMITED 1.5 M"
  },
  {
    SERIAL: "4460000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXi LIMITED 1.5 A",
    label: "LANCER GLXi LIMITED 1.5 A",
    value: "LANCER GLXi LIMITED 1.5 A"
  },
  {
    SERIAL: "4470000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER SEi 1.8 M",
    label: "LANCER SEi 1.8 M",
    value: "LANCER SEi 1.8 M"
  },
  {
    SERIAL: "4480000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER SEi 1.8 A",
    label: "LANCER SEi 1.8 A",
    value: "LANCER SEi 1.8 A"
  },
  {
    SERIAL: "4490000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER SEI AIRBAG ABS 1.8 A",
    label: "LANCER SEI AIRBAG ABS 1.8 A",
    value: "LANCER SEI AIRBAG ABS 1.8 A"
  },
  {
    SERIAL: "4500000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FUSO",
    label: "FUSO",
    value: "FUSO"
  },
  {
    SERIAL: "4510000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "COLT",
    label: "COLT",
    value: "COLT"
  },
  {
    SERIAL: "4520000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "L200 AEROBODY",
    label: "L200 AEROBODY",
    value: "L200 AEROBODY"
  },
  {
    SERIAL: "4530000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "CANTER",
    label: "CANTER",
    value: "CANTER"
  },
  {
    SERIAL: "4560000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "EVOLUTION 4",
    label: "EVOLUTION 4",
    value: "EVOLUTION 4"
  },
  {
    SERIAL: "4570000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FUSO CBU",
    label: "FUSO CBU",
    value: "FUSO CBU"
  },
  {
    SERIAL: "4580000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 3.0",
    label: "PAJERO 3.0",
    value: "PAJERO 3.0"
  },
  {
    SERIAL: "4610000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 2.8 TURBO",
    label: "PAJERO 2.8 TURBO",
    value: "PAJERO 2.8 TURBO"
  },
  {
    SERIAL: "4630000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER",
    label: "LANCER",
    value: "LANCER"
  },
  {
    SERIAL: "4640000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE444",
    label: "FE444",
    value: "FE444"
  },
  {
    SERIAL: "4650000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE449",
    label: "FE449",
    value: "FE449"
  },
  {
    SERIAL: "4690000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE657",
    label: "FE657",
    value: "FE657"
  },
  {
    SERIAL: "4700000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK415",
    label: "FK415",
    value: "FK415"
  },
  {
    SERIAL: "4740000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK455",
    label: "FK455",
    value: "FK455"
  },
  {
    SERIAL: "4750000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK457",
    label: "FK457",
    value: "FK457"
  },
  {
    SERIAL: "4760000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK458",
    label: "FK458",
    value: "FK458"
  },
  {
    SERIAL: "4770000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN515",
    label: "FN515",
    value: "FN515"
  },
  {
    SERIAL: "4780000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN517",
    label: "FN517",
    value: "FN517"
  },
  {
    SERIAL: "4790000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN518",
    label: "FN518",
    value: "FN518"
  },
  {
    SERIAL: "4800000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN527",
    label: "FN527",
    value: "FN527"
  },
  {
    SERIAL: "4810000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN528",
    label: "FN528",
    value: "FN528"
  },
  {
    SERIAL: "4830000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER EVOLUTION 2",
    label: "LANCER EVOLUTION 2",
    value: "LANCER EVOLUTION 2"
  },
  {
    SERIAL: "4850000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO",
    label: "PAJERO",
    value: "PAJERO"
  },
  {
    SERIAL: "4860000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER F STYLE 1.6 GLXI A",
    label: "LANCER F STYLE 1.6 GLXI A",
    value: "LANCER F STYLE 1.6 GLXI A"
  },
  {
    SERIAL: "4870000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER F STYLE 1.6 GLXI M",
    label: "LANCER F STYLE 1.6 GLXI M",
    value: "LANCER F STYLE 1.6 GLXI M"
  },
  {
    SERIAL: "4880000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER F STYLE 1.6 GLXI M LTD.",
    label: "LANCER F STYLE 1.6 GLXI M LTD.",
    value: "LANCER F STYLE 1.6 GLXI M LTD."
  },
  {
    SERIAL: "4890000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER F STYLE 1.6 GLXI A LTD.",
    label: "LANCER F STYLE 1.6 GLXI A LTD.",
    value: "LANCER F STYLE 1.6 GLXI A LTD."
  },
  {
    SERIAL: "4900000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER F STYLE 1.8 SEI A LTD.",
    label: "LANCER F STYLE 1.8 SEI A LTD.",
    value: "LANCER F STYLE 1.8 SEI A LTD."
  },
  {
    SERIAL: "4910000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA GRANDIS 2.8 GLX",
    label: "STRADA GRANDIS 2.8 GLX",
    value: "STRADA GRANDIS 2.8 GLX"
  },
  {
    SERIAL: "4920000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA GRANDIS 2.8/4 DR (4WD)",
    label: "STRADA GRANDIS 2.8/4 DR (4WD)",
    value: "STRADA GRANDIS 2.8/4 DR (4WD)"
  },
  {
    SERIAL: "4930000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA GRANDIS 2.8/4 DR TWO TONE (4WD) (2000-2004)",
    label: "STRADA GRANDIS 2.8/4 DR TWO TONE (4WD) (2000-2004)",
    value: "STRADA GRANDIS 2.8/4 DR TWO TONE (4WD) (2000-2004)"
  },
  {
    SERIAL: "4940000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA SUPER 2.8 MEGA CAB GLX (M)",
    label: "STRADA SUPER 2.8 MEGA CAB GLX (M)",
    value: "STRADA SUPER 2.8 MEGA CAB GLX (M)"
  },
  {
    SERIAL: "4950000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA SUPER 2.8 MEGA CAB GLS (M) 4WD",
    label: "STRADA SUPER 2.8 MEGA CAB GLS (M) 4WD",
    value: "STRADA SUPER 2.8 MEGA CAB GLS (M) 4WD"
  },
  {
    SERIAL: "4960000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA GRANDIS 4D 2.5",
    label: "STRADA GRANDIS 4D 2.5",
    value: "STRADA GRANDIS 4D 2.5"
  },
  {
    SERIAL: "4970000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA SUPER 2.8 GLX",
    label: "STRADA SUPER 2.8 GLX",
    value: "STRADA SUPER 2.8 GLX"
  },
  {
    SERIAL: "4980000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ATTRAGE 1.2 GLX A",
    label: "ATTRAGE 1.2 GLX A",
    value: "ATTRAGE 1.2 GLX A"
  },
  {
    SERIAL: "4990000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ATTRAGE 1.2 GLS A",
    label: "ATTRAGE 1.2 GLS A",
    value: "ATTRAGE 1.2 GLS A"
  },
  {
    SERIAL: "04AA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "6 ตัน",
    label: "6 ตัน",
    value: "6 ตัน"
  },
  {
    SERIAL: "04AB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "12 ตัน",
    label: "12 ตัน",
    value: "12 ตัน"
  },
  {
    SERIAL: "04AC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "OVER12000",
    label: "OVER12000",
    value: "OVER12000"
  },
  {
    SERIAL: "04AE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA GLXi 1.6",
    label: "LANCER CEDIA GLXi 1.6",
    value: "LANCER CEDIA GLXi 1.6"
  },
  {
    SERIAL: "04AF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA GLXi INVECS III 1.6 A",
    label: "LANCER CEDIA GLXi INVECS III 1.6 A",
    value: "LANCER CEDIA GLXi INVECS III 1.6 A"
  },
  {
    SERIAL: "04AG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA LIMITED 1.6 M",
    label: "LANCER CEDIA LIMITED 1.6 M",
    value: "LANCER CEDIA LIMITED 1.6 M"
  },
  {
    SERIAL: "04AH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA LIMITED INVECS III 1.6 A",
    label: "LANCER CEDIA LIMITED INVECS III 1.6 A",
    value: "LANCER CEDIA LIMITED INVECS III 1.6 A"
  },
  {
    SERIAL: "04AI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA INVECS III SPORTTRONIC1.8",
    label: "LANCER CEDIA INVECS III SPORTTRONIC1.8",
    value: "LANCER CEDIA INVECS III SPORTTRONIC1.8"
  },
  {
    SERIAL: "04AJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA G.WAGON M",
    label: "STRADA G.WAGON M",
    value: "STRADA G.WAGON M"
  },
  {
    SERIAL: "04AK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA G.WAGON M TWOTONE",
    label: "STRADA G.WAGON M TWOTONE",
    value: "STRADA G.WAGON M TWOTONE"
  },
  {
    SERIAL: "04AL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA G.WAGON GLS A TWOTONE",
    label: "STRADA G.WAGON GLS A TWOTONE",
    value: "STRADA G.WAGON GLS A TWOTONE"
  },
  {
    SERIAL: "04AM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 2.8 TURBO A",
    label: "PAJERO 2.8 TURBO A",
    value: "PAJERO 2.8 TURBO A"
  },
  {
    SERIAL: "04AN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 3.2 V6 A",
    label: "PAJERO 3.2 V6 A",
    value: "PAJERO 3.2 V6 A"
  },
  {
    SERIAL: "04AO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 3.5 V6 GDI A.",
    label: "PAJERO 3.5 V6 GDI A.",
    value: "PAJERO 3.5 V6 GDI A."
  },
  {
    SERIAL: "04AT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE515",
    label: "FE515",
    value: "FE515"
  },
  {
    SERIAL: "04AU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE535",
    label: "FE535",
    value: "FE535"
  },
  {
    SERIAL: "04AV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK457",
    label: "FK457",
    value: "FK457"
  },
  {
    SERIAL: "04AX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA DOUBLECAB 4WD M.2.8 (RALLY MASTER) (2003-2004)",
    label: "STRADA DOUBLECAB 4WD M.2.8 (RALLY MASTER) (2003-2004)",
    value: "STRADA DOUBLECAB 4WD M.2.8 (RALLY MASTER) (2003-2004)"
  },
  {
    SERIAL: "04AY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA DOUBLECAB 4WD A.2.8 (RALLY MASTER",
    label: "STRADA DOUBLECAB 4WD A.2.8 (RALLY MASTER",
    value: "STRADA DOUBLECAB 4WD A.2.8 (RALLY MASTER"
  },
  {
    SERIAL: "04AZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA G.WAGON M.2.8 (RALLY MASTER)",
    label: "STRADA G.WAGON M.2.8 (RALLY MASTER)",
    value: "STRADA G.WAGON M.2.8 (RALLY MASTER)"
  },
  {
    SERIAL: "04BA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA G.WAGON A.2.8 (RALLY MASTER)",
    label: "STRADA G.WAGON A.2.8 (RALLY MASTER)",
    value: "STRADA G.WAGON A.2.8 (RALLY MASTER)"
  },
  {
    SERIAL: "04BB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA G.WAGON VG TURBO 2.5",
    label: "STRADA G.WAGON VG TURBO 2.5",
    value: "STRADA G.WAGON VG TURBO 2.5"
  },
  {
    SERIAL: "04BF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FB511 (CANTER)",
    label: "FB511 (CANTER)",
    value: "FB511 (CANTER)"
  },
  {
    SERIAL: "04BG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA 1.6 GLXI M",
    label: "LANCER CEDIA 1.6 GLXI M",
    value: "LANCER CEDIA 1.6 GLXI M"
  },
  {
    SERIAL: "04BH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA 1.6 GLXI A",
    label: "LANCER CEDIA 1.6 GLXI A",
    value: "LANCER CEDIA 1.6 GLXI A"
  },
  {
    SERIAL: "04BI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER CEDIA 1.6 GLXI A AIRBAG ABS",
    label: "LANCER CEDIA 1.6 GLXI A AIRBAG ABS",
    value: "LANCER CEDIA 1.6 GLXI A AIRBAG ABS"
  },
  {
    SERIAL: "04BK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "OUT LANDER",
    label: "OUT LANDER",
    value: "OUT LANDER"
  },
  {
    SERIAL: "04BL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FB511 (GUTS สี่ล้อใหญ่)",
    label: "FB511 (GUTS สี่ล้อใหญ่)",
    value: "FB511 (GUTS สี่ล้อใหญ่)"
  },
  {
    SERIAL: "04BM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER 2.0",
    label: "LANCER 2.0",
    value: "LANCER 2.0"
  },
  {
    SERIAL: "04BN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FUSO (MMTFN528)",
    label: "FUSO (MMTFN528)",
    value: "FUSO (MMTFN528)"
  },
  {
    SERIAL: "04BO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXI M 1.6 (NEW)",
    label: "LANCER GLXI M 1.6 (NEW)",
    value: "LANCER GLXI M 1.6 (NEW)"
  },
  {
    SERIAL: "04BP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLX A 1.6 (NEW)",
    label: "LANCER GLX A 1.6 (NEW)",
    value: "LANCER GLX A 1.6 (NEW)"
  },
  {
    SERIAL: "04BQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLXI A.CVT LTD 1.6",
    label: "LANCER GLXI A.CVT LTD 1.6",
    value: "LANCER GLXI A.CVT LTD 1.6"
  },
  {
    SERIAL: "04BR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN617",
    label: "FN617",
    value: "FN617"
  },
  {
    SERIAL: "04BS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "SPACE WAGON 2.4 GLS",
    label: "SPACE WAGON 2.4 GLS",
    value: "SPACE WAGON 2.4 GLS"
  },
  {
    SERIAL: "04BV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SHOGUN 2.5",
    label: "PAJERO SHOGUN 2.5",
    value: "PAJERO SHOGUN 2.5"
  },
  {
    SERIAL: "04BW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "CYCLONE",
    label: "CYCLONE",
    value: "CYCLONE"
  },
  {
    SERIAL: "04BX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE539",
    label: "FE539",
    value: "FE539"
  },
  {
    SERIAL: "04BY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE649",
    label: "FE649",
    value: "FE649"
  },
  {
    SERIAL: "04BZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE659",
    label: "FE659",
    value: "FE659"
  },
  {
    SERIAL: "04CB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "SPACE WAGON 2.4 GT",
    label: "SPACE WAGON 2.4 GT",
    value: "SPACE WAGON 2.4 GT"
  },
  {
    SERIAL: "04CC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G.CAB EXCEED 3.2 4DR.4WD",
    label: "TRITON G.CAB EXCEED 3.2 4DR.4WD",
    value: "TRITON G.CAB EXCEED 3.2 4DR.4WD"
  },
  {
    SERIAL: "04CD0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN527",
    label: "FN527",
    value: "FN527"
  },
  {
    SERIAL: "04CE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 3.8",
    label: "PAJERO 3.8",
    value: "PAJERO 3.8"
  },
  {
    SERIAL: "04CF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER SEI 2.0 AUTO",
    label: "LANCER SEI 2.0 AUTO",
    value: "LANCER SEI 2.0 AUTO"
  },
  {
    SERIAL: "04CG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FV5138 (หัวลาก)",
    label: "FV5138 (หัวลาก)",
    value: "FV5138 (หัวลาก)"
  },
  {
    SERIAL: "04CH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE519",
    label: "FE519",
    value: "FE519"
  },
  {
    SERIAL: "04CI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN627H (หัวลาก)",
    label: "FN627H (หัวลาก)",
    value: "FN627H (หัวลาก)"
  },
  {
    SERIAL: "04CJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN515 (BUILT)",
    label: "FN515 (BUILT)",
    value: "FN515 (BUILT)"
  },
  {
    SERIAL: "04CK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FM1524 (FM65)",
    label: "FM1524 (FM65)",
    value: "FM1524 (FM65)"
  },
  {
    SERIAL: "04CM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "SPACE WAGON 2.4 GLS LTD",
    label: "SPACE WAGON 2.4 GLS LTD",
    value: "SPACE WAGON 2.4 GLS LTD"
  },
  {
    SERIAL: "04CN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT GLS 2.5 A",
    label: "PAJERO SPORT GLS 2.5 A",
    value: "PAJERO SPORT GLS 2.5 A"
  },
  {
    SERIAL: "04CO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT GT 2.5 A",
    label: "PAJERO SPORT GT 2.5 A",
    value: "PAJERO SPORT GT 2.5 A"
  },
  {
    SERIAL: "04CP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 3.2 GLS A 4WD",
    label: "PAJERO SPORT 3.2 GLS A 4WD",
    value: "PAJERO SPORT 3.2 GLS A 4WD"
  },
  {
    SERIAL: "04CQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT GT 4WD 3.2 A",
    label: "PAJERO SPORT GT 4WD 3.2 A",
    value: "PAJERO SPORT GT 4WD 3.2 A"
  },
  {
    SERIAL: "04CR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLX CNG 1.6 M",
    label: "LANCER GLX CNG 1.6 M",
    value: "LANCER GLX CNG 1.6 M"
  },
  {
    SERIAL: "04CS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER GLX CNG 1.6 A",
    label: "LANCER GLX CNG 1.6 A",
    value: "LANCER GLX CNG 1.6 A"
  },
  {
    SERIAL: "04CT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G.CAB 4WD 2.5 GLS LTD",
    label: "TRITON G.CAB 4WD 2.5 GLS LTD",
    value: "TRITON G.CAB 4WD 2.5 GLS LTD"
  },
  {
    SERIAL: "04CU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G.CAB 4WD 3.2 GLS AUTO",
    label: "TRITON G.CAB 4WD 3.2 GLS AUTO",
    value: "TRITON G.CAB 4WD 3.2 GLS AUTO"
  },
  {
    SERIAL: "04CV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G.CAB GLX 2.5 M (AS)",
    label: "TRITON G.CAB GLX 2.5 M (AS)",
    value: "TRITON G.CAB GLX 2.5 M (AS)"
  },
  {
    SERIAL: "04CW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G.CAB GLX AUTO 2.5",
    label: "TRITON G.CAB GLX AUTO 2.5",
    value: "TRITON G.CAB GLX AUTO 2.5"
  },
  {
    SERIAL: "04CX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB GLX AUTO 2.5 (AS)",
    label: "TRITON DOUBLECAB GLX AUTO 2.5 (AS)",
    value: "TRITON DOUBLECAB GLX AUTO 2.5 (AS)"
  },
  {
    SERIAL: "04CY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGACAB GLX AUTO",
    label: "TRITON MEGACAB GLX AUTO",
    value: "TRITON MEGACAB GLX AUTO"
  },
  {
    SERIAL: "04CZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB GLX (AS)",
    label: "TRITON MEGA CAB GLX (AS)",
    value: "TRITON MEGA CAB GLX (AS)"
  },
  {
    SERIAL: "04DA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGACAB GLS",
    label: "TRITON MEGACAB GLS",
    value: "TRITON MEGACAB GLS"
  },
  {
    SERIAL: "04DC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB GLS 2.5",
    label: "TRITON PLUS MEGA CAB GLS 2.5",
    value: "TRITON PLUS MEGA CAB GLS 2.5"
  },
  {
    SERIAL: "04DD0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB GLS AUTO",
    label: "TRITON PLUS MEGA CAB GLS AUTO",
    value: "TRITON PLUS MEGA CAB GLS AUTO"
  },
  {
    SERIAL: "04DE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS G.CAB GLS 2.5",
    label: "TRITON PLUS G.CAB GLS 2.5",
    value: "TRITON PLUS G.CAB GLS 2.5"
  },
  {
    SERIAL: "04DF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS G.CAB GLS 2.5 AUTO",
    label: "TRITON PLUS G.CAB GLS 2.5 AUTO",
    value: "TRITON PLUS G.CAB GLS 2.5 AUTO"
  },
  {
    SERIAL: "04DG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.4 BENZINE",
    label: "TRITON SINGLE CAB 2.4 BENZINE",
    value: "TRITON SINGLE CAB 2.4 BENZINE"
  },
  {
    SERIAL: "04DH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS G-CAB 4D 2.4 BENZINE",
    label: "TRITON PLUS G-CAB 4D 2.4 BENZINE",
    value: "TRITON PLUS G-CAB 4D 2.4 BENZINE"
  },
  {
    SERIAL: "04DI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER SEI CNG 1.6 AUTO",
    label: "LANCER SEI CNG 1.6 AUTO",
    value: "LANCER SEI CNG 1.6 AUTO"
  },
  {
    SERIAL: "04DJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER EX 1.8 GLX",
    label: "LANCER EX 1.8 GLX",
    value: "LANCER EX 1.8 GLX"
  },
  {
    SERIAL: "04DK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER EX 1.8 GLS",
    label: "LANCER EX 1.8 GLS",
    value: "LANCER EX 1.8 GLS"
  },
  {
    SERIAL: "04DL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER EX 1.8 GLS-LTD",
    label: "LANCER EX 1.8 GLS-LTD",
    value: "LANCER EX 1.8 GLS-LTD"
  },
  {
    SERIAL: "04DM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "LANCER EX 2.0 GT",
    label: "LANCER EX 2.0 GT",
    value: "LANCER EX 2.0 GT"
  },
  {
    SERIAL: "04DN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE84",
    label: "FE84",
    value: "FE84"
  },
  {
    SERIAL: "04DO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE85",
    label: "FE85",
    value: "FE85"
  },
  {
    SERIAL: "04DP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB GLS LTD 2.4D 4WD",
    label: "TRITON DOUBLECAB GLS LTD 2.4D 4WD",
    value: "TRITON DOUBLECAB GLS LTD 2.4D 4WD"
  },
  {
    SERIAL: "04DQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB GLS LTD 2.4 A 4WD",
    label: "TRITON DOUBLECAB GLS LTD 2.4 A 4WD",
    value: "TRITON DOUBLECAB GLS LTD 2.4 A 4WD"
  },
  {
    SERIAL: "04DR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB GLS 2.4D 4WD",
    label: "TRITON DOUBLECAB GLS 2.4D 4WD",
    value: "TRITON DOUBLECAB GLS 2.4D 4WD"
  },
  {
    SERIAL: "04DS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLX 2.4 M",
    label: "TRITON DOUBLECAB PLUS GLX 2.4 M",
    value: "TRITON DOUBLECAB PLUS GLX 2.4 M"
  },
  {
    SERIAL: "04DT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLS 2.4 GASOLINE",
    label: "TRITON DOUBLECAB PLUS GLS 2.4 GASOLINE",
    value: "TRITON DOUBLECAB PLUS GLS 2.4 GASOLINE"
  },
  {
    SERIAL: "04DU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLS 2.4D M",
    label: "TRITON DOUBLECAB PLUS GLS 2.4D M",
    value: "TRITON DOUBLECAB PLUS GLS 2.4D M"
  },
  {
    SERIAL: "04DV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLS 2.4D A",
    label: "TRITON DOUBLECAB PLUS GLS 2.4D A",
    value: "TRITON DOUBLECAB PLUS GLS 2.4D A"
  },
  {
    SERIAL: "04DW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLS 2.4D NAVI",
    label: "TRITON DOUBLECAB PLUS GLS 2.4D NAVI",
    value: "TRITON DOUBLECAB PLUS GLS 2.4D NAVI"
  },
  {
    SERIAL: "04DX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLS 2.4D A NAVI",
    label: "TRITON DOUBLECAB PLUS GLS 2.4D A NAVI",
    value: "TRITON DOUBLECAB PLUS GLS 2.4D A NAVI"
  },
  {
    SERIAL: "04DY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.5 GL M",
    label: "TRITON SINGLE CAB 2.5 GL M",
    value: "TRITON SINGLE CAB 2.5 GL M"
  },
  {
    SERIAL: "04DZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GLX M",
    label: "TRITON MEGA CAB 2.5 GLX M",
    value: "TRITON MEGA CAB 2.5 GLX M"
  },
  {
    SERIAL: "04EA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GLS M",
    label: "TRITON MEGA CAB 2.5 GLS M",
    value: "TRITON MEGA CAB 2.5 GLS M"
  },
  {
    SERIAL: "04EB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4D GLX M",
    label: "TRITON PLUS MEGA CAB 2.4D GLX M",
    value: "TRITON PLUS MEGA CAB 2.4D GLX M"
  },
  {
    SERIAL: "04EC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4 GLS M",
    label: "TRITON PLUS MEGA CAB 2.4 GLS M",
    value: "TRITON PLUS MEGA CAB 2.4 GLS M"
  },
  {
    SERIAL: "04ED0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4 GLS KEY GENIUS",
    label: "TRITON PLUS MEGA CAB 2.4 GLS KEY GENIUS",
    value: "TRITON PLUS MEGA CAB 2.4 GLS KEY GENIUS"
  },
  {
    SERIAL: "04EE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.5D GLS A NAVIGAT OR",
    label: "TRITON PLUS MEGA CAB 2.5D GLS A NAVIGAT OR",
    value: "TRITON PLUS MEGA CAB 2.5D GLS A NAVIGAT OR"
  },
  {
    SERIAL: "04EF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "04EG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 3.2 4WD.",
    label: "TRITON SINGLE CAB 3.2 4WD.",
    value: "TRITON SINGLE CAB 3.2 4WD."
  },
  {
    SERIAL: "04EH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAN ATHLETE M",
    label: "TRITON PLUS MEGA CAN ATHLETE M",
    value: "TRITON PLUS MEGA CAN ATHLETE M"
  },
  {
    SERIAL: "04EI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS ATHLETE M",
    label: "TRITON DOUBLECAB PLUS ATHLETE M",
    value: "TRITON DOUBLECAB PLUS ATHLETE M"
  },
  {
    SERIAL: "04EJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS ATHLETE A",
    label: "TRITON DOUBLECAB PLUS ATHLETE A",
    value: "TRITON DOUBLECAB PLUS ATHLETE A"
  },
  {
    SERIAL: "04EK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS ATHLETE 4WD A",
    label: "TRITON DOUBLECAB PLUS ATHLETE 4WD A",
    value: "TRITON DOUBLECAB PLUS ATHLETE 4WD A"
  },
  {
    SERIAL: "04EL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS 2.4 GLX",
    label: "TRITON DOUBLECAB PLUS 2.4 GLX",
    value: "TRITON DOUBLECAB PLUS 2.4 GLX"
  },
  {
    SERIAL: "04EM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.4 GL 4WD",
    label: "TRITON SINGLE CAB 2.4 GL 4WD",
    value: "TRITON SINGLE CAB 2.4 GL 4WD"
  },
  {
    SERIAL: "04EN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS 2.4 GLS",
    label: "TRITON DOUBLECAB PLUS 2.4 GLS",
    value: "TRITON DOUBLECAB PLUS 2.4 GLS"
  },
  {
    SERIAL: "04EO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GLX",
    label: "TRITON MEGA CAB 2.5 GLX",
    value: "TRITON MEGA CAB 2.5 GLX"
  },
  {
    SERIAL: "04EP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS 2.4 GLS A",
    label: "TRITON DOUBLECAB PLUS 2.4 GLS A",
    value: "TRITON DOUBLECAB PLUS 2.4 GLS A"
  },
  {
    SERIAL: "04EQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4 GLS",
    label: "TRITON PLUS MEGA CAB 2.4 GLS",
    value: "TRITON PLUS MEGA CAB 2.4 GLS"
  },
  {
    SERIAL: "04ER0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4 GT",
    label: "TRITON PLUS MEGA CAB 2.4 GT",
    value: "TRITON PLUS MEGA CAB 2.4 GT"
  },
  {
    SERIAL: "04ES0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4 GT A",
    label: "TRITON PLUS MEGA CAB 2.4 GT A",
    value: "TRITON PLUS MEGA CAB 2.4 GT A"
  },
  {
    SERIAL: "04ET0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS 2.4 GT",
    label: "TRITON DOUBLECAB PLUS 2.4 GT",
    value: "TRITON DOUBLECAB PLUS 2.4 GT"
  },
  {
    SERIAL: "04EU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS 2.4 GT A",
    label: "TRITON DOUBLECAB PLUS 2.4 GT A",
    value: "TRITON DOUBLECAB PLUS 2.4 GT A"
  },
  {
    SERIAL: "04EV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS 2.4 GT PREMIUM",
    label: "TRITON DOUBLECAB PLUS 2.4 GT PREMIUM",
    value: "TRITON DOUBLECAB PLUS 2.4 GT PREMIUM"
  },
  {
    SERIAL: "04EW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS 2.4 GT PREMIUM A",
    label: "TRITON DOUBLECAB PLUS 2.4 GT PREMIUM A",
    value: "TRITON DOUBLECAB PLUS 2.4 GT PREMIUM A"
  },
  {
    SERIAL: "04EX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB 2.4 GLS 4WD",
    label: "TRITON DOUBLECAB 2.4 GLS 4WD",
    value: "TRITON DOUBLECAB 2.4 GLS 4WD"
  },
  {
    SERIAL: "04EY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB 2.4 GT PREMIUM 4WD",
    label: "TRITON DOUBLECAB 2.4 GT PREMIUM 4WD",
    value: "TRITON DOUBLECAB 2.4 GT PREMIUM 4WD"
  },
  {
    SERIAL: "04EZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB 2.4 GT PREMIUM 4WD A",
    label: "TRITON DOUBLECAB 2.4 GT PREMIUM 4WD A",
    value: "TRITON DOUBLECAB 2.4 GT PREMIUM 4WD A"
  },
  {
    SERIAL: "04FG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN215",
    label: "FN215",
    value: "FN215"
  },
  {
    SERIAL: "04FH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN516",
    label: "FN516",
    value: "FN516"
  },
  {
    SERIAL: "04FI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "STRADA SUPER 2.8",
    label: "STRADA SUPER 2.8",
    value: "STRADA SUPER 2.8"
  },
  {
    SERIAL: "04FJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB",
    label: "TRITON SINGLE CAB",
    value: "TRITON SINGLE CAB"
  },
  {
    SERIAL: "04FK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GLX (2WD)",
    label: "TRITON MEGA CAB 2.5 GLX (2WD)",
    value: "TRITON MEGA CAB 2.5 GLX (2WD)"
  },
  {
    SERIAL: "04FL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GLS (4WD)",
    label: "TRITON MEGA CAB 2.5 GLS (4WD)",
    value: "TRITON MEGA CAB 2.5 GLS (4WD)"
  },
  {
    SERIAL: "04FM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G-CAB 2.5 4DR.GLX (2WD)",
    label: "TRITON G-CAB 2.5 4DR.GLX (2WD)",
    value: "TRITON G-CAB 2.5 4DR.GLX (2WD)"
  },
  {
    SERIAL: "04FN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G-CAB 2.5 4DR.GLS (4WD)",
    label: "TRITON G-CAB 2.5 4DR.GLS (4WD)",
    value: "TRITON G-CAB 2.5 4DR.GLS (4WD)"
  },
  {
    SERIAL: "04FO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G-CAB 3.2 4DR.GLS (4WD)",
    label: "TRITON G-CAB 3.2 4DR.GLS (4WD)",
    value: "TRITON G-CAB 3.2 4DR.GLS (4WD)"
  },
  {
    SERIAL: "04FP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G-CAB 3.2 4DR.GLS LIMITED (4WD)",
    label: "TRITON G-CAB 3.2 4DR.GLS LIMITED (4WD)",
    value: "TRITON G-CAB 3.2 4DR.GLS LIMITED (4WD)"
  },
  {
    SERIAL: "04FQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN527",
    label: "FN527",
    value: "FN527"
  },
  {
    SERIAL: "04FR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GL",
    label: "TRITON MEGA CAB 2.5 GL",
    value: "TRITON MEGA CAB 2.5 GL"
  },
  {
    SERIAL: "04FS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G-CAB 2.5 4DR.GL",
    label: "TRITON G-CAB 2.5 4DR.GL",
    value: "TRITON G-CAB 2.5 4DR.GL"
  },
  {
    SERIAL: "04FT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE449",
    label: "FE449",
    value: "FE449"
  },
  {
    SERIAL: "04FV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.5 GL SWB 4WD",
    label: "TRITON SINGLE CAB 2.5 GL SWB 4WD",
    value: "TRITON SINGLE CAB 2.5 GL SWB 4WD"
  },
  {
    SERIAL: "04FW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 3.2 DIESEL A CBU",
    label: "PAJERO 3.2 DIESEL A CBU",
    value: "PAJERO 3.2 DIESEL A CBU"
  },
  {
    SERIAL: "04GA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO 2.8 TURBO A",
    label: "PAJERO 2.8 TURBO A",
    value: "PAJERO 2.8 TURBO A"
  },
  {
    SERIAL: "04GB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE515",
    label: "FE515",
    value: "FE515"
  },
  {
    SERIAL: "04GC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "L300",
    label: "L300",
    value: "L300"
  },
  {
    SERIAL: "04GD0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE71B",
    label: "FE71B",
    value: "FE71B"
  },
  {
    SERIAL: "04GE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN61FR",
    label: "FN61FR",
    value: "FN61FR"
  },
  {
    SERIAL: "04GF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "XLRDE33KT (BUS)",
    label: "XLRDE33KT (BUS)",
    value: "XLRDE33KT (BUS)"
  },
  {
    SERIAL: "04GG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.5 GL",
    label: "TRITON SINGLE CAB 2.5 GL",
    value: "TRITON SINGLE CAB 2.5 GL"
  },
  {
    SERIAL: "04GH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.4 GLX",
    label: "TRITON MEGA CAB 2.4 GLX",
    value: "TRITON MEGA CAB 2.4 GLX"
  },
  {
    SERIAL: "04GI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN62",
    label: "FN62",
    value: "FN62"
  },
  {
    SERIAL: "04GJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN516 (BUILT)",
    label: "FN516 (BUILT)",
    value: "FN516 (BUILT)"
  },
  {
    SERIAL: "04GK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.4 CNG",
    label: "TRITON SINGLE CAB 2.4 CNG",
    value: "TRITON SINGLE CAB 2.4 CNG"
  },
  {
    SERIAL: "04GL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.4 CNG",
    label: "TRITON MEGA CAB 2.4 CNG",
    value: "TRITON MEGA CAB 2.4 CNG"
  },
  {
    SERIAL: "04GM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G-CAB 2.4 GLS",
    label: "TRITON G-CAB 2.4 GLS",
    value: "TRITON G-CAB 2.4 GLS"
  },
  {
    SERIAL: "04GN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE73CB (BUILT)",
    label: "FE73CB (BUILT)",
    value: "FE73CB (BUILT)"
  },
  {
    SERIAL: "04GO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FB523",
    label: "FB523",
    value: "FB523"
  },
  {
    SERIAL: "04GP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK644",
    label: "FK644",
    value: "FK644"
  },
  {
    SERIAL: "04GQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN517 (BUILT)",
    label: "FN517 (BUILT)",
    value: "FN517 (BUILT)"
  },
  {
    SERIAL: "04GR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.4i GL",
    label: "TRITON SINGLE CAB 2.4i GL",
    value: "TRITON SINGLE CAB 2.4i GL"
  },
  {
    SERIAL: "04GS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GL",
    label: "MIRAGE GL",
    value: "MIRAGE GL"
  },
  {
    SERIAL: "04GT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLX (A)",
    label: "MIRAGE GLX (A)",
    value: "MIRAGE GLX (A)"
  },
  {
    SERIAL: "04GU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLX (M)",
    label: "MIRAGE GLX (M)",
    value: "MIRAGE GLX (M)"
  },
  {
    SERIAL: "04GV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLS (A)",
    label: "MIRAGE GLS (A)",
    value: "MIRAGE GLS (A)"
  },
  {
    SERIAL: "04GW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLS (A) LIMITED",
    label: "MIRAGE GLS (A) LIMITED",
    value: "MIRAGE GLS (A) LIMITED"
  },
  {
    SERIAL: "04GX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.5D GL 4WD",
    label: "TRITON SINGLE CAB 2.5D GL 4WD",
    value: "TRITON SINGLE CAB 2.5D GL 4WD"
  },
  {
    SERIAL: "04GY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GL",
    label: "TRITON MEGA CAB 2.5 GL",
    value: "TRITON MEGA CAB 2.5 GL"
  },
  {
    SERIAL: "04GZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRUCK 12000",
    label: "TRUCK 12000",
    value: "TRUCK 12000"
  },
  {
    SERIAL: "04HA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FT50",
    label: "FT50",
    value: "FT50"
  },
  {
    SERIAL: "04HB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN517 (BUILT)",
    label: "FN517 (BUILT)",
    value: "FN517 (BUILT)"
  },
  {
    SERIAL: "04HC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT GT 3.0",
    label: "PAJERO SPORT GT 3.0",
    value: "PAJERO SPORT GT 3.0"
  },
  {
    SERIAL: "04HD0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT BENZIN 2.4",
    label: "PAJERO SPORT BENZIN 2.4",
    value: "PAJERO SPORT BENZIN 2.4"
  },
  {
    SERIAL: "04HE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT GT 2.5 4WD",
    label: "PAJERO SPORT GT 2.5 4WD",
    value: "PAJERO SPORT GT 2.5 4WD"
  },
  {
    SERIAL: "04HF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.5 4WD",
    label: "TRITON SINGLE CAB 2.5 4WD",
    value: "TRITON SINGLE CAB 2.5 4WD"
  },
  {
    SERIAL: "04HG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN527 (BUILT)",
    label: "FN527 (BUILT)",
    value: "FN527 (BUILT)"
  },
  {
    SERIAL: "04HH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "BUS (BUILT)",
    label: "BUS (BUILT)",
    value: "BUS (BUILT)"
  },
  {
    SERIAL: "04HI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ATTRAGE 1.2 GLX M",
    label: "ATTRAGE 1.2 GLX M",
    value: "ATTRAGE 1.2 GLX M"
  },
  {
    SERIAL: "04HJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "ATTRAGE 1.2 GLS A LIMITED",
    label: "ATTRAGE 1.2 GLS A LIMITED",
    value: "ATTRAGE 1.2 GLS A LIMITED"
  },
  {
    SERIAL: "04HK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN527 (RENOVATE)",
    label: "FN527 (RENOVATE)",
    value: "FN527 (RENOVATE)"
  },
  {
    SERIAL: "04HL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK455 (UNIQUE)",
    label: "FK455 (UNIQUE)",
    value: "FK455 (UNIQUE)"
  },
  {
    SERIAL: "04HM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK455 (UNIQUE)",
    label: "FK455 (UNIQUE)",
    value: "FK455 (UNIQUE)"
  },
  {
    SERIAL: "04HN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK455 (UNIQUE)",
    label: "FK455 (UNIQUE)",
    value: "FK455 (UNIQUE)"
  },
  {
    SERIAL: "04HO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK458 (UNIQUE)",
    label: "FK458 (UNIQUE)",
    value: "FK458 (UNIQUE)"
  },
  {
    SERIAL: "04HP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FM65",
    label: "FM65",
    value: "FM65"
  },
  {
    SERIAL: "04HQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON G.CAB 2.4 GLX CNG",
    label: "TRITON G.CAB 2.4 GLX CNG",
    value: "TRITON G.CAB 2.4 GLX CNG"
  },
  {
    SERIAL: "04HR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS G.CAB GLS 2.5 CNG",
    label: "TRITON PLUS G.CAB GLS 2.5 CNG",
    value: "TRITON PLUS G.CAB GLS 2.5 CNG"
  },
  {
    SERIAL: "04HS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FM61",
    label: "FM61",
    value: "FM61"
  },
  {
    SERIAL: "04HT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB GLX 2.5",
    label: "TRITON PLUS MEGA CAB GLX 2.5",
    value: "TRITON PLUS MEGA CAB GLX 2.5"
  },
  {
    SERIAL: "04HU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FUSO FV572",
    label: "FUSO FV572",
    value: "FUSO FV572"
  },
  {
    SERIAL: "04HV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FV51..H1 (หัวลาก)",
    label: "FV51..H1 (หัวลาก)",
    value: "FV51..H1 (หัวลาก)"
  },
  {
    SERIAL: "04HW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FV51..H0 (หัวลาก) CNG",
    label: "FV51..H0 (หัวลาก) CNG",
    value: "FV51..H0 (หัวลาก) CNG"
  },
  {
    SERIAL: "04HX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FV51..H1 (กะบะ)",
    label: "FV51..H1 (กะบะ)",
    value: "FV51..H1 (กะบะ)"
  },
  {
    SERIAL: "04HY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "DELICA 2.0",
    label: "DELICA 2.0",
    value: "DELICA 2.0"
  },
  {
    SERIAL: "04HZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE73B",
    label: "FE73B",
    value: "FE73B"
  },
  {
    SERIAL: "04IA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN61FM",
    label: "FN61FM",
    value: "FN61FM"
  },
  {
    SERIAL: "04IB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GLS LTD 2WD",
    label: "PAJERO SPORT 2.4 GLS LTD 2WD",
    value: "PAJERO SPORT 2.4 GLS LTD 2WD"
  },
  {
    SERIAL: "04IC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GT LTD 2WD",
    label: "PAJERO SPORT 2.4 GT LTD 2WD",
    value: "PAJERO SPORT 2.4 GT LTD 2WD"
  },
  {
    SERIAL: "04ID0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GT PREMIUM 4WD",
    label: "PAJERO SPORT 2.4 GT PREMIUM 4WD",
    value: "PAJERO SPORT 2.4 GT PREMIUM 4WD"
  },
  {
    SERIAL: "04IE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN517 (BUILT)",
    label: "FN517 (BUILT)",
    value: "FN517 (BUILT)"
  },
  {
    SERIAL: "04IF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN215 (BUS) BUILT",
    label: "FN215 (BUS) BUILT",
    value: "FN215 (BUS) BUILT"
  },
  {
    SERIAL: "04IG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "DELICA SPACE WAGON 2.0",
    label: "DELICA SPACE WAGON 2.0",
    value: "DELICA SPACE WAGON 2.0"
  },
  {
    SERIAL: "04IH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GL 1.2 M",
    label: "MIRAGE GL 1.2 M",
    value: "MIRAGE GL 1.2 M"
  },
  {
    SERIAL: "04II0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLX 1.2 M",
    label: "MIRAGE GLX 1.2 M",
    value: "MIRAGE GLX 1.2 M"
  },
  {
    SERIAL: "04IJ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLX 1.2 A",
    label: "MIRAGE GLX 1.2 A",
    value: "MIRAGE GLX 1.2 A"
  },
  {
    SERIAL: "04IK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLS 1.2 A",
    label: "MIRAGE GLS 1.2 A",
    value: "MIRAGE GLS 1.2 A"
  },
  {
    SERIAL: "04IL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "MIRAGE GLS LTD 1.2 A",
    label: "MIRAGE GLS LTD 1.2 A",
    value: "MIRAGE GLS LTD 1.2 A"
  },
  {
    SERIAL: "04IM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB LIMITED EDITION 2.4 M",
    label: "TRITON DOUBLECAB LIMITED EDITION 2.4 M",
    value: "TRITON DOUBLECAB LIMITED EDITION 2.4 M"
  },
  {
    SERIAL: "04IN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB LIMITED EDITION 2.4 A",
    label: "TRITON DOUBLECAB LIMITED EDITION 2.4 A",
    value: "TRITON DOUBLECAB LIMITED EDITION 2.4 A"
  },
  {
    SERIAL: "04IO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FUSO FJX3WK (MIXER)",
    label: "FUSO FJX3WK (MIXER)",
    value: "FUSO FJX3WK (MIXER)"
  },
  {
    SERIAL: "04IQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4 GLS LTD M",
    label: "TRITON PLUS MEGA CAB 2.4 GLS LTD M",
    value: "TRITON PLUS MEGA CAB 2.4 GLS LTD M"
  },
  {
    SERIAL: "04IR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4 GLS LTD A",
    label: "TRITON PLUS MEGA CAB 2.4 GLS LTD A",
    value: "TRITON PLUS MEGA CAB 2.4 GLS LTD A"
  },
  {
    SERIAL: "04IS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FK116",
    label: "FK116",
    value: "FK116"
  },
  {
    SERIAL: "04IT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON",
    label: "TRITON",
    value: "TRITON"
  },
  {
    SERIAL: "04IU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.4 GLX",
    label: "TRITON MEGA CAB 2.4 GLX",
    value: "TRITON MEGA CAB 2.4 GLX"
  },
  {
    SERIAL: "04IV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLS LTD 2.4 M",
    label: "TRITON DOUBLECAB PLUS GLS LTD 2.4 M",
    value: "TRITON DOUBLECAB PLUS GLS LTD 2.4 M"
  },
  {
    SERIAL: "04IW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS GLS LTD 2.4 A",
    label: "TRITON DOUBLECAB PLUS GLS LTD 2.4 A",
    value: "TRITON DOUBLECAB PLUS GLS LTD 2.4 A"
  },
  {
    SERIAL: "04IX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB GLS LTD NAVI 4WD 2.4 M",
    label: "TRITON DOUBLECAB GLS LTD NAVI 4WD 2.4 M",
    value: "TRITON DOUBLECAB GLS LTD NAVI 4WD 2.4 M"
  },
  {
    SERIAL: "04IY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB GLS LTD NAVI 4WD 2.4 A",
    label: "TRITON DOUBLECAB GLS LTD NAVI 4WD 2.4 A",
    value: "TRITON DOUBLECAB GLS LTD NAVI 4WD 2.4 A"
  },
  {
    SERIAL: "04IZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB 2.5 GLX",
    label: "TRITON DOUBLECAB 2.5 GLX",
    value: "TRITON DOUBLECAB 2.5 GLX"
  },
  {
    SERIAL: "04JA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GT PREMIUM 2WD",
    label: "PAJERO SPORT 2.4 GT PREMIUM 2WD",
    value: "PAJERO SPORT 2.4 GT PREMIUM 2WD"
  },
  {
    SERIAL: "04JB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FN515 (BUILT)",
    label: "FN515 (BUILT)",
    value: "FN515 (BUILT)"
  },
  {
    SERIAL: "04JC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "XPANDER 1.5 GLS LTD",
    label: "XPANDER 1.5 GLS LTD",
    value: "XPANDER 1.5 GLS LTD"
  },
  {
    SERIAL: "04JD0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "XPANDER 1.5 GT",
    label: "XPANDER 1.5 GT",
    value: "XPANDER 1.5 GT"
  },
  {
    SERIAL: "04JE0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GT 2WD",
    label: "PAJERO SPORT 2.4 GT 2WD",
    value: "PAJERO SPORT 2.4 GT 2WD"
  },
  {
    SERIAL: "04JF0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.5D GL",
    label: "TRITON SINGLE CAB 2.5D GL",
    value: "TRITON SINGLE CAB 2.5D GL"
  },
  {
    SERIAL: "04JG0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5D GL",
    label: "TRITON MEGA CAB 2.5D GL",
    value: "TRITON MEGA CAB 2.5D GL"
  },
  {
    SERIAL: "04JH0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON PLUS MEGA CAB 2.4D GLX",
    label: "TRITON PLUS MEGA CAB 2.4D GLX",
    value: "TRITON PLUS MEGA CAB 2.4D GLX"
  },
  {
    SERIAL: "04JI0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB GLX 2.5D",
    label: "TRITON DOUBLECAB GLX 2.5D",
    value: "TRITON DOUBLECAB GLX 2.5D"
  },
  {
    SERIAL: "04JK0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRUCK OVER 12000",
    label: "TRUCK OVER 12000",
    value: "TRUCK OVER 12000"
  },
  {
    SERIAL: "04JL0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "04JM0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON SINGLE CAB 2.4 GL 4WD A",
    label: "TRITON SINGLE CAB 2.4 GL 4WD A",
    value: "TRITON SINGLE CAB 2.4 GL 4WD A"
  },
  {
    SERIAL: "04JN0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS ATHLETE 2.4 A",
    label: "TRITON DOUBLECAB PLUS ATHLETE 2.4 A",
    value: "TRITON DOUBLECAB PLUS ATHLETE 2.4 A"
  },
  {
    SERIAL: "04JO0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON DOUBLECAB PLUS ATHLETE 2.4 4WD A",
    label: "TRITON DOUBLECAB PLUS ATHLETE 2.4 4WD A",
    value: "TRITON DOUBLECAB PLUS ATHLETE 2.4 4WD A"
  },
  {
    SERIAL: "04JP0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "OVER 2000 CC",
    label: "OVER 2000 CC",
    value: "OVER 2000 CC"
  },
  {
    SERIAL: "04JQ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "XPANDER CROSS 1.5",
    label: "XPANDER CROSS 1.5",
    value: "XPANDER CROSS 1.5"
  },
  {
    SERIAL: "04JR0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GT PREMIUM ELITE",
    label: "PAJERO SPORT 2.4 GT PREMIUM ELITE",
    value: "PAJERO SPORT 2.4 GT PREMIUM ELITE"
  },
  {
    SERIAL: "04JS0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GT PREMIUM ELITE 4WD",
    label: "PAJERO SPORT 2.4 GT PREMIUM ELITE 4WD",
    value: "PAJERO SPORT 2.4 GT PREMIUM ELITE 4WD"
  },
  {
    SERIAL: "04JT0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "PAJERO SPORT 2.4 GT PLUS",
    label: "PAJERO SPORT 2.4 GT PLUS",
    value: "PAJERO SPORT 2.4 GT PLUS"
  },
  {
    SERIAL: "04JU0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "OUT LANDER 2.4 PHEV GT",
    label: "OUT LANDER 2.4 PHEV GT",
    value: "OUT LANDER 2.4 PHEV GT"
  },
  {
    SERIAL: "04JV0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "OUT LANDER 2.4 PHEV GT PREMIUM",
    label: "OUT LANDER 2.4 PHEV GT PREMIUM",
    value: "OUT LANDER 2.4 PHEV GT PREMIUM"
  },
  {
    SERIAL: "04JW0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE84",
    label: "FE84",
    value: "FE84"
  },
  {
    SERIAL: "04JX0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FE85",
    label: "FE85",
    value: "FE85"
  },
  {
    SERIAL: "04JY0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FIV1P",
    label: "FIV1P",
    value: "FIV1P"
  },
  {
    SERIAL: "04JZ0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FIV2P",
    label: "FIV2P",
    value: "FIV2P"
  },
  {
    SERIAL: "04KA0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FJY1W",
    label: "FJY1W",
    value: "FJY1W"
  },
  {
    SERIAL: "04KB0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FJX3W",
    label: "FJX3W",
    value: "FJX3W"
  },
  {
    SERIAL: "04KC0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "FZY3W",
    label: "FZY3W",
    value: "FZY3W"
  },
  {
    SERIAL: "04KD0000026",
    MAKE_NAME: "MITSUBISHI",
    MODEL_NAME: "TRITON MEGA CAB 2.5 GLX LTD",
    label: "TRITON MEGA CAB 2.5 GLX LTD",
    value: "TRITON MEGA CAB 2.5 GLX LTD"
  },
  {
    SERIAL: "5000000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ISUZU",
    label: "ISUZU",
    value: "ISUZU"
  },
  {
    SERIAL: "5010000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPARK EX",
    label: "SPARK EX",
    value: "SPARK EX"
  },
  {
    SERIAL: "5030000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SL M",
    label: "SPACECAB SL M",
    value: "SPACECAB SL M"
  },
  {
    SERIAL: "5040000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SL A",
    label: "SPACECAB SL A",
    value: "SPACECAB SL A"
  },
  {
    SERIAL: "5050000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX M",
    label: "SPACECAB SLX M",
    value: "SPACECAB SLX M"
  },
  {
    SERIAL: "5060000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX A",
    label: "SPACECAB SLX A",
    value: "SPACECAB SLX A"
  },
  {
    SERIAL: "5070000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "DOUBLE CAB",
    label: "DOUBLE CAB",
    value: "DOUBLE CAB"
  },
  {
    SERIAL: "5080000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE 4WD",
    label: "ADVENTURE 4WD",
    value: "ADVENTURE 4WD"
  },
  {
    SERIAL: "5090000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE 4WD",
    label: "ADVENTURE 4WD",
    value: "ADVENTURE 4WD"
  },
  {
    SERIAL: "5100000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "WANDERER",
    label: "WANDERER",
    value: "WANDERER"
  },
  {
    SERIAL: "5110000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "STATION WAGON",
    label: "STATION WAGON",
    value: "STATION WAGON"
  },
  {
    SERIAL: "5120000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "RODEO 4WD",
    label: "RODEO 4WD",
    value: "RODEO 4WD"
  },
  {
    SERIAL: "5130000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "DOUBLECAB",
    label: "DOUBLECAB",
    value: "DOUBLECAB"
  },
  {
    SERIAL: "5140000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CAMEO",
    label: "CAMEO",
    value: "CAMEO"
  },
  {
    SERIAL: "5150000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TROOPER LS(M)",
    label: "TROOPER LS(M)",
    value: "TROOPER LS(M)"
  },
  {
    SERIAL: "5160000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TROOPER (A)",
    label: "TROOPER (A)",
    value: "TROOPER (A)"
  },
  {
    SERIAL: "5170000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MUE",
    label: "MUE",
    value: "MUE"
  },
  {
    SERIAL: "5190000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GEMINE",
    label: "GEMINE",
    value: "GEMINE"
  },
  {
    SERIAL: "5200000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "BUDDY",
    label: "BUDDY",
    value: "BUDDY"
  },
  {
    SERIAL: "5220000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VERTEX JL 1.6",
    label: "VERTEX JL 1.6",
    value: "VERTEX JL 1.6"
  },
  {
    SERIAL: "5230000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VERTEX JE 1.6 A",
    label: "VERTEX JE 1.6 A",
    value: "VERTEX JE 1.6 A"
  },
  {
    SERIAL: "5250000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VERTEX SE VTEC 1.6 A",
    label: "VERTEX SE VTEC 1.6 A",
    value: "VERTEX SE VTEC 1.6 A"
  },
  {
    SERIAL: "5260000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VERTEX SL ABS VTEC 1.6",
    label: "VERTEX SL ABS VTEC 1.6",
    value: "VERTEX SL ABS VTEC 1.6"
  },
  {
    SERIAL: "5270000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VERTEX SE ABS VTEC 1.6",
    label: "VERTEX SE ABS VTEC 1.6",
    value: "VERTEX SE ABS VTEC 1.6"
  },
  {
    SERIAL: "5300000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR59",
    label: "NPR59",
    value: "NPR59"
  },
  {
    SERIAL: "5310000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VICTOR",
    label: "VICTOR",
    value: "VICTOR"
  },
  {
    SERIAL: "5340000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TROOPER SPORT",
    label: "TROOPER SPORT",
    value: "TROOPER SPORT"
  },
  {
    SERIAL: "5350000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "RODEO 4WD 2.5",
    label: "RODEO 4WD 2.5",
    value: "RODEO 4WD 2.5"
  },
  {
    SERIAL: "5360000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR55",
    label: "NKR55",
    value: "NKR55"
  },
  {
    SERIAL: "5370000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR66L",
    label: "NKR66L",
    value: "NKR66L"
  },
  {
    SERIAL: "5380000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SL 2.5 TURBO",
    label: "SPACECAB SL 2.5 TURBO",
    value: "SPACECAB SL 2.5 TURBO"
  },
  {
    SERIAL: "5390000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPARK EX 2.5 TURBO",
    label: "SPARK EX 2.5 TURBO",
    value: "SPARK EX 2.5 TURBO"
  },
  {
    SERIAL: "5400000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SX 2.5 TURBO",
    label: "SPACECAB SX 2.5 TURBO",
    value: "SPACECAB SX 2.5 TURBO"
  },
  {
    SERIAL: "5410000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 2.5",
    label: "SPACECAB SLX 2.5",
    value: "SPACECAB SLX 2.5"
  },
  {
    SERIAL: "5430000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 2.8 M.TURBO",
    label: "SPACECAB SLX 2.8 M.TURBO",
    value: "SPACECAB SLX 2.8 M.TURBO"
  },
  {
    SERIAL: "5440000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 2.8 A.TURBO",
    label: "SPACECAB SLX 2.8 A.TURBO",
    value: "SPACECAB SLX 2.8 A.TURBO"
  },
  {
    SERIAL: "5460000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VEGA 4WD 2.8 M",
    label: "VEGA 4WD 2.8 M",
    value: "VEGA 4WD 2.8 M"
  },
  {
    SERIAL: "5470000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR33",
    label: "FTR33",
    value: "FTR33"
  },
  {
    SERIAL: "5480000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR66",
    label: "NPR66",
    value: "NPR66"
  },
  {
    SERIAL: "5490000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TRIPPER TRIO (M) 3.0",
    label: "TRIPPER TRIO (M) 3.0",
    value: "TRIPPER TRIO (M) 3.0"
  },
  {
    SERIAL: "5500000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR32",
    label: "FTR32",
    value: "FTR32"
  },
  {
    SERIAL: "5510000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FSR32",
    label: "FSR32",
    value: "FSR32"
  },
  {
    SERIAL: "5520000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FSR11",
    label: "FSR11",
    value: "FSR11"
  },
  {
    SERIAL: "5530000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR11",
    label: "FTR11",
    value: "FTR11"
  },
  {
    SERIAL: "5540000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12",
    label: "FVM12",
    value: "FVM12"
  },
  {
    SERIAL: "5550000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM32",
    label: "FVM32",
    value: "FVM32"
  },
  {
    SERIAL: "5560000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVZ12",
    label: "FVZ12",
    value: "FVZ12"
  },
  {
    SERIAL: "5570000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ13",
    label: "FXZ13",
    value: "FXZ13"
  },
  {
    SERIAL: "5580000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ23",
    label: "FXZ23",
    value: "FXZ23"
  },
  {
    SERIAL: "5590000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FASTER",
    label: "FASTER",
    value: "FASTER"
  },
  {
    SERIAL: "5600000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 2.8 TURBO DRAGOM EYES",
    label: "SPACECAB SLX 2.8 TURBO DRAGOM EYES",
    value: "SPACECAB SLX 2.8 TURBO DRAGOM EYES"
  },
  {
    SERIAL: "5620000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 2WD M.",
    label: "D-MAX CAB4 2WD M.",
    value: "D-MAX CAB4 2WD M."
  },
  {
    SERIAL: "5630000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 2WD A",
    label: "D-MAX CAB4 2WD A",
    value: "D-MAX CAB4 2WD A"
  },
  {
    SERIAL: "5640000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD",
    label: "D-MAX CAB4 4WD",
    value: "D-MAX CAB4 4WD"
  },
  {
    SERIAL: "5670000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR90",
    label: "FRR90",
    value: "FRR90"
  },
  {
    SERIAL: "5680000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GVR34 (หัวลาก)",
    label: "GVR34 (หัวลาก)",
    value: "GVR34 (หัวลาก)"
  },
  {
    SERIAL: "5690000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GXZ77 (หัวลาก)",
    label: "GXZ77 (หัวลาก)",
    value: "GXZ77 (หัวลาก)"
  },
  {
    SERIAL: "5700000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ77",
    label: "FXZ77",
    value: "FXZ77"
  },
  {
    SERIAL: "5710000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM34Q",
    label: "FVM34Q",
    value: "FVM34Q"
  },
  {
    SERIAL: "5730000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12ตัน (BUILT)",
    label: "12ตัน (BUILT)",
    value: "12ตัน (BUILT)"
  },
  {
    SERIAL: "5740000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NMR130",
    label: "NMR130",
    value: "NMR130"
  },
  {
    SERIAL: "5760000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ23 BUILT 4เพลา",
    label: "FXZ23 BUILT 4เพลา",
    value: "FXZ23 BUILT 4เพลา"
  },
  {
    SERIAL: "5770000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FR1KZD",
    label: "FR1KZD",
    value: "FR1KZD"
  },
  {
    SERIAL: "5780000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FSR11 (BUILT)",
    label: "FSR11 (BUILT)",
    value: "FSR11 (BUILT)"
  },
  {
    SERIAL: "5790000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR72",
    label: "NPR72",
    value: "NPR72"
  },
  {
    SERIAL: "5870000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG50 (BUILT)",
    label: "CXG50 (BUILT)",
    value: "CXG50 (BUILT)"
  },
  {
    SERIAL: "5880000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "AKR81E (BUILT)",
    label: "AKR81E (BUILT)",
    value: "AKR81E (BUILT)"
  },
  {
    SERIAL: "5900000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR13 (BUILT)",
    label: "FRR13 (BUILT)",
    value: "FRR13 (BUILT)"
  },
  {
    SERIAL: "5910000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR81E (BUILT)",
    label: "NKR81E (BUILT)",
    value: "NKR81E (BUILT)"
  },
  {
    SERIAL: "5930000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR22 (BUILT)",
    label: "FTR22 (BUILT)",
    value: "FTR22 (BUILT)"
  },
  {
    SERIAL: "5940000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "BKR66E",
    label: "BKR66E",
    value: "BKR66E"
  },
  {
    SERIAL: "5950000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHS69 (BUILT)",
    label: "NHS69 (BUILT)",
    value: "NHS69 (BUILT)"
  },
  {
    SERIAL: "5960000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG23 (BUILT)",
    label: "CXG23 (BUILT)",
    value: "CXG23 (BUILT)"
  },
  {
    SERIAL: "5970000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG23 (BUILT)",
    label: "CXG23 (BUILT)",
    value: "CXG23 (BUILT)"
  },
  {
    SERIAL: "5980000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG23 (BUILT)",
    label: "CXG23 (BUILT)",
    value: "CXG23 (BUILT)"
  },
  {
    SERIAL: "05AA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "4 ตัน",
    label: "4 ตัน",
    value: "4 ตัน"
  },
  {
    SERIAL: "05AB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "6 ตัน",
    label: "6 ตัน",
    value: "6 ตัน"
  },
  {
    SERIAL: "05AC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "8 ตัน",
    label: "8 ตัน",
    value: "8 ตัน"
  },
  {
    SERIAL: "05AD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12 ตัน",
    label: "12 ตัน",
    value: "12 ตัน"
  },
  {
    SERIAL: "05AE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน 12 ตัน",
    label: "เกิน 12 ตัน",
    value: "เกิน 12 ตัน"
  },
  {
    SERIAL: "05AF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE TRIO 4WD(A) 2.8",
    label: "ADVENTURE TRIO 4WD(A) 2.8",
    value: "ADVENTURE TRIO 4WD(A) 2.8"
  },
  {
    SERIAL: "05AG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GRAND ADVENTURE(M) 2.8",
    label: "GRAND ADVENTURE(M) 2.8",
    value: "GRAND ADVENTURE(M) 2.8"
  },
  {
    SERIAL: "05AH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GRAND ADVENTURE(A) 2.8",
    label: "GRAND ADVENTURE(A) 2.8",
    value: "GRAND ADVENTURE(A) 2.8"
  },
  {
    SERIAL: "05AI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GRAND ADVENTURE 2.8 (M) 4WD",
    label: "GRAND ADVENTURE 2.8 (M) 4WD",
    value: "GRAND ADVENTURE 2.8 (M) 4WD"
  },
  {
    SERIAL: "05AJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE TRIO 2WD (M) 2.8",
    label: "ADVENTURE TRIO 2WD (M) 2.8",
    value: "ADVENTURE TRIO 2WD (M) 2.8"
  },
  {
    SERIAL: "05AK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE TRIO 4WD (M) 2.8",
    label: "ADVENTURE TRIO 4WD (M) 2.8",
    value: "ADVENTURE TRIO 4WD (M) 2.8"
  },
  {
    SERIAL: "05AM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SUPREME 2WD",
    label: "SUPREME 2WD",
    value: "SUPREME 2WD"
  },
  {
    SERIAL: "05AN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 2.5 TURBO NEW DRAGON EYES",
    label: "SPACECAB SLX 2.5 TURBO NEW DRAGON EYES",
    value: "SPACECAB SLX 2.5 TURBO NEW DRAGON EYES"
  },
  {
    SERIAL: "05AO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPARK EX 2.5 DRAGON EYES",
    label: "SPARK EX 2.5 DRAGON EYES",
    value: "SPARK EX 2.5 DRAGON EYES"
  },
  {
    SERIAL: "05AP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE MASTER4*2 M.",
    label: "ADVENTURE MASTER4*2 M.",
    value: "ADVENTURE MASTER4*2 M."
  },
  {
    SERIAL: "05AQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE MASTER4*2 A",
    label: "ADVENTURE MASTER4*2 A",
    value: "ADVENTURE MASTER4*2 A"
  },
  {
    SERIAL: "05AR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SX 2.5 TURBO NEW DRAGON EYES",
    label: "SPACECAB SX 2.5 TURBO NEW DRAGON EYES",
    value: "SPACECAB SX 2.5 TURBO NEW DRAGON EYES"
  },
  {
    SERIAL: "05AS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPARK EX 2.5 NEW DRAGON EYES",
    label: "SPARK EX 2.5 NEW DRAGON EYES",
    value: "SPARK EX 2.5 NEW DRAGON EYES"
  },
  {
    SERIAL: "05AT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VEGA 4WD 2.8 A",
    label: "VEGA 4WD 2.8 A",
    value: "VEGA 4WD 2.8 A"
  },
  {
    SERIAL: "05AU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "RODEO DRAGON EYES (S) 2.8",
    label: "RODEO DRAGON EYES (S) 2.8",
    value: "RODEO DRAGON EYES (S) 2.8"
  },
  {
    SERIAL: "05AV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR32",
    label: "FRR32",
    value: "FRR32"
  },
  {
    SERIAL: "05AW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "RODEO DRAGON EYES (LS) TURBO 2.8",
    label: "RODEO DRAGON EYES (LS) TURBO 2.8",
    value: "RODEO DRAGON EYES (LS) TURBO 2.8"
  },
  {
    SERIAL: "05AX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "RODEO DRAGON EYES 3.0 TURBO (S)",
    label: "RODEO DRAGON EYES 3.0 TURBO (S)",
    value: "RODEO DRAGON EYES 3.0 TURBO (S)"
  },
  {
    SERIAL: "05AY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "RODEO DRAGON EYES 3.0 TURBO (LS)",
    label: "RODEO DRAGON EYES 3.0 TURBO (LS)",
    value: "RODEO DRAGON EYES 3.0 TURBO (LS)"
  },
  {
    SERIAL: "05AZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TRIPPER SEL (M)",
    label: "TRIPPER SEL (M)",
    value: "TRIPPER SEL (M)"
  },
  {
    SERIAL: "05BA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TRIPPER SEL (A)",
    label: "TRIPPER SEL (A)",
    value: "TRIPPER SEL (A)"
  },
  {
    SERIAL: "05BB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VERTEX 1.6 JE ABS A/B",
    label: "VERTEX 1.6 JE ABS A/B",
    value: "VERTEX 1.6 JE ABS A/B"
  },
  {
    SERIAL: "05BF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GRAND ADVENTURE 3.0 (M) 2WD",
    label: "GRAND ADVENTURE 3.0 (M) 2WD",
    value: "GRAND ADVENTURE 3.0 (M) 2WD"
  },
  {
    SERIAL: "05BG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GRAND ADVENTURE 3.0 (A) 2WD",
    label: "GRAND ADVENTURE 3.0 (A) 2WD",
    value: "GRAND ADVENTURE 3.0 (A) 2WD"
  },
  {
    SERIAL: "05BH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GRAND ADVENTURE 3.0 (M) 4WD",
    label: "GRAND ADVENTURE 3.0 (M) 4WD",
    value: "GRAND ADVENTURE 3.0 (M) 4WD"
  },
  {
    SERIAL: "05BJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TRIPPER TRIO (A)",
    label: "TRIPPER TRIO (A)",
    value: "TRIPPER TRIO (A)"
  },
  {
    SERIAL: "05BL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "WANDERER (A)",
    label: "WANDERER (A)",
    value: "WANDERER (A)"
  },
  {
    SERIAL: "05BN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GXZ23KZ หัวลาก",
    label: "GXZ23KZ หัวลาก",
    value: "GXZ23KZ หัวลาก"
  },
  {
    SERIAL: "05BO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 3.0 TURBO M.",
    label: "SPACECAB SLX 3.0 TURBO M.",
    value: "SPACECAB SLX 3.0 TURBO M."
  },
  {
    SERIAL: "05BP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 3.0 TURBO A.",
    label: "SPACECAB SLX 3.0 TURBO A.",
    value: "SPACECAB SLX 3.0 TURBO A."
  },
  {
    SERIAL: "05BQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "RODEO 3.0 TURBO",
    label: "RODEO 3.0 TURBO",
    value: "RODEO 3.0 TURBO"
  },
  {
    SERIAL: "05BR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR55 (BUILT)",
    label: "NHR55 (BUILT)",
    value: "NHR55 (BUILT)"
  },
  {
    SERIAL: "05BS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR71LY52",
    label: "NPR71LY52",
    value: "NPR71LY52"
  },
  {
    SERIAL: "05BT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR71PY52",
    label: "NPR71PY52",
    value: "NPR71PY52"
  },
  {
    SERIAL: "05BU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NQR70RY52",
    label: "NQR70RY52",
    value: "NQR70RY52"
  },
  {
    SERIAL: "05BV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NQR70LY52",
    label: "NQR70LY52",
    value: "NQR70LY52"
  },
  {
    SERIAL: "05BW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB SLX 4WD 3.0",
    label: "SPACECAB SLX 4WD 3.0",
    value: "SPACECAB SLX 4WD 3.0"
  },
  {
    SERIAL: "05BX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM33",
    label: "FVM33",
    value: "FVM33"
  },
  {
    SERIAL: "05BY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR71LY52",
    label: "NKR71LY52",
    value: "NKR71LY52"
  },
  {
    SERIAL: "05BZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58",
    label: "NKR58",
    value: "NKR58"
  },
  {
    SERIAL: "05CA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK EX 2.5",
    label: "D-MAX SPARK EX 2.5",
    value: "D-MAX SPARK EX 2.5"
  },
  {
    SERIAL: "05CB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK EX POWER 2.5",
    label: "D-MAX SPARK EX POWER 2.5",
    value: "D-MAX SPARK EX POWER 2.5"
  },
  {
    SERIAL: "05CC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK EX 3.0 POWER",
    label: "D-MAX SPARK EX 3.0 POWER",
    value: "D-MAX SPARK EX 3.0 POWER"
  },
  {
    SERIAL: "05CD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SL 2.5",
    label: "D-MAX SPACECAB SL 2.5",
    value: "D-MAX SPACECAB SL 2.5"
  },
  {
    SERIAL: "05CE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SX 2.5",
    label: "D-MAX SPACECAB SX 2.5",
    value: "D-MAX SPACECAB SX 2.5"
  },
  {
    SERIAL: "05CF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX 2.5",
    label: "D-MAX SPACECAB SLX 2.5",
    value: "D-MAX SPACECAB SLX 2.5"
  },
  {
    SERIAL: "05CG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX 3.0",
    label: "D-MAX SPACECAB SLX 3.0",
    value: "D-MAX SPACECAB SLX 3.0"
  },
  {
    SERIAL: "05CH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX 3.0 AUTO",
    label: "D-MAX SPACECAB SLX 3.0 AUTO",
    value: "D-MAX SPACECAB SLX 3.0 AUTO"
  },
  {
    SERIAL: "05CJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD 3.0 LS",
    label: "D-MAX SPACECAB 4WD 3.0 LS",
    value: "D-MAX SPACECAB 4WD 3.0 LS"
  },
  {
    SERIAL: "05CK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX 3.0 ABS",
    label: "D-MAX SPACECAB SLX 3.0 ABS",
    value: "D-MAX SPACECAB SLX 3.0 ABS"
  },
  {
    SERIAL: "05CL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX 3.0 AUTO ABS",
    label: "D-MAX SPACECAB SLX 3.0 AUTO ABS",
    value: "D-MAX SPACECAB SLX 3.0 AUTO ABS"
  },
  {
    SERIAL: "05CM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GXZ23 (หัวลาก)",
    label: "GXZ23 (หัวลาก)",
    value: "GXZ23 (หัวลาก)"
  },
  {
    SERIAL: "05CN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VEGA 3.0 4WD M",
    label: "VEGA 3.0 4WD M",
    value: "VEGA 3.0 4WD M"
  },
  {
    SERIAL: "05CO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "VEGA 3.0 4WD A",
    label: "VEGA 3.0 4WD A",
    value: "VEGA 3.0 4WD A"
  },
  {
    SERIAL: "05CS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR12",
    label: "FRR12",
    value: "FRR12"
  },
  {
    SERIAL: "05CT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SL 2.5 M",
    label: "D-MAX CAB4 SL 2.5 M",
    value: "D-MAX CAB4 SL 2.5 M"
  },
  {
    SERIAL: "05CU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SL 2.5 A",
    label: "D-MAX CAB4 SL 2.5 A",
    value: "D-MAX CAB4 SL 2.5 A"
  },
  {
    SERIAL: "05CV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX 3.0 M",
    label: "D-MAX CAB4 SLX 3.0 M",
    value: "D-MAX CAB4 SLX 3.0 M"
  },
  {
    SERIAL: "05CW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX 3.0 A",
    label: "D-MAX CAB4 SLX 3.0 A",
    value: "D-MAX CAB4 SLX 3.0 A"
  },
  {
    SERIAL: "05CX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0 LS",
    label: "D-MAX CAB4 4WD 3.0 LS",
    value: "D-MAX CAB4 4WD 3.0 LS"
  },
  {
    SERIAL: "05CY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "EXZ",
    label: "EXZ",
    value: "EXZ"
  },
  {
    SERIAL: "05CZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 RODEO",
    label: "D-MAX CAB4 RODEO",
    value: "D-MAX CAB4 RODEO"
  },
  {
    SERIAL: "05DA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE MASTER4*4 M",
    label: "ADVENTURE MASTER4*4 M",
    value: "ADVENTURE MASTER4*4 M"
  },
  {
    SERIAL: "05DB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE MASTER4*4 A",
    label: "ADVENTURE MASTER4*4 A",
    value: "ADVENTURE MASTER4*4 A"
  },
  {
    SERIAL: "05DC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SURF",
    label: "SURF",
    value: "SURF"
  },
  {
    SERIAL: "05DD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 GRAND CARRYBOY",
    label: "D-MAX CAB4 GRAND CARRYBOY",
    value: "D-MAX CAB4 GRAND CARRYBOY"
  },
  {
    SERIAL: "05DE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVZ32",
    label: "FVZ32",
    value: "FVZ32"
  },
  {
    SERIAL: "05DF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 3.0",
    label: "D-MAX CAB4 HI-LANDER 3.0",
    value: "D-MAX CAB4 HI-LANDER 3.0"
  },
  {
    SERIAL: "05DG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 3.0 SLX",
    label: "D-MAX SPACECAB HI-LANDER 3.0 SLX",
    value: "D-MAX SPACECAB HI-LANDER 3.0 SLX"
  },
  {
    SERIAL: "05DI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR70PY",
    label: "NPR70PY",
    value: "NPR70PY"
  },
  {
    SERIAL: "05DJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE MASTER ELEGANCE 4*4 A",
    label: "ADVENTURE MASTER ELEGANCE 4*4 A",
    value: "ADVENTURE MASTER ELEGANCE 4*4 A"
  },
  {
    SERIAL: "05DK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR32",
    label: "FRR32",
    value: "FRR32"
  },
  {
    SERIAL: "05DL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR66E (BUILT)",
    label: "NKR66E (BUILT)",
    value: "NKR66E (BUILT)"
  },
  {
    SERIAL: "05DM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 2.5 SX",
    label: "D-MAX CAB4 2.5 SX",
    value: "D-MAX CAB4 2.5 SX"
  },
  {
    SERIAL: "05DN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK ECONOVAN (ตู้อลูมิเนียม)",
    label: "D-MAX SPARK ECONOVAN (ตู้อลูมิเนียม)",
    value: "D-MAX SPARK ECONOVAN (ตู้อลูมิเนียม)"
  },
  {
    SERIAL: "05DO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPARK ECONOVAN",
    label: "SPARK ECONOVAN",
    value: "SPARK ECONOVAN"
  },
  {
    SERIAL: "05DP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX 3.0 COMMON RAIL",
    label: "D-MAX SPACECAB SLX 3.0 COMMON RAIL",
    value: "D-MAX SPACECAB SLX 3.0 COMMON RAIL"
  },
  {
    SERIAL: "05DQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI LANDER COMMON 3.0 SLX",
    label: "D-MAX SPACECAB HI LANDER COMMON 3.0 SLX",
    value: "D-MAX SPACECAB HI LANDER COMMON 3.0 SLX"
  },
  {
    SERIAL: "05DR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD 3.0 S COMMON RAIL",
    label: "D-MAX SPACECAB 4WD 3.0 S COMMON RAIL",
    value: "D-MAX SPACECAB 4WD 3.0 S COMMON RAIL"
  },
  {
    SERIAL: "05DS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD 3.0 LS ABS AIRBAG COM",
    label: "D-MAX SPACECAB 4WD 3.0 LS ABS AIRBAG COM",
    value: "D-MAX SPACECAB 4WD 3.0 LS ABS AIRBAG COM"
  },
  {
    SERIAL: "05DT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX COMMONRAIL 3.0",
    label: "D-MAX CAB4 SLX COMMONRAIL 3.0",
    value: "D-MAX CAB4 SLX COMMONRAIL 3.0"
  },
  {
    SERIAL: "05DU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX AIRBAG ABS COMMONRAI 3.0",
    label: "D-MAX CAB4 SLX AIRBAG ABS COMMONRAI 3.0",
    value: "D-MAX CAB4 SLX AIRBAG ABS COMMONRAI 3.0"
  },
  {
    SERIAL: "05DV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI LANDER COMMON RAIL 3.0",
    label: "D-MAX CAB4 HI LANDER COMMON RAIL 3.0",
    value: "D-MAX CAB4 HI LANDER COMMON RAIL 3.0"
  },
  {
    SERIAL: "05DW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD COMMON RAIL",
    label: "D-MAX CAB4 4WD COMMON RAIL",
    value: "D-MAX CAB4 4WD COMMON RAIL"
  },
  {
    SERIAL: "05DX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR69",
    label: "NKR69",
    value: "NKR69"
  },
  {
    SERIAL: "05DY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPARK",
    label: "SPARK",
    value: "SPARK"
  },
  {
    SERIAL: "05EA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 (4*2) MONOTONE",
    label: "MU-7 (4*2) MONOTONE",
    value: "MU-7 (4*2) MONOTONE"
  },
  {
    SERIAL: "05EB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 (4*4) M",
    label: "MU-7 (4*4) M",
    value: "MU-7 (4*4) M"
  },
  {
    SERIAL: "05EC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 4*2 TWOTONE",
    label: "MU-7 4*2 TWOTONE",
    value: "MU-7 4*2 TWOTONE"
  },
  {
    SERIAL: "05ED0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 4*4 A",
    label: "MU-7 4*4 A",
    value: "MU-7 4*4 A"
  },
  {
    SERIAL: "05EF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR69E(BUILT)",
    label: "NHR69E(BUILT)",
    value: "NHR69E(BUILT)"
  },
  {
    SERIAL: "05EG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12",
    label: "FVM12",
    value: "FVM12"
  },
  {
    SERIAL: "05EI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน12ตัน",
    label: "เกิน12ตัน",
    value: "เกิน12ตัน"
  },
  {
    SERIAL: "05EJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR55E12 (4ล้อ)",
    label: "NKR55E12 (4ล้อ)",
    value: "NKR55E12 (4ล้อ)"
  },
  {
    SERIAL: "05EK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR55EY52A (6ล้อ)",
    label: "NKR55EY52A (6ล้อ)",
    value: "NKR55EY52A (6ล้อ)"
  },
  {
    SERIAL: "05EL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVZ33MZ2B",
    label: "FVZ33MZ2B",
    value: "FVZ33MZ2B"
  },
  {
    SERIAL: "05EM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ23KC2B (โม่ปูน)",
    label: "FXZ23KC2B (โม่ปูน)",
    value: "FXZ23KC2B (โม่ปูน)"
  },
  {
    SERIAL: "05EN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน 12 ตัน",
    label: "เกิน 12 ตัน",
    value: "เกิน 12 ตัน"
  },
  {
    SERIAL: "05EO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR55 (BUILT)",
    label: "NHR55 (BUILT)",
    value: "NHR55 (BUILT)"
  },
  {
    SERIAL: "05EP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12 (Y.2001)",
    label: "FVM12 (Y.2001)",
    value: "FVM12 (Y.2001)"
  },
  {
    SERIAL: "05ER0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน 12 ตัน",
    label: "เกิน 12 ตัน",
    value: "เกิน 12 ตัน"
  },
  {
    SERIAL: "05ES0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVZ12",
    label: "FVZ12",
    value: "FVZ12"
  },
  {
    SERIAL: "05ET0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR11",
    label: "FTR11",
    value: "FTR11"
  },
  {
    SERIAL: "05EU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR69E",
    label: "NKR69E",
    value: "NKR69E"
  },
  {
    SERIAL: "05EV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVZ12",
    label: "FVZ12",
    value: "FVZ12"
  },
  {
    SERIAL: "05EW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ27",
    label: "FXZ27",
    value: "FXZ27"
  },
  {
    SERIAL: "05EX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12 ตัน",
    label: "12 ตัน",
    value: "12 ตัน"
  },
  {
    SERIAL: "05EY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58",
    label: "NKR58",
    value: "NKR58"
  },
  {
    SERIAL: "05EZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12",
    label: "FVM12",
    value: "FVM12"
  },
  {
    SERIAL: "05FA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12ตัน",
    label: "12ตัน",
    value: "12ตัน"
  },
  {
    SERIAL: "05FB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58",
    label: "NKR58",
    value: "NKR58"
  },
  {
    SERIAL: "05FC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน12ตัน",
    label: "เกิน12ตัน",
    value: "เกิน12ตัน"
  },
  {
    SERIAL: "05FD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58 (BUILT)",
    label: "NKR58 (BUILT)",
    value: "NKR58 (BUILT)"
  },
  {
    SERIAL: "05FE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12ตัน",
    label: "12ตัน",
    value: "12ตัน"
  },
  {
    SERIAL: "05FF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR55 (BUILT)",
    label: "NHR55 (BUILT)",
    value: "NHR55 (BUILT)"
  },
  {
    SERIAL: "05FG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR55E",
    label: "NKR55E",
    value: "NKR55E"
  },
  {
    SERIAL: "05FH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR58",
    label: "NPR58",
    value: "NPR58"
  },
  {
    SERIAL: "05FI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR69C",
    label: "NHR69C",
    value: "NHR69C"
  },
  {
    SERIAL: "05FJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12 ตัน",
    label: "12 ตัน",
    value: "12 ตัน"
  },
  {
    SERIAL: "05FL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58E(BUILT)",
    label: "NKR58E(BUILT)",
    value: "NKR58E(BUILT)"
  },
  {
    SERIAL: "05FM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHA54",
    label: "NHA54",
    value: "NHA54"
  },
  {
    SERIAL: "05FN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "6 ตัน",
    label: "6 ตัน",
    value: "6 ตัน"
  },
  {
    SERIAL: "05FO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "6 ตัน",
    label: "6 ตัน",
    value: "6 ตัน"
  },
  {
    SERIAL: "05FQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน 12 ตัน",
    label: "เกิน 12 ตัน",
    value: "เกิน 12 ตัน"
  },
  {
    SERIAL: "05FR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR13",
    label: "FTR13",
    value: "FTR13"
  },
  {
    SERIAL: "05FS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR55E",
    label: "NKR55E",
    value: "NKR55E"
  },
  {
    SERIAL: "05FT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NQR66",
    label: "NQR66",
    value: "NQR66"
  },
  {
    SERIAL: "05FU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR55E",
    label: "NKR55E",
    value: "NKR55E"
  },
  {
    SERIAL: "05FV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR55E (BUILT)",
    label: "NHR55E (BUILT)",
    value: "NHR55E (BUILT)"
  },
  {
    SERIAL: "05FW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58",
    label: "NKR58",
    value: "NKR58"
  },
  {
    SERIAL: "05FX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FSR11",
    label: "FSR11",
    value: "FSR11"
  },
  {
    SERIAL: "05FY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58",
    label: "NKR58",
    value: "NKR58"
  },
  {
    SERIAL: "05FZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NQR75",
    label: "NQR75",
    value: "NQR75"
  },
  {
    SERIAL: "05GA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPACECAB RODEO",
    label: "SPACECAB RODEO",
    value: "SPACECAB RODEO"
  },
  {
    SERIAL: "05GB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX 2.5 M",
    label: "D-MAX CAB4 SLX 2.5 M",
    value: "D-MAX CAB4 SLX 2.5 M"
  },
  {
    SERIAL: "05GC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX COML 3.0 A",
    label: "D-MAX CAB4 SLX COML 3.0 A",
    value: "D-MAX CAB4 SLX COML 3.0 A"
  },
  {
    SERIAL: "05GD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR66E",
    label: "NKR66E",
    value: "NKR66E"
  },
  {
    SERIAL: "05GE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR13",
    label: "FRR13",
    value: "FRR13"
  },
  {
    SERIAL: "05GF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR54",
    label: "NHR54",
    value: "NHR54"
  },
  {
    SERIAL: "05GG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR12 (BUILT)",
    label: "FTR12 (BUILT)",
    value: "FTR12 (BUILT)"
  },
  {
    SERIAL: "05GH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 2.5 COML M.",
    label: "D-MAX SPARK 2.5 COML M.",
    value: "D-MAX SPARK 2.5 COML M."
  },
  {
    SERIAL: "05GI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 2.5 COML A.",
    label: "D-MAX SPARK 2.5 COML A.",
    value: "D-MAX SPARK 2.5 COML A."
  },
  {
    SERIAL: "05GJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SL COML 2.5 M",
    label: "D-MAX SPACECAB SL COML 2.5 M",
    value: "D-MAX SPACECAB SL COML 2.5 M"
  },
  {
    SERIAL: "05GK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SX COML 2.5 M",
    label: "D-MAX SPACECAB SX COML 2.5 M",
    value: "D-MAX SPACECAB SX COML 2.5 M"
  },
  {
    SERIAL: "05GL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX COML 2.5 M",
    label: "D-MAX SPACECAB SLX COML 2.5 M",
    value: "D-MAX SPACECAB SLX COML 2.5 M"
  },
  {
    SERIAL: "05GM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX COML2.5 M ABS AIRBAG",
    label: "D-MAX SPACECAB SLX COML2.5 M ABS AIRBAG",
    value: "D-MAX SPACECAB SLX COML2.5 M ABS AIRBAG"
  },
  {
    SERIAL: "05GN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX COML3.0 M ABS AIRBAG",
    label: "D-MAX SPACECAB SLX COML3.0 M ABS AIRBAG",
    value: "D-MAX SPACECAB SLX COML3.0 M ABS AIRBAG"
  },
  {
    SERIAL: "05GO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX COML 3.0 A ABS AIRBAG",
    label: "D-MAX SPACECAB SLX COML 3.0 A ABS AIRBAG",
    value: "D-MAX SPACECAB SLX COML 3.0 A ABS AIRBAG"
  },
  {
    SERIAL: "05GP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX RODEO 4WD LS COML 2.5 M",
    label: "D-MAX RODEO 4WD LS COML 2.5 M",
    value: "D-MAX RODEO 4WD LS COML 2.5 M"
  },
  {
    SERIAL: "05GQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX RODEO 4WD LS COML 3.0",
    label: "D-MAX RODEO 4WD LS COML 3.0",
    value: "D-MAX RODEO 4WD LS COML 3.0"
  },
  {
    SERIAL: "05GR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX RODEO 4WD 3.0 S",
    label: "D-MAX RODEO 4WD 3.0 S",
    value: "D-MAX RODEO 4WD 3.0 S"
  },
  {
    SERIAL: "05GS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX HILANDER CAB SLX COML 2.5 M",
    label: "D-MAX HILANDER CAB SLX COML 2.5 M",
    value: "D-MAX HILANDER CAB SLX COML 2.5 M"
  },
  {
    SERIAL: "05GT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX HILANDER CAB COML 3.0 M",
    label: "D-MAX HILANDER CAB COML 3.0 M",
    value: "D-MAX HILANDER CAB COML 3.0 M"
  },
  {
    SERIAL: "05GU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER SLX COML 2.5 M",
    label: "D-MAX CAB4 HILANDER SLX COML 2.5 M",
    value: "D-MAX CAB4 HILANDER SLX COML 2.5 M"
  },
  {
    SERIAL: "05GV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER SLX COML 3.0 M",
    label: "D-MAX CAB4 HILANDER SLX COML 3.0 M",
    value: "D-MAX CAB4 HILANDER SLX COML 3.0 M"
  },
  {
    SERIAL: "05GW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER COML 3.0 A",
    label: "D-MAX CAB4 HILANDER COML 3.0 A",
    value: "D-MAX CAB4 HILANDER COML 3.0 A"
  },
  {
    SERIAL: "05GX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD LS COML 3.0 M",
    label: "D-MAX CAB4 4WD LS COML 3.0 M",
    value: "D-MAX CAB4 4WD LS COML 3.0 M"
  },
  {
    SERIAL: "05GY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX COML 2.5 M",
    label: "D-MAX CAB4 SLX COML 2.5 M",
    value: "D-MAX CAB4 SLX COML 2.5 M"
  },
  {
    SERIAL: "05GZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SX COML 2.5 M",
    label: "D-MAX CAB4 SX COML 2.5 M",
    value: "D-MAX CAB4 SX COML 2.5 M"
  },
  {
    SERIAL: "05HA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SL COML 2.5 M",
    label: "D-MAX CAB4 SL COML 2.5 M",
    value: "D-MAX CAB4 SL COML 2.5 M"
  },
  {
    SERIAL: "05HB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 PRIMO 2WD 3.0",
    label: "MU-7 PRIMO 2WD 3.0",
    value: "MU-7 PRIMO 2WD 3.0"
  },
  {
    SERIAL: "05HC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 ACTIVO 4WD 3.0",
    label: "MU-7 ACTIVO 4WD 3.0",
    value: "MU-7 ACTIVO 4WD 3.0"
  },
  {
    SERIAL: "05HE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12 (BUILT)",
    label: "FVM12 (BUILT)",
    value: "FVM12 (BUILT)"
  },
  {
    SERIAL: "05HF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR34",
    label: "FTR34",
    value: "FTR34"
  },
  {
    SERIAL: "05HG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR69E (BULT)",
    label: "NKR69E (BULT)",
    value: "NKR69E (BULT)"
  },
  {
    SERIAL: "05HH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0 LS A",
    label: "D-MAX CAB4 4WD 3.0 LS A",
    value: "D-MAX CAB4 4WD 3.0 LS A"
  },
  {
    SERIAL: "05HI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG50 (BUILT)",
    label: "CXG50 (BUILT)",
    value: "CXG50 (BUILT)"
  },
  {
    SERIAL: "05HJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "หาง SCTS-1800",
    label: "หาง SCTS-1800",
    value: "หาง SCTS-1800"
  },
  {
    SERIAL: "05HL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK ECONOVAN",
    label: "D-MAX SPARK ECONOVAN",
    value: "D-MAX SPARK ECONOVAN"
  },
  {
    SERIAL: "05HM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR71E",
    label: "NKR71E",
    value: "NKR71E"
  },
  {
    SERIAL: "05HO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR57E (BULT)",
    label: "NKR57E (BULT)",
    value: "NKR57E (BULT)"
  },
  {
    SERIAL: "05HP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TDJ72",
    label: "TDJ72",
    value: "TDJ72"
  },
  {
    SERIAL: "05HQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 PRODUCT CAMPAIGN",
    label: "MU-7 PRODUCT CAMPAIGN",
    value: "MU-7 PRODUCT CAMPAIGN"
  },
  {
    SERIAL: "05HR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE SPORT AUTO 4*2",
    label: "ADVENTURE SPORT AUTO 4*2",
    value: "ADVENTURE SPORT AUTO 4*2"
  },
  {
    SERIAL: "05HS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE SPORT M 4*2",
    label: "ADVENTURE SPORT M 4*2",
    value: "ADVENTURE SPORT M 4*2"
  },
  {
    SERIAL: "05HT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE ELEGANCE II 4*4",
    label: "ADVENTURE ELEGANCE II 4*4",
    value: "ADVENTURE ELEGANCE II 4*4"
  },
  {
    SERIAL: "05HU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE SPORT AUTO 4*4",
    label: "ADVENTURE SPORT AUTO 4*4",
    value: "ADVENTURE SPORT AUTO 4*4"
  },
  {
    SERIAL: "05HV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12 (BUILT)",
    label: "FVM12 (BUILT)",
    value: "FVM12 (BUILT)"
  },
  {
    SERIAL: "05HW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน12ตัน (BUILT)",
    label: "เกิน12ตัน (BUILT)",
    value: "เกิน12ตัน (BUILT)"
  },
  {
    SERIAL: "05HX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NMR85",
    label: "NMR85",
    value: "NMR85"
  },
  {
    SERIAL: "05HY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NLR85E",
    label: "NLR85E",
    value: "NLR85E"
  },
  {
    SERIAL: "05HZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR66 (BUILT)",
    label: "NPR66 (BUILT)",
    value: "NPR66 (BUILT)"
  },
  {
    SERIAL: "05IA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR75",
    label: "NPR75",
    value: "NPR75"
  },
  {
    SERIAL: "05IB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVR13 (BUILT)",
    label: "FVR13 (BUILT)",
    value: "FVR13 (BUILT)"
  },
  {
    SERIAL: "05IC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NQR75 (BUS)",
    label: "NQR75 (BUS)",
    value: "NQR75 (BUS)"
  },
  {
    SERIAL: "05ID0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TD10 (BUILT)",
    label: "TD10 (BUILT)",
    value: "TD10 (BUILT)"
  },
  {
    SERIAL: "05IE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK EX PLATINUM 2.5 M",
    label: "D-MAX SPARK EX PLATINUM 2.5 M",
    value: "D-MAX SPARK EX PLATINUM 2.5 M"
  },
  {
    SERIAL: "05IF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX PLATINUM SMART 2.5 M",
    label: "D-MAX SPACECAB SLX PLATINUM SMART 2.5 M",
    value: "D-MAX SPACECAB SLX PLATINUM SMART 2.5 M"
  },
  {
    SERIAL: "05IG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD LS PLATINUM 2.5",
    label: "D-MAX SPACECAB 4WD LS PLATINUM 2.5",
    value: "D-MAX SPACECAB 4WD LS PLATINUM 2.5"
  },
  {
    SERIAL: "05IH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER PLATINUM 3.0 M",
    label: "D-MAX SPACECAB HI-LANDER PLATINUM 3.0 M",
    value: "D-MAX SPACECAB HI-LANDER PLATINUM 3.0 M"
  },
  {
    SERIAL: "05II0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX HI-LANDER PLATINUM SMART 2.5 M",
    label: "D-MAX HI-LANDER PLATINUM SMART 2.5 M",
    value: "D-MAX HI-LANDER PLATINUM SMART 2.5 M"
  },
  {
    SERIAL: "05IJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK EXL PLATINUM 2.5 M",
    label: "D-MAX SPARK EXL PLATINUM 2.5 M",
    value: "D-MAX SPARK EXL PLATINUM 2.5 M"
  },
  {
    SERIAL: "05IK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB PLATINUM SX 2.5 M",
    label: "D-MAX SPACECAB PLATINUM SX 2.5 M",
    value: "D-MAX SPACECAB PLATINUM SX 2.5 M"
  },
  {
    SERIAL: "05IL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB PLATINUM SL 2.5 M",
    label: "D-MAX SPACECAB PLATINUM SL 2.5 M",
    value: "D-MAX SPACECAB PLATINUM SL 2.5 M"
  },
  {
    SERIAL: "05IM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX PLATINUM 3.0 A",
    label: "D-MAX SPACECAB SLX PLATINUM 3.0 A",
    value: "D-MAX SPACECAB SLX PLATINUM 3.0 A"
  },
  {
    SERIAL: "05IN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX PLATINUM 3.0 M",
    label: "D-MAX SPACECAB SLX PLATINUM 3.0 M",
    value: "D-MAX SPACECAB SLX PLATINUM 3.0 M"
  },
  {
    SERIAL: "05IO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB SLX PLATINUM 2.5 M",
    label: "D-MAX SPACECAB SLX PLATINUM 2.5 M",
    value: "D-MAX SPACECAB SLX PLATINUM 2.5 M"
  },
  {
    SERIAL: "05IP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD PLATINUM 3.0 M LS",
    label: "D-MAX SPACECAB 4WD PLATINUM 3.0 M LS",
    value: "D-MAX SPACECAB 4WD PLATINUM 3.0 M LS"
  },
  {
    SERIAL: "05IQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX PLATINUM 3.0",
    label: "D-MAX CAB4 SLX PLATINUM 3.0",
    value: "D-MAX CAB4 SLX PLATINUM 3.0"
  },
  {
    SERIAL: "05IR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD LS 3.0 M",
    label: "D-MAX CAB4 4WD LS 3.0 M",
    value: "D-MAX CAB4 4WD LS 3.0 M"
  },
  {
    SERIAL: "05IS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX PLATINUM 2.5 M",
    label: "D-MAX CAB4 SLX PLATINUM 2.5 M",
    value: "D-MAX CAB4 SLX PLATINUM 2.5 M"
  },
  {
    SERIAL: "05IT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SLX PLATINUM SMART 2.5 M",
    label: "D-MAX CAB4 SLX PLATINUM SMART 2.5 M",
    value: "D-MAX CAB4 SLX PLATINUM SMART 2.5 M"
  },
  {
    SERIAL: "05IU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SX PLATINUM 2.5 M",
    label: "D-MAX CAB4 SX PLATINUM 2.5 M",
    value: "D-MAX CAB4 SX PLATINUM 2.5 M"
  },
  {
    SERIAL: "05IV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER 3.0 M",
    label: "D-MAX CAB4 HILANDER 3.0 M",
    value: "D-MAX CAB4 HILANDER 3.0 M"
  },
  {
    SERIAL: "05IW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER 3.0 A",
    label: "D-MAX CAB4 HILANDER 3.0 A",
    value: "D-MAX CAB4 HILANDER 3.0 A"
  },
  {
    SERIAL: "05IX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER PLATINUM 2.5 M",
    label: "D-MAX CAB4 HILANDER PLATINUM 2.5 M",
    value: "D-MAX CAB4 HILANDER PLATINUM 2.5 M"
  },
  {
    SERIAL: "05IY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD LS 3.0 A",
    label: "D-MAX CAB4 4WD LS 3.0 A",
    value: "D-MAX CAB4 4WD LS 3.0 A"
  },
  {
    SERIAL: "05JA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVZ34",
    label: "FVZ34",
    value: "FVZ34"
  },
  {
    SERIAL: "05JC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR82 NGV",
    label: "NPR82 NGV",
    value: "NPR82 NGV"
  },
  {
    SERIAL: "05JD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "JCM49Y (BUILT)",
    label: "JCM49Y (BUILT)",
    value: "JCM49Y (BUILT)"
  },
  {
    SERIAL: "05JE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "ADVENTURE 2WD 3.0",
    label: "ADVENTURE 2WD 3.0",
    value: "ADVENTURE 2WD 3.0"
  },
  {
    SERIAL: "05JF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR86 (CNG)",
    label: "FTR86 (CNG)",
    value: "FTR86 (CNG)"
  },
  {
    SERIAL: "05JG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR66 (BUILT)",
    label: "NKR66 (BUILT)",
    value: "NKR66 (BUILT)"
  },
  {
    SERIAL: "05JI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NHR69 (BUILT)",
    label: "NHR69 (BUILT)",
    value: "NHR69 (BUILT)"
  },
  {
    SERIAL: "05JJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 SUPER PLATINUM",
    label: "D-MAX CAB4 SUPER PLATINUM",
    value: "D-MAX CAB4 SUPER PLATINUM"
  },
  {
    SERIAL: "05JK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR32 (BUILT)",
    label: "FTR32 (BUILT)",
    value: "FTR32 (BUILT)"
  },
  {
    SERIAL: "05JL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR12 (BUILT)",
    label: "FRR12 (BUILT)",
    value: "FRR12 (BUILT)"
  },
  {
    SERIAL: "05JM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG23 (BUILT)",
    label: "CXG23 (BUILT)",
    value: "CXG23 (BUILT)"
  },
  {
    SERIAL: "05JO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CYL50V2W (BUILT)",
    label: "CYL50V2W (BUILT)",
    value: "CYL50V2W (BUILT)"
  },
  {
    SERIAL: "05JP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER TITANIUM 3.0",
    label: "D-MAX CAB4 HILANDER TITANIUM 3.0",
    value: "D-MAX CAB4 HILANDER TITANIUM 3.0"
  },
  {
    SERIAL: "05JQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "OVER 2000",
    label: "OVER 2000",
    value: "OVER 2000"
  },
  {
    SERIAL: "05JR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ360",
    label: "FXZ360",
    value: "FXZ360"
  },
  {
    SERIAL: "05JS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12 (BUILT)",
    label: "FVM12 (BUILT)",
    value: "FVM12 (BUILT)"
  },
  {
    SERIAL: "05JT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "K21",
    label: "K21",
    value: "K21"
  },
  {
    SERIAL: "05JU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "AKR66",
    label: "AKR66",
    value: "AKR66"
  },
  {
    SERIAL: "05JV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน 12 ตัน (BUILT)",
    label: "เกิน 12 ตัน (BUILT)",
    value: "เกิน 12 ตัน (BUILT)"
  },
  {
    SERIAL: "05JW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "AKR66E (CNG)",
    label: "AKR66E (CNG)",
    value: "AKR66E (CNG)"
  },
  {
    SERIAL: "05JX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NMR82",
    label: "NMR82",
    value: "NMR82"
  },
  {
    SERIAL: "05JY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CYM81V2 (BUILT)",
    label: "CYM81V2 (BUILT)",
    value: "CYM81V2 (BUILT)"
  },
  {
    SERIAL: "05JZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X THE ONYX 3.0 VGS TURBO",
    label: "MU-X THE ONYX 3.0 VGS TURBO",
    value: "MU-X THE ONYX 3.0 VGS TURBO"
  },
  {
    SERIAL: "05KA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "K21",
    label: "K21",
    value: "K21"
  },
  {
    SERIAL: "05KB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 2.5",
    label: "D-MAX SPACECAB HI-LANDER 2.5",
    value: "D-MAX SPACECAB HI-LANDER 2.5"
  },
  {
    SERIAL: "05KC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR71E",
    label: "NKR71E",
    value: "NKR71E"
  },
  {
    SERIAL: "05KD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 2.5 B",
    label: "D-MAX SPARK 2.5 B",
    value: "D-MAX SPARK 2.5 B"
  },
  {
    SERIAL: "05KE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 2.5 S",
    label: "D-MAX SPARK 2.5 S",
    value: "D-MAX SPARK 2.5 S"
  },
  {
    SERIAL: "05KF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 2.5 VGS S",
    label: "D-MAX SPARK 2.5 VGS S",
    value: "D-MAX SPARK 2.5 VGS S"
  },
  {
    SERIAL: "05KG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 2.5 S",
    label: "D-MAX SPACECAB 2.5 S",
    value: "D-MAX SPACECAB 2.5 S"
  },
  {
    SERIAL: "05KH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 2.5 L",
    label: "D-MAX SPACECAB 2.5 L",
    value: "D-MAX SPACECAB 2.5 L"
  },
  {
    SERIAL: "05KI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 2.5 Z",
    label: "D-MAX SPACECAB 2.5 Z",
    value: "D-MAX SPACECAB 2.5 Z"
  },
  {
    SERIAL: "05KJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 2.5 VGS Z",
    label: "D-MAX SPACECAB 2.5 VGS Z",
    value: "D-MAX SPACECAB 2.5 VGS Z"
  },
  {
    SERIAL: "05KK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 2.5 L",
    label: "D-MAX SPACECAB HI-LANDER 2.5 L",
    value: "D-MAX SPACECAB HI-LANDER 2.5 L"
  },
  {
    SERIAL: "05KL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 2.5 Z",
    label: "D-MAX SPACECAB HI-LANDER 2.5 Z",
    value: "D-MAX SPACECAB HI-LANDER 2.5 Z"
  },
  {
    SERIAL: "05KN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 2.5 VGS Z A PR ETIGE DVD IGENIE",
    label: "D-MAX SPACECAB HI-LANDER 2.5 VGS Z A PR ETIGE DVD IGENIE",
    value: "D-MAX SPACECAB HI-LANDER 2.5 VGS Z A PR ETIGE DVD IGENIE"
  },
  {
    SERIAL: "05KO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 3.0 VGS Z PRETIGE DVD IGENIE",
    label: "D-MAX SPACECAB HI-LANDER 3.0 VGS Z PRETIGE DVD IGENIE",
    value: "D-MAX SPACECAB HI-LANDER 3.0 VGS Z PRETIGE DVD IGENIE"
  },
  {
    SERIAL: "05KP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD 2.5 VGS Z",
    label: "D-MAX SPACECAB 4WD 2.5 VGS Z",
    value: "D-MAX SPACECAB 4WD 2.5 VGS Z"
  },
  {
    SERIAL: "05KQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD 3.0 VGS Z PRETIGE",
    label: "D-MAX SPACECAB 4WD 3.0 VGS Z PRETIGE",
    value: "D-MAX SPACECAB 4WD 3.0 VGS Z PRETIGE"
  },
  {
    SERIAL: "05KR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 2.5 S",
    label: "D-MAX CAB4 2.5 S",
    value: "D-MAX CAB4 2.5 S"
  },
  {
    SERIAL: "05KS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 2.5 L",
    label: "D-MAX CAB4 2.5 L",
    value: "D-MAX CAB4 2.5 L"
  },
  {
    SERIAL: "05KT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 2.5 L",
    label: "D-MAX CAB4 HI-LANDER 2.5 L",
    value: "D-MAX CAB4 HI-LANDER 2.5 L"
  },
  {
    SERIAL: "05KU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 2.5 VGS Z",
    label: "D-MAX CAB4 HI-LANDER 2.5 VGS Z",
    value: "D-MAX CAB4 HI-LANDER 2.5 VGS Z"
  },
  {
    SERIAL: "05KV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 2.5 VGS z DVD",
    label: "D-MAX CAB4 HI-LANDER 2.5 VGS z DVD",
    value: "D-MAX CAB4 HI-LANDER 2.5 VGS z DVD"
  },
  {
    SERIAL: "05KW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 2.5 VGS Z PRETIGE",
    label: "D-MAX CAB4 HI-LANDER 2.5 VGS Z PRETIGE",
    value: "D-MAX CAB4 HI-LANDER 2.5 VGS Z PRETIGE"
  },
  {
    SERIAL: "05KX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 3.0 VGS Z PRETIGE",
    label: "D-MAX CAB4 HI-LANDER 3.0 VGS Z PRETIGE",
    value: "D-MAX CAB4 HI-LANDER 3.0 VGS Z PRETIGE"
  },
  {
    SERIAL: "05KY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 3.0 VGS Z TOP",
    label: "D-MAX CAB4 HI-LANDER 3.0 VGS Z TOP",
    value: "D-MAX CAB4 HI-LANDER 3.0 VGS Z TOP"
  },
  {
    SERIAL: "05KZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 2.5 L",
    label: "D-MAX CAB4 4WD 2.5 L",
    value: "D-MAX CAB4 4WD 2.5 L"
  },
  {
    SERIAL: "05LA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0 VGS Z PRETIGE",
    label: "D-MAX CAB4 4WD 3.0 VGS Z PRETIGE",
    value: "D-MAX CAB4 4WD 3.0 VGS Z PRETIGE"
  },
  {
    SERIAL: "05LB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0 VGS Z PRETIGE TOP",
    label: "D-MAX CAB4 4WD 3.0 VGS Z PRETIGE TOP",
    value: "D-MAX CAB4 4WD 3.0 VGS Z PRETIGE TOP"
  },
  {
    SERIAL: "05LC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FSR12 (BUILT)",
    label: "FSR12 (BUILT)",
    value: "FSR12 (BUILT)"
  },
  {
    SERIAL: "05LD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG81P (BUILT)",
    label: "CXG81P (BUILT)",
    value: "CXG81P (BUILT)"
  },
  {
    SERIAL: "05LE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "AKR66 (CNG,BUILT)",
    label: "AKR66 (CNG,BUILT)",
    value: "AKR66 (CNG,BUILT)"
  },
  {
    SERIAL: "05LG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM88",
    label: "FVM88",
    value: "FVM88"
  },
  {
    SERIAL: "05LH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXZ18JK (BUILT)",
    label: "CXZ18JK (BUILT)",
    value: "CXZ18JK (BUILT)"
  },
  {
    SERIAL: "05LI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR662",
    label: "NPR662",
    value: "NPR662"
  },
  {
    SERIAL: "05LN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NRR32L CRANE (BUILT)",
    label: "NRR32L CRANE (BUILT)",
    value: "NRR32L CRANE (BUILT)"
  },
  {
    SERIAL: "05LO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NLR130",
    label: "NLR130",
    value: "NLR130"
  },
  {
    SERIAL: "05LP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXZ19",
    label: "CXZ19",
    value: "CXZ19"
  },
  {
    SERIAL: "05LQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TMK47E (BUILT)",
    label: "TMK47E (BUILT)",
    value: "TMK47E (BUILT)"
  },
  {
    SERIAL: "05LS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FYH77",
    label: "FYH77",
    value: "FYH77"
  },
  {
    SERIAL: "05LT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "BUS (Y.2012)",
    label: "BUS (Y.2012)",
    value: "BUS (Y.2012)"
  },
  {
    SERIAL: "05LU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK CAB CHASSIS",
    label: "D-MAX SPARK CAB CHASSIS",
    value: "D-MAX SPARK CAB CHASSIS"
  },
  {
    SERIAL: "05LV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "BKR81E",
    label: "BKR81E",
    value: "BKR81E"
  },
  {
    SERIAL: "05LW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ13 (BUILT)",
    label: "FXZ13 (BUILT)",
    value: "FXZ13 (BUILT)"
  },
  {
    SERIAL: "05LY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR32 (BUILT)",
    label: "FTR32 (BUILT)",
    value: "FTR32 (BUILT)"
  },
  {
    SERIAL: "05LZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ23NZ (BUILT)",
    label: "FXZ23NZ (BUILT)",
    value: "FXZ23NZ (BUILT)"
  },
  {
    SERIAL: "05MB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM86 - CNG",
    label: "FVM86 - CNG",
    value: "FVM86 - CNG"
  },
  {
    SERIAL: "05MC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXM23U",
    label: "CXM23U",
    value: "CXM23U"
  },
  {
    SERIAL: "05MD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM12 (BUILT)",
    label: "FVM12 (BUILT)",
    value: "FVM12 (BUILT)"
  },
  {
    SERIAL: "05ME0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GXZ78 NGV",
    label: "GXZ78 NGV",
    value: "GXZ78 NGV"
  },
  {
    SERIAL: "05MF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR58L (BUILT)",
    label: "NKR58L (BUILT)",
    value: "NKR58L (BUILT)"
  },
  {
    SERIAL: "05MG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 3.0 A",
    label: "MU-7 3.0 A",
    value: "MU-7 3.0 A"
  },
  {
    SERIAL: "05MH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-7 4WD 3.0",
    label: "MU-7 4WD 3.0",
    value: "MU-7 4WD 3.0"
  },
  {
    SERIAL: "05MI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ78Q (CNG)",
    label: "FXZ78Q (CNG)",
    value: "FXZ78Q (CNG)"
  },
  {
    SERIAL: "05MJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK SUPER BIG 2.5",
    label: "D-MAX SPARK SUPER BIG 2.5",
    value: "D-MAX SPARK SUPER BIG 2.5"
  },
  {
    SERIAL: "05MK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 2.5 Z",
    label: "D-MAX CAB4 2.5 Z",
    value: "D-MAX CAB4 2.5 Z"
  },
  {
    SERIAL: "05MM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "เกิน 12 ตัน (RENOVATE)",
    label: "เกิน 12 ตัน (RENOVATE)",
    value: "เกิน 12 ตัน (RENOVATE)"
  },
  {
    SERIAL: "05MN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "EXZ (BUILT)",
    label: "EXZ (BUILT)",
    value: "EXZ (BUILT)"
  },
  {
    SERIAL: "05MO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NQR75 (BUS)",
    label: "NQR75 (BUS)",
    value: "NQR75 (BUS)"
  },
  {
    SERIAL: "05MP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 2.5 VGS",
    label: "MU-X 2.5 VGS",
    value: "MU-X 2.5 VGS"
  },
  {
    SERIAL: "05MQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 2.5 VGS DVD",
    label: "MU-X 2.5 VGS DVD",
    value: "MU-X 2.5 VGS DVD"
  },
  {
    SERIAL: "05MR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 3.0 VGS DA DVD NAVIGATOR",
    label: "MU-X 3.0 VGS DA DVD NAVIGATOR",
    value: "MU-X 3.0 VGS DA DVD NAVIGATOR"
  },
  {
    SERIAL: "05MS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 3.0 VGS DA DVD NAVIGATOR 4WD",
    label: "MU-X 3.0 VGS DA DVD NAVIGATOR 4WD",
    value: "MU-X 3.0 VGS DA DVD NAVIGATOR 4WD"
  },
  {
    SERIAL: "05MT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NLR75 (UNIQUE)",
    label: "NLR75 (UNIQUE)",
    value: "NLR75 (UNIQUE)"
  },
  {
    SERIAL: "05MU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR32 (UNIQUE)",
    label: "FTR32 (UNIQUE)",
    value: "FTR32 (UNIQUE)"
  },
  {
    SERIAL: "05MV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR13 (UNIQUE)",
    label: "FTR13 (UNIQUE)",
    value: "FTR13 (UNIQUE)"
  },
  {
    SERIAL: "05MW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR11 (UNIQUE)",
    label: "FTR11 (UNIQUE)",
    value: "FTR11 (UNIQUE)"
  },
  {
    SERIAL: "05MX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR32 (UNIQUE)",
    label: "FTR32 (UNIQUE)",
    value: "FTR32 (UNIQUE)"
  },
  {
    SERIAL: "05MY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR33 (UNIQUE)",
    label: "FTR33 (UNIQUE)",
    value: "FTR33 (UNIQUE)"
  },
  {
    SERIAL: "05MZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "TRAILER (UNIQUE)",
    label: "TRAILER (UNIQUE)",
    value: "TRAILER (UNIQUE)"
  },
  {
    SERIAL: "05NA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR33 (BUILT)",
    label: "FTR33 (BUILT)",
    value: "FTR33 (BUILT)"
  },
  {
    SERIAL: "05NB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR13 (UNIQUE)",
    label: "FTR13 (UNIQUE)",
    value: "FTR13 (UNIQUE)"
  },
  {
    SERIAL: "05NC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FSR51 (UNIQUE)",
    label: "FSR51 (UNIQUE)",
    value: "FSR51 (UNIQUE)"
  },
  {
    SERIAL: "05ND0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX HI LANDER CAB SUPER PLATINUM 2.5",
    label: "D-MAX HI LANDER CAB SUPER PLATINUM 2.5",
    value: "D-MAX HI LANDER CAB SUPER PLATINUM 2.5"
  },
  {
    SERIAL: "05NE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER X-SERIES 2.5 Z",
    label: "D-MAX SPACECAB HI-LANDER X-SERIES 2.5 Z",
    value: "D-MAX SPACECAB HI-LANDER X-SERIES 2.5 Z"
  },
  {
    SERIAL: "05NF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 2.5 M VGS Z",
    label: "D-MAX SPACECAB HI-LANDER 2.5 M VGS Z",
    value: "D-MAX SPACECAB HI-LANDER 2.5 M VGS Z"
  },
  {
    SERIAL: "05NG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB X SERIES Z 2.5 VGS",
    label: "D-MAX SPACECAB X SERIES Z 2.5 VGS",
    value: "D-MAX SPACECAB X SERIES Z 2.5 VGS"
  },
  {
    SERIAL: "05NH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 2.5 VGS 4WD",
    label: "D-MAX SPARK 2.5 VGS 4WD",
    value: "D-MAX SPARK 2.5 VGS 4WD"
  },
  {
    SERIAL: "05NI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER SUPER PLATINUM 3.0",
    label: "D-MAX CAB4 HILANDER SUPER PLATINUM 3.0",
    value: "D-MAX CAB4 HILANDER SUPER PLATINUM 3.0"
  },
  {
    SERIAL: "05NJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HILANDER X SERIES 2.5 Z VGS",
    label: "D-MAX CAB4 HILANDER X SERIES 2.5 Z VGS",
    value: "D-MAX CAB4 HILANDER X SERIES 2.5 Z VGS"
  },
  {
    SERIAL: "05NK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR58",
    label: "NPR58",
    value: "NPR58"
  },
  {
    SERIAL: "05NL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FTR11NY (BUILT)",
    label: "FTR11NY (BUILT)",
    value: "FTR11NY (BUILT)"
  },
  {
    SERIAL: "05NM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER X-SERIES 2.5A VGS Z-PRETIGE",
    label: "D-MAX CAB4 HI-LANDER X-SERIES 2.5A VGS Z-PRETIGE",
    value: "D-MAX CAB4 HI-LANDER X-SERIES 2.5A VGS Z-PRETIGE"
  },
  {
    SERIAL: "05NN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HILANDER 2.5 L",
    label: "D-MAX SPACECAB HILANDER 2.5 L",
    value: "D-MAX SPACECAB HILANDER 2.5 L"
  },
  {
    SERIAL: "05NO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 3.0 B",
    label: "D-MAX SPARK 3.0 B",
    value: "D-MAX SPARK 3.0 B"
  },
  {
    SERIAL: "05NP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB L 2.5 VGS 4WD",
    label: "D-MAX SPACECAB L 2.5 VGS 4WD",
    value: "D-MAX SPACECAB L 2.5 VGS 4WD"
  },
  {
    SERIAL: "05NQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 2.5 A Z VGS DVD",
    label: "D-MAX CAB4 4WD 2.5 A Z VGS DVD",
    value: "D-MAX CAB4 4WD 2.5 A Z VGS DVD"
  },
  {
    SERIAL: "05NS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 2.5 A VGS Z",
    label: "D-MAX SPACECAB HI-LANDER 2.5 A VGS Z",
    value: "D-MAX SPACECAB HI-LANDER 2.5 A VGS Z"
  },
  {
    SERIAL: "05NT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM34R",
    label: "FVM34R",
    value: "FVM34R"
  },
  {
    SERIAL: "05NU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM34T",
    label: "FVM34T",
    value: "FVM34T"
  },
  {
    SERIAL: "05NV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ34P",
    label: "FXZ34P",
    value: "FXZ34P"
  },
  {
    SERIAL: "05NW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ77Q",
    label: "FXZ77Q",
    value: "FXZ77Q"
  },
  {
    SERIAL: "05NX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ78QXB",
    label: "FXZ78QXB",
    value: "FXZ78QXB"
  },
  {
    SERIAL: "05NY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GVR86 6W 4*2",
    label: "GVR86 6W 4*2",
    value: "GVR86 6W 4*2"
  },
  {
    SERIAL: "05NZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GVR86 10W 6*2",
    label: "GVR86 10W 6*2",
    value: "GVR86 10W 6*2"
  },
  {
    SERIAL: "05OA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR71L (BUILT)",
    label: "NPR71L (BUILT)",
    value: "NPR71L (BUILT)"
  },
  {
    SERIAL: "05OB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM300",
    label: "FVM300",
    value: "FVM300"
  },
  {
    SERIAL: "05OC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NLR75",
    label: "NLR75",
    value: "NLR75"
  },
  {
    SERIAL: "05OD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPM450 - BUS",
    label: "SPM450 - BUS",
    value: "SPM450 - BUS"
  },
  {
    SERIAL: "05OE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "SPZ440 - BUS",
    label: "SPZ440 - BUS",
    value: "SPZ440 - BUS"
  },
  {
    SERIAL: "05OF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVZ12 (BUILT)",
    label: "FVZ12 (BUILT)",
    value: "FVZ12 (BUILT)"
  },
  {
    SERIAL: "05OG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FXZ23N (BUILT)",
    label: "FXZ23N (BUILT)",
    value: "FXZ23N (BUILT)"
  },
  {
    SERIAL: "05OH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CYG23 (BUILT)",
    label: "CYG23 (BUILT)",
    value: "CYG23 (BUILT)"
  },
  {
    SERIAL: "05OI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR33 (BUILT)",
    label: "FRR33 (BUILT)",
    value: "FRR33 (BUILT)"
  },
  {
    SERIAL: "05OJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9D Z DVD",
    label: "D-MAX CAB4 HI-LANDER 1.9D Z DVD",
    value: "D-MAX CAB4 HI-LANDER 1.9D Z DVD"
  },
  {
    SERIAL: "05OK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 1.9D B",
    label: "D-MAX SPARK 1.9D B",
    value: "D-MAX SPARK 1.9D B"
  },
  {
    SERIAL: "05OL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 1.9D S",
    label: "D-MAX SPARK 1.9D S",
    value: "D-MAX SPARK 1.9D S"
  },
  {
    SERIAL: "05OM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 1.9D S",
    label: "D-MAX SPACECAB 1.9D S",
    value: "D-MAX SPACECAB 1.9D S"
  },
  {
    SERIAL: "05ON0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 1.9D L",
    label: "D-MAX SPACECAB 1.9D L",
    value: "D-MAX SPACECAB 1.9D L"
  },
  {
    SERIAL: "05OO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 1.9D Z",
    label: "D-MAX SPACECAB 1.9D Z",
    value: "D-MAX SPACECAB 1.9D Z"
  },
  {
    SERIAL: "05OP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9D L",
    label: "D-MAX SPACECAB HI-LANDER 1.9D L",
    value: "D-MAX SPACECAB HI-LANDER 1.9D L"
  },
  {
    SERIAL: "05OQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9D Z",
    label: "D-MAX SPACECAB HI-LANDER 1.9D Z",
    value: "D-MAX SPACECAB HI-LANDER 1.9D Z"
  },
  {
    SERIAL: "05OR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9D Z DVD",
    label: "D-MAX SPACECAB HI-LANDER 1.9D Z DVD",
    value: "D-MAX SPACECAB HI-LANDER 1.9D Z DVD"
  },
  {
    SERIAL: "05OS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9D Z-PRETIGE",
    label: "D-MAX SPACECAB HI-LANDER 1.9D Z-PRETIGE",
    value: "D-MAX SPACECAB HI-LANDER 1.9D Z-PRETIGE"
  },
  {
    SERIAL: "05OT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 1.9D S",
    label: "D-MAX CAB4 1.9D S",
    value: "D-MAX CAB4 1.9D S"
  },
  {
    SERIAL: "05OU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 1.9D Z",
    label: "D-MAX CAB4 1.9D Z",
    value: "D-MAX CAB4 1.9D Z"
  },
  {
    SERIAL: "05OV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9D L",
    label: "D-MAX CAB4 HI-LANDER 1.9D L",
    value: "D-MAX CAB4 HI-LANDER 1.9D L"
  },
  {
    SERIAL: "05OW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9D Z",
    label: "D-MAX CAB4 HI-LANDER 1.9D Z",
    value: "D-MAX CAB4 HI-LANDER 1.9D Z"
  },
  {
    SERIAL: "05OX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9D Z-PRETIGE M",
    label: "D-MAX CAB4 HI-LANDER 1.9D Z-PRETIGE M",
    value: "D-MAX CAB4 HI-LANDER 1.9D Z-PRETIGE M"
  },
  {
    SERIAL: "05OY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 3.0D Z-PRETIGE",
    label: "D-MAX CAB4 HI-LANDER 3.0D Z-PRETIGE",
    value: "D-MAX CAB4 HI-LANDER 3.0D Z-PRETIGE"
  },
  {
    SERIAL: "05OZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 3.0D Z-PRETIGE",
    label: "D-MAX SPACECAB HI-LANDER 3.0D Z-PRETIGE",
    value: "D-MAX SPACECAB HI-LANDER 3.0D Z-PRETIGE"
  },
  {
    SERIAL: "05PA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0D Z",
    label: "D-MAX CAB4 4WD 3.0D Z",
    value: "D-MAX CAB4 4WD 3.0D Z"
  },
  {
    SERIAL: "05PB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD 3.0D Z",
    label: "D-MAX SPACECAB 4WD 3.0D Z",
    value: "D-MAX SPACECAB 4WD 3.0D Z"
  },
  {
    SERIAL: "05PC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 3.0D S",
    label: "D-MAX SPARK 3.0D S",
    value: "D-MAX SPARK 3.0D S"
  },
  {
    SERIAL: "05PD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 4WD 3.0D S",
    label: "D-MAX SPARK 4WD 3.0D S",
    value: "D-MAX SPARK 4WD 3.0D S"
  },
  {
    SERIAL: "05PE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0D Z-PRETIGE",
    label: "D-MAX CAB4 4WD 3.0D Z-PRETIGE",
    value: "D-MAX CAB4 4WD 3.0D Z-PRETIGE"
  },
  {
    SERIAL: "05PF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX 3.0",
    label: "D-MAX 3.0",
    value: "D-MAX 3.0"
  },
  {
    SERIAL: "05PG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 2WD DVD 1.9 A",
    label: "MU-X 2WD DVD 1.9 A",
    value: "MU-X 2WD DVD 1.9 A"
  },
  {
    SERIAL: "05PH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 2WD CD 1.9 A",
    label: "MU-X 2WD CD 1.9 A",
    value: "MU-X 2WD CD 1.9 A"
  },
  {
    SERIAL: "05PI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 2WD DVD 1.9 M",
    label: "MU-X 2WD DVD 1.9 M",
    value: "MU-X 2WD DVD 1.9 M"
  },
  {
    SERIAL: "05PJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 2WD DA DVD NAVIGATOR 1.9 A",
    label: "MU-X 2WD DA DVD NAVIGATOR 1.9 A",
    value: "MU-X 2WD DA DVD NAVIGATOR 1.9 A"
  },
  {
    SERIAL: "05PK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR12 (BUILT)",
    label: "FRR12 (BUILT)",
    value: "FRR12 (BUILT)"
  },
  {
    SERIAL: "05PL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CKD",
    label: "CKD",
    value: "CKD"
  },
  {
    SERIAL: "05PM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NKR57E (BUILT)",
    label: "NKR57E (BUILT)",
    value: "NKR57E (BUILT)"
  },
  {
    SERIAL: "05PN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GXZ360 (หัวลาก 6 ล้อ) CNG",
    label: "GXZ360 (หัวลาก 6 ล้อ) CNG",
    value: "GXZ360 (หัวลาก 6 ล้อ) CNG"
  },
  {
    SERIAL: "05PO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "GXZ360 (หัวลาก 10 ล้อ) CNG",
    label: "GXZ360 (หัวลาก 10 ล้อ) CNG",
    value: "GXZ360 (หัวลาก 10 ล้อ) CNG"
  },
  {
    SERIAL: "05PP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX",
    label: "D-MAX",
    value: "D-MAX"
  },
  {
    SERIAL: "05PQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "05PR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "05PS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "05PU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "BUS (BUILT)",
    label: "BUS (BUILT)",
    value: "BUS (BUILT)"
  },
  {
    SERIAL: "05PV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0D MAXX M",
    label: "D-MAX CAB4 4WD 3.0D MAXX M",
    value: "D-MAX CAB4 4WD 3.0D MAXX M"
  },
  {
    SERIAL: "05PW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0D MAXX A",
    label: "D-MAX CAB4 4WD 3.0D MAXX A",
    value: "D-MAX CAB4 4WD 3.0D MAXX A"
  },
  {
    SERIAL: "05PX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9D Z-PRETIGE A",
    label: "D-MAX CAB4 HI-LANDER 1.9D Z-PRETIGE A",
    value: "D-MAX CAB4 HI-LANDER 1.9D Z-PRETIGE A"
  },
  {
    SERIAL: "05PY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXM23 RENOVATE",
    label: "CXM23 RENOVATE",
    value: "CXM23 RENOVATE"
  },
  {
    SERIAL: "05PZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FRR32 (BUILT)",
    label: "FRR32 (BUILT)",
    value: "FRR32 (BUILT)"
  },
  {
    SERIAL: "05QA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "12 ตัน - BUILT",
    label: "12 ตัน - BUILT",
    value: "12 ตัน - BUILT"
  },
  {
    SERIAL: "05QB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR71PY52A3",
    label: "NPR71PY52A3",
    value: "NPR71PY52A3"
  },
  {
    SERIAL: "05QC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM33N2",
    label: "FVM33N2",
    value: "FVM33N2"
  },
  {
    SERIAL: "05QD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER STEALTH 1.9 Z-PRET IGE A.",
    label: "D-MAX CAB4 HI-LANDER STEALTH 1.9 Z-PRET IGE A.",
    value: "D-MAX CAB4 HI-LANDER STEALTH 1.9 Z-PRET IGE A."
  },
  {
    SERIAL: "05QE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER STEALTH 1.9 Z-PRET IGE M.",
    label: "D-MAX CAB4 HI-LANDER STEALTH 1.9 Z-PRET IGE M.",
    value: "D-MAX CAB4 HI-LANDER STEALTH 1.9 Z-PRET IGE M."
  },
  {
    SERIAL: "05QF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X THE ICONIC 1.9",
    label: "MU-X THE ICONIC 1.9",
    value: "MU-X THE ICONIC 1.9"
  },
  {
    SERIAL: "05QG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM240",
    label: "FVM240",
    value: "FVM240"
  },
  {
    SERIAL: "05QH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X THE ONYX 1.9 VGS TURBO",
    label: "MU-X THE ONYX 1.9 VGS TURBO",
    value: "MU-X THE ONYX 1.9 VGS TURBO"
  },
  {
    SERIAL: "05QI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM34S",
    label: "FVM34S",
    value: "FVM34S"
  },
  {
    SERIAL: "05QJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "FVM34W",
    label: "FVM34W",
    value: "FVM34W"
  },
  {
    SERIAL: "05QK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0 M AUTO",
    label: "D-MAX CAB4 4WD 3.0 M AUTO",
    value: "D-MAX CAB4 4WD 3.0 M AUTO"
  },
  {
    SERIAL: "05QL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0 ZP",
    label: "D-MAX CAB4 4WD 3.0 ZP",
    value: "D-MAX CAB4 4WD 3.0 ZP"
  },
  {
    SERIAL: "05QM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 4WD 3.0 Z",
    label: "D-MAX CAB4 4WD 3.0 Z",
    value: "D-MAX CAB4 4WD 3.0 Z"
  },
  {
    SERIAL: "05QN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 4WD 3.0 Z M",
    label: "D-MAX SPACECAB 4WD 3.0 Z M",
    value: "D-MAX SPACECAB 4WD 3.0 Z M"
  },
  {
    SERIAL: "05QO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 3.0 M AUTO",
    label: "D-MAX CAB4 HI-LANDER 3.0 M AUTO",
    value: "D-MAX CAB4 HI-LANDER 3.0 M AUTO"
  },
  {
    SERIAL: "05QP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 3.0 ZP",
    label: "D-MAX CAB4 HI-LANDER 3.0 ZP",
    value: "D-MAX CAB4 HI-LANDER 3.0 ZP"
  },
  {
    SERIAL: "05QQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9 M AUTO",
    label: "D-MAX CAB4 HI-LANDER 1.9 M AUTO",
    value: "D-MAX CAB4 HI-LANDER 1.9 M AUTO"
  },
  {
    SERIAL: "05QR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9 ZP AUTO",
    label: "D-MAX CAB4 HI-LANDER 1.9 ZP AUTO",
    value: "D-MAX CAB4 HI-LANDER 1.9 ZP AUTO"
  },
  {
    SERIAL: "05QS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9 ZP",
    label: "D-MAX CAB4 HI-LANDER 1.9 ZP",
    value: "D-MAX CAB4 HI-LANDER 1.9 ZP"
  },
  {
    SERIAL: "05QT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9 Z AUTO",
    label: "D-MAX CAB4 HI-LANDER 1.9 Z AUTO",
    value: "D-MAX CAB4 HI-LANDER 1.9 Z AUTO"
  },
  {
    SERIAL: "05QU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9 Z",
    label: "D-MAX CAB4 HI-LANDER 1.9 Z",
    value: "D-MAX CAB4 HI-LANDER 1.9 Z"
  },
  {
    SERIAL: "05QV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9 L DA",
    label: "D-MAX CAB4 HI-LANDER 1.9 L DA",
    value: "D-MAX CAB4 HI-LANDER 1.9 L DA"
  },
  {
    SERIAL: "05QW0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 HI-LANDER 1.9 L",
    label: "D-MAX CAB4 HI-LANDER 1.9 L",
    value: "D-MAX CAB4 HI-LANDER 1.9 L"
  },
  {
    SERIAL: "05QX0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 1.9 Z",
    label: "D-MAX CAB4 1.9 Z",
    value: "D-MAX CAB4 1.9 Z"
  },
  {
    SERIAL: "05QY0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 1.9 L DA",
    label: "D-MAX CAB4 1.9 L DA",
    value: "D-MAX CAB4 1.9 L DA"
  },
  {
    SERIAL: "05QZ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 1.9 L",
    label: "D-MAX CAB4 1.9 L",
    value: "D-MAX CAB4 1.9 L"
  },
  {
    SERIAL: "05RA0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX CAB4 1.9 S",
    label: "D-MAX CAB4 1.9 S",
    value: "D-MAX CAB4 1.9 S"
  },
  {
    SERIAL: "05RB0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 3.0 ZP",
    label: "D-MAX SPACECAB HI-LANDER 3.0 ZP",
    value: "D-MAX SPACECAB HI-LANDER 3.0 ZP"
  },
  {
    SERIAL: "05RC0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9 ZP AUTO",
    label: "D-MAX SPACECAB HI-LANDER 1.9 ZP AUTO",
    value: "D-MAX SPACECAB HI-LANDER 1.9 ZP AUTO"
  },
  {
    SERIAL: "05RD0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9 ZP",
    label: "D-MAX SPACECAB HI-LANDER 1.9 ZP",
    value: "D-MAX SPACECAB HI-LANDER 1.9 ZP"
  },
  {
    SERIAL: "05RE0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9 Z AUTO",
    label: "D-MAX SPACECAB HI-LANDER 1.9 Z AUTO",
    value: "D-MAX SPACECAB HI-LANDER 1.9 Z AUTO"
  },
  {
    SERIAL: "05RF0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9 Z",
    label: "D-MAX SPACECAB HI-LANDER 1.9 Z",
    value: "D-MAX SPACECAB HI-LANDER 1.9 Z"
  },
  {
    SERIAL: "05RG0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9 L DA",
    label: "D-MAX SPACECAB HI-LANDER 1.9 L DA",
    value: "D-MAX SPACECAB HI-LANDER 1.9 L DA"
  },
  {
    SERIAL: "05RH0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB HI-LANDER 1.9 L",
    label: "D-MAX SPACECAB HI-LANDER 1.9 L",
    value: "D-MAX SPACECAB HI-LANDER 1.9 L"
  },
  {
    SERIAL: "05RI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 1.9 Z",
    label: "D-MAX SPACECAB 1.9 Z",
    value: "D-MAX SPACECAB 1.9 Z"
  },
  {
    SERIAL: "05RJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 1.9 L DA",
    label: "D-MAX SPACECAB 1.9 L DA",
    value: "D-MAX SPACECAB 1.9 L DA"
  },
  {
    SERIAL: "05RK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 1.9 L",
    label: "D-MAX SPACECAB 1.9 L",
    value: "D-MAX SPACECAB 1.9 L"
  },
  {
    SERIAL: "05RL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 3.0 S",
    label: "D-MAX SPACECAB 3.0 S",
    value: "D-MAX SPACECAB 3.0 S"
  },
  {
    SERIAL: "05RM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPACECAB 1.9 S",
    label: "D-MAX SPACECAB 1.9 S",
    value: "D-MAX SPACECAB 1.9 S"
  },
  {
    SERIAL: "05RN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 4WD 3.0 S",
    label: "D-MAX SPARK 4WD 3.0 S",
    value: "D-MAX SPARK 4WD 3.0 S"
  },
  {
    SERIAL: "05RO0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 3.0 S",
    label: "D-MAX SPARK 3.0 S",
    value: "D-MAX SPARK 3.0 S"
  },
  {
    SERIAL: "05RP0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 1.9 S",
    label: "D-MAX SPARK 1.9 S",
    value: "D-MAX SPARK 1.9 S"
  },
  {
    SERIAL: "05RQ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 1.9 B",
    label: "D-MAX SPARK 1.9 B",
    value: "D-MAX SPARK 1.9 B"
  },
  {
    SERIAL: "05RR0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "D-MAX SPARK 1.9 CHASIS",
    label: "D-MAX SPARK 1.9 CHASIS",
    value: "D-MAX SPARK 1.9 CHASIS"
  },
  {
    SERIAL: "05RS0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "CXG23 (BUILT)",
    label: "CXG23 (BUILT)",
    value: "CXG23 (BUILT)"
  },
  {
    SERIAL: "05RT0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NPR71PY52Y",
    label: "NPR71PY52Y",
    value: "NPR71PY52Y"
  },
  {
    SERIAL: "05RU0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "NLR77",
    label: "NLR77",
    value: "NLR77"
  },
  {
    SERIAL: "05RV0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 1.9 ACTIVE",
    label: "MU-X 1.9 ACTIVE",
    value: "MU-X 1.9 ACTIVE"
  },
  {
    SERIAL: "05SI0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 3.0 ULTIMATE 4WD",
    label: "MU-X 3.0 ULTIMATE 4WD",
    value: "MU-X 3.0 ULTIMATE 4WD"
  },
  {
    SERIAL: "05SJ0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 1.9 LUXURY",
    label: "MU-X 1.9 LUXURY",
    value: "MU-X 1.9 LUXURY"
  },
  {
    SERIAL: "05SK0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 1.9 LUXURY A",
    label: "MU-X 1.9 LUXURY A",
    value: "MU-X 1.9 LUXURY A"
  },
  {
    SERIAL: "05SL0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 1.9 ELEGANT",
    label: "MU-X 1.9 ELEGANT",
    value: "MU-X 1.9 ELEGANT"
  },
  {
    SERIAL: "05SM0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 1.9 ULTIMATE",
    label: "MU-X 1.9 ULTIMATE",
    value: "MU-X 1.9 ULTIMATE"
  },
  {
    SERIAL: "05SN0000026",
    MAKE_NAME: "ISUZU",
    MODEL_NAME: "MU-X 3.0 ULTIMATE",
    label: "MU-X 3.0 ULTIMATE",
    value: "MU-X 3.0 ULTIMATE"
  },
  {
    SERIAL: "6000000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "MAZDA",
    label: "MAZDA",
    value: "MAZDA"
  },
  {
    SERIAL: "6010000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "121 1.3",
    label: "121 1.3",
    value: "121 1.3"
  },
  {
    SERIAL: "6050000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 ASTINA 1.8 5Dr.",
    label: "323 ASTINA 1.8 5Dr.",
    value: "323 ASTINA 1.8 5Dr."
  },
  {
    SERIAL: "6060000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 SEDAN GLX 1.6",
    label: "323 SEDAN GLX 1.6",
    value: "323 SEDAN GLX 1.6"
  },
  {
    SERIAL: "6080000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 SEDAN New M",
    label: "323 SEDAN New M",
    value: "323 SEDAN New M"
  },
  {
    SERIAL: "6090000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 SEDAN GLX 1.6 A",
    label: "323 SEDAN GLX 1.6 A",
    value: "323 SEDAN GLX 1.6 A"
  },
  {
    SERIAL: "6100000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "ASTINA M (NEW)",
    label: "ASTINA M (NEW)",
    value: "ASTINA M (NEW)"
  },
  {
    SERIAL: "6110000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "ASTINA A (NEW)",
    label: "ASTINA A (NEW)",
    value: "ASTINA A (NEW)"
  },
  {
    SERIAL: "6120000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "626 1.8",
    label: "626 1.8",
    value: "626 1.8"
  },
  {
    SERIAL: "6130000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "626 2.0 5Dr.",
    label: "626 2.0 5Dr.",
    value: "626 2.0 5Dr."
  },
  {
    SERIAL: "6150000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CRONOS 626 5Dr.2.0 M",
    label: "CRONOS 626 5Dr.2.0 M",
    value: "CRONOS 626 5Dr.2.0 M"
  },
  {
    SERIAL: "6160000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CRONOS 626 5Dr.2.0 A",
    label: "CRONOS 626 5Dr.2.0 A",
    value: "CRONOS 626 5Dr.2.0 A"
  },
  {
    SERIAL: "6170000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CRONOS 626 4Dr.2.0 M",
    label: "CRONOS 626 4Dr.2.0 M",
    value: "CRONOS 626 4Dr.2.0 M"
  },
  {
    SERIAL: "6180000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CRONOS 626 4Dr.2.0 A",
    label: "CRONOS 626 4Dr.2.0 A",
    value: "CRONOS 626 4Dr.2.0 A"
  },
  {
    SERIAL: "6210000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "LANTIS",
    label: "LANTIS",
    value: "LANTIS"
  },
  {
    SERIAL: "6220000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "LANTIS 1.8 M",
    label: "LANTIS 1.8 M",
    value: "LANTIS 1.8 M"
  },
  {
    SERIAL: "6230000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "LANTIS 1.8 A",
    label: "LANTIS 1.8 A",
    value: "LANTIS 1.8 A"
  },
  {
    SERIAL: "6250000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "LANTIS 2.0 V6 A",
    label: "LANTIS 2.0 V6 A",
    value: "LANTIS 2.0 V6 A"
  },
  {
    SERIAL: "6280000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "929 V6 3.0",
    label: "929 V6 3.0",
    value: "929 V6 3.0"
  },
  {
    SERIAL: "6290000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "RX-7",
    label: "RX-7",
    value: "RX-7"
  },
  {
    SERIAL: "6300000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "MX-5 1.8 M",
    label: "MX-5 1.8 M",
    value: "MX-5 1.8 M"
  },
  {
    SERIAL: "6310000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "MX-5 1.8 ABS",
    label: "MX-5 1.8 ABS",
    value: "MX-5 1.8 ABS"
  },
  {
    SERIAL: "6320000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FAMILIA",
    label: "FAMILIA",
    value: "FAMILIA"
  },
  {
    SERIAL: "6330000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FAMILIA 1300",
    label: "FAMILIA 1300",
    value: "FAMILIA 1300"
  },
  {
    SERIAL: "6340000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FAMILIA 1400",
    label: "FAMILIA 1400",
    value: "FAMILIA 1400"
  },
  {
    SERIAL: "6350000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FAMILIA MAXI CAB",
    label: "FAMILIA MAXI CAB",
    value: "FAMILIA MAXI CAB"
  },
  {
    SERIAL: "6360000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "THUNDER B 2500",
    label: "THUNDER B 2500",
    value: "THUNDER B 2500"
  },
  {
    SERIAL: "6370000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "THUNDER CAB B 2500",
    label: "THUNDER CAB B 2500",
    value: "THUNDER CAB B 2500"
  },
  {
    SERIAL: "6380000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "MX-5",
    label: "MX-5",
    value: "MX-5"
  },
  {
    SERIAL: "6390000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "B 2500",
    label: "B 2500",
    value: "B 2500"
  },
  {
    SERIAL: "6400000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "626 GLX",
    label: "626 GLX",
    value: "626 GLX"
  },
  {
    SERIAL: "6410000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER SUPER SALOON CAB 2.5",
    label: "FIGHTER SUPER SALOON CAB 2.5",
    value: "FIGHTER SUPER SALOON CAB 2.5"
  },
  {
    SERIAL: "6420000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER STD 2.5 WL",
    label: "FIGHTER STD 2.5 WL",
    value: "FIGHTER STD 2.5 WL"
  },
  {
    SERIAL: "6430000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER LUXURY",
    label: "FIGHTER LUXURY",
    value: "FIGHTER LUXURY"
  },
  {
    SERIAL: "6440000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER 4WD",
    label: "FIGHTER 4WD",
    value: "FIGHTER 4WD"
  },
  {
    SERIAL: "6500000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 PROTEGE LX 1.6 M",
    label: "323 PROTEGE LX 1.6 M",
    value: "323 PROTEGE LX 1.6 M"
  },
  {
    SERIAL: "6510000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 PROTEGE LX 1.6 A",
    label: "323 PROTEGE LX 1.6 A",
    value: "323 PROTEGE LX 1.6 A"
  },
  {
    SERIAL: "6520000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 PROTEGE GLX 1.6 M",
    label: "323 PROTEGE GLX 1.6 M",
    value: "323 PROTEGE GLX 1.6 M"
  },
  {
    SERIAL: "6530000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 PROTEGE GLX 1.6 A",
    label: "323 PROTEGE GLX 1.6 A",
    value: "323 PROTEGE GLX 1.6 A"
  },
  {
    SERIAL: "6540000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 PROTEGE GT 1.8 M",
    label: "323 PROTEGE GT 1.8 M",
    value: "323 PROTEGE GT 1.8 M"
  },
  {
    SERIAL: "6550000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 PROTEGE GT 1.8 A",
    label: "323 PROTEGE GT 1.8 A",
    value: "323 PROTEGE GT 1.8 A"
  },
  {
    SERIAL: "6580000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER CAB 2.5 4WD",
    label: "FIGHTER CAB 2.5 4WD",
    value: "FIGHTER CAB 2.5 4WD"
  },
  {
    SERIAL: "6590000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER",
    label: "FIGHTER",
    value: "FIGHTER"
  },
  {
    SERIAL: "6600000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER STR (CAB) 2.5",
    label: "FIGHTER STR (CAB) 2.5",
    value: "FIGHTER STR (CAB) 2.5"
  },
  {
    SERIAL: "6610000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER CAB 2.9",
    label: "FIGHTER CAB 2.9",
    value: "FIGHTER CAB 2.9"
  },
  {
    SERIAL: "6620000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER CAB 2.9 4WD",
    label: "FIGHTER CAB 2.9 4WD",
    value: "FIGHTER CAB 2.9 4WD"
  },
  {
    SERIAL: "6630000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLE CAB 2.9",
    label: "FIGHTER DOUBLE CAB 2.9",
    value: "FIGHTER DOUBLE CAB 2.9"
  },
  {
    SERIAL: "6640000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLE CAB MID 2.5 WL",
    label: "FIGHTER DOUBLE CAB MID 2.5 WL",
    value: "FIGHTER DOUBLE CAB MID 2.5 WL"
  },
  {
    SERIAL: "6670000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER STD 2.9",
    label: "FIGHTER STD 2.9",
    value: "FIGHTER STD 2.9"
  },
  {
    SERIAL: "6680000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "323 PROTEGE 2.0 GT",
    label: "323 PROTEGE 2.0 GT",
    value: "323 PROTEGE 2.0 GT"
  },
  {
    SERIAL: "6690000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FREESTYLE CAB WL 2.5",
    label: "FREESTYLE CAB WL 2.5",
    value: "FREESTYLE CAB WL 2.5"
  },
  {
    SERIAL: "6700000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FREESTYLE CAB 4*2 LUX W9 2.9",
    label: "FREESTYLE CAB 4*2 LUX W9 2.9",
    value: "FREESTYLE CAB 4*2 LUX W9 2.9"
  },
  {
    SERIAL: "6710000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FREESTYLE CAB 4*2 LUX WLT 2.5",
    label: "FREESTYLE CAB 4*2 LUX WLT 2.5",
    value: "FREESTYLE CAB 4*2 LUX WLT 2.5"
  },
  {
    SERIAL: "6720000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FREESTYLE CAB 4*4 LUX WLT 2.5",
    label: "FREESTYLE CAB 4*4 LUX WLT 2.5",
    value: "FREESTYLE CAB 4*4 LUX WLT 2.5"
  },
  {
    SERIAL: "6730000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FREESTYLE CAB 4*4 LUX WLT 2.5 AIRBAG",
    label: "FREESTYLE CAB 4*4 LUX WLT 2.5 AIRBAG",
    value: "FREESTYLE CAB 4*4 LUX WLT 2.5 AIRBAG"
  },
  {
    SERIAL: "6740000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FREESTYLE CAB 4*4 LUX W9 2.9",
    label: "FREESTYLE CAB 4*4 LUX W9 2.9",
    value: "FREESTYLE CAB 4*4 LUX W9 2.9"
  },
  {
    SERIAL: "6750000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER SUPER SALOON 2.9",
    label: "FIGHTER SUPER SALOON 2.9",
    value: "FIGHTER SUPER SALOON 2.9"
  },
  {
    SERIAL: "6760000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER CAB SUPER SALOON ABS",
    label: "FIGHTER CAB SUPER SALOON ABS",
    value: "FIGHTER CAB SUPER SALOON ABS"
  },
  {
    SERIAL: "6770000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER CAB SUPER SALOON 4*4 2.5",
    label: "FIGHTER CAB SUPER SALOON 4*4 2.5",
    value: "FIGHTER CAB SUPER SALOON 4*4 2.5"
  },
  {
    SERIAL: "6780000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER CAB SUPER SALOON 4*4 2.9",
    label: "FIGHTER CAB SUPER SALOON 4*4 2.9",
    value: "FIGHTER CAB SUPER SALOON 4*4 2.9"
  },
  {
    SERIAL: "6790000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "MPV 3.0",
    label: "MPV 3.0",
    value: "MPV 3.0"
  },
  {
    SERIAL: "6800000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "TRIBUTE 2.0",
    label: "TRIBUTE 2.0",
    value: "TRIBUTE 2.0"
  },
  {
    SERIAL: "6810000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER STD 2.5",
    label: "FIGHTER STD 2.5",
    value: "FIGHTER STD 2.5"
  },
  {
    SERIAL: "6820000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FREESTYLECAB LEMANS",
    label: "FREESTYLECAB LEMANS",
    value: "FREESTYLECAB LEMANS"
  },
  {
    SERIAL: "6830000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "RX-8 2.6",
    label: "RX-8 2.6",
    value: "RX-8 2.6"
  },
  {
    SERIAL: "6840000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "TRIBUTE 2.3",
    label: "TRIBUTE 2.3",
    value: "TRIBUTE 2.3"
  },
  {
    SERIAL: "6850000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "TRIBUTE 3.0",
    label: "TRIBUTE 3.0",
    value: "TRIBUTE 3.0"
  },
  {
    SERIAL: "6860000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D S.1.6 M",
    label: "3 4D S.1.6 M",
    value: "3 4D S.1.6 M"
  },
  {
    SERIAL: "6870000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D S.1.6 A",
    label: "3 4D S.1.6 A",
    value: "3 4D S.1.6 A"
  },
  {
    SERIAL: "6880000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D V.1.6 A",
    label: "3 4D V.1.6 A",
    value: "3 4D V.1.6 A"
  },
  {
    SERIAL: "6890000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D R.2.0 A",
    label: "3 4D R.2.0 A",
    value: "3 4D R.2.0 A"
  },
  {
    SERIAL: "6900000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D R.2.0 A",
    label: "3 5D R.2.0 A",
    value: "3 5D R.2.0 A"
  },
  {
    SERIAL: "6910000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D R.2.0 A MOONROOF",
    label: "3 5D R.2.0 A MOONROOF",
    value: "3 5D R.2.0 A MOONROOF"
  },
  {
    SERIAL: "6920000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLECAB 4*4 2.5 ABS AIRBAG",
    label: "FIGHTER DOUBLECAB 4*4 2.5 ABS AIRBAG",
    value: "FIGHTER DOUBLECAB 4*4 2.5 ABS AIRBAG"
  },
  {
    SERIAL: "6930000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLECAB LUX PLUS WLT 4*4 2.5",
    label: "FIGHTER DOUBLECAB LUX PLUS WLT 4*4 2.5",
    value: "FIGHTER DOUBLECAB LUX PLUS WLT 4*4 2.5"
  },
  {
    SERIAL: "6940000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "626",
    label: "626",
    value: "626"
  },
  {
    SERIAL: "6950000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLECAB LUX 2.5 WL",
    label: "FIGHTER DOUBLECAB LUX 2.5 WL",
    value: "FIGHTER DOUBLECAB LUX 2.5 WL"
  },
  {
    SERIAL: "6960000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLECAB LUX PLUS WLT ABS/TT",
    label: "FIGHTER DOUBLECAB LUX PLUS WLT ABS/TT",
    value: "FIGHTER DOUBLECAB LUX PLUS WLT ABS/TT"
  },
  {
    SERIAL: "6970000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLECAB LUX PLUS WLT ABS/TT4*4",
    label: "FIGHTER DOUBLECAB LUX PLUS WLT ABS/TT4*4",
    value: "FIGHTER DOUBLECAB LUX PLUS WLT ABS/TT4*4"
  },
  {
    SERIAL: "6990000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "MX-5 (Y.2007)",
    label: "MX-5 (Y.2007)",
    value: "MX-5 (Y.2007)"
  },
  {
    SERIAL: "06AA0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D R.2.0 A SPORT",
    label: "3 5D R.2.0 A SPORT",
    value: "3 5D R.2.0 A SPORT"
  },
  {
    SERIAL: "06AB0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 STANDARD 2.5 S",
    label: "BT-50 STANDARD 2.5 S",
    value: "BT-50 STANDARD 2.5 S"
  },
  {
    SERIAL: "06AC0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 STANDARD COMMONRAIL 2.5 POWER S",
    label: "BT-50 STANDARD COMMONRAIL 2.5 POWER S",
    value: "BT-50 STANDARD COMMONRAIL 2.5 POWER S"
  },
  {
    SERIAL: "06AD0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 2.5 S",
    label: "BT-50 FREE S CAB 2.5 S",
    value: "BT-50 FREE S CAB 2.5 S"
  },
  {
    SERIAL: "06AE0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 2.5 V MAX",
    label: "BT-50 FREE S CAB 2.5 V MAX",
    value: "BT-50 FREE S CAB 2.5 V MAX"
  },
  {
    SERIAL: "06AF0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 2.5 V M",
    label: "BT-50 FREE S CAB 2.5 V M",
    value: "BT-50 FREE S CAB 2.5 V M"
  },
  {
    SERIAL: "06AG0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 4WD 3.0 R MAX 2TP",
    label: "BT-50 FREE S CAB 4WD 3.0 R MAX 2TP",
    value: "BT-50 FREE S CAB 4WD 3.0 R MAX 2TP"
  },
  {
    SERIAL: "06AH0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 2.5 V MAX DAB",
    label: "BT-50 FREE S CAB 2.5 V MAX DAB",
    value: "BT-50 FREE S CAB 2.5 V MAX DAB"
  },
  {
    SERIAL: "06AI0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 2.5 HI RACER",
    label: "BT-50 FREE S CAB 2.5 HI RACER",
    value: "BT-50 FREE S CAB 2.5 HI RACER"
  },
  {
    SERIAL: "06AJ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 2.5 HI RACER DAB",
    label: "BT-50 FREE S CAB 2.5 HI RACER DAB",
    value: "BT-50 FREE S CAB 2.5 HI RACER DAB"
  },
  {
    SERIAL: "06AK0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 4WD R 3.0 MAX ABS",
    label: "BT-50 FREE S CAB 4WD R 3.0 MAX ABS",
    value: "BT-50 FREE S CAB 4WD R 3.0 MAX ABS"
  },
  {
    SERIAL: "06AL0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREE S CAB 4WD R 3.0 M,ABS,DAB",
    label: "BT-50 FREE S CAB 4WD R 3.0 M,ABS,DAB",
    value: "BT-50 FREE S CAB 4WD R 3.0 M,ABS,DAB"
  },
  {
    SERIAL: "06AM0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB S 2.5 M",
    label: "BT-50 DOUBLE CAB S 2.5 M",
    value: "BT-50 DOUBLE CAB S 2.5 M"
  },
  {
    SERIAL: "06AN0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 2.5 V.MAX ABS",
    label: "BT-50 DOUBLE CAB 2.5 V.MAX ABS",
    value: "BT-50 DOUBLE CAB 2.5 V.MAX ABS"
  },
  {
    SERIAL: "06AO0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 4WD R 3.0 M,ABS",
    label: "BT-50 DOUBLE CAB 4WD R 3.0 M,ABS",
    value: "BT-50 DOUBLE CAB 4WD R 3.0 M,ABS"
  },
  {
    SERIAL: "06AP0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D GROOVE 1.6",
    label: "3 4D GROOVE 1.6",
    value: "3 4D GROOVE 1.6"
  },
  {
    SERIAL: "06AQ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D SPIRIT 1.6",
    label: "3 4D SPIRIT 1.6",
    value: "3 4D SPIRIT 1.6"
  },
  {
    SERIAL: "06AR0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D MAXX 2.0 CBU",
    label: "3 4D MAXX 2.0 CBU",
    value: "3 4D MAXX 2.0 CBU"
  },
  {
    SERIAL: "06AS0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D SPIRIT SPORT 1.6",
    label: "3 5D SPIRIT SPORT 1.6",
    value: "3 5D SPIRIT SPORT 1.6"
  },
  {
    SERIAL: "06AT0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D MAXX SPORT SUNROOF 2.0",
    label: "3 5D MAXX SPORT SUNROOF 2.0",
    value: "3 5D MAXX SPORT SUNROOF 2.0"
  },
  {
    SERIAL: "06AU0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 GROOVE SPORT 1.5 MT 5D",
    label: "2 GROOVE SPORT 1.5 MT 5D",
    value: "2 GROOVE SPORT 1.5 MT 5D"
  },
  {
    SERIAL: "06AV0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 GROOVE SPORT 1.5 AT 5D",
    label: "2 GROOVE SPORT 1.5 AT 5D",
    value: "2 GROOVE SPORT 1.5 AT 5D"
  },
  {
    SERIAL: "06AW0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 SPIRIT SPORT 1.5 AT 5D.",
    label: "2 SPIRIT SPORT 1.5 AT 5D.",
    value: "2 SPIRIT SPORT 1.5 AT 5D."
  },
  {
    SERIAL: "06AX0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 MAXX SPORT 1.5 AT 5D",
    label: "2 MAXX SPORT 1.5 AT 5D",
    value: "2 MAXX SPORT 1.5 AT 5D"
  },
  {
    SERIAL: "06AY0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-9",
    label: "CX-9",
    value: "CX-9"
  },
  {
    SERIAL: "06AZ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 GROOVE 1.5 MT 4D",
    label: "2 GROOVE 1.5 MT 4D",
    value: "2 GROOVE 1.5 MT 4D"
  },
  {
    SERIAL: "06BA0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 GROOVE 1.5 AT 4D",
    label: "2 GROOVE 1.5 AT 4D",
    value: "2 GROOVE 1.5 AT 4D"
  },
  {
    SERIAL: "06BB0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 SPIRIT SPORT 1.5 AT 4D",
    label: "2 SPIRIT SPORT 1.5 AT 4D",
    value: "2 SPIRIT SPORT 1.5 AT 4D"
  },
  {
    SERIAL: "06BC0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 MAXX 1.5 AT 4D",
    label: "2 MAXX 1.5 AT 4D",
    value: "2 MAXX 1.5 AT 4D"
  },
  {
    SERIAL: "06BD0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-7",
    label: "CX-7",
    value: "CX-7"
  },
  {
    SERIAL: "06BF0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "RX-8 1.3",
    label: "RX-8 1.3",
    value: "RX-8 1.3"
  },
  {
    SERIAL: "06BG0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "MX5 ROADSTER",
    label: "MX5 ROADSTER",
    value: "MX5 ROADSTER"
  },
  {
    SERIAL: "06BH0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D PLAY 2.0",
    label: "3 4D PLAY 2.0",
    value: "3 4D PLAY 2.0"
  },
  {
    SERIAL: "06BI0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D PLAY 2.0",
    label: "3 5D PLAY 2.0",
    value: "3 5D PLAY 2.0"
  },
  {
    SERIAL: "06BJ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 2.5 HI RACER ABS",
    label: "BT-50 DOUBLE CAB 2.5 HI RACER ABS",
    value: "BT-50 DOUBLE CAB 2.5 HI RACER ABS"
  },
  {
    SERIAL: "06BK0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 2.5 HI RACER ABS DAB S",
    label: "BT-50 DOUBLE CAB 2.5 HI RACER ABS DAB S",
    value: "BT-50 DOUBLE CAB 2.5 HI RACER ABS DAB S"
  },
  {
    SERIAL: "06BL0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "RX-8 1.3",
    label: "RX-8 1.3",
    value: "RX-8 1.3"
  },
  {
    SERIAL: "06BM0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 1.6",
    label: "3 4D 1.6",
    value: "3 4D 1.6"
  },
  {
    SERIAL: "06BN0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D MAXX 2.0 CKD",
    label: "3 4D MAXX 2.0 CKD",
    value: "3 4D MAXX 2.0 CKD"
  },
  {
    SERIAL: "06BO0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D MAXX 2.0",
    label: "3 5D MAXX 2.0",
    value: "3 5D MAXX 2.0"
  },
  {
    SERIAL: "06BQ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLE 4WD 3.2",
    label: "BT-50 PRO DOUBLE 4WD 3.2",
    value: "BT-50 PRO DOUBLE 4WD 3.2"
  },
  {
    SERIAL: "06BR0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D SPORT AT 1.6",
    label: "3 5D SPORT AT 1.6",
    value: "3 5D SPORT AT 1.6"
  },
  {
    SERIAL: "06BS0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREESTYLE CAB HIRACER 2.2",
    label: "BT-50 FREESTYLE CAB HIRACER 2.2",
    value: "BT-50 FREESTYLE CAB HIRACER 2.2"
  },
  {
    SERIAL: "06BT0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREE-CAB S 2.2",
    label: "BT-50 PRO FREE-CAB S 2.2",
    value: "BT-50 PRO FREE-CAB S 2.2"
  },
  {
    SERIAL: "06BU0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREE-CAB V 2.2",
    label: "BT-50 PRO FREE-CAB V 2.2",
    value: "BT-50 PRO FREE-CAB V 2.2"
  },
  {
    SERIAL: "06BV0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREE-CAB V ABS 2.2",
    label: "BT-50 PRO FREE-CAB V ABS 2.2",
    value: "BT-50 PRO FREE-CAB V ABS 2.2"
  },
  {
    SERIAL: "06BW0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREE-CAB R 4WD 3.2",
    label: "BT-50 PRO FREE-CAB R 4WD 3.2",
    value: "BT-50 PRO FREE-CAB R 4WD 3.2"
  },
  {
    SERIAL: "06BX0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREE-CAB HI-RACER 2.2",
    label: "BT-50 PRO FREE-CAB HI-RACER 2.2",
    value: "BT-50 PRO FREE-CAB HI-RACER 2.2"
  },
  {
    SERIAL: "06BY0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREE-CAB HI-RACER 2.2",
    label: "BT-50 PRO FREE-CAB HI-RACER 2.2",
    value: "BT-50 PRO FREE-CAB HI-RACER 2.2"
  },
  {
    SERIAL: "06BZ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREE-CAB HI-RACER 2.2 ABS",
    label: "BT-50 PRO FREE-CAB HI-RACER 2.2 ABS",
    value: "BT-50 PRO FREE-CAB HI-RACER 2.2 ABS"
  },
  {
    SERIAL: "06CA0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BIANTE 2.0",
    label: "BIANTE 2.0",
    value: "BIANTE 2.0"
  },
  {
    SERIAL: "06CB0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB S 2.2",
    label: "BT-50 PRO DOUBLECAB S 2.2",
    value: "BT-50 PRO DOUBLECAB S 2.2"
  },
  {
    SERIAL: "06CC0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB V 2.2",
    label: "BT-50 PRO DOUBLECAB V 2.2",
    value: "BT-50 PRO DOUBLECAB V 2.2"
  },
  {
    SERIAL: "06CD0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB V ABS 2.2",
    label: "BT-50 PRO DOUBLECAB V ABS 2.2",
    value: "BT-50 PRO DOUBLECAB V ABS 2.2"
  },
  {
    SERIAL: "06CE0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB HI-RACER 2.2",
    label: "BT-50 PRO DOUBLECAB HI-RACER 2.2",
    value: "BT-50 PRO DOUBLECAB HI-RACER 2.2"
  },
  {
    SERIAL: "06CF0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB HI-RACER ABS 2.2 V",
    label: "BT-50 PRO DOUBLECAB HI-RACER ABS 2.2 V",
    value: "BT-50 PRO DOUBLECAB HI-RACER ABS 2.2 V"
  },
  {
    SERIAL: "06CG0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 ELEGANCE SPIRIT 4D (AT)",
    label: "2 ELEGANCE SPIRIT 4D (AT)",
    value: "2 ELEGANCE SPIRIT 4D (AT)"
  },
  {
    SERIAL: "06CI0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.0 S",
    label: "CX-5 2.0 S",
    value: "CX-5 2.0 S"
  },
  {
    SERIAL: "06CJ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.5 S",
    label: "CX-5 2.5 S",
    value: "CX-5 2.5 S"
  },
  {
    SERIAL: "06CK0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.2 XDL AWD",
    label: "CX-5 2.2 XDL AWD",
    value: "CX-5 2.2 XDL AWD"
  },
  {
    SERIAL: "06CL0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 2.0 E",
    label: "3 4D 2.0 E",
    value: "3 4D 2.0 E"
  },
  {
    SERIAL: "06CM0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 2.0 C",
    label: "3 4D 2.0 C",
    value: "3 4D 2.0 C"
  },
  {
    SERIAL: "06CN0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 2.0 S",
    label: "3 4D 2.0 S",
    value: "3 4D 2.0 S"
  },
  {
    SERIAL: "06CO0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D 2.0 E SPORT",
    label: "3 5D 2.0 E SPORT",
    value: "3 5D 2.0 E SPORT"
  },
  {
    SERIAL: "06CP0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D 2.0 C SPORT",
    label: "3 5D 2.0 C SPORT",
    value: "3 5D 2.0 C SPORT"
  },
  {
    SERIAL: "06CQ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D 2.0 S SPORT",
    label: "3 5D 2.0 S SPORT",
    value: "3 5D 2.0 S SPORT"
  },
  {
    SERIAL: "06CR0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D 2.0 SP SPORT",
    label: "3 5D 2.0 SP SPORT",
    value: "3 5D 2.0 SP SPORT"
  },
  {
    SERIAL: "06CS0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.0 C",
    label: "CX-5 2.0 C",
    value: "CX-5 2.0 C"
  },
  {
    SERIAL: "06CT0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLECAB R 4WD 3.0 ABS DAB SAB",
    label: "BT-50 DOUBLECAB R 4WD 3.0 ABS DAB SAB",
    value: "BT-50 DOUBLECAB R 4WD 3.0 ABS DAB SAB"
  },
  {
    SERIAL: "06CU0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLECAB V 2.5 A ABS",
    label: "BT-50 DOUBLECAB V 2.5 A ABS",
    value: "BT-50 DOUBLECAB V 2.5 A ABS"
  },
  {
    SERIAL: "06CV0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER SUPER SALOON CAB MID 2.5 M PS",
    label: "FIGHTER SUPER SALOON CAB MID 2.5 M PS",
    value: "FIGHTER SUPER SALOON CAB MID 2.5 M PS"
  },
  {
    SERIAL: "06CW0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "FIGHTER DOUBLECAB LUX 2.5 WLT",
    label: "FIGHTER DOUBLECAB LUX 2.5 WLT",
    value: "FIGHTER DOUBLECAB LUX 2.5 WLT"
  },
  {
    SERIAL: "06CX0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO ECLIPSE FREECAB HIRACER M 2.2 ABS",
    label: "BT-50 PRO ECLIPSE FREECAB HIRACER M 2.2 ABS",
    value: "BT-50 PRO ECLIPSE FREECAB HIRACER M 2.2 ABS"
  },
  {
    SERIAL: "06CY0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO ECLIPSE DOUBLECAB HI RACER M",
    label: "BT-50 PRO ECLIPSE DOUBLECAB HI RACER M",
    value: "BT-50 PRO ECLIPSE DOUBLECAB HI RACER M"
  },
  {
    SERIAL: "06CZ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO ECLIPSE DOUBLECAB HI RACER A",
    label: "BT-50 PRO ECLIPSE DOUBLECAB HI RACER A",
    value: "BT-50 PRO ECLIPSE DOUBLECAB HI RACER A"
  },
  {
    SERIAL: "06DA0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D XD 1.5 A",
    label: "2 4D XD 1.5 A",
    value: "2 4D XD 1.5 A"
  },
  {
    SERIAL: "06DB0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D XD HIGH 1.5 A",
    label: "2 4D XD HIGH 1.5 A",
    value: "2 4D XD HIGH 1.5 A"
  },
  {
    SERIAL: "06DC0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D XD HIGH PLUS 1.5 A",
    label: "2 4D XD HIGH PLUS 1.5 A",
    value: "2 4D XD HIGH PLUS 1.5 A"
  },
  {
    SERIAL: "06DD0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D XD SPORT 1.5 A",
    label: "2 5D XD SPORT 1.5 A",
    value: "2 5D XD SPORT 1.5 A"
  },
  {
    SERIAL: "06DE0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D XD SPORT HIGH 1.5 A",
    label: "2 5D XD SPORT HIGH 1.5 A",
    value: "2 5D XD SPORT HIGH 1.5 A"
  },
  {
    SERIAL: "06DF0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D XD SPORT HIGH PLUS 1.5 A",
    label: "2 5D XD SPORT HIGH PLUS 1.5 A",
    value: "2 5D XD SPORT HIGH PLUS 1.5 A"
  },
  {
    SERIAL: "06DL0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB HI-RACER 2.2 M ABS",
    label: "BT-50 PRO DOUBLECAB HI-RACER 2.2 M ABS",
    value: "BT-50 PRO DOUBLECAB HI-RACER 2.2 M ABS"
  },
  {
    SERIAL: "06DM0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO SINGLE CAB S 2.5 M",
    label: "BT-50 PRO SINGLE CAB S 2.5 M",
    value: "BT-50 PRO SINGLE CAB S 2.5 M"
  },
  {
    SERIAL: "06DN0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO SINGLE CAB S 2.5 M CNG",
    label: "BT-50 PRO SINGLE CAB S 2.5 M CNG",
    value: "BT-50 PRO SINGLE CAB S 2.5 M CNG"
  },
  {
    SERIAL: "06DO0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREESTYLE CAB S 2.5 M",
    label: "BT-50 PRO FREESTYLE CAB S 2.5 M",
    value: "BT-50 PRO FREESTYLE CAB S 2.5 M"
  },
  {
    SERIAL: "06DP0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO FREESTYLE CAB S 2.5 M CNG",
    label: "BT-50 PRO FREESTYLE CAB S 2.5 M CNG",
    value: "BT-50 PRO FREESTYLE CAB S 2.5 M CNG"
  },
  {
    SERIAL: "06DQ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLE CAB S 2.5 M",
    label: "BT-50 PRO DOUBLE CAB S 2.5 M",
    value: "BT-50 PRO DOUBLE CAB S 2.5 M"
  },
  {
    SERIAL: "06DR0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLE CAB S 2.5 M CNG",
    label: "BT-50 PRO DOUBLE CAB S 2.5 M CNG",
    value: "BT-50 PRO DOUBLE CAB S 2.5 M CNG"
  },
  {
    SERIAL: "06DS0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB R 3.2 A ABS 4WD",
    label: "BT-50 PRO DOUBLECAB R 3.2 A ABS 4WD",
    value: "BT-50 PRO DOUBLECAB R 3.2 A ABS 4WD"
  },
  {
    SERIAL: "06DT0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB R 3.2 M ABS 4WD",
    label: "BT-50 PRO DOUBLECAB R 3.2 M ABS 4WD",
    value: "BT-50 PRO DOUBLECAB R 3.2 M ABS 4WD"
  },
  {
    SERIAL: "06DU0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 STANDARD",
    label: "2 4D 1.3 STANDARD",
    value: "2 4D 1.3 STANDARD"
  },
  {
    SERIAL: "06DV0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 HIGH",
    label: "2 4D 1.3 HIGH",
    value: "2 4D 1.3 HIGH"
  },
  {
    SERIAL: "06DW0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 HIGH PLUS",
    label: "2 4D 1.3 HIGH PLUS",
    value: "2 4D 1.3 HIGH PLUS"
  },
  {
    SERIAL: "06DX0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 SPORT STANDARD",
    label: "2 5D 1.3 SPORT STANDARD",
    value: "2 5D 1.3 SPORT STANDARD"
  },
  {
    SERIAL: "06DY0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 SPORT HIGH",
    label: "2 5D 1.3 SPORT HIGH",
    value: "2 5D 1.3 SPORT HIGH"
  },
  {
    SERIAL: "06DZ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 SPORT HIGH PLUS",
    label: "2 5D 1.3 SPORT HIGH PLUS",
    value: "2 5D 1.3 SPORT HIGH PLUS"
  },
  {
    SERIAL: "06EA0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 E",
    label: "CX-3 2.0 E",
    value: "CX-3 2.0 E"
  },
  {
    SERIAL: "06EB0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 C",
    label: "CX-3 2.0 C",
    value: "CX-3 2.0 C"
  },
  {
    SERIAL: "06EC0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 S",
    label: "CX-3 2.0 S",
    value: "CX-3 2.0 S"
  },
  {
    SERIAL: "06ED0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 SP",
    label: "CX-3 2.0 SP",
    value: "CX-3 2.0 SP"
  },
  {
    SERIAL: "06EE0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 1.5 XDL DIESEL",
    label: "CX-3 1.5 XDL DIESEL",
    value: "CX-3 1.5 XDL DIESEL"
  },
  {
    SERIAL: "06EF0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D XD SPORT HIGH CONNECT 1.5 A",
    label: "2 5D XD SPORT HIGH CONNECT 1.5 A",
    value: "2 5D XD SPORT HIGH CONNECT 1.5 A"
  },
  {
    SERIAL: "06EG0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D XD HIGH CONNECT 1.5 A",
    label: "2 4D XD HIGH CONNECT 1.5 A",
    value: "2 4D XD HIGH CONNECT 1.5 A"
  },
  {
    SERIAL: "06EH0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D XD HIGH PLUS L 1.5 A",
    label: "2 4D XD HIGH PLUS L 1.5 A",
    value: "2 4D XD HIGH PLUS L 1.5 A"
  },
  {
    SERIAL: "06EI0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D XD SPORT HIGH PLUS L 1.5 A",
    label: "2 5D XD SPORT HIGH PLUS L 1.5 A",
    value: "2 5D XD SPORT HIGH PLUS L 1.5 A"
  },
  {
    SERIAL: "06EJ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 HIGH CONNECT A",
    label: "2 4D 1.3 HIGH CONNECT A",
    value: "2 4D 1.3 HIGH CONNECT A"
  },
  {
    SERIAL: "06EK0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 SPORT HIGH CONNECT A",
    label: "2 5D 1.3 SPORT HIGH CONNECT A",
    value: "2 5D 1.3 SPORT HIGH CONNECT A"
  },
  {
    SERIAL: "06EL0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO SINGLE CAB S 2.2 M",
    label: "BT-50 PRO SINGLE CAB S 2.2 M",
    value: "BT-50 PRO SINGLE CAB S 2.2 M"
  },
  {
    SERIAL: "06EM0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 1.5 XDL",
    label: "CX-3 1.5 XDL",
    value: "CX-3 1.5 XDL"
  },
  {
    SERIAL: "06EN0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2",
    label: "2",
    value: "2"
  },
  {
    SERIAL: "06EO0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3",
    label: "3",
    value: "3"
  },
  {
    SERIAL: "06EP0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50",
    label: "BT-50",
    value: "BT-50"
  },
  {
    SERIAL: "06ER0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.2 XD",
    label: "CX-5 2.2 XD",
    value: "CX-5 2.2 XD"
  },
  {
    SERIAL: "06ES0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.0 C",
    label: "CX-5 2.0 C",
    value: "CX-5 2.0 C"
  },
  {
    SERIAL: "06ET0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.0 S",
    label: "CX-5 2.0 S",
    value: "CX-5 2.0 S"
  },
  {
    SERIAL: "06EU0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.0 SP",
    label: "CX-5 2.0 SP",
    value: "CX-5 2.0 SP"
  },
  {
    SERIAL: "06EV0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.2 XDL",
    label: "CX-5 2.2 XDL",
    value: "CX-5 2.2 XDL"
  },
  {
    SERIAL: "06EW0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "6000",
    label: "6000",
    value: "6000"
  },
  {
    SERIAL: "06EX0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 2.0 SP",
    label: "3 4D 2.0 SP",
    value: "3 4D 2.0 SP"
  },
  {
    SERIAL: "06EY0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 SKYACTIV 1.3",
    label: "2 SKYACTIV 1.3",
    value: "2 SKYACTIV 1.3"
  },
  {
    SERIAL: "06EZ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 SKYACTIV 1.5 DIESEL",
    label: "2 SKYACTIV 1.5 DIESEL",
    value: "2 SKYACTIV 1.5 DIESEL"
  },
  {
    SERIAL: "06FA0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-8 2.5 S",
    label: "CX-8 2.5 S",
    value: "CX-8 2.5 S"
  },
  {
    SERIAL: "06FB0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-8 2.5 SP",
    label: "CX-8 2.5 SP",
    value: "CX-8 2.5 SP"
  },
  {
    SERIAL: "06FC0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-8 2.2 XDL",
    label: "CX-8 2.2 XDL",
    value: "CX-8 2.2 XDL"
  },
  {
    SERIAL: "06FD0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-8 2.2 XDL 4WD",
    label: "CX-8 2.2 XDL 4WD",
    value: "CX-8 2.2 XDL 4WD"
  },
  {
    SERIAL: "06FE0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 E",
    label: "2 4D 1.3 E",
    value: "2 4D 1.3 E"
  },
  {
    SERIAL: "06FF0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 C",
    label: "2 4D 1.3 C",
    value: "2 4D 1.3 C"
  },
  {
    SERIAL: "06FG0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 S",
    label: "2 4D 1.3 S",
    value: "2 4D 1.3 S"
  },
  {
    SERIAL: "06FH0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 S LEATHER",
    label: "2 4D 1.3 S LEATHER",
    value: "2 4D 1.3 S LEATHER"
  },
  {
    SERIAL: "06FI0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.3 SP",
    label: "2 4D 1.3 SP",
    value: "2 4D 1.3 SP"
  },
  {
    SERIAL: "06FJ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.5 XD",
    label: "2 4D 1.5 XD",
    value: "2 4D 1.5 XD"
  },
  {
    SERIAL: "06FK0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 4D 1.5 XDL",
    label: "2 4D 1.5 XDL",
    value: "2 4D 1.5 XDL"
  },
  {
    SERIAL: "06FL0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 E",
    label: "2 5D 1.3 E",
    value: "2 5D 1.3 E"
  },
  {
    SERIAL: "06FM0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 C",
    label: "2 5D 1.3 C",
    value: "2 5D 1.3 C"
  },
  {
    SERIAL: "06FN0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 S",
    label: "2 5D 1.3 S",
    value: "2 5D 1.3 S"
  },
  {
    SERIAL: "06FO0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 S LEATHER",
    label: "2 5D 1.3 S LEATHER",
    value: "2 5D 1.3 S LEATHER"
  },
  {
    SERIAL: "06FP0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.3 SP",
    label: "2 5D 1.3 SP",
    value: "2 5D 1.3 SP"
  },
  {
    SERIAL: "06FQ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.5 XD",
    label: "2 5D 1.5 XD",
    value: "2 5D 1.5 XD"
  },
  {
    SERIAL: "06FR0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2 5D 1.5 XDL",
    label: "2 5D 1.5 XDL",
    value: "2 5D 1.5 XDL"
  },
  {
    SERIAL: "06FS0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-5 2.5 TURBO SP",
    label: "CX-5 2.5 TURBO SP",
    value: "CX-5 2.5 TURBO SP"
  },
  {
    SERIAL: "06FT0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "2000",
    label: "2000",
    value: "2000"
  },
  {
    SERIAL: "06FU0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "OVER 2000",
    label: "OVER 2000",
    value: "OVER 2000"
  },
  {
    SERIAL: "06FV0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-30 2.0 C",
    label: "CX-30 2.0 C",
    value: "CX-30 2.0 C"
  },
  {
    SERIAL: "06FW0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-30 2.0 S",
    label: "CX-30 2.0 S",
    value: "CX-30 2.0 S"
  },
  {
    SERIAL: "06FX0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-30 2.0 SP",
    label: "CX-30 2.0 SP",
    value: "CX-30 2.0 SP"
  },
  {
    SERIAL: "06FY0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 2.0 C",
    label: "3 4D 2.0 C",
    value: "3 4D 2.0 C"
  },
  {
    SERIAL: "06FZ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 2.0 S",
    label: "3 4D 2.0 S",
    value: "3 4D 2.0 S"
  },
  {
    SERIAL: "06GA0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 4D 2.0 SP",
    label: "3 4D 2.0 SP",
    value: "3 4D 2.0 SP"
  },
  {
    SERIAL: "06GB0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D 2.0 C",
    label: "3 5D 2.0 C",
    value: "3 5D 2.0 C"
  },
  {
    SERIAL: "06GC0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D 2.0 S",
    label: "3 5D 2.0 S",
    value: "3 5D 2.0 S"
  },
  {
    SERIAL: "06GD0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "3 5D 2.0 SP",
    label: "3 5D 2.0 SP",
    value: "3 5D 2.0 SP"
  },
  {
    SERIAL: "06GE0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 PRO DOUBLECAB HI-RACER 2.2 A LEAT HER",
    label: "BT-50 PRO DOUBLECAB HI-RACER 2.2 A LEAT HER",
    value: "BT-50 PRO DOUBLECAB HI-RACER 2.2 A LEAT HER"
  },
  {
    SERIAL: "06GF0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 BASE",
    label: "CX-3 2.0 BASE",
    value: "CX-3 2.0 BASE"
  },
  {
    SERIAL: "06GG0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 COMFORT",
    label: "CX-3 2.0 COMFORT",
    value: "CX-3 2.0 COMFORT"
  },
  {
    SERIAL: "06GH0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 PROACTIVE",
    label: "CX-3 2.0 PROACTIVE",
    value: "CX-3 2.0 PROACTIVE"
  },
  {
    SERIAL: "06GI0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 STYLE",
    label: "CX-3 2.0 STYLE",
    value: "CX-3 2.0 STYLE"
  },
  {
    SERIAL: "06GJ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 STANDARD CAB 1.9 E",
    label: "BT-50 STANDARD CAB 1.9 E",
    value: "BT-50 STANDARD CAB 1.9 E"
  },
  {
    SERIAL: "06GK0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREESTYLE CAB 1.9 C",
    label: "BT-50 FREESTYLE CAB 1.9 C",
    value: "BT-50 FREESTYLE CAB 1.9 C"
  },
  {
    SERIAL: "06GL0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREESTYLE CAB 1.9 C HI-RACER",
    label: "BT-50 FREESTYLE CAB 1.9 C HI-RACER",
    value: "BT-50 FREESTYLE CAB 1.9 C HI-RACER"
  },
  {
    SERIAL: "06GM0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREESTYLE CAB 1.9 C HI-RACER A",
    label: "BT-50 FREESTYLE CAB 1.9 C HI-RACER A",
    value: "BT-50 FREESTYLE CAB 1.9 C HI-RACER A"
  },
  {
    SERIAL: "06GN0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREESTYLE CAB 1.9 S HI-RACER",
    label: "BT-50 FREESTYLE CAB 1.9 S HI-RACER",
    value: "BT-50 FREESTYLE CAB 1.9 S HI-RACER"
  },
  {
    SERIAL: "06GO0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 FREESTYLE CAB 1.9 S HI-RACER A",
    label: "BT-50 FREESTYLE CAB 1.9 S HI-RACER A",
    value: "BT-50 FREESTYLE CAB 1.9 S HI-RACER A"
  },
  {
    SERIAL: "06GP0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 1.9 C",
    label: "BT-50 DOUBLE CAB 1.9 C",
    value: "BT-50 DOUBLE CAB 1.9 C"
  },
  {
    SERIAL: "06GQ0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 1.9 S",
    label: "BT-50 DOUBLE CAB 1.9 S",
    value: "BT-50 DOUBLE CAB 1.9 S"
  },
  {
    SERIAL: "06GR0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 1.9 S HI-RACER",
    label: "BT-50 DOUBLE CAB 1.9 S HI-RACER",
    value: "BT-50 DOUBLE CAB 1.9 S HI-RACER"
  },
  {
    SERIAL: "06GS0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 1.9 S HI-RACER A",
    label: "BT-50 DOUBLE CAB 1.9 S HI-RACER A",
    value: "BT-50 DOUBLE CAB 1.9 S HI-RACER A"
  },
  {
    SERIAL: "06GT0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 1.9 SP HI-RACER",
    label: "BT-50 DOUBLE CAB 1.9 SP HI-RACER",
    value: "BT-50 DOUBLE CAB 1.9 SP HI-RACER"
  },
  {
    SERIAL: "06GU0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 1.9 SP HI-RACER A",
    label: "BT-50 DOUBLE CAB 1.9 SP HI-RACER A",
    value: "BT-50 DOUBLE CAB 1.9 SP HI-RACER A"
  },
  {
    SERIAL: "06GV0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 3.0 SP 4WD",
    label: "BT-50 DOUBLE CAB 3.0 SP 4WD",
    value: "BT-50 DOUBLE CAB 3.0 SP 4WD"
  },
  {
    SERIAL: "06GW0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "BT-50 DOUBLE CAB 3.0 SP A 4WD",
    label: "BT-50 DOUBLE CAB 3.0 SP A 4WD",
    value: "BT-50 DOUBLE CAB 3.0 SP A 4WD"
  },
  {
    SERIAL: "06GX0000026",
    MAKE_NAME: "MAZDA",
    MODEL_NAME: "CX-3 2.0 BASE PLUS",
    label: "CX-3 2.0 BASE PLUS",
    value: "CX-3 2.0 BASE PLUS"
  },
  {
    SERIAL: "7000000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "BMW",
    label: "BMW",
    value: "BMW"
  },
  {
    SERIAL: "7010000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "316I",
    label: "316I",
    value: "316I"
  },
  {
    SERIAL: "7020000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "316I COMPACT 1.6",
    label: "316I COMPACT 1.6",
    value: "316I COMPACT 1.6"
  },
  {
    SERIAL: "7030000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318I 1.8",
    label: "318I 1.8",
    value: "318I 1.8"
  },
  {
    SERIAL: "7040000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318IA 1.8",
    label: "318IA 1.8",
    value: "318IA 1.8"
  },
  {
    SERIAL: "7050000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318IA 1.8",
    label: "318IA 1.8",
    value: "318IA 1.8"
  },
  {
    SERIAL: "7060000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318IS 1.8 2Dr. M",
    label: "318IS 1.8 2Dr. M",
    value: "318IS 1.8 2Dr. M"
  },
  {
    SERIAL: "7070000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318IS 1.8 2Dr. A",
    label: "318IS 1.8 2Dr. A",
    value: "318IS 1.8 2Dr. A"
  },
  {
    SERIAL: "7080000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320",
    label: "320",
    value: "320"
  },
  {
    SERIAL: "7090000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320IA CABRIOLET 2.0",
    label: "320IA CABRIOLET 2.0",
    value: "320IA CABRIOLET 2.0"
  },
  {
    SERIAL: "7100000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320IA 2.0",
    label: "320IA 2.0",
    value: "320IA 2.0"
  },
  {
    SERIAL: "7110000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325I 2D 2.5",
    label: "325I 2D 2.5",
    value: "325I 2D 2.5"
  },
  {
    SERIAL: "7120000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325I 4D 2.5",
    label: "325I 4D 2.5",
    value: "325I 4D 2.5"
  },
  {
    SERIAL: "7130000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520I 2.0 M",
    label: "520I 2.0 M",
    value: "520I 2.0 M"
  },
  {
    SERIAL: "7140000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520IA 2.0",
    label: "520IA 2.0",
    value: "520IA 2.0"
  },
  {
    SERIAL: "7150000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520IA TOURING",
    label: "520IA TOURING",
    value: "520IA TOURING"
  },
  {
    SERIAL: "7160000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520IS 2.0",
    label: "520IS 2.0",
    value: "520IS 2.0"
  },
  {
    SERIAL: "7170000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "523IA (Y.2000)",
    label: "523IA (Y.2000)",
    value: "523IA (Y.2000)"
  },
  {
    SERIAL: "7180000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I 2.5",
    label: "525I 2.5",
    value: "525I 2.5"
  },
  {
    SERIAL: "7190000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I",
    label: "525I",
    value: "525I"
  },
  {
    SERIAL: "7200000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I TOURING 2.5",
    label: "525I TOURING 2.5",
    value: "525I TOURING 2.5"
  },
  {
    SERIAL: "7210000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "528IA 2.4",
    label: "528IA 2.4",
    value: "528IA 2.4"
  },
  {
    SERIAL: "7220000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530IA 3.0",
    label: "530IA 3.0",
    value: "530IA 3.0"
  },
  {
    SERIAL: "7230000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "535I",
    label: "535I",
    value: "535I"
  },
  {
    SERIAL: "7250000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730IA 3.0",
    label: "730IA 3.0",
    value: "730IA 3.0"
  },
  {
    SERIAL: "7260000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730IA 3.0",
    label: "730IA 3.0",
    value: "730IA 3.0"
  },
  {
    SERIAL: "7270000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740IAL V8 4.0",
    label: "740IAL V8 4.0",
    value: "740IAL V8 4.0"
  },
  {
    SERIAL: "7300000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "850CI A",
    label: "850CI A",
    value: "850CI A"
  },
  {
    SERIAL: "7320000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318CI",
    label: "318CI",
    value: "318CI"
  },
  {
    SERIAL: "7330000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "523IA",
    label: "523IA",
    value: "523IA"
  },
  {
    SERIAL: "7350000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320 CI CONVERTIBLE",
    label: "320 CI CONVERTIBLE",
    value: "320 CI CONVERTIBLE"
  },
  {
    SERIAL: "7360000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520",
    label: "520",
    value: "520"
  },
  {
    SERIAL: "7370000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I TOURING CBU 2.2",
    label: "320I TOURING CBU 2.2",
    value: "320I TOURING CBU 2.2"
  },
  {
    SERIAL: "7400000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740IAL",
    label: "740IAL",
    value: "740IAL"
  },
  {
    SERIAL: "7410000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325IA 2.4",
    label: "325IA 2.4",
    value: "325IA 2.4"
  },
  {
    SERIAL: "7420000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "735LI",
    label: "735LI",
    value: "735LI"
  },
  {
    SERIAL: "7430000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "323IA",
    label: "323IA",
    value: "323IA"
  },
  {
    SERIAL: "7440000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318I 4DR (E46)",
    label: "318I 4DR (E46)",
    value: "318I 4DR (E46)"
  },
  {
    SERIAL: "7450000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318IA SE",
    label: "318IA SE",
    value: "318IA SE"
  },
  {
    SERIAL: "7470000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "316I COMPACT AUTO",
    label: "316I COMPACT AUTO",
    value: "316I COMPACT AUTO"
  },
  {
    SERIAL: "7480000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z3 (2.8)",
    label: "Z3 (2.8)",
    value: "Z3 (2.8)"
  },
  {
    SERIAL: "7500000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I CONVERTIBLE CBU",
    label: "320I CONVERTIBLE CBU",
    value: "320I CONVERTIBLE CBU"
  },
  {
    SERIAL: "7510000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 3.0",
    label: "X5 3.0",
    value: "X5 3.0"
  },
  {
    SERIAL: "7520000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 4.4",
    label: "X5 4.4",
    value: "X5 4.4"
  },
  {
    SERIAL: "7530000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "735IAL",
    label: "735IAL",
    value: "735IAL"
  },
  {
    SERIAL: "7540000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330IA",
    label: "330IA",
    value: "330IA"
  },
  {
    SERIAL: "7550000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320 CI CONVERTIBLE",
    label: "320 CI CONVERTIBLE",
    value: "320 CI CONVERTIBLE"
  },
  {
    SERIAL: "7560000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "728 IA",
    label: "728 IA",
    value: "728 IA"
  },
  {
    SERIAL: "7570000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "728 IAL",
    label: "728 IAL",
    value: "728 IAL"
  },
  {
    SERIAL: "7580000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325 CI",
    label: "325 CI",
    value: "325 CI"
  },
  {
    SERIAL: "7600000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "323 IS",
    label: "323 IS",
    value: "323 IS"
  },
  {
    SERIAL: "7610000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 CONVERTABLE SA 2.5",
    label: "Z4 CONVERTABLE SA 2.5",
    value: "Z4 CONVERTABLE SA 2.5"
  },
  {
    SERIAL: "7620000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "760LI",
    label: "760LI",
    value: "760LI"
  },
  {
    SERIAL: "7630000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530IA",
    label: "530IA",
    value: "530IA"
  },
  {
    SERIAL: "7640000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730 LI (A)",
    label: "730 LI (A)",
    value: "730 LI (A)"
  },
  {
    SERIAL: "7650000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "323 IASE",
    label: "323 IASE",
    value: "323 IASE"
  },
  {
    SERIAL: "7660000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M3 COUPE",
    label: "M3 COUPE",
    value: "M3 COUPE"
  },
  {
    SERIAL: "7670000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M3 CONVERTIBLE",
    label: "M3 CONVERTIBLE",
    value: "M3 CONVERTIBLE"
  },
  {
    SERIAL: "7680000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "745LI",
    label: "745LI",
    value: "745LI"
  },
  {
    SERIAL: "7710000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I SE",
    label: "525I SE",
    value: "525I SE"
  },
  {
    SERIAL: "7720000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525 i",
    label: "525 i",
    value: "525 i"
  },
  {
    SERIAL: "7730000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730 LI SE",
    label: "730 LI SE",
    value: "730 LI SE"
  },
  {
    SERIAL: "7740000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "645CI COUPE",
    label: "645CI COUPE",
    value: "645CI COUPE"
  },
  {
    SERIAL: "7750000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325I",
    label: "325I",
    value: "325I"
  },
  {
    SERIAL: "7760000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "120I",
    label: "120I",
    value: "120I"
  },
  {
    SERIAL: "7770000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "323IA",
    label: "323IA",
    value: "323IA"
  },
  {
    SERIAL: "7780000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325 CI CONVERTIBLE",
    label: "325 CI CONVERTIBLE",
    value: "325 CI CONVERTIBLE"
  },
  {
    SERIAL: "7790000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520I",
    label: "520I",
    value: "520I"
  },
  {
    SERIAL: "7800000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I TOURING",
    label: "525I TOURING",
    value: "525I TOURING"
  },
  {
    SERIAL: "7810000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 2.5",
    label: "X3 2.5",
    value: "X3 2.5"
  },
  {
    SERIAL: "7820000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520 I",
    label: "520 I",
    value: "520 I"
  },
  {
    SERIAL: "7830000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520 IA",
    label: "520 IA",
    value: "520 IA"
  },
  {
    SERIAL: "7850000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I",
    label: "320I",
    value: "320I"
  },
  {
    SERIAL: "7870000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 C",
    label: "R1200 C",
    value: "R1200 C"
  },
  {
    SERIAL: "7880000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I SE",
    label: "320I SE",
    value: "320I SE"
  },
  {
    SERIAL: "7890000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1000 RS",
    label: "K1000 RS",
    value: "K1000 RS"
  },
  {
    SERIAL: "7900000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d (DIESEL)",
    label: "520d (DIESEL)",
    value: "520d (DIESEL)"
  },
  {
    SERIAL: "7910000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525 I (A)",
    label: "525 I (A)",
    value: "525 I (A)"
  },
  {
    SERIAL: "7920000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "335I COUPE",
    label: "335I COUPE",
    value: "335I COUPE"
  },
  {
    SERIAL: "7930000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M5 SMAC",
    label: "M5 SMAC",
    value: "M5 SMAC"
  },
  {
    SERIAL: "7940000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M6 COUPE",
    label: "M6 COUPE",
    value: "M6 COUPE"
  },
  {
    SERIAL: "7950000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "523I",
    label: "523I",
    value: "523I"
  },
  {
    SERIAL: "7960000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1200 RS",
    label: "K1200 RS",
    value: "K1200 RS"
  },
  {
    SERIAL: "7970000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d LCI 2.0 A",
    label: "520d LCI 2.0 A",
    value: "520d LCI 2.0 A"
  },
  {
    SERIAL: "7980000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1150 GS US ADVENTURE",
    label: "R1150 GS US ADVENTURE",
    value: "R1150 GS US ADVENTURE"
  },
  {
    SERIAL: "7990000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "630CI (COUPE)",
    label: "630CI (COUPE)",
    value: "630CI (COUPE)"
  },
  {
    SERIAL: "07AA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "650I COUPE",
    label: "650I COUPE",
    value: "650I COUPE"
  },
  {
    SERIAL: "07AB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "750LI",
    label: "750LI",
    value: "750LI"
  },
  {
    SERIAL: "07AC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 4.8",
    label: "X5 4.8",
    value: "X5 4.8"
  },
  {
    SERIAL: "07AD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318I",
    label: "318I",
    value: "318I"
  },
  {
    SERIAL: "07AE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740LI",
    label: "740LI",
    value: "740LI"
  },
  {
    SERIAL: "07AF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 TP COUPE 3.0",
    label: "Z4 TP COUPE 3.0",
    value: "Z4 TP COUPE 3.0"
  },
  {
    SERIAL: "07AG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 3.2 M COUPE",
    label: "Z4 3.2 M COUPE",
    value: "Z4 3.2 M COUPE"
  },
  {
    SERIAL: "07AH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I",
    label: "525I",
    value: "525I"
  },
  {
    SERIAL: "07AI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 SE 2.5",
    label: "X3 SE 2.5",
    value: "X3 SE 2.5"
  },
  {
    SERIAL: "07AJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320 CI COUPE",
    label: "320 CI COUPE",
    value: "320 CI COUPE"
  },
  {
    SERIAL: "07AK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "523 IA SPORT",
    label: "523 IA SPORT",
    value: "523 IA SPORT"
  },
  {
    SERIAL: "07AM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 2.0 D",
    label: "X3 2.0 D",
    value: "X3 2.0 D"
  },
  {
    SERIAL: "07AN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d",
    label: "320d",
    value: "320d"
  },
  {
    SERIAL: "07AO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318I SE",
    label: "318I SE",
    value: "318I SE"
  },
  {
    SERIAL: "07AP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325I 2.5",
    label: "325I 2.5",
    value: "325I 2.5"
  },
  {
    SERIAL: "07AQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325CI 2.5",
    label: "325CI 2.5",
    value: "325CI 2.5"
  },
  {
    SERIAL: "07AR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325 CI CONVERTIBLE 2.5",
    label: "325 CI CONVERTIBLE 2.5",
    value: "325 CI CONVERTIBLE 2.5"
  },
  {
    SERIAL: "07AS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1150 GS US ADVENTURE",
    label: "R1150 GS US ADVENTURE",
    value: "R1150 GS US ADVENTURE"
  },
  {
    SERIAL: "07AT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 3.0 DIESEL",
    label: "X5 3.0 DIESEL",
    value: "X5 3.0 DIESEL"
  },
  {
    SERIAL: "07AU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 R",
    label: "R1200 R",
    value: "R1200 R"
  },
  {
    SERIAL: "07AV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "120d",
    label: "120d",
    value: "120d"
  },
  {
    SERIAL: "07AW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320 CI COUPE",
    label: "320 CI COUPE",
    value: "320 CI COUPE"
  },
  {
    SERIAL: "07AX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "650CI CONVERTIBLE",
    label: "650CI CONVERTIBLE",
    value: "650CI CONVERTIBLE"
  },
  {
    SERIAL: "07AY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 3.0",
    label: "X6 3.0",
    value: "X6 3.0"
  },
  {
    SERIAL: "07AZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1150 RT",
    label: "R1150 RT",
    value: "R1150 RT"
  },
  {
    SERIAL: "07BA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 RT",
    label: "R1200 RT",
    value: "R1200 RT"
  },
  {
    SERIAL: "07BB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 GS",
    label: "R1200 GS",
    value: "R1200 GS"
  },
  {
    SERIAL: "07BC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 GS",
    label: "R1200 GS",
    value: "R1200 GS"
  },
  {
    SERIAL: "07BD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d COUPE",
    label: "320d COUPE",
    value: "320d COUPE"
  },
  {
    SERIAL: "07BE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 GS",
    label: "F800 GS",
    value: "F800 GS"
  },
  {
    SERIAL: "07BF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1200 R",
    label: "K1200 R",
    value: "K1200 R"
  },
  {
    SERIAL: "07BG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1300 GT",
    label: "K1300 GT",
    value: "K1300 GT"
  },
  {
    SERIAL: "07BH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1200 LT",
    label: "K1200 LT",
    value: "K1200 LT"
  },
  {
    SERIAL: "07BI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1200 GT",
    label: "K1200 GT",
    value: "K1200 GT"
  },
  {
    SERIAL: "07BJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730 Ld",
    label: "730 Ld",
    value: "730 Ld"
  },
  {
    SERIAL: "07BK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 CONVERTABLE 2.5 HIGHLINE",
    label: "Z4 CONVERTABLE 2.5 HIGHLINE",
    value: "Z4 CONVERTABLE 2.5 HIGHLINE"
  },
  {
    SERIAL: "07BL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 CONVERTABLE 3.0 TT",
    label: "Z4 CONVERTABLE 3.0 TT",
    value: "Z4 CONVERTABLE 3.0 TT"
  },
  {
    SERIAL: "07BM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 2.0 D. (CBU)",
    label: "X1 2.0 D. (CBU)",
    value: "X1 2.0 D. (CBU)"
  },
  {
    SERIAL: "07BN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530d",
    label: "530d",
    value: "530d"
  },
  {
    SERIAL: "07BO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d SPORT",
    label: "320d SPORT",
    value: "320d SPORT"
  },
  {
    SERIAL: "07BP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1200 S",
    label: "K1200 S",
    value: "K1200 S"
  },
  {
    SERIAL: "07BQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 4.4",
    label: "X6 4.4",
    value: "X6 4.4"
  },
  {
    SERIAL: "07BR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530d GT",
    label: "530d GT",
    value: "530d GT"
  },
  {
    SERIAL: "07BS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "635CI DIESEL",
    label: "635CI DIESEL",
    value: "635CI DIESEL"
  },
  {
    SERIAL: "07BT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F650",
    label: "F650",
    value: "F650"
  },
  {
    SERIAL: "07BU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1200 RS",
    label: "K1200 RS",
    value: "K1200 RS"
  },
  {
    SERIAL: "07BV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 SDRIVE 23I 2.5",
    label: "Z4 SDRIVE 23I 2.5",
    value: "Z4 SDRIVE 23I 2.5"
  },
  {
    SERIAL: "07BW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "HP2 SPORT",
    label: "HP2 SPORT",
    value: "HP2 SPORT"
  },
  {
    SERIAL: "07BX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "630CI CONVERTIBLE",
    label: "630CI CONVERTIBLE",
    value: "630CI CONVERTIBLE"
  },
  {
    SERIAL: "07BY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "635d SPORT 3.0",
    label: "635d SPORT 3.0",
    value: "635d SPORT 3.0"
  },
  {
    SERIAL: "07BZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "521I",
    label: "521I",
    value: "521I"
  },
  {
    SERIAL: "07CA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F650 GS",
    label: "F650 GS",
    value: "F650 GS"
  },
  {
    SERIAL: "07CB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 3.0",
    label: "X5 3.0",
    value: "X5 3.0"
  },
  {
    SERIAL: "07CC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 3.0 DIESEL",
    label: "X6 3.0 DIESEL",
    value: "X6 3.0 DIESEL"
  },
  {
    SERIAL: "07CD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525d 3.0",
    label: "525d 3.0",
    value: "525d 3.0"
  },
  {
    SERIAL: "07CE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE 2.0 SPORT",
    label: "X1 SDRIVE 2.0 SPORT",
    value: "X1 SDRIVE 2.0 SPORT"
  },
  {
    SERIAL: "07CF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "1150 RT",
    label: "1150 RT",
    value: "1150 RT"
  },
  {
    SERIAL: "07CH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 RR - CKD",
    label: "S1000 RR - CKD",
    value: "S1000 RR - CKD"
  },
  {
    SERIAL: "07CI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 4.0 D",
    label: "X6 4.0 D",
    value: "X6 4.0 D"
  },
  {
    SERIAL: "07CJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M3",
    label: "M3",
    value: "M3"
  },
  {
    SERIAL: "07CK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE20d XLine 2.0 D (CKD)",
    label: "X1 SDRIVE20d XLine 2.0 D (CKD)",
    value: "X1 SDRIVE20d XLine 2.0 D (CKD)"
  },
  {
    SERIAL: "07CL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 2.0 D HIGHLINE",
    label: "X1 2.0 D HIGHLINE",
    value: "X1 2.0 D HIGHLINE"
  },
  {
    SERIAL: "07CM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d TOURING SPORT VAN 2.0",
    label: "520d TOURING SPORT VAN 2.0",
    value: "520d TOURING SPORT VAN 2.0"
  },
  {
    SERIAL: "07CN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1300 S",
    label: "K1300 S",
    value: "K1300 S"
  },
  {
    SERIAL: "07CO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "640CI CONVERTIBLE",
    label: "640CI CONVERTIBLE",
    value: "640CI CONVERTIBLE"
  },
  {
    SERIAL: "07CP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d TOURING SPORT VAN 5D",
    label: "520d TOURING SPORT VAN 5D",
    value: "520d TOURING SPORT VAN 5D"
  },
  {
    SERIAL: "07CQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE 18i 2.0",
    label: "X1 SDRIVE 18i 2.0",
    value: "X1 SDRIVE 18i 2.0"
  },
  {
    SERIAL: "07CR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "1M 3.0",
    label: "1M 3.0",
    value: "1M 3.0"
  },
  {
    SERIAL: "07CS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "G650 GS",
    label: "G650 GS",
    value: "G650 GS"
  },
  {
    SERIAL: "07CT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F650 GS",
    label: "F650 GS",
    value: "F650 GS"
  },
  {
    SERIAL: "07CU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 R",
    label: "F800 R",
    value: "F800 R"
  },
  {
    SERIAL: "07CV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 GS ADVENTURE CBU",
    label: "R1200 GS ADVENTURE CBU",
    value: "R1200 GS ADVENTURE CBU"
  },
  {
    SERIAL: "07CW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1300 R",
    label: "K1300 R",
    value: "K1300 R"
  },
  {
    SERIAL: "07CX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1600 GTL",
    label: "K1600 GTL",
    value: "K1600 GTL"
  },
  {
    SERIAL: "07CY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "HP2 SPORT",
    label: "HP2 SPORT",
    value: "HP2 SPORT"
  },
  {
    SERIAL: "07CZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M3 GTS 4.4",
    label: "M3 GTS 4.4",
    value: "M3 GTS 4.4"
  },
  {
    SERIAL: "07DA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1600 GT",
    label: "K1600 GT",
    value: "K1600 GT"
  },
  {
    SERIAL: "07DB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6M 4.4",
    label: "X6M 4.4",
    value: "X6M 4.4"
  },
  {
    SERIAL: "07DC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 2.0 HIGHLINE",
    label: "Z4 2.0 HIGHLINE",
    value: "Z4 2.0 HIGHLINE"
  },
  {
    SERIAL: "07DD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "640 D COUPE 3.0",
    label: "640 D COUPE 3.0",
    value: "640 D COUPE 3.0"
  },
  {
    SERIAL: "07DE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "528IA 2.4",
    label: "528IA 2.4",
    value: "528IA 2.4"
  },
  {
    SERIAL: "07DF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X7 3.0",
    label: "X7 3.0",
    value: "X7 3.0"
  },
  {
    SERIAL: "07DG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 2.0I",
    label: "Z4 2.0I",
    value: "Z4 2.0I"
  },
  {
    SERIAL: "07DH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1000 GTL",
    label: "K1000 GTL",
    value: "K1000 GTL"
  },
  {
    SERIAL: "07DI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 2.3",
    label: "X1 2.3",
    value: "X1 2.3"
  },
  {
    SERIAL: "07DJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 2.2",
    label: "Z4 2.2",
    value: "Z4 2.2"
  },
  {
    SERIAL: "07DK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520I",
    label: "520I",
    value: "520I"
  },
  {
    SERIAL: "07DL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330 CI GRAY",
    label: "330 CI GRAY",
    value: "330 CI GRAY"
  },
  {
    SERIAL: "07DM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M5-F10 4.4",
    label: "M5-F10 4.4",
    value: "M5-F10 4.4"
  },
  {
    SERIAL: "07DN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "640I COUPE 3.0",
    label: "640I COUPE 3.0",
    value: "640I COUPE 3.0"
  },
  {
    SERIAL: "07DO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 R Classic",
    label: "R1200 R Classic",
    value: "R1200 R Classic"
  },
  {
    SERIAL: "07DP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "C 600 SPORT",
    label: "C 600 SPORT",
    value: "C 600 SPORT"
  },
  {
    SERIAL: "07DQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "C 650 GT",
    label: "C 650 GT",
    value: "C 650 GT"
  },
  {
    SERIAL: "07DR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "G650X COUNTRY",
    label: "G650X COUNTRY",
    value: "G650X COUNTRY"
  },
  {
    SERIAL: "07DS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d M-SPORT SALOON",
    label: "520d M-SPORT SALOON",
    value: "520d M-SPORT SALOON"
  },
  {
    SERIAL: "07DT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525d 2.0",
    label: "525d 2.0",
    value: "525d 2.0"
  },
  {
    SERIAL: "07DU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525d TOURING 2.0",
    label: "525d TOURING 2.0",
    value: "525d TOURING 2.0"
  },
  {
    SERIAL: "07DV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1100",
    label: "R1100",
    value: "R1100"
  },
  {
    SERIAL: "07DW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d GRAN TOURISMO M SPORT",
    label: "520d GRAN TOURISMO M SPORT",
    value: "520d GRAN TOURISMO M SPORT"
  },
  {
    SERIAL: "07DX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 2.0 D",
    label: "X3 2.0 D",
    value: "X3 2.0 D"
  },
  {
    SERIAL: "07DY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d GT",
    label: "520d GT",
    value: "520d GT"
  },
  {
    SERIAL: "07DZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "118i Convertible 1.6",
    label: "118i Convertible 1.6",
    value: "118i Convertible 1.6"
  },
  {
    SERIAL: "07EA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d TOURING 2.0",
    label: "320d TOURING 2.0",
    value: "320d TOURING 2.0"
  },
  {
    SERIAL: "07EB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I",
    label: "320I",
    value: "320I"
  },
  {
    SERIAL: "07EC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "328I SPORT",
    label: "328I SPORT",
    value: "328I SPORT"
  },
  {
    SERIAL: "07ED0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 3.0 D SPORT EDITION",
    label: "X6 3.0 D SPORT EDITION",
    value: "X6 3.0 D SPORT EDITION"
  },
  {
    SERIAL: "07EE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "535 HYBRID 3.0",
    label: "535 HYBRID 3.0",
    value: "535 HYBRID 3.0"
  },
  {
    SERIAL: "07EF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525d 2.0 GT",
    label: "525d 2.0 GT",
    value: "525d 2.0 GT"
  },
  {
    SERIAL: "07EG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "HP4 1.0",
    label: "HP4 1.0",
    value: "HP4 1.0"
  },
  {
    SERIAL: "07EH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "HP4 COMPETITION",
    label: "HP4 COMPETITION",
    value: "HP4 COMPETITION"
  },
  {
    SERIAL: "07EI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "528I 2.0",
    label: "528I 2.0",
    value: "528I 2.0"
  },
  {
    SERIAL: "07EJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I HYBRID 3.3",
    label: "525I HYBRID 3.3",
    value: "525I HYBRID 3.3"
  },
  {
    SERIAL: "07EK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I HYBRID M SPORT 3.3",
    label: "525I HYBRID M SPORT 3.3",
    value: "525I HYBRID M SPORT 3.3"
  },
  {
    SERIAL: "07EL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "ACTIVE-HYBIRD 5 3.0",
    label: "ACTIVE-HYBIRD 5 3.0",
    value: "ACTIVE-HYBIRD 5 3.0"
  },
  {
    SERIAL: "07EM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 2.5",
    label: "X3 2.5",
    value: "X3 2.5"
  },
  {
    SERIAL: "07EN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "335I 3.0 GRAY",
    label: "335I 3.0 GRAY",
    value: "335I 3.0 GRAY"
  },
  {
    SERIAL: "07EO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318I",
    label: "318I",
    value: "318I"
  },
  {
    SERIAL: "07EP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "523I HIGHLINE",
    label: "523I HIGHLINE",
    value: "523I HIGHLINE"
  },
  {
    SERIAL: "07EQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "116i M SPORT 1.6",
    label: "116i M SPORT 1.6",
    value: "116i M SPORT 1.6"
  },
  {
    SERIAL: "07ES0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 RR LIMITED EDITION",
    label: "S1000 RR LIMITED EDITION",
    value: "S1000 RR LIMITED EDITION"
  },
  {
    SERIAL: "07ET0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "ACTIVE HYBRID 7 3.0",
    label: "ACTIVE HYBRID 7 3.0",
    value: "ACTIVE HYBRID 7 3.0"
  },
  {
    SERIAL: "07EU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F700 GS",
    label: "F700 GS",
    value: "F700 GS"
  },
  {
    SERIAL: "07EV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "ACTIVE-HYBRID 3",
    label: "ACTIVE-HYBRID 3",
    value: "ACTIVE-HYBRID 3"
  },
  {
    SERIAL: "07EW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "ACTIVE HYBRID 7 L",
    label: "ACTIVE HYBRID 7 L",
    value: "ACTIVE HYBRID 7 L"
  },
  {
    SERIAL: "07EX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 2.2",
    label: "Z4 2.2",
    value: "Z4 2.2"
  },
  {
    SERIAL: "07EY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 3.0 (M50D)",
    label: "X6 3.0 (M50D)",
    value: "X6 3.0 (M50D)"
  },
  {
    SERIAL: "07EZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d M SPORT COUPE",
    label: "420d M SPORT COUPE",
    value: "420d M SPORT COUPE"
  },
  {
    SERIAL: "07FA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 2.0 I",
    label: "X3 2.0 I",
    value: "X3 2.0 I"
  },
  {
    SERIAL: "07FB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 1.8 ROADSTER",
    label: "Z4 1.8 ROADSTER",
    value: "Z4 1.8 ROADSTER"
  },
  {
    SERIAL: "07FC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530d",
    label: "530d",
    value: "530d"
  },
  {
    SERIAL: "07FD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "RS 800",
    label: "RS 800",
    value: "RS 800"
  },
  {
    SERIAL: "07FE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1600 GTL Exclusive",
    label: "K1600 GTL Exclusive",
    value: "K1600 GTL Exclusive"
  },
  {
    SERIAL: "07FF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 R (CKD)",
    label: "F800 R (CKD)",
    value: "F800 R (CKD)"
  },
  {
    SERIAL: "07FG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 GS Adventure",
    label: "F800 GS Adventure",
    value: "F800 GS Adventure"
  },
  {
    SERIAL: "07FH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R NineT",
    label: "R NineT",
    value: "R NineT"
  },
  {
    SERIAL: "07FI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 R",
    label: "S1000 R",
    value: "S1000 R"
  },
  {
    SERIAL: "07FJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420CI M SPORT COUPE",
    label: "420CI M SPORT COUPE",
    value: "420CI M SPORT COUPE"
  },
  {
    SERIAL: "07FK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d SE 2.0",
    label: "320d SE 2.0",
    value: "320d SE 2.0"
  },
  {
    SERIAL: "07FL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I",
    label: "320I",
    value: "320I"
  },
  {
    SERIAL: "07FM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I SPORT",
    label: "320I SPORT",
    value: "320I SPORT"
  },
  {
    SERIAL: "07FN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325I SPORT",
    label: "325I SPORT",
    value: "325I SPORT"
  },
  {
    SERIAL: "07FO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330I SE",
    label: "330I SE",
    value: "330I SE"
  },
  {
    SERIAL: "07FP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d SPORT COUPE 2.0 T",
    label: "420d SPORT COUPE 2.0 T",
    value: "420d SPORT COUPE 2.0 T"
  },
  {
    SERIAL: "07FQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "528I SPORT 2.0",
    label: "528I SPORT 2.0",
    value: "528I SPORT 2.0"
  },
  {
    SERIAL: "07FR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 2.0 D HIGHLINE",
    label: "X3 2.0 D HIGHLINE",
    value: "X3 2.0 D HIGHLINE"
  },
  {
    SERIAL: "07FS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "316I 1.6",
    label: "316I 1.6",
    value: "316I 1.6"
  },
  {
    SERIAL: "07FT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "325d M SPORT",
    label: "325d M SPORT",
    value: "325d M SPORT"
  },
  {
    SERIAL: "07FU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 GS (CKD)",
    label: "F800 GS (CKD)",
    value: "F800 GS (CKD)"
  },
  {
    SERIAL: "07FV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 Xdrive25d",
    label: "X5 Xdrive25d",
    value: "X5 Xdrive25d"
  },
  {
    SERIAL: "07FW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330d COUPE",
    label: "330d COUPE",
    value: "330d COUPE"
  },
  {
    SERIAL: "07FX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "I8 1.5",
    label: "I8 1.5",
    value: "I8 1.5"
  },
  {
    SERIAL: "07FY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X4 XDrive20d 2.0 M SPORT",
    label: "X4 XDrive20d 2.0 M SPORT",
    value: "X4 XDrive20d 2.0 M SPORT"
  },
  {
    SERIAL: "07FZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "116i 1.6",
    label: "116i 1.6",
    value: "116i 1.6"
  },
  {
    SERIAL: "07GA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420CI SPORT COUPE",
    label: "420CI SPORT COUPE",
    value: "420CI SPORT COUPE"
  },
  {
    SERIAL: "07GB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d GRAN SPORT 4D COUPE 2.0",
    label: "420d GRAN SPORT 4D COUPE 2.0",
    value: "420d GRAN SPORT 4D COUPE 2.0"
  },
  {
    SERIAL: "07GC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d SPORT CONVERTIBLE",
    label: "420d SPORT CONVERTIBLE",
    value: "420d SPORT CONVERTIBLE"
  },
  {
    SERIAL: "07GD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d M SPORT CONVERTIBLE",
    label: "420d M SPORT CONVERTIBLE",
    value: "420d M SPORT CONVERTIBLE"
  },
  {
    SERIAL: "07GE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d GRAN TOURISMO SPORT",
    label: "320d GRAN TOURISMO SPORT",
    value: "320d GRAN TOURISMO SPORT"
  },
  {
    SERIAL: "07GF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d GRANTURISMO LUXURY",
    label: "320d GRANTURISMO LUXURY",
    value: "320d GRANTURISMO LUXURY"
  },
  {
    SERIAL: "07GG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525d 2.0 LUXURY",
    label: "525d 2.0 LUXURY",
    value: "525d 2.0 LUXURY"
  },
  {
    SERIAL: "07GH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525d 2.0 M SPORT",
    label: "525d 2.0 M SPORT",
    value: "525d 2.0 M SPORT"
  },
  {
    SERIAL: "07GI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "528i 2.0 LUXURY",
    label: "528i 2.0 LUXURY",
    value: "528i 2.0 LUXURY"
  },
  {
    SERIAL: "07GJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "528i 2.0 M SPORT",
    label: "528i 2.0 M SPORT",
    value: "528i 2.0 M SPORT"
  },
  {
    SERIAL: "07GK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "640CI GRAN COUPE 3.0",
    label: "640CI GRAN COUPE 3.0",
    value: "640CI GRAN COUPE 3.0"
  },
  {
    SERIAL: "07GL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE 18i 2.0 xLine",
    label: "X1 SDRIVE 18i 2.0 xLine",
    value: "X1 SDRIVE 18i 2.0 xLine"
  },
  {
    SERIAL: "07GM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 3.0 D M SPORT",
    label: "X6 3.0 D M SPORT",
    value: "X6 3.0 D M SPORT"
  },
  {
    SERIAL: "07GN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "220I SPORT 2.0",
    label: "220I SPORT 2.0",
    value: "220I SPORT 2.0"
  },
  {
    SERIAL: "07GO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "220I M SPORT 2.0",
    label: "220I M SPORT 2.0",
    value: "220I M SPORT 2.0"
  },
  {
    SERIAL: "07GP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d 2.0",
    label: "520d 2.0",
    value: "520d 2.0"
  },
  {
    SERIAL: "07GQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I",
    label: "320I",
    value: "320I"
  },
  {
    SERIAL: "07GR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420CI GRAN SPORT COUPE 2.0",
    label: "420CI GRAN SPORT COUPE 2.0",
    value: "420CI GRAN SPORT COUPE 2.0"
  },
  {
    SERIAL: "07GS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420CI GRAN M SPORT COUPE 2.0",
    label: "420CI GRAN M SPORT COUPE 2.0",
    value: "420CI GRAN M SPORT COUPE 2.0"
  },
  {
    SERIAL: "07GT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d GRAN M SPORT 4D COUPE 2.0",
    label: "420d GRAN M SPORT 4D COUPE 2.0",
    value: "420d GRAN M SPORT 4D COUPE 2.0"
  },
  {
    SERIAL: "07GU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "428I GRAN M SPORT COUPE 2.0",
    label: "428I GRAN M SPORT COUPE 2.0",
    value: "428I GRAN M SPORT COUPE 2.0"
  },
  {
    SERIAL: "07GV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420CI SPORT CONVERTIBLE 2.0",
    label: "420CI SPORT CONVERTIBLE 2.0",
    value: "420CI SPORT CONVERTIBLE 2.0"
  },
  {
    SERIAL: "07GW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420CI M SPORT CONVERTIBLE 2.0",
    label: "420CI M SPORT CONVERTIBLE 2.0",
    value: "420CI M SPORT CONVERTIBLE 2.0"
  },
  {
    SERIAL: "07GX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 M SPORT 3.0D",
    label: "X5 M SPORT 3.0D",
    value: "X5 M SPORT 3.0D"
  },
  {
    SERIAL: "07GY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 Sdrive25d PURE EXPERIENCE 2.0D",
    label: "X5 Sdrive25d PURE EXPERIENCE 2.0D",
    value: "X5 Sdrive25d PURE EXPERIENCE 2.0D"
  },
  {
    SERIAL: "07GZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "318i F30 LCi 1.5",
    label: "318i F30 LCi 1.5",
    value: "318i F30 LCi 1.5"
  },
  {
    SERIAL: "07HA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d M PERFORMANCE 2.0",
    label: "320d M PERFORMANCE 2.0",
    value: "320d M PERFORMANCE 2.0"
  },
  {
    SERIAL: "07HB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320I LUXURY 2.0",
    label: "320I LUXURY 2.0",
    value: "320I LUXURY 2.0"
  },
  {
    SERIAL: "07HC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d LUXURY 2.0",
    label: "320d LUXURY 2.0",
    value: "320d LUXURY 2.0"
  },
  {
    SERIAL: "07HD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 xDrive 40D",
    label: "X6 xDrive 40D",
    value: "X6 xDrive 40D"
  },
  {
    SERIAL: "07HE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 XR",
    label: "S1000 XR",
    value: "S1000 XR"
  },
  {
    SERIAL: "07HF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 GS - CKD",
    label: "R1200 GS - CKD",
    value: "R1200 GS - CKD"
  },
  {
    SERIAL: "07HG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 GS ADVENTURE - CKD",
    label: "R1200 GS ADVENTURE - CKD",
    value: "R1200 GS ADVENTURE - CKD"
  },
  {
    SERIAL: "07HH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730Ld BUSINESS (LOW OPTION)",
    label: "730Ld BUSINESS (LOW OPTION)",
    value: "730Ld BUSINESS (LOW OPTION)"
  },
  {
    SERIAL: "07HI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I 1.8",
    label: "525I 1.8",
    value: "525I 1.8"
  },
  {
    SERIAL: "07HJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M4 COUPE 3.0",
    label: "M4 COUPE 3.0",
    value: "M4 COUPE 3.0"
  },
  {
    SERIAL: "07HK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "I3",
    label: "I3",
    value: "I3"
  },
  {
    SERIAL: "07HL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 R",
    label: "R1200 R",
    value: "R1200 R"
  },
  {
    SERIAL: "07HM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 GT",
    label: "F800 GT",
    value: "F800 GT"
  },
  {
    SERIAL: "07HN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 6T",
    label: "F800 6T",
    value: "F800 6T"
  },
  {
    SERIAL: "07HO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740IL 4.4",
    label: "740IL 4.4",
    value: "740IL 4.4"
  },
  {
    SERIAL: "07HP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "218i ACTIVE TOURER M SPORT 1.5",
    label: "218i ACTIVE TOURER M SPORT 1.5",
    value: "218i ACTIVE TOURER M SPORT 1.5"
  },
  {
    SERIAL: "07HQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "C 650 SPORT",
    label: "C 650 SPORT",
    value: "C 650 SPORT"
  },
  {
    SERIAL: "07HR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330e M SPORT HYBRID 2.0",
    label: "330e M SPORT HYBRID 2.0",
    value: "330e M SPORT HYBRID 2.0"
  },
  {
    SERIAL: "07HS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M2 3.0 COUPE",
    label: "M2 3.0 COUPE",
    value: "M2 3.0 COUPE"
  },
  {
    SERIAL: "07HT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "118i SPORT 1.6",
    label: "118i SPORT 1.6",
    value: "118i SPORT 1.6"
  },
  {
    SERIAL: "07HU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740LI PURE EXCELLENCE 3.0",
    label: "740LI PURE EXCELLENCE 3.0",
    value: "740LI PURE EXCELLENCE 3.0"
  },
  {
    SERIAL: "07HW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "218i COUPE M SPORT 1.5",
    label: "218i COUPE M SPORT 1.5",
    value: "218i COUPE M SPORT 1.5"
  },
  {
    SERIAL: "07HX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "118i M SPORT 1.6",
    label: "118i M SPORT 1.6",
    value: "118i M SPORT 1.6"
  },
  {
    SERIAL: "07HY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "218i GRAND TOURER LUXURY 1.5",
    label: "218i GRAND TOURER LUXURY 1.5",
    value: "218i GRAND TOURER LUXURY 1.5"
  },
  {
    SERIAL: "07HZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 XDRIVE30D PURE EXTRAVAGANCE 4WD 3.0",
    label: "X6 XDRIVE30D PURE EXTRAVAGANCE 4WD 3.0",
    value: "X6 XDRIVE30D PURE EXTRAVAGANCE 4WD 3.0"
  },
  {
    SERIAL: "07IA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 XDRIVE40E HYBRID 2.0 M SPORT",
    label: "X5 XDRIVE40E HYBRID 2.0 M SPORT",
    value: "X5 XDRIVE40E HYBRID 2.0 M SPORT"
  },
  {
    SERIAL: "07IB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d M-SPORT 2.0",
    label: "520d M-SPORT 2.0",
    value: "520d M-SPORT 2.0"
  },
  {
    SERIAL: "07IC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F800 GS ADVENTURE (CKD)",
    label: "F800 GS ADVENTURE (CKD)",
    value: "F800 GS ADVENTURE (CKD)"
  },
  {
    SERIAL: "07ID0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d ICONIC 2.0",
    label: "320d ICONIC 2.0",
    value: "320d ICONIC 2.0"
  },
  {
    SERIAL: "07IE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R Nine T Scramble",
    label: "R Nine T Scramble",
    value: "R Nine T Scramble"
  },
  {
    SERIAL: "07IF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d ELITE 2.0",
    label: "520d ELITE 2.0",
    value: "520d ELITE 2.0"
  },
  {
    SERIAL: "07IG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520I TOURING 2.0 (GREY)",
    label: "520I TOURING 2.0 (GREY)",
    value: "520I TOURING 2.0 (GREY)"
  },
  {
    SERIAL: "07IH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d GRAN TUURISMO SPORT 2.0",
    label: "320d GRAN TUURISMO SPORT 2.0",
    value: "320d GRAN TUURISMO SPORT 2.0"
  },
  {
    SERIAL: "07II0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d CELEBRATION EDITION",
    label: "320d CELEBRATION EDITION",
    value: "320d CELEBRATION EDITION"
  },
  {
    SERIAL: "07IJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d GRAN TURISMO LUXURY 2.0",
    label: "320d GRAN TURISMO LUXURY 2.0",
    value: "320d GRAN TURISMO LUXURY 2.0"
  },
  {
    SERIAL: "07IK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320d GRAN TURISMO CELEBRATION EDITION",
    label: "320d GRAN TURISMO CELEBRATION EDITION",
    value: "320d GRAN TURISMO CELEBRATION EDITION"
  },
  {
    SERIAL: "07IL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d M SPORT (PREMIUM PACKAGE)COUPE 2.0",
    label: "420d M SPORT (PREMIUM PACKAGE)COUPE 2.0",
    value: "420d M SPORT (PREMIUM PACKAGE)COUPE 2.0"
  },
  {
    SERIAL: "07IM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d SPORT (PREMIUM PACKAGE)COUPE 2.0",
    label: "420d SPORT (PREMIUM PACKAGE)COUPE 2.0",
    value: "420d SPORT (PREMIUM PACKAGE)COUPE 2.0"
  },
  {
    SERIAL: "07IN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d M SPORT CONVERTIBLE PREMIUM PACKAG",
    label: "420d M SPORT CONVERTIBLE PREMIUM PACKAG",
    value: "420d M SPORT CONVERTIBLE PREMIUM PACKAG"
  },
  {
    SERIAL: "07IO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d GRAND SPORT (PREMIUM PACKAGE)COUPE",
    label: "420d GRAND SPORT (PREMIUM PACKAGE)COUPE",
    value: "420d GRAND SPORT (PREMIUM PACKAGE)COUPE"
  },
  {
    SERIAL: "07IP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "420d GRAND M SPORT (PREMIUM PACKAGE)COU PE",
    label: "420d GRAND M SPORT (PREMIUM PACKAGE)COU PE",
    value: "420d GRAND M SPORT (PREMIUM PACKAGE)COU PE"
  },
  {
    SERIAL: "07IQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525d CELEBRATION EDITION 2.0",
    label: "525d CELEBRATION EDITION 2.0",
    value: "525d CELEBRATION EDITION 2.0"
  },
  {
    SERIAL: "07IR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730LD M SPORT 3.0",
    label: "730LD M SPORT 3.0",
    value: "730LD M SPORT 3.0"
  },
  {
    SERIAL: "07IS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDrive18d XLine 2.0",
    label: "X1 SDrive18d XLine 2.0",
    value: "X1 SDrive18d XLine 2.0"
  },
  {
    SERIAL: "07IT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDrive18d M SPORT 2.0",
    label: "X1 SDrive18d M SPORT 2.0",
    value: "X1 SDrive18d M SPORT 2.0"
  },
  {
    SERIAL: "07IU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 XDrive20d CELEBRATION EDITION 2.0",
    label: "X3 XDrive20d CELEBRATION EDITION 2.0",
    value: "X3 XDrive20d CELEBRATION EDITION 2.0"
  },
  {
    SERIAL: "07IV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 XDrive20d HIGHLINE 2.0",
    label: "X3 XDrive20d HIGHLINE 2.0",
    value: "X3 XDrive20d HIGHLINE 2.0"
  },
  {
    SERIAL: "07IW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X4 XDrive20i 2.0 M SPORT",
    label: "X4 XDrive20i 2.0 M SPORT",
    value: "X4 XDrive20i 2.0 M SPORT"
  },
  {
    SERIAL: "07IX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 SDrive25d CELEBRATION EDITION 3.0",
    label: "X5 SDrive25d CELEBRATION EDITION 3.0",
    value: "X5 SDrive25d CELEBRATION EDITION 3.0"
  },
  {
    SERIAL: "07IY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 XDrive30d 3.0",
    label: "X6 XDrive30d 3.0",
    value: "X6 XDrive30d 3.0"
  },
  {
    SERIAL: "07IZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X6 XDrive30d M SPORT 3.0",
    label: "X6 XDrive30d M SPORT 3.0",
    value: "X6 XDrive30d M SPORT 3.0"
  },
  {
    SERIAL: "07JA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M4 COUPE COMPETITION EDITION 3.0",
    label: "M4 COUPE COMPETITION EDITION 3.0",
    value: "M4 COUPE COMPETITION EDITION 3.0"
  },
  {
    SERIAL: "07JB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330e LUXURY CKD 2.0",
    label: "330e LUXURY CKD 2.0",
    value: "330e LUXURY CKD 2.0"
  },
  {
    SERIAL: "07JC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "116i M SPORT 1.5",
    label: "116i M SPORT 1.5",
    value: "116i M SPORT 1.5"
  },
  {
    SERIAL: "07JD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740i LIMOUSINE 3.0",
    label: "740i LIMOUSINE 3.0",
    value: "740i LIMOUSINE 3.0"
  },
  {
    SERIAL: "07JE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520d LUXURY",
    label: "520d LUXURY",
    value: "520d LUXURY"
  },
  {
    SERIAL: "07JF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530i M SPORT 2.0",
    label: "530i M SPORT 2.0",
    value: "530i M SPORT 2.0"
  },
  {
    SERIAL: "07JG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 RR HP LINE",
    label: "S1000 RR HP LINE",
    value: "S1000 RR HP LINE"
  },
  {
    SERIAL: "07JH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 RR -CBU SPECIAL COLOR",
    label: "S1000 RR -CBU SPECIAL COLOR",
    value: "S1000 RR -CBU SPECIAL COLOR"
  },
  {
    SERIAL: "07JI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 RR CKD SPECIAL COLOR",
    label: "S1000 RR CKD SPECIAL COLOR",
    value: "S1000 RR CKD SPECIAL COLOR"
  },
  {
    SERIAL: "07JJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE18I XLINE 1.5iTT",
    label: "X1 SDRIVE18I XLINE 1.5iTT",
    value: "X1 SDRIVE18I XLINE 1.5iTT"
  },
  {
    SERIAL: "07JK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740Le xDRIVE PURE EXCELLENCE 2.0",
    label: "740Le xDRIVE PURE EXCELLENCE 2.0",
    value: "740Le xDRIVE PURE EXCELLENCE 2.0"
  },
  {
    SERIAL: "07JL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 xDRIVE20d M SPORT 2.0",
    label: "X3 xDRIVE20d M SPORT 2.0",
    value: "X3 xDRIVE20d M SPORT 2.0"
  },
  {
    SERIAL: "07JM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320D M PERFORMANCE 2.0",
    label: "320D M PERFORMANCE 2.0",
    value: "320D M PERFORMANCE 2.0"
  },
  {
    SERIAL: "07JN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "760Li XDRIVE 6.6",
    label: "760Li XDRIVE 6.6",
    value: "760Li XDRIVE 6.6"
  },
  {
    SERIAL: "07JO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "430I COUPE LUXURY 2.0",
    label: "430I COUPE LUXURY 2.0",
    value: "430I COUPE LUXURY 2.0"
  },
  {
    SERIAL: "07JP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "I3 650",
    label: "I3 650",
    value: "I3 650"
  },
  {
    SERIAL: "07JQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "328I",
    label: "328I",
    value: "328I"
  },
  {
    SERIAL: "07JR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 3.0",
    label: "X3 3.0",
    value: "X3 3.0"
  },
  {
    SERIAL: "07JS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "430i M SPORT CONVERTIBLE 2.0",
    label: "430i M SPORT CONVERTIBLE 2.0",
    value: "430i M SPORT CONVERTIBLE 2.0"
  },
  {
    SERIAL: "07JT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1600 B",
    label: "K1600 B",
    value: "K1600 B"
  },
  {
    SERIAL: "07JU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "G310 GS",
    label: "G310 GS",
    value: "G310 GS"
  },
  {
    SERIAL: "07JV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "430I COUPE M SPORT 2.0",
    label: "430I COUPE M SPORT 2.0",
    value: "430I COUPE M SPORT 2.0"
  },
  {
    SERIAL: "07JW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530E LUXURY 2.0",
    label: "530E LUXURY 2.0",
    value: "530E LUXURY 2.0"
  },
  {
    SERIAL: "07JX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M4 DTM CHAMPION EDITION",
    label: "M4 DTM CHAMPION EDITION",
    value: "M4 DTM CHAMPION EDITION"
  },
  {
    SERIAL: "07JY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "430I LUXURY CONVERTIBLE 2.0",
    label: "430I LUXURY CONVERTIBLE 2.0",
    value: "430I LUXURY CONVERTIBLE 2.0"
  },
  {
    SERIAL: "07JZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330E ICONIC 2.0",
    label: "330E ICONIC 2.0",
    value: "330E ICONIC 2.0"
  },
  {
    SERIAL: "07KA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520D SPORT 2.0",
    label: "520D SPORT 2.0",
    value: "520D SPORT 2.0"
  },
  {
    SERIAL: "07KB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520D LUXURY 2.0",
    label: "520D LUXURY 2.0",
    value: "520D LUXURY 2.0"
  },
  {
    SERIAL: "07KC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530E M SPORT 2.0",
    label: "530E M SPORT 2.0",
    value: "530E M SPORT 2.0"
  },
  {
    SERIAL: "07KD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "630D GRANTURISMO M SPORT 3.0",
    label: "630D GRANTURISMO M SPORT 3.0",
    value: "630D GRANTURISMO M SPORT 3.0"
  },
  {
    SERIAL: "07KE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 XDRIVE20D XLINE 2.0",
    label: "X3 XDRIVE20D XLINE 2.0",
    value: "X3 XDRIVE20D XLINE 2.0"
  },
  {
    SERIAL: "07KF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 XDRIVE40E HYBRID PURE EXPERIENCE 2.0",
    label: "X5 XDRIVE40E HYBRID PURE EXPERIENCE 2.0",
    value: "X5 XDRIVE40E HYBRID PURE EXPERIENCE 2.0"
  },
  {
    SERIAL: "07KG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1600 GT",
    label: "K1600 GT",
    value: "K1600 GT"
  },
  {
    SERIAL: "07KH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1600 GTL",
    label: "K1600 GTL",
    value: "K1600 GTL"
  },
  {
    SERIAL: "07KI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "2000 CC",
    label: "2000 CC",
    value: "2000 CC"
  },
  {
    SERIAL: "07KJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M4 CS 3.0 COUPE",
    label: "M4 CS 3.0 COUPE",
    value: "M4 CS 3.0 COUPE"
  },
  {
    SERIAL: "07KK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R26",
    label: "R26",
    value: "R26"
  },
  {
    SERIAL: "07KL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 SDRIVE25D CELEBRATION EDITION 2.0",
    label: "X5 SDRIVE25D CELEBRATION EDITION 2.0",
    value: "X5 SDRIVE25D CELEBRATION EDITION 2.0"
  },
  {
    SERIAL: "07KM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320D GRANTURISMO M SPORT 2.0",
    label: "320D GRANTURISMO M SPORT 2.0",
    value: "320D GRANTURISMO M SPORT 2.0"
  },
  {
    SERIAL: "07KN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X2 SDRIVE20I M SPORT X 2.0",
    label: "X2 SDRIVE20I M SPORT X 2.0",
    value: "X2 SDRIVE20I M SPORT X 2.0"
  },
  {
    SERIAL: "07KO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320D M SPORT 2.0",
    label: "320D M SPORT 2.0",
    value: "320D M SPORT 2.0"
  },
  {
    SERIAL: "07KP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330E SPORT 2.0",
    label: "330E SPORT 2.0",
    value: "330E SPORT 2.0"
  },
  {
    SERIAL: "07KQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE20D M SPORT 2.0",
    label: "X1 SDRIVE20D M SPORT 2.0",
    value: "X1 SDRIVE20D M SPORT 2.0"
  },
  {
    SERIAL: "07KR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M760LI M SPORT 6.6",
    label: "M760LI M SPORT 6.6",
    value: "M760LI M SPORT 6.6"
  },
  {
    SERIAL: "07KT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520D M SPORT 2.0",
    label: "520D M SPORT 2.0",
    value: "520D M SPORT 2.0"
  },
  {
    SERIAL: "07KU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "520D LIMOUSINE 2.0 CBU",
    label: "520D LIMOUSINE 2.0 CBU",
    value: "520D LIMOUSINE 2.0 CBU"
  },
  {
    SERIAL: "07KV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "I3S",
    label: "I3S",
    value: "I3S"
  },
  {
    SERIAL: "07KW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530E HIGHLINE 2.0",
    label: "530E HIGHLINE 2.0",
    value: "530E HIGHLINE 2.0"
  },
  {
    SERIAL: "07KX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530I TOURING M SPORT 2.0",
    label: "530I TOURING M SPORT 2.0",
    value: "530I TOURING M SPORT 2.0"
  },
  {
    SERIAL: "07KY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740LE XDRIVE M SPORT 2.0",
    label: "740LE XDRIVE M SPORT 2.0",
    value: "740LE XDRIVE M SPORT 2.0"
  },
  {
    SERIAL: "07KZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M850I XDRIVE COUPE 4.4 4WD",
    label: "M850I XDRIVE COUPE 4.4 4WD",
    value: "M850I XDRIVE COUPE 4.4 4WD"
  },
  {
    SERIAL: "07LA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F850 GS",
    label: "F850 GS",
    value: "F850 GS"
  },
  {
    SERIAL: "07LB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F750 GS",
    label: "F750 GS",
    value: "F750 GS"
  },
  {
    SERIAL: "07LC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530I LIMOUSINE 2.0",
    label: "530I LIMOUSINE 2.0",
    value: "530I LIMOUSINE 2.0"
  },
  {
    SERIAL: "07LD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "118I M SPORT 1.5",
    label: "118I M SPORT 1.5",
    value: "118I M SPORT 1.5"
  },
  {
    SERIAL: "07LE0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M3 3.0",
    label: "M3 3.0",
    value: "M3 3.0"
  },
  {
    SERIAL: "07LF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE18I ICONIC 1.5TTI",
    label: "X1 SDRIVE18I ICONIC 1.5TTI",
    value: "X1 SDRIVE18I ICONIC 1.5TTI"
  },
  {
    SERIAL: "07LG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M2 COMPETITION 3.0 COUPE",
    label: "M2 COMPETITION 3.0 COUPE",
    value: "M2 COMPETITION 3.0 COUPE"
  },
  {
    SERIAL: "07LH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M4 COUPE 3.0 COMPETITION PACKAGE",
    label: "M4 COUPE 3.0 COMPETITION PACKAGE",
    value: "M4 COUPE 3.0 COMPETITION PACKAGE"
  },
  {
    SERIAL: "07LI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M4 CONVERTIBLE 3.0 EDITION 30 YEARS",
    label: "M4 CONVERTIBLE 3.0 EDITION 30 YEARS",
    value: "M4 CONVERTIBLE 3.0 EDITION 30 YEARS"
  },
  {
    SERIAL: "07LJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "400 GT",
    label: "400 GT",
    value: "400 GT"
  },
  {
    SERIAL: "07LK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "C 400 GT",
    label: "C 400 GT",
    value: "C 400 GT"
  },
  {
    SERIAL: "07LL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "525I LIMOUSINE",
    label: "525I LIMOUSINE",
    value: "525I LIMOUSINE"
  },
  {
    SERIAL: "07LM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 SDRIVE30I M SPORT 2.0",
    label: "Z4 SDRIVE30I M SPORT 2.0",
    value: "Z4 SDRIVE30I M SPORT 2.0"
  },
  {
    SERIAL: "07LN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 M40I 3.0 CONVERTIBLE",
    label: "Z4 M40I 3.0 CONVERTIBLE",
    value: "Z4 M40I 3.0 CONVERTIBLE"
  },
  {
    SERIAL: "07LO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "K1600 B GRRAND AMERICA",
    label: "K1600 B GRRAND AMERICA",
    value: "K1600 B GRRAND AMERICA"
  },
  {
    SERIAL: "07LP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320D SPORT 2.0",
    label: "320D SPORT 2.0",
    value: "320D SPORT 2.0"
  },
  {
    SERIAL: "07LQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "330I M SPORT 2.0",
    label: "330I M SPORT 2.0",
    value: "330I M SPORT 2.0"
  },
  {
    SERIAL: "07LR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X7 M50D 3.0",
    label: "X7 M50D 3.0",
    value: "X7 M50D 3.0"
  },
  {
    SERIAL: "07LS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z4 M ROADSTER",
    label: "Z4 M ROADSTER",
    value: "Z4 M ROADSTER"
  },
  {
    SERIAL: "07LT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "OVER 250 CC.",
    label: "OVER 250 CC.",
    value: "OVER 250 CC."
  },
  {
    SERIAL: "07LU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "530E ELITE 2.0",
    label: "530E ELITE 2.0",
    value: "530E ELITE 2.0"
  },
  {
    SERIAL: "07LV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M850I XDRIVE CONVERTIBLE 4.4",
    label: "M850I XDRIVE CONVERTIBLE 4.4",
    value: "M850I XDRIVE CONVERTIBLE 4.4"
  },
  {
    SERIAL: "07LW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F850 GS ADVENTURE",
    label: "F850 GS ADVENTURE",
    value: "F850 GS ADVENTURE"
  },
  {
    SERIAL: "07LX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 XDRIVE30D M SPORT 3.0 CBU",
    label: "X5 XDRIVE30D M SPORT 3.0 CBU",
    value: "X5 XDRIVE30D M SPORT 3.0 CBU"
  },
  {
    SERIAL: "07LY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 XDRIVE45E M SPORT 3.0 HYBRID",
    label: "X5 XDRIVE45E M SPORT 3.0 HYBRID",
    value: "X5 XDRIVE45E M SPORT 3.0 HYBRID"
  },
  {
    SERIAL: "07LZ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3M 3.0",
    label: "X3M 3.0",
    value: "X3M 3.0"
  },
  {
    SERIAL: "07MA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X4M 3.0",
    label: "X4M 3.0",
    value: "X4M 3.0"
  },
  {
    SERIAL: "07MB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "07MC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "C400 X",
    label: "C400 X",
    value: "C400 X"
  },
  {
    SERIAL: "07MD0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "740LE XDRIVE M SPORT 2.0",
    label: "740LE XDRIVE M SPORT 2.0",
    value: "740LE XDRIVE M SPORT 2.0"
  },
  {
    SERIAL: "07ME0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "I8 ROADSTER GRAY",
    label: "I8 ROADSTER GRAY",
    value: "I8 ROADSTER GRAY"
  },
  {
    SERIAL: "07MF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M3 CSL 2D 3.2",
    label: "M3 CSL 2D 3.2",
    value: "M3 CSL 2D 3.2"
  },
  {
    SERIAL: "07MG0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M3 3.0",
    label: "M3 3.0",
    value: "M3 3.0"
  },
  {
    SERIAL: "07MH0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "218I GRAN COUPE M SPORT 1.5",
    label: "218I GRAN COUPE M SPORT 1.5",
    value: "218I GRAN COUPE M SPORT 1.5"
  },
  {
    SERIAL: "07MI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "840D XDRIVE COUPE",
    label: "840D XDRIVE COUPE",
    value: "840D XDRIVE COUPE"
  },
  {
    SERIAL: "07MJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "320D GRAN TOURISMO SPORT 2.0",
    label: "320D GRAN TOURISMO SPORT 2.0",
    value: "320D GRAN TOURISMO SPORT 2.0"
  },
  {
    SERIAL: "07MK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "630I GRAN TOURISMO M SPORT 2.0",
    label: "630I GRAN TOURISMO M SPORT 2.0",
    value: "630I GRAN TOURISMO M SPORT 2.0"
  },
  {
    SERIAL: "07ML0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 XDRIVE30E M SPORT 2.0",
    label: "X3 XDRIVE30E M SPORT 2.0",
    value: "X3 XDRIVE30E M SPORT 2.0"
  },
  {
    SERIAL: "07MM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 XDRIVE30D M SPORT 3.0 CKD",
    label: "X5 XDRIVE30D M SPORT 3.0 CKD",
    value: "X5 XDRIVE30D M SPORT 3.0 CKD"
  },
  {
    SERIAL: "07MN0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F900 XR",
    label: "F900 XR",
    value: "F900 XR"
  },
  {
    SERIAL: "07MO0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "118I M SPORT 1.5",
    label: "118I M SPORT 1.5",
    value: "118I M SPORT 1.5"
  },
  {
    SERIAL: "07MP0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 RR RACING RED",
    label: "S1000 RR RACING RED",
    value: "S1000 RR RACING RED"
  },
  {
    SERIAL: "07MQ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "S1000 RR TRI COLOR",
    label: "S1000 RR TRI COLOR",
    value: "S1000 RR TRI COLOR"
  },
  {
    SERIAL: "07MR0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M8 COMPETITION 4.4",
    label: "M8 COMPETITION 4.4",
    value: "M8 COMPETITION 4.4"
  },
  {
    SERIAL: "07MS0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "220I GRAN COUPE 2.0 M SPORT",
    label: "220I GRAN COUPE 2.0 M SPORT",
    value: "220I GRAN COUPE 2.0 M SPORT"
  },
  {
    SERIAL: "07MT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X1 SDRIVE20D XLINE 2.0",
    label: "X1 SDRIVE20D XLINE 2.0",
    value: "X1 SDRIVE20D XLINE 2.0"
  },
  {
    SERIAL: "07MU0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X3 XDRIVE30E XLINE 2.0",
    label: "X3 XDRIVE30E XLINE 2.0",
    value: "X3 XDRIVE30E XLINE 2.0"
  },
  {
    SERIAL: "07MV0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "X5 XDRIVE30D XLINE 3.0",
    label: "X5 XDRIVE30D XLINE 3.0",
    value: "X5 XDRIVE30D XLINE 3.0"
  },
  {
    SERIAL: "07MW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M2 CS 3.0",
    label: "M2 CS 3.0",
    value: "M2 CS 3.0"
  },
  {
    SERIAL: "07MX0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "F900 R",
    label: "F900 R",
    value: "F900 R"
  },
  {
    SERIAL: "07MY0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "OVER 2000 CC.",
    label: "OVER 2000 CC.",
    value: "OVER 2000 CC."
  },
  {
    SERIAL: "07NA0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "730LD PURE EXCELLENCE",
    label: "730LD PURE EXCELLENCE",
    value: "730LD PURE EXCELLENCE"
  },
  {
    SERIAL: "07NB0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "725LD 2.0",
    label: "725LD 2.0",
    value: "725LD 2.0"
  },
  {
    SERIAL: "07NC0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "745LE XDRIVE M SPORT 3.0",
    label: "745LE XDRIVE M SPORT 3.0",
    value: "745LE XDRIVE M SPORT 3.0"
  },
  {
    SERIAL: "07NF0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "I8 PROTONIC FROZEN YELLOW",
    label: "I8 PROTONIC FROZEN YELLOW",
    value: "I8 PROTONIC FROZEN YELLOW"
  },
  {
    SERIAL: "07OI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "M5 V8 4.4TTI 4WD SEDAN",
    label: "M5 V8 4.4TTI 4WD SEDAN",
    value: "M5 V8 4.4TTI 4WD SEDAN"
  },
  {
    SERIAL: "07OW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R18",
    label: "R18",
    value: "R18"
  },
  {
    SERIAL: "07PM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1200 GS CELEBRATION EDITION",
    label: "R1200 GS CELEBRATION EDITION",
    value: "R1200 GS CELEBRATION EDITION"
  },
  {
    SERIAL: "07PW0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "Z3 M ROADSTER COLLECTOR CAR",
    label: "Z3 M ROADSTER COLLECTOR CAR",
    value: "Z3 M ROADSTER COLLECTOR CAR"
  },
  {
    SERIAL: "07RT0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: ">200 CC",
    label: ">200 CC",
    value: ">200 CC"
  },
  {
    SERIAL: "07TI0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R100 GS",
    label: "R100 GS",
    value: "R100 GS"
  },
  {
    SERIAL: "07TJ0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R100 RS",
    label: "R100 RS",
    value: "R100 RS"
  },
  {
    SERIAL: "07TK0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1250 GS HP",
    label: "R1250 GS HP",
    value: "R1250 GS HP"
  },
  {
    SERIAL: "07TL0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1250 GS ADVENTURE",
    label: "R1250 GS ADVENTURE",
    value: "R1250 GS ADVENTURE"
  },
  {
    SERIAL: "07TM0000026",
    MAKE_NAME: "BMW",
    MODEL_NAME: "R1250 GS",
    label: "R1250 GS",
    value: "R1250 GS"
  },
  {
    SERIAL: "8000000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "BENZ",
    label: "BENZ",
    value: "BENZ"
  },
  {
    SERIAL: "8010000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "190E 1.8 (201)",
    label: "190E 1.8 (201)",
    value: "190E 1.8 (201)"
  },
  {
    SERIAL: "8020000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "190E 2.0 (201)",
    label: "190E 2.0 (201)",
    value: "190E 2.0 (201)"
  },
  {
    SERIAL: "8030000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "190E 2.3 (201)",
    label: "190E 2.3 (201)",
    value: "190E 2.3 (201)"
  },
  {
    SERIAL: "8040000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "200",
    label: "200",
    value: "200"
  },
  {
    SERIAL: "8060000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "200E (124)",
    label: "200E (124)",
    value: "200E (124)"
  },
  {
    SERIAL: "8070000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "220E 2.2 (124)",
    label: "220E 2.2 (124)",
    value: "220E 2.2 (124)"
  },
  {
    SERIAL: "8080000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "220EL",
    label: "220EL",
    value: "220EL"
  },
  {
    SERIAL: "8100000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "230CE 2.3 2Dr.(124)",
    label: "230CE 2.3 2Dr.(124)",
    value: "230CE 2.3 2Dr.(124)"
  },
  {
    SERIAL: "8110000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300CE 3.0 2Dr.(124)",
    label: "300CE 3.0 2Dr.(124)",
    value: "300CE 3.0 2Dr.(124)"
  },
  {
    SERIAL: "8120000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "230E 2.3",
    label: "230E 2.3",
    value: "230E 2.3"
  },
  {
    SERIAL: "8130000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "230E 2.3 (123)",
    label: "230E 2.3 (123)",
    value: "230E 2.3 (123)"
  },
  {
    SERIAL: "8140000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK230 (208)",
    label: "CLK230 (208)",
    value: "CLK230 (208)"
  },
  {
    SERIAL: "8150000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "240D",
    label: "240D",
    value: "240D"
  },
  {
    SERIAL: "8170000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "250D",
    label: "250D",
    value: "250D"
  },
  {
    SERIAL: "8200000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR ESTATE (203)",
    label: "C200 KOMPRESSOR ESTATE (203)",
    value: "C200 KOMPRESSOR ESTATE (203)"
  },
  {
    SERIAL: "8210000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280",
    label: "280",
    value: "280"
  },
  {
    SERIAL: "8230000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280E 2.8(124)",
    label: "280E 2.8(124)",
    value: "280E 2.8(124)"
  },
  {
    SERIAL: "8240000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280S 2.8 (140)",
    label: "280S 2.8 (140)",
    value: "280S 2.8 (140)"
  },
  {
    SERIAL: "8250000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280SE 2.8 (126)",
    label: "280SE 2.8 (126)",
    value: "280SE 2.8 (126)"
  },
  {
    SERIAL: "8260000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280SEL (126)",
    label: "280SEL (126)",
    value: "280SEL (126)"
  },
  {
    SERIAL: "8270000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300CE 3.0 2Dr.(124)",
    label: "300CE 3.0 2Dr.(124)",
    value: "300CE 3.0 2Dr.(124)"
  },
  {
    SERIAL: "8280000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300 SE (126)",
    label: "300 SE (126)",
    value: "300 SE (126)"
  },
  {
    SERIAL: "8290000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300E 3.0 (124)",
    label: "300E 3.0 (124)",
    value: "300E 3.0 (124)"
  },
  {
    SERIAL: "8300000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300SEL 3.0 (140)",
    label: "300SEL 3.0 (140)",
    value: "300SEL 3.0 (140)"
  },
  {
    SERIAL: "8310000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300SEL 3.0 (126)",
    label: "300SEL 3.0 (126)",
    value: "300SEL 3.0 (126)"
  },
  {
    SERIAL: "8320000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300SL-24 3.0 2Dr.(129)",
    label: "300SL-24 3.0 2Dr.(129)",
    value: "300SL-24 3.0 2Dr.(129)"
  },
  {
    SERIAL: "8330000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300TD VAN 3.0 5Dr.(123)",
    label: "300TD VAN 3.0 5Dr.(123)",
    value: "300TD VAN 3.0 5Dr.(123)"
  },
  {
    SERIAL: "8340000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300TE 3.0 (124)",
    label: "300TE 3.0 (124)",
    value: "300TE 3.0 (124)"
  },
  {
    SERIAL: "8350000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "380SE 3.8 (126)",
    label: "380SE 3.8 (126)",
    value: "380SE 3.8 (126)"
  },
  {
    SERIAL: "8370000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "380 SEL 3.8 (126)",
    label: "380 SEL 3.8 (126)",
    value: "380 SEL 3.8 (126)"
  },
  {
    SERIAL: "8390000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "400SEL 4.0 (140)",
    label: "400SEL 4.0 (140)",
    value: "400SEL 4.0 (140)"
  },
  {
    SERIAL: "8400000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "450SLC 4.5",
    label: "450SLC 4.5",
    value: "450SLC 4.5"
  },
  {
    SERIAL: "8430000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "500SEC 5.0 2Dr.(126)",
    label: "500SEC 5.0 2Dr.(126)",
    value: "500SEC 5.0 2Dr.(126)"
  },
  {
    SERIAL: "8440000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "500SEL (126)",
    label: "500SEL (126)",
    value: "500SEL (126)"
  },
  {
    SERIAL: "8450000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "500SEL (140)",
    label: "500SEL (140)",
    value: "500SEL (140)"
  },
  {
    SERIAL: "8460000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "500SL (129)",
    label: "500SL (129)",
    value: "500SL (129)"
  },
  {
    SERIAL: "8470000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "600SEL (140)",
    label: "600SEL (140)",
    value: "600SEL (140)"
  },
  {
    SERIAL: "8480000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 1.8 CBU (202)",
    label: "C180 1.8 CBU (202)",
    value: "C180 1.8 CBU (202)"
  },
  {
    SERIAL: "8490000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 1.8 CKD (202)",
    label: "C180 1.8 CKD (202)",
    value: "C180 1.8 CKD (202)"
  },
  {
    SERIAL: "8500000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300D (123)",
    label: "300D (123)",
    value: "300D (123)"
  },
  {
    SERIAL: "8510000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 ESPRIT (202)",
    label: "C180 ESPRIT (202)",
    value: "C180 ESPRIT (202)"
  },
  {
    SERIAL: "8520000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 (202)",
    label: "C200 (202)",
    value: "C200 (202)"
  },
  {
    SERIAL: "8530000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 (202)",
    label: "C220 (202)",
    value: "C220 (202)"
  },
  {
    SERIAL: "8540000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 EL",
    label: "C220 EL",
    value: "C220 EL"
  },
  {
    SERIAL: "8550000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 SPORT (202)",
    label: "C220 SPORT (202)",
    value: "C220 SPORT (202)"
  },
  {
    SERIAL: "8560000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 ELEGANCE (202)",
    label: "C220 ELEGANCE (202)",
    value: "C220 ELEGANCE (202)"
  },
  {
    SERIAL: "8570000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 (202)",
    label: "C230 (202)",
    value: "C230 (202)"
  },
  {
    SERIAL: "8580000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C240 (202)",
    label: "C240 (202)",
    value: "C240 (202)"
  },
  {
    SERIAL: "8590000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C280 SPORT (202)",
    label: "C280 SPORT (202)",
    value: "C280 SPORT (202)"
  },
  {
    SERIAL: "8600000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 (124)",
    label: "E200 (124)",
    value: "E200 (124)"
  },
  {
    SERIAL: "8610000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 ELEGANCE (210)",
    label: "E200 ELEGANCE (210)",
    value: "E200 ELEGANCE (210)"
  },
  {
    SERIAL: "8620000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 (124)",
    label: "E220 (124)",
    value: "E220 (124)"
  },
  {
    SERIAL: "8630000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 NEW EYES (210)",
    label: "E220 NEW EYES (210)",
    value: "E220 NEW EYES (210)"
  },
  {
    SERIAL: "8640000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 COUPE (124)",
    label: "E220 COUPE (124)",
    value: "E220 COUPE (124)"
  },
  {
    SERIAL: "8670000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E230 (124)",
    label: "E230 (124)",
    value: "E230 (124)"
  },
  {
    SERIAL: "8680000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E230 NEW EYES (210)",
    label: "E230 NEW EYES (210)",
    value: "E230 NEW EYES (210)"
  },
  {
    SERIAL: "8690000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E280 (124)",
    label: "E280 (124)",
    value: "E280 (124)"
  },
  {
    SERIAL: "8700000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E280 NEW EYES (210)",
    label: "E280 NEW EYES (210)",
    value: "E280 NEW EYES (210)"
  },
  {
    SERIAL: "8720000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E280 ELEGANCE (210)",
    label: "E280 ELEGANCE (210)",
    value: "E280 ELEGANCE (210)"
  },
  {
    SERIAL: "8730000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "MB100 DIESEL",
    label: "MB100 DIESEL",
    value: "MB100 DIESEL"
  },
  {
    SERIAL: "8740000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "MB140 DIESEL",
    label: "MB140 DIESEL",
    value: "MB140 DIESEL"
  },
  {
    SERIAL: "8750000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER",
    label: "SPRINTER",
    value: "SPRINTER"
  },
  {
    SERIAL: "8760000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S280 (140)",
    label: "S280 (140)",
    value: "S280 (140)"
  },
  {
    SERIAL: "8770000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S320 (140)",
    label: "S320 (140)",
    value: "S320 (140)"
  },
  {
    SERIAL: "8780000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL500 (230)",
    label: "SL500 (230)",
    value: "SL500 (230)"
  },
  {
    SERIAL: "8790000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 (140)",
    label: "S500 (140)",
    value: "S500 (140)"
  },
  {
    SERIAL: "8800000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SC600 (140)",
    label: "SC600 (140)",
    value: "SC600 (140)"
  },
  {
    SERIAL: "8830000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL280 (129)",
    label: "SL280 (129)",
    value: "SL280 (129)"
  },
  {
    SERIAL: "8840000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL320 (129)",
    label: "SL320 (129)",
    value: "SL320 (129)"
  },
  {
    SERIAL: "8870000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 (170)",
    label: "SLK200 (170)",
    value: "SLK200 (170)"
  },
  {
    SERIAL: "8880000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK230 KOMPRESSOR (170)",
    label: "SLK230 KOMPRESSOR (170)",
    value: "SLK230 KOMPRESSOR (170)"
  },
  {
    SERIAL: "8890000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR 1.8 (CBU) (204)",
    label: "C200 KOMPRESSOR 1.8 (CBU) (204)",
    value: "C200 KOMPRESSOR 1.8 (CBU) (204)"
  },
  {
    SERIAL: "8990000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "230TE",
    label: "230TE",
    value: "230TE"
  },
  {
    SERIAL: "08A10000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS300",
    label: "CLS300",
    value: "CLS300"
  },
  {
    SERIAL: "08A20000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC300E 4MATIC AMG DYNAMIC 2.0",
    label: "GLC300E 4MATIC AMG DYNAMIC 2.0",
    value: "GLC300E 4MATIC AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08A30000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE350",
    label: "GLE350",
    value: "GLE350"
  },
  {
    SERIAL: "08A40000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS53 AMG 4MATIC 3.0",
    label: "CLS53 AMG 4MATIC 3.0",
    value: "CLS53 AMG 4MATIC 3.0"
  },
  {
    SERIAL: "08A50000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC63 S 4MATIC+ SEDAN COUPE 4.0",
    label: "GLC63 S 4MATIC+ SEDAN COUPE 4.0",
    value: "GLC63 S 4MATIC+ SEDAN COUPE 4.0"
  },
  {
    SERIAL: "08A60000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA35 AMG 2.0",
    label: "CLA35 AMG 2.0",
    value: "CLA35 AMG 2.0"
  },
  {
    SERIAL: "08AA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 CL 1.8 (202)",
    label: "C180 CL 1.8 (202)",
    value: "C180 CL 1.8 (202)"
  },
  {
    SERIAL: "08AB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A160 (168)",
    label: "A160 (168)",
    value: "A160 (168)"
  },
  {
    SERIAL: "08AC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML320 (A) CBU (163)",
    label: "ML320 (A) CBU (163)",
    value: "ML320 (A) CBU (163)"
  },
  {
    SERIAL: "08AD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E240 (210)",
    label: "E240 (210)",
    value: "E240 (210)"
  },
  {
    SERIAL: "08AE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C240 (A) ELEGANCE (202)",
    label: "C240 (A) ELEGANCE (202)",
    value: "C240 (A) ELEGANCE (202)"
  },
  {
    SERIAL: "08AF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E240 AVANTGARDE 2.6 CBU (210,211)",
    label: "E240 AVANTGARDE 2.6 CBU (210,211)",
    value: "E240 AVANTGARDE 2.6 CBU (210,211)"
  },
  {
    SERIAL: "08AG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR ELEGANCE 2.0 (203)",
    label: "C200 KOMPRESSOR ELEGANCE 2.0 (203)",
    value: "C200 KOMPRESSOR ELEGANCE 2.0 (203)"
  },
  {
    SERIAL: "08AH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E230 ELEGANCE (210)",
    label: "E230 ELEGANCE (210)",
    value: "E230 ELEGANCE (210)"
  },
  {
    SERIAL: "08AI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 K.ELEGANCE 2.0 CBU (203)",
    label: "C200 K.ELEGANCE 2.0 CBU (203)",
    value: "C200 K.ELEGANCE 2.0 CBU (203)"
  },
  {
    SERIAL: "08AJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CL230 KOMPRESSOR SPORT 2.3 (203)",
    label: "CL230 KOMPRESSOR SPORT 2.3 (203)",
    value: "CL230 KOMPRESSOR SPORT 2.3 (203)"
  },
  {
    SERIAL: "08AK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E240 ELEGANCE FACELIFT CKD (210)",
    label: "E240 ELEGANCE FACELIFT CKD (210)",
    value: "E240 ELEGANCE FACELIFT CKD (210)"
  },
  {
    SERIAL: "08AL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E280 V6 ELEGANCE (210)",
    label: "E280 V6 ELEGANCE (210)",
    value: "E280 V6 ELEGANCE (210)"
  },
  {
    SERIAL: "08AM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK230 KOMPRESSOR (208)",
    label: "CLK230 KOMPRESSOR (208)",
    value: "CLK230 KOMPRESSOR (208)"
  },
  {
    SERIAL: "08AO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S280 CBU (220)",
    label: "S280 CBU (220)",
    value: "S280 CBU (220)"
  },
  {
    SERIAL: "08AP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S280 CKD (220)",
    label: "S280 CKD (220)",
    value: "S280 CKD (220)"
  },
  {
    SERIAL: "08AR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 313 CDI 11 SEAT DIESEL",
    label: "SPRINTER 313 CDI 11 SEAT DIESEL",
    value: "SPRINTER 313 CDI 11 SEAT DIESEL"
  },
  {
    SERIAL: "08AV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR AVANTGARDE 2.0 CKD",
    label: "C200 KOMPRESSOR AVANTGARDE 2.0 CKD",
    value: "C200 KOMPRESSOR AVANTGARDE 2.0 CKD"
  },
  {
    SERIAL: "08AW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E240 AVANTGARDE 2.6 CKD (211)",
    label: "E240 AVANTGARDE 2.6 CKD (211)",
    value: "E240 AVANTGARDE 2.6 CKD (211)"
  },
  {
    SERIAL: "08AX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR AVANTGARDE 2.0 CBU (203",
    label: "C200 KOMPRESSOR AVANTGARDE 2.0 CBU (203",
    value: "C200 KOMPRESSOR AVANTGARDE 2.0 CBU (203"
  },
  {
    SERIAL: "08AY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 ELEGANCE2.0 CBU (203)",
    label: "C180 ELEGANCE2.0 CBU (203)",
    value: "C180 ELEGANCE2.0 CBU (203)"
  },
  {
    SERIAL: "08AZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL320 (129)(230)",
    label: "SL320 (129)(230)",
    value: "SL320 (129)(230)"
  },
  {
    SERIAL: "08B10000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS220D AMG",
    label: "CLS220D AMG",
    value: "CLS220D AMG"
  },
  {
    SERIAL: "08BA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 EL",
    label: "C200 EL",
    value: "C200 EL"
  },
  {
    SERIAL: "08BC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "MB140 BENZINE 2.3 CBU",
    label: "MB140 BENZINE 2.3 CBU",
    value: "MB140 BENZINE 2.3 CBU"
  },
  {
    SERIAL: "08BD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V-CLASS AMBIENTE CKD (VITO)",
    label: "V-CLASS AMBIENTE CKD (VITO)",
    value: "V-CLASS AMBIENTE CKD (VITO)"
  },
  {
    SERIAL: "08BJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO L112 CDI PANEL VAN",
    label: "VITO L112 CDI PANEL VAN",
    value: "VITO L112 CDI PANEL VAN"
  },
  {
    SERIAL: "08BK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML270 (163)",
    label: "ML270 (163)",
    value: "ML270 (163)"
  },
  {
    SERIAL: "08BL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E240 ELEGANCE CKD (210)",
    label: "E240 ELEGANCE CKD (210)",
    value: "E240 ELEGANCE CKD (210)"
  },
  {
    SERIAL: "08BM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI CLASSIC (210,211)",
    label: "E220 CDI CLASSIC (210,211)",
    value: "E220 CDI CLASSIC (210,211)"
  },
  {
    SERIAL: "08BN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 KOMPRESSOR CKD (210,211)",
    label: "E200 KOMPRESSOR CKD (210,211)",
    value: "E200 KOMPRESSOR CKD (210,211)"
  },
  {
    SERIAL: "08BS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 KOMPRESSOR (CBU) (210,211)",
    label: "E200 KOMPRESSOR (CBU) (210,211)",
    value: "E200 KOMPRESSOR (CBU) (210,211)"
  },
  {
    SERIAL: "08BT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V230 (638) 2.3",
    label: "V230 (638) 2.3",
    value: "V230 (638) 2.3"
  },
  {
    SERIAL: "08BU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 CLASSIC 1.8 (CKD)",
    label: "C180 CLASSIC 1.8 (CKD)",
    value: "C180 CLASSIC 1.8 (CKD)"
  },
  {
    SERIAL: "08BW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK240 (209)",
    label: "CLK240 (209)",
    value: "CLK240 (209)"
  },
  {
    SERIAL: "08BX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E320 (210)",
    label: "E320 (210)",
    value: "E320 (210)"
  },
  {
    SERIAL: "08BY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E240 ELEGANCE V6 (211)",
    label: "E240 ELEGANCE V6 (211)",
    value: "E240 ELEGANCE V6 (211)"
  },
  {
    SERIAL: "08BZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V220 CDI (638)",
    label: "V220 CDI (638)",
    value: "V220 CDI (638)"
  },
  {
    SERIAL: "08CA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350 (220)",
    label: "S350 (220)",
    value: "S350 (220)"
  },
  {
    SERIAL: "08CB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL500 ROADSTER 5.0(230)",
    label: "SL500 ROADSTER 5.0(230)",
    value: "SL500 ROADSTER 5.0(230)"
  },
  {
    SERIAL: "08CC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V-CLASS 220 CDI",
    label: "V-CLASS 220 CDI",
    value: "V-CLASS 220 CDI"
  },
  {
    SERIAL: "08CG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E55 (211)",
    label: "E55 (211)",
    value: "E55 (211)"
  },
  {
    SERIAL: "08CK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280E (123)",
    label: "280E (123)",
    value: "280E (123)"
  },
  {
    SERIAL: "08CL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK200 KOMPRESSOR COUPE (209)",
    label: "CLK200 KOMPRESSOR COUPE (209)",
    value: "CLK200 KOMPRESSOR COUPE (209)"
  },
  {
    SERIAL: "08CM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR ELEGANCE 1.8 CKD (203)",
    label: "C200 KOMPRESSOR ELEGANCE 1.8 CKD (203)",
    value: "C200 KOMPRESSOR ELEGANCE 1.8 CKD (203)"
  },
  {
    SERIAL: "08CN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR AVANTGRADE 1.8 CKD (203",
    label: "C200 KOMPRESSOR AVANTGRADE 1.8 CKD (203",
    value: "C200 KOMPRESSOR AVANTGRADE 1.8 CKD (203"
  },
  {
    SERIAL: "08CO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML350 (163)",
    label: "ML350 (163)",
    value: "ML350 (163)"
  },
  {
    SERIAL: "08CQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "MB140 VIP (DIESEL)",
    label: "MB140 VIP (DIESEL)",
    value: "MB140 VIP (DIESEL)"
  },
  {
    SERIAL: "08CR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 NGT AVANTGARDE (211)",
    label: "E200 NGT AVANTGARDE (211)",
    value: "E200 NGT AVANTGARDE (211)"
  },
  {
    SERIAL: "08CS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL280 CKD (230)",
    label: "SL280 CKD (230)",
    value: "SL280 CKD (230)"
  },
  {
    SERIAL: "08CT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 KOMPRESSOR ELEGANCE (CKD) (203)",
    label: "C180 KOMPRESSOR ELEGANCE (CKD) (203)",
    value: "C180 KOMPRESSOR ELEGANCE (CKD) (203)"
  },
  {
    SERIAL: "08CU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E270 CDI (211)",
    label: "E270 CDI (211)",
    value: "E270 CDI (211)"
  },
  {
    SERIAL: "08CV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 COUPE (CL203)",
    label: "C230 COUPE (CL203)",
    value: "C230 COUPE (CL203)"
  },
  {
    SERIAL: "08CW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL350 ROADSTER (230)",
    label: "SL350 ROADSTER (230)",
    value: "SL350 ROADSTER (230)"
  },
  {
    SERIAL: "08CX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 KOMPRESSOR ELEGANCE(CKD) (211)",
    label: "E220 KOMPRESSOR ELEGANCE(CKD) (211)",
    value: "E220 KOMPRESSOR ELEGANCE(CKD) (211)"
  },
  {
    SERIAL: "08CY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SMART",
    label: "SMART",
    value: "SMART"
  },
  {
    SERIAL: "08CZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL55 AMG ROADSTER",
    label: "SL55 AMG ROADSTER",
    value: "SL55 AMG ROADSTER"
  },
  {
    SERIAL: "08DA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR AMG (203)",
    label: "C200 KOMPRESSOR AMG (203)",
    value: "C200 KOMPRESSOR AMG (203)"
  },
  {
    SERIAL: "08DC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI CBU (211)",
    label: "E220 CDI CBU (211)",
    value: "E220 CDI CBU (211)"
  },
  {
    SERIAL: "08DD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK200 KOMPRESSOR CABRIOLET (209)",
    label: "CLK200 KOMPRESSOR CABRIOLET (209)",
    value: "CLK200 KOMPRESSOR CABRIOLET (209)"
  },
  {
    SERIAL: "08DI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI ELEGANCE CKD (211)",
    label: "E220 CDI ELEGANCE CKD (211)",
    value: "E220 CDI ELEGANCE CKD (211)"
  },
  {
    SERIAL: "08DJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI ESTATE (211)",
    label: "E220 CDI ESTATE (211)",
    value: "E220 CDI ESTATE (211)"
  },
  {
    SERIAL: "08DK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 KOMPRESSOR (171)",
    label: "SLK200 KOMPRESSOR (171)",
    value: "SLK200 KOMPRESSOR (171)"
  },
  {
    SERIAL: "08DM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR SPORT COUPE (CL203)",
    label: "C200 KOMPRESSOR SPORT COUPE (CL203)",
    value: "C200 KOMPRESSOR SPORT COUPE (CL203)"
  },
  {
    SERIAL: "08DN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 KOMPRESSOR ELEGANCE 1.8 (203)",
    label: "C230 KOMPRESSOR ELEGANCE 1.8 (203)",
    value: "C230 KOMPRESSOR ELEGANCE 1.8 (203)"
  },
  {
    SERIAL: "08DO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 (CKD) 1.8 (203)",
    label: "C230 (CKD) 1.8 (203)",
    value: "C230 (CKD) 1.8 (203)"
  },
  {
    SERIAL: "08DP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 316",
    label: "SPRINTER 316",
    value: "SPRINTER 316"
  },
  {
    SERIAL: "08DQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 416",
    label: "SPRINTER 416",
    value: "SPRINTER 416"
  },
  {
    SERIAL: "08DR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 CDI (CBU) (203)",
    label: "C220 CDI (CBU) (203)",
    value: "C220 CDI (CBU) (203)"
  },
  {
    SERIAL: "08DS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S55 AMG",
    label: "S55 AMG",
    value: "S55 AMG"
  },
  {
    SERIAL: "08DT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VIANO LINE AMBIENTE 3.0",
    label: "VIANO LINE AMBIENTE 3.0",
    value: "VIANO LINE AMBIENTE 3.0"
  },
  {
    SERIAL: "08DU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VIANO LINE AMBIENTE 2.2",
    label: "VIANO LINE AMBIENTE 2.2",
    value: "VIANO LINE AMBIENTE 2.2"
  },
  {
    SERIAL: "08DV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VIANO LINE TREND",
    label: "VIANO LINE TREND",
    value: "VIANO LINE TREND"
  },
  {
    SERIAL: "08DY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "TRAILER",
    label: "TRAILER",
    value: "TRAILER"
  },
  {
    SERIAL: "08EA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A170 (169)",
    label: "A170 (169)",
    value: "A170 (169)"
  },
  {
    SERIAL: "08EB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A200 (169)",
    label: "A200 (169)",
    value: "A200 (169)"
  },
  {
    SERIAL: "08EC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 KOMPRESSOR AVANGARDE 1.8 (203)",
    label: "C230 KOMPRESSOR AVANGARDE 1.8 (203)",
    value: "C230 KOMPRESSOR AVANGARDE 1.8 (203)"
  },
  {
    SERIAL: "08ED0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 ELEGANCE (A) TURBO VAN DIESEL",
    label: "E300 ELEGANCE (A) TURBO VAN DIESEL",
    value: "E300 ELEGANCE (A) TURBO VAN DIESEL"
  },
  {
    SERIAL: "08EE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 115 CDI (639)",
    label: "VITO 115 CDI (639)",
    value: "VITO 115 CDI (639)"
  },
  {
    SERIAL: "08EF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "230E (124)",
    label: "230E (124)",
    value: "230E (124)"
  },
  {
    SERIAL: "08EG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280 SE (116)",
    label: "280 SE (116)",
    value: "280 SE (116)"
  },
  {
    SERIAL: "08EH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS350 SEDAN (219)",
    label: "CLS350 SEDAN (219)",
    value: "CLS350 SEDAN (219)"
  },
  {
    SERIAL: "08EI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS500 SEDAN (219)",
    label: "CLS500 SEDAN (219)",
    value: "CLS500 SEDAN (219)"
  },
  {
    SERIAL: "08EJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "380 SEL 3.8 (126)",
    label: "380 SEL 3.8 (126)",
    value: "380 SEL 3.8 (126)"
  },
  {
    SERIAL: "08EK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E280 (124)",
    label: "E280 (124)",
    value: "E280 (124)"
  },
  {
    SERIAL: "08EL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 CDI (CBU) (202)",
    label: "C220 CDI (CBU) (202)",
    value: "C220 CDI (CBU) (202)"
  },
  {
    SERIAL: "08EM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL350 3.5(230)",
    label: "SL350 3.5(230)",
    value: "SL350 3.5(230)"
  },
  {
    SERIAL: "08EN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL500 ROADSTER 5.5 (230)",
    label: "SL500 ROADSTER 5.5 (230)",
    value: "SL500 ROADSTER 5.5 (230)"
  },
  {
    SERIAL: "08EO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B180 2.0 CDI (245)",
    label: "B180 2.0 CDI (245)",
    value: "B180 2.0 CDI (245)"
  },
  {
    SERIAL: "08EP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B180 2.0 CDI SUNROOF (245)",
    label: "B180 2.0 CDI SUNROOF (245)",
    value: "B180 2.0 CDI SUNROOF (245)"
  },
  {
    SERIAL: "08EQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E280 (CBU) (211)",
    label: "E280 (CBU) (211)",
    value: "E280 (CBU) (211)"
  },
  {
    SERIAL: "08ER0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 123 (BENZINE) 3.7",
    label: "VITO 123 (BENZINE) 3.7",
    value: "VITO 123 (BENZINE) 3.7"
  },
  {
    SERIAL: "08ES0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R350 (251)",
    label: "R350 (251)",
    value: "R350 (251)"
  },
  {
    SERIAL: "08ET0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML280 CDI (164)",
    label: "ML280 CDI (164)",
    value: "ML280 CDI (164)"
  },
  {
    SERIAL: "08EV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK280 (171)",
    label: "SLK280 (171)",
    value: "SLK280 (171)"
  },
  {
    SERIAL: "08EW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R350",
    label: "R350",
    value: "R350"
  },
  {
    SERIAL: "08EX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R350 EXECUTIVE (251)",
    label: "R350 EXECUTIVE (251)",
    value: "R350 EXECUTIVE (251)"
  },
  {
    SERIAL: "08EY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350 CBU (221)",
    label: "S350 CBU (221)",
    value: "S350 CBU (221)"
  },
  {
    SERIAL: "08EZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 122 CDI",
    label: "VITO 122 CDI",
    value: "VITO 122 CDI"
  },
  {
    SERIAL: "08FA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S320 (220)",
    label: "S320 (220)",
    value: "S320 (220)"
  },
  {
    SERIAL: "08FB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S320 CDI (V221)",
    label: "S320 CDI (V221)",
    value: "S320 CDI (V221)"
  },
  {
    SERIAL: "08FC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S280 LWB (V220)",
    label: "S280 LWB (V220)",
    value: "S280 LWB (V220)"
  },
  {
    SERIAL: "08FD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 2.5 (203)",
    label: "C230 2.5 (203)",
    value: "C230 2.5 (203)"
  },
  {
    SERIAL: "08FF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "280 CE",
    label: "280 CE",
    value: "280 CE"
  },
  {
    SERIAL: "08FG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 CBU (220)",
    label: "S500 CBU (220)",
    value: "S500 CBU (220)"
  },
  {
    SERIAL: "08FH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 CBU (221)",
    label: "S500 CBU (221)",
    value: "S500 CBU (221)"
  },
  {
    SERIAL: "08FI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "200E",
    label: "200E",
    value: "200E"
  },
  {
    SERIAL: "08FJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A180 2.0 CDI (169)",
    label: "A180 2.0 CDI (169)",
    value: "A180 2.0 CDI (169)"
  },
  {
    SERIAL: "08FK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R280 CDI (251)",
    label: "R280 CDI (251)",
    value: "R280 CDI (251)"
  },
  {
    SERIAL: "08FL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 NGT ELEGANCE 1.8",
    label: "E200 NGT ELEGANCE 1.8",
    value: "E200 NGT ELEGANCE 1.8"
  },
  {
    SERIAL: "08FM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S300 L (221)",
    label: "S300 L (221)",
    value: "S300 L (221)"
  },
  {
    SERIAL: "08FO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK280 (209)",
    label: "CLK280 (209)",
    value: "CLK280 (209)"
  },
  {
    SERIAL: "08FQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 (202)",
    label: "C230 (202)",
    value: "C230 (202)"
  },
  {
    SERIAL: "08FR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E320 (211)",
    label: "E320 (211)",
    value: "E320 (211)"
  },
  {
    SERIAL: "08FS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CL500 (216)",
    label: "CL500 (216)",
    value: "CL500 (216)"
  },
  {
    SERIAL: "08FT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML350 (164)",
    label: "ML350 (164)",
    value: "ML350 (164)"
  },
  {
    SERIAL: "08FU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK55 AMG (171)",
    label: "SLK55 AMG (171)",
    value: "SLK55 AMG (171)"
  },
  {
    SERIAL: "08FV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI (210)",
    label: "E220 CDI (210)",
    value: "E220 CDI (210)"
  },
  {
    SERIAL: "08FW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 KOMPRESSOR AVANTGARDE (203)",
    label: "C180 KOMPRESSOR AVANTGARDE (203)",
    value: "C180 KOMPRESSOR AVANTGARDE (203)"
  },
  {
    SERIAL: "08FX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 KOMPRESSOR WAGON SPORT (203)",
    label: "C180 KOMPRESSOR WAGON SPORT (203)",
    value: "C180 KOMPRESSOR WAGON SPORT (203)"
  },
  {
    SERIAL: "08FY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E230 (211)",
    label: "E230 (211)",
    value: "E230 (211)"
  },
  {
    SERIAL: "08FZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "500SEC",
    label: "500SEC",
    value: "500SEC"
  },
  {
    SERIAL: "08GA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI AVANTGARDE (211)",
    label: "E220 CDI AVANTGARDE (211)",
    value: "E220 CDI AVANTGARDE (211)"
  },
  {
    SERIAL: "08GB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E230 (210)",
    label: "E230 (210)",
    value: "E230 (210)"
  },
  {
    SERIAL: "08GC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E230 AVANTGARDE 2.5 (211)",
    label: "E230 AVANTGARDE 2.5 (211)",
    value: "E230 AVANTGARDE 2.5 (211)"
  },
  {
    SERIAL: "08GD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 BLUE EFFICIENCY AVANTGARDE1.8(204)",
    label: "C200 BLUE EFFICIENCY AVANTGARDE1.8(204)",
    value: "C200 BLUE EFFICIENCY AVANTGARDE1.8(204)"
  },
  {
    SERIAL: "08GE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK240 (209)",
    label: "CLK240 (209)",
    value: "CLK240 (209)"
  },
  {
    SERIAL: "08GF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S280",
    label: "S280",
    value: "S280"
  },
  {
    SERIAL: "08GG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK200 (208)",
    label: "CLK200 (208)",
    value: "CLK200 (208)"
  },
  {
    SERIAL: "08GH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 BLUE EFFICIENCY ELEGANCE 1.8 (204)",
    label: "C200 BLUE EFFICIENCY ELEGANCE 1.8 (204)",
    value: "C200 BLUE EFFICIENCY ELEGANCE 1.8 (204)"
  },
  {
    SERIAL: "08GI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 123 3.7 BENZINE",
    label: "VITO 123 3.7 BENZINE",
    value: "VITO 123 3.7 BENZINE"
  },
  {
    SERIAL: "08GJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 WAGON (203)",
    label: "C180 WAGON (203)",
    value: "C180 WAGON (203)"
  },
  {
    SERIAL: "08GK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK240",
    label: "SLK240",
    value: "SLK240"
  },
  {
    SERIAL: "08GL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLC200 KOMPRESSOR SPORT COUPE 1.8 (203)",
    label: "CLC200 KOMPRESSOR SPORT COUPE 1.8 (203)",
    value: "CLC200 KOMPRESSOR SPORT COUPE 1.8 (203)"
  },
  {
    SERIAL: "08GM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300B",
    label: "300B",
    value: "300B"
  },
  {
    SERIAL: "08GN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 CKD (221)",
    label: "S500 CKD (221)",
    value: "S500 CKD (221)"
  },
  {
    SERIAL: "08GO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK55 (171)",
    label: "SLK55 (171)",
    value: "SLK55 (171)"
  },
  {
    SERIAL: "08GP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E230 NEW EYES (210)",
    label: "E230 NEW EYES (210)",
    value: "E230 NEW EYES (210)"
  },
  {
    SERIAL: "08GQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C230 2.5 AVANTGARDE (204)",
    label: "C230 2.5 AVANTGARDE (204)",
    value: "C230 2.5 AVANTGARDE (204)"
  },
  {
    SERIAL: "08GR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 COUPE AVANTGARDE AMG",
    label: "E350 COUPE AVANTGARDE AMG",
    value: "E350 COUPE AVANTGARDE AMG"
  },
  {
    SERIAL: "08GS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR 1.8 IS (204)",
    label: "C200 KOMPRESSOR 1.8 IS (204)",
    value: "C200 KOMPRESSOR 1.8 IS (204)"
  },
  {
    SERIAL: "08GT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SMART",
    label: "SMART",
    value: "SMART"
  },
  {
    SERIAL: "08GU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 CDI CLASSIC 2.2 (204)",
    label: "C220 CDI CLASSIC 2.2 (204)",
    value: "C220 CDI CLASSIC 2.2 (204)"
  },
  {
    SERIAL: "08GV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 2.5 CDI",
    label: "E250 2.5 CDI",
    value: "E250 2.5 CDI"
  },
  {
    SERIAL: "08GW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI AVANTGARDE 1.8 SEDAN (212)",
    label: "E250 CGI AVANTGARDE 1.8 SEDAN (212)",
    value: "E250 CGI AVANTGARDE 1.8 SEDAN (212)"
  },
  {
    SERIAL: "08GX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI AVANTGARDE COUPE AMG(207) 2D",
    label: "E250 CGI AVANTGARDE COUPE AMG(207) 2D",
    value: "E250 CGI AVANTGARDE COUPE AMG(207) 2D"
  },
  {
    SERIAL: "08GY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI ELEGANCE COUPE (207) 1.8",
    label: "E250 CGI ELEGANCE COUPE (207) 1.8",
    value: "E250 CGI ELEGANCE COUPE (207) 1.8"
  },
  {
    SERIAL: "08GZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E240 (210)",
    label: "E240 (210)",
    value: "E240 (210)"
  },
  {
    SERIAL: "08HA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 AVANTGARDE (212)",
    label: "E300 AVANTGARDE (212)",
    value: "E300 AVANTGARDE (212)"
  },
  {
    SERIAL: "08HB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "BUS ประกอบ",
    label: "BUS ประกอบ",
    value: "BUS ประกอบ"
  },
  {
    SERIAL: "08HC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 2.2 CDI (212)",
    label: "E250 2.2 CDI (212)",
    value: "E250 2.2 CDI (212)"
  },
  {
    SERIAL: "08HD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350 CDI (CKD)",
    label: "S350 CDI (CKD)",
    value: "S350 CDI (CKD)"
  },
  {
    SERIAL: "08HE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E500 (212)",
    label: "E500 (212)",
    value: "E500 (212)"
  },
  {
    SERIAL: "08HF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML300 CDI",
    label: "ML300 CDI",
    value: "ML300 CDI"
  },
  {
    SERIAL: "08HG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 CGI AVANTGARDE 1.8 (204)",
    label: "C250 CGI AVANTGARDE 1.8 (204)",
    value: "C250 CGI AVANTGARDE 1.8 (204)"
  },
  {
    SERIAL: "08HH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CGI ELEGANCE 1.8 (212)",
    label: "E200 CGI ELEGANCE 1.8 (212)",
    value: "E200 CGI ELEGANCE 1.8 (212)"
  },
  {
    SERIAL: "08HI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 KOMPRESSOR AMG 1.8 (172)",
    label: "SLK200 KOMPRESSOR AMG 1.8 (172)",
    value: "SLK200 KOMPRESSOR AMG 1.8 (172)"
  },
  {
    SERIAL: "08HJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLS AMG 6.2",
    label: "SLS AMG 6.2",
    value: "SLS AMG 6.2"
  },
  {
    SERIAL: "08HK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 COUPE",
    label: "E350 COUPE",
    value: "E350 COUPE"
  },
  {
    SERIAL: "08HL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI COUPE (207) 2.2",
    label: "E250 CGI COUPE (207) 2.2",
    value: "E250 CGI COUPE (207) 2.2"
  },
  {
    SERIAL: "08HM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CGI COUPE AMG 1.8",
    label: "E200 CGI COUPE AMG 1.8",
    value: "E200 CGI COUPE AMG 1.8"
  },
  {
    SERIAL: "08HN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VIANO LINE AMBIENTE 3.0",
    label: "VIANO LINE AMBIENTE 3.0",
    value: "VIANO LINE AMBIENTE 3.0"
  },
  {
    SERIAL: "08HO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C63",
    label: "C63",
    value: "C63"
  },
  {
    SERIAL: "08HP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 CGI 1.8 STYLE (204)",
    label: "C200 CGI 1.8 STYLE (204)",
    value: "C200 CGI 1.8 STYLE (204)"
  },
  {
    SERIAL: "08HQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 CDI AVANTGARDE 2.2 (204)",
    label: "C250 CDI AVANTGARDE 2.2 (204)",
    value: "C250 CDI AVANTGARDE 2.2 (204)"
  },
  {
    SERIAL: "08HR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 CGI ELEGANCE 1.8 (204)",
    label: "C200 CGI ELEGANCE 1.8 (204)",
    value: "C200 CGI ELEGANCE 1.8 (204)"
  },
  {
    SERIAL: "08HS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 CGI AVANTGARDE 1.8 (204)",
    label: "C200 CGI AVANTGARDE 1.8 (204)",
    value: "C200 CGI AVANTGARDE 1.8 (204)"
  },
  {
    SERIAL: "08HT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI CABRIOLET 1.8 (207)",
    label: "E250 CGI CABRIOLET 1.8 (207)",
    value: "E250 CGI CABRIOLET 1.8 (207)"
  },
  {
    SERIAL: "08HU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CDI 3.0",
    label: "E250 CDI 3.0",
    value: "E250 CDI 3.0"
  },
  {
    SERIAL: "08HV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI ESTATE 1.8 (212)",
    label: "E250 CGI ESTATE 1.8 (212)",
    value: "E250 CGI ESTATE 1.8 (212)"
  },
  {
    SERIAL: "08HW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO L112 CDI",
    label: "VITO L112 CDI",
    value: "VITO L112 CDI"
  },
  {
    SERIAL: "08HX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 315 CDI HIGHROOF",
    label: "SPRINTER 315 CDI HIGHROOF",
    value: "SPRINTER 315 CDI HIGHROOF"
  },
  {
    SERIAL: "08HY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E63 AMG (212)",
    label: "E63 AMG (212)",
    value: "E63 AMG (212)"
  },
  {
    SERIAL: "08HZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 ELEGANCE 1.8 (CBU)",
    label: "C180 ELEGANCE 1.8 (CBU)",
    value: "C180 ELEGANCE 1.8 (CBU)"
  },
  {
    SERIAL: "08IA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 AVANTGARDE CGI 1.8 (CBU)",
    label: "E200 AVANTGARDE CGI 1.8 (CBU)",
    value: "E200 AVANTGARDE CGI 1.8 (CBU)"
  },
  {
    SERIAL: "08IB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CGI ELEGANCE 1.8 AMG (CBU)",
    label: "E200 CGI ELEGANCE 1.8 AMG (CBU)",
    value: "E200 CGI ELEGANCE 1.8 AMG (CBU)"
  },
  {
    SERIAL: "08IC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 120 V6",
    label: "VITO 120 V6",
    value: "VITO 120 V6"
  },
  {
    SERIAL: "08ID0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C63 AMG",
    label: "C63 AMG",
    value: "C63 AMG"
  },
  {
    SERIAL: "08IF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI COUPE SPORT AMG (CBU) (207)",
    label: "E250 CGI COUPE SPORT AMG (CBU) (207)",
    value: "E250 CGI COUPE SPORT AMG (CBU) (207)"
  },
  {
    SERIAL: "08IG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350 CGI",
    label: "S350 CGI",
    value: "S350 CGI"
  },
  {
    SERIAL: "08IH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CDI AMG 2.2",
    label: "E250 CDI AMG 2.2",
    value: "E250 CDI AMG 2.2"
  },
  {
    SERIAL: "08II0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S550",
    label: "S550",
    value: "S550"
  },
  {
    SERIAL: "08IJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI 2.2 (212) GREY",
    label: "E220 CDI 2.2 (212) GREY",
    value: "E220 CDI 2.2 (212) GREY"
  },
  {
    SERIAL: "08IK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL500 (230)",
    label: "SL500 (230)",
    value: "SL500 (230)"
  },
  {
    SERIAL: "08IL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 SEDAN",
    label: "E350 SEDAN",
    value: "E350 SEDAN"
  },
  {
    SERIAL: "08IM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CGI CABRIOLET 2.0 (207)",
    label: "E200 CGI CABRIOLET 2.0 (207)",
    value: "E200 CGI CABRIOLET 2.0 (207)"
  },
  {
    SERIAL: "08IN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CDI SEDAN AMG 1.8",
    label: "E250 CDI SEDAN AMG 1.8",
    value: "E250 CDI SEDAN AMG 1.8"
  },
  {
    SERIAL: "08IO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CL63",
    label: "CL63",
    value: "CL63"
  },
  {
    SERIAL: "08IP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 ML",
    label: "C200 ML",
    value: "C200 ML"
  },
  {
    SERIAL: "08IQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK320",
    label: "CLK320",
    value: "CLK320"
  },
  {
    SERIAL: "08IR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 ESTATE",
    label: "E350 ESTATE",
    value: "E350 ESTATE"
  },
  {
    SERIAL: "08IS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS350 CDI",
    label: "CLS350 CDI",
    value: "CLS350 CDI"
  },
  {
    SERIAL: "08IT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CDI COUPE 2.2 (207)",
    label: "E250 CDI COUPE 2.2 (207)",
    value: "E250 CDI COUPE 2.2 (207)"
  },
  {
    SERIAL: "08IU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 122 CDI",
    label: "VITO 122 CDI",
    value: "VITO 122 CDI"
  },
  {
    SERIAL: "08IV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E500 CABRIOLET",
    label: "E500 CABRIOLET",
    value: "E500 CABRIOLET"
  },
  {
    SERIAL: "08IW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 CABRIOLET",
    label: "E350 CABRIOLET",
    value: "E350 CABRIOLET"
  },
  {
    SERIAL: "08IX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GL350 CDI",
    label: "GL350 CDI",
    value: "GL350 CDI"
  },
  {
    SERIAL: "08IY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350 CDI",
    label: "S350 CDI",
    value: "S350 CDI"
  },
  {
    SERIAL: "08IZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CGI 2.2",
    label: "CLS250 CGI 2.2",
    value: "CLS250 CGI 2.2"
  },
  {
    SERIAL: "08JA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "08JB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK350 (171)",
    label: "SLK350 (171)",
    value: "SLK350 (171)"
  },
  {
    SERIAL: "08JC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CGI CABRIOLET 1.8",
    label: "E200 CGI CABRIOLET 1.8",
    value: "E200 CGI CABRIOLET 1.8"
  },
  {
    SERIAL: "08JD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CL500 (215)",
    label: "CL500 (215)",
    value: "CL500 (215)"
  },
  {
    SERIAL: "08JE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 CGI 1.8",
    label: "C180 CGI 1.8",
    value: "C180 CGI 1.8"
  },
  {
    SERIAL: "08JF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI COUPE (AMG) 1.8",
    label: "E250 CGI COUPE (AMG) 1.8",
    value: "E250 CGI COUPE (AMG) 1.8"
  },
  {
    SERIAL: "08JG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 (211)",
    label: "E300 (211)",
    value: "E300 (211)"
  },
  {
    SERIAL: "08JH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 CDI COUPE (207)",
    label: "E350 CDI COUPE (207)",
    value: "E350 CDI COUPE (207)"
  },
  {
    SERIAL: "08JI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK250 CGI",
    label: "SLK250 CGI",
    value: "SLK250 CGI"
  },
  {
    SERIAL: "08JJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 CGI 1.6 COUPE AMG (204)",
    label: "C180 CGI 1.6 COUPE AMG (204)",
    value: "C180 CGI 1.6 COUPE AMG (204)"
  },
  {
    SERIAL: "08JK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI",
    label: "CLS250 CDI",
    value: "CLS250 CDI"
  },
  {
    SERIAL: "08JL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 CGI COUPE 1.8",
    label: "C250 CGI COUPE 1.8",
    value: "C250 CGI COUPE 1.8"
  },
  {
    SERIAL: "08JM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK350 SPORT AMG 3.5 (172)",
    label: "SLK350 SPORT AMG 3.5 (172)",
    value: "SLK350 SPORT AMG 3.5 (172)"
  },
  {
    SERIAL: "08JN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CL500 SPORT AMG",
    label: "CL500 SPORT AMG",
    value: "CL500 SPORT AMG"
  },
  {
    SERIAL: "08JO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R300 CDI",
    label: "R300 CDI",
    value: "R300 CDI"
  },
  {
    SERIAL: "08JP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R300 CDI EXECUTIVE",
    label: "R300 CDI EXECUTIVE",
    value: "R300 CDI EXECUTIVE"
  },
  {
    SERIAL: "08JQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R300 CDI EXECUTIVE SUNROOF",
    label: "R300 CDI EXECUTIVE SUNROOF",
    value: "R300 CDI EXECUTIVE SUNROOF"
  },
  {
    SERIAL: "08JR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML420",
    label: "ML420",
    value: "ML420"
  },
  {
    SERIAL: "08JS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS63 AMG FULL OPTION",
    label: "CLS63 AMG FULL OPTION",
    value: "CLS63 AMG FULL OPTION"
  },
  {
    SERIAL: "08JT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS63 AMG",
    label: "CLS63 AMG",
    value: "CLS63 AMG"
  },
  {
    SERIAL: "08JU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CDI CABRIOLET",
    label: "E250 CDI CABRIOLET",
    value: "E250 CDI CABRIOLET"
  },
  {
    SERIAL: "08JV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E63 AMG ESTATE",
    label: "E63 AMG ESTATE",
    value: "E63 AMG ESTATE"
  },
  {
    SERIAL: "08JW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G300 CDI STATION WAGON",
    label: "G300 CDI STATION WAGON",
    value: "G300 CDI STATION WAGON"
  },
  {
    SERIAL: "08JX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G350 CDI",
    label: "G350 CDI",
    value: "G350 CDI"
  },
  {
    SERIAL: "08JY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G350 CGI",
    label: "G350 CGI",
    value: "G350 CGI"
  },
  {
    SERIAL: "08JZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 2.2 CDI VAN",
    label: "VITO 2.2 CDI VAN",
    value: "VITO 2.2 CDI VAN"
  },
  {
    SERIAL: "08KA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 126 3.5",
    label: "VITO 126 3.5",
    value: "VITO 126 3.5"
  },
  {
    SERIAL: "08KB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS350 CGI (AMG)",
    label: "CLS350 CGI (AMG)",
    value: "CLS350 CGI (AMG)"
  },
  {
    SERIAL: "08KC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 KOMPRESSOR ESTATE 1.8 (204)",
    label: "C200 KOMPRESSOR ESTATE 1.8 (204)",
    value: "C200 KOMPRESSOR ESTATE 1.8 (204)"
  },
  {
    SERIAL: "08KD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R320 CDI",
    label: "R320 CDI",
    value: "R320 CDI"
  },
  {
    SERIAL: "08KE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI 2.2 (CARLSSON)",
    label: "CLS250 CDI 2.2 (CARLSSON)",
    value: "CLS250 CDI 2.2 (CARLSSON)"
  },
  {
    SERIAL: "08KF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "320 S",
    label: "320 S",
    value: "320 S"
  },
  {
    SERIAL: "08KI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 116 CDI",
    label: "VITO 116 CDI",
    value: "VITO 116 CDI"
  },
  {
    SERIAL: "08KJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VIANO 2.2 CDI",
    label: "VIANO 2.2 CDI",
    value: "VIANO 2.2 CDI"
  },
  {
    SERIAL: "08KK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML250 BLUETEC EXCUTIVE 2.2 CDI",
    label: "ML250 BLUETEC EXCUTIVE 2.2 CDI",
    value: "ML250 BLUETEC EXCUTIVE 2.2 CDI"
  },
  {
    SERIAL: "08KL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 L 4.7 (221)",
    label: "S500 L 4.7 (221)",
    value: "S500 L 4.7 (221)"
  },
  {
    SERIAL: "08KM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI 2.2 (AMG)",
    label: "CLS250 CDI 2.2 (AMG)",
    value: "CLS250 CDI 2.2 (AMG)"
  },
  {
    SERIAL: "08KN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E55 (211)",
    label: "E55 (211)",
    value: "E55 (211)"
  },
  {
    SERIAL: "08KO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E55",
    label: "E55",
    value: "E55"
  },
  {
    SERIAL: "08KP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B180 CGI (246)",
    label: "B180 CGI (246)",
    value: "B180 CGI (246)"
  },
  {
    SERIAL: "08KQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 CGI (172)",
    label: "SLK200 CGI (172)",
    value: "SLK200 CGI (172)"
  },
  {
    SERIAL: "08KR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350 CDI EDITION",
    label: "S350 CDI EDITION",
    value: "S350 CDI EDITION"
  },
  {
    SERIAL: "08KS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B200 1.6 CGI(246)",
    label: "B200 1.6 CGI(246)",
    value: "B200 1.6 CGI(246)"
  },
  {
    SERIAL: "08KT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G55 AMG",
    label: "G55 AMG",
    value: "G55 AMG"
  },
  {
    SERIAL: "08KU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 CGI SALOON FULL OPTION",
    label: "C180 CGI SALOON FULL OPTION",
    value: "C180 CGI SALOON FULL OPTION"
  },
  {
    SERIAL: "08KV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 CGI SPORT AMG 1.6",
    label: "C180 CGI SPORT AMG 1.6",
    value: "C180 CGI SPORT AMG 1.6"
  },
  {
    SERIAL: "08KW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML250 BLUETEC AMG 2.2 CDI SPORT DYNAMIC",
    label: "ML250 BLUETEC AMG 2.2 CDI SPORT DYNAMIC",
    value: "ML250 BLUETEC AMG 2.2 CDI SPORT DYNAMIC"
  },
  {
    SERIAL: "08KX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B200 1.6 CGI (246)",
    label: "B200 1.6 CGI (246)",
    value: "B200 1.6 CGI (246)"
  },
  {
    SERIAL: "08KY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML350 CDI (166)",
    label: "ML350 CDI (166)",
    value: "ML350 CDI (166)"
  },
  {
    SERIAL: "08KZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL65 AMG (230)",
    label: "SL65 AMG (230)",
    value: "SL65 AMG (230)"
  },
  {
    SERIAL: "08LA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL63 AMG",
    label: "SL63 AMG",
    value: "SL63 AMG"
  },
  {
    SERIAL: "08LB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 COUPE 2.2",
    label: "C250 COUPE 2.2",
    value: "C250 COUPE 2.2"
  },
  {
    SERIAL: "08LC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI COUPE AMG DYNAMIC",
    label: "CLS250 CDI COUPE AMG DYNAMIC",
    value: "CLS250 CDI COUPE AMG DYNAMIC"
  },
  {
    SERIAL: "08LD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI COUPE AMG PREMIUM",
    label: "CLS250 CDI COUPE AMG PREMIUM",
    value: "CLS250 CDI COUPE AMG PREMIUM"
  },
  {
    SERIAL: "08LE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI SHOOTING BRAKE EXCLUSIVE 2.2",
    label: "CLS250 CDI SHOOTING BRAKE EXCLUSIVE 2.2",
    value: "CLS250 CDI SHOOTING BRAKE EXCLUSIVE 2.2"
  },
  {
    SERIAL: "08LF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI SHOOTING BRAKE AMG 2.2 (218)",
    label: "CLS250 CDI SHOOTING BRAKE AMG 2.2 (218)",
    value: "CLS250 CDI SHOOTING BRAKE AMG 2.2 (218)"
  },
  {
    SERIAL: "08LG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A180 1.6 (176)",
    label: "A180 1.6 (176)",
    value: "A180 1.6 (176)"
  },
  {
    SERIAL: "08LH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A250 2.0 AMG (176)",
    label: "A250 2.0 AMG (176)",
    value: "A250 2.0 AMG (176)"
  },
  {
    SERIAL: "08LI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350 CDI EXCLUSIVE 3.0",
    label: "S350 CDI EXCLUSIVE 3.0",
    value: "S350 CDI EXCLUSIVE 3.0"
  },
  {
    SERIAL: "08LJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 4.7 (222)",
    label: "S500 4.7 (222)",
    value: "S500 4.7 (222)"
  },
  {
    SERIAL: "08LK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL500 AMG",
    label: "SL500 AMG",
    value: "SL500 AMG"
  },
  {
    SERIAL: "08LL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 1.8 AMG",
    label: "E250 1.8 AMG",
    value: "E250 1.8 AMG"
  },
  {
    SERIAL: "08LM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 COUPE SPORT 1.8",
    label: "E200 COUPE SPORT 1.8",
    value: "E200 COUPE SPORT 1.8"
  },
  {
    SERIAL: "08LN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 COUPE AMG 1.8 (207)",
    label: "E250 COUPE AMG 1.8 (207)",
    value: "E250 COUPE AMG 1.8 (207)"
  },
  {
    SERIAL: "08LO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CABRIOLET AMG 1.8",
    label: "E250 CABRIOLET AMG 1.8",
    value: "E250 CABRIOLET AMG 1.8"
  },
  {
    SERIAL: "08LP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 CGI (172) GREY",
    label: "SLK200 CGI (172) GREY",
    value: "SLK200 CGI (172) GREY"
  },
  {
    SERIAL: "08LQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL600",
    label: "SL600",
    value: "SL600"
  },
  {
    SERIAL: "08LR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL63 AMG",
    label: "SL63 AMG",
    value: "SL63 AMG"
  },
  {
    SERIAL: "08LS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 CDI 1.8 (212)",
    label: "E220 CDI 1.8 (212)",
    value: "E220 CDI 1.8 (212)"
  },
  {
    SERIAL: "08LT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 BLUETEC HYBRID 2.2 CDI EXECUTIVE",
    label: "E300 BLUETEC HYBRID 2.2 CDI EXECUTIVE",
    value: "E300 BLUETEC HYBRID 2.2 CDI EXECUTIVE"
  },
  {
    SERIAL: "08LU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CL63 (216)",
    label: "CL63 (216)",
    value: "CL63 (216)"
  },
  {
    SERIAL: "08LV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 319 CDI",
    label: "SPRINTER 319 CDI",
    value: "SPRINTER 319 CDI"
  },
  {
    SERIAL: "08LW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML250 CDI SPORT 2.2",
    label: "ML250 CDI SPORT 2.2",
    value: "ML250 CDI SPORT 2.2"
  },
  {
    SERIAL: "08LX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS55",
    label: "CLS55",
    value: "CLS55"
  },
  {
    SERIAL: "08LY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "R350 (231)",
    label: "R350 (231)",
    value: "R350 (231)"
  },
  {
    SERIAL: "08LZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 HYBRID 3.0 CDI",
    label: "E300 HYBRID 3.0 CDI",
    value: "E300 HYBRID 3.0 CDI"
  },
  {
    SERIAL: "08MA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL350 3.5 (231)",
    label: "SL350 3.5 (231)",
    value: "SL350 3.5 (231)"
  },
  {
    SERIAL: "08MB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S300 BLUETEC HYBRID EXECUTIVE 2.2 CDI",
    label: "S300 BLUETEC HYBRID EXECUTIVE 2.2 CDI",
    value: "S300 BLUETEC HYBRID EXECUTIVE 2.2 CDI"
  },
  {
    SERIAL: "08MC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 COUPE 2.0 AMG (207)",
    label: "E200 COUPE 2.0 AMG (207)",
    value: "E200 COUPE 2.0 AMG (207)"
  },
  {
    SERIAL: "08MD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CGI - Import",
    label: "E200 CGI - Import",
    value: "E200 CGI - Import"
  },
  {
    SERIAL: "08ME0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA250 AMG SPORT 2.0 COUPE 4D (C117)",
    label: "CLA250 AMG SPORT 2.0 COUPE 4D (C117)",
    value: "CLA250 AMG SPORT 2.0 COUPE 4D (C117)"
  },
  {
    SERIAL: "08MF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 BARBUS",
    label: "C180 BARBUS",
    value: "C180 BARBUS"
  },
  {
    SERIAL: "08MG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 COUPE",
    label: "C200 COUPE",
    value: "C200 COUPE"
  },
  {
    SERIAL: "08MH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 BLUETEC HYBRID 2.2 CDI AMG DYNAMIC",
    label: "E300 BLUETEC HYBRID 2.2 CDI AMG DYNAMIC",
    value: "E300 BLUETEC HYBRID 2.2 CDI AMG DYNAMIC"
  },
  {
    SERIAL: "08MI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 EXECUTIVE 2.0 (212)",
    label: "E200 EXECUTIVE 2.0 (212)",
    value: "E200 EXECUTIVE 2.0 (212)"
  },
  {
    SERIAL: "08MJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CGI SALOON 2.0",
    label: "E200 CGI SALOON 2.0",
    value: "E200 CGI SALOON 2.0"
  },
  {
    SERIAL: "08ML0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "BUS (BUILT)",
    label: "BUS (BUILT)",
    value: "BUS (BUILT)"
  },
  {
    SERIAL: "08MM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GL350 BLUETEC AMG 3.0 D (166)",
    label: "GL350 BLUETEC AMG 3.0 D (166)",
    value: "GL350 BLUETEC AMG 3.0 D (166)"
  },
  {
    SERIAL: "08MN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 1.8 AMG DYNAMIC",
    label: "SLK200 1.8 AMG DYNAMIC",
    value: "SLK200 1.8 AMG DYNAMIC"
  },
  {
    SERIAL: "08MO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 COUPE BRABUS",
    label: "C180 COUPE BRABUS",
    value: "C180 COUPE BRABUS"
  },
  {
    SERIAL: "08MP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 SALOON 2.2",
    label: "E220 SALOON 2.2",
    value: "E220 SALOON 2.2"
  },
  {
    SERIAL: "08MQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B180 1.6 CDI SPORT (246)",
    label: "B180 1.6 CDI SPORT (246)",
    value: "B180 1.6 CDI SPORT (246)"
  },
  {
    SERIAL: "08MR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G350d SPORT 3.0 D",
    label: "G350d SPORT 3.0 D",
    value: "G350d SPORT 3.0 D"
  },
  {
    SERIAL: "08MS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A180 1.6 AMG (176)",
    label: "A180 1.6 AMG (176)",
    value: "A180 1.6 AMG (176)"
  },
  {
    SERIAL: "08MT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CDI 1.8",
    label: "E200 CDI 1.8",
    value: "E200 CDI 1.8"
  },
  {
    SERIAL: "08MU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A45 2.0 AMG (176)",
    label: "A45 2.0 AMG (176)",
    value: "A45 2.0 AMG (176)"
  },
  {
    SERIAL: "08MV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA45 AMG 2.0 4D COUPE (C117)",
    label: "CLA45 AMG 2.0 4D COUPE (C117)",
    value: "CLA45 AMG 2.0 4D COUPE (C117)"
  },
  {
    SERIAL: "08MW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLA200 1.6 URBAN (156)",
    label: "GLA200 1.6 URBAN (156)",
    value: "GLA200 1.6 URBAN (156)"
  },
  {
    SERIAL: "08MX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S300 BLUETEC HYBRID AMG 2.2 CDI",
    label: "S300 BLUETEC HYBRID AMG 2.2 CDI",
    value: "S300 BLUETEC HYBRID AMG 2.2 CDI"
  },
  {
    SERIAL: "08MY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S400 HYBRID AMG 3.5",
    label: "S400 HYBRID AMG 3.5",
    value: "S400 HYBRID AMG 3.5"
  },
  {
    SERIAL: "08MZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 (205)",
    label: "C250 (205)",
    value: "C250 (205)"
  },
  {
    SERIAL: "08NA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 114 (639)",
    label: "VITO 114 (639)",
    value: "VITO 114 (639)"
  },
  {
    SERIAL: "08NB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E500 COUPE",
    label: "E500 COUPE",
    value: "E500 COUPE"
  },
  {
    SERIAL: "08NC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "TRAILER",
    label: "TRAILER",
    value: "TRAILER"
  },
  {
    SERIAL: "08ND0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 EDITION 1.8",
    label: "C200 EDITION 1.8",
    value: "C200 EDITION 1.8"
  },
  {
    SERIAL: "08NE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220 EXECUTIVE 2.1 DTI",
    label: "C220 EXECUTIVE 2.1 DTI",
    value: "C220 EXECUTIVE 2.1 DTI"
  },
  {
    SERIAL: "08NF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS350 CGI 3.5TTI",
    label: "CLS350 CGI 3.5TTI",
    value: "CLS350 CGI 3.5TTI"
  },
  {
    SERIAL: "08NG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 CABRIOLET 1.8 CGI AMG (207)",
    label: "E200 CABRIOLET 1.8 CGI AMG (207)",
    value: "E200 CABRIOLET 1.8 CGI AMG (207)"
  },
  {
    SERIAL: "08NH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 NGV 1.8 (CBU) (211)",
    label: "E200 NGV 1.8 (CBU) (211)",
    value: "E200 NGV 1.8 (CBU) (211)"
  },
  {
    SERIAL: "08NI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 AVANTGARDE 2.2 CDI (211)",
    label: "E220 AVANTGARDE 2.2 CDI (211)",
    value: "E220 AVANTGARDE 2.2 CDI (211)"
  },
  {
    SERIAL: "08NJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S400 HYBRID AMG 3.5 (222)",
    label: "S400 HYBRID AMG 3.5 (222)",
    value: "S400 HYBRID AMG 3.5 (222)"
  },
  {
    SERIAL: "08NL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS350 CDI 3.0",
    label: "CLS350 CDI 3.0",
    value: "CLS350 CDI 3.0"
  },
  {
    SERIAL: "08NM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA180 1.6 (117)",
    label: "CLA180 1.6 (117)",
    value: "CLA180 1.6 (117)"
  },
  {
    SERIAL: "08NN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLA220 2.2",
    label: "GLA220 2.2",
    value: "GLA220 2.2"
  },
  {
    SERIAL: "08NO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA220 CDI 2.0 AMG",
    label: "CLA220 CDI 2.0 AMG",
    value: "CLA220 CDI 2.0 AMG"
  },
  {
    SERIAL: "08NP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 EXCLUSIVE 1.6 SEDAN (205)",
    label: "C180 EXCLUSIVE 1.6 SEDAN (205)",
    value: "C180 EXCLUSIVE 1.6 SEDAN (205)"
  },
  {
    SERIAL: "08NQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 AVANTGARDE 1.8 (212)",
    label: "E200 AVANTGARDE 1.8 (212)",
    value: "E200 AVANTGARDE 1.8 (212)"
  },
  {
    SERIAL: "08NT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL300 (230)",
    label: "SL300 (230)",
    value: "SL300 (230)"
  },
  {
    SERIAL: "08NU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C300 BLUETECH HYBRID AMG DYNAMIC",
    label: "C300 BLUETECH HYBRID AMG DYNAMIC",
    value: "C300 BLUETECH HYBRID AMG DYNAMIC"
  },
  {
    SERIAL: "08NV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C300 BLUETECH HYBRID ESTATE 2.2 (205)",
    label: "C300 BLUETECH HYBRID ESTATE 2.2 (205)",
    value: "C300 BLUETECH HYBRID ESTATE 2.2 (205)"
  },
  {
    SERIAL: "08NW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI 4D COUPE EXCLUSIVE 2.2 (218)",
    label: "CLS250 CDI 4D COUPE EXCLUSIVE 2.2 (218)",
    value: "CLS250 CDI 4D COUPE EXCLUSIVE 2.2 (218)"
  },
  {
    SERIAL: "08NX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250 CDI 4D COUPE AMG PREMIUM 2.2(218",
    label: "CLS250 CDI 4D COUPE AMG PREMIUM 2.2(218",
    value: "CLS250 CDI 4D COUPE AMG PREMIUM 2.2(218"
  },
  {
    SERIAL: "08NY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B200 1.6 CGI SPORT (246)",
    label: "B200 1.6 CGI SPORT (246)",
    value: "B200 1.6 CGI SPORT (246)"
  },
  {
    SERIAL: "08NZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 AVANTGARDE 1.6 (205)",
    label: "C180 AVANTGARDE 1.6 (205)",
    value: "C180 AVANTGARDE 1.6 (205)"
  },
  {
    SERIAL: "08OA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 1.6 2D COUPE AMG PLUS (204)",
    label: "C180 1.6 2D COUPE AMG PLUS (204)",
    value: "C180 1.6 2D COUPE AMG PLUS (204)"
  },
  {
    SERIAL: "08OB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 BLUETEC HYBRID ESTATE 2.2 CDI AMG",
    label: "E300 BLUETEC HYBRID ESTATE 2.2 CDI AMG",
    value: "E300 BLUETEC HYBRID ESTATE 2.2 CDI AMG"
  },
  {
    SERIAL: "08OC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 CARBONLOOK 1.8",
    label: "SLK200 CARBONLOOK 1.8",
    value: "SLK200 CARBONLOOK 1.8"
  },
  {
    SERIAL: "08OD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CGI SALOON 2.0",
    label: "E250 CGI SALOON 2.0",
    value: "E250 CGI SALOON 2.0"
  },
  {
    SERIAL: "08OE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA250 SHOOTING BRAKE AMG SPORT 2.0(X11 7)",
    label: "CLA250 SHOOTING BRAKE AMG SPORT 2.0(X11 7)",
    value: "CLA250 SHOOTING BRAKE AMG SPORT 2.0(X11 7)"
  },
  {
    SERIAL: "08OF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 119 CDI 2.2",
    label: "VITO 119 CDI 2.2",
    value: "VITO 119 CDI 2.2"
  },
  {
    SERIAL: "08OG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 AMG HYBRID 3.0",
    label: "S500 AMG HYBRID 3.0",
    value: "S500 AMG HYBRID 3.0"
  },
  {
    SERIAL: "08OH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A45 4.5 AMG",
    label: "A45 4.5 AMG",
    value: "A45 4.5 AMG"
  },
  {
    SERIAL: "08OI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 BLUETEC HYBRID 2.2 DIESEL",
    label: "E300 BLUETEC HYBRID 2.2 DIESEL",
    value: "E300 BLUETEC HYBRID 2.2 DIESEL"
  },
  {
    SERIAL: "08OJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 AVANTGARDE 2.0 (205)",
    label: "C200 AVANTGARDE 2.0 (205)",
    value: "C200 AVANTGARDE 2.0 (205)"
  },
  {
    SERIAL: "08OK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C63 ESTATE",
    label: "C63 ESTATE",
    value: "C63 ESTATE"
  },
  {
    SERIAL: "08OL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C63 AMG",
    label: "C63 AMG",
    value: "C63 AMG"
  },
  {
    SERIAL: "08OM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "08ON0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 CDI ESTATE 3.0",
    label: "E350 CDI ESTATE 3.0",
    value: "E350 CDI ESTATE 3.0"
  },
  {
    SERIAL: "08OO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V250 BLUETEC AVANTGARDE LONG 2.2",
    label: "V250 BLUETEC AVANTGARDE LONG 2.2",
    value: "V250 BLUETEC AVANTGARDE LONG 2.2"
  },
  {
    SERIAL: "08OQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C300 BLUETECH HYBRID EXCLUSIVE 2.2 (205",
    label: "C300 BLUETECH HYBRID EXCLUSIVE 2.2 (205",
    value: "C300 BLUETECH HYBRID EXCLUSIVE 2.2 (205"
  },
  {
    SERIAL: "08OR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 COUPE AMG PLUS 2.0 (207)",
    label: "E250 COUPE AMG PLUS 2.0 (207)",
    value: "E250 COUPE AMG PLUS 2.0 (207)"
  },
  {
    SERIAL: "08OS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLA250 AMG DYNAMIC 2.0",
    label: "GLA250 AMG DYNAMIC 2.0",
    value: "GLA250 AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08OT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "AMG GT S V8 4.0",
    label: "AMG GT S V8 4.0",
    value: "AMG GT S V8 4.0"
  },
  {
    SERIAL: "08OU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE450 AMG COUPE 4D 3.0",
    label: "GLE450 AMG COUPE 4D 3.0",
    value: "GLE450 AMG COUPE 4D 3.0"
  },
  {
    SERIAL: "08OV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE350d 4MATIC AMG DYNAMIC COUPE 3.0",
    label: "GLE350d 4MATIC AMG DYNAMIC COUPE 3.0",
    value: "GLE350d 4MATIC AMG DYNAMIC COUPE 3.0"
  },
  {
    SERIAL: "08OW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE250d EXCLUSIVE 2.2",
    label: "GLE250d EXCLUSIVE 2.2",
    value: "GLE250d EXCLUSIVE 2.2"
  },
  {
    SERIAL: "08OX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE250d AMG DYNAMIC 2.2",
    label: "GLE250d AMG DYNAMIC 2.2",
    value: "GLE250d AMG DYNAMIC 2.2"
  },
  {
    SERIAL: "08OY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A180 STYLE 1.6",
    label: "A180 STYLE 1.6",
    value: "A180 STYLE 1.6"
  },
  {
    SERIAL: "08OZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B200 URBAN 1.6",
    label: "B200 URBAN 1.6",
    value: "B200 URBAN 1.6"
  },
  {
    SERIAL: "08PB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 MAYBACH PREMIUM 4.7",
    label: "S500 MAYBACH PREMIUM 4.7",
    value: "S500 MAYBACH PREMIUM 4.7"
  },
  {
    SERIAL: "08PC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 EDITION E 2.0 (212)",
    label: "E200 EDITION E 2.0 (212)",
    value: "E200 EDITION E 2.0 (212)"
  },
  {
    SERIAL: "08PD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA200 URBAN 1.6 (C117)",
    label: "CLA200 URBAN 1.6 (C117)",
    value: "CLA200 URBAN 1.6 (C117)"
  },
  {
    SERIAL: "08PE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA250 AMG DYNAMIC 2.0 (C117)",
    label: "CLA250 AMG DYNAMIC 2.0 (C117)",
    value: "CLA250 AMG DYNAMIC 2.0 (C117)"
  },
  {
    SERIAL: "08PF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC250d 4MATIC OFF ROAD 2.2",
    label: "GLC250d 4MATIC OFF ROAD 2.2",
    value: "GLC250d 4MATIC OFF ROAD 2.2"
  },
  {
    SERIAL: "08PG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC250d 4MATIC AMG DYNAMIC 2.2",
    label: "GLC250d 4MATIC AMG DYNAMIC 2.2",
    value: "GLC250d 4MATIC AMG DYNAMIC 2.2"
  },
  {
    SERIAL: "08PH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 SPORT 2.0",
    label: "SLK200 SPORT 2.0",
    value: "SLK200 SPORT 2.0"
  },
  {
    SERIAL: "08PI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLK200 AMG DYNAMIC 2.0",
    label: "SLK200 AMG DYNAMIC 2.0",
    value: "SLK200 AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08PJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 AMG DYNAMIC CABRIOLET 2.0",
    label: "E300 AMG DYNAMIC CABRIOLET 2.0",
    value: "E300 AMG DYNAMIC CABRIOLET 2.0"
  },
  {
    SERIAL: "08PK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 COUPE SPORT 2.0 (207)",
    label: "E200 COUPE SPORT 2.0 (207)",
    value: "E200 COUPE SPORT 2.0 (207)"
  },
  {
    SERIAL: "08PL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E250 CABRIOLET AMG PLUS 2.0",
    label: "E250 CABRIOLET AMG PLUS 2.0",
    value: "E250 CABRIOLET AMG PLUS 2.0"
  },
  {
    SERIAL: "08PM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250d SHOOTING BRAKE AMG PREMIUM 2.2",
    label: "CLS250d SHOOTING BRAKE AMG PREMIUM 2.2",
    value: "CLS250d SHOOTING BRAKE AMG PREMIUM 2.2"
  },
  {
    SERIAL: "08PN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 COUPE AMG PREMIUM 4.7 (217)",
    label: "S500 COUPE AMG PREMIUM 4.7 (217)",
    value: "S500 COUPE AMG PREMIUM 4.7 (217)"
  },
  {
    SERIAL: "08PO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL400 SPORT 3.0",
    label: "SL400 SPORT 3.0",
    value: "SL400 SPORT 3.0"
  },
  {
    SERIAL: "08PP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C350 e ESTATE AMG DYNAMIC 2.0",
    label: "C350 e ESTATE AMG DYNAMIC 2.0",
    value: "C350 e ESTATE AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08PQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C350 e AMG DYNAMIC 2.0 (205)",
    label: "C350 e AMG DYNAMIC 2.0 (205)",
    value: "C350 e AMG DYNAMIC 2.0 (205)"
  },
  {
    SERIAL: "08PR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C350 e EXECLUSIVE 2.0 (205)",
    label: "C350 e EXECLUSIVE 2.0 (205)",
    value: "C350 e EXECLUSIVE 2.0 (205)"
  },
  {
    SERIAL: "08PS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500e EXECUTIVE 3.0",
    label: "S500e EXECUTIVE 3.0",
    value: "S500e EXECUTIVE 3.0"
  },
  {
    SERIAL: "08PT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500e AMG PREMIUM 3.0",
    label: "S500e AMG PREMIUM 3.0",
    value: "S500e AMG PREMIUM 3.0"
  },
  {
    SERIAL: "08PU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "ML 6.3 AMG",
    label: "ML 6.3 AMG",
    value: "ML 6.3 AMG"
  },
  {
    SERIAL: "08PV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220d EXCLUSIVE 2.0 (213)",
    label: "E220d EXCLUSIVE 2.0 (213)",
    value: "E220d EXCLUSIVE 2.0 (213)"
  },
  {
    SERIAL: "08PW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "BUS 26 SEATS",
    label: "BUS 26 SEATS",
    value: "BUS 26 SEATS"
  },
  {
    SERIAL: "08PX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 COUPE AMG DYNAMIC 2.0 (205)",
    label: "C250 COUPE AMG DYNAMIC 2.0 (205)",
    value: "C250 COUPE AMG DYNAMIC 2.0 (205)"
  },
  {
    SERIAL: "08PY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLK500",
    label: "CLK500",
    value: "CLK500"
  },
  {
    SERIAL: "08PZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C63 S COUPE 4.0 AMG (C205)",
    label: "C63 S COUPE 4.0 AMG (C205)",
    value: "C63 S COUPE 4.0 AMG (C205)"
  },
  {
    SERIAL: "08QA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220d AMG DYNAMIC 2.0 (213)",
    label: "E220d AMG DYNAMIC 2.0 (213)",
    value: "E220d AMG DYNAMIC 2.0 (213)"
  },
  {
    SERIAL: "08QB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "714",
    label: "714",
    value: "714"
  },
  {
    SERIAL: "08QC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS220 CDI 2.1",
    label: "CLS220 CDI 2.1",
    value: "CLS220 CDI 2.1"
  },
  {
    SERIAL: "08QD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLA45 AMG 2.0",
    label: "GLA45 AMG 2.0",
    value: "GLA45 AMG 2.0"
  },
  {
    SERIAL: "08QE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S63 AMG 5.5",
    label: "S63 AMG 5.5",
    value: "S63 AMG 5.5"
  },
  {
    SERIAL: "08QF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLS AMG ROADSTER 6.2",
    label: "SLS AMG ROADSTER 6.2",
    value: "SLS AMG ROADSTER 6.2"
  },
  {
    SERIAL: "08QG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C350e AVANTGARDE 2.0 (205)",
    label: "C350e AVANTGARDE 2.0 (205)",
    value: "C350e AVANTGARDE 2.0 (205)"
  },
  {
    SERIAL: "08QH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 COUPE EDITION 1 2.0 (205)",
    label: "C250 COUPE EDITION 1 2.0 (205)",
    value: "C250 COUPE EDITION 1 2.0 (205)"
  },
  {
    SERIAL: "08QI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC250d AMG PLUS 2.2 COUPE",
    label: "GLC250d AMG PLUS 2.2 COUPE",
    value: "GLC250d AMG PLUS 2.2 COUPE"
  },
  {
    SERIAL: "08QJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLC300 AMG DYNAMIC 2.0",
    label: "SLC300 AMG DYNAMIC 2.0",
    value: "SLC300 AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08QK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250d EXCLUSIVE 2.2",
    label: "CLS250d EXCLUSIVE 2.2",
    value: "CLS250d EXCLUSIVE 2.2"
  },
  {
    SERIAL: "08QL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS250d AMG PREMIUM 2.2",
    label: "CLS250d AMG PREMIUM 2.2",
    value: "CLS250d AMG PREMIUM 2.2"
  },
  {
    SERIAL: "08QM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500e EXCLUSIVE 3.0",
    label: "S500e EXCLUSIVE 3.0",
    value: "S500e EXCLUSIVE 3.0"
  },
  {
    SERIAL: "08QN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLS350d AMG PREMIUM 3.0",
    label: "GLS350d AMG PREMIUM 3.0",
    value: "GLS350d AMG PREMIUM 3.0"
  },
  {
    SERIAL: "08QO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V250 d 2.2",
    label: "V250 d 2.2",
    value: "V250 d 2.2"
  },
  {
    SERIAL: "08QP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE500E AMG DYNAMIC 4WD 3.0",
    label: "GLE500E AMG DYNAMIC 4WD 3.0",
    value: "GLE500E AMG DYNAMIC 4WD 3.0"
  },
  {
    SERIAL: "08QQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE500E EXCLUSIVE 4WD 3.0",
    label: "GLE500E EXCLUSIVE 4WD 3.0",
    value: "GLE500E EXCLUSIVE 4WD 3.0"
  },
  {
    SERIAL: "08QR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 CABRIOLET AMG PREMIUM 4.7",
    label: "S500 CABRIOLET AMG PREMIUM 4.7",
    value: "S500 CABRIOLET AMG PREMIUM 4.7"
  },
  {
    SERIAL: "08QS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL400 AMG PREMIUM 3.0",
    label: "SL400 AMG PREMIUM 3.0",
    value: "SL400 AMG PREMIUM 3.0"
  },
  {
    SERIAL: "08QT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLC43 CONVERTIBLE AMG 3.0",
    label: "SLC43 CONVERTIBLE AMG 3.0",
    value: "SLC43 CONVERTIBLE AMG 3.0"
  },
  {
    SERIAL: "08QU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 d AMG SPORT 2.0 (213)",
    label: "E220 d AMG SPORT 2.0 (213)",
    value: "E220 d AMG SPORT 2.0 (213)"
  },
  {
    SERIAL: "08QV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 WALD 2.0 (212)",
    label: "E200 WALD 2.0 (212)",
    value: "E200 WALD 2.0 (212)"
  },
  {
    SERIAL: "08QW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA220 CDI 2.2",
    label: "CLA220 CDI 2.2",
    value: "CLA220 CDI 2.2"
  },
  {
    SERIAL: "08QX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "VITO 116 TOURER SELECT 2.2",
    label: "VITO 116 TOURER SELECT 2.2",
    value: "VITO 116 TOURER SELECT 2.2"
  },
  {
    SERIAL: "08QY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500 MAYBACH EXCLUSIVE 4.7",
    label: "S500 MAYBACH EXCLUSIVE 4.7",
    value: "S500 MAYBACH EXCLUSIVE 4.7"
  },
  {
    SERIAL: "08QZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA180 URBAN 1.6 (C117)",
    label: "CLA180 URBAN 1.6 (C117)",
    value: "CLA180 URBAN 1.6 (C117)"
  },
  {
    SERIAL: "08RA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC250d 4MATIC OFF ROAD 2.2",
    label: "GLC250d 4MATIC OFF ROAD 2.2",
    value: "GLC250d 4MATIC OFF ROAD 2.2"
  },
  {
    SERIAL: "08RB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "B240",
    label: "B240",
    value: "B240"
  },
  {
    SERIAL: "08RC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "BUS (Cover 3)",
    label: "BUS (Cover 3)",
    value: "BUS (Cover 3)"
  },
  {
    SERIAL: "08RD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "350 SL",
    label: "350 SL",
    value: "350 SL"
  },
  {
    SERIAL: "08RE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "08RF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350e AMG DYNAMIC 2.0",
    label: "E350e AMG DYNAMIC 2.0",
    value: "E350e AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08RG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "TRCK 12000",
    label: "TRCK 12000",
    value: "TRCK 12000"
  },
  {
    SERIAL: "08RH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C300 CABRIOLET AMG DYNAMIC 2.0 (A205)",
    label: "C300 CABRIOLET AMG DYNAMIC 2.0 (A205)",
    value: "C300 CABRIOLET AMG DYNAMIC 2.0 (A205)"
  },
  {
    SERIAL: "08RI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C43 AMG 4MATIC 3.0 COUPE (205)",
    label: "C43 AMG 4MATIC 3.0 COUPE (205)",
    value: "C43 AMG 4MATIC 3.0 COUPE (205)"
  },
  {
    SERIAL: "08RJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE43 AMG WAGON 4WD 3.0 COUPE",
    label: "GLE43 AMG WAGON 4WD 3.0 COUPE",
    value: "GLE43 AMG WAGON 4WD 3.0 COUPE"
  },
  {
    SERIAL: "08RK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 ESTATE AMG DYNAMIC 2.0",
    label: "E220 ESTATE AMG DYNAMIC 2.0",
    value: "E220 ESTATE AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08RL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GT S AMG 4.0",
    label: "GT S AMG 4.0",
    value: "GT S AMG 4.0"
  },
  {
    SERIAL: "08RM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300 AMG DYNAMIC COUPE 2.0",
    label: "E300 AMG DYNAMIC COUPE 2.0",
    value: "E300 AMG DYNAMIC COUPE 2.0"
  },
  {
    SERIAL: "08RN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350e AVANTGARDE 2.0",
    label: "E350e AVANTGARDE 2.0",
    value: "E350e AVANTGARDE 2.0"
  },
  {
    SERIAL: "08RO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350e EXCLUSIVE 2.0",
    label: "E350e EXCLUSIVE 2.0",
    value: "E350e EXCLUSIVE 2.0"
  },
  {
    SERIAL: "08RP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C250 COUPE SPORT 2.0 (205)",
    label: "C250 COUPE SPORT 2.0 (205)",
    value: "C250 COUPE SPORT 2.0 (205)"
  },
  {
    SERIAL: "08RQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC43 AMG SEDAN COUPE 3.0",
    label: "GLC43 AMG SEDAN COUPE 3.0",
    value: "GLC43 AMG SEDAN COUPE 3.0"
  },
  {
    SERIAL: "08RR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 319 CDI HIGH ROOF",
    label: "SPRINTER 319 CDI HIGH ROOF",
    value: "SPRINTER 319 CDI HIGH ROOF"
  },
  {
    SERIAL: "08RS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C240 (203)",
    label: "C240 (203)",
    value: "C240 (203)"
  },
  {
    SERIAL: "08RT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 316",
    label: "SPRINTER 316",
    value: "SPRINTER 316"
  },
  {
    SERIAL: "08RU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC250 4MATIC AMG PLUS 2.2 COUPE 4DR",
    label: "GLC250 4MATIC AMG PLUS 2.2 COUPE 4DR",
    value: "GLC250 4MATIC AMG PLUS 2.2 COUPE 4DR"
  },
  {
    SERIAL: "08RV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350D AMG PREMIUM 3.0",
    label: "S350D AMG PREMIUM 3.0",
    value: "S350D AMG PREMIUM 3.0"
  },
  {
    SERIAL: "08RW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S560 COUPE AMG PREMIUM 4.0 (217)",
    label: "S560 COUPE AMG PREMIUM 4.0 (217)",
    value: "S560 COUPE AMG PREMIUM 4.0 (217)"
  },
  {
    SERIAL: "08RX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G63 AMG",
    label: "G63 AMG",
    value: "G63 AMG"
  },
  {
    SERIAL: "08RY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SL320",
    label: "SL320",
    value: "SL320"
  },
  {
    SERIAL: "08RZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SLS AMG FANAL EDITION ROADSTER 6.2",
    label: "SLS AMG FANAL EDITION ROADSTER 6.2",
    value: "SLS AMG FANAL EDITION ROADSTER 6.2"
  },
  {
    SERIAL: "08SA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S65 AMG",
    label: "S65 AMG",
    value: "S65 AMG"
  },
  {
    SERIAL: "08SB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "2000 CC",
    label: "2000 CC",
    value: "2000 CC"
  },
  {
    SERIAL: "08SC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "OVER 2000 CC",
    label: "OVER 2000 CC",
    value: "OVER 2000 CC"
  },
  {
    SERIAL: "08SD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS300D AMG PREMIUM 2.0",
    label: "CLS300D AMG PREMIUM 2.0",
    value: "CLS300D AMG PREMIUM 2.0"
  },
  {
    SERIAL: "08SE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S560 AMG PREMIUM CABRIOLET 4.0 (217)",
    label: "S560 AMG PREMIUM CABRIOLET 4.0 (217)",
    value: "S560 AMG PREMIUM CABRIOLET 4.0 (217)"
  },
  {
    SERIAL: "08SF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 AMG DYNAMIC 2.0 COUPE",
    label: "E200 AMG DYNAMIC 2.0 COUPE",
    value: "E200 AMG DYNAMIC 2.0 COUPE"
  },
  {
    SERIAL: "08SG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S350D EXCLUSIVE 3.0",
    label: "S350D EXCLUSIVE 3.0",
    value: "S350D EXCLUSIVE 3.0"
  },
  {
    SERIAL: "08SH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "UNIMOG",
    label: "UNIMOG",
    value: "UNIMOG"
  },
  {
    SERIAL: "08SI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E63 S 4MATIC+ AMG 4.0",
    label: "E63 S 4MATIC+ AMG 4.0",
    value: "E63 S 4MATIC+ AMG 4.0"
  },
  {
    SERIAL: "08SJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S560 PREMIUM MAYBACH 4.0",
    label: "S560 PREMIUM MAYBACH 4.0",
    value: "S560 PREMIUM MAYBACH 4.0"
  },
  {
    SERIAL: "08SK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220D AVANTGARDE 2.0",
    label: "C220D AVANTGARDE 2.0",
    value: "C220D AVANTGARDE 2.0"
  },
  {
    SERIAL: "08SL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220D EXCLUSIVE 2.0",
    label: "C220D EXCLUSIVE 2.0",
    value: "C220D EXCLUSIVE 2.0"
  },
  {
    SERIAL: "08SM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C220D AMG DYNAMIC 2.0",
    label: "C220D AMG DYNAMIC 2.0",
    value: "C220D AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08SN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 AMG DYNAMIC COUPE 1.5 2DR.",
    label: "C200 AMG DYNAMIC COUPE 1.5 2DR.",
    value: "C200 AMG DYNAMIC COUPE 1.5 2DR."
  },
  {
    SERIAL: "08SO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 519",
    label: "SPRINTER 519",
    value: "SPRINTER 519"
  },
  {
    SERIAL: "08SP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS53 AMG 3.0",
    label: "CLS53 AMG 3.0",
    value: "CLS53 AMG 3.0"
  },
  {
    SERIAL: "08SQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C180 COUPE (204)",
    label: "C180 COUPE (204)",
    value: "C180 COUPE (204)"
  },
  {
    SERIAL: "08SR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E350 ESTATE 3.0",
    label: "E350 ESTATE 3.0",
    value: "E350 ESTATE 3.0"
  },
  {
    SERIAL: "08SS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLS53 AMG 3.0 (CKD)",
    label: "CLS53 AMG 3.0 (CKD)",
    value: "CLS53 AMG 3.0 (CKD)"
  },
  {
    SERIAL: "08ST0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E53 AMG 3.0 COUPE (238)",
    label: "E53 AMG 3.0 COUPE (238)",
    value: "E53 AMG 3.0 COUPE (238)"
  },
  {
    SERIAL: "08SU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 513",
    label: "SPRINTER 513",
    value: "SPRINTER 513"
  },
  {
    SERIAL: "08SV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC250 4MATIC COUPE AMG PLUS 2.0",
    label: "GLC250 4MATIC COUPE AMG PLUS 2.0",
    value: "GLC250 4MATIC COUPE AMG PLUS 2.0"
  },
  {
    SERIAL: "08SW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC250 4MATIC COUPE 2.2",
    label: "GLC250 4MATIC COUPE 2.2",
    value: "GLC250 4MATIC COUPE 2.2"
  },
  {
    SERIAL: "08SX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S560E AMG PREMIUM 3.0",
    label: "S560E AMG PREMIUM 3.0",
    value: "S560E AMG PREMIUM 3.0"
  },
  {
    SERIAL: "08SY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "AMG GT63 4.0",
    label: "AMG GT63 4.0",
    value: "AMG GT63 4.0"
  },
  {
    SERIAL: "08SZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC220D 2.1",
    label: "GLC220D 2.1",
    value: "GLC220D 2.1"
  },
  {
    SERIAL: "08TA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "300SEL",
    label: "300SEL",
    value: "300SEL"
  },
  {
    SERIAL: "08TB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLE300D 4MATIC AMG DYNAMIC 2.0",
    label: "GLE300D 4MATIC AMG DYNAMIC 2.0",
    value: "GLE300D 4MATIC AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08TC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A200 AMG DYNAMIC 1.3",
    label: "A200 AMG DYNAMIC 1.3",
    value: "A200 AMG DYNAMIC 1.3"
  },
  {
    SERIAL: "08TD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C300E AVANTGARDE 2.0",
    label: "C300E AVANTGARDE 2.0",
    value: "C300E AVANTGARDE 2.0"
  },
  {
    SERIAL: "08TE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C300E AMG DYNAMIC 2.0",
    label: "C300E AMG DYNAMIC 2.0",
    value: "C300E AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08TF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V220D BUSINESS 2.2",
    label: "V220D BUSINESS 2.2",
    value: "V220D BUSINESS 2.2"
  },
  {
    SERIAL: "08TG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V220D AVANTGARDE PREMIUM 2.2",
    label: "V220D AVANTGARDE PREMIUM 2.2",
    value: "V220D AVANTGARDE PREMIUM 2.2"
  },
  {
    SERIAL: "08TH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E53 AMG 3.0 SEDAN (213)",
    label: "E53 AMG 3.0 SEDAN (213)",
    value: "E53 AMG 3.0 SEDAN (213)"
  },
  {
    SERIAL: "08TI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC220D AMG DYNAMIC 2.0",
    label: "GLC220D AMG DYNAMIC 2.0",
    value: "GLC220D AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08TJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E200 (213)",
    label: "E200 (213)",
    value: "E200 (213)"
  },
  {
    SERIAL: "08TK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC220D 2.0",
    label: "GLC220D 2.0",
    value: "GLC220D 2.0"
  },
  {
    SERIAL: "08TL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC220D 4MATIC AMG DYNAMIC COUPE 2.0",
    label: "GLC220D 4MATIC AMG DYNAMIC COUPE 2.0",
    value: "GLC220D 4MATIC AMG DYNAMIC COUPE 2.0"
  },
  {
    SERIAL: "08TM0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300E AVANTGARDE 2.0",
    label: "E300E AVANTGARDE 2.0",
    value: "E300E AVANTGARDE 2.0"
  },
  {
    SERIAL: "08TN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300E EXCLUSIVE 2.0",
    label: "E300E EXCLUSIVE 2.0",
    value: "E300E EXCLUSIVE 2.0"
  },
  {
    SERIAL: "08TO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "S500E HYBRID 3.0",
    label: "S500E HYBRID 3.0",
    value: "S500E HYBRID 3.0"
  },
  {
    SERIAL: "08TP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E300E AMG DYNAMIC 2.0",
    label: "E300E AMG DYNAMIC 2.0",
    value: "E300E AMG DYNAMIC 2.0"
  },
  {
    SERIAL: "08TQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLS350D AMG PREMIUM 3.0",
    label: "GLS350D AMG PREMIUM 3.0",
    value: "GLS350D AMG PREMIUM 3.0"
  },
  {
    SERIAL: "08TR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 AMG DYNAMIC COUPE 2.0 2DR.",
    label: "C200 AMG DYNAMIC COUPE 2.0 2DR.",
    value: "C200 AMG DYNAMIC COUPE 2.0 2DR."
  },
  {
    SERIAL: "08TS0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G350D 3.0 (GREY)",
    label: "G350D 3.0 (GREY)",
    value: "G350D 3.0 (GREY)"
  },
  {
    SERIAL: "08TT0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A45 S AMG 2.0",
    label: "A45 S AMG 2.0",
    value: "A45 S AMG 2.0"
  },
  {
    SERIAL: "08TU0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "AIR STREAM INTERSTATE",
    label: "AIR STREAM INTERSTATE",
    value: "AIR STREAM INTERSTATE"
  },
  {
    SERIAL: "08TV0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "CLA45 AMG 2.0 S 4D COUPE (C118)",
    label: "CLA45 AMG 2.0 S 4D COUPE (C118)",
    value: "CLA45 AMG 2.0 S 4D COUPE (C118)"
  },
  {
    SERIAL: "08TX0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C200 D 2.0",
    label: "C200 D 2.0",
    value: "C200 D 2.0"
  },
  {
    SERIAL: "08TY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G350 SPORT BODY STYLING 2.9",
    label: "G350 SPORT BODY STYLING 2.9",
    value: "G350 SPORT BODY STYLING 2.9"
  },
  {
    SERIAL: "08TZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V250 BUSINESS 2.1DTTI",
    label: "V250 BUSINESS 2.1DTTI",
    value: "V250 BUSINESS 2.1DTTI"
  },
  {
    SERIAL: "08UA0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 419 CDI",
    label: "SPRINTER 419 CDI",
    value: "SPRINTER 419 CDI"
  },
  {
    SERIAL: "08UB0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C43 AMG 4MATIC 3.0 SEDAN",
    label: "C43 AMG 4MATIC 3.0 SEDAN",
    value: "C43 AMG 4MATIC 3.0 SEDAN"
  },
  {
    SERIAL: "08UC0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GT53 4MATIC 4D COUPE 3.0",
    label: "GT53 4MATIC 4D COUPE 3.0",
    value: "GT53 4MATIC 4D COUPE 3.0"
  },
  {
    SERIAL: "08UD0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GT63 S 4MATIC 4D COUPE 4.0",
    label: "GT63 S 4MATIC 4D COUPE 4.0",
    value: "GT63 S 4MATIC 4D COUPE 4.0"
  },
  {
    SERIAL: "08UE0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLB200 1.3",
    label: "GLB200 1.3",
    value: "GLB200 1.3"
  },
  {
    SERIAL: "08UF0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "C300E AMG SPORT 2.0",
    label: "C300E AMG SPORT 2.0",
    value: "C300E AMG SPORT 2.0"
  },
  {
    SERIAL: "08UG0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLC300E 4MATIC AMG DYNAMIC COUPE 2.0",
    label: "GLC300E 4MATIC AMG DYNAMIC COUPE 2.0",
    value: "GLC300E 4MATIC AMG DYNAMIC COUPE 2.0"
  },
  {
    SERIAL: "08UH0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 419 CDI LONG WHEEL BASE",
    label: "SPRINTER 419 CDI LONG WHEEL BASE",
    value: "SPRINTER 419 CDI LONG WHEEL BASE"
  },
  {
    SERIAL: "08UI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "A200 PROGRESSIVE 1.3",
    label: "A200 PROGRESSIVE 1.3",
    value: "A200 PROGRESSIVE 1.3"
  },
  {
    SERIAL: "08UJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLA200 AMG DYNAMIC 1.3",
    label: "GLA200 AMG DYNAMIC 1.3",
    value: "GLA200 AMG DYNAMIC 1.3"
  },
  {
    SERIAL: "08UK0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 416 CDI BUSINESS",
    label: "SPRINTER 416 CDI BUSINESS",
    value: "SPRINTER 416 CDI BUSINESS"
  },
  {
    SERIAL: "08UL0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "SPRINTER 316",
    label: "SPRINTER 316",
    value: "SPRINTER 316"
  },
  {
    SERIAL: "08UN0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GT C 4.0 ROADSTER",
    label: "GT C 4.0 ROADSTER",
    value: "GT C 4.0 ROADSTER"
  },
  {
    SERIAL: "08UO0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GT R AMG 4.0",
    label: "GT R AMG 4.0",
    value: "GT R AMG 4.0"
  },
  {
    SERIAL: "08UP0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GT S AMG 4.0 COUPE",
    label: "GT S AMG 4.0 COUPE",
    value: "GT S AMG 4.0 COUPE"
  },
  {
    SERIAL: "08UQ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V250 AVANTGARDE PREMIUM 2.1DTTI",
    label: "V250 AVANTGARDE PREMIUM 2.1DTTI",
    value: "V250 AVANTGARDE PREMIUM 2.1DTTI"
  },
  {
    SERIAL: "08UR0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "V250 BUSINESS PLUS 2.1D TTI",
    label: "V250 BUSINESS PLUS 2.1D TTI",
    value: "V250 BUSINESS PLUS 2.1D TTI"
  },
  {
    SERIAL: "08UW0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "GLA35 4MATIC AMG",
    label: "GLA35 4MATIC AMG",
    value: "GLA35 4MATIC AMG"
  },
  {
    SERIAL: "08WI0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220 D SPORT",
    label: "E220 D SPORT",
    value: "E220 D SPORT"
  },
  {
    SERIAL: "08WJ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "E220D AMG SPORT 2.0",
    label: "E220D AMG SPORT 2.0",
    value: "E220D AMG SPORT 2.0"
  },
  {
    SERIAL: "08WY0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G63 AMG EDITION 1",
    label: "G63 AMG EDITION 1",
    value: "G63 AMG EDITION 1"
  },
  {
    SERIAL: "08WZ0000026",
    MAKE_NAME: "BENZ",
    MODEL_NAME: "G63 AMG 4.0",
    label: "G63 AMG 4.0",
    value: "G63 AMG 4.0"
  },
  {
    SERIAL: "9000000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "VOLVO",
    label: "VOLVO",
    value: "VOLVO"
  },
  {
    SERIAL: "9030000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "244 DL 2.3",
    label: "244 DL 2.3",
    value: "244 DL 2.3"
  },
  {
    SERIAL: "9040000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "244 GLT 2.3",
    label: "244 GLT 2.3",
    value: "244 GLT 2.3"
  },
  {
    SERIAL: "9060000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "740 GL 2.3",
    label: "740 GL 2.3",
    value: "740 GL 2.3"
  },
  {
    SERIAL: "9070000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "740 GLE 2.3",
    label: "740 GLE 2.3",
    value: "740 GLE 2.3"
  },
  {
    SERIAL: "9090000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "760 GLE 2.3",
    label: "760 GLE 2.3",
    value: "760 GLE 2.3"
  },
  {
    SERIAL: "9120000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "440 GLT 2.0 A",
    label: "440 GLT 2.0 A",
    value: "440 GLT 2.0 A"
  },
  {
    SERIAL: "9150000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "460 GLE 2.0 A",
    label: "460 GLE 2.0 A",
    value: "460 GLE 2.0 A"
  },
  {
    SERIAL: "9170000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "460 GLT 2.0 A",
    label: "460 GLT 2.0 A",
    value: "460 GLT 2.0 A"
  },
  {
    SERIAL: "9180000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "460 GLT 2.0 M",
    label: "460 GLT 2.0 M",
    value: "460 GLT 2.0 M"
  },
  {
    SERIAL: "9200000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "850 GLE 2.4 A",
    label: "850 GLE 2.4 A",
    value: "850 GLE 2.4 A"
  },
  {
    SERIAL: "9210000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "850 GLE 2.4 M",
    label: "850 GLE 2.4 M",
    value: "850 GLE 2.4 M"
  },
  {
    SERIAL: "9230000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "850 GLT 2.3 A",
    label: "850 GLT 2.3 A",
    value: "850 GLT 2.3 A"
  },
  {
    SERIAL: "9240000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "850 GLT 2.3 M",
    label: "850 GLT 2.3 M",
    value: "850 GLT 2.3 M"
  },
  {
    SERIAL: "9250000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "850 SI A",
    label: "850 SI A",
    value: "850 SI A"
  },
  {
    SERIAL: "9260000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "850 TURBO CBU SPORT WAGON A",
    label: "850 TURBO CBU SPORT WAGON A",
    value: "850 TURBO CBU SPORT WAGON A"
  },
  {
    SERIAL: "9280000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "940 GL 2.3 A",
    label: "940 GL 2.3 A",
    value: "940 GL 2.3 A"
  },
  {
    SERIAL: "9290000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "940 GLE 2.3 A",
    label: "940 GLE 2.3 A",
    value: "940 GLE 2.3 A"
  },
  {
    SERIAL: "9300000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "940 ESTATE A",
    label: "940 ESTATE A",
    value: "940 ESTATE A"
  },
  {
    SERIAL: "9310000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "940 SE A",
    label: "940 SE A",
    value: "940 SE A"
  },
  {
    SERIAL: "9320000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "940 SE M",
    label: "940 SE M",
    value: "940 SE M"
  },
  {
    SERIAL: "9340000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "960 2.4 A",
    label: "960 2.4 A",
    value: "960 2.4 A"
  },
  {
    SERIAL: "9350000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "960 3.0 A",
    label: "960 3.0 A",
    value: "960 3.0 A"
  },
  {
    SERIAL: "9360000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "960 3.0 EXECUTIVE",
    label: "960 3.0 EXECUTIVE",
    value: "960 3.0 EXECUTIVE"
  },
  {
    SERIAL: "9370000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "940 GLT",
    label: "940 GLT",
    value: "940 GLT"
  },
  {
    SERIAL: "9380000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "240 GL",
    label: "240 GL",
    value: "240 GL"
  },
  {
    SERIAL: "9400000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 40",
    label: "S 40",
    value: "S 40"
  },
  {
    SERIAL: "9410000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 40",
    label: "V 40",
    value: "V 40"
  },
  {
    SERIAL: "9430000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 70 2.3 (A)",
    label: "S 70 2.3 (A)",
    value: "S 70 2.3 (A)"
  },
  {
    SERIAL: "9440000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 70 2.3",
    label: "V 70 2.3",
    value: "V 70 2.3"
  },
  {
    SERIAL: "9450000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 2.4",
    label: "S 90 2.4",
    value: "S 90 2.4"
  },
  {
    SERIAL: "9460000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 3.0",
    label: "S 90 3.0",
    value: "S 90 3.0"
  },
  {
    SERIAL: "9470000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.3",
    label: "S 80 2.3",
    value: "S 80 2.3"
  },
  {
    SERIAL: "9500000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 70 2.3 T AUTO",
    label: "S 70 2.3 T AUTO",
    value: "S 70 2.3 T AUTO"
  },
  {
    SERIAL: "9510000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 70 2.3 A",
    label: "V 70 2.3 A",
    value: "V 70 2.3 A"
  },
  {
    SERIAL: "9520000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 40 A",
    label: "S 40 A",
    value: "S 40 A"
  },
  {
    SERIAL: "9530000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 T6",
    label: "S 80 T6",
    value: "S 80 T6"
  },
  {
    SERIAL: "9540000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 70 2.3 T5",
    label: "S 70 2.3 T5",
    value: "S 70 2.3 T5"
  },
  {
    SERIAL: "9550000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.9",
    label: "S 80 2.9",
    value: "S 80 2.9"
  },
  {
    SERIAL: "9560000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 2.0",
    label: "S 60 2.0",
    value: "S 60 2.0"
  },
  {
    SERIAL: "9570000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 2.3",
    label: "S 60 2.3",
    value: "S 60 2.3"
  },
  {
    SERIAL: "9600000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 70 CROSS COUNTRY",
    label: "V 70 CROSS COUNTRY",
    value: "V 70 CROSS COUNTRY"
  },
  {
    SERIAL: "9610000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "หัวลาก",
    label: "หัวลาก",
    value: "หัวลาก"
  },
  {
    SERIAL: "9620000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T6 4WD 2.9",
    label: "XC 90 T6 4WD 2.9",
    value: "XC 90 T6 4WD 2.9"
  },
  {
    SERIAL: "9630000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 2.4 4WD",
    label: "XC 90 2.4 4WD",
    value: "XC 90 2.4 4WD"
  },
  {
    SERIAL: "9640000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 70 2.3",
    label: "XC 70 2.3",
    value: "XC 70 2.3"
  },
  {
    SERIAL: "9650000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 70 XC 2.3",
    label: "V 70 XC 2.3",
    value: "V 70 XC 2.3"
  },
  {
    SERIAL: "9660000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "FL",
    label: "FL",
    value: "FL"
  },
  {
    SERIAL: "9670000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.4 D5",
    label: "S 80 2.4 D5",
    value: "S 80 2.4 D5"
  },
  {
    SERIAL: "9680000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 70 D5(DIESEL) 2.4",
    label: "V 70 D5(DIESEL) 2.4",
    value: "V 70 D5(DIESEL) 2.4"
  },
  {
    SERIAL: "9690000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 70 (2.0) T",
    label: "V 70 (2.0) T",
    value: "V 70 (2.0) T"
  },
  {
    SERIAL: "9700000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.0 T",
    label: "S 80 2.0 T",
    value: "S 80 2.0 T"
  },
  {
    SERIAL: "9720000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "C 70 CONVERTABLE 2.4",
    label: "C 70 CONVERTABLE 2.4",
    value: "C 70 CONVERTABLE 2.4"
  },
  {
    SERIAL: "9730000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.4",
    label: "S 80 2.4",
    value: "S 80 2.4"
  },
  {
    SERIAL: "9740000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "C 70 CONVERTIBLE T5 2.5",
    label: "C 70 CONVERTIBLE T5 2.5",
    value: "C 70 CONVERTIBLE T5 2.5"
  },
  {
    SERIAL: "9750000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.4 DIESEL",
    label: "S 80 2.4 DIESEL",
    value: "S 80 2.4 DIESEL"
  },
  {
    SERIAL: "9760000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.5 T BENZIN",
    label: "S 80 2.5 T BENZIN",
    value: "S 80 2.5 T BENZIN"
  },
  {
    SERIAL: "9770000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 3.2",
    label: "S 80 3.2",
    value: "S 80 3.2"
  },
  {
    SERIAL: "9780000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "C 30 COUPE 2.4",
    label: "C 30 COUPE 2.4",
    value: "C 30 COUPE 2.4"
  },
  {
    SERIAL: "9790000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 D5 2.4 AWD",
    label: "XC 90 D5 2.4 AWD",
    value: "XC 90 D5 2.4 AWD"
  },
  {
    SERIAL: "9800000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "9810000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 2.4 D5",
    label: "S 60 2.4 D5",
    value: "S 60 2.4 D5"
  },
  {
    SERIAL: "9820000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "C 30 1.8",
    label: "C 30 1.8",
    value: "C 30 1.8"
  },
  {
    SERIAL: "9830000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.5 FT",
    label: "S 80 2.5 FT",
    value: "S 80 2.5 FT"
  },
  {
    SERIAL: "9850000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 40",
    label: "S 40",
    value: "S 40"
  },
  {
    SERIAL: "9860000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 50",
    label: "V 50",
    value: "V 50"
  },
  {
    SERIAL: "9870000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 2.4",
    label: "XC 60 2.4",
    value: "XC 60 2.4"
  },
  {
    SERIAL: "9880000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "FM13GZT",
    label: "FM13GZT",
    value: "FM13GZT"
  },
  {
    SERIAL: "9890000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "9920000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 2.0 T",
    label: "S 60 2.0 T",
    value: "S 60 2.0 T"
  },
  {
    SERIAL: "9940000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 2.0 D3",
    label: "XC 60 2.0 D3",
    value: "XC 60 2.0 D3"
  },
  {
    SERIAL: "9960000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 1.6 B",
    label: "S 60 1.6 B",
    value: "S 60 1.6 B"
  },
  {
    SERIAL: "9970000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.0 D3",
    label: "S 80 2.0 D3",
    value: "S 80 2.0 D3"
  },
  {
    SERIAL: "9980000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.0 T",
    label: "S 80 2.0 T",
    value: "S 80 2.0 T"
  },
  {
    SERIAL: "9990000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 1.6 S",
    label: "S 60 1.6 S",
    value: "S 60 1.6 S"
  },
  {
    SERIAL: "09AA0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 1.6 T4F",
    label: "V 60 1.6 T4F",
    value: "V 60 1.6 T4F"
  },
  {
    SERIAL: "09AB0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "C 30 2.0 R DESIGN",
    label: "C 30 2.0 R DESIGN",
    value: "C 30 2.0 R DESIGN"
  },
  {
    SERIAL: "09AC0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "FM13",
    label: "FM13",
    value: "FM13"
  },
  {
    SERIAL: "09AD0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "FM1162",
    label: "FM1162",
    value: "FM1162"
  },
  {
    SERIAL: "09AE0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 D4 2.0",
    label: "XC 60 D4 2.0",
    value: "XC 60 D4 2.0"
  },
  {
    SERIAL: "09AF0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 1.6 TC",
    label: "S 80 1.6 TC",
    value: "S 80 1.6 TC"
  },
  {
    SERIAL: "09AG0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.0 D4",
    label: "S 80 2.0 D4",
    value: "S 80 2.0 D4"
  },
  {
    SERIAL: "09AH0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 40 2.0 S",
    label: "V 40 2.0 S",
    value: "V 40 2.0 S"
  },
  {
    SERIAL: "09AJ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 T5 2.0",
    label: "XC 60 T5 2.0",
    value: "XC 60 T5 2.0"
  },
  {
    SERIAL: "09AK0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "C30 2.0 E",
    label: "C30 2.0 E",
    value: "C30 2.0 E"
  },
  {
    SERIAL: "09AL0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "C30 2.0 G",
    label: "C30 2.0 G",
    value: "C30 2.0 G"
  },
  {
    SERIAL: "09AM0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.0 T4",
    label: "S 80 2.0 T4",
    value: "S 80 2.0 T4"
  },
  {
    SERIAL: "09AN0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 40 B",
    label: "V 40 B",
    value: "V 40 B"
  },
  {
    SERIAL: "09AO0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 40 CROSS COUNTRY",
    label: "V 40 CROSS COUNTRY",
    value: "V 40 CROSS COUNTRY"
  },
  {
    SERIAL: "09AP0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 70 T SE",
    label: "V 70 T SE",
    value: "V 70 T SE"
  },
  {
    SERIAL: "09AQ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 2.4 - IMPORT",
    label: "S 60 2.4 - IMPORT",
    value: "S 60 2.4 - IMPORT"
  },
  {
    SERIAL: "09AR0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 2.0T T5",
    label: "S 60 2.0T T5",
    value: "S 60 2.0T T5"
  },
  {
    SERIAL: "09AS0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 R-DESIGN STYLINGE KIT 1.6T",
    label: "S 60 R-DESIGN STYLINGE KIT 1.6T",
    value: "S 60 R-DESIGN STYLINGE KIT 1.6T"
  },
  {
    SERIAL: "09AT0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 R-DESIGN STYLING KIT 1.6T",
    label: "V 60 R-DESIGN STYLING KIT 1.6T",
    value: "V 60 R-DESIGN STYLING KIT 1.6T"
  },
  {
    SERIAL: "09AU0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 2.0 T4 SPORT PACKAGE TC",
    label: "S 80 2.0 T4 SPORT PACKAGE TC",
    value: "S 80 2.0 T4 SPORT PACKAGE TC"
  },
  {
    SERIAL: "09AV0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "FH1362",
    label: "FH1362",
    value: "FH1362"
  },
  {
    SERIAL: "09AW0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "GWE11 - หัวลาก (ปกติไม่รับประกัน)",
    label: "GWE11 - หัวลาก (ปกติไม่รับประกัน)",
    value: "GWE11 - หัวลาก (ปกติไม่รับประกัน)"
  },
  {
    SERIAL: "09AX0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 40 T5 R LIMITED 2.0",
    label: "V 40 T5 R LIMITED 2.0",
    value: "V 40 T5 R LIMITED 2.0"
  },
  {
    SERIAL: "09AY0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 T5 2.0",
    label: "V 60 T5 2.0",
    value: "V 60 T5 2.0"
  },
  {
    SERIAL: "09AZ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 D5 2.0 MOMENTUM",
    label: "XC 90 D5 2.0 MOMENTUM",
    value: "XC 90 D5 2.0 MOMENTUM"
  },
  {
    SERIAL: "09BA0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 2.0 HYBRID MOMENTUM",
    label: "XC 90 T8 2.0 HYBRID MOMENTUM",
    value: "XC 90 T8 2.0 HYBRID MOMENTUM"
  },
  {
    SERIAL: "09BB0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 2.0 HYBRID INSCRIPTION",
    label: "XC 90 T8 2.0 HYBRID INSCRIPTION",
    value: "XC 90 T8 2.0 HYBRID INSCRIPTION"
  },
  {
    SERIAL: "09BC0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "JPCZM30D",
    label: "JPCZM30D",
    value: "JPCZM30D"
  },
  {
    SERIAL: "09BD0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 D4 2.0",
    label: "V 60 D4 2.0",
    value: "V 60 D4 2.0"
  },
  {
    SERIAL: "09BE0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 D4 2.0",
    label: "S 60 D4 2.0",
    value: "S 60 D4 2.0"
  },
  {
    SERIAL: "09BF0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 T4F 1.6",
    label: "S 60 T4F 1.6",
    value: "S 60 T4F 1.6"
  },
  {
    SERIAL: "09BG0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 D4 I-ART 2.0",
    label: "XC 60 D4 I-ART 2.0",
    value: "XC 60 D4 I-ART 2.0"
  },
  {
    SERIAL: "09BH0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 80 D4 2.0 I-ART",
    label: "S 80 D4 2.0 I-ART",
    value: "S 80 D4 2.0 I-ART"
  },
  {
    SERIAL: "09BI0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 40 D4 CROSS COUNTRY 2.0",
    label: "V 40 D4 CROSS COUNTRY 2.0",
    value: "V 40 D4 CROSS COUNTRY 2.0"
  },
  {
    SERIAL: "09BJ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 D4 INSCRIPTION 2.0",
    label: "S 90 D4 INSCRIPTION 2.0",
    value: "S 90 D4 INSCRIPTION 2.0"
  },
  {
    SERIAL: "09BK0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V40 T4 2.0",
    label: "V40 T4 2.0",
    value: "V40 T4 2.0"
  },
  {
    SERIAL: "09BL0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 90 D4 INSCRIPTION 2.0",
    label: "V 90 D4 INSCRIPTION 2.0",
    value: "V 90 D4 INSCRIPTION 2.0"
  },
  {
    SERIAL: "09BM0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 D4 DYNAMIC EDITION 2.0",
    label: "XC 60 D4 DYNAMIC EDITION 2.0",
    value: "XC 60 D4 DYNAMIC EDITION 2.0"
  },
  {
    SERIAL: "09BN0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 T5 POLESTAR 2.0",
    label: "S 60 T5 POLESTAR 2.0",
    value: "S 60 T5 POLESTAR 2.0"
  },
  {
    SERIAL: "09BO0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 T5 POLESTAR 2.0",
    label: "V 60 T5 POLESTAR 2.0",
    value: "V 60 T5 POLESTAR 2.0"
  },
  {
    SERIAL: "09BP0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "870 GLE",
    label: "870 GLE",
    value: "870 GLE"
  },
  {
    SERIAL: "09BQ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "2000 CC",
    label: "2000 CC",
    value: "2000 CC"
  },
  {
    SERIAL: "09BR0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 D4 MOMENTUM 2.0",
    label: "S 90 D4 MOMENTUM 2.0",
    value: "S 90 D4 MOMENTUM 2.0"
  },
  {
    SERIAL: "09BS0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 T8 MOMENTUM 2.0 AWD",
    label: "S 90 T8 MOMENTUM 2.0 AWD",
    value: "S 90 T8 MOMENTUM 2.0 AWD"
  },
  {
    SERIAL: "09BT0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 T8 INSCRIPTION 2.0 AWD HYBRID",
    label: "S 90 T8 INSCRIPTION 2.0 AWD HYBRID",
    value: "S 90 T8 INSCRIPTION 2.0 AWD HYBRID"
  },
  {
    SERIAL: "09BU0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 40 T4 2.0",
    label: "V 40 T4 2.0",
    value: "V 40 T4 2.0"
  },
  {
    SERIAL: "09BV0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 T8 MOMENTUM 2.0 AWD",
    label: "XC 60 T8 MOMENTUM 2.0 AWD",
    value: "XC 60 T8 MOMENTUM 2.0 AWD"
  },
  {
    SERIAL: "09BW0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 T8 R-DESIGN 2.0 AWD HYBRID",
    label: "XC 60 T8 R-DESIGN 2.0 AWD HYBRID",
    value: "XC 60 T8 R-DESIGN 2.0 AWD HYBRID"
  },
  {
    SERIAL: "09BX0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 MOMENTUM 2.0 AWD HYBRID",
    label: "XC 90 T8 MOMENTUM 2.0 AWD HYBRID",
    value: "XC 90 T8 MOMENTUM 2.0 AWD HYBRID"
  },
  {
    SERIAL: "09BY0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 INSCRIPTION 2.0 AWD HYBRID",
    label: "XC 90 T8 INSCRIPTION 2.0 AWD HYBRID",
    value: "XC 90 T8 INSCRIPTION 2.0 AWD HYBRID"
  },
  {
    SERIAL: "09BZ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 D4 MOMENTUM 2.0 AWD",
    label: "XC 60 D4 MOMENTUM 2.0 AWD",
    value: "XC 60 D4 MOMENTUM 2.0 AWD"
  },
  {
    SERIAL: "09CA0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 D3 2.0",
    label: "S 60 D3 2.0",
    value: "S 60 D3 2.0"
  },
  {
    SERIAL: "09CB0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 D3 2.0",
    label: "V 60 D3 2.0",
    value: "V 60 D3 2.0"
  },
  {
    SERIAL: "09CC0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    label: "XC 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    value: "XC 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CD0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 T8 R-DESIGN 2.0 AWD HYBRID",
    label: "S 90 T8 R-DESIGN 2.0 AWD HYBRID",
    value: "S 90 T8 R-DESIGN 2.0 AWD HYBRID"
  },
  {
    SERIAL: "09CE0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 D4 DYNAMIC EDITION 2.0",
    label: "V 60 D4 DYNAMIC EDITION 2.0",
    value: "V 60 D4 DYNAMIC EDITION 2.0"
  },
  {
    SERIAL: "09CF0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 40 T4 MOMENTUM 2.0",
    label: "XC 40 T4 MOMENTUM 2.0",
    value: "XC 40 T4 MOMENTUM 2.0"
  },
  {
    SERIAL: "09CG0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 40 T5 R-DESIGN 2.0 AWD",
    label: "XC 40 T5 R-DESIGN 2.0 AWD",
    value: "XC 40 T5 R-DESIGN 2.0 AWD"
  },
  {
    SERIAL: "09CH0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 R-DESIGN 2.0 AWD HYBRID",
    label: "XC 90 T8 R-DESIGN 2.0 AWD HYBRID",
    value: "XC 90 T8 R-DESIGN 2.0 AWD HYBRID"
  },
  {
    SERIAL: "09CI0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 EXCELLENCE 2.0 AWD PLUG IN HYB RID",
    label: "XC 90 T8 EXCELLENCE 2.0 AWD PLUG IN HYB RID",
    value: "XC 90 T8 EXCELLENCE 2.0 AWD PLUG IN HYB RID"
  },
  {
    SERIAL: "09CJ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 40 T5 INSCRIPTION 2.0 AWD HYBRID",
    label: "XC 40 T5 INSCRIPTION 2.0 AWD HYBRID",
    value: "XC 40 T5 INSCRIPTION 2.0 AWD HYBRID"
  },
  {
    SERIAL: "09CK0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 T8 MOMENTUM 2.0 PLUG IN HYBRID",
    label: "V 60 T8 MOMENTUM 2.0 PLUG IN HYBRID",
    value: "V 60 T8 MOMENTUM 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CL0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    label: "V 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    value: "V 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CM0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 T8 MOMENTUM 2.0 PLUG IN HYBRID",
    label: "S 60 T8 MOMENTUM 2.0 PLUG IN HYBRID",
    value: "S 60 T8 MOMENTUM 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CN0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 T8 R-DESIGN 2.0 PLUG IN HYBRID",
    label: "S 60 T8 R-DESIGN 2.0 PLUG IN HYBRID",
    value: "S 60 T8 R-DESIGN 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CO0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 T8 POLESTAR 2.0 PLUG IN HYBRID",
    label: "XC 60 T8 POLESTAR 2.0 PLUG IN HYBRID",
    value: "XC 60 T8 POLESTAR 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CP0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 R-DESIGN EXPRESSION 2.0 PLUG I N HYBRID",
    label: "XC 90 T8 R-DESIGN EXPRESSION 2.0 PLUG I N HYBRID",
    value: "XC 90 T8 R-DESIGN EXPRESSION 2.0 PLUG I N HYBRID"
  },
  {
    SERIAL: "09CQ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 T8 R-DESIGN EXPRESSION 2.0 AWD HV",
    label: "XC 60 T8 R-DESIGN EXPRESSION 2.0 AWD HV",
    value: "XC 60 T8 R-DESIGN EXPRESSION 2.0 AWD HV"
  },
  {
    SERIAL: "09CR0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 40 T5 R-DESIGN EXPRESSION 1.5 A PHEV",
    label: "XC 40 T5 R-DESIGN EXPRESSION 1.5 A PHEV",
    value: "XC 40 T5 R-DESIGN EXPRESSION 1.5 A PHEV"
  },
  {
    SERIAL: "09CS0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 T8 R-DESIGN EXPRESSION 2.0 AWD PHE V",
    label: "V 60 T8 R-DESIGN EXPRESSION 2.0 AWD PHE V",
    value: "V 60 T8 R-DESIGN EXPRESSION 2.0 AWD PHE V"
  },
  {
    SERIAL: "09CT0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 60 T8 INSCRIPTION EXPRESSION 2.0 PHEV",
    label: "S 60 T8 INSCRIPTION EXPRESSION 2.0 PHEV",
    value: "S 60 T8 INSCRIPTION EXPRESSION 2.0 PHEV"
  },
  {
    SERIAL: "09CU0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 40 T5 R-DESIGN 1.5 A PLUG IN HYBRID",
    label: "XC 40 T5 R-DESIGN 1.5 A PLUG IN HYBRID",
    value: "XC 40 T5 R-DESIGN 1.5 A PLUG IN HYBRID"
  },
  {
    SERIAL: "09CV0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 40 T5 INSCRIPTION 1.5 A PLUG IN HYBR ID",
    label: "XC 40 T5 INSCRIPTION 1.5 A PLUG IN HYBR ID",
    value: "XC 40 T5 INSCRIPTION 1.5 A PLUG IN HYBR ID"
  },
  {
    SERIAL: "09CW0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "S 90 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    label: "S 90 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    value: "S 90 T8 INSCRIPTION 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CX0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "V 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    label: "V 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID",
    value: "V 60 T8 INSCRIPTION 2.0 PLUG IN HYBRID"
  },
  {
    SERIAL: "09CY0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 60 T8 R-DESIGN 2.0 AWD PLUG IN HYBRI D",
    label: "XC 60 T8 R-DESIGN 2.0 AWD PLUG IN HYBRI D",
    value: "XC 60 T8 R-DESIGN 2.0 AWD PLUG IN HYBRI D"
  },
  {
    SERIAL: "09CZ0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 R-DESIGN 2.0 AWD PLUG IN HYBRI D",
    label: "XC 90 T8 R-DESIGN 2.0 AWD PLUG IN HYBRI D",
    value: "XC 90 T8 R-DESIGN 2.0 AWD PLUG IN HYBRI D"
  },
  {
    SERIAL: "09DA0000026",
    MAKE_NAME: "VOLVO",
    MODEL_NAME: "XC 90 T8 INSCRIPTION 2.0 AWD PLUG IN HY BRID",
    label: "XC 90 T8 INSCRIPTION 2.0 AWD PLUG IN HY BRID",
    value: "XC 90 T8 INSCRIPTION 2.0 AWD PLUG IN HY BRID"
  },
  {
    SERIAL: "10000000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "PEUGEOT",
    label: "PEUGEOT",
    value: "PEUGEOT"
  },
  {
    SERIAL: "10020000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "205 GRi 1.4 M",
    label: "205 GRi 1.4 M",
    value: "205 GRi 1.4 M"
  },
  {
    SERIAL: "10100000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "306 SR 1.8 M",
    label: "306 SR 1.8 M",
    value: "306 SR 1.8 M"
  },
  {
    SERIAL: "10110000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "306 SR 1.8 A",
    label: "306 SR 1.8 A",
    value: "306 SR 1.8 A"
  },
  {
    SERIAL: "10120000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "306 CABRIOLET 2.0 A",
    label: "306 CABRIOLET 2.0 A",
    value: "306 CABRIOLET 2.0 A"
  },
  {
    SERIAL: "10160000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "405 GR 2.0",
    label: "405 GR 2.0",
    value: "405 GR 2.0"
  },
  {
    SERIAL: "10170000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "405 GR 1.6",
    label: "405 GR 1.6",
    value: "405 GR 1.6"
  },
  {
    SERIAL: "10180000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "405 GRi 1.6 M",
    label: "405 GRi 1.6 M",
    value: "405 GRi 1.6 M"
  },
  {
    SERIAL: "10210000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "605",
    label: "605",
    value: "605"
  },
  {
    SERIAL: "10220000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "605 SRi M",
    label: "605 SRi M",
    value: "605 SRi M"
  },
  {
    SERIAL: "10260000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "504",
    label: "504",
    value: "504"
  },
  {
    SERIAL: "10270000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "504 PICK UP",
    label: "504 PICK UP",
    value: "504 PICK UP"
  },
  {
    SERIAL: "10280000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "505",
    label: "505",
    value: "505"
  },
  {
    SERIAL: "10290000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "406 V2",
    label: "406 V2",
    value: "406 V2"
  },
  {
    SERIAL: "10300000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "406 M",
    label: "406 M",
    value: "406 M"
  },
  {
    SERIAL: "10310000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "406 A",
    label: "406 A",
    value: "406 A"
  },
  {
    SERIAL: "10370000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "406 COUPE",
    label: "406 COUPE",
    value: "406 COUPE"
  },
  {
    SERIAL: "10380000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "406 ST",
    label: "406 ST",
    value: "406 ST"
  },
  {
    SERIAL: "10440000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "405 SRI A",
    label: "405 SRI A",
    value: "405 SRI A"
  },
  {
    SERIAL: "10450000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "605 SRI A",
    label: "605 SRI A",
    value: "605 SRI A"
  },
  {
    SERIAL: "10460000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "206 CC CABRIOLET",
    label: "206 CC CABRIOLET",
    value: "206 CC CABRIOLET"
  },
  {
    SERIAL: "10470000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "607",
    label: "607",
    value: "607"
  },
  {
    SERIAL: "10480000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "307 XS 5D",
    label: "307 XS 5D",
    value: "307 XS 5D"
  },
  {
    SERIAL: "10490000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "807 SL",
    label: "807 SL",
    value: "807 SL"
  },
  {
    SERIAL: "10500000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "406 EA9",
    label: "406 EA9",
    value: "406 EA9"
  },
  {
    SERIAL: "10510000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "PARTNER",
    label: "PARTNER",
    value: "PARTNER"
  },
  {
    SERIAL: "10520000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "206 XS",
    label: "206 XS",
    value: "206 XS"
  },
  {
    SERIAL: "10530000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "307 CC CABRIOLET",
    label: "307 CC CABRIOLET",
    value: "307 CC CABRIOLET"
  },
  {
    SERIAL: "10540000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "405 GR 2.0",
    label: "405 GR 2.0",
    value: "405 GR 2.0"
  },
  {
    SERIAL: "10560000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "406 L5",
    label: "406 L5",
    value: "406 L5"
  },
  {
    SERIAL: "10570000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "407 4D ST 2.2 BENZINE",
    label: "407 4D ST 2.2 BENZINE",
    value: "407 4D ST 2.2 BENZINE"
  },
  {
    SERIAL: "10590000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "407 5D SW SPORT 2.2 BENZINE",
    label: "407 5D SW SPORT 2.2 BENZINE",
    value: "407 5D SW SPORT 2.2 BENZINE"
  },
  {
    SERIAL: "10600000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "407 5D DIESEL 2.0",
    label: "407 5D DIESEL 2.0",
    value: "407 5D DIESEL 2.0"
  },
  {
    SERIAL: "10610000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "1007",
    label: "1007",
    value: "1007"
  },
  {
    SERIAL: "10620000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "407 3.0 COUPE",
    label: "407 3.0 COUPE",
    value: "407 3.0 COUPE"
  },
  {
    SERIAL: "10630000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "207 1.6 A",
    label: "207 1.6 A",
    value: "207 1.6 A"
  },
  {
    SERIAL: "10640000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "207 1.6 A SPORT",
    label: "207 1.6 A SPORT",
    value: "207 1.6 A SPORT"
  },
  {
    SERIAL: "10650000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "207 1.6 M GT",
    label: "207 1.6 M GT",
    value: "207 1.6 M GT"
  },
  {
    SERIAL: "10660000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "207 CC CABRIOLET",
    label: "207 CC CABRIOLET",
    value: "207 CC CABRIOLET"
  },
  {
    SERIAL: "10670000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "EXPERT 2.0 (11SEAT)",
    label: "EXPERT 2.0 (11SEAT)",
    value: "EXPERT 2.0 (11SEAT)"
  },
  {
    SERIAL: "10680000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "407 4D ST DIESEL 2.0",
    label: "407 4D ST DIESEL 2.0",
    value: "407 4D ST DIESEL 2.0"
  },
  {
    SERIAL: "10690000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "407 5D SW EXECUTIVE DIESEL 2.0",
    label: "407 5D SW EXECUTIVE DIESEL 2.0",
    value: "407 5D SW EXECUTIVE DIESEL 2.0"
  },
  {
    SERIAL: "10700000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "206 5D 1.4 SPORT AUTO",
    label: "206 5D 1.4 SPORT AUTO",
    value: "206 5D 1.4 SPORT AUTO"
  },
  {
    SERIAL: "10710000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "407 4D ST 2.0 BENZINE",
    label: "407 4D ST 2.0 BENZINE",
    value: "407 4D ST 2.0 BENZINE"
  },
  {
    SERIAL: "10720000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "308 5D 1.6",
    label: "308 5D 1.6",
    value: "308 5D 1.6"
  },
  {
    SERIAL: "10730000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "308 5D 1.6 VTI",
    label: "308 5D 1.6 VTI",
    value: "308 5D 1.6 VTI"
  },
  {
    SERIAL: "10740000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "308 CABRIOLET 1.6 TURBO",
    label: "308 CABRIOLET 1.6 TURBO",
    value: "308 CABRIOLET 1.6 TURBO"
  },
  {
    SERIAL: "10750000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "BIPPER 5 (TRUCK) 1.4 DIESEL",
    label: "BIPPER 5 (TRUCK) 1.4 DIESEL",
    value: "BIPPER 5 (TRUCK) 1.4 DIESEL"
  },
  {
    SERIAL: "10760000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "BIPPER METRO 1.4 DIESEL (SEDAN)",
    label: "BIPPER METRO 1.4 DIESEL (SEDAN)",
    value: "BIPPER METRO 1.4 DIESEL (SEDAN)"
  },
  {
    SERIAL: "10770000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "207 SEDAN 1.6",
    label: "207 SEDAN 1.6",
    value: "207 SEDAN 1.6"
  },
  {
    SERIAL: "10780000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "RCZ SPORT",
    label: "RCZ SPORT",
    value: "RCZ SPORT"
  },
  {
    SERIAL: "10790000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "RCZ COUPE GT",
    label: "RCZ COUPE GT",
    value: "RCZ COUPE GT"
  },
  {
    SERIAL: "10800000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "408 1.6 T",
    label: "408 1.6 T",
    value: "408 1.6 T"
  },
  {
    SERIAL: "10810000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "508 2.0",
    label: "508 2.0",
    value: "508 2.0"
  },
  {
    SERIAL: "10820000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "408 2.0",
    label: "408 2.0",
    value: "408 2.0"
  },
  {
    SERIAL: "10830000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "807 HDI 2.0T",
    label: "807 HDI 2.0T",
    value: "807 HDI 2.0T"
  },
  {
    SERIAL: "10840000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "807 HDI SUNROOF 2.0T",
    label: "807 HDI SUNROOF 2.0T",
    value: "807 HDI SUNROOF 2.0T"
  },
  {
    SERIAL: "10850000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "EXPERT 11 SEAT 2.0DT",
    label: "EXPERT 11 SEAT 2.0DT",
    value: "EXPERT 11 SEAT 2.0DT"
  },
  {
    SERIAL: "10860000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "3008 1.6T",
    label: "3008 1.6T",
    value: "3008 1.6T"
  },
  {
    SERIAL: "10870000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "308 SW WAGON 1.6",
    label: "308 SW WAGON 1.6",
    value: "308 SW WAGON 1.6"
  },
  {
    SERIAL: "10880000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "408 E-THP 1.6",
    label: "408 E-THP 1.6",
    value: "408 E-THP 1.6"
  },
  {
    SERIAL: "10890000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "403",
    label: "403",
    value: "403"
  },
  {
    SERIAL: "10900000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "208 5D. 1.2",
    label: "208 5D. 1.2",
    value: "208 5D. 1.2"
  },
  {
    SERIAL: "10910000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "308 SW 1.6",
    label: "308 SW 1.6",
    value: "308 SW 1.6"
  },
  {
    SERIAL: "10920000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "3008 SUV 2.0",
    label: "3008 SUV 2.0",
    value: "3008 SUV 2.0"
  },
  {
    SERIAL: "10930000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "5008 1.6 ALLURE",
    label: "5008 1.6 ALLURE",
    value: "5008 1.6 ALLURE"
  },
  {
    SERIAL: "10940000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "3008 1.6 ACTIVE",
    label: "3008 1.6 ACTIVE",
    value: "3008 1.6 ACTIVE"
  },
  {
    SERIAL: "10950000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "3008 1.6 ALLURE",
    label: "3008 1.6 ALLURE",
    value: "3008 1.6 ALLURE"
  },
  {
    SERIAL: "10960000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "5008 1.6 ACTIVE",
    label: "5008 1.6 ACTIVE",
    value: "5008 1.6 ACTIVE"
  },
  {
    SERIAL: "10970000026",
    MAKE_NAME: "PEUGEOT",
    MODEL_NAME: "DJANGO 150 CC.",
    label: "DJANGO 150 CC.",
    value: "DJANGO 150 CC."
  },
  {
    SERIAL: "11000000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "HYUNDAI",
    label: "HYUNDAI",
    value: "HYUNDAI"
  },
  {
    SERIAL: "11020000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ACCENT SPRINT LI M 1.5",
    label: "ACCENT SPRINT LI M 1.5",
    value: "ACCENT SPRINT LI M 1.5"
  },
  {
    SERIAL: "11030000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ACCENT LS 1.5",
    label: "ACCENT LS 1.5",
    value: "ACCENT LS 1.5"
  },
  {
    SERIAL: "11040000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ACCENT GLS 1.5 M",
    label: "ACCENT GLS 1.5 M",
    value: "ACCENT GLS 1.5 M"
  },
  {
    SERIAL: "11050000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ACCENT GLI 1.5 M",
    label: "ACCENT GLI 1.5 M",
    value: "ACCENT GLI 1.5 M"
  },
  {
    SERIAL: "11090000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA GLS 1.6 M",
    label: "ELANTRA GLS 1.6 M",
    value: "ELANTRA GLS 1.6 M"
  },
  {
    SERIAL: "11100000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA GLS 1.6 A",
    label: "ELANTRA GLS 1.6 A",
    value: "ELANTRA GLS 1.6 A"
  },
  {
    SERIAL: "11110000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA GLS 1.8 M",
    label: "ELANTRA GLS 1.8 M",
    value: "ELANTRA GLS 1.8 M"
  },
  {
    SERIAL: "11120000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA GLS 1.8 A",
    label: "ELANTRA GLS 1.8 A",
    value: "ELANTRA GLS 1.8 A"
  },
  {
    SERIAL: "11140000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA WAGON GLS 1.6 M",
    label: "ELANTRA WAGON GLS 1.6 M",
    value: "ELANTRA WAGON GLS 1.6 M"
  },
  {
    SERIAL: "11200000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA GL 2.0 M",
    label: "SONATA GL 2.0 M",
    value: "SONATA GL 2.0 M"
  },
  {
    SERIAL: "11210000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA GL 2.0 A",
    label: "SONATA GL 2.0 A",
    value: "SONATA GL 2.0 A"
  },
  {
    SERIAL: "11220000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA GLS 2.0 M",
    label: "SONATA GLS 2.0 M",
    value: "SONATA GLS 2.0 M"
  },
  {
    SERIAL: "11230000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA GLS 2.0 A",
    label: "SONATA GLS 2.0 A",
    value: "SONATA GLS 2.0 A"
  },
  {
    SERIAL: "11250000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA GLS ABS 2.0 A",
    label: "SONATA GLS ABS 2.0 A",
    value: "SONATA GLS ABS 2.0 A"
  },
  {
    SERIAL: "11290000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "EXCEL LS 1.3",
    label: "EXCEL LS 1.3",
    value: "EXCEL LS 1.3"
  },
  {
    SERIAL: "11300000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "EXCEL LS 1.5 M",
    label: "EXCEL LS 1.5 M",
    value: "EXCEL LS 1.5 M"
  },
  {
    SERIAL: "11320000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "EXCEL GLS 1.5 M",
    label: "EXCEL GLS 1.5 M",
    value: "EXCEL GLS 1.5 M"
  },
  {
    SERIAL: "11330000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "EXCEL GLS 1.5 A",
    label: "EXCEL GLS 1.5 A",
    value: "EXCEL GLS 1.5 A"
  },
  {
    SERIAL: "11370000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SCOUPE TURBO 1.5",
    label: "SCOUPE TURBO 1.5",
    value: "SCOUPE TURBO 1.5"
  },
  {
    SERIAL: "11430000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA 2.0 GLS (M)",
    label: "SONATA 2.0 GLS (M)",
    value: "SONATA 2.0 GLS (M)"
  },
  {
    SERIAL: "11440000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA 2.0 GLS (A)",
    label: "SONATA 2.0 GLS (A)",
    value: "SONATA 2.0 GLS (A)"
  },
  {
    SERIAL: "11450000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA AVANTE SEDAN 1.6GLS M",
    label: "ELANTRA AVANTE SEDAN 1.6GLS M",
    value: "ELANTRA AVANTE SEDAN 1.6GLS M"
  },
  {
    SERIAL: "11470000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA AVANTE SPORTY TOURING 1.6 GLS M",
    label: "ELANTRA AVANTE SPORTY TOURING 1.6 GLS M",
    value: "ELANTRA AVANTE SPORTY TOURING 1.6 GLS M"
  },
  {
    SERIAL: "11570000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ACCENT 1.5 GLI 5DRS AUTO",
    label: "ACCENT 1.5 GLI 5DRS AUTO",
    value: "ACCENT 1.5 GLI 5DRS AUTO"
  },
  {
    SERIAL: "11580000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ACCENT 1.5 GLS 4DRS AUTO",
    label: "ACCENT 1.5 GLS 4DRS AUTO",
    value: "ACCENT 1.5 GLS 4DRS AUTO"
  },
  {
    SERIAL: "11590000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ACCENT 1.5 GLS 5DRS AUTO",
    label: "ACCENT 1.5 GLS 5DRS AUTO",
    value: "ACCENT 1.5 GLS 5DRS AUTO"
  },
  {
    SERIAL: "11680000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "TIBURON TYPE R 2.0 (M)",
    label: "TIBURON TYPE R 2.0 (M)",
    value: "TIBURON TYPE R 2.0 (M)"
  },
  {
    SERIAL: "11700000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "GRAND SONATA 2.0 GL (M)",
    label: "GRAND SONATA 2.0 GL (M)",
    value: "GRAND SONATA 2.0 GL (M)"
  },
  {
    SERIAL: "11710000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "GRAND SONATA 2.0 GL (A)",
    label: "GRAND SONATA 2.0 GL (A)",
    value: "GRAND SONATA 2.0 GL (A)"
  },
  {
    SERIAL: "11720000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "GRAND SONATA 2.0 GLS (M)",
    label: "GRAND SONATA 2.0 GLS (M)",
    value: "GRAND SONATA 2.0 GLS (M)"
  },
  {
    SERIAL: "11730000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "GRAND SONATA 2.0 GLS (A)",
    label: "GRAND SONATA 2.0 GLS (A)",
    value: "GRAND SONATA 2.0 GLS (A)"
  },
  {
    SERIAL: "11750000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SANTA E 2.7 GL",
    label: "SANTA E 2.7 GL",
    value: "SANTA E 2.7 GL"
  },
  {
    SERIAL: "11770000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA ELEGANCE 2.0 A",
    label: "SONATA ELEGANCE 2.0 A",
    value: "SONATA ELEGANCE 2.0 A"
  },
  {
    SERIAL: "11780000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA SPORT 2.0 A",
    label: "SONATA SPORT 2.0 A",
    value: "SONATA SPORT 2.0 A"
  },
  {
    SERIAL: "11800000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA EXECUTIVE 2.4 A",
    label: "SONATA EXECUTIVE 2.4 A",
    value: "SONATA EXECUTIVE 2.4 A"
  },
  {
    SERIAL: "11810000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SANTAFE 2.2 DIESEL",
    label: "SANTAFE 2.2 DIESEL",
    value: "SANTAFE 2.2 DIESEL"
  },
  {
    SERIAL: "11830000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 TOURING (11SEAT)",
    label: "H1 TOURING (11SEAT)",
    value: "H1 TOURING (11SEAT)"
  },
  {
    SERIAL: "11840000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 MAESTO EXECUTIVE (11SEAT)",
    label: "H1 MAESTO EXECUTIVE (11SEAT)",
    value: "H1 MAESTO EXECUTIVE (11SEAT)"
  },
  {
    SERIAL: "11850000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA 2.0 EL CNG",
    label: "SONATA 2.0 EL CNG",
    value: "SONATA 2.0 EL CNG"
  },
  {
    SERIAL: "11860000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA 2.0 SP CNG",
    label: "SONATA 2.0 SP CNG",
    value: "SONATA 2.0 SP CNG"
  },
  {
    SERIAL: "11870000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H-100",
    label: "H-100",
    value: "H-100"
  },
  {
    SERIAL: "11880000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "COUPE 2.0",
    label: "COUPE 2.0",
    value: "COUPE 2.0"
  },
  {
    SERIAL: "11890000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 MAESTO SS",
    label: "H1 MAESTO SS",
    value: "H1 MAESTO SS"
  },
  {
    SERIAL: "11900000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 MAESTO DELUXE",
    label: "H1 MAESTO DELUXE",
    value: "H1 MAESTO DELUXE"
  },
  {
    SERIAL: "11910000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "TUCSON 2 WD. 2.0 S",
    label: "TUCSON 2 WD. 2.0 S",
    value: "TUCSON 2 WD. 2.0 S"
  },
  {
    SERIAL: "11920000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "TUCSON 4WD 2.0 G",
    label: "TUCSON 4WD 2.0 G",
    value: "TUCSON 4WD 2.0 G"
  },
  {
    SERIAL: "11930000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "GRAND STAREX 2.5",
    label: "GRAND STAREX 2.5",
    value: "GRAND STAREX 2.5"
  },
  {
    SERIAL: "11940000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA SPORT S 2.0",
    label: "SONATA SPORT S 2.0",
    value: "SONATA SPORT S 2.0"
  },
  {
    SERIAL: "11950000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "SONATA SPORT G 2.0",
    label: "SONATA SPORT G 2.0",
    value: "SONATA SPORT G 2.0"
  },
  {
    SERIAL: "11960000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA 1.8S",
    label: "ELANTRA 1.8S",
    value: "ELANTRA 1.8S"
  },
  {
    SERIAL: "11970000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA 1.8E",
    label: "ELANTRA 1.8E",
    value: "ELANTRA 1.8E"
  },
  {
    SERIAL: "11980000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA 1.8G",
    label: "ELANTRA 1.8G",
    value: "ELANTRA 1.8G"
  },
  {
    SERIAL: "11990000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "GRAND STAREX VIP 2.5",
    label: "GRAND STAREX VIP 2.5",
    value: "GRAND STAREX VIP 2.5"
  },
  {
    SERIAL: "11AA0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "TUCSON 4WD 2.0 D",
    label: "TUCSON 4WD 2.0 D",
    value: "TUCSON 4WD 2.0 D"
  },
  {
    SERIAL: "11AB0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "VELOSTER 1.6",
    label: "VELOSTER 1.6",
    value: "VELOSTER 1.6"
  },
  {
    SERIAL: "11AC0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "VELOSTER SPORT TURBO 1.6",
    label: "VELOSTER SPORT TURBO 1.6",
    value: "VELOSTER SPORT TURBO 1.6"
  },
  {
    SERIAL: "11AD0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 ELITE",
    label: "H1 ELITE",
    value: "H1 ELITE"
  },
  {
    SERIAL: "11AE0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 GRAND MAESTO 2.5",
    label: "H1 GRAND MAESTO 2.5",
    value: "H1 GRAND MAESTO 2.5"
  },
  {
    SERIAL: "11AF0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 GLS",
    label: "H1 GLS",
    value: "H1 GLS"
  },
  {
    SERIAL: "11AG0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA SPORT 1.8 GL",
    label: "ELANTRA SPORT 1.8 GL",
    value: "ELANTRA SPORT 1.8 GL"
  },
  {
    SERIAL: "11AH0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA SPORT 1.8 GLE",
    label: "ELANTRA SPORT 1.8 GLE",
    value: "ELANTRA SPORT 1.8 GLE"
  },
  {
    SERIAL: "11AI0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA SPORT 1.8 GLS NAVIGATOR",
    label: "ELANTRA SPORT 1.8 GLS NAVIGATOR",
    value: "ELANTRA SPORT 1.8 GLS NAVIGATOR"
  },
  {
    SERIAL: "11AJ0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "ELANTRA SPORT SE 1.8",
    label: "ELANTRA SPORT SE 1.8",
    value: "ELANTRA SPORT SE 1.8"
  },
  {
    SERIAL: "11AK0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 LIMITED",
    label: "H1 LIMITED",
    value: "H1 LIMITED"
  },
  {
    SERIAL: "11AL0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "GRAND STAREX PREMIUM 2.5",
    label: "GRAND STAREX PREMIUM 2.5",
    value: "GRAND STAREX PREMIUM 2.5"
  },
  {
    SERIAL: "11AM0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "IONIQ EV",
    label: "IONIQ EV",
    value: "IONIQ EV"
  },
  {
    SERIAL: "11AN0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "KONA SE (EV)",
    label: "KONA SE (EV)",
    value: "KONA SE (EV)"
  },
  {
    SERIAL: "11AO0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "KONA SEL (EV)",
    label: "KONA SEL (EV)",
    value: "KONA SEL (EV)"
  },
  {
    SERIAL: "11AP0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 LIMITED",
    label: "H1 LIMITED",
    value: "H1 LIMITED"
  },
  {
    SERIAL: "11AQ0000026",
    MAKE_NAME: "HYUNDAI",
    MODEL_NAME: "H1 IMPRESSIVE 2.5",
    label: "H1 IMPRESSIVE 2.5",
    value: "H1 IMPRESSIVE 2.5"
  },
  {
    SERIAL: "12000000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "VOLKSWAGEN",
    label: "VOLKSWAGEN",
    value: "VOLKSWAGEN"
  },
  {
    SERIAL: "12040000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.8",
    label: "CARAVELLE 2.8",
    value: "CARAVELLE 2.8"
  },
  {
    SERIAL: "12050000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2500 GL",
    label: "CARAVELLE 2500 GL",
    value: "CARAVELLE 2500 GL"
  },
  {
    SERIAL: "12060000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE GL SYNCRO",
    label: "CARAVELLE GL SYNCRO",
    value: "CARAVELLE GL SYNCRO"
  },
  {
    SERIAL: "12080000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TRANSPORTER",
    label: "TRANSPORTER",
    value: "TRANSPORTER"
  },
  {
    SERIAL: "12090000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT 2.0 A",
    label: "PASSAT 2.0 A",
    value: "PASSAT 2.0 A"
  },
  {
    SERIAL: "12110000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "VENTO 1.8 M",
    label: "VENTO 1.8 M",
    value: "VENTO 1.8 M"
  },
  {
    SERIAL: "12120000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "VENTO 1.8 A",
    label: "VENTO 1.8 A",
    value: "VENTO 1.8 A"
  },
  {
    SERIAL: "12130000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF",
    label: "GOLF",
    value: "GOLF"
  },
  {
    SERIAL: "12150000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF 1.8 5Dr. M",
    label: "GOLF 1.8 5Dr. M",
    value: "GOLF 1.8 5Dr. M"
  },
  {
    SERIAL: "12160000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF 1.8 5Dr. A",
    label: "GOLF 1.8 5Dr. A",
    value: "GOLF 1.8 5Dr. A"
  },
  {
    SERIAL: "12170000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT 1.8",
    label: "PASSAT 1.8",
    value: "PASSAT 1.8"
  },
  {
    SERIAL: "12190000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SHARAN 2.0",
    label: "SHARAN 2.0",
    value: "SHARAN 2.0"
  },
  {
    SERIAL: "12200000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT HIGHLINE 1.8 (A)",
    label: "PASSAT HIGHLINE 1.8 (A)",
    value: "PASSAT HIGHLINE 1.8 (A)"
  },
  {
    SERIAL: "12220000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT 2.3 V5 A",
    label: "PASSAT 2.3 V5 A",
    value: "PASSAT 2.3 V5 A"
  },
  {
    SERIAL: "12240000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF 1.6 5DRS",
    label: "GOLF 1.6 5DRS",
    value: "GOLF 1.6 5DRS"
  },
  {
    SERIAL: "12280000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 2.0",
    label: "BEETLE 2.0",
    value: "BEETLE 2.0"
  },
  {
    SERIAL: "12290000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.8 V6",
    label: "CARAVELLE 2.8 V6",
    value: "CARAVELLE 2.8 V6"
  },
  {
    SERIAL: "12300000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.8 VR 6 (A)",
    label: "CARAVELLE 2.8 VR 6 (A)",
    value: "CARAVELLE 2.8 VR 6 (A)"
  },
  {
    SERIAL: "12310000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "VENTO A",
    label: "VENTO A",
    value: "VENTO A"
  },
  {
    SERIAL: "12330000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT1.9 TDI",
    label: "PASSAT1.9 TDI",
    value: "PASSAT1.9 TDI"
  },
  {
    SERIAL: "12340000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SHARAN (NEW)",
    label: "SHARAN (NEW)",
    value: "SHARAN (NEW)"
  },
  {
    SERIAL: "12350000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.5 TDI",
    label: "CARAVELLE 2.5 TDI",
    value: "CARAVELLE 2.5 TDI"
  },
  {
    SERIAL: "12360000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 3.2 V6",
    label: "CARAVELLE 3.2 V6",
    value: "CARAVELLE 3.2 V6"
  },
  {
    SERIAL: "12370000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TRANSPORTER 2.5 TDI",
    label: "TRANSPORTER 2.5 TDI",
    value: "TRANSPORTER 2.5 TDI"
  },
  {
    SERIAL: "12380000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 2.0 CABRIOLET",
    label: "BEETLE 2.0 CABRIOLET",
    value: "BEETLE 2.0 CABRIOLET"
  },
  {
    SERIAL: "12390000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF",
    label: "GOLF",
    value: "GOLF"
  },
  {
    SERIAL: "12400000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF 2.0 GTI",
    label: "GOLF 2.0 GTI",
    value: "GOLF 2.0 GTI"
  },
  {
    SERIAL: "12420000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.5 TOURING TDI",
    label: "CARAVELLE 2.5 TOURING TDI",
    value: "CARAVELLE 2.5 TOURING TDI"
  },
  {
    SERIAL: "12430000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 3.2 BUSINESSLINE",
    label: "CARAVELLE 3.2 BUSINESSLINE",
    value: "CARAVELLE 3.2 BUSINESSLINE"
  },
  {
    SERIAL: "12440000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.5 HIGHLINE TDI",
    label: "CARAVELLE 2.5 HIGHLINE TDI",
    value: "CARAVELLE 2.5 HIGHLINE TDI"
  },
  {
    SERIAL: "12450000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 3.2 HIGHLINE",
    label: "CARAVELLE 3.2 HIGHLINE",
    value: "CARAVELLE 3.2 HIGHLINE"
  },
  {
    SERIAL: "12460000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TIGUAN 2.0 TIPTRONIC 4WD",
    label: "TIGUAN 2.0 TIPTRONIC 4WD",
    value: "TIGUAN 2.0 TIPTRONIC 4WD"
  },
  {
    SERIAL: "12470000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 1.6 COUPE",
    label: "BEETLE 1.6 COUPE",
    value: "BEETLE 1.6 COUPE"
  },
  {
    SERIAL: "12480000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 1.8 IT COUPE",
    label: "BEETLE 1.8 IT COUPE",
    value: "BEETLE 1.8 IT COUPE"
  },
  {
    SERIAL: "12490000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF 1.4 TSI",
    label: "GOLF 1.4 TSI",
    value: "GOLF 1.4 TSI"
  },
  {
    SERIAL: "12500000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT CC 2.0",
    label: "PASSAT CC 2.0",
    value: "PASSAT CC 2.0"
  },
  {
    SERIAL: "12510000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SCIROCCO 2.0 IT",
    label: "SCIROCCO 2.0 IT",
    value: "SCIROCCO 2.0 IT"
  },
  {
    SERIAL: "12520000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TIGUAN 2.0 IT",
    label: "TIGUAN 2.0 IT",
    value: "TIGUAN 2.0 IT"
  },
  {
    SERIAL: "12530000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TRANSPORTER T5 HARTMANN 3.2",
    label: "TRANSPORTER T5 HARTMANN 3.2",
    value: "TRANSPORTER T5 HARTMANN 3.2"
  },
  {
    SERIAL: "12540000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.0 TDI",
    label: "CARAVELLE 2.0 TDI",
    value: "CARAVELLE 2.0 TDI"
  },
  {
    SERIAL: "12550000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SCIROCCO 2.0 R",
    label: "SCIROCCO 2.0 R",
    value: "SCIROCCO 2.0 R"
  },
  {
    SERIAL: "12560000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 2.0 COUPE",
    label: "BEETLE 2.0 COUPE",
    value: "BEETLE 2.0 COUPE"
  },
  {
    SERIAL: "12570000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SCIROCCO 1.4",
    label: "SCIROCCO 1.4",
    value: "SCIROCCO 1.4"
  },
  {
    SERIAL: "12580000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TOUAREG 3.2 L",
    label: "TOUAREG 3.2 L",
    value: "TOUAREG 3.2 L"
  },
  {
    SERIAL: "12590000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TOUAREG 3.0 HYBRID",
    label: "TOUAREG 3.0 HYBRID",
    value: "TOUAREG 3.0 HYBRID"
  },
  {
    SERIAL: "12600000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "POLO 1.4",
    label: "POLO 1.4",
    value: "POLO 1.4"
  },
  {
    SERIAL: "12610000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TOUAREG 3.0 DIESEL",
    label: "TOUAREG 3.0 DIESEL",
    value: "TOUAREG 3.0 DIESEL"
  },
  {
    SERIAL: "12620000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "MULTIVAN 2.0 TDI",
    label: "MULTIVAN 2.0 TDI",
    value: "MULTIVAN 2.0 TDI"
  },
  {
    SERIAL: "12630000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT 2.0 TSI",
    label: "PASSAT 2.0 TSI",
    value: "PASSAT 2.0 TSI"
  },
  {
    SERIAL: "12640000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF R",
    label: "GOLF R",
    value: "GOLF R"
  },
  {
    SERIAL: "12650000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 1.2 TSI",
    label: "BEETLE 1.2 TSI",
    value: "BEETLE 1.2 TSI"
  },
  {
    SERIAL: "12660000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TRANSPORTER 2.0",
    label: "TRANSPORTER 2.0",
    value: "TRANSPORTER 2.0"
  },
  {
    SERIAL: "12680000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PASSAT CC 2.0 TDI",
    label: "PASSAT CC 2.0 TDI",
    value: "PASSAT CC 2.0 TDI"
  },
  {
    SERIAL: "12690000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SHARAN 2.0 TSI",
    label: "SHARAN 2.0 TSI",
    value: "SHARAN 2.0 TSI"
  },
  {
    SERIAL: "12700000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "AMAROK 4D 4WD 2.0 D",
    label: "AMAROK 4D 4WD 2.0 D",
    value: "AMAROK 4D 4WD 2.0 D"
  },
  {
    SERIAL: "12710000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 1.4 TSI",
    label: "BEETLE 1.4 TSI",
    value: "BEETLE 1.4 TSI"
  },
  {
    SERIAL: "12720000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 1.4 CABRIOLET",
    label: "BEETLE 1.4 CABRIOLET",
    value: "BEETLE 1.4 CABRIOLET"
  },
  {
    SERIAL: "12730000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T5 SUPER TOURING 2.5DTI",
    label: "CARAVELLE T5 SUPER TOURING 2.5DTI",
    value: "CARAVELLE T5 SUPER TOURING 2.5DTI"
  },
  {
    SERIAL: "12740000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T5 EXECUTIVE 2.5DTI",
    label: "CARAVELLE T5 EXECUTIVE 2.5DTI",
    value: "CARAVELLE T5 EXECUTIVE 2.5DTI"
  },
  {
    SERIAL: "12750000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T5 EXECUTIVE 3.2I",
    label: "CARAVELLE T5 EXECUTIVE 3.2I",
    value: "CARAVELLE T5 EXECUTIVE 3.2I"
  },
  {
    SERIAL: "12760000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T5 SUPER TOURING 3.2I",
    label: "CARAVELLE T5 SUPER TOURING 3.2I",
    value: "CARAVELLE T5 SUPER TOURING 3.2I"
  },
  {
    SERIAL: "12770000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SCIROCCO 2.0 TSI HIGHLINE",
    label: "SCIROCCO 2.0 TSI HIGHLINE",
    value: "SCIROCCO 2.0 TSI HIGHLINE"
  },
  {
    SERIAL: "12780000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF R 3D 2.0",
    label: "GOLF R 3D 2.0",
    value: "GOLF R 3D 2.0"
  },
  {
    SERIAL: "12790000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.0 TDI TOURING",
    label: "CARAVELLE 2.0 TDI TOURING",
    value: "CARAVELLE 2.0 TDI TOURING"
  },
  {
    SERIAL: "12800000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE CLASSIC",
    label: "BEETLE CLASSIC",
    value: "BEETLE CLASSIC"
  },
  {
    SERIAL: "12810000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "BEETLE 2.0 (จดประกอบ)",
    label: "BEETLE 2.0 (จดประกอบ)",
    value: "BEETLE 2.0 (จดประกอบ)"
  },
  {
    SERIAL: "12850000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "FASTBACK",
    label: "FASTBACK",
    value: "FASTBACK"
  },
  {
    SERIAL: "12860000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.0 TDI BUSINESS",
    label: "CARAVELLE 2.0 TDI BUSINESS",
    value: "CARAVELLE 2.0 TDI BUSINESS"
  },
  {
    SERIAL: "12870000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "GOLF 2.0 TSI",
    label: "GOLF 2.0 TSI",
    value: "GOLF 2.0 TSI"
  },
  {
    SERIAL: "12880000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TRANSPORTER T5 2.0",
    label: "TRANSPORTER T5 2.0",
    value: "TRANSPORTER T5 2.0"
  },
  {
    SERIAL: "12890000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "SHUTTLE",
    label: "SHUTTLE",
    value: "SHUTTLE"
  },
  {
    SERIAL: "12900000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "PICK UP",
    label: "PICK UP",
    value: "PICK UP"
  },
  {
    SERIAL: "12910000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T6 2.0",
    label: "CARAVELLE T6 2.0",
    value: "CARAVELLE T6 2.0"
  },
  {
    SERIAL: "12920000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T5 3.2",
    label: "CARAVELLE T5 3.2",
    value: "CARAVELLE T5 3.2"
  },
  {
    SERIAL: "12930000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "TRANSPORTER",
    label: "TRANSPORTER",
    value: "TRANSPORTER"
  },
  {
    SERIAL: "12950000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE COMFORT 2.0 BITDI",
    label: "CARAVELLE COMFORT 2.0 BITDI",
    value: "CARAVELLE COMFORT 2.0 BITDI"
  },
  {
    SERIAL: "12960000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 2.0 BI TDI",
    label: "CARAVELLE 2.0 BI TDI",
    value: "CARAVELLE 2.0 BI TDI"
  },
  {
    SERIAL: "12970000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE 6.1 EXCLUSIVE 2.0D 7 SEATS",
    label: "CARAVELLE 6.1 EXCLUSIVE 2.0D 7 SEATS",
    value: "CARAVELLE 6.1 EXCLUSIVE 2.0D 7 SEATS"
  },
  {
    SERIAL: "12980000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T69 2.0DTTI",
    label: "CARAVELLE T69 2.0DTTI",
    value: "CARAVELLE T69 2.0DTTI"
  },
  {
    SERIAL: "12990000026",
    MAKE_NAME: "VOLKSWAGEN",
    MODEL_NAME: "CARAVELLE T69 SE",
    label: "CARAVELLE T69 SE",
    value: "CARAVELLE T69 SE"
  },
  {
    SERIAL: "13000000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FORD",
    label: "FORD",
    value: "FORD"
  },
  {
    SERIAL: "13010000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ASPIRE",
    label: "ASPIRE",
    value: "ASPIRE"
  },
  {
    SERIAL: "13020000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ASPIRE 3Dr. XL 1.3",
    label: "ASPIRE 3Dr. XL 1.3",
    value: "ASPIRE 3Dr. XL 1.3"
  },
  {
    SERIAL: "13030000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ASPIRE 5Dr. GL 1.3",
    label: "ASPIRE 5Dr. GL 1.3",
    value: "ASPIRE 5Dr. GL 1.3"
  },
  {
    SERIAL: "13050000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FESTIVA 5Dr. GL 1.3",
    label: "FESTIVA 5Dr. GL 1.3",
    value: "FESTIVA 5Dr. GL 1.3"
  },
  {
    SERIAL: "13060000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER",
    label: "LASER",
    value: "LASER"
  },
  {
    SERIAL: "13080000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER GLXi 4Dr.1.6 M",
    label: "LASER GLXi 4Dr.1.6 M",
    value: "LASER GLXi 4Dr.1.6 M"
  },
  {
    SERIAL: "13090000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER GLXi 4Dr. 1.6 A",
    label: "LASER GLXi 4Dr. 1.6 A",
    value: "LASER GLXi 4Dr. 1.6 A"
  },
  {
    SERIAL: "13100000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER GLXi 3Dr. 1.8",
    label: "LASER GLXi 3Dr. 1.8",
    value: "LASER GLXi 3Dr. 1.8"
  },
  {
    SERIAL: "13110000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "TELSTAR 1.8",
    label: "TELSTAR 1.8",
    value: "TELSTAR 1.8"
  },
  {
    SERIAL: "13120000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "TELSTAR 2.0",
    label: "TELSTAR 2.0",
    value: "TELSTAR 2.0"
  },
  {
    SERIAL: "13180000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCORT GHIA 4Dr. 1.8",
    label: "ESCORT GHIA 4Dr. 1.8",
    value: "ESCORT GHIA 4Dr. 1.8"
  },
  {
    SERIAL: "13200000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCORT",
    label: "ESCORT",
    value: "ESCORT"
  },
  {
    SERIAL: "13220000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "GRANADA GHIA 4Dr. 2.0",
    label: "GRANADA GHIA 4Dr. 2.0",
    value: "GRANADA GHIA 4Dr. 2.0"
  },
  {
    SERIAL: "13230000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "GRANADA GHIA 5Dr. 2.0",
    label: "GRANADA GHIA 5Dr. 2.0",
    value: "GRANADA GHIA 5Dr. 2.0"
  },
  {
    SERIAL: "13250000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MONDEO",
    label: "MONDEO",
    value: "MONDEO"
  },
  {
    SERIAL: "13260000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MONDEO GLX 2.0",
    label: "MONDEO GLX 2.0",
    value: "MONDEO GLX 2.0"
  },
  {
    SERIAL: "13270000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MONDEO GLX 5Dr. 2.0",
    label: "MONDEO GLX 5Dr. 2.0",
    value: "MONDEO GLX 5Dr. 2.0"
  },
  {
    SERIAL: "13310000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "SIERRA LX 4Dr. 2.0",
    label: "SIERRA LX 4Dr. 2.0",
    value: "SIERRA LX 4Dr. 2.0"
  },
  {
    SERIAL: "13320000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "SIERRA LX 5Dr. 2.0",
    label: "SIERRA LX 5Dr. 2.0",
    value: "SIERRA LX 5Dr. 2.0"
  },
  {
    SERIAL: "13400000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FALCON",
    label: "FALCON",
    value: "FALCON"
  },
  {
    SERIAL: "13410000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MARATHON STANDARD",
    label: "MARATHON STANDARD",
    value: "MARATHON STANDARD"
  },
  {
    SERIAL: "13460000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FESTIVA",
    label: "FESTIVA",
    value: "FESTIVA"
  },
  {
    SERIAL: "13480000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EXPLORER",
    label: "EXPLORER",
    value: "EXPLORER"
  },
  {
    SERIAL: "13500000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.5",
    label: "RANGER STANDARD 2.5",
    value: "RANGER STANDARD 2.5"
  },
  {
    SERIAL: "13510000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER SUPERCAB BASE 2.5",
    label: "RANGER SUPERCAB BASE 2.5",
    value: "RANGER SUPERCAB BASE 2.5"
  },
  {
    SERIAL: "13520000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER SUPERCAB XLT 4WD",
    label: "RANGER SUPERCAB XLT 4WD",
    value: "RANGER SUPERCAB XLT 4WD"
  },
  {
    SERIAL: "13530000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER SUPERCAB XLT 2.5",
    label: "RANGER SUPERCAB XLT 2.5",
    value: "RANGER SUPERCAB XLT 2.5"
  },
  {
    SERIAL: "13540000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER SUPERCAB XL 2.5",
    label: "RANGER SUPERCAB XL 2.5",
    value: "RANGER SUPERCAB XL 2.5"
  },
  {
    SERIAL: "13550000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER 4WD XLT TURBO",
    label: "RANGER 4WD XLT TURBO",
    value: "RANGER 4WD XLT TURBO"
  },
  {
    SERIAL: "13560000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER GLXI 1.6 M",
    label: "LASER GLXI 1.6 M",
    value: "LASER GLXI 1.6 M"
  },
  {
    SERIAL: "13570000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER GLXI 1.6 A",
    label: "LASER GLXI 1.6 A",
    value: "LASER GLXI 1.6 A"
  },
  {
    SERIAL: "13580000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER GHIA 1.8 A",
    label: "LASER GHIA 1.8 A",
    value: "LASER GHIA 1.8 A"
  },
  {
    SERIAL: "13590000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XL",
    label: "RANGER DOUBLE CAB 2.5 XL",
    value: "RANGER DOUBLE CAB 2.5 XL"
  },
  {
    SERIAL: "13600000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB (4WD)2.5 A XLT",
    label: "RANGER DOUBLE CAB (4WD)2.5 A XLT",
    value: "RANGER DOUBLE CAB (4WD)2.5 A XLT"
  },
  {
    SERIAL: "13610000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XLT",
    label: "RANGER DOUBLE CAB 2.5 XLT",
    value: "RANGER DOUBLE CAB 2.5 XLT"
  },
  {
    SERIAL: "13620000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER 1.6 GLXI",
    label: "LASER 1.6 GLXI",
    value: "LASER 1.6 GLXI"
  },
  {
    SERIAL: "13630000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER GLI",
    label: "LASER GLI",
    value: "LASER GLI"
  },
  {
    SERIAL: "13740000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ASPIRE GL 5D. AUTO",
    label: "ASPIRE GL 5D. AUTO",
    value: "ASPIRE GL 5D. AUTO"
  },
  {
    SERIAL: "13820000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MONDEO GLX AUTO",
    label: "MONDEO GLX AUTO",
    value: "MONDEO GLX AUTO"
  },
  {
    SERIAL: "13840000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MONDEO GLX 5 D. AUTO",
    label: "MONDEO GLX 5 D. AUTO",
    value: "MONDEO GLX 5 D. AUTO"
  },
  {
    SERIAL: "13890000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER TIERRA 1.6 M (NEW)",
    label: "LASER TIERRA 1.6 M (NEW)",
    value: "LASER TIERRA 1.6 M (NEW)"
  },
  {
    SERIAL: "13900000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER TIERRA 1.8 (NEW)",
    label: "LASER TIERRA 1.8 (NEW)",
    value: "LASER TIERRA 1.8 (NEW)"
  },
  {
    SERIAL: "13910000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER TIERRA 1.6 A (NEW)",
    label: "LASER TIERRA 1.6 A (NEW)",
    value: "LASER TIERRA 1.6 A (NEW)"
  },
  {
    SERIAL: "13920000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XLS",
    label: "RANGER DOUBLE CAB 2.5 XLS",
    value: "RANGER DOUBLE CAB 2.5 XLS"
  },
  {
    SERIAL: "13930000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.9 XLT",
    label: "RANGER DOUBLE CAB 2.9 XLT",
    value: "RANGER DOUBLE CAB 2.9 XLT"
  },
  {
    SERIAL: "13940000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB XLT 2.5",
    label: "RANGER OPEN CAB XLT 2.5",
    value: "RANGER OPEN CAB XLT 2.5"
  },
  {
    SERIAL: "13950000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB XLT 2.9",
    label: "RANGER OPEN CAB XLT 2.9",
    value: "RANGER OPEN CAB XLT 2.9"
  },
  {
    SERIAL: "13960000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.5 XLT 4*4",
    label: "RANGER OPEN CAB 2.5 XLT 4*4",
    value: "RANGER OPEN CAB 2.5 XLT 4*4"
  },
  {
    SERIAL: "13980000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER",
    label: "RANGER",
    value: "RANGER"
  },
  {
    SERIAL: "13990000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCAPE 2.3 XLS (4*2)",
    label: "ESCAPE 2.3 XLS (4*2)",
    value: "ESCAPE 2.3 XLS (4*2)"
  },
  {
    SERIAL: "13AA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER HURICANE 4D 4WD",
    label: "RANGER HURICANE 4D 4WD",
    value: "RANGER HURICANE 4D 4WD"
  },
  {
    SERIAL: "13AB0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCAPE 3.0 4WD",
    label: "ESCAPE 3.0 4WD",
    value: "ESCAPE 3.0 4WD"
  },
  {
    SERIAL: "13AC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EXPLORER 4.0",
    label: "EXPLORER 4.0",
    value: "EXPLORER 4.0"
  },
  {
    SERIAL: "13AD0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EXPLORER 4.6",
    label: "EXPLORER 4.6",
    value: "EXPLORER 4.6"
  },
  {
    SERIAL: "13AE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.9",
    label: "RANGER STANDARD 2.9",
    value: "RANGER STANDARD 2.9"
  },
  {
    SERIAL: "13AF0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCAPE 2.0",
    label: "ESCAPE 2.0",
    value: "ESCAPE 2.0"
  },
  {
    SERIAL: "13AG0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 4*2",
    label: "EVEREST 4*2",
    value: "EVEREST 4*2"
  },
  {
    SERIAL: "13AH0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 4*2 LTD",
    label: "EVEREST 4*2 LTD",
    value: "EVEREST 4*2 LTD"
  },
  {
    SERIAL: "13AI0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 4*4 LTD 2.5",
    label: "EVEREST 4*4 LTD 2.5",
    value: "EVEREST 4*4 LTD 2.5"
  },
  {
    SERIAL: "13AJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCAPE 2.3 (4WD)",
    label: "ESCAPE 2.3 (4WD)",
    value: "ESCAPE 2.3 (4WD)"
  },
  {
    SERIAL: "13AK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EXPLORER LIMITED V8",
    label: "EXPLORER LIMITED V8",
    value: "EXPLORER LIMITED V8"
  },
  {
    SERIAL: "13AL0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LASER TIERRA 2.0",
    label: "LASER TIERRA 2.0",
    value: "LASER TIERRA 2.0"
  },
  {
    SERIAL: "13AM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 4*2",
    label: "RANGER OPEN CAB HI-RIDER 4*2",
    value: "RANGER OPEN CAB HI-RIDER 4*2"
  },
  {
    SERIAL: "13AN0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HIRIDER AUTO 4*2",
    label: "RANGER OPEN CAB HIRIDER AUTO 4*2",
    value: "RANGER OPEN CAB HIRIDER AUTO 4*2"
  },
  {
    SERIAL: "13AO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER 4*2",
    label: "RANGER DOUBLE CAB HI-RIDER 4*2",
    value: "RANGER DOUBLE CAB HI-RIDER 4*2"
  },
  {
    SERIAL: "13AP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER 4*2 AUTO",
    label: "RANGER DOUBLE CAB HI-RIDER 4*2 AUTO",
    value: "RANGER DOUBLE CAB HI-RIDER 4*2 AUTO"
  },
  {
    SERIAL: "13AQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TREND 4D 1.8 AUTO",
    label: "FOCUS TREND 4D 1.8 AUTO",
    value: "FOCUS TREND 4D 1.8 AUTO"
  },
  {
    SERIAL: "13AR0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS FINESSE 4D 1.8 AUTO",
    label: "FOCUS FINESSE 4D 1.8 AUTO",
    value: "FOCUS FINESSE 4D 1.8 AUTO"
  },
  {
    SERIAL: "13AS0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS GHIA 4D 1.8 AUTO",
    label: "FOCUS GHIA 4D 1.8 AUTO",
    value: "FOCUS GHIA 4D 1.8 AUTO"
  },
  {
    SERIAL: "13AT0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TREND 5D 1.8 AUTO",
    label: "FOCUS TREND 5D 1.8 AUTO",
    value: "FOCUS TREND 5D 1.8 AUTO"
  },
  {
    SERIAL: "13AU0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS SPORT 5D 2.0 AUTO",
    label: "FOCUS SPORT 5D 2.0 AUTO",
    value: "FOCUS SPORT 5D 2.0 AUTO"
  },
  {
    SERIAL: "13AV0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.5 (Y.2006)",
    label: "RANGER STANDARD 2.5 (Y.2006)",
    value: "RANGER STANDARD 2.5 (Y.2006)"
  },
  {
    SERIAL: "13AW0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD WLC2.5",
    label: "RANGER STANDARD WLC2.5",
    value: "RANGER STANDARD WLC2.5"
  },
  {
    SERIAL: "13AX0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB WL3 2.5",
    label: "RANGER OPEN CAB WL3 2.5",
    value: "RANGER OPEN CAB WL3 2.5"
  },
  {
    SERIAL: "13AY0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB XL WLC2.5",
    label: "RANGER OPEN CAB XL WLC2.5",
    value: "RANGER OPEN CAB XL WLC2.5"
  },
  {
    SERIAL: "13AZ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB XLS WLC2.5",
    label: "RANGER OPEN CAB XLS WLC2.5",
    value: "RANGER OPEN CAB XLS WLC2.5"
  },
  {
    SERIAL: "13BA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.5 XLS WLC",
    label: "RANGER OPEN CAB HI-RIDER 2.5 XLS WLC",
    value: "RANGER OPEN CAB HI-RIDER 2.5 XLS WLC"
  },
  {
    SERIAL: "13BB0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER XLT WEC3.0",
    label: "RANGER OPEN CAB HI-RIDER XLT WEC3.0",
    value: "RANGER OPEN CAB HI-RIDER XLT WEC3.0"
  },
  {
    SERIAL: "13BC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 3.0 XLS WEC 4WD",
    label: "RANGER OPEN CAB 3.0 XLS WEC 4WD",
    value: "RANGER OPEN CAB 3.0 XLS WEC 4WD"
  },
  {
    SERIAL: "13BD0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 3.0 XLT WEC 4WD",
    label: "RANGER OPEN CAB 3.0 XLT WEC 4WD",
    value: "RANGER OPEN CAB 3.0 XLT WEC 4WD"
  },
  {
    SERIAL: "13BE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XL WL3",
    label: "RANGER DOUBLE CAB 2.5 XL WL3",
    value: "RANGER DOUBLE CAB 2.5 XL WL3"
  },
  {
    SERIAL: "13BF0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XL WLC",
    label: "RANGER DOUBLE CAB 2.5 XL WLC",
    value: "RANGER DOUBLE CAB 2.5 XL WLC"
  },
  {
    SERIAL: "13BG0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XLS WLC",
    label: "RANGER DOUBLE CAB 2.5 XLS WLC",
    value: "RANGER DOUBLE CAB 2.5 XLS WLC"
  },
  {
    SERIAL: "13BH0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 4WD XLT WEC3.0",
    label: "RANGER DOUBLE CAB 4WD XLT WEC3.0",
    value: "RANGER DOUBLE CAB 4WD XLT WEC3.0"
  },
  {
    SERIAL: "13BI0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 4WD XLT WEC3.0 TOP",
    label: "RANGER DOUBLE CAB 4WD XLT WEC3.0 TOP",
    value: "RANGER DOUBLE CAB 4WD XLT WEC3.0 TOP"
  },
  {
    SERIAL: "13BJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.5 XLT M. 4*2",
    label: "EVEREST 2.5 XLT M. 4*2",
    value: "EVEREST 2.5 XLT M. 4*2"
  },
  {
    SERIAL: "13BK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.5 LTD A 4*2",
    label: "EVEREST 2.5 LTD A 4*2",
    value: "EVEREST 2.5 LTD A 4*2"
  },
  {
    SERIAL: "13BL0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 3.0 XLT M 4*4",
    label: "EVEREST 3.0 XLT M 4*4",
    value: "EVEREST 3.0 XLT M 4*4"
  },
  {
    SERIAL: "13BM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 3.0 LTD A 4*4",
    label: "EVEREST 3.0 LTD A 4*4",
    value: "EVEREST 3.0 LTD A 4*4"
  },
  {
    SERIAL: "13BO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "TERRITORY GHIA 4WD 4.0",
    label: "TERRITORY GHIA 4WD 4.0",
    value: "TERRITORY GHIA 4WD 4.0"
  },
  {
    SERIAL: "13BP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "GT 5.4",
    label: "GT 5.4",
    value: "GT 5.4"
  },
  {
    SERIAL: "13BQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB XLS 2.5",
    label: "RANGER OPEN CAB XLS 2.5",
    value: "RANGER OPEN CAB XLS 2.5"
  },
  {
    SERIAL: "13BR0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDR 2.5 XLS",
    label: "RANGER DOUBLE CAB HI-RIDR 2.5 XLS",
    value: "RANGER DOUBLE CAB HI-RIDR 2.5 XLS"
  },
  {
    SERIAL: "13BS0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER XLT2.5",
    label: "RANGER DOUBLE CAB HI-RIDER XLT2.5",
    value: "RANGER DOUBLE CAB HI-RIDER XLT2.5"
  },
  {
    SERIAL: "13BT0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "THUNDERBIRD",
    label: "THUNDERBIRD",
    value: "THUNDERBIRD"
  },
  {
    SERIAL: "13BU0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCAPE 2.3 (4*4)",
    label: "ESCAPE 2.3 (4*4)",
    value: "ESCAPE 2.3 (4*4)"
  },
  {
    SERIAL: "13BV0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TDCI DIESEL 2.0",
    label: "FOCUS TDCI DIESEL 2.0",
    value: "FOCUS TDCI DIESEL 2.0"
  },
  {
    SERIAL: "13BW0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCAPE 2.3 XLT (4*4)",
    label: "ESCAPE 2.3 XLT (4*4)",
    value: "ESCAPE 2.3 XLT (4*4)"
  },
  {
    SERIAL: "13BX0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "SHELBY GT500",
    label: "SHELBY GT500",
    value: "SHELBY GT500"
  },
  {
    SERIAL: "13BY0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS GHIA 4D 2.0 A",
    label: "FOCUS GHIA 4D 2.0 A",
    value: "FOCUS GHIA 4D 2.0 A"
  },
  {
    SERIAL: "13BZ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS FINESS 5D 1.8 A",
    label: "FOCUS FINESS 5D 1.8 A",
    value: "FOCUS FINESS 5D 1.8 A"
  },
  {
    SERIAL: "13CA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS AMBIENTE 5D 1.8 A",
    label: "FOCUS AMBIENTE 5D 1.8 A",
    value: "FOCUS AMBIENTE 5D 1.8 A"
  },
  {
    SERIAL: "13CB0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ESCAPE 2.3 XLT 2WD",
    label: "ESCAPE 2.3 XLT 2WD",
    value: "ESCAPE 2.3 XLT 2WD"
  },
  {
    SERIAL: "13CC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA STYLE 4D 1.4 M",
    label: "FIESTA STYLE 4D 1.4 M",
    value: "FIESTA STYLE 4D 1.4 M"
  },
  {
    SERIAL: "13CD0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA STYLE 4D 1.4 A",
    label: "FIESTA STYLE 4D 1.4 A",
    value: "FIESTA STYLE 4D 1.4 A"
  },
  {
    SERIAL: "13CE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA STYLE 5D 1.4 A",
    label: "FIESTA STYLE 5D 1.4 A",
    value: "FIESTA STYLE 5D 1.4 A"
  },
  {
    SERIAL: "13CF0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA TREND 4D 1.6 A",
    label: "FIESTA TREND 4D 1.6 A",
    value: "FIESTA TREND 4D 1.6 A"
  },
  {
    SERIAL: "13CG0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA TREND 5D 1.6 A",
    label: "FIESTA TREND 5D 1.6 A",
    value: "FIESTA TREND 5D 1.6 A"
  },
  {
    SERIAL: "13CH0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA SPORT 4D 1.6 A",
    label: "FIESTA SPORT 4D 1.6 A",
    value: "FIESTA SPORT 4D 1.6 A"
  },
  {
    SERIAL: "13CJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA SPORT 5D 1.6",
    label: "FIESTA SPORT 5D 1.6",
    value: "FIESTA SPORT 5D 1.6"
  },
  {
    SERIAL: "13CK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.5 XL",
    label: "RANGER STANDARD 2.5 XL",
    value: "RANGER STANDARD 2.5 XL"
  },
  {
    SERIAL: "13CL0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.5 XL",
    label: "RANGER OPEN CAB 2.5 XL",
    value: "RANGER OPEN CAB 2.5 XL"
  },
  {
    SERIAL: "13CM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.5 XLS",
    label: "RANGER OPEN CAB HI-RIDER 2.5 XLS",
    value: "RANGER OPEN CAB HI-RIDER 2.5 XLS"
  },
  {
    SERIAL: "13CN0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 4WD",
    label: "RANGER OPEN CAB 4WD",
    value: "RANGER OPEN CAB 4WD"
  },
  {
    SERIAL: "13CO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XL",
    label: "RANGER DOUBLE CAB 2.5 XL",
    value: "RANGER DOUBLE CAB 2.5 XL"
  },
  {
    SERIAL: "13CP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.5 XL ABS AIRBAG",
    label: "RANGER STANDARD 2.5 XL ABS AIRBAG",
    value: "RANGER STANDARD 2.5 XL ABS AIRBAG"
  },
  {
    SERIAL: "13CQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 3.0 4WD",
    label: "RANGER STANDARD 3.0 4WD",
    value: "RANGER STANDARD 3.0 4WD"
  },
  {
    SERIAL: "13CR0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.5 XLS",
    label: "RANGER OPEN CAB 2.5 XLS",
    value: "RANGER OPEN CAB 2.5 XLS"
  },
  {
    SERIAL: "13CS0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.5 XLT",
    label: "RANGER OPEN CAB 2.5 XLT",
    value: "RANGER OPEN CAB 2.5 XLT"
  },
  {
    SERIAL: "13CT0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.5 XLT",
    label: "RANGER OPEN CAB HI-RIDER 2.5 XLT",
    value: "RANGER OPEN CAB HI-RIDER 2.5 XLT"
  },
  {
    SERIAL: "13CU0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.5 XLT",
    label: "RANGER OPEN CAB HI-RIDER 2.5 XLT",
    value: "RANGER OPEN CAB HI-RIDER 2.5 XLT"
  },
  {
    SERIAL: "13CV0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 3.0 XLT",
    label: "RANGER OPEN CAB HI-RIDER 3.0 XLT",
    value: "RANGER OPEN CAB HI-RIDER 3.0 XLT"
  },
  {
    SERIAL: "13CW0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 3.0 4WD XLS",
    label: "RANGER OPEN CAB 3.0 4WD XLS",
    value: "RANGER OPEN CAB 3.0 4WD XLS"
  },
  {
    SERIAL: "13CX0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 3.0 4WD XLT",
    label: "RANGER OPEN CAB 3.0 4WD XLT",
    value: "RANGER OPEN CAB 3.0 4WD XLT"
  },
  {
    SERIAL: "13CY0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.5 XLS",
    label: "RANGER DOUBLE CAB 2.5 XLS",
    value: "RANGER DOUBLE CAB 2.5 XLS"
  },
  {
    SERIAL: "13CZ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER 2.5 XLT",
    label: "RANGER DOUBLE CAB HI-RIDER 2.5 XLT",
    value: "RANGER DOUBLE CAB HI-RIDER 2.5 XLT"
  },
  {
    SERIAL: "13DA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER 2.2 XLS",
    label: "RANGER DOUBLE CAB HI-RIDER 2.2 XLS",
    value: "RANGER DOUBLE CAB HI-RIDER 2.2 XLS"
  },
  {
    SERIAL: "13DC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER WILDTRAK2.5XLT",
    label: "RANGER OPEN CAB HI-RIDER WILDTRAK2.5XLT",
    value: "RANGER OPEN CAB HI-RIDER WILDTRAK2.5XLT"
  },
  {
    SERIAL: "13DD0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.5 XLT LIMITED",
    label: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.5 XLT LIMITED",
    value: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.5 XLT LIMITED"
  },
  {
    SERIAL: "13DE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.5 XLT",
    label: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.5 XLT",
    value: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.5 XLT"
  },
  {
    SERIAL: "13DI0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA SPORT ULTIMATE 1.6 A 5D",
    label: "FIESTA SPORT ULTIMATE 1.6 A 5D",
    value: "FIESTA SPORT ULTIMATE 1.6 A 5D"
  },
  {
    SERIAL: "13DJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA",
    label: "FIESTA",
    value: "FIESTA"
  },
  {
    SERIAL: "13DK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER WILDTRACK2.5",
    label: "RANGER DOUBLE CAB HI-RIDER WILDTRACK2.5",
    value: "RANGER DOUBLE CAB HI-RIDER WILDTRACK2.5"
  },
  {
    SERIAL: "13DL0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS SPORT+ 5D 2.0",
    label: "FOCUS SPORT+ 5D 2.0",
    value: "FOCUS SPORT+ 5D 2.0"
  },
  {
    SERIAL: "13DM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER XLT 2.2",
    label: "RANGER DOUBLE CAB HI-RIDER XLT 2.2",
    value: "RANGER DOUBLE CAB HI-RIDER XLT 2.2"
  },
  {
    SERIAL: "13DN0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.2 XLT",
    label: "RANGER OPEN CAB 2.2 XLT",
    value: "RANGER OPEN CAB 2.2 XLT"
  },
  {
    SERIAL: "13DO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.2 XLT",
    label: "RANGER OPEN CAB HI-RIDER 2.2 XLT",
    value: "RANGER OPEN CAB HI-RIDER 2.2 XLT"
  },
  {
    SERIAL: "13DP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.2 XLT",
    label: "RANGER DOUBLE CAB 2.2 XLT",
    value: "RANGER DOUBLE CAB 2.2 XLT"
  },
  {
    SERIAL: "13DQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER WILDTRAK 2.2",
    label: "RANGER OPEN CAB HI-RIDER WILDTRAK 2.2",
    value: "RANGER OPEN CAB HI-RIDER WILDTRAK 2.2"
  },
  {
    SERIAL: "13DR0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER XLT 2.2 A",
    label: "RANGER DOUBLE CAB HI-RIDER XLT 2.2 A",
    value: "RANGER DOUBLE CAB HI-RIDER XLT 2.2 A"
  },
  {
    SERIAL: "13DS0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.2",
    label: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.2",
    value: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.2"
  },
  {
    SERIAL: "13DT0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB WILDTRAK 4*4 2.2 A",
    label: "RANGER DOUBLE CAB WILDTRAK 4*4 2.2 A",
    value: "RANGER DOUBLE CAB WILDTRAK 4*4 2.2 A"
  },
  {
    SERIAL: "13DU0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB WILDTRAK 3.2 A 4WD",
    label: "RANGER DOUBLE CAB WILDTRAK 3.2 A 4WD",
    value: "RANGER DOUBLE CAB WILDTRAK 3.2 A 4WD"
  },
  {
    SERIAL: "13DV0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS RS 2.5",
    label: "FOCUS RS 2.5",
    value: "FOCUS RS 2.5"
  },
  {
    SERIAL: "13DW0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA TREND 4D 1.5 A",
    label: "FIESTA TREND 4D 1.5 A",
    value: "FIESTA TREND 4D 1.5 A"
  },
  {
    SERIAL: "13DX0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA SPORT 4D 1.5 A",
    label: "FIESTA SPORT 4D 1.5 A",
    value: "FIESTA SPORT 4D 1.5 A"
  },
  {
    SERIAL: "13DY0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "TERRITORY TDCI 2.7",
    label: "TERRITORY TDCI 2.7",
    value: "TERRITORY TDCI 2.7"
  },
  {
    SERIAL: "13DZ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "LINCOLN",
    label: "LINCOLN",
    value: "LINCOLN"
  },
  {
    SERIAL: "13EA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MUSTANG 3.7",
    label: "MUSTANG 3.7",
    value: "MUSTANG 3.7"
  },
  {
    SERIAL: "13EB0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA TREND 5D 1.5 A",
    label: "FIESTA TREND 5D 1.5 A",
    value: "FIESTA TREND 5D 1.5 A"
  },
  {
    SERIAL: "13EC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA SPORT 5D 1.5 A",
    label: "FIESTA SPORT 5D 1.5 A",
    value: "FIESTA SPORT 5D 1.5 A"
  },
  {
    SERIAL: "13ED0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TREND 4D 1.6",
    label: "FOCUS TREND 4D 1.6",
    value: "FOCUS TREND 4D 1.6"
  },
  {
    SERIAL: "13EE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS SPORT 5D 2.0",
    label: "FOCUS SPORT 5D 2.0",
    value: "FOCUS SPORT 5D 2.0"
  },
  {
    SERIAL: "13EF0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS AMBIENTE 4D 1.6 M",
    label: "FOCUS AMBIENTE 4D 1.6 M",
    value: "FOCUS AMBIENTE 4D 1.6 M"
  },
  {
    SERIAL: "13EG0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS AMBIENTE 4D 1.6 A",
    label: "FOCUS AMBIENTE 4D 1.6 A",
    value: "FOCUS AMBIENTE 4D 1.6 A"
  },
  {
    SERIAL: "13EH0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS AMBIENTE 5D 1.6 A",
    label: "FOCUS AMBIENTE 5D 1.6 A",
    value: "FOCUS AMBIENTE 5D 1.6 A"
  },
  {
    SERIAL: "13EI0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TREND 5D 1.6",
    label: "FOCUS TREND 5D 1.6",
    value: "FOCUS TREND 5D 1.6"
  },
  {
    SERIAL: "13EJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TITANIUM 4D 2.0",
    label: "FOCUS TITANIUM 4D 2.0",
    value: "FOCUS TITANIUM 4D 2.0"
  },
  {
    SERIAL: "13EK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TITANIUM PLUS 4D 2.0",
    label: "FOCUS TITANIUM PLUS 4D 2.0",
    value: "FOCUS TITANIUM PLUS 4D 2.0"
  },
  {
    SERIAL: "13EM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MUSTANG 4.6",
    label: "MUSTANG 4.6",
    value: "MUSTANG 4.6"
  },
  {
    SERIAL: "13EN0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB WILDTRAK 2.2 4*4",
    label: "RANGER DOUBLE CAB WILDTRAK 2.2 4*4",
    value: "RANGER DOUBLE CAB WILDTRAK 2.2 4*4"
  },
  {
    SERIAL: "13EO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ECOSPORT 1.5 AMBIENTE M",
    label: "ECOSPORT 1.5 AMBIENTE M",
    value: "ECOSPORT 1.5 AMBIENTE M"
  },
  {
    SERIAL: "13EP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ECOSPORT 1.5 AMBIENTE A",
    label: "ECOSPORT 1.5 AMBIENTE A",
    value: "ECOSPORT 1.5 AMBIENTE A"
  },
  {
    SERIAL: "13EQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ECOSPORT 1.5 TREND A",
    label: "ECOSPORT 1.5 TREND A",
    value: "ECOSPORT 1.5 TREND A"
  },
  {
    SERIAL: "13ER0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "ECOSPORT 1.5 TITANIUM A",
    label: "ECOSPORT 1.5 TITANIUM A",
    value: "ECOSPORT 1.5 TITANIUM A"
  },
  {
    SERIAL: "13ES0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MUSTANG SHELLBY 5.8",
    label: "MUSTANG SHELLBY 5.8",
    value: "MUSTANG SHELLBY 5.8"
  },
  {
    SERIAL: "13ET0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA ECOBOOST 1.0 4D TITANIUM",
    label: "FIESTA ECOBOOST 1.0 4D TITANIUM",
    value: "FIESTA ECOBOOST 1.0 4D TITANIUM"
  },
  {
    SERIAL: "13EU0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA ECOBOOST 1.0 5D",
    label: "FIESTA ECOBOOST 1.0 5D",
    value: "FIESTA ECOBOOST 1.0 5D"
  },
  {
    SERIAL: "13EV0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 4*2 XLT 2.5",
    label: "EVEREST 4*2 XLT 2.5",
    value: "EVEREST 4*2 XLT 2.5"
  },
  {
    SERIAL: "13EW0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA SPORT+ 4D 1.6 A",
    label: "FIESTA SPORT+ 4D 1.6 A",
    value: "FIESTA SPORT+ 4D 1.6 A"
  },
  {
    SERIAL: "13EX0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA SPORT+ 5D 1.6 A",
    label: "FIESTA SPORT+ 5D 1.6 A",
    value: "FIESTA SPORT+ 5D 1.6 A"
  },
  {
    SERIAL: "13EY0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.5 XL",
    label: "RANGER OPEN CAB 2.5 XL",
    value: "RANGER OPEN CAB 2.5 XL"
  },
  {
    SERIAL: "13EZ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.2 XLS",
    label: "RANGER OPEN CAB 2.2 XLS",
    value: "RANGER OPEN CAB 2.2 XLS"
  },
  {
    SERIAL: "13FA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB WILDTRAK 4WD 3.0 XLT",
    label: "RANGER DOUBLE CAB WILDTRAK 4WD 3.0 XLT",
    value: "RANGER DOUBLE CAB WILDTRAK 4WD 3.0 XLT"
  },
  {
    SERIAL: "13FC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA AMBIENTE 4D 1.5 M",
    label: "FIESTA AMBIENTE 4D 1.5 M",
    value: "FIESTA AMBIENTE 4D 1.5 M"
  },
  {
    SERIAL: "13FD0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA AMBIENTE 4D 1.5 A",
    label: "FIESTA AMBIENTE 4D 1.5 A",
    value: "FIESTA AMBIENTE 4D 1.5 A"
  },
  {
    SERIAL: "13FE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA TITANIUM 4D 1.5 A",
    label: "FIESTA TITANIUM 4D 1.5 A",
    value: "FIESTA TITANIUM 4D 1.5 A"
  },
  {
    SERIAL: "13FF0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA AMBIENTE 5D 1.5 A",
    label: "FIESTA AMBIENTE 5D 1.5 A",
    value: "FIESTA AMBIENTE 5D 1.5 A"
  },
  {
    SERIAL: "13FG0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FIESTA TREND 5D 1.5 A (Y.2014)",
    label: "FIESTA TREND 5D 1.5 A (Y.2014)",
    value: "FIESTA TREND 5D 1.5 A (Y.2014)"
  },
  {
    SERIAL: "13FJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.5",
    label: "RANGER STANDARD 2.5",
    value: "RANGER STANDARD 2.5"
  },
  {
    SERIAL: "13FK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.2 XLS",
    label: "RANGER OPEN CAB HI-RIDER 2.2 XLS",
    value: "RANGER OPEN CAB HI-RIDER 2.2 XLS"
  },
  {
    SERIAL: "13FL0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.2 XLT A",
    label: "RANGER OPEN CAB HI-RIDER 2.2 XLT A",
    value: "RANGER OPEN CAB HI-RIDER 2.2 XLT A"
  },
  {
    SERIAL: "13FM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS TITANIUM+ LUXURY PACK 2.0 A",
    label: "FOCUS TITANIUM+ LUXURY PACK 2.0 A",
    value: "FOCUS TITANIUM+ LUXURY PACK 2.0 A"
  },
  {
    SERIAL: "13FN0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS SPORT+ LUXURY PACK 5D 2.0 A",
    label: "FOCUS SPORT+ LUXURY PACK 5D 2.0 A",
    value: "FOCUS SPORT+ LUXURY PACK 5D 2.0 A"
  },
  {
    SERIAL: "13FO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST LTD NAVIGATOR 2.5 A",
    label: "EVEREST LTD NAVIGATOR 2.5 A",
    value: "EVEREST LTD NAVIGATOR 2.5 A"
  },
  {
    SERIAL: "13FP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST LTD NAVIGATOR 2.5 A 4WD",
    label: "EVEREST LTD NAVIGATOR 2.5 A 4WD",
    value: "EVEREST LTD NAVIGATOR 2.5 A 4WD"
  },
  {
    SERIAL: "13FQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.2 XLT 4WD",
    label: "RANGER DOUBLE CAB 2.2 XLT 4WD",
    value: "RANGER DOUBLE CAB 2.2 XLT 4WD"
  },
  {
    SERIAL: "13FR0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.2 2WD TITANIUM",
    label: "EVEREST 2.2 2WD TITANIUM",
    value: "EVEREST 2.2 2WD TITANIUM"
  },
  {
    SERIAL: "13FS0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 3.2 TITANIUM+ 4WD",
    label: "EVEREST 3.2 TITANIUM+ 4WD",
    value: "EVEREST 3.2 TITANIUM+ 4WD"
  },
  {
    SERIAL: "13FT0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 3.2 TITANIUM+ 4WD",
    label: "EVEREST 3.2 TITANIUM+ 4WD",
    value: "EVEREST 3.2 TITANIUM+ 4WD"
  },
  {
    SERIAL: "13FU0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "F150 RAPTOR",
    label: "F150 RAPTOR",
    value: "F150 RAPTOR"
  },
  {
    SERIAL: "13FV0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MUSTANG 2.5",
    label: "MUSTANG 2.5",
    value: "MUSTANG 2.5"
  },
  {
    SERIAL: "13FW0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLECAB HI-RIDER WILDTRAK 2.2A",
    label: "RANGER DOUBLECAB HI-RIDER WILDTRAK 2.2A",
    value: "RANGER DOUBLECAB HI-RIDER WILDTRAK 2.2A"
  },
  {
    SERIAL: "13FX0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MUSTANG SHELBY GT500 5.8",
    label: "MUSTANG SHELBY GT500 5.8",
    value: "MUSTANG SHELBY GT500 5.8"
  },
  {
    SERIAL: "13FY0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MUSTANG 2.3 ECOBOOST",
    label: "MUSTANG 2.3 ECOBOOST",
    value: "MUSTANG 2.3 ECOBOOST"
  },
  {
    SERIAL: "13FZ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS ECOBOOST 1.5",
    label: "FOCUS ECOBOOST 1.5",
    value: "FOCUS ECOBOOST 1.5"
  },
  {
    SERIAL: "13GA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.2 XL",
    label: "RANGER STANDARD 2.2 XL",
    value: "RANGER STANDARD 2.2 XL"
  },
  {
    SERIAL: "13GB0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.2 XL",
    label: "RANGER OPEN CAB 2.2 XL",
    value: "RANGER OPEN CAB 2.2 XL"
  },
  {
    SERIAL: "13GC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.2 XLS",
    label: "RANGER OPEN CAB HI-RIDER 2.2 XLS",
    value: "RANGER OPEN CAB HI-RIDER 2.2 XLS"
  },
  {
    SERIAL: "13GD0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.2 XLT 4WD",
    label: "RANGER OPEN CAB 2.2 XLT 4WD",
    value: "RANGER OPEN CAB 2.2 XLT 4WD"
  },
  {
    SERIAL: "13GE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 3.2 XLT 4WD",
    label: "RANGER OPEN CAB 3.2 XLT 4WD",
    value: "RANGER OPEN CAB 3.2 XLT 4WD"
  },
  {
    SERIAL: "13GF0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 4WD 3.2 XLT A",
    label: "RANGER DOUBLE CAB 4WD 3.2 XLT A",
    value: "RANGER DOUBLE CAB 4WD 3.2 XLT A"
  },
  {
    SERIAL: "13GG0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "MUSTANG GT 5.0 COUPE",
    label: "MUSTANG GT 5.0 COUPE",
    value: "MUSTANG GT 5.0 COUPE"
  },
  {
    SERIAL: "13GH0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER FX4 2.2 M",
    label: "RANGER DOUBLE CAB HI-RIDER FX4 2.2 M",
    value: "RANGER DOUBLE CAB HI-RIDER FX4 2.2 M"
  },
  {
    SERIAL: "13GI0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER FX4 2.2 A",
    label: "RANGER DOUBLE CAB HI-RIDER FX4 2.2 A",
    value: "RANGER DOUBLE CAB HI-RIDER FX4 2.2 A"
  },
  {
    SERIAL: "13GJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.2 A TITANIUM PLUS",
    label: "EVEREST 2.2 A TITANIUM PLUS",
    value: "EVEREST 2.2 A TITANIUM PLUS"
  },
  {
    SERIAL: "13GK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.2 XL+",
    label: "RANGER OPEN CAB HI-RIDER 2.2 XL+",
    value: "RANGER OPEN CAB HI-RIDER 2.2 XL+"
  },
  {
    SERIAL: "13GL0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.2 XLS 4WD",
    label: "RANGER OPEN CAB 2.2 XLS 4WD",
    value: "RANGER OPEN CAB 2.2 XLS 4WD"
  },
  {
    SERIAL: "13GM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "FOCUS",
    label: "FOCUS",
    value: "FOCUS"
  },
  {
    SERIAL: "13GN0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.2 TITANIUM+ NAVIGATOR",
    label: "EVEREST 2.2 TITANIUM+ NAVIGATOR",
    value: "EVEREST 2.2 TITANIUM+ NAVIGATOR"
  },
  {
    SERIAL: "13GO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.0",
    label: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.0",
    value: "RANGER DOUBLE CAB HI-RIDER WILDTRAK 2.0"
  },
  {
    SERIAL: "13GP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER 2.0 LTD A",
    label: "RANGER DOUBLE CAB HI-RIDER 2.0 LTD A",
    value: "RANGER DOUBLE CAB HI-RIDER 2.0 LTD A"
  },
  {
    SERIAL: "13GQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER 2.0 LTD M",
    label: "RANGER DOUBLE CAB HI-RIDER 2.0 LTD M",
    value: "RANGER DOUBLE CAB HI-RIDER 2.0 LTD M"
  },
  {
    SERIAL: "13GR0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 4WD 2.0 LIMITED",
    label: "RANGER OPEN CAB 4WD 2.0 LIMITED",
    value: "RANGER OPEN CAB 4WD 2.0 LIMITED"
  },
  {
    SERIAL: "13GS0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 4WD 2.0 LIMITED",
    label: "RANGER DOUBLE CAB 4WD 2.0 LIMITED",
    value: "RANGER DOUBLE CAB 4WD 2.0 LIMITED"
  },
  {
    SERIAL: "13GT0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB WILDTRAK 4WD 2.0BI TU RBO",
    label: "RANGER DOUBLE CAB WILDTRAK 4WD 2.0BI TU RBO",
    value: "RANGER DOUBLE CAB WILDTRAK 4WD 2.0BI TU RBO"
  },
  {
    SERIAL: "13GU0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.0 TREND",
    label: "EVEREST 2.0 TREND",
    value: "EVEREST 2.0 TREND"
  },
  {
    SERIAL: "13GV0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.0 TITANIUM",
    label: "EVEREST 2.0 TITANIUM",
    value: "EVEREST 2.0 TITANIUM"
  },
  {
    SERIAL: "13GW0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.0 TITANIUM+",
    label: "EVEREST 2.0 TITANIUM+",
    value: "EVEREST 2.0 TITANIUM+"
  },
  {
    SERIAL: "13GX0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.0 BI TURBO TITANIUM+ 4WD",
    label: "EVEREST 2.0 BI TURBO TITANIUM+ 4WD",
    value: "EVEREST 2.0 BI TURBO TITANIUM+ 4WD"
  },
  {
    SERIAL: "13GY0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLECAB RAPTOR 4*4 2.0 BI TURB 0",
    label: "RANGER DOUBLECAB RAPTOR 4*4 2.0 BI TURB 0",
    value: "RANGER DOUBLECAB RAPTOR 4*4 2.0 BI TURB 0"
  },
  {
    SERIAL: "13GZ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "13HA0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD 2.2 XL 4WD",
    label: "RANGER STANDARD 2.2 XL 4WD",
    value: "RANGER STANDARD 2.2 XL 4WD"
  },
  {
    SERIAL: "13HB0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB 2.2 XL",
    label: "RANGER DOUBLE CAB 2.2 XL",
    value: "RANGER DOUBLE CAB 2.2 XL"
  },
  {
    SERIAL: "13HC0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLE CAB HI-RIDER 2.2 XL+",
    label: "RANGER DOUBLE CAB HI-RIDER 2.2 XL+",
    value: "RANGER DOUBLE CAB HI-RIDER 2.2 XL+"
  },
  {
    SERIAL: "13HE0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD CAB SWB 2.0 TURBO",
    label: "RANGER STANDARD CAB SWB 2.0 TURBO",
    value: "RANGER STANDARD CAB SWB 2.0 TURBO"
  },
  {
    SERIAL: "13HF0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD CAB SWB 2.0 TURBO 4WD",
    label: "RANGER STANDARD CAB SWB 2.0 TURBO 4WD",
    value: "RANGER STANDARD CAB SWB 2.0 TURBO 4WD"
  },
  {
    SERIAL: "13HG0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB SWB CHASSIS 2.0 XL",
    label: "RANGER OPEN CAB SWB CHASSIS 2.0 XL",
    value: "RANGER OPEN CAB SWB CHASSIS 2.0 XL"
  },
  {
    SERIAL: "13HH0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.2 XLS AUTO",
    label: "RANGER OPEN CAB HI-RIDER 2.2 XLS AUTO",
    value: "RANGER OPEN CAB HI-RIDER 2.2 XLS AUTO"
  },
  {
    SERIAL: "13HI0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB HI-RIDER 2.2 XL+ SPORT",
    label: "RANGER OPEN CAB HI-RIDER 2.2 XL+ SPORT",
    value: "RANGER OPEN CAB HI-RIDER 2.2 XL+ SPORT"
  },
  {
    SERIAL: "13HJ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER OPEN CAB 2.2 XLT 4WD",
    label: "RANGER OPEN CAB 2.2 XLT 4WD",
    value: "RANGER OPEN CAB 2.2 XLT 4WD"
  },
  {
    SERIAL: "13HK0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLECAB HI-RIDER FX4 2.2 A",
    label: "RANGER DOUBLECAB HI-RIDER FX4 2.2 A",
    value: "RANGER DOUBLECAB HI-RIDER FX4 2.2 A"
  },
  {
    SERIAL: "13HL0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER DOUBLECAB HI-RIDER 2.0 TURBO A W ILDTRACK",
    label: "RANGER DOUBLECAB HI-RIDER 2.0 TURBO A W ILDTRACK",
    value: "RANGER DOUBLECAB HI-RIDER 2.0 TURBO A W ILDTRACK"
  },
  {
    SERIAL: "13HM0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "CONTINENTAL",
    label: "CONTINENTAL",
    value: "CONTINENTAL"
  },
  {
    SERIAL: "13HN0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "OVER 2000 CC",
    label: "OVER 2000 CC",
    value: "OVER 2000 CC"
  },
  {
    SERIAL: "13HO0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "6 ตัน",
    label: "6 ตัน",
    value: "6 ตัน"
  },
  {
    SERIAL: "13HP0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "EVEREST 2.0 TITANIUM SPORT",
    label: "EVEREST 2.0 TITANIUM SPORT",
    value: "EVEREST 2.0 TITANIUM SPORT"
  },
  {
    SERIAL: "13HQ0000026",
    MAKE_NAME: "FORD",
    MODEL_NAME: "RANGER STANDARD CHASSIS CAB 2.2",
    label: "RANGER STANDARD CHASSIS CAB 2.2",
    value: "RANGER STANDARD CHASSIS CAB 2.2"
  },
  {
    SERIAL: "14000000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "OPEL",
    label: "OPEL",
    value: "OPEL"
  },
  {
    SERIAL: "14010000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA 3D.",
    label: "CORSA 3D.",
    value: "CORSA 3D."
  },
  {
    SERIAL: "14020000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA 5D.",
    label: "CORSA 5D.",
    value: "CORSA 5D."
  },
  {
    SERIAL: "14030000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA 3D. 1.4 M",
    label: "CORSA 3D. 1.4 M",
    value: "CORSA 3D. 1.4 M"
  },
  {
    SERIAL: "14050000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA 5D. 1.4 M",
    label: "CORSA 5D. 1.4 M",
    value: "CORSA 5D. 1.4 M"
  },
  {
    SERIAL: "14070000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA JOY 3D. 1.4 M",
    label: "CORSA JOY 3D. 1.4 M",
    value: "CORSA JOY 3D. 1.4 M"
  },
  {
    SERIAL: "14080000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA JOY 3D. 1.4 A",
    label: "CORSA JOY 3D. 1.4 A",
    value: "CORSA JOY 3D. 1.4 A"
  },
  {
    SERIAL: "14090000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA JOY 5D. 1.4 M",
    label: "CORSA JOY 5D. 1.4 M",
    value: "CORSA JOY 5D. 1.4 M"
  },
  {
    SERIAL: "14100000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA JOY 5D. 1.4 A",
    label: "CORSA JOY 5D. 1.4 A",
    value: "CORSA JOY 5D. 1.4 A"
  },
  {
    SERIAL: "14110000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA GSI 3D. 1.6",
    label: "CORSA GSI 3D. 1.6",
    value: "CORSA GSI 3D. 1.6"
  },
  {
    SERIAL: "14120000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA",
    label: "ASTRA",
    value: "ASTRA"
  },
  {
    SERIAL: "14130000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA ELITE 4Dr. 16V",
    label: "ASTRA ELITE 4Dr. 16V",
    value: "ASTRA ELITE 4Dr. 16V"
  },
  {
    SERIAL: "14140000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA 4Dr. 16V M",
    label: "ASTRA 4Dr. 16V M",
    value: "ASTRA 4Dr. 16V M"
  },
  {
    SERIAL: "14150000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA 4Dr. 16V A",
    label: "ASTRA 4Dr. 16V A",
    value: "ASTRA 4Dr. 16V A"
  },
  {
    SERIAL: "14160000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA CARAVAN 1.6 M",
    label: "ASTRA CARAVAN 1.6 M",
    value: "ASTRA CARAVAN 1.6 M"
  },
  {
    SERIAL: "14170000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA CARAVAN 1.6 A",
    label: "ASTRA CARAVAN 1.6 A",
    value: "ASTRA CARAVAN 1.6 A"
  },
  {
    SERIAL: "14220000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA 4Dr. ABS 1.8i M",
    label: "ASTRA 4Dr. ABS 1.8i M",
    value: "ASTRA 4Dr. ABS 1.8i M"
  },
  {
    SERIAL: "14290000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "OMEGA GLS 2.0",
    label: "OMEGA GLS 2.0",
    value: "OMEGA GLS 2.0"
  },
  {
    SERIAL: "14300000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "OMEGA SEDAN 2.0 M",
    label: "OMEGA SEDAN 2.0 M",
    value: "OMEGA SEDAN 2.0 M"
  },
  {
    SERIAL: "14320000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "OMEGA WAGON GLA 2.0 A",
    label: "OMEGA WAGON GLA 2.0 A",
    value: "OMEGA WAGON GLA 2.0 A"
  },
  {
    SERIAL: "14350000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "VECTRA 2.0 M",
    label: "VECTRA 2.0 M",
    value: "VECTRA 2.0 M"
  },
  {
    SERIAL: "14360000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "VECTRA 2.0 A",
    label: "VECTRA 2.0 A",
    value: "VECTRA 2.0 A"
  },
  {
    SERIAL: "14370000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "VECTRA ABS 2.0 A",
    label: "VECTRA ABS 2.0 A",
    value: "VECTRA ABS 2.0 A"
  },
  {
    SERIAL: "14380000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "VECTRA 5D. 2.0 A",
    label: "VECTRA 5D. 2.0 A",
    value: "VECTRA 5D. 2.0 A"
  },
  {
    SERIAL: "14390000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "KADETT",
    label: "KADETT",
    value: "KADETT"
  },
  {
    SERIAL: "14470000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CAMPO CAB GL",
    label: "CAMPO CAB GL",
    value: "CAMPO CAB GL"
  },
  {
    SERIAL: "14500000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "ASTRA GL 4D.",
    label: "ASTRA GL 4D.",
    value: "ASTRA GL 4D."
  },
  {
    SERIAL: "14590000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "VECTRA GL 5D.",
    label: "VECTRA GL 5D.",
    value: "VECTRA GL 5D."
  },
  {
    SERIAL: "14660000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "CORSA SWING 3D. A",
    label: "CORSA SWING 3D. A",
    value: "CORSA SWING 3D. A"
  },
  {
    SERIAL: "14670000026",
    MAKE_NAME: "OPEL",
    MODEL_NAME: "OMEGA GL A",
    label: "OMEGA GL A",
    value: "OMEGA GL A"
  },
  {
    SERIAL: "15000000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "CITROEN",
    label: "CITROEN",
    value: "CITROEN"
  },
  {
    SERIAL: "15020000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "ZX 5Dr. 1.8",
    label: "ZX 5Dr. 1.8",
    value: "ZX 5Dr. 1.8"
  },
  {
    SERIAL: "15030000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "ZX iA 5Dr. 2.0",
    label: "ZX iA 5Dr. 2.0",
    value: "ZX iA 5Dr. 2.0"
  },
  {
    SERIAL: "15040000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "XANTIA 2.0I",
    label: "XANTIA 2.0I",
    value: "XANTIA 2.0I"
  },
  {
    SERIAL: "15060000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "XANTIA 2.0I 16V",
    label: "XANTIA 2.0I 16V",
    value: "XANTIA 2.0I 16V"
  },
  {
    SERIAL: "15070000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "XM 2.0I",
    label: "XM 2.0I",
    value: "XM 2.0I"
  },
  {
    SERIAL: "15110000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "AX 1.4",
    label: "AX 1.4",
    value: "AX 1.4"
  },
  {
    SERIAL: "15180000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "BX 16 RS",
    label: "BX 16 RS",
    value: "BX 16 RS"
  },
  {
    SERIAL: "15190000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "XANTIA 2.0 IA",
    label: "XANTIA 2.0 IA",
    value: "XANTIA 2.0 IA"
  },
  {
    SERIAL: "15210000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "EVASION SX STANDARD",
    label: "EVASION SX STANDARD",
    value: "EVASION SX STANDARD"
  },
  {
    SERIAL: "15220000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "EVASION EXCLUSIVE",
    label: "EVASION EXCLUSIVE",
    value: "EVASION EXCLUSIVE"
  },
  {
    SERIAL: "15230000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C5 3.0",
    label: "C5 3.0",
    value: "C5 3.0"
  },
  {
    SERIAL: "15240000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C5 2.0 DIESEL",
    label: "C5 2.0 DIESEL",
    value: "C5 2.0 DIESEL"
  },
  {
    SERIAL: "15250000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C5 2.0",
    label: "C5 2.0",
    value: "C5 2.0"
  },
  {
    SERIAL: "15260000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C3 1.4",
    label: "C3 1.4",
    value: "C3 1.4"
  },
  {
    SERIAL: "15270000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C8 2.0 LUXURY",
    label: "C8 2.0 LUXURY",
    value: "C8 2.0 LUXURY"
  },
  {
    SERIAL: "15280000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C8 2.0 SX",
    label: "C8 2.0 SX",
    value: "C8 2.0 SX"
  },
  {
    SERIAL: "15290000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C8 2.0 EXCLUSIVE",
    label: "C8 2.0 EXCLUSIVE",
    value: "C8 2.0 EXCLUSIVE"
  },
  {
    SERIAL: "15300000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C8 2.0 EXCLUSIVE SUNROOF",
    label: "C8 2.0 EXCLUSIVE SUNROOF",
    value: "C8 2.0 EXCLUSIVE SUNROOF"
  },
  {
    SERIAL: "15310000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "BERLINGO 1.9 SD",
    label: "BERLINGO 1.9 SD",
    value: "BERLINGO 1.9 SD"
  },
  {
    SERIAL: "15320000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "BERLINGO 1.9 WI",
    label: "BERLINGO 1.9 WI",
    value: "BERLINGO 1.9 WI"
  },
  {
    SERIAL: "15330000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C3 1.6",
    label: "C3 1.6",
    value: "C3 1.6"
  },
  {
    SERIAL: "15340000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "XSARA PICASSO",
    label: "XSARA PICASSO",
    value: "XSARA PICASSO"
  },
  {
    SERIAL: "15350000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C3 PLURIEL 1.6 (CABRIOLET)",
    label: "C3 PLURIEL 1.6 (CABRIOLET)",
    value: "C3 PLURIEL 1.6 (CABRIOLET)"
  },
  {
    SERIAL: "15360000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C4 COUPE (3D) 1.6",
    label: "C4 COUPE (3D) 1.6",
    value: "C4 COUPE (3D) 1.6"
  },
  {
    SERIAL: "15370000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C4 HATCHBACK (5D) 2.0",
    label: "C4 HATCHBACK (5D) 2.0",
    value: "C4 HATCHBACK (5D) 2.0"
  },
  {
    SERIAL: "15380000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C5 2.0 (Y.2006)",
    label: "C5 2.0 (Y.2006)",
    value: "C5 2.0 (Y.2006)"
  },
  {
    SERIAL: "15390000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C6 EXECUTIVE 3.0",
    label: "C6 EXECUTIVE 3.0",
    value: "C6 EXECUTIVE 3.0"
  },
  {
    SERIAL: "15400000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C6 EXECUTIVE 2.7 DTT",
    label: "C6 EXECUTIVE 2.7 DTT",
    value: "C6 EXECUTIVE 2.7 DTT"
  },
  {
    SERIAL: "15410000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER 2.2 BUSINESS",
    label: "JUMPER 2.2 BUSINESS",
    value: "JUMPER 2.2 BUSINESS"
  },
  {
    SERIAL: "15420000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER 2.2 BUSINESS LWB",
    label: "JUMPER 2.2 BUSINESS LWB",
    value: "JUMPER 2.2 BUSINESS LWB"
  },
  {
    SERIAL: "15430000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER 3.0 PRESIDENT LWB",
    label: "JUMPER 3.0 PRESIDENT LWB",
    value: "JUMPER 3.0 PRESIDENT LWB"
  },
  {
    SERIAL: "15440000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER 2.2 PRESIDENT LWB",
    label: "JUMPER 2.2 PRESIDENT LWB",
    value: "JUMPER 2.2 PRESIDENT LWB"
  },
  {
    SERIAL: "15450000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "2CV",
    label: "2CV",
    value: "2CV"
  },
  {
    SERIAL: "15460000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "DS3 1.6",
    label: "DS3 1.6",
    value: "DS3 1.6"
  },
  {
    SERIAL: "15470000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "DS4 1.6",
    label: "DS4 1.6",
    value: "DS4 1.6"
  },
  {
    SERIAL: "15480000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "DS3 1.4",
    label: "DS3 1.4",
    value: "DS3 1.4"
  },
  {
    SERIAL: "15490000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER J 2.2D",
    label: "JUMPER J 2.2D",
    value: "JUMPER J 2.2D"
  },
  {
    SERIAL: "15500000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER J SPORT 2.2D",
    label: "JUMPER J SPORT 2.2D",
    value: "JUMPER J SPORT 2.2D"
  },
  {
    SERIAL: "15510000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER JL 2.2D",
    label: "JUMPER JL 2.2D",
    value: "JUMPER JL 2.2D"
  },
  {
    SERIAL: "15520000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "JUMPER JL SPORT 2.2D",
    label: "JUMPER JL SPORT 2.2D",
    value: "JUMPER JL SPORT 2.2D"
  },
  {
    SERIAL: "15530000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "C5 1.6 ITC",
    label: "C5 1.6 ITC",
    value: "C5 1.6 ITC"
  },
  {
    SERIAL: "15540000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "DS5 1.6",
    label: "DS5 1.6",
    value: "DS5 1.6"
  },
  {
    SERIAL: "15550000026",
    MAKE_NAME: "CITROEN",
    MODEL_NAME: "DS",
    label: "DS",
    value: "DS"
  },
  {
    SERIAL: "16000000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "DAEWOO",
    label: "DAEWOO",
    value: "DAEWOO"
  },
  {
    SERIAL: "16010000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "CIELO GLX 1.5",
    label: "CIELO GLX 1.5",
    value: "CIELO GLX 1.5"
  },
  {
    SERIAL: "16020000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "ESPERO DLX 2.0",
    label: "ESPERO DLX 2.0",
    value: "ESPERO DLX 2.0"
  },
  {
    SERIAL: "16040000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "FANTASY ETi 1.5",
    label: "FANTASY ETi 1.5",
    value: "FANTASY ETi 1.5"
  },
  {
    SERIAL: "16060000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "FANTASY STi 4Dr. 1.5",
    label: "FANTASY STi 4Dr. 1.5",
    value: "FANTASY STi 4Dr. 1.5"
  },
  {
    SERIAL: "16080000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "NEXIA 5Dr. 1.5",
    label: "NEXIA 5Dr. 1.5",
    value: "NEXIA 5Dr. 1.5"
  },
  {
    SERIAL: "16100000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "RACER 1.6",
    label: "RACER 1.6",
    value: "RACER 1.6"
  },
  {
    SERIAL: "16120000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "LANOS M",
    label: "LANOS M",
    value: "LANOS M"
  },
  {
    SERIAL: "16130000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "LANOS A",
    label: "LANOS A",
    value: "LANOS A"
  },
  {
    SERIAL: "16150000026",
    MAKE_NAME: "DAEWOO",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "17000000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "DAIHATSU",
    label: "DAIHATSU",
    value: "DAIHATSU"
  },
  {
    SERIAL: "17010000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "MIRA",
    label: "MIRA",
    value: "MIRA"
  },
  {
    SERIAL: "17020000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "MIRA 1.0",
    label: "MIRA 1.0",
    value: "MIRA 1.0"
  },
  {
    SERIAL: "17040000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "MIRA 3Dr. 1.0",
    label: "MIRA 3Dr. 1.0",
    value: "MIRA 3Dr. 1.0"
  },
  {
    SERIAL: "17070000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "MIRA MINT 1.0",
    label: "MIRA MINT 1.0",
    value: "MIRA MINT 1.0"
  },
  {
    SERIAL: "17080000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "APPLAUSE",
    label: "APPLAUSE",
    value: "APPLAUSE"
  },
  {
    SERIAL: "17090000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "APPLAUSE LI 1.6",
    label: "APPLAUSE LI 1.6",
    value: "APPLAUSE LI 1.6"
  },
  {
    SERIAL: "17110000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "CHARADE HATCHBACK 1.3",
    label: "CHARADE HATCHBACK 1.3",
    value: "CHARADE HATCHBACK 1.3"
  },
  {
    SERIAL: "17140000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "HIJET",
    label: "HIJET",
    value: "HIJET"
  },
  {
    SERIAL: "17150000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "MINI VAN",
    label: "MINI VAN",
    value: "MINI VAN"
  },
  {
    SERIAL: "17220000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "MIRA 3D",
    label: "MIRA 3D",
    value: "MIRA 3D"
  },
  {
    SERIAL: "17240000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "3WHEEL",
    label: "3WHEEL",
    value: "3WHEEL"
  },
  {
    SERIAL: "17270000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "17280000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "CUSTOM",
    label: "CUSTOM",
    value: "CUSTOM"
  },
  {
    SERIAL: "17290000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "3 WHEEL (TUK TUK)",
    label: "3 WHEEL (TUK TUK)",
    value: "3 WHEEL (TUK TUK)"
  },
  {
    SERIAL: "17350000026",
    MAKE_NAME: "DAIHATSU",
    MODEL_NAME: "TRACTOR",
    label: "TRACTOR",
    value: "TRACTOR"
  },
  {
    SERIAL: "18000000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "FIAT",
    label: "FIAT",
    value: "FIAT"
  },
  {
    SERIAL: "18070000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "PUNTO GT TURBO 3Dr.",
    label: "PUNTO GT TURBO 3Dr.",
    value: "PUNTO GT TURBO 3Dr."
  },
  {
    SERIAL: "18080000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "TEMPRA 1.6",
    label: "TEMPRA 1.6",
    value: "TEMPRA 1.6"
  },
  {
    SERIAL: "18140000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "132 GLS 2.0",
    label: "132 GLS 2.0",
    value: "132 GLS 2.0"
  },
  {
    SERIAL: "18160000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "125P 1.5",
    label: "125P 1.5",
    value: "125P 1.5"
  },
  {
    SERIAL: "18190000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "AGENTA",
    label: "AGENTA",
    value: "AGENTA"
  },
  {
    SERIAL: "18210000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 LOUNGE 1.4",
    label: "500 LOUNGE 1.4",
    value: "500 LOUNGE 1.4"
  },
  {
    SERIAL: "18220000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 SUPER LOUNGE 1.4",
    label: "500 SUPER LOUNGE 1.4",
    value: "500 SUPER LOUNGE 1.4"
  },
  {
    SERIAL: "18230000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 SPORT",
    label: "500 SPORT",
    value: "500 SPORT"
  },
  {
    SERIAL: "18240000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 SPORT PREMIUM",
    label: "500 SPORT PREMIUM",
    value: "500 SPORT PREMIUM"
  },
  {
    SERIAL: "18250000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 LOUNGE",
    label: "500 LOUNGE",
    value: "500 LOUNGE"
  },
  {
    SERIAL: "18260000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 LOUNGE PREMIUM",
    label: "500 LOUNGE PREMIUM",
    value: "500 LOUNGE PREMIUM"
  },
  {
    SERIAL: "18270000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 LIMITED 1.4",
    label: "500 LIMITED 1.4",
    value: "500 LIMITED 1.4"
  },
  {
    SERIAL: "18280000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 CONVERTIBLE",
    label: "500 CONVERTIBLE",
    value: "500 CONVERTIBLE"
  },
  {
    SERIAL: "18290000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 Abarth",
    label: "500 Abarth",
    value: "500 Abarth"
  },
  {
    SERIAL: "18300000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "Abart 695 1.4 T",
    label: "Abart 695 1.4 T",
    value: "Abart 695 1.4 T"
  },
  {
    SERIAL: "18310000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "Abart 695 LIMITED",
    label: "Abart 695 LIMITED",
    value: "Abart 695 LIMITED"
  },
  {
    SERIAL: "18320000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 abart TRIBUTO FERRARI 1.4",
    label: "500 abart TRIBUTO FERRARI 1.4",
    value: "500 abart TRIBUTO FERRARI 1.4"
  },
  {
    SERIAL: "18330000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "ABART 695 TRIBUTO FERRARI 1.4",
    label: "ABART 695 TRIBUTO FERRARI 1.4",
    value: "ABART 695 TRIBUTO FERRARI 1.4"
  },
  {
    SERIAL: "18340000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "500 BY GUCCI",
    label: "500 BY GUCCI",
    value: "500 BY GUCCI"
  },
  {
    SERIAL: "18350000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "ABART 695 TRIBUTO FERRARI LIMITED",
    label: "ABART 695 TRIBUTO FERRARI LIMITED",
    value: "ABART 695 TRIBUTO FERRARI LIMITED"
  },
  {
    SERIAL: "18360000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "DUCATO 2.0",
    label: "DUCATO 2.0",
    value: "DUCATO 2.0"
  },
  {
    SERIAL: "18370000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "1100",
    label: "1100",
    value: "1100"
  },
  {
    SERIAL: "18380000026",
    MAKE_NAME: "FIAT",
    MODEL_NAME: "ABART 595 TOURISMO",
    label: "ABART 595 TOURISMO",
    value: "ABART 595 TOURISMO"
  },
  {
    SERIAL: "19000000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "SUBARU",
    label: "SUBARU",
    value: "SUBARU"
  },
  {
    SERIAL: "19010000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 1.6 A",
    label: "IMPREZA 1.6 A",
    value: "IMPREZA 1.6 A"
  },
  {
    SERIAL: "19020000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 1.8 A",
    label: "IMPREZA 1.8 A",
    value: "IMPREZA 1.8 A"
  },
  {
    SERIAL: "19040000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 2.0 M TURBO",
    label: "IMPREZA 2.0 M TURBO",
    value: "IMPREZA 2.0 M TURBO"
  },
  {
    SERIAL: "19050000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY",
    label: "LEGACY",
    value: "LEGACY"
  },
  {
    SERIAL: "19060000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY WAGON 2.2",
    label: "LEGACY WAGON 2.2",
    value: "LEGACY WAGON 2.2"
  },
  {
    SERIAL: "19080000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY 2.0",
    label: "LEGACY 2.0",
    value: "LEGACY 2.0"
  },
  {
    SERIAL: "19110000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 1.6 M (NEW)",
    label: "IMPREZA 1.6 M (NEW)",
    value: "IMPREZA 1.6 M (NEW)"
  },
  {
    SERIAL: "19120000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 1.6 A (NEW)",
    label: "IMPREZA 1.6 A (NEW)",
    value: "IMPREZA 1.6 A (NEW)"
  },
  {
    SERIAL: "19130000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 2.0 WRX",
    label: "IMPREZA 2.0 WRX",
    value: "IMPREZA 2.0 WRX"
  },
  {
    SERIAL: "19140000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER XT 2.0 IT",
    label: "FORESTER XT 2.0 IT",
    value: "FORESTER XT 2.0 IT"
  },
  {
    SERIAL: "19150000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "OUT BACK",
    label: "OUT BACK",
    value: "OUT BACK"
  },
  {
    SERIAL: "19160000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA WRX STI 2.0",
    label: "IMPREZA WRX STI 2.0",
    value: "IMPREZA WRX STI 2.0"
  },
  {
    SERIAL: "19170000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY GT 2.0",
    label: "LEGACY GT 2.0",
    value: "LEGACY GT 2.0"
  },
  {
    SERIAL: "19180000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA WRX LIMITED EDITION",
    label: "IMPREZA WRX LIMITED EDITION",
    value: "IMPREZA WRX LIMITED EDITION"
  },
  {
    SERIAL: "19190000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY 2.0",
    label: "LEGACY 2.0",
    value: "LEGACY 2.0"
  },
  {
    SERIAL: "19200000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY 2.2 A",
    label: "LEGACY 2.2 A",
    value: "LEGACY 2.2 A"
  },
  {
    SERIAL: "19210000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "TRIBECA 3.0",
    label: "TRIBECA 3.0",
    value: "TRIBECA 3.0"
  },
  {
    SERIAL: "19220000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER XT 2.5",
    label: "FORESTER XT 2.5",
    value: "FORESTER XT 2.5"
  },
  {
    SERIAL: "19230000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 4D WRX STI 2.5",
    label: "IMPREZA 4D WRX STI 2.5",
    value: "IMPREZA 4D WRX STI 2.5"
  },
  {
    SERIAL: "19240000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA WRX 2.5",
    label: "IMPREZA WRX 2.5",
    value: "IMPREZA WRX 2.5"
  },
  {
    SERIAL: "19250000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY SEDAN 2.0",
    label: "LEGACY SEDAN 2.0",
    value: "LEGACY SEDAN 2.0"
  },
  {
    SERIAL: "19260000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY WAGON 2.0",
    label: "LEGACY WAGON 2.0",
    value: "LEGACY WAGON 2.0"
  },
  {
    SERIAL: "19270000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY WAGON GT 2.0",
    label: "LEGACY WAGON GT 2.0",
    value: "LEGACY WAGON GT 2.0"
  },
  {
    SERIAL: "19280000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "R1 3D",
    label: "R1 3D",
    value: "R1 3D"
  },
  {
    SERIAL: "19290000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "R1 3D 4WD",
    label: "R1 3D 4WD",
    value: "R1 3D 4WD"
  },
  {
    SERIAL: "19300000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "R2 5D",
    label: "R2 5D",
    value: "R2 5D"
  },
  {
    SERIAL: "19310000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "R2 5D 4WD",
    label: "R2 5D 4WD",
    value: "R2 5D 4WD"
  },
  {
    SERIAL: "19320000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 5D 1.6",
    label: "IMPREZA 5D 1.6",
    value: "IMPREZA 5D 1.6"
  },
  {
    SERIAL: "19330000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY 5D 2.5 GT",
    label: "LEGACY 5D 2.5 GT",
    value: "LEGACY 5D 2.5 GT"
  },
  {
    SERIAL: "19340000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY 4D 2.5 GT",
    label: "LEGACY 4D 2.5 GT",
    value: "LEGACY 4D 2.5 GT"
  },
  {
    SERIAL: "19350000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY EXECUTIVE 2.0",
    label: "LEGACY EXECUTIVE 2.0",
    value: "LEGACY EXECUTIVE 2.0"
  },
  {
    SERIAL: "19360000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 5D. 4WD 1.5 M",
    label: "IMPREZA 5D. 4WD 1.5 M",
    value: "IMPREZA 5D. 4WD 1.5 M"
  },
  {
    SERIAL: "19370000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 5D. 4WD 1.5 A",
    label: "IMPREZA 5D. 4WD 1.5 A",
    value: "IMPREZA 5D. 4WD 1.5 A"
  },
  {
    SERIAL: "19380000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 5D 2.0 M",
    label: "IMPREZA 5D 2.0 M",
    value: "IMPREZA 5D 2.0 M"
  },
  {
    SERIAL: "19390000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 5D 2.0 A",
    label: "IMPREZA 5D 2.0 A",
    value: "IMPREZA 5D 2.0 A"
  },
  {
    SERIAL: "19400000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0i",
    label: "FORESTER 2.0i",
    value: "FORESTER 2.0i"
  },
  {
    SERIAL: "19410000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.5",
    label: "FORESTER 2.5",
    value: "FORESTER 2.5"
  },
  {
    SERIAL: "19420000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 4D 1.5 M",
    label: "IMPREZA 4D 1.5 M",
    value: "IMPREZA 4D 1.5 M"
  },
  {
    SERIAL: "19430000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 4D 1.5 A",
    label: "IMPREZA 4D 1.5 A",
    value: "IMPREZA 4D 1.5 A"
  },
  {
    SERIAL: "19440000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "EXIGA GT 2.0 IT 4WD",
    label: "EXIGA GT 2.0 IT 4WD",
    value: "EXIGA GT 2.0 IT 4WD"
  },
  {
    SERIAL: "19450000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER X 2.0 IT",
    label: "FORESTER X 2.0 IT",
    value: "FORESTER X 2.0 IT"
  },
  {
    SERIAL: "19460000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 4D R-S 2.0",
    label: "IMPREZA 4D R-S 2.0",
    value: "IMPREZA 4D R-S 2.0"
  },
  {
    SERIAL: "19470000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0 STI 4WD",
    label: "XV 2.0 STI 4WD",
    value: "XV 2.0 STI 4WD"
  },
  {
    SERIAL: "19480000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY 4D 2.5 GT",
    label: "LEGACY 4D 2.5 GT",
    value: "LEGACY 4D 2.5 GT"
  },
  {
    SERIAL: "19490000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "BRZ 2.0 M COUPE",
    label: "BRZ 2.0 M COUPE",
    value: "BRZ 2.0 M COUPE"
  },
  {
    SERIAL: "19500000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "BRZ 2.0 A COUPE",
    label: "BRZ 2.0 A COUPE",
    value: "BRZ 2.0 A COUPE"
  },
  {
    SERIAL: "19510000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA WRX STI 2.5 T",
    label: "IMPREZA WRX STI 2.5 T",
    value: "IMPREZA WRX STI 2.5 T"
  },
  {
    SERIAL: "19520000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA 5D R-S 2.0",
    label: "IMPREZA 5D R-S 2.0",
    value: "IMPREZA 5D R-S 2.0"
  },
  {
    SERIAL: "19530000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEGACY 4D SPORT R 4WD 2.0 T",
    label: "LEGACY 4D SPORT R 4WD 2.0 T",
    value: "LEGACY 4D SPORT R 4WD 2.0 T"
  },
  {
    SERIAL: "19540000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA WRX 2.0 M AWD",
    label: "IMPREZA WRX 2.0 M AWD",
    value: "IMPREZA WRX 2.0 M AWD"
  },
  {
    SERIAL: "19550000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA WRX 2.0 A LINEARTRONIC",
    label: "IMPREZA WRX 2.0 A LINEARTRONIC",
    value: "IMPREZA WRX 2.0 A LINEARTRONIC"
  },
  {
    SERIAL: "19560000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0 XT",
    label: "FORESTER 2.0 XT",
    value: "FORESTER 2.0 XT"
  },
  {
    SERIAL: "19570000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0 4WD",
    label: "XV 2.0 4WD",
    value: "XV 2.0 4WD"
  },
  {
    SERIAL: "19580000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0 4WD SPORT",
    label: "XV 2.0 4WD SPORT",
    value: "XV 2.0 4WD SPORT"
  },
  {
    SERIAL: "19590000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0i 4WD",
    label: "FORESTER 2.0i 4WD",
    value: "FORESTER 2.0i 4WD"
  },
  {
    SERIAL: "19600000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0i-P 4WD",
    label: "FORESTER 2.0i-P 4WD",
    value: "FORESTER 2.0i-P 4WD"
  },
  {
    SERIAL: "19610000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0 XT 4wd",
    label: "FORESTER 2.0 XT 4wd",
    value: "FORESTER 2.0 XT 4wd"
  },
  {
    SERIAL: "19620000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "LEVORG 1.6 TURBO AWD",
    label: "LEVORG 1.6 TURBO AWD",
    value: "LEVORG 1.6 TURBO AWD"
  },
  {
    SERIAL: "19630000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0i-P",
    label: "XV 2.0i-P",
    value: "XV 2.0i-P"
  },
  {
    SERIAL: "19640000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0 AWD CVT",
    label: "XV 2.0 AWD CVT",
    value: "XV 2.0 AWD CVT"
  },
  {
    SERIAL: "19650000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV CROSSTREK 2.0",
    label: "XV CROSSTREK 2.0",
    value: "XV CROSSTREK 2.0"
  },
  {
    SERIAL: "19660000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "GL",
    label: "GL",
    value: "GL"
  },
  {
    SERIAL: "19670000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0I-S",
    label: "FORESTER 2.0I-S",
    value: "FORESTER 2.0I-S"
  },
  {
    SERIAL: "19680000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "IMPREZA WRX AWD 2.0 M",
    label: "IMPREZA WRX AWD 2.0 M",
    value: "IMPREZA WRX AWD 2.0 M"
  },
  {
    SERIAL: "19690000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0I",
    label: "XV 2.0I",
    value: "XV 2.0I"
  },
  {
    SERIAL: "19700000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0I-L",
    label: "FORESTER 2.0I-L",
    value: "FORESTER 2.0I-L"
  },
  {
    SERIAL: "19710000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0I-S",
    label: "FORESTER 2.0I-S",
    value: "FORESTER 2.0I-S"
  },
  {
    SERIAL: "19720000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "FORESTER 2.0I-S ES",
    label: "FORESTER 2.0I-S ES",
    value: "FORESTER 2.0I-S ES"
  },
  {
    SERIAL: "19730000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0 AWD CVT",
    label: "XV 2.0 AWD CVT",
    value: "XV 2.0 AWD CVT"
  },
  {
    SERIAL: "19740000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "XV 2.0 GT EDITION",
    label: "XV 2.0 GT EDITION",
    value: "XV 2.0 GT EDITION"
  },
  {
    SERIAL: "19750000026",
    MAKE_NAME: "SUBARU",
    MODEL_NAME: "TRUCK-VAN",
    label: "TRUCK-VAN",
    value: "TRUCK-VAN"
  },
  {
    SERIAL: "20000000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SUZUKI",
    label: "SUZUKI",
    value: "SUZUKI"
  },
  {
    SERIAL: "20010000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CARIBIAN 1.3",
    label: "CARIBIAN 1.3",
    value: "CARIBIAN 1.3"
  },
  {
    SERIAL: "20020000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CARIBIAN SPORTY 1.3",
    label: "CARIBIAN SPORTY 1.3",
    value: "CARIBIAN SPORTY 1.3"
  },
  {
    SERIAL: "20030000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "VITARA 5Dr. 1.6 A",
    label: "VITARA 5Dr. 1.6 A",
    value: "VITARA 5Dr. 1.6 A"
  },
  {
    SERIAL: "20040000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "VITARA 3Dr. 1.6",
    label: "VITARA 3Dr. 1.6",
    value: "VITARA 3Dr. 1.6"
  },
  {
    SERIAL: "20050000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ESTEEM 1.6 M",
    label: "ESTEEM 1.6 M",
    value: "ESTEEM 1.6 M"
  },
  {
    SERIAL: "20060000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ESTEEM 1.6 A",
    label: "ESTEEM 1.6 A",
    value: "ESTEEM 1.6 A"
  },
  {
    SERIAL: "20070000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.3",
    label: "SWIFT 1.3",
    value: "SWIFT 1.3"
  },
  {
    SERIAL: "20100000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "FRONTE",
    label: "FRONTE",
    value: "FRONTE"
  },
  {
    SERIAL: "20140000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "VITARA 5 DR. (M)",
    label: "VITARA 5 DR. (M)",
    value: "VITARA 5 DR. (M)"
  },
  {
    SERIAL: "20150000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "VITARA 5 DR. 1.6 A",
    label: "VITARA 5 DR. 1.6 A",
    value: "VITARA 5 DR. 1.6 A"
  },
  {
    SERIAL: "20160000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GRAND VITARA 2.7 (7SEAT)",
    label: "GRAND VITARA 2.7 (7SEAT)",
    value: "GRAND VITARA 2.7 (7SEAT)"
  },
  {
    SERIAL: "20170000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GRAND VITARA 2.0 (5SEAT)",
    label: "GRAND VITARA 2.0 (5SEAT)",
    value: "GRAND VITARA 2.0 (5SEAT)"
  },
  {
    SERIAL: "20190000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BEST PRO",
    label: "BEST PRO",
    value: "BEST PRO"
  },
  {
    SERIAL: "20200000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "RAIDER",
    label: "RAIDER",
    value: "RAIDER"
  },
  {
    SERIAL: "20210000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "APV GA 1.6",
    label: "APV GA 1.6",
    value: "APV GA 1.6"
  },
  {
    SERIAL: "20220000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "APV GL 1.6",
    label: "APV GL 1.6",
    value: "APV GL 1.6"
  },
  {
    SERIAL: "20230000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "APV GLX 1.6",
    label: "APV GLX 1.6",
    value: "APV GLX 1.6"
  },
  {
    SERIAL: "20240000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX1400",
    label: "GSX1400",
    value: "GSX1400"
  },
  {
    SERIAL: "20250000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-R",
    label: "GSX-R",
    value: "GSX-R"
  },
  {
    SERIAL: "20260000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "HAYABUSA GSX1300",
    label: "HAYABUSA GSX1300",
    value: "HAYABUSA GSX1300"
  },
  {
    SERIAL: "20270000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX 1300 R HAYABUSA",
    label: "GSX 1300 R HAYABUSA",
    value: "GSX 1300 R HAYABUSA"
  },
  {
    SERIAL: "20280000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CARRY PICK-UP 1.6",
    label: "CARRY PICK-UP 1.6",
    value: "CARRY PICK-UP 1.6"
  },
  {
    SERIAL: "20290000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "HAYABUSA",
    label: "HAYABUSA",
    value: "HAYABUSA"
  },
  {
    SERIAL: "20300000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT WAGON 1.5",
    label: "SWIFT WAGON 1.5",
    value: "SWIFT WAGON 1.5"
  },
  {
    SERIAL: "20310000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "VAN CBU",
    label: "VAN CBU",
    value: "VAN CBU"
  },
  {
    SERIAL: "20320000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "APV GLS 1.6",
    label: "APV GLS 1.6",
    value: "APV GLS 1.6"
  },
  {
    SERIAL: "20330000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX 1400",
    label: "GSX 1400",
    value: "GSX 1400"
  },
  {
    SERIAL: "20340000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT NEW 1.5 GA",
    label: "SWIFT NEW 1.5 GA",
    value: "SWIFT NEW 1.5 GA"
  },
  {
    SERIAL: "20350000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT NEW 1.5 GL",
    label: "SWIFT NEW 1.5 GL",
    value: "SWIFT NEW 1.5 GL"
  },
  {
    SERIAL: "20360000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BIMOTA SB8R",
    label: "BIMOTA SB8R",
    value: "BIMOTA SB8R"
  },
  {
    SERIAL: "20370000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD C50",
    label: "BOULEVARD C50",
    value: "BOULEVARD C50"
  },
  {
    SERIAL: "20380000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD C50T",
    label: "BOULEVARD C50T",
    value: "BOULEVARD C50T"
  },
  {
    SERIAL: "20390000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD C90",
    label: "BOULEVARD C90",
    value: "BOULEVARD C90"
  },
  {
    SERIAL: "20400000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD C90T",
    label: "BOULEVARD C90T",
    value: "BOULEVARD C90T"
  },
  {
    SERIAL: "20410000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD C109R",
    label: "BOULEVARD C109R",
    value: "BOULEVARD C109R"
  },
  {
    SERIAL: "20420000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD C109RT",
    label: "BOULEVARD C109RT",
    value: "BOULEVARD C109RT"
  },
  {
    SERIAL: "20430000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD M109R",
    label: "BOULEVARD M109R",
    value: "BOULEVARD M109R"
  },
  {
    SERIAL: "20440000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD M50",
    label: "BOULEVARD M50",
    value: "BOULEVARD M50"
  },
  {
    SERIAL: "20450000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD S50",
    label: "BOULEVARD S50",
    value: "BOULEVARD S50"
  },
  {
    SERIAL: "20460000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD S83",
    label: "BOULEVARD S83",
    value: "BOULEVARD S83"
  },
  {
    SERIAL: "20470000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-R 1300",
    label: "GSX-R 1300",
    value: "GSX-R 1300"
  },
  {
    SERIAL: "20480000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "HAYABUSA GSX1300 RA",
    label: "HAYABUSA GSX1300 RA",
    value: "HAYABUSA GSX1300 RA"
  },
  {
    SERIAL: "20490000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-R1000",
    label: "GSX-R1000",
    value: "GSX-R1000"
  },
  {
    SERIAL: "20500000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GRAND VITARA 2.5",
    label: "GRAND VITARA 2.5",
    value: "GRAND VITARA 2.5"
  },
  {
    SERIAL: "20510000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "INTRUDER 1800",
    label: "INTRUDER 1800",
    value: "INTRUDER 1800"
  },
  {
    SERIAL: "20520000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-R 1600",
    label: "GSX-R 1600",
    value: "GSX-R 1600"
  },
  {
    SERIAL: "20530000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SHOGUN 125",
    label: "SHOGUN 125",
    value: "SHOGUN 125"
  },
  {
    SERIAL: "20540000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-750",
    label: "GSX-750",
    value: "GSX-750"
  },
  {
    SERIAL: "20550000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BURGMAN 650",
    label: "BURGMAN 650",
    value: "BURGMAN 650"
  },
  {
    SERIAL: "20560000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "INTRUDER M800",
    label: "INTRUDER M800",
    value: "INTRUDER M800"
  },
  {
    SERIAL: "20570000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SV650 SA",
    label: "SV650 SA",
    value: "SV650 SA"
  },
  {
    SERIAL: "20580000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GLADIUS SFV650",
    label: "GLADIUS SFV650",
    value: "GLADIUS SFV650"
  },
  {
    SERIAL: "20590000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BANDIT 1250",
    label: "BANDIT 1250",
    value: "BANDIT 1250"
  },
  {
    SERIAL: "20600000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BANDIT 1250 SA",
    label: "BANDIT 1250 SA",
    value: "BANDIT 1250 SA"
  },
  {
    SERIAL: "20610000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-R 750",
    label: "GSX-R 750",
    value: "GSX-R 750"
  },
  {
    SERIAL: "20620000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SX4 1.6",
    label: "SX4 1.6",
    value: "SX4 1.6"
  },
  {
    SERIAL: "20630000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GA A",
    label: "SWIFT 1.2 GA A",
    value: "SWIFT 1.2 GA A"
  },
  {
    SERIAL: "20640000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GL A",
    label: "SWIFT 1.2 GL A",
    value: "SWIFT 1.2 GL A"
  },
  {
    SERIAL: "20650000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GLX",
    label: "SWIFT 1.2 GLX",
    value: "SWIFT 1.2 GLX"
  },
  {
    SERIAL: "20660000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ERTIGA GA M 1.4",
    label: "ERTIGA GA M 1.4",
    value: "ERTIGA GA M 1.4"
  },
  {
    SERIAL: "20670000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ERTIGA GL A 1.4",
    label: "ERTIGA GL A 1.4",
    value: "ERTIGA GL A 1.4"
  },
  {
    SERIAL: "20680000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ERTIGA GX A 1.4",
    label: "ERTIGA GX A 1.4",
    value: "ERTIGA GX A 1.4"
  },
  {
    SERIAL: "20690000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "DRZ40",
    label: "DRZ40",
    value: "DRZ40"
  },
  {
    SERIAL: "20700000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CELERIO GA 1.0 M",
    label: "CELERIO GA 1.0 M",
    value: "CELERIO GA 1.0 M"
  },
  {
    SERIAL: "20710000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CELERIO GL 1.0 A",
    label: "CELERIO GL 1.0 A",
    value: "CELERIO GL 1.0 A"
  },
  {
    SERIAL: "20720000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-R 1000",
    label: "GSX-R 1000",
    value: "GSX-R 1000"
  },
  {
    SERIAL: "20730000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "HAYABUSA GSX 1300 R",
    label: "HAYABUSA GSX 1300 R",
    value: "HAYABUSA GSX 1300 R"
  },
  {
    SERIAL: "20740000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "HAYATE",
    label: "HAYATE",
    value: "HAYATE"
  },
  {
    SERIAL: "20750000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CELERIO GLX 1.0 A",
    label: "CELERIO GLX 1.0 A",
    value: "CELERIO GLX 1.0 A"
  },
  {
    SERIAL: "20760000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "V-STROM 1000",
    label: "V-STROM 1000",
    value: "V-STROM 1000"
  },
  {
    SERIAL: "20770000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GA M",
    label: "SWIFT 1.2 GA M",
    value: "SWIFT 1.2 GA M"
  },
  {
    SERIAL: "20780000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GL M",
    label: "SWIFT 1.2 GL M",
    value: "SWIFT 1.2 GL M"
  },
  {
    SERIAL: "20790000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 RX",
    label: "SWIFT 1.2 RX",
    value: "SWIFT 1.2 RX"
  },
  {
    SERIAL: "20800000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CIAZ GA 1.25 M",
    label: "CIAZ GA 1.25 M",
    value: "CIAZ GA 1.25 M"
  },
  {
    SERIAL: "20810000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CIAZ GL 1.25 M",
    label: "CIAZ GL 1.25 M",
    value: "CIAZ GL 1.25 M"
  },
  {
    SERIAL: "20820000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CIAZ GL 1.25 A",
    label: "CIAZ GL 1.25 A",
    value: "CIAZ GL 1.25 A"
  },
  {
    SERIAL: "20830000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CIAZ GLX 1.25 A",
    label: "CIAZ GLX 1.25 A",
    value: "CIAZ GLX 1.25 A"
  },
  {
    SERIAL: "20840000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "HUSTLER",
    label: "HUSTLER",
    value: "HUSTLER"
  },
  {
    SERIAL: "20850000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CIAZ 1.25 RS",
    label: "CIAZ 1.25 RS",
    value: "CIAZ 1.25 RS"
  },
  {
    SERIAL: "20870000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "K7 1000",
    label: "K7 1000",
    value: "K7 1000"
  },
  {
    SERIAL: "20890000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-S1000",
    label: "GSX-S1000",
    value: "GSX-S1000"
  },
  {
    SERIAL: "20900000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-S1000 F",
    label: "GSX-S1000 F",
    value: "GSX-S1000 F"
  },
  {
    SERIAL: "20910000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT SAI 1.2",
    label: "SWIFT SAI 1.2",
    value: "SWIFT SAI 1.2"
  },
  {
    SERIAL: "20920000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ERTIGA DREZA 1.4",
    label: "ERTIGA DREZA 1.4",
    value: "ERTIGA DREZA 1.4"
  },
  {
    SERIAL: "20930000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "V STROM 650 XT",
    label: "V STROM 650 XT",
    value: "V STROM 650 XT"
  },
  {
    SERIAL: "20940000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "WAGON R 1.3",
    label: "WAGON R 1.3",
    value: "WAGON R 1.3"
  },
  {
    SERIAL: "20950000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GD110",
    label: "GD110",
    value: "GD110"
  },
  {
    SERIAL: "20960000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "110 CC",
    label: "110 CC",
    value: "110 CC"
  },
  {
    SERIAL: "20970000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "125 CC",
    label: "125 CC",
    value: "125 CC"
  },
  {
    SERIAL: "20980000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "20990000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GA A",
    label: "SWIFT 1.2 GA A",
    value: "SWIFT 1.2 GA A"
  },
  {
    SERIAL: "20AA0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SMASH",
    label: "SMASH",
    value: "SMASH"
  },
  {
    SERIAL: "20AB0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "เกิน 150 CC",
    label: "เกิน 150 CC",
    value: "เกิน 150 CC"
  },
  {
    SERIAL: "20AC0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "EVERY",
    label: "EVERY",
    value: "EVERY"
  },
  {
    SERIAL: "20AD0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "RAIDER R150",
    label: "RAIDER R150",
    value: "RAIDER R150"
  },
  {
    SERIAL: "20AE0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "LET 113",
    label: "LET 113",
    value: "LET 113"
  },
  {
    SERIAL: "20AF0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BURGMAN 200",
    label: "BURGMAN 200",
    value: "BURGMAN 200"
  },
  {
    SERIAL: "20AG0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "VAN VAN RV 125",
    label: "VAN VAN RV 125",
    value: "VAN VAN RV 125"
  },
  {
    SERIAL: "20AH0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SKYDRIVE 125",
    label: "SKYDRIVE 125",
    value: "SKYDRIVE 125"
  },
  {
    SERIAL: "20AI0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BOULEVARD VL800",
    label: "BOULEVARD VL800",
    value: "BOULEVARD VL800"
  },
  {
    SERIAL: "20AJ0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "LAPIN CHOCOLAT",
    label: "LAPIN CHOCOLAT",
    value: "LAPIN CHOCOLAT"
  },
  {
    SERIAL: "20AK0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "VAN",
    label: "VAN",
    value: "VAN"
  },
  {
    SERIAL: "20AL0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GL A",
    label: "SWIFT 1.2 GL A",
    value: "SWIFT 1.2 GL A"
  },
  {
    SERIAL: "20AM0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GLX A",
    label: "SWIFT 1.2 GLX A",
    value: "SWIFT 1.2 GLX A"
  },
  {
    SERIAL: "20AN0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GLX NAVIGATOR A",
    label: "SWIFT 1.2 GLX NAVIGATOR A",
    value: "SWIFT 1.2 GLX NAVIGATOR A"
  },
  {
    SERIAL: "20AO0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-S750",
    label: "GSX-S750",
    value: "GSX-S750"
  },
  {
    SERIAL: "20AP0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX R1000 - L3",
    label: "GSX R1000 - L3",
    value: "GSX R1000 - L3"
  },
  {
    SERIAL: "20AQ0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ERTIGA 1.5 GL",
    label: "ERTIGA 1.5 GL",
    value: "ERTIGA 1.5 GL"
  },
  {
    SERIAL: "20AR0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ERTIGA 1.5 GX",
    label: "ERTIGA 1.5 GX",
    value: "ERTIGA 1.5 GX"
  },
  {
    SERIAL: "20AS0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CELERIO 1.0 A GX",
    label: "CELERIO 1.0 A GX",
    value: "CELERIO 1.0 A GX"
  },
  {
    SERIAL: "20AT0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "2000",
    label: "2000",
    value: "2000"
  },
  {
    SERIAL: "20AU0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "MINI TRUCK",
    label: "MINI TRUCK",
    value: "MINI TRUCK"
  },
  {
    SERIAL: "20AV0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "ADDRESS 113",
    label: "ADDRESS 113",
    value: "ADDRESS 113"
  },
  {
    SERIAL: "20AW0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "JIMNY 1.5 M",
    label: "JIMNY 1.5 M",
    value: "JIMNY 1.5 M"
  },
  {
    SERIAL: "20AX0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "JIMNY 1.5 AUTO",
    label: "JIMNY 1.5 AUTO",
    value: "JIMNY 1.5 AUTO"
  },
  {
    SERIAL: "20AY0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CARRY 1.5",
    label: "CARRY 1.5",
    value: "CARRY 1.5"
  },
  {
    SERIAL: "20AZ0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "JELATO",
    label: "JELATO",
    value: "JELATO"
  },
  {
    SERIAL: "20BA0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "CIAZ GL PLUS 1.25 A",
    label: "CIAZ GL PLUS 1.25 A",
    value: "CIAZ GL PLUS 1.25 A"
  },
  {
    SERIAL: "20BB0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GL SPORT EDITION",
    label: "SWIFT 1.2 GL SPORT EDITION",
    value: "SWIFT 1.2 GL SPORT EDITION"
  },
  {
    SERIAL: "20BC0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "XL7 1.5",
    label: "XL7 1.5",
    value: "XL7 1.5"
  },
  {
    SERIAL: "20BD0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "BURGMAN 400",
    label: "BURGMAN 400",
    value: "BURGMAN 400"
  },
  {
    SERIAL: "20BE0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "SWIFT 1.2 GL MAX EDITION",
    label: "SWIFT 1.2 GL MAX EDITION",
    value: "SWIFT 1.2 GL MAX EDITION"
  },
  {
    SERIAL: "20BI0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX-R150",
    label: "GSX-R150",
    value: "GSX-R150"
  },
  {
    SERIAL: "20BJ0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "GSX R1000-L7",
    label: "GSX R1000-L7",
    value: "GSX R1000-L7"
  },
  {
    SERIAL: "20BU0000026",
    MAKE_NAME: "SUZUKI",
    MODEL_NAME: "B-KING",
    label: "B-KING",
    value: "B-KING"
  },
  {
    SERIAL: "21000000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "AUDI",
    label: "AUDI",
    value: "AUDI"
  },
  {
    SERIAL: "21010000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "100 2.0 E",
    label: "100 2.0 E",
    value: "100 2.0 E"
  },
  {
    SERIAL: "21030000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "100 2.3 E",
    label: "100 2.3 E",
    value: "100 2.3 E"
  },
  {
    SERIAL: "21040000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "100 2.8 E",
    label: "100 2.8 E",
    value: "100 2.8 E"
  },
  {
    SERIAL: "21050000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "80 2.0 E",
    label: "80 2.0 E",
    value: "80 2.0 E"
  },
  {
    SERIAL: "21100000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 1.8",
    label: "A4 1.8",
    value: "A4 1.8"
  },
  {
    SERIAL: "21110000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 2.4",
    label: "A6 2.4",
    value: "A6 2.4"
  },
  {
    SERIAL: "21130000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "S6 4WD(A) 2.8",
    label: "S6 4WD(A) 2.8",
    value: "S6 4WD(A) 2.8"
  },
  {
    SERIAL: "21140000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 2.4",
    label: "A4 2.4",
    value: "A4 2.4"
  },
  {
    SERIAL: "21150000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 2.3",
    label: "A6 2.3",
    value: "A6 2.3"
  },
  {
    SERIAL: "21160000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 1.8",
    label: "A3 1.8",
    value: "A3 1.8"
  },
  {
    SERIAL: "21170000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 1.8 TURBO 3 DR. (NEW)",
    label: "A3 1.8 TURBO 3 DR. (NEW)",
    value: "A3 1.8 TURBO 3 DR. (NEW)"
  },
  {
    SERIAL: "21180000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 1.8 5DR. (NEW)",
    label: "A3 1.8 5DR. (NEW)",
    value: "A3 1.8 5DR. (NEW)"
  },
  {
    SERIAL: "21190000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 2.0",
    label: "A4 2.0",
    value: "A4 2.0"
  },
  {
    SERIAL: "21200000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 2.4 (Y.2000)",
    label: "A4 2.4 (Y.2000)",
    value: "A4 2.4 (Y.2000)"
  },
  {
    SERIAL: "21210000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 2.4 AVANT MULTITRONIC",
    label: "A6 2.4 AVANT MULTITRONIC",
    value: "A6 2.4 AVANT MULTITRONIC"
  },
  {
    SERIAL: "21240000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 WAGON 2.4",
    label: "A6 WAGON 2.4",
    value: "A6 WAGON 2.4"
  },
  {
    SERIAL: "21250000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 2.4 AVANT QUATTRO 4WD",
    label: "A6 2.4 AVANT QUATTRO 4WD",
    value: "A6 2.4 AVANT QUATTRO 4WD"
  },
  {
    SERIAL: "21260000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A8 QUATTRO",
    label: "A8 QUATTRO",
    value: "A8 QUATTRO"
  },
  {
    SERIAL: "21290000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT COUPE 1.8",
    label: "TT COUPE 1.8",
    value: "TT COUPE 1.8"
  },
  {
    SERIAL: "21300000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT CONVRTIBLE 1.8",
    label: "TT CONVRTIBLE 1.8",
    value: "TT CONVRTIBLE 1.8"
  },
  {
    SERIAL: "21310000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 3.0",
    label: "A4 3.0",
    value: "A4 3.0"
  },
  {
    SERIAL: "21320000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT COUPE QUATTRO 4WD 1.8",
    label: "TT COUPE QUATTRO 4WD 1.8",
    value: "TT COUPE QUATTRO 4WD 1.8"
  },
  {
    SERIAL: "21330000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT CONVERTIBLE QUATTRO 4WD 1.8",
    label: "TT CONVERTIBLE QUATTRO 4WD 1.8",
    value: "TT CONVERTIBLE QUATTRO 4WD 1.8"
  },
  {
    SERIAL: "21340000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 1.8 TURBO",
    label: "A4 1.8 TURBO",
    value: "A4 1.8 TURBO"
  },
  {
    SERIAL: "21350000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 2.0 WAGON AVANT TURBO FSI",
    label: "A4 2.0 WAGON AVANT TURBO FSI",
    value: "A4 2.0 WAGON AVANT TURBO FSI"
  },
  {
    SERIAL: "21360000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 AVANT 1.8 TURBO",
    label: "A4 AVANT 1.8 TURBO",
    value: "A4 AVANT 1.8 TURBO"
  },
  {
    SERIAL: "21370000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 AVANT 2.0 FSI WAGON",
    label: "A4 AVANT 2.0 FSI WAGON",
    value: "A4 AVANT 2.0 FSI WAGON"
  },
  {
    SERIAL: "21380000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 COMFORT 4WD 3.0",
    label: "Q7 COMFORT 4WD 3.0",
    value: "Q7 COMFORT 4WD 3.0"
  },
  {
    SERIAL: "21390000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 2.0 TDI",
    label: "A4 2.0 TDI",
    value: "A4 2.0 TDI"
  },
  {
    SERIAL: "21400000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 1.8 5DR",
    label: "A3 1.8 5DR",
    value: "A3 1.8 5DR"
  },
  {
    SERIAL: "21410000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 QUATTRO ML 3.0 4WD",
    label: "A6 QUATTRO ML 3.0 4WD",
    value: "A6 QUATTRO ML 3.0 4WD"
  },
  {
    SERIAL: "21420000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 EXECUTIVE ML 2.4",
    label: "A6 EXECUTIVE ML 2.4",
    value: "A6 EXECUTIVE ML 2.4"
  },
  {
    SERIAL: "21430000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 ML 2.0 FSI",
    label: "A6 ML 2.0 FSI",
    value: "A6 ML 2.0 FSI"
  },
  {
    SERIAL: "21440000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 QUATTRO ML TDI 4WD 3.0 4D",
    label: "A6 QUATTRO ML TDI 4WD 3.0 4D",
    value: "A6 QUATTRO ML TDI 4WD 3.0 4D"
  },
  {
    SERIAL: "21450000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 QUATTRO WAGON TDI TP 4wd 3.0",
    label: "A6 QUATTRO WAGON TDI TP 4wd 3.0",
    value: "A6 QUATTRO WAGON TDI TP 4wd 3.0"
  },
  {
    SERIAL: "21460000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 AMBITION TP 3.0 TDI",
    label: "Q7 AMBITION TP 3.0 TDI",
    value: "Q7 AMBITION TP 3.0 TDI"
  },
  {
    SERIAL: "21470000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 PREMIUM 3.0",
    label: "Q7 PREMIUM 3.0",
    value: "Q7 PREMIUM 3.0"
  },
  {
    SERIAL: "21480000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS6",
    label: "RS6",
    value: "RS6"
  },
  {
    SERIAL: "21490000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT COUPE 2.0 FSI",
    label: "TT COUPE 2.0 FSI",
    value: "TT COUPE 2.0 FSI"
  },
  {
    SERIAL: "21500000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 2.0 FSI",
    label: "A4 2.0 FSI",
    value: "A4 2.0 FSI"
  },
  {
    SERIAL: "21510000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 AVANT TFSI 2.0",
    label: "A6 AVANT TFSI 2.0",
    value: "A6 AVANT TFSI 2.0"
  },
  {
    SERIAL: "21520000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q5 4WD WAGON 2.0",
    label: "Q5 4WD WAGON 2.0",
    value: "Q5 4WD WAGON 2.0"
  },
  {
    SERIAL: "21530000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "R8 V8",
    label: "R8 V8",
    value: "R8 V8"
  },
  {
    SERIAL: "21540000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TTS 2.0",
    label: "TTS 2.0",
    value: "TTS 2.0"
  },
  {
    SERIAL: "21550000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 QUATTRO CVT",
    label: "A5 QUATTRO CVT",
    value: "A5 QUATTRO CVT"
  },
  {
    SERIAL: "21560000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A8 FSI 2.8",
    label: "A8 FSI 2.8",
    value: "A8 FSI 2.8"
  },
  {
    SERIAL: "21570000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 3.0 TDI QUATTRO",
    label: "Q7 3.0 TDI QUATTRO",
    value: "Q7 3.0 TDI QUATTRO"
  },
  {
    SERIAL: "21580000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "R8 V10",
    label: "R8 V10",
    value: "R8 V10"
  },
  {
    SERIAL: "21590000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "S8 5.2",
    label: "S8 5.2",
    value: "S8 5.2"
  },
  {
    SERIAL: "21600000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT RS",
    label: "TT RS",
    value: "TT RS"
  },
  {
    SERIAL: "21610000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT COUPE QUATTRO (GREY)",
    label: "TT COUPE QUATTRO (GREY)",
    value: "TT COUPE QUATTRO (GREY)"
  },
  {
    SERIAL: "21620000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "S5",
    label: "S5",
    value: "S5"
  },
  {
    SERIAL: "21630000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 SPORTBACK S LINE",
    label: "A5 SPORTBACK S LINE",
    value: "A5 SPORTBACK S LINE"
  },
  {
    SERIAL: "21640000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A1 S LINE 1.4",
    label: "A1 S LINE 1.4",
    value: "A1 S LINE 1.4"
  },
  {
    SERIAL: "21650000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT CONVERTIBLE 2.0",
    label: "TT CONVERTIBLE 2.0",
    value: "TT CONVERTIBLE 2.0"
  },
  {
    SERIAL: "21660000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TTS 2.0 CONVERTIBLE",
    label: "TTS 2.0 CONVERTIBLE",
    value: "TTS 2.0 CONVERTIBLE"
  },
  {
    SERIAL: "21670000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A1 1.4",
    label: "A1 1.4",
    value: "A1 1.4"
  },
  {
    SERIAL: "21680000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A7 3.0",
    label: "A7 3.0",
    value: "A7 3.0"
  },
  {
    SERIAL: "21690000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q5",
    label: "Q5",
    value: "Q5"
  },
  {
    SERIAL: "21700000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q5 2.0 TFSI QUATTRO",
    label: "Q5 2.0 TFSI QUATTRO",
    value: "Q5 2.0 TFSI QUATTRO"
  },
  {
    SERIAL: "21710000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 COUPE QUATTRO 2.0",
    label: "A5 COUPE QUATTRO 2.0",
    value: "A5 COUPE QUATTRO 2.0"
  },
  {
    SERIAL: "21720000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A1 1.6 TDI SUNROOF",
    label: "A1 1.6 TDI SUNROOF",
    value: "A1 1.6 TDI SUNROOF"
  },
  {
    SERIAL: "21730000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 4.2 TDI",
    label: "Q7 4.2 TDI",
    value: "Q7 4.2 TDI"
  },
  {
    SERIAL: "21740000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS3 2.5",
    label: "RS3 2.5",
    value: "RS3 2.5"
  },
  {
    SERIAL: "21750000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 2.0 TDI AVANT",
    label: "A6 2.0 TDI AVANT",
    value: "A6 2.0 TDI AVANT"
  },
  {
    SERIAL: "21760000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS5 4.2 COUPE",
    label: "RS5 4.2 COUPE",
    value: "RS5 4.2 COUPE"
  },
  {
    SERIAL: "21770000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 TFSI QUATTRO 2.0",
    label: "Q3 TFSI QUATTRO 2.0",
    value: "Q3 TFSI QUATTRO 2.0"
  },
  {
    SERIAL: "21780000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 6.0",
    label: "Q7 6.0",
    value: "Q7 6.0"
  },
  {
    SERIAL: "21790000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A8 4.2",
    label: "A8 4.2",
    value: "A8 4.2"
  },
  {
    SERIAL: "21800000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 3.0 TDI AVANT",
    label: "A6 3.0 TDI AVANT",
    value: "A6 3.0 TDI AVANT"
  },
  {
    SERIAL: "21810000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "S3",
    label: "S3",
    value: "S3"
  },
  {
    SERIAL: "21820000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS4 4.2",
    label: "RS4 4.2",
    value: "RS4 4.2"
  },
  {
    SERIAL: "21830000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A8 HYBRID 2.0",
    label: "A8 HYBRID 2.0",
    value: "A8 HYBRID 2.0"
  },
  {
    SERIAL: "21840000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 2.0 TDI",
    label: "A4 2.0 TDI",
    value: "A4 2.0 TDI"
  },
  {
    SERIAL: "21850000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS6 5.0",
    label: "RS6 5.0",
    value: "RS6 5.0"
  },
  {
    SERIAL: "21860000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 2.0 T HYBRID",
    label: "A6 2.0 T HYBRID",
    value: "A6 2.0 T HYBRID"
  },
  {
    SERIAL: "21870000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 QUATTRO CABRIOLET 4WD 2.0T",
    label: "A5 QUATTRO CABRIOLET 4WD 2.0T",
    value: "A5 QUATTRO CABRIOLET 4WD 2.0T"
  },
  {
    SERIAL: "21880000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 WAGON AVANT MULTITRONIC 2.0T",
    label: "A6 WAGON AVANT MULTITRONIC 2.0T",
    value: "A6 WAGON AVANT MULTITRONIC 2.0T"
  },
  {
    SERIAL: "21890000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 LIMOUSINE 1.4 TFSI",
    label: "A3 LIMOUSINE 1.4 TFSI",
    value: "A3 LIMOUSINE 1.4 TFSI"
  },
  {
    SERIAL: "21900000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 2.0 TFSI S-LINE QUATTRO",
    label: "Q3 2.0 TFSI S-LINE QUATTRO",
    value: "Q3 2.0 TFSI S-LINE QUATTRO"
  },
  {
    SERIAL: "21910000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q5 2.0 TDI QUATTRO",
    label: "Q5 2.0 TDI QUATTRO",
    value: "Q5 2.0 TDI QUATTRO"
  },
  {
    SERIAL: "21920000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 2.0 TDI QUATTRO",
    label: "Q3 2.0 TDI QUATTRO",
    value: "Q3 2.0 TDI QUATTRO"
  },
  {
    SERIAL: "21930000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "S8 4.0",
    label: "S8 4.0",
    value: "S8 4.0"
  },
  {
    SERIAL: "21940000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TTS QUATTRO 2.0 COUPE",
    label: "TTS QUATTRO 2.0 COUPE",
    value: "TTS QUATTRO 2.0 COUPE"
  },
  {
    SERIAL: "21950000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 TFSI S LINE QUATTRO 2.0",
    label: "A4 TFSI S LINE QUATTRO 2.0",
    value: "A4 TFSI S LINE QUATTRO 2.0"
  },
  {
    SERIAL: "21960000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 TFSI S LINE QUATTRO AVANT 2.0 BLACK EDITION",
    label: "A4 TFSI S LINE QUATTRO AVANT 2.0 BLACK EDITION",
    value: "A4 TFSI S LINE QUATTRO AVANT 2.0 BLACK EDITION"
  },
  {
    SERIAL: "21970000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 40 TFSI 2.0 COUPE",
    label: "A5 40 TFSI 2.0 COUPE",
    value: "A5 40 TFSI 2.0 COUPE"
  },
  {
    SERIAL: "21980000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A7 2.8",
    label: "A7 2.8",
    value: "A7 2.8"
  },
  {
    SERIAL: "21990000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 SPORTBACK 40 TFSI 2.0",
    label: "A5 SPORTBACK 40 TFSI 2.0",
    value: "A5 SPORTBACK 40 TFSI 2.0"
  },
  {
    SERIAL: "21AA0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 2.0 CDI",
    label: "A3 2.0 CDI",
    value: "A3 2.0 CDI"
  },
  {
    SERIAL: "21AB0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 2.5",
    label: "A3 2.5",
    value: "A3 2.5"
  },
  {
    SERIAL: "21AC0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT COUPE QUATTRO 2.0 TFSI",
    label: "TT COUPE QUATTRO 2.0 TFSI",
    value: "TT COUPE QUATTRO 2.0 TFSI"
  },
  {
    SERIAL: "21AD0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 TFSI S SPORT 2.0",
    label: "A4 TFSI S SPORT 2.0",
    value: "A4 TFSI S SPORT 2.0"
  },
  {
    SERIAL: "21AE0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 TFSI 2.0 QUATTRO 4WD",
    label: "Q3 TFSI 2.0 QUATTRO 4WD",
    value: "Q3 TFSI 2.0 QUATTRO 4WD"
  },
  {
    SERIAL: "21AF0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 2.0 TDI QUATTRO 4WD",
    label: "Q3 2.0 TDI QUATTRO 4WD",
    value: "Q3 2.0 TDI QUATTRO 4WD"
  },
  {
    SERIAL: "21AG0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 TFSI S LINE 2.0",
    label: "A4 TFSI S LINE 2.0",
    value: "A4 TFSI S LINE 2.0"
  },
  {
    SERIAL: "21AH0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A3 TDI SPORT BACK S 2.0",
    label: "A3 TDI SPORT BACK S 2.0",
    value: "A3 TDI SPORT BACK S 2.0"
  },
  {
    SERIAL: "21AI0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 QUATTRO 4WD 3.0 TDI",
    label: "Q7 QUATTRO 4WD 3.0 TDI",
    value: "Q7 QUATTRO 4WD 3.0 TDI"
  },
  {
    SERIAL: "21AJ0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 TFSI S 1.8",
    label: "A6 TFSI S 1.8",
    value: "A6 TFSI S 1.8"
  },
  {
    SERIAL: "21AK0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 2.0 TDI S",
    label: "A6 2.0 TDI S",
    value: "A6 2.0 TDI S"
  },
  {
    SERIAL: "21AL0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "S3 2.0",
    label: "S3 2.0",
    value: "S3 2.0"
  },
  {
    SERIAL: "21AM0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 3.0TDI",
    label: "A6 3.0TDI",
    value: "A6 3.0TDI"
  },
  {
    SERIAL: "21AN0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 45 QUATTRO S LINE 3.0 TFSI",
    label: "Q7 45 QUATTRO S LINE 3.0 TFSI",
    value: "Q7 45 QUATTRO S LINE 3.0 TFSI"
  },
  {
    SERIAL: "21AO0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 3.0 TDI QUATTRO",
    label: "Q7 3.0 TDI QUATTRO",
    value: "Q7 3.0 TDI QUATTRO"
  },
  {
    SERIAL: "21AP0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 TFSI QUATTRO 2.0",
    label: "Q7 TFSI QUATTRO 2.0",
    value: "Q7 TFSI QUATTRO 2.0"
  },
  {
    SERIAL: "21AQ0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 TFSI S QUATTRO 2.0",
    label: "A4 TFSI S QUATTRO 2.0",
    value: "A4 TFSI S QUATTRO 2.0"
  },
  {
    SERIAL: "21AR0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 TFSI S LINE 1.8",
    label: "A6 TFSI S LINE 1.8",
    value: "A6 TFSI S LINE 1.8"
  },
  {
    SERIAL: "21AS0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q2 35 TFSI S 1.4",
    label: "Q2 35 TFSI S 1.4",
    value: "Q2 35 TFSI S 1.4"
  },
  {
    SERIAL: "21AT0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 TFSI S 2.0",
    label: "A5 TFSI S 2.0",
    value: "A5 TFSI S 2.0"
  },
  {
    SERIAL: "21AU0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 TFSI QUATTRO S LINE 2.0 COUPE",
    label: "A5 TFSI QUATTRO S LINE 2.0 COUPE",
    value: "A5 TFSI QUATTRO S LINE 2.0 COUPE"
  },
  {
    SERIAL: "21AV0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q5 45 TFSI QUATTRO S LINE 4WD 2.0",
    label: "Q5 45 TFSI QUATTRO S LINE 4WD 2.0",
    value: "Q5 45 TFSI QUATTRO S LINE 4WD 2.0"
  },
  {
    SERIAL: "21AW0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT COUPE QUATTRO S LINE 2.0 TFSI",
    label: "TT COUPE QUATTRO S LINE 2.0 TFSI",
    value: "TT COUPE QUATTRO S LINE 2.0 TFSI"
  },
  {
    SERIAL: "21AX0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS4 2.0",
    label: "RS4 2.0",
    value: "RS4 2.0"
  },
  {
    SERIAL: "21AY0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 SPORTBACK 45 TFSI QUATTRO S LINE 2.0",
    label: "A5 SPORTBACK 45 TFSI QUATTRO S LINE 2.0",
    value: "A5 SPORTBACK 45 TFSI QUATTRO S LINE 2.0"
  },
  {
    SERIAL: "21AZ0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 45 QUATTRO S LINE 3.0 TDI",
    label: "Q7 45 QUATTRO S LINE 3.0 TDI",
    value: "Q7 45 QUATTRO S LINE 3.0 TDI"
  },
  {
    SERIAL: "21BA0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS3 2.5",
    label: "RS3 2.5",
    value: "RS3 2.5"
  },
  {
    SERIAL: "21BB0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A7 SPORTBACK 55 TFSI QUATTRO S-LINE 3.0",
    label: "A7 SPORTBACK 55 TFSI QUATTRO S-LINE 3.0",
    value: "A7 SPORTBACK 55 TFSI QUATTRO S-LINE 3.0"
  },
  {
    SERIAL: "21BC0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A8 L55 TFSI QUATTRO PREMIUM 3.0 4WD",
    label: "A8 L55 TFSI QUATTRO PREMIUM 3.0 4WD",
    value: "A8 L55 TFSI QUATTRO PREMIUM 3.0 4WD"
  },
  {
    SERIAL: "21BD0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 40 S LINE 2.0 COUPE TFSI",
    label: "A5 40 S LINE 2.0 COUPE TFSI",
    value: "A5 40 S LINE 2.0 COUPE TFSI"
  },
  {
    SERIAL: "21BE0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A8 L55 TFSI QUATTRO PRESTIGE 3.0",
    label: "A8 L55 TFSI QUATTRO PRESTIGE 3.0",
    value: "A8 L55 TFSI QUATTRO PRESTIGE 3.0"
  },
  {
    SERIAL: "21BF0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 45 QUATTRO S LINE BLACK EDITION TFSI 3.0",
    label: "Q7 45 QUATTRO S LINE BLACK EDITION TFSI 3.0",
    value: "Q7 45 QUATTRO S LINE BLACK EDITION TFSI 3.0"
  },
  {
    SERIAL: "21BG0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q8 55 QUATTRO S LINE 3.0 TFSI",
    label: "Q8 55 QUATTRO S LINE 3.0 TFSI",
    value: "Q8 55 QUATTRO S LINE 3.0 TFSI"
  },
  {
    SERIAL: "21BH0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 55 TFSI QUATTRO S LINE AVANT 3.0",
    label: "A6 55 TFSI QUATTRO S LINE AVANT 3.0",
    value: "A6 55 TFSI QUATTRO S LINE AVANT 3.0"
  },
  {
    SERIAL: "21BI0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q8 S LINE 50 3.0 TDI QUATTRO (GREY)",
    label: "Q8 S LINE 50 3.0 TDI QUATTRO (GREY)",
    value: "Q8 S LINE 50 3.0 TDI QUATTRO (GREY)"
  },
  {
    SERIAL: "21BJ0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "E-TRON 55 QUATTRO (ELECTRIC VEHICLE)",
    label: "E-TRON 55 QUATTRO (ELECTRIC VEHICLE)",
    value: "E-TRON 55 QUATTRO (ELECTRIC VEHICLE)"
  },
  {
    SERIAL: "21BK0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT COUPE 45 TFSI QUATTRO S LINE 2.0",
    label: "TT COUPE 45 TFSI QUATTRO S LINE 2.0",
    value: "TT COUPE 45 TFSI QUATTRO S LINE 2.0"
  },
  {
    SERIAL: "21BL0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A7 SPORTBACK 45 QUATTRO S 2.0 TFSI 4WD",
    label: "A7 SPORTBACK 45 QUATTRO S 2.0 TFSI 4WD",
    value: "A7 SPORTBACK 45 QUATTRO S 2.0 TFSI 4WD"
  },
  {
    SERIAL: "21BM0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A8 3.0",
    label: "A8 3.0",
    value: "A8 3.0"
  },
  {
    SERIAL: "21BN0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 55 TFSI QUATTRO S LINE 3.0TI 4WD",
    label: "Q7 55 TFSI QUATTRO S LINE 3.0TI 4WD",
    value: "Q7 55 TFSI QUATTRO S LINE 3.0TI 4WD"
  },
  {
    SERIAL: "21BP0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A1 SPORTBACK 35 TFSI S LINE 1.5",
    label: "A1 SPORTBACK 35 TFSI S LINE 1.5",
    value: "A1 SPORTBACK 35 TFSI S LINE 1.5"
  },
  {
    SERIAL: "21BQ0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 45 AVANT TFSI QUATTRO SLINE 2.0 BLAC K EDITION",
    label: "A6 45 AVANT TFSI QUATTRO SLINE 2.0 BLAC K EDITION",
    value: "A6 45 AVANT TFSI QUATTRO SLINE 2.0 BLAC K EDITION"
  },
  {
    SERIAL: "21BR0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 35 TFSI 1.4",
    label: "Q3 35 TFSI 1.4",
    value: "Q3 35 TFSI 1.4"
  },
  {
    SERIAL: "21BS0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 35 TFSI S LINE 1.4",
    label: "Q3 35 TFSI S LINE 1.4",
    value: "Q3 35 TFSI S LINE 1.4"
  },
  {
    SERIAL: "21BT0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q3 35 TFSI S LINE SPORTBACK 1.4",
    label: "Q3 35 TFSI S LINE SPORTBACK 1.4",
    value: "Q3 35 TFSI S LINE SPORTBACK 1.4"
  },
  {
    SERIAL: "21BU0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q5 40 TDI QUATTRO 2.0",
    label: "Q5 40 TDI QUATTRO 2.0",
    value: "Q5 40 TDI QUATTRO 2.0"
  },
  {
    SERIAL: "21BV0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT 45 TFSI QUATTRO S LINE 2.0 CONVERTIB LE",
    label: "TT 45 TFSI QUATTRO S LINE 2.0 CONVERTIB LE",
    value: "TT 45 TFSI QUATTRO S LINE 2.0 CONVERTIB LE"
  },
  {
    SERIAL: "21BW0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 40 S LINE 2.0 TFSI",
    label: "A6 40 S LINE 2.0 TFSI",
    value: "A6 40 S LINE 2.0 TFSI"
  },
  {
    SERIAL: "21BX0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A6 40 WAGON AVANT S LINE 2.0 TFSI",
    label: "A6 40 WAGON AVANT S LINE 2.0 TFSI",
    value: "A6 40 WAGON AVANT S LINE 2.0 TFSI"
  },
  {
    SERIAL: "21BY0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 SPORTBACK 40 S LINE 2.0 TFSI",
    label: "A5 SPORTBACK 40 S LINE 2.0 TFSI",
    value: "A5 SPORTBACK 40 S LINE 2.0 TFSI"
  },
  {
    SERIAL: "21BZ0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "TT RS QUATTRO 2.5 COUPE",
    label: "TT RS QUATTRO 2.5 COUPE",
    value: "TT RS QUATTRO 2.5 COUPE"
  },
  {
    SERIAL: "21CA0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS4 AVANT QUATTRO 2.9",
    label: "RS4 AVANT QUATTRO 2.9",
    value: "RS4 AVANT QUATTRO 2.9"
  },
  {
    SERIAL: "21CB0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS Q8 QUATTRO 4.0",
    label: "RS Q8 QUATTRO 4.0",
    value: "RS Q8 QUATTRO 4.0"
  },
  {
    SERIAL: "21CC0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 40 S LINE 2.0 TFSI",
    label: "A4 40 S LINE 2.0 TFSI",
    value: "A4 40 S LINE 2.0 TFSI"
  },
  {
    SERIAL: "21CD0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A4 45 WAGON AVANT S LINE QUATTRO 2.0 BE",
    label: "A4 45 WAGON AVANT S LINE QUATTRO 2.0 BE",
    value: "A4 45 WAGON AVANT S LINE QUATTRO 2.0 BE"
  },
  {
    SERIAL: "21CE0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 45 SPORTBACK QUATTRO S LINE 2.0 TFSI B/E",
    label: "A5 45 SPORTBACK QUATTRO S LINE 2.0 TFSI B/E",
    value: "A5 45 SPORTBACK QUATTRO S LINE 2.0 TFSI B/E"
  },
  {
    SERIAL: "21CF0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 45 COUPE QUATTRO S LINE 2.0 TFSI B/E",
    label: "A5 45 COUPE QUATTRO S LINE 2.0 TFSI B/E",
    value: "A5 45 COUPE QUATTRO S LINE 2.0 TFSI B/E"
  },
  {
    SERIAL: "21CG0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "A5 40 S LINE COUPE 2.0 TFSI",
    label: "A5 40 S LINE COUPE 2.0 TFSI",
    value: "A5 40 S LINE COUPE 2.0 TFSI"
  },
  {
    SERIAL: "21CH0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "E-TRON 55 SPORTBACK QUATTRO S LINE (EV)",
    label: "E-TRON 55 SPORTBACK QUATTRO S LINE (EV)",
    value: "E-TRON 55 SPORTBACK QUATTRO S LINE (EV)"
  },
  {
    SERIAL: "21CI0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "Q7 45 QUATTRO 3.0 TDI",
    label: "Q7 45 QUATTRO 3.0 TDI",
    value: "Q7 45 QUATTRO 3.0 TDI"
  },
  {
    SERIAL: "21CJ0000026",
    MAKE_NAME: "AUDI",
    MODEL_NAME: "RS6 4.0",
    label: "RS6 4.0",
    value: "RS6 4.0"
  },
  {
    SERIAL: "22000000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "SAAB",
    label: "SAAB",
    value: "SAAB"
  },
  {
    SERIAL: "22030000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "900 S 2.0 3DR",
    label: "900 S 2.0 3DR",
    value: "900 S 2.0 3DR"
  },
  {
    SERIAL: "22040000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "900 S 2.0 5DR A",
    label: "900 S 2.0 5DR A",
    value: "900 S 2.0 5DR A"
  },
  {
    SERIAL: "22060000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "900 S 2.3 2DR CONVERTIBLE A",
    label: "900 S 2.3 2DR CONVERTIBLE A",
    value: "900 S 2.3 2DR CONVERTIBLE A"
  },
  {
    SERIAL: "22080000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CS 2.0LT A",
    label: "9000 CS 2.0LT A",
    value: "9000 CS 2.0LT A"
  },
  {
    SERIAL: "22090000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CS 2.3LT A",
    label: "9000 CS 2.3LT A",
    value: "9000 CS 2.3LT A"
  },
  {
    SERIAL: "22120000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CSI 2.3 5DR",
    label: "9000 CSI 2.3 5DR",
    value: "9000 CSI 2.3 5DR"
  },
  {
    SERIAL: "22140000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CSET 2.3 4DR",
    label: "9000 CSET 2.3 4DR",
    value: "9000 CSET 2.3 4DR"
  },
  {
    SERIAL: "22150000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CD 2.0LT A",
    label: "9000 CD 2.0LT A",
    value: "9000 CD 2.0LT A"
  },
  {
    SERIAL: "22160000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CD 2.3LT A",
    label: "9000 CD 2.3LT A",
    value: "9000 CD 2.3LT A"
  },
  {
    SERIAL: "22190000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CDET 2.3",
    label: "9000 CDET 2.3",
    value: "9000 CDET 2.3"
  },
  {
    SERIAL: "22200000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CDI 2.0",
    label: "9000 CDI 2.0",
    value: "9000 CDI 2.0"
  },
  {
    SERIAL: "22210000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9000 CDT 2.3 GRIFFIN",
    label: "9000 CDT 2.3 GRIFFIN",
    value: "9000 CDT 2.3 GRIFFIN"
  },
  {
    SERIAL: "22230000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 2.0 LPT 5DR.",
    label: "9-3 2.0 LPT 5DR.",
    value: "9-3 2.0 LPT 5DR."
  },
  {
    SERIAL: "22260000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.0 LINEAR LPT WAGON",
    label: "9-5 2.0 LINEAR LPT WAGON",
    value: "9-5 2.0 LINEAR LPT WAGON"
  },
  {
    SERIAL: "22270000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.3 LINEAR LPT SEDAN",
    label: "9-5 2.3 LINEAR LPT SEDAN",
    value: "9-5 2.3 LINEAR LPT SEDAN"
  },
  {
    SERIAL: "22280000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.0T VIGGEN SEDAN",
    label: "9-5 2.0T VIGGEN SEDAN",
    value: "9-5 2.0T VIGGEN SEDAN"
  },
  {
    SERIAL: "22290000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.0 LINEAR LPT SEDAN",
    label: "9-5 2.0 LINEAR LPT SEDAN",
    value: "9-5 2.0 LINEAR LPT SEDAN"
  },
  {
    SERIAL: "22300000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.3 T VIGGEN SUPER",
    label: "9-5 2.3 T VIGGEN SUPER",
    value: "9-5 2.3 T VIGGEN SUPER"
  },
  {
    SERIAL: "22310000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.3 T VIGGEN WAGON",
    label: "9-5 2.3 T VIGGEN WAGON",
    value: "9-5 2.3 T VIGGEN WAGON"
  },
  {
    SERIAL: "22320000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 2.0 SPORT SEDAN LPT",
    label: "9-3 2.0 SPORT SEDAN LPT",
    value: "9-3 2.0 SPORT SEDAN LPT"
  },
  {
    SERIAL: "22330000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 2.0 SPORT COMBI 5D T",
    label: "9-3 2.0 SPORT COMBI 5D T",
    value: "9-3 2.0 SPORT COMBI 5D T"
  },
  {
    SERIAL: "22340000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 2.0 LINEAR T 4DR",
    label: "9-3 2.0 LINEAR T 4DR",
    value: "9-3 2.0 LINEAR T 4DR"
  },
  {
    SERIAL: "22350000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.3 LINEAR WAGON",
    label: "9-5 2.3 LINEAR WAGON",
    value: "9-5 2.3 LINEAR WAGON"
  },
  {
    SERIAL: "22360000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 2.0 CONVERTIBLE",
    label: "9-3 2.0 CONVERTIBLE",
    value: "9-3 2.0 CONVERTIBLE"
  },
  {
    SERIAL: "22380000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 2.0 AERO T 4DR",
    label: "9-3 2.0 AERO T 4DR",
    value: "9-3 2.0 AERO T 4DR"
  },
  {
    SERIAL: "22390000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 2.0 LINEAR LPT 4DR",
    label: "9-3 2.0 LINEAR LPT 4DR",
    value: "9-3 2.0 LINEAR LPT 4DR"
  },
  {
    SERIAL: "22400000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.3 VECTOR T SEDAN",
    label: "9-5 2.3 VECTOR T SEDAN",
    value: "9-5 2.3 VECTOR T SEDAN"
  },
  {
    SERIAL: "22410000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-5 2.3 VECTOR T WAGON",
    label: "9-5 2.3 VECTOR T WAGON",
    value: "9-5 2.3 VECTOR T WAGON"
  },
  {
    SERIAL: "22420000026",
    MAKE_NAME: "SAAB",
    MODEL_NAME: "9-3 VIGGEN TS 2.0",
    label: "9-3 VIGGEN TS 2.0",
    value: "9-3 VIGGEN TS 2.0"
  },
  {
    SERIAL: "23000000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "ROVER",
    label: "ROVER",
    value: "ROVER"
  },
  {
    SERIAL: "23010000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "MINI SPRITE 1.3 A",
    label: "MINI SPRITE 1.3 A",
    value: "MINI SPRITE 1.3 A"
  },
  {
    SERIAL: "23020000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "MINI MAYFAIR 1.3 A",
    label: "MINI MAYFAIR 1.3 A",
    value: "MINI MAYFAIR 1.3 A"
  },
  {
    SERIAL: "23030000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "MINI COOPER 1.3 M",
    label: "MINI COOPER 1.3 M",
    value: "MINI COOPER 1.3 M"
  },
  {
    SERIAL: "23050000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "220 COUPE TURBO 2.0 M",
    label: "220 COUPE TURBO 2.0 M",
    value: "220 COUPE TURBO 2.0 M"
  },
  {
    SERIAL: "23060000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "623 SLi 4Dr. A",
    label: "623 SLi 4Dr. A",
    value: "623 SLi 4Dr. A"
  },
  {
    SERIAL: "23080000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "623 GSi A",
    label: "623 GSi A",
    value: "623 GSi A"
  },
  {
    SERIAL: "23190000026",
    MAKE_NAME: "ROVER",
    MODEL_NAME: "827 STERLING V6 A",
    label: "827 STERLING V6 A",
    value: "827 STERLING V6 A"
  },
  {
    SERIAL: "24000000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "LAND ROVER",
    label: "LAND ROVER",
    value: "LAND ROVER"
  },
  {
    SERIAL: "24010000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY MPi PETROL M",
    label: "DISCOVERY MPi PETROL M",
    value: "DISCOVERY MPi PETROL M"
  },
  {
    SERIAL: "24020000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY TDi DIESEL TURBO M",
    label: "DISCOVERY TDi DIESEL TURBO M",
    value: "DISCOVERY TDi DIESEL TURBO M"
  },
  {
    SERIAL: "24050000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY V8i ES PETROL A",
    label: "DISCOVERY V8i ES PETROL A",
    value: "DISCOVERY V8i ES PETROL A"
  },
  {
    SERIAL: "24070000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER HSE 4.6",
    label: "RANGE ROVER HSE 4.6",
    value: "RANGE ROVER HSE 4.6"
  },
  {
    SERIAL: "24080000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY",
    label: "DISCOVERY",
    value: "DISCOVERY"
  },
  {
    SERIAL: "24110000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 110 TDI CAB",
    label: "DEFENDER 110 TDI CAB",
    value: "DEFENDER 110 TDI CAB"
  },
  {
    SERIAL: "24130000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY MPI",
    label: "DISCOVERY MPI",
    value: "DISCOVERY MPI"
  },
  {
    SERIAL: "24140000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER S",
    label: "FREE LANDER S",
    value: "FREE LANDER S"
  },
  {
    SERIAL: "24150000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER GS 2.5",
    label: "FREE LANDER GS 2.5",
    value: "FREE LANDER GS 2.5"
  },
  {
    SERIAL: "24160000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY II V8 S",
    label: "DISCOVERY II V8 S",
    value: "DISCOVERY II V8 S"
  },
  {
    SERIAL: "24170000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY II V8 ES 4.0",
    label: "DISCOVERY II V8 ES 4.0",
    value: "DISCOVERY II V8 ES 4.0"
  },
  {
    SERIAL: "24180000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY II TD5 HSE 2.5",
    label: "DISCOVERY II TD5 HSE 2.5",
    value: "DISCOVERY II TD5 HSE 2.5"
  },
  {
    SERIAL: "24190000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY II TD5 ES 2.5",
    label: "DISCOVERY II TD5 ES 2.5",
    value: "DISCOVERY II TD5 ES 2.5"
  },
  {
    SERIAL: "24200000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER V8 HSE 4.4",
    label: "RANGE ROVER V8 HSE 4.4",
    value: "RANGE ROVER V8 HSE 4.4"
  },
  {
    SERIAL: "24210000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER TD6",
    label: "RANGE ROVER TD6",
    value: "RANGE ROVER TD6"
  },
  {
    SERIAL: "24220000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER V6 EXPERIENCE",
    label: "FREE LANDER V6 EXPERIENCE",
    value: "FREE LANDER V6 EXPERIENCE"
  },
  {
    SERIAL: "24230000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER TD4 HSE 2.0",
    label: "FREE LANDER TD4 HSE 2.0",
    value: "FREE LANDER TD4 HSE 2.0"
  },
  {
    SERIAL: "24240000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER V6 HSE 2.5",
    label: "FREE LANDER V6 HSE 2.5",
    value: "FREE LANDER V6 HSE 2.5"
  },
  {
    SERIAL: "24250000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER V6 HSE PREMIER",
    label: "FREE LANDER V6 HSE PREMIER",
    value: "FREE LANDER V6 HSE PREMIER"
  },
  {
    SERIAL: "24260000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER V6 55TH",
    label: "FREE LANDER V6 55TH",
    value: "FREE LANDER V6 55TH"
  },
  {
    SERIAL: "24270000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY TD5 EXPERIENCE 2.5",
    label: "DISCOVERY TD5 EXPERIENCE 2.5",
    value: "DISCOVERY TD5 EXPERIENCE 2.5"
  },
  {
    SERIAL: "24280000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY II TD5 ES 5ST",
    label: "DISCOVERY II TD5 ES 5ST",
    value: "DISCOVERY II TD5 ES 5ST"
  },
  {
    SERIAL: "24290000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY V8I ES 5ST",
    label: "DISCOVERY V8I ES 5ST",
    value: "DISCOVERY V8I ES 5ST"
  },
  {
    SERIAL: "24300000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY II TD5 ES",
    label: "DISCOVERY II TD5 ES",
    value: "DISCOVERY II TD5 ES"
  },
  {
    SERIAL: "24310000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VOGUE",
    label: "RANGE ROVER VOGUE",
    value: "RANGE ROVER VOGUE"
  },
  {
    SERIAL: "24320000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "PASSPORT POWER 2.5",
    label: "PASSPORT POWER 2.5",
    value: "PASSPORT POWER 2.5"
  },
  {
    SERIAL: "24330000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY III TD6 SE 2.7",
    label: "DISCOVERY III TD6 SE 2.7",
    value: "DISCOVERY III TD6 SE 2.7"
  },
  {
    SERIAL: "24340000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT V8 SUPERCHARGE 4.2",
    label: "RANGE ROVER SPORT V8 SUPERCHARGE 4.2",
    value: "RANGE ROVER SPORT V8 SUPERCHARGE 4.2"
  },
  {
    SERIAL: "24350000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT 4.4 V8 HSE PETROL",
    label: "RANGE ROVER SPORT 4.4 V8 HSE PETROL",
    value: "RANGE ROVER SPORT 4.4 V8 HSE PETROL"
  },
  {
    SERIAL: "24360000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGEROVER SPORT 2.7D",
    label: "RANGEROVER SPORT 2.7D",
    value: "RANGEROVER SPORT 2.7D"
  },
  {
    SERIAL: "24370000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY III V8 HSE 4.4",
    label: "DISCOVERY III V8 HSE 4.4",
    value: "DISCOVERY III V8 HSE 4.4"
  },
  {
    SERIAL: "24380000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY III TDV6 HSE 2.7 TDI",
    label: "DISCOVERY III TDV6 HSE 2.7 TDI",
    value: "DISCOVERY III TDV6 HSE 2.7 TDI"
  },
  {
    SERIAL: "24390000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER KV-6 ES 2.5",
    label: "FREE LANDER KV-6 ES 2.5",
    value: "FREE LANDER KV-6 ES 2.5"
  },
  {
    SERIAL: "24400000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER KV-6 HSE 2.5",
    label: "FREE LANDER KV-6 HSE 2.5",
    value: "FREE LANDER KV-6 HSE 2.5"
  },
  {
    SERIAL: "24410000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER TD4 2.0",
    label: "FREE LANDER TD4 2.0",
    value: "FREE LANDER TD4 2.0"
  },
  {
    SERIAL: "24420000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY TD V6",
    label: "DISCOVERY TD V6",
    value: "DISCOVERY TD V6"
  },
  {
    SERIAL: "24430000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER HSE 2.2 DT AUTO",
    label: "FREE LANDER HSE 2.2 DT AUTO",
    value: "FREE LANDER HSE 2.2 DT AUTO"
  },
  {
    SERIAL: "24440000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY IIII V8 HSE",
    label: "DISCOVERY IIII V8 HSE",
    value: "DISCOVERY IIII V8 HSE"
  },
  {
    SERIAL: "24450000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY IIII TURBO DIESEL V6 HSE 3.0",
    label: "DISCOVERY IIII TURBO DIESEL V6 HSE 3.0",
    value: "DISCOVERY IIII TURBO DIESEL V6 HSE 3.0"
  },
  {
    SERIAL: "24460000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 110 SE 2.4 TDI",
    label: "DEFENDER 110 SE 2.4 TDI",
    value: "DEFENDER 110 SE 2.4 TDI"
  },
  {
    SERIAL: "24470000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER V8 4.4 (GRAY)",
    label: "RANGE ROVER V8 4.4 (GRAY)",
    value: "RANGE ROVER V8 4.4 (GRAY)"
  },
  {
    SERIAL: "24480000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SC V8 5.0",
    label: "RANGE ROVER SPORT SC V8 5.0",
    value: "RANGE ROVER SPORT SC V8 5.0"
  },
  {
    SERIAL: "24490000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT TD V6 3.0 4WD",
    label: "RANGE ROVER SPORT TD V6 3.0 4WD",
    value: "RANGE ROVER SPORT TD V6 3.0 4WD"
  },
  {
    SERIAL: "24500000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER V8 SUPERCHARGE 4.2",
    label: "RANGE ROVER V8 SUPERCHARGE 4.2",
    value: "RANGE ROVER V8 SUPERCHARGE 4.2"
  },
  {
    SERIAL: "24550000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT TD V8 HSE 3.6",
    label: "RANGE ROVER SPORT TD V8 HSE 3.6",
    value: "RANGE ROVER SPORT TD V8 HSE 3.6"
  },
  {
    SERIAL: "24560000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VOGUE SE 4WD 5.0",
    label: "RANGE ROVER VOGUE SE 4WD 5.0",
    value: "RANGE ROVER VOGUE SE 4WD 5.0"
  },
  {
    SERIAL: "24570000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER V8 VOGUE SE 3.6",
    label: "RANGE ROVER V8 VOGUE SE 3.6",
    value: "RANGE ROVER V8 VOGUE SE 3.6"
  },
  {
    SERIAL: "24580000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY IIII V8 HSE 4WD 5.0",
    label: "DISCOVERY IIII V8 HSE 4WD 5.0",
    value: "DISCOVERY IIII V8 HSE 4WD 5.0"
  },
  {
    SERIAL: "24590000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SUPERCHARGE 5.0",
    label: "RANGE ROVER SPORT SUPERCHARGE 5.0",
    value: "RANGE ROVER SPORT SUPERCHARGE 5.0"
  },
  {
    SERIAL: "24600000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE 2.0",
    label: "RANGE ROVER EVOQUE 2.0",
    value: "RANGE ROVER EVOQUE 2.0"
  },
  {
    SERIAL: "24650000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER AUTOBIOGRAPHY V8 5.0",
    label: "RANGE ROVER AUTOBIOGRAPHY V8 5.0",
    value: "RANGE ROVER AUTOBIOGRAPHY V8 5.0"
  },
  {
    SERIAL: "24670000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY IIII SD V6 HSE 3.0",
    label: "DISCOVERY IIII SD V6 HSE 3.0",
    value: "DISCOVERY IIII SD V6 HSE 3.0"
  },
  {
    SERIAL: "24680000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY IIII SD V6 4WD PREMIUM PACK",
    label: "DISCOVERY IIII SD V6 4WD PREMIUM PACK",
    value: "DISCOVERY IIII SD V6 4WD PREMIUM PACK"
  },
  {
    SERIAL: "24690000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT AUTOBIOGRAPHY 3.0 D",
    label: "RANGE ROVER SPORT AUTOBIOGRAPHY 3.0 D",
    value: "RANGE ROVER SPORT AUTOBIOGRAPHY 3.0 D"
  },
  {
    SERIAL: "24700000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SD V6 SE 3.0",
    label: "RANGE ROVER SPORT SD V6 SE 3.0",
    value: "RANGE ROVER SPORT SD V6 SE 3.0"
  },
  {
    SERIAL: "24710000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT SD4 HSE LUXURY 2.2D",
    label: "DISCOVERY SPORT SD4 HSE LUXURY 2.2D",
    value: "DISCOVERY SPORT SD4 HSE LUXURY 2.2D"
  },
  {
    SERIAL: "24720000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER AB SD V6 3.0",
    label: "RANGE ROVER AB SD V6 3.0",
    value: "RANGE ROVER AB SD V6 3.0"
  },
  {
    SERIAL: "24730000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER AB SD V8 4.4 LWB",
    label: "RANGE ROVER AB SD V8 4.4 LWB",
    value: "RANGE ROVER AB SD V8 4.4 LWB"
  },
  {
    SERIAL: "24760000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SD V6 HSE 3.0 PREMIUM",
    label: "RANGE ROVER SPORT SD V6 HSE 3.0 PREMIUM",
    value: "RANGE ROVER SPORT SD V6 HSE 3.0 PREMIUM"
  },
  {
    SERIAL: "24770000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SD V6 AB 3.0",
    label: "RANGE ROVER SPORT SD V6 AB 3.0",
    value: "RANGE ROVER SPORT SD V6 AB 3.0"
  },
  {
    SERIAL: "24780000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER TD V6 4WD 3.0",
    label: "RANGE ROVER TD V6 4WD 3.0",
    value: "RANGE ROVER TD V6 4WD 3.0"
  },
  {
    SERIAL: "24790000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SD V8 VOGUE 4.4",
    label: "RANGE ROVER SD V8 VOGUE 4.4",
    value: "RANGE ROVER SD V8 VOGUE 4.4"
  },
  {
    SERIAL: "24800000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SD V8 AB 4.4",
    label: "RANGE ROVER SD V8 AB 4.4",
    value: "RANGE ROVER SD V8 AB 4.4"
  },
  {
    SERIAL: "24810000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER V8 SUPERCHARGE AB 5.0",
    label: "RANGE ROVER V8 SUPERCHARGE AB 5.0",
    value: "RANGE ROVER V8 SUPERCHARGE AB 5.0"
  },
  {
    SERIAL: "24820000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE PURE 2.2",
    label: "RANGE ROVER EVOQUE PURE 2.2",
    value: "RANGE ROVER EVOQUE PURE 2.2"
  },
  {
    SERIAL: "24830000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE PURE PLUS PACK 2.2",
    label: "RANGE ROVER EVOQUE PURE PLUS PACK 2.2",
    value: "RANGE ROVER EVOQUE PURE PLUS PACK 2.2"
  },
  {
    SERIAL: "24840000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE PURE COUPE 2.2",
    label: "RANGE ROVER EVOQUE PURE COUPE 2.2",
    value: "RANGE ROVER EVOQUE PURE COUPE 2.2"
  },
  {
    SERIAL: "24850000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE PURE COUPE PLUS PACK 2.2",
    label: "RANGE ROVER EVOQUE PURE COUPE PLUS PACK 2.2",
    value: "RANGE ROVER EVOQUE PURE COUPE PLUS PACK 2.2"
  },
  {
    SERIAL: "24860000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE PRESTIGE 2.2",
    label: "RANGE ROVER EVOQUE PRESTIGE 2.2",
    value: "RANGE ROVER EVOQUE PRESTIGE 2.2"
  },
  {
    SERIAL: "24870000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE PRESTIGE PREMIUM PAC K 2.2",
    label: "RANGE ROVER EVOQUE PRESTIGE PREMIUM PAC K 2.2",
    value: "RANGE ROVER EVOQUE PRESTIGE PREMIUM PAC K 2.2"
  },
  {
    SERIAL: "24880000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE PRESTIGE COUPE 2.2",
    label: "RANGE ROVER EVOQUE PRESTIGE COUPE 2.2",
    value: "RANGE ROVER EVOQUE PRESTIGE COUPE 2.2"
  },
  {
    SERIAL: "24900000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE DYNAMIC 2.2",
    label: "RANGE ROVER EVOQUE DYNAMIC 2.2",
    value: "RANGE ROVER EVOQUE DYNAMIC 2.2"
  },
  {
    SERIAL: "24920000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE DYNAMIC COUPE 2.2",
    label: "RANGE ROVER EVOQUE DYNAMIC COUPE 2.2",
    value: "RANGE ROVER EVOQUE DYNAMIC COUPE 2.2"
  },
  {
    SERIAL: "24940000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY IIII TDV6 HSE 3.0T",
    label: "DISCOVERY IIII TDV6 HSE 3.0T",
    value: "DISCOVERY IIII TDV6 HSE 3.0T"
  },
  {
    SERIAL: "24950000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY IIII TDV6 HSE 3.0T PREMIUM",
    label: "DISCOVERY IIII TDV6 HSE 3.0T PREMIUM",
    value: "DISCOVERY IIII TDV6 HSE 3.0T PREMIUM"
  },
  {
    SERIAL: "24960000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT V8 SC AB 5.0 DYNAMIC",
    label: "RANGE ROVER SPORT V8 SC AB 5.0 DYNAMIC",
    value: "RANGE ROVER SPORT V8 SC AB 5.0 DYNAMIC"
  },
  {
    SERIAL: "24970000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER V8 SUPERCHARGE 5.0",
    label: "RANGE ROVER V8 SUPERCHARGE 5.0",
    value: "RANGE ROVER V8 SUPERCHARGE 5.0"
  },
  {
    SERIAL: "24980000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 90 SE 2.4",
    label: "DEFENDER 90 SE 2.4",
    value: "DEFENDER 90 SE 2.4"
  },
  {
    SERIAL: "24990000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT SD4 HSE 2.2D",
    label: "DISCOVERY SPORT SD4 HSE 2.2D",
    value: "DISCOVERY SPORT SD4 HSE 2.2D"
  },
  {
    SERIAL: "24AA0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 110 ADVENTURE 2.2 TDI",
    label: "DEFENDER 110 ADVENTURE 2.2 TDI",
    value: "DEFENDER 110 ADVENTURE 2.2 TDI"
  },
  {
    SERIAL: "24AB0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE SD4 SE PLUS 2.2D",
    label: "RANGE ROVER EVOQUE SD4 SE PLUS 2.2D",
    value: "RANGE ROVER EVOQUE SD4 SE PLUS 2.2D"
  },
  {
    SERIAL: "24AC0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE HSE 2.2D LUXURY",
    label: "RANGE ROVER EVOQUE HSE 2.2D LUXURY",
    value: "RANGE ROVER EVOQUE HSE 2.2D LUXURY"
  },
  {
    SERIAL: "24AD0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 90 STATION WAGON",
    label: "DEFENDER 90 STATION WAGON",
    value: "DEFENDER 90 STATION WAGON"
  },
  {
    SERIAL: "24AE0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT 2.0L HSE",
    label: "DISCOVERY SPORT 2.0L HSE",
    value: "DISCOVERY SPORT 2.0L HSE"
  },
  {
    SERIAL: "24AF0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "FREE LANDER",
    label: "FREE LANDER",
    value: "FREE LANDER"
  },
  {
    SERIAL: "24AG0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SVR 5.0",
    label: "RANGE ROVER SPORT SVR 5.0",
    value: "RANGE ROVER SPORT SVR 5.0"
  },
  {
    SERIAL: "24AH0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOGQUE SI4 HSE CONVERTIBLE 2.0",
    label: "RANGE ROVER EVOGQUE SI4 HSE CONVERTIBLE 2.0",
    value: "RANGE ROVER EVOGQUE SI4 HSE CONVERTIBLE 2.0"
  },
  {
    SERIAL: "24AI0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 2.2",
    label: "DEFENDER 2.2",
    value: "DEFENDER 2.2"
  },
  {
    SERIAL: "24AJ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY TDV6 3.0 SE",
    label: "DISCOVERY TDV6 3.0 SE",
    value: "DISCOVERY TDV6 3.0 SE"
  },
  {
    SERIAL: "24AK0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT SDV6 3.0L HSE DYNAMIC",
    label: "DISCOVERY SPORT SDV6 3.0L HSE DYNAMIC",
    value: "DISCOVERY SPORT SDV6 3.0L HSE DYNAMIC"
  },
  {
    SERIAL: "24AL0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SDV6 3.0L HSE DYNAMIC",
    label: "RANGE ROVER SPORT SDV6 3.0L HSE DYNAMIC",
    value: "RANGE ROVER SPORT SDV6 3.0L HSE DYNAMIC"
  },
  {
    SERIAL: "24AM0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VELAR 2.0L INGENIUM S",
    label: "RANGE ROVER VELAR 2.0L INGENIUM S",
    value: "RANGE ROVER VELAR 2.0L INGENIUM S"
  },
  {
    SERIAL: "24AN0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VELAR 2.0L INGENIUM S R-DYN AMIC",
    label: "RANGE ROVER VELAR 2.0L INGENIUM S R-DYN AMIC",
    value: "RANGE ROVER VELAR 2.0L INGENIUM S R-DYN AMIC"
  },
  {
    SERIAL: "24AO0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VELAR 2.0L INGENIUM HSE",
    label: "RANGE ROVER VELAR 2.0L INGENIUM HSE",
    value: "RANGE ROVER VELAR 2.0L INGENIUM HSE"
  },
  {
    SERIAL: "24AP0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE TD4 2.0 INGENIUM SE PLUS",
    label: "RANGE ROVER EVOQUE TD4 2.0 INGENIUM SE PLUS",
    value: "RANGE ROVER EVOQUE TD4 2.0 INGENIUM SE PLUS"
  },
  {
    SERIAL: "24AQ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE TD4 2.0 INGENIUM HSE",
    label: "RANGE ROVER EVOQUE TD4 2.0 INGENIUM HSE",
    value: "RANGE ROVER EVOQUE TD4 2.0 INGENIUM HSE"
  },
  {
    SERIAL: "24AR0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY TD6 3.0 HSE",
    label: "DISCOVERY TD6 3.0 HSE",
    value: "DISCOVERY TD6 3.0 HSE"
  },
  {
    SERIAL: "24AS0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER",
    label: "DEFENDER",
    value: "DEFENDER"
  },
  {
    SERIAL: "24AT0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SD V8 AB 4.4",
    label: "RANGE ROVER SD V8 AB 4.4",
    value: "RANGE ROVER SD V8 AB 4.4"
  },
  {
    SERIAL: "24AU0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT 2.0 HSE DYNAMIC",
    label: "RANGE ROVER SPORT 2.0 HSE DYNAMIC",
    value: "RANGE ROVER SPORT 2.0 HSE DYNAMIC"
  },
  {
    SERIAL: "24AV0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT 2.0 INGENIUM TD4 HSE HY BRID",
    label: "DISCOVERY SPORT 2.0 INGENIUM TD4 HSE HY BRID",
    value: "DISCOVERY SPORT 2.0 INGENIUM TD4 HSE HY BRID"
  },
  {
    SERIAL: "24AW0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VOGUE 2.0 HYBRID SWB",
    label: "RANGE ROVER VOGUE 2.0 HYBRID SWB",
    value: "RANGE ROVER VOGUE 2.0 HYBRID SWB"
  },
  {
    SERIAL: "24AX0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER 2.0 HYBRID AUTOBIOGRAPHY",
    label: "RANGE ROVER 2.0 HYBRID AUTOBIOGRAPHY",
    value: "RANGE ROVER 2.0 HYBRID AUTOBIOGRAPHY"
  },
  {
    SERIAL: "24AY0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VOGUE SWB 3.0D",
    label: "RANGE ROVER VOGUE SWB 3.0D",
    value: "RANGE ROVER VOGUE SWB 3.0D"
  },
  {
    SERIAL: "24AZ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER AUTOBIOGRAPHY LWB 4.4D",
    label: "RANGE ROVER AUTOBIOGRAPHY LWB 4.4D",
    value: "RANGE ROVER AUTOBIOGRAPHY LWB 4.4D"
  },
  {
    SERIAL: "24BA0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT HYBRID 2.0 HSE",
    label: "RANGE ROVER SPORT HYBRID 2.0 HSE",
    value: "RANGE ROVER SPORT HYBRID 2.0 HSE"
  },
  {
    SERIAL: "24BB0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT DYNAMIC 3.0D HSE",
    label: "RANGE ROVER SPORT DYNAMIC 3.0D HSE",
    value: "RANGE ROVER SPORT DYNAMIC 3.0D HSE"
  },
  {
    SERIAL: "24BC0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VELAR INGENIUM BASE 2.0D",
    label: "RANGE ROVER VELAR INGENIUM BASE 2.0D",
    value: "RANGE ROVER VELAR INGENIUM BASE 2.0D"
  },
  {
    SERIAL: "24BD0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VELAR INGENIUM BASE 2.0D R DYNAMIC",
    label: "RANGE ROVER VELAR INGENIUM BASE 2.0D R DYNAMIC",
    value: "RANGE ROVER VELAR INGENIUM BASE 2.0D R DYNAMIC"
  },
  {
    SERIAL: "24BE0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY INGENIUM S 3.0D",
    label: "DISCOVERY INGENIUM S 3.0D",
    value: "DISCOVERY INGENIUM S 3.0D"
  },
  {
    SERIAL: "24BF0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY INGENIUM SE 3.0D",
    label: "DISCOVERY INGENIUM SE 3.0D",
    value: "DISCOVERY INGENIUM SE 3.0D"
  },
  {
    SERIAL: "24BG0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY INGENIUM HSE 3.0D",
    label: "DISCOVERY INGENIUM HSE 3.0D",
    value: "DISCOVERY INGENIUM HSE 3.0D"
  },
  {
    SERIAL: "24BH0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT INGENIUM S 2.0D",
    label: "DISCOVERY SPORT INGENIUM S 2.0D",
    value: "DISCOVERY SPORT INGENIUM S 2.0D"
  },
  {
    SERIAL: "24BI0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT HYBRID 2.0 HSE PLUS",
    label: "RANGE ROVER SPORT HYBRID 2.0 HSE PLUS",
    value: "RANGE ROVER SPORT HYBRID 2.0 HSE PLUS"
  },
  {
    SERIAL: "24BJ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE 1.5 SE HYBRID",
    label: "RANGE ROVER EVOQUE 1.5 SE HYBRID",
    value: "RANGE ROVER EVOQUE 1.5 SE HYBRID"
  },
  {
    SERIAL: "24BK0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE 1.5 SE R-DYNAMIC+ HV",
    label: "RANGE ROVER EVOQUE 1.5 SE R-DYNAMIC+ HV",
    value: "RANGE ROVER EVOQUE 1.5 SE R-DYNAMIC+ HV"
  },
  {
    SERIAL: "24BL0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE INGENIUM 2.0 SE",
    label: "RANGE ROVER EVOQUE INGENIUM 2.0 SE",
    value: "RANGE ROVER EVOQUE INGENIUM 2.0 SE"
  },
  {
    SERIAL: "24BM0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER EVOQUE INGENIUM 2.0 SE R-DY NAMIC",
    label: "RANGE ROVER EVOQUE INGENIUM 2.0 SE R-DY NAMIC",
    value: "RANGE ROVER EVOQUE INGENIUM 2.0 SE R-DY NAMIC"
  },
  {
    SERIAL: "24BN0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT WAGON HYBRID 1.5 SE",
    label: "DISCOVERY SPORT WAGON HYBRID 1.5 SE",
    value: "DISCOVERY SPORT WAGON HYBRID 1.5 SE"
  },
  {
    SERIAL: "24BO0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT R-DYNAMIC HYBRID 1.5 SE PLUS",
    label: "DISCOVERY SPORT R-DYNAMIC HYBRID 1.5 SE PLUS",
    value: "DISCOVERY SPORT R-DYNAMIC HYBRID 1.5 SE PLUS"
  },
  {
    SERIAL: "24BP0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY WAGON 2.0 SE",
    label: "DISCOVERY WAGON 2.0 SE",
    value: "DISCOVERY WAGON 2.0 SE"
  },
  {
    SERIAL: "24BQ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT R-DYNAMIC 2.0 SE",
    label: "DISCOVERY SPORT R-DYNAMIC 2.0 SE",
    value: "DISCOVERY SPORT R-DYNAMIC 2.0 SE"
  },
  {
    SERIAL: "24BR0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 90 D200 2.0 S",
    label: "DEFENDER WAGON 90 D200 2.0 S",
    value: "DEFENDER WAGON 90 D200 2.0 S"
  },
  {
    SERIAL: "24BS0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 90 D240 2.0 SE",
    label: "DEFENDER WAGON 90 D240 2.0 SE",
    value: "DEFENDER WAGON 90 D240 2.0 SE"
  },
  {
    SERIAL: "24BT0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 90 P300 2.0 SE",
    label: "DEFENDER WAGON 90 P300 2.0 SE",
    value: "DEFENDER WAGON 90 P300 2.0 SE"
  },
  {
    SERIAL: "24BU0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 90 P400 3.0 SE",
    label: "DEFENDER WAGON 90 P400 3.0 SE",
    value: "DEFENDER WAGON 90 P400 3.0 SE"
  },
  {
    SERIAL: "24BV0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 90 P400 3.0 X",
    label: "DEFENDER WAGON 90 P400 3.0 X",
    value: "DEFENDER WAGON 90 P400 3.0 X"
  },
  {
    SERIAL: "24BW0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 90 P400 3.0 X",
    label: "DEFENDER WAGON 90 P400 3.0 X",
    value: "DEFENDER WAGON 90 P400 3.0 X"
  },
  {
    SERIAL: "24BX0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 110 P200 2.0 S",
    label: "DEFENDER WAGON 110 P200 2.0 S",
    value: "DEFENDER WAGON 110 P200 2.0 S"
  },
  {
    SERIAL: "24BY0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 110 D240 2.0 SE",
    label: "DEFENDER WAGON 110 D240 2.0 SE",
    value: "DEFENDER WAGON 110 D240 2.0 SE"
  },
  {
    SERIAL: "24BZ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 110 P300 2.0 SE",
    label: "DEFENDER WAGON 110 P300 2.0 SE",
    value: "DEFENDER WAGON 110 P300 2.0 SE"
  },
  {
    SERIAL: "24CA0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 110 P400 3.0 SE",
    label: "DEFENDER WAGON 110 P400 3.0 SE",
    value: "DEFENDER WAGON 110 P400 3.0 SE"
  },
  {
    SERIAL: "24CB0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER WAGON 110 P400 3.0 X",
    label: "DEFENDER WAGON 110 P400 3.0 X",
    value: "DEFENDER WAGON 110 P400 3.0 X"
  },
  {
    SERIAL: "24CC0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 3D INGENIUM 240PS 2.0D SE",
    label: "DEFENDER 3D INGENIUM 240PS 2.0D SE",
    value: "DEFENDER 3D INGENIUM 240PS 2.0D SE"
  },
  {
    SERIAL: "24CD0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 3D INGENIUM 300PS 2.0I SE",
    label: "DEFENDER 3D INGENIUM 300PS 2.0I SE",
    value: "DEFENDER 3D INGENIUM 300PS 2.0I SE"
  },
  {
    SERIAL: "24CE0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 3D INGENIUM 400PS 3.0I SE",
    label: "DEFENDER 3D INGENIUM 400PS 3.0I SE",
    value: "DEFENDER 3D INGENIUM 400PS 3.0I SE"
  },
  {
    SERIAL: "24CF0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 3D INGENIUM 400PS 3.0I X",
    label: "DEFENDER 3D INGENIUM 400PS 3.0I X",
    value: "DEFENDER 3D INGENIUM 400PS 3.0I X"
  },
  {
    SERIAL: "24CG0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 5D INGENIUM 240PS 2.0D SE",
    label: "DEFENDER 5D INGENIUM 240PS 2.0D SE",
    value: "DEFENDER 5D INGENIUM 240PS 2.0D SE"
  },
  {
    SERIAL: "24CH0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 5D INGENIUM 300PS 2.0I SE",
    label: "DEFENDER 5D INGENIUM 300PS 2.0I SE",
    value: "DEFENDER 5D INGENIUM 300PS 2.0I SE"
  },
  {
    SERIAL: "24CI0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 5D INGENIUM 400PS 3.0I SE",
    label: "DEFENDER 5D INGENIUM 400PS 3.0I SE",
    value: "DEFENDER 5D INGENIUM 400PS 3.0I SE"
  },
  {
    SERIAL: "24CJ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DEFENDER 5D INGENIUM 400PS 3.0I X",
    label: "DEFENDER 5D INGENIUM 400PS 3.0I X",
    value: "DEFENDER 5D INGENIUM 400PS 3.0I X"
  },
  {
    SERIAL: "24CK0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "DISCOVERY SPORT 2.0D SE AWD",
    label: "DISCOVERY SPORT 2.0D SE AWD",
    value: "DISCOVERY SPORT 2.0D SE AWD"
  },
  {
    SERIAL: "24CL0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VELAR INGENIUM 5.0 AB DYNAM IC SV",
    label: "RANGE ROVER VELAR INGENIUM 5.0 AB DYNAM IC SV",
    value: "RANGE ROVER VELAR INGENIUM 5.0 AB DYNAM IC SV"
  },
  {
    SERIAL: "24CM0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER SPORT SUPERCHARGE 5.0 SVR",
    label: "RANGE ROVER SPORT SUPERCHARGE 5.0 SVR",
    value: "RANGE ROVER SPORT SUPERCHARGE 5.0 SVR"
  },
  {
    SERIAL: "24CN0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER 2.0 HYBRID AUTOBIOGRAPHY+",
    label: "RANGE ROVER 2.0 HYBRID AUTOBIOGRAPHY+",
    value: "RANGE ROVER 2.0 HYBRID AUTOBIOGRAPHY+"
  },
  {
    SERIAL: "24CO0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VOGUE PLUS SWB HYBRID 2.0",
    label: "RANGE ROVER VOGUE PLUS SWB HYBRID 2.0",
    value: "RANGE ROVER VOGUE PLUS SWB HYBRID 2.0"
  },
  {
    SERIAL: "24CP0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER HYBRID AUTOBIOGRAPHY 2.0 SV",
    label: "RANGE ROVER HYBRID AUTOBIOGRAPHY 2.0 SV",
    value: "RANGE ROVER HYBRID AUTOBIOGRAPHY 2.0 SV"
  },
  {
    SERIAL: "24CQ0000026",
    MAKE_NAME: "LAND ROVER",
    MODEL_NAME: "RANGE ROVER VOGUE P400E HYBRID 2.0",
    label: "RANGE ROVER VOGUE P400E HYBRID 2.0",
    value: "RANGE ROVER VOGUE P400E HYBRID 2.0"
  },
  {
    SERIAL: "25000000026",
    MAKE_NAME: "RENAULT",
    MODEL_NAME: "RENAULT",
    label: "RENAULT",
    value: "RENAULT"
  },
  {
    SERIAL: "25020000026",
    MAKE_NAME: "RENAULT",
    MODEL_NAME: "RENAULT",
    label: "RENAULT",
    value: "RENAULT"
  },
  {
    SERIAL: "25030000026",
    MAKE_NAME: "RENAULT",
    MODEL_NAME: "< 2000 CC",
    label: "< 2000 CC",
    value: "< 2000 CC"
  },
  {
    SERIAL: "25130000026",
    MAKE_NAME: "RENAULT",
    MODEL_NAME: "RENAULT",
    label: "RENAULT",
    value: "RENAULT"
  },
  {
    SERIAL: "26000000026",
    MAKE_NAME: "HOLDEN",
    MODEL_NAME: "HOLDEN",
    label: "HOLDEN",
    value: "HOLDEN"
  },
  {
    SERIAL: "26030000026",
    MAKE_NAME: "HOLDEN",
    MODEL_NAME: "CAMIRA 2.0",
    label: "CAMIRA 2.0",
    value: "CAMIRA 2.0"
  },
  {
    SERIAL: "26100000026",
    MAKE_NAME: "HOLDEN",
    MODEL_NAME: "CALAIS",
    label: "CALAIS",
    value: "CALAIS"
  },
  {
    SERIAL: "27000000026",
    MAKE_NAME: "AC",
    MODEL_NAME: "AC",
    label: "AC",
    value: "AC"
  },
  {
    SERIAL: "27020000026",
    MAKE_NAME: "AC",
    MODEL_NAME: "ACE A",
    label: "ACE A",
    value: "ACE A"
  },
  {
    SERIAL: "27030000026",
    MAKE_NAME: "AC",
    MODEL_NAME: "COBRA A",
    label: "COBRA A",
    value: "COBRA A"
  },
  {
    SERIAL: "28000000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "ALFA",
    label: "ALFA",
    value: "ALFA"
  },
  {
    SERIAL: "28010000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "155 2.0 TWIN SPARK",
    label: "155 2.0 TWIN SPARK",
    value: "155 2.0 TWIN SPARK"
  },
  {
    SERIAL: "28160000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "156 SPORT WAGON",
    label: "156 SPORT WAGON",
    value: "156 SPORT WAGON"
  },
  {
    SERIAL: "28200000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "147 3D",
    label: "147 3D",
    value: "147 3D"
  },
  {
    SERIAL: "28210000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "147 5D",
    label: "147 5D",
    value: "147 5D"
  },
  {
    SERIAL: "28220000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "GT",
    label: "GT",
    value: "GT"
  },
  {
    SERIAL: "28230000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "BRERA 2.2",
    label: "BRERA 2.2",
    value: "BRERA 2.2"
  },
  {
    SERIAL: "28240000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "159",
    label: "159",
    value: "159"
  },
  {
    SERIAL: "28250000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "159 WAGON",
    label: "159 WAGON",
    value: "159 WAGON"
  },
  {
    SERIAL: "28260000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "156 MAC 2.0",
    label: "156 MAC 2.0",
    value: "156 MAC 2.0"
  },
  {
    SERIAL: "28270000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "SPIDER MAC 2.2",
    label: "SPIDER MAC 2.2",
    value: "SPIDER MAC 2.2"
  },
  {
    SERIAL: "28280000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "156 SPECIALE MAC II",
    label: "156 SPECIALE MAC II",
    value: "156 SPECIALE MAC II"
  },
  {
    SERIAL: "28290000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "156 PLATINO MAC 2.0",
    label: "156 PLATINO MAC 2.0",
    value: "156 PLATINO MAC 2.0"
  },
  {
    SERIAL: "28300000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "ROMEO 4C COUPE",
    label: "ROMEO 4C COUPE",
    value: "ROMEO 4C COUPE"
  },
  {
    SERIAL: "28320000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "ROMEO 4C SPIDER",
    label: "ROMEO 4C SPIDER",
    value: "ROMEO 4C SPIDER"
  },
  {
    SERIAL: "28330000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "GIULIA V6 3.0",
    label: "GIULIA V6 3.0",
    value: "GIULIA V6 3.0"
  },
  {
    SERIAL: "28470000026",
    MAKE_NAME: "ALFA",
    MODEL_NAME: "ROMEO",
    label: "ROMEO",
    value: "ROMEO"
  },
  {
    SERIAL: "29000000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "ASTON MARTIN",
    label: "ASTON MARTIN",
    value: "ASTON MARTIN"
  },
  {
    SERIAL: "29010000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB7",
    label: "DB7",
    value: "DB7"
  },
  {
    SERIAL: "29020000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB9 COUPE SMAC",
    label: "DB9 COUPE SMAC",
    value: "DB9 COUPE SMAC"
  },
  {
    SERIAL: "29030000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE V8 CONVERTIBLE 4.3",
    label: "VANTAGE V8 CONVERTIBLE 4.3",
    value: "VANTAGE V8 CONVERTIBLE 4.3"
  },
  {
    SERIAL: "29040000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANQUISH MAC 5.9I",
    label: "VANQUISH MAC 5.9I",
    value: "VANQUISH MAC 5.9I"
  },
  {
    SERIAL: "29050000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE V12 5.9",
    label: "VANTAGE V12 5.9",
    value: "VANTAGE V12 5.9"
  },
  {
    SERIAL: "29060000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DBS",
    label: "DBS",
    value: "DBS"
  },
  {
    SERIAL: "29070000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE V8 SMAC COUPE 4.3",
    label: "VANTAGE V8 SMAC COUPE 4.3",
    value: "VANTAGE V8 SMAC COUPE 4.3"
  },
  {
    SERIAL: "29080000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "RAPIDE 6.0",
    label: "RAPIDE 6.0",
    value: "RAPIDE 6.0"
  },
  {
    SERIAL: "29090000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE V8 COUPE 4.7",
    label: "VANTAGE V8 COUPE 4.7",
    value: "VANTAGE V8 COUPE 4.7"
  },
  {
    SERIAL: "29100000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB9 VOLENTE SMAC 5.9 CONVERTIBLE",
    label: "DB9 VOLENTE SMAC 5.9 CONVERTIBLE",
    value: "DB9 VOLENTE SMAC 5.9 CONVERTIBLE"
  },
  {
    SERIAL: "29110000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB9 VOLENTE SMAC CONVERTIBLE 5.9",
    label: "DB9 VOLENTE SMAC CONVERTIBLE 5.9",
    value: "DB9 VOLENTE SMAC CONVERTIBLE 5.9"
  },
  {
    SERIAL: "29120000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "RAPIDE S 5.9",
    label: "RAPIDE S 5.9",
    value: "RAPIDE S 5.9"
  },
  {
    SERIAL: "29130000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANQUISH SMAC COUPE 5.9",
    label: "VANQUISH SMAC COUPE 5.9",
    value: "VANQUISH SMAC COUPE 5.9"
  },
  {
    SERIAL: "29140000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE CONVERTIBLE S ROADSTER 4.7 V8",
    label: "VANTAGE CONVERTIBLE S ROADSTER 4.7 V8",
    value: "VANTAGE CONVERTIBLE S ROADSTER 4.7 V8"
  },
  {
    SERIAL: "29150000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE COUPE Q S SPORT V12 6.0",
    label: "VANTAGE COUPE Q S SPORT V12 6.0",
    value: "VANTAGE COUPE Q S SPORT V12 6.0"
  },
  {
    SERIAL: "29160000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE COUPE S 5.9 V12",
    label: "VANTAGE COUPE S 5.9 V12",
    value: "VANTAGE COUPE S 5.9 V12"
  },
  {
    SERIAL: "29170000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE COUPE S 4.7 V8",
    label: "VANTAGE COUPE S 4.7 V8",
    value: "VANTAGE COUPE S 4.7 V8"
  },
  {
    SERIAL: "29180000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE COUPE SPORT 4.7 V8",
    label: "VANTAGE COUPE SPORT 4.7 V8",
    value: "VANTAGE COUPE SPORT 4.7 V8"
  },
  {
    SERIAL: "29200000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB11 V12 5.2",
    label: "DB11 V12 5.2",
    value: "DB11 V12 5.2"
  },
  {
    SERIAL: "29210000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB9 5.9 COUPE",
    label: "DB9 5.9 COUPE",
    value: "DB9 5.9 COUPE"
  },
  {
    SERIAL: "29220000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANQUISH VOLANTE SMAC 5.9 CONVERTIBLE",
    label: "VANQUISH VOLANTE SMAC 5.9 CONVERTIBLE",
    value: "VANQUISH VOLANTE SMAC 5.9 CONVERTIBLE"
  },
  {
    SERIAL: "29230000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE COUPE SPORTSHIFT 4.7 V8 N430",
    label: "VANTAGE COUPE SPORTSHIFT 4.7 V8 N430",
    value: "VANTAGE COUPE SPORTSHIFT 4.7 V8 N430"
  },
  {
    SERIAL: "29240000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB11 V8 4.0",
    label: "DB11 V8 4.0",
    value: "DB11 V8 4.0"
  },
  {
    SERIAL: "29250000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DB9 CARBON EDITION 5.9 COUPE",
    label: "DB9 CARBON EDITION 5.9 COUPE",
    value: "DB9 CARBON EDITION 5.9 COUPE"
  },
  {
    SERIAL: "29260000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANQUISH CARBON EDITION 5.9 COUPE",
    label: "VANQUISH CARBON EDITION 5.9 COUPE",
    value: "VANQUISH CARBON EDITION 5.9 COUPE"
  },
  {
    SERIAL: "29270000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DBX 4.0",
    label: "DBX 4.0",
    value: "DBX 4.0"
  },
  {
    SERIAL: "29350000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "VANTAGE V8 4.0",
    label: "VANTAGE V8 4.0",
    value: "VANTAGE V8 4.0"
  },
  {
    SERIAL: "29380000026",
    MAKE_NAME: "ASTON MARTIN",
    MODEL_NAME: "DBS SUPERIEGGERA 5.2",
    label: "DBS SUPERIEGGERA 5.2",
    value: "DBS SUPERIEGGERA 5.2"
  },
  {
    SERIAL: "30000000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "BENTLEY",
    label: "BENTLEY",
    value: "BENTLEY"
  },
  {
    SERIAL: "30010000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "BROOKLANDS SWB 6.8",
    label: "BROOKLANDS SWB 6.8",
    value: "BROOKLANDS SWB 6.8"
  },
  {
    SERIAL: "30020000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "BROOKLANDS LWB 6.8",
    label: "BROOKLANDS LWB 6.8",
    value: "BROOKLANDS LWB 6.8"
  },
  {
    SERIAL: "30050000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL COUPE T 6.75",
    label: "CONTINENTAL COUPE T 6.75",
    value: "CONTINENTAL COUPE T 6.75"
  },
  {
    SERIAL: "30060000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL COUPE R 6.75",
    label: "CONTINENTAL COUPE R 6.75",
    value: "CONTINENTAL COUPE R 6.75"
  },
  {
    SERIAL: "30100000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "ARNAGE R 6.8",
    label: "ARNAGE R 6.8",
    value: "ARNAGE R 6.8"
  },
  {
    SERIAL: "30110000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "ARNAGE RL 6.8",
    label: "ARNAGE RL 6.8",
    value: "ARNAGE RL 6.8"
  },
  {
    SERIAL: "30120000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "ARNAGE T 6.8",
    label: "ARNAGE T 6.8",
    value: "ARNAGE T 6.8"
  },
  {
    SERIAL: "30130000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "AZURE CONVERTIBLE 6.8",
    label: "AZURE CONVERTIBLE 6.8",
    value: "AZURE CONVERTIBLE 6.8"
  },
  {
    SERIAL: "30140000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "TURBO LWB",
    label: "TURBO LWB",
    value: "TURBO LWB"
  },
  {
    SERIAL: "30150000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL CONVERTIBLE GTC 6.0 4WD",
    label: "CONTINENTAL CONVERTIBLE GTC 6.0 4WD",
    value: "CONTINENTAL CONVERTIBLE GTC 6.0 4WD"
  },
  {
    SERIAL: "30160000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT 6.0",
    label: "CONTINENTAL GT 6.0",
    value: "CONTINENTAL GT 6.0"
  },
  {
    SERIAL: "30170000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "FLYING SPUR 6.0",
    label: "FLYING SPUR 6.0",
    value: "FLYING SPUR 6.0"
  },
  {
    SERIAL: "30180000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT 4WD 6.0 CONVERTIBLE",
    label: "CONTINENTAL GT 4WD 6.0 CONVERTIBLE",
    value: "CONTINENTAL GT 4WD 6.0 CONVERTIBLE"
  },
  {
    SERIAL: "30190000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT 6.0 COUPE",
    label: "CONTINENTAL GT 6.0 COUPE",
    value: "CONTINENTAL GT 6.0 COUPE"
  },
  {
    SERIAL: "30200000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT SPEED 6.0",
    label: "CONTINENTAL GT SPEED 6.0",
    value: "CONTINENTAL GT SPEED 6.0"
  },
  {
    SERIAL: "30210000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL SUPER SPORT COUPE 6.0",
    label: "CONTINENTAL SUPER SPORT COUPE 6.0",
    value: "CONTINENTAL SUPER SPORT COUPE 6.0"
  },
  {
    SERIAL: "30220000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "FLYING SPUR SPEED 6.0 4WD SEDAN",
    label: "FLYING SPUR SPEED 6.0 4WD SEDAN",
    value: "FLYING SPUR SPEED 6.0 4WD SEDAN"
  },
  {
    SERIAL: "30240000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GTC SPEED 4WD 6.0 CONVERTIB LE",
    label: "CONTINENTAL GTC SPEED 4WD 6.0 CONVERTIB LE",
    value: "CONTINENTAL GTC SPEED 4WD 6.0 CONVERTIB LE"
  },
  {
    SERIAL: "30250000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT SPEED 6.0 COUPE",
    label: "CONTINENTAL GT SPEED 6.0 COUPE",
    value: "CONTINENTAL GT SPEED 6.0 COUPE"
  },
  {
    SERIAL: "30260000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "MULSANNE 6.8",
    label: "MULSANNE 6.8",
    value: "MULSANNE 6.8"
  },
  {
    SERIAL: "30270000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT V8 4.0 COUPE",
    label: "CONTINENTAL GT V8 4.0 COUPE",
    value: "CONTINENTAL GT V8 4.0 COUPE"
  },
  {
    SERIAL: "30280000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL SUPER SPORT CONVERTIBLE 6.0",
    label: "CONTINENTAL SUPER SPORT CONVERTIBLE 6.0",
    value: "CONTINENTAL SUPER SPORT CONVERTIBLE 6.0"
  },
  {
    SERIAL: "30290000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT CONVERTABLE V8 S 4.0",
    label: "CONTINENTAL GT CONVERTABLE V8 S 4.0",
    value: "CONTINENTAL GT CONVERTABLE V8 S 4.0"
  },
  {
    SERIAL: "30300000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT 6.0 CONVERTIBLE W12",
    label: "CONTINENTAL GT 6.0 CONVERTIBLE W12",
    value: "CONTINENTAL GT 6.0 CONVERTIBLE W12"
  },
  {
    SERIAL: "30310000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT SPEED CONVERTIBLE 6.0",
    label: "CONTINENTAL GT SPEED CONVERTIBLE 6.0",
    value: "CONTINENTAL GT SPEED CONVERTIBLE 6.0"
  },
  {
    SERIAL: "30320000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT V8 S 4.0 CONVERTIBLE",
    label: "CONTINENTAL GT V8 S 4.0 CONVERTIBLE",
    value: "CONTINENTAL GT V8 S 4.0 CONVERTIBLE"
  },
  {
    SERIAL: "30330000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT V8 S 4.0 COUPE",
    label: "CONTINENTAL GT V8 S 4.0 COUPE",
    value: "CONTINENTAL GT V8 S 4.0 COUPE"
  },
  {
    SERIAL: "30340000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "FLYING SPUR V.8 4.0",
    label: "FLYING SPUR V.8 4.0",
    value: "FLYING SPUR V.8 4.0"
  },
  {
    SERIAL: "30350000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "FLYING SPUR W12 6.0",
    label: "FLYING SPUR W12 6.0",
    value: "FLYING SPUR W12 6.0"
  },
  {
    SERIAL: "30360000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "BENTAYGA 6.0",
    label: "BENTAYGA 6.0",
    value: "BENTAYGA 6.0"
  },
  {
    SERIAL: "30370000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT3-R COUPE V8 4.0",
    label: "CONTINENTAL GT3-R COUPE V8 4.0",
    value: "CONTINENTAL GT3-R COUPE V8 4.0"
  },
  {
    SERIAL: "30380000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT W12 6.0",
    label: "CONTINENTAL GT W12 6.0",
    value: "CONTINENTAL GT W12 6.0"
  },
  {
    SERIAL: "30390000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "BENTAYGA 4.0",
    label: "BENTAYGA 4.0",
    value: "BENTAYGA 4.0"
  },
  {
    SERIAL: "30400000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "FLYING SPUR 6.0",
    label: "FLYING SPUR 6.0",
    value: "FLYING SPUR 6.0"
  },
  {
    SERIAL: "30410000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "CONTINENTAL GT V8 4.0 COUPE",
    label: "CONTINENTAL GT V8 4.0 COUPE",
    value: "CONTINENTAL GT V8 4.0 COUPE"
  },
  {
    SERIAL: "30420000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "BENTAYGA W12 6.0",
    label: "BENTAYGA W12 6.0",
    value: "BENTAYGA W12 6.0"
  },
  {
    SERIAL: "30480000026",
    MAKE_NAME: "BENTLEY",
    MODEL_NAME: "BENTAYGA HYBRID 3.0",
    label: "BENTAYGA HYBRID 3.0",
    value: "BENTAYGA HYBRID 3.0"
  },
  {
    SERIAL: "31000000026",
    MAKE_NAME: "CADILLAC",
    MODEL_NAME: "CADILLAC",
    label: "CADILLAC",
    value: "CADILLAC"
  },
  {
    SERIAL: "31010000026",
    MAKE_NAME: "CADILLAC",
    MODEL_NAME: "LIMOSINE",
    label: "LIMOSINE",
    value: "LIMOSINE"
  },
  {
    SERIAL: "31020000026",
    MAKE_NAME: "CADILLAC",
    MODEL_NAME: "S.T.S.",
    label: "S.T.S.",
    value: "S.T.S."
  },
  {
    SERIAL: "31030000026",
    MAKE_NAME: "CADILLAC",
    MODEL_NAME: "DEVILL",
    label: "DEVILL",
    value: "DEVILL"
  },
  {
    SERIAL: "31040000026",
    MAKE_NAME: "CADILLAC",
    MODEL_NAME: "FLEETWOOD",
    label: "FLEETWOOD",
    value: "FLEETWOOD"
  },
  {
    SERIAL: "31050000026",
    MAKE_NAME: "CADILLAC",
    MODEL_NAME: "ELDORODO",
    label: "ELDORODO",
    value: "ELDORODO"
  },
  {
    SERIAL: "31060000026",
    MAKE_NAME: "CADILLAC",
    MODEL_NAME: "ESCALADE 6.2",
    label: "ESCALADE 6.2",
    value: "ESCALADE 6.2"
  },
  {
    SERIAL: "32000000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CHEVROLET",
    label: "CHEVROLET",
    value: "CHEVROLET"
  },
  {
    SERIAL: "32020000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SUBURAN",
    label: "SUBURAN",
    value: "SUBURAN"
  },
  {
    SERIAL: "32040000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SHOT ROOF",
    label: "SHOT ROOF",
    value: "SHOT ROOF"
  },
  {
    SERIAL: "32080000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "HIGHROOF",
    label: "HIGHROOF",
    value: "HIGHROOF"
  },
  {
    SERIAL: "32100000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "PRESIDENTIAL",
    label: "PRESIDENTIAL",
    value: "PRESIDENTIAL"
  },
  {
    SERIAL: "32110000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "REGULAR-CAB SPORTSIDE",
    label: "REGULAR-CAB SPORTSIDE",
    value: "REGULAR-CAB SPORTSIDE"
  },
  {
    SERIAL: "32120000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "REGULAR-CAB FLEETSIDE",
    label: "REGULAR-CAB FLEETSIDE",
    value: "REGULAR-CAB FLEETSIDE"
  },
  {
    SERIAL: "32130000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "EXTENDED-CAB 2WD",
    label: "EXTENDED-CAB 2WD",
    value: "EXTENDED-CAB 2WD"
  },
  {
    SERIAL: "32140000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "EXTENDED-CAB 4WD",
    label: "EXTENDED-CAB 4WD",
    value: "EXTENDED-CAB 4WD"
  },
  {
    SERIAL: "32150000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CHEVY ASTRO VAN",
    label: "CHEVY ASTRO VAN",
    value: "CHEVY ASTRO VAN"
  },
  {
    SERIAL: "32160000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ZAFIRA 1.8 CD",
    label: "ZAFIRA 1.8 CD",
    value: "ZAFIRA 1.8 CD"
  },
  {
    SERIAL: "32170000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ZAFIRA 2.2",
    label: "ZAFIRA 2.2",
    value: "ZAFIRA 2.2"
  },
  {
    SERIAL: "32180000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ZAFIRA 1.8 GL",
    label: "ZAFIRA 1.8 GL",
    value: "ZAFIRA 1.8 GL"
  },
  {
    SERIAL: "32190000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ZAFIRA 2.2 SPORT",
    label: "ZAFIRA 2.2 SPORT",
    value: "ZAFIRA 2.2 SPORT"
  },
  {
    SERIAL: "32200000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LS M",
    label: "OPTRA 1.6 LS M",
    value: "OPTRA 1.6 LS M"
  },
  {
    SERIAL: "32210000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LT A",
    label: "OPTRA 1.6 LT A",
    value: "OPTRA 1.6 LT A"
  },
  {
    SERIAL: "32220000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.8 A LS",
    label: "OPTRA 1.8 A LS",
    value: "OPTRA 1.8 A LS"
  },
  {
    SERIAL: "32230000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.8 A LT",
    label: "OPTRA 1.8 A LT",
    value: "OPTRA 1.8 A LT"
  },
  {
    SERIAL: "32240000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TAHOE",
    label: "TAHOE",
    value: "TAHOE"
  },
  {
    SERIAL: "32250000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "EXPRESS VAN",
    label: "EXPRESS VAN",
    value: "EXPRESS VAN"
  },
  {
    SERIAL: "32260000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "LUMINA S 3.8",
    label: "LUMINA S 3.8",
    value: "LUMINA S 3.8"
  },
  {
    SERIAL: "32270000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "LUMINA LTZ 3.8",
    label: "LUMINA LTZ 3.8",
    value: "LUMINA LTZ 3.8"
  },
  {
    SERIAL: "32290000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO SINGLE CAB 2.5 M",
    label: "COLORADO SINGLE CAB 2.5 M",
    value: "COLORADO SINGLE CAB 2.5 M"
  },
  {
    SERIAL: "32300000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO SINGLE CAB 3.0 M",
    label: "COLORADO SINGLE CAB 3.0 M",
    value: "COLORADO SINGLE CAB 3.0 M"
  },
  {
    SERIAL: "32310000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 M",
    label: "COLORADO EXTENDED CAB 2.5 M",
    value: "COLORADO EXTENDED CAB 2.5 M"
  },
  {
    SERIAL: "32320000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LS M",
    label: "COLORADO EXTENDED CAB 2.5 LS M",
    value: "COLORADO EXTENDED CAB 2.5 LS M"
  },
  {
    SERIAL: "32330000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 3.0 M LT",
    label: "COLORADO EXTENDED CAB 3.0 M LT",
    value: "COLORADO EXTENDED CAB 3.0 M LT"
  },
  {
    SERIAL: "32340000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 3.0 M LT1",
    label: "COLORADO EXTENDED CAB 3.0 M LT1",
    value: "COLORADO EXTENDED CAB 3.0 M LT1"
  },
  {
    SERIAL: "32350000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 3.0 A LT1",
    label: "COLORADO EXTENDED CAB 3.0 A LT1",
    value: "COLORADO EXTENDED CAB 3.0 A LT1"
  },
  {
    SERIAL: "32360000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CREWCAB (4DOOR) 3.0 M",
    label: "COLORADO CREWCAB (4DOOR) 3.0 M",
    value: "COLORADO CREWCAB (4DOOR) 3.0 M"
  },
  {
    SERIAL: "32370000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CREWCAB (4DOOR) 3.0 M LT1",
    label: "COLORADO CREWCAB (4DOOR) 3.0 M LT1",
    value: "COLORADO CREWCAB (4DOOR) 3.0 M LT1"
  },
  {
    SERIAL: "32380000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CREWCAB (4DOOR) 3.0 A LT1",
    label: "COLORADO CREWCAB (4DOOR) 3.0 A LT1",
    value: "COLORADO CREWCAB (4DOOR) 3.0 A LT1"
  },
  {
    SERIAL: "32390000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 3.0 M 4*4",
    label: "COLORADO EXTENDED CAB 3.0 M 4*4",
    value: "COLORADO EXTENDED CAB 3.0 M 4*4"
  },
  {
    SERIAL: "32400000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CREWCAB (4DOOR)3.0 M 4*4",
    label: "COLORADO CREWCAB (4DOOR)3.0 M 4*4",
    value: "COLORADO CREWCAB (4DOOR)3.0 M 4*4"
  },
  {
    SERIAL: "32410000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CREWCAB (4DOOR)3.0 A 4*4",
    label: "COLORADO CREWCAB (4DOOR)3.0 A 4*4",
    value: "COLORADO CREWCAB (4DOOR)3.0 A 4*4"
  },
  {
    SERIAL: "32420000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO DOUBLECAB 2.5 Z71",
    label: "COLORADO DOUBLECAB 2.5 Z71",
    value: "COLORADO DOUBLECAB 2.5 Z71"
  },
  {
    SERIAL: "32430000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CREWCAB (4DOOR) 2.5 M",
    label: "COLORADO CREWCAB (4DOOR) 2.5 M",
    value: "COLORADO CREWCAB (4DOOR) 2.5 M"
  },
  {
    SERIAL: "32440000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE (VAN) 1.6 M LS",
    label: "OPTRA ESTATE (VAN) 1.6 M LS",
    value: "OPTRA ESTATE (VAN) 1.6 M LS"
  },
  {
    SERIAL: "32450000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE (VAN) 1.6 A LS",
    label: "OPTRA ESTATE (VAN) 1.6 A LS",
    value: "OPTRA ESTATE (VAN) 1.6 A LS"
  },
  {
    SERIAL: "32460000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "EXPLORER V8",
    label: "EXPLORER V8",
    value: "EXPLORER V8"
  },
  {
    SERIAL: "32470000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.4 M",
    label: "AVEO 1.4 M",
    value: "AVEO 1.4 M"
  },
  {
    SERIAL: "32480000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.4 A",
    label: "AVEO 1.4 A",
    value: "AVEO 1.4 A"
  },
  {
    SERIAL: "32490000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.4 LS A",
    label: "AVEO 1.4 LS A",
    value: "AVEO 1.4 LS A"
  },
  {
    SERIAL: "32500000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.4 LT A",
    label: "AVEO 1.4 LT A",
    value: "AVEO 1.4 LT A"
  },
  {
    SERIAL: "32510000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LT A SPORT",
    label: "OPTRA 1.6 LT A SPORT",
    value: "OPTRA 1.6 LT A SPORT"
  },
  {
    SERIAL: "32520000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LT M SPORT",
    label: "OPTRA 1.6 LT M SPORT",
    value: "OPTRA 1.6 LT M SPORT"
  },
  {
    SERIAL: "32530000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE VAN 1.6 A LS SPORT",
    label: "OPTRA ESTATE VAN 1.6 A LS SPORT",
    value: "OPTRA ESTATE VAN 1.6 A LS SPORT"
  },
  {
    SERIAL: "32540000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.8 LT A SPORT",
    label: "OPTRA 1.8 LT A SPORT",
    value: "OPTRA 1.8 LT A SPORT"
  },
  {
    SERIAL: "32550000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.8 LS A SPORT",
    label: "OPTRA 1.8 LS A SPORT",
    value: "OPTRA 1.8 LS A SPORT"
  },
  {
    SERIAL: "32560000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LS A",
    label: "OPTRA 1.6 LS A",
    value: "OPTRA 1.6 LS A"
  },
  {
    SERIAL: "32570000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 LS (4*2)",
    label: "CAPTIVA 2.4 LS (4*2)",
    value: "CAPTIVA 2.4 LS (4*2)"
  },
  {
    SERIAL: "32580000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 LT (4*4)",
    label: "CAPTIVA 2.4 LT (4*4)",
    value: "CAPTIVA 2.4 LT (4*4)"
  },
  {
    SERIAL: "32590000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "EXPRESS",
    label: "EXPRESS",
    value: "EXPRESS"
  },
  {
    SERIAL: "32600000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.0 LT 4*4 DIESEL",
    label: "CAPTIVA 2.0 LT 4*4 DIESEL",
    value: "CAPTIVA 2.0 LT 4*4 DIESEL"
  },
  {
    SERIAL: "32610000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.0 LS DIESEL",
    label: "CAPTIVA 2.0 LS DIESEL",
    value: "CAPTIVA 2.0 LS DIESEL"
  },
  {
    SERIAL: "32620000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ALLROADER 4*2 2.5 M",
    label: "ALLROADER 4*2 2.5 M",
    value: "ALLROADER 4*2 2.5 M"
  },
  {
    SERIAL: "32630000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ALLROADER 4*2 3.0 A",
    label: "ALLROADER 4*2 3.0 A",
    value: "ALLROADER 4*2 3.0 A"
  },
  {
    SERIAL: "32640000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ALLROADER Z71 4*4 3.0 A",
    label: "ALLROADER Z71 4*4 3.0 A",
    value: "ALLROADER Z71 4*4 3.0 A"
  },
  {
    SERIAL: "32650000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LS A NGV",
    label: "OPTRA 1.6 LS A NGV",
    value: "OPTRA 1.6 LS A NGV"
  },
  {
    SERIAL: "32660000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LT M",
    label: "OPTRA 1.6 LT M",
    value: "OPTRA 1.6 LT M"
  },
  {
    SERIAL: "32670000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE 1.6 A LT NGV",
    label: "OPTRA ESTATE 1.6 A LT NGV",
    value: "OPTRA ESTATE 1.6 A LT NGV"
  },
  {
    SERIAL: "32680000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE LUXURY 1.6 A LT",
    label: "OPTRA ESTATE LUXURY 1.6 A LT",
    value: "OPTRA ESTATE LUXURY 1.6 A LT"
  },
  {
    SERIAL: "32690000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA LUXURY 1.6 A LT",
    label: "OPTRA LUXURY 1.6 A LT",
    value: "OPTRA LUXURY 1.6 A LT"
  },
  {
    SERIAL: "32700000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 LT A NGV",
    label: "OPTRA 1.6 LT A NGV",
    value: "OPTRA 1.6 LT A NGV"
  },
  {
    SERIAL: "32710000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO SINGLE CAB 2.5 CNG",
    label: "COLORADO SINGLE CAB 2.5 CNG",
    value: "COLORADO SINGLE CAB 2.5 CNG"
  },
  {
    SERIAL: "32720000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 LS CNG",
    label: "COLORADO X-CAB 2.5 LS CNG",
    value: "COLORADO X-CAB 2.5 LS CNG"
  },
  {
    SERIAL: "32730000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 M LS1",
    label: "COLORADO X-CAB 2.5 M LS1",
    value: "COLORADO X-CAB 2.5 M LS1"
  },
  {
    SERIAL: "32740000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 LT",
    label: "COLORADO X-CAB 2.5 LT",
    value: "COLORADO X-CAB 2.5 LT"
  },
  {
    SERIAL: "32750000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.4 LSX A",
    label: "AVEO 1.4 LSX A",
    value: "AVEO 1.4 LSX A"
  },
  {
    SERIAL: "32760000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.4 SS A",
    label: "AVEO 1.4 SS A",
    value: "AVEO 1.4 SS A"
  },
  {
    SERIAL: "32770000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.4 LUX A",
    label: "AVEO 1.4 LUX A",
    value: "AVEO 1.4 LUX A"
  },
  {
    SERIAL: "32780000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO DOUBLECAB G80 XTREME",
    label: "COLORADO DOUBLECAB G80 XTREME",
    value: "COLORADO DOUBLECAB G80 XTREME"
  },
  {
    SERIAL: "32790000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO DOUBLECAB G80 XTREME ยกสูง",
    label: "COLORADO DOUBLECAB G80 XTREME ยกสูง",
    value: "COLORADO DOUBLECAB G80 XTREME ยกสูง"
  },
  {
    SERIAL: "32800000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CAB G80 XTREME",
    label: "COLORADO CAB G80 XTREME",
    value: "COLORADO CAB G80 XTREME"
  },
  {
    SERIAL: "32810000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO CAB G80 XTREME ยกสูง",
    label: "COLORADO CAB G80 XTREME ยกสูง",
    value: "COLORADO CAB G80 XTREME ยกสูง"
  },
  {
    SERIAL: "32820000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO SINGLE CAB G80 XTREME",
    label: "COLORADO SINGLE CAB G80 XTREME",
    value: "COLORADO SINGLE CAB G80 XTREME"
  },
  {
    SERIAL: "32830000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO",
    label: "COLORADO",
    value: "COLORADO"
  },
  {
    SERIAL: "32840000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 LS1 CNG",
    label: "COLORADO X-CAB 2.5 LS1 CNG",
    value: "COLORADO X-CAB 2.5 LS1 CNG"
  },
  {
    SERIAL: "32850000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 LT CNG",
    label: "COLORADO X-CAB 2.5 LT CNG",
    value: "COLORADO X-CAB 2.5 LT CNG"
  },
  {
    SERIAL: "32860000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CORVETTE",
    label: "CORVETTE",
    value: "CORVETTE"
  },
  {
    SERIAL: "32870000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 M Z71",
    label: "COLORADO C-CAB 4D 2.5 M Z71",
    value: "COLORADO C-CAB 4D 2.5 M Z71"
  },
  {
    SERIAL: "32880000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D Z71 4*4 M MAXX 3.0",
    label: "COLORADO C-CAB 4D Z71 4*4 M MAXX 3.0",
    value: "COLORADO C-CAB 4D Z71 4*4 M MAXX 3.0"
  },
  {
    SERIAL: "32890000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D Z71 4*4 3.0 A MAXX",
    label: "COLORADO C-CAB 4D Z71 4*4 3.0 A MAXX",
    value: "COLORADO C-CAB 4D Z71 4*4 3.0 A MAXX"
  },
  {
    SERIAL: "32900000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LS M",
    label: "COLORADO C-CAB 4D 2.5 LS M",
    value: "COLORADO C-CAB 4D 2.5 LS M"
  },
  {
    SERIAL: "32910000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 3.0 LS M",
    label: "COLORADO C-CAB 4D 3.0 LS M",
    value: "COLORADO C-CAB 4D 3.0 LS M"
  },
  {
    SERIAL: "32920000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 3.0 M LT",
    label: "COLORADO C-CAB 4D 3.0 M LT",
    value: "COLORADO C-CAB 4D 3.0 M LT"
  },
  {
    SERIAL: "32930000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 3.0 M LT1",
    label: "COLORADO C-CAB 4D 3.0 M LT1",
    value: "COLORADO C-CAB 4D 3.0 M LT1"
  },
  {
    SERIAL: "32940000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 3.0 A LT1",
    label: "COLORADO C-CAB 4D 3.0 A LT1",
    value: "COLORADO C-CAB 4D 3.0 A LT1"
  },
  {
    SERIAL: "32950000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 3.0 M Z71",
    label: "COLORADO C-CAB 4D 3.0 M Z71",
    value: "COLORADO C-CAB 4D 3.0 M Z71"
  },
  {
    SERIAL: "32960000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 3.0 A Z71",
    label: "COLORADO C-CAB 4D 3.0 A Z71",
    value: "COLORADO C-CAB 4D 3.0 A Z71"
  },
  {
    SERIAL: "32980000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 4*4 3.0 M Z71",
    label: "COLORADO C-CAB 4D 4*4 3.0 M Z71",
    value: "COLORADO C-CAB 4D 4*4 3.0 M Z71"
  },
  {
    SERIAL: "32990000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 3.0 M LT",
    label: "COLORADO X-CAB 3.0 M LT",
    value: "COLORADO X-CAB 3.0 M LT"
  },
  {
    SERIAL: "32AA0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 3.0 M LT1",
    label: "COLORADO X-CAB 3.0 M LT1",
    value: "COLORADO X-CAB 3.0 M LT1"
  },
  {
    SERIAL: "32AB0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 3.0 A LT1",
    label: "COLORADO X-CAB 3.0 A LT1",
    value: "COLORADO X-CAB 3.0 A LT1"
  },
  {
    SERIAL: "32AC0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 3.0 M Z71",
    label: "COLORADO X-CAB 3.0 M Z71",
    value: "COLORADO X-CAB 3.0 M Z71"
  },
  {
    SERIAL: "32AD0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 3.0 M Z71 4WD",
    label: "COLORADO X-CAB 3.0 M Z71 4WD",
    value: "COLORADO X-CAB 3.0 M Z71 4WD"
  },
  {
    SERIAL: "32AE0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 3.0 A Z71 4WD",
    label: "COLORADO X-CAB 3.0 A Z71 4WD",
    value: "COLORADO X-CAB 3.0 A Z71 4WD"
  },
  {
    SERIAL: "32AF0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 M LS",
    label: "COLORADO X-CAB 2.5 M LS",
    value: "COLORADO X-CAB 2.5 M LS"
  },
  {
    SERIAL: "32AG0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 M Z71 ABS AIRBAG",
    label: "COLORADO X-CAB 2.5 M Z71 ABS AIRBAG",
    value: "COLORADO X-CAB 2.5 M Z71 ABS AIRBAG"
  },
  {
    SERIAL: "32AH0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 M Z71 4WD",
    label: "COLORADO X-CAB 2.5 M Z71 4WD",
    value: "COLORADO X-CAB 2.5 M Z71 4WD"
  },
  {
    SERIAL: "32AJ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO X-CAB 2.5 M Z71",
    label: "COLORADO X-CAB 2.5 M Z71",
    value: "COLORADO X-CAB 2.5 M Z71"
  },
  {
    SERIAL: "32AK0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "LUMINA LTZ 3.6",
    label: "LUMINA LTZ 3.6",
    value: "LUMINA LTZ 3.6"
  },
  {
    SERIAL: "32AL0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE 1.6 M LT SPORT NGV",
    label: "OPTRA ESTATE 1.6 M LT SPORT NGV",
    value: "OPTRA ESTATE 1.6 M LT SPORT NGV"
  },
  {
    SERIAL: "32AM0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE 1.6 M LT NGV",
    label: "OPTRA ESTATE 1.6 M LT NGV",
    value: "OPTRA ESTATE 1.6 M LT NGV"
  },
  {
    SERIAL: "32AN0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 M LS NGV",
    label: "OPTRA 1.6 M LS NGV",
    value: "OPTRA 1.6 M LS NGV"
  },
  {
    SERIAL: "32AO0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 M LT NGV",
    label: "OPTRA 1.6 M LT NGV",
    value: "OPTRA 1.6 M LT NGV"
  },
  {
    SERIAL: "32AP0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 A LT SPORT NGV",
    label: "OPTRA 1.6 A LT SPORT NGV",
    value: "OPTRA 1.6 A LT SPORT NGV"
  },
  {
    SERIAL: "32AQ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA 1.6 M LT SPORT NGV",
    label: "OPTRA 1.6 M LT SPORT NGV",
    value: "OPTRA 1.6 M LT SPORT NGV"
  },
  {
    SERIAL: "32AR0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE LT LUXURY 1.6 A NGV",
    label: "OPTRA ESTATE LT LUXURY 1.6 A NGV",
    value: "OPTRA ESTATE LT LUXURY 1.6 A NGV"
  },
  {
    SERIAL: "32AS0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE 1.6 A LT SPORT NGV",
    label: "OPTRA ESTATE 1.6 A LT SPORT NGV",
    value: "OPTRA ESTATE 1.6 A LT SPORT NGV"
  },
  {
    SERIAL: "32AT0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA LUXURY 1.6 A LT NGV",
    label: "OPTRA LUXURY 1.6 A LT NGV",
    value: "OPTRA LUXURY 1.6 A LT NGV"
  },
  {
    SERIAL: "32AU0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE 1.6 M LT",
    label: "OPTRA ESTATE 1.6 M LT",
    value: "OPTRA ESTATE 1.6 M LT"
  },
  {
    SERIAL: "32AV0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA ESTATE 1.6 A LT",
    label: "OPTRA ESTATE 1.6 A LT",
    value: "OPTRA ESTATE 1.6 A LT"
  },
  {
    SERIAL: "32AW0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.0 DIESEL LSX 4*2",
    label: "CAPTIVA 2.0 DIESEL LSX 4*2",
    value: "CAPTIVA 2.0 DIESEL LSX 4*2"
  },
  {
    SERIAL: "32AX0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.0 LTZ 4*4",
    label: "CAPTIVA 2.0 LTZ 4*4",
    value: "CAPTIVA 2.0 LTZ 4*4"
  },
  {
    SERIAL: "32AY0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.6 LT",
    label: "AVEO 1.6 LT",
    value: "AVEO 1.6 LT"
  },
  {
    SERIAL: "32AZ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.6 LUX",
    label: "AVEO 1.6 LUX",
    value: "AVEO 1.6 LUX"
  },
  {
    SERIAL: "32BA0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.6 LSX",
    label: "AVEO 1.6 LSX",
    value: "AVEO 1.6 LSX"
  },
  {
    SERIAL: "32BB0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.6 SS",
    label: "AVEO 1.6 SS",
    value: "AVEO 1.6 SS"
  },
  {
    SERIAL: "32BC0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 1.6 M LS",
    label: "CRUZE 1.6 M LS",
    value: "CRUZE 1.6 M LS"
  },
  {
    SERIAL: "32BD0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 1.6 A LS",
    label: "CRUZE 1.6 A LS",
    value: "CRUZE 1.6 A LS"
  },
  {
    SERIAL: "32BE0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 1.8 M LS",
    label: "CRUZE 1.8 M LS",
    value: "CRUZE 1.8 M LS"
  },
  {
    SERIAL: "32BF0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 1.8 A LS",
    label: "CRUZE 1.8 A LS",
    value: "CRUZE 1.8 A LS"
  },
  {
    SERIAL: "32BG0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 1.8 A LT",
    label: "CRUZE 1.8 A LT",
    value: "CRUZE 1.8 A LT"
  },
  {
    SERIAL: "32BH0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 1.8 A LTZ",
    label: "CRUZE 1.8 A LTZ",
    value: "CRUZE 1.8 A LTZ"
  },
  {
    SERIAL: "32BI0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 2.0 A LTZ",
    label: "CRUZE 2.0 A LTZ",
    value: "CRUZE 2.0 A LTZ"
  },
  {
    SERIAL: "32BJ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ALLROADER Z71 2.5 M",
    label: "ALLROADER Z71 2.5 M",
    value: "ALLROADER Z71 2.5 M"
  },
  {
    SERIAL: "32BK0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "ALLROADER 2.5 M",
    label: "ALLROADER 2.5 M",
    value: "ALLROADER 2.5 M"
  },
  {
    SERIAL: "32BL0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.6 BASE AT CNG",
    label: "AVEO 1.6 BASE AT CNG",
    value: "AVEO 1.6 BASE AT CNG"
  },
  {
    SERIAL: "32BM0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.6 LS AT CNG",
    label: "AVEO 1.6 LS AT CNG",
    value: "AVEO 1.6 LS AT CNG"
  },
  {
    SERIAL: "32BN0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO 1.6 LSX CNG",
    label: "AVEO 1.6 LSX CNG",
    value: "AVEO 1.6 LSX CNG"
  },
  {
    SERIAL: "32BO0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 LT",
    label: "CAPTIVA 2.4 LT",
    value: "CAPTIVA 2.4 LT"
  },
  {
    SERIAL: "32BP0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 LS",
    label: "CAPTIVA 2.4 LS",
    value: "CAPTIVA 2.4 LS"
  },
  {
    SERIAL: "32BQ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 LSX",
    label: "CAPTIVA 2.4 LSX",
    value: "CAPTIVA 2.4 LSX"
  },
  {
    SERIAL: "32BR0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA",
    label: "CAPTIVA",
    value: "CAPTIVA"
  },
  {
    SERIAL: "32BS0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO SINGLE CAB 2.5",
    label: "COLORADO SINGLE CAB 2.5",
    value: "COLORADO SINGLE CAB 2.5"
  },
  {
    SERIAL: "32BT0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LS",
    label: "COLORADO EXTENDED CAB 2.5 LS",
    value: "COLORADO EXTENDED CAB 2.5 LS"
  },
  {
    SERIAL: "32BU0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LT Z71",
    label: "COLORADO EXTENDED CAB 2.5 LT Z71",
    value: "COLORADO EXTENDED CAB 2.5 LT Z71"
  },
  {
    SERIAL: "32BV0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LT Z71 4WD",
    label: "COLORADO EXTENDED CAB 2.5 LT Z71 4WD",
    value: "COLORADO EXTENDED CAB 2.5 LT Z71 4WD"
  },
  {
    SERIAL: "32BX0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LT Z71 4*2",
    label: "COLORADO C-CAB 4D 2.5 LT Z71 4*2",
    value: "COLORADO C-CAB 4D 2.5 LT Z71 4*2"
  },
  {
    SERIAL: "32BY0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LT Z71 4*4",
    label: "COLORADO C-CAB 4D 2.5 LT Z71 4*4",
    value: "COLORADO C-CAB 4D 2.5 LT Z71 4*4"
  },
  {
    SERIAL: "32BZ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LT Z71 A",
    label: "COLORADO C-CAB 4D 2.8 LT Z71 A",
    value: "COLORADO C-CAB 4D 2.8 LT Z71 A"
  },
  {
    SERIAL: "32CA0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.8 LT Z71 4*4",
    label: "COLORADO EXTENDED CAB 2.8 LT Z71 4*4",
    value: "COLORADO EXTENDED CAB 2.8 LT Z71 4*4"
  },
  {
    SERIAL: "32CB0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.8 LTZ Z71 4*4",
    label: "COLORADO EXTENDED CAB 2.8 LTZ Z71 4*4",
    value: "COLORADO EXTENDED CAB 2.8 LTZ Z71 4*4"
  },
  {
    SERIAL: "32CC0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LT",
    label: "COLORADO EXTENDED CAB 2.5 LT",
    value: "COLORADO EXTENDED CAB 2.5 LT"
  },
  {
    SERIAL: "32CD0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LTZ Z71",
    label: "COLORADO C-CAB 4D 2.8 LTZ Z71",
    value: "COLORADO C-CAB 4D 2.8 LTZ Z71"
  },
  {
    SERIAL: "32CE0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LTZ Z71 4*4 A",
    label: "COLORADO C-CAB 4D 2.8 LTZ Z71 4*4 A",
    value: "COLORADO C-CAB 4D 2.8 LTZ Z71 4*4 A"
  },
  {
    SERIAL: "32CF0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LT Z71",
    label: "COLORADO C-CAB 4D 2.8 LT Z71",
    value: "COLORADO C-CAB 4D 2.8 LT Z71"
  },
  {
    SERIAL: "32CG0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LT Z71 4*4",
    label: "COLORADO C-CAB 4D 2.8 LT Z71 4*4",
    value: "COLORADO C-CAB 4D 2.8 LT Z71 4*4"
  },
  {
    SERIAL: "32CH0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.8 LT Z71",
    label: "COLORADO EXTENDED CAB 2.8 LT Z71",
    value: "COLORADO EXTENDED CAB 2.8 LT Z71"
  },
  {
    SERIAL: "32CI0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 2.5 LT",
    label: "COLORADO C-CAB 2.5 LT",
    value: "COLORADO C-CAB 2.5 LT"
  },
  {
    SERIAL: "32CJ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LTZ",
    label: "COLORADO C-CAB 4D 2.8 LTZ",
    value: "COLORADO C-CAB 4D 2.8 LTZ"
  },
  {
    SERIAL: "32CK0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LTZ Z71 A",
    label: "COLORADO C-CAB 4D 2.8 LTZ Z71 A",
    value: "COLORADO C-CAB 4D 2.8 LTZ Z71 A"
  },
  {
    SERIAL: "32CL0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.8 LTZ Z71 4*4",
    label: "COLORADO C-CAB 4D 2.8 LTZ Z71 4*4",
    value: "COLORADO C-CAB 4D 2.8 LTZ Z71 4*4"
  },
  {
    SERIAL: "32CM0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.8 LTZ Z71",
    label: "COLORADO EXTENDED CAB 2.8 LTZ Z71",
    value: "COLORADO EXTENDED CAB 2.8 LTZ Z71"
  },
  {
    SERIAL: "32CN0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.8 LT Z71 A",
    label: "COLORADO EXTENDED CAB 2.8 LT Z71 A",
    value: "COLORADO EXTENDED CAB 2.8 LT Z71 A"
  },
  {
    SERIAL: "32CO0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LTZ",
    label: "COLORADO EXTENDED CAB 2.5 LTZ",
    value: "COLORADO EXTENDED CAB 2.5 LTZ"
  },
  {
    SERIAL: "32CP0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.8 LTZ",
    label: "COLORADO EXTENDED CAB 2.8 LTZ",
    value: "COLORADO EXTENDED CAB 2.8 LTZ"
  },
  {
    SERIAL: "32CQ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.5 M LT",
    label: "TRAILBLAZER 2.5 M LT",
    value: "TRAILBLAZER 2.5 M LT"
  },
  {
    SERIAL: "32CR0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.8 2WD LT",
    label: "TRAILBLAZER 2.8 2WD LT",
    value: "TRAILBLAZER 2.8 2WD LT"
  },
  {
    SERIAL: "32CS0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.8 4WD LT",
    label: "TRAILBLAZER 2.8 4WD LT",
    value: "TRAILBLAZER 2.8 4WD LT"
  },
  {
    SERIAL: "32CT0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.8 4WD LTZ",
    label: "TRAILBLAZER 2.8 4WD LTZ",
    value: "TRAILBLAZER 2.8 4WD LTZ"
  },
  {
    SERIAL: "32CU0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.8 4WD LTZ1",
    label: "TRAILBLAZER 2.8 4WD LTZ1",
    value: "TRAILBLAZER 2.8 4WD LTZ1"
  },
  {
    SERIAL: "32CV0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 M LT 4D",
    label: "SONIC 1.4 M LT 4D",
    value: "SONIC 1.4 M LT 4D"
  },
  {
    SERIAL: "32CW0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 M LS 4D",
    label: "SONIC 1.4 M LS 4D",
    value: "SONIC 1.4 M LS 4D"
  },
  {
    SERIAL: "32CX0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 A LS 4D",
    label: "SONIC 1.4 A LS 4D",
    value: "SONIC 1.4 A LS 4D"
  },
  {
    SERIAL: "32CY0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 A LT 4D",
    label: "SONIC 1.4 A LT 4D",
    value: "SONIC 1.4 A LT 4D"
  },
  {
    SERIAL: "32CZ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 A LTZ 4D",
    label: "SONIC 1.4 A LTZ 4D",
    value: "SONIC 1.4 A LTZ 4D"
  },
  {
    SERIAL: "32DA0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 M LT 5D",
    label: "SONIC 1.4 M LT 5D",
    value: "SONIC 1.4 M LT 5D"
  },
  {
    SERIAL: "32DB0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 A LT 5D",
    label: "SONIC 1.4 A LT 5D",
    value: "SONIC 1.4 A LT 5D"
  },
  {
    SERIAL: "32DC0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC 1.4 A LTZ 5D",
    label: "SONIC 1.4 A LTZ 5D",
    value: "SONIC 1.4 A LTZ 5D"
  },
  {
    SERIAL: "32DD0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO 6.2L V8",
    label: "CAMARO 6.2L V8",
    value: "CAMARO 6.2L V8"
  },
  {
    SERIAL: "32DE0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SPIN 1.5 LTZ",
    label: "SPIN 1.5 LTZ",
    value: "SPIN 1.5 LTZ"
  },
  {
    SERIAL: "32DF0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 LTZ 4*4",
    label: "CAPTIVA 2.4 LTZ 4*4",
    value: "CAPTIVA 2.4 LTZ 4*4"
  },
  {
    SERIAL: "32DG0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 GASOLINE 4WD LTZ",
    label: "CAPTIVA 2.4 GASOLINE 4WD LTZ",
    value: "CAPTIVA 2.4 GASOLINE 4WD LTZ"
  },
  {
    SERIAL: "32DH0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC LTZ 1.6 4D",
    label: "SONIC LTZ 1.6 4D",
    value: "SONIC LTZ 1.6 4D"
  },
  {
    SERIAL: "32DI0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC LTZ 1.6 5D",
    label: "SONIC LTZ 1.6 5D",
    value: "SONIC LTZ 1.6 5D"
  },
  {
    SERIAL: "32DJ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 3.0 M Z71 4WD",
    label: "COLORADO EXTENDED CAB 3.0 M Z71 4WD",
    value: "COLORADO EXTENDED CAB 3.0 M Z71 4WD"
  },
  {
    SERIAL: "32DK0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 3.0 M Z71",
    label: "COLORADO EXTENDED CAB 3.0 M Z71",
    value: "COLORADO EXTENDED CAB 3.0 M Z71"
  },
  {
    SERIAL: "32DL0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LT",
    label: "COLORADO EXTENDED CAB 2.5 LT",
    value: "COLORADO EXTENDED CAB 2.5 LT"
  },
  {
    SERIAL: "32DM0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CRUZE 1.6 LT",
    label: "CRUZE 1.6 LT",
    value: "CRUZE 1.6 LT"
  },
  {
    SERIAL: "32DN0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.8 2WD LTZ",
    label: "TRAILBLAZER 2.8 2WD LTZ",
    value: "TRAILBLAZER 2.8 2WD LTZ"
  },
  {
    SERIAL: "32DO0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CORVETTE STINGRAY 6.2",
    label: "CORVETTE STINGRAY 6.2",
    value: "CORVETTE STINGRAY 6.2"
  },
  {
    SERIAL: "32DP0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SONIC LT 1.6 A 4D",
    label: "SONIC LT 1.6 A 4D",
    value: "SONIC LT 1.6 A 4D"
  },
  {
    SERIAL: "32DQ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO 6.2 COUPE",
    label: "CAMARO 6.2 COUPE",
    value: "CAMARO 6.2 COUPE"
  },
  {
    SERIAL: "32DR0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO 3.6",
    label: "CAMARO 3.6",
    value: "CAMARO 3.6"
  },
  {
    SERIAL: "32DS0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.0 LTZ 4WD NAVIGATOR",
    label: "CAPTIVA 2.0 LTZ 4WD NAVIGATOR",
    value: "CAPTIVA 2.0 LTZ 4WD NAVIGATOR"
  },
  {
    SERIAL: "32DT0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.4 LTZ 4WD NAVIGATOR",
    label: "CAPTIVA 2.4 LTZ 4WD NAVIGATOR",
    value: "CAPTIVA 2.4 LTZ 4WD NAVIGATOR"
  },
  {
    SERIAL: "32DU0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LS1",
    label: "COLORADO EXTENDED CAB 2.5 LS1",
    value: "COLORADO EXTENDED CAB 2.5 LS1"
  },
  {
    SERIAL: "32DV0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LT Z71 (Y.2014)",
    label: "COLORADO EXTENDED CAB 2.5 LT Z71 (Y.2014)",
    value: "COLORADO EXTENDED CAB 2.5 LT Z71 (Y.2014)"
  },
  {
    SERIAL: "32DW0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LT Z71 4WD (Y.2014)",
    label: "COLORADO EXTENDED CAB 2.5 LT Z71 4WD (Y.2014)",
    value: "COLORADO EXTENDED CAB 2.5 LT Z71 4WD (Y.2014)"
  },
  {
    SERIAL: "32DX0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LS1",
    label: "COLORADO C-CAB 4D 2.5 LS1",
    value: "COLORADO C-CAB 4D 2.5 LS1"
  },
  {
    SERIAL: "32DY0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO SINGLE CAB 2.5 LS SHORT WHEEL",
    label: "COLORADO SINGLE CAB 2.5 LS SHORT WHEEL",
    value: "COLORADO SINGLE CAB 2.5 LS SHORT WHEEL"
  },
  {
    SERIAL: "32DZ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO 3.6",
    label: "CAMARO 3.6",
    value: "CAMARO 3.6"
  },
  {
    SERIAL: "32EA0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "SPIN 1.5 LT",
    label: "SPIN 1.5 LT",
    value: "SPIN 1.5 LT"
  },
  {
    SERIAL: "32EB0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGHCOUNTRY 2.8 LTZ Z71 A",
    label: "COLORADO 4D HIGHCOUNTRY 2.8 LTZ Z71 A",
    value: "COLORADO 4D HIGHCOUNTRY 2.8 LTZ Z71 A"
  },
  {
    SERIAL: "32EC0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGHCOUNTRY 4WD 2.8 LTZ Z71 A",
    label: "COLORADO 4D HIGHCOUNTRY 4WD 2.8 LTZ Z71 A",
    value: "COLORADO 4D HIGHCOUNTRY 4WD 2.8 LTZ Z71 A"
  },
  {
    SERIAL: "32ED0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LS",
    label: "COLORADO EXTENDED CAB 2.5 LS",
    value: "COLORADO EXTENDED CAB 2.5 LS"
  },
  {
    SERIAL: "32EE0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LTZ Z71",
    label: "COLORADO EXTENDED CAB 2.5 LTZ Z71",
    value: "COLORADO EXTENDED CAB 2.5 LTZ Z71"
  },
  {
    SERIAL: "32EF0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LTZ Z71",
    label: "COLORADO C-CAB 4D 2.5 LTZ Z71",
    value: "COLORADO C-CAB 4D 2.5 LTZ Z71"
  },
  {
    SERIAL: "32EI0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGHCOUNTRY STROM 2.8LTZ Z7 1",
    label: "COLORADO 4D HIGHCOUNTRY STROM 2.8LTZ Z7 1",
    value: "COLORADO 4D HIGHCOUNTRY STROM 2.8LTZ Z7 1"
  },
  {
    SERIAL: "32EJ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGHCOUNTRY STROM 4WD 2.8LT Z Z71",
    label: "COLORADO 4D HIGHCOUNTRY STROM 4WD 2.8LT Z Z71",
    value: "COLORADO 4D HIGHCOUNTRY STROM 4WD 2.8LT Z Z71"
  },
  {
    SERIAL: "32EK0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 2.8 LT Z71 A",
    label: "COLORADO C-CAB 2.8 LT Z71 A",
    value: "COLORADO C-CAB 2.8 LT Z71 A"
  },
  {
    SERIAL: "32EL0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.5 M LT (NEW)",
    label: "TRAILBLAZER 2.5 M LT (NEW)",
    value: "TRAILBLAZER 2.5 M LT (NEW)"
  },
  {
    SERIAL: "32EM0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.5 LTZ",
    label: "TRAILBLAZER 2.5 LTZ",
    value: "TRAILBLAZER 2.5 LTZ"
  },
  {
    SERIAL: "32EN0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.5 LTZ 4WD",
    label: "TRAILBLAZER 2.5 LTZ 4WD",
    value: "TRAILBLAZER 2.5 LTZ 4WD"
  },
  {
    SERIAL: "32EO0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED LTZ Z71 2.5 A",
    label: "COLORADO EXTENDED LTZ Z71 2.5 A",
    value: "COLORADO EXTENDED LTZ Z71 2.5 A"
  },
  {
    SERIAL: "32EP0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LS",
    label: "COLORADO C-CAB 4D 2.5 LS",
    value: "COLORADO C-CAB 4D 2.5 LS"
  },
  {
    SERIAL: "32EQ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LT Z71",
    label: "COLORADO C-CAB 4D 2.5 LT Z71",
    value: "COLORADO C-CAB 4D 2.5 LT Z71"
  },
  {
    SERIAL: "32ER0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LT Z71 A",
    label: "COLORADO C-CAB 4D 2.5 LT Z71 A",
    value: "COLORADO C-CAB 4D 2.5 LT Z71 A"
  },
  {
    SERIAL: "32ES0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D 2.5 LTZ Z71 A",
    label: "COLORADO C-CAB 4D 2.5 LTZ Z71 A",
    value: "COLORADO C-CAB 4D 2.5 LTZ Z71 A"
  },
  {
    SERIAL: "32ET0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D HIGHCOUNTRY 2.5 A",
    label: "COLORADO C-CAB 4D HIGHCOUNTRY 2.5 A",
    value: "COLORADO C-CAB 4D HIGHCOUNTRY 2.5 A"
  },
  {
    SERIAL: "32EU0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 4D HIGHCOUNTRY 4WD 2.5 A",
    label: "COLORADO C-CAB 4D HIGHCOUNTRY 4WD 2.5 A",
    value: "COLORADO C-CAB 4D HIGHCOUNTRY 4WD 2.5 A"
  },
  {
    SERIAL: "32EV0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 2.0 DIESEL LTZ",
    label: "CAPTIVA 2.0 DIESEL LTZ",
    value: "CAPTIVA 2.0 DIESEL LTZ"
  },
  {
    SERIAL: "32EW0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO SS 6.2",
    label: "CAMARO SS 6.2",
    value: "CAMARO SS 6.2"
  },
  {
    SERIAL: "32EX0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CLASSIC",
    label: "CLASSIC",
    value: "CLASSIC"
  },
  {
    SERIAL: "32EY0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "WEDA",
    label: "WEDA",
    value: "WEDA"
  },
  {
    SERIAL: "32EZ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO 5.7",
    label: "CAMARO 5.7",
    value: "CAMARO 5.7"
  },
  {
    SERIAL: "32FA0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "AVEO",
    label: "AVEO",
    value: "AVEO"
  },
  {
    SERIAL: "32FB0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "OPTRA",
    label: "OPTRA",
    value: "OPTRA"
  },
  {
    SERIAL: "32FC0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGH COUNTRY STROM 2.5",
    label: "COLORADO 4D HIGH COUNTRY STROM 2.5",
    value: "COLORADO 4D HIGH COUNTRY STROM 2.5"
  },
  {
    SERIAL: "32FD0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGH COUNTRY STROM 2.5 4WD",
    label: "COLORADO 4D HIGH COUNTRY STROM 2.5 4WD",
    value: "COLORADO 4D HIGH COUNTRY STROM 2.5 4WD"
  },
  {
    SERIAL: "32FE0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO 2.0",
    label: "CAMARO 2.0",
    value: "CAMARO 2.0"
  },
  {
    SERIAL: "32FF0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGHCOUNTRY 2.5",
    label: "COLORADO 4D HIGHCOUNTRY 2.5",
    value: "COLORADO 4D HIGHCOUNTRY 2.5"
  },
  {
    SERIAL: "32FG0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO 4D HIGHCOUNTRY 2.5 4WD",
    label: "COLORADO 4D HIGHCOUNTRY 2.5 4WD",
    value: "COLORADO 4D HIGHCOUNTRY 2.5 4WD"
  },
  {
    SERIAL: "32FH0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO S-CAB 2.5 LS",
    label: "COLORADO S-CAB 2.5 LS",
    value: "COLORADO S-CAB 2.5 LS"
  },
  {
    SERIAL: "32FI0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.5 LT",
    label: "TRAILBLAZER 2.5 LT",
    value: "TRAILBLAZER 2.5 LT"
  },
  {
    SERIAL: "32FJ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER",
    label: "TRAILBLAZER",
    value: "TRAILBLAZER"
  },
  {
    SERIAL: "32FK0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "TRAILBLAZER 2.5 LTZ Z71",
    label: "TRAILBLAZER 2.5 LTZ Z71",
    value: "TRAILBLAZER 2.5 LTZ Z71"
  },
  {
    SERIAL: "32FL0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LTZ CENTENNIA L EDITION",
    label: "COLORADO EXTENDED CAB 2.5 LTZ CENTENNIA L EDITION",
    value: "COLORADO EXTENDED CAB 2.5 LTZ CENTENNIA L EDITION"
  },
  {
    SERIAL: "32FM0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 2.5 LTZ CENTENNIAL EDITI ON",
    label: "COLORADO C-CAB 2.5 LTZ CENTENNIAL EDITI ON",
    value: "COLORADO C-CAB 2.5 LTZ CENTENNIAL EDITI ON"
  },
  {
    SERIAL: "32FN0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 2.5 HIGH COUNTRY LTZ CEN TENNIALEDITION",
    label: "COLORADO C-CAB 2.5 HIGH COUNTRY LTZ CEN TENNIALEDITION",
    value: "COLORADO C-CAB 2.5 HIGH COUNTRY LTZ CEN TENNIALEDITION"
  },
  {
    SERIAL: "32FO0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB 2.5 LT RS",
    label: "COLORADO C-CAB 2.5 LT RS",
    value: "COLORADO C-CAB 2.5 LT RS"
  },
  {
    SERIAL: "32FP0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 1.5 LS 5 SEAT",
    label: "CAPTIVA 1.5 LS 5 SEAT",
    value: "CAPTIVA 1.5 LS 5 SEAT"
  },
  {
    SERIAL: "32FQ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 1.5 LS 7 SEAT",
    label: "CAPTIVA 1.5 LS 7 SEAT",
    value: "CAPTIVA 1.5 LS 7 SEAT"
  },
  {
    SERIAL: "32FR0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 1.5 LT",
    label: "CAPTIVA 1.5 LT",
    value: "CAPTIVA 1.5 LT"
  },
  {
    SERIAL: "32FS0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAPTIVA 1.5 PREMIER",
    label: "CAPTIVA 1.5 PREMIER",
    value: "CAPTIVA 1.5 PREMIER"
  },
  {
    SERIAL: "32FT0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "> 2000 CC",
    label: "> 2000 CC",
    value: "> 2000 CC"
  },
  {
    SERIAL: "32FU0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CORVETTE 3.8",
    label: "CORVETTE 3.8",
    value: "CORVETTE 3.8"
  },
  {
    SERIAL: "32FV0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "EXPRESS VAN",
    label: "EXPRESS VAN",
    value: "EXPRESS VAN"
  },
  {
    SERIAL: "32FW0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB TRAIL BOSS 2.5 M",
    label: "COLORADO C-CAB TRAIL BOSS 2.5 M",
    value: "COLORADO C-CAB TRAIL BOSS 2.5 M"
  },
  {
    SERIAL: "32FX0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO C-CAB TRAIL BOSS 2.5 A",
    label: "COLORADO C-CAB TRAIL BOSS 2.5 A",
    value: "COLORADO C-CAB TRAIL BOSS 2.5 A"
  },
  {
    SERIAL: "32FY0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LTZ Z71 A",
    label: "COLORADO EXTENDED CAB 2.5 LTZ Z71 A",
    value: "COLORADO EXTENDED CAB 2.5 LTZ Z71 A"
  },
  {
    SERIAL: "32FZ0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "COLORADO EXTENDED CAB 2.5 LT RS",
    label: "COLORADO EXTENDED CAB 2.5 LT RS",
    value: "COLORADO EXTENDED CAB 2.5 LT RS"
  },
  {
    SERIAL: "32GA0000026",
    MAKE_NAME: "CHEVROLET",
    MODEL_NAME: "CAMARO 2.0 1LT",
    label: "CAMARO 2.0 1LT",
    value: "CAMARO 2.0 1LT"
  },
  {
    SERIAL: "33000000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "CHRYSLER",
    label: "CHRYSLER",
    value: "CHRYSLER"
  },
  {
    SERIAL: "33010000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP CHEROKEE LIMITED",
    label: "JEEP CHEROKEE LIMITED",
    value: "JEEP CHEROKEE LIMITED"
  },
  {
    SERIAL: "33020000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP GRAND CHEROKEE LIMITED",
    label: "JEEP GRAND CHEROKEE LIMITED",
    value: "JEEP GRAND CHEROKEE LIMITED"
  },
  {
    SERIAL: "33030000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP CHEROKEE SPORT 2.5",
    label: "JEEP CHEROKEE SPORT 2.5",
    value: "JEEP CHEROKEE SPORT 2.5"
  },
  {
    SERIAL: "33040000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "NEON A.",
    label: "NEON A.",
    value: "NEON A."
  },
  {
    SERIAL: "33050000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "NEON (M)",
    label: "NEON (M)",
    value: "NEON (M)"
  },
  {
    SERIAL: "33070000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP GRAND CHEROKEE LAREDO",
    label: "JEEP GRAND CHEROKEE LAREDO",
    value: "JEEP GRAND CHEROKEE LAREDO"
  },
  {
    SERIAL: "33080000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "VOYAGER",
    label: "VOYAGER",
    value: "VOYAGER"
  },
  {
    SERIAL: "33090000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "GRAND VOYAGER",
    label: "GRAND VOYAGER",
    value: "GRAND VOYAGER"
  },
  {
    SERIAL: "33110000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP",
    label: "JEEP",
    value: "JEEP"
  },
  {
    SERIAL: "33120000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP WRANGLER 4.0L SAHALA",
    label: "JEEP WRANGLER 4.0L SAHALA",
    value: "JEEP WRANGLER 4.0L SAHALA"
  },
  {
    SERIAL: "33130000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "PT CRUISER 2.0",
    label: "PT CRUISER 2.0",
    value: "PT CRUISER 2.0"
  },
  {
    SERIAL: "33140000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP WRANGLER SPORT 4.0",
    label: "JEEP WRANGLER SPORT 4.0",
    value: "JEEP WRANGLER SPORT 4.0"
  },
  {
    SERIAL: "33150000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP CHEROKEE LIMITED4.0 (NEW)",
    label: "JEEP CHEROKEE LIMITED4.0 (NEW)",
    value: "JEEP CHEROKEE LIMITED4.0 (NEW)"
  },
  {
    SERIAL: "33160000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP GRAND CHEROKEE LIMITED 4.0 (NEW)",
    label: "JEEP GRAND CHEROKEE LIMITED 4.0 (NEW)",
    value: "JEEP GRAND CHEROKEE LIMITED 4.0 (NEW)"
  },
  {
    SERIAL: "33170000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP GRAND CHEROKEE LIMITED 4.7",
    label: "JEEP GRAND CHEROKEE LIMITED 4.7",
    value: "JEEP GRAND CHEROKEE LIMITED 4.7"
  },
  {
    SERIAL: "33200000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "WRANGLER",
    label: "WRANGLER",
    value: "WRANGLER"
  },
  {
    SERIAL: "33220000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "JEEP CHEROKEE 3.7 CRD (DIESEL)",
    label: "JEEP CHEROKEE 3.7 CRD (DIESEL)",
    value: "JEEP CHEROKEE 3.7 CRD (DIESEL)"
  },
  {
    SERIAL: "33240000026",
    MAKE_NAME: "CHRYSLER",
    MODEL_NAME: "300C 3.6",
    label: "300C 3.6",
    value: "300C 3.6"
  },
  {
    SERIAL: "34000000026",
    MAKE_NAME: "DAIMLER",
    MODEL_NAME: "DAIMLER",
    label: "DAIMLER",
    value: "DAIMLER"
  },
  {
    SERIAL: "34060000026",
    MAKE_NAME: "DAIMLER",
    MODEL_NAME: "OVER 2000 CC",
    label: "OVER 2000 CC",
    value: "OVER 2000 CC"
  },
  {
    SERIAL: "35000000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "FERRARI",
    label: "FERRARI",
    value: "FERRARI"
  },
  {
    SERIAL: "35040000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F355 GTS 3.5",
    label: "F355 GTS 3.5",
    value: "F355 GTS 3.5"
  },
  {
    SERIAL: "35050000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F355 SPIDER",
    label: "F355 SPIDER",
    value: "F355 SPIDER"
  },
  {
    SERIAL: "35060000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F430 SPIDER",
    label: "F430 SPIDER",
    value: "F430 SPIDER"
  },
  {
    SERIAL: "35070000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F550 MARANELLO 5.5 COUPE",
    label: "F550 MARANELLO 5.5 COUPE",
    value: "F550 MARANELLO 5.5 COUPE"
  },
  {
    SERIAL: "35140000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F612 SCAGLIETTI SMAC 5.7 COUPE",
    label: "F612 SCAGLIETTI SMAC 5.7 COUPE",
    value: "F612 SCAGLIETTI SMAC 5.7 COUPE"
  },
  {
    SERIAL: "35150000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F360 MODENA 3.6 COUPE",
    label: "F360 MODENA 3.6 COUPE",
    value: "F360 MODENA 3.6 COUPE"
  },
  {
    SERIAL: "35160000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F360 SPIDER",
    label: "F360 SPIDER",
    value: "F360 SPIDER"
  },
  {
    SERIAL: "35170000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F360 CHALLENGE STRADALE 3.6 COUPE",
    label: "F360 CHALLENGE STRADALE 3.6 COUPE",
    value: "F360 CHALLENGE STRADALE 3.6 COUPE"
  },
  {
    SERIAL: "35180000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F575 MARANELLO 5.7 COUPE",
    label: "F575 MARANELLO 5.7 COUPE",
    value: "F575 MARANELLO 5.7 COUPE"
  },
  {
    SERIAL: "35190000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F456M GT 5.5 COUPE",
    label: "F456M GT 5.5 COUPE",
    value: "F456M GT 5.5 COUPE"
  },
  {
    SERIAL: "35200000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "599 GTB FIORANO F1 6.0 COUPE",
    label: "599 GTB FIORANO F1 6.0 COUPE",
    value: "599 GTB FIORANO F1 6.0 COUPE"
  },
  {
    SERIAL: "35210000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F430 COUPE",
    label: "F430 COUPE",
    value: "F430 COUPE"
  },
  {
    SERIAL: "35220000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "308 GTB",
    label: "308 GTB",
    value: "308 GTB"
  },
  {
    SERIAL: "35230000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "512TR",
    label: "512TR",
    value: "512TR"
  },
  {
    SERIAL: "35240000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "348 TB",
    label: "348 TB",
    value: "348 TB"
  },
  {
    SERIAL: "35250000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "CALIFORNIA CONVERTIBLE SMAC 4.3",
    label: "CALIFORNIA CONVERTIBLE SMAC 4.3",
    value: "CALIFORNIA CONVERTIBLE SMAC 4.3"
  },
  {
    SERIAL: "35270000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "PORTOFINO 3.8",
    label: "PORTOFINO 3.8",
    value: "PORTOFINO 3.8"
  },
  {
    SERIAL: "35280000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F430 SPIDER",
    label: "F430 SPIDER",
    value: "F430 SPIDER"
  },
  {
    SERIAL: "35290000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F430 SCUDERIA COUPE",
    label: "F430 SCUDERIA COUPE",
    value: "F430 SCUDERIA COUPE"
  },
  {
    SERIAL: "35300000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F430 F1 COUPE",
    label: "F430 F1 COUPE",
    value: "F430 F1 COUPE"
  },
  {
    SERIAL: "35310000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "458 ITALIA 4.5 COUPE",
    label: "458 ITALIA 4.5 COUPE",
    value: "458 ITALIA 4.5 COUPE"
  },
  {
    SERIAL: "35320000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "599 GTO",
    label: "599 GTO",
    value: "599 GTO"
  },
  {
    SERIAL: "35330000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F575 MARANELLO F1 3.6 COUPE",
    label: "F575 MARANELLO F1 3.6 COUPE",
    value: "F575 MARANELLO F1 3.6 COUPE"
  },
  {
    SERIAL: "35340000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F612 SCAGLIETTI",
    label: "F612 SCAGLIETTI",
    value: "F612 SCAGLIETTI"
  },
  {
    SERIAL: "35350000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F430 F1 SCUDERIA COUPE",
    label: "F430 F1 SCUDERIA COUPE",
    value: "F430 F1 SCUDERIA COUPE"
  },
  {
    SERIAL: "35360000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F355",
    label: "F355",
    value: "F355"
  },
  {
    SERIAL: "35390000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "458 SPECIALE 4.5",
    label: "458 SPECIALE 4.5",
    value: "458 SPECIALE 4.5"
  },
  {
    SERIAL: "35400000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F12 BERLINETTA 6.3",
    label: "F12 BERLINETTA 6.3",
    value: "F12 BERLINETTA 6.3"
  },
  {
    SERIAL: "35410000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "FF SMAC COUPE 6.3",
    label: "FF SMAC COUPE 6.3",
    value: "FF SMAC COUPE 6.3"
  },
  {
    SERIAL: "35420000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "458 SPIDER 4.5",
    label: "458 SPIDER 4.5",
    value: "458 SPIDER 4.5"
  },
  {
    SERIAL: "35430000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F550 MARANELLO F1 5.7 COUPE",
    label: "F550 MARANELLO F1 5.7 COUPE",
    value: "F550 MARANELLO F1 5.7 COUPE"
  },
  {
    SERIAL: "35440000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F360 MODENA F1 3.6 COUPE",
    label: "F360 MODENA F1 3.6 COUPE",
    value: "F360 MODENA F1 3.6 COUPE"
  },
  {
    SERIAL: "35450000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "CALIFORNIA T CONVERTIBLE 3.9",
    label: "CALIFORNIA T CONVERTIBLE 3.9",
    value: "CALIFORNIA T CONVERTIBLE 3.9"
  },
  {
    SERIAL: "35460000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "488 GTB 3.9",
    label: "488 GTB 3.9",
    value: "488 GTB 3.9"
  },
  {
    SERIAL: "35470000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "488 SPIDER 3.9",
    label: "488 SPIDER 3.9",
    value: "488 SPIDER 3.9"
  },
  {
    SERIAL: "35490000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F12 TDF 6.3",
    label: "F12 TDF 6.3",
    value: "F12 TDF 6.3"
  },
  {
    SERIAL: "35500000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "GTC4LUSSO T 3.9",
    label: "GTC4LUSSO T 3.9",
    value: "GTC4LUSSO T 3.9"
  },
  {
    SERIAL: "35510000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "812 SUPERFAST 6.5",
    label: "812 SUPERFAST 6.5",
    value: "812 SUPERFAST 6.5"
  },
  {
    SERIAL: "35520000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "SF90 STADALE 4.0",
    label: "SF90 STADALE 4.0",
    value: "SF90 STADALE 4.0"
  },
  {
    SERIAL: "35530000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F12 TDF 6.5",
    label: "F12 TDF 6.5",
    value: "F12 TDF 6.5"
  },
  {
    SERIAL: "35550000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "GTC4LUSSO 6.3L V12",
    label: "GTC4LUSSO 6.3L V12",
    value: "GTC4LUSSO 6.3L V12"
  },
  {
    SERIAL: "35560000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "488 SPIDER 70TH ANNIVERSARY",
    label: "488 SPIDER 70TH ANNIVERSARY",
    value: "488 SPIDER 70TH ANNIVERSARY"
  },
  {
    SERIAL: "35570000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "488 PISTA 3.9",
    label: "488 PISTA 3.9",
    value: "488 PISTA 3.9"
  },
  {
    SERIAL: "35580000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F8 TRIBUTO 3.9",
    label: "F8 TRIBUTO 3.9",
    value: "F8 TRIBUTO 3.9"
  },
  {
    SERIAL: "35590000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "F8 SPIDER 3.9",
    label: "F8 SPIDER 3.9",
    value: "F8 SPIDER 3.9"
  },
  {
    SERIAL: "35600000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "812 GTS 6.5 CONVERTIBLE",
    label: "812 GTS 6.5 CONVERTIBLE",
    value: "812 GTS 6.5 CONVERTIBLE"
  },
  {
    SERIAL: "35610000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "ROMA 3.9",
    label: "ROMA 3.9",
    value: "ROMA 3.9"
  },
  {
    SERIAL: "35ER0000026",
    MAKE_NAME: "FERRARI",
    MODEL_NAME: "488 PISTA SPIDER 3.9",
    label: "488 PISTA SPIDER 3.9",
    value: "488 PISTA SPIDER 3.9"
  },
  {
    SERIAL: "36000000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "JAGUAR",
    label: "JAGUAR",
    value: "JAGUAR"
  },
  {
    SERIAL: "36010000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ6 3.2",
    label: "XJ6 3.2",
    value: "XJ6 3.2"
  },
  {
    SERIAL: "36030000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "SOVEREIGN 4.0",
    label: "SOVEREIGN 4.0",
    value: "SOVEREIGN 4.0"
  },
  {
    SERIAL: "36110000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XK8 COUPE 4.0",
    label: "XK8 COUPE 4.0",
    value: "XK8 COUPE 4.0"
  },
  {
    SERIAL: "36120000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJR 4.0 SUPERCHARGED",
    label: "XJR 4.0 SUPERCHARGED",
    value: "XJR 4.0 SUPERCHARGED"
  },
  {
    SERIAL: "36140000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "X-TYPE 2.5 V6",
    label: "X-TYPE 2.5 V6",
    value: "X-TYPE 2.5 V6"
  },
  {
    SERIAL: "36150000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "S-TYPE 3.0 V6",
    label: "S-TYPE 3.0 V6",
    value: "S-TYPE 3.0 V6"
  },
  {
    SERIAL: "36160000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ6 3.0",
    label: "XJ6 3.0",
    value: "XJ6 3.0"
  },
  {
    SERIAL: "36170000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "X-TYPE 2.0",
    label: "X-TYPE 2.0",
    value: "X-TYPE 2.0"
  },
  {
    SERIAL: "36180000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ6 3.0",
    label: "XJ6 3.0",
    value: "XJ6 3.0"
  },
  {
    SERIAL: "36190000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ8 3.5 LWB (Y.2006)",
    label: "XJ8 3.5 LWB (Y.2006)",
    value: "XJ8 3.5 LWB (Y.2006)"
  },
  {
    SERIAL: "36210000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ8 3.5 LWB",
    label: "XJ8 3.5 LWB",
    value: "XJ8 3.5 LWB"
  },
  {
    SERIAL: "36230000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XK8 COUPE 4.2",
    label: "XK8 COUPE 4.2",
    value: "XK8 COUPE 4.2"
  },
  {
    SERIAL: "36240000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "S-TYPE 2.5 LUXURY",
    label: "S-TYPE 2.5 LUXURY",
    value: "S-TYPE 2.5 LUXURY"
  },
  {
    SERIAL: "36250000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "S-TYPE 2.5 SPORT",
    label: "S-TYPE 2.5 SPORT",
    value: "S-TYPE 2.5 SPORT"
  },
  {
    SERIAL: "36260000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ6 3.0 A LWB 4ST",
    label: "XJ6 3.0 A LWB 4ST",
    value: "XJ6 3.0 A LWB 4ST"
  },
  {
    SERIAL: "36270000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF SA 4.2",
    label: "XF SA 4.2",
    value: "XF SA 4.2"
  },
  {
    SERIAL: "36280000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF SV8 4.2 IS",
    label: "XF SV8 4.2 IS",
    value: "XF SV8 4.2 IS"
  },
  {
    SERIAL: "36290000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF PREMIUM LUXURY 3.0I",
    label: "XF PREMIUM LUXURY 3.0I",
    value: "XF PREMIUM LUXURY 3.0I"
  },
  {
    SERIAL: "36300000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ6 3.0 A LWB 5ST",
    label: "XJ6 3.0 A LWB 5ST",
    value: "XJ6 3.0 A LWB 5ST"
  },
  {
    SERIAL: "36310000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "X-TYPE 2.0 SE",
    label: "X-TYPE 2.0 SE",
    value: "X-TYPE 2.0 SE"
  },
  {
    SERIAL: "36320000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "X-TYPE 2.5 SE",
    label: "X-TYPE 2.5 SE",
    value: "X-TYPE 2.5 SE"
  },
  {
    SERIAL: "36330000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ6 3.0 (Y.2007)",
    label: "XJ6 3.0 (Y.2007)",
    value: "XJ6 3.0 (Y.2007)"
  },
  {
    SERIAL: "36340000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XK 4.2",
    label: "XK 4.2",
    value: "XK 4.2"
  },
  {
    SERIAL: "36350000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 5.0 SI",
    label: "XF 5.0 SI",
    value: "XF 5.0 SI"
  },
  {
    SERIAL: "36360000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 3.0 V6 SPORT EDITION",
    label: "XF 3.0 V6 SPORT EDITION",
    value: "XF 3.0 V6 SPORT EDITION"
  },
  {
    SERIAL: "36370000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ 3.0 V6 DIESEL",
    label: "XJ 3.0 V6 DIESEL",
    value: "XJ 3.0 V6 DIESEL"
  },
  {
    SERIAL: "36380000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ 5.0 V8 PORTFOLIO SWB",
    label: "XJ 5.0 V8 PORTFOLIO SWB",
    value: "XJ 5.0 V8 PORTFOLIO SWB"
  },
  {
    SERIAL: "36390000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ 5.0 V8 PORTFOLIO LWB",
    label: "XJ 5.0 V8 PORTFOLIO LWB",
    value: "XJ 5.0 V8 PORTFOLIO LWB"
  },
  {
    SERIAL: "36400000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XK 5.0 V8 PORTFOLIO",
    label: "XK 5.0 V8 PORTFOLIO",
    value: "XK 5.0 V8 PORTFOLIO"
  },
  {
    SERIAL: "36410000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XK 5.0 V8 PORTFOLIO CONVERTIBLE",
    label: "XK 5.0 V8 PORTFOLIO CONVERTIBLE",
    value: "XK 5.0 V8 PORTFOLIO CONVERTIBLE"
  },
  {
    SERIAL: "36420000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XKR 5.0 S/C COUPE",
    label: "XKR 5.0 S/C COUPE",
    value: "XKR 5.0 S/C COUPE"
  },
  {
    SERIAL: "36430000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ 3.0 PORTFOLIO LWB",
    label: "XJ 3.0 PORTFOLIO LWB",
    value: "XJ 3.0 PORTFOLIO LWB"
  },
  {
    SERIAL: "36440000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 2.2 DIESEL",
    label: "XF 2.2 DIESEL",
    value: "XF 2.2 DIESEL"
  },
  {
    SERIAL: "36450000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ 4.0",
    label: "XJ 4.0",
    value: "XJ 4.0"
  },
  {
    SERIAL: "36470000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJL 3.0",
    label: "XJL 3.0",
    value: "XJL 3.0"
  },
  {
    SERIAL: "36490000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 3.0 V6 ENTRY",
    label: "XF 3.0 V6 ENTRY",
    value: "XF 3.0 V6 ENTRY"
  },
  {
    SERIAL: "36500000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ 3.0 PORTFOLIO SWB",
    label: "XJ 3.0 PORTFOLIO SWB",
    value: "XJ 3.0 PORTFOLIO SWB"
  },
  {
    SERIAL: "36510000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE SA 3.0 CONVERTIBLE",
    label: "F-TYPE SA 3.0 CONVERTIBLE",
    value: "F-TYPE SA 3.0 CONVERTIBLE"
  },
  {
    SERIAL: "36520000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE S SA 3.0 CONVERTIBLE",
    label: "F-TYPE S SA 3.0 CONVERTIBLE",
    value: "F-TYPE S SA 3.0 CONVERTIBLE"
  },
  {
    SERIAL: "36530000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE S SA V8 3.0 CONVERTIBLE",
    label: "F-TYPE S SA V8 3.0 CONVERTIBLE",
    value: "F-TYPE S SA V8 3.0 CONVERTIBLE"
  },
  {
    SERIAL: "36550000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ8 3.2",
    label: "XJ8 3.2",
    value: "XJ8 3.2"
  },
  {
    SERIAL: "36560000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE V8 S 5.0 COUPE",
    label: "F-TYPE V8 S 5.0 COUPE",
    value: "F-TYPE V8 S 5.0 COUPE"
  },
  {
    SERIAL: "36570000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 3.0 DTC PORTFOLIO",
    label: "XF 3.0 DTC PORTFOLIO",
    value: "XF 3.0 DTC PORTFOLIO"
  },
  {
    SERIAL: "36580000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XK CONVERTIBLE 5.0T",
    label: "XK CONVERTIBLE 5.0T",
    value: "XK CONVERTIBLE 5.0T"
  },
  {
    SERIAL: "36600000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJL 2.0 DIESEL",
    label: "XJL 2.0 DIESEL",
    value: "XJL 2.0 DIESEL"
  },
  {
    SERIAL: "36610000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE S 3.0 COUPE",
    label: "F-TYPE S 3.0 COUPE",
    value: "F-TYPE S 3.0 COUPE"
  },
  {
    SERIAL: "36620000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE COUPE R SUPERCHARGE 5.0",
    label: "F-TYPE COUPE R SUPERCHARGE 5.0",
    value: "F-TYPE COUPE R SUPERCHARGE 5.0"
  },
  {
    SERIAL: "36630000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ 2.0 PREMIUM LUXURY LWB",
    label: "XJ 2.0 PREMIUM LUXURY LWB",
    value: "XJ 2.0 PREMIUM LUXURY LWB"
  },
  {
    SERIAL: "36640000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 2.0 SA PORTFOLIO",
    label: "XF 2.0 SA PORTFOLIO",
    value: "XF 2.0 SA PORTFOLIO"
  },
  {
    SERIAL: "36650000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJL 3.0 DIESEL LWB",
    label: "XJL 3.0 DIESEL LWB",
    value: "XJL 3.0 DIESEL LWB"
  },
  {
    SERIAL: "36660000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XE 2.0i PORTFOLIO",
    label: "XE 2.0i PORTFOLIO",
    value: "XE 2.0i PORTFOLIO"
  },
  {
    SERIAL: "36670000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XE 2.0i R-SPORT",
    label: "XE 2.0i R-SPORT",
    value: "XE 2.0i R-SPORT"
  },
  {
    SERIAL: "36680000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE 3.0 COUPE",
    label: "F-TYPE 3.0 COUPE",
    value: "F-TYPE 3.0 COUPE"
  },
  {
    SERIAL: "36690000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE PURE 2.0",
    label: "F-PACE PURE 2.0",
    value: "F-PACE PURE 2.0"
  },
  {
    SERIAL: "36700000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE R-SPORT 2.0",
    label: "F-PACE R-SPORT 2.0",
    value: "F-PACE R-SPORT 2.0"
  },
  {
    SERIAL: "36710000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE PORTFOLIO 2.0",
    label: "F-PACE PORTFOLIO 2.0",
    value: "F-PACE PORTFOLIO 2.0"
  },
  {
    SERIAL: "36720000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF R-SPORT 2.0",
    label: "XF R-SPORT 2.0",
    value: "XF R-SPORT 2.0"
  },
  {
    SERIAL: "36730000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF PORTFOLIO 2.0",
    label: "XF PORTFOLIO 2.0",
    value: "XF PORTFOLIO 2.0"
  },
  {
    SERIAL: "36740000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ PREMIUM LUXURY LWB 2.0",
    label: "XJ PREMIUM LUXURY LWB 2.0",
    value: "XJ PREMIUM LUXURY LWB 2.0"
  },
  {
    SERIAL: "36750000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE 3.0TDI FIRST EDITION",
    label: "F-PACE 3.0TDI FIRST EDITION",
    value: "F-PACE 3.0TDI FIRST EDITION"
  },
  {
    SERIAL: "36760000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJ",
    label: "XJ",
    value: "XJ"
  },
  {
    SERIAL: "36770000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XE GTDI R-SPORT 2.0L",
    label: "XE GTDI R-SPORT 2.0L",
    value: "XE GTDI R-SPORT 2.0L"
  },
  {
    SERIAL: "36780000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF INGENIUM PRESTIGE 2.0L 250PS",
    label: "XF INGENIUM PRESTIGE 2.0L 250PS",
    value: "XF INGENIUM PRESTIGE 2.0L 250PS"
  },
  {
    SERIAL: "36790000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF INGENIUM PORTFOLIO 2.0L",
    label: "XF INGENIUM PORTFOLIO 2.0L",
    value: "XF INGENIUM PORTFOLIO 2.0L"
  },
  {
    SERIAL: "36800000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XJL PREMIUM LUXURY 3.0L",
    label: "XJL PREMIUM LUXURY 3.0L",
    value: "XJL PREMIUM LUXURY 3.0L"
  },
  {
    SERIAL: "36810000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE 3.0L V6 COUPE",
    label: "F-TYPE 3.0L V6 COUPE",
    value: "F-TYPE 3.0L V6 COUPE"
  },
  {
    SERIAL: "36820000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE 3.0L V6 R-DYNAMIC CONVERTIBLE",
    label: "F-TYPE 3.0L V6 R-DYNAMIC CONVERTIBLE",
    value: "F-TYPE 3.0L V6 R-DYNAMIC CONVERTIBLE"
  },
  {
    SERIAL: "36830000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE 3.0L V6 SPORT COUPE",
    label: "F-TYPE 3.0L V6 SPORT COUPE",
    value: "F-TYPE 3.0L V6 SPORT COUPE"
  },
  {
    SERIAL: "36840000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF PRESTIGE 2.0",
    label: "XF PRESTIGE 2.0",
    value: "XF PRESTIGE 2.0"
  },
  {
    SERIAL: "36850000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE 2.0 PERFORMANCE DESIGN",
    label: "F-TYPE 2.0 PERFORMANCE DESIGN",
    value: "F-TYPE 2.0 PERFORMANCE DESIGN"
  },
  {
    SERIAL: "36860000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "E-PACE 4WD 2.0",
    label: "E-PACE 4WD 2.0",
    value: "E-PACE 4WD 2.0"
  },
  {
    SERIAL: "36870000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "I-PACE S (EV)",
    label: "I-PACE S (EV)",
    value: "I-PACE S (EV)"
  },
  {
    SERIAL: "36880000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "I-PACE SE (EV)",
    label: "I-PACE SE (EV)",
    value: "I-PACE SE (EV)"
  },
  {
    SERIAL: "36890000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "I-PACE HSE (EV)",
    label: "I-PACE HSE (EV)",
    value: "I-PACE HSE (EV)"
  },
  {
    SERIAL: "36900000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE 2.0 CONVERTIBLE PERFORMANCE DESI GN",
    label: "F-TYPE 2.0 CONVERTIBLE PERFORMANCE DESI GN",
    value: "F-TYPE 2.0 CONVERTIBLE PERFORMANCE DESI GN"
  },
  {
    SERIAL: "36910000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "E-PACE S 2.0",
    label: "E-PACE S 2.0",
    value: "E-PACE S 2.0"
  },
  {
    SERIAL: "36920000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF INGENIUM PRESTIGE 2.0L 200PS",
    label: "XF INGENIUM PRESTIGE 2.0L 200PS",
    value: "XF INGENIUM PRESTIGE 2.0L 200PS"
  },
  {
    SERIAL: "36930000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XE INGENIUM S 2.0D",
    label: "XE INGENIUM S 2.0D",
    value: "XE INGENIUM S 2.0D"
  },
  {
    SERIAL: "36940000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE INGEMIUM PURE AWD 2.0D",
    label: "F-PACE INGEMIUM PURE AWD 2.0D",
    value: "F-PACE INGEMIUM PURE AWD 2.0D"
  },
  {
    SERIAL: "36950000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE INGENIUM R-SPORT AWD 2.0D",
    label: "F-PACE INGENIUM R-SPORT AWD 2.0D",
    value: "F-PACE INGENIUM R-SPORT AWD 2.0D"
  },
  {
    SERIAL: "36960000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE COUPE 2.0",
    label: "F-TYPE COUPE 2.0",
    value: "F-TYPE COUPE 2.0"
  },
  {
    SERIAL: "36970000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE INGENIUM PERFORMANCE DESIGN 2.0C OUPE",
    label: "F-TYPE INGENIUM PERFORMANCE DESIGN 2.0C OUPE",
    value: "F-TYPE INGENIUM PERFORMANCE DESIGN 2.0C OUPE"
  },
  {
    SERIAL: "36980000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE R-DYNAMIC CONVERTIBLE 2.0",
    label: "F-TYPE R-DYNAMIC CONVERTIBLE 2.0",
    value: "F-TYPE R-DYNAMIC CONVERTIBLE 2.0"
  },
  {
    SERIAL: "36990000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE CONVERTIBLE SVR SUPERCHARGED 5.0",
    label: "F-TYPE CONVERTIBLE SVR SUPERCHARGED 5.0",
    value: "F-TYPE CONVERTIBLE SVR SUPERCHARGED 5.0"
  },
  {
    SERIAL: "36AA0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "X-TYPE 2.5 SE",
    label: "X-TYPE 2.5 SE",
    value: "X-TYPE 2.5 SE"
  },
  {
    SERIAL: "36AB0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XE R-DYNAMIC INGENIUM 2.0 SE",
    label: "XE R-DYNAMIC INGENIUM 2.0 SE",
    value: "XE R-DYNAMIC INGENIUM 2.0 SE"
  },
  {
    SERIAL: "36AC0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-TYPE COUPE R-DYNAMIC 2.0",
    label: "F-TYPE COUPE R-DYNAMIC 2.0",
    value: "F-TYPE COUPE R-DYNAMIC 2.0"
  },
  {
    SERIAL: "36AD0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 2.0 SE",
    label: "XF 2.0 SE",
    value: "XF 2.0 SE"
  },
  {
    SERIAL: "36AE0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "XF 2.0 SE R-DYNAMIC",
    label: "XF 2.0 SE R-DYNAMIC",
    value: "XF 2.0 SE R-DYNAMIC"
  },
  {
    SERIAL: "36CC0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE SUPERCHARGED SVR 5.0",
    label: "F-PACE SUPERCHARGED SVR 5.0",
    value: "F-PACE SUPERCHARGED SVR 5.0"
  },
  {
    SERIAL: "36CD0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE 2.0 SE",
    label: "F-PACE 2.0 SE",
    value: "F-PACE 2.0 SE"
  },
  {
    SERIAL: "36CE0000026",
    MAKE_NAME: "JAGUAR",
    MODEL_NAME: "F-PACE 2.0 SE R-DYNAMIC",
    label: "F-PACE 2.0 SE R-DYNAMIC",
    value: "F-PACE 2.0 SE R-DYNAMIC"
  },
  {
    SERIAL: "37000000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "KIA",
    label: "KIA",
    value: "KIA"
  },
  {
    SERIAL: "37020000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SEPHIA GTX 1.6 M",
    label: "SEPHIA GTX 1.6 M",
    value: "SEPHIA GTX 1.6 M"
  },
  {
    SERIAL: "37040000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SPORTAGE SLX 2.0 M",
    label: "SPORTAGE SLX 2.0 M",
    value: "SPORTAGE SLX 2.0 M"
  },
  {
    SERIAL: "37050000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SPORTAGE GTX 2.0 A",
    label: "SPORTAGE GTX 2.0 A",
    value: "SPORTAGE GTX 2.0 A"
  },
  {
    SERIAL: "37070000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND SPORTAGE 2.0 A",
    label: "GRAND SPORTAGE 2.0 A",
    value: "GRAND SPORTAGE 2.0 A"
  },
  {
    SERIAL: "37090000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARNIVAL 2.4 GS (A)",
    label: "CARNIVAL 2.4 GS (A)",
    value: "CARNIVAL 2.4 GS (A)"
  },
  {
    SERIAL: "37100000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PREGIO 2.7 DIESEL",
    label: "PREGIO 2.7 DIESEL",
    value: "PREGIO 2.7 DIESEL"
  },
  {
    SERIAL: "37110000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARENS",
    label: "CARENS",
    value: "CARENS"
  },
  {
    SERIAL: "37120000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SORENTO 4WD 3.5",
    label: "SORENTO 4WD 3.5",
    value: "SORENTO 4WD 3.5"
  },
  {
    SERIAL: "37130000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SORENTO 4WD 2.5",
    label: "SORENTO 4WD 2.5",
    value: "SORENTO 4WD 2.5"
  },
  {
    SERIAL: "37140000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "K2700",
    label: "K2700",
    value: "K2700"
  },
  {
    SERIAL: "37150000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARNIVAL PLATINUM",
    label: "CARNIVAL PLATINUM",
    value: "CARNIVAL PLATINUM"
  },
  {
    SERIAL: "37160000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CARNIVAL LX (11 SEAT)",
    label: "GRAND CARNIVAL LX (11 SEAT)",
    value: "GRAND CARNIVAL LX (11 SEAT)"
  },
  {
    SERIAL: "37170000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CARNIVAL EX (11 SEAT)",
    label: "GRAND CARNIVAL EX (11 SEAT)",
    value: "GRAND CARNIVAL EX (11 SEAT)"
  },
  {
    SERIAL: "37180000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CANIVAL EXECUTIVE (11 SEAT)",
    label: "GRAND CANIVAL EXECUTIVE (11 SEAT)",
    value: "GRAND CANIVAL EXECUTIVE (11 SEAT)"
  },
  {
    SERIAL: "37190000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARENS CRDI V-ESTATE 2.0",
    label: "CARENS CRDI V-ESTATE 2.0",
    value: "CARENS CRDI V-ESTATE 2.0"
  },
  {
    SERIAL: "37200000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARNIVAL FLAGSHIP 2.4",
    label: "CARNIVAL FLAGSHIP 2.4",
    value: "CARNIVAL FLAGSHIP 2.4"
  },
  {
    SERIAL: "37210000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PREGIO GRAND TOURING",
    label: "PREGIO GRAND TOURING",
    value: "PREGIO GRAND TOURING"
  },
  {
    SERIAL: "37220000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PREGIO TLC",
    label: "PREGIO TLC",
    value: "PREGIO TLC"
  },
  {
    SERIAL: "37240000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARENS CRDI EX AUTO",
    label: "CARENS CRDI EX AUTO",
    value: "CARENS CRDI EX AUTO"
  },
  {
    SERIAL: "37250000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PICANTO LX M 5D 1.1",
    label: "PICANTO LX M 5D 1.1",
    value: "PICANTO LX M 5D 1.1"
  },
  {
    SERIAL: "37260000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PICANTO LX AUTO 5D 1.1",
    label: "PICANTO LX AUTO 5D 1.1",
    value: "PICANTO LX AUTO 5D 1.1"
  },
  {
    SERIAL: "37270000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PICANTO EX AUTO 5D 1.1",
    label: "PICANTO EX AUTO 5D 1.1",
    value: "PICANTO EX AUTO 5D 1.1"
  },
  {
    SERIAL: "37280000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARNIVAL 2.4",
    label: "CARNIVAL 2.4",
    value: "CARNIVAL 2.4"
  },
  {
    SERIAL: "37290000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "K2900",
    label: "K2900",
    value: "K2900"
  },
  {
    SERIAL: "37310000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CANIVAL TOURING (11 SEAT)",
    label: "GRAND CANIVAL TOURING (11 SEAT)",
    value: "GRAND CANIVAL TOURING (11 SEAT)"
  },
  {
    SERIAL: "37320000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SOUL 1.6",
    label: "SOUL 1.6",
    value: "SOUL 1.6"
  },
  {
    SERIAL: "37330000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "K2500",
    label: "K2500",
    value: "K2500"
  },
  {
    SERIAL: "37340000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PICANTO 1.2 M",
    label: "PICANTO 1.2 M",
    value: "PICANTO 1.2 M"
  },
  {
    SERIAL: "37350000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PICANTO 1.2 A",
    label: "PICANTO 1.2 A",
    value: "PICANTO 1.2 A"
  },
  {
    SERIAL: "37360000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CARNIVAL CEO 2.9 (11 SEAT)",
    label: "GRAND CARNIVAL CEO 2.9 (11 SEAT)",
    value: "GRAND CARNIVAL CEO 2.9 (11 SEAT)"
  },
  {
    SERIAL: "37370000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CARNIVAL 2.4 CEO",
    label: "CARNIVAL 2.4 CEO",
    value: "CARNIVAL 2.4 CEO"
  },
  {
    SERIAL: "37380000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "RIO 5D 1.4",
    label: "RIO 5D 1.4",
    value: "RIO 5D 1.4"
  },
  {
    SERIAL: "37390000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SORENTO 2.2 DTI",
    label: "SORENTO 2.2 DTI",
    value: "SORENTO 2.2 DTI"
  },
  {
    SERIAL: "37400000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "K2500 JUMBO",
    label: "K2500 JUMBO",
    value: "K2500 JUMBO"
  },
  {
    SERIAL: "37410000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "PICANTO 1.2 A SE",
    label: "PICANTO 1.2 A SE",
    value: "PICANTO 1.2 A SE"
  },
  {
    SERIAL: "37420000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "CERATO 2.0 COUPE",
    label: "CERATO 2.0 COUPE",
    value: "CERATO 2.0 COUPE"
  },
  {
    SERIAL: "37430000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "RIO 4D 1.4",
    label: "RIO 4D 1.4",
    value: "RIO 4D 1.4"
  },
  {
    SERIAL: "37450000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CARNIVAL LX 2.2 (11 SEAT)",
    label: "GRAND CARNIVAL LX 2.2 (11 SEAT)",
    value: "GRAND CARNIVAL LX 2.2 (11 SEAT)"
  },
  {
    SERIAL: "37460000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CARNIVAL EX 2.2 (11 SEAT)",
    label: "GRAND CARNIVAL EX 2.2 (11 SEAT)",
    value: "GRAND CARNIVAL EX 2.2 (11 SEAT)"
  },
  {
    SERIAL: "37470000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "37480000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "STINGER 2.0",
    label: "STINGER 2.0",
    value: "STINGER 2.0"
  },
  {
    SERIAL: "37490000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "GRAND CANIVAL SXL 2.2",
    label: "GRAND CANIVAL SXL 2.2",
    value: "GRAND CANIVAL SXL 2.2"
  },
  {
    SERIAL: "37500000026",
    MAKE_NAME: "KIA",
    MODEL_NAME: "SOUL EV",
    label: "SOUL EV",
    value: "SOUL EV"
  },
  {
    SERIAL: "38000000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "LAMBORGHINI",
    label: "LAMBORGHINI",
    value: "LAMBORGHINI"
  },
  {
    SERIAL: "38010000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "DIABLO",
    label: "DIABLO",
    value: "DIABLO"
  },
  {
    SERIAL: "38020000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP560-4 SPIDER 5.0",
    label: "GALLARDO LP560-4 SPIDER 5.0",
    value: "GALLARDO LP560-4 SPIDER 5.0"
  },
  {
    SERIAL: "38030000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO MAC 5.0",
    label: "GALLARDO MAC 5.0",
    value: "GALLARDO MAC 5.0"
  },
  {
    SERIAL: "38040000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "MURCIELAGO LP640 6.5 COUPE",
    label: "MURCIELAGO LP640 6.5 COUPE",
    value: "MURCIELAGO LP640 6.5 COUPE"
  },
  {
    SERIAL: "38050000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO SUPERLEGGERA MAC COUPE 5.0",
    label: "GALLARDO SUPERLEGGERA MAC COUPE 5.0",
    value: "GALLARDO SUPERLEGGERA MAC COUPE 5.0"
  },
  {
    SERIAL: "38060000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP560-4 COUPE 5.2",
    label: "GALLARDO LP560-4 COUPE 5.2",
    value: "GALLARDO LP560-4 COUPE 5.2"
  },
  {
    SERIAL: "38070000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "MURCIERAGO LP670-4 6.5 COUPE",
    label: "MURCIERAGO LP670-4 6.5 COUPE",
    value: "MURCIERAGO LP670-4 6.5 COUPE"
  },
  {
    SERIAL: "38080000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "BLANCPAIN SUPER TROFEO",
    label: "BLANCPAIN SUPER TROFEO",
    value: "BLANCPAIN SUPER TROFEO"
  },
  {
    SERIAL: "38090000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP550-2",
    label: "GALLARDO LP550-2",
    value: "GALLARDO LP550-2"
  },
  {
    SERIAL: "38110000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP570-4 SUPER LEGGERA",
    label: "GALLARDO LP570-4 SUPER LEGGERA",
    value: "GALLARDO LP570-4 SUPER LEGGERA"
  },
  {
    SERIAL: "38120000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP570-4 SPYDER 5.2",
    label: "GALLARDO LP570-4 SPYDER 5.2",
    value: "GALLARDO LP570-4 SPYDER 5.2"
  },
  {
    SERIAL: "38130000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP570-4 BLANCPAIN",
    label: "GALLARDO LP570-4 BLANCPAIN",
    value: "GALLARDO LP570-4 BLANCPAIN"
  },
  {
    SERIAL: "38140000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR LP700-4 6.5 COUPE",
    label: "AVENTADOR LP700-4 6.5 COUPE",
    value: "AVENTADOR LP700-4 6.5 COUPE"
  },
  {
    SERIAL: "38150000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP560-4 SPIDER 5.2",
    label: "GALLARDO LP560-4 SPIDER 5.2",
    value: "GALLARDO LP560-4 SPIDER 5.2"
  },
  {
    SERIAL: "38160000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR LP720-4 6.5 COUPE",
    label: "AVENTADOR LP720-4 6.5 COUPE",
    value: "AVENTADOR LP720-4 6.5 COUPE"
  },
  {
    SERIAL: "38170000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP570-4 SQUADRA CORSE",
    label: "GALLARDO LP570-4 SQUADRA CORSE",
    value: "GALLARDO LP570-4 SQUADRA CORSE"
  },
  {
    SERIAL: "38200000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR LP720-4 6.5 COUPE (50th)",
    label: "AVENTADOR LP720-4 6.5 COUPE (50th)",
    value: "AVENTADOR LP720-4 6.5 COUPE (50th)"
  },
  {
    SERIAL: "38210000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR LP700-4 6.5 COUPE ROADSTER",
    label: "AVENTADOR LP700-4 6.5 COUPE ROADSTER",
    value: "AVENTADOR LP700-4 6.5 COUPE ROADSTER"
  },
  {
    SERIAL: "38220000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP570-4 SUPER TROFEO STRADALE 5.2",
    label: "GALLARDO LP570-4 SUPER TROFEO STRADALE 5.2",
    value: "GALLARDO LP570-4 SUPER TROFEO STRADALE 5.2"
  },
  {
    SERIAL: "38250000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP560-4 BICOLORE",
    label: "GALLARDO LP560-4 BICOLORE",
    value: "GALLARDO LP560-4 BICOLORE"
  },
  {
    SERIAL: "38260000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN COUPE LP610-4 5.2",
    label: "HURACAN COUPE LP610-4 5.2",
    value: "HURACAN COUPE LP610-4 5.2"
  },
  {
    SERIAL: "38280000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP570-4 PERFORMANTE",
    label: "GALLARDO LP570-4 PERFORMANTE",
    value: "GALLARDO LP570-4 PERFORMANTE"
  },
  {
    SERIAL: "38290000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO LP550-2 SPIDER",
    label: "GALLARDO LP550-2 SPIDER",
    value: "GALLARDO LP550-2 SPIDER"
  },
  {
    SERIAL: "38300000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR LP720-4 6.5 COUPE ROADSTER",
    label: "AVENTADOR LP720-4 6.5 COUPE ROADSTER",
    value: "AVENTADOR LP720-4 6.5 COUPE ROADSTER"
  },
  {
    SERIAL: "38310000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN LP610-4 SPYDER 5.2",
    label: "HURACAN LP610-4 SPYDER 5.2",
    value: "HURACAN LP610-4 SPYDER 5.2"
  },
  {
    SERIAL: "38320000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "GALLARDO TRICOLORE LP550-2",
    label: "GALLARDO TRICOLORE LP550-2",
    value: "GALLARDO TRICOLORE LP550-2"
  },
  {
    SERIAL: "38330000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN COUPE LP580-2",
    label: "HURACAN COUPE LP580-2",
    value: "HURACAN COUPE LP580-2"
  },
  {
    SERIAL: "38340000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN PERFORMANTE LP640-4 5.2",
    label: "HURACAN PERFORMANTE LP640-4 5.2",
    value: "HURACAN PERFORMANTE LP640-4 5.2"
  },
  {
    SERIAL: "38350000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "URUS 4.0",
    label: "URUS 4.0",
    value: "URUS 4.0"
  },
  {
    SERIAL: "38360000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN PERFORMANTE SPYDER 5.2",
    label: "HURACAN PERFORMANTE SPYDER 5.2",
    value: "HURACAN PERFORMANTE SPYDER 5.2"
  },
  {
    SERIAL: "38370000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR SVJ 6.5",
    label: "AVENTADOR SVJ 6.5",
    value: "AVENTADOR SVJ 6.5"
  },
  {
    SERIAL: "38410000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR LP750-4 SUPERVELOCE 6.5",
    label: "AVENTADOR LP750-4 SUPERVELOCE 6.5",
    value: "AVENTADOR LP750-4 SUPERVELOCE 6.5"
  },
  {
    SERIAL: "38430000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN EVO RWD 5.2",
    label: "HURACAN EVO RWD 5.2",
    value: "HURACAN EVO RWD 5.2"
  },
  {
    SERIAL: "38450000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN EVO 5.2",
    label: "HURACAN EVO 5.2",
    value: "HURACAN EVO 5.2"
  },
  {
    SERIAL: "38540000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "HURACAN EVO SPYDER 5.2",
    label: "HURACAN EVO SPYDER 5.2",
    value: "HURACAN EVO SPYDER 5.2"
  },
  {
    SERIAL: "38BT0000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR SV COUPE",
    label: "AVENTADOR SV COUPE",
    value: "AVENTADOR SV COUPE"
  },
  {
    SERIAL: "38BU0000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR S 6.5",
    label: "AVENTADOR S 6.5",
    value: "AVENTADOR S 6.5"
  },
  {
    SERIAL: "38BV0000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR SVJ ROADSTER 6.5",
    label: "AVENTADOR SVJ ROADSTER 6.5",
    value: "AVENTADOR SVJ ROADSTER 6.5"
  },
  {
    SERIAL: "38BW0000026",
    MAKE_NAME: "LAMBORGHINI",
    MODEL_NAME: "AVENTADOR S ROADSTER 6.5",
    label: "AVENTADOR S ROADSTER 6.5",
    value: "AVENTADOR S ROADSTER 6.5"
  },
  {
    SERIAL: "39000000026",
    MAKE_NAME: "LANCIA",
    MODEL_NAME: "LANCIA",
    label: "LANCIA",
    value: "LANCIA"
  },
  {
    SERIAL: "40000000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LEXUS",
    label: "LEXUS",
    value: "LEXUS"
  },
  {
    SERIAL: "40010000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS300 3.0",
    label: "GS300 3.0",
    value: "GS300 3.0"
  },
  {
    SERIAL: "40020000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS200",
    label: "IS200",
    value: "IS200"
  },
  {
    SERIAL: "40030000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS430",
    label: "LS430",
    value: "LS430"
  },
  {
    SERIAL: "40050000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "ES 300 3.0",
    label: "ES 300 3.0",
    value: "ES 300 3.0"
  },
  {
    SERIAL: "40060000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS400",
    label: "LS400",
    value: "LS400"
  },
  {
    SERIAL: "40070000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LX470",
    label: "LX470",
    value: "LX470"
  },
  {
    SERIAL: "40080000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX300 (HARRIER)",
    label: "RX300 (HARRIER)",
    value: "RX300 (HARRIER)"
  },
  {
    SERIAL: "40090000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS250 LUXURY",
    label: "IS250 LUXURY",
    value: "IS250 LUXURY"
  },
  {
    SERIAL: "40100000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS250 PREMIUM",
    label: "IS250 PREMIUM",
    value: "IS250 PREMIUM"
  },
  {
    SERIAL: "40110000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS460",
    label: "LS460",
    value: "LS460"
  },
  {
    SERIAL: "40120000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX350",
    label: "RX350",
    value: "RX350"
  },
  {
    SERIAL: "40130000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX400",
    label: "RX400",
    value: "RX400"
  },
  {
    SERIAL: "40140000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS460 LWB",
    label: "LS460 LWB",
    value: "LS460 LWB"
  },
  {
    SERIAL: "40150000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX450h (HYBRID)",
    label: "RX450h (HYBRID)",
    value: "RX450h (HYBRID)"
  },
  {
    SERIAL: "40160000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS250 CONVERTIBLE 2.5",
    label: "IS250 CONVERTIBLE 2.5",
    value: "IS250 CONVERTIBLE 2.5"
  },
  {
    SERIAL: "40170000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS600hl",
    label: "LS600hl",
    value: "LS600hl"
  },
  {
    SERIAL: "40180000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX270 LUXURY",
    label: "RX270 LUXURY",
    value: "RX270 LUXURY"
  },
  {
    SERIAL: "40190000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX270 LUXURY NAVIGATOR",
    label: "RX270 LUXURY NAVIGATOR",
    value: "RX270 LUXURY NAVIGATOR"
  },
  {
    SERIAL: "40200000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX350 NAVIGATOR",
    label: "RX350 NAVIGATOR",
    value: "RX350 NAVIGATOR"
  },
  {
    SERIAL: "40210000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "CT200h LUXURY 1.8",
    label: "CT200h LUXURY 1.8",
    value: "CT200h LUXURY 1.8"
  },
  {
    SERIAL: "40220000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "CT200h F-SPORT 1.8",
    label: "CT200h F-SPORT 1.8",
    value: "CT200h F-SPORT 1.8"
  },
  {
    SERIAL: "40230000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "CT200h PREMIUM NAVIGATOR 1.8",
    label: "CT200h PREMIUM NAVIGATOR 1.8",
    value: "CT200h PREMIUM NAVIGATOR 1.8"
  },
  {
    SERIAL: "40240000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "CT200h PREMIUM MOONROOF 1.8",
    label: "CT200h PREMIUM MOONROOF 1.8",
    value: "CT200h PREMIUM MOONROOF 1.8"
  },
  {
    SERIAL: "40250000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300h 2.5",
    label: "IS300h 2.5",
    value: "IS300h 2.5"
  },
  {
    SERIAL: "40260000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300h 2.5 PREMIUM",
    label: "IS300h 2.5 PREMIUM",
    value: "IS300h 2.5 PREMIUM"
  },
  {
    SERIAL: "40270000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS250 SPORT (NEW)",
    label: "IS250 SPORT (NEW)",
    value: "IS250 SPORT (NEW)"
  },
  {
    SERIAL: "40280000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LFA NURBURGRING",
    label: "LFA NURBURGRING",
    value: "LFA NURBURGRING"
  },
  {
    SERIAL: "40290000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS250 2.5 LUXURY",
    label: "GS250 2.5 LUXURY",
    value: "GS250 2.5 LUXURY"
  },
  {
    SERIAL: "40300000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX450",
    label: "RX450",
    value: "RX450"
  },
  {
    SERIAL: "40310000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LX570",
    label: "LX570",
    value: "LX570"
  },
  {
    SERIAL: "40330000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX270 PREMIUM 2.7",
    label: "RX270 PREMIUM 2.7",
    value: "RX270 PREMIUM 2.7"
  },
  {
    SERIAL: "40340000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX270 PREMIUM MOON ROOF 2.7",
    label: "RX270 PREMIUM MOON ROOF 2.7",
    value: "RX270 PREMIUM MOON ROOF 2.7"
  },
  {
    SERIAL: "40350000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300h F SPORT 2.5",
    label: "IS300h F SPORT 2.5",
    value: "IS300h F SPORT 2.5"
  },
  {
    SERIAL: "40360000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS460 SWB",
    label: "LS460 SWB",
    value: "LS460 SWB"
  },
  {
    SERIAL: "40370000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "CT200h SPORT 1.8",
    label: "CT200h SPORT 1.8",
    value: "CT200h SPORT 1.8"
  },
  {
    SERIAL: "40380000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "CT200h F-SPORT NAVIGATOR M00NROOF 1.8",
    label: "CT200h F-SPORT NAVIGATOR M00NROOF 1.8",
    value: "CT200h F-SPORT NAVIGATOR M00NROOF 1.8"
  },
  {
    SERIAL: "40390000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS250 PREMIUM 2.5",
    label: "GS250 PREMIUM 2.5",
    value: "GS250 PREMIUM 2.5"
  },
  {
    SERIAL: "40400000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS250 F-SPORT 2.5",
    label: "GS250 F-SPORT 2.5",
    value: "GS250 F-SPORT 2.5"
  },
  {
    SERIAL: "40410000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS300 PREMIUM 3.0",
    label: "GS300 PREMIUM 3.0",
    value: "GS300 PREMIUM 3.0"
  },
  {
    SERIAL: "40420000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS300h 2.5 LUXURY",
    label: "GS300h 2.5 LUXURY",
    value: "GS300h 2.5 LUXURY"
  },
  {
    SERIAL: "40430000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS300h 2.5",
    label: "GS300h 2.5",
    value: "GS300h 2.5"
  },
  {
    SERIAL: "40440000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS300h 2.5 PREMIUM MOONROOF",
    label: "GS300h 2.5 PREMIUM MOONROOF",
    value: "GS300h 2.5 PREMIUM MOONROOF"
  },
  {
    SERIAL: "40450000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LX460",
    label: "LX460",
    value: "LX460"
  },
  {
    SERIAL: "40460000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS350 F-SPORT MOONROOF 3.5",
    label: "GS350 F-SPORT MOONROOF 3.5",
    value: "GS350 F-SPORT MOONROOF 3.5"
  },
  {
    SERIAL: "40470000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS450h PREMIUM NAVIGATOR 3.5",
    label: "GS450h PREMIUM NAVIGATOR 3.5",
    value: "GS450h PREMIUM NAVIGATOR 3.5"
  },
  {
    SERIAL: "40480000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS250 PREMIUM MOONROOF 2.5",
    label: "IS250 PREMIUM MOONROOF 2.5",
    value: "IS250 PREMIUM MOONROOF 2.5"
  },
  {
    SERIAL: "40490000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS460 F-SPORT SWB 4.6",
    label: "LS460 F-SPORT SWB 4.6",
    value: "LS460 F-SPORT SWB 4.6"
  },
  {
    SERIAL: "40500000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS460L LWB 4.6",
    label: "LS460L LWB 4.6",
    value: "LS460L LWB 4.6"
  },
  {
    SERIAL: "40510000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300h PREMIUM 2.5",
    label: "NX300h PREMIUM 2.5",
    value: "NX300h PREMIUM 2.5"
  },
  {
    SERIAL: "40520000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LSF SPORT 4.6",
    label: "LSF SPORT 4.6",
    value: "LSF SPORT 4.6"
  },
  {
    SERIAL: "40530000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "ES300h LUXURY 2.5",
    label: "ES300h LUXURY 2.5",
    value: "ES300h LUXURY 2.5"
  },
  {
    SERIAL: "40540000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "ES300h 2.5 PREMIUM",
    label: "ES300h 2.5 PREMIUM",
    value: "ES300h 2.5 PREMIUM"
  },
  {
    SERIAL: "40550000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX270 LUXURY 2.7",
    label: "RX270 LUXURY 2.7",
    value: "RX270 LUXURY 2.7"
  },
  {
    SERIAL: "40570000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300h LUXURY 2.5",
    label: "NX300h LUXURY 2.5",
    value: "NX300h LUXURY 2.5"
  },
  {
    SERIAL: "40580000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300h GRAND LUXURY 2.5",
    label: "NX300h GRAND LUXURY 2.5",
    value: "NX300h GRAND LUXURY 2.5"
  },
  {
    SERIAL: "40590000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300h F SPORT 2.5",
    label: "NX300h F SPORT 2.5",
    value: "NX300h F SPORT 2.5"
  },
  {
    SERIAL: "40600000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300h F SPORT 2.5 AWD",
    label: "NX300h F SPORT 2.5 AWD",
    value: "NX300h F SPORT 2.5 AWD"
  },
  {
    SERIAL: "40610000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS250 F-SPOORT 2.5 NAVIGATOR MOONROOF",
    label: "IS250 F-SPOORT 2.5 NAVIGATOR MOONROOF",
    value: "IS250 F-SPOORT 2.5 NAVIGATOR MOONROOF"
  },
  {
    SERIAL: "40620000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RC300h F SPORT COUPE 2.5",
    label: "RC300h F SPORT COUPE 2.5",
    value: "RC300h F SPORT COUPE 2.5"
  },
  {
    SERIAL: "40630000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RC200t F SPORT 2.0",
    label: "RC200t F SPORT 2.0",
    value: "RC200t F SPORT 2.0"
  },
  {
    SERIAL: "40640000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS200T 2.0 LUXURY",
    label: "GS200T 2.0 LUXURY",
    value: "GS200T 2.0 LUXURY"
  },
  {
    SERIAL: "40650000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS200T F-SPORT 2.0",
    label: "GS200T F-SPORT 2.0",
    value: "GS200T F-SPORT 2.0"
  },
  {
    SERIAL: "40660000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS350 F-SPORT 3.5",
    label: "GS350 F-SPORT 3.5",
    value: "GS350 F-SPORT 3.5"
  },
  {
    SERIAL: "40670000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX200t LUXURY 2.0",
    label: "RX200t LUXURY 2.0",
    value: "RX200t LUXURY 2.0"
  },
  {
    SERIAL: "40680000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX200t 2.0 PREMIUM",
    label: "RX200t 2.0 PREMIUM",
    value: "RX200t 2.0 PREMIUM"
  },
  {
    SERIAL: "40690000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX200t 2.0 F-SPORT",
    label: "RX200t 2.0 F-SPORT",
    value: "RX200t 2.0 F-SPORT"
  },
  {
    SERIAL: "40700000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS460 SWB 4.6",
    label: "LS460 SWB 4.6",
    value: "LS460 SWB 4.6"
  },
  {
    SERIAL: "40710000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS200t F-SPORT 2.0",
    label: "IS200t F-SPORT 2.0",
    value: "IS200t F-SPORT 2.0"
  },
  {
    SERIAL: "40720000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX200t F-SPORT 2.0",
    label: "NX200t F-SPORT 2.0",
    value: "NX200t F-SPORT 2.0"
  },
  {
    SERIAL: "40730000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RC200t F-SPORT 2.0",
    label: "RC200t F-SPORT 2.0",
    value: "RC200t F-SPORT 2.0"
  },
  {
    SERIAL: "40740000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS600hl",
    label: "LS600hl",
    value: "LS600hl"
  },
  {
    SERIAL: "40750000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS450h",
    label: "GS450h",
    value: "GS450h"
  },
  {
    SERIAL: "40760000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "CT200h PREMIUM 1.8",
    label: "CT200h PREMIUM 1.8",
    value: "CT200h PREMIUM 1.8"
  },
  {
    SERIAL: "40770000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300 GRAND LUXURY 2.0",
    label: "NX300 GRAND LUXURY 2.0",
    value: "NX300 GRAND LUXURY 2.0"
  },
  {
    SERIAL: "40780000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300 F SPORT 2.0 4WD",
    label: "NX300 F SPORT 2.0 4WD",
    value: "NX300 F SPORT 2.0 4WD"
  },
  {
    SERIAL: "40790000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX450h 3.5",
    label: "RX450h 3.5",
    value: "RX450h 3.5"
  },
  {
    SERIAL: "40800000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS500 3.5",
    label: "LS500 3.5",
    value: "LS500 3.5"
  },
  {
    SERIAL: "40810000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS500H 3.5",
    label: "LS500H 3.5",
    value: "LS500H 3.5"
  },
  {
    SERIAL: "40820000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "ES300h GRAND LUXURY 2.5",
    label: "ES300h GRAND LUXURY 2.5",
    value: "ES300h GRAND LUXURY 2.5"
  },
  {
    SERIAL: "40830000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "ES300h 2.5",
    label: "ES300h 2.5",
    value: "ES300h 2.5"
  },
  {
    SERIAL: "40840000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS300 2.0",
    label: "GS300 2.0",
    value: "GS300 2.0"
  },
  {
    SERIAL: "40850000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300h F SPORT 2.0",
    label: "IS300h F SPORT 2.0",
    value: "IS300h F SPORT 2.0"
  },
  {
    SERIAL: "40860000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300 2.0 F SPORT",
    label: "IS300 2.0 F SPORT",
    value: "IS300 2.0 F SPORT"
  },
  {
    SERIAL: "40870000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RC300 F-SPORT 2.0",
    label: "RC300 F-SPORT 2.0",
    value: "RC300 F-SPORT 2.0"
  },
  {
    SERIAL: "40880000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "UX250H GRAND LUXURY 2.0",
    label: "UX250H GRAND LUXURY 2.0",
    value: "UX250H GRAND LUXURY 2.0"
  },
  {
    SERIAL: "40890000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "UX250H LUXURY 2.0 E",
    label: "UX250H LUXURY 2.0 E",
    value: "UX250H LUXURY 2.0 E"
  },
  {
    SERIAL: "40900000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "UX250H F-SPORT AWD 2.0",
    label: "UX250H F-SPORT AWD 2.0",
    value: "UX250H F-SPORT AWD 2.0"
  },
  {
    SERIAL: "40910000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX300 2.0 LUXURY",
    label: "RX300 2.0 LUXURY",
    value: "RX300 2.0 LUXURY"
  },
  {
    SERIAL: "40920000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX300 2.0 PREMIUM",
    label: "RX300 2.0 PREMIUM",
    value: "RX300 2.0 PREMIUM"
  },
  {
    SERIAL: "40930000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX300 2.0 F-SPORT",
    label: "RX300 2.0 F-SPORT",
    value: "RX300 2.0 F-SPORT"
  },
  {
    SERIAL: "40940000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "GS200T 2.0",
    label: "GS200T 2.0",
    value: "GS200T 2.0"
  },
  {
    SERIAL: "40950000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300 2.0",
    label: "NX300 2.0",
    value: "NX300 2.0"
  },
  {
    SERIAL: "40960000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "NX300h 2.5",
    label: "NX300h 2.5",
    value: "NX300h 2.5"
  },
  {
    SERIAL: "40970000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RX350 F-SPORT 3.5",
    label: "RX350 F-SPORT 3.5",
    value: "RX350 F-SPORT 3.5"
  },
  {
    SERIAL: "40980000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LM300H 4SEAT 2.5",
    label: "LM300H 4SEAT 2.5",
    value: "LM300H 4SEAT 2.5"
  },
  {
    SERIAL: "40990000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LM300H 7SEAT 2.5",
    label: "LM300H 7SEAT 2.5",
    value: "LM300H 7SEAT 2.5"
  },
  {
    SERIAL: "40AA0000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "RC300 F-SPORT 2.0 COUPE",
    label: "RC300 F-SPORT 2.0 COUPE",
    value: "RC300 F-SPORT 2.0 COUPE"
  },
  {
    SERIAL: "40AB0000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "UX300E (EV)",
    label: "UX300E (EV)",
    value: "UX300E (EV)"
  },
  {
    SERIAL: "40AC0000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300h 2.5 LUXURY",
    label: "IS300h 2.5 LUXURY",
    value: "IS300h 2.5 LUXURY"
  },
  {
    SERIAL: "40AD0000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300h 2.5 PREMIUM",
    label: "IS300h 2.5 PREMIUM",
    value: "IS300h 2.5 PREMIUM"
  },
  {
    SERIAL: "40AE0000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "IS300h 2.5 F-SPORT",
    label: "IS300h 2.5 F-SPORT",
    value: "IS300h 2.5 F-SPORT"
  },
  {
    SERIAL: "40AF0000026",
    MAKE_NAME: "LEXUS",
    MODEL_NAME: "LS350 3.5 LUXURY",
    label: "LS350 3.5 LUXURY",
    value: "LS350 3.5 LUXURY"
  },
  {
    SERIAL: "41000000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "LOTUS",
    label: "LOTUS",
    value: "LOTUS"
  },
  {
    SERIAL: "41010000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELAN SE TURBO",
    label: "ELAN SE TURBO",
    value: "ELAN SE TURBO"
  },
  {
    SERIAL: "41060000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ESPRIT SE TURBO",
    label: "ESPRIT SE TURBO",
    value: "ESPRIT SE TURBO"
  },
  {
    SERIAL: "41070000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE",
    label: "ELISE",
    value: "ELISE"
  },
  {
    SERIAL: "41080000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EXIGE S 1.8",
    label: "EXIGE S 1.8",
    value: "EXIGE S 1.8"
  },
  {
    SERIAL: "41090000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ESPRIT",
    label: "ESPRIT",
    value: "ESPRIT"
  },
  {
    SERIAL: "41100000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE S 1.8",
    label: "ELISE S 1.8",
    value: "ELISE S 1.8"
  },
  {
    SERIAL: "41110000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EXIGE COUPE 1.8",
    label: "EXIGE COUPE 1.8",
    value: "EXIGE COUPE 1.8"
  },
  {
    SERIAL: "41120000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE R 1.8",
    label: "ELISE R 1.8",
    value: "ELISE R 1.8"
  },
  {
    SERIAL: "41130000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EXIGE S GT SPECIAL EDITION",
    label: "EXIGE S GT SPECIAL EDITION",
    value: "EXIGE S GT SPECIAL EDITION"
  },
  {
    SERIAL: "41140000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "2-ELEVEN",
    label: "2-ELEVEN",
    value: "2-ELEVEN"
  },
  {
    SERIAL: "41150000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EUROPA 2.0",
    label: "EUROPA 2.0",
    value: "EUROPA 2.0"
  },
  {
    SERIAL: "41160000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE SC 1.8",
    label: "ELISE SC 1.8",
    value: "ELISE SC 1.8"
  },
  {
    SERIAL: "41170000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "340R",
    label: "340R",
    value: "340R"
  },
  {
    SERIAL: "41180000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EVORA 3.5",
    label: "EVORA 3.5",
    value: "EVORA 3.5"
  },
  {
    SERIAL: "41200000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE CLUB 1.8",
    label: "ELISE CLUB 1.8",
    value: "ELISE CLUB 1.8"
  },
  {
    SERIAL: "41210000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EXIGE CUP 260 COUPE 1.8",
    label: "EXIGE CUP 260 COUPE 1.8",
    value: "EXIGE CUP 260 COUPE 1.8"
  },
  {
    SERIAL: "41220000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EXIGE SCURA CBRIOLET 1.8",
    label: "EXIGE SCURA CBRIOLET 1.8",
    value: "EXIGE SCURA CBRIOLET 1.8"
  },
  {
    SERIAL: "41230000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EXIGE V6 3.5",
    label: "EXIGE V6 3.5",
    value: "EXIGE V6 3.5"
  },
  {
    SERIAL: "41240000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE CLUB RACER 1.8",
    label: "ELISE CLUB RACER 1.8",
    value: "ELISE CLUB RACER 1.8"
  },
  {
    SERIAL: "41250000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE SC 1.8",
    label: "ELISE SC 1.8",
    value: "ELISE SC 1.8"
  },
  {
    SERIAL: "41260000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "EVORA COUPE 3.5",
    label: "EVORA COUPE 3.5",
    value: "EVORA COUPE 3.5"
  },
  {
    SERIAL: "41270000026",
    MAKE_NAME: "LOTUS",
    MODEL_NAME: "ELISE 1.6",
    label: "ELISE 1.6",
    value: "ELISE 1.6"
  },
  {
    SERIAL: "42000000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "MASERATI",
    label: "MASERATI",
    value: "MASERATI"
  },
  {
    SERIAL: "42020000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI 2.0 4V M",
    label: "GHIBLI 2.0 4V M",
    value: "GHIBLI 2.0 4V M"
  },
  {
    SERIAL: "42040000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "SPYDER 2.0 3V M",
    label: "SPYDER 2.0 3V M",
    value: "SPYDER 2.0 3V M"
  },
  {
    SERIAL: "42120000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "SPYDER GT SMAC 4.2",
    label: "SPYDER GT SMAC 4.2",
    value: "SPYDER GT SMAC 4.2"
  },
  {
    SERIAL: "42130000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "COUPE CAMBIOCORSA F1 4.2",
    label: "COUPE CAMBIOCORSA F1 4.2",
    value: "COUPE CAMBIOCORSA F1 4.2"
  },
  {
    SERIAL: "42140000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANDSPORT COUPE",
    label: "GRANDSPORT COUPE",
    value: "GRANDSPORT COUPE"
  },
  {
    SERIAL: "42150000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE 4.2",
    label: "QUATTROPORTE 4.2",
    value: "QUATTROPORTE 4.2"
  },
  {
    SERIAL: "42160000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE S 4.7",
    label: "QUATTROPORTE S 4.7",
    value: "QUATTROPORTE S 4.7"
  },
  {
    SERIAL: "42170000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANTURISMO COUPE 4.2",
    label: "GRANTURISMO COUPE 4.2",
    value: "GRANTURISMO COUPE 4.2"
  },
  {
    SERIAL: "42180000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANTURISMO COUPE 4.7",
    label: "GRANTURISMO COUPE 4.7",
    value: "GRANTURISMO COUPE 4.7"
  },
  {
    SERIAL: "42190000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANTURISMO COUPE SMAC 4.7",
    label: "GRANTURISMO COUPE SMAC 4.7",
    value: "GRANTURISMO COUPE SMAC 4.7"
  },
  {
    SERIAL: "42200000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANCABRIO CONVETIBLE 4.7",
    label: "GRANCABRIO CONVETIBLE 4.7",
    value: "GRANCABRIO CONVETIBLE 4.7"
  },
  {
    SERIAL: "42210000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE S 4.7",
    label: "QUATTROPORTE S 4.7",
    value: "QUATTROPORTE S 4.7"
  },
  {
    SERIAL: "42220000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI 3.0 DIESEL",
    label: "GHIBLI 3.0 DIESEL",
    value: "GHIBLI 3.0 DIESEL"
  },
  {
    SERIAL: "42230000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "M138 4.2 COUPE",
    label: "M138 4.2 COUPE",
    value: "M138 4.2 COUPE"
  },
  {
    SERIAL: "42240000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE EXECUTIVE 4.2",
    label: "QUATTROPORTE EXECUTIVE 4.2",
    value: "QUATTROPORTE EXECUTIVE 4.2"
  },
  {
    SERIAL: "42250000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE GT S 4.7",
    label: "QUATTROPORTE GT S 4.7",
    value: "QUATTROPORTE GT S 4.7"
  },
  {
    SERIAL: "42260000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE GTS SMAC 3.8",
    label: "QUATTROPORTE GTS SMAC 3.8",
    value: "QUATTROPORTE GTS SMAC 3.8"
  },
  {
    SERIAL: "42270000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "CAMBRIOCORSA CONVERTIBLE F1 SMAC 4.2",
    label: "CAMBRIOCORSA CONVERTIBLE F1 SMAC 4.2",
    value: "CAMBRIOCORSA CONVERTIBLE F1 SMAC 4.2"
  },
  {
    SERIAL: "42280000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI 3.0I",
    label: "GHIBLI 3.0I",
    value: "GHIBLI 3.0I"
  },
  {
    SERIAL: "42290000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI S 3.0TTI",
    label: "GHIBLI S 3.0TTI",
    value: "GHIBLI S 3.0TTI"
  },
  {
    SERIAL: "42300000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANTURISMO MC STRADALE 4.7 COUPE",
    label: "GRANTURISMO MC STRADALE 4.7 COUPE",
    value: "GRANTURISMO MC STRADALE 4.7 COUPE"
  },
  {
    SERIAL: "42310000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANTURISMO SPORT SMAC 4.7 COUPE",
    label: "GRANTURISMO SPORT SMAC 4.7 COUPE",
    value: "GRANTURISMO SPORT SMAC 4.7 COUPE"
  },
  {
    SERIAL: "42320000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.0 DIESEL STANDARD",
    label: "LEVANTE 3.0 DIESEL STANDARD",
    value: "LEVANTE 3.0 DIESEL STANDARD"
  },
  {
    SERIAL: "42340000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANCABRIO SPORT 4.7",
    label: "GRANCABRIO SPORT 4.7",
    value: "GRANCABRIO SPORT 4.7"
  },
  {
    SERIAL: "42350000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "OVER 2000 CC",
    label: "OVER 2000 CC",
    value: "OVER 2000 CC"
  },
  {
    SERIAL: "42360000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE 3.0 DIESEL GRANLUSSO",
    label: "QUATTROPORTE 3.0 DIESEL GRANLUSSO",
    value: "QUATTROPORTE 3.0 DIESEL GRANLUSSO"
  },
  {
    SERIAL: "42370000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE 3.0 S",
    label: "QUATTROPORTE 3.0 S",
    value: "QUATTROPORTE 3.0 S"
  },
  {
    SERIAL: "42380000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE GTS GRANSPORT QUATTRO 3.8I",
    label: "QUATTROPORTE GTS GRANSPORT QUATTRO 3.8I",
    value: "QUATTROPORTE GTS GRANSPORT QUATTRO 3.8I"
  },
  {
    SERIAL: "42390000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.0 S GRANSPORT",
    label: "LEVANTE 3.0 S GRANSPORT",
    value: "LEVANTE 3.0 S GRANSPORT"
  },
  {
    SERIAL: "42400000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.0 DIESEL GRANLUSSO",
    label: "LEVANTE 3.0 DIESEL GRANLUSSO",
    value: "LEVANTE 3.0 DIESEL GRANLUSSO"
  },
  {
    SERIAL: "42410000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.0 DIESEL GRANLUSSO PREMIO",
    label: "LEVANTE 3.0 DIESEL GRANLUSSO PREMIO",
    value: "LEVANTE 3.0 DIESEL GRANLUSSO PREMIO"
  },
  {
    SERIAL: "42420000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI 3.0I GRANLUSSO",
    label: "GHIBLI 3.0I GRANLUSSO",
    value: "GHIBLI 3.0I GRANLUSSO"
  },
  {
    SERIAL: "42430000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI S GRANSPORT 3.0",
    label: "GHIBLI S GRANSPORT 3.0",
    value: "GHIBLI S GRANSPORT 3.0"
  },
  {
    SERIAL: "42440000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE GRANLUSSO QUATTRO 3.0I",
    label: "QUATTROPORTE GRANLUSSO QUATTRO 3.0I",
    value: "QUATTROPORTE GRANLUSSO QUATTRO 3.0I"
  },
  {
    SERIAL: "42450000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI 3.0D GRANLUSSO",
    label: "GHIBLI 3.0D GRANLUSSO",
    value: "GHIBLI 3.0D GRANLUSSO"
  },
  {
    SERIAL: "42460000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE S GRANLUSSO QUATTRO 3.0I",
    label: "QUATTROPORTE S GRANLUSSO QUATTRO 3.0I",
    value: "QUATTROPORTE S GRANLUSSO QUATTRO 3.0I"
  },
  {
    SERIAL: "42470000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "QUATTROPORTE S GRANSPORT QUATTRO 3.0I",
    label: "QUATTROPORTE S GRANSPORT QUATTRO 3.0I",
    value: "QUATTROPORTE S GRANSPORT QUATTRO 3.0I"
  },
  {
    SERIAL: "42480000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI 3.0D GRANLUSSO PREMIO",
    label: "GHIBLI 3.0D GRANLUSSO PREMIO",
    value: "GHIBLI 3.0D GRANLUSSO PREMIO"
  },
  {
    SERIAL: "42490000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANTURISMO SPORT 4.7",
    label: "GRANTURISMO SPORT 4.7",
    value: "GRANTURISMO SPORT 4.7"
  },
  {
    SERIAL: "42500000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GRANCABRIO SPORT 4.7",
    label: "GRANCABRIO SPORT 4.7",
    value: "GRANCABRIO SPORT 4.7"
  },
  {
    SERIAL: "42510000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.0 VULCANO",
    label: "LEVANTE 3.0 VULCANO",
    value: "LEVANTE 3.0 VULCANO"
  },
  {
    SERIAL: "42520000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.0 GRIGIO MATTE",
    label: "LEVANTE 3.0 GRIGIO MATTE",
    value: "LEVANTE 3.0 GRIGIO MATTE"
  },
  {
    SERIAL: "42530000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.8 TROFEO",
    label: "LEVANTE 3.8 TROFEO",
    value: "LEVANTE 3.8 TROFEO"
  },
  {
    SERIAL: "42540000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.8 TROFEO EDITION",
    label: "LEVANTE 3.8 TROFEO EDITION",
    value: "LEVANTE 3.8 TROFEO EDITION"
  },
  {
    SERIAL: "42550000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "GHIBLI HYBRID 2.0",
    label: "GHIBLI HYBRID 2.0",
    value: "GHIBLI HYBRID 2.0"
  },
  {
    SERIAL: "42560000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "MC 20 3.0I",
    label: "MC 20 3.0I",
    value: "MC 20 3.0I"
  },
  {
    SERIAL: "42580000026",
    MAKE_NAME: "MASERATI",
    MODEL_NAME: "LEVANTE 3.0 GRANLUSSO",
    label: "LEVANTE 3.0 GRANLUSSO",
    value: "LEVANTE 3.0 GRANLUSSO"
  },
  {
    SERIAL: "43000000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PORSCHE",
    label: "PORSCHE",
    value: "PORSCHE"
  },
  {
    SERIAL: "43010000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.6I M6 (993)",
    label: "911 CARRERA COUPE 3.6I M6 (993)",
    value: "911 CARRERA COUPE 3.6I M6 (993)"
  },
  {
    SERIAL: "43020000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 COUPE 3.4 (996)",
    label: "911 CARRERA 4 COUPE 3.4 (996)",
    value: "911 CARRERA 4 COUPE 3.4 (996)"
  },
  {
    SERIAL: "43030000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA",
    label: "911 TARGA",
    value: "911 TARGA"
  },
  {
    SERIAL: "43040000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.6 (997)",
    label: "911 CARRERA COUPE 3.6 (997)",
    value: "911 CARRERA COUPE 3.6 (997)"
  },
  {
    SERIAL: "43050000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.6 COLLECTOR CAR",
    label: "911 CARRERA 3.6 COLLECTOR CAR",
    value: "911 CARRERA 3.6 COLLECTOR CAR"
  },
  {
    SERIAL: "43060000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA CABRIOLET",
    label: "911 CARRERA CABRIOLET",
    value: "911 CARRERA CABRIOLET"
  },
  {
    SERIAL: "43080000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA RS",
    label: "911 CARRERA RS",
    value: "911 CARRERA RS"
  },
  {
    SERIAL: "43090000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.6 IS (996)",
    label: "911 CARRERA COUPE 3.6 IS (996)",
    value: "911 CARRERA COUPE 3.6 IS (996)"
  },
  {
    SERIAL: "43100000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911",
    label: "911",
    value: "911"
  },
  {
    SERIAL: "43110000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "928",
    label: "928",
    value: "928"
  },
  {
    SERIAL: "43120000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "986 BOXTER",
    label: "986 BOXTER",
    value: "986 BOXTER"
  },
  {
    SERIAL: "43130000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER CONVERTIBLE 2.7 S TIPTRONIC 987",
    label: "BOXSTER CONVERTIBLE 2.7 S TIPTRONIC 987",
    value: "BOXSTER CONVERTIBLE 2.7 S TIPTRONIC 987"
  },
  {
    SERIAL: "43140000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S COUPE 3.8 (996,997)",
    label: "911 CARRERA 4S COUPE 3.8 (996,997)",
    value: "911 CARRERA 4S COUPE 3.8 (996,997)"
  },
  {
    SERIAL: "43150000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S COUPE 3.6",
    label: "911 CARRERA S COUPE 3.6",
    value: "911 CARRERA S COUPE 3.6"
  },
  {
    SERIAL: "43160000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA CABRIOLET 3.4 4WD (996)",
    label: "911 CARRERA CABRIOLET 3.4 4WD (996)",
    value: "911 CARRERA CABRIOLET 3.4 4WD (996)"
  },
  {
    SERIAL: "43170000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.4 IS",
    label: "911 CARRERA COUPE 3.4 IS",
    value: "911 CARRERA COUPE 3.4 IS"
  },
  {
    SERIAL: "43180000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S CABRIOLET 3.8",
    label: "911 CARRERA S CABRIOLET 3.8",
    value: "911 CARRERA S CABRIOLET 3.8"
  },
  {
    SERIAL: "43190000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S COUPE 3.8 (997)",
    label: "911 CARRERA S COUPE 3.8 (997)",
    value: "911 CARRERA S COUPE 3.8 (997)"
  },
  {
    SERIAL: "43200000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 COUPE 3.6",
    label: "911 CARRERA 4 COUPE 3.6",
    value: "911 CARRERA 4 COUPE 3.6"
  },
  {
    SERIAL: "43210000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "912 CARRERA 3.6 CONVERTIBLE",
    label: "912 CARRERA 3.6 CONVERTIBLE",
    value: "912 CARRERA 3.6 CONVERTIBLE"
  },
  {
    SERIAL: "43220000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 2.5",
    label: "BOXSTER 2.5",
    value: "BOXSTER 2.5"
  },
  {
    SERIAL: "43230000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER CONVERTIBLE 2.7",
    label: "BOXSTER CONVERTIBLE 2.7",
    value: "BOXSTER CONVERTIBLE 2.7"
  },
  {
    SERIAL: "43240000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER CONVERTIBLE 3.2 S (986)",
    label: "BOXSTER CONVERTIBLE 3.2 S (986)",
    value: "BOXSTER CONVERTIBLE 3.2 S (986)"
  },
  {
    SERIAL: "43250000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S 3.2 4WD",
    label: "CAYENNE S 3.2 4WD",
    value: "CAYENNE S 3.2 4WD"
  },
  {
    SERIAL: "43260000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE 4.5I",
    label: "CAYENNE 4.5I",
    value: "CAYENNE 4.5I"
  },
  {
    SERIAL: "43270000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE 4.5I TURBO",
    label: "CAYENNE 4.5I TURBO",
    value: "CAYENNE 4.5I TURBO"
  },
  {
    SERIAL: "43280000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN S 3.4 COUPE (987)",
    label: "CAYMAN S 3.4 COUPE (987)",
    value: "CAYMAN S 3.4 COUPE (987)"
  },
  {
    SERIAL: "43290000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN S 2.7 COUPE",
    label: "CAYMAN S 2.7 COUPE",
    value: "CAYMAN S 2.7 COUPE"
  },
  {
    SERIAL: "43300000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT3 COUPE 3.6 (997)",
    label: "911 GT3 COUPE 3.6 (997)",
    value: "911 GT3 COUPE 3.6 (997)"
  },
  {
    SERIAL: "43310000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S COUPE 3.6 (996)",
    label: "911 CARRERA S COUPE 3.6 (996)",
    value: "911 CARRERA S COUPE 3.6 (996)"
  },
  {
    SERIAL: "43320000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S COUPE 3.4 (996)",
    label: "911 CARRERA S COUPE 3.4 (996)",
    value: "911 CARRERA S COUPE 3.4 (996)"
  },
  {
    SERIAL: "43330000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S CABLIOLET 3.4 (996)",
    label: "911 CARRERA S CABLIOLET 3.4 (996)",
    value: "911 CARRERA S CABLIOLET 3.4 (996)"
  },
  {
    SERIAL: "43350000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S CABRIOLET 3.8 (997)",
    label: "911 CARRERA S CABRIOLET 3.8 (997)",
    value: "911 CARRERA S CABRIOLET 3.8 (997)"
  },
  {
    SERIAL: "43360000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO COUPE 3.6 (996)",
    label: "911 TURBO COUPE 3.6 (996)",
    value: "911 TURBO COUPE 3.6 (996)"
  },
  {
    SERIAL: "43370000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO COUPE 3.6 (997)",
    label: "911 TURBO COUPE 3.6 (997)",
    value: "911 TURBO COUPE 3.6 (997)"
  },
  {
    SERIAL: "43380000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE 4WD 3.6",
    label: "CAYENNE 4WD 3.6",
    value: "CAYENNE 4WD 3.6"
  },
  {
    SERIAL: "43390000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S 4WD 4.8",
    label: "CAYENNE S 4WD 4.8",
    value: "CAYENNE S 4WD 4.8"
  },
  {
    SERIAL: "43400000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE TURBO 4WD 4.8",
    label: "CAYENNE TURBO 4WD 4.8",
    value: "CAYENNE TURBO 4WD 4.8"
  },
  {
    SERIAL: "43410000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S CABRIOLET (996)",
    label: "911 CARRERA 4S CABRIOLET (996)",
    value: "911 CARRERA 4S CABRIOLET (996)"
  },
  {
    SERIAL: "43420000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.6 BLACK EDITION",
    label: "911 CARRERA 3.6 BLACK EDITION",
    value: "911 CARRERA 3.6 BLACK EDITION"
  },
  {
    SERIAL: "43450000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN 2.7",
    label: "CAYMAN 2.7",
    value: "CAYMAN 2.7"
  },
  {
    SERIAL: "43460000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN S 3.4",
    label: "CAYMAN S 3.4",
    value: "CAYMAN S 3.4"
  },
  {
    SERIAL: "43470000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN 2.9 (987)",
    label: "CAYMAN 2.9 (987)",
    value: "CAYMAN 2.9 (987)"
  },
  {
    SERIAL: "43480000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA S 4.8 (970)",
    label: "PANAMERA S 4.8 (970)",
    value: "PANAMERA S 4.8 (970)"
  },
  {
    SERIAL: "43490000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4S 4.8 4WD (970)",
    label: "PANAMERA 4S 4.8 4WD (970)",
    value: "PANAMERA 4S 4.8 4WD (970)"
  },
  {
    SERIAL: "43500000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO 4.8 4WD (970)",
    label: "PANAMERA TURBO 4.8 4WD (970)",
    value: "PANAMERA TURBO 4.8 4WD (970)"
  },
  {
    SERIAL: "43510000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER CONVERTABLE 2.9 (987)",
    label: "BOXSTER CONVERTABLE 2.9 (987)",
    value: "BOXSTER CONVERTABLE 2.9 (987)"
  },
  {
    SERIAL: "43520000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S CABRIOLET 3.8 (997)",
    label: "911 CARRERA 4S CABRIOLET 3.8 (997)",
    value: "911 CARRERA 4S CABRIOLET 3.8 (997)"
  },
  {
    SERIAL: "43530000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER S 3.4 (981)",
    label: "BOXSTER S 3.4 (981)",
    value: "BOXSTER S 3.4 (981)"
  },
  {
    SERIAL: "43540000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE GTS 4.8",
    label: "CAYENNE GTS 4.8",
    value: "CAYENNE GTS 4.8"
  },
  {
    SERIAL: "43550000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S CABRIOLET 3.6",
    label: "911 CARRERA S CABRIOLET 3.6",
    value: "911 CARRERA S CABRIOLET 3.6"
  },
  {
    SERIAL: "43560000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S CABRIOLET 3.8",
    label: "911 CARRERA S CABRIOLET 3.8",
    value: "911 CARRERA S CABRIOLET 3.8"
  },
  {
    SERIAL: "43570000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S 3.6",
    label: "911 CARRERA 4S 3.6",
    value: "911 CARRERA 4S 3.6"
  },
  {
    SERIAL: "43580000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 CABRIOLET 3.6",
    label: "911 CARRERA 4 CABRIOLET 3.6",
    value: "911 CARRERA 4 CABRIOLET 3.6"
  },
  {
    SERIAL: "43590000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4 3.6",
    label: "911 TARGA 4 3.6",
    value: "911 TARGA 4 3.6"
  },
  {
    SERIAL: "43600000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4S 3.8",
    label: "911 TARGA 4S 3.8",
    value: "911 TARGA 4S 3.8"
  },
  {
    SERIAL: "43610000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.8 COUPE",
    label: "911 TURBO 3.8 COUPE",
    value: "911 TURBO 3.8 COUPE"
  },
  {
    SERIAL: "43620000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.6",
    label: "911 CARRERA 3.6",
    value: "911 CARRERA 3.6"
  },
  {
    SERIAL: "43630000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT3 3.8 (991)",
    label: "911 GT3 3.8 (991)",
    value: "911 GT3 3.8 (991)"
  },
  {
    SERIAL: "43640000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.6 (997)",
    label: "911 TURBO 3.6 (997)",
    value: "911 TURBO 3.6 (997)"
  },
  {
    SERIAL: "43650000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO CABRIOLET 3.6 (997)",
    label: "911 TURBO CABRIOLET 3.6 (997)",
    value: "911 TURBO CABRIOLET 3.6 (997)"
  },
  {
    SERIAL: "43660000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.6",
    label: "911 CARRERA COUPE 3.6",
    value: "911 CARRERA COUPE 3.6"
  },
  {
    SERIAL: "43670000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO PDK CABRIOLET 3.8 (997)",
    label: "911 TURBO PDK CABRIOLET 3.8 (997)",
    value: "911 TURBO PDK CABRIOLET 3.8 (997)"
  },
  {
    SERIAL: "43680000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.6",
    label: "911 TURBO 3.6",
    value: "911 TURBO 3.6"
  },
  {
    SERIAL: "43690000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE DIESEL 3.0",
    label: "CAYENNE DIESEL 3.0",
    value: "CAYENNE DIESEL 3.0"
  },
  {
    SERIAL: "43700000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S HYBRID 3.0",
    label: "CAYENNE S HYBRID 3.0",
    value: "CAYENNE S HYBRID 3.0"
  },
  {
    SERIAL: "43710000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 3.4",
    label: "BOXSTER 3.4",
    value: "BOXSTER 3.4"
  },
  {
    SERIAL: "43720000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT2 3.6",
    label: "911 GT2 3.6",
    value: "911 GT2 3.6"
  },
  {
    SERIAL: "43730000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO PDK CABRIOLET 3.8 (997)",
    label: "911 TURBO PDK CABRIOLET 3.8 (997)",
    value: "911 TURBO PDK CABRIOLET 3.8 (997)"
  },
  {
    SERIAL: "43740000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 3.6 (970)",
    label: "PANAMERA 3.6 (970)",
    value: "PANAMERA 3.6 (970)"
  },
  {
    SERIAL: "43750000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S CABRIOLET 3.6 (996)",
    label: "911 CARRERA 4S CABRIOLET 3.6 (996)",
    value: "911 CARRERA 4S CABRIOLET 3.6 (996)"
  },
  {
    SERIAL: "43760000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4 3.6",
    label: "PANAMERA 4 3.6",
    value: "PANAMERA 4 3.6"
  },
  {
    SERIAL: "43770000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GTS 3.8",
    label: "911 GTS 3.8",
    value: "911 GTS 3.8"
  },
  {
    SERIAL: "43780000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GTS CABRIOLET 3.8",
    label: "911 GTS CABRIOLET 3.8",
    value: "911 GTS CABRIOLET 3.8"
  },
  {
    SERIAL: "43790000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO S 3.8 COUPE",
    label: "911 TURBO S 3.8 COUPE",
    value: "911 TURBO S 3.8 COUPE"
  },
  {
    SERIAL: "43800000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO CABRIOLET 3.8",
    label: "911 TURBO CABRIOLET 3.8",
    value: "911 TURBO CABRIOLET 3.8"
  },
  {
    SERIAL: "43810000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO S CABRIOLET 3.8",
    label: "911 TURBO S CABRIOLET 3.8",
    value: "911 TURBO S CABRIOLET 3.8"
  },
  {
    SERIAL: "43820000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT3 RS 3.8",
    label: "911 GT3 RS 3.8",
    value: "911 GT3 RS 3.8"
  },
  {
    SERIAL: "43830000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 SPEEDSTER 3.8",
    label: "911 SPEEDSTER 3.8",
    value: "911 SPEEDSTER 3.8"
  },
  {
    SERIAL: "43840000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN R 3.4",
    label: "CAYMAN R 3.4",
    value: "CAYMAN R 3.4"
  },
  {
    SERIAL: "43850000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 2.9",
    label: "BOXSTER 2.9",
    value: "BOXSTER 2.9"
  },
  {
    SERIAL: "43880000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.8 (997)",
    label: "911 CARRERA 3.8 (997)",
    value: "911 CARRERA 3.8 (997)"
  },
  {
    SERIAL: "43890000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 SPORT CLASSIC",
    label: "911 SPORT CLASSIC",
    value: "911 SPORT CLASSIC"
  },
  {
    SERIAL: "43900000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA S HYBRID 3.0",
    label: "PANAMERA S HYBRID 3.0",
    value: "PANAMERA S HYBRID 3.0"
  },
  {
    SERIAL: "43910000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S HYBRID TURBO 3.0",
    label: "CAYENNE S HYBRID TURBO 3.0",
    value: "CAYENNE S HYBRID TURBO 3.0"
  },
  {
    SERIAL: "43920000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.6 (996)",
    label: "911 TURBO 3.6 (996)",
    value: "911 TURBO 3.6 (996)"
  },
  {
    SERIAL: "43930000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "194",
    label: "194",
    value: "194"
  },
  {
    SERIAL: "43940000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "964",
    label: "964",
    value: "964"
  },
  {
    SERIAL: "43950000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA DIESEL 3.0",
    label: "PANAMERA DIESEL 3.0",
    value: "PANAMERA DIESEL 3.0"
  },
  {
    SERIAL: "43960000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 COUPE 3.4",
    label: "911 CARRERA 4 COUPE 3.4",
    value: "911 CARRERA 4 COUPE 3.4"
  },
  {
    SERIAL: "43970000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA CABRIOLET 3.4",
    label: "911 CARRERA CABRIOLET 3.4",
    value: "911 CARRERA CABRIOLET 3.4"
  },
  {
    SERIAL: "43980000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 CABRIOLET 3.4",
    label: "911 CARRERA 4 CABRIOLET 3.4",
    value: "911 CARRERA 4 CABRIOLET 3.4"
  },
  {
    SERIAL: "43990000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S DIESEL 4.2",
    label: "CAYENNE S DIESEL 4.2",
    value: "CAYENNE S DIESEL 4.2"
  },
  {
    SERIAL: "43AA0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S COUPE 3.4",
    label: "911 CARRERA S COUPE 3.4",
    value: "911 CARRERA S COUPE 3.4"
  },
  {
    SERIAL: "43AB0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO 4.8",
    label: "PANAMERA TURBO 4.8",
    value: "PANAMERA TURBO 4.8"
  },
  {
    SERIAL: "43AC0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 3.6",
    label: "PANAMERA 3.6",
    value: "PANAMERA 3.6"
  },
  {
    SERIAL: "43AD0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S PDK 3.8 (991)",
    label: "911 CARRERA S PDK 3.8 (991)",
    value: "911 CARRERA S PDK 3.8 (991)"
  },
  {
    SERIAL: "43AE0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S CABRIOLET 3.8 (991)",
    label: "911 CARRERA 4S CABRIOLET 3.8 (991)",
    value: "911 CARRERA 4S CABRIOLET 3.8 (991)"
  },
  {
    SERIAL: "43AF0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA (997) GREY",
    label: "911 CARRERA (997) GREY",
    value: "911 CARRERA (997) GREY"
  },
  {
    SERIAL: "43AG0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 3.0 TURBO",
    label: "PANAMERA 3.0 TURBO",
    value: "PANAMERA 3.0 TURBO"
  },
  {
    SERIAL: "43AH0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT2 RS 3.6",
    label: "911 GT2 RS 3.6",
    value: "911 GT2 RS 3.6"
  },
  {
    SERIAL: "43AI0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE GTS 4.8",
    label: "CAYENNE GTS 4.8",
    value: "CAYENNE GTS 4.8"
  },
  {
    SERIAL: "43AJ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA S E-HYBRID 3.0",
    label: "PANAMERA S E-HYBRID 3.0",
    value: "PANAMERA S E-HYBRID 3.0"
  },
  {
    SERIAL: "43AK0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.4",
    label: "911 CARRERA COUPE 3.4",
    value: "911 CARRERA COUPE 3.4"
  },
  {
    SERIAL: "43AL0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S 3.8 COUPE (991)",
    label: "911 CARRERA S 3.8 COUPE (991)",
    value: "911 CARRERA S 3.8 COUPE (991)"
  },
  {
    SERIAL: "43AM0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 2.7 (981)",
    label: "BOXSTER 2.7 (981)",
    value: "BOXSTER 2.7 (981)"
  },
  {
    SERIAL: "43AN0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE GTS 4.8",
    label: "CAYENNE GTS 4.8",
    value: "CAYENNE GTS 4.8"
  },
  {
    SERIAL: "43AO0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN 2.7 (981)",
    label: "CAYMAN 2.7 (981)",
    value: "CAYMAN 2.7 (981)"
  },
  {
    SERIAL: "43AP0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN S 3.4 (981)",
    label: "CAYMAN S 3.4 (981)",
    value: "CAYMAN S 3.4 (981)"
  },
  {
    SERIAL: "43AQ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA GTS 4.8",
    label: "PANAMERA GTS 4.8",
    value: "PANAMERA GTS 4.8"
  },
  {
    SERIAL: "43AR0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE 3.6 GTS",
    label: "CAYENNE 3.6 GTS",
    value: "CAYENNE 3.6 GTS"
  },
  {
    SERIAL: "43AS0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT 3.8",
    label: "911 GT 3.8",
    value: "911 GT 3.8"
  },
  {
    SERIAL: "43AT0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S 50TH",
    label: "911 CARRERA S 50TH",
    value: "911 CARRERA S 50TH"
  },
  {
    SERIAL: "43AU0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "918 SPYDER 4.6",
    label: "918 SPYDER 4.6",
    value: "918 SPYDER 4.6"
  },
  {
    SERIAL: "43AV0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA S 3.0",
    label: "PANAMERA S 3.0",
    value: "PANAMERA S 3.0"
  },
  {
    SERIAL: "43AW0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4S 3.0",
    label: "PANAMERA 4S 3.0",
    value: "PANAMERA 4S 3.0"
  },
  {
    SERIAL: "43AX0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO S 4.8",
    label: "PANAMERA TURBO S 4.8",
    value: "PANAMERA TURBO S 4.8"
  },
  {
    SERIAL: "43AY0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE TURBO S 4.8",
    label: "CAYENNE TURBO S 4.8",
    value: "CAYENNE TURBO S 4.8"
  },
  {
    SERIAL: "43AZ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE ESPRESSO CLASSIC 3.0",
    label: "CAYENNE ESPRESSO CLASSIC 3.0",
    value: "CAYENNE ESPRESSO CLASSIC 3.0"
  },
  {
    SERIAL: "43BA0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4 3.4",
    label: "911 TARGA 4 3.4",
    value: "911 TARGA 4 3.4"
  },
  {
    SERIAL: "43BG0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.6 (997)",
    label: "911 CARRERA 3.6 (997)",
    value: "911 CARRERA 3.6 (997)"
  },
  {
    SERIAL: "43BH0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.4I",
    label: "911 CARRERA COUPE 3.4I",
    value: "911 CARRERA COUPE 3.4I"
  },
  {
    SERIAL: "43BI0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT2 3.6",
    label: "911 GT2 3.6",
    value: "911 GT2 3.6"
  },
  {
    SERIAL: "43BJ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT3 3.6 (996)",
    label: "911 GT3 3.6 (996)",
    value: "911 GT3 3.6 (996)"
  },
  {
    SERIAL: "43BK0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "356 A SPEEDSTER",
    label: "356 A SPEEDSTER",
    value: "356 A SPEEDSTER"
  },
  {
    SERIAL: "43BL0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN S DIESEL 3.0",
    label: "MACAN S DIESEL 3.0",
    value: "MACAN S DIESEL 3.0"
  },
  {
    SERIAL: "43BM0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN 2.0",
    label: "MACAN 2.0",
    value: "MACAN 2.0"
  },
  {
    SERIAL: "43BN0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN TURBO 4WD 3.6",
    label: "MACAN TURBO 4WD 3.6",
    value: "MACAN TURBO 4WD 3.6"
  },
  {
    SERIAL: "43BO0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S E-HYBRID 3.0",
    label: "CAYENNE S E-HYBRID 3.0",
    value: "CAYENNE S E-HYBRID 3.0"
  },
  {
    SERIAL: "43BP0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN S 3.0",
    label: "MACAN S 3.0",
    value: "MACAN S 3.0"
  },
  {
    SERIAL: "43BQ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT3 RS 4.0",
    label: "911 GT3 RS 4.0",
    value: "911 GT3 RS 4.0"
  },
  {
    SERIAL: "43BS0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "RUF 987 3.8 PDK",
    label: "RUF 987 3.8 PDK",
    value: "RUF 987 3.8 PDK"
  },
  {
    SERIAL: "43BT0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "918 SPYDER MARTINI",
    label: "918 SPYDER MARTINI",
    value: "918 SPYDER MARTINI"
  },
  {
    SERIAL: "43BU0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 GTS",
    label: "911 CARRERA 4 GTS",
    value: "911 CARRERA 4 GTS"
  },
  {
    SERIAL: "43BV0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.8 (997)",
    label: "911 TURBO 3.8 (997)",
    value: "911 TURBO 3.8 (997)"
  },
  {
    SERIAL: "43BW0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4 GTS 3.8",
    label: "911 TARGA 4 GTS 3.8",
    value: "911 TARGA 4 GTS 3.8"
  },
  {
    SERIAL: "43BX0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.6 (993) COLLECTOR CAR",
    label: "911 TURBO 3.6 (993) COLLECTOR CAR",
    value: "911 TURBO 3.6 (993) COLLECTOR CAR"
  },
  {
    SERIAL: "43BY0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.0",
    label: "911 CARRERA 3.0",
    value: "911 CARRERA 3.0"
  },
  {
    SERIAL: "43BZ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT RS 4.0 ( GREY)",
    label: "911 GT RS 4.0 ( GREY)",
    value: "911 GT RS 4.0 ( GREY)"
  },
  {
    SERIAL: "43CA0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "968 TIPTRONIC COUPE",
    label: "968 TIPTRONIC COUPE",
    value: "968 TIPTRONIC COUPE"
  },
  {
    SERIAL: "43CB0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.2",
    label: "911 CARRERA 3.2",
    value: "911 CARRERA 3.2"
  },
  {
    SERIAL: "43CC0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA COUPE 3.0",
    label: "911 CARRERA COUPE 3.0",
    value: "911 CARRERA COUPE 3.0"
  },
  {
    SERIAL: "43CD0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S 3.0 COUPE(991)",
    label: "911 CARRERA S 3.0 COUPE(991)",
    value: "911 CARRERA S 3.0 COUPE(991)"
  },
  {
    SERIAL: "43CE0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.6 (964)",
    label: "911 TURBO 3.6 (964)",
    value: "911 TURBO 3.6 (964)"
  },
  {
    SERIAL: "43CF0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN GT4 3.8 COUPE",
    label: "CAYMAN GT4 3.8 COUPE",
    value: "CAYMAN GT4 3.8 COUPE"
  },
  {
    SERIAL: "43CG0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 2.0 TURBO COUPE (718)",
    label: "BOXSTER 2.0 TURBO COUPE (718)",
    value: "BOXSTER 2.0 TURBO COUPE (718)"
  },
  {
    SERIAL: "43CH0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER S 2.5 (718)",
    label: "BOXSTER S 2.5 (718)",
    value: "BOXSTER S 2.5 (718)"
  },
  {
    SERIAL: "43CI0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN 2.0 (718)",
    label: "CAYMAN 2.0 (718)",
    value: "CAYMAN 2.0 (718)"
  },
  {
    SERIAL: "43CJ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN S 2.5 (718)",
    label: "CAYMAN S 2.5 (718)",
    value: "CAYMAN S 2.5 (718)"
  },
  {
    SERIAL: "43CK0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4 3.0",
    label: "911 TARGA 4 3.0",
    value: "911 TARGA 4 3.0"
  },
  {
    SERIAL: "43CL0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4S 3.0",
    label: "911 TARGA 4S 3.0",
    value: "911 TARGA 4S 3.0"
  },
  {
    SERIAL: "43CN0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S 3.0",
    label: "911 CARRERA 4S 3.0",
    value: "911 CARRERA 4S 3.0"
  },
  {
    SERIAL: "43CO0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 R 4.0",
    label: "911 R 4.0",
    value: "911 R 4.0"
  },
  {
    SERIAL: "43CP0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4 E-HYBRID 2.9",
    label: "PANAMERA 4 E-HYBRID 2.9",
    value: "PANAMERA 4 E-HYBRID 2.9"
  },
  {
    SERIAL: "43CQ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO 4.0",
    label: "PANAMERA TURBO 4.0",
    value: "PANAMERA TURBO 4.0"
  },
  {
    SERIAL: "43CR0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 SPEEDSTER 3.2",
    label: "911 SPEEDSTER 3.2",
    value: "911 SPEEDSTER 3.2"
  },
  {
    SERIAL: "43CS0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE SR HYBRID",
    label: "CAYENNE SR HYBRID",
    value: "CAYENNE SR HYBRID"
  },
  {
    SERIAL: "43CT0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.3",
    label: "911 TURBO 3.3",
    value: "911 TURBO 3.3"
  },
  {
    SERIAL: "43CU0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GTS 3.0",
    label: "911 GTS 3.0",
    value: "911 GTS 3.0"
  },
  {
    SERIAL: "43CV0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 2.0 CABRIOLET (718)",
    label: "BOXSTER 2.0 CABRIOLET (718)",
    value: "BOXSTER 2.0 CABRIOLET (718)"
  },
  {
    SERIAL: "43CW0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA RS 3.8 (993)",
    label: "911 CARRERA RS 3.8 (993)",
    value: "911 CARRERA RS 3.8 (993)"
  },
  {
    SERIAL: "43CX0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 3.6",
    label: "911 TARGA 3.6",
    value: "911 TARGA 3.6"
  },
  {
    SERIAL: "43CY0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA CABRIOLET 3.6",
    label: "911 CARRERA CABRIOLET 3.6",
    value: "911 CARRERA CABRIOLET 3.6"
  },
  {
    SERIAL: "43CZ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN R4",
    label: "MACAN R4",
    value: "MACAN R4"
  },
  {
    SERIAL: "43DA0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 3.4 60TH",
    label: "BOXSTER 3.4 60TH",
    value: "BOXSTER 3.4 60TH"
  },
  {
    SERIAL: "43DB0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT3 4.0",
    label: "911 GT3 4.0",
    value: "911 GT3 4.0"
  },
  {
    SERIAL: "43DC0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE E-HYBRID 3.0",
    label: "CAYENNE E-HYBRID 3.0",
    value: "CAYENNE E-HYBRID 3.0"
  },
  {
    SERIAL: "43DD0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE 3.0 BENZINE",
    label: "CAYENNE 3.0 BENZINE",
    value: "CAYENNE 3.0 BENZINE"
  },
  {
    SERIAL: "43DE0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S 2.9",
    label: "CAYENNE S 2.9",
    value: "CAYENNE S 2.9"
  },
  {
    SERIAL: "43DF0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE TURBO 4.0",
    label: "CAYENNE TURBO 4.0",
    value: "CAYENNE TURBO 4.0"
  },
  {
    SERIAL: "43DG0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 3.8 SPYDER",
    label: "BOXSTER 3.8 SPYDER",
    value: "BOXSTER 3.8 SPYDER"
  },
  {
    SERIAL: "43DH0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT2 RS 3.8",
    label: "911 GT2 RS 3.8",
    value: "911 GT2 RS 3.8"
  },
  {
    SERIAL: "43DI0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO S CABRIOLET 3.6 996",
    label: "911 TURBO S CABRIOLET 3.6 996",
    value: "911 TURBO S CABRIOLET 3.6 996"
  },
  {
    SERIAL: "43DJ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN 2.0",
    label: "MACAN 2.0",
    value: "MACAN 2.0"
  },
  {
    SERIAL: "43DK0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4 GTS 3.0",
    label: "911 TARGA 4 GTS 3.0",
    value: "911 TARGA 4 GTS 3.0"
  },
  {
    SERIAL: "43DL0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S ENDURANCE RACING +EXCESS",
    label: "911 CARRERA S ENDURANCE RACING +EXCESS",
    value: "911 CARRERA S ENDURANCE RACING +EXCESS"
  },
  {
    SERIAL: "43DM0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.6",
    label: "911 CARRERA 3.6",
    value: "911 CARRERA 3.6"
  },
  {
    SERIAL: "43DN0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 GT3 RS 3.6 (997) COLLECTOR CAR",
    label: "911 GT3 RS 3.6 (997) COLLECTOR CAR",
    value: "911 GT3 RS 3.6 (997) COLLECTOR CAR"
  },
  {
    SERIAL: "43DO0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN 3.0 GTS",
    label: "MACAN 3.0 GTS",
    value: "MACAN 3.0 GTS"
  },
  {
    SERIAL: "43DP0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN TURBO 3.0",
    label: "MACAN TURBO 3.0",
    value: "MACAN TURBO 3.0"
  },
  {
    SERIAL: "43DQ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 SPEEDSTER 70TH",
    label: "911 SPEEDSTER 70TH",
    value: "911 SPEEDSTER 70TH"
  },
  {
    SERIAL: "43DR0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 3.0",
    label: "911 CARRERA 4 3.0",
    value: "911 CARRERA 4 3.0"
  },
  {
    SERIAL: "43DS0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 3.0 CABRIOLET",
    label: "911 CARRERA 3.0 CABRIOLET",
    value: "911 CARRERA 3.0 CABRIOLET"
  },
  {
    SERIAL: "43DT0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4S DIESEL 4.0",
    label: "PANAMERA 4S DIESEL 4.0",
    value: "PANAMERA 4S DIESEL 4.0"
  },
  {
    SERIAL: "43DU0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4S 2.9",
    label: "PANAMERA 4S 2.9",
    value: "PANAMERA 4S 2.9"
  },
  {
    SERIAL: "43DV0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4 E-HYBRID SPORT TURISMO",
    label: "PANAMERA 4 E-HYBRID SPORT TURISMO",
    value: "PANAMERA 4 E-HYBRID SPORT TURISMO"
  },
  {
    SERIAL: "43DW0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE COUPE 3.0 BENZINE",
    label: "CAYENNE COUPE 3.0 BENZINE",
    value: "CAYENNE COUPE 3.0 BENZINE"
  },
  {
    SERIAL: "43DX0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S 3.0 CABRIOLET",
    label: "911 CARRERA S 3.0 CABRIOLET",
    value: "911 CARRERA S 3.0 CABRIOLET"
  },
  {
    SERIAL: "43DY0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4S 3.0 CABRIOLET",
    label: "911 CARRERA 4S 3.0 CABRIOLET",
    value: "911 CARRERA 4S 3.0 CABRIOLET"
  },
  {
    SERIAL: "43DZ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO 3.8 COUPE",
    label: "911 TURBO 3.8 COUPE",
    value: "911 TURBO 3.8 COUPE"
  },
  {
    SERIAL: "43EA0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "718 BOXSTER SPYDER 4.0",
    label: "718 BOXSTER SPYDER 4.0",
    value: "718 BOXSTER SPYDER 4.0"
  },
  {
    SERIAL: "43EB0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER GTS 2.5",
    label: "BOXSTER GTS 2.5",
    value: "BOXSTER GTS 2.5"
  },
  {
    SERIAL: "43EC0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN GTS 2.5",
    label: "CAYMAN GTS 2.5",
    value: "CAYMAN GTS 2.5"
  },
  {
    SERIAL: "43ED0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 3.0 BENZINE",
    label: "PANAMERA 3.0 BENZINE",
    value: "PANAMERA 3.0 BENZINE"
  },
  {
    SERIAL: "43EE0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4S SPORT TURISMO 2.9",
    label: "PANAMERA 4S SPORT TURISMO 2.9",
    value: "PANAMERA 4S SPORT TURISMO 2.9"
  },
  {
    SERIAL: "43EF0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4S EXECUTIVE 2.9",
    label: "PANAMERA 4S EXECUTIVE 2.9",
    value: "PANAMERA 4S EXECUTIVE 2.9"
  },
  {
    SERIAL: "43EG0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO 4.0",
    label: "PANAMERA TURBO 4.0",
    value: "PANAMERA TURBO 4.0"
  },
  {
    SERIAL: "43EH0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4 E-HYBRID EXECUTIVE 2.9",
    label: "PANAMERA 4 E-HYBRID EXECUTIVE 2.9",
    value: "PANAMERA 4 E-HYBRID EXECUTIVE 2.9"
  },
  {
    SERIAL: "43EI0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO SE HYBRID 4.0",
    label: "PANAMERA TURBO SE HYBRID 4.0",
    value: "PANAMERA TURBO SE HYBRID 4.0"
  },
  {
    SERIAL: "43EJ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO SE HYBRID SPORT TURISMO4 .0",
    label: "PANAMERA TURBO SE HYBRID SPORT TURISMO4 .0",
    value: "PANAMERA TURBO SE HYBRID SPORT TURISMO4 .0"
  },
  {
    SERIAL: "43EK0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA TURBO SE HYBRID EXECUTIVE 4.0",
    label: "PANAMERA TURBO SE HYBRID EXECUTIVE 4.0",
    value: "PANAMERA TURBO SE HYBRID EXECUTIVE 4.0"
  },
  {
    SERIAL: "43EL0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE TURBO SE HYBRID 4.0",
    label: "CAYENNE TURBO SE HYBRID 4.0",
    value: "CAYENNE TURBO SE HYBRID 4.0"
  },
  {
    SERIAL: "43EM0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE E-HYBRID COUPE 3.0",
    label: "CAYENNE E-HYBRID COUPE 3.0",
    value: "CAYENNE E-HYBRID COUPE 3.0"
  },
  {
    SERIAL: "43EN0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE S COUPE 2.9",
    label: "CAYENNE S COUPE 2.9",
    value: "CAYENNE S COUPE 2.9"
  },
  {
    SERIAL: "43EO0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE TURBO COUPE 4.0",
    label: "CAYENNE TURBO COUPE 4.0",
    value: "CAYENNE TURBO COUPE 4.0"
  },
  {
    SERIAL: "43EP0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE TURBO SE HYBRID COUPE 4.0",
    label: "CAYENNE TURBO SE HYBRID COUPE 4.0",
    value: "CAYENNE TURBO SE HYBRID COUPE 4.0"
  },
  {
    SERIAL: "43EQ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "TAYCAN 4S (EV)",
    label: "TAYCAN 4S (EV)",
    value: "TAYCAN 4S (EV)"
  },
  {
    SERIAL: "43ER0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "TAYCAN TURBO (EV)",
    label: "TAYCAN TURBO (EV)",
    value: "TAYCAN TURBO (EV)"
  },
  {
    SERIAL: "43ES0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "TAYCAN TURBO S (EV)",
    label: "TAYCAN TURBO S (EV)",
    value: "TAYCAN TURBO S (EV)"
  },
  {
    SERIAL: "43ET0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 GTS 3.0",
    label: "911 CARRERA 4 GTS 3.0",
    value: "911 CARRERA 4 GTS 3.0"
  },
  {
    SERIAL: "43EU0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA 4S 3.8",
    label: "911 TARGA 4S 3.8",
    value: "911 TARGA 4S 3.8"
  },
  {
    SERIAL: "43EW0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "924",
    label: "924",
    value: "924"
  },
  {
    SERIAL: "43EX0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TURBO S 3.8 CABRIOLET",
    label: "911 TURBO S 3.8 CABRIOLET",
    value: "911 TURBO S 3.8 CABRIOLET"
  },
  {
    SERIAL: "43EY0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYMAN 2.0 T",
    label: "CAYMAN 2.0 T",
    value: "CAYMAN 2.0 T"
  },
  {
    SERIAL: "43EZ0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "BOXSTER 2.0 T CABRUOLET",
    label: "BOXSTER 2.0 T CABRUOLET",
    value: "BOXSTER 2.0 T CABRUOLET"
  },
  {
    SERIAL: "43FA0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 4S E-HYBRID 2.9",
    label: "PANAMERA 4S E-HYBRID 2.9",
    value: "PANAMERA 4S E-HYBRID 2.9"
  },
  {
    SERIAL: "43FB0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "PANAMERA 3.0I",
    label: "PANAMERA 3.0I",
    value: "PANAMERA 3.0I"
  },
  {
    SERIAL: "43FC0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "MACAN 2.9 GTS",
    label: "MACAN 2.9 GTS",
    value: "MACAN 2.9 GTS"
  },
  {
    SERIAL: "43FD0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE GTS 4.0",
    label: "CAYENNE GTS 4.0",
    value: "CAYENNE GTS 4.0"
  },
  {
    SERIAL: "43FE0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE GTS COUPE 4.0",
    label: "CAYENNE GTS COUPE 4.0",
    value: "CAYENNE GTS COUPE 4.0"
  },
  {
    SERIAL: "43FF0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "CAYENNE PLATINUM 3.0 DIESEL",
    label: "CAYENNE PLATINUM 3.0 DIESEL",
    value: "CAYENNE PLATINUM 3.0 DIESEL"
  },
  {
    SERIAL: "43IU0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 SPEEDSTER 4.0",
    label: "911 SPEEDSTER 4.0",
    value: "911 SPEEDSTER 4.0"
  },
  {
    SERIAL: "43IV0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA S 3.0 (992)",
    label: "911 CARRERA S 3.0 (992)",
    value: "911 CARRERA S 3.0 (992)"
  },
  {
    SERIAL: "43IW0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA T 3.0",
    label: "911 CARRERA T 3.0",
    value: "911 CARRERA T 3.0"
  },
  {
    SERIAL: "43IX0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 CARRERA 4 CABRIOLET 3.0",
    label: "911 CARRERA 4 CABRIOLET 3.0",
    value: "911 CARRERA 4 CABRIOLET 3.0"
  },
  {
    SERIAL: "43IY0000026",
    MAKE_NAME: "PORSCHE",
    MODEL_NAME: "911 TARGA4S HERITAGE DESIGN EDITION 3.0",
    label: "911 TARGA4S HERITAGE DESIGN EDITION 3.0",
    value: "911 TARGA4S HERITAGE DESIGN EDITION 3.0"
  },
  {
    SERIAL: "44000000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "ROLLS-ROYCE",
    label: "ROLLS-ROYCE",
    value: "ROLLS-ROYCE"
  },
  {
    SERIAL: "44010000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "SILVER SPIRIT III",
    label: "SILVER SPIRIT III",
    value: "SILVER SPIRIT III"
  },
  {
    SERIAL: "44020000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "SILVER SPUR",
    label: "SILVER SPUR",
    value: "SILVER SPUR"
  },
  {
    SERIAL: "44030000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "SILVER SHADOW SYE",
    label: "SILVER SHADOW SYE",
    value: "SILVER SHADOW SYE"
  },
  {
    SERIAL: "44040000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "GHOST 6.6",
    label: "GHOST 6.6",
    value: "GHOST 6.6"
  },
  {
    SERIAL: "44050000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "FLYING SPUR",
    label: "FLYING SPUR",
    value: "FLYING SPUR"
  },
  {
    SERIAL: "44060000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "PHANTOM",
    label: "PHANTOM",
    value: "PHANTOM"
  },
  {
    SERIAL: "44070000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "SILVER SERAPH 5.4",
    label: "SILVER SERAPH 5.4",
    value: "SILVER SERAPH 5.4"
  },
  {
    SERIAL: "44080000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "WRAITH 6.6 COUPE",
    label: "WRAITH 6.6 COUPE",
    value: "WRAITH 6.6 COUPE"
  },
  {
    SERIAL: "44090000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "GHOST 6.6 SWB",
    label: "GHOST 6.6 SWB",
    value: "GHOST 6.6 SWB"
  },
  {
    SERIAL: "44100000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "PHANTOM DROPHEAD 6.7 COUPE",
    label: "PHANTOM DROPHEAD 6.7 COUPE",
    value: "PHANTOM DROPHEAD 6.7 COUPE"
  },
  {
    SERIAL: "44110000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "PHANTOM SERIES II 6.7",
    label: "PHANTOM SERIES II 6.7",
    value: "PHANTOM SERIES II 6.7"
  },
  {
    SERIAL: "44120000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "PHANTOM LWB SERIES II 6.7",
    label: "PHANTOM LWB SERIES II 6.7",
    value: "PHANTOM LWB SERIES II 6.7"
  },
  {
    SERIAL: "44130000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "GHOST 6.6 EWB",
    label: "GHOST 6.6 EWB",
    value: "GHOST 6.6 EWB"
  },
  {
    SERIAL: "44140000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "GHOST EWB คชมงคล 6.6",
    label: "GHOST EWB คชมงคล 6.6",
    value: "GHOST EWB คชมงคล 6.6"
  },
  {
    SERIAL: "44150000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "PHANTOM 6.8",
    label: "PHANTOM 6.8",
    value: "PHANTOM 6.8"
  },
  {
    SERIAL: "44160000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "PHANTOM VIII 6.7",
    label: "PHANTOM VIII 6.7",
    value: "PHANTOM VIII 6.7"
  },
  {
    SERIAL: "44170000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "PHANTOM VIII EWB 6.7",
    label: "PHANTOM VIII EWB 6.7",
    value: "PHANTOM VIII EWB 6.7"
  },
  {
    SERIAL: "44180000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "CULLINAN 6.75",
    label: "CULLINAN 6.75",
    value: "CULLINAN 6.75"
  },
  {
    SERIAL: "44190000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "SILVER DAWN 6.7",
    label: "SILVER DAWN 6.7",
    value: "SILVER DAWN 6.7"
  },
  {
    SERIAL: "44200000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "WRAITH BLACK BADGE 6.6",
    label: "WRAITH BLACK BADGE 6.6",
    value: "WRAITH BLACK BADGE 6.6"
  },
  {
    SERIAL: "44210000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "CULLINAN BLACK BADGE 6.75",
    label: "CULLINAN BLACK BADGE 6.75",
    value: "CULLINAN BLACK BADGE 6.75"
  },
  {
    SERIAL: "44220000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "GHOST 6.75",
    label: "GHOST 6.75",
    value: "GHOST 6.75"
  },
  {
    SERIAL: "44230000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "GHOST EWB 6.75",
    label: "GHOST EWB 6.75",
    value: "GHOST EWB 6.75"
  },
  {
    SERIAL: "44420000026",
    MAKE_NAME: "ROLLS-ROYCE",
    MODEL_NAME: "DAWN 6.6 CONVERTIBLE",
    label: "DAWN 6.6 CONVERTIBLE",
    value: "DAWN 6.6 CONVERTIBLE"
  },
  {
    SERIAL: "45000000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "SSANG YONG",
    label: "SSANG YONG",
    value: "SSANG YONG"
  },
  {
    SERIAL: "45010000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "MUSSO D226",
    label: "MUSSO D226",
    value: "MUSSO D226"
  },
  {
    SERIAL: "45020000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "MUSSO D287",
    label: "MUSSO D287",
    value: "MUSSO D287"
  },
  {
    SERIAL: "45030000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "MUSSO D288",
    label: "MUSSO D288",
    value: "MUSSO D288"
  },
  {
    SERIAL: "45060000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "E23",
    label: "E23",
    value: "E23"
  },
  {
    SERIAL: "45070000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "D602 EL",
    label: "D602 EL",
    value: "D602 EL"
  },
  {
    SERIAL: "45090000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "D630 5Dr.",
    label: "D630 5Dr.",
    value: "D630 5Dr."
  },
  {
    SERIAL: "45100000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "MUSSO IL6",
    label: "MUSSO IL6",
    value: "MUSSO IL6"
  },
  {
    SERIAL: "45110000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "MUSSO 2.9 D",
    label: "MUSSO 2.9 D",
    value: "MUSSO 2.9 D"
  },
  {
    SERIAL: "45130000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "REXTON RX290",
    label: "REXTON RX290",
    value: "REXTON RX290"
  },
  {
    SERIAL: "45140000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "REXTON RX320",
    label: "REXTON RX320",
    value: "REXTON RX320"
  },
  {
    SERIAL: "45150000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC S",
    label: "STAVIC S",
    value: "STAVIC S"
  },
  {
    SERIAL: "45170000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "REXTON ENDURO",
    label: "REXTON ENDURO",
    value: "REXTON ENDURO"
  },
  {
    SERIAL: "45180000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "ACTYON STANDARD",
    label: "ACTYON STANDARD",
    value: "ACTYON STANDARD"
  },
  {
    SERIAL: "45190000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "ACTYON COMFORT 2.0",
    label: "ACTYON COMFORT 2.0",
    value: "ACTYON COMFORT 2.0"
  },
  {
    SERIAL: "45200000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "ACTYON TECHLINE 2.0",
    label: "ACTYON TECHLINE 2.0",
    value: "ACTYON TECHLINE 2.0"
  },
  {
    SERIAL: "45210000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "CHAIRMAN 2.3",
    label: "CHAIRMAN 2.3",
    value: "CHAIRMAN 2.3"
  },
  {
    SERIAL: "45220000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "KYRON 2.0",
    label: "KYRON 2.0",
    value: "KYRON 2.0"
  },
  {
    SERIAL: "45230000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "REXTON RXII 2.7",
    label: "REXTON RXII 2.7",
    value: "REXTON RXII 2.7"
  },
  {
    SERIAL: "45240000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC SE",
    label: "STAVIC SE",
    value: "STAVIC SE"
  },
  {
    SERIAL: "45250000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC SL",
    label: "STAVIC SL",
    value: "STAVIC SL"
  },
  {
    SERIAL: "45260000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC 270 4WD",
    label: "STAVIC 270 4WD",
    value: "STAVIC 270 4WD"
  },
  {
    SERIAL: "45270000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC 270 SL-2WD (LPG)",
    label: "STAVIC 270 SL-2WD (LPG)",
    value: "STAVIC 270 SL-2WD (LPG)"
  },
  {
    SERIAL: "45280000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC 270 STD-2WD (LPG)",
    label: "STAVIC 270 STD-2WD (LPG)",
    value: "STAVIC 270 STD-2WD (LPG)"
  },
  {
    SERIAL: "45290000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC 270 SL 4WD (LPG)",
    label: "STAVIC 270 SL 4WD (LPG)",
    value: "STAVIC 270 SL 4WD (LPG)"
  },
  {
    SERIAL: "45300000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "CHAIRMAN CM 600L 3.2",
    label: "CHAIRMAN CM 600L 3.2",
    value: "CHAIRMAN CM 600L 3.2"
  },
  {
    SERIAL: "45310000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC SV320 S 3.2",
    label: "STAVIC SV320 S 3.2",
    value: "STAVIC SV320 S 3.2"
  },
  {
    SERIAL: "45320000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC EXECUTIVE 2.7",
    label: "STAVIC EXECUTIVE 2.7",
    value: "STAVIC EXECUTIVE 2.7"
  },
  {
    SERIAL: "45330000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC EXECLUSIVE 4WD 2.7",
    label: "STAVIC EXECLUSIVE 4WD 2.7",
    value: "STAVIC EXECLUSIVE 4WD 2.7"
  },
  {
    SERIAL: "45340000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "KORANDO 2.0",
    label: "KORANDO 2.0",
    value: "KORANDO 2.0"
  },
  {
    SERIAL: "45350000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "SV320 EXECLUSIVE 3.2",
    label: "SV320 EXECLUSIVE 3.2",
    value: "SV320 EXECLUSIVE 3.2"
  },
  {
    SERIAL: "45360000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "SV200",
    label: "SV200",
    value: "SV200"
  },
  {
    SERIAL: "45370000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "SV200 4WD",
    label: "SV200 4WD",
    value: "SV200 4WD"
  },
  {
    SERIAL: "45380000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "REXTON 2.0 A 4WD",
    label: "REXTON 2.0 A 4WD",
    value: "REXTON 2.0 A 4WD"
  },
  {
    SERIAL: "45390000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC TOURISMO STANDARD",
    label: "STAVIC TOURISMO STANDARD",
    value: "STAVIC TOURISMO STANDARD"
  },
  {
    SERIAL: "45400000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "STAVIC TOURISMO TOP VERSION",
    label: "STAVIC TOURISMO TOP VERSION",
    value: "STAVIC TOURISMO TOP VERSION"
  },
  {
    SERIAL: "45410000026",
    MAKE_NAME: "SSANG YONG",
    MODEL_NAME: "TIVOLI 1.6",
    label: "TIVOLI 1.6",
    value: "TIVOLI 1.6"
  },
  {
    SERIAL: "46000000026",
    MAKE_NAME: "SEAT",
    MODEL_NAME: "SEAT",
    label: "SEAT",
    value: "SEAT"
  },
  {
    SERIAL: "46020000026",
    MAKE_NAME: "SEAT",
    MODEL_NAME: "CORDOBA 1.8 GLX A",
    label: "CORDOBA 1.8 GLX A",
    value: "CORDOBA 1.8 GLX A"
  },
  {
    SERIAL: "46030000026",
    MAKE_NAME: "SEAT",
    MODEL_NAME: "ALHAMBRA 1.9 TDI",
    label: "ALHAMBRA 1.9 TDI",
    value: "ALHAMBRA 1.9 TDI"
  },
  {
    SERIAL: "46040000026",
    MAKE_NAME: "SEAT",
    MODEL_NAME: "TOLEDO",
    label: "TOLEDO",
    value: "TOLEDO"
  },
  {
    SERIAL: "47000000026",
    MAKE_NAME: "HILLMAN",
    MODEL_NAME: "HILLMAN",
    label: "HILLMAN",
    value: "HILLMAN"
  },
  {
    SERIAL: "48000000026",
    MAKE_NAME: "JEEP",
    MODEL_NAME: "JEEP",
    label: "JEEP",
    value: "JEEP"
  },
  {
    SERIAL: "48020000026",
    MAKE_NAME: "JEEP",
    MODEL_NAME: "CHEROKEE",
    label: "CHEROKEE",
    value: "CHEROKEE"
  },
  {
    SERIAL: "48030000026",
    MAKE_NAME: "JEEP",
    MODEL_NAME: "WILLY",
    label: "WILLY",
    value: "WILLY"
  },
  {
    SERIAL: "49000000026",
    MAKE_NAME: "DATSUN",
    MODEL_NAME: "DATSUN",
    label: "DATSUN",
    value: "DATSUN"
  },
  {
    SERIAL: "49020000026",
    MAKE_NAME: "DATSUN",
    MODEL_NAME: "BLUEBIRD",
    label: "BLUEBIRD",
    value: "BLUEBIRD"
  },
  {
    SERIAL: "49040000026",
    MAKE_NAME: "DATSUN",
    MODEL_NAME: "STANZA",
    label: "STANZA",
    value: "STANZA"
  },
  {
    SERIAL: "49050000026",
    MAKE_NAME: "DATSUN",
    MODEL_NAME: "3000",
    label: "3000",
    value: "3000"
  },
  {
    SERIAL: "49060000026",
    MAKE_NAME: "DATSUN",
    MODEL_NAME: "SUNNY",
    label: "SUNNY",
    value: "SUNNY"
  },
  {
    SERIAL: "49090000026",
    MAKE_NAME: "DATSUN",
    MODEL_NAME: "3000 CC",
    label: "3000 CC",
    value: "3000 CC"
  },
  {
    SERIAL: "50000000026",
    MAKE_NAME: "AUSTIN",
    MODEL_NAME: "AUSTIN",
    label: "AUSTIN",
    value: "AUSTIN"
  },
  {
    SERIAL: "50010000026",
    MAKE_NAME: "AUSTIN",
    MODEL_NAME: "AUSTIN",
    label: "AUSTIN",
    value: "AUSTIN"
  },
  {
    SERIAL: "50020000026",
    MAKE_NAME: "AUSTIN",
    MODEL_NAME: "MINI",
    label: "MINI",
    value: "MINI"
  },
  {
    SERIAL: "51000000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "HINO",
    label: "HINO",
    value: "HINO"
  },
  {
    SERIAL: "51020000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB2WE",
    label: "FB2WE",
    value: "FB2WE"
  },
  {
    SERIAL: "51030000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB2WG",
    label: "FB2WG",
    value: "FB2WG"
  },
  {
    SERIAL: "51040000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2WE",
    label: "FC2WE",
    value: "FC2WE"
  },
  {
    SERIAL: "51050000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2WH",
    label: "FC2WH",
    value: "FC2WH"
  },
  {
    SERIAL: "51060000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HG",
    label: "FF3HG",
    value: "FF3HG"
  },
  {
    SERIAL: "51070000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HJ",
    label: "FF3HJ",
    value: "FF3HJ"
  },
  {
    SERIAL: "51080000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HM",
    label: "FF3HM",
    value: "FF3HM"
  },
  {
    SERIAL: "51090000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HP",
    label: "FF3HP",
    value: "FF3HP"
  },
  {
    SERIAL: "51100000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL3H",
    label: "FL3H",
    value: "FL3H"
  },
  {
    SERIAL: "51110000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL3M",
    label: "FL3M",
    value: "FL3M"
  },
  {
    SERIAL: "51120000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3H",
    label: "FM3H",
    value: "FM3H"
  },
  {
    SERIAL: "51130000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3MD",
    label: "FM3MD",
    value: "FM3MD"
  },
  {
    SERIAL: "51140000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3MK",
    label: "FM3MK",
    value: "FM3MK"
  },
  {
    SERIAL: "51150000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2KN",
    label: "FM2KN",
    value: "FM2KN"
  },
  {
    SERIAL: "51160000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2KKLA(หัวลาก)",
    label: "FM2KKLA(หัวลาก)",
    value: "FM2KKLA(หัวลาก)"
  },
  {
    SERIAL: "51170000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB112",
    label: "FB112",
    value: "FB112"
  },
  {
    SERIAL: "51180000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB113",
    label: "FB113",
    value: "FB113"
  },
  {
    SERIAL: "51190000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC112",
    label: "FC112",
    value: "FC112"
  },
  {
    SERIAL: "51200000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC114",
    label: "FC114",
    value: "FC114"
  },
  {
    SERIAL: "51210000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF172",
    label: "FF172",
    value: "FF172"
  },
  {
    SERIAL: "51230000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF177",
    label: "FF177",
    value: "FF177"
  },
  {
    SERIAL: "51240000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL177",
    label: "FL177",
    value: "FL177"
  },
  {
    SERIAL: "51250000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL187",
    label: "FL187",
    value: "FL187"
  },
  {
    SERIAL: "51260000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL227",
    label: "FL227",
    value: "FL227"
  },
  {
    SERIAL: "51270000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM221LA(หัวลาก)",
    label: "FM221LA(หัวลาก)",
    value: "FM221LA(หัวลาก)"
  },
  {
    SERIAL: "51280000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM227LD",
    label: "FM227LD",
    value: "FM227LD"
  },
  {
    SERIAL: "51300000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3MKKA(หัวลาก)",
    label: "FM3MKKA(หัวลาก)",
    value: "FM3MKKA(หัวลาก)"
  },
  {
    SERIAL: "51310000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3",
    label: "FM3",
    value: "FM3"
  },
  {
    SERIAL: "51320000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KM777",
    label: "KM777",
    value: "KM777"
  },
  {
    SERIAL: "51330000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KM779",
    label: "KM779",
    value: "KM779"
  },
  {
    SERIAL: "51350000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF1J",
    label: "FF1J",
    value: "FF1J"
  },
  {
    SERIAL: "51360000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL1J",
    label: "FL1J",
    value: "FL1J"
  },
  {
    SERIAL: "51370000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1J",
    label: "FM1J",
    value: "FM1J"
  },
  {
    SERIAL: "51380000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC3J",
    label: "FC3J",
    value: "FC3J"
  },
  {
    SERIAL: "51390000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR279",
    label: "FR279",
    value: "FR279"
  },
  {
    SERIAL: "51400000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "RK1",
    label: "RK1",
    value: "RK1"
  },
  {
    SERIAL: "51410000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "SS711A",
    label: "SS711A",
    value: "SS711A"
  },
  {
    SERIAL: "51420000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB4J (BUILT)",
    label: "FB4J (BUILT)",
    value: "FB4J (BUILT)"
  },
  {
    SERIAL: "51430000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2KWC (BUILT 12 WHEEL)",
    label: "FN2KWC (BUILT 12 WHEEL)",
    value: "FN2KWC (BUILT 12 WHEEL)"
  },
  {
    SERIAL: "51440000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "GY2PLA",
    label: "GY2PLA",
    value: "GY2PLA"
  },
  {
    SERIAL: "51460000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2 (BUILT)",
    label: "FC2 (BUILT)",
    value: "FC2 (BUILT)"
  },
  {
    SERIAL: "51480000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD1HLA (BUILT)",
    label: "FD1HLA (BUILT)",
    value: "FD1HLA (BUILT)"
  },
  {
    SERIAL: "51500000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "RK8",
    label: "RK8",
    value: "RK8"
  },
  {
    SERIAL: "51510000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG1JPKA (NGV)",
    label: "FG1JPKA (NGV)",
    value: "FG1JPKA (NGV)"
  },
  {
    SERIAL: "51520000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1JKKA (NGV) หัวลาก",
    label: "FM1JKKA (NGV) หัวลาก",
    value: "FM1JKKA (NGV) หัวลาก"
  },
  {
    SERIAL: "51530000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR3WB BUILT (3เพลา)",
    label: "FR3WB BUILT (3เพลา)",
    value: "FR3WB BUILT (3เพลา)"
  },
  {
    SERIAL: "51540000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM186",
    label: "FM186",
    value: "FM186"
  },
  {
    SERIAL: "51550000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8J NGV",
    label: "FG8J NGV",
    value: "FG8J NGV"
  },
  {
    SERIAL: "51560000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN1 BUILT 4เพลา",
    label: "FN1 BUILT 4เพลา",
    value: "FN1 BUILT 4เพลา"
  },
  {
    SERIAL: "51570000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG1J NGV",
    label: "FG1J NGV",
    value: "FG1J NGV"
  },
  {
    SERIAL: "51580000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR3FZD (BUILT)",
    label: "FR3FZD (BUILT)",
    value: "FR3FZD (BUILT)"
  },
  {
    SERIAL: "51600000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT 925 (BUILT)",
    label: "KT 925 (BUILT)",
    value: "KT 925 (BUILT)"
  },
  {
    SERIAL: "51610000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HP (BUILT)",
    label: "FF3HP (BUILT)",
    value: "FF3HP (BUILT)"
  },
  {
    SERIAL: "51620000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR274",
    label: "FR274",
    value: "FR274"
  },
  {
    SERIAL: "51630000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKUJ22H",
    label: "MNKUJ22H",
    value: "MNKUJ22H"
  },
  {
    SERIAL: "51640000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL17J (NGV)",
    label: "FL17J (NGV)",
    value: "FL17J (NGV)"
  },
  {
    SERIAL: "51680000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "เกิน12ตัน 3เพลา",
    label: "เกิน12ตัน 3เพลา",
    value: "เกิน12ตัน 3เพลา"
  },
  {
    SERIAL: "51700000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF173",
    label: "FF173",
    value: "FF173"
  },
  {
    SERIAL: "51720000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "GY2PSLR (BUILT 4 เพลา)",
    label: "GY2PSLR (BUILT 4 เพลา)",
    value: "GY2PSLR (BUILT 4 เพลา)"
  },
  {
    SERIAL: "51740000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2K (BUILT)",
    label: "FN2K (BUILT)",
    value: "FN2K (BUILT)"
  },
  {
    SERIAL: "51750000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL1J NGV",
    label: "FL1J NGV",
    value: "FL1J NGV"
  },
  {
    SERIAL: "51770000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2P (NGV)",
    label: "FM2P (NGV)",
    value: "FM2P (NGV)"
  },
  {
    SERIAL: "51780000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD2 (BUILT)",
    label: "FD2 (BUILT)",
    value: "FD2 (BUILT)"
  },
  {
    SERIAL: "51790000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU423 (BUS)",
    label: "XZU423 (BUS)",
    value: "XZU423 (BUS)"
  },
  {
    SERIAL: "51800000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2P (BUILT)",
    label: "FN2P (BUILT)",
    value: "FN2P (BUILT)"
  },
  {
    SERIAL: "51810000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "GY2PS (12 WHEEL)",
    label: "GY2PS (12 WHEEL)",
    value: "GY2PS (12 WHEEL)"
  },
  {
    SERIAL: "51820000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB4J",
    label: "FB4J",
    value: "FB4J"
  },
  {
    SERIAL: "51830000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "51850000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC3J (BUILT)",
    label: "FC3J (BUILT)",
    value: "FC3J (BUILT)"
  },
  {
    SERIAL: "51860000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL3H (BUILT)",
    label: "FL3H (BUILT)",
    value: "FL3H (BUILT)"
  },
  {
    SERIAL: "51870000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1AN1D-SHT",
    label: "FM1AN1D-SHT",
    value: "FM1AN1D-SHT"
  },
  {
    SERIAL: "51880000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PN1D-XHT",
    label: "FM2PN1D-XHT",
    value: "FM2PN1D-XHT"
  },
  {
    SERIAL: "51890000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "BUS (Cover 3)",
    label: "BUS (Cover 3)",
    value: "BUS (Cover 3)"
  },
  {
    SERIAL: "51900000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL1A (หัวลาก)",
    label: "FL1A (หัวลาก)",
    value: "FL1A (หัวลาก)"
  },
  {
    SERIAL: "51910000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL8JT1X",
    label: "FL8JT1X",
    value: "FL8JT1X"
  },
  {
    SERIAL: "51920000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR1KZF NGV- BUILT",
    label: "FR1KZF NGV- BUILT",
    value: "FR1KZF NGV- BUILT"
  },
  {
    SERIAL: "51930000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925 (BUILT)",
    label: "KT925 (BUILT)",
    value: "KT925 (BUILT)"
  },
  {
    SERIAL: "51940000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "700 SPLENDER",
    label: "700 SPLENDER",
    value: "700 SPLENDER"
  },
  {
    SERIAL: "51950000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FW1 (BUILT)",
    label: "FW1 (BUILT)",
    value: "FW1 (BUILT)"
  },
  {
    SERIAL: "51960000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1AK1M-SHT",
    label: "FM1AK1M-SHT",
    value: "FM1AK1M-SHT"
  },
  {
    SERIAL: "51970000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "GY2PSLA (12 WHEEL)",
    label: "GY2PSLA (12 WHEEL)",
    value: "GY2PSLA (12 WHEEL)"
  },
  {
    SERIAL: "51980000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PK1B-XHT (หัวลาก)",
    label: "FM2PK1B-XHT (หัวลาก)",
    value: "FM2PK1B-XHT (หัวลาก)"
  },
  {
    SERIAL: "51990000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "SS1EKLA (หัวลาก)",
    label: "SS1EKLA (หัวลาก)",
    value: "SS1EKLA (หัวลาก)"
  },
  {
    SERIAL: "51AA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "4000",
    label: "4000",
    value: "4000"
  },
  {
    SERIAL: "51AB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "6000",
    label: "6000",
    value: "6000"
  },
  {
    SERIAL: "51AC0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "8000",
    label: "8000",
    value: "8000"
  },
  {
    SERIAL: "51AD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "51AE0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "16000",
    label: "16000",
    value: "16000"
  },
  {
    SERIAL: "51AF0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC4J",
    label: "FC4J",
    value: "FC4J"
  },
  {
    SERIAL: "51AG0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "ZM303",
    label: "ZM303",
    value: "ZM303"
  },
  {
    SERIAL: "51AH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2",
    label: "FN2",
    value: "FN2"
  },
  {
    SERIAL: "51AI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC144",
    label: "FC144",
    value: "FC144"
  },
  {
    SERIAL: "51AJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR278",
    label: "FR278",
    value: "FR278"
  },
  {
    SERIAL: "51AK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG1J",
    label: "FG1J",
    value: "FG1J"
  },
  {
    SERIAL: "51AL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FA142",
    label: "FA142",
    value: "FA142"
  },
  {
    SERIAL: "51AM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2TNT",
    label: "FM2TNT",
    value: "FM2TNT"
  },
  {
    SERIAL: "51AN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL3H (BUILT)",
    label: "FL3H (BUILT)",
    value: "FL3H (BUILT)"
  },
  {
    SERIAL: "51AO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MR031BZE",
    label: "MR031BZE",
    value: "MR031BZE"
  },
  {
    SERIAL: "51AP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB1J",
    label: "FB1J",
    value: "FB1J"
  },
  {
    SERIAL: "51AR0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR60",
    label: "FR60",
    value: "FR60"
  },
  {
    SERIAL: "51AS0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD3H (BUILT)",
    label: "FD3H (BUILT)",
    value: "FD3H (BUILT)"
  },
  {
    SERIAL: "51AV0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKAM04H",
    label: "MNKAM04H",
    value: "MNKAM04H"
  },
  {
    SERIAL: "51AW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT920",
    label: "KT920",
    value: "KT920"
  },
  {
    SERIAL: "51AX0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "16000",
    label: "16000",
    value: "16000"
  },
  {
    SERIAL: "51AZ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925",
    label: "KT925",
    value: "KT925"
  },
  {
    SERIAL: "51BB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "WU410R",
    label: "WU410R",
    value: "WU410R"
  },
  {
    SERIAL: "51BC0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "WU420R",
    label: "WU420R",
    value: "WU420R"
  },
  {
    SERIAL: "51BD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FE173",
    label: "FE173",
    value: "FE173"
  },
  {
    SERIAL: "51BE0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KM350",
    label: "KM350",
    value: "KM350"
  },
  {
    SERIAL: "51BF0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KM310",
    label: "KM310",
    value: "KM310"
  },
  {
    SERIAL: "51BG0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "OVER 12000 (BUILT)",
    label: "OVER 12000 (BUILT)",
    value: "OVER 12000 (BUILT)"
  },
  {
    SERIAL: "51BH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKYL22H",
    label: "MNKYL22H",
    value: "MNKYL22H"
  },
  {
    SERIAL: "51BI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT929",
    label: "KT929",
    value: "KT929"
  },
  {
    SERIAL: "51BJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925 (Y.1983)",
    label: "KT925 (Y.1983)",
    value: "KT925 (Y.1983)"
  },
  {
    SERIAL: "51BK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL176",
    label: "FL176",
    value: "FL176"
  },
  {
    SERIAL: "51BL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925",
    label: "KT925",
    value: "KT925"
  },
  {
    SERIAL: "51BM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC45L",
    label: "FC45L",
    value: "FC45L"
  },
  {
    SERIAL: "51BN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "PG1J",
    label: "PG1J",
    value: "PG1J"
  },
  {
    SERIAL: "51BO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MR032BZM",
    label: "MR032BZM",
    value: "MR032BZM"
  },
  {
    SERIAL: "51BP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925 (Y.1988)",
    label: "KT925 (Y.1988)",
    value: "KT925 (Y.1988)"
  },
  {
    SERIAL: "51BR0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PKPA (หัวลาก)",
    label: "FM2PKPA (หัวลาก)",
    value: "FM2PKPA (หัวลาก)"
  },
  {
    SERIAL: "51BS0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "บรรทุกหัวลากเกิน12ตัน",
    label: "บรรทุกหัวลากเกิน12ตัน",
    value: "บรรทุกหัวลากเกิน12ตัน"
  },
  {
    SERIAL: "51BT0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FFUPLA",
    label: "FFUPLA",
    value: "FFUPLA"
  },
  {
    SERIAL: "51BU0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925",
    label: "KT925",
    value: "KT925"
  },
  {
    SERIAL: "51BV0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD2H",
    label: "FD2H",
    value: "FD2H"
  },
  {
    SERIAL: "51BW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KM310",
    label: "KM310",
    value: "KM310"
  },
  {
    SERIAL: "51BX0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB113",
    label: "FB113",
    value: "FB113"
  },
  {
    SERIAL: "51BY0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKYL22H",
    label: "MNKYL22H",
    value: "MNKYL22H"
  },
  {
    SERIAL: "51BZ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2WH",
    label: "FC2WH",
    value: "FC2WH"
  },
  {
    SERIAL: "51CA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "6000",
    label: "6000",
    value: "6000"
  },
  {
    SERIAL: "51CB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3H",
    label: "FM3H",
    value: "FM3H"
  },
  {
    SERIAL: "51CC0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "พ่วง ประกอบ adidas",
    label: "พ่วง ประกอบ adidas",
    value: "พ่วง ประกอบ adidas"
  },
  {
    SERIAL: "51CD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "WU340",
    label: "WU340",
    value: "WU340"
  },
  {
    SERIAL: "51CF0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925 (BUILT)",
    label: "KT925 (BUILT)",
    value: "KT925 (BUILT)"
  },
  {
    SERIAL: "51CG0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KL300",
    label: "KL300",
    value: "KL300"
  },
  {
    SERIAL: "51CH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2P,FR2F (BUILT)",
    label: "FN2P,FR2F (BUILT)",
    value: "FN2P,FR2F (BUILT)"
  },
  {
    SERIAL: "51CJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKUL12H",
    label: "MNKUL12H",
    value: "MNKUL12H"
  },
  {
    SERIAL: "51CK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "F18",
    label: "F18",
    value: "F18"
  },
  {
    SERIAL: "51CL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNK04H (BUILT)",
    label: "MNK04H (BUILT)",
    value: "MNK04H (BUILT)"
  },
  {
    SERIAL: "51CP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FH2K (หัวลาก)",
    label: "FH2K (หัวลาก)",
    value: "FH2K (หัวลาก)"
  },
  {
    SERIAL: "51CQ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD1J (BUILT)",
    label: "FD1J (BUILT)",
    value: "FD1J (BUILT)"
  },
  {
    SERIAL: "51CR0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB4J (BUILT)",
    label: "FB4J (BUILT)",
    value: "FB4J (BUILT)"
  },
  {
    SERIAL: "51CS0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN1",
    label: "FN1",
    value: "FN1"
  },
  {
    SERIAL: "51CT0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU303 4.8",
    label: "XZU303 4.8",
    value: "XZU303 4.8"
  },
  {
    SERIAL: "51CU0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU303 6.8",
    label: "XZU303 6.8",
    value: "XZU303 6.8"
  },
  {
    SERIAL: "51CV0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU343",
    label: "XZU343",
    value: "XZU343"
  },
  {
    SERIAL: "51CW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU413",
    label: "XZU413",
    value: "XZU413"
  },
  {
    SERIAL: "51CX0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU423",
    label: "XZU423",
    value: "XZU423"
  },
  {
    SERIAL: "51CY0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1JKPA (หัวลาก)",
    label: "FM1JKPA (หัวลาก)",
    value: "FM1JKPA (หัวลาก)"
  },
  {
    SERIAL: "51CZ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PNPD",
    label: "FM2PNPD",
    value: "FM2PNPD"
  },
  {
    SERIAL: "51DA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FMPKPM",
    label: "FMPKPM",
    value: "FMPKPM"
  },
  {
    SERIAL: "51DB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN288 (BUILT)",
    label: "FN288 (BUILT)",
    value: "FN288 (BUILT)"
  },
  {
    SERIAL: "51DC0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "PERSIA (BUILT) 12 WHEELS",
    label: "PERSIA (BUILT) 12 WHEELS",
    value: "PERSIA (BUILT) 12 WHEELS"
  },
  {
    SERIAL: "51DD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT725",
    label: "KT725",
    value: "KT725"
  },
  {
    SERIAL: "51DE0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF2 (BUILT)",
    label: "FF2 (BUILT)",
    value: "FF2 (BUILT)"
  },
  {
    SERIAL: "51DF0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT920 (BUILT)",
    label: "KT920 (BUILT)",
    value: "KT920 (BUILT)"
  },
  {
    SERIAL: "51DG0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC9JE",
    label: "FC9JE",
    value: "FC9JE"
  },
  {
    SERIAL: "51DH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8JGLT (หัวลาก6ล้อ)",
    label: "FG8JGLT (หัวลาก6ล้อ)",
    value: "FG8JGLT (หัวลาก6ล้อ)"
  },
  {
    SERIAL: "51DI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8J",
    label: "FG8J",
    value: "FG8J"
  },
  {
    SERIAL: "51DJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL8J",
    label: "FL8J",
    value: "FL8J"
  },
  {
    SERIAL: "51DK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM8J",
    label: "FM8J",
    value: "FM8J"
  },
  {
    SERIAL: "51DL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1A",
    label: "FM1A",
    value: "FM1A"
  },
  {
    SERIAL: "51DM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2P",
    label: "FM2P",
    value: "FM2P"
  },
  {
    SERIAL: "51DN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM8JKKA (หัวลาก)",
    label: "FM8JKKA (หัวลาก)",
    value: "FM8JKKA (หัวลาก)"
  },
  {
    SERIAL: "51DO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1AKKA (หัวลาก)",
    label: "FM1AKKA (หัวลาก)",
    value: "FM1AKKA (หัวลาก)"
  },
  {
    SERIAL: "51DP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1AKLA (หัวลาก)",
    label: "FM1AKLA (หัวลาก)",
    value: "FM1AKLA (หัวลาก)"
  },
  {
    SERIAL: "51DQ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PKLA (หัวลาก)",
    label: "FM2PKLA (หัวลาก)",
    value: "FM2PKLA (หัวลาก)"
  },
  {
    SERIAL: "51DR0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL177LA",
    label: "FL177LA",
    value: "FL177LA"
  },
  {
    SERIAL: "51DS0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT920",
    label: "KT920",
    value: "KT920"
  },
  {
    SERIAL: "51DT0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "GN272A",
    label: "GN272A",
    value: "GN272A"
  },
  {
    SERIAL: "51DU0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM226 (BUILT)",
    label: "FM226 (BUILT)",
    value: "FM226 (BUILT)"
  },
  {
    SERIAL: "51DW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD3H (BUILT)",
    label: "FD3H (BUILT)",
    value: "FD3H (BUILT)"
  },
  {
    SERIAL: "51DX0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "RK8 (BUS)",
    label: "RK8 (BUS)",
    value: "RK8 (BUS)"
  },
  {
    SERIAL: "51DZ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT725 (BUILT)",
    label: "KT725 (BUILT)",
    value: "KT725 (BUILT)"
  },
  {
    SERIAL: "51EC0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8 DRY CONTAINER (BUILT)",
    label: "FG8 DRY CONTAINER (BUILT)",
    value: "FG8 DRY CONTAINER (BUILT)"
  },
  {
    SERIAL: "51ED0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2PW (BUILT)",
    label: "FN2PW (BUILT)",
    value: "FN2PW (BUILT)"
  },
  {
    SERIAL: "51EF0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FE8ARL (BUILT)",
    label: "FE8ARL (BUILT)",
    value: "FE8ARL (BUILT)"
  },
  {
    SERIAL: "51EG0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL177 (BUILT)",
    label: "FL177 (BUILT)",
    value: "FL177 (BUILT)"
  },
  {
    SERIAL: "51EH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL8JN1A",
    label: "FL8JN1A",
    value: "FL8JN1A"
  },
  {
    SERIAL: "51EI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL8JT1A",
    label: "FL8JT1A",
    value: "FL8JT1A"
  },
  {
    SERIAL: "51EJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD1JL (BUILT)",
    label: "FD1JL (BUILT)",
    value: "FD1JL (BUILT)"
  },
  {
    SERIAL: "51EK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PKPA (BUILT)",
    label: "FM2PKPA (BUILT)",
    value: "FM2PKPA (BUILT)"
  },
  {
    SERIAL: "51EL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN1KWA (BUILT)",
    label: "FN1KWA (BUILT)",
    value: "FN1KWA (BUILT)"
  },
  {
    SERIAL: "51EM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL1JNPA",
    label: "FL1JNPA",
    value: "FL1JNPA"
  },
  {
    SERIAL: "51EN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR1FPB",
    label: "FR1FPB",
    value: "FR1FPB"
  },
  {
    SERIAL: "51EO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC176",
    label: "FC176",
    value: "FC176"
  },
  {
    SERIAL: "51EW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "GY2PS1A (12 WHEEL)",
    label: "GY2PS1A (12 WHEEL)",
    value: "GY2PS1A (12 WHEEL)"
  },
  {
    SERIAL: "51GG0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD1HL (BUILT)",
    label: "FD1HL (BUILT)",
    value: "FD1HL (BUILT)"
  },
  {
    SERIAL: "51GH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR289 (BUILT 3เพลา)",
    label: "FR289 (BUILT 3เพลา)",
    value: "FR289 (BUILT 3เพลา)"
  },
  {
    SERIAL: "51GJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT925 (BUILT)",
    label: "KT925 (BUILT)",
    value: "KT925 (BUILT)"
  },
  {
    SERIAL: "51GK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2PWC (BUILT 3 เพลา)",
    label: "FN2PWC (BUILT 3 เพลา)",
    value: "FN2PWC (BUILT 3 เพลา)"
  },
  {
    SERIAL: "51GL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "LY101 (BUILT)",
    label: "LY101 (BUILT)",
    value: "LY101 (BUILT)"
  },
  {
    SERIAL: "51GM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FDH (BUILT)",
    label: "FDH (BUILT)",
    value: "FDH (BUILT)"
  },
  {
    SERIAL: "51GN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM187",
    label: "FM187",
    value: "FM187"
  },
  {
    SERIAL: "51GO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KM777",
    label: "KM777",
    value: "KM777"
  },
  {
    SERIAL: "51GP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2JKB (BUILT)",
    label: "FC2JKB (BUILT)",
    value: "FC2JKB (BUILT)"
  },
  {
    SERIAL: "51GQ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2PWC (BUILT)",
    label: "FN2PWC (BUILT)",
    value: "FN2PWC (BUILT)"
  },
  {
    SERIAL: "51GS0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1ANLD",
    label: "FM1ANLD",
    value: "FM1ANLD"
  },
  {
    SERIAL: "51GU0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "GD2JL",
    label: "GD2JL",
    value: "GD2JL"
  },
  {
    SERIAL: "51GX0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR1KKD",
    label: "FR1KKD",
    value: "FR1KKD"
  },
  {
    SERIAL: "51GY0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FYJEUPA (BUILT)",
    label: "FYJEUPA (BUILT)",
    value: "FYJEUPA (BUILT)"
  },
  {
    SERIAL: "51HA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3M (BUILT)",
    label: "FF3M (BUILT)",
    value: "FF3M (BUILT)"
  },
  {
    SERIAL: "51HB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2WHL (BUILT)",
    label: "FC2WHL (BUILT)",
    value: "FC2WHL (BUILT)"
  },
  {
    SERIAL: "51HD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN338",
    label: "FN338",
    value: "FN338"
  },
  {
    SERIAL: "51HE0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN338 (BUILT) 3 เพลา",
    label: "FN338 (BUILT) 3 เพลา",
    value: "FN338 (BUILT) 3 เพลา"
  },
  {
    SERIAL: "51HH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR1XKD",
    label: "FR1XKD",
    value: "FR1XKD"
  },
  {
    SERIAL: "51HI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN1KWC (BUILT)",
    label: "FN1KWC (BUILT)",
    value: "FN1KWC (BUILT)"
  },
  {
    SERIAL: "51HJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM227",
    label: "FM227",
    value: "FM227"
  },
  {
    SERIAL: "51HK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC3JJ (BUILT) กะบะยาว",
    label: "FC3JJ (BUILT) กะบะยาว",
    value: "FC3JJ (BUILT) กะบะยาว"
  },
  {
    SERIAL: "51HM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD1J (BUILT)",
    label: "FD1J (BUILT)",
    value: "FD1J (BUILT)"
  },
  {
    SERIAL: "51HN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKAH104D",
    label: "MNKAH104D",
    value: "MNKAH104D"
  },
  {
    SERIAL: "51HO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKAJ03H",
    label: "MNKAJ03H",
    value: "MNKAJ03H"
  },
  {
    SERIAL: "51HP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL176",
    label: "FL176",
    value: "FL176"
  },
  {
    SERIAL: "51HQ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FY2P",
    label: "FY2P",
    value: "FY2P"
  },
  {
    SERIAL: "51HS0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU600R - 4W",
    label: "XZU600R - 4W",
    value: "XZU600R - 4W"
  },
  {
    SERIAL: "51HT0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU600R - 6W",
    label: "XZU600R - 6W",
    value: "XZU600R - 6W"
  },
  {
    SERIAL: "51HU0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU650R",
    label: "XZU650R",
    value: "XZU650R"
  },
  {
    SERIAL: "51HV0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU710R",
    label: "XZU710R",
    value: "XZU710R"
  },
  {
    SERIAL: "51HW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZU720R",
    label: "XZU720R",
    value: "XZU720R"
  },
  {
    SERIAL: "51HY0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL187L (BUILT)",
    label: "FL187L (BUILT)",
    value: "FL187L (BUILT)"
  },
  {
    SERIAL: "51HZ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "XZB870R",
    label: "XZB870R",
    value: "XZB870R"
  },
  {
    SERIAL: "51JA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PKMA NGV",
    label: "FM2PKMA NGV",
    value: "FM2PKMA NGV"
  },
  {
    SERIAL: "51JB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT725 (BUILT)",
    label: "KT725 (BUILT)",
    value: "KT725 (BUILT)"
  },
  {
    SERIAL: "51JD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FP289B",
    label: "FP289B",
    value: "FP289B"
  },
  {
    SERIAL: "51JE0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FP289",
    label: "FP289",
    value: "FP289"
  },
  {
    SERIAL: "51JF0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "KT729",
    label: "KT729",
    value: "KT729"
  },
  {
    SERIAL: "51JH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "CL3D NGV",
    label: "CL3D NGV",
    value: "CL3D NGV"
  },
  {
    SERIAL: "51JI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM227 (RENOVATE)",
    label: "FM227 (RENOVATE)",
    value: "FM227 (RENOVATE)"
  },
  {
    SERIAL: "51JK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PNLD",
    label: "FM2PNLD",
    value: "FM2PNLD"
  },
  {
    SERIAL: "51JL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FH2K (BUILT)",
    label: "FH2K (BUILT)",
    value: "FH2K (BUILT)"
  },
  {
    SERIAL: "51JM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3 (BUILT)",
    label: "FM3 (BUILT)",
    value: "FM3 (BUILT)"
  },
  {
    SERIAL: "51JN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FYH77SDM",
    label: "FYH77SDM",
    value: "FYH77SDM"
  },
  {
    SERIAL: "51JO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL176 (BUILT)",
    label: "FL176 (BUILT)",
    value: "FL176 (BUILT)"
  },
  {
    SERIAL: "51JP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR1KZD",
    label: "FR1KZD",
    value: "FR1KZD"
  },
  {
    SERIAL: "51JQ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HP (BUILT)",
    label: "FF3HP (BUILT)",
    value: "FF3HP (BUILT)"
  },
  {
    SERIAL: "51JR0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "SS1FJC (BUILT)",
    label: "SS1FJC (BUILT)",
    value: "SS1FJC (BUILT)"
  },
  {
    SERIAL: "51JT0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1ANKD",
    label: "FM1ANKD",
    value: "FM1ANKD"
  },
  {
    SERIAL: "51JU0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1AKKM",
    label: "FM1AKKM",
    value: "FM1AKKM"
  },
  {
    SERIAL: "51JV0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1JNKD NGV",
    label: "FM1JNKD NGV",
    value: "FM1JNKD NGV"
  },
  {
    SERIAL: "51JW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2PNMD NGV",
    label: "FM2PNMD NGV",
    value: "FM2PNMD NGV"
  },
  {
    SERIAL: "51JX0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL1JTKA NGV",
    label: "FL1JTKA NGV",
    value: "FL1JTKA NGV"
  },
  {
    SERIAL: "51JY0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FR1KZD (BUILT)",
    label: "FR1KZD (BUILT)",
    value: "FR1KZD (BUILT)"
  },
  {
    SERIAL: "51JZ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKRCL2H",
    label: "MNKRCL2H",
    value: "MNKRCL2H"
  },
  {
    SERIAL: "51KA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM3H (BUILT)",
    label: "FM3H (BUILT)",
    value: "FM3H (BUILT)"
  },
  {
    SERIAL: "51KB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FE82B",
    label: "FE82B",
    value: "FE82B"
  },
  {
    SERIAL: "51KC0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKCCLOH",
    label: "MNKCCLOH",
    value: "MNKCCLOH"
  },
  {
    SERIAL: "51KD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "BUS (BUILT)",
    label: "BUS (BUILT)",
    value: "BUS (BUILT)"
  },
  {
    SERIAL: "51KE0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM187LA (BUILT)",
    label: "FM187LA (BUILT)",
    value: "FM187LA (BUILT)"
  },
  {
    SERIAL: "51KG0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKACK4H",
    label: "MNKACK4H",
    value: "MNKACK4H"
  },
  {
    SERIAL: "51KH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HG (UNIQUE)",
    label: "FF3HG (UNIQUE)",
    value: "FF3HG (UNIQUE)"
  },
  {
    SERIAL: "51KI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1J (UNIQUE)",
    label: "FM1J (UNIQUE)",
    value: "FM1J (UNIQUE)"
  },
  {
    SERIAL: "51KJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1J (UNIQUE)",
    label: "FM1J (UNIQUE)",
    value: "FM1J (UNIQUE)"
  },
  {
    SERIAL: "51KK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC3J (UNIQUE)",
    label: "FC3J (UNIQUE)",
    value: "FC3J (UNIQUE)"
  },
  {
    SERIAL: "51KL0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM2P (UNIQUE)",
    label: "FM2P (UNIQUE)",
    value: "FM2P (UNIQUE)"
  },
  {
    SERIAL: "51KM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "RK1 (BUILT)",
    label: "RK1 (BUILT)",
    value: "RK1 (BUILT)"
  },
  {
    SERIAL: "51KN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL1JY (BUILT)",
    label: "FL1JY (BUILT)",
    value: "FL1JY (BUILT)"
  },
  {
    SERIAL: "51KO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD175",
    label: "FD175",
    value: "FD175"
  },
  {
    SERIAL: "51KP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG1J (BUILT)",
    label: "FG1J (BUILT)",
    value: "FG1J (BUILT)"
  },
  {
    SERIAL: "51KQ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2PWG-12 WHEELS (BUILT)",
    label: "FN2PWG-12 WHEELS (BUILT)",
    value: "FN2PWG-12 WHEELS (BUILT)"
  },
  {
    SERIAL: "51KR0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FF3HP",
    label: "FF3HP",
    value: "FF3HP"
  },
  {
    SERIAL: "51KS0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2J",
    label: "FC2J",
    value: "FC2J"
  },
  {
    SERIAL: "51KT0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "ZM500",
    label: "ZM500",
    value: "ZM500"
  },
  {
    SERIAL: "51KU0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC2J (BUILT)",
    label: "FC2J (BUILT)",
    value: "FC2J (BUILT)"
  },
  {
    SERIAL: "51KV0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC4J NGV",
    label: "FC4J NGV",
    value: "FC4J NGV"
  },
  {
    SERIAL: "51KW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC9JJ",
    label: "FC9JJ",
    value: "FC9JJ"
  },
  {
    SERIAL: "51KX0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC9JJ RADIAL TUBE",
    label: "FC9JJ RADIAL TUBE",
    value: "FC9JJ RADIAL TUBE"
  },
  {
    SERIAL: "51KY0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC9JL",
    label: "FC9JL",
    value: "FC9JL"
  },
  {
    SERIAL: "51KZ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FC9JL RADIAL TUBE",
    label: "FC9JL RADIAL TUBE",
    value: "FC9JL RADIAL TUBE"
  },
  {
    SERIAL: "51LA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG1JPKA NGV CAR CARRIER",
    label: "FG1JPKA NGV CAR CARRIER",
    value: "FG1JPKA NGV CAR CARRIER"
  },
  {
    SERIAL: "51LB0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8JJL",
    label: "FG8JJL",
    value: "FG8JJL"
  },
  {
    SERIAL: "51LC0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8JMLA",
    label: "FG8JMLA",
    value: "FG8JMLA"
  },
  {
    SERIAL: "51LD0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8JPLA",
    label: "FG8JPLA",
    value: "FG8JPLA"
  },
  {
    SERIAL: "51LE0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FG8JRLA",
    label: "FG8JRLA",
    value: "FG8JRLA"
  },
  {
    SERIAL: "51LF0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "PM2KLA (หัวลาก)",
    label: "PM2KLA (หัวลาก)",
    value: "PM2KLA (หัวลาก)"
  },
  {
    SERIAL: "51LH0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FB112 (BUILT)",
    label: "FB112 (BUILT)",
    value: "FB112 (BUILT)"
  },
  {
    SERIAL: "51LI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FD3HKA (BUILT)",
    label: "FD3HKA (BUILT)",
    value: "FD3HKA (BUILT)"
  },
  {
    SERIAL: "51LJ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM227LD (BUILT)",
    label: "FM227LD (BUILT)",
    value: "FM227LD (BUILT)"
  },
  {
    SERIAL: "51LK0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2KWC (BUILT)",
    label: "FN2KWC (BUILT)",
    value: "FN2KWC (BUILT)"
  },
  {
    SERIAL: "51LM0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FM1AK1B-SHT (หัวลาก)",
    label: "FM1AK1B-SHT (หัวลาก)",
    value: "FM1AK1B-SHT (หัวลาก)"
  },
  {
    SERIAL: "51LN0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "12000 (Cover 3)",
    label: "12000 (Cover 3)",
    value: "12000 (Cover 3)"
  },
  {
    SERIAL: "51LO0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FL1AS1A-SHT",
    label: "FL1AS1A-SHT",
    value: "FL1AS1A-SHT"
  },
  {
    SERIAL: "51LP0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "51LQ0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "FN2PYH (BUILT)",
    label: "FN2PYH (BUILT)",
    value: "FN2PYH (BUILT)"
  },
  {
    SERIAL: "51LW0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "OVER 21000",
    label: "OVER 21000",
    value: "OVER 21000"
  },
  {
    SERIAL: "51OI0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKGY",
    label: "MNKGY",
    value: "MNKGY"
  },
  {
    SERIAL: "51PA0000026",
    MAKE_NAME: "HINO",
    MODEL_NAME: "MNKACL1H",
    label: "MNKACL1H",
    value: "MNKACL1H"
  },
  {
    SERIAL: "52000000026",
    MAKE_NAME: "DODGE",
    MODEL_NAME: "DODGE",
    label: "DODGE",
    value: "DODGE"
  },
  {
    SERIAL: "52010000026",
    MAKE_NAME: "DODGE",
    MODEL_NAME: "H96G",
    label: "H96G",
    value: "H96G"
  },
  {
    SERIAL: "52020000026",
    MAKE_NAME: "DODGE",
    MODEL_NAME: "CHALLENGER 3.6",
    label: "CHALLENGER 3.6",
    value: "CHALLENGER 3.6"
  },
  {
    SERIAL: "52030000026",
    MAKE_NAME: "DODGE",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "52040000026",
    MAKE_NAME: "DODGE",
    MODEL_NAME: "DURANGO 2.3",
    label: "DURANGO 2.3",
    value: "DURANGO 2.3"
  },
  {
    SERIAL: "52050000026",
    MAKE_NAME: "DODGE",
    MODEL_NAME: "CHARGER SXT 3.6",
    label: "CHARGER SXT 3.6",
    value: "CHARGER SXT 3.6"
  },
  {
    SERIAL: "52090000026",
    MAKE_NAME: "DODGE",
    MODEL_NAME: "CHALLENGER HELLCAT 6.2",
    label: "CHALLENGER HELLCAT 6.2",
    value: "CHALLENGER HELLCAT 6.2"
  },
  {
    SERIAL: "53000000026",
    MAKE_NAME: "DAF",
    MODEL_NAME: "DAF",
    label: "DAF",
    value: "DAF"
  },
  {
    SERIAL: "53010000026",
    MAKE_NAME: "DAF",
    MODEL_NAME: "FTH2300HT",
    label: "FTH2300HT",
    value: "FTH2300HT"
  },
  {
    SERIAL: "53020000026",
    MAKE_NAME: "DAF",
    MODEL_NAME: "FTS2300HT",
    label: "FTS2300HT",
    value: "FTS2300HT"
  },
  {
    SERIAL: "54000000026",
    MAKE_NAME: "CHEETAH",
    MODEL_NAME: "CHEETAH",
    label: "CHEETAH",
    value: "CHEETAH"
  },
  {
    SERIAL: "54010000026",
    MAKE_NAME: "CHEETAH",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "55000000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "รถพ่วง",
    label: "รถพ่วง",
    value: "รถพ่วง"
  },
  {
    SERIAL: "55030000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงดั้ม",
    label: "พ่วงดั้ม",
    value: "พ่วงดั้ม"
  },
  {
    SERIAL: "55040000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงกะบะสั้น",
    label: "พ่วงกะบะสั้น",
    value: "พ่วงกะบะสั้น"
  },
  {
    SERIAL: "55050000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงกะบะ",
    label: "พ่วงกะบะ",
    value: "พ่วงกะบะ"
  },
  {
    SERIAL: "55060000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ",
    label: "พ่วงพื้นเรียบ",
    value: "พ่วงพื้นเรียบ"
  },
  {
    SERIAL: "55070000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท้งค์สั้น",
    label: "พ่วงแท้งค์สั้น",
    value: "พ่วงแท้งค์สั้น"
  },
  {
    SERIAL: "55080000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท้งค์ยาว",
    label: "พ่วงแท้งค์ยาว",
    value: "พ่วงแท้งค์ยาว"
  },
  {
    SERIAL: "55100000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงตู้บรรทุก",
    label: "พ่วงตู้บรรทุก",
    value: "พ่วงตู้บรรทุก"
  },
  {
    SERIAL: "55110000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงกะบะ",
    label: "พ่วงกะบะ",
    value: "พ่วงกะบะ"
  },
  {
    SERIAL: "55120000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์",
    label: "พ่วงแท็งค์",
    value: "พ่วงแท็งค์"
  },
  {
    SERIAL: "55130000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท้งค์น้ำมัน",
    label: "พ่วงแท้งค์น้ำมัน",
    value: "พ่วงแท้งค์น้ำมัน"
  },
  {
    SERIAL: "55140000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงกะบะยาว 3 เพลา",
    label: "พ่วงกะบะยาว 3 เพลา",
    value: "พ่วงกะบะยาว 3 เพลา"
  },
  {
    SERIAL: "55150000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงกะบะ3เพลา (BUILT)",
    label: "พ่วงกะบะ3เพลา (BUILT)",
    value: "พ่วงกะบะ3เพลา (BUILT)"
  },
  {
    SERIAL: "55160000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงกะบะ (BUILT)",
    label: "พ่วงกะบะ (BUILT)",
    value: "พ่วงกะบะ (BUILT)"
  },
  {
    SERIAL: "55170000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "TRANSPORT CAR",
    label: "TRANSPORT CAR",
    value: "TRANSPORT CAR"
  },
  {
    SERIAL: "55190000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงกะบะ (Cover 3)",
    label: "พ่วงกะบะ (Cover 3)",
    value: "พ่วงกะบะ (Cover 3)"
  },
  {
    SERIAL: "55200000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "HYDROGEN TUBE TRAILER",
    label: "HYDROGEN TUBE TRAILER",
    value: "HYDROGEN TUBE TRAILER"
  },
  {
    SERIAL: "55210000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "HYDROGEN TUBE TRAILER",
    label: "HYDROGEN TUBE TRAILER",
    value: "HYDROGEN TUBE TRAILER"
  },
  {
    SERIAL: "55220000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "HYDROGEN TUBE TRAILER",
    label: "HYDROGEN TUBE TRAILER",
    value: "HYDROGEN TUBE TRAILER"
  },
  {
    SERIAL: "55230000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "HYDROGEN TUBE TRAILER",
    label: "HYDROGEN TUBE TRAILER",
    value: "HYDROGEN TUBE TRAILER"
  },
  {
    SERIAL: "55240000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "HYDROGEN TUBE TRAILER",
    label: "HYDROGEN TUBE TRAILER",
    value: "HYDROGEN TUBE TRAILER"
  },
  {
    SERIAL: "55250000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "MOBILE PURGING",
    label: "MOBILE PURGING",
    value: "MOBILE PURGING"
  },
  {
    SERIAL: "55260000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "LOW BED TRAILER/TR 111",
    label: "LOW BED TRAILER/TR 111",
    value: "LOW BED TRAILER/TR 111"
  },
  {
    SERIAL: "55270000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "SEMI TRAILER TANKER/MVE",
    label: "SEMI TRAILER TANKER/MVE",
    value: "SEMI TRAILER TANKER/MVE"
  },
  {
    SERIAL: "55280000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "SEMI TRAILER TANKER",
    label: "SEMI TRAILER TANKER",
    value: "SEMI TRAILER TANKER"
  },
  {
    SERIAL: "55290000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "HYDROGEN TUBE",
    label: "HYDROGEN TUBE",
    value: "HYDROGEN TUBE"
  },
  {
    SERIAL: "55300000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "MINI TRAILER",
    label: "MINI TRAILER",
    value: "MINI TRAILER"
  },
  {
    SERIAL: "55310000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "PANUS ASSEMBLY",
    label: "PANUS ASSEMBLY",
    value: "PANUS ASSEMBLY"
  },
  {
    SERIAL: "55320000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "CRYOTECH SEMI-TRAILER",
    label: "CRYOTECH SEMI-TRAILER",
    value: "CRYOTECH SEMI-TRAILER"
  },
  {
    SERIAL: "55330000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "TRAILER TL33000",
    label: "TRAILER TL33000",
    value: "TRAILER TL33000"
  },
  {
    SERIAL: "55340000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "SEMI TRAILER",
    label: "SEMI TRAILER",
    value: "SEMI TRAILER"
  },
  {
    SERIAL: "55350000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "SEMI TRAILER",
    label: "SEMI TRAILER",
    value: "SEMI TRAILER"
  },
  {
    SERIAL: "55360000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงดั้ม (BUILT)",
    label: "พ่วงดั้ม (BUILT)",
    value: "พ่วงดั้ม (BUILT)"
  },
  {
    SERIAL: "55370000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงดั้ม 3 เพลา",
    label: "พ่วงดั้ม 3 เพลา",
    value: "พ่วงดั้ม 3 เพลา"
  },
  {
    SERIAL: "55380000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "กะบะพ่วง (BUILT)",
    label: "กะบะพ่วง (BUILT)",
    value: "กะบะพ่วง (BUILT)"
  },
  {
    SERIAL: "55390000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย",
    label: "พ่วงแท็งค์ วัตถุอันตราย",
    value: "พ่วงแท็งค์ วัตถุอันตราย"
  },
  {
    SERIAL: "55400000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย",
    label: "พ่วงแท็งค์ วัตถุอันตราย",
    value: "พ่วงแท็งค์ วัตถุอันตราย"
  },
  {
    SERIAL: "55410000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย 12 ตัน",
    label: "พ่วงแท็งค์ วัตถุอันตราย 12 ตัน",
    value: "พ่วงแท็งค์ วัตถุอันตราย 12 ตัน"
  },
  {
    SERIAL: "55420000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ (Cover 3)",
    label: "พ่วงแท็งค์ (Cover 3)",
    value: "พ่วงแท็งค์ (Cover 3)"
  },
  {
    SERIAL: "55430000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงดั้ม SC-301 (BUILT)",
    label: "พ่วงดั้ม SC-301 (BUILT)",
    value: "พ่วงดั้ม SC-301 (BUILT)"
  },
  {
    SERIAL: "55440000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ (BUILT)",
    label: "พ่วงพื้นเรียบ (BUILT)",
    value: "พ่วงพื้นเรียบ (BUILT)"
  },
  {
    SERIAL: "55450000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "SEMI TRAILER",
    label: "SEMI TRAILER",
    value: "SEMI TRAILER"
  },
  {
    SERIAL: "55460000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "FLAT BED",
    label: "FLAT BED",
    value: "FLAT BED"
  },
  {
    SERIAL: "55470000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "FLAT BED",
    label: "FLAT BED",
    value: "FLAT BED"
  },
  {
    SERIAL: "55480000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "FLAT BED",
    label: "FLAT BED",
    value: "FLAT BED"
  },
  {
    SERIAL: "55490000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "LOW BED",
    label: "LOW BED",
    value: "LOW BED"
  },
  {
    SERIAL: "55500000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ 40MPP",
    label: "พ่วงพื้นเรียบ 40MPP",
    value: "พ่วงพื้นเรียบ 40MPP"
  },
  {
    SERIAL: "55510000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ (UNIQUE)",
    label: "พ่วงพื้นเรียบ (UNIQUE)",
    value: "พ่วงพื้นเรียบ (UNIQUE)"
  },
  {
    SERIAL: "55520000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)",
    label: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)",
    value: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)"
  },
  {
    SERIAL: "55530000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)",
    label: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)",
    value: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)"
  },
  {
    SERIAL: "55540000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)",
    label: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)",
    value: "พ่วงพื้นเรียบ 3 เพลา (UNIQUE)"
  },
  {
    SERIAL: "55550000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "TANK GAS WITH PUMP AND FLOW METER (LOW)",
    label: "TANK GAS WITH PUMP AND FLOW METER (LOW)",
    value: "TANK GAS WITH PUMP AND FLOW METER (LOW)"
  },
  {
    SERIAL: "55560000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย 35 ตัน",
    label: "พ่วงแท็งค์ วัตถุอันตราย 35 ตัน",
    value: "พ่วงแท็งค์ วัตถุอันตราย 35 ตัน"
  },
  {
    SERIAL: "55570000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย 35 ตัน",
    label: "พ่วงแท็งค์ วัตถุอันตราย 35 ตัน",
    value: "พ่วงแท็งค์ วัตถุอันตราย 35 ตัน"
  },
  {
    SERIAL: "55580000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "TANK GAS WITH PUMP AND FLOW METER (HIGH )",
    label: "TANK GAS WITH PUMP AND FLOW METER (HIGH )",
    value: "TANK GAS WITH PUMP AND FLOW METER (HIGH )"
  },
  {
    SERIAL: "55600000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบท้ายไฮโดรลิค (BUILT)",
    label: "พ่วงพื้นเรียบท้ายไฮโดรลิค (BUILT)",
    value: "พ่วงพื้นเรียบท้ายไฮโดรลิค (BUILT)"
  },
  {
    SERIAL: "55610000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "UTILITIES BOX TRAILER",
    label: "UTILITIES BOX TRAILER",
    value: "UTILITIES BOX TRAILER"
  },
  {
    SERIAL: "55620000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "TANK GAS",
    label: "TANK GAS",
    value: "TANK GAS"
  },
  {
    SERIAL: "55630000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงตู้บรรทุก SMALL",
    label: "พ่วงตู้บรรทุก SMALL",
    value: "พ่วงตู้บรรทุก SMALL"
  },
  {
    SERIAL: "55640000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ 2 เพลา",
    label: "พ่วงพื้นเรียบ 2 เพลา",
    value: "พ่วงพื้นเรียบ 2 เพลา"
  },
  {
    SERIAL: "55700000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงพื้นเรียบ",
    label: "พ่วงพื้นเรียบ",
    value: "พ่วงพื้นเรียบ"
  },
  {
    SERIAL: "55800000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย (Y.2016)",
    label: "พ่วงแท็งค์ วัตถุอันตราย (Y.2016)",
    value: "พ่วงแท็งค์ วัตถุอันตราย (Y.2016)"
  },
  {
    SERIAL: "55810000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย",
    label: "พ่วงแท็งค์ วัตถุอันตราย",
    value: "พ่วงแท็งค์ วัตถุอันตราย"
  },
  {
    SERIAL: "55890000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์ วัตถุอันตราย-Built",
    label: "พ่วงแท็งค์ วัตถุอันตราย-Built",
    value: "พ่วงแท็งค์ วัตถุอันตราย-Built"
  },
  {
    SERIAL: "55900000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์+PUMP+FLOW-Built",
    label: "พ่วงแท็งค์+PUMP+FLOW-Built",
    value: "พ่วงแท็งค์+PUMP+FLOW-Built"
  },
  {
    SERIAL: "55910000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "พ่วงแท็งค์+PUMP+FLOW-Built",
    label: "พ่วงแท็งค์+PUMP+FLOW-Built",
    value: "พ่วงแท็งค์+PUMP+FLOW-Built"
  },
  {
    SERIAL: "55920000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "FLAT BED ROBET 8 AXLES",
    label: "FLAT BED ROBET 8 AXLES",
    value: "FLAT BED ROBET 8 AXLES"
  },
  {
    SERIAL: "55930000026",
    MAKE_NAME: "รถพ่วง",
    MODEL_NAME: "SEMI TRAILER",
    label: "SEMI TRAILER",
    value: "SEMI TRAILER"
  },
  {
    SERIAL: "56000000026",
    MAKE_NAME: "AGRICUTURE TRUCK",
    MODEL_NAME: "พ่วงดั๊ม",
    label: "พ่วงดั๊ม",
    value: "พ่วงดั๊ม"
  },
  {
    SERIAL: "56030000026",
    MAKE_NAME: "AGRICUTURE TRUCK",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "58000000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YAMAHA",
    label: "YAMAHA",
    value: "YAMAHA"
  },
  {
    SERIAL: "58010000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SPARK",
    label: "SPARK",
    value: "SPARK"
  },
  {
    SERIAL: "58020000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SPARK",
    label: "SPARK",
    value: "SPARK"
  },
  {
    SERIAL: "58030000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MIO",
    label: "MIO",
    value: "MIO"
  },
  {
    SERIAL: "58040000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "NOUVO",
    label: "NOUVO",
    value: "NOUVO"
  },
  {
    SERIAL: "58050000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD STAR MIDNIGHT SILVERADO",
    label: "ROAD STAR MIDNIGHT SILVERADO",
    value: "ROAD STAR MIDNIGHT SILVERADO"
  },
  {
    SERIAL: "58060000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R15",
    label: "YZF-R15",
    value: "YZF-R15"
  },
  {
    SERIAL: "58070000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "GMAX",
    label: "GMAX",
    value: "GMAX"
  },
  {
    SERIAL: "58080000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1",
    label: "YZF-R1",
    value: "YZF-R1"
  },
  {
    SERIAL: "58090000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF R1",
    label: "YZF R1",
    value: "YZF R1"
  },
  {
    SERIAL: "58100000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FINO",
    label: "FINO",
    value: "FINO"
  },
  {
    SERIAL: "58110000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "VMAX",
    label: "VMAX",
    value: "VMAX"
  },
  {
    SERIAL: "58120000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD STAR WARRIOR",
    label: "ROAD STAR WARRIOR",
    value: "ROAD STAR WARRIOR"
  },
  {
    SERIAL: "58130000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROYAL STAR VENTURE",
    label: "ROYAL STAR VENTURE",
    value: "ROYAL STAR VENTURE"
  },
  {
    SERIAL: "58140000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROYAL STAR TOUR DELUXE",
    label: "ROYAL STAR TOUR DELUXE",
    value: "ROYAL STAR TOUR DELUXE"
  },
  {
    SERIAL: "58150000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "RAIDER BASE",
    label: "RAIDER BASE",
    value: "RAIDER BASE"
  },
  {
    SERIAL: "58160000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FJR1300",
    label: "FJR1300",
    value: "FJR1300"
  },
  {
    SERIAL: "58170000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "RAIDER S",
    label: "RAIDER S",
    value: "RAIDER S"
  },
  {
    SERIAL: "58180000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD STAR S",
    label: "ROAD STAR S",
    value: "ROAD STAR S"
  },
  {
    SERIAL: "58190000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD STAR BASE",
    label: "ROAD STAR BASE",
    value: "ROAD STAR BASE"
  },
  {
    SERIAL: "58200000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD LINER S",
    label: "ROAD LINER S",
    value: "ROAD LINER S"
  },
  {
    SERIAL: "58210000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD LINER MIDNIGHT",
    label: "ROAD LINER MIDNIGHT",
    value: "ROAD LINER MIDNIGHT"
  },
  {
    SERIAL: "58220000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD STAR SILVERADO",
    label: "ROAD STAR SILVERADO",
    value: "ROAD STAR SILVERADO"
  },
  {
    SERIAL: "58230000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "STRATO LINER S",
    label: "STRATO LINER S",
    value: "STRATO LINER S"
  },
  {
    SERIAL: "58240000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "VSTAR 1300",
    label: "VSTAR 1300",
    value: "VSTAR 1300"
  },
  {
    SERIAL: "58250000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "VSTAR 1300 TOURER",
    label: "VSTAR 1300 TOURER",
    value: "VSTAR 1300 TOURER"
  },
  {
    SERIAL: "58260000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "VSTAR 1100 CUSTOM",
    label: "VSTAR 1100 CUSTOM",
    value: "VSTAR 1100 CUSTOM"
  },
  {
    SERIAL: "58270000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "VSTAR 1100 CLASSIC",
    label: "VSTAR 1100 CLASSIC",
    value: "VSTAR 1100 CLASSIC"
  },
  {
    SERIAL: "58280000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "VSTAR 1100 SILVERADO",
    label: "VSTAR 1100 SILVERADO",
    value: "VSTAR 1100 SILVERADO"
  },
  {
    SERIAL: "58290000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "VSTAR 950",
    label: "VSTAR 950",
    value: "VSTAR 950"
  },
  {
    SERIAL: "58300000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD STAR WARRIOR BASE",
    label: "ROAD STAR WARRIOR BASE",
    value: "ROAD STAR WARRIOR BASE"
  },
  {
    SERIAL: "58310000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "ROAD STAR WARRIOR MIDNIGHT",
    label: "ROAD STAR WARRIOR MIDNIGHT",
    value: "ROAD STAR WARRIOR MIDNIGHT"
  },
  {
    SERIAL: "58320000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "V MAX BASE",
    label: "V MAX BASE",
    value: "V MAX BASE"
  },
  {
    SERIAL: "58330000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FZ1",
    label: "FZ1",
    value: "FZ1"
  },
  {
    SERIAL: "58340000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FZ6",
    label: "FZ6",
    value: "FZ6"
  },
  {
    SERIAL: "58350000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FJR 1300 AE",
    label: "FJR 1300 AE",
    value: "FJR 1300 AE"
  },
  {
    SERIAL: "58360000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MIDNIGHT STAR",
    label: "MIDNIGHT STAR",
    value: "MIDNIGHT STAR"
  },
  {
    SERIAL: "58370000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R6",
    label: "YZF-R6",
    value: "YZF-R6"
  },
  {
    SERIAL: "58380000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YTS500",
    label: "YTS500",
    value: "YTS500"
  },
  {
    SERIAL: "58390000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX XP500",
    label: "TMAX XP500",
    value: "TMAX XP500"
  },
  {
    SERIAL: "58400000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "NOUVO",
    label: "NOUVO",
    value: "NOUVO"
  },
  {
    SERIAL: "58410000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX 500",
    label: "TMAX 500",
    value: "TMAX 500"
  },
  {
    SERIAL: "58420000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SPARK NANO 135",
    label: "SPARK NANO 135",
    value: "SPARK NANO 135"
  },
  {
    SERIAL: "58430000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FZ1 SC",
    label: "FZ1 SC",
    value: "FZ1 SC"
  },
  {
    SERIAL: "58440000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "WARRIOR 1700 CC.",
    label: "WARRIOR 1700 CC.",
    value: "WARRIOR 1700 CC."
  },
  {
    SERIAL: "58450000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SUPER TENERE XT1200Z",
    label: "SUPER TENERE XT1200Z",
    value: "SUPER TENERE XT1200Z"
  },
  {
    SERIAL: "58460000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FZ8",
    label: "FZ8",
    value: "FZ8"
  },
  {
    SERIAL: "58470000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1",
    label: "YZF-R1",
    value: "YZF-R1"
  },
  {
    SERIAL: "58480000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SUPER TENERE",
    label: "SUPER TENERE",
    value: "SUPER TENERE"
  },
  {
    SERIAL: "58490000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XJR 1300",
    label: "XJR 1300",
    value: "XJR 1300"
  },
  {
    SERIAL: "58500000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SPARK 115i",
    label: "SPARK 115i",
    value: "SPARK 115i"
  },
  {
    SERIAL: "58510000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "BOLT",
    label: "BOLT",
    value: "BOLT"
  },
  {
    SERIAL: "58520000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX 530",
    label: "TMAX 530",
    value: "TMAX 530"
  },
  {
    SERIAL: "58530000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FZ09",
    label: "FZ09",
    value: "FZ09"
  },
  {
    SERIAL: "58540000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XVS950CU (BOLT)",
    label: "XVS950CU (BOLT)",
    value: "XVS950CU (BOLT)"
  },
  {
    SERIAL: "58550000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1 MODEL YEAR 2015",
    label: "YZF-R1 MODEL YEAR 2015",
    value: "YZF-R1 MODEL YEAR 2015"
  },
  {
    SERIAL: "58560000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1",
    label: "YZF-R1",
    value: "YZF-R1"
  },
  {
    SERIAL: "58570000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "BOLT-R",
    label: "BOLT-R",
    value: "BOLT-R"
  },
  {
    SERIAL: "58580000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FX09",
    label: "FX09",
    value: "FX09"
  },
  {
    SERIAL: "58590000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX 59",
    label: "TMAX 59",
    value: "TMAX 59"
  },
  {
    SERIAL: "58600000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SUPER TENERE XT1200Z FULL OPTION",
    label: "SUPER TENERE XT1200Z FULL OPTION",
    value: "SUPER TENERE XT1200Z FULL OPTION"
  },
  {
    SERIAL: "58610000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FJR 1300 AC",
    label: "FJR 1300 AC",
    value: "FJR 1300 AC"
  },
  {
    SERIAL: "58620000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TRICITY 3 Wheels",
    label: "TRICITY 3 Wheels",
    value: "TRICITY 3 Wheels"
  },
  {
    SERIAL: "58630000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SUPER TERENE XT1200Z",
    label: "SUPER TERENE XT1200Z",
    value: "SUPER TERENE XT1200Z"
  },
  {
    SERIAL: "58640000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "GT125",
    label: "GT125",
    value: "GT125"
  },
  {
    SERIAL: "58650000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1 M (2015)",
    label: "YZF-R1 M (2015)",
    value: "YZF-R1 M (2015)"
  },
  {
    SERIAL: "58660000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FJ-09",
    label: "FJ-09",
    value: "FJ-09"
  },
  {
    SERIAL: "58670000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "1100",
    label: "1100",
    value: "1100"
  },
  {
    SERIAL: "58680000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1 (2017)",
    label: "YZF-R1 (2017)",
    value: "YZF-R1 (2017)"
  },
  {
    SERIAL: "58690000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R3",
    label: "YZF-R3",
    value: "YZF-R3"
  },
  {
    SERIAL: "58700000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "135 CC",
    label: "135 CC",
    value: "135 CC"
  },
  {
    SERIAL: "58710000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MIO 115",
    label: "MIO 115",
    value: "MIO 115"
  },
  {
    SERIAL: "58720000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "M-SLAZ",
    label: "M-SLAZ",
    value: "M-SLAZ"
  },
  {
    SERIAL: "58750000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FINN",
    label: "FINN",
    value: "FINN"
  },
  {
    SERIAL: "58760000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MT-03",
    label: "MT-03",
    value: "MT-03"
  },
  {
    SERIAL: "58770000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "EXCITER 150",
    label: "EXCITER 150",
    value: "EXCITER 150"
  },
  {
    SERIAL: "58780000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FINO SPORT 115",
    label: "FINO SPORT 115",
    value: "FINO SPORT 115"
  },
  {
    SERIAL: "58790000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MIO 125",
    label: "MIO 125",
    value: "MIO 125"
  },
  {
    SERIAL: "58800000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FREEGO 125",
    label: "FREEGO 125",
    value: "FREEGO 125"
  },
  {
    SERIAL: "58810000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MT-15",
    label: "MT-15",
    value: "MT-15"
  },
  {
    SERIAL: "58820000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FIORE 115",
    label: "FIORE 115",
    value: "FIORE 115"
  },
  {
    SERIAL: "58AA0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX SX",
    label: "TMAX SX",
    value: "TMAX SX"
  },
  {
    SERIAL: "58AB0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "BOLT-R",
    label: "BOLT-R",
    value: "BOLT-R"
  },
  {
    SERIAL: "58AC0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FZ-09 RAPID RED",
    label: "FZ-09 RAPID RED",
    value: "FZ-09 RAPID RED"
  },
  {
    SERIAL: "58AD0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX 530 MATTE BROWN",
    label: "TMAX 530 MATTE BROWN",
    value: "TMAX 530 MATTE BROWN"
  },
  {
    SERIAL: "58AE0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SUPER TENERE",
    label: "SUPER TENERE",
    value: "SUPER TENERE"
  },
  {
    SERIAL: "58AF0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FJR1300",
    label: "FJR1300",
    value: "FJR1300"
  },
  {
    SERIAL: "58AG0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "NMAX",
    label: "NMAX",
    value: "NMAX"
  },
  {
    SERIAL: "58AH0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1",
    label: "YZF-R1",
    value: "YZF-R1"
  },
  {
    SERIAL: "58AI0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1 M",
    label: "YZF-R1 M",
    value: "YZF-R1 M"
  },
  {
    SERIAL: "58AJ0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XT660 TENERE - IMPORT",
    label: "XT660 TENERE - IMPORT",
    value: "XT660 TENERE - IMPORT"
  },
  {
    SERIAL: "58AK0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MT-07",
    label: "MT-07",
    value: "MT-07"
  },
  {
    SERIAL: "58AL0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MT10",
    label: "MT10",
    value: "MT10"
  },
  {
    SERIAL: "58AM0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "GRAND FILANO",
    label: "GRAND FILANO",
    value: "GRAND FILANO"
  },
  {
    SERIAL: "58AN0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XT1200Z",
    label: "XT1200Z",
    value: "XT1200Z"
  },
  {
    SERIAL: "58AO0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "GRAND FINO",
    label: "GRAND FINO",
    value: "GRAND FINO"
  },
  {
    SERIAL: "58AP0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TTX",
    label: "TTX",
    value: "TTX"
  },
  {
    SERIAL: "58AQ0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "58AR0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "125 CC",
    label: "125 CC",
    value: "125 CC"
  },
  {
    SERIAL: "58AS0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "110 CC",
    label: "110 CC",
    value: "110 CC"
  },
  {
    SERIAL: "58AT0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "58AU0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "MT09",
    label: "MT09",
    value: "MT09"
  },
  {
    SERIAL: "58AV0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "115 CC",
    label: "115 CC",
    value: "115 CC"
  },
  {
    SERIAL: "58AW0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "CRF1000D",
    label: "CRF1000D",
    value: "CRF1000D"
  },
  {
    SERIAL: "58AX0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XSR 900",
    label: "XSR 900",
    value: "XSR 900"
  },
  {
    SERIAL: "58AY0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "100 CC",
    label: "100 CC",
    value: "100 CC"
  },
  {
    SERIAL: "58AZ0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: ">150 CC",
    label: ">150 CC",
    value: ">150 CC"
  },
  {
    SERIAL: "58BA0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TRACER 900",
    label: "TRACER 900",
    value: "TRACER 900"
  },
  {
    SERIAL: "58BC0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TRACER 900 GT",
    label: "TRACER 900 GT",
    value: "TRACER 900 GT"
  },
  {
    SERIAL: "58BD0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TRACER 900 GT SIDE CASE BAG",
    label: "TRACER 900 GT SIDE CASE BAG",
    value: "TRACER 900 GT SIDE CASE BAG"
  },
  {
    SERIAL: "58BE0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX 560",
    label: "TMAX 560",
    value: "TMAX 560"
  },
  {
    SERIAL: "58BF0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX 560 TECH",
    label: "TMAX 560 TECH",
    value: "TMAX 560 TECH"
  },
  {
    SERIAL: "58BJ0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1",
    label: "YZF-R1",
    value: "YZF-R1"
  },
  {
    SERIAL: "58BK0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX DX",
    label: "TMAX DX",
    value: "TMAX DX"
  },
  {
    SERIAL: "58BL0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XMAX 300",
    label: "XMAX 300",
    value: "XMAX 300"
  },
  {
    SERIAL: "58BM0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FILANO",
    label: "FILANO",
    value: "FILANO"
  },
  {
    SERIAL: "58BN0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "QBIX",
    label: "QBIX",
    value: "QBIX"
  },
  {
    SERIAL: "58BO0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "AEROX 155",
    label: "AEROX 155",
    value: "AEROX 155"
  },
  {
    SERIAL: "58BP0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FJR1300 A",
    label: "FJR1300 A",
    value: "FJR1300 A"
  },
  {
    SERIAL: "58BQ0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "FJR1300 AS",
    label: "FJR1300 AS",
    value: "FJR1300 AS"
  },
  {
    SERIAL: "58BR0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XSR700",
    label: "XSR700",
    value: "XSR700"
  },
  {
    SERIAL: "58BS0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SCR950",
    label: "SCR950",
    value: "SCR950"
  },
  {
    SERIAL: "58BT0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "SR400",
    label: "SR400",
    value: "SR400"
  },
  {
    SERIAL: "58BU0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "AEROX 125",
    label: "AEROX 125",
    value: "AEROX 125"
  },
  {
    SERIAL: "58BW0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "XSR 155",
    label: "XSR 155",
    value: "XSR 155"
  },
  {
    SERIAL: "58CK0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "YZF-R1 STANDARD",
    label: "YZF-R1 STANDARD",
    value: "YZF-R1 STANDARD"
  },
  {
    SERIAL: "58GI0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "TMAX 530",
    label: "TMAX 530",
    value: "TMAX 530"
  },
  {
    SERIAL: "58OI0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "JUPITER RC",
    label: "JUPITER RC",
    value: "JUPITER RC"
  },
  {
    SERIAL: "58PS0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "LEXI S",
    label: "LEXI S",
    value: "LEXI S"
  },
  {
    SERIAL: "58PT0000026",
    MAKE_NAME: "YAMAHA",
    MODEL_NAME: "LEXI",
    label: "LEXI",
    value: "LEXI"
  },
  {
    SERIAL: "59000000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KAWASAKI",
    label: "KAWASAKI",
    value: "KAWASAKI"
  },
  {
    SERIAL: "59010000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KAZE 110",
    label: "KAZE 110",
    value: "KAZE 110"
  },
  {
    SERIAL: "59030000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-10R",
    label: "NINJA ZX-10R",
    value: "NINJA ZX-10R"
  },
  {
    SERIAL: "59040000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "ZX900C",
    label: "ZX900C",
    value: "ZX900C"
  },
  {
    SERIAL: "59050000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z1000",
    label: "Z1000",
    value: "Z1000"
  },
  {
    SERIAL: "59070000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "D-TRACKER X (KLX 250)",
    label: "D-TRACKER X (KLX 250)",
    value: "D-TRACKER X (KLX 250)"
  },
  {
    SERIAL: "59080000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KLX300 R",
    label: "KLX300 R",
    value: "KLX300 R"
  },
  {
    SERIAL: "59090000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z1000",
    label: "Z1000",
    value: "Z1000"
  },
  {
    SERIAL: "59100000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 900 CLASSIC",
    label: "VULCAN 900 CLASSIC",
    value: "VULCAN 900 CLASSIC"
  },
  {
    SERIAL: "59110000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 1700 CLASSIC",
    label: "VULCAN 1700 CLASSIC",
    value: "VULCAN 1700 CLASSIC"
  },
  {
    SERIAL: "59120000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 1700 VOYAGER",
    label: "VULCAN 1700 VOYAGER",
    value: "VULCAN 1700 VOYAGER"
  },
  {
    SERIAL: "59130000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 2000 CLASSIC",
    label: "VULCAN 2000 CLASSIC",
    value: "VULCAN 2000 CLASSIC"
  },
  {
    SERIAL: "59140000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 1700 NORMAD",
    label: "VULCAN 1700 NORMAD",
    value: "VULCAN 1700 NORMAD"
  },
  {
    SERIAL: "59150000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 1500 CLASSIC",
    label: "VULCAN 1500 CLASSIC",
    value: "VULCAN 1500 CLASSIC"
  },
  {
    SERIAL: "59160000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 1600 CLASSIC",
    label: "VULCAN 1600 CLASSIC",
    value: "VULCAN 1600 CLASSIC"
  },
  {
    SERIAL: "59170000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "CONCOURS 14 ABS",
    label: "CONCOURS 14 ABS",
    value: "CONCOURS 14 ABS"
  },
  {
    SERIAL: "59180000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "ZZR 1400",
    label: "ZZR 1400",
    value: "ZZR 1400"
  },
  {
    SERIAL: "59190000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-10R ABS",
    label: "NINJA ZX-10R ABS",
    value: "NINJA ZX-10R ABS"
  },
  {
    SERIAL: "59200000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 900 CUSTOM SPECIAL",
    label: "VULCAN 900 CUSTOM SPECIAL",
    value: "VULCAN 900 CUSTOM SPECIAL"
  },
  {
    SERIAL: "59210000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 900 CUSTOM",
    label: "VULCAN 900 CUSTOM",
    value: "VULCAN 900 CUSTOM"
  },
  {
    SERIAL: "59230000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z1000",
    label: "Z1000",
    value: "Z1000"
  },
  {
    SERIAL: "59250000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 250 SE",
    label: "NINJA 250 SE",
    value: "NINJA 250 SE"
  },
  {
    SERIAL: "59260000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "ER-6n 650",
    label: "ER-6n 650",
    value: "ER-6n 650"
  },
  {
    SERIAL: "59270000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 650",
    label: "NINJA 650",
    value: "NINJA 650"
  },
  {
    SERIAL: "59280000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS 650",
    label: "VERSYS 650",
    value: "VERSYS 650"
  },
  {
    SERIAL: "59300000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-10R",
    label: "NINJA ZX-10R",
    value: "NINJA ZX-10R"
  },
  {
    SERIAL: "59310000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "GTR 1400",
    label: "GTR 1400",
    value: "GTR 1400"
  },
  {
    SERIAL: "59320000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-14R",
    label: "NINJA ZX-14R",
    value: "NINJA ZX-14R"
  },
  {
    SERIAL: "59330000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-14",
    label: "NINJA ZX-14",
    value: "NINJA ZX-14"
  },
  {
    SERIAL: "59340000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KX250F",
    label: "KX250F",
    value: "KX250F"
  },
  {
    SERIAL: "59350000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KF450F ABS",
    label: "KF450F ABS",
    value: "KF450F ABS"
  },
  {
    SERIAL: "59360000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z250",
    label: "Z250",
    value: "Z250"
  },
  {
    SERIAL: "59370000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 300",
    label: "NINJA 300",
    value: "NINJA 300"
  },
  {
    SERIAL: "59380000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z800",
    label: "Z800",
    value: "Z800"
  },
  {
    SERIAL: "59390000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z1000 SE",
    label: "Z1000 SE",
    value: "Z1000 SE"
  },
  {
    SERIAL: "59400000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 1000",
    label: "NINJA 1000",
    value: "NINJA 1000"
  },
  {
    SERIAL: "59410000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KH125",
    label: "KH125",
    value: "KH125"
  },
  {
    SERIAL: "59420000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "GTR 1400",
    label: "GTR 1400",
    value: "GTR 1400"
  },
  {
    SERIAL: "59430000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS 1000 ABS",
    label: "VERSYS 1000 ABS",
    value: "VERSYS 1000 ABS"
  },
  {
    SERIAL: "59440000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z650",
    label: "Z650",
    value: "Z650"
  },
  {
    SERIAL: "59450000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS 650 ABS",
    label: "VERSYS 650 ABS",
    value: "VERSYS 650 ABS"
  },
  {
    SERIAL: "59460000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN 650",
    label: "VULCAN 650",
    value: "VULCAN 650"
  },
  {
    SERIAL: "59470000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA H2",
    label: "NINJA H2",
    value: "NINJA H2"
  },
  {
    SERIAL: "59480000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "1400 GTR ABS",
    label: "1400 GTR ABS",
    value: "1400 GTR ABS"
  },
  {
    SERIAL: "59490000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VULCAN S ABS",
    label: "VULCAN S ABS",
    value: "VULCAN S ABS"
  },
  {
    SERIAL: "59500000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z300 ABS",
    label: "Z300 ABS",
    value: "Z300 ABS"
  },
  {
    SERIAL: "59530000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z900 SPECIAL EDITION",
    label: "Z900 SPECIAL EDITION",
    value: "Z900 SPECIAL EDITION"
  },
  {
    SERIAL: "59540000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z900",
    label: "Z900",
    value: "Z900"
  },
  {
    SERIAL: "59550000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "ZX1000 H2 (FOR COVER 3)",
    label: "ZX1000 H2 (FOR COVER 3)",
    value: "ZX1000 H2 (FOR COVER 3)"
  },
  {
    SERIAL: "59560000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 300 KRT",
    label: "NINJA 300 KRT",
    value: "NINJA 300 KRT"
  },
  {
    SERIAL: "59570000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "BN175 E",
    label: "BN175 E",
    value: "BN175 E"
  },
  {
    SERIAL: "59580000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "110 CC",
    label: "110 CC",
    value: "110 CC"
  },
  {
    SERIAL: "59590000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "125 CC",
    label: "125 CC",
    value: "125 CC"
  },
  {
    SERIAL: "59600000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "59610000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 250 SL ABS",
    label: "NINJA 250 SL ABS",
    value: "NINJA 250 SL ABS"
  },
  {
    SERIAL: "59620000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z250 SL",
    label: "Z250 SL",
    value: "Z250 SL"
  },
  {
    SERIAL: "59630000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS 650 S ABS",
    label: "VERSYS 650 S ABS",
    value: "VERSYS 650 S ABS"
  },
  {
    SERIAL: "59640000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "OVER150 CC",
    label: "OVER150 CC",
    value: "OVER150 CC"
  },
  {
    SERIAL: "59650000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-10R",
    label: "NINJA ZX-10R",
    value: "NINJA ZX-10R"
  },
  {
    SERIAL: "59680000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "ZX1000",
    label: "ZX1000",
    value: "ZX1000"
  },
  {
    SERIAL: "59690000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "W 800",
    label: "W 800",
    value: "W 800"
  },
  {
    SERIAL: "59700000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "W 800 SPECIAL EDITION",
    label: "W 800 SPECIAL EDITION",
    value: "W 800 SPECIAL EDITION"
  },
  {
    SERIAL: "59710000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "1200",
    label: "1200",
    value: "1200"
  },
  {
    SERIAL: "59720000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z125 PRO",
    label: "Z125 PRO",
    value: "Z125 PRO"
  },
  {
    SERIAL: "59730000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS-X 300",
    label: "VERSYS-X 300",
    value: "VERSYS-X 300"
  },
  {
    SERIAL: "59740000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 400",
    label: "NINJA 400",
    value: "NINJA 400"
  },
  {
    SERIAL: "59750000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 250 R",
    label: "NINJA 250 R",
    value: "NINJA 250 R"
  },
  {
    SERIAL: "59770000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "ZX130",
    label: "ZX130",
    value: "ZX130"
  },
  {
    SERIAL: "59780000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS X 300 ABS",
    label: "VERSYS X 300 ABS",
    value: "VERSYS X 300 ABS"
  },
  {
    SERIAL: "59790000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS X 300 ABS TOURER",
    label: "VERSYS X 300 ABS TOURER",
    value: "VERSYS X 300 ABS TOURER"
  },
  {
    SERIAL: "59800000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z400 ABS",
    label: "Z400 ABS",
    value: "Z400 ABS"
  },
  {
    SERIAL: "59810000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-10R SE",
    label: "NINJA ZX-10R SE",
    value: "NINJA ZX-10R SE"
  },
  {
    SERIAL: "59820000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA H2 SX SE",
    label: "NINJA H2 SX SE",
    value: "NINJA H2 SX SE"
  },
  {
    SERIAL: "59830000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z900 RS",
    label: "Z900 RS",
    value: "Z900 RS"
  },
  {
    SERIAL: "59840000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z900 RS SE",
    label: "Z900 RS SE",
    value: "Z900 RS SE"
  },
  {
    SERIAL: "59850000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z900 RS CAFE",
    label: "Z900 RS CAFE",
    value: "Z900 RS CAFE"
  },
  {
    SERIAL: "59860000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KLX250",
    label: "KLX250",
    value: "KLX250"
  },
  {
    SERIAL: "59880000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z1000 R",
    label: "Z1000 R",
    value: "Z1000 R"
  },
  {
    SERIAL: "59890000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "ZX14R",
    label: "ZX14R",
    value: "ZX14R"
  },
  {
    SERIAL: "59900000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "118 CC",
    label: "118 CC",
    value: "118 CC"
  },
  {
    SERIAL: "59910000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z H2 998",
    label: "Z H2 998",
    value: "Z H2 998"
  },
  {
    SERIAL: "59920000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "MEGURO K3",
    label: "MEGURO K3",
    value: "MEGURO K3"
  },
  {
    SERIAL: "59BI0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "Z 250SL ABS",
    label: "Z 250SL ABS",
    value: "Z 250SL ABS"
  },
  {
    SERIAL: "59BK0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "W 250",
    label: "W 250",
    value: "W 250"
  },
  {
    SERIAL: "59BL0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "W 250 SE",
    label: "W 250 SE",
    value: "W 250 SE"
  },
  {
    SERIAL: "59BM0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 250SL",
    label: "NINJA 250SL",
    value: "NINJA 250SL"
  },
  {
    SERIAL: "59BN0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA 250",
    label: "NINJA 250",
    value: "NINJA 250"
  },
  {
    SERIAL: "59BO0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-6R",
    label: "NINJA ZX-6R",
    value: "NINJA ZX-6R"
  },
  {
    SERIAL: "59BP0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-25R",
    label: "NINJA ZX-25R",
    value: "NINJA ZX-25R"
  },
  {
    SERIAL: "59BQ0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "NINJA ZX-10RR",
    label: "NINJA ZX-10RR",
    value: "NINJA ZX-10RR"
  },
  {
    SERIAL: "59BR0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS 1000 SE",
    label: "VERSYS 1000 SE",
    value: "VERSYS 1000 SE"
  },
  {
    SERIAL: "59BS0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "VERSYS 1000",
    label: "VERSYS 1000",
    value: "VERSYS 1000"
  },
  {
    SERIAL: "59BW0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "W 175 SE",
    label: "W 175 SE",
    value: "W 175 SE"
  },
  {
    SERIAL: "59BX0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "W 175",
    label: "W 175",
    value: "W 175"
  },
  {
    SERIAL: "59ER0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KLX230",
    label: "KLX230",
    value: "KLX230"
  },
  {
    SERIAL: "59ES0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KLX150",
    label: "KLX150",
    value: "KLX150"
  },
  {
    SERIAL: "59KK0000026",
    MAKE_NAME: "KAWASAKI",
    MODEL_NAME: "KSR",
    label: "KSR",
    value: "KSR"
  },
  {
    SERIAL: "60000000026",
    MAKE_NAME: "STATEMAN",
    MODEL_NAME: "STATEMAN",
    label: "STATEMAN",
    value: "STATEMAN"
  },
  {
    SERIAL: "60010000026",
    MAKE_NAME: "STATEMAN",
    MODEL_NAME: "STATEMAN",
    label: "STATEMAN",
    value: "STATEMAN"
  },
  {
    SERIAL: "61000000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "VESPA",
    label: "VESPA",
    value: "VESPA"
  },
  {
    SERIAL: "61010000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LX 125 IE",
    label: "LX 125 IE",
    value: "LX 125 IE"
  },
  {
    SERIAL: "61020000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LIBERTY 125 IE",
    label: "LIBERTY 125 IE",
    value: "LIBERTY 125 IE"
  },
  {
    SERIAL: "61030000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "S 150 IE",
    label: "S 150 IE",
    value: "S 150 IE"
  },
  {
    SERIAL: "61040000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LX 150",
    label: "LX 150",
    value: "LX 150"
  },
  {
    SERIAL: "61050000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LX 150 LIMITED",
    label: "LX 150 LIMITED",
    value: "LX 150 LIMITED"
  },
  {
    SERIAL: "61060000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTV 250 IE",
    label: "GTV 250 IE",
    value: "GTV 250 IE"
  },
  {
    SERIAL: "61070000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 250 IE",
    label: "GTS 250 IE",
    value: "GTS 250 IE"
  },
  {
    SERIAL: "61080000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS SUPER 300IE",
    label: "GTS SUPER 300IE",
    value: "GTS SUPER 300IE"
  },
  {
    SERIAL: "61090000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS SUPER SPORT",
    label: "GTS SUPER SPORT",
    value: "GTS SUPER SPORT"
  },
  {
    SERIAL: "61100000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTV 300 IE",
    label: "GTV 300 IE",
    value: "GTV 300 IE"
  },
  {
    SERIAL: "61110000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "BEVERLY 300IE",
    label: "BEVERLY 300IE",
    value: "BEVERLY 300IE"
  },
  {
    SERIAL: "61120000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "MP3 400 IE",
    label: "MP3 400 IE",
    value: "MP3 400 IE"
  },
  {
    SERIAL: "61130000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "FUOCO 500",
    label: "FUOCO 500",
    value: "FUOCO 500"
  },
  {
    SERIAL: "61140000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GP 800",
    label: "GP 800",
    value: "GP 800"
  },
  {
    SERIAL: "61150000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LX 150 IE",
    label: "LX 150 IE",
    value: "LX 150 IE"
  },
  {
    SERIAL: "61160000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "S 125 IE",
    label: "S 125 IE",
    value: "S 125 IE"
  },
  {
    SERIAL: "61170000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LX 125 3Vie",
    label: "LX 125 3Vie",
    value: "LX 125 3Vie"
  },
  {
    SERIAL: "61180000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "S 125 3Vie",
    label: "S 125 3Vie",
    value: "S 125 3Vie"
  },
  {
    SERIAL: "61200000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "S 150 3Vie",
    label: "S 150 3Vie",
    value: "S 150 3Vie"
  },
  {
    SERIAL: "61210000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LXV 150 3Vie",
    label: "LXV 150 3Vie",
    value: "LXV 150 3Vie"
  },
  {
    SERIAL: "61220000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LX 125 3VIE VINTAGE EDITION",
    label: "LX 125 3VIE VINTAGE EDITION",
    value: "LX 125 3VIE VINTAGE EDITION"
  },
  {
    SERIAL: "61230000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "946 3VIE",
    label: "946 3VIE",
    value: "946 3VIE"
  },
  {
    SERIAL: "61240000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS150 3Vie",
    label: "GTS150 3Vie",
    value: "GTS150 3Vie"
  },
  {
    SERIAL: "61250000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 300 TOURING",
    label: "GTS 300 TOURING",
    value: "GTS 300 TOURING"
  },
  {
    SERIAL: "61260000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "SPRINT 150 3Vie",
    label: "SPRINT 150 3Vie",
    value: "SPRINT 150 3Vie"
  },
  {
    SERIAL: "61270000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "SPRINT",
    label: "SPRINT",
    value: "SPRINT"
  },
  {
    SERIAL: "61290000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 300 SUPER SPORT",
    label: "GTS 300 SUPER SPORT",
    value: "GTS 300 SUPER SPORT"
  },
  {
    SERIAL: "61300000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 300 SUPER i-GET ABS",
    label: "GTS 300 SUPER i-GET ABS",
    value: "GTS 300 SUPER i-GET ABS"
  },
  {
    SERIAL: "61310000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS SUPERTECH 300 HPE",
    label: "GTS SUPERTECH 300 HPE",
    value: "GTS SUPERTECH 300 HPE"
  },
  {
    SERIAL: "61380000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "946",
    label: "946",
    value: "946"
  },
  {
    SERIAL: "61500000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 150",
    label: "GTS 150",
    value: "GTS 150"
  },
  {
    SERIAL: "61510000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 150 MATT",
    label: "GTS 150 MATT",
    value: "GTS 150 MATT"
  },
  {
    SERIAL: "61520000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "PRIMAVERA 125",
    label: "PRIMAVERA 125",
    value: "PRIMAVERA 125"
  },
  {
    SERIAL: "61530000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "PRIMAVERA 150 3Vie",
    label: "PRIMAVERA 150 3Vie",
    value: "PRIMAVERA 150 3Vie"
  },
  {
    SERIAL: "61540000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "MEDLEY 150 ABS",
    label: "MEDLEY 150 ABS",
    value: "MEDLEY 150 ABS"
  },
  {
    SERIAL: "61550000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "MP3 300 YOURBRAN RL",
    label: "MP3 300 YOURBRAN RL",
    value: "MP3 300 YOURBRAN RL"
  },
  {
    SERIAL: "61560000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "MP3 500 LT SPORT ABS",
    label: "MP3 500 LT SPORT ABS",
    value: "MP3 500 LT SPORT ABS"
  },
  {
    SERIAL: "61570000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "946 3Vie (EMPORIO ARMANI)",
    label: "946 3Vie (EMPORIO ARMANI)",
    value: "946 3Vie (EMPORIO ARMANI)"
  },
  {
    SERIAL: "61580000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "PX125",
    label: "PX125",
    value: "PX125"
  },
  {
    SERIAL: "61590000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "PX150",
    label: "PX150",
    value: "PX150"
  },
  {
    SERIAL: "61600000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 150 Vie 70th ANNIVERSARY",
    label: "GTS 150 Vie 70th ANNIVERSARY",
    value: "GTS 150 Vie 70th ANNIVERSARY"
  },
  {
    SERIAL: "61610000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "SPRINT 150 3Vie AVVENTURA 70th LIMITED",
    label: "SPRINT 150 3Vie AVVENTURA 70th LIMITED",
    value: "SPRINT 150 3Vie AVVENTURA 70th LIMITED"
  },
  {
    SERIAL: "61620000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "PRIMAVERA 150 3Vie ABS 70th",
    label: "PRIMAVERA 150 3Vie ABS 70th",
    value: "PRIMAVERA 150 3Vie ABS 70th"
  },
  {
    SERIAL: "61630000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LXV 125 3Vie",
    label: "LXV 125 3Vie",
    value: "LXV 125 3Vie"
  },
  {
    SERIAL: "61640000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LXV150 3Vie SAFARI LIMITED EDITION",
    label: "LXV150 3Vie SAFARI LIMITED EDITION",
    value: "LXV150 3Vie SAFARI LIMITED EDITION"
  },
  {
    SERIAL: "61650000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "LT125 3Vie",
    label: "LT125 3Vie",
    value: "LT125 3Vie"
  },
  {
    SERIAL: "61660000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "SPRINT 125 3Vie",
    label: "SPRINT 125 3Vie",
    value: "SPRINT 125 3Vie"
  },
  {
    SERIAL: "61670000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "SPRINT 150 3Vie 68th",
    label: "SPRINT 150 3Vie 68th",
    value: "SPRINT 150 3Vie 68th"
  },
  {
    SERIAL: "61700000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS 300 SUPER",
    label: "GTS 300 SUPER",
    value: "GTS 300 SUPER"
  },
  {
    SERIAL: "61710000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS SUPER 300 ABS",
    label: "GTS SUPER 300 ABS",
    value: "GTS SUPER 300 ABS"
  },
  {
    SERIAL: "61720000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTS SUPER SPORT 300 ABS",
    label: "GTS SUPER SPORT 300 ABS",
    value: "GTS SUPER SPORT 300 ABS"
  },
  {
    SERIAL: "61730000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "100 CC",
    label: "100 CC",
    value: "100 CC"
  },
  {
    SERIAL: "61740000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "61750000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "946 3VIE RED",
    label: "946 3VIE RED",
    value: "946 3VIE RED"
  },
  {
    SERIAL: "61760000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "SPRINT S150",
    label: "SPRINT S150",
    value: "SPRINT S150"
  },
  {
    SERIAL: "61780000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "GTV 300 HPE",
    label: "GTV 300 HPE",
    value: "GTV 300 HPE"
  },
  {
    SERIAL: "61850000026",
    MAKE_NAME: "VESPA",
    MODEL_NAME: "S 125 IGET",
    label: "S 125 IGET",
    value: "S 125 IGET"
  },
  {
    SERIAL: "62000000026",
    MAKE_NAME: "PIAGGIO",
    MODEL_NAME: "PIAGGIO",
    label: "PIAGGIO",
    value: "PIAGGIO"
  },
  {
    SERIAL: "62020000026",
    MAKE_NAME: "PIAGGIO",
    MODEL_NAME: "MP3",
    label: "MP3",
    value: "MP3"
  },
  {
    SERIAL: "62030000026",
    MAKE_NAME: "PIAGGIO",
    MODEL_NAME: "BEVERLY 300IE",
    label: "BEVERLY 300IE",
    value: "BEVERLY 300IE"
  },
  {
    SERIAL: "62040000026",
    MAKE_NAME: "PIAGGIO",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "62150000026",
    MAKE_NAME: "PIAGGIO",
    MODEL_NAME: "MP3 500 LT SPORT",
    label: "MP3 500 LT SPORT",
    value: "MP3 500 LT SPORT"
  },
  {
    SERIAL: "63000000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "HARLEY DAVIDSON",
    label: "HARLEY DAVIDSON",
    value: "HARLEY DAVIDSON"
  },
  {
    SERIAL: "63010000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE",
    label: "SOFTAIL HERITAGE",
    value: "SOFTAIL HERITAGE"
  },
  {
    SERIAL: "63020000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FATBOY",
    label: "FATBOY",
    value: "FATBOY"
  },
  {
    SERIAL: "63030000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "LOW RIDER",
    label: "LOW RIDER",
    value: "LOW RIDER"
  },
  {
    SERIAL: "63040000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL STANDARD",
    label: "SOFTAIL STANDARD",
    value: "SOFTAIL STANDARD"
  },
  {
    SERIAL: "63050000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "V-ROD",
    label: "V-ROD",
    value: "V-ROD"
  },
  {
    SERIAL: "63060000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE SPRINGER",
    label: "SOFTAIL HERITAGE SPRINGER",
    value: "SOFTAIL HERITAGE SPRINGER"
  },
  {
    SERIAL: "63080000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING CUSTOM (จดประกอบ)",
    label: "TOURING ROAD KING CUSTOM (จดประกอบ)",
    value: "TOURING ROAD KING CUSTOM (จดประกอบ)"
  },
  {
    SERIAL: "63090000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSCB V-ROD - GRAY",
    label: "VRSCB V-ROD - GRAY",
    value: "VRSCB V-ROD - GRAY"
  },
  {
    SERIAL: "63100000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FATBOY",
    label: "FATBOY",
    value: "FATBOY"
  },
  {
    SERIAL: "63120000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSC V-ROD",
    label: "VRSC V-ROD",
    value: "VRSC V-ROD"
  },
  {
    SERIAL: "63130000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE",
    label: "DYNA SUPER GLIDE",
    value: "DYNA SUPER GLIDE"
  },
  {
    SERIAL: "63140000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE ULTRA CLESSIC",
    label: "ELECTRA GLIDE ULTRA CLESSIC",
    value: "ELECTRA GLIDE ULTRA CLESSIC"
  },
  {
    SERIAL: "63150000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING CUSTOM",
    label: "ROAD KING CUSTOM",
    value: "ROAD KING CUSTOM"
  },
  {
    SERIAL: "63170000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING จดประกอบ",
    label: "ROAD KING จดประกอบ",
    value: "ROAD KING จดประกอบ"
  },
  {
    SERIAL: "63180000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SCREAMING EAGLE V-ROD",
    label: "SCREAMING EAGLE V-ROD",
    value: "SCREAMING EAGLE V-ROD"
  },
  {
    SERIAL: "63200000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE STANDARD FLHT",
    label: "ELECTRA GLIDE STANDARD FLHT",
    value: "ELECTRA GLIDE STANDARD FLHT"
  },
  {
    SERIAL: "63210000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA CLASSIC",
    label: "TOURING ELECTRA GLIDE ULTRA CLASSIC",
    value: "TOURING ELECTRA GLIDE ULTRA CLASSIC"
  },
  {
    SERIAL: "63220000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DELUXE",
    label: "SOFTAIL DELUXE",
    value: "SOFTAIL DELUXE"
  },
  {
    SERIAL: "63230000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE",
    label: "DYNA SUPER GLIDE",
    value: "DYNA SUPER GLIDE"
  },
  {
    SERIAL: "63240000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING",
    label: "TOURING ROAD KING",
    value: "TOURING ROAD KING"
  },
  {
    SERIAL: "63250000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING",
    label: "ROAD KING",
    value: "ROAD KING"
  },
  {
    SERIAL: "63260000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE CLASSIC",
    label: "SOFTAIL HERITAGE CLASSIC",
    value: "SOFTAIL HERITAGE CLASSIC"
  },
  {
    SERIAL: "63270000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA",
    label: "DYNA",
    value: "DYNA"
  },
  {
    SERIAL: "63280000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE CLASSIC",
    label: "SOFTAIL HERITAGE CLASSIC",
    value: "SOFTAIL HERITAGE CLASSIC"
  },
  {
    SERIAL: "63290000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FATBOY",
    label: "FATBOY",
    value: "FATBOY"
  },
  {
    SERIAL: "63300000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FATBOY",
    label: "FATBOY",
    value: "FATBOY"
  },
  {
    SERIAL: "63310000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL CUSTOM",
    label: "SOFTAIL CUSTOM",
    value: "SOFTAIL CUSTOM"
  },
  {
    SERIAL: "63320000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL NIGHT TRAIN - BUILT",
    label: "SOFTAIL NIGHT TRAIN - BUILT",
    value: "SOFTAIL NIGHT TRAIN - BUILT"
  },
  {
    SERIAL: "63330000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FATBOY SCREAMIN EAGLE",
    label: "FATBOY SCREAMIN EAGLE",
    value: "FATBOY SCREAMIN EAGLE"
  },
  {
    SERIAL: "63340000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL",
    label: "SOFTAIL",
    value: "SOFTAIL"
  },
  {
    SERIAL: "63350000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER (COVER 3)",
    label: "SPORTSTER (COVER 3)",
    value: "SPORTSTER (COVER 3)"
  },
  {
    SERIAL: "63370000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL CLASSIC",
    label: "SOFTAIL CLASSIC",
    value: "SOFTAIL CLASSIC"
  },
  {
    SERIAL: "63380000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "V-ROD",
    label: "V-ROD",
    value: "V-ROD"
  },
  {
    SERIAL: "63390000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ULTRA CLASSIC ELECTRA GLIDE",
    label: "ULTRA CLASSIC ELECTRA GLIDE",
    value: "ULTRA CLASSIC ELECTRA GLIDE"
  },
  {
    SERIAL: "63400000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA CLASSIC",
    label: "TOURING ELECTRA GLIDE ULTRA CLASSIC",
    value: "TOURING ELECTRA GLIDE ULTRA CLASSIC"
  },
  {
    SERIAL: "63430000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING (Cover 3)",
    label: "ROAD KING (Cover 3)",
    value: "ROAD KING (Cover 3)"
  },
  {
    SERIAL: "63450000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ULTRA CLASSIC",
    label: "ULTRA CLASSIC",
    value: "ULTRA CLASSIC"
  },
  {
    SERIAL: "63460000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER",
    label: "SPORTSTER",
    value: "SPORTSTER"
  },
  {
    SERIAL: "63490000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING",
    label: "ROAD KING",
    value: "ROAD KING"
  },
  {
    SERIAL: "63500000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER",
    label: "SPORTSTER",
    value: "SPORTSTER"
  },
  {
    SERIAL: "63510000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA WIDE GILDE",
    label: "DYNA WIDE GILDE",
    value: "DYNA WIDE GILDE"
  },
  {
    SERIAL: "63520000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER",
    label: "SPORTSTER",
    value: "SPORTSTER"
  },
  {
    SERIAL: "63530000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING CLASSIC",
    label: "ROAD KING CLASSIC",
    value: "ROAD KING CLASSIC"
  },
  {
    SERIAL: "63540000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD GLIDE",
    label: "ROAD GLIDE",
    value: "ROAD GLIDE"
  },
  {
    SERIAL: "63550000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL CUSTOM",
    label: "SOFTAIL CUSTOM",
    value: "SOFTAIL CUSTOM"
  },
  {
    SERIAL: "63560000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE",
    label: "DYNA SUPER GLIDE",
    value: "DYNA SUPER GLIDE"
  },
  {
    SERIAL: "63570000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SCREAMING EAGLE ROAD KING",
    label: "CVO SCREAMING EAGLE ROAD KING",
    value: "CVO SCREAMING EAGLE ROAD KING"
  },
  {
    SERIAL: "63580000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL",
    label: "SOFTAIL",
    value: "SOFTAIL"
  },
  {
    SERIAL: "63590000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET BOB",
    label: "STREET BOB",
    value: "STREET BOB"
  },
  {
    SERIAL: "63600000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPRINGER SOFTAIL",
    label: "SPRINGER SOFTAIL",
    value: "SPRINGER SOFTAIL"
  },
  {
    SERIAL: "63620000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ULTRA CLASSIC",
    label: "ULTRA CLASSIC",
    value: "ULTRA CLASSIC"
  },
  {
    SERIAL: "63630000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING CLASSIC (FLHR)",
    label: "TOURING ROAD KING CLASSIC (FLHR)",
    value: "TOURING ROAD KING CLASSIC (FLHR)"
  },
  {
    SERIAL: "63640000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA",
    label: "DYNA",
    value: "DYNA"
  },
  {
    SERIAL: "63650000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA WIDE GILDE",
    label: "DYNA WIDE GILDE",
    value: "DYNA WIDE GILDE"
  },
  {
    SERIAL: "63660000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSC NIGHT ROD SPECIAL",
    label: "VRSC NIGHT ROD SPECIAL",
    value: "VRSC NIGHT ROD SPECIAL"
  },
  {
    SERIAL: "63670000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "BUELL M2",
    label: "BUELL M2",
    value: "BUELL M2"
  },
  {
    SERIAL: "63680000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOW RIDER",
    label: "DYNA LOW RIDER",
    value: "DYNA LOW RIDER"
  },
  {
    SERIAL: "63690000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA CLASSIC",
    label: "TOURING ELECTRA GLIDE ULTRA CLASSIC",
    value: "TOURING ELECTRA GLIDE ULTRA CLASSIC"
  },
  {
    SERIAL: "63710000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA STREET BOB",
    label: "DYNA STREET BOB",
    value: "DYNA STREET BOB"
  },
  {
    SERIAL: "63720000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOW RIDER",
    label: "DYNA LOW RIDER",
    value: "DYNA LOW RIDER"
  },
  {
    SERIAL: "63730000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE",
    label: "SOFTAIL HERITAGE",
    value: "SOFTAIL HERITAGE"
  },
  {
    SERIAL: "63750000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "BUELL XB9",
    label: "BUELL XB9",
    value: "BUELL XB9"
  },
  {
    SERIAL: "63770000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING - Built",
    label: "TOURING ROAD KING - Built",
    value: "TOURING ROAD KING - Built"
  },
  {
    SERIAL: "63780000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE STANDARD",
    label: "ELECTRA GLIDE STANDARD",
    value: "ELECTRA GLIDE STANDARD"
  },
  {
    SERIAL: "63800000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FLH",
    label: "FLH",
    value: "FLH"
  },
  {
    SERIAL: "63810000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE",
    label: "DYNA SUPER GLIDE",
    value: "DYNA SUPER GLIDE"
  },
  {
    SERIAL: "63820000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA STREET BOB",
    label: "DYNA STREET BOB",
    value: "DYNA STREET BOB"
  },
  {
    SERIAL: "63830000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO STREET GLIDE",
    label: "CVO STREET GLIDE",
    value: "CVO STREET GLIDE"
  },
  {
    SERIAL: "63850000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "V-ROD NIGHT ROD",
    label: "V-ROD NIGHT ROD",
    value: "V-ROD NIGHT ROD"
  },
  {
    SERIAL: "63860000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSC V-ROD MUSCLE",
    label: "VRSC V-ROD MUSCLE",
    value: "VRSC V-ROD MUSCLE"
  },
  {
    SERIAL: "63880000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE STANDARD",
    label: "ELECTRA GLIDE STANDARD",
    value: "ELECTRA GLIDE STANDARD"
  },
  {
    SERIAL: "63890000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL NIGHT TRAIN",
    label: "SOFTAIL NIGHT TRAIN",
    value: "SOFTAIL NIGHT TRAIN"
  },
  {
    SERIAL: "63900000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "BUELL FIREBOLT XB12R",
    label: "BUELL FIREBOLT XB12R",
    value: "BUELL FIREBOLT XB12R"
  },
  {
    SERIAL: "63910000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOW RIDER",
    label: "DYNA LOW RIDER",
    value: "DYNA LOW RIDER"
  },
  {
    SERIAL: "63920000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200N NIGHTSTER",
    label: "SPORTSTER XL1200N NIGHTSTER",
    value: "SPORTSTER XL1200N NIGHTSTER"
  },
  {
    SERIAL: "63930000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DELUXE",
    label: "SOFTAIL DELUXE",
    value: "SOFTAIL DELUXE"
  },
  {
    SERIAL: "63940000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE ULTRA",
    label: "ELECTRA GLIDE ULTRA",
    value: "ELECTRA GLIDE ULTRA"
  },
  {
    SERIAL: "63950000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FXDL",
    label: "FXDL",
    value: "FXDL"
  },
  {
    SERIAL: "63960000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSCA V-ROD (COVER 3)",
    label: "VRSCA V-ROD (COVER 3)",
    value: "VRSCA V-ROD (COVER 3)"
  },
  {
    SERIAL: "63970000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GUIDE CUSTOM",
    label: "DYNA SUPER GUIDE CUSTOM",
    value: "DYNA SUPER GUIDE CUSTOM"
  },
  {
    SERIAL: "63980000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CROSS BONES",
    label: "CROSS BONES",
    value: "CROSS BONES"
  },
  {
    SERIAL: "63990000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE",
    label: "ELECTRA GLIDE",
    value: "ELECTRA GLIDE"
  },
  {
    SERIAL: "63AA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING CUSTOM",
    label: "ROAD KING CUSTOM",
    value: "ROAD KING CUSTOM"
  },
  {
    SERIAL: "63AB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200",
    label: "SPORTSTER XL1200",
    value: "SPORTSTER XL1200"
  },
  {
    SERIAL: "63AC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER",
    label: "SPORTSTER",
    value: "SPORTSTER"
  },
  {
    SERIAL: "63AD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "NIGHT TRAIN - BUILT",
    label: "NIGHT TRAIN - BUILT",
    value: "NIGHT TRAIN - BUILT"
  },
  {
    SERIAL: "63AE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSCF",
    label: "VRSCF",
    value: "VRSCF"
  },
  {
    SERIAL: "63AF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA FATBOB",
    label: "DYNA FATBOB",
    value: "DYNA FATBOB"
  },
  {
    SERIAL: "63AG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE SPRINGER",
    label: "SOFTAIL HERITAGE SPRINGER",
    value: "SOFTAIL HERITAGE SPRINGER"
  },
  {
    SERIAL: "63AH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA",
    label: "TOURING ELECTRA GLIDE ULTRA",
    value: "TOURING ELECTRA GLIDE ULTRA"
  },
  {
    SERIAL: "63AI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA ANNIERSARY",
    label: "DYNA ANNIERSARY",
    value: "DYNA ANNIERSARY"
  },
  {
    SERIAL: "63AJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 883",
    label: "SPORTSTER 883",
    value: "SPORTSTER 883"
  },
  {
    SERIAL: "63AK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200 CUSTOM",
    label: "SPORTSTER XL1200 CUSTOM",
    value: "SPORTSTER XL1200 CUSTOM"
  },
  {
    SERIAL: "63AL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 1200 NIGHTSTER",
    label: "SPORTSTER 1200 NIGHTSTER",
    value: "SPORTSTER 1200 NIGHTSTER"
  },
  {
    SERIAL: "63AM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 1200 LOW",
    label: "SPORTSTER 1200 LOW",
    value: "SPORTSTER 1200 LOW"
  },
  {
    SERIAL: "63AN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 883 LOW",
    label: "SPORTSTER 883 LOW",
    value: "SPORTSTER 883 LOW"
  },
  {
    SERIAL: "63AO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 883 CUSTOM",
    label: "SPORTSTER 883 CUSTOM",
    value: "SPORTSTER 883 CUSTOM"
  },
  {
    SERIAL: "63AP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XR1200X",
    label: "SPORTSTER XR1200X",
    value: "SPORTSTER XR1200X"
  },
  {
    SERIAL: "63AQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE ULTRA CLASSIC",
    label: "ELECTRA GLIDE ULTRA CLASSIC",
    value: "ELECTRA GLIDE ULTRA CLASSIC"
  },
  {
    SERIAL: "63AR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD GLIDE BASE",
    label: "ROAD GLIDE BASE",
    value: "ROAD GLIDE BASE"
  },
  {
    SERIAL: "63AS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE CUSTOM",
    label: "DYNA SUPER GLIDE CUSTOM",
    value: "DYNA SUPER GLIDE CUSTOM"
  },
  {
    SERIAL: "63AT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA FAT BOB",
    label: "DYNA FAT BOB",
    value: "DYNA FAT BOB"
  },
  {
    SERIAL: "63AU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL CROSS BONES",
    label: "SOFTAIL CROSS BONES",
    value: "SOFTAIL CROSS BONES"
  },
  {
    SERIAL: "63AV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE (GRAY)",
    label: "TOURING STREET GLIDE (GRAY)",
    value: "TOURING STREET GLIDE (GRAY)"
  },
  {
    SERIAL: "63AW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSC V-ROD",
    label: "VRSC V-ROD",
    value: "VRSC V-ROD"
  },
  {
    SERIAL: "63AX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA STREET BOB",
    label: "DYNA STREET BOB",
    value: "DYNA STREET BOB"
  },
  {
    SERIAL: "63AY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA SCREAMING",
    label: "CVO ULTRA SCREAMING",
    value: "CVO ULTRA SCREAMING"
  },
  {
    SERIAL: "63AZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSC V-ROD MUSCLE",
    label: "VRSC V-ROD MUSCLE",
    value: "VRSC V-ROD MUSCLE"
  },
  {
    SERIAL: "63BA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 883 ROADSTER",
    label: "SPORTSTER 883 ROADSTER",
    value: "SPORTSTER 883 ROADSTER"
  },
  {
    SERIAL: "63BB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPRINGER CVO LIMITED EDITION",
    label: "SPRINGER CVO LIMITED EDITION",
    value: "SPRINGER CVO LIMITED EDITION"
  },
  {
    SERIAL: "63BC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA CLASSIC ELECTRA GLIDE",
    label: "CVO ULTRA CLASSIC ELECTRA GLIDE",
    value: "CVO ULTRA CLASSIC ELECTRA GLIDE"
  },
  {
    SERIAL: "63BD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SCREAMING EAGLE ROAD KING",
    label: "CVO SCREAMING EAGLE ROAD KING",
    value: "CVO SCREAMING EAGLE ROAD KING"
  },
  {
    SERIAL: "63BE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SCREAMING EAGLE DYNA FAT BOB",
    label: "SCREAMING EAGLE DYNA FAT BOB",
    value: "SCREAMING EAGLE DYNA FAT BOB"
  },
  {
    SERIAL: "63BF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET BOB",
    label: "STREET BOB",
    value: "STREET BOB"
  },
  {
    SERIAL: "63BG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER",
    label: "SOFTAIL SPRINGER",
    value: "SOFTAIL SPRINGER"
  },
  {
    SERIAL: "63BH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FATBOY LOW",
    label: "FATBOY LOW",
    value: "FATBOY LOW"
  },
  {
    SERIAL: "63BI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD GLIDE SPORT",
    label: "ROAD GLIDE SPORT",
    value: "ROAD GLIDE SPORT"
  },
  {
    SERIAL: "63BJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOY",
    label: "SOFTAIL FAT BOY",
    value: "SOFTAIL FAT BOY"
  },
  {
    SERIAL: "63BK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE ULTRA",
    label: "CVO ROAD GLIDE ULTRA",
    value: "CVO ROAD GLIDE ULTRA"
  },
  {
    SERIAL: "63BL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO STREET GLIDE",
    label: "CVO STREET GLIDE",
    value: "CVO STREET GLIDE"
  },
  {
    SERIAL: "63BM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL ROCKER C",
    label: "SOFTAIL ROCKER C",
    value: "SOFTAIL ROCKER C"
  },
  {
    SERIAL: "63BN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE (จดประกอบ)",
    label: "TOURING ELECTRA GLIDE (จดประกอบ)",
    value: "TOURING ELECTRA GLIDE (จดประกอบ)"
  },
  {
    SERIAL: "63BO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CUSTOM - BUILT",
    label: "CUSTOM - BUILT",
    value: "CUSTOM - BUILT"
  },
  {
    SERIAL: "63BP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA FAT BOB (FXDF)",
    label: "DYNA FAT BOB (FXDF)",
    value: "DYNA FAT BOB (FXDF)"
  },
  {
    SERIAL: "63BQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE CUSTOM",
    label: "TOURING ROAD GLIDE CUSTOM",
    value: "TOURING ROAD GLIDE CUSTOM"
  },
  {
    SERIAL: "63BR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200",
    label: "SPORTSTER XL1200",
    value: "SPORTSTER XL1200"
  },
  {
    SERIAL: "63BS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING POLICE - BUILT",
    label: "ROAD KING POLICE - BUILT",
    value: "ROAD KING POLICE - BUILT"
  },
  {
    SERIAL: "63BT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY LOW",
    label: "SOFTAIL FATBOY LOW",
    value: "SOFTAIL FATBOY LOW"
  },
  {
    SERIAL: "63BU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "BLACK LINE (จดประกอบ)",
    label: "BLACK LINE (จดประกอบ)",
    value: "BLACK LINE (จดประกอบ)"
  },
  {
    SERIAL: "63BV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE POLICE (จดประกอบ)",
    label: "ELECTRA GLIDE POLICE (จดประกอบ)",
    value: "ELECTRA GLIDE POLICE (จดประกอบ)"
  },
  {
    SERIAL: "63BW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SOFTAIL CONVERTIBLE",
    label: "CVO SOFTAIL CONVERTIBLE",
    value: "CVO SOFTAIL CONVERTIBLE"
  },
  {
    SERIAL: "63BX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA LIMITED",
    label: "TOURING ELECTRA GLIDE ULTRA LIMITED",
    value: "TOURING ELECTRA GLIDE ULTRA LIMITED"
  },
  {
    SERIAL: "63BY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER (BUILT)",
    label: "SOFTAIL SPRINGER (BUILT)",
    value: "SOFTAIL SPRINGER (BUILT)"
  },
  {
    SERIAL: "63BZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SWITCHBACK",
    label: "DYNA SWITCHBACK",
    value: "DYNA SWITCHBACK"
  },
  {
    SERIAL: "63CA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE ULTRA CUSTOM",
    label: "CVO ROAD GLIDE ULTRA CUSTOM",
    value: "CVO ROAD GLIDE ULTRA CUSTOM"
  },
  {
    SERIAL: "63CB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SCREAMING EAGLE ROAD GLIDE",
    label: "CVO SCREAMING EAGLE ROAD GLIDE",
    value: "CVO SCREAMING EAGLE ROAD GLIDE"
  },
  {
    SERIAL: "63CC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER IRON 883",
    label: "SPORTSTER IRON 883",
    value: "SPORTSTER IRON 883"
  },
  {
    SERIAL: "63CD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "WILDE GLIDE",
    label: "WILDE GLIDE",
    value: "WILDE GLIDE"
  },
  {
    SERIAL: "63CE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE CUSTOM",
    label: "DYNA SUPER GLIDE CUSTOM",
    value: "DYNA SUPER GLIDE CUSTOM"
  },
  {
    SERIAL: "63CF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE (BUILT)",
    label: "TOURING ROAD GLIDE (BUILT)",
    value: "TOURING ROAD GLIDE (BUILT)"
  },
  {
    SERIAL: "63CG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY SPECIAL",
    label: "SOFTAIL FATBOY SPECIAL",
    value: "SOFTAIL FATBOY SPECIAL"
  },
  {
    SERIAL: "63CH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA STREET GLIDE",
    label: "DYNA STREET GLIDE",
    value: "DYNA STREET GLIDE"
  },
  {
    SERIAL: "63CI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL - Built",
    label: "SOFTAIL - Built",
    value: "SOFTAIL - Built"
  },
  {
    SERIAL: "63CJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO FAT BOB",
    label: "CVO FAT BOB",
    value: "CVO FAT BOB"
  },
  {
    SERIAL: "63CK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE",
    label: "SOFTAIL HERITAGE",
    value: "SOFTAIL HERITAGE"
  },
  {
    SERIAL: "63CL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE DELUXE",
    label: "SOFTAIL HERITAGE DELUXE",
    value: "SOFTAIL HERITAGE DELUXE"
  },
  {
    SERIAL: "63CM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE POLICE",
    label: "ELECTRA GLIDE POLICE",
    value: "ELECTRA GLIDE POLICE"
  },
  {
    SERIAL: "63CN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET GLIDE - BUILT",
    label: "STREET GLIDE - BUILT",
    value: "STREET GLIDE - BUILT"
  },
  {
    SERIAL: "63CO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BLACKLINE",
    label: "SOFTAIL BLACKLINE",
    value: "SOFTAIL BLACKLINE"
  },
  {
    SERIAL: "63CP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SOFTAIL SPRINGER",
    label: "CVO SOFTAIL SPRINGER",
    value: "CVO SOFTAIL SPRINGER"
  },
  {
    SERIAL: "63CQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE ULTRA LIMITED",
    label: "ELECTRA GLIDE ULTRA LIMITED",
    value: "ELECTRA GLIDE ULTRA LIMITED"
  },
  {
    SERIAL: "63CR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE (BUILT)",
    label: "DYNA SUPER GLIDE (BUILT)",
    value: "DYNA SUPER GLIDE (BUILT)"
  },
  {
    SERIAL: "63CS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ULTRA CLASSIC (BUILT)",
    label: "ULTRA CLASSIC (BUILT)",
    value: "ULTRA CLASSIC (BUILT)"
  },
  {
    SERIAL: "63CT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE ULTRA (BUILT)",
    label: "ELECTRA GLIDE ULTRA (BUILT)",
    value: "ELECTRA GLIDE ULTRA (BUILT)"
  },
  {
    SERIAL: "63CU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DELUXE",
    label: "SOFTAIL DELUXE",
    value: "SOFTAIL DELUXE"
  },
  {
    SERIAL: "63CV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET ROD",
    label: "STREET ROD",
    value: "STREET ROD"
  },
  {
    SERIAL: "63CW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOY SPECIAL",
    label: "SOFTAIL FAT BOY SPECIAL",
    value: "SOFTAIL FAT BOY SPECIAL"
  },
  {
    SERIAL: "63CX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE",
    label: "TOURING STREET GLIDE",
    value: "TOURING STREET GLIDE"
  },
  {
    SERIAL: "63CY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER IRON 883",
    label: "SPORTSTER IRON 883",
    value: "SPORTSTER IRON 883"
  },
  {
    SERIAL: "63CZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD GLIDE-BUILT",
    label: "ROAD GLIDE-BUILT",
    value: "ROAD GLIDE-BUILT"
  },
  {
    SERIAL: "63DA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200X 48 (BUILT)",
    label: "SPORTSTER XL1200X 48 (BUILT)",
    value: "SPORTSTER XL1200X 48 (BUILT)"
  },
  {
    SERIAL: "63DB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SWITCHBACK",
    label: "DYNA SWITCHBACK",
    value: "DYNA SWITCHBACK"
  },
  {
    SERIAL: "63DC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SCREAMING EAGLE SPRINGER",
    label: "CVO SCREAMING EAGLE SPRINGER",
    value: "CVO SCREAMING EAGLE SPRINGER"
  },
  {
    SERIAL: "63DD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE (BUILT)",
    label: "CVO ROAD GLIDE (BUILT)",
    value: "CVO ROAD GLIDE (BUILT)"
  },
  {
    SERIAL: "63DE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BLACKLINE (FXS)",
    label: "SOFTAIL BLACKLINE (FXS)",
    value: "SOFTAIL BLACKLINE (FXS)"
  },
  {
    SERIAL: "63DF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA CVO (BUILT)",
    label: "DYNA CVO (BUILT)",
    value: "DYNA CVO (BUILT)"
  },
  {
    SERIAL: "63DG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO STREET GLIDE-BUILT",
    label: "CVO STREET GLIDE-BUILT",
    value: "CVO STREET GLIDE-BUILT"
  },
  {
    SERIAL: "63DH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING POLICE",
    label: "ROAD KING POLICE",
    value: "ROAD KING POLICE"
  },
  {
    SERIAL: "63DI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA CLASSIC - BUILT",
    label: "CVO ULTRA CLASSIC - BUILT",
    value: "CVO ULTRA CLASSIC - BUILT"
  },
  {
    SERIAL: "63DJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SCREAMING EAGLE STREET",
    label: "SCREAMING EAGLE STREET",
    value: "SCREAMING EAGLE STREET"
  },
  {
    SERIAL: "63DK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SCREAMING EAGLE",
    label: "CVO SCREAMING EAGLE",
    value: "CVO SCREAMING EAGLE"
  },
  {
    SERIAL: "63DL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SLIM",
    label: "SOFTAIL SLIM",
    value: "SOFTAIL SLIM"
  },
  {
    SERIAL: "63DM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TRI GLIDE ULTRA CLASSIC",
    label: "TRI GLIDE ULTRA CLASSIC",
    value: "TRI GLIDE ULTRA CLASSIC"
  },
  {
    SERIAL: "63DN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO BREAKOUT",
    label: "CVO BREAKOUT",
    value: "CVO BREAKOUT"
  },
  {
    SERIAL: "63DO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD KING",
    label: "CVO ROAD KING",
    value: "CVO ROAD KING"
  },
  {
    SERIAL: "63DP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SLIM",
    label: "SOFTAIL SLIM",
    value: "SOFTAIL SLIM"
  },
  {
    SERIAL: "63DQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SLIM - IMPORT",
    label: "SOFTAIL SLIM - IMPORT",
    value: "SOFTAIL SLIM - IMPORT"
  },
  {
    SERIAL: "63DR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING 105th",
    label: "ROAD KING 105th",
    value: "ROAD KING 105th"
  },
  {
    SERIAL: "63DS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD GLIDE 105th",
    label: "ROAD GLIDE 105th",
    value: "ROAD GLIDE 105th"
  },
  {
    SERIAL: "63DT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE - GREY",
    label: "CVO ROAD GLIDE - GREY",
    value: "CVO ROAD GLIDE - GREY"
  },
  {
    SERIAL: "63DU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY 110 ANNIVERSARY",
    label: "SOFTAIL FATBOY 110 ANNIVERSARY",
    value: "SOFTAIL FATBOY 110 ANNIVERSARY"
  },
  {
    SERIAL: "63DV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY 105th",
    label: "SOFTAIL FATBOY 105th",
    value: "SOFTAIL FATBOY 105th"
  },
  {
    SERIAL: "63DW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE-IMPORT",
    label: "TOURING STREET GLIDE-IMPORT",
    value: "TOURING STREET GLIDE-IMPORT"
  },
  {
    SERIAL: "63DX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200X FORTY-EIGHT",
    label: "SPORTSTER XL1200X FORTY-EIGHT",
    value: "SPORTSTER XL1200X FORTY-EIGHT"
  },
  {
    SERIAL: "63DY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE CUSTOM (BUILT)",
    label: "CVO ROAD GLIDE CUSTOM (BUILT)",
    value: "CVO ROAD GLIDE CUSTOM (BUILT)"
  },
  {
    SERIAL: "63DZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO STREET GLIDE SPECIAL (GREY)",
    label: "CVO STREET GLIDE SPECIAL (GREY)",
    value: "CVO STREET GLIDE SPECIAL (GREY)"
  },
  {
    SERIAL: "63EA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE CONVERTIBLE",
    label: "SOFTAIL HERITAGE CONVERTIBLE",
    value: "SOFTAIL HERITAGE CONVERTIBLE"
  },
  {
    SERIAL: "63EB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BREAKOUT FXSB",
    label: "SOFTAIL BREAKOUT FXSB",
    value: "SOFTAIL BREAKOUT FXSB"
  },
  {
    SERIAL: "63EC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200 CUSTOM (B)",
    label: "SPORTSTER XL1200 CUSTOM (B)",
    value: "SPORTSTER XL1200 CUSTOM (B)"
  },
  {
    SERIAL: "63ED0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200V SEVENTY-TWO",
    label: "SPORTSTER XL1200V SEVENTY-TWO",
    value: "SPORTSTER XL1200V SEVENTY-TWO"
  },
  {
    SERIAL: "63EE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY",
    label: "SOFTAIL FATBOY",
    value: "SOFTAIL FATBOY"
  },
  {
    SERIAL: "63EF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SOFTAIL",
    label: "CVO SOFTAIL",
    value: "CVO SOFTAIL"
  },
  {
    SERIAL: "63EG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL CROSS BONES (Built)",
    label: "SOFTAIL CROSS BONES (Built)",
    value: "SOFTAIL CROSS BONES (Built)"
  },
  {
    SERIAL: "63EH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE ULTRA",
    label: "TOURING ROAD GLIDE ULTRA",
    value: "TOURING ROAD GLIDE ULTRA"
  },
  {
    SERIAL: "63EI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL NIGHT TRAIN",
    label: "SOFTAIL NIGHT TRAIN",
    value: "SOFTAIL NIGHT TRAIN"
  },
  {
    SERIAL: "63EJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD KING",
    label: "CVO ROAD KING",
    value: "CVO ROAD KING"
  },
  {
    SERIAL: "63EK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200 CUSTOM (A)",
    label: "SPORTSTER XL1200 CUSTOM (A)",
    value: "SPORTSTER XL1200 CUSTOM (A)"
  },
  {
    SERIAL: "63EL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SOFTAIL SPRINGER 105th",
    label: "CVO SOFTAIL SPRINGER 105th",
    value: "CVO SOFTAIL SPRINGER 105th"
  },
  {
    SERIAL: "63EM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE - BUILT",
    label: "TOURING STREET GLIDE - BUILT",
    value: "TOURING STREET GLIDE - BUILT"
  },
  {
    SERIAL: "63EN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE CUSTOM",
    label: "CVO ROAD GLIDE CUSTOM",
    value: "CVO ROAD GLIDE CUSTOM"
  },
  {
    SERIAL: "63EO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROADKING - BUILT",
    label: "CVO ROADKING - BUILT",
    value: "CVO ROADKING - BUILT"
  },
  {
    SERIAL: "63EP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL883 ROADSTER",
    label: "SPORTSTER XL883 ROADSTER",
    value: "SPORTSTER XL883 ROADSTER"
  },
  {
    SERIAL: "63EQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL NIGHT TRAIN (จดประกอบ)",
    label: "SOFTAIL NIGHT TRAIN (จดประกอบ)",
    value: "SOFTAIL NIGHT TRAIN (จดประกอบ)"
  },
  {
    SERIAL: "63ER0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200X 48th (จดประกอบ)",
    label: "SPORTSTER XL1200X 48th (จดประกอบ)",
    value: "SPORTSTER XL1200X 48th (จดประกอบ)"
  },
  {
    SERIAL: "63ES0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA CLASSIC 110th (จดประกอบ)",
    label: "CVO ULTRA CLASSIC 110th (จดประกอบ)",
    value: "CVO ULTRA CLASSIC 110th (จดประกอบ)"
  },
  {
    SERIAL: "63ET0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DELUXE (จดประกอบ)",
    label: "SOFTAIL DELUXE (จดประกอบ)",
    value: "SOFTAIL DELUXE (จดประกอบ)"
  },
  {
    SERIAL: "63EU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "VRSC NIGHT ROD SPECIAL - GREY",
    label: "VRSC NIGHT ROD SPECIAL - GREY",
    value: "VRSC NIGHT ROD SPECIAL - GREY"
  },
  {
    SERIAL: "63EV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOWRIDER 35th-Built",
    label: "DYNA LOWRIDER 35th-Built",
    value: "DYNA LOWRIDER 35th-Built"
  },
  {
    SERIAL: "63EW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO LIMITED CUSTOM",
    label: "CVO LIMITED CUSTOM",
    value: "CVO LIMITED CUSTOM"
  },
  {
    SERIAL: "63EX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING (จดประกอบ)",
    label: "TOURING ROAD KING (จดประกอบ)",
    value: "TOURING ROAD KING (จดประกอบ)"
  },
  {
    SERIAL: "63EY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE SPECIAL",
    label: "TOURING STREET GLIDE SPECIAL",
    value: "TOURING STREET GLIDE SPECIAL"
  },
  {
    SERIAL: "63EZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA CLASSIC LOW",
    label: "TOURING ELECTRA GLIDE ULTRA CLASSIC LOW",
    value: "TOURING ELECTRA GLIDE ULTRA CLASSIC LOW"
  },
  {
    SERIAL: "63FA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA LIMITED",
    label: "TOURING ULTRA LIMITED",
    value: "TOURING ULTRA LIMITED"
  },
  {
    SERIAL: "63FB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE SPECIAL",
    label: "TOURING ROAD GLIDE SPECIAL",
    value: "TOURING ROAD GLIDE SPECIAL"
  },
  {
    SERIAL: "63FC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SWITCHBACK",
    label: "DYNA SWITCHBACK",
    value: "DYNA SWITCHBACK"
  },
  {
    SERIAL: "63FD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOW RIDER",
    label: "DYNA LOW RIDER",
    value: "DYNA LOW RIDER"
  },
  {
    SERIAL: "63FE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER SUPER LOW",
    label: "SPORTSTER SUPER LOW",
    value: "SPORTSTER SUPER LOW"
  },
  {
    SERIAL: "63FF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200V SEVENTY-TWO",
    label: "SPORTSTER XL1200V SEVENTY-TWO",
    value: "SPORTSTER XL1200V SEVENTY-TWO"
  },
  {
    SERIAL: "63FG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA CLASSIC(จดป ระกอบ)",
    label: "TOURING ELECTRA GLIDE ULTRA CLASSIC(จดป ระกอบ)",
    value: "TOURING ELECTRA GLIDE ULTRA CLASSIC(จดป ระกอบ)"
  },
  {
    SERIAL: "63FH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE",
    label: "CVO ROAD GLIDE",
    value: "CVO ROAD GLIDE"
  },
  {
    SERIAL: "63FI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA LTD-Built",
    label: "TOURING ELECTRA GLIDE ULTRA LTD-Built",
    value: "TOURING ELECTRA GLIDE ULTRA LTD-Built"
  },
  {
    SERIAL: "63FJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER (จดประกอบ)",
    label: "SOFTAIL SPRINGER (จดประกอบ)",
    value: "SOFTAIL SPRINGER (จดประกอบ)"
  },
  {
    SERIAL: "63FK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET XG750",
    label: "STREET XG750",
    value: "STREET XG750"
  },
  {
    SERIAL: "63FL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO STREET GLIDE (GREY)",
    label: "CVO STREET GLIDE (GREY)",
    value: "CVO STREET GLIDE (GREY)"
  },
  {
    SERIAL: "63FM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BLACKLINE -Built",
    label: "SOFTAIL BLACKLINE -Built",
    value: "SOFTAIL BLACKLINE -Built"
  },
  {
    SERIAL: "63FP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE (BUILT)",
    label: "TOURING ROAD GLIDE (BUILT)",
    value: "TOURING ROAD GLIDE (BUILT)"
  },
  {
    SERIAL: "63FQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO STREET GLIDE (FLHXSE)",
    label: "CVO STREET GLIDE (FLHXSE)",
    value: "CVO STREET GLIDE (FLHXSE)"
  },
  {
    SERIAL: "63FR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET XG750 LIMITED",
    label: "STREET XG750 LIMITED",
    value: "STREET XG750 LIMITED"
  },
  {
    SERIAL: "63FS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER (BUILT)",
    label: "SOFTAIL SPRINGER (BUILT)",
    value: "SOFTAIL SPRINGER (BUILT)"
  },
  {
    SERIAL: "63FT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "FATBOY (BUILT)",
    label: "FATBOY (BUILT)",
    value: "FATBOY (BUILT)"
  },
  {
    SERIAL: "63FU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 1200 NIGHTSTER-BUILT",
    label: "SPORTSTER 1200 NIGHTSTER-BUILT",
    value: "SPORTSTER 1200 NIGHTSTER-BUILT"
  },
  {
    SERIAL: "63FV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD KING (จดประกอบ)",
    label: "CVO ROAD KING (จดประกอบ)",
    value: "CVO ROAD KING (จดประกอบ)"
  },
  {
    SERIAL: "63FW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY - Built",
    label: "SOFTAIL FATBOY - Built",
    value: "SOFTAIL FATBOY - Built"
  },
  {
    SERIAL: "63FX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SOFTAIL DELUXE (FLSTNSE)",
    label: "CVO SOFTAIL DELUXE (FLSTNSE)",
    value: "CVO SOFTAIL DELUXE (FLSTNSE)"
  },
  {
    SERIAL: "63FY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROAD KING POLICE - BUILT",
    label: "ROAD KING POLICE - BUILT",
    value: "ROAD KING POLICE - BUILT"
  },
  {
    SERIAL: "63FZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE - BUILT",
    label: "SOFTAIL HERITAGE - BUILT",
    value: "SOFTAIL HERITAGE - BUILT"
  },
  {
    SERIAL: "63GA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "V-ROD - BUILT",
    label: "V-ROD - BUILT",
    value: "V-ROD - BUILT"
  },
  {
    SERIAL: "63GB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BREAKOUT 114 CBU",
    label: "SOFTAIL BREAKOUT 114 CBU",
    value: "SOFTAIL BREAKOUT 114 CBU"
  },
  {
    SERIAL: "63GC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TRI GLIDE ULTRA",
    label: "TRI GLIDE ULTRA",
    value: "TRI GLIDE ULTRA"
  },
  {
    SERIAL: "63GD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA LIMITED (FLHTKSE)",
    label: "CVO ULTRA LIMITED (FLHTKSE)",
    value: "CVO ULTRA LIMITED (FLHTKSE)"
  },
  {
    SERIAL: "63GE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE POLICE 100th-จดประกอบ",
    label: "ELECTRA GLIDE POLICE 100th-จดประกอบ",
    value: "ELECTRA GLIDE POLICE 100th-จดประกอบ"
  },
  {
    SERIAL: "63GF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO SOFTAIL SPRINGER (จดประกอบ)",
    label: "CVO SOFTAIL SPRINGER (จดประกอบ)",
    value: "CVO SOFTAIL SPRINGER (จดประกอบ)"
  },
  {
    SERIAL: "63GG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER SUPER LOW CKD",
    label: "SPORTSTER SUPER LOW CKD",
    value: "SPORTSTER SUPER LOW CKD"
  },
  {
    SERIAL: "63GH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE (BUILT)",
    label: "TOURING ROAD GLIDE (BUILT)",
    value: "TOURING ROAD GLIDE (BUILT)"
  },
  {
    SERIAL: "63GI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL CROSS BONES (จดประกอบ)",
    label: "SOFTAIL CROSS BONES (จดประกอบ)",
    value: "SOFTAIL CROSS BONES (จดประกอบ)"
  },
  {
    SERIAL: "63GJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE SPRINGER - BUILT",
    label: "SOFTAIL HERITAGE SPRINGER - BUILT",
    value: "SOFTAIL HERITAGE SPRINGER - BUILT"
  },
  {
    SERIAL: "63GK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO BREAKOUT (จดประกอบ)",
    label: "CVO BREAKOUT (จดประกอบ)",
    value: "CVO BREAKOUT (จดประกอบ)"
  },
  {
    SERIAL: "63GL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER IRON 883 - BUILT",
    label: "SPORTSTER IRON 883 - BUILT",
    value: "SPORTSTER IRON 883 - BUILT"
  },
  {
    SERIAL: "63GM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE (จดประกอบ)",
    label: "TOURING STREET GLIDE (จดประกอบ)",
    value: "TOURING STREET GLIDE (จดประกอบ)"
  },
  {
    SERIAL: "63GN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SLIM S",
    label: "SOFTAIL SLIM S",
    value: "SOFTAIL SLIM S"
  },
  {
    SERIAL: "63GO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE POLICE (Built)",
    label: "ELECTRA GLIDE POLICE (Built)",
    value: "ELECTRA GLIDE POLICE (Built)"
  },
  {
    SERIAL: "63GP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "NIGHT TRAIN (จดประกอบ)",
    label: "NIGHT TRAIN (จดประกอบ)",
    value: "NIGHT TRAIN (จดประกอบ)"
  },
  {
    SERIAL: "63GQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE ULTRA (จดประกอบ)",
    label: "CVO ROAD GLIDE ULTRA (จดประกอบ)",
    value: "CVO ROAD GLIDE ULTRA (จดประกอบ)"
  },
  {
    SERIAL: "63GR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING 100th-BUILT",
    label: "TOURING ROAD KING 100th-BUILT",
    value: "TOURING ROAD KING 100th-BUILT"
  },
  {
    SERIAL: "63GS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL NIGHT TRAIN (จดประกอบ)",
    label: "SOFTAIL NIGHT TRAIN (จดประกอบ)",
    value: "SOFTAIL NIGHT TRAIN (จดประกอบ)"
  },
  {
    SERIAL: "63GT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SCREAMING EAGLE FAT BOY - BUILT",
    label: "SCREAMING EAGLE FAT BOY - BUILT",
    value: "SCREAMING EAGLE FAT BOY - BUILT"
  },
  {
    SERIAL: "63GU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA 3 Wheels-Built",
    label: "TOURING ULTRA 3 Wheels-Built",
    value: "TOURING ULTRA 3 Wheels-Built"
  },
  {
    SERIAL: "63GV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO PRO STREET BREAKOUT",
    label: "CVO PRO STREET BREAKOUT",
    value: "CVO PRO STREET BREAKOUT"
  },
  {
    SERIAL: "63GW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE 103 - GREY",
    label: "TOURING ROAD GLIDE 103 - GREY",
    value: "TOURING ROAD GLIDE 103 - GREY"
  },
  {
    SERIAL: "63GX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA CLASSIC (จดประกอบ)",
    label: "CVO ULTRA CLASSIC (จดประกอบ)",
    value: "CVO ULTRA CLASSIC (จดประกอบ)"
  },
  {
    SERIAL: "63GY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE (จดประกอบ)",
    label: "DYNA SUPER GLIDE (จดประกอบ)",
    value: "DYNA SUPER GLIDE (จดประกอบ)"
  },
  {
    SERIAL: "63GZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SLIM - BUILT",
    label: "SOFTAIL SLIM - BUILT",
    value: "SOFTAIL SLIM - BUILT"
  },
  {
    SERIAL: "63HA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200V",
    label: "SPORTSTER XL1200V",
    value: "SPORTSTER XL1200V"
  },
  {
    SERIAL: "63HE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SWITCHBACK MODEL 2016",
    label: "DYNA SWITCHBACK MODEL 2016",
    value: "DYNA SWITCHBACK MODEL 2016"
  },
  {
    SERIAL: "63HI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOW RIDER",
    label: "DYNA LOW RIDER",
    value: "DYNA LOW RIDER"
  },
  {
    SERIAL: "63HJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE SPECIAL CUSTOM",
    label: "TOURING STREET GLIDE SPECIAL CUSTOM",
    value: "TOURING STREET GLIDE SPECIAL CUSTOM"
  },
  {
    SERIAL: "63HK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING CUSTOM",
    label: "TOURING ROAD KING CUSTOM",
    value: "TOURING ROAD KING CUSTOM"
  },
  {
    SERIAL: "63HL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200CX ROADSTER",
    label: "SPORTSTER XL1200CX ROADSTER",
    value: "SPORTSTER XL1200CX ROADSTER"
  },
  {
    SERIAL: "63HM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY 100th (Built)",
    label: "SOFTAIL FATBOY 100th (Built)",
    value: "SOFTAIL FATBOY 100th (Built)"
  },
  {
    SERIAL: "63HN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA SUPER GLIDE 100th (Built)",
    label: "DYNA SUPER GLIDE 100th (Built)",
    value: "DYNA SUPER GLIDE 100th (Built)"
  },
  {
    SERIAL: "63HO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA CLASSIC (GREY)",
    label: "CVO ULTRA CLASSIC (GREY)",
    value: "CVO ULTRA CLASSIC (GREY)"
  },
  {
    SERIAL: "63HP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ROCKER - BUILT",
    label: "ROCKER - BUILT",
    value: "ROCKER - BUILT"
  },
  {
    SERIAL: "63HQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOY S CUSTOM 1.8",
    label: "SOFTAIL FAT BOY S CUSTOM 1.8",
    value: "SOFTAIL FAT BOY S CUSTOM 1.8"
  },
  {
    SERIAL: "63HR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE ULTRA CLASSIC 110",
    label: "TOURING ELECTRA GLIDE ULTRA CLASSIC 110",
    value: "TOURING ELECTRA GLIDE ULTRA CLASSIC 110"
  },
  {
    SERIAL: "63HT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA LIMITED LOW",
    label: "TOURING ULTRA LIMITED LOW",
    value: "TOURING ULTRA LIMITED LOW"
  },
  {
    SERIAL: "63HU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE ULTRA",
    label: "TOURING ROAD GLIDE ULTRA",
    value: "TOURING ROAD GLIDE ULTRA"
  },
  {
    SERIAL: "63HV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "V-ROD NIGHT ROD SPECIAL (2016)",
    label: "V-ROD NIGHT ROD SPECIAL (2016)",
    value: "V-ROD NIGHT ROD SPECIAL (2016)"
  },
  {
    SERIAL: "63HW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200CX ROADSTER",
    label: "SPORTSTER XL1200CX ROADSTER",
    value: "SPORTSTER XL1200CX ROADSTER"
  },
  {
    SERIAL: "63HX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA LIMITED- Built",
    label: "TOURING ULTRA LIMITED- Built",
    value: "TOURING ULTRA LIMITED- Built"
  },
  {
    SERIAL: "63HY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL883N (CANDY COLOR)",
    label: "SPORTSTER XL883N (CANDY COLOR)",
    value: "SPORTSTER XL883N (CANDY COLOR)"
  },
  {
    SERIAL: "63HZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOW RIDER SPECIAL",
    label: "DYNA LOW RIDER SPECIAL",
    value: "DYNA LOW RIDER SPECIAL"
  },
  {
    SERIAL: "63IA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200CX ROADSTER.",
    label: "SPORTSTER XL1200CX ROADSTER.",
    value: "SPORTSTER XL1200CX ROADSTER."
  },
  {
    SERIAL: "63IB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA CLASSIC - Built",
    label: "TOURING ULTRA CLASSIC - Built",
    value: "TOURING ULTRA CLASSIC - Built"
  },
  {
    SERIAL: "63IC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE (BUILT)",
    label: "TOURING ELECTRA GLIDE (BUILT)",
    value: "TOURING ELECTRA GLIDE (BUILT)"
  },
  {
    SERIAL: "63ID0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER 1200 R (BUILT)",
    label: "SPORTSTER 1200 R (BUILT)",
    value: "SPORTSTER 1200 R (BUILT)"
  },
  {
    SERIAL: "63IE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE (จดประกอบ)",
    label: "SOFTAIL HERITAGE (จดประกอบ)",
    value: "SOFTAIL HERITAGE (จดประกอบ)"
  },
  {
    SERIAL: "63IF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET ROD XG750A",
    label: "STREET ROD XG750A",
    value: "STREET ROD XG750A"
  },
  {
    SERIAL: "63IG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER",
    label: "SOFTAIL SPRINGER",
    value: "SOFTAIL SPRINGER"
  },
  {
    SERIAL: "63IH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DEUCE - BUILT",
    label: "SOFTAIL DEUCE - BUILT",
    value: "SOFTAIL DEUCE - BUILT"
  },
  {
    SERIAL: "63II0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE - GRAY",
    label: "TOURING ROAD GLIDE - GRAY",
    value: "TOURING ROAD GLIDE - GRAY"
  },
  {
    SERIAL: "63IJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE SPRINGER",
    label: "SOFTAIL HERITAGE SPRINGER",
    value: "SOFTAIL HERITAGE SPRINGER"
  },
  {
    SERIAL: "63IK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET GLIDE - BUILT",
    label: "STREET GLIDE - BUILT",
    value: "STREET GLIDE - BUILT"
  },
  {
    SERIAL: "63IL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL STREET BOB (FXBB)",
    label: "SOFTAIL STREET BOB (FXBB)",
    value: "SOFTAIL STREET BOB (FXBB)"
  },
  {
    SERIAL: "63IM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOB CBU",
    label: "SOFTAIL FAT BOB CBU",
    value: "SOFTAIL FAT BOB CBU"
  },
  {
    SERIAL: "63IN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOB",
    label: "SOFTAIL FAT BOB",
    value: "SOFTAIL FAT BOB"
  },
  {
    SERIAL: "63IO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ROAD GLIDE",
    label: "CVO ROAD GLIDE",
    value: "CVO ROAD GLIDE"
  },
  {
    SERIAL: "63IP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL LOW RIDER",
    label: "SOFTAIL LOW RIDER",
    value: "SOFTAIL LOW RIDER"
  },
  {
    SERIAL: "63IQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING SPECIAL",
    label: "TOURING ROAD KING SPECIAL",
    value: "TOURING ROAD KING SPECIAL"
  },
  {
    SERIAL: "63IR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE SPECIAL ANNIVERSAR Y",
    label: "TOURING STREET GLIDE SPECIAL ANNIVERSAR Y",
    value: "TOURING STREET GLIDE SPECIAL ANNIVERSAR Y"
  },
  {
    SERIAL: "63IS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER GREY",
    label: "SOFTAIL SPRINGER GREY",
    value: "SOFTAIL SPRINGER GREY"
  },
  {
    SERIAL: "63IT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE CLASSIC 1900 CC",
    label: "SOFTAIL HERITAGE CLASSIC 1900 CC",
    value: "SOFTAIL HERITAGE CLASSIC 1900 CC"
  },
  {
    SERIAL: "63IU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY (จดประกอบ)",
    label: "SOFTAIL FATBOY (จดประกอบ)",
    value: "SOFTAIL FATBOY (จดประกอบ)"
  },
  {
    SERIAL: "63IV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DEUCE (BUILT)",
    label: "SOFTAIL DEUCE (BUILT)",
    value: "SOFTAIL DEUCE (BUILT)"
  },
  {
    SERIAL: "63IW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPORT GLIDE CBU",
    label: "SOFTAIL SPORT GLIDE CBU",
    value: "SOFTAIL SPORT GLIDE CBU"
  },
  {
    SERIAL: "63IX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA CLASSIC 110TH 3 WHEELS-BUILT",
    label: "CVO ULTRA CLASSIC 110TH 3 WHEELS-BUILT",
    value: "CVO ULTRA CLASSIC 110TH 3 WHEELS-BUILT"
  },
  {
    SERIAL: "63IY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200 115TH",
    label: "SPORTSTER XL1200 115TH",
    value: "SPORTSTER XL1200 115TH"
  },
  {
    SERIAL: "63IZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE",
    label: "TOURING STREET GLIDE",
    value: "TOURING STREET GLIDE"
  },
  {
    SERIAL: "63JA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL LOW RIDER",
    label: "SOFTAIL LOW RIDER",
    value: "SOFTAIL LOW RIDER"
  },
  {
    SERIAL: "63JB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BREAKOUT (FXBR)",
    label: "SOFTAIL BREAKOUT (FXBR)",
    value: "SOFTAIL BREAKOUT (FXBR)"
  },
  {
    SERIAL: "63JC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE STANDARD",
    label: "TOURING ELECTRA GLIDE STANDARD",
    value: "TOURING ELECTRA GLIDE STANDARD"
  },
  {
    SERIAL: "63JD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "STREET BOB 114",
    label: "STREET BOB 114",
    value: "STREET BOB 114"
  },
  {
    SERIAL: "63KA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER IRON 883 CKD",
    label: "SPORTSTER IRON 883 CKD",
    value: "SPORTSTER IRON 883 CKD"
  },
  {
    SERIAL: "63KB0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200 CUSTOM CKD",
    label: "SPORTSTER XL1200 CUSTOM CKD",
    value: "SPORTSTER XL1200 CUSTOM CKD"
  },
  {
    SERIAL: "63KC0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200CX ROADSTER CKD",
    label: "SPORTSTER XL1200CX ROADSTER CKD",
    value: "SPORTSTER XL1200CX ROADSTER CKD"
  },
  {
    SERIAL: "63KD0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200X FORTY EIGHT CKD",
    label: "SPORTSTER XL1200X FORTY EIGHT CKD",
    value: "SPORTSTER XL1200X FORTY EIGHT CKD"
  },
  {
    SERIAL: "63KE0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING 114 CKD",
    label: "TOURING ROAD KING 114 CKD",
    value: "TOURING ROAD KING 114 CKD"
  },
  {
    SERIAL: "63KF0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING SPECIAL 114 CKD",
    label: "TOURING ROAD KING SPECIAL 114 CKD",
    value: "TOURING ROAD KING SPECIAL 114 CKD"
  },
  {
    SERIAL: "63KG0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE 114 CKD",
    label: "TOURING STREET GLIDE 114 CKD",
    value: "TOURING STREET GLIDE 114 CKD"
  },
  {
    SERIAL: "63KH0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE SPECIAL 114 CKD",
    label: "TOURING STREET GLIDE SPECIAL 114 CKD",
    value: "TOURING STREET GLIDE SPECIAL 114 CKD"
  },
  {
    SERIAL: "63KI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE 114 CKD",
    label: "TOURING ROAD GLIDE 114 CKD",
    value: "TOURING ROAD GLIDE 114 CKD"
  },
  {
    SERIAL: "63KJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE SPECIAL 114 CKD",
    label: "TOURING ROAD GLIDE SPECIAL 114 CKD",
    value: "TOURING ROAD GLIDE SPECIAL 114 CKD"
  },
  {
    SERIAL: "63KK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA LIMITED 114 CKD",
    label: "TOURING ULTRA LIMITED 114 CKD",
    value: "TOURING ULTRA LIMITED 114 CKD"
  },
  {
    SERIAL: "63KL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA LIMITED LOW 114 CKD",
    label: "TOURING ULTRA LIMITED LOW 114 CKD",
    value: "TOURING ULTRA LIMITED LOW 114 CKD"
  },
  {
    SERIAL: "63KM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE ULTRA 114 CKD",
    label: "TOURING ROAD GLIDE ULTRA 114 CKD",
    value: "TOURING ROAD GLIDE ULTRA 114 CKD"
  },
  {
    SERIAL: "63KN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL STREET BOB CKD",
    label: "SOFTAIL STREET BOB CKD",
    value: "SOFTAIL STREET BOB CKD"
  },
  {
    SERIAL: "63KO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL LOW RIDER CKD",
    label: "SOFTAIL LOW RIDER CKD",
    value: "SOFTAIL LOW RIDER CKD"
  },
  {
    SERIAL: "63KP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPORT GLIDE CKD",
    label: "SOFTAIL SPORT GLIDE CKD",
    value: "SOFTAIL SPORT GLIDE CKD"
  },
  {
    SERIAL: "63KQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOB 114 CKD",
    label: "SOFTAIL FAT BOB 114 CKD",
    value: "SOFTAIL FAT BOB 114 CKD"
  },
  {
    SERIAL: "63KR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE CLASSIC 114 CKD",
    label: "SOFTAIL HERITAGE CLASSIC 114 CKD",
    value: "SOFTAIL HERITAGE CLASSIC 114 CKD"
  },
  {
    SERIAL: "63KS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BREAKOUT 114 CKD",
    label: "SOFTAIL BREAKOUT 114 CKD",
    value: "SOFTAIL BREAKOUT 114 CKD"
  },
  {
    SERIAL: "63KT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOY 114 CKD",
    label: "SOFTAIL FAT BOY 114 CKD",
    value: "SOFTAIL FAT BOY 114 CKD"
  },
  {
    SERIAL: "63KU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "OVER 150",
    label: "OVER 150",
    value: "OVER 150"
  },
  {
    SERIAL: "63KV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA LOW RIDER S",
    label: "DYNA LOW RIDER S",
    value: "DYNA LOW RIDER S"
  },
  {
    SERIAL: "63KW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE SPRINGER - GRAY",
    label: "SOFTAIL HERITAGE SPRINGER - GRAY",
    value: "SOFTAIL HERITAGE SPRINGER - GRAY"
  },
  {
    SERIAL: "63KX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO FAT BOY - BUILT",
    label: "CVO FAT BOY - BUILT",
    value: "CVO FAT BOY - BUILT"
  },
  {
    SERIAL: "63KY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "CVO ULTRA LIMITED (จดประกอบ)",
    label: "CVO ULTRA LIMITED (จดประกอบ)",
    value: "CVO ULTRA LIMITED (จดประกอบ)"
  },
  {
    SERIAL: "63KZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORT GLIDE",
    label: "SPORT GLIDE",
    value: "SPORT GLIDE"
  },
  {
    SERIAL: "63NN0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DELUXE -GREY",
    label: "SOFTAIL DELUXE -GREY",
    value: "SOFTAIL DELUXE -GREY"
  },
  {
    SERIAL: "63OI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA LIMITED ANNIVERSARY",
    label: "TOURING ULTRA LIMITED ANNIVERSARY",
    value: "TOURING ULTRA LIMITED ANNIVERSARY"
  },
  {
    SERIAL: "63OJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD GLIDE SPECIAL STOCK 2018",
    label: "TOURING ROAD GLIDE SPECIAL STOCK 2018",
    value: "TOURING ROAD GLIDE SPECIAL STOCK 2018"
  },
  {
    SERIAL: "63OK0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE STOCK 2018",
    label: "TOURING STREET GLIDE STOCK 2018",
    value: "TOURING STREET GLIDE STOCK 2018"
  },
  {
    SERIAL: "63OL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING",
    label: "TOURING ROAD KING",
    value: "TOURING ROAD KING"
  },
  {
    SERIAL: "63OM0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE STOCK 2017",
    label: "TOURING STREET GLIDE STOCK 2017",
    value: "TOURING STREET GLIDE STOCK 2017"
  },
  {
    SERIAL: "63ON0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ULTRA LIMITED STOCK 2018",
    label: "TOURING ULTRA LIMITED STOCK 2018",
    value: "TOURING ULTRA LIMITED STOCK 2018"
  },
  {
    SERIAL: "63OO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BREAKOUT 107 STOCK-2018",
    label: "SOFTAIL BREAKOUT 107 STOCK-2018",
    value: "SOFTAIL BREAKOUT 107 STOCK-2018"
  },
  {
    SERIAL: "63OP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ELECTRA GLIDE CKD",
    label: "TOURING ELECTRA GLIDE CKD",
    value: "TOURING ELECTRA GLIDE CKD"
  },
  {
    SERIAL: "63OQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING ROAD KING SPECIAL CKD",
    label: "TOURING ROAD KING SPECIAL CKD",
    value: "TOURING ROAD KING SPECIAL CKD"
  },
  {
    SERIAL: "63OR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER",
    label: "SPORTSTER",
    value: "SPORTSTER"
  },
  {
    SERIAL: "63OS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE - จดประกอบ",
    label: "TOURING STREET GLIDE - จดประกอบ",
    value: "TOURING STREET GLIDE - จดประกอบ"
  },
  {
    SERIAL: "63OT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "TOURING STREET GLIDE SPECIA STOCK-2018",
    label: "TOURING STREET GLIDE SPECIA STOCK-2018",
    value: "TOURING STREET GLIDE SPECIA STOCK-2018"
  },
  {
    SERIAL: "63PL0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL BADBOY",
    label: "SOFTAIL BADBOY",
    value: "SOFTAIL BADBOY"
  },
  {
    SERIAL: "63PO0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ULTRA CLASSIC 3 WHEELS-BUILT",
    label: "ULTRA CLASSIC 3 WHEELS-BUILT",
    value: "ULTRA CLASSIC 3 WHEELS-BUILT"
  },
  {
    SERIAL: "63PP0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOY 114 ANNIVERSARY - V",
    label: "SOFTAIL FAT BOY 114 ANNIVERSARY - V",
    value: "SOFTAIL FAT BOY 114 ANNIVERSARY - V"
  },
  {
    SERIAL: "63PQ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE CLASSIC 114 115TH ANNIVERSARY",
    label: "SOFTAIL HERITAGE CLASSIC 114 115TH ANNIVERSARY",
    value: "SOFTAIL HERITAGE CLASSIC 114 115TH ANNIVERSARY"
  },
  {
    SERIAL: "63PR0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE 100TH - BUILT",
    label: "SOFTAIL HERITAGE 100TH - BUILT",
    value: "SOFTAIL HERITAGE 100TH - BUILT"
  },
  {
    SERIAL: "63PS0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FAT BOY 115TH",
    label: "SOFTAIL FAT BOY 115TH",
    value: "SOFTAIL FAT BOY 115TH"
  },
  {
    SERIAL: "63PT0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL CUSTOM - BUILT",
    label: "SOFTAIL CUSTOM - BUILT",
    value: "SOFTAIL CUSTOM - BUILT"
  },
  {
    SERIAL: "63PU0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FATBOY SCREAMING - BUILT",
    label: "SOFTAIL FATBOY SCREAMING - BUILT",
    value: "SOFTAIL FATBOY SCREAMING - BUILT"
  },
  {
    SERIAL: "63PV0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL DEUCE 100TH - BUILT",
    label: "SOFTAIL DEUCE 100TH - BUILT",
    value: "SOFTAIL DEUCE 100TH - BUILT"
  },
  {
    SERIAL: "63PW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER BADBOY",
    label: "SOFTAIL SPRINGER BADBOY",
    value: "SOFTAIL SPRINGER BADBOY"
  },
  {
    SERIAL: "63PX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL FXDR 114 CKD",
    label: "SOFTAIL FXDR 114 CKD",
    value: "SOFTAIL FXDR 114 CKD"
  },
  {
    SERIAL: "63PY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL SPRINGER CROSS BONES",
    label: "SOFTAIL SPRINGER CROSS BONES",
    value: "SOFTAIL SPRINGER CROSS BONES"
  },
  {
    SERIAL: "63PZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SOFTAIL HERITAGE SPRINGER 100TH -GRAY",
    label: "SOFTAIL HERITAGE SPRINGER 100TH -GRAY",
    value: "SOFTAIL HERITAGE SPRINGER 100TH -GRAY"
  },
  {
    SERIAL: "63QA0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "DYNA FAT BOB STOCK 2015",
    label: "DYNA FAT BOB STOCK 2015",
    value: "DYNA FAT BOB STOCK 2015"
  },
  {
    SERIAL: "63RW0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER SEVENTY-TWO MODEL 2015",
    label: "SPORTSTER SEVENTY-TWO MODEL 2015",
    value: "SPORTSTER SEVENTY-TWO MODEL 2015"
  },
  {
    SERIAL: "63RX0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER XL1200CX ROADSTER STOCK2017",
    label: "SPORTSTER XL1200CX ROADSTER STOCK2017",
    value: "SPORTSTER XL1200CX ROADSTER STOCK2017"
  },
  {
    SERIAL: "63RY0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER IRON 1200 XL1200NS CKD",
    label: "SPORTSTER IRON 1200 XL1200NS CKD",
    value: "SPORTSTER IRON 1200 XL1200NS CKD"
  },
  {
    SERIAL: "63RZ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "SPORTSTER IRON 883 STOCK 2018",
    label: "SPORTSTER IRON 883 STOCK 2018",
    value: "SPORTSTER IRON 883 STOCK 2018"
  },
  {
    SERIAL: "63UI0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE ULTRA CLASSIC - BUILT",
    label: "ELECTRA GLIDE ULTRA CLASSIC - BUILT",
    value: "ELECTRA GLIDE ULTRA CLASSIC - BUILT"
  },
  {
    SERIAL: "63UJ0000026",
    MAKE_NAME: "HARLEY DAVIDSON",
    MODEL_NAME: "ELECTRA GLIDE ULTRA 100TH - BUILT",
    label: "ELECTRA GLIDE ULTRA 100TH - BUILT",
    value: "ELECTRA GLIDE ULTRA 100TH - BUILT"
  },
  {
    SERIAL: "64000000026",
    MAKE_NAME: "BAJAJ",
    MODEL_NAME: "BAJAJ",
    label: "BAJAJ",
    value: "BAJAJ"
  },
  {
    SERIAL: "64010000026",
    MAKE_NAME: "BAJAJ",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "65000000026",
    MAKE_NAME: "SIMCA",
    MODEL_NAME: "SIMCA",
    label: "SIMCA",
    value: "SIMCA"
  },
  {
    SERIAL: "66000000026",
    MAKE_NAME: "BACO",
    MODEL_NAME: "BACO",
    label: "BACO",
    value: "BACO"
  },
  {
    SERIAL: "66010000026",
    MAKE_NAME: "BACO",
    MODEL_NAME: "150 cc",
    label: "150 cc",
    value: "150 cc"
  },
  {
    SERIAL: "66020000026",
    MAKE_NAME: "BACO",
    MODEL_NAME: "145 CC",
    label: "145 CC",
    value: "145 CC"
  },
  {
    SERIAL: "67000000026",
    MAKE_NAME: "OLDSMOBILE",
    MODEL_NAME: "OLDSMOBILE",
    label: "OLDSMOBILE",
    value: "OLDSMOBILE"
  },
  {
    SERIAL: "67010000026",
    MAKE_NAME: "OLDSMOBILE",
    MODEL_NAME: "5800 CC",
    label: "5800 CC",
    value: "5800 CC"
  },
  {
    SERIAL: "68000000026",
    MAKE_NAME: "สามล้อเครื่อง",
    MODEL_NAME: "สามล้อเครื่อง",
    label: "สามล้อเครื่อง",
    value: "สามล้อเครื่อง"
  },
  {
    SERIAL: "69000000026",
    MAKE_NAME: "CATER PILLAR",
    MODEL_NAME: "CATER PILLAR",
    label: "CATER PILLAR",
    value: "CATER PILLAR"
  },
  {
    SERIAL: "69010000026",
    MAKE_NAME: "CATER PILLAR",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "70000000026",
    MAKE_NAME: "KOMATSU",
    MODEL_NAME: "KOMATSU",
    label: "KOMATSU",
    value: "KOMATSU"
  },
  {
    SERIAL: "70010000026",
    MAKE_NAME: "KOMATSU",
    MODEL_NAME: "PC200-7",
    label: "PC200-7",
    value: "PC200-7"
  },
  {
    SERIAL: "70020000026",
    MAKE_NAME: "KOMATSU",
    MODEL_NAME: "CAR",
    label: "CAR",
    value: "CAR"
  },
  {
    SERIAL: "70030000026",
    MAKE_NAME: "KOMATSU",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "71000000026",
    MAKE_NAME: "SAKAI",
    MODEL_NAME: "SAKAI",
    label: "SAKAI",
    value: "SAKAI"
  },
  {
    SERIAL: "71010000026",
    MAKE_NAME: "SAKAI",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "72000000026",
    MAKE_NAME: "TRACTOR",
    MODEL_NAME: "TRACTOR",
    label: "TRACTOR",
    value: "TRACTOR"
  },
  {
    SERIAL: "72010000026",
    MAKE_NAME: "TRACTOR",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "73000000026",
    MAKE_NAME: "DYNAPAC",
    MODEL_NAME: "DYNAPAC",
    label: "DYNAPAC",
    value: "DYNAPAC"
  },
  {
    SERIAL: "74000000026",
    MAKE_NAME: "STRIDE",
    MODEL_NAME: "STRIDE",
    label: "STRIDE",
    value: "STRIDE"
  },
  {
    SERIAL: "75000000026",
    MAKE_NAME: "SCOOTER",
    MODEL_NAME: "SCOOTER",
    label: "SCOOTER",
    value: "SCOOTER"
  },
  {
    SERIAL: "76000000026",
    MAKE_NAME: "KUBOTA",
    MODEL_NAME: "KUBOTA",
    label: "KUBOTA",
    value: "KUBOTA"
  },
  {
    SERIAL: "76010000026",
    MAKE_NAME: "KUBOTA",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "76020000026",
    MAKE_NAME: "KUBOTA",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "77000000026",
    MAKE_NAME: "PREMAS",
    MODEL_NAME: "PREMAS",
    label: "PREMAS",
    value: "PREMAS"
  },
  {
    SERIAL: "78000000026",
    MAKE_NAME: "BRIDGE STONE",
    MODEL_NAME: "BRIDGE STONE",
    label: "BRIDGE STONE",
    value: "BRIDGE STONE"
  },
  {
    SERIAL: "79000000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "SCANIA",
    label: "SCANIA",
    value: "SCANIA"
  },
  {
    SERIAL: "79010000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "21000 ตัน",
    label: "21000 ตัน",
    value: "21000 ตัน"
  },
  {
    SERIAL: "79020000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "T114",
    label: "T114",
    value: "T114"
  },
  {
    SERIAL: "79030000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "16000",
    label: "16000",
    value: "16000"
  },
  {
    SERIAL: "79040000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "หัวลาก",
    label: "หัวลาก",
    value: "หัวลาก"
  },
  {
    SERIAL: "79050000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "79070000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "P380LA6X (หัวลาก)",
    label: "P380LA6X (หัวลาก)",
    value: "P380LA6X (หัวลาก)"
  },
  {
    SERIAL: "79080000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "P410LA",
    label: "P410LA",
    value: "P410LA"
  },
  {
    SERIAL: "79090000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "79100000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "P360LA",
    label: "P360LA",
    value: "P360LA"
  },
  {
    SERIAL: "79110000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "79120000026",
    MAKE_NAME: "SCANIA",
    MODEL_NAME: "K250IB",
    label: "K250IB",
    value: "K250IB"
  },
  {
    SERIAL: "80000000026",
    MAKE_NAME: "CAGIVA",
    MODEL_NAME: "CAGIVA",
    label: "CAGIVA",
    value: "CAGIVA"
  },
  {
    SERIAL: "81000000026",
    MAKE_NAME: "JAFLIN",
    MODEL_NAME: "JAFLIN",
    label: "JAFLIN",
    value: "JAFLIN"
  },
  {
    SERIAL: "83000000026",
    MAKE_NAME: "VMC",
    MODEL_NAME: "VMC",
    label: "VMC",
    value: "VMC"
  },
  {
    SERIAL: "83010000026",
    MAKE_NAME: "VMC",
    MODEL_NAME: "VMC",
    label: "VMC",
    value: "VMC"
  },
  {
    SERIAL: "84000000026",
    MAKE_NAME: "IWAFUJI",
    MODEL_NAME: "IWAFUJI",
    label: "IWAFUJI",
    value: "IWAFUJI"
  },
  {
    SERIAL: "85000000026",
    MAKE_NAME: "YANMA",
    MODEL_NAME: "YANMA",
    label: "YANMA",
    value: "YANMA"
  },
  {
    SERIAL: "86000000026",
    MAKE_NAME: "SINGER",
    MODEL_NAME: "SINGER",
    label: "SINGER",
    value: "SINGER"
  },
  {
    SERIAL: "87000000026",
    MAKE_NAME: "PONTIAC",
    MODEL_NAME: "PONTIAC",
    label: "PONTIAC",
    value: "PONTIAC"
  },
  {
    SERIAL: "87010000026",
    MAKE_NAME: "PONTIAC",
    MODEL_NAME: "CLASSIC",
    label: "CLASSIC",
    value: "CLASSIC"
  },
  {
    SERIAL: "88000000026",
    MAKE_NAME: "BUCHING",
    MODEL_NAME: "BUCHING",
    label: "BUCHING",
    value: "BUCHING"
  },
  {
    SERIAL: "89000000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "MG",
    label: "MG",
    value: "MG"
  },
  {
    SERIAL: "89010000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "1997",
    label: "1997",
    value: "1997"
  },
  {
    SERIAL: "89020000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T D",
    label: "6 5D 1.8T D",
    value: "6 5D 1.8T D"
  },
  {
    SERIAL: "89030000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T D SUNROOF",
    label: "6 5D 1.8T D SUNROOF",
    value: "6 5D 1.8T D SUNROOF"
  },
  {
    SERIAL: "89040000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T X",
    label: "6 5D 1.8T X",
    value: "6 5D 1.8T X"
  },
  {
    SERIAL: "89050000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T X SUNROOF",
    label: "6 5D 1.8T X SUNROOF",
    value: "6 5D 1.8T X SUNROOF"
  },
  {
    SERIAL: "89060000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 4D 1.8L C",
    label: "6 4D 1.8L C",
    value: "6 4D 1.8L C"
  },
  {
    SERIAL: "89070000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 4D 1.8L D",
    label: "6 4D 1.8L D",
    value: "6 4D 1.8L D"
  },
  {
    SERIAL: "89080000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 4D 1.8L D SUNROOF",
    label: "6 4D 1.8L D SUNROOF",
    value: "6 4D 1.8L D SUNROOF"
  },
  {
    SERIAL: "89090000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 4D 1.8T X",
    label: "6 4D 1.8T X",
    value: "6 4D 1.8T X"
  },
  {
    SERIAL: "89100000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 4D 1.8T X SUNROOF",
    label: "6 4D 1.8T X SUNROOF",
    value: "6 4D 1.8T X SUNROOF"
  },
  {
    SERIAL: "89110000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T D BLACK TOP DESIGN",
    label: "6 5D 1.8T D BLACK TOP DESIGN",
    value: "6 5D 1.8T D BLACK TOP DESIGN"
  },
  {
    SERIAL: "89120000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T D SUNROOF BLACK TOP DESIGN",
    label: "6 5D 1.8T D SUNROOF BLACK TOP DESIGN",
    value: "6 5D 1.8T D SUNROOF BLACK TOP DESIGN"
  },
  {
    SERIAL: "89130000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T X BLACK TOP DESIGN",
    label: "6 5D 1.8T X BLACK TOP DESIGN",
    value: "6 5D 1.8T X BLACK TOP DESIGN"
  },
  {
    SERIAL: "89140000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T X SUNROOF BLACK TOP DESIGN",
    label: "6 5D 1.8T X SUNROOF BLACK TOP DESIGN",
    value: "6 5D 1.8T X SUNROOF BLACK TOP DESIGN"
  },
  {
    SERIAL: "89150000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 5D C",
    label: "3 5D C",
    value: "3 5D C"
  },
  {
    SERIAL: "89160000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 5D D",
    label: "3 5D D",
    value: "3 5D D"
  },
  {
    SERIAL: "89170000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 5D X SUNROOF",
    label: "3 5D X SUNROOF",
    value: "3 5D X SUNROOF"
  },
  {
    SERIAL: "89180000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 XROSS X SUNROOF",
    label: "3 XROSS X SUNROOF",
    value: "3 XROSS X SUNROOF"
  },
  {
    SERIAL: "89190000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "5 1.5L D",
    label: "5 1.5L D",
    value: "5 1.5L D"
  },
  {
    SERIAL: "89200000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "5 1.5L X SUNROOF",
    label: "5 1.5L X SUNROOF",
    value: "5 1.5L X SUNROOF"
  },
  {
    SERIAL: "89210000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "5 1.5L D TURBO",
    label: "5 1.5L D TURBO",
    value: "5 1.5L D TURBO"
  },
  {
    SERIAL: "89220000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "5 1.5L X TURBO SUNROOF",
    label: "5 1.5L X TURBO SUNROOF",
    value: "5 1.5L X TURBO SUNROOF"
  },
  {
    SERIAL: "89230000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "GS 2.0 T D 2WD",
    label: "GS 2.0 T D 2WD",
    value: "GS 2.0 T D 2WD"
  },
  {
    SERIAL: "89240000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "GS 2.0 T X AWD SUNROOF",
    label: "GS 2.0 T X AWD SUNROOF",
    value: "GS 2.0 T X AWD SUNROOF"
  },
  {
    SERIAL: "89250000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "6 5D 1.8T C",
    label: "6 5D 1.8T C",
    value: "6 5D 1.8T C"
  },
  {
    SERIAL: "89260000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "GS 1.5T D",
    label: "GS 1.5T D",
    value: "GS 1.5T D"
  },
  {
    SERIAL: "89270000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "GS 1.5T X SUNROOF",
    label: "GS 1.5T X SUNROOF",
    value: "GS 1.5T X SUNROOF"
  },
  {
    SERIAL: "89280000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 5D V SUNROOF",
    label: "3 5D V SUNROOF",
    value: "3 5D V SUNROOF"
  },
  {
    SERIAL: "89290000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "ZS 1.5 C",
    label: "ZS 1.5 C",
    value: "ZS 1.5 C"
  },
  {
    SERIAL: "89300000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "ZS 1.5 D",
    label: "ZS 1.5 D",
    value: "ZS 1.5 D"
  },
  {
    SERIAL: "89310000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "ZS 1.5 X",
    label: "ZS 1.5 X",
    value: "ZS 1.5 X"
  },
  {
    SERIAL: "89320000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "WA",
    label: "WA",
    value: "WA"
  },
  {
    SERIAL: "89400000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "V80 M",
    label: "V80 M",
    value: "V80 M"
  },
  {
    SERIAL: "89410000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "V80 A",
    label: "V80 A",
    value: "V80 A"
  },
  {
    SERIAL: "89420000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER GIANT CAB 2.0 C",
    label: "EXTENDER GIANT CAB 2.0 C",
    value: "EXTENDER GIANT CAB 2.0 C"
  },
  {
    SERIAL: "89430000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER GIANT CAB 2.0 D",
    label: "EXTENDER GIANT CAB 2.0 D",
    value: "EXTENDER GIANT CAB 2.0 D"
  },
  {
    SERIAL: "89440000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER GIANT CAB GRAND 2.0 D M",
    label: "EXTENDER GIANT CAB GRAND 2.0 D M",
    value: "EXTENDER GIANT CAB GRAND 2.0 D M"
  },
  {
    SERIAL: "89450000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER GIANT CAB GRAND 2.0 D A",
    label: "EXTENDER GIANT CAB GRAND 2.0 D A",
    value: "EXTENDER GIANT CAB GRAND 2.0 D A"
  },
  {
    SERIAL: "89460000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER GIANT CAB GRAND 2.0 X M",
    label: "EXTENDER GIANT CAB GRAND 2.0 X M",
    value: "EXTENDER GIANT CAB GRAND 2.0 X M"
  },
  {
    SERIAL: "89470000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER DOUBLE CAB GRAND 2.0 D M",
    label: "EXTENDER DOUBLE CAB GRAND 2.0 D M",
    value: "EXTENDER DOUBLE CAB GRAND 2.0 D M"
  },
  {
    SERIAL: "89480000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER DOUBLE CAB GRAND 2.0 D A",
    label: "EXTENDER DOUBLE CAB GRAND 2.0 D A",
    value: "EXTENDER DOUBLE CAB GRAND 2.0 D A"
  },
  {
    SERIAL: "89490000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER DOUBLE CAB GRAND 2.0 X A",
    label: "EXTENDER DOUBLE CAB GRAND 2.0 X A",
    value: "EXTENDER DOUBLE CAB GRAND 2.0 X A"
  },
  {
    SERIAL: "89500000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EXTENDER DOUBLE CAB GRAND 4WD 2.0 X A",
    label: "EXTENDER DOUBLE CAB GRAND 4WD 2.0 X A",
    value: "EXTENDER DOUBLE CAB GRAND 4WD 2.0 X A"
  },
  {
    SERIAL: "89510000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "HS 1.5 C",
    label: "HS 1.5 C",
    value: "HS 1.5 C"
  },
  {
    SERIAL: "89520000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "HS 1.5 D",
    label: "HS 1.5 D",
    value: "HS 1.5 D"
  },
  {
    SERIAL: "89530000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "HS 1.5 X",
    label: "HS 1.5 X",
    value: "HS 1.5 X"
  },
  {
    SERIAL: "89540000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "ZS (ELECTRIC VEHICLE)",
    label: "ZS (ELECTRIC VEHICLE)",
    value: "ZS (ELECTRIC VEHICLE)"
  },
  {
    SERIAL: "89550000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 HATCHBACK C",
    label: "3 HATCHBACK C",
    value: "3 HATCHBACK C"
  },
  {
    SERIAL: "89560000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 HATCHBACK D",
    label: "3 HATCHBACK D",
    value: "3 HATCHBACK D"
  },
  {
    SERIAL: "89570000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 HATCHBACK X SUNROOF",
    label: "3 HATCHBACK X SUNROOF",
    value: "3 HATCHBACK X SUNROOF"
  },
  {
    SERIAL: "89580000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 HATCHBACK V SUNROOF",
    label: "3 HATCHBACK V SUNROOF",
    value: "3 HATCHBACK V SUNROOF"
  },
  {
    SERIAL: "89590000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "3 HATCHBACK X LIMITED EDITION",
    label: "3 HATCHBACK X LIMITED EDITION",
    value: "3 HATCHBACK X LIMITED EDITION"
  },
  {
    SERIAL: "89600000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "ZS 1.5 C+",
    label: "ZS 1.5 C+",
    value: "ZS 1.5 C+"
  },
  {
    SERIAL: "89610000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "ZS 1.5 D+",
    label: "ZS 1.5 D+",
    value: "ZS 1.5 D+"
  },
  {
    SERIAL: "89620000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "ZS 1.5 X+",
    label: "ZS 1.5 X+",
    value: "ZS 1.5 X+"
  },
  {
    SERIAL: "89630000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "HS PHEV (PLUG IN HYBRIRD) 1.5",
    label: "HS PHEV (PLUG IN HYBRIRD) 1.5",
    value: "HS PHEV (PLUG IN HYBRIRD) 1.5"
  },
  {
    SERIAL: "89640000026",
    MAKE_NAME: "MG",
    MODEL_NAME: "EP (EV)",
    label: "EP (EV)",
    value: "EP (EV)"
  },
  {
    SERIAL: "90000000026",
    MAKE_NAME: "INTERNATIONAL",
    MODEL_NAME: "INTERNATIONAL",
    label: "INTERNATIONAL",
    value: "INTERNATIONAL"
  },
  {
    SERIAL: "90010000026",
    MAKE_NAME: "INTERNATIONAL",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "90020000026",
    MAKE_NAME: "INTERNATIONAL",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "91000000026",
    MAKE_NAME: "KATO",
    MODEL_NAME: "KATO",
    label: "KATO",
    value: "KATO"
  },
  {
    SERIAL: "91010000026",
    MAKE_NAME: "KATO",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "92000000026",
    MAKE_NAME: "TADANO",
    MODEL_NAME: "TADANO",
    label: "TADANO",
    value: "TADANO"
  },
  {
    SERIAL: "92010000026",
    MAKE_NAME: "TADANO",
    MODEL_NAME: "CRAIN",
    label: "CRAIN",
    value: "CRAIN"
  },
  {
    SERIAL: "92020000026",
    MAKE_NAME: "TADANO",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "93000000026",
    MAKE_NAME: "KOBELCO",
    MODEL_NAME: "KOBELCO",
    label: "KOBELCO",
    value: "KOBELCO"
  },
  {
    SERIAL: "93010000026",
    MAKE_NAME: "KOBELCO",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "93020000026",
    MAKE_NAME: "KOBELCO",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "94000000026",
    MAKE_NAME: "HITACHI",
    MODEL_NAME: "HITACHI",
    label: "HITACHI",
    value: "HITACHI"
  },
  {
    SERIAL: "94020000026",
    MAKE_NAME: "HITACHI",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "95000000026",
    MAKE_NAME: "CORD",
    MODEL_NAME: "CORD",
    label: "CORD",
    value: "CORD"
  },
  {
    SERIAL: "96000000026",
    MAKE_NAME: "CAT",
    MODEL_NAME: "CAT",
    label: "CAT",
    value: "CAT"
  },
  {
    SERIAL: "97000000026",
    MAKE_NAME: "CATER INGERSOILL-RAND",
    MODEL_NAME: "CATER INGERSOILL-RAND",
    label: "CATER INGERSOILL-RAND",
    value: "CATER INGERSOILL-RAND"
  },
  {
    SERIAL: "98000000026",
    MAKE_NAME: "CARD",
    MODEL_NAME: "CARD",
    label: "CARD",
    value: "CARD"
  },
  {
    SERIAL: "99000000026",
    MAKE_NAME: "LAMBRETTA",
    MODEL_NAME: "LAMBRETTA",
    label: "LAMBRETTA",
    value: "LAMBRETTA"
  },
  {
    SERIAL: "99010000026",
    MAKE_NAME: "LAMBRETTA",
    MODEL_NAME: "F2",
    label: "F2",
    value: "F2"
  },
  {
    SERIAL: "99030000026",
    MAKE_NAME: "LAMBRETTA",
    MODEL_NAME: "V200",
    label: "V200",
    value: "V200"
  },
  {
    SERIAL: "99040000026",
    MAKE_NAME: "LAMBRETTA",
    MODEL_NAME: "V125",
    label: "V125",
    value: "V125"
  },
  {
    SERIAL: "99050000026",
    MAKE_NAME: "LAMBRETTA",
    MODEL_NAME: "V200 STYPE",
    label: "V200 STYPE",
    value: "V200 STYPE"
  },
  {
    SERIAL: "AA000000026",
    MAKE_NAME: "VALIANT",
    MODEL_NAME: "VALIANT",
    label: "VALIANT",
    value: "VALIANT"
  },
  {
    SERIAL: "AB000000026",
    MAKE_NAME: "M.Z.",
    MODEL_NAME: "M.Z.",
    label: "M.Z.",
    value: "M.Z."
  },
  {
    SERIAL: "AC000000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TRIUMPH",
    label: "TRIUMPH",
    value: "TRIUMPH"
  },
  {
    SERIAL: "AC010000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SPRINT ST",
    label: "SPRINT ST",
    value: "SPRINT ST"
  },
  {
    SERIAL: "AC020000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE T100",
    label: "BONNEVILLE T100",
    value: "BONNEVILLE T100"
  },
  {
    SERIAL: "AC030000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE T100",
    label: "BONNEVILLE T100",
    value: "BONNEVILLE T100"
  },
  {
    SERIAL: "AC040000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 1050 ABS",
    label: "TIGER 1050 ABS",
    value: "TIGER 1050 ABS"
  },
  {
    SERIAL: "AC050000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 1050",
    label: "TIGER 1050",
    value: "TIGER 1050"
  },
  {
    SERIAL: "AC060000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "AMERICA",
    label: "AMERICA",
    value: "AMERICA"
  },
  {
    SERIAL: "AC070000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET III BASE",
    label: "ROCKET III BASE",
    value: "ROCKET III BASE"
  },
  {
    SERIAL: "AC080000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SPEED MASTER",
    label: "SPEED MASTER",
    value: "SPEED MASTER"
  },
  {
    SERIAL: "AC090000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SPEED TRIPLE",
    label: "SPEED TRIPLE",
    value: "SPEED TRIPLE"
  },
  {
    SERIAL: "AC100000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "STREET TRIPLE",
    label: "STREET TRIPLE",
    value: "STREET TRIPLE"
  },
  {
    SERIAL: "AC110000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "THRUXTON 900",
    label: "THRUXTON 900",
    value: "THRUXTON 900"
  },
  {
    SERIAL: "AC120000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET III TOURING",
    label: "ROCKET III TOURING",
    value: "ROCKET III TOURING"
  },
  {
    SERIAL: "AC130000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SCRAMBLER",
    label: "SCRAMBLER",
    value: "SCRAMBLER"
  },
  {
    SERIAL: "AC140000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "DAYTONA 675",
    label: "DAYTONA 675",
    value: "DAYTONA 675"
  },
  {
    SERIAL: "AC150000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "THRUXTON 900",
    label: "THRUXTON 900",
    value: "THRUXTON 900"
  },
  {
    SERIAL: "AC160000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE",
    label: "BONNEVILLE",
    value: "BONNEVILLE"
  },
  {
    SERIAL: "AC170000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE SE",
    label: "BONNEVILLE SE",
    value: "BONNEVILLE SE"
  },
  {
    SERIAL: "AC180000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "THUNDERBIRD ABS",
    label: "THUNDERBIRD ABS",
    value: "THUNDERBIRD ABS"
  },
  {
    SERIAL: "AC190000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 800 ABS",
    label: "TIGER 800 ABS",
    value: "TIGER 800 ABS"
  },
  {
    SERIAL: "AC200000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "STREET TRIPLE R 675",
    label: "STREET TRIPLE R 675",
    value: "STREET TRIPLE R 675"
  },
  {
    SERIAL: "AC210000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "DAYTONA 675 R",
    label: "DAYTONA 675 R",
    value: "DAYTONA 675 R"
  },
  {
    SERIAL: "AC220000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SPRINT GT 1050 ABS",
    label: "SPRINT GT 1050 ABS",
    value: "SPRINT GT 1050 ABS"
  },
  {
    SERIAL: "AC230000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 1050 ABS SE",
    label: "TIGER 1050 ABS SE",
    value: "TIGER 1050 ABS SE"
  },
  {
    SERIAL: "AC240000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "THUNDERBIRD STORM 1700 ABS",
    label: "THUNDERBIRD STORM 1700 ABS",
    value: "THUNDERBIRD STORM 1700 ABS"
  },
  {
    SERIAL: "AC250000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET III ROADSTER ABS",
    label: "ROCKET III ROADSTER ABS",
    value: "ROCKET III ROADSTER ABS"
  },
  {
    SERIAL: "AC260000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SCRAMBLER",
    label: "SCRAMBLER",
    value: "SCRAMBLER"
  },
  {
    SERIAL: "AC270000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SPEED TRIPLE 1050 ABS",
    label: "SPEED TRIPLE 1050 ABS",
    value: "SPEED TRIPLE 1050 ABS"
  },
  {
    SERIAL: "AC280000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SPEED TRIPLE 1050 R ABS",
    label: "SPEED TRIPLE 1050 R ABS",
    value: "SPEED TRIPLE 1050 R ABS"
  },
  {
    SERIAL: "AC290000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "EXPLORER 1200 ABS",
    label: "EXPLORER 1200 ABS",
    value: "EXPLORER 1200 ABS"
  },
  {
    SERIAL: "AC310000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 800 XC ABS",
    label: "TIGER 800 XC ABS",
    value: "TIGER 800 XC ABS"
  },
  {
    SERIAL: "AC320000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TROPHY 1200SE",
    label: "TROPHY 1200SE",
    value: "TROPHY 1200SE"
  },
  {
    SERIAL: "AC330000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER EXPLORER",
    label: "TIGER EXPLORER",
    value: "TIGER EXPLORER"
  },
  {
    SERIAL: "AC340000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER EXPLORER",
    label: "TIGER EXPLORER",
    value: "TIGER EXPLORER"
  },
  {
    SERIAL: "AC350000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 1050 SPORT",
    label: "TIGER 1050 SPORT",
    value: "TIGER 1050 SPORT"
  },
  {
    SERIAL: "AC360000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE NEW CHURCH",
    label: "BONNEVILLE NEW CHURCH",
    value: "BONNEVILLE NEW CHURCH"
  },
  {
    SERIAL: "AC370000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE T100 BLACK",
    label: "BONNEVILLE T100 BLACK",
    value: "BONNEVILLE T100 BLACK"
  },
  {
    SERIAL: "AC380000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 800 XRX",
    label: "TIGER 800 XRX",
    value: "TIGER 800 XRX"
  },
  {
    SERIAL: "AC390000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 800 XCX",
    label: "TIGER 800 XCX",
    value: "TIGER 800 XCX"
  },
  {
    SERIAL: "AC400000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "STREET TWIN",
    label: "STREET TWIN",
    value: "STREET TWIN"
  },
  {
    SERIAL: "AC410000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET X",
    label: "ROCKET X",
    value: "ROCKET X"
  },
  {
    SERIAL: "AC420000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE T120",
    label: "BONNEVILLE T120",
    value: "BONNEVILLE T120"
  },
  {
    SERIAL: "AC430000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "THRUXTON R 900",
    label: "THRUXTON R 900",
    value: "THRUXTON R 900"
  },
  {
    SERIAL: "AC440000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "THRUXTON R 1200",
    label: "THRUXTON R 1200",
    value: "THRUXTON R 1200"
  },
  {
    SERIAL: "AC450000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 800 XCA",
    label: "TIGER 800 XCA",
    value: "TIGER 800 XCA"
  },
  {
    SERIAL: "AC460000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "STREET TRIPLE 775",
    label: "STREET TRIPLE 775",
    value: "STREET TRIPLE 775"
  },
  {
    SERIAL: "AC470000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE BOBBER BLACK",
    label: "BONNEVILLE BOBBER BLACK",
    value: "BONNEVILLE BOBBER BLACK"
  },
  {
    SERIAL: "AC480000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "STREET CUP",
    label: "STREET CUP",
    value: "STREET CUP"
  },
  {
    SERIAL: "AC490000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "AC500000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE SPEED MASTER",
    label: "BONNEVILLE SPEED MASTER",
    value: "BONNEVILLE SPEED MASTER"
  },
  {
    SERIAL: "AC510000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "STREET TRIPLE RS",
    label: "STREET TRIPLE RS",
    value: "STREET TRIPLE RS"
  },
  {
    SERIAL: "AC520000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 800 XRT",
    label: "TIGER 800 XRT",
    value: "TIGER 800 XRT"
  },
  {
    SERIAL: "AC530000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET III TFC 2.5",
    label: "ROCKET III TFC 2.5",
    value: "ROCKET III TFC 2.5"
  },
  {
    SERIAL: "AC540000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET 3GT USE DUCATI RATE",
    label: "ROCKET 3GT USE DUCATI RATE",
    value: "ROCKET 3GT USE DUCATI RATE"
  },
  {
    SERIAL: "AC550000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET 3R USE DUCATI RATE",
    label: "ROCKET 3R USE DUCATI RATE",
    value: "ROCKET 3R USE DUCATI RATE"
  },
  {
    SERIAL: "AC560000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SPEED TWIN 1200",
    label: "SPEED TWIN 1200",
    value: "SPEED TWIN 1200"
  },
  {
    SERIAL: "AC570000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 900 RALLY",
    label: "TIGER 900 RALLY",
    value: "TIGER 900 RALLY"
  },
  {
    SERIAL: "AC580000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "STREET SCRAMBLER",
    label: "STREET SCRAMBLER",
    value: "STREET SCRAMBLER"
  },
  {
    SERIAL: "AC590000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SCRAMBLER 1200 XC",
    label: "SCRAMBLER 1200 XC",
    value: "SCRAMBLER 1200 XC"
  },
  {
    SERIAL: "AC600000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "THRUXTON RS 1200",
    label: "THRUXTON RS 1200",
    value: "THRUXTON RS 1200"
  },
  {
    SERIAL: "AC610000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "TIGER 900 GT PRO",
    label: "TIGER 900 GT PRO",
    value: "TIGER 900 GT PRO"
  },
  {
    SERIAL: "AC620000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "ROCKET 3",
    label: "ROCKET 3",
    value: "ROCKET 3"
  },
  {
    SERIAL: "AC630000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "SCRAMBLER 1200 XE",
    label: "SCRAMBLER 1200 XE",
    value: "SCRAMBLER 1200 XE"
  },
  {
    SERIAL: "AC710000026",
    MAKE_NAME: "TRIUMPH",
    MODEL_NAME: "BONNEVILLE BOBBER",
    label: "BONNEVILLE BOBBER",
    value: "BONNEVILLE BOBBER"
  },
  {
    SERIAL: "AD000000026",
    MAKE_NAME: "NICE",
    MODEL_NAME: "NICE",
    label: "NICE",
    value: "NICE"
  },
  {
    SERIAL: "AD010000026",
    MAKE_NAME: "NICE",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "AE000000026",
    MAKE_NAME: "BUICK",
    MODEL_NAME: "BUICK",
    label: "BUICK",
    value: "BUICK"
  },
  {
    SERIAL: "AF000000026",
    MAKE_NAME: "RANGE ROVER",
    MODEL_NAME: "RANGE ROVER",
    label: "RANGE ROVER",
    value: "RANGE ROVER"
  },
  {
    SERIAL: "AF020000026",
    MAKE_NAME: "RANGE ROVER",
    MODEL_NAME: "400E AUTOBIOGRAPHY HYBRID 2.0",
    label: "400E AUTOBIOGRAPHY HYBRID 2.0",
    value: "400E AUTOBIOGRAPHY HYBRID 2.0"
  },
  {
    SERIAL: "AG000000026",
    MAKE_NAME: "YEMINI",
    MODEL_NAME: "YEMINI",
    label: "YEMINI",
    value: "YEMINI"
  },
  {
    SERIAL: "AH000000026",
    MAKE_NAME: "JUPITER",
    MODEL_NAME: "JUPITER",
    label: "JUPITER",
    value: "JUPITER"
  },
  {
    SERIAL: "AH010000026",
    MAKE_NAME: "JUPITER",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "AI000000026",
    MAKE_NAME: "SUMITOMO",
    MODEL_NAME: "SUMITOMO",
    label: "SUMITOMO",
    value: "SUMITOMO"
  },
  {
    SERIAL: "AI010000026",
    MAKE_NAME: "SUMITOMO",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "AJ000000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "SKODA",
    label: "SKODA",
    value: "SKODA"
  },
  {
    SERIAL: "AJ010000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "FABIA 1.9",
    label: "FABIA 1.9",
    value: "FABIA 1.9"
  },
  {
    SERIAL: "AJ020000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "OCTAVIA 2.0 A",
    label: "OCTAVIA 2.0 A",
    value: "OCTAVIA 2.0 A"
  },
  {
    SERIAL: "AJ050000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "OCTAVIA COMBI 1.8 T A",
    label: "OCTAVIA COMBI 1.8 T A",
    value: "OCTAVIA COMBI 1.8 T A"
  },
  {
    SERIAL: "AJ060000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "SUPERB 1.9",
    label: "SUPERB 1.9",
    value: "SUPERB 1.9"
  },
  {
    SERIAL: "AJ070000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "OCTAVIA TDI 1.9 SEDAN",
    label: "OCTAVIA TDI 1.9 SEDAN",
    value: "OCTAVIA TDI 1.9 SEDAN"
  },
  {
    SERIAL: "AJ080000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "OCTAVIA TDI 1.9 COMBI WAGON",
    label: "OCTAVIA TDI 1.9 COMBI WAGON",
    value: "OCTAVIA TDI 1.9 COMBI WAGON"
  },
  {
    SERIAL: "AJ090000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "OCTAVIA TSI 1.8T",
    label: "OCTAVIA TSI 1.8T",
    value: "OCTAVIA TSI 1.8T"
  },
  {
    SERIAL: "AJ100000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "FABIA 1.6",
    label: "FABIA 1.6",
    value: "FABIA 1.6"
  },
  {
    SERIAL: "AJ110000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "FABIA 1.2 CLASSIC",
    label: "FABIA 1.2 CLASSIC",
    value: "FABIA 1.2 CLASSIC"
  },
  {
    SERIAL: "AJ120000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "FABIA 1.2 AMBIENTE",
    label: "FABIA 1.2 AMBIENTE",
    value: "FABIA 1.2 AMBIENTE"
  },
  {
    SERIAL: "AJ130000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "SUPERB 1.8 CLASSIC",
    label: "SUPERB 1.8 CLASSIC",
    value: "SUPERB 1.8 CLASSIC"
  },
  {
    SERIAL: "AJ140000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "SUPERB COMBI AMBIENTE",
    label: "SUPERB COMBI AMBIENTE",
    value: "SUPERB COMBI AMBIENTE"
  },
  {
    SERIAL: "AJ150000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "FABIA RS 1.4",
    label: "FABIA RS 1.4",
    value: "FABIA RS 1.4"
  },
  {
    SERIAL: "AJ160000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "YETI AMBIENTE 1.2 T",
    label: "YETI AMBIENTE 1.2 T",
    value: "YETI AMBIENTE 1.2 T"
  },
  {
    SERIAL: "AJ170000026",
    MAKE_NAME: "SKODA",
    MODEL_NAME: "OCTAVIA COMBI AMBIENTE 2.0D",
    label: "OCTAVIA COMBI AMBIENTE 2.0D",
    value: "OCTAVIA COMBI AMBIENTE 2.0D"
  },
  {
    SERIAL: "AK000000026",
    MAKE_NAME: "ASIA COMBI",
    MODEL_NAME: "ASIS COMBI",
    label: "ASIS COMBI",
    value: "ASIS COMBI"
  },
  {
    SERIAL: "AK010000026",
    MAKE_NAME: "ASIA COMBI",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "AK020000026",
    MAKE_NAME: "ASIA COMBI",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "AL000000026",
    MAKE_NAME: "JCB",
    MODEL_NAME: "JCB",
    label: "JCB",
    value: "JCB"
  },
  {
    SERIAL: "AL020000026",
    MAKE_NAME: "JCB",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "AL030000026",
    MAKE_NAME: "JCB",
    MODEL_NAME: "TRACTOR",
    label: "TRACTOR",
    value: "TRACTOR"
  },
  {
    SERIAL: "AM000000026",
    MAKE_NAME: "WATT",
    MODEL_NAME: "WATT",
    label: "WATT",
    value: "WATT"
  },
  {
    SERIAL: "AN000000026",
    MAKE_NAME: "INGERSOLL RAND",
    MODEL_NAME: "INGERSOLL RAND",
    label: "INGERSOLL RAND",
    value: "INGERSOLL RAND"
  },
  {
    SERIAL: "AO000000026",
    MAKE_NAME: "ELECTRIC MOTORCYCLE",
    MODEL_NAME: "ELECTRIC MOTORCYCLE",
    label: "ELECTRIC MOTORCYCLE",
    value: "ELECTRIC MOTORCYCLE"
  },
  {
    SERIAL: "AO010000026",
    MAKE_NAME: "ELECTRIC MOTORCYCLE",
    MODEL_NAME: "125",
    label: "125",
    value: "125"
  },
  {
    SERIAL: "AP000000026",
    MAKE_NAME: "A.J.S.",
    MODEL_NAME: "A.J.S.",
    label: "A.J.S.",
    value: "A.J.S."
  },
  {
    SERIAL: "AQ000000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "SOKON",
    label: "SOKON",
    value: "SOKON"
  },
  {
    SERIAL: "AQ010000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "TRUCK 1.1 LPG",
    label: "TRUCK 1.1 LPG",
    value: "TRUCK 1.1 LPG"
  },
  {
    SERIAL: "AQ020000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "MINI VAN 1.1",
    label: "MINI VAN 1.1",
    value: "MINI VAN 1.1"
  },
  {
    SERIAL: "AQ030000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "MINI VAN 1.3",
    label: "MINI VAN 1.3",
    value: "MINI VAN 1.3"
  },
  {
    SERIAL: "AQ050000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "MINI TRUCK 1.3",
    label: "MINI TRUCK 1.3",
    value: "MINI TRUCK 1.3"
  },
  {
    SERIAL: "AQ060000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "MINI TRUCK 4D 1.1",
    label: "MINI TRUCK 4D 1.1",
    value: "MINI TRUCK 4D 1.1"
  },
  {
    SERIAL: "AQ070000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "MINI TRUCK 4D 1.3",
    label: "MINI TRUCK 4D 1.3",
    value: "MINI TRUCK 4D 1.3"
  },
  {
    SERIAL: "AQ080000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "MINI BUS 1.1",
    label: "MINI BUS 1.1",
    value: "MINI BUS 1.1"
  },
  {
    SERIAL: "AQ090000026",
    MAKE_NAME: "SOKON",
    MODEL_NAME: "MINI BUS 1.3",
    label: "MINI BUS 1.3",
    value: "MINI BUS 1.3"
  },
  {
    SERIAL: "AR000000026",
    MAKE_NAME: "PRINCE",
    MODEL_NAME: "PRINCE",
    label: "PRINCE",
    value: "PRINCE"
  },
  {
    SERIAL: "AS000000026",
    MAKE_NAME: "NARMADA",
    MODEL_NAME: "NARMADA",
    label: "NARMADA",
    value: "NARMADA"
  },
  {
    SERIAL: "AT000000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "3 WHEEL",
    label: "3 WHEEL",
    value: "3 WHEEL"
  },
  {
    SERIAL: "AT010000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "PASSENGER",
    label: "PASSENGER",
    value: "PASSENGER"
  },
  {
    SERIAL: "AT020000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "TUKTUK",
    label: "TUKTUK",
    value: "TUKTUK"
  },
  {
    SERIAL: "AT030000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "2 ตอน",
    label: "2 ตอน",
    value: "2 ตอน"
  },
  {
    SERIAL: "AT040000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "PASSENGER",
    label: "PASSENGER",
    value: "PASSENGER"
  },
  {
    SERIAL: "AT050000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "AT060000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "FORWARDER",
    label: "FORWARDER",
    value: "FORWARDER"
  },
  {
    SERIAL: "AT070000026",
    MAKE_NAME: "TUKTUK",
    MODEL_NAME: "3 ตอน",
    label: "3 ตอน",
    value: "3 ตอน"
  },
  {
    SERIAL: "AU000000026",
    MAKE_NAME: "PLYMOUTH PROWLER",
    MODEL_NAME: "PLYMOUTH PROWLER",
    label: "PLYMOUTH PROWLER",
    value: "PLYMOUTH PROWLER"
  },
  {
    SERIAL: "AV000000026",
    MAKE_NAME: "PECASSO",
    MODEL_NAME: "PECASSO",
    label: "PECASSO",
    value: "PECASSO"
  },
  {
    SERIAL: "AW000000026",
    MAKE_NAME: "RIAL",
    MODEL_NAME: "RIAL",
    label: "RIAL",
    value: "RIAL"
  },
  {
    SERIAL: "AX000000026",
    MAKE_NAME: "MZ",
    MODEL_NAME: "MZ",
    label: "MZ",
    value: "MZ"
  },
  {
    SERIAL: "AY000000026",
    MAKE_NAME: "CHEVY VAN",
    MODEL_NAME: "CHEVY VAN",
    label: "CHEVY VAN",
    value: "CHEVY VAN"
  },
  {
    SERIAL: "AZ000000026",
    MAKE_NAME: "SHIBULA",
    MODEL_NAME: "SHIBULA",
    label: "SHIBULA",
    value: "SHIBULA"
  },
  {
    SERIAL: "BA000000026",
    MAKE_NAME: "M0RRIS",
    MODEL_NAME: "MORRIS",
    label: "MORRIS",
    value: "MORRIS"
  },
  {
    SERIAL: "BA010000026",
    MAKE_NAME: "M0RRIS",
    MODEL_NAME: "1.3",
    label: "1.3",
    value: "1.3"
  },
  {
    SERIAL: "BA020000026",
    MAKE_NAME: "M0RRIS",
    MODEL_NAME: "2",
    label: "2",
    value: "2"
  },
  {
    SERIAL: "BB000000026",
    MAKE_NAME: "INTER",
    MODEL_NAME: "INTER",
    label: "INTER",
    value: "INTER"
  },
  {
    SERIAL: "BB010000026",
    MAKE_NAME: "INTER",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "BC000000026",
    MAKE_NAME: "WATANABE",
    MODEL_NAME: "WATANABE",
    label: "WATANABE",
    value: "WATANABE"
  },
  {
    SERIAL: "BC010000026",
    MAKE_NAME: "WATANABE",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "BD000000026",
    MAKE_NAME: "RILEY",
    MODEL_NAME: "RILEY",
    label: "RILEY",
    value: "RILEY"
  },
  {
    SERIAL: "BE000000026",
    MAKE_NAME: "MOTOATT",
    MODEL_NAME: "MOTOATT",
    label: "MOTOATT",
    value: "MOTOATT"
  },
  {
    SERIAL: "BF000000026",
    MAKE_NAME: "BOMAG",
    MODEL_NAME: "BOMAG",
    label: "BOMAG",
    value: "BOMAG"
  },
  {
    SERIAL: "BG000000026",
    MAKE_NAME: "NIKATA",
    MODEL_NAME: "NIKATA",
    label: "NIKATA",
    value: "NIKATA"
  },
  {
    SERIAL: "BH000000026",
    MAKE_NAME: "MAXGI RUDOY",
    MODEL_NAME: "MAXGI RUDOY",
    label: "MAXGI RUDOY",
    value: "MAXGI RUDOY"
  },
  {
    SERIAL: "BI000000026",
    MAKE_NAME: "HAMM",
    MODEL_NAME: "HAMM",
    label: "HAMM",
    value: "HAMM"
  },
  {
    SERIAL: "BI010000026",
    MAKE_NAME: "HAMM",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "BJ000000026",
    MAKE_NAME: "รถบดถนน",
    MODEL_NAME: "รถบดถนน",
    label: "รถบดถนน",
    value: "รถบดถนน"
  },
  {
    SERIAL: "BK000000026",
    MAKE_NAME: "CANTER",
    MODEL_NAME: "CANTER",
    label: "CANTER",
    value: "CANTER"
  },
  {
    SERIAL: "BL000000026",
    MAKE_NAME: "PERGIBCH",
    MODEL_NAME: "PERGIBCH",
    label: "PERGIBCH",
    value: "PERGIBCH"
  },
  {
    SERIAL: "BM000000026",
    MAKE_NAME: "GRAND",
    MODEL_NAME: "GRAND",
    label: "GRAND",
    value: "GRAND"
  },
  {
    SERIAL: "BN000000026",
    MAKE_NAME: "LML",
    MODEL_NAME: "LML",
    label: "LML",
    value: "LML"
  },
  {
    SERIAL: "BN010000026",
    MAKE_NAME: "LML",
    MODEL_NAME: "150CC",
    label: "150CC",
    value: "150CC"
  },
  {
    SERIAL: "BO000000026",
    MAKE_NAME: "LEYLAND",
    MODEL_NAME: "LEYLAND",
    label: "LEYLAND",
    value: "LEYLAND"
  },
  {
    SERIAL: "BO010000026",
    MAKE_NAME: "LEYLAND",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "BP000000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "ROYAL ENFIELD",
    label: "ROYAL ENFIELD",
    value: "ROYAL ENFIELD"
  },
  {
    SERIAL: "BP010000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "CONTINENTAL GT",
    label: "CONTINENTAL GT",
    value: "CONTINENTAL GT"
  },
  {
    SERIAL: "BP020000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "BULLET 500",
    label: "BULLET 500",
    value: "BULLET 500"
  },
  {
    SERIAL: "BP030000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "CLASSIC 500",
    label: "CLASSIC 500",
    value: "CLASSIC 500"
  },
  {
    SERIAL: "BP040000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "CLASSIC CHROME",
    label: "CLASSIC CHROME",
    value: "CLASSIC CHROME"
  },
  {
    SERIAL: "BP050000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "HIMALAYAN",
    label: "HIMALAYAN",
    value: "HIMALAYAN"
  },
  {
    SERIAL: "BP110000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "INTERCEPTOR 650",
    label: "INTERCEPTOR 650",
    value: "INTERCEPTOR 650"
  },
  {
    SERIAL: "BP240000026",
    MAKE_NAME: "ROYAL ENFIELD",
    MODEL_NAME: "CONTINENTAL GT 535 CUSTOM",
    label: "CONTINENTAL GT 535 CUSTOM",
    value: "CONTINENTAL GT 535 CUSTOM"
  },
  {
    SERIAL: "BQ000000026",
    MAKE_NAME: "VAUXHALL",
    MODEL_NAME: "VAUXHALL",
    label: "VAUXHALL",
    value: "VAUXHALL"
  },
  {
    SERIAL: "BQ010000026",
    MAKE_NAME: "VAUXHALL",
    MODEL_NAME: "ASTRA",
    label: "ASTRA",
    value: "ASTRA"
  },
  {
    SERIAL: "BR000000026",
    MAKE_NAME: "B S A",
    MODEL_NAME: "B S A",
    label: "B S A",
    value: "B S A"
  },
  {
    SERIAL: "BS000000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DUCATI",
    label: "DUCATI",
    value: "DUCATI"
  },
  {
    SERIAL: "BS010000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "999R",
    label: "999R",
    value: "999R"
  },
  {
    SERIAL: "BS020000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 620",
    label: "MONSTER 620",
    value: "MONSTER 620"
  },
  {
    SERIAL: "BS030000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "749R",
    label: "749R",
    value: "749R"
  },
  {
    SERIAL: "BS040000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 696",
    label: "MONSTER 696",
    value: "MONSTER 696"
  },
  {
    SERIAL: "BS050000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1100",
    label: "MONSTER 1100",
    value: "MONSTER 1100"
  },
  {
    SERIAL: "BS060000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1100 S",
    label: "MONSTER 1100 S",
    value: "MONSTER 1100 S"
  },
  {
    SERIAL: "BS070000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SPORT CLASSIC GT1000",
    label: "SPORT CLASSIC GT1000",
    value: "SPORT CLASSIC GT1000"
  },
  {
    SERIAL: "BS080000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD 1100S",
    label: "HYPERMOTARD 1100S",
    value: "HYPERMOTARD 1100S"
  },
  {
    SERIAL: "BS090000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1100",
    label: "MULTISTRADA 1100",
    value: "MULTISTRADA 1100"
  },
  {
    SERIAL: "BS100000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL",
    label: "DIAVEL",
    value: "DIAVEL"
  },
  {
    SERIAL: "BS110000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1098 S",
    label: "1098 S",
    value: "1098 S"
  },
  {
    SERIAL: "BS120000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD 796",
    label: "HYPERMOTARD 796",
    value: "HYPERMOTARD 796"
  },
  {
    SERIAL: "BS130000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 S TOURING",
    label: "MULTISTRADA 1200 S TOURING",
    value: "MULTISTRADA 1200 S TOURING"
  },
  {
    SERIAL: "BS140000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 796",
    label: "MONSTER 796",
    value: "MONSTER 796"
  },
  {
    SERIAL: "BS150000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848 EVO",
    label: "STREETFIGHTER 848 EVO",
    value: "STREETFIGHTER 848 EVO"
  },
  {
    SERIAL: "BS160000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200",
    label: "MULTISTRADA 1200",
    value: "MULTISTRADA 1200"
  },
  {
    SERIAL: "BS170000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 ABS",
    label: "MULTISTRADA 1200 ABS",
    value: "MULTISTRADA 1200 ABS"
  },
  {
    SERIAL: "BS180000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 S/S",
    label: "MULTISTRADA 1200 S/S",
    value: "MULTISTRADA 1200 S/S"
  },
  {
    SERIAL: "BS190000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD 1100 EVO",
    label: "HYPERMOTARD 1100 EVO",
    value: "HYPERMOTARD 1100 EVO"
  },
  {
    SERIAL: "BS200000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD 1100 EVO SP",
    label: "HYPERMOTARD 1100 EVO SP",
    value: "HYPERMOTARD 1100 EVO SP"
  },
  {
    SERIAL: "BS210000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 696 ABS",
    label: "MONSTER 696 ABS",
    value: "MONSTER 696 ABS"
  },
  {
    SERIAL: "BS220000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1100 ABS",
    label: "MONSTER 1100 ABS",
    value: "MONSTER 1100 ABS"
  },
  {
    SERIAL: "BS230000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL STRIPE SPORTIVO",
    label: "DIAVEL STRIPE SPORTIVO",
    value: "DIAVEL STRIPE SPORTIVO"
  },
  {
    SERIAL: "BS240000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1100 S ABS",
    label: "MONSTER 1100 S ABS",
    value: "MONSTER 1100 S ABS"
  },
  {
    SERIAL: "BS250000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848",
    label: "STREETFIGHTER 848",
    value: "STREETFIGHTER 848"
  },
  {
    SERIAL: "BS260000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER S",
    label: "STREETFIGHTER S",
    value: "STREETFIGHTER S"
  },
  {
    SERIAL: "BS280000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848",
    label: "STREETFIGHTER 848",
    value: "STREETFIGHTER 848"
  },
  {
    SERIAL: "BS290000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1198",
    label: "1198",
    value: "1198"
  },
  {
    SERIAL: "BS300000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1198 S",
    label: "1198 S",
    value: "1198 S"
  },
  {
    SERIAL: "BS310000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1198 S CORSE",
    label: "1198 S CORSE",
    value: "1198 S CORSE"
  },
  {
    SERIAL: "BS320000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1198 R CORSE",
    label: "1198 R CORSE",
    value: "1198 R CORSE"
  },
  {
    SERIAL: "BS330000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1198 S (BUILT)",
    label: "1198 S (BUILT)",
    value: "1198 S (BUILT)"
  },
  {
    SERIAL: "BS340000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "S2R",
    label: "S2R",
    value: "S2R"
  },
  {
    SERIAL: "BS350000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 796 ABS",
    label: "MONSTER 796 ABS",
    value: "MONSTER 796 ABS"
  },
  {
    SERIAL: "BS360000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848 EVO",
    label: "STREETFIGHTER 848 EVO",
    value: "STREETFIGHTER 848 EVO"
  },
  {
    SERIAL: "BS370000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL ABS",
    label: "DIAVEL ABS",
    value: "DIAVEL ABS"
  },
  {
    SERIAL: "BS380000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CARBON",
    label: "DIAVEL CARBON",
    value: "DIAVEL CARBON"
  },
  {
    SERIAL: "BS390000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CARBON RED",
    label: "DIAVEL CARBON RED",
    value: "DIAVEL CARBON RED"
  },
  {
    SERIAL: "BS400000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "S1000",
    label: "S1000",
    value: "S1000"
  },
  {
    SERIAL: "BS410000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1098 R",
    label: "1098 R",
    value: "1098 R"
  },
  {
    SERIAL: "BS420000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "PAUL SMART 1000",
    label: "PAUL SMART 1000",
    value: "PAUL SMART 1000"
  },
  {
    SERIAL: "BS430000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1000 SS",
    label: "1000 SS",
    value: "1000 SS"
  },
  {
    SERIAL: "BS440000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1098",
    label: "1098",
    value: "1098"
  },
  {
    SERIAL: "BS450000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848",
    label: "STREETFIGHTER 848",
    value: "STREETFIGHTER 848"
  },
  {
    SERIAL: "BS460000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 821 STRIPS RED",
    label: "MONSTER 821 STRIPS RED",
    value: "MONSTER 821 STRIPS RED"
  },
  {
    SERIAL: "BS470000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "916",
    label: "916",
    value: "916"
  },
  {
    SERIAL: "BS480000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 821 YELLOW",
    label: "MONSTER 821 YELLOW",
    value: "MONSTER 821 YELLOW"
  },
  {
    SERIAL: "BS490000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 821 ACCESSARIES VERSION",
    label: "MONSTER 821 ACCESSARIES VERSION",
    value: "MONSTER 821 ACCESSARIES VERSION"
  },
  {
    SERIAL: "BS500000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 795",
    label: "MONSTER 795",
    value: "MONSTER 795"
  },
  {
    SERIAL: "BS510000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200S PIKE PEAK",
    label: "MULTISTRADA 1200S PIKE PEAK",
    value: "MULTISTRADA 1200S PIKE PEAK"
  },
  {
    SERIAL: "BS520000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE S ABS",
    label: "1199 PANIGALE S ABS",
    value: "1199 PANIGALE S ABS"
  },
  {
    SERIAL: "BS530000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848 EVO CORSE",
    label: "STREETFIGHTER 848 EVO CORSE",
    value: "STREETFIGHTER 848 EVO CORSE"
  },
  {
    SERIAL: "BS540000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE",
    label: "1199 PANIGALE",
    value: "1199 PANIGALE"
  },
  {
    SERIAL: "BS550000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 795 ABS PERFORMANCE",
    label: "MONSTER 795 ABS PERFORMANCE",
    value: "MONSTER 795 ABS PERFORMANCE"
  },
  {
    SERIAL: "BS570000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE ABS",
    label: "1199 PANIGALE ABS",
    value: "1199 PANIGALE ABS"
  },
  {
    SERIAL: "BS580000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE S ABS",
    label: "1199 PANIGALE S ABS",
    value: "1199 PANIGALE S ABS"
  },
  {
    SERIAL: "BS590000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL DARK",
    label: "DIAVEL DARK",
    value: "DIAVEL DARK"
  },
  {
    SERIAL: "BS600000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "GT1000 TOURING",
    label: "GT1000 TOURING",
    value: "GT1000 TOURING"
  },
  {
    SERIAL: "BS610000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD",
    label: "HYPERMOTARD",
    value: "HYPERMOTARD"
  },
  {
    SERIAL: "BS620000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 796 ABS SP",
    label: "MONSTER 796 ABS SP",
    value: "MONSTER 796 ABS SP"
  },
  {
    SERIAL: "BS630000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "CUSTOM 1199 S RACE",
    label: "CUSTOM 1199 S RACE",
    value: "CUSTOM 1199 S RACE"
  },
  {
    SERIAL: "BS640000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 SR ACCESSARIES",
    label: "1199 SR ACCESSARIES",
    value: "1199 SR ACCESSARIES"
  },
  {
    SERIAL: "BS650000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 821 PERFORMANCE",
    label: "MONSTER 821 PERFORMANCE",
    value: "MONSTER 821 PERFORMANCE"
  },
  {
    SERIAL: "BS660000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD 939 RED",
    label: "HYPERMOTARD 939 RED",
    value: "HYPERMOTARD 939 RED"
  },
  {
    SERIAL: "BS670000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERBIKE 959 PANIGALE TRACK EVO PLUS R ACING KIT",
    label: "SUPERBIKE 959 PANIGALE TRACK EVO PLUS R ACING KIT",
    value: "SUPERBIKE 959 PANIGALE TRACK EVO PLUS R ACING KIT"
  },
  {
    SERIAL: "BS680000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 PIKES PEAK",
    label: "MULTISTRADA 1200 PIKES PEAK",
    value: "MULTISTRADA 1200 PIKES PEAK"
  },
  {
    SERIAL: "BS690000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 S",
    label: "MULTISTRADA 1200 S",
    value: "MULTISTRADA 1200 S"
  },
  {
    SERIAL: "BS700000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200S GRANTURISMO",
    label: "MULTISTRADA 1200S GRANTURISMO",
    value: "MULTISTRADA 1200S GRANTURISMO"
  },
  {
    SERIAL: "BS710000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CROMO PERFORMANCE",
    label: "DIAVEL CROMO PERFORMANCE",
    value: "DIAVEL CROMO PERFORMANCE"
  },
  {
    SERIAL: "BS720000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200",
    label: "MULTISTRADA 1200",
    value: "MULTISTRADA 1200"
  },
  {
    SERIAL: "BS730000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 ENDURO",
    label: "MULTISTRADA 1200 ENDURO",
    value: "MULTISTRADA 1200 ENDURO"
  },
  {
    SERIAL: "BS740000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 ENDURO TRAVEL VERSION",
    label: "MULTISTRADA 1200 ENDURO TRAVEL VERSION",
    value: "MULTISTRADA 1200 ENDURO TRAVEL VERSION"
  },
  {
    SERIAL: "BS750000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "XDIAVEL",
    label: "XDIAVEL",
    value: "XDIAVEL"
  },
  {
    SERIAL: "BS760000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "XDIAVEL S",
    label: "XDIAVEL S",
    value: "XDIAVEL S"
  },
  {
    SERIAL: "BS770000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "XDIAVEL RSD FULL MUSCLE",
    label: "XDIAVEL RSD FULL MUSCLE",
    value: "XDIAVEL RSD FULL MUSCLE"
  },
  {
    SERIAL: "BS780000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "XDIAVEL CARBON VERSION",
    label: "XDIAVEL CARBON VERSION",
    value: "XDIAVEL CARBON VERSION"
  },
  {
    SERIAL: "BS790000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "XDIAVEL S CARBON VERSION",
    label: "XDIAVEL S CARBON VERSION",
    value: "XDIAVEL S CARBON VERSION"
  },
  {
    SERIAL: "BS800000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER ICON SPORT VERSION",
    label: "SCRAMBLER ICON SPORT VERSION",
    value: "SCRAMBLER ICON SPORT VERSION"
  },
  {
    SERIAL: "BS810000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER ICON ENDURO",
    label: "SCRAMBLER ICON ENDURO",
    value: "SCRAMBLER ICON ENDURO"
  },
  {
    SERIAL: "BS820000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 950",
    label: "MULTISTRADA 950",
    value: "MULTISTRADA 950"
  },
  {
    SERIAL: "BS830000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 797",
    label: "MONSTER 797",
    value: "MONSTER 797"
  },
  {
    SERIAL: "BS840000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 950 RED ADVENTURE TOURING",
    label: "MULTISTRADA 950 RED ADVENTURE TOURING",
    value: "MULTISTRADA 950 RED ADVENTURE TOURING"
  },
  {
    SERIAL: "BS850000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MUTISTRADA 950 WHITE ADVENTURE TOURING",
    label: "MUTISTRADA 950 WHITE ADVENTURE TOURING",
    value: "MUTISTRADA 950 WHITE ADVENTURE TOURING"
  },
  {
    SERIAL: "BS860000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER URBAN WARRIOR PLUS",
    label: "SCRAMBLER URBAN WARRIOR PLUS",
    value: "SCRAMBLER URBAN WARRIOR PLUS"
  },
  {
    SERIAL: "BS870000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER CAFE RACER",
    label: "SCRAMBLER CAFE RACER",
    value: "SCRAMBLER CAFE RACER"
  },
  {
    SERIAL: "BS880000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER DESERT SLED",
    label: "SCRAMBLER DESERT SLED",
    value: "SCRAMBLER DESERT SLED"
  },
  {
    SERIAL: "BS890000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1260 PIKES PEAK",
    label: "MULTISTRADA 1260 PIKES PEAK",
    value: "MULTISTRADA 1260 PIKES PEAK"
  },
  {
    SERIAL: "BS900000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "PANIGALE V4S 1103",
    label: "PANIGALE V4S 1103",
    value: "PANIGALE V4S 1103"
  },
  {
    SERIAL: "BS910000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER 1100 SPORT",
    label: "SCRAMBLER 1100 SPORT",
    value: "SCRAMBLER 1100 SPORT"
  },
  {
    SERIAL: "BS920000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD 950 PERFORMANCE",
    label: "HYPERMOTARD 950 PERFORMANCE",
    value: "HYPERMOTARD 950 PERFORMANCE"
  },
  {
    SERIAL: "BSAB0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "996",
    label: "996",
    value: "996"
  },
  {
    SERIAL: "BSAC0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CARBON RED CKD",
    label: "DIAVEL CARBON RED CKD",
    value: "DIAVEL CARBON RED CKD"
  },
  {
    SERIAL: "BSAD0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848",
    label: "STREETFIGHTER 848",
    value: "STREETFIGHTER 848"
  },
  {
    SERIAL: "BSAE0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "GT1000",
    label: "GT1000",
    value: "GT1000"
  },
  {
    SERIAL: "BSAF0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL AMG",
    label: "DIAVEL AMG",
    value: "DIAVEL AMG"
  },
  {
    SERIAL: "BSAG0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE S TRI COLOR",
    label: "1199 PANIGALE S TRI COLOR",
    value: "1199 PANIGALE S TRI COLOR"
  },
  {
    SERIAL: "BSAI0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1198 SP",
    label: "1198 SP",
    value: "1198 SP"
  },
  {
    SERIAL: "BSAJ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CROMO",
    label: "DIAVEL CROMO",
    value: "DIAVEL CROMO"
  },
  {
    SERIAL: "BSAK0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CARBON PERFORMANCE",
    label: "DIAVEL CARBON PERFORMANCE",
    value: "DIAVEL CARBON PERFORMANCE"
  },
  {
    SERIAL: "BSAL0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1100 EVO ABS",
    label: "MONSTER 1100 EVO ABS",
    value: "MONSTER 1100 EVO ABS"
  },
  {
    SERIAL: "BSAM0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 ABS-CKD",
    label: "MULTISTRADA 1200 ABS-CKD",
    value: "MULTISTRADA 1200 ABS-CKD"
  },
  {
    SERIAL: "BSAN0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200S TOURING-CKD",
    label: "MULTISTRADA 1200S TOURING-CKD",
    value: "MULTISTRADA 1200S TOURING-CKD"
  },
  {
    SERIAL: "BSAO0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200S PIKE PEAK-CKD",
    label: "MULTISTRADA 1200S PIKE PEAK-CKD",
    value: "MULTISTRADA 1200S PIKE PEAK-CKD"
  },
  {
    SERIAL: "BSAP0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848 EVO PERFORMANACE",
    label: "STREETFIGHTER 848 EVO PERFORMANACE",
    value: "STREETFIGHTER 848 EVO PERFORMANACE"
  },
  {
    SERIAL: "BSAQ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "PANIGALE SUPERLEGGERA",
    label: "PANIGALE SUPERLEGGERA",
    value: "PANIGALE SUPERLEGGERA"
  },
  {
    SERIAL: "BSAR0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 796 ABS CORSE STRIPE",
    label: "MONSTER 796 ABS CORSE STRIPE",
    value: "MONSTER 796 ABS CORSE STRIPE"
  },
  {
    SERIAL: "BSAS0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPER DUKE 899",
    label: "SUPER DUKE 899",
    value: "SUPER DUKE 899"
  },
  {
    SERIAL: "BSAT0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200 ABS PERFORMANCE",
    label: "MULTISTRADA 1200 ABS PERFORMANCE",
    value: "MULTISTRADA 1200 ABS PERFORMANCE"
  },
  {
    SERIAL: "BSAU0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1200S TOURING PERFORMANCE",
    label: "MULTISTRADA 1200S TOURING PERFORMANCE",
    value: "MULTISTRADA 1200S TOURING PERFORMANCE"
  },
  {
    SERIAL: "BSAV0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA MATT CHROME TOURING",
    label: "MULTISTRADA MATT CHROME TOURING",
    value: "MULTISTRADA MATT CHROME TOURING"
  },
  {
    SERIAL: "BSAW0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD SP",
    label: "HYPERMOTARD SP",
    value: "HYPERMOTARD SP"
  },
  {
    SERIAL: "BSAX0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 848 EVO SP",
    label: "STREETFIGHTER 848 EVO SP",
    value: "STREETFIGHTER 848 EVO SP"
  },
  {
    SERIAL: "BSAZ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERSTRADA",
    label: "HYPERSTRADA",
    value: "HYPERSTRADA"
  },
  {
    SERIAL: "BSBB0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE R",
    label: "1199 PANIGALE R",
    value: "1199 PANIGALE R"
  },
  {
    SERIAL: "BSBD0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 795 ABS",
    label: "MONSTER 795 ABS",
    value: "MONSTER 795 ABS"
  },
  {
    SERIAL: "BSBE0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1200",
    label: "MONSTER 1200",
    value: "MONSTER 1200"
  },
  {
    SERIAL: "BSBF0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 ABS",
    label: "1199 ABS",
    value: "1199 ABS"
  },
  {
    SERIAL: "BSBG0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 795 Y14",
    label: "MONSTER 795 Y14",
    value: "MONSTER 795 Y14"
  },
  {
    SERIAL: "BSBH0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "998R",
    label: "998R",
    value: "998R"
  },
  {
    SERIAL: "BSBK0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "899 PANIGALE - CBU",
    label: "899 PANIGALE - CBU",
    value: "899 PANIGALE - CBU"
  },
  {
    SERIAL: "BSBL0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1200S",
    label: "MONSTER 1200S",
    value: "MONSTER 1200S"
  },
  {
    SERIAL: "BSBM0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 SUPERLEGGERA",
    label: "1199 SUPERLEGGERA",
    value: "1199 SUPERLEGGERA"
  },
  {
    SERIAL: "BSBO0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 796 ABS ANNIVERSARY",
    label: "MONSTER 796 ABS ANNIVERSARY",
    value: "MONSTER 796 ABS ANNIVERSARY"
  },
  {
    SERIAL: "BSBQ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 1099",
    label: "STREETFIGHTER 1099",
    value: "STREETFIGHTER 1099"
  },
  {
    SERIAL: "BSBS0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA SUPER TOURING",
    label: "MULTISTRADA SUPER TOURING",
    value: "MULTISTRADA SUPER TOURING"
  },
  {
    SERIAL: "BSBT0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MULTISTRADA 1260 S RED",
    label: "MULTISTRADA 1260 S RED",
    value: "MULTISTRADA 1260 S RED"
  },
  {
    SERIAL: "BSBV0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "999 S",
    label: "999 S",
    value: "999 S"
  },
  {
    SERIAL: "BSBW0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "S4RS",
    label: "S4RS",
    value: "S4RS"
  },
  {
    SERIAL: "BSBX0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MTS 1000 DS",
    label: "MTS 1000 DS",
    value: "MTS 1000 DS"
  },
  {
    SERIAL: "BSBY0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER DIESEL",
    label: "MONSTER DIESEL",
    value: "MONSTER DIESEL"
  },
  {
    SERIAL: "BSBZ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL ABS BLACK CKD",
    label: "DIAVEL ABS BLACK CKD",
    value: "DIAVEL ABS BLACK CKD"
  },
  {
    SERIAL: "BSCA0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD DARK",
    label: "HYPERMOTARD DARK",
    value: "HYPERMOTARD DARK"
  },
  {
    SERIAL: "BSCB0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 S RACE",
    label: "1199 S RACE",
    value: "1199 S RACE"
  },
  {
    SERIAL: "BSCC0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CARBON CBU",
    label: "DIAVEL CARBON CBU",
    value: "DIAVEL CARBON CBU"
  },
  {
    SERIAL: "BSCD0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL DARK CBU",
    label: "DIAVEL DARK CBU",
    value: "DIAVEL DARK CBU"
  },
  {
    SERIAL: "BSCE0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 1098 R",
    label: "STREETFIGHTER 1098 R",
    value: "STREETFIGHTER 1098 R"
  },
  {
    SERIAL: "BSCF0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "899 PANIGALE - CKD",
    label: "899 PANIGALE - CKD",
    value: "899 PANIGALE - CKD"
  },
  {
    SERIAL: "BSCG0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 796 GP RED",
    label: "MONSTER 796 GP RED",
    value: "MONSTER 796 GP RED"
  },
  {
    SERIAL: "BSCH0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER S2R",
    label: "MONSTER S2R",
    value: "MONSTER S2R"
  },
  {
    SERIAL: "BSCI0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER S2R S TI",
    label: "MONSTER S2R S TI",
    value: "MONSTER S2R S TI"
  },
  {
    SERIAL: "BSCJ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL S",
    label: "DIAVEL S",
    value: "DIAVEL S"
  },
  {
    SERIAL: "BSCK0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER 1098 S",
    label: "STREETFIGHTER 1098 S",
    value: "STREETFIGHTER 1098 S"
  },
  {
    SERIAL: "BSCL0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREET FIGHTER 1100",
    label: "STREET FIGHTER 1100",
    value: "STREET FIGHTER 1100"
  },
  {
    SERIAL: "BSCM0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 821",
    label: "MONSTER 821",
    value: "MONSTER 821"
  },
  {
    SERIAL: "BSCN0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1299 PANIGALE ABS",
    label: "1299 PANIGALE ABS",
    value: "1299 PANIGALE ABS"
  },
  {
    SERIAL: "BSCO0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER FOR SMART",
    label: "SCRAMBLER FOR SMART",
    value: "SCRAMBLER FOR SMART"
  },
  {
    SERIAL: "BSCP0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER ICON RED",
    label: "SCRAMBLER ICON RED",
    value: "SCRAMBLER ICON RED"
  },
  {
    SERIAL: "BSCQ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCREAMBLER ICON YELLOW",
    label: "SCREAMBLER ICON YELLOW",
    value: "SCREAMBLER ICON YELLOW"
  },
  {
    SERIAL: "BSCR0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER CLASSIC ORANGE",
    label: "SCRAMBLER CLASSIC ORANGE",
    value: "SCRAMBLER CLASSIC ORANGE"
  },
  {
    SERIAL: "BSCS0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER URBAN ENDURO GREEN",
    label: "SCRAMBLER URBAN ENDURO GREEN",
    value: "SCRAMBLER URBAN ENDURO GREEN"
  },
  {
    SERIAL: "BSCT0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER FULL THROTTLE",
    label: "SCRAMBLER FULL THROTTLE",
    value: "SCRAMBLER FULL THROTTLE"
  },
  {
    SERIAL: "BSCU0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER TITANIUM 1198",
    label: "STREETFIGHTER TITANIUM 1198",
    value: "STREETFIGHTER TITANIUM 1198"
  },
  {
    SERIAL: "BSCV0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERBIKE 899",
    label: "SUPERBIKE 899",
    value: "SUPERBIKE 899"
  },
  {
    SERIAL: "BSCW0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE S ABS (GRAY)",
    label: "1199 PANIGALE S ABS (GRAY)",
    value: "1199 PANIGALE S ABS (GRAY)"
  },
  {
    SERIAL: "BSCX0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER SIXTY 2",
    label: "SCRAMBLER SIXTY 2",
    value: "SCRAMBLER SIXTY 2"
  },
  {
    SERIAL: "BSCY0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER ICON SOLEIL",
    label: "SCRAMBLER ICON SOLEIL",
    value: "SCRAMBLER ICON SOLEIL"
  },
  {
    SERIAL: "BSCZ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER URBAN WARRIOR BLACK",
    label: "SCRAMBLER URBAN WARRIOR BLACK",
    value: "SCRAMBLER URBAN WARRIOR BLACK"
  },
  {
    SERIAL: "BSDA0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER LEGEND 62 BIANCO",
    label: "SCRAMBLER LEGEND 62 BIANCO",
    value: "SCRAMBLER LEGEND 62 BIANCO"
  },
  {
    SERIAL: "BSDC0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER CUSTOM URBAN ENDURO BLACK",
    label: "SCRAMBLER CUSTOM URBAN ENDURO BLACK",
    value: "SCRAMBLER CUSTOM URBAN ENDURO BLACK"
  },
  {
    SERIAL: "BSDE0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 821 DARK STEALTH",
    label: "MONSTER 821 DARK STEALTH",
    value: "MONSTER 821 DARK STEALTH"
  },
  {
    SERIAL: "BSDF0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 821 RED",
    label: "MONSTER 821 RED",
    value: "MONSTER 821 RED"
  },
  {
    SERIAL: "BSDG0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD CUSTOM HYPERFORMANCE",
    label: "HYPERMOTARD CUSTOM HYPERFORMANCE",
    value: "HYPERMOTARD CUSTOM HYPERFORMANCE"
  },
  {
    SERIAL: "BSDH0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "HYPERMOTARD CUSTOM HYPE ADVENTURE",
    label: "HYPERMOTARD CUSTOM HYPE ADVENTURE",
    value: "HYPERMOTARD CUSTOM HYPE ADVENTURE"
  },
  {
    SERIAL: "BSDI0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1299 PANIGALE ABS",
    label: "1299 PANIGALE ABS",
    value: "1299 PANIGALE ABS"
  },
  {
    SERIAL: "BSDJ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1299 PANIGALE S",
    label: "1299 PANIGALE S",
    value: "1299 PANIGALE S"
  },
  {
    SERIAL: "BSDK0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "PANIGALE R",
    label: "PANIGALE R",
    value: "PANIGALE R"
  },
  {
    SERIAL: "BSDL0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MH900E ทั่วไปไม่รับ",
    label: "MH900E ทั่วไปไม่รับ",
    value: "MH900E ทั่วไปไม่รับ"
  },
  {
    SERIAL: "BSDM0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CARBON PRIMA PERFORMANCE",
    label: "DIAVEL CARBON PRIMA PERFORMANCE",
    value: "DIAVEL CARBON PRIMA PERFORMANCE"
  },
  {
    SERIAL: "BSDN0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL TITANIUM LIMITED EDITION",
    label: "DIAVEL TITANIUM LIMITED EDITION",
    value: "DIAVEL TITANIUM LIMITED EDITION"
  },
  {
    SERIAL: "BSDO0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERBIKE 848 EVO",
    label: "SUPERBIKE 848 EVO",
    value: "SUPERBIKE 848 EVO"
  },
  {
    SERIAL: "BSDP0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SPORT 1000",
    label: "SPORT 1000",
    value: "SPORT 1000"
  },
  {
    SERIAL: "BSDQ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERBIKE 959 PANIGALE TRACK EVO VERSIO",
    label: "SUPERBIKE 959 PANIGALE TRACK EVO VERSIO",
    value: "SUPERBIKE 959 PANIGALE TRACK EVO VERSIO"
  },
  {
    SERIAL: "BSDR0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SCRAMBLER MIKE HAILWOOD",
    label: "SCRAMBLER MIKE HAILWOOD",
    value: "SCRAMBLER MIKE HAILWOOD"
  },
  {
    SERIAL: "BSDT0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL DARK PRIMA PERFORMANCE",
    label: "DIAVEL DARK PRIMA PERFORMANCE",
    value: "DIAVEL DARK PRIMA PERFORMANCE"
  },
  {
    SERIAL: "BSDV0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "PASO",
    label: "PASO",
    value: "PASO"
  },
  {
    SERIAL: "BSDW0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "S2R",
    label: "S2R",
    value: "S2R"
  },
  {
    SERIAL: "BSDX0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 PANIGALE S (GREY)",
    label: "1199 PANIGALE S (GREY)",
    value: "1199 PANIGALE S (GREY)"
  },
  {
    SERIAL: "BSDY0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL S GLOSS BLACK",
    label: "DIAVEL S GLOSS BLACK",
    value: "DIAVEL S GLOSS BLACK"
  },
  {
    SERIAL: "BSDZ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1199 SUPERLEGGERA",
    label: "1199 SUPERLEGGERA",
    value: "1199 SUPERLEGGERA"
  },
  {
    SERIAL: "BSEA0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERSPORT",
    label: "SUPERSPORT",
    value: "SUPERSPORT"
  },
  {
    SERIAL: "BSEB0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERSPORT S",
    label: "SUPERSPORT S",
    value: "SUPERSPORT S"
  },
  {
    SERIAL: "BSEC0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERSPORT S PERFORMANCE",
    label: "SUPERSPORT S PERFORMANCE",
    value: "SUPERSPORT S PERFORMANCE"
  },
  {
    SERIAL: "BSED0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "SUPERSPORT S RACE",
    label: "SUPERSPORT S RACE",
    value: "SUPERSPORT S RACE"
  },
  {
    SERIAL: "BSEE0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "MONSTER 1200 S",
    label: "MONSTER 1200 S",
    value: "MONSTER 1200 S"
  },
  {
    SERIAL: "BSEF0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER V4",
    label: "STREETFIGHTER V4",
    value: "STREETFIGHTER V4"
  },
  {
    SERIAL: "BSEG0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER V4S",
    label: "STREETFIGHTER V4S",
    value: "STREETFIGHTER V4S"
  },
  {
    SERIAL: "BSEH0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "STREETFIGHTER V4 S RED EVO",
    label: "STREETFIGHTER V4 S RED EVO",
    value: "STREETFIGHTER V4 S RED EVO"
  },
  {
    SERIAL: "BSEI0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL CARBON WHITE PRIMA PERFORMANCE",
    label: "DIAVEL CARBON WHITE PRIMA PERFORMANCE",
    value: "DIAVEL CARBON WHITE PRIMA PERFORMANCE"
  },
  {
    SERIAL: "BSEJ0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "DIAVEL 1260 S",
    label: "DIAVEL 1260 S",
    value: "DIAVEL 1260 S"
  },
  {
    SERIAL: "BSEL0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "PANIGALE V4",
    label: "PANIGALE V4",
    value: "PANIGALE V4"
  },
  {
    SERIAL: "BSEM0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "PANIGALE 959",
    label: "PANIGALE 959",
    value: "PANIGALE 959"
  },
  {
    SERIAL: "BSER0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1299 SUPERLEGGERA COVER 2",
    label: "1299 SUPERLEGGERA COVER 2",
    value: "1299 SUPERLEGGERA COVER 2"
  },
  {
    SERIAL: "BSES0000026",
    MAKE_NAME: "DUCATI",
    MODEL_NAME: "1299 PANIGALE R FINAL EDITION RARE",
    label: "1299 PANIGALE R FINAL EDITION RARE",
    value: "1299 PANIGALE R FINAL EDITION RARE"
  },
  {
    SERIAL: "BU000000026",
    MAKE_NAME: "FARGO",
    MODEL_NAME: "FARGO",
    label: "FARGO",
    value: "FARGO"
  },
  {
    SERIAL: "BU010000026",
    MAKE_NAME: "FARGO",
    MODEL_NAME: "VAN",
    label: "VAN",
    value: "VAN"
  },
  {
    SERIAL: "BU020000026",
    MAKE_NAME: "FARGO",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "BV000000026",
    MAKE_NAME: "ARMSTRONG",
    MODEL_NAME: "ARMSTRONG",
    label: "ARMSTRONG",
    value: "ARMSTRONG"
  },
  {
    SERIAL: "BX000000026",
    MAKE_NAME: "MASLY",
    MODEL_NAME: "MASLY",
    label: "MASLY",
    value: "MASLY"
  },
  {
    SERIAL: "BZ000000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "TATA",
    label: "TATA",
    value: "TATA"
  },
  {
    SERIAL: "BZ010000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON CAB DLE 2.2",
    label: "XENON CAB DLE 2.2",
    value: "XENON CAB DLE 2.2"
  },
  {
    SERIAL: "BZ020000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON CAB DLS 2.2",
    label: "XENON CAB DLS 2.2",
    value: "XENON CAB DLS 2.2"
  },
  {
    SERIAL: "BZ030000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON DOUBLECAB DLS 2.2",
    label: "XENON DOUBLECAB DLS 2.2",
    value: "XENON DOUBLECAB DLS 2.2"
  },
  {
    SERIAL: "BZ040000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON EXTENED CAB CLE 2.1 CNG",
    label: "XENON EXTENED CAB CLE 2.1 CNG",
    value: "XENON EXTENED CAB CLE 2.1 CNG"
  },
  {
    SERIAL: "BZ050000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLECAB GIANT 2.2",
    label: "XENON SINGLECAB GIANT 2.2",
    value: "XENON SINGLECAB GIANT 2.2"
  },
  {
    SERIAL: "BZ060000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLE CAB GIANT CNG",
    label: "XENON SINGLE CAB GIANT CNG",
    value: "XENON SINGLE CAB GIANT CNG"
  },
  {
    SERIAL: "BZ070000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "SUPER ACE 1.4",
    label: "SUPER ACE 1.4",
    value: "SUPER ACE 1.4"
  },
  {
    SERIAL: "BZ100000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "NANO",
    label: "NANO",
    value: "NANO"
  },
  {
    SERIAL: "BZ110000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "EXTENDED CAB MAX CAB 2.1 CNG",
    label: "EXTENDED CAB MAX CAB 2.1 CNG",
    value: "EXTENDED CAB MAX CAB 2.1 CNG"
  },
  {
    SERIAL: "BZ120000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "EXTENDED CAB MAX CAB 2.2",
    label: "EXTENDED CAB MAX CAB 2.2",
    value: "EXTENDED CAB MAX CAB 2.2"
  },
  {
    SERIAL: "BZ130000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLE CAB GIANT HEAVY DUTY 2.2",
    label: "XENON SINGLE CAB GIANT HEAVY DUTY 2.2",
    value: "XENON SINGLE CAB GIANT HEAVY DUTY 2.2"
  },
  {
    SERIAL: "BZ140000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLE CAB GIANT HEAVYDUTY CNG2.1",
    label: "XENON SINGLE CAB GIANT HEAVYDUTY CNG2.1",
    value: "XENON SINGLE CAB GIANT HEAVYDUTY CNG2.1"
  },
  {
    SERIAL: "BZ150000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLE CAB 150N X-PERT 2.2 4WD",
    label: "XENON SINGLE CAB 150N X-PERT 2.2 4WD",
    value: "XENON SINGLE CAB 150N X-PERT 2.2 4WD"
  },
  {
    SERIAL: "BZ160000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON 150N X-PLORE DOUBLE CAB 2.2 4WD",
    label: "XENON 150N X-PLORE DOUBLE CAB 2.2 4WD",
    value: "XENON 150N X-PLORE DOUBLE CAB 2.2 4WD"
  },
  {
    SERIAL: "BZ170000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLE CAB 150N X-PERT HEAVY DUTY",
    label: "XENON SINGLE CAB 150N X-PERT HEAVY DUTY",
    value: "XENON SINGLE CAB 150N X-PERT HEAVY DUTY"
  },
  {
    SERIAL: "BZ180000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON 150N X-TREME DOUBLE CAB 2.2",
    label: "XENON 150N X-TREME DOUBLE CAB 2.2",
    value: "XENON 150N X-TREME DOUBLE CAB 2.2"
  },
  {
    SERIAL: "BZ190000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLE CAB HEAVY DUTY CNG PLUS2.1",
    label: "XENON SINGLE CAB HEAVY DUTY CNG PLUS2.1",
    value: "XENON SINGLE CAB HEAVY DUTY CNG PLUS2.1"
  },
  {
    SERIAL: "BZ200000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "XENON SINGLE CAB N-EXPERT CHASIS 2.2",
    label: "XENON SINGLE CAB N-EXPERT CHASIS 2.2",
    value: "XENON SINGLE CAB N-EXPERT CHASIS 2.2"
  },
  {
    SERIAL: "BZ210000026",
    MAKE_NAME: "TATA",
    MODEL_NAME: "ULTRA",
    label: "ULTRA",
    value: "ULTRA"
  },
  {
    SERIAL: "CA000000026",
    MAKE_NAME: "NIVA",
    MODEL_NAME: "NIVA",
    label: "NIVA",
    value: "NIVA"
  },
  {
    SERIAL: "CB000000026",
    MAKE_NAME: "WOLSELEY",
    MODEL_NAME: "WOLSELEY",
    label: "WOLSELEY",
    value: "WOLSELEY"
  },
  {
    SERIAL: "CC000000026",
    MAKE_NAME: "COMBI",
    MODEL_NAME: "COMBI",
    label: "COMBI",
    value: "COMBI"
  },
  {
    SERIAL: "CC010000026",
    MAKE_NAME: "COMBI",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "CD000000026",
    MAKE_NAME: "KEDONG",
    MODEL_NAME: "KEDONG",
    label: "KEDONG",
    value: "KEDONG"
  },
  {
    SERIAL: "CE000000026",
    MAKE_NAME: "FAR",
    MODEL_NAME: "FAR",
    label: "FAR",
    value: "FAR"
  },
  {
    SERIAL: "CF000000026",
    MAKE_NAME: "TOYOPET",
    MODEL_NAME: "TOYOPET",
    label: "TOYOPET",
    value: "TOYOPET"
  },
  {
    SERIAL: "CG000000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PROTON",
    label: "PROTON",
    value: "PROTON"
  },
  {
    SERIAL: "CG010000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "WIRA",
    label: "WIRA",
    value: "WIRA"
  },
  {
    SERIAL: "CG020000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PERDANA",
    label: "PERDANA",
    value: "PERDANA"
  },
  {
    SERIAL: "CG030000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SAVVY MEDIUM LINE M",
    label: "SAVVY MEDIUM LINE M",
    value: "SAVVY MEDIUM LINE M"
  },
  {
    SERIAL: "CG040000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "GEN 2 MEDIUM LINE M",
    label: "GEN 2 MEDIUM LINE M",
    value: "GEN 2 MEDIUM LINE M"
  },
  {
    SERIAL: "CG050000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "NEO MEDIUM LINE M",
    label: "NEO MEDIUM LINE M",
    value: "NEO MEDIUM LINE M"
  },
  {
    SERIAL: "CG060000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "GEN2 MEDIUM LINE A 1.6",
    label: "GEN2 MEDIUM LINE A 1.6",
    value: "GEN2 MEDIUM LINE A 1.6"
  },
  {
    SERIAL: "CG070000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "GEN2 HIGH LINE A",
    label: "GEN2 HIGH LINE A",
    value: "GEN2 HIGH LINE A"
  },
  {
    SERIAL: "CG080000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "NEO MEDIUM LINE A 1.6",
    label: "NEO MEDIUM LINE A 1.6",
    value: "NEO MEDIUM LINE A 1.6"
  },
  {
    SERIAL: "CG090000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "NEO HIGH LINE A",
    label: "NEO HIGH LINE A",
    value: "NEO HIGH LINE A"
  },
  {
    SERIAL: "CG110000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SAVVY MEDIUM LINE 1.1 A",
    label: "SAVVY MEDIUM LINE 1.1 A",
    value: "SAVVY MEDIUM LINE 1.1 A"
  },
  {
    SERIAL: "CG120000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SAVVY HIGH LINE 1.1 A",
    label: "SAVVY HIGH LINE 1.1 A",
    value: "SAVVY HIGH LINE 1.1 A"
  },
  {
    SERIAL: "CG130000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PERSONA MEDIUMLINE CNG 1.6 M",
    label: "PERSONA MEDIUMLINE CNG 1.6 M",
    value: "PERSONA MEDIUMLINE CNG 1.6 M"
  },
  {
    SERIAL: "CG140000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PERSONA MEDIUMLINE CNG 1.6 A",
    label: "PERSONA MEDIUMLINE CNG 1.6 A",
    value: "PERSONA MEDIUMLINE CNG 1.6 A"
  },
  {
    SERIAL: "CG150000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PERSONA HIGHLINE CNG 1.6 A",
    label: "PERSONA HIGHLINE CNG 1.6 A",
    value: "PERSONA HIGHLINE CNG 1.6 A"
  },
  {
    SERIAL: "CG160000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PERSONA HIGH LINE 1.6 A",
    label: "PERSONA HIGH LINE 1.6 A",
    value: "PERSONA HIGH LINE 1.6 A"
  },
  {
    SERIAL: "CG170000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PERSONA MEDIUMLINE M",
    label: "PERSONA MEDIUMLINE M",
    value: "PERSONA MEDIUMLINE M"
  },
  {
    SERIAL: "CG180000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PERSONA MEDIUMLINE A",
    label: "PERSONA MEDIUMLINE A",
    value: "PERSONA MEDIUMLINE A"
  },
  {
    SERIAL: "CG190000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA BASELINE 1.6 M",
    label: "EXORA BASELINE 1.6 M",
    value: "EXORA BASELINE 1.6 M"
  },
  {
    SERIAL: "CG200000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA MEDIUMLINE 1.6 A",
    label: "EXORA MEDIUMLINE 1.6 A",
    value: "EXORA MEDIUMLINE 1.6 A"
  },
  {
    SERIAL: "CG210000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA HIGHLINE 1.6 A TOP",
    label: "EXORA HIGHLINE 1.6 A TOP",
    value: "EXORA HIGHLINE 1.6 A TOP"
  },
  {
    SERIAL: "CG220000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SAGA BASE LINE M",
    label: "SAGA BASE LINE M",
    value: "SAGA BASE LINE M"
  },
  {
    SERIAL: "CG230000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SAGA BASE LINE 1.3 A",
    label: "SAGA BASE LINE 1.3 A",
    value: "SAGA BASE LINE 1.3 A"
  },
  {
    SERIAL: "CG240000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SAGA MEDIUM LINE 1.3 M",
    label: "SAGA MEDIUM LINE 1.3 M",
    value: "SAGA MEDIUM LINE 1.3 M"
  },
  {
    SERIAL: "CG250000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SAGA MEDIUM LINE 1.3 A",
    label: "SAGA MEDIUM LINE 1.3 A",
    value: "SAGA MEDIUM LINE 1.3 A"
  },
  {
    SERIAL: "CG260000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA BASELINE 1.6 A",
    label: "EXORA BASELINE 1.6 A",
    value: "EXORA BASELINE 1.6 A"
  },
  {
    SERIAL: "CG270000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA MEDUIMLINE 1.6 M",
    label: "EXORA MEDUIMLINE 1.6 M",
    value: "EXORA MEDUIMLINE 1.6 M"
  },
  {
    SERIAL: "CG290000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE STANDARD 1.6 M",
    label: "PREVE STANDARD 1.6 M",
    value: "PREVE STANDARD 1.6 M"
  },
  {
    SERIAL: "CG300000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE STANDARD 1.6 A",
    label: "PREVE STANDARD 1.6 A",
    value: "PREVE STANDARD 1.6 A"
  },
  {
    SERIAL: "CG310000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE EXECUTIVE 1.6 M",
    label: "PREVE EXECUTIVE 1.6 M",
    value: "PREVE EXECUTIVE 1.6 M"
  },
  {
    SERIAL: "CG320000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE EXECUTIVE 1.6 A",
    label: "PREVE EXECUTIVE 1.6 A",
    value: "PREVE EXECUTIVE 1.6 A"
  },
  {
    SERIAL: "CG330000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE PREMIUM 1.6 A",
    label: "PREVE PREMIUM 1.6 A",
    value: "PREVE PREMIUM 1.6 A"
  },
  {
    SERIAL: "CG340000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA PRIME 1.6",
    label: "EXORA PRIME 1.6",
    value: "EXORA PRIME 1.6"
  },
  {
    SERIAL: "CG350000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SUPRIMA S PREMIUM 1.6 A",
    label: "SUPRIMA S PREMIUM 1.6 A",
    value: "SUPRIMA S PREMIUM 1.6 A"
  },
  {
    SERIAL: "CG360000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "SUPRIMA S EXECUTIVE 1.6 A",
    label: "SUPRIMA S EXECUTIVE 1.6 A",
    value: "SUPRIMA S EXECUTIVE 1.6 A"
  },
  {
    SERIAL: "CG370000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA MEDIUM LINE 1.6 M LPG",
    label: "EXORA MEDIUM LINE 1.6 M LPG",
    value: "EXORA MEDIUM LINE 1.6 M LPG"
  },
  {
    SERIAL: "CG380000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA MEDIUM LINE 1.6 A LPG",
    label: "EXORA MEDIUM LINE 1.6 A LPG",
    value: "EXORA MEDIUM LINE 1.6 A LPG"
  },
  {
    SERIAL: "CG390000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA HIGH LINE 1.6 A LPG",
    label: "EXORA HIGH LINE 1.6 A LPG",
    value: "EXORA HIGH LINE 1.6 A LPG"
  },
  {
    SERIAL: "CG400000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE STANDARD 1.6 M LPG",
    label: "PREVE STANDARD 1.6 M LPG",
    value: "PREVE STANDARD 1.6 M LPG"
  },
  {
    SERIAL: "CG410000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE STANDARD 1.6 A LPG",
    label: "PREVE STANDARD 1.6 A LPG",
    value: "PREVE STANDARD 1.6 A LPG"
  },
  {
    SERIAL: "CG420000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE EXECUTIVE 1.6 M LPG",
    label: "PREVE EXECUTIVE 1.6 M LPG",
    value: "PREVE EXECUTIVE 1.6 M LPG"
  },
  {
    SERIAL: "CG430000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE EXECUTIVE 1.6 A LPG",
    label: "PREVE EXECUTIVE 1.6 A LPG",
    value: "PREVE EXECUTIVE 1.6 A LPG"
  },
  {
    SERIAL: "CG440000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "PREVE PREMIUM 1.6 A LPG",
    label: "PREVE PREMIUM 1.6 A LPG",
    value: "PREVE PREMIUM 1.6 A LPG"
  },
  {
    SERIAL: "CG470000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA PRIME 1.6 A LPG",
    label: "EXORA PRIME 1.6 A LPG",
    value: "EXORA PRIME 1.6 A LPG"
  },
  {
    SERIAL: "CG480000026",
    MAKE_NAME: "PROTON",
    MODEL_NAME: "EXORA",
    label: "EXORA",
    value: "EXORA"
  },
  {
    SERIAL: "CH000000026",
    MAKE_NAME: "ALLUS",
    MODEL_NAME: "ALLUS",
    label: "ALLUS",
    value: "ALLUS"
  },
  {
    SERIAL: "CI000000026",
    MAKE_NAME: "BORGWARD HANZA",
    MODEL_NAME: "BORGWARD HANZA",
    label: "BORGWARD HANZA",
    value: "BORGWARD HANZA"
  },
  {
    SERIAL: "CJ000000026",
    MAKE_NAME: "WILLSSAINTE CLAIRE",
    MODEL_NAME: "WILLSSAINTE CLAIRE",
    label: "WILLSSAINTE CLAIRE",
    value: "WILLSSAINTE CLAIRE"
  },
  {
    SERIAL: "CK000000026",
    MAKE_NAME: "RUF",
    MODEL_NAME: "RUF",
    label: "RUF",
    value: "RUF"
  },
  {
    SERIAL: "CK010000026",
    MAKE_NAME: "RUF",
    MODEL_NAME: "RT 12S COUPE",
    label: "RT 12S COUPE",
    value: "RT 12S COUPE"
  },
  {
    SERIAL: "CL000000026",
    MAKE_NAME: "BARBER",
    MODEL_NAME: "BARBER",
    label: "BARBER",
    value: "BARBER"
  },
  {
    SERIAL: "CN000000026",
    MAKE_NAME: "JOHN DEERE",
    MODEL_NAME: "JOHN DEERE",
    label: "JOHN DEERE",
    value: "JOHN DEERE"
  },
  {
    SERIAL: "CN010000026",
    MAKE_NAME: "JOHN DEERE",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "CO000000026",
    MAKE_NAME: "MNNCK",
    MODEL_NAME: "MNNCK",
    label: "MNNCK",
    value: "MNNCK"
  },
  {
    SERIAL: "CP000000026",
    MAKE_NAME: "GMC",
    MODEL_NAME: "GMC",
    label: "GMC",
    value: "GMC"
  },
  {
    SERIAL: "CP010000026",
    MAKE_NAME: "GMC",
    MODEL_NAME: "12 ตัน",
    label: "12 ตัน",
    value: "12 ตัน"
  },
  {
    SERIAL: "CP020000026",
    MAKE_NAME: "GMC",
    MODEL_NAME: "YUKON",
    label: "YUKON",
    value: "YUKON"
  },
  {
    SERIAL: "CQ000000026",
    MAKE_NAME: "ACURA",
    MODEL_NAME: "ACURA",
    label: "ACURA",
    value: "ACURA"
  },
  {
    SERIAL: "CR000000026",
    MAKE_NAME: "BERTONE",
    MODEL_NAME: "BERTONE",
    label: "BERTONE",
    value: "BERTONE"
  },
  {
    SERIAL: "CS000000026",
    MAKE_NAME: "CATERA",
    MODEL_NAME: "CATERA",
    label: "CATERA",
    value: "CATERA"
  },
  {
    SERIAL: "CT000000026",
    MAKE_NAME: "EAGLE",
    MODEL_NAME: "EAGLE",
    label: "EAGLE",
    value: "EAGLE"
  },
  {
    SERIAL: "CT010000026",
    MAKE_NAME: "EAGLE",
    MODEL_NAME: "EAGLE",
    label: "EAGLE",
    value: "EAGLE"
  },
  {
    SERIAL: "CU000000026",
    MAKE_NAME: "GM",
    MODEL_NAME: "GM",
    label: "GM",
    value: "GM"
  },
  {
    SERIAL: "CV000000026",
    MAKE_NAME: "REO",
    MODEL_NAME: "REO",
    label: "REO",
    value: "REO"
  },
  {
    SERIAL: "CV010000026",
    MAKE_NAME: "REO",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "CW000000026",
    MAKE_NAME: "HUMMER",
    MODEL_NAME: "HUMMER",
    label: "HUMMER",
    value: "HUMMER"
  },
  {
    SERIAL: "CW010000026",
    MAKE_NAME: "HUMMER",
    MODEL_NAME: "H2",
    label: "H2",
    value: "H2"
  },
  {
    SERIAL: "CW020000026",
    MAKE_NAME: "HUMMER",
    MODEL_NAME: "HUMMER",
    label: "HUMMER",
    value: "HUMMER"
  },
  {
    SERIAL: "CW030000026",
    MAKE_NAME: "HUMMER",
    MODEL_NAME: "H3",
    label: "H3",
    value: "H3"
  },
  {
    SERIAL: "CW040000026",
    MAKE_NAME: "HUMMER",
    MODEL_NAME: "H2",
    label: "H2",
    value: "H2"
  },
  {
    SERIAL: "CX000000026",
    MAKE_NAME: "INFINITI",
    MODEL_NAME: "INFINITI",
    label: "INFINITI",
    value: "INFINITI"
  },
  {
    SERIAL: "CX010000026",
    MAKE_NAME: "INFINITI",
    MODEL_NAME: "FX30 D",
    label: "FX30 D",
    value: "FX30 D"
  },
  {
    SERIAL: "CY000000026",
    MAKE_NAME: "ITALDESIGN",
    MODEL_NAME: "ITALDESIGN",
    label: "ITALDESIGN",
    value: "ITALDESIGN"
  },
  {
    SERIAL: "CZ000000026",
    MAKE_NAME: "LINCON",
    MODEL_NAME: "LINCON",
    label: "LINCON",
    value: "LINCON"
  },
  {
    SERIAL: "DA000000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "MCLAREN",
    label: "MCLAREN",
    value: "MCLAREN"
  },
  {
    SERIAL: "DA010000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "MP4-12",
    label: "MP4-12",
    value: "MP4-12"
  },
  {
    SERIAL: "DA020000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "650S 3.8 SPIDER",
    label: "650S 3.8 SPIDER",
    value: "650S 3.8 SPIDER"
  },
  {
    SERIAL: "DA030000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "650S 3.8 COUPE",
    label: "650S 3.8 COUPE",
    value: "650S 3.8 COUPE"
  },
  {
    SERIAL: "DA040000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "675LT 3.8",
    label: "675LT 3.8",
    value: "675LT 3.8"
  },
  {
    SERIAL: "DA050000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "570S COUPE 3.8",
    label: "570S COUPE 3.8",
    value: "570S COUPE 3.8"
  },
  {
    SERIAL: "DA060000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "720S 4.0 COUPE",
    label: "720S 4.0 COUPE",
    value: "720S 4.0 COUPE"
  },
  {
    SERIAL: "DA070000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "540C 3.8 COUPE",
    label: "540C 3.8 COUPE",
    value: "540C 3.8 COUPE"
  },
  {
    SERIAL: "DA080000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "600LT 3.8",
    label: "600LT 3.8",
    value: "600LT 3.8"
  },
  {
    SERIAL: "DA090000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "GT 4.0",
    label: "GT 4.0",
    value: "GT 4.0"
  },
  {
    SERIAL: "DA100000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "765LT 4.0",
    label: "765LT 4.0",
    value: "765LT 4.0"
  },
  {
    SERIAL: "DA150000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "570GT 3.8",
    label: "570GT 3.8",
    value: "570GT 3.8"
  },
  {
    SERIAL: "DA250000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "720S 4.0 SPIDER",
    label: "720S 4.0 SPIDER",
    value: "720S 4.0 SPIDER"
  },
  {
    SERIAL: "DA340000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "720S COUPE LUXURY",
    label: "720S COUPE LUXURY",
    value: "720S COUPE LUXURY"
  },
  {
    SERIAL: "DA500000026",
    MAKE_NAME: "MCLAREN",
    MODEL_NAME: "600LT SPIDER 3.8",
    label: "600LT SPIDER 3.8",
    value: "600LT SPIDER 3.8"
  },
  {
    SERIAL: "DB000000026",
    MAKE_NAME: "MERCURY",
    MODEL_NAME: "MERCURY",
    label: "MERCURY",
    value: "MERCURY"
  },
  {
    SERIAL: "DD000000026",
    MAKE_NAME: "MORGAN",
    MODEL_NAME: "MORGAN",
    label: "MORGAN",
    value: "MORGAN"
  },
  {
    SERIAL: "DD010000026",
    MAKE_NAME: "MORGAN",
    MODEL_NAME: "4/4 SPORT 1.6",
    label: "4/4 SPORT 1.6",
    value: "4/4 SPORT 1.6"
  },
  {
    SERIAL: "DD020000026",
    MAKE_NAME: "MORGAN",
    MODEL_NAME: "3 Wheeler",
    label: "3 Wheeler",
    value: "3 Wheeler"
  },
  {
    SERIAL: "DF000000026",
    MAKE_NAME: "SATURN",
    MODEL_NAME: "SATURN",
    label: "SATURN",
    value: "SATURN"
  },
  {
    SERIAL: "DG000000026",
    MAKE_NAME: "SHADA",
    MODEL_NAME: "SHADA",
    label: "SHADA",
    value: "SHADA"
  },
  {
    SERIAL: "DH000000026",
    MAKE_NAME: "TVR",
    MODEL_NAME: "TVR",
    label: "TVR",
    value: "TVR"
  },
  {
    SERIAL: "DJ000000026",
    MAKE_NAME: "YULON",
    MODEL_NAME: "YULON",
    label: "YULON",
    value: "YULON"
  },
  {
    SERIAL: "DK000000026",
    MAKE_NAME: "ZAGATO",
    MODEL_NAME: "ZAGATO",
    label: "ZAGATO",
    value: "ZAGATO"
  },
  {
    SERIAL: "DL000000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "KTM",
    label: "KTM",
    value: "KTM"
  },
  {
    SERIAL: "DL010000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "X-BOW",
    label: "X-BOW",
    value: "X-BOW"
  },
  {
    SERIAL: "DL020000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "X-BOW",
    label: "X-BOW",
    value: "X-BOW"
  },
  {
    SERIAL: "DL030000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "990 SUPERDUKE",
    label: "990 SUPERDUKE",
    value: "990 SUPERDUKE"
  },
  {
    SERIAL: "DL040000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "990 SUPERDUKE R",
    label: "990 SUPERDUKE R",
    value: "990 SUPERDUKE R"
  },
  {
    SERIAL: "DL050000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "690 SMC",
    label: "690 SMC",
    value: "690 SMC"
  },
  {
    SERIAL: "DL060000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "990 SUPERMOTO T ABS",
    label: "990 SUPERMOTO T ABS",
    value: "990 SUPERMOTO T ABS"
  },
  {
    SERIAL: "DL070000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "990 SUPERMOTO R",
    label: "990 SUPERMOTO R",
    value: "990 SUPERMOTO R"
  },
  {
    SERIAL: "DL080000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "690 ENDURO R",
    label: "690 ENDURO R",
    value: "690 ENDURO R"
  },
  {
    SERIAL: "DL090000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "990 ADVENTURE",
    label: "990 ADVENTURE",
    value: "990 ADVENTURE"
  },
  {
    SERIAL: "DL100000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "990 ADVENTURE R",
    label: "990 ADVENTURE R",
    value: "990 ADVENTURE R"
  },
  {
    SERIAL: "DL110000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "690 DUKE",
    label: "690 DUKE",
    value: "690 DUKE"
  },
  {
    SERIAL: "DL120000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "690 SMCR",
    label: "690 SMCR",
    value: "690 SMCR"
  },
  {
    SERIAL: "DL130000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1190 ADVENTURE",
    label: "1190 ADVENTURE",
    value: "1190 ADVENTURE"
  },
  {
    SERIAL: "DL140000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "X-BOW GT",
    label: "X-BOW GT",
    value: "X-BOW GT"
  },
  {
    SERIAL: "DL150000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1290 SUPER DUKE",
    label: "1290 SUPER DUKE",
    value: "1290 SUPER DUKE"
  },
  {
    SERIAL: "DL160000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "BOLT",
    label: "BOLT",
    value: "BOLT"
  },
  {
    SERIAL: "DL170000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "690 DUKE R",
    label: "690 DUKE R",
    value: "690 DUKE R"
  },
  {
    SERIAL: "DL180000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1190 RC8 R",
    label: "1190 RC8 R",
    value: "1190 RC8 R"
  },
  {
    SERIAL: "DL190000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1190 ADVENTURE R",
    label: "1190 ADVENTURE R",
    value: "1190 ADVENTURE R"
  },
  {
    SERIAL: "DL200000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "RC 200",
    label: "RC 200",
    value: "RC 200"
  },
  {
    SERIAL: "DL210000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "RC 390",
    label: "RC 390",
    value: "RC 390"
  },
  {
    SERIAL: "DL220000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1290 SUPER ADVENTURE",
    label: "1290 SUPER ADVENTURE",
    value: "1290 SUPER ADVENTURE"
  },
  {
    SERIAL: "DL230000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1050 ADVENTURE",
    label: "1050 ADVENTURE",
    value: "1050 ADVENTURE"
  },
  {
    SERIAL: "DL240000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1190 RC8",
    label: "1190 RC8",
    value: "1190 RC8"
  },
  {
    SERIAL: "DL250000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1290 SUPERDUKE R SPECIAL EDITION",
    label: "1290 SUPERDUKE R SPECIAL EDITION",
    value: "1290 SUPERDUKE R SPECIAL EDITION"
  },
  {
    SERIAL: "DL260000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "RC 250",
    label: "RC 250",
    value: "RC 250"
  },
  {
    SERIAL: "DL270000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "DL280000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1290 SUPER DUKE R",
    label: "1290 SUPER DUKE R",
    value: "1290 SUPER DUKE R"
  },
  {
    SERIAL: "DL290000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "390 DUKE",
    label: "390 DUKE",
    value: "390 DUKE"
  },
  {
    SERIAL: "DL300000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "1090 ADVENTURE",
    label: "1090 ADVENTURE",
    value: "1090 ADVENTURE"
  },
  {
    SERIAL: "DL310000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "790 ADVENTURE",
    label: "790 ADVENTURE",
    value: "790 ADVENTURE"
  },
  {
    SERIAL: "DL320000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "790 ADVENTURE R",
    label: "790 ADVENTURE R",
    value: "790 ADVENTURE R"
  },
  {
    SERIAL: "DL330000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "890 ADVENTURE R",
    label: "890 ADVENTURE R",
    value: "890 ADVENTURE R"
  },
  {
    SERIAL: "DL470000026",
    MAKE_NAME: "KTM",
    MODEL_NAME: "350 EXC",
    label: "350 EXC",
    value: "350 EXC"
  },
  {
    SERIAL: "DM000000026",
    MAKE_NAME: "SAMSUNG",
    MODEL_NAME: "SAMSUNG",
    label: "SAMSUNG",
    value: "SAMSUNG"
  },
  {
    SERIAL: "DM010000026",
    MAKE_NAME: "SAMSUNG",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "DN000000026",
    MAKE_NAME: "DE MAC",
    MODEL_NAME: "DE MAC",
    label: "DE MAC",
    value: "DE MAC"
  },
  {
    SERIAL: "DO000000026",
    MAKE_NAME: "BABERGREEN",
    MODEL_NAME: "BABERGREEN",
    label: "BABERGREEN",
    value: "BABERGREEN"
  },
  {
    SERIAL: "DP000000026",
    MAKE_NAME: "BEIJINE",
    MODEL_NAME: "BEIJINE",
    label: "BEIJINE",
    value: "BEIJINE"
  },
  {
    SERIAL: "DQ000000026",
    MAKE_NAME: "BITELLI",
    MODEL_NAME: "BITELLI",
    label: "BITELLI",
    value: "BITELLI"
  },
  {
    SERIAL: "DQ010000026",
    MAKE_NAME: "BITELLI",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "DR000000026",
    MAKE_NAME: "GAGIVA",
    MODEL_NAME: "CAGIVA",
    label: "CAGIVA",
    value: "CAGIVA"
  },
  {
    SERIAL: "DS000000026",
    MAKE_NAME: "PERODUA",
    MODEL_NAME: "PERADUA",
    label: "PERADUA",
    value: "PERADUA"
  },
  {
    SERIAL: "DS010000026",
    MAKE_NAME: "PERODUA",
    MODEL_NAME: "KELISA",
    label: "KELISA",
    value: "KELISA"
  },
  {
    SERIAL: "DS020000026",
    MAKE_NAME: "PERODUA",
    MODEL_NAME: "KEMBARA",
    label: "KEMBARA",
    value: "KEMBARA"
  },
  {
    SERIAL: "DS030000026",
    MAKE_NAME: "PERODUA",
    MODEL_NAME: "KANCIL",
    label: "KANCIL",
    value: "KANCIL"
  },
  {
    SERIAL: "DT000000026",
    MAKE_NAME: "BOSS",
    MODEL_NAME: "BOSS",
    label: "BOSS",
    value: "BOSS"
  },
  {
    SERIAL: "DU000000026",
    MAKE_NAME: "MGF",
    MODEL_NAME: "MGF",
    label: "MGF",
    value: "MGF"
  },
  {
    SERIAL: "DV000000026",
    MAKE_NAME: "PLATINUM",
    MODEL_NAME: "PLATINUM",
    label: "PLATINUM",
    value: "PLATINUM"
  },
  {
    SERIAL: "DV010000026",
    MAKE_NAME: "PLATINUM",
    MODEL_NAME: "MONACO",
    label: "MONACO",
    value: "MONACO"
  },
  {
    SERIAL: "DV020000026",
    MAKE_NAME: "PLATINUM",
    MODEL_NAME: "HOT TOWN",
    label: "HOT TOWN",
    value: "HOT TOWN"
  },
  {
    SERIAL: "DW000000026",
    MAKE_NAME: "MAN",
    MODEL_NAME: "MAN",
    label: "MAN",
    value: "MAN"
  },
  {
    SERIAL: "DW010000026",
    MAKE_NAME: "MAN",
    MODEL_NAME: "TRAILER",
    label: "TRAILER",
    value: "TRAILER"
  },
  {
    SERIAL: "DW020000026",
    MAKE_NAME: "MAN",
    MODEL_NAME: "R37-BUS",
    label: "R37-BUS",
    value: "R37-BUS"
  },
  {
    SERIAL: "DW030000026",
    MAKE_NAME: "MAN",
    MODEL_NAME: "CLA 26.280 6*4",
    label: "CLA 26.280 6*4",
    value: "CLA 26.280 6*4"
  },
  {
    SERIAL: "DW040000026",
    MAKE_NAME: "MAN",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "DX000000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TR",
    label: "TR",
    value: "TR"
  },
  {
    SERIAL: "DX020000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER 4WD 3.0",
    label: "TRANFORMER 4WD 3.0",
    value: "TRANFORMER 4WD 3.0"
  },
  {
    SERIAL: "DX030000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER PLUS4 4WD 3.0A",
    label: "TRANFORMER PLUS4 4WD 3.0A",
    value: "TRANFORMER PLUS4 4WD 3.0A"
  },
  {
    SERIAL: "DX040000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER 2.5",
    label: "TRANFORMER 2.5",
    value: "TRANFORMER 2.5"
  },
  {
    SERIAL: "DX060000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER II 5SEAT 2.4 2WD",
    label: "TRANFORMER II 5SEAT 2.4 2WD",
    value: "TRANFORMER II 5SEAT 2.4 2WD"
  },
  {
    SERIAL: "DX070000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER II 5SEAT 2.8 4WD",
    label: "TRANFORMER II 5SEAT 2.8 4WD",
    value: "TRANFORMER II 5SEAT 2.8 4WD"
  },
  {
    SERIAL: "DX080000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "3000",
    label: "3000",
    value: "3000"
  },
  {
    SERIAL: "DX090000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER MAX 9SEAT 2.4 2WD",
    label: "TRANFORMER MAX 9SEAT 2.4 2WD",
    value: "TRANFORMER MAX 9SEAT 2.4 2WD"
  },
  {
    SERIAL: "DX100000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER MAX 9SEAT 2.8 4WD",
    label: "TRANFORMER MAX 9SEAT 2.8 4WD",
    value: "TRANFORMER MAX 9SEAT 2.8 4WD"
  },
  {
    SERIAL: "DX110000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER MAXI 11 SEAT 2.4 2WD",
    label: "TRANFORMER MAXI 11 SEAT 2.4 2WD",
    value: "TRANFORMER MAXI 11 SEAT 2.4 2WD"
  },
  {
    SERIAL: "DX120000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER MAXI 11 SEAT 2.8 4WD",
    label: "TRANFORMER MAXI 11 SEAT 2.8 4WD",
    value: "TRANFORMER MAXI 11 SEAT 2.8 4WD"
  },
  {
    SERIAL: "DX130000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER MAXI 7SEAT 2.4 2WD",
    label: "TRANFORMER MAXI 7SEAT 2.4 2WD",
    value: "TRANFORMER MAXI 7SEAT 2.4 2WD"
  },
  {
    SERIAL: "DX140000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER MAXI 7SEAT 2.8 4WD",
    label: "TRANFORMER MAXI 7SEAT 2.8 4WD",
    value: "TRANFORMER MAXI 7SEAT 2.8 4WD"
  },
  {
    SERIAL: "DX150000026",
    MAKE_NAME: "TR",
    MODEL_NAME: "TRANFORMER X-TREME 9SEAT 2.8 4WD",
    label: "TRANFORMER X-TREME 9SEAT 2.8 4WD",
    value: "TRANFORMER X-TREME 9SEAT 2.8 4WD"
  },
  {
    SERIAL: "DZ000000026",
    MAKE_NAME: "OTTAWA",
    MODEL_NAME: "OTTAWA",
    label: "OTTAWA",
    value: "OTTAWA"
  },
  {
    SERIAL: "EA000000026",
    MAKE_NAME: "COMMINS",
    MODEL_NAME: "COMMINS",
    label: "COMMINS",
    value: "COMMINS"
  },
  {
    SERIAL: "EB000000026",
    MAKE_NAME: "MASSCYFERCUSON",
    MODEL_NAME: "MASSCYFERCUSON",
    label: "MASSCYFERCUSON",
    value: "MASSCYFERCUSON"
  },
  {
    SERIAL: "EB010000026",
    MAKE_NAME: "MASSCYFERCUSON",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "EC000000026",
    MAKE_NAME: "SUNBEAM",
    MODEL_NAME: "SUNBEAM",
    label: "SUNBEAM",
    value: "SUNBEAM"
  },
  {
    SERIAL: "ED000000026",
    MAKE_NAME: "AMMANN",
    MODEL_NAME: "AMMANN",
    label: "AMMANN",
    value: "AMMANN"
  },
  {
    SERIAL: "EE000000026",
    MAKE_NAME: "FESER",
    MODEL_NAME: "FESER",
    label: "FESER",
    value: "FESER"
  },
  {
    SERIAL: "EE010000026",
    MAKE_NAME: "FESER",
    MODEL_NAME: "FESER",
    label: "FESER",
    value: "FESER"
  },
  {
    SERIAL: "EF000000026",
    MAKE_NAME: "APRILIA",
    MODEL_NAME: "APRILIA",
    label: "APRILIA",
    value: "APRILIA"
  },
  {
    SERIAL: "EF010000026",
    MAKE_NAME: "APRILIA",
    MODEL_NAME: "APRIILIA",
    label: "APRIILIA",
    value: "APRIILIA"
  },
  {
    SERIAL: "EF020000026",
    MAKE_NAME: "APRILIA",
    MODEL_NAME: "RSV 4",
    label: "RSV 4",
    value: "RSV 4"
  },
  {
    SERIAL: "EF030000026",
    MAKE_NAME: "APRILIA",
    MODEL_NAME: "TUONO V4 1100 FACTORY",
    label: "TUONO V4 1100 FACTORY",
    value: "TUONO V4 1100 FACTORY"
  },
  {
    SERIAL: "EF040000026",
    MAKE_NAME: "APRILIA",
    MODEL_NAME: "RSV4 RR",
    label: "RSV4 RR",
    value: "RSV4 RR"
  },
  {
    SERIAL: "EF050000026",
    MAKE_NAME: "APRILIA",
    MODEL_NAME: "RSV4 RF",
    label: "RSV4 RF",
    value: "RSV4 RF"
  },
  {
    SERIAL: "EG000000026",
    MAKE_NAME: "KANCIL",
    MODEL_NAME: "KANCIL",
    label: "KANCIL",
    value: "KANCIL"
  },
  {
    SERIAL: "EG010000026",
    MAKE_NAME: "KANCIL",
    MODEL_NAME: "850",
    label: "850",
    value: "850"
  },
  {
    SERIAL: "EG020000026",
    MAKE_NAME: "KANCIL",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "EH000000026",
    MAKE_NAME: "HUTSON",
    MODEL_NAME: "HUTSON",
    label: "HUTSON",
    value: "HUTSON"
  },
  {
    SERIAL: "EI000000026",
    MAKE_NAME: "TIGER",
    MODEL_NAME: "TIGER",
    label: "TIGER",
    value: "TIGER"
  },
  {
    SERIAL: "EI010000026",
    MAKE_NAME: "TIGER",
    MODEL_NAME: "SMART",
    label: "SMART",
    value: "SMART"
  },
  {
    SERIAL: "EI020000026",
    MAKE_NAME: "TIGER",
    MODEL_NAME: "125 CC",
    label: "125 CC",
    value: "125 CC"
  },
  {
    SERIAL: "EI030000026",
    MAKE_NAME: "TIGER",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "EI040000026",
    MAKE_NAME: "TIGER",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "EJ000000026",
    MAKE_NAME: "MARCOS",
    MODEL_NAME: "MARCOS",
    label: "MARCOS",
    value: "MARCOS"
  },
  {
    SERIAL: "EK000000026",
    MAKE_NAME: "CHEPPEL",
    MODEL_NAME: "CHEPPEL",
    label: "CHEPPEL",
    value: "CHEPPEL"
  },
  {
    SERIAL: "EL000000026",
    MAKE_NAME: "JRD",
    MODEL_NAME: "JRD",
    label: "JRD",
    value: "JRD"
  },
  {
    SERIAL: "EL010000026",
    MAKE_NAME: "JRD",
    MODEL_NAME: "TORNADO",
    label: "TORNADO",
    value: "TORNADO"
  },
  {
    SERIAL: "EL020000026",
    MAKE_NAME: "JRD",
    MODEL_NAME: "SNOW-Z",
    label: "SNOW-Z",
    value: "SNOW-Z"
  },
  {
    SERIAL: "EM000000026",
    MAKE_NAME: "NEOPLAN",
    MODEL_NAME: "NEOPLAN",
    label: "NEOPLAN",
    value: "NEOPLAN"
  },
  {
    SERIAL: "EN000000026",
    MAKE_NAME: "DEUTZ",
    MODEL_NAME: "DEUTZ",
    label: "DEUTZ",
    value: "DEUTZ"
  },
  {
    SERIAL: "EN010000026",
    MAKE_NAME: "DEUTZ",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "EO000000026",
    MAKE_NAME: "KANUCH",
    MODEL_NAME: "KANUCH",
    label: "KANUCH",
    value: "KANUCH"
  },
  {
    SERIAL: "EP000000026",
    MAKE_NAME: "GEO",
    MODEL_NAME: "GEO",
    label: "GEO",
    value: "GEO"
  },
  {
    SERIAL: "EQ000000026",
    MAKE_NAME: "IVECO",
    MODEL_NAME: "IVECO",
    label: "IVECO",
    value: "IVECO"
  },
  {
    SERIAL: "EQ010000026",
    MAKE_NAME: "IVECO",
    MODEL_NAME: "682",
    label: "682",
    value: "682"
  },
  {
    SERIAL: "EQ020000026",
    MAKE_NAME: "IVECO",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "ER000000026",
    MAKE_NAME: "DONGFENG",
    MODEL_NAME: "DONGFENG",
    label: "DONGFENG",
    value: "DONGFENG"
  },
  {
    SERIAL: "ER010000026",
    MAKE_NAME: "DONGFENG",
    MODEL_NAME: "TRAILER CNG",
    label: "TRAILER CNG",
    value: "TRAILER CNG"
  },
  {
    SERIAL: "ER020000026",
    MAKE_NAME: "DONGFENG",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "ES000000026",
    MAKE_NAME: "WULING",
    MODEL_NAME: "WULING",
    label: "WULING",
    value: "WULING"
  },
  {
    SERIAL: "ES010000026",
    MAKE_NAME: "WULING",
    MODEL_NAME: "SCORPION",
    label: "SCORPION",
    value: "SCORPION"
  },
  {
    SERIAL: "ES020000026",
    MAKE_NAME: "WULING",
    MODEL_NAME: "V1 8SEAT",
    label: "V1 8SEAT",
    value: "V1 8SEAT"
  },
  {
    SERIAL: "ES030000026",
    MAKE_NAME: "WULING",
    MODEL_NAME: "V1 11SEAT",
    label: "V1 11SEAT",
    value: "V1 11SEAT"
  },
  {
    SERIAL: "ES040000026",
    MAKE_NAME: "WULING",
    MODEL_NAME: "HONGTU COMFORTABLE VAN 7SEAT",
    label: "HONGTU COMFORTABLE VAN 7SEAT",
    value: "HONGTU COMFORTABLE VAN 7SEAT"
  },
  {
    SERIAL: "ES050000026",
    MAKE_NAME: "WULING",
    MODEL_NAME: "HONGTU TRANSPORT VAN TRUCK",
    label: "HONGTU TRANSPORT VAN TRUCK",
    value: "HONGTU TRANSPORT VAN TRUCK"
  },
  {
    SERIAL: "ES060000026",
    MAKE_NAME: "WULING",
    MODEL_NAME: "HONGTU TRANSPORT VAN TRUCK LPG",
    label: "HONGTU TRANSPORT VAN TRUCK LPG",
    value: "HONGTU TRANSPORT VAN TRUCK LPG"
  },
  {
    SERIAL: "ET000000026",
    MAKE_NAME: "VOGELE",
    MODEL_NAME: "VOGELE",
    label: "VOGELE",
    value: "VOGELE"
  },
  {
    SERIAL: "EU000000026",
    MAKE_NAME: "GARUDA",
    MODEL_NAME: "GARUDA",
    label: "GARUDA",
    value: "GARUDA"
  },
  {
    SERIAL: "EU010000026",
    MAKE_NAME: "GARUDA",
    MODEL_NAME: "HELLO",
    label: "HELLO",
    value: "HELLO"
  },
  {
    SERIAL: "EV000000026",
    MAKE_NAME: "CHANG JAING",
    MODEL_NAME: "CHANG JAING",
    label: "CHANG JAING",
    value: "CHANG JAING"
  },
  {
    SERIAL: "EW000000026",
    MAKE_NAME: "CASE",
    MODEL_NAME: "CASE",
    label: "CASE",
    value: "CASE"
  },
  {
    SERIAL: "EW010000026",
    MAKE_NAME: "CASE",
    MODEL_NAME: "CAR",
    label: "CAR",
    value: "CAR"
  },
  {
    SERIAL: "EX000000026",
    MAKE_NAME: "LIFAN",
    MODEL_NAME: "LIFAN",
    label: "LIFAN",
    value: "LIFAN"
  },
  {
    SERIAL: "EX010000026",
    MAKE_NAME: "LIFAN",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "EX020000026",
    MAKE_NAME: "LIFAN",
    MODEL_NAME: "110",
    label: "110",
    value: "110"
  },
  {
    SERIAL: "EY000000026",
    MAKE_NAME: "BMC",
    MODEL_NAME: "BMC",
    label: "BMC",
    value: "BMC"
  },
  {
    SERIAL: "EZ000000026",
    MAKE_NAME: "PEGASUS",
    MODEL_NAME: "PEGASUS",
    label: "PEGASUS",
    value: "PEGASUS"
  },
  {
    SERIAL: "FB000000026",
    MAKE_NAME: "ATV",
    MODEL_NAME: "ATV",
    label: "ATV",
    value: "ATV"
  },
  {
    SERIAL: "FC000000026",
    MAKE_NAME: "VIBROMAX",
    MODEL_NAME: "VIBROMAX",
    label: "VIBROMAX",
    value: "VIBROMAX"
  },
  {
    SERIAL: "FC010000026",
    MAKE_NAME: "VIBROMAX",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "FD000000026",
    MAKE_NAME: "GOLDEN DRAGON",
    MODEL_NAME: "GOLDEN DRAGON",
    label: "GOLDEN DRAGON",
    value: "GOLDEN DRAGON"
  },
  {
    SERIAL: "FD030000026",
    MAKE_NAME: "GOLDEN DRAGON",
    MODEL_NAME: "GOLDEN DRAGON XML 6796",
    label: "GOLDEN DRAGON XML 6796",
    value: "GOLDEN DRAGON XML 6796"
  },
  {
    SERIAL: "FD040000026",
    MAKE_NAME: "GOLDEN DRAGON",
    MODEL_NAME: "LFZ",
    label: "LFZ",
    value: "LFZ"
  },
  {
    SERIAL: "FD050000026",
    MAKE_NAME: "GOLDEN DRAGON",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "FD060000026",
    MAKE_NAME: "GOLDEN DRAGON",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "FD070000026",
    MAKE_NAME: "GOLDEN DRAGON",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "FF000000026",
    MAKE_NAME: "LONDON CAB",
    MODEL_NAME: "LONDON CAB",
    label: "LONDON CAB",
    value: "LONDON CAB"
  },
  {
    SERIAL: "FF010000026",
    MAKE_NAME: "LONDON CAB",
    MODEL_NAME: "LONDIN CAB",
    label: "LONDIN CAB",
    value: "LONDIN CAB"
  },
  {
    SERIAL: "FG000000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "INDIAN",
    label: "INDIAN",
    value: "INDIAN"
  },
  {
    SERIAL: "FG010000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "CRUISER CHIEF CLASSIC",
    label: "CRUISER CHIEF CLASSIC",
    value: "CRUISER CHIEF CLASSIC"
  },
  {
    SERIAL: "FG020000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "CHIEF TAIN",
    label: "CHIEF TAIN",
    value: "CHIEF TAIN"
  },
  {
    SERIAL: "FG040000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "CHIEF VINTAGE",
    label: "CHIEF VINTAGE",
    value: "CHIEF VINTAGE"
  },
  {
    SERIAL: "FG050000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "ROAD MASTER",
    label: "ROAD MASTER",
    value: "ROAD MASTER"
  },
  {
    SERIAL: "FG060000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "SCOUT",
    label: "SCOUT",
    value: "SCOUT"
  },
  {
    SERIAL: "FG070000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "CHIEF DARK HORSE",
    label: "CHIEF DARK HORSE",
    value: "CHIEF DARK HORSE"
  },
  {
    SERIAL: "FG250000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "CHIEF CLASSIC",
    label: "CHIEF CLASSIC",
    value: "CHIEF CLASSIC"
  },
  {
    SERIAL: "FG260000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "SCOUT BOBBER TWENTY",
    label: "SCOUT BOBBER TWENTY",
    value: "SCOUT BOBBER TWENTY"
  },
  {
    SERIAL: "FG270000026",
    MAKE_NAME: "INDIAN",
    MODEL_NAME: "ROAD MASTER ICON",
    label: "ROAD MASTER ICON",
    value: "ROAD MASTER ICON"
  },
  {
    SERIAL: "FH000000026",
    MAKE_NAME: "BUELL",
    MODEL_NAME: "BUELL",
    label: "BUELL",
    value: "BUELL"
  },
  {
    SERIAL: "FH010000026",
    MAKE_NAME: "BUELL",
    MODEL_NAME: "FIRE BOLT 1200CC",
    label: "FIRE BOLT 1200CC",
    value: "FIRE BOLT 1200CC"
  },
  {
    SERIAL: "FJ000000026",
    MAKE_NAME: "JOYNER",
    MODEL_NAME: "JOYNER",
    label: "JOYNER",
    value: "JOYNER"
  },
  {
    SERIAL: "FK000000026",
    MAKE_NAME: "HARDE",
    MODEL_NAME: "HARDE",
    label: "HARDE",
    value: "HARDE"
  },
  {
    SERIAL: "FL000000026",
    MAKE_NAME: "STIE",
    MODEL_NAME: "STIE",
    label: "STIE",
    value: "STIE"
  },
  {
    SERIAL: "FL010000026",
    MAKE_NAME: "STIE",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "FL020000026",
    MAKE_NAME: "STIE",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "FM000000026",
    MAKE_NAME: "STEYR-PUCH",
    MODEL_NAME: "STEYR-PUCH",
    label: "STEYR-PUCH",
    value: "STEYR-PUCH"
  },
  {
    SERIAL: "FM010000026",
    MAKE_NAME: "STEYR-PUCH",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "FN000000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "MINI",
    label: "MINI",
    value: "MINI"
  },
  {
    SERIAL: "FN010000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "ONE 1.6",
    label: "ONE 1.6",
    value: "ONE 1.6"
  },
  {
    SERIAL: "FN020000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER",
    label: "COOPER",
    value: "COOPER"
  },
  {
    SERIAL: "FN030000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 1.6 M",
    label: "COOPER S 1.6 M",
    value: "COOPER S 1.6 M"
  },
  {
    SERIAL: "FN040000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 1.6 LOOK1",
    label: "COOPER 1.6 LOOK1",
    value: "COOPER 1.6 LOOK1"
  },
  {
    SERIAL: "FN050000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 1.6 LOOK2",
    label: "COOPER 1.6 LOOK2",
    value: "COOPER 1.6 LOOK2"
  },
  {
    SERIAL: "FN060000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 1.6 LOOK1",
    label: "COOPER S 1.6 LOOK1",
    value: "COOPER S 1.6 LOOK1"
  },
  {
    SERIAL: "FN070000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 1.6 LOOK2",
    label: "COOPER S 1.6 LOOK2",
    value: "COOPER S 1.6 LOOK2"
  },
  {
    SERIAL: "FN080000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CONVERTIBLE",
    label: "COOPER CONVERTIBLE",
    value: "COOPER CONVERTIBLE"
  },
  {
    SERIAL: "FN090000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S CONVERTIBLE",
    label: "COOPER S CONVERTIBLE",
    value: "COOPER S CONVERTIBLE"
  },
  {
    SERIAL: "FN100000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBMAN 1.6 A",
    label: "COOPER CLUBMAN 1.6 A",
    value: "COOPER CLUBMAN 1.6 A"
  },
  {
    SERIAL: "FN110000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S CLUBMAN",
    label: "COOPER S CLUBMAN",
    value: "COOPER S CLUBMAN"
  },
  {
    SERIAL: "FN120000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CONVERTIBLE",
    label: "COOPER CONVERTIBLE",
    value: "COOPER CONVERTIBLE"
  },
  {
    SERIAL: "FN130000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S CABRIOLET",
    label: "COOPER S CABRIOLET",
    value: "COOPER S CABRIOLET"
  },
  {
    SERIAL: "FN140000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "JOHN COOPER WORKS",
    label: "JOHN COOPER WORKS",
    value: "JOHN COOPER WORKS"
  },
  {
    SERIAL: "FN150000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "50 MAYFAIR",
    label: "50 MAYFAIR",
    value: "50 MAYFAIR"
  },
  {
    SERIAL: "FN160000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "50 CAMDEN",
    label: "50 CAMDEN",
    value: "50 CAMDEN"
  },
  {
    SERIAL: "FN170000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRY MAN 5D 1.6",
    label: "COOPER COUNTRY MAN 5D 1.6",
    value: "COOPER COUNTRY MAN 5D 1.6"
  },
  {
    SERIAL: "FN180000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S COUNTRY MAN",
    label: "COOPER S COUNTRY MAN",
    value: "COOPER S COUNTRY MAN"
  },
  {
    SERIAL: "FN190000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER D COUNTRY MAN 2.0",
    label: "COOPER D COUNTRY MAN 2.0",
    value: "COOPER D COUNTRY MAN 2.0"
  },
  {
    SERIAL: "FN200000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S CANDLE",
    label: "COOPER S CANDLE",
    value: "COOPER S CANDLE"
  },
  {
    SERIAL: "FN210000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRY MAN 4WD 1.6",
    label: "COOPER COUNTRY MAN 4WD 1.6",
    value: "COOPER COUNTRY MAN 4WD 1.6"
  },
  {
    SERIAL: "FN220000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "ONE 1.6",
    label: "ONE 1.6",
    value: "ONE 1.6"
  },
  {
    SERIAL: "FN230000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUPE 1.6 (Cover 3)",
    label: "COOPER COUPE 1.6 (Cover 3)",
    value: "COOPER COUPE 1.6 (Cover 3)"
  },
  {
    SERIAL: "FN240000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S COUPE",
    label: "COOPER S COUPE",
    value: "COOPER S COUPE"
  },
  {
    SERIAL: "FN250000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER SD 2.0 COUPE",
    label: "COOPER SD 2.0 COUPE",
    value: "COOPER SD 2.0 COUPE"
  },
  {
    SERIAL: "FN260000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER GOODWOOD",
    label: "COOPER GOODWOOD",
    value: "COOPER GOODWOOD"
  },
  {
    SERIAL: "FN270000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER ROADSTER",
    label: "COOPER ROADSTER",
    value: "COOPER ROADSTER"
  },
  {
    SERIAL: "FN280000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER - BUILT",
    label: "COOPER - BUILT",
    value: "COOPER - BUILT"
  },
  {
    SERIAL: "FN290000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 3D 2.0 SD 4WD",
    label: "COOPER COUNTRYMAN 3D 2.0 SD 4WD",
    value: "COOPER COUNTRYMAN 3D 2.0 SD 4WD"
  },
  {
    SERIAL: "FN300000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 1.6 TT",
    label: "COOPER S 1.6 TT",
    value: "COOPER S 1.6 TT"
  },
  {
    SERIAL: "FN310000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D D 2.0",
    label: "COOPER 3D D 2.0",
    value: "COOPER 3D D 2.0"
  },
  {
    SERIAL: "FN320000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D SD 2.0 CBU",
    label: "COOPER 3D SD 2.0 CBU",
    value: "COOPER 3D SD 2.0 CBU"
  },
  {
    SERIAL: "FN330000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5D S 2.0 SPECIAL EDITION",
    label: "COOPER 5D S 2.0 SPECIAL EDITION",
    value: "COOPER 5D S 2.0 SPECIAL EDITION"
  },
  {
    SERIAL: "FN340000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S CLUBMAN HAMPTON 1.6",
    label: "COOPER S CLUBMAN HAMPTON 1.6",
    value: "COOPER S CLUBMAN HAMPTON 1.6"
  },
  {
    SERIAL: "FN350000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D 1.6 L1",
    label: "COOPER COUNTRYMAN 5D 1.6 L1",
    value: "COOPER COUNTRYMAN 5D 1.6 L1"
  },
  {
    SERIAL: "FN360000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D 1.6 L2",
    label: "COOPER COUNTRYMAN 5D 1.6 L2",
    value: "COOPER COUNTRYMAN 5D 1.6 L2"
  },
  {
    SERIAL: "FN370000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 3D 2.0 D L1",
    label: "COOPER COUNTRYMAN 3D 2.0 D L1",
    value: "COOPER COUNTRYMAN 3D 2.0 D L1"
  },
  {
    SERIAL: "FN380000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 3D 2.0 D L2",
    label: "COOPER COUNTRYMAN 3D 2.0 D L2",
    value: "COOPER COUNTRYMAN 3D 2.0 D L2"
  },
  {
    SERIAL: "FN390000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 3D 2.0 SD 4WD",
    label: "COOPER COUNTRYMAN 3D 2.0 SD 4WD",
    value: "COOPER COUNTRYMAN 3D 2.0 SD 4WD"
  },
  {
    SERIAL: "FN400000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUPE 1.6",
    label: "COOPER COUPE 1.6",
    value: "COOPER COUPE 1.6"
  },
  {
    SERIAL: "FN410000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D PACEMAN 1.6I",
    label: "COOPER 3D PACEMAN 1.6I",
    value: "COOPER 3D PACEMAN 1.6I"
  },
  {
    SERIAL: "FN420000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D PACEMAN S 4WD 1.6I",
    label: "COOPER 3D PACEMAN S 4WD 1.6I",
    value: "COOPER 3D PACEMAN S 4WD 1.6I"
  },
  {
    SERIAL: "FN430000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D PACEMAN SD 4WD 2.0",
    label: "COOPER 3D PACEMAN SD 4WD 2.0",
    value: "COOPER 3D PACEMAN SD 4WD 2.0"
  },
  {
    SERIAL: "FN440000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "CLUBVAN 1.6 D",
    label: "CLUBVAN 1.6 D",
    value: "CLUBVAN 1.6 D"
  },
  {
    SERIAL: "FN450000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S HATCH 3D 1.6",
    label: "COOPER S HATCH 3D 1.6",
    value: "COOPER S HATCH 3D 1.6"
  },
  {
    SERIAL: "FN460000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "ONE 1.4",
    label: "ONE 1.4",
    value: "ONE 1.4"
  },
  {
    SERIAL: "FN470000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5D 1.6",
    label: "COOPER 5D 1.6",
    value: "COOPER 5D 1.6"
  },
  {
    SERIAL: "FN520000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S JOHN COOPER WORKS 1.6",
    label: "COOPER S JOHN COOPER WORKS 1.6",
    value: "COOPER S JOHN COOPER WORKS 1.6"
  },
  {
    SERIAL: "FN550000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBVAN 2.0 DIESEL",
    label: "COOPER CLUBVAN 2.0 DIESEL",
    value: "COOPER CLUBVAN 2.0 DIESEL"
  },
  {
    SERIAL: "FN560000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBVAN 5D 2.0D",
    label: "COOPER CLUBVAN 5D 2.0D",
    value: "COOPER CLUBVAN 5D 2.0D"
  },
  {
    SERIAL: "FN570000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D 1.5I",
    label: "COOPER 3D 1.5I",
    value: "COOPER 3D 1.5I"
  },
  {
    SERIAL: "FN580000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D D 1.5",
    label: "COOPER 3D D 1.5",
    value: "COOPER 3D D 1.5"
  },
  {
    SERIAL: "FN590000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D S 2.0",
    label: "COOPER 3D S 2.0",
    value: "COOPER 3D S 2.0"
  },
  {
    SERIAL: "FN600000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5D 1.5TTi",
    label: "COOPER 5D 1.5TTi",
    value: "COOPER 5D 1.5TTi"
  },
  {
    SERIAL: "FN610000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5D 1.5DTTi",
    label: "COOPER 5D 1.5DTTi",
    value: "COOPER 5D 1.5DTTi"
  },
  {
    SERIAL: "FN620000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5D S 2.0 TTi",
    label: "COOPER 5D S 2.0 TTi",
    value: "COOPER 5D S 2.0 TTi"
  },
  {
    SERIAL: "FN630000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5D SD 2.0 TTi",
    label: "COOPER 5D SD 2.0 TTi",
    value: "COOPER 5D SD 2.0 TTi"
  },
  {
    SERIAL: "FN640000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D 1.6 A",
    label: "COOPER COUNTRYMAN 5D 1.6 A",
    value: "COOPER COUNTRYMAN 5D 1.6 A"
  },
  {
    SERIAL: "FN650000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D 1.6 A HIGHTRIM",
    label: "COOPER COUNTRYMAN 5D 1.6 A HIGHTRIM",
    value: "COOPER COUNTRYMAN 5D 1.6 A HIGHTRIM"
  },
  {
    SERIAL: "FN660000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D D 2.0 A HIGHTRIM",
    label: "COOPER COUNTRYMAN 5D D 2.0 A HIGHTRIM",
    value: "COOPER COUNTRYMAN 5D D 2.0 A HIGHTRIM"
  },
  {
    SERIAL: "FN670000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D SD 2.0D 4WD",
    label: "COOPER COUNTRYMAN 5D SD 2.0D 4WD",
    value: "COOPER COUNTRYMAN 5D SD 2.0D 4WD"
  },
  {
    SERIAL: "FN680000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CHALLENGE 210",
    label: "COOPER CHALLENGE 210",
    value: "COOPER CHALLENGE 210"
  },
  {
    SERIAL: "FN690000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 2D CONVERTIBLE 2.0",
    label: "COOPER S 2D CONVERTIBLE 2.0",
    value: "COOPER S 2D CONVERTIBLE 2.0"
  },
  {
    SERIAL: "FN700000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "ONE 1.2",
    label: "ONE 1.2",
    value: "ONE 1.2"
  },
  {
    SERIAL: "FN710000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBMAN 5D 1.5 TTi",
    label: "COOPER CLUBMAN 5D 1.5 TTi",
    value: "COOPER CLUBMAN 5D 1.5 TTi"
  },
  {
    SERIAL: "FN720000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBVAN S 5D 2.0Ti",
    label: "COOPER CLUBVAN S 5D 2.0Ti",
    value: "COOPER CLUBVAN S 5D 2.0Ti"
  },
  {
    SERIAL: "FN730000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S CLUBMAN 5D 2.0Ti HIGHTRIM",
    label: "COOPER S CLUBMAN 5D 2.0Ti HIGHTRIM",
    value: "COOPER S CLUBMAN 5D 2.0Ti HIGHTRIM"
  },
  {
    SERIAL: "FN740000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "JOHN COOPER WORKS 3D 2.0",
    label: "JOHN COOPER WORKS 3D 2.0",
    value: "JOHN COOPER WORKS 3D 2.0"
  },
  {
    SERIAL: "FN750000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "ONE 1.5",
    label: "ONE 1.5",
    value: "ONE 1.5"
  },
  {
    SERIAL: "FN760000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 3D JCW DRESS UP EDITION 2.0",
    label: "COOPER S 3D JCW DRESS UP EDITION 2.0",
    value: "COOPER S 3D JCW DRESS UP EDITION 2.0"
  },
  {
    SERIAL: "FN770000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 5D JCW DRESS UP EDITION 2.0",
    label: "COOPER S 5D JCW DRESS UP EDITION 2.0",
    value: "COOPER S 5D JCW DRESS UP EDITION 2.0"
  },
  {
    SERIAL: "FN780000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D 4WD PARKLANE 2.0",
    label: "COOPER COUNTRYMAN 5D 4WD PARKLANE 2.0",
    value: "COOPER COUNTRYMAN 5D 4WD PARKLANE 2.0"
  },
  {
    SERIAL: "FN790000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER SEVEN EDITION 5D 2.0",
    label: "COOPER SEVEN EDITION 5D 2.0",
    value: "COOPER SEVEN EDITION 5D 2.0"
  },
  {
    SERIAL: "FN800000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER SEVEN EDITION 3D 2.0",
    label: "COOPER SEVEN EDITION 3D 2.0",
    value: "COOPER SEVEN EDITION 3D 2.0"
  },
  {
    SERIAL: "FN810000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBMAN 5D 2.0D",
    label: "COOPER CLUBMAN 5D 2.0D",
    value: "COOPER CLUBMAN 5D 2.0D"
  },
  {
    SERIAL: "FN820000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBMAN 5D S 2.0TI",
    label: "COOPER CLUBMAN 5D S 2.0TI",
    value: "COOPER CLUBMAN 5D S 2.0TI"
  },
  {
    SERIAL: "FN830000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN 5D 1.5",
    label: "COOPER COUNTRYMAN 5D 1.5",
    value: "COOPER COUNTRYMAN 5D 1.5"
  },
  {
    SERIAL: "FN840000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN S 5D 2.0",
    label: "COOPER COUNTRYMAN S 5D 2.0",
    value: "COOPER COUNTRYMAN S 5D 2.0"
  },
  {
    SERIAL: "FN850000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN S HIGHTRIM 5D 2.0",
    label: "COOPER COUNTRYMAN S HIGHTRIM 5D 2.0",
    value: "COOPER COUNTRYMAN S HIGHTRIM 5D 2.0"
  },
  {
    SERIAL: "FN860000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "JOHN COOPER WORKS CLUBMAN 5D 2.0",
    label: "JOHN COOPER WORKS CLUBMAN 5D 2.0",
    value: "JOHN COOPER WORKS CLUBMAN 5D 2.0"
  },
  {
    SERIAL: "FN870000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "JOHN COOPER WORKS COUNTRYMAN 5D 2.0",
    label: "JOHN COOPER WORKS COUNTRYMAN 5D 2.0",
    value: "JOHN COOPER WORKS COUNTRYMAN 5D 2.0"
  },
  {
    SERIAL: "FN880000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 3D S HIGHTRIM 2.0",
    label: "COOPER 3D S HIGHTRIM 2.0",
    value: "COOPER 3D S HIGHTRIM 2.0"
  },
  {
    SERIAL: "FN890000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "JOHN COOPER WORKS CONVERTIBLE 2.0",
    label: "JOHN COOPER WORKS CONVERTIBLE 2.0",
    value: "JOHN COOPER WORKS CONVERTIBLE 2.0"
  },
  {
    SERIAL: "FN900000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBMAN S HIGHTRIM PANORAMIC GLA SS ROOF 2.0",
    label: "COOPER CLUBMAN S HIGHTRIM PANORAMIC GLA SS ROOF 2.0",
    value: "COOPER CLUBMAN S HIGHTRIM PANORAMIC GLA SS ROOF 2.0"
  },
  {
    SERIAL: "FN910000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 5D 60TH EDITION 2.0",
    label: "COOPER S 5D 60TH EDITION 2.0",
    value: "COOPER S 5D 60TH EDITION 2.0"
  },
  {
    SERIAL: "FN920000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S 3D 60TH EDITION 2.0",
    label: "COOPER S 3D 60TH EDITION 2.0",
    value: "COOPER S 3D 60TH EDITION 2.0"
  },
  {
    SERIAL: "FN940000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "WORKS GP3",
    label: "WORKS GP3",
    value: "WORKS GP3"
  },
  {
    SERIAL: "FN950000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER S CABRIOLET 2.0",
    label: "COOPER S CABRIOLET 2.0",
    value: "COOPER S CABRIOLET 2.0"
  },
  {
    SERIAL: "FN960000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5D S 2.0I HIGHTRIM",
    label: "COOPER 5D S 2.0I HIGHTRIM",
    value: "COOPER 5D S 2.0I HIGHTRIM"
  },
  {
    SERIAL: "FN970000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER CLUBMAN 5D S 2.0TI HIGTRIM",
    label: "COOPER CLUBMAN 5D S 2.0TI HIGTRIM",
    value: "COOPER CLUBMAN 5D S 2.0TI HIGTRIM"
  },
  {
    SERIAL: "FN980000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER COUNTRYMAN S 5D 2.0 ENTRY",
    label: "COOPER COUNTRYMAN S 5D 2.0 ENTRY",
    value: "COOPER COUNTRYMAN S 5D 2.0 ENTRY"
  },
  {
    SERIAL: "FN990000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "JOHN COOPER WORKS GP 2.0",
    label: "JOHN COOPER WORKS GP 2.0",
    value: "JOHN COOPER WORKS GP 2.0"
  },
  {
    SERIAL: "FNAA0000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER 5-TURER RHD 1.6",
    label: "COOPER 5-TURER RHD 1.6",
    value: "COOPER 5-TURER RHD 1.6"
  },
  {
    SERIAL: "FNAB0000026",
    MAKE_NAME: "MINI",
    MODEL_NAME: "COOPER SE 3D HATCH (EV)",
    label: "COOPER SE 3D HATCH (EV)",
    value: "COOPER SE 3D HATCH (EV)"
  },
  {
    SERIAL: "FO000000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "FAW",
    label: "FAW",
    value: "FAW"
  },
  {
    SERIAL: "FO010000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "TRAILER NGV",
    label: "TRAILER NGV",
    value: "TRAILER NGV"
  },
  {
    SERIAL: "FO020000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "TRAILER",
    label: "TRAILER",
    value: "TRAILER"
  },
  {
    SERIAL: "FO030000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "WP10",
    label: "WP10",
    value: "WP10"
  },
  {
    SERIAL: "FO040000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "CONCRETE MIXER NGV",
    label: "CONCRETE MIXER NGV",
    value: "CONCRETE MIXER NGV"
  },
  {
    SERIAL: "FO050000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "WP12 (NGV)",
    label: "WP12 (NGV)",
    value: "WP12 (NGV)"
  },
  {
    SERIAL: "FO060000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "6 WHEEL",
    label: "6 WHEEL",
    value: "6 WHEEL"
  },
  {
    SERIAL: "FO070000026",
    MAKE_NAME: "FAW",
    MODEL_NAME: "6 WHEEL CNG",
    label: "6 WHEEL CNG",
    value: "6 WHEEL CNG"
  },
  {
    SERIAL: "FP000000026",
    MAKE_NAME: "BOBKAD",
    MODEL_NAME: "BOBKAD",
    label: "BOBKAD",
    value: "BOBKAD"
  },
  {
    SERIAL: "FQ000000026",
    MAKE_NAME: "VIVACE",
    MODEL_NAME: "VIVACE",
    label: "VIVACE",
    value: "VIVACE"
  },
  {
    SERIAL: "FR000000026",
    MAKE_NAME: "NEW HOLLAND",
    MODEL_NAME: "NEW HOLLAND",
    label: "NEW HOLLAND",
    value: "NEW HOLLAND"
  },
  {
    SERIAL: "FR020000026",
    MAKE_NAME: "NEW HOLLAND",
    MODEL_NAME: "TRACTOR",
    label: "TRACTOR",
    value: "TRACTOR"
  },
  {
    SERIAL: "FS000000026",
    MAKE_NAME: "T-UNITED",
    MODEL_NAME: "T-UNITED",
    label: "T-UNITED",
    value: "T-UNITED"
  },
  {
    SERIAL: "FU000000026",
    MAKE_NAME: "TWINNER",
    MODEL_NAME: "TWINNER",
    label: "TWINNER",
    value: "TWINNER"
  },
  {
    SERIAL: "FV000000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SAMMITR",
    label: "SAMMITR",
    value: "SAMMITR"
  },
  {
    SERIAL: "FV010000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SA2031 (4*4)",
    label: "SA2031 (4*4)",
    value: "SA2031 (4*4)"
  },
  {
    SERIAL: "FV020000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SA2033-I (4*2)",
    label: "SA2033-I (4*2)",
    value: "SA2033-I (4*2)"
  },
  {
    SERIAL: "FV030000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SA2033-I CNG (4*2)",
    label: "SA2033-I CNG (4*2)",
    value: "SA2033-I CNG (4*2)"
  },
  {
    SERIAL: "FV040000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SA1062-CNG",
    label: "SA1062-CNG",
    value: "SA1062-CNG"
  },
  {
    SERIAL: "FV050000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SA1052-I",
    label: "SA1052-I",
    value: "SA1052-I"
  },
  {
    SERIAL: "FV060000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SA1062-I",
    label: "SA1062-I",
    value: "SA1062-I"
  },
  {
    SERIAL: "FV070000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SINGLE CAB CNG",
    label: "SINGLE CAB CNG",
    value: "SINGLE CAB CNG"
  },
  {
    SERIAL: "FV080000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "SMART CAB CNG",
    label: "SMART CAB CNG",
    value: "SMART CAB CNG"
  },
  {
    SERIAL: "FV090000026",
    MAKE_NAME: "SAMMITR",
    MODEL_NAME: "DOUBLE CAB CNG",
    label: "DOUBLE CAB CNG",
    value: "DOUBLE CAB CNG"
  },
  {
    SERIAL: "FW000000026",
    MAKE_NAME: "RYUKA",
    MODEL_NAME: "RYUKA",
    label: "RYUKA",
    value: "RYUKA"
  },
  {
    SERIAL: "FW010000026",
    MAKE_NAME: "RYUKA",
    MODEL_NAME: "OVER 150 CC.",
    label: "OVER 150 CC.",
    value: "OVER 150 CC."
  },
  {
    SERIAL: "FW020000026",
    MAKE_NAME: "RYUKA",
    MODEL_NAME: "CLASSIC",
    label: "CLASSIC",
    value: "CLASSIC"
  },
  {
    SERIAL: "FW030000026",
    MAKE_NAME: "RYUKA",
    MODEL_NAME: "CRUISER",
    label: "CRUISER",
    value: "CRUISER"
  },
  {
    SERIAL: "FW040000026",
    MAKE_NAME: "RYUKA",
    MODEL_NAME: "RX1",
    label: "RX1",
    value: "RX1"
  },
  {
    SERIAL: "FX000000026",
    MAKE_NAME: "M BIKE",
    MODEL_NAME: "M BIKE",
    label: "M BIKE",
    value: "M BIKE"
  },
  {
    SERIAL: "FX010000026",
    MAKE_NAME: "M BIKE",
    MODEL_NAME: "110",
    label: "110",
    value: "110"
  },
  {
    SERIAL: "FX020000026",
    MAKE_NAME: "M BIKE",
    MODEL_NAME: "125",
    label: "125",
    value: "125"
  },
  {
    SERIAL: "FY000000026",
    MAKE_NAME: "HERCUUS",
    MODEL_NAME: "HERCUUS",
    label: "HERCUUS",
    value: "HERCUUS"
  },
  {
    SERIAL: "FZ000000026",
    MAKE_NAME: "CHERDCHAI",
    MODEL_NAME: "CHERDCHAI",
    label: "CHERDCHAI",
    value: "CHERDCHAI"
  },
  {
    SERIAL: "FZ010000026",
    MAKE_NAME: "CHERDCHAI",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "GA000000026",
    MAKE_NAME: "SON",
    MODEL_NAME: "SON",
    label: "SON",
    value: "SON"
  },
  {
    SERIAL: "GB000000026",
    MAKE_NAME: "MONIKA",
    MODEL_NAME: "MONIKA",
    label: "MONIKA",
    value: "MONIKA"
  },
  {
    SERIAL: "GC000000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "MITSUOKA",
    label: "MITSUOKA",
    value: "MITSUOKA"
  },
  {
    SERIAL: "GC010000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "GALUE 250 EX MAC",
    label: "GALUE 250 EX MAC",
    value: "GALUE 250 EX MAC"
  },
  {
    SERIAL: "GC020000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "GALUE LIMOUSINE MAC 3.5",
    label: "GALUE LIMOUSINE MAC 3.5",
    value: "GALUE LIMOUSINE MAC 3.5"
  },
  {
    SERIAL: "GC030000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "GALUE LIMOUSINE MAC 2.5",
    label: "GALUE LIMOUSINE MAC 2.5",
    value: "GALUE LIMOUSINE MAC 2.5"
  },
  {
    SERIAL: "GC040000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "OROCHI COUPE 3.3",
    label: "OROCHI COUPE 3.3",
    value: "OROCHI COUPE 3.3"
  },
  {
    SERIAL: "GC050000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "GALUE 350 MAC",
    label: "GALUE 350 MAC",
    value: "GALUE 350 MAC"
  },
  {
    SERIAL: "GC060000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "HIMIKO CONVERTIBLE 2.0",
    label: "HIMIKO CONVERTIBLE 2.0",
    value: "HIMIKO CONVERTIBLE 2.0"
  },
  {
    SERIAL: "GC070000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "OROCHI 3.3",
    label: "OROCHI 3.3",
    value: "OROCHI 3.3"
  },
  {
    SERIAL: "GC080000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "GALUE G-XL200 2.0",
    label: "GALUE G-XL200 2.0",
    value: "GALUE G-XL200 2.0"
  },
  {
    SERIAL: "GC090000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "GALUE G-XV250 2.5",
    label: "GALUE G-XV250 2.5",
    value: "GALUE G-XV250 2.5"
  },
  {
    SERIAL: "GC100000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "VIEWT V-EL 1.2",
    label: "VIEWT V-EL 1.2",
    value: "VIEWT V-EL 1.2"
  },
  {
    SERIAL: "GC110000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "VIEWT V-VL 1.2",
    label: "VIEWT V-VL 1.2",
    value: "VIEWT V-VL 1.2"
  },
  {
    SERIAL: "GC120000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "VIEWT CLASSIC 1.2",
    label: "VIEWT CLASSIC 1.2",
    value: "VIEWT CLASSIC 1.2"
  },
  {
    SERIAL: "GD000000026",
    MAKE_NAME: "BEAT ATV P110",
    MODEL_NAME: "BEAT ATV P110",
    label: "BEAT ATV P110",
    value: "BEAT ATV P110"
  },
  {
    SERIAL: "GE000000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "DFM",
    label: "DFM",
    value: "DFM"
  },
  {
    SERIAL: "GE010000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "TRUCK 1.1L (LPG)",
    label: "TRUCK 1.1L (LPG)",
    value: "TRUCK 1.1L (LPG)"
  },
  {
    SERIAL: "GE020000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "VAN 1.1 L (LPG)",
    label: "VAN 1.1 L (LPG)",
    value: "VAN 1.1 L (LPG)"
  },
  {
    SERIAL: "GE030000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "MINI MPV 1.1 (NGV,LPG)",
    label: "MINI MPV 1.1 (NGV,LPG)",
    value: "MINI MPV 1.1 (NGV,LPG)"
  },
  {
    SERIAL: "GE040000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "MINI STAR 1.3 (NGV,LPG)",
    label: "MINI STAR 1.3 (NGV,LPG)",
    value: "MINI STAR 1.3 (NGV,LPG)"
  },
  {
    SERIAL: "GE060000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "TRUCK 1.1L (NGV)",
    label: "TRUCK 1.1L (NGV)",
    value: "TRUCK 1.1L (NGV)"
  },
  {
    SERIAL: "GE070000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "TRUCK 1.3 L (NGV)",
    label: "TRUCK 1.3 L (NGV)",
    value: "TRUCK 1.3 L (NGV)"
  },
  {
    SERIAL: "GE090000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "VAN 1.3 L (LPG)",
    label: "VAN 1.3 L (LPG)",
    value: "VAN 1.3 L (LPG)"
  },
  {
    SERIAL: "GE110000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "CAFE 1.1 L (LPG)",
    label: "CAFE 1.1 L (LPG)",
    value: "CAFE 1.1 L (LPG)"
  },
  {
    SERIAL: "GE120000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "CAFE 1.1 L (NGV)",
    label: "CAFE 1.1 L (NGV)",
    value: "CAFE 1.1 L (NGV)"
  },
  {
    SERIAL: "GE130000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "CAFE 1.3 L (LPG)",
    label: "CAFE 1.3 L (LPG)",
    value: "CAFE 1.3 L (LPG)"
  },
  {
    SERIAL: "GE140000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "CAFE 1.3 L (NGV)",
    label: "CAFE 1.3 L (NGV)",
    value: "CAFE 1.3 L (NGV)"
  },
  {
    SERIAL: "GE150000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "OTOP 1.1 L (LPG)",
    label: "OTOP 1.1 L (LPG)",
    value: "OTOP 1.1 L (LPG)"
  },
  {
    SERIAL: "GE160000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "OTOP 1.1 L (NGV)",
    label: "OTOP 1.1 L (NGV)",
    value: "OTOP 1.1 L (NGV)"
  },
  {
    SERIAL: "GE170000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "OTOP 1.3 L (LPG)",
    label: "OTOP 1.3 L (LPG)",
    value: "OTOP 1.3 L (LPG)"
  },
  {
    SERIAL: "GE180000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "OTOP 1.3 L (NGV)",
    label: "OTOP 1.3 L (NGV)",
    value: "OTOP 1.3 L (NGV)"
  },
  {
    SERIAL: "GE190000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "TRUCK 1.3 L (LPG)",
    label: "TRUCK 1.3 L (LPG)",
    value: "TRUCK 1.3 L (LPG)"
  },
  {
    SERIAL: "GE200000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "V27 1.3 L LPG",
    label: "V27 1.3 L LPG",
    value: "V27 1.3 L LPG"
  },
  {
    SERIAL: "GE210000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "V27 1.3 L DVD LPG",
    label: "V27 1.3 L DVD LPG",
    value: "V27 1.3 L DVD LPG"
  },
  {
    SERIAL: "GE220000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "V27 1.3 L EQUIPPED",
    label: "V27 1.3 L EQUIPPED",
    value: "V27 1.3 L EQUIPPED"
  },
  {
    SERIAL: "GE230000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "TRUCK CHAMPION 1.3 V21",
    label: "TRUCK CHAMPION 1.3 V21",
    value: "TRUCK CHAMPION 1.3 V21"
  },
  {
    SERIAL: "GE240000026",
    MAKE_NAME: "DFM",
    MODEL_NAME: "GLORY 1.5",
    label: "GLORY 1.5",
    value: "GLORY 1.5"
  },
  {
    SERIAL: "GF000000026",
    MAKE_NAME: "HIGER",
    MODEL_NAME: "HIGER",
    label: "HIGER",
    value: "HIGER"
  },
  {
    SERIAL: "GF010000026",
    MAKE_NAME: "HIGER",
    MODEL_NAME: "HIGER 18 SEAT",
    label: "HIGER 18 SEAT",
    value: "HIGER 18 SEAT"
  },
  {
    SERIAL: "GF020000026",
    MAKE_NAME: "HIGER",
    MODEL_NAME: "KLQ6840C 30 SEAT",
    label: "KLQ6840C 30 SEAT",
    value: "KLQ6840C 30 SEAT"
  },
  {
    SERIAL: "GF030000026",
    MAKE_NAME: "HIGER",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "GG000000026",
    MAKE_NAME: "EUROTRAC",
    MODEL_NAME: "EUROTRAC",
    label: "EUROTRAC",
    value: "EUROTRAC"
  },
  {
    SERIAL: "GH000000026",
    MAKE_NAME: "MAXWELL",
    MODEL_NAME: "MAXWELL",
    label: "MAXWELL",
    value: "MAXWELL"
  },
  {
    SERIAL: "GI000000026",
    MAKE_NAME: "LAND INI",
    MODEL_NAME: "LAND INI",
    label: "LAND INI",
    value: "LAND INI"
  },
  {
    SERIAL: "GJ000000026",
    MAKE_NAME: "ARIEL",
    MODEL_NAME: "ARIEL",
    label: "ARIEL",
    value: "ARIEL"
  },
  {
    SERIAL: "GJ010000026",
    MAKE_NAME: "ARIEL",
    MODEL_NAME: "ATOM",
    label: "ATOM",
    value: "ATOM"
  },
  {
    SERIAL: "GJ020000026",
    MAKE_NAME: "ARIEL",
    MODEL_NAME: "NOMAD 2.0",
    label: "NOMAD 2.0",
    value: "NOMAD 2.0"
  },
  {
    SERIAL: "GK000000026",
    MAKE_NAME: "SPYKER",
    MODEL_NAME: "SPYKER",
    label: "SPYKER",
    value: "SPYKER"
  },
  {
    SERIAL: "GK010000026",
    MAKE_NAME: "SPYKER",
    MODEL_NAME: "C8 SPYDER 4.2 CONVERTIBLE",
    label: "C8 SPYDER 4.2 CONVERTIBLE",
    value: "C8 SPYDER 4.2 CONVERTIBLE"
  },
  {
    SERIAL: "GK020000026",
    MAKE_NAME: "SPYKER",
    MODEL_NAME: "C8 LAVIOLETTE 4.2 COUPE",
    label: "C8 LAVIOLETTE 4.2 COUPE",
    value: "C8 LAVIOLETTE 4.2 COUPE"
  },
  {
    SERIAL: "GK030000026",
    MAKE_NAME: "SPYKER",
    MODEL_NAME: "C8 DOUBLE 12 S 4.2 CONVERTIBLE",
    label: "C8 DOUBLE 12 S 4.2 CONVERTIBLE",
    value: "C8 DOUBLE 12 S 4.2 CONVERTIBLE"
  },
  {
    SERIAL: "GL000000026",
    MAKE_NAME: "NAZA",
    MODEL_NAME: "NAZA",
    label: "NAZA",
    value: "NAZA"
  },
  {
    SERIAL: "GL010000026",
    MAKE_NAME: "NAZA",
    MODEL_NAME: "FORZA 1.1 M",
    label: "FORZA 1.1 M",
    value: "FORZA 1.1 M"
  },
  {
    SERIAL: "GL020000026",
    MAKE_NAME: "NAZA",
    MODEL_NAME: "FORZA 1.1 SPORT M",
    label: "FORZA 1.1 SPORT M",
    value: "FORZA 1.1 SPORT M"
  },
  {
    SERIAL: "GM000000026",
    MAKE_NAME: "โร่เลช",
    MODEL_NAME: "โร่เลช",
    label: "โร่เลช",
    value: "โร่เลช"
  },
  {
    SERIAL: "GN000000026",
    MAKE_NAME: "STALLION",
    MODEL_NAME: "STALLION",
    label: "STALLION",
    value: "STALLION"
  },
  {
    SERIAL: "GN010000026",
    MAKE_NAME: "STALLION",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "GN030000026",
    MAKE_NAME: "STALLION",
    MODEL_NAME: "CENTAUR 400",
    label: "CENTAUR 400",
    value: "CENTAUR 400"
  },
  {
    SERIAL: "GN040000026",
    MAKE_NAME: "STALLION",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "GN050000026",
    MAKE_NAME: "STALLION",
    MODEL_NAME: "CENTAUR 150",
    label: "CENTAUR 150",
    value: "CENTAUR 150"
  },
  {
    SERIAL: "GN060000026",
    MAKE_NAME: "STALLION",
    MODEL_NAME: "CENTAUR SIAM 70S",
    label: "CENTAUR SIAM 70S",
    value: "CENTAUR SIAM 70S"
  },
  {
    SERIAL: "GO000000026",
    MAKE_NAME: "BOBCAT",
    MODEL_NAME: "BOBCAT",
    label: "BOBCAT",
    value: "BOBCAT"
  },
  {
    SERIAL: "GP000000026",
    MAKE_NAME: "POLARSUN",
    MODEL_NAME: "POLARSUN",
    label: "POLARSUN",
    value: "POLARSUN"
  },
  {
    SERIAL: "GP010000026",
    MAKE_NAME: "POLARSUN",
    MODEL_NAME: "CENTURY BUSINESS LH 11SEAT 2.2i",
    label: "CENTURY BUSINESS LH 11SEAT 2.2i",
    value: "CENTURY BUSINESS LH 11SEAT 2.2i"
  },
  {
    SERIAL: "GP020000026",
    MAKE_NAME: "POLARSUN",
    MODEL_NAME: "CENTURY COMMON LH 11SEAT 2.2i",
    label: "CENTURY COMMON LH 11SEAT 2.2i",
    value: "CENTURY COMMON LH 11SEAT 2.2i"
  },
  {
    SERIAL: "GP030000026",
    MAKE_NAME: "POLARSUN",
    MODEL_NAME: "CENTURY COMMON EX 11SEAT 2.2i",
    label: "CENTURY COMMON EX 11SEAT 2.2i",
    value: "CENTURY COMMON EX 11SEAT 2.2i"
  },
  {
    SERIAL: "GP040000026",
    MAKE_NAME: "POLARSUN",
    MODEL_NAME: "CENTURY COMMON SX 11SEAT 2.2i",
    label: "CENTURY COMMON SX 11SEAT 2.2i",
    value: "CENTURY COMMON SX 11SEAT 2.2i"
  },
  {
    SERIAL: "GQ000000026",
    MAKE_NAME: "SONDABUS",
    MODEL_NAME: "SONDABUS",
    label: "SONDABUS",
    value: "SONDABUS"
  },
  {
    SERIAL: "GR000000026",
    MAKE_NAME: "SKYWING",
    MODEL_NAME: "SKYWING",
    label: "SKYWING",
    value: "SKYWING"
  },
  {
    SERIAL: "GS000000026",
    MAKE_NAME: "KENWORTH",
    MODEL_NAME: "KENWORTH",
    label: "KENWORTH",
    value: "KENWORTH"
  },
  {
    SERIAL: "GT000000026",
    MAKE_NAME: "JIALING",
    MODEL_NAME: "JIALING",
    label: "JIALING",
    value: "JIALING"
  },
  {
    SERIAL: "GU000000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "CHERY",
    label: "CHERY",
    value: "CHERY"
  },
  {
    SERIAL: "GU010000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "QQ 1.1 M",
    label: "QQ 1.1 M",
    value: "QQ 1.1 M"
  },
  {
    SERIAL: "GU020000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "QQ 1.1 A",
    label: "QQ 1.1 A",
    value: "QQ 1.1 A"
  },
  {
    SERIAL: "GU030000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "CROSS",
    label: "CROSS",
    value: "CROSS"
  },
  {
    SERIAL: "GU040000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "CROSS LS",
    label: "CROSS LS",
    value: "CROSS LS"
  },
  {
    SERIAL: "GU050000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "TIGGO M",
    label: "TIGGO M",
    value: "TIGGO M"
  },
  {
    SERIAL: "GU060000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "TIGGO A",
    label: "TIGGO A",
    value: "TIGGO A"
  },
  {
    SERIAL: "GU070000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "QQ HIGH LINE AUTO",
    label: "QQ HIGH LINE AUTO",
    value: "QQ HIGH LINE AUTO"
  },
  {
    SERIAL: "GU080000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "QQ HIGH LINE M",
    label: "QQ HIGH LINE M",
    value: "QQ HIGH LINE M"
  },
  {
    SERIAL: "GU090000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "A1",
    label: "A1",
    value: "A1"
  },
  {
    SERIAL: "GU100000026",
    MAKE_NAME: "CHERY",
    MODEL_NAME: "BIG D 2.0 CNG VAN",
    label: "BIG D 2.0 CNG VAN",
    value: "BIG D 2.0 CNG VAN"
  },
  {
    SERIAL: "GV000000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "TRAILER",
    label: "TRAILER",
    value: "TRAILER"
  },
  {
    SERIAL: "GV010000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "CEMCRYO TRAILER TANKER",
    label: "CEMCRYO TRAILER TANKER",
    value: "CEMCRYO TRAILER TANKER"
  },
  {
    SERIAL: "GV020000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "SEMI TRAILER TANKER",
    label: "SEMI TRAILER TANKER",
    value: "SEMI TRAILER TANKER"
  },
  {
    SERIAL: "GV030000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "HYDROGEN TUBE TRAILER",
    label: "HYDROGEN TUBE TRAILER",
    value: "HYDROGEN TUBE TRAILER"
  },
  {
    SERIAL: "GV040000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "LOW BED TRAILER",
    label: "LOW BED TRAILER",
    value: "LOW BED TRAILER"
  },
  {
    SERIAL: "GV050000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "CRYOTECH SEMI-TRAILER",
    label: "CRYOTECH SEMI-TRAILER",
    value: "CRYOTECH SEMI-TRAILER"
  },
  {
    SERIAL: "GV060000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "HYDROGEN TUBE",
    label: "HYDROGEN TUBE",
    value: "HYDROGEN TUBE"
  },
  {
    SERIAL: "GV070000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "PANUS ASSEMBLY SEMI TRAILER",
    label: "PANUS ASSEMBLY SEMI TRAILER",
    value: "PANUS ASSEMBLY SEMI TRAILER"
  },
  {
    SERIAL: "GV080000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "MOBILE PURGING",
    label: "MOBILE PURGING",
    value: "MOBILE PURGING"
  },
  {
    SERIAL: "GV090000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "MINI TRAILER",
    label: "MINI TRAILER",
    value: "MINI TRAILER"
  },
  {
    SERIAL: "GV100000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "FLAT-BED TRAILER",
    label: "FLAT-BED TRAILER",
    value: "FLAT-BED TRAILER"
  },
  {
    SERIAL: "GV110000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "CRYOTECH TL-330",
    label: "CRYOTECH TL-330",
    value: "CRYOTECH TL-330"
  },
  {
    SERIAL: "GV120000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "SEMI TRAILER",
    label: "SEMI TRAILER",
    value: "SEMI TRAILER"
  },
  {
    SERIAL: "GV130000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "SEMI TRAILER TANKER",
    label: "SEMI TRAILER TANKER",
    value: "SEMI TRAILER TANKER"
  },
  {
    SERIAL: "GV140000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "LOW BED 3 AXLE",
    label: "LOW BED 3 AXLE",
    value: "LOW BED 3 AXLE"
  },
  {
    SERIAL: "GV150000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "LOW BED 4 AXLE",
    label: "LOW BED 4 AXLE",
    value: "LOW BED 4 AXLE"
  },
  {
    SERIAL: "GV160000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "LOW BED 5 AXLE",
    label: "LOW BED 5 AXLE",
    value: "LOW BED 5 AXLE"
  },
  {
    SERIAL: "GV170000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "CRYOTECH SEMI-TRAILER",
    label: "CRYOTECH SEMI-TRAILER",
    value: "CRYOTECH SEMI-TRAILER"
  },
  {
    SERIAL: "GV180000026",
    MAKE_NAME: "TRAILER",
    MODEL_NAME: "LOW BED 6 AXLE",
    label: "LOW BED 6 AXLE",
    value: "LOW BED 6 AXLE"
  },
  {
    SERIAL: "GW000000026",
    MAKE_NAME: "AICHI",
    MODEL_NAME: "AICHI",
    label: "AICHI",
    value: "AICHI"
  },
  {
    SERIAL: "GW010000026",
    MAKE_NAME: "AICHI",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "GW020000026",
    MAKE_NAME: "AICHI",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "GW030000026",
    MAKE_NAME: "AICHI",
    MODEL_NAME: "12000",
    label: "12000",
    value: "12000"
  },
  {
    SERIAL: "GX000000026",
    MAKE_NAME: "RADICAL",
    MODEL_NAME: "RADICAL",
    label: "RADICAL",
    value: "RADICAL"
  },
  {
    SERIAL: "GX010000026",
    MAKE_NAME: "RADICAL",
    MODEL_NAME: "SR3",
    label: "SR3",
    value: "SR3"
  },
  {
    SERIAL: "GX020000026",
    MAKE_NAME: "RADICAL",
    MODEL_NAME: "RCX",
    label: "RCX",
    value: "RCX"
  },
  {
    SERIAL: "GY000000026",
    MAKE_NAME: "WILLYS",
    MODEL_NAME: "WILLYS",
    label: "WILLYS",
    value: "WILLYS"
  },
  {
    SERIAL: "GY010000026",
    MAKE_NAME: "WILLYS",
    MODEL_NAME: "JEEP",
    label: "JEEP",
    value: "JEEP"
  },
  {
    SERIAL: "GZ000000026",
    MAKE_NAME: "TEREX",
    MODEL_NAME: "TEREX",
    label: "TEREX",
    value: "TEREX"
  },
  {
    SERIAL: "HA000000026",
    MAKE_NAME: "HKMCO",
    MODEL_NAME: "HKMCO",
    label: "HKMCO",
    value: "HKMCO"
  },
  {
    SERIAL: "HB000000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "SINOTRUK",
    label: "SINOTRUK",
    value: "SINOTRUK"
  },
  {
    SERIAL: "HB010000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "HOWO-หัวลาก (CNG) 10 ล้อ",
    label: "HOWO-หัวลาก (CNG) 10 ล้อ",
    value: "HOWO-หัวลาก (CNG) 10 ล้อ"
  },
  {
    SERIAL: "HB030000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "HOWO (CNG) 12 ล้อ",
    label: "HOWO (CNG) 12 ล้อ",
    value: "HOWO (CNG) 12 ล้อ"
  },
  {
    SERIAL: "HB040000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "HOWO",
    label: "HOWO",
    value: "HOWO"
  },
  {
    SERIAL: "HB050000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "HOWO CONCRETE MIXER",
    label: "HOWO CONCRETE MIXER",
    value: "HOWO CONCRETE MIXER"
  },
  {
    SERIAL: "HB060000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "HOYUN ULTIMATE CNG",
    label: "HOYUN ULTIMATE CNG",
    value: "HOYUN ULTIMATE CNG"
  },
  {
    SERIAL: "HB070000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "HOWO TRUCK DIESEL",
    label: "HOWO TRUCK DIESEL",
    value: "HOWO TRUCK DIESEL"
  },
  {
    SERIAL: "HB080000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "GOLDEN PRINCE DUMP",
    label: "GOLDEN PRINCE DUMP",
    value: "GOLDEN PRINCE DUMP"
  },
  {
    SERIAL: "HB090000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "GOLDEN PRINCE DUMP 12 WHEEL",
    label: "GOLDEN PRINCE DUMP 12 WHEEL",
    value: "GOLDEN PRINCE DUMP 12 WHEEL"
  },
  {
    SERIAL: "HB100000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "MOBILE CONCRETE PUMP",
    label: "MOBILE CONCRETE PUMP",
    value: "MOBILE CONCRETE PUMP"
  },
  {
    SERIAL: "HB110000026",
    MAKE_NAME: "SINOTRUK",
    MODEL_NAME: "HOYUN DIESEL",
    label: "HOYUN DIESEL",
    value: "HOYUN DIESEL"
  },
  {
    SERIAL: "HC000000026",
    MAKE_NAME: "RETRO",
    MODEL_NAME: "RETRO",
    label: "RETRO",
    value: "RETRO"
  },
  {
    SERIAL: "HD000000026",
    MAKE_NAME: "R-REX",
    MODEL_NAME: "R-REX",
    label: "R-REX",
    value: "R-REX"
  },
  {
    SERIAL: "HE000000026",
    MAKE_NAME: "CHAMP",
    MODEL_NAME: "CHAMP",
    label: "CHAMP",
    value: "CHAMP"
  },
  {
    SERIAL: "HE010000026",
    MAKE_NAME: "CHAMP",
    MODEL_NAME: "SKYLAB",
    label: "SKYLAB",
    value: "SKYLAB"
  },
  {
    SERIAL: "HI000000026",
    MAKE_NAME: "MAYBACH",
    MODEL_NAME: "MAYBACH",
    label: "MAYBACH",
    value: "MAYBACH"
  },
  {
    SERIAL: "HI010000026",
    MAKE_NAME: "MAYBACH",
    MODEL_NAME: "62",
    label: "62",
    value: "62"
  },
  {
    SERIAL: "HI020000026",
    MAKE_NAME: "MAYBACH",
    MODEL_NAME: "62",
    label: "62",
    value: "62"
  },
  {
    SERIAL: "HJ000000026",
    MAKE_NAME: "CMI",
    MODEL_NAME: "CMI",
    label: "CMI",
    value: "CMI"
  },
  {
    SERIAL: "HK000000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "MOTOGUZZI",
    label: "MOTOGUZZI",
    value: "MOTOGUZZI"
  },
  {
    SERIAL: "HK010000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "V7 II STORNELLO",
    label: "V7 II STORNELLO",
    value: "V7 II STORNELLO"
  },
  {
    SERIAL: "HK020000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "V7 II STONE",
    label: "V7 II STONE",
    value: "V7 II STONE"
  },
  {
    SERIAL: "HK030000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "V7 II RACER",
    label: "V7 II RACER",
    value: "V7 II RACER"
  },
  {
    SERIAL: "HK040000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "AUDACE",
    label: "AUDACE",
    value: "AUDACE"
  },
  {
    SERIAL: "HK050000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "V9 ROAMER",
    label: "V9 ROAMER",
    value: "V9 ROAMER"
  },
  {
    SERIAL: "HK060000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "V9 BOBBER",
    label: "V9 BOBBER",
    value: "V9 BOBBER"
  },
  {
    SERIAL: "HK070000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "MGX-21",
    label: "MGX-21",
    value: "MGX-21"
  },
  {
    SERIAL: "HK080000026",
    MAKE_NAME: "MOTOGUZZI",
    MODEL_NAME: "V7 III ANNIVERSARIO",
    label: "V7 III ANNIVERSARIO",
    value: "V7 III ANNIVERSARIO"
  },
  {
    SERIAL: "HL000000026",
    MAKE_NAME: "PANUS",
    MODEL_NAME: "PANUS",
    label: "PANUS",
    value: "PANUS"
  },
  {
    SERIAL: "HM000000026",
    MAKE_NAME: "GUSTO",
    MODEL_NAME: "GUSTO",
    label: "GUSTO",
    value: "GUSTO"
  },
  {
    SERIAL: "HN000000026",
    MAKE_NAME: "WESTFIELD",
    MODEL_NAME: "WESTFIELD",
    label: "WESTFIELD",
    value: "WESTFIELD"
  },
  {
    SERIAL: "HN010000026",
    MAKE_NAME: "WESTFIELD",
    MODEL_NAME: "WESTFIELD 1.6",
    label: "WESTFIELD 1.6",
    value: "WESTFIELD 1.6"
  },
  {
    SERIAL: "HO000000026",
    MAKE_NAME: "TESLA",
    MODEL_NAME: "ROADSTER",
    label: "ROADSTER",
    value: "ROADSTER"
  },
  {
    SERIAL: "HO010000026",
    MAKE_NAME: "TESLA",
    MODEL_NAME: "XP100 D",
    label: "XP100 D",
    value: "XP100 D"
  },
  {
    SERIAL: "HO020000026",
    MAKE_NAME: "TESLA",
    MODEL_NAME: "X75D",
    label: "X75D",
    value: "X75D"
  },
  {
    SERIAL: "HO040000026",
    MAKE_NAME: "TESLA",
    MODEL_NAME: "MODEL 3",
    label: "MODEL 3",
    value: "MODEL 3"
  },
  {
    SERIAL: "HO050000026",
    MAKE_NAME: "TESLA",
    MODEL_NAME: "MODEL S",
    label: "MODEL S",
    value: "MODEL S"
  },
  {
    SERIAL: "HO060000026",
    MAKE_NAME: "TESLA",
    MODEL_NAME: "MODEL 3 LONG RANGE AWD",
    label: "MODEL 3 LONG RANGE AWD",
    value: "MODEL 3 LONG RANGE AWD"
  },
  {
    SERIAL: "HQ000000026",
    MAKE_NAME: "SHACMAN",
    MODEL_NAME: "SHACMAN",
    label: "SHACMAN",
    value: "SHACMAN"
  },
  {
    SERIAL: "HQ020000026",
    MAKE_NAME: "SHACMAN",
    MODEL_NAME: "หัวลาก 6X4 (CNG)",
    label: "หัวลาก 6X4 (CNG)",
    value: "หัวลาก 6X4 (CNG)"
  },
  {
    SERIAL: "HQ030000026",
    MAKE_NAME: "SHACMAN",
    MODEL_NAME: "TRUCK 3 เพลา",
    label: "TRUCK 3 เพลา",
    value: "TRUCK 3 เพลา"
  },
  {
    SERIAL: "HQ040000026",
    MAKE_NAME: "SHACMAN",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "HQ050000026",
    MAKE_NAME: "SHACMAN",
    MODEL_NAME: "WP10",
    label: "WP10",
    value: "WP10"
  },
  {
    SERIAL: "HQ060000026",
    MAKE_NAME: "SHACMAN",
    MODEL_NAME: "WP12",
    label: "WP12",
    value: "WP12"
  },
  {
    SERIAL: "HQ070000026",
    MAKE_NAME: "SHACMAN",
    MODEL_NAME: "WP12N",
    label: "WP12N",
    value: "WP12N"
  },
  {
    SERIAL: "HR000000026",
    MAKE_NAME: "KEEWAY",
    MODEL_NAME: "KEEWAY",
    label: "KEEWAY",
    value: "KEEWAY"
  },
  {
    SERIAL: "HR010000026",
    MAKE_NAME: "KEEWAY",
    MODEL_NAME: "OVER 150 CC.",
    label: "OVER 150 CC.",
    value: "OVER 150 CC."
  },
  {
    SERIAL: "HR020000026",
    MAKE_NAME: "KEEWAY",
    MODEL_NAME: "JL200",
    label: "JL200",
    value: "JL200"
  },
  {
    SERIAL: "HS000000026",
    MAKE_NAME: "VOLGA",
    MODEL_NAME: "VOLGA",
    label: "VOLGA",
    value: "VOLGA"
  },
  {
    SERIAL: "HT000000026",
    MAKE_NAME: "RAMBLER",
    MODEL_NAME: "RAMBLER",
    label: "RAMBLER",
    value: "RAMBLER"
  },
  {
    SERIAL: "HU000000026",
    MAKE_NAME: "DEVA",
    MODEL_NAME: "DEVA",
    label: "DEVA",
    value: "DEVA"
  },
  {
    SERIAL: "HU010000026",
    MAKE_NAME: "DEVA",
    MODEL_NAME: "HERCULES X",
    label: "HERCULES X",
    value: "HERCULES X"
  },
  {
    SERIAL: "HV000000026",
    MAKE_NAME: "T-REX",
    MODEL_NAME: "T-REX",
    label: "T-REX",
    value: "T-REX"
  },
  {
    SERIAL: "HV010000026",
    MAKE_NAME: "T-REX",
    MODEL_NAME: "T-REX",
    label: "T-REX",
    value: "T-REX"
  },
  {
    SERIAL: "HW000000026",
    MAKE_NAME: "ZERO",
    MODEL_NAME: "ZERO",
    label: "ZERO",
    value: "ZERO"
  },
  {
    SERIAL: "HW010000026",
    MAKE_NAME: "ZERO",
    MODEL_NAME: "TYPE 5",
    label: "TYPE 5",
    value: "TYPE 5"
  },
  {
    SERIAL: "HW020000026",
    MAKE_NAME: "ZERO",
    MODEL_NAME: "TYPE 9",
    label: "TYPE 9",
    value: "TYPE 9"
  },
  {
    SERIAL: "HW030000026",
    MAKE_NAME: "ZERO",
    MODEL_NAME: "TYPE 6",
    label: "TYPE 6",
    value: "TYPE 6"
  },
  {
    SERIAL: "HW040000026",
    MAKE_NAME: "ZERO",
    MODEL_NAME: "TYPE 9 (GRAY)",
    label: "TYPE 9 (GRAY)",
    value: "TYPE 9 (GRAY)"
  },
  {
    SERIAL: "HW050000026",
    MAKE_NAME: "ZERO",
    MODEL_NAME: "1300 CC",
    label: "1300 CC",
    value: "1300 CC"
  },
  {
    SERIAL: "HX000000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "SANY",
    label: "SANY",
    value: "SANY"
  },
  {
    SERIAL: "HX010000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "Concrete Mixer",
    label: "Concrete Mixer",
    value: "Concrete Mixer"
  },
  {
    SERIAL: "HX020000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "P11C",
    label: "P11C",
    value: "P11C"
  },
  {
    SERIAL: "HX030000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "MOBILE CONCRETE PUMP",
    label: "MOBILE CONCRETE PUMP",
    value: "MOBILE CONCRETE PUMP"
  },
  {
    SERIAL: "HX040000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "CRANE",
    label: "CRANE",
    value: "CRANE"
  },
  {
    SERIAL: "HX050000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "BACK HOE",
    label: "BACK HOE",
    value: "BACK HOE"
  },
  {
    SERIAL: "HX060000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "DRUM ROLLER",
    label: "DRUM ROLLER",
    value: "DRUM ROLLER"
  },
  {
    SERIAL: "HX070000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "BULLDOZER ZOOMLION",
    label: "BULLDOZER ZOOMLION",
    value: "BULLDOZER ZOOMLION"
  },
  {
    SERIAL: "HX080000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "GRADER",
    label: "GRADER",
    value: "GRADER"
  },
  {
    SERIAL: "HX090000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "CONCRETE MIXER",
    label: "CONCRETE MIXER",
    value: "CONCRETE MIXER"
  },
  {
    SERIAL: "HX100000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "MORETHAN 12 TUN",
    label: "MORETHAN 12 TUN",
    value: "MORETHAN 12 TUN"
  },
  {
    SERIAL: "HX110000026",
    MAKE_NAME: "SANY",
    MODEL_NAME: "GRADER SAG200C-6",
    label: "GRADER SAG200C-6",
    value: "GRADER SAG200C-6"
  },
  {
    SERIAL: "HY000000026",
    MAKE_NAME: "TCX",
    MODEL_NAME: "TCX",
    label: "TCX",
    value: "TCX"
  },
  {
    SERIAL: "HZ000000026",
    MAKE_NAME: "DAYUN",
    MODEL_NAME: "DAYUN",
    label: "DAYUN",
    value: "DAYUN"
  },
  {
    SERIAL: "HZ010000026",
    MAKE_NAME: "DAYUN",
    MODEL_NAME: "CGC4252 (หัวลาก)",
    label: "CGC4252 (หัวลาก)",
    value: "CGC4252 (หัวลาก)"
  },
  {
    SERIAL: "HZ020000026",
    MAKE_NAME: "DAYUN",
    MODEL_NAME: "CGC4252 (หัวลาก) CNG",
    label: "CGC4252 (หัวลาก) CNG",
    value: "CGC4252 (หัวลาก) CNG"
  },
  {
    SERIAL: "HZ030000026",
    MAKE_NAME: "DAYUN",
    MODEL_NAME: "DYX3251",
    label: "DYX3251",
    value: "DYX3251"
  },
  {
    SERIAL: "HZ040000026",
    MAKE_NAME: "DAYUN",
    MODEL_NAME: "DYX3310",
    label: "DYX3310",
    value: "DYX3310"
  },
  {
    SERIAL: "IA000000026",
    MAKE_NAME: "MITSUOKA",
    MODEL_NAME: "MITSUOKA",
    label: "MITSUOKA",
    value: "MITSUOKA"
  },
  {
    SERIAL: "IB000000026",
    MAKE_NAME: "JOYLONG",
    MODEL_NAME: "JOYLONG",
    label: "JOYLONG",
    value: "JOYLONG"
  },
  {
    SERIAL: "IB010000026",
    MAKE_NAME: "JOYLONG",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "IB020000026",
    MAKE_NAME: "JOYLONG",
    MODEL_NAME: "VAN",
    label: "VAN",
    value: "VAN"
  },
  {
    SERIAL: "IC000000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "DFSK",
    label: "DFSK",
    value: "DFSK"
  },
  {
    SERIAL: "IC010000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "MINI TRUCK 1100 CC LPG",
    label: "MINI TRUCK 1100 CC LPG",
    value: "MINI TRUCK 1100 CC LPG"
  },
  {
    SERIAL: "IC020000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "MINI TRUCK 1100 CC NGV",
    label: "MINI TRUCK 1100 CC NGV",
    value: "MINI TRUCK 1100 CC NGV"
  },
  {
    SERIAL: "IC030000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "MINI TRUCK 1300 CC LPG",
    label: "MINI TRUCK 1300 CC LPG",
    value: "MINI TRUCK 1300 CC LPG"
  },
  {
    SERIAL: "IC040000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "MINI TRUCK 1300 CC NGV",
    label: "MINI TRUCK 1300 CC NGV",
    value: "MINI TRUCK 1300 CC NGV"
  },
  {
    SERIAL: "IC050000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "V27 1.3L",
    label: "V27 1.3L",
    value: "V27 1.3L"
  },
  {
    SERIAL: "IC060000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "V27 1.3L DVD",
    label: "V27 1.3L DVD",
    value: "V27 1.3L DVD"
  },
  {
    SERIAL: "IC070000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "V27 1.3L EQUIPPED",
    label: "V27 1.3L EQUIPPED",
    value: "V27 1.3L EQUIPPED"
  },
  {
    SERIAL: "IC080000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "MINI VAN 1.1 L LPG",
    label: "MINI VAN 1.1 L LPG",
    value: "MINI VAN 1.1 L LPG"
  },
  {
    SERIAL: "IC090000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "MINI VAN 1.3 L LPG",
    label: "MINI VAN 1.3 L LPG",
    value: "MINI VAN 1.3 L LPG"
  },
  {
    SERIAL: "IC110000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "OTOP 1.1L LPG",
    label: "OTOP 1.1L LPG",
    value: "OTOP 1.1L LPG"
  },
  {
    SERIAL: "IC120000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "OTOP 1.3L LPG",
    label: "OTOP 1.3L LPG",
    value: "OTOP 1.3L LPG"
  },
  {
    SERIAL: "IC150000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "CAFE 1.1L LPG",
    label: "CAFE 1.1L LPG",
    value: "CAFE 1.1L LPG"
  },
  {
    SERIAL: "IC160000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "CAFE 1.3L LPG",
    label: "CAFE 1.3L LPG",
    value: "CAFE 1.3L LPG"
  },
  {
    SERIAL: "IC170000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "V21 CHAMPION 1.3",
    label: "V21 CHAMPION 1.3",
    value: "V21 CHAMPION 1.3"
  },
  {
    SERIAL: "IC180000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "TRUCK 1.1 LPG",
    label: "TRUCK 1.1 LPG",
    value: "TRUCK 1.1 LPG"
  },
  {
    SERIAL: "IC190000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "TRUCK 1.3 LPG",
    label: "TRUCK 1.3 LPG",
    value: "TRUCK 1.3 LPG"
  },
  {
    SERIAL: "IC200000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "VAN 1.1 LPG",
    label: "VAN 1.1 LPG",
    value: "VAN 1.1 LPG"
  },
  {
    SERIAL: "IC210000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "VAN 1.3 LPG",
    label: "VAN 1.3 LPG",
    value: "VAN 1.3 LPG"
  },
  {
    SERIAL: "IC220000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "SAVER 1.1",
    label: "SAVER 1.1",
    value: "SAVER 1.1"
  },
  {
    SERIAL: "IC230000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "PLUS 1.1 LPG",
    label: "PLUS 1.1 LPG",
    value: "PLUS 1.1 LPG"
  },
  {
    SERIAL: "IC250000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "PLUS TRUCK 1.1",
    label: "PLUS TRUCK 1.1",
    value: "PLUS TRUCK 1.1"
  },
  {
    SERIAL: "IC260000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "V21 CHAMPION FOOD TRUCK 1.3",
    label: "V21 CHAMPION FOOD TRUCK 1.3",
    value: "V21 CHAMPION FOOD TRUCK 1.3"
  },
  {
    SERIAL: "IC270000026",
    MAKE_NAME: "DFSK",
    MODEL_NAME: "SAVER FOOD TRUCK",
    label: "SAVER FOOD TRUCK",
    value: "SAVER FOOD TRUCK"
  },
  {
    SERIAL: "ID000000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "SUNLONG",
    label: "SUNLONG",
    value: "SUNLONG"
  },
  {
    SERIAL: "ID010000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "BUS 40 SEATS",
    label: "BUS 40 SEATS",
    value: "BUS 40 SEATS"
  },
  {
    SERIAL: "ID020000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "SLK6126",
    label: "SLK6126",
    value: "SLK6126"
  },
  {
    SERIAL: "ID030000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "SLK6102D",
    label: "SLK6102D",
    value: "SLK6102D"
  },
  {
    SERIAL: "ID040000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "SLK6120",
    label: "SLK6120",
    value: "SLK6120"
  },
  {
    SERIAL: "ID050000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "BUS 38 SEAT",
    label: "BUS 38 SEAT",
    value: "BUS 38 SEAT"
  },
  {
    SERIAL: "ID060000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "SLK6111",
    label: "SLK6111",
    value: "SLK6111"
  },
  {
    SERIAL: "ID070000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "SLK6102D",
    label: "SLK6102D",
    value: "SLK6102D"
  },
  {
    SERIAL: "ID080000026",
    MAKE_NAME: "SUNLONG",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "IF000000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "FOTON",
    label: "FOTON",
    value: "FOTON"
  },
  {
    SERIAL: "IF010000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "BJ 4253",
    label: "BJ 4253",
    value: "BJ 4253"
  },
  {
    SERIAL: "IF020000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "BJ5257 (MIXER)",
    label: "BJ5257 (MIXER)",
    value: "BJ5257 (MIXER)"
  },
  {
    SERIAL: "IF030000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "VIEW VAN A1 11 SEAT",
    label: "VIEW VAN A1 11 SEAT",
    value: "VIEW VAN A1 11 SEAT"
  },
  {
    SERIAL: "IF040000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "VIEW VAN A2 11SEAT",
    label: "VIEW VAN A2 11SEAT",
    value: "VIEW VAN A2 11SEAT"
  },
  {
    SERIAL: "IF050000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "VIEW VAN A3 11SEAT",
    label: "VIEW VAN A3 11SEAT",
    value: "VIEW VAN A3 11SEAT"
  },
  {
    SERIAL: "IF060000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "VIEW VAN TOURING 11SEAT",
    label: "VIEW VAN TOURING 11SEAT",
    value: "VIEW VAN TOURING 11SEAT"
  },
  {
    SERIAL: "IF070000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "COLUMBUS STANDARD VAN 2.8",
    label: "COLUMBUS STANDARD VAN 2.8",
    value: "COLUMBUS STANDARD VAN 2.8"
  },
  {
    SERIAL: "IF080000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "COLUMBUS VIP VAN 2.8",
    label: "COLUMBUS VIP VAN 2.8",
    value: "COLUMBUS VIP VAN 2.8"
  },
  {
    SERIAL: "IF090000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "IF100000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "AUMARK C 4W",
    label: "AUMARK C 4W",
    value: "AUMARK C 4W"
  },
  {
    SERIAL: "IF110000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "TUNLAND SINGLECAB 4WD 2.8",
    label: "TUNLAND SINGLECAB 4WD 2.8",
    value: "TUNLAND SINGLECAB 4WD 2.8"
  },
  {
    SERIAL: "IF120000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "TUNLAND DOUBLECAB 2.8",
    label: "TUNLAND DOUBLECAB 2.8",
    value: "TUNLAND DOUBLECAB 2.8"
  },
  {
    SERIAL: "IF130000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "TUNLAND DOUBLECAB HIGH 2.8",
    label: "TUNLAND DOUBLECAB HIGH 2.8",
    value: "TUNLAND DOUBLECAB HIGH 2.8"
  },
  {
    SERIAL: "IF140000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "TUNLAND DOUBLECAB HIGH PREMIUM 2.8",
    label: "TUNLAND DOUBLECAB HIGH PREMIUM 2.8",
    value: "TUNLAND DOUBLECAB HIGH PREMIUM 2.8"
  },
  {
    SERIAL: "IF150000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "TUNLAND DOUBLECAB HIGH 4WD 2.8",
    label: "TUNLAND DOUBLECAB HIGH 4WD 2.8",
    value: "TUNLAND DOUBLECAB HIGH 4WD 2.8"
  },
  {
    SERIAL: "IF160000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "VIEW CS2 2.8",
    label: "VIEW CS2 2.8",
    value: "VIEW CS2 2.8"
  },
  {
    SERIAL: "IF170000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "BUS OVER 15 SEAT",
    label: "BUS OVER 15 SEAT",
    value: "BUS OVER 15 SEAT"
  },
  {
    SERIAL: "IF180000026",
    MAKE_NAME: "FOTON",
    MODEL_NAME: "BJ4257",
    label: "BJ4257",
    value: "BJ4257"
  },
  {
    SERIAL: "IG000000026",
    MAKE_NAME: "SUNTA",
    MODEL_NAME: "SUNTA",
    label: "SUNTA",
    value: "SUNTA"
  },
  {
    SERIAL: "IH000000026",
    MAKE_NAME: "GTS",
    MODEL_NAME: "GTS",
    label: "GTS",
    value: "GTS"
  },
  {
    SERIAL: "II000000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "MV AGUSTA",
    label: "MV AGUSTA",
    value: "MV AGUSTA"
  },
  {
    SERIAL: "II010000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "B3",
    label: "B3",
    value: "B3"
  },
  {
    SERIAL: "II020000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 675",
    label: "F3 675",
    value: "F3 675"
  },
  {
    SERIAL: "II030000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 675 EAS",
    label: "F3 675 EAS",
    value: "F3 675 EAS"
  },
  {
    SERIAL: "II040000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 Oro",
    label: "F3 Oro",
    value: "F3 Oro"
  },
  {
    SERIAL: "II050000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE",
    label: "BRUTALE",
    value: "BRUTALE"
  },
  {
    SERIAL: "II060000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 1090 R",
    label: "BRUTALE 1090 R",
    value: "BRUTALE 1090 R"
  },
  {
    SERIAL: "II070000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 1090 RR",
    label: "BRUTALE 1090 RR",
    value: "BRUTALE 1090 RR"
  },
  {
    SERIAL: "II080000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F4 R",
    label: "F4 R",
    value: "F4 R"
  },
  {
    SERIAL: "II090000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F4 RR",
    label: "F4 RR",
    value: "F4 RR"
  },
  {
    SERIAL: "II100000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "RIVALE 800",
    label: "RIVALE 800",
    value: "RIVALE 800"
  },
  {
    SERIAL: "II110000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 675",
    label: "BRUTALE 675",
    value: "BRUTALE 675"
  },
  {
    SERIAL: "II120000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 800 EAS",
    label: "F3 800 EAS",
    value: "F3 800 EAS"
  },
  {
    SERIAL: "II130000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 800 AGO",
    label: "F3 800 AGO",
    value: "F3 800 AGO"
  },
  {
    SERIAL: "II140000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "B3 800 EAS",
    label: "B3 800 EAS",
    value: "B3 800 EAS"
  },
  {
    SERIAL: "II150000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 800 EAS",
    label: "BRUTALE 800 EAS",
    value: "BRUTALE 800 EAS"
  },
  {
    SERIAL: "II160000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE DRAGSTER 800",
    label: "BRUTALE DRAGSTER 800",
    value: "BRUTALE DRAGSTER 800"
  },
  {
    SERIAL: "II170000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 800 EAS ABS",
    label: "F3 800 EAS ABS",
    value: "F3 800 EAS ABS"
  },
  {
    SERIAL: "II180000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 1090 RR CORSA",
    label: "BRUTALE 1090 RR CORSA",
    value: "BRUTALE 1090 RR CORSA"
  },
  {
    SERIAL: "II190000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F4",
    label: "F4",
    value: "F4"
  },
  {
    SERIAL: "II200000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 675 EAS",
    label: "BRUTALE 675 EAS",
    value: "BRUTALE 675 EAS"
  },
  {
    SERIAL: "II210000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "STADALE 800",
    label: "STADALE 800",
    value: "STADALE 800"
  },
  {
    SERIAL: "II220000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 800 RR",
    label: "BRUTALE 800 RR",
    value: "BRUTALE 800 RR"
  },
  {
    SERIAL: "II230000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "DRAGSTER 800",
    label: "DRAGSTER 800",
    value: "DRAGSTER 800"
  },
  {
    SERIAL: "II240000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "B3 675 EAS",
    label: "B3 675 EAS",
    value: "B3 675 EAS"
  },
  {
    SERIAL: "II250000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 920",
    label: "BRUTALE 920",
    value: "BRUTALE 920"
  },
  {
    SERIAL: "II260000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 1090 ABS",
    label: "BRUTALE 1090 ABS",
    value: "BRUTALE 1090 ABS"
  },
  {
    SERIAL: "II270000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 1090 R",
    label: "BRUTALE 1090 R",
    value: "BRUTALE 1090 R"
  },
  {
    SERIAL: "II280000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 1090 RR ABS",
    label: "BRUTALE 1090 RR ABS",
    value: "BRUTALE 1090 RR ABS"
  },
  {
    SERIAL: "II290000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE DRAGSTER 800 RR",
    label: "BRUTALE DRAGSTER 800 RR",
    value: "BRUTALE DRAGSTER 800 RR"
  },
  {
    SERIAL: "II300000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "B3 675 EAS ABS",
    label: "B3 675 EAS ABS",
    value: "B3 675 EAS ABS"
  },
  {
    SERIAL: "II310000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "RIVALE 800 ABS",
    label: "RIVALE 800 ABS",
    value: "RIVALE 800 ABS"
  },
  {
    SERIAL: "II320000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE 800",
    label: "BRUTALE 800",
    value: "BRUTALE 800"
  },
  {
    SERIAL: "II340000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "TURISMO VELOCE 800",
    label: "TURISMO VELOCE 800",
    value: "TURISMO VELOCE 800"
  },
  {
    SERIAL: "II350000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "TURISMO VELOCE LUSSO 800",
    label: "TURISMO VELOCE LUSSO 800",
    value: "TURISMO VELOCE LUSSO 800"
  },
  {
    SERIAL: "II360000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F4 RC",
    label: "F4 RC",
    value: "F4 RC"
  },
  {
    SERIAL: "II370000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 800RC",
    label: "F3 800RC",
    value: "F3 800RC"
  },
  {
    SERIAL: "II380000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "F3 800",
    label: "F3 800",
    value: "F3 800"
  },
  {
    SERIAL: "II400000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "BRUTALE DRAGSTER RC 800",
    label: "BRUTALE DRAGSTER RC 800",
    value: "BRUTALE DRAGSTER RC 800"
  },
  {
    SERIAL: "II410000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "DRAGSTER 800 RR",
    label: "DRAGSTER 800 RR",
    value: "DRAGSTER 800 RR"
  },
  {
    SERIAL: "II420000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "DRAGSTER 800 RC",
    label: "DRAGSTER 800 RC",
    value: "DRAGSTER 800 RC"
  },
  {
    SERIAL: "II430000026",
    MAKE_NAME: "MV AGUSTA",
    MODEL_NAME: "TURISMO VELOCE LUSSO 800 RC",
    label: "TURISMO VELOCE LUSSO 800 RC",
    value: "TURISMO VELOCE LUSSO 800 RC"
  },
  {
    SERIAL: "IJ000000026",
    MAKE_NAME: "MAKEERUS",
    MODEL_NAME: "MAKEERUS",
    label: "MAKEERUS",
    value: "MAKEERUS"
  },
  {
    SERIAL: "IJ010000026",
    MAKE_NAME: "MAKEERUS",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "IK000000026",
    MAKE_NAME: "SUMOTA",
    MODEL_NAME: "SUMOTA",
    label: "SUMOTA",
    value: "SUMOTA"
  },
  {
    SERIAL: "IL000000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "VICTORY",
    label: "VICTORY",
    value: "VICTORY"
  },
  {
    SERIAL: "IL010000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER VEGAS 8 BALL",
    label: "CRUISER VEGAS 8 BALL",
    value: "CRUISER VEGAS 8 BALL"
  },
  {
    SERIAL: "IL020000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER HAMMER 8-BALL",
    label: "CRUISER HAMMER 8-BALL",
    value: "CRUISER HAMMER 8-BALL"
  },
  {
    SERIAL: "IL030000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER HAMMER - S FIREBALL",
    label: "CRUISER HAMMER - S FIREBALL",
    value: "CRUISER HAMMER - S FIREBALL"
  },
  {
    SERIAL: "IL040000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER JUDGE",
    label: "CRUISER JUDGE",
    value: "CRUISER JUDGE"
  },
  {
    SERIAL: "IL050000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER JACKPOT",
    label: "CRUISER JACKPOT",
    value: "CRUISER JACKPOT"
  },
  {
    SERIAL: "IL060000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER JACKPOT W/GRAPHICS",
    label: "CRUISER JACKPOT W/GRAPHICS",
    value: "CRUISER JACKPOT W/GRAPHICS"
  },
  {
    SERIAL: "IL070000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "BAGGERS CROSS ROADS CLASSIC",
    label: "BAGGERS CROSS ROADS CLASSIC",
    value: "BAGGERS CROSS ROADS CLASSIC"
  },
  {
    SERIAL: "IL080000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "NESS SIGNATURE CROSS COUNTRY CORY NESS",
    label: "NESS SIGNATURE CROSS COUNTRY CORY NESS",
    value: "NESS SIGNATURE CROSS COUNTRY CORY NESS"
  },
  {
    SERIAL: "IL090000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "BAGGERS CROSS COUNTRY",
    label: "BAGGERS CROSS COUNTRY",
    value: "BAGGERS CROSS COUNTRY"
  },
  {
    SERIAL: "IL100000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "TOURING CROSS COUNTRY TOUR",
    label: "TOURING CROSS COUNTRY TOUR",
    value: "TOURING CROSS COUNTRY TOUR"
  },
  {
    SERIAL: "IL110000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "NESS SIGNATURE CROSS COUNTRY ZACH NESS",
    label: "NESS SIGNATURE CROSS COUNTRY ZACH NESS",
    value: "NESS SIGNATURE CROSS COUNTRY ZACH NESS"
  },
  {
    SERIAL: "IL120000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "TOURING VISION TOUR",
    label: "TOURING VISION TOUR",
    value: "TOURING VISION TOUR"
  },
  {
    SERIAL: "IL130000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "NESS SIGNATURE VISION TOUR ARLEN NESS",
    label: "NESS SIGNATURE VISION TOUR ARLEN NESS",
    value: "NESS SIGNATURE VISION TOUR ARLEN NESS"
  },
  {
    SERIAL: "IL140000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "BAGGERS HARD BALL",
    label: "BAGGERS HARD BALL",
    value: "BAGGERS HARD BALL"
  },
  {
    SERIAL: "IL150000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER BOADWALK",
    label: "CRUISER BOADWALK",
    value: "CRUISER BOADWALK"
  },
  {
    SERIAL: "IL160000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CROSS COUNTRY SUEDE NUCLEAR SUNSET",
    label: "CROSS COUNTRY SUEDE NUCLEAR SUNSET",
    value: "CROSS COUNTRY SUEDE NUCLEAR SUNSET"
  },
  {
    SERIAL: "IL170000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "VEGAS 8 BALL COVER-2 ONLY",
    label: "VEGAS 8 BALL COVER-2 ONLY",
    value: "VEGAS 8 BALL COVER-2 ONLY"
  },
  {
    SERIAL: "IL180000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "GUNNER",
    label: "GUNNER",
    value: "GUNNER"
  },
  {
    SERIAL: "IL190000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER HIGH BALL",
    label: "CRUISER HIGH BALL",
    value: "CRUISER HIGH BALL"
  },
  {
    SERIAL: "IL200000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER HIGH BALL",
    label: "CRUISER HIGH BALL",
    value: "CRUISER HIGH BALL"
  },
  {
    SERIAL: "IL210000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER HUMMER 8 BALL",
    label: "CRUISER HUMMER 8 BALL",
    value: "CRUISER HUMMER 8 BALL"
  },
  {
    SERIAL: "IL220000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER HUMMER S",
    label: "CRUISER HUMMER S",
    value: "CRUISER HUMMER S"
  },
  {
    SERIAL: "IL230000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "CRUISER HUMMER S LE",
    label: "CRUISER HUMMER S LE",
    value: "CRUISER HUMMER S LE"
  },
  {
    SERIAL: "IL240000026",
    MAKE_NAME: "VICTORY",
    MODEL_NAME: "BEGGERS NESS CROSS COUNTRY LIMITED",
    label: "BEGGERS NESS CROSS COUNTRY LIMITED",
    value: "BEGGERS NESS CROSS COUNTRY LIMITED"
  },
  {
    SERIAL: "IM000000026",
    MAKE_NAME: "HENGTONG",
    MODEL_NAME: "HENGTONG",
    label: "HENGTONG",
    value: "HENGTONG"
  },
  {
    SERIAL: "IM010000026",
    MAKE_NAME: "HENGTONG",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "IN000000026",
    MAKE_NAME: "KARRY",
    MODEL_NAME: "KARRY",
    label: "KARRY",
    value: "KARRY"
  },
  {
    SERIAL: "IO000000026",
    MAKE_NAME: "SERPOLLET",
    MODEL_NAME: "SERPOLLET",
    label: "SERPOLLET",
    value: "SERPOLLET"
  },
  {
    SERIAL: "IP000000026",
    MAKE_NAME: "YAXING",
    MODEL_NAME: "YAXING",
    label: "YAXING",
    value: "YAXING"
  },
  {
    SERIAL: "IP010000026",
    MAKE_NAME: "YAXING",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "IQ000000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "BENELLI",
    label: "BENELLI",
    value: "BENELLI"
  },
  {
    SERIAL: "IQ010000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TORNADO NAKED TRE 899",
    label: "TORNADO NAKED TRE 899",
    value: "TORNADO NAKED TRE 899"
  },
  {
    SERIAL: "IQ020000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TNT899",
    label: "TNT899",
    value: "TNT899"
  },
  {
    SERIAL: "IQ030000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TRE1130 K",
    label: "TRE1130 K",
    value: "TRE1130 K"
  },
  {
    SERIAL: "IQ040000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TNT1130 R",
    label: "TNT1130 R",
    value: "TNT1130 R"
  },
  {
    SERIAL: "IQ050000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TNT1130 T",
    label: "TNT1130 T",
    value: "TNT1130 T"
  },
  {
    SERIAL: "IQ060000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "BN600-E",
    label: "BN600-E",
    value: "BN600-E"
  },
  {
    SERIAL: "IQ070000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "LEONCINO 500",
    label: "LEONCINO 500",
    value: "LEONCINO 500"
  },
  {
    SERIAL: "IQ080000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TITANIUM 1130",
    label: "TITANIUM 1130",
    value: "TITANIUM 1130"
  },
  {
    SERIAL: "IQ090000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "IQ100000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "BN302-E",
    label: "BN302-E",
    value: "BN302-E"
  },
  {
    SERIAL: "IQ110000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TNT300S",
    label: "TNT300S",
    value: "TNT300S"
  },
  {
    SERIAL: "IQ120000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TRK 502X",
    label: "TRK 502X",
    value: "TRK 502X"
  },
  {
    SERIAL: "IQ130000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "302R",
    label: "302R",
    value: "302R"
  },
  {
    SERIAL: "IQ140000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "BN302 S",
    label: "BN302 S",
    value: "BN302 S"
  },
  {
    SERIAL: "IQ150000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "BJ600GS",
    label: "BJ600GS",
    value: "BJ600GS"
  },
  {
    SERIAL: "IQ160000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "IMPERIALE 400",
    label: "IMPERIALE 400",
    value: "IMPERIALE 400"
  },
  {
    SERIAL: "IQ170000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "ZONTES X310",
    label: "ZONTES X310",
    value: "ZONTES X310"
  },
  {
    SERIAL: "IQ180000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TNT 135",
    label: "TNT 135",
    value: "TNT 135"
  },
  {
    SERIAL: "IQ220000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TRK 251",
    label: "TRK 251",
    value: "TRK 251"
  },
  {
    SERIAL: "IQ420000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "BN600I",
    label: "BN600I",
    value: "BN600I"
  },
  {
    SERIAL: "IQ440000026",
    MAKE_NAME: "BENELLI",
    MODEL_NAME: "TNT600GT",
    label: "TNT600GT",
    value: "TNT600GT"
  },
  {
    SERIAL: "IR000000026",
    MAKE_NAME: "BEIBEN",
    MODEL_NAME: "BEIBEN",
    label: "BEIBEN",
    value: "BEIBEN"
  },
  {
    SERIAL: "IR010000026",
    MAKE_NAME: "BEIBEN",
    MODEL_NAME: "V3 DIESEL",
    label: "V3 DIESEL",
    value: "V3 DIESEL"
  },
  {
    SERIAL: "IR020000026",
    MAKE_NAME: "BEIBEN",
    MODEL_NAME: "V3 4*2",
    label: "V3 4*2",
    value: "V3 4*2"
  },
  {
    SERIAL: "IS000000026",
    MAKE_NAME: "CATERHAM",
    MODEL_NAME: "CATERHAM",
    label: "CATERHAM",
    value: "CATERHAM"
  },
  {
    SERIAL: "IS010000026",
    MAKE_NAME: "CATERHAM",
    MODEL_NAME: "SUPER SPORT 2.0",
    label: "SUPER SPORT 2.0",
    value: "SUPER SPORT 2.0"
  },
  {
    SERIAL: "IT000000026",
    MAKE_NAME: "PAGANI",
    MODEL_NAME: "PAGANI",
    label: "PAGANI",
    value: "PAGANI"
  },
  {
    SERIAL: "IT010000026",
    MAKE_NAME: "PAGANI",
    MODEL_NAME: "HUAYRA 6.0",
    label: "HUAYRA 6.0",
    value: "HUAYRA 6.0"
  },
  {
    SERIAL: "IU000000026",
    MAKE_NAME: "YANGTSE",
    MODEL_NAME: "YANGTSE",
    label: "YANGTSE",
    value: "YANGTSE"
  },
  {
    SERIAL: "IV000000026",
    MAKE_NAME: "STERY",
    MODEL_NAME: "STERY",
    label: "STERY",
    value: "STERY"
  },
  {
    SERIAL: "IV010000026",
    MAKE_NAME: "STERY",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "IW000000026",
    MAKE_NAME: "JMC",
    MODEL_NAME: "JMC",
    label: "JMC",
    value: "JMC"
  },
  {
    SERIAL: "IW010000026",
    MAKE_NAME: "JMC",
    MODEL_NAME: "JX1032DBR",
    label: "JX1032DBR",
    value: "JX1032DBR"
  },
  {
    SERIAL: "IW020000026",
    MAKE_NAME: "JMC",
    MODEL_NAME: "JX1032DB2R",
    label: "JX1032DB2R",
    value: "JX1032DB2R"
  },
  {
    SERIAL: "IW030000026",
    MAKE_NAME: "JMC",
    MODEL_NAME: "JX5033XXYXAR (ตู้บรรทุก)",
    label: "JX5033XXYXAR (ตู้บรรทุก)",
    value: "JX5033XXYXAR (ตู้บรรทุก)"
  },
  {
    SERIAL: "IW040000026",
    MAKE_NAME: "JMC",
    MODEL_NAME: "JX5043XXYXA2R (ตู้บรรทุก)",
    label: "JX5043XXYXA2R (ตู้บรรทุก)",
    value: "JX5043XXYXA2R (ตู้บรรทุก)"
  },
  {
    SERIAL: "IW050000026",
    MAKE_NAME: "JMC",
    MODEL_NAME: "JX3041D2R (ดั๊ม)",
    label: "JX3041D2R (ดั๊ม)",
    value: "JX3041D2R (ดั๊ม)"
  },
  {
    SERIAL: "IW060000026",
    MAKE_NAME: "JMC",
    MODEL_NAME: "JX1041DLA2R",
    label: "JX1041DLA2R",
    value: "JX1041DLA2R"
  },
  {
    SERIAL: "IX000000026",
    MAKE_NAME: "CAMC",
    MODEL_NAME: "CAMC",
    label: "CAMC",
    value: "CAMC"
  },
  {
    SERIAL: "IX010000026",
    MAKE_NAME: "CAMC",
    MODEL_NAME: "HN3313",
    label: "HN3313",
    value: "HN3313"
  },
  {
    SERIAL: "IX020000026",
    MAKE_NAME: "CAMC",
    MODEL_NAME: "MIXER CONCRETT",
    label: "MIXER CONCRETT",
    value: "MIXER CONCRETT"
  },
  {
    SERIAL: "IX030000026",
    MAKE_NAME: "CAMC",
    MODEL_NAME: "T430 HP",
    label: "T430 HP",
    value: "T430 HP"
  },
  {
    SERIAL: "IY000000026",
    MAKE_NAME: "FUWA",
    MODEL_NAME: "FUWA",
    label: "FUWA",
    value: "FUWA"
  },
  {
    SERIAL: "IY010000026",
    MAKE_NAME: "FUWA",
    MODEL_NAME: "QUY80",
    label: "QUY80",
    value: "QUY80"
  },
  {
    SERIAL: "IY020000026",
    MAKE_NAME: "FUWA",
    MODEL_NAME: "QUY150",
    label: "QUY150",
    value: "QUY150"
  },
  {
    SERIAL: "IZ000000026",
    MAKE_NAME: "TPM",
    MODEL_NAME: "TPM",
    label: "TPM",
    value: "TPM"
  },
  {
    SERIAL: "IZ010000026",
    MAKE_NAME: "TPM",
    MODEL_NAME: "TRACKTOR",
    label: "TRACKTOR",
    value: "TRACKTOR"
  },
  {
    SERIAL: "IZ020000026",
    MAKE_NAME: "TPM",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "JA000000026",
    MAKE_NAME: "LIUGONG",
    MODEL_NAME: "LIUGONG",
    label: "LIUGONG",
    value: "LIUGONG"
  },
  {
    SERIAL: "JA010000026",
    MAKE_NAME: "LIUGONG",
    MODEL_NAME: "FORKLIFT",
    label: "FORKLIFT",
    value: "FORKLIFT"
  },
  {
    SERIAL: "JA020000026",
    MAKE_NAME: "LIUGONG",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "JB000000026",
    MAKE_NAME: "YUTONG",
    MODEL_NAME: "YUTONG",
    label: "YUTONG",
    value: "YUTONG"
  },
  {
    SERIAL: "JB010000026",
    MAKE_NAME: "YUTONG",
    MODEL_NAME: "ZK6608D - BUS",
    label: "ZK6608D - BUS",
    value: "ZK6608D - BUS"
  },
  {
    SERIAL: "JB020000026",
    MAKE_NAME: "YUTONG",
    MODEL_NAME: "ZK6122H9 - BUS",
    label: "ZK6122H9 - BUS",
    value: "ZK6122H9 - BUS"
  },
  {
    SERIAL: "JB030000026",
    MAKE_NAME: "YUTONG",
    MODEL_NAME: "ZK6908H - BUS",
    label: "ZK6908H - BUS",
    value: "ZK6908H - BUS"
  },
  {
    SERIAL: "JB040000026",
    MAKE_NAME: "YUTONG",
    MODEL_NAME: "ZK6729",
    label: "ZK6729",
    value: "ZK6729"
  },
  {
    SERIAL: "JB050000026",
    MAKE_NAME: "YUTONG",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "JC000000026",
    MAKE_NAME: "V13R",
    MODEL_NAME: "V13R",
    label: "V13R",
    value: "V13R"
  },
  {
    SERIAL: "JC010000026",
    MAKE_NAME: "V13R",
    MODEL_NAME: "V13R",
    label: "V13R",
    value: "V13R"
  },
  {
    SERIAL: "JD000000026",
    MAKE_NAME: "BYD",
    MODEL_NAME: "BYD",
    label: "BYD",
    value: "BYD"
  },
  {
    SERIAL: "JD010000026",
    MAKE_NAME: "BYD",
    MODEL_NAME: "E6 - Electric",
    label: "E6 - Electric",
    value: "E6 - Electric"
  },
  {
    SERIAL: "JE000000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "MAXUS",
    label: "MAXUS",
    value: "MAXUS"
  },
  {
    SERIAL: "JE010000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 16st 2.5",
    label: "V80 16st 2.5",
    value: "V80 16st 2.5"
  },
  {
    SERIAL: "JE020000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 12st GRAND TOURER 2.5",
    label: "V80 12st GRAND TOURER 2.5",
    value: "V80 12st GRAND TOURER 2.5"
  },
  {
    SERIAL: "JE030000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 GRAND EURO VIP 11 SEAT 2.5",
    label: "V80 GRAND EURO VIP 11 SEAT 2.5",
    value: "V80 GRAND EURO VIP 11 SEAT 2.5"
  },
  {
    SERIAL: "JE040000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 LATIVA 2.5",
    label: "V80 LATIVA 2.5",
    value: "V80 LATIVA 2.5"
  },
  {
    SERIAL: "JE050000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 PALINA 2.5",
    label: "V80 PALINA 2.5",
    value: "V80 PALINA 2.5"
  },
  {
    SERIAL: "JE060000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 PASINA 2.5",
    label: "V80 PASINA 2.5",
    value: "V80 PASINA 2.5"
  },
  {
    SERIAL: "JE070000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 SATIVA 2.5 A",
    label: "V80 SATIVA 2.5 A",
    value: "V80 SATIVA 2.5 A"
  },
  {
    SERIAL: "JE080000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 SATIVA 2.5 M",
    label: "V80 SATIVA 2.5 M",
    value: "V80 SATIVA 2.5 M"
  },
  {
    SERIAL: "JE090000026",
    MAKE_NAME: "MAXUS",
    MODEL_NAME: "V80 COTINA 2.5",
    label: "V80 COTINA 2.5",
    value: "V80 COTINA 2.5"
  },
  {
    SERIAL: "JF000000026",
    MAKE_NAME: "UD",
    MODEL_NAME: "UD",
    label: "UD",
    value: "UD"
  },
  {
    SERIAL: "JF010000026",
    MAKE_NAME: "UD",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "JF020000026",
    MAKE_NAME: "UD",
    MODEL_NAME: "QUESTER (JPCZM30D1JT)",
    label: "QUESTER (JPCZM30D1JT)",
    value: "QUESTER (JPCZM30D1JT)"
  },
  {
    SERIAL: "JF030000026",
    MAKE_NAME: "UD",
    MODEL_NAME: "25000 KG.",
    label: "25000 KG.",
    value: "25000 KG."
  },
  {
    SERIAL: "JF040000026",
    MAKE_NAME: "UD",
    MODEL_NAME: "JPCZM3",
    label: "JPCZM3",
    value: "JPCZM3"
  },
  {
    SERIAL: "JG000000026",
    MAKE_NAME: "FUSO",
    MODEL_NAME: "FUSO",
    label: "FUSO",
    value: "FUSO"
  },
  {
    SERIAL: "JG010000026",
    MAKE_NAME: "FUSO",
    MODEL_NAME: "FJ2528 (MIXER)",
    label: "FJ2528 (MIXER)",
    value: "FJ2528 (MIXER)"
  },
  {
    SERIAL: "JG030000026",
    MAKE_NAME: "FUSO",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "JG150000026",
    MAKE_NAME: "FUSO",
    MODEL_NAME: "FZ4928TT",
    label: "FZ4928TT",
    value: "FZ4928TT"
  },
  {
    SERIAL: "JH000000026",
    MAKE_NAME: "POLARIS",
    MODEL_NAME: "POLARIS",
    label: "POLARIS",
    value: "POLARIS"
  },
  {
    SERIAL: "JH010000026",
    MAKE_NAME: "POLARIS",
    MODEL_NAME: "SLINGSHOT",
    label: "SLINGSHOT",
    value: "SLINGSHOT"
  },
  {
    SERIAL: "JH020000026",
    MAKE_NAME: "POLARIS",
    MODEL_NAME: "RZR900",
    label: "RZR900",
    value: "RZR900"
  },
  {
    SERIAL: "JI000000026",
    MAKE_NAME: "KIOTI",
    MODEL_NAME: "KIOTI",
    label: "KIOTI",
    value: "KIOTI"
  },
  {
    SERIAL: "JJ000000026",
    MAKE_NAME: "CAN AM",
    MODEL_NAME: "CAN AM",
    label: "CAN AM",
    value: "CAN AM"
  },
  {
    SERIAL: "JJ010000026",
    MAKE_NAME: "CAN AM",
    MODEL_NAME: "MAVERICK X3S",
    label: "MAVERICK X3S",
    value: "MAVERICK X3S"
  },
  {
    SERIAL: "JK000000026",
    MAKE_NAME: "ZOOMLION",
    MODEL_NAME: "ZOOMLION",
    label: "ZOOMLION",
    value: "ZOOMLION"
  },
  {
    SERIAL: "JN000000026",
    MAKE_NAME: "MAHINDRA",
    MODEL_NAME: "MAHINDRA",
    label: "MAHINDRA",
    value: "MAHINDRA"
  },
  {
    SERIAL: "JZ000000026",
    MAKE_NAME: "SEM",
    MODEL_NAME: "SEM",
    label: "SEM",
    value: "SEM"
  },
  {
    SERIAL: "KA000000026",
    MAKE_NAME: "HAIMA",
    MODEL_NAME: "HAIMA",
    label: "HAIMA",
    value: "HAIMA"
  },
  {
    SERIAL: "KB000000026",
    MAKE_NAME: "CHANGAN",
    MODEL_NAME: "CHANGAN",
    label: "CHANGAN",
    value: "CHANGAN"
  },
  {
    SERIAL: "KB010000026",
    MAKE_NAME: "CHANGAN",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "KC000000026",
    MAKE_NAME: "TOYOTRON",
    MODEL_NAME: "TOYOTRON",
    label: "TOYOTRON",
    value: "TOYOTRON"
  },
  {
    SERIAL: "KD000000026",
    MAKE_NAME: "DONGYU",
    MODEL_NAME: "DONGYU",
    label: "DONGYU",
    value: "DONGYU"
  },
  {
    SERIAL: "KD010000026",
    MAKE_NAME: "DONGYU",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "KE000000026",
    MAKE_NAME: "KINGLONG",
    MODEL_NAME: "KINGLONG",
    label: "KINGLONG",
    value: "KINGLONG"
  },
  {
    SERIAL: "KE010000026",
    MAKE_NAME: "KINGLONG",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "KF000000026",
    MAKE_NAME: "CXT",
    MODEL_NAME: "CXT",
    label: "CXT",
    value: "CXT"
  },
  {
    SERIAL: "KF010000026",
    MAKE_NAME: "CXT",
    MODEL_NAME: "CXT7700",
    label: "CXT7700",
    value: "CXT7700"
  },
  {
    SERIAL: "KG000000026",
    MAKE_NAME: "SCOMADI",
    MODEL_NAME: "SCOMADI",
    label: "SCOMADI",
    value: "SCOMADI"
  },
  {
    SERIAL: "KG010000026",
    MAKE_NAME: "SCOMADI",
    MODEL_NAME: "125",
    label: "125",
    value: "125"
  },
  {
    SERIAL: "KG020000026",
    MAKE_NAME: "SCOMADI",
    MODEL_NAME: "TOURISMO LOGGERA",
    label: "TOURISMO LOGGERA",
    value: "TOURISMO LOGGERA"
  },
  {
    SERIAL: "KG030000026",
    MAKE_NAME: "SCOMADI",
    MODEL_NAME: "TT200",
    label: "TT200",
    value: "TT200"
  },
  {
    SERIAL: "KH000000026",
    MAKE_NAME: "RAGE",
    MODEL_NAME: "RAGE",
    label: "RAGE",
    value: "RAGE"
  },
  {
    SERIAL: "KH010000026",
    MAKE_NAME: "RAGE",
    MODEL_NAME: "Rage",
    label: "Rage",
    value: "Rage"
  },
  {
    SERIAL: "KI000000026",
    MAKE_NAME: "JAVAN",
    MODEL_NAME: "JAVAN",
    label: "JAVAN",
    value: "JAVAN"
  },
  {
    SERIAL: "KI010000026",
    MAKE_NAME: "JAVAN",
    MODEL_NAME: "R1 2.0",
    label: "R1 2.0",
    value: "R1 2.0"
  },
  {
    SERIAL: "KL000000026",
    MAKE_NAME: "V-TWIN",
    MODEL_NAME: "V-TWIN",
    label: "V-TWIN",
    value: "V-TWIN"
  },
  {
    SERIAL: "KL010000026",
    MAKE_NAME: "V-TWIN",
    MODEL_NAME: "REPLICA BOBBER",
    label: "REPLICA BOBBER",
    value: "REPLICA BOBBER"
  },
  {
    SERIAL: "KL020000026",
    MAKE_NAME: "V-TWIN",
    MODEL_NAME: "WISHBONE BOBBER",
    label: "WISHBONE BOBBER",
    value: "WISHBONE BOBBER"
  },
  {
    SERIAL: "KL030000026",
    MAKE_NAME: "V-TWIN",
    MODEL_NAME: "REPLICA KNUCKLEHEAD BOBBER",
    label: "REPLICA KNUCKLEHEAD BOBBER",
    value: "REPLICA KNUCKLEHEAD BOBBER"
  },
  {
    SERIAL: "KL040000026",
    MAKE_NAME: "V-TWIN",
    MODEL_NAME: "REPLICA PANHEAD",
    label: "REPLICA PANHEAD",
    value: "REPLICA PANHEAD"
  },
  {
    SERIAL: "KM000000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "HUSQVARNA",
    label: "HUSQVARNA",
    value: "HUSQVARNA"
  },
  {
    SERIAL: "KM010000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "TE150",
    label: "TE150",
    value: "TE150"
  },
  {
    SERIAL: "KM020000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "TE250",
    label: "TE250",
    value: "TE250"
  },
  {
    SERIAL: "KM030000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "TE250I",
    label: "TE250I",
    value: "TE250I"
  },
  {
    SERIAL: "KM040000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "TE300",
    label: "TE300",
    value: "TE300"
  },
  {
    SERIAL: "KM050000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "TE300I",
    label: "TE300I",
    value: "TE300I"
  },
  {
    SERIAL: "KM060000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "FE250",
    label: "FE250",
    value: "FE250"
  },
  {
    SERIAL: "KM070000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "FE350",
    label: "FE350",
    value: "FE350"
  },
  {
    SERIAL: "KM080000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "FE450",
    label: "FE450",
    value: "FE450"
  },
  {
    SERIAL: "KM090000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "FE501",
    label: "FE501",
    value: "FE501"
  },
  {
    SERIAL: "KM100000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "701 ENDURO",
    label: "701 ENDURO",
    value: "701 ENDURO"
  },
  {
    SERIAL: "KM110000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "701 SUPERMOTO",
    label: "701 SUPERMOTO",
    value: "701 SUPERMOTO"
  },
  {
    SERIAL: "KM120000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "SVARTPILEN 401",
    label: "SVARTPILEN 401",
    value: "SVARTPILEN 401"
  },
  {
    SERIAL: "KM130000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "VITPILEN 401",
    label: "VITPILEN 401",
    value: "VITPILEN 401"
  },
  {
    SERIAL: "KM140000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "VITPILEN 701",
    label: "VITPILEN 701",
    value: "VITPILEN 701"
  },
  {
    SERIAL: "KM250000026",
    MAKE_NAME: "HUSQVARNA",
    MODEL_NAME: "TE125",
    label: "TE125",
    value: "TE125"
  },
  {
    SERIAL: "KN000000026",
    MAKE_NAME: "AM GENERAL",
    MODEL_NAME: "AM GENERAL",
    label: "AM GENERAL",
    value: "AM GENERAL"
  },
  {
    SERIAL: "KN010000026",
    MAKE_NAME: "AM GENERAL",
    MODEL_NAME: "AM GENERAL",
    label: "AM GENERAL",
    value: "AM GENERAL"
  },
  {
    SERIAL: "KO000000026",
    MAKE_NAME: "DEMON",
    MODEL_NAME: "DEMON",
    label: "DEMON",
    value: "DEMON"
  },
  {
    SERIAL: "KO010000026",
    MAKE_NAME: "DEMON",
    MODEL_NAME: "GPX",
    label: "GPX",
    value: "GPX"
  },
  {
    SERIAL: "KP000000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "GPX",
    label: "GPX",
    value: "GPX"
  },
  {
    SERIAL: "KP010000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "CR5",
    label: "CR5",
    value: "CR5"
  },
  {
    SERIAL: "KP020000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "125 CC",
    label: "125 CC",
    value: "125 CC"
  },
  {
    SERIAL: "KP030000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "OVER150 CC",
    label: "OVER150 CC",
    value: "OVER150 CC"
  },
  {
    SERIAL: "KP040000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "150 CC.",
    label: "150 CC.",
    value: "150 CC."
  },
  {
    SERIAL: "KP050000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "LEGEND 200",
    label: "LEGEND 200",
    value: "LEGEND 200"
  },
  {
    SERIAL: "KP060000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "DEMON 150GR",
    label: "DEMON 150GR",
    value: "DEMON 150GR"
  },
  {
    SERIAL: "KP070000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "DEMON 125",
    label: "DEMON 125",
    value: "DEMON 125"
  },
  {
    SERIAL: "KP080000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "RAPTOR 180",
    label: "RAPTOR 180",
    value: "RAPTOR 180"
  },
  {
    SERIAL: "KP090000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "MAD 300",
    label: "MAD 300",
    value: "MAD 300"
  },
  {
    SERIAL: "KP100000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "LEGEND 150",
    label: "LEGEND 150",
    value: "LEGEND 150"
  },
  {
    SERIAL: "KP110000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "DEMON-150GN",
    label: "DEMON-150GN",
    value: "DEMON-150GN"
  },
  {
    SERIAL: "KP120000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "DEMON GR200R",
    label: "DEMON GR200R",
    value: "DEMON GR200R"
  },
  {
    SERIAL: "KP130000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "RAZER 220",
    label: "RAZER 220",
    value: "RAZER 220"
  },
  {
    SERIAL: "KP220000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "LEGEND 250",
    label: "LEGEND 250",
    value: "LEGEND 250"
  },
  {
    SERIAL: "KP300000026",
    MAKE_NAME: "GPX",
    MODEL_NAME: "ROCK RACING",
    label: "ROCK RACING",
    value: "ROCK RACING"
  },
  {
    SERIAL: "KR000000026",
    MAKE_NAME: "DOOSAN",
    MODEL_NAME: "DOOSAN",
    label: "DOOSAN",
    value: "DOOSAN"
  },
  {
    SERIAL: "KR020000026",
    MAKE_NAME: "DOOSAN",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "KS000000026",
    MAKE_NAME: "BIMOTA",
    MODEL_NAME: "BIMOTA",
    label: "BIMOTA",
    value: "BIMOTA"
  },
  {
    SERIAL: "KT000000026",
    MAKE_NAME: "DEBERTI",
    MODEL_NAME: "DEBERTI 3.5",
    label: "DEBERTI 3.5",
    value: "DEBERTI 3.5"
  },
  {
    SERIAL: "KT010000026",
    MAKE_NAME: "DEBERTI",
    MODEL_NAME: "DEBERTI",
    label: "DEBERTI",
    value: "DEBERTI"
  },
  {
    SERIAL: "KU000000026",
    MAKE_NAME: "STALLIONS",
    MODEL_NAME: "STALLIONS",
    label: "STALLIONS",
    value: "STALLIONS"
  },
  {
    SERIAL: "KU010000026",
    MAKE_NAME: "STALLIONS",
    MODEL_NAME: "CENTAUR",
    label: "CENTAUR",
    value: "CENTAUR"
  },
  {
    SERIAL: "KU020000026",
    MAKE_NAME: "STALLIONS",
    MODEL_NAME: "CARINO",
    label: "CARINO",
    value: "CARINO"
  },
  {
    SERIAL: "KU030000026",
    MAKE_NAME: "STALLIONS",
    MODEL_NAME: "MAKINA 250 SP",
    label: "MAKINA 250 SP",
    value: "MAKINA 250 SP"
  },
  {
    SERIAL: "KU040000026",
    MAKE_NAME: "STALLIONS",
    MODEL_NAME: "MAKINA 150",
    label: "MAKINA 150",
    value: "MAKINA 150"
  },
  {
    SERIAL: "KU050000026",
    MAKE_NAME: "STALLIONS",
    MODEL_NAME: "CARINO 130",
    label: "CARINO 130",
    value: "CARINO 130"
  },
  {
    SERIAL: "KU060000026",
    MAKE_NAME: "STALLIONS",
    MODEL_NAME: "CENTAUR 400 SE",
    label: "CENTAUR 400 SE",
    value: "CENTAUR 400 SE"
  },
  {
    SERIAL: "KV000000026",
    MAKE_NAME: "LINGONG",
    MODEL_NAME: "LINGONG",
    label: "LINGONG",
    value: "LINGONG"
  },
  {
    SERIAL: "KV010000026",
    MAKE_NAME: "LINGONG",
    MODEL_NAME: "FORKLIFT",
    label: "FORKLIFT",
    value: "FORKLIFT"
  },
  {
    SERIAL: "KW000000026",
    MAKE_NAME: "SYM",
    MODEL_NAME: "SYM",
    label: "SYM",
    value: "SYM"
  },
  {
    SERIAL: "KW010000026",
    MAKE_NAME: "SYM",
    MODEL_NAME: "OVER 150 CC",
    label: "OVER 150 CC",
    value: "OVER 150 CC"
  },
  {
    SERIAL: "KW020000026",
    MAKE_NAME: "SYM",
    MODEL_NAME: "MAXSYM400I",
    label: "MAXSYM400I",
    value: "MAXSYM400I"
  },
  {
    SERIAL: "KX000000026",
    MAKE_NAME: "ROADTEX",
    MODEL_NAME: "ROADTEX",
    label: "ROADTEX",
    value: "ROADTEX"
  },
  {
    SERIAL: "KX010000026",
    MAKE_NAME: "ROADTEX",
    MODEL_NAME: "TRACTOR",
    label: "TRACTOR",
    value: "TRACTOR"
  },
  {
    SERIAL: "KY000000026",
    MAKE_NAME: "ZENOS",
    MODEL_NAME: "ZENOS",
    label: "ZENOS",
    value: "ZENOS"
  },
  {
    SERIAL: "KY010000026",
    MAKE_NAME: "ZENOS",
    MODEL_NAME: "E10",
    label: "E10",
    value: "E10"
  },
  {
    SERIAL: "KZ000000026",
    MAKE_NAME: "COMARTH",
    MODEL_NAME: "COMARTH",
    label: "COMARTH",
    value: "COMARTH"
  },
  {
    SERIAL: "KZ010000026",
    MAKE_NAME: "COMARTH",
    MODEL_NAME: "2",
    label: "2",
    value: "2"
  },
  {
    SERIAL: "LA000000026",
    MAKE_NAME: "CALLILATER",
    MODEL_NAME: "CALLILATER",
    label: "CALLILATER",
    value: "CALLILATER"
  },
  {
    SERIAL: "LA010000026",
    MAKE_NAME: "CALLILATER",
    MODEL_NAME: "CAR",
    label: "CAR",
    value: "CAR"
  },
  {
    SERIAL: "LB000000026",
    MAKE_NAME: "JBC",
    MODEL_NAME: "JBC",
    label: "JBC",
    value: "JBC"
  },
  {
    SERIAL: "LB010000026",
    MAKE_NAME: "JBC",
    MODEL_NAME: "DUMPER TRUCK 6 WHEELS",
    label: "DUMPER TRUCK 6 WHEELS",
    value: "DUMPER TRUCK 6 WHEELS"
  },
  {
    SERIAL: "LB020000026",
    MAKE_NAME: "JBC",
    MODEL_NAME: "NORMAL TRUCK 6 WHEELS",
    label: "NORMAL TRUCK 6 WHEELS",
    value: "NORMAL TRUCK 6 WHEELS"
  },
  {
    SERIAL: "LC000000026",
    MAKE_NAME: "URAL",
    MODEL_NAME: "URAL",
    label: "URAL",
    value: "URAL"
  },
  {
    SERIAL: "LC010000026",
    MAKE_NAME: "URAL",
    MODEL_NAME: "RANGER",
    label: "RANGER",
    value: "RANGER"
  },
  {
    SERIAL: "LC030000026",
    MAKE_NAME: "URAL",
    MODEL_NAME: "SAHARA",
    label: "SAHARA",
    value: "SAHARA"
  },
  {
    SERIAL: "LC040000026",
    MAKE_NAME: "URAL",
    MODEL_NAME: "GEAR UP",
    label: "GEAR UP",
    value: "GEAR UP"
  },
  {
    SERIAL: "LD000000026",
    MAKE_NAME: "BCM",
    MODEL_NAME: "BCM",
    label: "BCM",
    value: "BCM"
  },
  {
    SERIAL: "LD010000026",
    MAKE_NAME: "BCM",
    MODEL_NAME: "BS1 RAJCHATHANI ONLY",
    label: "BS1 RAJCHATHANI ONLY",
    value: "BS1 RAJCHATHANI ONLY"
  },
  {
    SERIAL: "LD020000026",
    MAKE_NAME: "BCM",
    MODEL_NAME: "BT1 RAJCHATHANI ONLY",
    label: "BT1 RAJCHATHANI ONLY",
    value: "BT1 RAJCHATHANI ONLY"
  },
  {
    SERIAL: "LD030000026",
    MAKE_NAME: "BCM",
    MODEL_NAME: "BS2 RAJCHATHANI ONLY",
    label: "BS2 RAJCHATHANI ONLY",
    value: "BS2 RAJCHATHANI ONLY"
  },
  {
    SERIAL: "LD040000026",
    MAKE_NAME: "BCM",
    MODEL_NAME: "BS2S RAJCHATHANI ONLY",
    label: "BS2S RAJCHATHANI ONLY",
    value: "BS2S RAJCHATHANI ONLY"
  },
  {
    SERIAL: "LE000000026",
    MAKE_NAME: "WIRTGEN",
    MODEL_NAME: "WIRTGEN",
    label: "WIRTGEN",
    value: "WIRTGEN"
  },
  {
    SERIAL: "LE010000026",
    MAKE_NAME: "WIRTGEN",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "LE020000026",
    MAKE_NAME: "WIRTGEN",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "LF000000026",
    MAKE_NAME: "HANTA",
    MODEL_NAME: "HANTA",
    label: "HANTA",
    value: "HANTA"
  },
  {
    SERIAL: "LF010000026",
    MAKE_NAME: "HANTA",
    MODEL_NAME: "6000",
    label: "6000",
    value: "6000"
  },
  {
    SERIAL: "LG000000026",
    MAKE_NAME: "NORTON",
    MODEL_NAME: "NORTON",
    label: "NORTON",
    value: "NORTON"
  },
  {
    SERIAL: "LG010000026",
    MAKE_NAME: "NORTON",
    MODEL_NAME: "COMMANDO",
    label: "COMMANDO",
    value: "COMMANDO"
  },
  {
    SERIAL: "LH000000026",
    MAKE_NAME: "BOBBER",
    MODEL_NAME: "BOBBER",
    label: "BOBBER",
    value: "BOBBER"
  },
  {
    SERIAL: "LH010000026",
    MAKE_NAME: "BOBBER",
    MODEL_NAME: "BOBBER COVER 2 ONLY",
    label: "BOBBER COVER 2 ONLY",
    value: "BOBBER COVER 2 ONLY"
  },
  {
    SERIAL: "LI000000026",
    MAKE_NAME: "SHANTUI",
    MODEL_NAME: "SHANTUI",
    label: "SHANTUI",
    value: "SHANTUI"
  },
  {
    SERIAL: "LI010000026",
    MAKE_NAME: "SHANTUI",
    MODEL_NAME: "BULLDOZER SD16",
    label: "BULLDOZER SD16",
    value: "BULLDOZER SD16"
  },
  {
    SERIAL: "LI020000026",
    MAKE_NAME: "SHANTUI",
    MODEL_NAME: "SL50W-3",
    label: "SL50W-3",
    value: "SL50W-3"
  },
  {
    SERIAL: "LJ000000026",
    MAKE_NAME: "ALLARD",
    MODEL_NAME: "ALLAD",
    label: "ALLAD",
    value: "ALLAD"
  },
  {
    SERIAL: "LJ010000026",
    MAKE_NAME: "ALLARD",
    MODEL_NAME: "ALLARD",
    label: "ALLARD",
    value: "ALLARD"
  },
  {
    SERIAL: "LK000000026",
    MAKE_NAME: "JAC",
    MODEL_NAME: "JAC",
    label: "JAC",
    value: "JAC"
  },
  {
    SERIAL: "LK010000026",
    MAKE_NAME: "JAC",
    MODEL_NAME: "N 43",
    label: "N 43",
    value: "N 43"
  },
  {
    SERIAL: "LK030000026",
    MAKE_NAME: "JAC",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "LK040000026",
    MAKE_NAME: "JAC",
    MODEL_NAME: "HFC1061K",
    label: "HFC1061K",
    value: "HFC1061K"
  },
  {
    SERIAL: "LK060000026",
    MAKE_NAME: "JAC",
    MODEL_NAME: "JAC 1042",
    label: "JAC 1042",
    value: "JAC 1042"
  },
  {
    SERIAL: "LM000000026",
    MAKE_NAME: "JANSSEN",
    MODEL_NAME: "JANSSEN",
    label: "JANSSEN",
    value: "JANSSEN"
  },
  {
    SERIAL: "LN000000026",
    MAKE_NAME: "SMITH MOTORSPORT",
    MODEL_NAME: "SMITH MOTORSPORT",
    label: "SMITH MOTORSPORT",
    value: "SMITH MOTORSPORT"
  },
  {
    SERIAL: "LN010000026",
    MAKE_NAME: "SMITH MOTORSPORT",
    MODEL_NAME: "UTV 4.5",
    label: "UTV 4.5",
    value: "UTV 4.5"
  },
  {
    SERIAL: "LO000000026",
    MAKE_NAME: "ABG",
    MODEL_NAME: "ABG",
    label: "ABG",
    value: "ABG"
  },
  {
    SERIAL: "LO010000026",
    MAKE_NAME: "ABG",
    MODEL_NAME: "TITAN",
    label: "TITAN",
    value: "TITAN"
  },
  {
    SERIAL: "LP000000026",
    MAKE_NAME: "THAINA",
    MODEL_NAME: "THAINA",
    label: "THAINA",
    value: "THAINA"
  },
  {
    SERIAL: "LP010000026",
    MAKE_NAME: "THAINA",
    MODEL_NAME: "CR6",
    label: "CR6",
    value: "CR6"
  },
  {
    SERIAL: "LR000000026",
    MAKE_NAME: "SPCN",
    MODEL_NAME: "LAMBRETTA",
    label: "LAMBRETTA",
    value: "LAMBRETTA"
  },
  {
    SERIAL: "LR010000026",
    MAKE_NAME: "SPCN",
    MODEL_NAME: "150 CC",
    label: "150 CC",
    value: "150 CC"
  },
  {
    SERIAL: "LR020000026",
    MAKE_NAME: "SPCN",
    MODEL_NAME: "CUSTOM NOT COVER 01",
    label: "CUSTOM NOT COVER 01",
    value: "CUSTOM NOT COVER 01"
  },
  {
    SERIAL: "LS000000026",
    MAKE_NAME: "VELOCETTE",
    MODEL_NAME: "VELOCETTE",
    label: "VELOCETTE",
    value: "VELOCETTE"
  },
  {
    SERIAL: "LS010000026",
    MAKE_NAME: "VELOCETTE",
    MODEL_NAME: "400 CC",
    label: "400 CC",
    value: "400 CC"
  },
  {
    SERIAL: "LT000000026",
    MAKE_NAME: "VINCENET",
    MODEL_NAME: "VINCENET",
    label: "VINCENET",
    value: "VINCENET"
  },
  {
    SERIAL: "LT010000026",
    MAKE_NAME: "VINCENET",
    MODEL_NAME: "1000 CC",
    label: "1000 CC",
    value: "1000 CC"
  },
  {
    SERIAL: "LV000000026",
    MAKE_NAME: "PANTHER",
    MODEL_NAME: "PANTHER",
    label: "PANTHER",
    value: "PANTHER"
  },
  {
    SERIAL: "LV010000026",
    MAKE_NAME: "PANTHER",
    MODEL_NAME: "600 CC",
    label: "600 CC",
    value: "600 CC"
  },
  {
    SERIAL: "LW000000026",
    MAKE_NAME: "ZONGSHINE",
    MODEL_NAME: "ZONGSHINE",
    label: "ZONGSHINE",
    value: "ZONGSHINE"
  },
  {
    SERIAL: "LW010000026",
    MAKE_NAME: "ZONGSHINE",
    MODEL_NAME: "ZS125",
    label: "ZS125",
    value: "ZS125"
  },
  {
    SERIAL: "LX000000026",
    MAKE_NAME: "MOTO PARILLA",
    MODEL_NAME: "MOTO PARILLA",
    label: "MOTO PARILLA",
    value: "MOTO PARILLA"
  },
  {
    SERIAL: "LX010000026",
    MAKE_NAME: "MOTO PARILLA",
    MODEL_NAME: "150",
    label: "150",
    value: "150"
  },
  {
    SERIAL: "LY000000026",
    MAKE_NAME: "LIEBHERR",
    MODEL_NAME: "LIEBHERR",
    label: "LIEBHERR",
    value: "LIEBHERR"
  },
  {
    SERIAL: "LY010000026",
    MAKE_NAME: "LIEBHERR",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "LY020000026",
    MAKE_NAME: "LIEBHERR",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "LZ000000026",
    MAKE_NAME: "RELY",
    MODEL_NAME: "RELY",
    label: "RELY",
    value: "RELY"
  },
  {
    SERIAL: "LZ010000026",
    MAKE_NAME: "RELY",
    MODEL_NAME: "Q22",
    label: "Q22",
    value: "Q22"
  },
  {
    SERIAL: "MA000000026",
    MAKE_NAME: "HANWAY",
    MODEL_NAME: "HANWAY",
    label: "HANWAY",
    value: "HANWAY"
  },
  {
    SERIAL: "MA010000026",
    MAKE_NAME: "HANWAY",
    MODEL_NAME: "125",
    label: "125",
    value: "125"
  },
  {
    SERIAL: "MA020000026",
    MAKE_NAME: "HANWAY",
    MODEL_NAME: "150",
    label: "150",
    value: "150"
  },
  {
    SERIAL: "MB000000026",
    MAKE_NAME: "DOUZ",
    MODEL_NAME: "DOUZ",
    label: "DOUZ",
    value: "DOUZ"
  },
  {
    SERIAL: "MB010000026",
    MAKE_NAME: "DOUZ",
    MODEL_NAME: "OVER 12000",
    label: "OVER 12000",
    value: "OVER 12000"
  },
  {
    SERIAL: "MC000000026",
    MAKE_NAME: "RABA",
    MODEL_NAME: "RABA",
    label: "RABA",
    value: "RABA"
  },
  {
    SERIAL: "MC010000026",
    MAKE_NAME: "RABA",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "MD000000026",
    MAKE_NAME: "NKT",
    MODEL_NAME: "NKT",
    label: "NKT",
    value: "NKT"
  },
  {
    SERIAL: "MD010000026",
    MAKE_NAME: "NKT",
    MODEL_NAME: "GO125",
    label: "GO125",
    value: "GO125"
  },
  {
    SERIAL: "ME000000026",
    MAKE_NAME: "VIBRATING ROLLER",
    MODEL_NAME: "VIBRATING ROLLER",
    label: "VIBRATING ROLLER",
    value: "VIBRATING ROLLER"
  },
  {
    SERIAL: "ME010000026",
    MAKE_NAME: "VIBRATING ROLLER",
    MODEL_NAME: "SW40",
    label: "SW40",
    value: "SW40"
  },
  {
    SERIAL: "MF000000026",
    MAKE_NAME: "SANIT",
    MODEL_NAME: "STD 3 WHEEL",
    label: "STD 3 WHEEL",
    value: "STD 3 WHEEL"
  },
  {
    SERIAL: "MF010000026",
    MAKE_NAME: "SANIT",
    MODEL_NAME: "STD 3 WHEEL",
    label: "STD 3 WHEEL",
    value: "STD 3 WHEEL"
  },
  {
    SERIAL: "MG010000026",
    MAKE_NAME: "DENWAY",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "MH000000026",
    MAKE_NAME: "ISEKI",
    MODEL_NAME: "ISEKI",
    label: "ISEKI",
    value: "ISEKI"
  },
  {
    SERIAL: "MH010000026",
    MAKE_NAME: "ISEKI",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "MI000000026",
    MAKE_NAME: "FARM MAC",
    MODEL_NAME: "FARM MAC",
    label: "FARM MAC",
    value: "FARM MAC"
  },
  {
    SERIAL: "MI010000026",
    MAKE_NAME: "FARM MAC",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "MK010000026",
    MAKE_NAME: "SKYWELL",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "MO000000026",
    MAKE_NAME: "CONFEDERATE",
    MODEL_NAME: "CONFEDERATE",
    label: "CONFEDERATE",
    value: "CONFEDERATE"
  },
  {
    SERIAL: "MO010000026",
    MAKE_NAME: "CONFEDERATE",
    MODEL_NAME: "X132",
    label: "X132",
    value: "X132"
  },
  {
    SERIAL: "MP000000026",
    MAKE_NAME: "CFMOTO",
    MODEL_NAME: "CFMOTO",
    label: "CFMOTO",
    value: "CFMOTO"
  },
  {
    SERIAL: "MP010000026",
    MAKE_NAME: "CFMOTO",
    MODEL_NAME: "250 NK",
    label: "250 NK",
    value: "250 NK"
  },
  {
    SERIAL: "MP020000026",
    MAKE_NAME: "CFMOTO",
    MODEL_NAME: "650 MT TOURING",
    label: "650 MT TOURING",
    value: "650 MT TOURING"
  },
  {
    SERIAL: "MQ000000026",
    MAKE_NAME: "HUMBER",
    MODEL_NAME: "HUMMER",
    label: "HUMMER",
    value: "HUMMER"
  },
  {
    SERIAL: "MQ010000026",
    MAKE_NAME: "HUMBER",
    MODEL_NAME: "SEDAN",
    label: "SEDAN",
    value: "SEDAN"
  },
  {
    SERIAL: "MR000000026",
    MAKE_NAME: "NIU",
    MODEL_NAME: "NIU",
    label: "NIU",
    value: "NIU"
  },
  {
    SERIAL: "MR010000026",
    MAKE_NAME: "NIU",
    MODEL_NAME: "N1S",
    label: "N1S",
    value: "N1S"
  },
  {
    SERIAL: "MR020000026",
    MAKE_NAME: "NIU",
    MODEL_NAME: "ELECTRIC SCOOTER",
    label: "ELECTRIC SCOOTER",
    value: "ELECTRIC SCOOTER"
  },
  {
    SERIAL: "MS010000026",
    MAKE_NAME: "LEABON",
    MODEL_NAME: "LNE5000 - HOTEL",
    label: "LNE5000 - HOTEL",
    value: "LNE5000 - HOTEL"
  },
  {
    SERIAL: "MT000000026",
    MAKE_NAME: "STAR8-V",
    MODEL_NAME: "STAR-V",
    label: "STAR-V",
    value: "STAR-V"
  },
  {
    SERIAL: "MT010000026",
    MAKE_NAME: "STAR8-V",
    MODEL_NAME: "JUNO",
    label: "JUNO",
    value: "JUNO"
  },
  {
    SERIAL: "MU000000026",
    MAKE_NAME: "CHANGLIN",
    MODEL_NAME: "CHANGLIN",
    label: "CHANGLIN",
    value: "CHANGLIN"
  },
  {
    SERIAL: "MU010000026",
    MAKE_NAME: "CHANGLIN",
    MODEL_NAME: "TRACKTOR",
    label: "TRACKTOR",
    value: "TRACKTOR"
  },
  {
    SERIAL: "MU020000026",
    MAKE_NAME: "CHANGLIN",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "MV000000026",
    MAKE_NAME: "TCM",
    MODEL_NAME: "TCM",
    label: "TCM",
    value: "TCM"
  },
  {
    SERIAL: "MV010000026",
    MAKE_NAME: "TCM",
    MODEL_NAME: "FORKLIFT",
    label: "FORKLIFT",
    value: "FORKLIFT"
  },
  {
    SERIAL: "MW000000026",
    MAKE_NAME: "ANYUAN",
    MODEL_NAME: "ANYUAN",
    label: "ANYUAN",
    value: "ANYUAN"
  },
  {
    SERIAL: "MW010000026",
    MAKE_NAME: "ANYUAN",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "MX010000026",
    MAKE_NAME: "DKW",
    MODEL_NAME: "SEDAN",
    label: "SEDAN",
    value: "SEDAN"
  },
  {
    SERIAL: "MY000000026",
    MAKE_NAME: "ECOOTER",
    MODEL_NAME: "ECOOTER",
    label: "ECOOTER",
    value: "ECOOTER"
  },
  {
    SERIAL: "MY010000026",
    MAKE_NAME: "ECOOTER",
    MODEL_NAME: "ELONE",
    label: "ELONE",
    value: "ELONE"
  },
  {
    SERIAL: "MZ000000026",
    MAKE_NAME: "GROVE",
    MODEL_NAME: "GROVE",
    label: "GROVE",
    value: "GROVE"
  },
  {
    SERIAL: "MZ010000026",
    MAKE_NAME: "GROVE",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "NA000000026",
    MAKE_NAME: "ROYAL ALLOY",
    MODEL_NAME: "ROYAL ALLOY",
    label: "ROYAL ALLOY",
    value: "ROYAL ALLOY"
  },
  {
    SERIAL: "NA010000026",
    MAKE_NAME: "ROYAL ALLOY",
    MODEL_NAME: "GP 150",
    label: "GP 150",
    value: "GP 150"
  },
  {
    SERIAL: "NA020000026",
    MAKE_NAME: "ROYAL ALLOY",
    MODEL_NAME: "GP 200S",
    label: "GP 200S",
    value: "GP 200S"
  },
  {
    SERIAL: "NB000000026",
    MAKE_NAME: "DECO",
    MODEL_NAME: "DECO",
    label: "DECO",
    value: "DECO"
  },
  {
    SERIAL: "NB010000026",
    MAKE_NAME: "DECO",
    MODEL_NAME: "ELECTRIC MOTORCYCLE",
    label: "ELECTRIC MOTORCYCLE",
    value: "ELECTRIC MOTORCYCLE"
  },
  {
    SERIAL: "NC000000026",
    MAKE_NAME: "ANKAI",
    MODEL_NAME: "ANKAI",
    label: "ANKAI",
    value: "ANKAI"
  },
  {
    SERIAL: "NC010000026",
    MAKE_NAME: "ANKAI",
    MODEL_NAME: "HFF 6606K",
    label: "HFF 6606K",
    value: "HFF 6606K"
  },
  {
    SERIAL: "NC020000026",
    MAKE_NAME: "ANKAI",
    MODEL_NAME: "HFF 6700K",
    label: "HFF 6700K",
    value: "HFF 6700K"
  },
  {
    SERIAL: "ND010000026",
    MAKE_NAME: "OLDSCHOOL",
    MODEL_NAME: "TIME BANDIT CLASSIC 47 COVER 2",
    label: "TIME BANDIT CLASSIC 47 COVER 2",
    value: "TIME BANDIT CLASSIC 47 COVER 2"
  },
  {
    SERIAL: "NE010000026",
    MAKE_NAME: "THACO",
    MODEL_NAME: "BUS 30 SEAT",
    label: "BUS 30 SEAT",
    value: "BUS 30 SEAT"
  },
  {
    SERIAL: "NE020000026",
    MAKE_NAME: "THACO",
    MODEL_NAME: "BUS OVER 40 SEAT",
    label: "BUS OVER 40 SEAT",
    value: "BUS OVER 40 SEAT"
  },
  {
    SERIAL: "NF000000026",
    MAKE_NAME: "ZHONGTHONG",
    MODEL_NAME: "ZHONGTHONG",
    label: "ZHONGTHONG",
    value: "ZHONGTHONG"
  },
  {
    SERIAL: "NF010000026",
    MAKE_NAME: "ZHONGTHONG",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "NG000000026",
    MAKE_NAME: "PEDA",
    MODEL_NAME: "PEDA",
    label: "PEDA",
    value: "PEDA"
  },
  {
    SERIAL: "NG010000026",
    MAKE_NAME: "PEDA",
    MODEL_NAME: "ADVENTURE",
    label: "ADVENTURE",
    value: "ADVENTURE"
  },
  {
    SERIAL: "NI000000026",
    MAKE_NAME: "SBY",
    MODEL_NAME: "SBY",
    label: "SBY",
    value: "SBY"
  },
  {
    SERIAL: "NI010000026",
    MAKE_NAME: "SBY",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "NJ000000026",
    MAKE_NAME: "ULTIMA",
    MODEL_NAME: "ULTIMA",
    label: "ULTIMA",
    value: "ULTIMA"
  },
  {
    SERIAL: "NJ010000026",
    MAKE_NAME: "ULTIMA",
    MODEL_NAME: "UTILITY",
    label: "UTILITY",
    value: "UTILITY"
  },
  {
    SERIAL: "NK000000026",
    MAKE_NAME: "U-EINFLY",
    MODEL_NAME: "U-EINFLY",
    label: "U-EINFLY",
    value: "U-EINFLY"
  },
  {
    SERIAL: "NK010000026",
    MAKE_NAME: "U-EINFLY",
    MODEL_NAME: "BLUE WHALE",
    label: "BLUE WHALE",
    value: "BLUE WHALE"
  },
  {
    SERIAL: "NL010000026",
    MAKE_NAME: "SEV",
    MODEL_NAME: "CANDY",
    label: "CANDY",
    value: "CANDY"
  },
  {
    SERIAL: "NM000000026",
    MAKE_NAME: "SONTES",
    MODEL_NAME: "SONTES",
    label: "SONTES",
    value: "SONTES"
  },
  {
    SERIAL: "NM010000026",
    MAKE_NAME: "SONTES",
    MODEL_NAME: "X310",
    label: "X310",
    value: "X310"
  },
  {
    SERIAL: "NN000000026",
    MAKE_NAME: "LVNENG",
    MODEL_NAME: "LVNENG",
    label: "LVNENG",
    value: "LVNENG"
  },
  {
    SERIAL: "NN010000026",
    MAKE_NAME: "LVNENG",
    MODEL_NAME: "SWANG TYPE-S",
    label: "SWANG TYPE-S",
    value: "SWANG TYPE-S"
  },
  {
    SERIAL: "NO000000026",
    MAKE_NAME: "STEYR",
    MODEL_NAME: "STEYR",
    label: "STEYR",
    value: "STEYR"
  },
  {
    SERIAL: "NO010000026",
    MAKE_NAME: "STEYR",
    MODEL_NAME: "BUS",
    label: "BUS",
    value: "BUS"
  },
  {
    SERIAL: "NP000000026",
    MAKE_NAME: "FOMM",
    MODEL_NAME: "FOMM",
    label: "FOMM",
    value: "FOMM"
  },
  {
    SERIAL: "NP010000026",
    MAKE_NAME: "FOMM",
    MODEL_NAME: "ONE EV",
    label: "ONE EV",
    value: "ONE EV"
  },
  {
    SERIAL: "NQ000000026",
    MAKE_NAME: "HIDROMEX",
    MODEL_NAME: "HIDROMEX",
    label: "HIDROMEX",
    value: "HIDROMEX"
  },
  {
    SERIAL: "NQ010000026",
    MAKE_NAME: "HIDROMEX",
    MODEL_NAME: "TRUCK",
    label: "TRUCK",
    value: "TRUCK"
  },
  {
    SERIAL: "NR000000026",
    MAKE_NAME: "SIAM",
    MODEL_NAME: "SIAM",
    label: "SIAM",
    value: "SIAM"
  },
  {
    SERIAL: "NR010000026",
    MAKE_NAME: "SIAM",
    MODEL_NAME: "TREE WHEEL",
    label: "TREE WHEEL",
    value: "TREE WHEEL"
  },
  {
    SERIAL: "NS010000026",
    MAKE_NAME: "EXCALIBUR",
    MODEL_NAME: "4500 CC",
    label: "4500 CC",
    value: "4500 CC"
  }
]

const carYear = []

const getCarYear = () => {
  const currenDate = new Date().getFullYear()
  var i;
  for (i = currenDate - 40; i < currenDate + 1; i++) {
    carYear.push({ TH_YEAR: i + 543, EN_YEAR: i, label: `${i} | ${i + 543}`, value: `${i} | ${i + 543}` })
  }
  carYear.sort((a, b) => (a.EN_YEAR > b.EN_YEAR) ? -1 : 1)
}

getCarYear()


export { carMake, carModel, carYear }
import { storage, db } from '../../firebase/config'
import React, { useState, Component, PureComponent } from 'react';
import { Link, useParams } from "react-router-dom";
import { RadialBarChart, RadialBar, Legend, Tooltip, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BackgroundSlider from "react-background-slider";
import CountUp from "react-countup";

//Import Icons
import FeatherIcon from 'feather-icons-react';
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';


// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';


// Import Cards
import VibCard from '../../shared/images/cards/TVinsure-Covid-Card-Vib.jpg';
import AsiaCard from '../../shared/images/cards/TVinsure-Covid-Card-Asia.jpg';
import DhpCard from '../../shared/images/cards/TVinsure-Covid-Card-Dhp.jpg';
import SmkCard from '../../shared/images/cards/TVinsure-Covid-Card-Smk.jpg';
import SeiCard from '../../shared/images/cards/TVinsure-Covid-Card-Sei.jpg';

// Import Partners Img
import prh9logo from '../../shared/images/partners/PRH9-Logo.jpg';
import aswlogo from '../../shared/images/partners/ASW_LOGO.png';


import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { planCovidNewList } from '../../shared/masters/refData';


import './GetCovidIdSearch.css'
import { LocalFlorist } from '@material-ui/icons';

import CovidClaimDoc from '../components/CovidClaimDoc'

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]


const data = [
    {
        name: 'ความคุ้มครองทั้งหมด (จำนวนเงินรวม)',
        PLAN_DIAGNOSTIC_PAY_AMT: 0,
        PLAN_HOSPITAL_PAY_AMT: 0,
        PLAN_COMA_PAY_AMT: 0,
        PLAN_COMPENSTAION_PER_DAY_PAY_AMT: 0,
        PLAN_COMPENSTAION_TOTAL_AMT: 0,
        PLAN_VACCINE_HOSPITAL_PAY_AMT: 0,
        PLAN_VACCINE_COMA_PAY_AMT: 0,
        PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: 0,
        PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: 0
    }
];


const GetPolicy = () => {

    const partnerId = useParams().partnerid;
    const size = useWindowSize();

    const [searchData, setSearchData] = useState();
    const [searchCoverageScoreData, setSearchCoverageScoreData] = useState();
    const [searchCoverageEachData, setSearchCoverageEachData] = useState();
    const [formData, setFormData] = useState({
        idNum: "",
        idCheck: "uncheck"
    });

    const [vibCoverNoteUrl, setVibCoverNoteUrl] = useState("");
    const [policyUrlArray, setpolicyUrlArray] = useState([]);

    // console.log(covidAppData)
    // console.log(formData)

    const handleChange = event => {
        const { name, value } = event.target

        setFormData(prevValue => {
            return {
                ...prevValue,
                idCheck: "uncheck",
                [name]: value.replace(/-/g, "")
            }
        });
    }

    // VIB URL Covernote by id
    function onResolveCovernote(foundURL) {
        // console.log(foundURL);
        setVibCoverNoteUrl(foundURL);
    }

    function onRejectCovernote(error) {
        // console.log(error);
        setVibCoverNoteUrl("");
    }

    function OnRecheckCovernote() {
        storage.ref("covernotefile").child(`${formData.idNum}.PDF`).getDownloadURL().then(onResolveCovernote, onRejectCovernote);
    }

    // console.log(searchData)
    console.log(policyUrlArray)

    const handleClick = () => {
        db.collection("covidApplicationFormGroup").where("ID_CARD", "==", Number(formData.idNum)).where("GROUP_ID", "==", partnerId).get()
            .then((snapshot) => {
                if (snapshot.docs.length > 0) {
                    storage.ref("covernotefile").child(`${formData.idNum}.pdf`).getDownloadURL().then(onResolveCovernote, OnRecheckCovernote);
                    setFormData(prevValue => {
                        return {
                            ...prevValue,
                            idCheck: "check"
                        }
                    });
                    let CovidAppById = []
                    snapshot.docs.forEach(doc => {
                        storage.ref("policyfile").child(`${doc.data().POLICY_NUMBER.replace(/\//g, "")}.pdf`).getDownloadURL().then((url) => {
                            const policyFoundObject = {
                                policyNum: doc.data().POLICY_NUMBER,
                                policyUrl: url
                            }
                            setpolicyUrlArray([...policyUrlArray, policyFoundObject]);
                        }).catch((error) => {
                            const policyFoundObject = {
                                policyNum: doc.data().POLICY_NUMBER,
                                policyUrl: ""
                            }
                            setpolicyUrlArray([...policyUrlArray, policyFoundObject]);
                        });
                        CovidAppById.push(doc.data());
                    })

                    const CovidAppFilter = CovidAppById.filter(a => a.APP_FORM_PAYMENT_STATUS !== "INITIALIZE").filter(a => a.APP_FORM_PAYMENT_STATUS !== "CANCEL")
                    // console.log(CovidAppFilter)
                    setSearchData(CovidAppFilter);
                    let covidTotalCoverageSum = [
                        {
                            subjectId: "PLAN_DIAGNOSTIC_PAY_AMT",
                            subject: "เจอ จ่าย(ติดเชื้อ)",
                            Total: 0,
                            fullMark: 100
                        },
                        {
                            subjectId: "PLAN_HOSPITAL_PAY_AMT",
                            subject: "ค่ารักษา(ติดเชื้อ)",
                            Total: 0,
                            fullMark: 100
                        },
                        {
                            subjectId: "PLAN_COMA_PAY_AMT",
                            subject: "โคม่า(ติดเชื้อ)",
                            Total: 0,
                            fullMark: 100
                        },
                        {
                            subjectId: "PLAN_COMPENSTAION_TOTAL_AMT",
                            subject: "เงินชดเชย(ติดเชื้อ)",
                            Total: 0,
                            fullMark: 100
                        },
                        {
                            subjectId: "PLAN_VACCINE_HOSPITAL_AND_COMPENSTAION_PAY_AMT",
                            subject: "ค่ารักษา/ชดเชย(แพ้วัคซีน)",
                            Total: 0,
                            fullMark: 100
                        },
                        {
                            subjectId: "PLAN_VACCINE_COMA_PAY_AMT",
                            subject: "โคม่าจาก(แพ้วัคซีน)",
                            Total: 0,
                            fullMark: 100
                        }
                    ]
                    CovidAppFilter.forEach(doc => {
                        // console.log(doc.PLAN_ID)
                        // console.log(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_DIAGNOSTIC_PAY_AMT)
                        covidTotalCoverageSum.find(arr => arr.subjectId === "PLAN_DIAGNOSTIC_PAY_AMT").Total += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_DIAGNOSTIC_PAY_AMT) / 410000 * 100;
                        covidTotalCoverageSum.find(arr => arr.subjectId === "PLAN_HOSPITAL_PAY_AMT").Total += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_HOSPITAL_PAY_AMT) / 340000 * 100;
                        covidTotalCoverageSum.find(arr => arr.subjectId === "PLAN_COMA_PAY_AMT").Total += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_COMA_PAY_AMT) / 6000000 * 100;
                        covidTotalCoverageSum.find(arr => arr.subjectId === "PLAN_COMPENSTAION_TOTAL_AMT").Total += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_COMPENSTAION_TOTAL_AMT) / 226000 * 100;
                        covidTotalCoverageSum.find(arr => arr.subjectId === "PLAN_VACCINE_HOSPITAL_AND_COMPENSTAION_PAY_AMT").Total += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT) / 370000 * 100;
                        covidTotalCoverageSum.find(arr => arr.subjectId === "PLAN_VACCINE_HOSPITAL_AND_COMPENSTAION_PAY_AMT").Total += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT) / 370000 * 100;
                        covidTotalCoverageSum.find(arr => arr.subjectId === "PLAN_VACCINE_COMA_PAY_AMT").Total += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_VACCINE_COMA_PAY_AMT) / 7200000 * 100;
                        // console.log(covidTotalCoverageSum)
                    })
                    setSearchCoverageScoreData(covidTotalCoverageSum);

                    let coverageEach = [
                        {
                            name: 'ความคุ้มครองทั้งหมด (จำนวนเงินรวม)',
                            PLAN_DIAGNOSTIC_PAY_AMT: 0,
                            PLAN_HOSPITAL_PAY_AMT: 0,
                            PLAN_COMA_PAY_AMT: 0,
                            PLAN_COMPENSTAION_PER_DAY_PAY_AMT: 0,
                            PLAN_COMPENSTAION_TOTAL_AMT: 0,
                            PLAN_VACCINE_HOSPITAL_PAY_AMT: 0,
                            PLAN_VACCINE_COMA_PAY_AMT: 0,
                            PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT: 0,
                            PLAN_VACCINE_COMPENSTAION_TOTAL_AMT: 0,
                            PLAN_SEARCH_OTHER: 0
                        }
                    ];
                    CovidAppFilter.forEach(doc => {
                        coverageEach[0].PLAN_DIAGNOSTIC_PAY_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_DIAGNOSTIC_PAY_AMT);
                        coverageEach[0].PLAN_HOSPITAL_PAY_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_HOSPITAL_PAY_AMT);
                        coverageEach[0].PLAN_COMA_PAY_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_COMA_PAY_AMT);
                        coverageEach[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT);
                        coverageEach[0].PLAN_COMPENSTAION_TOTAL_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_COMPENSTAION_TOTAL_AMT);
                        coverageEach[0].PLAN_VACCINE_HOSPITAL_PAY_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT);
                        coverageEach[0].PLAN_VACCINE_COMA_PAY_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_VACCINE_COMA_PAY_AMT);
                        coverageEach[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT);
                        coverageEach[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT);
                        coverageEach[0].PLAN_SEARCH_OTHER += Number(planCovidNewList.filter(pf => pf.PLAN_ID === doc.PLAN_ID)[0].PLAN_SEARCH_OTHER);
                    })
                    setSearchCoverageEachData(coverageEach);
                }
                if (snapshot.docs.length === 0) {
                    setFormData(prevValue => {
                        return {
                            ...prevValue,
                            idCheck: "notFound"
                        }
                    });
                }
            })
            .catch((error) => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        idCheck: "notFound",
                    }
                });
            });
    }

    // console.log(formData);
    // console.log(vibCoverNoteUrl);

    return (
        <React.Fragment>
            <div className="back-to-home rounded d-none d-sm-block">
                <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                    <i>
                        <FeatherIcon icon="home" className="icons" />
                    </i>
                </a>
            </div>
            <section className="bg-half-80 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-3">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                            </div>
                            <div className="title-heading text-center">
                                <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                    เช็คความคุ้มครองประกันภัยไวรัสโคโรนา
                                </h4>
                                <p className="text-center text-white h5 mt-1 mb-5">สำหรับการสมัครประกันภัยโคโรนาไวรัสใหม่</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
            <section style={{ margin: "-110px 0 0" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>
                            <Card>
                                <CardBody className="shadow-lg">
                                    <h4 className="text-center mt-4 mb-3">เช็คความคุ้มครองของฉัน</h4>
                                    {/* Page For บริษัท โรงพยาบาลพระรามเก้า จำกัด (มหาชน) - PRH9 */}
                                    {partnerId === "PRH9" && <div className="text-center mb-3">
                                        <img src={prh9logo} height="120" alt="" />
                                        <h6 className="text-center h5"> สำหรับผู้เอาประกันจากบริษัท โรงพยาบาลพระรามเก้า จำกัด (มหาชน)</h6>
                                    </div>}
                                    {/* Page For บริษัท แอสเซทไวส์ จำกัด (มหาชน) - ASW */}
                                    {partnerId === "ASW" && <div className="text-center mb-3">
                                        <img src={aswlogo} height="150" alt="" />
                                        <h6 className="text-center h5"> สำหรับผู้เอาประกันจากบริษัท แอสเซทไวส์ จำกัด (มหาชน)</h6>
                                    </div>}

                                    <h6 className="text-center h6"> เพียงกรอกเลขบัตรประชาชนผู้เอาประกันภัย</h6>
                                    {/* <p className="text-center"> ชื่อสกุล ที่อยู่ วันเดือนปีเกิด สะกดผิดไม่เป็นไร เพียงแค่เลขบัตรประชาชนถูกต้อง ท่านได้รับความคุ้มครองแน่นอน</p> */}

                                    <AvForm className="login-form mt-4 mb-3">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลขบัตรประชาชนผู้เอาประกันภัย <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกเลขบัตรประชาชน"
                                                        validate={{ required: { value: true } }} placeholder="กรอกเลขบัตรประชาชน" name="idNum" onChange={handleChange} value={formData.idNum} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                    <Button className="mb-2" color={formData.idCheck === "uncheck" ? "danger" : formData.idCheck === "check" ? "success" : "danger"}
                                                        outline={formData.idCheck === "uncheck"} block
                                                        disabled={!formData.idNum}
                                                        onClick={handleClick}>{formData.idCheck === "uncheck" ? "เช็คความคุ้มครอง" : formData.idCheck === "check" ? "พบข้อมูลความคุ้มครอง" : "ไม่พบข้อมูลที่ค้นหา"}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            {formData.idCheck === "notFound" && <Col xs={12} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-danger mr-2 h6 mt-2">ไม่พบข้อมูลรายการที่ท่านค้นหา</small></p>
                                                <p className="mt-4 mb-3 text-left">* หากเกิดข้อผิดพลาด หรือ ไม่พบรายการท่านค้นหาสามารถติดต่อทีมงานได้ตามช่องทางด้านล่างเพื่อจัดส่งทางช่องทางอื่นเพิ่มเติม</p>
                                                <ul className="mt-1 text-left">
                                                    <li>Line: @tvinsure</li>
                                                    <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                                                </ul>
                                            </Col>}
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                            {searchData && searchCoverageScoreData && searchCoverageEachData && formData.idCheck === "check" && <Card className="mt-5 mb-0">
                                <CardBody className="shadow-lg">
                                    <Col xs={12} className="text-center">
                                        <p className="mt-3 mb-1 text-center h5">ภาพรวมความคุ้มครองประกันโควิด 19 (COVID-19)</p>
                                        <p className="mt-2 mb-1 text-center h5">สำหรับ {searchData[0].PRENAME_DESC} {searchData[0].FIRST_NAME} {searchData[0].LAST_NAME} </p>
                                        <p className="mt-2 mb-1 text-center">จำนวนกรมธรรม์ทั้งหมด {searchData.length} รายการ </p>
                                    </Col>
                                    {/* <Col xs={12} className="text-center">
                                        <p className="mt-5 mb-1 text-dark h6">คะแนนโดยรวมแต่ละด้านความคุ้มครอง (เต็ม 100 คะแนน)</p>
                                    </Col> */}
                                    {/* <RadarChart
                                        // outerRadius={size.width < 281 ? 10 : size.width < 999 ? 60 : 10}
                                        outerRadius={size.width < 999 ? 60 : 140}
                                        width={size.width < 700 ? size.width * 0.50 : size.width < 1024 ? size.width * 0.65 : 1000}
                                        height={400}
                                        data={searchCoverageScoreData}
                                        className="d-block mx-auto mt-2">
                                        <PolarGrid />
                                        <PolarAngleAxis dataKey="subject" />
                                        <PolarRadiusAxis angle={30} domain={[0, 100]} />
                                        <Radar name="ความคุ้มครองโดยรวม" dataKey="Total" stroke="#E81F5D" fill="#ffc107" fillOpacity={0.6} /> */}

                                    {/* <Radar name="Lily" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} /> */}
                                    {/* <Legend height={0} className="mt-5" /> */}

                                    {/* </RadarChart> */}

                                    {/* <Col xs={12} className="mt-4 pt-2">
                                        <Row id="counter">
                                            <div className="col-lg-3 col-md-12 mt-4">
                                                <Card className=" counter-box border-0 bg-primary shadow text-center rounded">
                                                    <CardBody className="py-5">
                                                        <h6 className="counter-head text-light title-dark mb-0">
                                                            เจอ จ่าย(ติดเชื้อ)
                                                        </h6>
                                                        <p className="mb-0 text-light title-dark">
                                                            <small className="counter-value" data-count="97">
                                                                <CountUp start={0} end={searchCoverageScoreData.filter(f => f.subjectId === "PLAN_DIAGNOSTIC_PAY_AMT")[0].Total} duration={8} />
                                                            </small>
                                                            /{searchCoverageScoreData.filter(f => f.subjectId === "PLAN_DIAGNOSTIC_PAY_AMT")[0].fullMark} คะแนน
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="col-lg-3 col-md-12 mt-4">
                                                <Card className=" counter-box border-0 shadow text-center rounded" style={{ backgroundColor: "#2eca8b" }}>
                                                    <CardBody className="py-5">
                                                        <h6 className="counter-head text-light title-dark mb-0">
                                                            ค่ารักษา(ติดเชื้อ)
                                                        </h6>
                                                        <p className="mb-0 text-light title-dark">
                                                            <small className="counter-value" data-count="97">
                                                                <CountUp start={0} end={searchCoverageScoreData.filter(f => f.subjectId === "PLAN_HOSPITAL_PAY_AMT")[0].Total} duration={8} />
                                                            /{searchCoverageScoreData.filter(f => f.subjectId === "PLAN_HOSPITAL_PAY_AMT")[0].fullMark} คะแนน
                                                            </small>
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="col-lg-3 col-md-12 mt-4">
                                                <Card className=" counter-box border-0 shadow text-center rounded" style={{ backgroundColor: "#e43f52" }}>
                                                    <CardBody className="py-5">
                                                        <h6 className="counter-head text-light title-dark mb-0">
                                                            โคม่า (ติดเชื้อ)
                                                        </h6>
                                                        <p className="mb-0 text-light title-dark">
                                                            <small className="counter-value" data-count="97">
                                                                <CountUp start={0} end={searchCoverageScoreData.filter(f => f.subjectId === "PLAN_COMA_PAY_AMT")[0].Total} duration={8} />
                                                            /{searchCoverageScoreData.filter(f => f.subjectId === "PLAN_COMA_PAY_AMT")[0].fullMark} คะแนน
                                                            </small>
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="col-lg-3 col-md-12 mt-4">
                                                <Card className=" counter-box border-0 shadow text-center rounded" style={{ backgroundColor: "#17a2b8" }}>
                                                    <CardBody className="py-5">
                                                        <h6 className="counter-head text-light title-dark mb-0">
                                                            ค่าชดเชย (ติดเชื้อ)
                                                        </h6>
                                                        <p className="mb-0 text-light title-dark">
                                                            <small className="counter-value" data-count="97">
                                                                <CountUp start={0} end={searchCoverageScoreData.filter(f => f.subjectId === "PLAN_COMPENSTAION_TOTAL_AMT")[0].Total} duration={8} />
                                                            /{searchCoverageScoreData.filter(f => f.subjectId === "PLAN_COMPENSTAION_TOTAL_AMT")[0].fullMark} คะแนน
                                                            </small>
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="col-lg-6 col-md-12 mt-4">
                                                <Card className=" counter-box border-0 shadow text-center rounded" style={{ backgroundColor: "#536DFE" }}>
                                                    <CardBody className="py-5">
                                                        <h6 className="counter-head text-light title-dark mb-0">
                                                            ค่ารักษา/ชดเชย (แพ้วัคซีน)
                                                        </h6>
                                                        <p className="mb-0 text-light title-dark">
                                                            <small className="counter-value" data-count="97">
                                                                <CountUp start={0} end={searchCoverageScoreData.filter(f => f.subjectId === "PLAN_VACCINE_HOSPITAL_AND_COMPENSTAION_PAY_AMT")[0].Total} duration={8} />
                                                            /{searchCoverageScoreData.filter(f => f.subjectId === "PLAN_VACCINE_HOSPITAL_AND_COMPENSTAION_PAY_AMT")[0].fullMark} คะแนน
                                                            </small>
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="col-lg-6 col-md-12 mt-4">
                                                <Card className=" counter-box border-0 shadow text-center rounded" style={{ backgroundColor: "#FF63C2" }}>
                                                    <CardBody className="py-5">
                                                        <h6 className="counter-head text-light title-dark mb-0">
                                                            โคม่า (แพ้วัคซีน)
                                                        </h6>
                                                        <p className="mb-0 text-light title-dark">
                                                            <small className="counter-value" data-count="97">
                                                                <CountUp start={0} end={searchCoverageScoreData.filter(f => f.subjectId === "PLAN_VACCINE_COMA_PAY_AMT")[0].Total} duration={8} />
                                                            /{searchCoverageScoreData.filter(f => f.subjectId === "PLAN_VACCINE_COMA_PAY_AMT")[0].fullMark} คะแนน
                                                            </small>
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </Row>
                                    </Col> */}

                                    <Col xs={12} className="text-center">
                                        <p className="mt-5 mb-5 text-dark h6">ยอดวงเงินความคุ้มครองประกันโควิดแยกตามประเภท</p>
                                    </Col>
                                    <BarChart
                                        width={size.width < 1024 ? size.width * 0.60 : 1000}
                                        height={400}
                                        data={searchCoverageEachData}
                                        className="d-block mx-auto mt-2"
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar name="เจอ จ่าย (ติดเชื้อ)" dataKey="PLAN_DIAGNOSTIC_PAY_AMT" fill="#E81F5D" />
                                        <Bar name="ค่ารักษา(ติดเชื้อ)" dataKey="PLAN_HOSPITAL_PAY_AMT" fill="#2eca8b" />
                                        <Bar name="โคม่า (ติดเชื้อ)" dataKey="PLAN_COMA_PAY_AMT" fill="#e43f52" />
                                        <Bar name="ค่าชดเชย/ต่อวัน (ติดเชื้อ)" dataKey="PLAN_COMPENSTAION_PER_DAY_PAY_AMT" fill="#00BFA6" />
                                        <Bar name="ค่าชดเชย (รวมสูงสุด)(ติดเชื้อ)" dataKey="PLAN_COMPENSTAION_TOTAL_AMT" fill="#17a2b8" />
                                        <Bar name="ค่ารักษา (แพ้วัคซีน)" dataKey="PLAN_VACCINE_HOSPITAL_PAY_AMT" fill="#536DFE" />
                                        <Bar name="โคม่า (แพ้วัคซีน)" dataKey="PLAN_VACCINE_COMA_PAY_AMT" fill="#FF63C2" />
                                        <Bar name="ค่าชดเชย/ต่อวัน (แพ้วัคซีน)" dataKey="PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT" fill="#EB00F5" />
                                        <Bar name="ค่าชดเชย (รวมสูงสุด) (แพ้วัคซีน)" dataKey="PLAN_VACCINE_COMPENSTAION_TOTAL_AMT" fill="#A74BF1" />
                                    </BarChart>
                                </CardBody>
                            </Card>}

                            {searchData && formData.idCheck === "check" && searchData.map((covidAppData) => (
                                <Card className="mt-5 mb-2" key={covidAppData.APP_FORM_ID}>
                                    <CardBody className="shadow-lg">
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <p className="mt-5 mb-3 text-center h4">รายละเอียดแผนที่คุณสมัคร</p>
                                                <p className="mb-3 text-center h6">{planCovidNewList.filter(pf => pf.PLAN_ID === covidAppData.PLAN_ID)[0].PLAN_COV_TITLE}</p>
                                                {/* <p className={`mb-1 text-center h6 ${covidAppData.POLICY_NUM ? "text-success" : covidAppData.COVERNOTE_NUM ? "text-danger" : covidAppData.PLAN_ISSUER_CODE === "VIB" && vibCoverNoteUrl ? "text-danger" : "text-danger"}`}>{covidAppData.POLICY_NUM ? "ได้รับกรมธรรม์แล้ว" : covidAppData.COVERNOTE_NUM ? "ได้รับหนังสือคุ้มครองแล้ว" : covidAppData.PLAN_ISSUER_CODE === "VIB" && vibCoverNoteUrl ? "ได้รับหนังสือคุ้มครองแล้ว" : "รอไฟล์กรมธรรม์"}</p> */}
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12} className="text-center mt-3 mb-5" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                                <div className="container">
                                                    {covidAppData.INS_COMPANY_CODE === "SMK" && <img src={SmkCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.INS_COMPANY_CODE === "DHP" && <img src={DhpCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.INS_COMPANY_CODE === "AII" && <img src={AsiaCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.INS_COMPANY_CODE === "VIB" && <img src={VibCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.INS_COMPANY_CODE === "SEI" && <img src={SeiCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    <div className="bottom-left-plan"><small>{covidAppData.PLAN_DESC.slice(0, 30)} ...</small></div>
                                                    <div className="bottom-left-name"><small>{covidAppData.PRENAME_DESC} {covidAppData.FIRST_NAME} {covidAppData.LAST_NAME} </small></div>
                                                    <div className="bottom-left-idcard"><small>เลขบัตรประชาชน: {covidAppData.ID_CARD}</small></div>
                                                    <div className="bottom-left-appnum">
                                                        <small>
                                                            {covidAppData.POLICY_NUMBER ? `เลขกรมธรรม์: ${covidAppData.POLICY_NUMBER}` : covidAppData.INS_COMPANY_CODE === "VIB" && vibCoverNoteUrl ? `เลขหนังสือคุ้มครอง:  583-003-00093-${covidAppData.ID_CARD}` : `สถานะการสมัคร: ยังไม่ได้รับหนังสือคุ้มครอง`}
                                                            {covidAppData.PLAN_ISSUER_CODE === "VIB" && vibCoverNoteUrl && <a href={`${vibCoverNoteUrl}`} target="_blank"><i><FeatherIcon icon="file-text" className="fea icon-sm icons pl-1 text-danger covid-file-num" /></i></a>}
                                                        </small>
                                                    </div>
                                                    {/* Show the coverage Date */}
                                                    <div className="bottom-left-covdate"><small>วันที่คุ้มครอง: {covidAppData.DATE_COV_START} - {covidAppData.DATE_COV_END} </small></div>
                                                </div>
                                            </Col>
                                            {/* Show Policy Number for Company that has such as PRH9*/}
                                            {covidAppData.POLICY_NUMBER && <Col lg={12} className="my-4 auth-button">
                                                <p className="mt-3 mb-3 text-center h4">หมายเลขกรมธรรม์ของคุณคือ {covidAppData.POLICY_NUMBER}</p>
                                            </Col>}

                                            {/* Show Policy Download URL SEARCH */}
                                            {policyUrlArray && policyUrlArray.filter(pf => pf.policyNum === covidAppData.POLICY_NUMBER && pf.policyUrl !== "")[0] && <Col lg={12} className="my-3 auth-button">
                                                <Button color="danger" outline className="d-block mx-auto"><a href={`${policyUrlArray.filter(pf => pf.policyNum === covidAppData.POLICY_NUMBER)[0].policyUrl}`} target="_blank" className="text-white">ดาวโหลดไฟล์กรมธรรม์ของคุณได้ที่นี่</a></Button>
                                            </Col>}

                                            {/* Show Covernote Download for ASW */}
                                            {covidAppData.INS_COMPANY_CODE === "VIB" && vibCoverNoteUrl && <Col lg={12} className="my-4 auth-button">

                                                <Button color="danger" outline className="d-block mx-auto"><a href={`${vibCoverNoteUrl}`} target="_blank" className="text-white">ดาวโหลดไฟล์หนังสือคุ้มครองจากวิริยะประกันภัย</a></Button>

                                            </Col>}

                                            {/* If there is an ASIA 500 will show Coverage table for PRH9 */}
                                            {covidAppData.PLAN_ID === "ASIA-500" && <Col xs={12}>
                                                <p className="mt-3 mb-1 text-center h5">ตารางแสดงความคุ้มครองโดยละเอียด</p>
                                                <div className="table-responsive bg-white shadow rounded mt-5">
                                                    <Table className="my-0 table-center invoice-tb" style={{ position: "relative" }}>
                                                        <thead className="bg-light">
                                                            <tr>
                                                                <th scope="col" className="text-left">#</th>
                                                                <th scope="col" style={{ maxWidth: "540px" }, { minWidth: "480px" }}>
                                                                    <p className="text-dark text-center mt-2 mb-0" >
                                                                        ความคุ้มครอง
                                                                    </p>
                                                                    <p className="text-dark text-center mb-0">
                                                                        <small>Coverage</small>
                                                                    </p>
                                                                </th>
                                                                <th scope="col" style={{ maxWidth: "140px" }, { minWidth: "140px" }}>
                                                                    <p className="text-dark text-center mt-2 mb-0" >
                                                                        จำนวนเงินเอาประกันภัย (บาท)
                                                                    </p>
                                                                    <p className="text-dark text-center mb-0" >
                                                                        <small>Sum Insured ( Baht )</small>
                                                                    </p>
                                                                </th>
                                                                <th scope="col" style={{ maxWidth: "120px" }, { minWidth: "120px" }}>
                                                                    <p className="text-dark text-center mt-2 mb-0" >
                                                                        เบี้ยประกันภัย (บาท)
                                                                    </p>
                                                                    <p className="text-dark text-center mb-0" >
                                                                        <small>Premium (Baht)</small>
                                                                    </p>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row" className="text-left">1</th>
                                                                <td className="text-left">
                                                                    <p className="text-dark font-weight-bold my-0">
                                                                        ค่าชดเชยรายวันอันเนื่องมาจากการเจ็บป่วยด้วยโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19)
                                                                    </p>
                                                                    <p className="text-dark">
                                                                        <small>(Hospital Inpatient Benefits due to COVID-19)</small>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="text-dark text-center font-weight-bold my-0">
                                                                        <small> 50,000 (วันละ 1,000 บาท สูงสุดไม่เกิน 50 วัน)</small>
                                                                    </p>
                                                                    <p className="font-weight-bold my-0"></p>
                                                                </td>
                                                                <td>
                                                                    <p className="text-dark text-center font-weight-bold my-0">
                                                                        <small> 500</small>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="text-left">2</th>
                                                                <td className="text-left">
                                                                    <p className="text-dark font-weight-bold my-0">
                                                                        การเจ็บป่วยด้วยภาวะโคม่าที่มีสาเหตุมาจากการติดเชื้อไวรัสโคโรนา 2019 (COVID-19)
                                                                    </p>
                                                                    <p className="text-dark">
                                                                        <small>(Comatosenes due to COVID-19)</small>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="text-dark text-center font-weight-bold my-0">
                                                                        <small> 500,000</small>
                                                                    </p>
                                                                    <p className="font-weight-bold my-0"></p>
                                                                </td>
                                                                <td>
                                                                    <p className="text-dark text-center font-weight-bold my-0">
                                                                        <small> รวมอยู่ในข้อ 1</small>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="text-left">3</th>
                                                                <td className="text-left">
                                                                    <p className="text-dark font-weight-bold my-0">
                                                                        การเจ็บป่วยด้วยโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19)
                                                                    </p>
                                                                    <p className="text-dark">
                                                                        <small>(Infection due to COVID-19)</small>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="text-dark text-center font-weight-bold my-0">
                                                                        <small> 50,000</small>
                                                                    </p>
                                                                    <p className="font-weight-bold my-0"></p>
                                                                </td>
                                                                <td>
                                                                    <p className="text-dark text-center font-weight-bold my-0">
                                                                        <small> รวมอยู่ในข้อ 1</small>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <p className="text-dark text-left font-weight-bold mt-3">
                                                    <small> * ภาวะโคม่า หมายถึง การสลบหรือหมดความรู้สึกที่ได้รับวินิจฉัยโดยอายุรแพทย์หรือประสาทศัลยแพทย์ และตรวจพบลักษณะต่อไปนี้ทุกข้อ</small>
                                                </p>
                                                <ul className="text-dark faq-ans my-3">
                                                    <li><small>ต้องอาศัยเครื่องช่วยชีวิต</small></li>
                                                    <li><small>ไม่มีการตอบสนองต่อสิ่งเร้าภายนอกอย่างน้อย 96 ชั่วโมง</small></li>
                                                    <li><small>สมองถูกทำลายอย่างถาวร ไม่สามารถปฏิบัติกิจวัตรประจำภายหลัง 30 วันจากวันที่สลบหรือหมดความรู้สึก</small></li>
                                                </ul>
                                            </Col>}
                                            <Col xs={12} className="text-center">
                                                <p className="my-5 text-center h5">รายละเอียดผู้เอาประกันภัย</p>
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> วันที่คุ้มครอง</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.DATE_COV_START} - {covidAppData.DATE_COV_END} </small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> ชื่อ-นามสกุล</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.PRENAME_DESC} {covidAppData.FIRST_NAME} {covidAppData.LAST_NAME} </small>
                                                    </div>
                                                </div>
                                                {covidAppData.BIRTH_DATE && <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> วันเดือนปีเกิดผู้เอาประกันภัย</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.BIRTH_DATE}</small>
                                                    </div>
                                                </div>}
                                                {covidAppData.GENDER && <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> เพศ</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.GENDER === 'M' ? "ชาย" : 'หญิง'} </small>
                                                    </div>
                                                </div>}
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> เลขที่บัตรประจำตัวประชาชน</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.ID_CARD} </small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-5 text-left">
                                                        <small className="mb-2 font-weight-bold"> ที่อยู่</small>
                                                    </div>
                                                    <div className="col-7 text-right">
                                                        <small>{covidAppData.ADDRESS_DETAIL}</small>
                                                    </div>
                                                    <div className="col-12 text-right">
                                                        <small>{covidAppData.ADDRESS_SUB_DISTRICT} {covidAppData.ADDRESS_DISTRICT} {covidAppData.ADDRESS_PROVINCE} {covidAppData.ADDRESS_ZIP_CODE}</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> ผู้รับประโยชน์</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.BENEFIT_DETAIL} </small>
                                                    </div>
                                                </div>
                                                {covidAppData.PHONE_NUMBER && <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> เบอร์โทรศัพท์มือถือ</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.PHONE_NUM} </small>
                                                    </div>
                                                </div>}
                                                {covidAppData.EMAIL_ADDRESS && <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> อีเมล</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.EMAIL_ADDRESS} </small>
                                                    </div>
                                                </div>}
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> บริษัทประกันภัย</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small>{covidAppData.INS_COMPANY_CODE}</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-2 font-weight-bold"> เบี้ยประกันภัย (เบี้ยรวม)</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small className="mb-2">{covidAppData.TOTAL_PREMIUM_AMT} บาท</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-4 font-weight-bold"> กลุ่ม/บริษัท</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small className="mb-4">{covidAppData.GROUP_ID} - {covidAppData.GROUP_DESC}</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-4 font-weight-bold"> รหัสดูแล</small>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <small className="mb-4">{covidAppData.AGENT_CODE}</small>
                                                    </div>
                                                </div>
                                                {covidAppData.POLICY_NUM && <div className="row">
                                                    <div className="col-6 text-left">
                                                        <small className="mb-4 font-weight-bold"> เลขกรมธรรม์ประกันภัย</small>
                                                    </div>
                                                    <div className={`col-6 text-right`}>
                                                        <small className="mb-4">{covidAppData.POLICY_NUMBER}</small>
                                                    </div>
                                                </div>}
                                            </Col>
                                            <CovidClaimDoc
                                                IssuerCode={covidAppData.INS_COMPANY_CODE}
                                            />
                                        </Row>
                                    </CardBody>
                                </Card>))
                            }

                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-5 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted mb-5">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default GetPolicy;
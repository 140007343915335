const API_ENDPOINT = "https://webservice.tvinsure.com";
const covidApi = "https://webservice.tvinsure.com"
const cloudFunctionEndpoint = "https://us-central1-tvinsure-covid-app.cloudfunctions.net"

export { covidApi }

export const FILES_URL = `${API_ENDPOINT}/api/files`
export const PACKAGES_URL = `${API_ENDPOINT}/api/packages`
export const TRANSACTIONS_URL = `${API_ENDPOINT}/api/transactions`
export const USERS_URL = `${API_ENDPOINT}/api/users`
export const MASTERS_URL = `${API_ENDPOINT}/api/masters`

export const getProvinceList = `${cloudFunctionEndpoint}/getProvinceList`
export const getAgentList = `${cloudFunctionEndpoint}/getAgentList`
export const carBrandPackage = `${cloudFunctionEndpoint}/carBrandPackage`
export const carModelPackage = `${cloudFunctionEndpoint}/carModelPackage`
export const carBrandOnline = `${cloudFunctionEndpoint}/carBrandOnline`
export const carModelOnline = `${cloudFunctionEndpoint}/carModelOnline`
export const carBodyTypeIssuer = `${cloudFunctionEndpoint}/carBodyTypeIssuer`
export const carColourCode = `${cloudFunctionEndpoint}/carColourCode`

import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Collapse, CustomInput } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import BackgroundSlider from "react-background-slider";

import { storage, db } from '../../firebase/config'

import { covidApi } from '../../shared/masters/url';



//Import Icons
import FeatherIcon from 'feather-icons-react';


// Import Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';

// Import images
import tvinsureCovidInsOne from '../../shared/images/tvinsure-sme-sabai-package-4.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Benefit-of-Insurance-home-consult.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Benefit-of-New-Insurance.png';

// Import css
import './Product.css';

import Select from 'react-select';

const ticketSubject = [{
    ticketType: "accounting",
    ticketCode: "ACC",
    ticketCodeName: "ฝ่ายบัญชี",
    ticketTypeData: [
        { label: 'นัดจ่ายผลประโยชน์', value: 'นัดจ่ายผลประโยชน์' },
        { label: 'ติดตามเงินคืนสำหรับการยกเลิก', value: 'ติดตามเงินคืนสำหรับการยกเลิก' },
        { label: 'ติดตามเอกสารหัก ณ ที่จ่าย', value: 'ติดตามเอกสารหัก ณ ที่จ่าย' },
        { label: 'ติดตามใบเสร็จรับเงิน/ใบกำกับภาษี', value: 'ติดตามใบเสร็จรับเงิน/ใบกำกับภาษี' },
        { label: 'แจ้งการชำระเบี้ยประกันภัย', value: 'แจ้งการชำระเบี้ยประกันภัย' },
        { label: 'สอบถามยอดค้างชำระ', value: 'สอบถามยอดค้างชำระ' },
        { label: 'สอบถามเรื่องอื่น ๆ', value: 'สอบถามเรื่องอื่น ๆ' }
    ]
},
{
    ticketType: "underwrite",
    ticketCode: "UW",
    ticketCodeName: "ฝ่ายรับประกันภัย",
    ticketTypeData: [
        { label: 'ติดตามเอกสารกรมธรรม์', value: 'ติดตามเอกสารกรมธรรม์' },
        { label: 'แก้ไขข้อมูลเกี่ยวกับกรมธรรม์ /สลักหลังกรมธรรม์', value: 'แก้ไขข้อมูลเกี่ยวกับกรมธรรม์ /สลักหลังกรมธรรม์' },
        { label: 'แจ้งยกเลิกกรมธรรม์/แจ้งถอนประกัน', value: 'แจ้งยกเลิกกรมธรรม์/แจ้งถอนประกัน' },
        { label: 'แจ้งประสาานงานการเคลม', value: 'แจ้งประสาานงานการเคลม' },
        { label: 'แจ้งปัญหาอื่น ๆ', value: 'แจ้งปัญหาอื่น ๆ' }
    ]
}
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted orange',
        color: state.isSelected ? 'orange' : 'slate',
        backgroundColor: "neutral5"
        // padding: 20,
    }),
    // control: () => ({
    //     // none of react-select's styles are passed to <Control />
    //     // width: 200,
    // }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
    }
}

var uniqid = require('uniqid');

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const TicketCreate = () => {

    const tickettype = useParams().tickettype;

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [responseData, setResponseData] = useState();
    const [agentData, setAgentData] = useState();
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent
    useEffect(() => {
        const sendAgentRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/agent`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setAgentData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Agent API, please try again.');
                setIsLoading(false);
            }
        }
        sendAgentRequest()
    }, []);

    //contain all form data
    const [formData, setFormData] = useState({
        ticketId: ticketSubject.filter(t => t.ticketType === tickettype)[0] && ticketSubject.filter(t => t.ticketType === tickettype)[0].ticketCode + "-" + uniqid.time().toUpperCase(),
        ticketType: tickettype,
        ticketTypeName: ticketSubject.filter(t => t.ticketType === tickettype)[0] && ticketSubject.filter(t => t.ticketType === tickettype)[0].ticketCodeName,
        ticketStatus: "INITIALIZE",
        ticketDescription: "",
        fname: "",
        lname: "",
        relatedfileonename: "",
        relatedfileoneurl: "",
        relatedfileoneurlloadfinish: "unselect",
        tel: "",
        email: "",
        isAgentOrCustomer: "unselect",
        agentCode: "",
        agentCheck: "uncheck",
        agentRemark: "",
        currenDate: new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }),
    });

    // console.log(formData);

    const handleSubjectTypeSelect = (opt) => {
        setFormData(prevValue => {
            return {
                ...prevValue,
                ticketSubject: opt.value,
            }
        });
    }

    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        if (name === "agentCode") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: value,
                    agentCheck: "uncheck"
                }
            });
        }

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    //Handle Check Agent
    const handleAgentOrCustomer = (result) => {
        if (result === "customer") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    isAgentOrCustomer: result,
                    agentCode: "1529",
                    agentName: "ลูกค้าตรง",
                    agentCheck: "success"
                }
            });
        }
        setFormData(prevValue => {
            return {
                ...prevValue,
                isAgentOrCustomer: result
            }
        });
    }

    //Handle Check Agent
    const handleCheckAgent = () => {
        const agentFilter = agentData.filter(a => a.AGENT_CODE === formData.agentCode)
        if (agentFilter.length > 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentName: agentFilter[0].AGENT_NAME_T,
                    agentCheck: "success"
                }
            });
        }
        if (agentFilter.length === 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: "",
                    agentName: "กรุณาระบุผู้แจ้งงาน",
                    agentCheck: "fail"
                }
            });
        }
    }

    //Select file function
    const fileSelectedHandler = (event) => {
        const { name, files } = event.target

        const fileMaxSize = 2097152

        if (files[0].size > fileMaxSize) {
            alert("ไฟล์ของคุณมีขนาดเกิน 2 MB กรุณาเลือกไฟล์ใหม่");
            window.location.reload()
        }

        if (files[0].size < fileMaxSize) {
            if (name === "relatedfileone") {
                filerelatedfileoneUploadHandler(files[0])
            }
        }
    }

    //Upload Id Card File
    const filerelatedfileoneUploadHandler = (file) => {
        const uploadTask = storage.ref(`ticketfile/relatedfileone/${formData.ticketId + "-FIRSTFILE"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        relatedfileoneurlloadfinish: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
                // console.log(error.message);
            },
            () => {
                storage
                    .ref('ticketfile/relatedfileone')
                    .child(formData.ticketId + "-FIRSTFILE")
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                relatedfileonename: formData.ticketId + "-FIRSTFILE",
                                relatedfileoneurl: url,
                                relatedfileoneurlloadfinish: "uploaded"
                            }
                        });
                        // console.log(url);
                    });
            })
        // console.log(event.target.files);
    }



    const handleValidSubmit = (event, values) => {
        if (formData.ticketSubject) {
            event.preventDefault();
            setIsSubmit(true);
            const requestSubmitFormData = () => {
                setIsLoading(true);
                db.collection('ticket').add({
                    ticketId: formData.ticketId,
                    ticketType: formData.ticketType,
                    ticketTypeName: formData.ticketTypeName,
                    ticketSubject: formData.ticketSubject,
                    ticketStatus: formData.ticketStatus,
                    ticketDescription: formData.ticketDescription,
                    fname: formData.fname,
                    lname: formData.lname,
                    relatedfileonename: formData.relatedfileonename,
                    relatedfileoneurl: formData.relatedfileoneurl,
                    tel: formData.tel,
                    email: formData.email,
                    agentCode: formData.agentCode,
                    agentName: formData.agentName,
                    agentRemark: formData.agentRemark,
                    currenDate: formData.currenDate
                }).then(() => {
                    db.collection('ticket').where('ticketId', '==', formData.ticketId).get().then((snapshot) => {
                        if (snapshot.docs && snapshot.docs.length > 0) {
                            const resonseDataFirebase = snapshot.docs[0].data()
                            setResponseData(resonseDataFirebase)
                            setIsLoading(false);
                            setSuccessModal(true);
                            return resonseDataFirebase
                        } else {
                            setIsLoading(false);
                            setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
                            setErrorModal(true);
                        }
                    }).then((resonseDataFirebase) => {
                        console.log(resonseDataFirebase)
                        const sendCovidApiRequest = async () => {
                            // alert("ส่งข้อมูลสำเร็จ");
                            try {
                                const response = await axios.post(`${covidApi}/api/hubspot-integration/create/new-ticket`,
                                    JSON.stringify(
                                        {
                                            ticketid: resonseDataFirebase.ticketId,
                                            ticketsubject: `${resonseDataFirebase.ticketSubject}`,
                                            subjectdescription: `${resonseDataFirebase.ticketDescription} ${resonseDataFirebase.agentCode}  ${resonseDataFirebase.agentName} ${resonseDataFirebase.agentRemark} ${resonseDataFirebase.fname} ${resonseDataFirebase.lname} ${resonseDataFirebase.tel} ${resonseDataFirebase.email}`,
                                            ticketpriority: "MEDIUM",
                                        }
                                    ),
                                    {
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    });
                                console.log(response);
                                setIsLoading(false);
                            } catch (err) {
                                setIsLoading(false);
                                console.log(err)
                            }
                        }
                        sendCovidApiRequest()
                    })
                        .catch((error) => {
                            setIsLoading(false);
                            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                            setErrorModal(true);
                        })
                })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                        setErrorModal(true);
                    });
            }
            requestSubmitFormData()
        } else {
            alert("กรุณากรอกข้อมูลสำคัญให้ครบถ้วน เช่น หัวข้อที่ต้องการแจ้ง")
        }

    }

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        setIsSubmit(false);
        window.location.reload()
    };

    return (
        <React.Fragment>
            <div className="wrapper">

                <MetaTags>
                    <title>ติดต่อฝ่ายบริการ  - TVinsure</title>
                    <meta name="description" content="ติดต่อฝ่ายบริการ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="ติดต่อฝ่ายบริการ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:description" content="ติดต่อฝ่ายบริการ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureCovidInsOne} />
                </MetaTags>

                {error && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                        <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                            <p className="mb-2">{error}</p>
                            <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>
                        </ModalFooter>
                    </Modal>
                </div>)}

                {responseData && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={successModal} >
                        <ModalHeader toggle={toggleSuccessModal}>แจ้งปัญหา/ติดต่อเพื่อแจ้งงานบริการ <i><FeatherIcon icon="check" className="fea icon-md icons text-success" /></i></ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-3 text-center h5">สรุปข้อมูลการแจ้งปัญหา/ติดต่อเพื่อแจ้งงานบริการ</p>
                            <p className="mb-3 text-center h6">หมายเลขการแจ้งปัญหา/ติดต่อเพื่อแจ้งงานบริการ: {responseData.ticketId}</p>
                            <div className="row">
                                <div className="col-12 text-left">
                                    <p className="mb-2 h6"> หัวข้อการแจ้ง</p>
                                </div>
                                <div className="col-12 text-left">
                                    <p>{responseData.ticketSubject} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-left">
                                    <p className="mb-2 h6"> รายละเอียดความต้องการ </p>
                                </div>
                                <div className="col-12 text-left">
                                    <p>{responseData.ticketDescription ? responseData.ticketDescription : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-left">
                                    <p className="mb-2 h6"> ผู้แจ้งงาน/ผู้ดูแล</p>
                                </div>
                                <div className="col-12 text-left">
                                    <p>{responseData.agentCode} - {responseData.agentName} {responseData.agentRemark}</p>
                                </div>
                                {responseData.fname && <div className="col-12 text-left">
                                    <p>{responseData.fname} {responseData.lname}</p>
                                </div>}
                                {responseData.tel && <div className="col-12 text-left">
                                    <p>{responseData.tel} - {responseData.email}</p>
                                </div>}
                            </div>
                            {responseData.relatedfileoneurl && <div className="row">
                                <div className="col-12 text-left">
                                    <p className="mb-2 h6"> ไฟล์ที่เกี่ยวข้อง</p>
                                </div>
                                <div className="col-12 text-left">
                                    <a className="col-6 text-primary" target="_blank" href={responseData.relatedfileoneurl}>{responseData.relatedfileonename}</a>
                                </div>
                            </div>}
                            <p className="mt-4 mb-3">- หากเกิดข้อผิดพลาดสามารถติดต่อได้ตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                            <p className="text-left"># {responseData.ticketId}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleSuccessModal}>แจ้งงานบริการเพิ่มเติม</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </div>)}

                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section className="bg-half-100 d-table w-100" >
                    <BackgroundSlider
                        images={items}
                        duration={5}
                        transition={3}
                    />
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="text-center mb-5">
                                    <a href="https://tvinsure.com" className="logo h5">
                                        <img src={tvinsurelogo} height="120" alt="" />
                                    </a>
                                </div>
                                <div className="title-heading text-center">
                                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                        แจ้งงานบริการ{ticketSubject.filter(t => t.ticketType === tickettype)[0] && ticketSubject.filter(t => t.ticketType === tickettype)[0].ticketCodeName}
                                    </h4>
                                    <p className="text-center text-white h5 mt-1 mb-5">หากท่านต้องการติดต่อหรือฝากเรื่องให้เราบริการสามารถทิ้งข้อความให้เราตามแบบฟอร์มด้านล่าง</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section style={{ margin: "-120px 0 0" }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} md={10} sm={8}>
                                <Card>
                                    <CardBody className="shadow-lg">
                                        <AvForm className="login-form mt-4" onValidSubmit={handleValidSubmit}>
                                            <Row>
                                                <Col md={12}>
                                                    <h4 className="text-center mt-2 mb-5">รายละเอียดการติดต่อ{ticketSubject.filter(t => t.ticketType === tickettype)[0] && ticketSubject.filter(t => t.ticketType === tickettype)[0].ticketCodeName}</h4>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>หัวข้อที่ต้องการแจ้ง <span className="text-danger">*</span></Label>
                                                        <Select
                                                            name="ticketsubject"
                                                            options={ticketSubject.filter(t => t.ticketType === tickettype)[0] && ticketSubject.filter(t => t.ticketType === tickettype)[0].ticketTypeData}
                                                            styles={customStyles}
                                                            onChange={opt => handleSubjectTypeSelect(opt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>รายละเอียดความต้องการ</Label>
                                                        <AvField type="textarea" className="form-control"
                                                            errorMessage="กรุณากรอกรายละเอียดความต้องการ"
                                                            validate={{ required: { value: true } }}
                                                            placeholder="กรุณากรอกรายละเอียดความต้องการ" name="ticketDescription" onChange={handleChange} value={formData.carDescription} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ไฟล์ที่เกี่ยวข้อง</Label>
                                                        {formData.relatedfileoneurlloadfinish === "uploading" && <small className="text-primary"> อัพโหลด</small>}
                                                        {formData.relatedfileoneurlloadfinish === "uploaded" && <small className="text-success"> เสร็จสิ้น</small>}
                                                        <i><FeatherIcon icon="file-text" className="fea icon-sm icons" /></i>
                                                        <AvField type="file" accept="image/*" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบหน้าตารางกรมธรรม์เดิม"
                                                            placeholder="ไฟล์หน้าตารางกรมธรรม์เดิม" name="relatedfileone" onChange={fileSelectedHandler} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-5">ข้อมูลรายละเอียดผู้ติดต่อ</h4>
                                                </Col>
                                                {formData.isAgentOrCustomer === "unselect" && <Col lg={6} md={12}>
                                                    <Button color="success" outline block onClick={() => handleAgentOrCustomer("customer")}>ไม่ได้เป็นตัวแทน</Button>
                                                </Col>}
                                                {formData.isAgentOrCustomer === "unselect" && <Col lg={6} md={12} className="mb-5">
                                                    <Button color="danger" outline block onClick={() => handleAgentOrCustomer("agent")}>เป็นตัวแทน</Button>
                                                </Col>}
                                                {formData.isAgentOrCustomer === "customer" &&
                                                    <React.Fragment>
                                                        <Col md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ชื่อ <span className="text-danger">*</span></Label>
                                                                <AvField type="text" className="form-control"
                                                                    errorMessage="กรุณากรอกชื่อ"
                                                                    validate={{ required: { value: true } }} placeholder="ชื่อ" name="fname" onChange={handleChange} value={formData.fname} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>นามสกุล <span className="text-danger">*</span></Label>
                                                                <AvField type="text" className="form-control"
                                                                    errorMessage="กรุณากรอกนามสกุล"
                                                                    validate={{ required: { value: true } }} placeholder="นามสกุล" name="lname" onChange={handleChange} value={formData.lname} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12}>
                                                            <FormGroup className="position-relative">
                                                                <Label>หมายเลขโทรศัพท์มือถือ <span className="text-danger">*</span></Label>
                                                                <i><FeatherIcon icon="smartphone" className="fea icon-sm icons" /></i>
                                                                <AvField type="text" className="form-control pl-5"
                                                                    errorMessage="กรุณากรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง"
                                                                    validate={{
                                                                        pattern: { value: '^[0][689][0-9]-?[0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9]$' },
                                                                        required: { value: true }
                                                                    }} placeholder="หมายเลขโทรศัพท์มือถือ" name="tel" onChange={handleChange} value={formData.tel} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12}>
                                                            <FormGroup className="position-relative">
                                                                <Label>อีเมล <span className="text-danger">*</span></Label>
                                                                <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                                <AvField type="email" className="form-control pl-5"
                                                                    errorMessage="กรุณากรอกอีเมลที่ถูกต้อง"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        email: { value: true }
                                                                    }} placeholder="อีเมล" name="email" onChange={handleChange} value={formData.email} />
                                                            </FormGroup>
                                                        </Col>
                                                    </React.Fragment>
                                                }
                                                {formData.isAgentOrCustomer === "agent" &&
                                                    <React.Fragment>
                                                        <Col lg={12}>
                                                            <p className="text-center mt-2 mb-5">หากใครไม่ทราบรหัสตัวแทนของตนเอง สามารถสอบถามทีมงาน TVinsure เข้ามาได้เลยผ่านไลน์ @tvinsure หรือเบอร์โทรศัพท์ 0-2266-373-1 ถึง 5</p>
                                                        </Col>
                                                        <Col lg={3} md={6}>

                                                            <FormGroup className="position-relative">
                                                                <Label>รหัสตัวแทน/ผู้แนะนำ <span className="text-danger">*</span></Label>
                                                                <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                                <AvField type="text" className="form-control pl-5"
                                                                    errorMessage="กรุณากรอกรหัสตัวแทน/ผู้แนะนำ"
                                                                    validate={{ required: { value: true } }} placeholder="รหัสตัวแทน/ผู้แนะนำ" name="agentCode" onChange={handleChange} value={formData.agentCode} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg={2} md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                                <Button className="mb-2" color={formData.agentCheck === "success" ? "success" : "danger"}
                                                                    outline={formData.agentCheck !== "success" && true} block
                                                                    disabled={!formData.agentCode}
                                                                    onClick={handleCheckAgent}>{formData.agentCheck === "success" ? "ตรวจสอบแล้ว" : "ตรวจสอบ"}
                                                                </Button>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label className="text-center">ชื่อตัวแทน/ผู้แนะนำ</Label>
                                                                <i><FeatherIcon icon="user" className="fea icon-sm icons" /></i>
                                                                <AvField type="text" className="form-control pl-5"
                                                                    placeholder="ชื่อผู้ตัวแทน/ผู้แนะนำ" name="agentName" value={formData.agentName} disabled>{formData.agentName}</AvField>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg={3} md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>หมายเหตุ</Label>
                                                                <AvField type="text" className="form-control"
                                                                    placeholder="หมายเหตุตัวแทน/ผู้แนะนำ" name="agentRemark" onChange={handleChange} value={formData.agentRemark} />
                                                            </FormGroup>
                                                        </Col>
                                                    </React.Fragment>
                                                }

                                                {formData.isAgentOrCustomer !== "unselect" && <Col lg={12} className="mb-0 auth-button">
                                                    {!isSubmit && formData.agentCheck !== "success" && <Button color="danger" type="submit" block disabled>กรุณาตรวจสอบผู้แจ้งงานก่อน</Button>}
                                                    {!isSubmit && formData.agentCheck === "success" && <Button color="danger" type="submit" block>ส่งข้อมูล</Button>}
                                                    {isSubmit && <Button color="success" block disabled>กำลังส่งข้อมูล</Button>}
                                                </Col>}
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                                <Row>
                                    <Col lg={12}>
                                        <p className="text-center mt-5 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                    </Col>
                                    <Col lg={12}>
                                        <p className="text-center text-muted mb-5">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


export default TicketCreate;
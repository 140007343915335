import React from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Icons
import FeatherIcon from 'feather-icons-react';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import './PageAuth.css';

const PageSignup = () => {
    return (
        <React.Fragment>
            {/* <div className="back-to-home rounded d-none d-sm-block">
                    <Link to="index" className="btn btn-icon btn-soft-primary"><i><FeatherIcon icon="home" className="icons" /></i></Link>
                </div> */}

            <section className="bg-auth bg-circle-gradiant">
                <div className="bg-overlay"></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={5} md={8}>
                            <Card>
                                <CardBody>
                                    <div className="text-center mb-3">
                                        <span>
                                            <img src={tvinsurelogo} alt="TVinsure" height="60" />
                                        </span>
                                    </div>
                                    <h4 className="text-center">ลงทะเบียนสมาชิก</h4>
                                    <AvForm className="login-form mt-4">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>ชื่อ <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="user" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกชื่อ"
                                                        validate={{ required: { value: true } }} placeholder="ชื่อ" name="fname" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>นามสกุล <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="user-check" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกนามสกุล"
                                                        validate={{ required: { value: true } }} placeholder="นามสกุล" name="lname" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>อีเมล <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                    <AvField type="email" className="form-control pl-5"
                                                        errorMessage="อีเมลอยู่ในรูปแบบที่ไม่ถูกต้อง"
                                                        validate={{
                                                            required: { value: true },
                                                            email: { value: true }
                                                        }} placeholder="อีเมล" name="email" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>รหัสผ่าน <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="lock" className="fea icon-sm icons" /></i>
                                                    <AvField type="password" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกรหัสผ่าน"
                                                        validate={{ required: { value: true } }} name="password" placeholder="รหัสผ่าน" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="position-relative">
                                                    <Label>ยืนยันรหัสผ่าน <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="lock" className="fea icon-sm icons" /></i>
                                                    <AvField type="password" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกรหัสผ่านให้ตรงกัน"
                                                        validate={{
                                                            required: { value: true },
                                                            match: { value: "password" }
                                                        }} name="repassword" placeholder="ยืนยันรหัสผ่าน" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                        <Label className="custom-control-label" htmlFor="customCheck1">I Accept <Link to="#" className="text-danger">Terms And Condition</Link></Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={12} className="mb-0 auth-button">
                                                <Button color="danger" block>ลงทะเบียน</Button>
                                            </Col>
                                            {/* <Col lg={12} className="mt-4 text-center">
                                                    <h6>Or Signup With</h6>
                                                    <ul className="list-unstyled social-icon mb-0 mt-3">
                                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i><FeatherIcon icon="facebook" className="fea icon-sm fea-social" /></i></Link></li>
                                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i><FeatherIcon icon="github" className="fea icon-sm fea-social" /></i></Link></li>
                                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i><FeatherIcon icon="twitter" className="fea icon-sm fea-social" /></i></Link></li>
                                                        <li className="list-inline-item"><Link to="#" className="rounded"><i><FeatherIcon icon="gitlab" className="fea icon-sm fea-social" /></i></Link></li>
                                                    </ul>
                                                </Col> */}
                                            <Col xs={12} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-dark h6 mr-2">มีบัญชีอยู่แล้วต้องการ...</small> <Link to="/login" className="text-danger h5 font-weight-bold">เข้าสู่ระบบ</Link></p>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

        </React.Fragment>
    );
}


export default PageSignup;
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Collapse, CustomInput, Badge } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import BackgroundSlider from "react-background-slider";

import { storage, db } from '../../firebase/config'
import { preName, benefitType, day, month, year, yearMoreThanfifteen, yearLessThanfifteen, getCustomYear, CovStartDate, CovEndDate, planCovidNewList, Occupation } from '../../shared/masters/refData'

import { covidApi } from '../../shared/masters/url';

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Import Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import seiinsurancelogo from '../../shared/images/SEI_LOGO.jpg';

// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

// Import css
import './Product.css';

// Import components
import CovidCondition from '../components/CovidCondition';

var uniqid = require('uniqid');

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const timeRef = 18

function addDaysOnly(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
        copy.setDate(date.getDate() + days)
        return copy
    } else {
        return copy
    }
}

function addDaysAndOneYear(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
        copy.setDate(date.getDate() + days)
        copy.setYear(date.getFullYear() + 1)
        return copy
    } else {
        copy.setYear(date.getFullYear() + 1)
        return copy
    }
}

function Script_checkID(id) {
    if (!IsNumeric(id)) return false;
    if (id.substring(0, 1) == 0) return false;
    if (id.length != 13) return false;
    for (var i = 0, sum = 0; i < 12; i++)
        sum += parseFloat(id.charAt(i)) * (13 - i);
    if ((11 - sum % 11) % 10 != parseFloat(id.charAt(12))) return false;
    return true;
}
function IsNumeric(input) {
    var RE = /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/;
    return (RE.test(input));
}


const CovidApplicationNew = () => {

    let history = useHistory();

    const planId = useParams().planid;
    const agentToken = useParams().agenttoken;
    const activecheck = useParams().activecheck;
    // console.log(planId);


    const planDetail = activecheck && activecheck === "uncheck" ? planCovidNewList.filter(p => p.PLAN_ID === planId)[0] : planCovidNewList.filter(p => p.PLAN_ID === planId && p.PRODUCT_STATUS === "ACTIVE")[0]

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [covidData, setCovidData] = useState({});
    const [provinceData, setProvinceData] = useState();
    const [districtData, setDistrictData] = useState();
    const [districtSubData, setDistrictSubData] = useState();
    const [districtSubFilter, setDistrictSubFilter] = useState();
    const [agentData, setAgentData] = useState();
    const [error, setError] = useState(null);
    const [outofStockModal, setOutofStockModal] = useState(false);

    const benefitTypeDhp = benefitType.filter(b => b !== "ตนเอง");

    // Get API Province and DistrictSub and Agent
    useEffect(() => {
        window.scrollTo(0, 0);
        if (planDetail && planDetail.PLAN_ISSUER_CODE === "SEI") {
            db.collection("covidCoverNoteAutoRun").where("ISSUER_CODE", "==", "SEI").where("PRODUCT_CODE", "==", "CV0").where("PLAN_PREFIX_COVERNOTE", "==", planDetail.PLAN_PREFIX_COVERNOTE).get()
                .then((snapshot) => {
                    // console.log(snapshot.docs.length)
                    if (snapshot.docs.length < 1) {
                        setOutofStockModal(true);
                    }
                    if (snapshot.docs[0].data() && snapshot.docs[0].data().CURRENT_NUM >= snapshot.docs[0].data().MAX_STOKC_NUM - 10) {
                        setOutofStockModal(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        const sendCovidRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`https://static.easysunday.com/covid-19/getTodayCases.json`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setCovidData(response.data)
                setIsLoading(false);
            } catch (err) {
                console.log(err)
                setCovidData(null);
                setIsLoading(false);
            }
        }
        sendCovidRequest()
        const sendProvinceRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/province`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setProvinceData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendProvinceRequest()
        const sendDistrictSubRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/districtSub`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setDistrictSubData(response.data)
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Province API, please try again.');
                setIsLoading(false);
            }
        }
        sendDistrictSubRequest()
        const sendAgentRequest = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${covidApi}/api/masters/agent`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setAgentData(response.data);
                if (agentToken) {
                    db.collection("genCovidShopToken").where("tokenid", "==", agentToken).get()
                        .then((snapshot) => {
                            if (!snapshot.docs[0].data().productView) {
                                db.collection('genCovidShopToken').doc(snapshot.docs[0].id).update({ productView: 1 })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                            if (snapshot.docs[0].data().productView > 0) {
                                db.collection('genCovidShopToken').doc(snapshot.docs[0].id).update({ productView: snapshot.docs[0].data().productView + 1 })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                            const agentFilter = response.data.filter(a => a.AGENT_CODE === snapshot.docs[0].data().agentCode)
                            if (agentFilter.length > 0) {
                                setFormData(prevValue => {
                                    return {
                                        ...prevValue,
                                        agentCode: snapshot.docs[0].data().agentCode,
                                        agentName: agentFilter[0].AGENT_NAME_T,
                                        agentToken: snapshot.docs[0].data().tokenid,
                                        agentCheck: "success"
                                    }
                                });
                                // console.log(formData.agentCode.slice(-4))
                            }
                            if (agentFilter.length === 0) {
                                setFormData(prevValue => {
                                    return {
                                        ...prevValue,
                                        agentCode: "1529",
                                        agentName: "ลูกค้าตรง",
                                        agentToken: "",
                                        agentCheck: "success"
                                    }
                                });
                            }
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                setIsLoading(false);
            } catch (err) {
                setError(err.message || 'Something went wrong with Agent API, please try again.');
                setIsLoading(false);
            }
        }
        sendAgentRequest()
    }, []);



    //contain all form data
    const [formData, setFormData] = useState({
        appid: "CVGB-" + uniqid.time().toUpperCase(),
        appType: "NEW-GB",
        birthDateDay: "",
        birthDateMonth: "",
        birthDateYear: "",
        prename: "",
        fname: "",
        lname: "",
        Occupation: "",
        gender: "",
        cardType: "nationalidcard",
        idcard: "",
        idcardfilename: "",
        idcardfileurl: "",
        idcardfileurlloadfinish: "unselect",
        idCardCheck: "uncheck",
        address: "",
        province: "",
        provinceid: "",
        district: "",
        districtid: "",
        districtSub: "",
        districtSubid: "",
        benefitType: "",
        benefitPreName: "",
        benefitFirstName: "",
        benefitLastName: "",
        covStartDate: CovStartDate(),
        covEndDate: CovEndDate(),
        tel: "",
        email: "",
        currenDate: new Date().toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        agentCode: "",
        agentCheck: "uncheck",
        paymentAmount: "",
        paymentDate: "",
        paymentTime: "",
        paymentfilename: "",
        paymentfileurl: "",
        paymentfileurlloadfinish: "unselect",
        IsConditionOk: false
    });

    // console.log(formData);

    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        if (name === "isConditionOk") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    isConditionOk: true
                }
            });
        }

        if (name === "prename" && value) {
            const gender = preName.filter(n => n.PRE_NAME === value)[0].GENDER
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    prename: value,
                    gender: gender
                }
            });
        }

        if (name === "benefitPreName" && value) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    benefitPreName: value
                }
            });
        }


        //province must have value
        if (name === "province" && value) {
            const provinceCode = provinceData.filter(p => p.PROV_NAME_T === value)[0].PROVINCE_CODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    province: value,
                    provinceid: provinceCode,
                    district: "",
                    districtid: "",
                    districtSub: "",
                    districtSubid: ""
                }
            });
            const sendDistrictRequest = async () => {
                setIsLoading(true);
                try {
                    const response = await axios.post(`${covidApi}/api/masters/district`,
                        JSON.stringify({
                            PROVINCE_CODE: provinceCode
                        }),
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    setDistrictData(response.data)
                    setIsLoading(false);
                } catch (err) {
                    setIsLoading(false);
                    setError(err.message || 'Something went wrong with District API, please try again.');
                }
            }
            sendDistrictRequest()
        }

        //district must have value
        if (name === "district" && value) {
            const districtCode = districtData.filter(d => d.DISTRICT_NAME_T === value)[0].DISTRICT_CODE
            const districtSubFilter = districtSubData.filter(ds => ds.DISTRICT_NAME_T === value)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    district: value,
                    districtid: districtCode,
                    districtSub: "",
                    districtSubid: ""
                }
            });
            setDistrictSubFilter(districtSubFilter)
        }

        //districtSub must have value
        if (name === "districtSub" && value) {
            const districtSubCode = districtSubFilter.filter(ds => ds.DISTRICT_NAME_T_SUB === value)[0].DISTRICT_CODE_SUB
            const zipCode = districtSubData.filter(ds => ds.DISTRICT_CODE_SUB === districtSubCode)[0].ZIPCODE
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    districtSub: value,
                    districtSubid: districtSubCode,
                    zipcode: zipCode
                }
            });
        }

        if (name === "benefitType" && value === "ตนเอง") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    benefitType: value,
                    benefitPreName: formData.prename,
                    benefitFirstName: formData.fname,
                    benefitLastName: formData.lname
                }
            });
        }

        if (name === "agentCode") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: value,
                    agentCheck: "uncheck"
                }
            });
        }

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    //Handle Check Agent
    const handleCheckAgent = () => {
        const agentFilter = agentData.filter(a => a.AGENT_CODE === formData.agentCode)
        if (agentFilter.length > 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentName: agentFilter[0].AGENT_NAME_T,
                    agentToken: "",
                    agentCheck: "success"
                }
            });
            // console.log(formData.agentCode.slice(-4))
        }
        if (agentFilter.length === 0) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    agentCode: "",
                    agentName: "กรุณาระบุผู้แจ้งงาน",
                    agentCheck: "fail"
                }
            });
        }
    }

    //Handle Check Agent
    const handleCheckId = () => {
        if (formData.idCardCheck !== "success" && planDetail) {
            db.collection('covidApplication').where('NATIONAL_ID', '==', formData.idcard).where('APP_FORM_PAYMENT_STATUS', '==', "COMPLETE").where('PLAN_ID', '==', planDetail ? planDetail.PLAN_ID : "").get().then((snapshot) => {
                if (snapshot.docs && snapshot.docs.length > 0) {
                    setFormData(prevValue => {
                        return {
                            ...prevValue,
                            cardType: "nationalidcard",
                            idcard: "",
                            idCardCheck: "uncheck"
                        }
                    });
                    alert("คุณสามารถซื้อแผนประกันนี้ได้สูงสุดแค่ 1 ฉบับเท่านั้นสำหรับแผน" + planDetail.PLAN_DESC + "และรายการที่คุณเคยสมัครและชำระเงินไว้คือ " + snapshot.docs[0].data().APP_FORM_ID);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    if (formData.cardType === "nationalidcard") {
                        const checkResult = Script_checkID(formData.idcard) === true ? "success" : "fail"
                        if (Script_checkID(formData.idcard) === true) {
                            alert("รหัสบัตรประชาชนถูกต้อง : " + Script_checkID(formData.idcard));
                            db.collection('covidApplication').where('NATIONAL_ID', '==', formData.idcard).where('APP_FORM_PAYMENT_STATUS', '==', "COMPLETE").get().then((snapshot) => {
                                if (snapshot.docs && snapshot.docs.length > 0) {
                                    const dataLoaded = snapshot.docs[0].data()
                                    // console.log(dataLoaded)
                                    const provinceCode = provinceData.filter(p => p.PROV_NAME_T === dataLoaded.ADDRESS_PROVINCE)[0].PROVINCE_CODE
                                    const sendDistrictRequest = async () => {
                                        setIsLoading(true);
                                        try {
                                            const response = await axios.post(`${covidApi}/api/masters/district`,
                                                JSON.stringify({
                                                    PROVINCE_CODE: provinceCode
                                                }),
                                                {
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    }
                                                });
                                            setDistrictData(response.data)
                                            if (response) {
                                                const districtSubFilter = districtSubData.filter(ds => ds.DISTRICT_NAME_T === dataLoaded.ADDRESS_DISTRICT)
                                                setDistrictSubFilter(districtSubFilter);
                                            }
                                            setIsLoading(false);
                                        } catch (err) {
                                            setIsLoading(false);
                                        }
                                    }
                                    sendDistrictRequest()
                                    setFormData(prevValue => {
                                        return {
                                            ...prevValue,
                                            birthDateDay: dataLoaded.BIRTH_DATE ? dataLoaded.BIRTH_DATE.split("/")[0] : "",
                                            birthDateMonth: dataLoaded.BIRTH_DATE ? dataLoaded.BIRTH_DATE.split("/")[1] : "",
                                            birthDateYear: dataLoaded.BIRTH_DATE ? dataLoaded.BIRTH_DATE.split("/")[2] : "",
                                            prename: dataLoaded.PRE_NAME && preName.filter(p => p.PRE_NAME === dataLoaded.PRE_NAME)[0] ? preName.filter(p => p.PRE_NAME === dataLoaded.PRE_NAME)[0].PRE_NAME : "",
                                            fname: dataLoaded.FIRST_NAME ? dataLoaded.FIRST_NAME : "",
                                            lname: dataLoaded.LAST_NAME ? dataLoaded.LAST_NAME : "",
                                            gender: dataLoaded.PRE_NAME && preName.filter(p => p.PRE_NAME === dataLoaded.PRE_NAME)[0] ? preName.filter(p => p.PRE_NAME === dataLoaded.PRE_NAME)[0].GENDER : "",
                                            address: dataLoaded.ADDRESS_DETAIL ? dataLoaded.ADDRESS_DETAIL : "",
                                            province: dataLoaded.ADDRESS_PROVINCE ? dataLoaded.ADDRESS_PROVINCE : "",
                                            provinceid: dataLoaded.ADDRESS_PROVINCE_ID ? dataLoaded.ADDRESS_PROVINCE_ID : "",
                                            district: dataLoaded.ADDRESS_DISTRICT ? dataLoaded.ADDRESS_DISTRICT : "",
                                            districtid: dataLoaded.ADDRESS_DISTRICT_ID ? dataLoaded.ADDRESS_DISTRICT_ID : "",
                                            districtSub: dataLoaded.ADDRESS_SUB_DISTRICT ? dataLoaded.ADDRESS_SUB_DISTRICT : "",
                                            districtSubid: dataLoaded.ADDRESS_SUB_DISTRICT_ID ? dataLoaded.ADDRESS_SUB_DISTRICT_ID : "",
                                            zipcode: dataLoaded.ADDRESS_ZIP_CODE ? dataLoaded.ADDRESS_ZIP_CODE : "",
                                            benefitType: dataLoaded.BENEFIT_TYPE ? dataLoaded.BENEFIT_TYPE : "",
                                            benefitPreName: dataLoaded.BENEFIT_PRE_NAME ? dataLoaded.BENEFIT_PRE_NAME : "",
                                            benefitFirstName: dataLoaded.BENEFIT_FIRST_NAME ? dataLoaded.BENEFIT_FIRST_NAME : "",
                                            benefitLastName: dataLoaded.BENEFIT_LAST_NAME ? dataLoaded.BENEFIT_LAST_NAME : "",
                                            email: dataLoaded.EMAIL_ADDRESS ? dataLoaded.EMAIL_ADDRESS : "",
                                            tel: dataLoaded.PHONE_NUM ? dataLoaded.PHONE_NUM : "",
                                            idcardfilename: dataLoaded.NATIONAL_ID_FILE_NAME ? dataLoaded.NATIONAL_ID_FILE_NAME : "",
                                            idcardfileurl: dataLoaded.NATIONAL_ID_ATTACH ? dataLoaded.NATIONAL_ID_ATTACH : "",
                                            idcardfileurlloadfinish: "uploaded"
                                        }
                                    });
                                    setIsLoading(false);
                                } else {
                                    setIsLoading(false);
                                }
                            }).catch((error) => {
                                setIsLoading(false);
                            })
                        } else if (Script_checkID(formData.idcard) === false) {
                            alert("รหัสบัตรประชาชนไม่ถูกต้อง : " + Script_checkID(formData.idcard));
                        }
                        // alert(Script_checkID(formData.idcard));
                        setIsLoading(true);
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                idCardCheck: checkResult
                            }
                        });
                    } if (formData.cardType === "passport") {
                        alert("เลขที่ Passport ของคุณคือ : " + formData.idcard + " กรุณาเช็คเพื่อความถูกต้อง");
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                idCardCheck: "success"
                            }
                        });
                    }
                }
            }).catch((error) => {
                setIsLoading(false);
            })


        } else {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    cardType: "nationalidcard",
                    idcard: "",
                    idCardCheck: "uncheck"
                }
            });
        }
    }
    //Select file function
    const fileSelectedHandler = (event) => {
        const { name, files } = event.target

        // const fileMaxSize = 1
        // const fileMaxSize = 2097152
        const fileMaxSize = 10485760


        if (files[0].size > fileMaxSize) {
            alert("ไฟล์ของคุณมีขนาดเกิน 10 MB กรุณาเลือกไฟล์ใหม่");
            window.location.reload()
        }

        if (files[0].size < fileMaxSize) {
            if (name === "idcardfile") {
                fileIdCardUploadHandler(files[0])
            }

            if (name === "paymentfile") {
                filePaymentUploadHandler(files[0])
            }
        }

        // if (name === "idcardfile") {
        //     fileIdCardUploadHandler(files[0])
        // }

        // if (name === "paymentfile") {
        //     filePaymentUploadHandler(files[0])
        // }
        // console.log(name);
        // console.log(files);
    }

    //Upload Id Card File
    const fileIdCardUploadHandler = (file) => {
        const uploadTask = storage.ref(`idcardfile/${formData.appid + "-IDCARD"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        idcardfileurlloadfinish: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
                // console.log(error.message);
            },
            () => {
                storage
                    .ref('idcardfile')
                    .child(formData.appid + "-IDCARD")
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                idcardfilename: formData.appid + "-IDCARD",
                                idcardfileurl: url,
                                idcardfileurlloadfinish: "uploaded"
                            }
                        });
                        // console.log(url);
                    });
            })
        // console.log(event.target.files);
    }

    const filePaymentUploadHandler = (file) => {
        const uploadTask = storage.ref(`paymentfile/${formData.appid + "-PAYSLIP"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        paymentfileurlloadfinish: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
            },
            () => {
                storage
                    .ref('paymentfile')
                    .child(formData.appid + "-PAYSLIP")
                    .getDownloadURL()
                    .then(url => {
                        setFormData(prevValue => {
                            return {
                                ...prevValue,
                                paymentfilename: formData.appid + "-PAYSLIP",
                                paymentfileurl: url,
                                paymentfileurlloadfinish: "uploaded"
                            }
                        });
                    });
            })
    }

    const handleValidSubmit = (event, values) => {
        event.preventDefault();
        setIsSubmit(true);
        const requestSubmitFormData = () => {
            setIsLoading(true);
            db.collection('covidApplication').add({
                APP_FORM_ID: formData.appid,
                APP_FORM_TYPE: formData.appType,
                APP_FORM_PAYMENT_STATUS: "INITIALIZE",
                APP_FORM_REF_PAYMENT_NO: Math.floor(Date.now() / 1000) + formData.agentCode.slice(-2) + Math.floor(Math.random() * 10) + formData.idcard.replace(/-/g, "").slice(-1),
                PRE_NAME: formData.prename,
                FIRST_NAME: formData.fname,
                LAST_NAME: formData.lname,
                OCCUPATION: formData.Occupation,
                NATIONAL_ID: formData.idcard.replace(/-/g, ""),
                NATIONAL_ID_FILE_NAME: formData.idcardfilename,
                NATIONAL_ID_ATTACH: formData.idcardfileurl,
                GENDER: formData.gender,
                BIRTH_DATE: `${formData.birthDateDay}/${formData.birthDateMonth}/${formData.birthDateYear}`,
                ADDRESS_DETAIL: formData.address,
                ADDRESS_PROVINCE_ID: formData.provinceid,
                ADDRESS_PROVINCE: formData.province,
                ADDRESS_DISTRICT_ID: formData.districtid,
                ADDRESS_DISTRICT: formData.district,
                ADDRESS_SUB_DISTRICT_ID: formData.districtSubid,
                ADDRESS_SUB_DISTRICT: formData.districtSub,
                ADDRESS_ZIP_CODE: formData.zipcode,
                PHONE_NUM: formData.tel.replace(/-/g, ""),
                EMAIL_ADDRESS: formData.email,
                DATE_COV_START: formData.covStartDate,
                DATE_COV_END: formData.covEndDate,
                BENEFIT_PRE_NAME: formData.benefitPreName,
                BENEFIT_FIRST_NAME: formData.benefitFirstName,
                BENEFIT_LAST_NAME: formData.benefitLastName,
                BENEFIT_TYPE: planDetail.PLAN_ISSUER_CODE === "SEI" ? "ทายาทโดยธรรม" : formData.benefitType,
                PLAN_ID: planDetail.PLAN_ID,
                PLAN_DESC: planDetail.PLAN_DESC + " " + planDetail.PLAN_COV_TITLE,
                PLAN_ISSUER_CODE: planDetail.PLAN_ISSUER_CODE,
                PLAN_ISSUER: planDetail.PLAN_ISSUER,
                NET_PREMIUM_AMT: planDetail.PLAN_NET_PREMIUM_AMT,
                STAMP_DUTY_AMT: planDetail.PLAN_STAMP_DUTY_AMT,
                VAT_AMT: planDetail.PLAN_VAT_AMT,
                TOTAL_PREMIUM: planDetail.PLAN_TOTAL_PREMIUM,
                PLAN_PREFIX_COVERNOTE: planDetail.PLAN_PREFIX_COVERNOTE,
                AGENT_CODE: formData.agentCode,
                AGENT_NAME: formData.agentName,
                AGENT_REMARK: formData.agentRemark || "",
                AGENT_TOKEN: formData.agentToken,
                PAYMENT_FILE_NAME: formData.paymentfilename,
                PAYMENT_ATTACH: formData.paymentfileurl,
                PAYMENT_DATE: "",
                PAYMENT_TIME: formData.paymentTime,
                PAYMENT_AMOUNT: formData.paymentAmount,
                CURRENT_DATE: formData.currenDate,
                PRODUCT_GROUP: planDetail.PRODUCT_GROUP,
                PRODUCT_POLICY: planDetail.PRODUCT_POLICY,
                PRODUCT_PACKAGE: planDetail.PRODUCT_PACKAGE,
                BROKER_CODE: planDetail.BROKER_CODE,
                POLICY_NUM: ""
            }).then(() => {
                history.push(`/application-form/payment/${formData.appid}`)
            })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
                    setErrorModal(true);
                });
        }
        requestSubmitFormData()
    }

    const handleInValidSubmit = (event) => {
        event.preventDefault();
        alert("กรุณากรอกข้อมูลรายละเอียดด้านบนให้ครบถ้วน");
        setIsSubmit(false);
    }

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        setIsSubmit(false);
        window.location.reload()
    };



    // console.log(formData);
    // console.log(responseData);

    return (
        <React.Fragment>
            <div className="wrapper">

                <MetaTags>
                    <title>ประกันภัยไวรัสโคโรนา - TVinsure</title>
                    <meta name="description" content="ประกันไวรัสโคโรนา TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:description" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureCovidInsOne} />
                </MetaTags>

                {error && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                        <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                            <p className="mb-2">{error}</p>
                            <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>
                        </ModalFooter>
                    </Modal>
                </div>)}

                {outofStockModal && !isLoading && planDetail && (<div className="justify-content-center">
                    <Modal isOpen={outofStockModal} >
                        <ModalHeader>แผนที่ท่านเลือกได้ทำการขายครบโควต้าประจำวันแล้ว</ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="text-center mb-2 h5">กรุณาลองใหม่อีกครั้ง ในวันทำการถัดไป</p>
                            <p className="text-center mb-2">แผนที่คุณเลือกคือ</p>
                            <div className="text-center mt-5 mb-5">
                                <span>
                                    {planDetail.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                    {planDetail.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="60" />}
                                    {planDetail.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                    {planDetail.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                    {planDetail.PLAN_ISSUER_CODE === "SEI" && <img src={seiinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="60" />}
                                </span>
                            </div>
                            <p className="text-center mb-2 h6">{planDetail.PLAN_DESC}</p>
                            <p className="text-center mb-2 h6">{planDetail.PLAN_COV_TITLE}</p>
                            <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => {
                                const check = window.confirm("ยืนยันการย้อนกลับไปเลือกแบบประกันภัยจากบริษัทอื่น")
                                if (check === true) {
                                    window.location.href = "https://info.tvinsure.com/covid-19/";
                                } else {
                                    return false
                                }
                            }}>เลือกซื้อแผนอื่น ๆ</Button>
                        </ModalFooter>
                    </Modal>
                </div>)}


                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section className="bg-half-100 d-table w-100" >
                    <BackgroundSlider
                        images={items}
                        duration={5}
                        transition={3}
                    />
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="text-center mb-5">
                                    <a href="https://tvinsure.com" className="logo h5">
                                        <img src={tvinsurelogo} height="120" alt="" />
                                    </a>
                                </div>
                                <div className="title-heading text-center">
                                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                        ประกันภัยไวรัสโคโรนา (ประกันภัยใหม่)
                                    </h4>
                                    <p className="text-center text-white h5 mt-1 mb-5">ทุกแผนประกันภัยมีระยะรอคอย 14 วัน ทั้ง ค่ารักษา / เจอ จ่าย จบ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section style={{ margin: "-120px 0 0" }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} md={10} sm={8}>
                                <Card>
                                    {!planDetail && <CardBody className="shadow-lg">
                                        <h6 className="text-center mt-2 mb-3">ไม่พบแผนประกันที่ต้องการสมัคร หรือ แผนประกันนั้นปิดการขายแล้ว</h6>
                                        <p className="text-center mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                                        <p className="text-center  mb-3">Line: @tvinsure หรือ โทรศัพท์: 0-2266-373-1 ถึง 5</p>
                                    </CardBody>}

                                    {planDetail && <CardBody className="shadow-lg">
                                        {/* <h4 className="text-center mt-5">{planDetail.PLAN_ISSUER}</h4> */}
                                        <div className="text-center mt-5 mb-3">
                                            <span>
                                                {planDetail.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                                {planDetail.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                                {planDetail.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                                {planDetail.PLAN_ISSUER_CODE === "SEI" && <img src={seiinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                            </span>
                                        </div>
                                        {/* <p className="text-muted text-center mt-2"> # {planDetail.PLAN_ID}</p> */}
                                        <h4 className="text-center mt-2"> {planDetail.PLAN_DESC}</h4>
                                        <h6 className="text-center mt-2"> {planDetail.PLAN_COV_TITLE}</h6>
                                        {planDetail.PLAN_COV_DETAIL && <p className="text-center mt-2"> {planDetail.PLAN_COV_DETAIL}</p>}
                                        {formData.agentCheck === "success" && <p className="text-center mt-2 mb-3">ผู้ดูแล: {formData.agentCode} - {formData.agentName}</p>}

                                        <AvForm className="login-form mt-4">
                                            <Row>
                                                {formData.agentCheck !== "success" && <Col lg={10} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>รหัสผู้แจ้งงาน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกรหัสผู้แจ้งงาน"
                                                            validate={{ required: { value: true } }} placeholder="รหัสผู้แจ้งงาน" name="agentCode" onChange={handleChange} value={formData.agentCode} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.agentCheck !== "success" && <Col lg={2} md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                        <Button className="mb-2" color={formData.agentCheck === "success" ? "success" : "danger"}
                                                            outline={formData.agentCheck !== "success" && true} block
                                                            disabled={!formData.agentCode}
                                                            onClick={handleCheckAgent}>{formData.agentCheck === "success" ? "ตรวจสอบแล้ว" : "ตรวจสอบ"}
                                                        </Button>
                                                    </FormGroup>
                                                </Col>}
                                                {formData.agentCheck !== "success" && <Col md={12}>
                                                    <p className="text-center">หากใครไม่ทราบรหัสตัวแทนของตนเอง สามารถสอบถามทีมงาน TVinsure เข้ามาได้เลยผ่านไลน์ @tvinsure หรือเบอร์โทรศัพท์ 0-2266-373-1 ถึง 5</p>
                                                </Col>}
                                            </Row>
                                        </AvForm>
                                        {formData.agentCheck === "success" && <AvForm className="login-form mt-4" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInValidSubmit}>
                                            <Row>
                                                {/* SEI Cov Date depend on payment Date  Only SEI */}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE === "SEI" && <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-5">วันที่คุ้มครองจะเริ่มต้นตามวันที่ที่ท่านชำระเงินเสร็จเสร็จสิ้น</h4>
                                                </Col>}
                                                {/* SEI Cov Date depend on payment Date  Only SEI */}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE === "SEI" && <Col md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>วันเริ่มต้นความคุ้มครองโดยประมาณ (ชำระเงินทันที)</Label>
                                                        <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="วันเริ่มต้นความคุ้มครอง" name="PaymentDateStart" value={addDaysOnly(new Date(), 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })} disabled></AvField>
                                                    </FormGroup>
                                                </Col>}
                                                {/* SEI Cov Date depend on payment Date  Only SEI */}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE === "SEI" && <Col md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>วันสิ้นสุดความคุ้มครองโดยประมาณ (ชำระเงินทันที)</Label>
                                                        <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="วันสิ้นสุดความคุ้มครอง" name="PaymentDateEnd" value={addDaysAndOneYear(new Date(), 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })} disabled></AvField>
                                                    </FormGroup>
                                                </Col>}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE === "SEI" && <p className="px-3 text-left"><small className="text-dark">** วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {addDaysOnly(new Date(), 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })} - {addDaysAndOneYear(new Date(), 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })} การสั่งซื้อประกัน COVID-19 กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์ภายหลังชำระเงินเสร็จสิ้นเท่านั้น โดยการสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</small></p>}
                                                {/* Other Is the same use CovStartDate Only Weekdays */}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" && <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-5">ข้อมูลรายละเอียดความคุ้มครองตามใบคำขอ</h4>
                                                </Col>}
                                                {/* Other Is the same use CovStartDate Only Weekdays */}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" && <Col md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>วันเริ่มต้นความคุ้มครองโดยประมาณ (อัตโนมัติ)</Label>
                                                        <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="วันเริ่มต้นความคุ้มครอง" name="covStartDate" value={formData.covStartDate} disabled>{formData.covStartDate}</AvField>
                                                    </FormGroup>
                                                </Col>}
                                                {/* Other Is the same use CovStartDate Only Weekdays */}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" && <Col md={6}>
                                                    <FormGroup className="position-relative">
                                                        <Label>วันสิ้นสุดความคุ้มครองโดยประมาณ (อัตโนมัติ)</Label>
                                                        <i><FeatherIcon icon="calendar" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            placeholder="วันสิ้นสุดความคุ้มครอง" name="covEndDate" value={formData.covEndDate} disabled>{formData.covEndDate}</AvField>
                                                    </FormGroup>
                                                </Col>}
                                                {/* Other Is the same use CovStartDate Only Weekdays */}
                                                {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" && <p className="px-3 text-left"><small className="text-dark">** วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {CovStartDate()} - {CovEndDate()} การสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</small></p>}
                                                <Col md={12}>
                                                    <h4 className="text-center mt-5 mb-5">ข้อมูลผู้เอาประกันภัย</h4>
                                                </Col>
                                                {/* Other Issuer except DHP foreigner cannot apply form fill only idcard*/}
                                                {planDetail.PLAN_ISSUER_CODE !== "DHP" && <Col lg={9} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง"
                                                            validate={{
                                                                pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' },
                                                                required: { value: true }
                                                            }} placeholder="เลขบัตรประชาชน 13 หลัก" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>}

                                                {planDetail.PLAN_ISSUER_CODE !== "DHP" && <Col lg={3} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>กดปุ่มเพื่อตรวจสอบบัตรประชาชน</Label>
                                                        <Button className="mb-2" color={formData.idCardCheck === "success" ? "danger" : "danger"}
                                                            outline={formData.idCardCheck !== "success" && true} block
                                                            // disabled={formData.idCardCheck === "success"}
                                                            onClick={handleCheckId}>{formData.idCardCheck === "success" ? "กรอกใหม่" : "ตรวจสอบ"}
                                                        </Button>
                                                    </FormGroup>
                                                </Col>}

                                                {/* Other Issuer except DHP foreigner cannot apply file attatch*/}
                                                {planDetail.PLAN_ISSUER_CODE !== "DHP" && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลือกไฟล์บัตรประชาชน <span className="text-danger">*</span></Label>
                                                        {formData.idcardfileurlloadfinish === "uploading" && <small className="text-primary"> กำลังอัพโหลด</small>}
                                                        {formData.idcardfileurlloadfinish === "uploaded" && <small className="text-success">  {formData.idcardfileurlloadfinish === "uploaded" && <a className="text-success" target="_blank" href={formData.idcardfileurl}>อัพโหลดเสร็จสิ้น </a>}</small>}
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="file" accept="image/*" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบไฟล์บัตรประชาชน/พาสปอร์ต"
                                                            placeholder="ไฟล์บัตรประชาชน/พาสปอร์ต" name="idcardfile" onChange={fileSelectedHandler} />

                                                    </FormGroup>
                                                </Col>}

                                                {/* DHP foreigner can Apply */}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && <Col lg={4} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ประเภทบัตร <span className="text-danger">*</span></Label>
                                                        <AvRadioGroup name="cardType" disabled={formData.idCardCheck === "success"} required inline errorMessage="กรุณาเลือกประเภทบัตร" onChange={handleChange} value={formData.cardType}>
                                                            <AvRadio label="บัตรประชาชน (ID Card)" value="nationalidcard" />
                                                            <AvRadio label="พาสปอร์ต (Passport)" value="passport" />
                                                        </AvRadioGroup>
                                                    </FormGroup>
                                                </Col>}

                                                {/* DHP thai idcard form fill can Apply */}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && formData.cardType === "nationalidcard" && <Col lg={5} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง"
                                                            disabled={formData.idCardCheck === "success"}
                                                            validate={{
                                                                pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' },
                                                                required: { value: true }
                                                            }} placeholder="เลขบัตรประชาชน 13 หลัก" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>}

                                                {/* DHP foreigner passport form fill can Apply */}
                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && formData.cardType === "passport" && <Col lg={5} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>พาสปอร์ต (Passport) <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขพาสปอร์ต(Passport)"
                                                            disabled={formData.idCardCheck === "success"}
                                                            validate={{ required: { value: true } }} placeholder="เลขพาสปอร์ต(Passport)" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>}

                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && <Col lg={3} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>กดปุ่มเพื่อตรวจสอบบัตรประชาชน</Label>
                                                        <Button className="mb-2" color={formData.idCardCheck === "success" ? "danger" : "danger"}
                                                            outline={formData.idCardCheck !== "success" && true} block
                                                            // disabled={formData.idCardCheck === "success"}
                                                            onClick={handleCheckId}>{formData.idCardCheck === "success" ? "กรอกใหม่" : "ตรวจสอบ"}
                                                        </Button>
                                                    </FormGroup>
                                                </Col>}

                                                {formData.idCardCheck === "success" && <React.Fragment>

                                                    {/* DHP idcard and passport file can Apply */}
                                                    {planDetail.PLAN_ISSUER_CODE === "DHP" && <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>เลือกไฟล์บัตรประชาชน/พาสปอร์ต<span className="text-danger">*</span></Label>
                                                            {formData.idcardfileurlloadfinish === "uploading" && <small className="text-primary"> กำลังอัพโหลด</small>}
                                                            {formData.idcardfileurlloadfinish === "uploaded" && <small className="text-success">  {formData.idcardfileurlloadfinish === "uploaded" && <a className="text-success" target="_blank" href={formData.idcardfileurl}>อัพโหลดเสร็จสิ้น </a>}</small>}
                                                            <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                            <AvField type="file" accept="image/*" className="form-control pl-5"
                                                                errorMessage="กรุณาแนบไฟล์บัตรประชาชน/พาสปอร์ต"
                                                                placeholder="ไฟล์บัตรประชาชน/พาสปอร์ต" name="idcardfile" onChange={fileSelectedHandler} />

                                                        </FormGroup>
                                                    </Col>}

                                                    {/* SEI foreigner can Apply */}

                                                    {/* {planDetail.PLAN_ISSUER_CODE === "SEI" && <Col md={4}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ประเภทบัตร <span className="text-danger">*</span></Label>
                                                        <AvRadioGroup name="cardType" required inline errorMessage="กรุณาเลือกประเภทบัตร" onChange={handleChange} value={formData.cardType}>
                                                            <AvRadio label="บัตรประชาชน (ID Card)" value="nationalidcard" />
                                                            <AvRadio label="พาสปอร์ต (Passport)" value="passport" />
                                                        </AvRadioGroup>
                                                    </FormGroup>
                                                </Col>} */}

                                                    {/* SEI thai idcard form fill can Apply */}

                                                    {/* {planDetail.PLAN_ISSUER_CODE === "SEI" && formData.cardType === "nationalidcard" && <Col md={8}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลขบัตรประชาชน <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง"
                                                            validate={{
                                                                pattern: { value: '^[0-9]-?[0-9][0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9][0-9]-?[0-9][0-9]-?[0-9]$' },
                                                                required: { value: true }
                                                            }} placeholder="เลขบัตรประชาชน 13 หลัก" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>} */}

                                                    {/* SEI foreigner passport form fill can Apply */}

                                                    {/* {planDetail.PLAN_ISSUER_CODE === "SEI" && formData.cardType === "passport" && <Col md={8}>
                                                    <FormGroup className="position-relative">
                                                        <Label>พาสปอร์ต (Passport) <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกเลขพาสปอร์ต(Passport)"
                                                            validate={{ required: { value: true } }} placeholder="เลขพาสปอร์ต(Passport)" name="idcard" onChange={handleChange} value={formData.idcard} />
                                                    </FormGroup>
                                                </Col>} */}

                                                    {/* SEI idcard and passport file can Apply */}

                                                    {/* {planDetail.PLAN_ISSUER_CODE === "SEI" && <Col md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เลือกไฟล์บัตรประชาชน/พาสปอร์ต<span className="text-danger">*</span></Label>
                                                        {formData.idcardfileurlloadfinish === "uploading" && <small className="text-primary"> กำลังอัพโหลด</small>}
                                                        {formData.idcardfileurlloadfinish === "uploaded" && <small className="text-success"> อัพโหลดเสร็จสิ้น</small>}
                                                        <i><FeatherIcon icon="credit-card" className="fea icon-sm icons" /></i>
                                                        <AvField type="file" accept="image/*" className="form-control pl-5"
                                                            errorMessage="กรุณาแนบไฟล์บัตรประชาชน/พาสปอร์ต"
                                                            validate={{ required: { value: true } }} placeholder="ไฟล์บัตรประชาชน/พาสปอร์ต" name="idcardfile" onChange={fileSelectedHandler} />
                                                    </FormGroup>
                                                </Col>} */}
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>วันเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกวันเกิดผู้เอาประกัน"
                                                                validate={{ required: { value: true } }} placeholder="วันเกิดผู้เอาประกัน" name="birthDateDay" value={formData.birthDateDay} onChange={handleChange}>
                                                                <option value="" selected>วันเกิดผู้เอาประกัน</option>
                                                                {day && day.map(day => (<option value={day.dayValue}>{day.dayDesc}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>เดือนเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกเดือนเกิดผู้เอาประกัน"
                                                                validate={{ required: { value: true } }} placeholder="เดือนเกิดผู้เอาประกัน" name="birthDateMonth" value={formData.birthDateMonth} onChange={handleChange}>
                                                                <option value="" selected>เดือนเกิดผู้เอาประกัน</option>
                                                                {month && month.map(month => (<option value={month.monthValue}>{month.monthDesc}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    {
                                                        planDetail.PLAN_ID === "SMK-COVID-3-IN-1-300" &&
                                                        <Col md={4}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                    validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" value={formData.birthDateYear} onChange={handleChange}>
                                                                    <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                    {yearLessThanfifteen && yearLessThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {
                                                        planDetail.PLAN_ID === "SMK-COVID-3-IN-1-500" &&
                                                        <Col md={4}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                    validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" value={formData.birthDateYear} onChange={handleChange}>
                                                                    <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                    {yearLessThanfifteen && yearLessThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {
                                                        planDetail.PLAN_ID === "SMK-COVID-3-IN-1-250" &&
                                                        <Col md={4}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                    validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" value={formData.birthDateYear} onChange={handleChange}>
                                                                    <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                    {yearMoreThanfifteen && yearMoreThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {
                                                        planDetail.PLAN_ID === "SMK-COVID-3-IN-1-450" &&
                                                        <Col md={4}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                    validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" value={formData.birthDateYear} onChange={handleChange}>
                                                                    <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                    {yearMoreThanfifteen && yearMoreThanfifteen.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {
                                                        planDetail.PLAN_ID === "AII-COVID-555" &&
                                                        <Col md={4}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                    validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" value={formData.birthDateYear} onChange={handleChange}>
                                                                    <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                    {getCustomYear(18, 65) && getCustomYear(18, 65).map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {
                                                        planDetail.PLAN_ID === "AII-COVID-333" &&
                                                        <Col md={4}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                    validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" value={formData.birthDateYear} onChange={handleChange}>
                                                                    <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                    {getCustomYear(1, 65) && getCustomYear(1, 65).map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {
                                                        planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-250" &&
                                                        planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-450" &&
                                                        planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-300" &&
                                                        planDetail.PLAN_ID !== "SMK-COVID-3-IN-1-500" &&
                                                        planDetail.PLAN_ID !== "AII-COVID-555" &&
                                                        planDetail.PLAN_ID !== "AII-COVID-333" &&
                                                        <Col md={4}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ปีเกิดผู้เอาประกัน <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกปีเกิดผู้เอาประกัน"
                                                                    validate={{ required: { value: true } }} placeholder="ปีเกิดผู้เอาประกัน" name="birthDateYear" value={formData.birthDateYear} onChange={handleChange}>
                                                                    <option value="" selected>ปีเกิดผู้เอาประกัน</option>
                                                                    {year && year.map(year => (<option value={year.TH_YEAR}>{year.EN_YEAR} | {year.TH_YEAR}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>คำนำหน้าชื่อ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกคำนำหน้าชื่อ"
                                                                validate={{ required: { value: true } }} placeholder="คำนำหน้าชื่อ" name="prename" value={formData.prename} onChange={handleChange}>
                                                                <option value="" selected>คำนำหน้าชื่อ</option>
                                                                {planDetail.PLAN_ISSUER_CODE === "DHP" && preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                                {planDetail.PLAN_ISSUER_CODE === "SEI" && preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                                {planDetail.PLAN_ISSUER_CODE !== "DHP" && planDetail.PLAN_ISSUER_CODE !== "SEI" && preName.filter(pn => pn.PRE_NAME !== "Mr." && pn.PRE_NAME !== "Mrs." && pn.PRE_NAME !== "Miss" && pn.PRE_NAME !== "Master" && pn.PRE_NAME !== "Mstr.").map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ชื่อ <span className="text-danger">*</span></Label>
                                                            <AvField type="text" className="form-control"
                                                                errorMessage="กรุณากรอกชื่อ"
                                                                validate={{ required: { value: true } }} placeholder="ชื่อ" name="fname" onChange={handleChange} value={formData.fname} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>นามสกุล <span className="text-danger">*</span></Label>
                                                            <AvField type="text" className="form-control"
                                                                errorMessage="กรุณากรอกนามสกุล"
                                                                validate={{ required: { value: true } }} placeholder="นามสกุล" name="lname" onChange={handleChange} value={formData.lname} />
                                                        </FormGroup>
                                                    </Col>
                                                    {planDetail.PLAN_ISSUER_CODE === "SEI" && <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>อาชีพ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกอาชีพ"
                                                                validate={{ required: { value: true } }} placeholder="อำเภอ/เขต" name="Occupation" onChange={handleChange} value={formData.Occupation}>
                                                                <option value="" selected>กรุณาเลือกอาชีพ</option>
                                                                {Occupation && Occupation.map(job => (<option value={job.Occupation}>{job.Occupation}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>}


                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ที่อยู่ <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="map" className="fea icon-sm icons" /></i>
                                                            <AvField type="map" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกที่อยู่"
                                                                validate={{ required: { value: true } }} placeholder="ที่อยู่ เช่น เลขที่บ้าน ชั้น ตึก ถนน ตรอก ซอย" name="address" onChange={handleChange} value={formData.address} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>จังหวัด <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกจังหวัด"
                                                                validate={{ required: { value: true } }} placeholder="จังหวัด" name="province" onChange={handleChange} value={formData.province} disabled={!provinceData}>
                                                                <option value="" selected>จังหวัด</option>
                                                                {provinceData && provinceData.map(province => (<option value={province.PROV_NAME_T} >{province.PROV_NAME_T}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>อำเภอ/เขต <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกอำเภอ/เขต"
                                                                validate={{ required: { value: true } }} placeholder="อำเภอ/เขต" name="district" onChange={handleChange} value={formData.district} disabled={!districtData && !districtSubData}>
                                                                <option value="" selected>อำเภอ/เขต</option>
                                                                {districtData && districtData.map(district => (<option value={district.DISTRICT_NAME_T}>{district.DISTRICT_NAME_T}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ตำบล/แขวง <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                            <AvField type="select" className="form-control"
                                                                errorMessage="กรุณาเลือกตำบล/แขวง"
                                                                validate={{ required: { value: true } }} placeholder="ตำบล/แขวง" name="districtSub" onChange={handleChange} value={formData.districtSub} disabled={!districtSubFilter}>
                                                                <option value="" selected>ตำบล/แขวง</option>
                                                                {districtSubFilter && districtSubFilter.map(districtSub => (<option value={districtSub.DISTRICT_NAME_T_SUB}>{districtSub.DISTRICT_NAME_T_SUB}</option>))}
                                                            </AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <FormGroup className="position-relative">
                                                            <Label>รหัสไปรษณีย์</Label>
                                                            <AvField type="text" className="form-control"
                                                                placeholder="รหัสไปรษณีย์" name="zipcode" value={formData.zipcode} disabled>{formData.zipcode}</AvField>
                                                        </FormGroup>
                                                    </Col>
                                                    {planDetail && planDetail.PLAN_ISSUER_CODE === "SEI" &&
                                                        <Col md={12}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ผู้รับประโยชน์ </Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาระบุผู้รับประโยชน์"
                                                                    disabled
                                                                    placeholder="ผู้รับประโยชน์" name="benefitType" value={formData.benefitType} onChange={handleChange}>
                                                                    <option value="ทายาทโดยธรรม" selected>ทายาทโดยธรรม</option>
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" &&
                                                        <Col lg={formData.benefitType === "ทายาทโดยธรรม" ? 12 : 3} md={formData.benefitType === "ทายาทโดยธรรม" ? 12 : 6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ผู้รับประโยชน์ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาระบุผู้รับประโยชน์"
                                                                    validate={{ required: { value: true } }} placeholder="ผู้รับประโยชน์" name="benefitType" value={formData.benefitType} onChange={handleChange}>
                                                                    <option value="" selected>ผู้รับประโยชน์</option>
                                                                    {planDetail.PLAN_ISSUER_CODE === "DHP" && benefitTypeDhp && benefitTypeDhp.map(bt => (<option value={bt}>{bt}</option>))}
                                                                    {planDetail.PLAN_ISSUER_CODE === "SMK" && benefitTypeDhp && benefitTypeDhp.map(bt => (<option value={bt}>{bt}</option>))}
                                                                    {planDetail.PLAN_ISSUER_CODE !== "SMK" && planDetail.PLAN_ISSUER_CODE !== "DHP" && benefitType && benefitType.map(bt => (<option value={bt}>{bt}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" && formData.benefitType !== "ทายาทโดยธรรม" &&
                                                        <Col lg={3} md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>คำนำหน้าชื่อผู้รับประโยชน์ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                                <AvField type="select" className="form-control"
                                                                    errorMessage="กรุณาเลือกคำนำหน้าชื่อผู้รับประโยชน์"
                                                                    validate={{ required: { value: true } }} placeholder="คำนำหน้าชื่อ" name="benefitPreName" onChange={handleChange} value={formData.benefitPreName}>
                                                                    <option value="">คำนำหน้าชื่อผู้รับประโยชน์</option>
                                                                    {preName.map(name => (<option value={name.PRE_NAME}>{name.PRE_NAME}</option>))}
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" && formData.benefitType !== "ทายาทโดยธรรม" &&
                                                        <Col lg={3} md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>ชื่อผู้รับประโยชน์ <span className="text-danger">*</span></Label>
                                                                <AvField type="text" className="form-control"
                                                                    errorMessage="กรุณากรอกชื่อผู้รับประโยชน์"
                                                                    validate={{ required: { value: true } }} placeholder="ชื่อผู้รับประโยชน์" name="benefitFirstName" onChange={handleChange} value={formData.benefitFirstName} />
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    {planDetail && planDetail.PLAN_ISSUER_CODE !== "SEI" && formData.benefitType !== "ทายาทโดยธรรม" &&
                                                        <Col lg={3} md={6}>
                                                            <FormGroup className="position-relative">
                                                                <Label>นามสกุลผู้รับประโยชน์ <span className="text-danger">*</span></Label>
                                                                <AvField type="text" className="form-control"
                                                                    errorMessage="กรุณากรอกนามสกุลผู้รับประโยชน์"
                                                                    validate={{ required: { value: true } }} placeholder="นามสกุลผู้รับประโยชน์" name="benefitLastName" onChange={handleChange} value={formData.benefitLastName} />
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    <Col md={12}>
                                                        <h4 className="text-center mt-5 mb-5">ข้อมูลการติดต่อและจัดส่งกรมธรรม์</h4>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>หมายเลขโทรศัพท์มือถือ <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="smartphone" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง"
                                                                validate={{
                                                                    pattern: { value: '^[0][689][0-9]-?[0-9][0-9][0-9]-?[0-9][0-9][0-9][0-9]$' },
                                                                    required: { value: true }
                                                                }} placeholder="หมายเลขโทรศัพท์มือถือ" name="tel" onChange={handleChange} value={formData.tel} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>อีเมล <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="mail" className="fea icon-sm icons" /></i>
                                                            <AvField type="email" className="form-control pl-5"
                                                                errorMessage="กรุณากรอกอีเมลที่ถูกต้อง"
                                                                validate={{
                                                                    required: { value: true },
                                                                    email: { value: true }
                                                                }} placeholder="อีเมล" name="email" onChange={handleChange} value={formData.email} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>หมายเหตุ</Label>
                                                            <i><FeatherIcon icon="align-center" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                placeholder="หมายเหตุ" name="agentRemark" onChange={handleChange} value={formData.agentRemark} />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={12}>
                                                        <CovidCondition
                                                            covStartDate={CovStartDate()}
                                                            covEndDate={CovEndDate()}
                                                            planId={planDetail.PLAN_ID}
                                                            isClosed={false}
                                                        />
                                                    </Col>

                                                    <Col md={12}>
                                                        <div className="form-check form-check-inline my-2">
                                                            <FormGroup>
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    label=" ข้าพเจ้า ได้อ่านเงื่อนไขข้อตกลงและนโยบายการการรับประกันภัยแล้ว ข้าพเจ้ายินยอมให้บริษัทฯ จัดเก็บ ใช้ และเปิดเผยข้อเท็จจริงเกี่ยวกับสุขภาพและข้อมูลข้าพเจ้าต่อสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.) เพื่อประโยชน์ในการกำกับดูแลธุรกิจประกันภัย (บริษัทขอสงวนสิทธิ์การคุ้มครอง กรณีที่ท่านกรอกข้อมูลไม่ครบถ้วนตามที่กำหนด)"
                                                                    name="isConditionOk"
                                                                    id="isConditionOk"
                                                                    value={true}
                                                                    disabled={formData.isConditionOk}
                                                                    onChange={handleChange}
                                                                />

                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mb-0 auth-button">
                                                        {!isSubmit && formData.idcardfileurlloadfinish !== "uploaded" && !formData.isConditionOk && <Button color="danger" type="submit" block disabled={formData.idcardfileurlloadfinish !== "uploaded" || !formData.isConditionOk}>กรุณาแนบไฟล์บัตรประชาชนก่อนดำเนินการต่อไป</Button>}
                                                        {!isSubmit && formData.idcardfileurlloadfinish !== "uploaded" && formData.isConditionOk && <Button color="danger" type="submit" block disabled={formData.idcardfileurlloadfinish !== "uploaded" || !formData.isConditionOk}>กรุณาแนบไฟล์บัตรประชาชนก่อนดำเนินการต่อไป</Button>}
                                                        {!isSubmit && formData.idcardfileurlloadfinish === "uploaded" && !formData.isConditionOk && <Button color="danger" type="submit" block disabled={formData.idcardfileurlloadfinish !== "uploaded" || !formData.isConditionOk}>กรุณายอมรับเงื่อนไขการสมัครประกันภัยก่อนดำเนินการต่อไป</Button>}
                                                        {!isSubmit && formData.idcardfileurlloadfinish === "uploaded" && formData.isConditionOk && <Button color="danger" type="submit" block> ตรวจสอบข้อมูลการสมัครและชำระเงิน</Button>}
                                                        {isSubmit && <Button className="py-2" color="danger" block disabled>กำลังเข้าสู่การชำระเงิน</Button>}
                                                    </Col>
                                                    <Col xs={12} className="text-center">
                                                        <p className="mb-0 mt-3"><small className="text-dark mr-2 h6 mt-2">กลับไปเลือกแบบประกันภัยจาก</small><Button onClick={() => {
                                                            const check = window.confirm("ยืนยันการย้อนกลับไปเลือกแบบประกันภัยจากบริษัทอื่น")
                                                            if (check === true) {
                                                                window.location.href = "https://info.tvinsure.com/covid-19/";
                                                            } else {
                                                                return false
                                                            }
                                                        }} className="font-weight-bold h5 text-danger mt-2" outline color="danger">บริษัทประกันภัยอื่น ๆ</Button></p>
                                                    </Col>
                                                </React.Fragment>}
                                            </Row>
                                        </AvForm>}
                                    </CardBody>}
                                </Card>

                                {covidData && <Card className="shadow-lg mt-5">
                                    <CardBody>
                                        <Col xs={12} className="mt-2 py-2" id="blockquotes">
                                            <div className="component-wrapper rounded shadow">
                                                <div className="p-4 border-bottom">
                                                    <h4 className="title mb-0"> ยอดผู้ป่วยโควิดรายวัน (อัพเดทล่าสุด {covidData.UpdateDate}) </h4>
                                                    <p className="my-1">ที่มา: static.easysunday.com
                                                    </p>
                                                </div>

                                                <div className="px-4 pt-4 pb-2">
                                                    <blockquote className="blockquote p-3 mb-1">
                                                        <Badge color="danger" pill className="mr-1 mb-2"> ติดเชื้อ [ {Number(covidData.NewConfirmed) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewConfirmed)}]</Badge>
                                                        <p className="text-muted mb-0 font-italic">
                                                            ผู้ป่วยติดเชื้อสะสม {Intl.NumberFormat('th-TH').format(covidData.Confirmed)} คน <span className="text-danger">({Number(covidData.NewConfirmed) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewConfirmed)} คน)</span>
                                                        </p>
                                                    </blockquote>
                                                    <blockquote className="blockquote p-3 mb-1">
                                                        <Badge color="success" pill className="mr-1 mb-2"> หายแล้ว [ {Number(covidData.NewRecovered) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewRecovered)}]</Badge>
                                                        <p className="text-muted mb-0 font-italic">
                                                            หายแล้ว {Intl.NumberFormat('th-TH').format(covidData.Recovered)} คน <span className="text-success">({Number(covidData.NewRecovered) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewRecovered)} คน)</span>
                                                        </p>
                                                    </blockquote>
                                                    <blockquote className="blockquote p-3 mb-1">
                                                        <Badge color="info" pill className="mr-1 mb-2"> รักษาอยู่ใน รพ. [ {Number(covidData.NewHospitalized) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewHospitalized)}]</Badge>
                                                        <p className="text-muted mb-0 font-italic">
                                                            รักษาอยู่ใน รพ. {Intl.NumberFormat('th-TH').format(covidData.Hospitalized)} คน <span className="text-info">({Number(covidData.NewHospitalized) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewHospitalized)} คน)</span>
                                                        </p>
                                                    </blockquote>
                                                    <blockquote className="blockquote p-3 mb-1">
                                                        <Badge color="danger" pill className="mr-1 mb-2"> เสียชีวิต [ {Number(covidData.NewDeaths) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewDeaths)}]</Badge>
                                                        <p className="text-muted mb-0 font-italic">
                                                            เสียชีวิต {Intl.NumberFormat('th-TH').format(covidData.Deaths)} คน <span className="text-danger">({Number(covidData.NewDeaths) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewDeaths)} คน)</span>
                                                        </p>
                                                    </blockquote>
                                                </div>
                                            </div>
                                        </Col>
                                    </CardBody>
                                </Card>}
                                <Row>
                                    <Col lg={12}>
                                        <p className="text-center mt-5 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                    </Col>
                                    <Col lg={12}>
                                        <p className="text-center text-muted mb-5">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


export default CovidApplicationNew;
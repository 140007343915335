import { storage, db } from '../../firebase/config'

import axios from 'axios'
import { covidApi } from '../../shared/masters/url';

import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { AuthContext } from '../../shared/context/auth-context';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';

import { forwardRef } from 'react';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid.png';

import MaterialTable, { MTableToolbar } from 'material-table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import "./ListPolicy.css";
import Menubar from '../components/MenuBar';

// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

import BackgroundSlider from "react-background-slider";

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const currentDate = new Date()

function addYear(date) {
    const copy = new Date(Number(date))
    copy.setYear(date.getFullYear() + 1)
    return copy
}

const newCovDateStart = currentDate.toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
const newCovDateEnd = addYear(currentDate).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

const ListPolicyRenew = () => {

    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);;
    const [covidAppList, setCovidAppList] = useState([]);
    const [covidAppSelectId, setcovidAppSelectId] = useState();
    const [covidAppSelect, setcovidAppSelect] = useState();
    const [refresh, setrefresh] = useState(false);
    const [formData, setFormData] = useState({
        POLICY_NUM: "",
        POLICY_NUM_FILE_NAME: "",
        POLICY_NUM_FILE_URL: "",
        POLICY_NUM_FILE_STATUS: "unselect",
        DATE_COV_START: newCovDateStart,
        DATE_COV_END: newCovDateEnd
    });

    useEffect(() => {
        db.collection("covidApplication").where("APP_FORM_TYPE", "==", "NEW-GB").where("APP_FORM_PAYMENT_STATUS", "==", "INITIALIZE").where("POLICY_NUM", "==", "").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    covidApp.push(doc.data());
                })
                setIsLoading(false);
                setCovidAppList(covidApp);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }, [refresh]);

    //Model Card Detail Set
    const [cardCovidAppDetailModal, setCardCovidAppDetailModal] = useState(false);
    const openCardCovidAppDetailModal = (appid) => {
        db.collection('covidApplication').where('APP_FORM_ID', '==', appid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setcovidAppSelectId(snapshot.docs[0].id)
                setcovidAppSelect(snapshot.docs[0].data())
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setCardCovidAppDetailModal(true);
    };

    const closeCardCovidAppDetailModal = () => {
        setCardCovidAppDetailModal(false);
        setcovidAppSelectId(null);
        setcovidAppSelect(null);
    };

    // console.log(covidAppList)

    const handleChange = event => {
        const { name, value } = event.target

        if (name === "DATE_COV_START") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    DATE_COV_START: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 543}`,
                    DATE_COV_END: `${value.split("-")[2]}/${value.split("-")[1]}/${Number(value.split("-")[0]) + 544}`
                }
            });
        }

        if (name === "POLICY_NUM") {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    POLICY_NUM: value.replace(/\s/g, ""),
                    POLICY_NUM_FILE_NAME: value.replace(/\s/g, "").replace(/\//g, "") + ".pdf"
                }
            });
        }
    }

    return (
        <React.Fragment>
            <section className="bg-half-100 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <img src={tvinsurelogo} height="120" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="title-heading text-center">
                        <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                            ระบบบริหารงานประกันภัย
                        </h4>
                        <p className="text-center text-white h5 mt-1 mb-5">สำหรับการใช้งานของพนักงานหลังบ้านเท่านั้น</p>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
            <section style={{ margin: "-120px 0 0" }}>

                <Container>
                    <Row className="justify-content-center">
                        {covidAppSelect && !isLoading && <Modal isOpen={cardCovidAppDetailModal} >
                            <ModalHeader toggle={closeCardCovidAppDetailModal}>เลขที่การสมัคร {covidAppSelect.APP_FORM_ID}</ModalHeader>
                            <ModalBody className="pb-2">
                                <p># {covidAppSelect.CURRENT_DATE}</p>
                                <div className="row">
                                    <p className="col-12 text-center h5 font-weight-bold">รายละเอียดแผนประกัน</p>
                                    <p className="col-12 text-center font-weight-bold mb-3">{covidAppSelect.PLAN_ID}{covidAppSelect.APP_FORM_TYPE}</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        แผน:
                                </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_DESC}
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        บริษัทประกัน:
                                </div>
                                    <div className="col-9 text-right mb-2">
                                        {covidAppSelect.PLAN_ISSUER}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบี้ยสุทธิ:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.NET_PREMIUM_AMT} บาท
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        อากร:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.STAMP_DUTY_AMT} บาท
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        VAT:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.VAT_AMT} บาท
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ราคาเบี้ยประกันภัย(เบี้ยรวม):
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.TOTAL_PREMIUM} บาท
                                </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้เอาประกัน</p>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่คุ้มครอง (ตามใบคำขอ):
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        ชื่อผู้เอาประกัน:
                                </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.PRE_NAME} {covidAppSelect.FIRST_NAME} {covidAppSelect.LAST_NAME}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันเดือนปีเกิดผู้เอาประกัน:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BIRTH_DATE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เพศ:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.GENDER === "M" ? "ชาย" : "หญิง"}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        บัตรประชาชน:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.NATIONAL_ID_ATTACH}>{covidAppSelect.NATIONAL_ID}</a>
                                    </div>
                                    <div className="col-3 text-left font-weight-bold">
                                        ที่อยู่:
                                </div>
                                    <div className="col-9 text-right">
                                        {covidAppSelect.ADDRESS_DETAIL}
                                    </div>
                                    <div className="col-12 text-right mb-2">
                                        {covidAppSelect.ADDRESS_SUB_DISTRICT} - {covidAppSelect.ADDRESS_DISTRICT} - จังหวัด{covidAppSelect.ADDRESS_PROVINCE} - {covidAppSelect.ADDRESS_ZIP_CODE}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        ชื่อผู้รับประโยชน์:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.BENEFIT_PRE_NAME} {covidAppSelect.BENEFIT_FIRST_NAME} {covidAppSelect.BENEFIT_LAST_NAME} ({covidAppSelect.BENEFIT_TYPE})
                                </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        เบอร์โทรศัพท์:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.PHONE_NUM}
                                    </div>
                                    <div className="col-4 text-left font-weight-bold">
                                        อีเมล:
                                </div>
                                    <div className="col-8 text-right mb-2">
                                        {covidAppSelect.EMAIL_ADDRESS}
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-3">รายละเอียดผู้ดูแล</p>
                                    <div className="col-3 text-left font-weight-bold">
                                        ผู้ดูแล:
                                </div>
                                    <div className="col-9 text-right mb-1">
                                        {covidAppSelect.AGENT_CODE} - {covidAppSelect.AGENT_NAME}
                                    </div>
                                    <div className="col-12 text-right mb-2">
                                        {covidAppSelect.AGENT_REMARK}
                                    </div>
                                    <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดการชำระเงิน</p>
                                    <div className="col-6 text-left font-weight-bold">
                                        สถานะการชำระเงิน:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.APP_FORM_PAYMENT_STATUS === "COMPLETE" ? "ชำระสำเร็จ" : covidAppSelect.APP_FORM_PAYMENT_STATUS === "INITIALIZE" ? "ชำระไม่สำเร็จ" : "มีความขัดข้อง"}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        หมายเลขการอ้างอิง TVinsure:
                                </div>
                                    <div className="col-6 text-right mb-2">
                                        {covidAppSelect.APP_FORM_REF_PAYMENT_NO}
                                    </div>
                                    <div className="col-6 text-left font-weight-bold">
                                        วันที่คุ้มครอง (ตามหน้ากรมธรรม์):
                                </div>
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        {covidAppSelect.DATE_COV_START} - {covidAppSelect.DATE_COV_END}
                                    </div>}
                                    {!covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        {formData.DATE_COV_START} - {formData.DATE_COV_END}
                                    </div>}
                                    {covidAppSelect.POLICY_NUM && <p className="col-12 text-center h5 font-weight-bold my-5">รายละเอียดกรมธรรม์</p>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-left font-weight-bold">
                                        เลขกรมธรรม์/เลขความคุ้มครอง:
                                </div>}
                                    {covidAppSelect.POLICY_NUM && <div className="col-6 text-right mb-2">
                                        <a className="col-6 text-primary" target="_blank" href={covidAppSelect.POLICY_NUM_FILE_URL}>{covidAppSelect.POLICY_NUM}</a>
                                    </div>}
                                </div>

                            </ModalBody>
                            <ModalFooter className="mx-auto">
                                <Button color="danger" outline onClick={closeCardCovidAppDetailModal}>ย้อนกลับ</Button>
                            </ModalFooter>
                        </Modal>}
                        <Col lg={12} md={10} sm={8}>

                            <Menubar page="list-policy-init-gb" />
                            {isLoading && <div className="row">
                                <div className="col-xl-12 text-center mt-5">
                                    <div style={{ maxWidth: '100%' }}>
                                        <p className="text-dark font-weight-bold">กำลังโหลดข้อมูล...</p>
                                    </div>
                                </div>
                            </div>}
                            {error && <div className="row">
                                <div className="col-xl-12 text-center mt-5">
                                    <div style={{ maxWidth: '100%' }}>
                                        <p className="text-dark font-weight-bold">{error}</p>
                                    </div>
                                </div>
                            </div>}
                            <MaterialTable
                                icons={tableIcons}
                                columns={[
                                    { title: 'รหัสตัวแทน', field: 'AGENT_CODE' },
                                    { title: 'เลขที่การสมัคร', field: 'APP_FORM_ID' },
                                    { title: 'ชื่อผู้เอาประกัน', field: 'FIRST_NAME' },
                                    { title: 'นามสกุลผู้เอาประกัน', field: 'LAST_NAME' },
                                    { title: 'วันที่เริ่มความคุ้มครอง', field: 'DATE_COV_START' },
                                    { title: 'เลขบัตรประชาชน', field: 'NATIONAL_ID' },
                                    { title: 'บริษัทประกัน', field: 'PLAN_ISSUER_CODE', defaultGroupOrder: 0 },
                                    { title: 'เบี้ยประกันภัย', field: 'TOTAL_PREMIUM' },
                                    { title: 'ช่องทางการสมัคร', field: 'APP_FORM_TYPE' },
                                    { title: 'รหัสอ้างอิง TVinsure', field: 'APP_FORM_REF_PAYMENT_NO' },
                                    { title: 'เลขกรมธรรม์', field: 'POLICY_NUM' },
                                ]}
                                data={covidAppList}
                                title={`สมัครใหม่ชำระไม่สำเร็จ (GB) ${covidAppList.length} รายการ`}
                                actions={[
                                    {
                                        icon: () => <ChevronRightIcon />,
                                        tooltip: 'รายละเอียด',
                                        onClick: (event, rowData) => {
                                            openCardCovidAppDetailModal(rowData.APP_FORM_ID);
                                        }
                                    }
                                ]}

                                components={{
                                    Toolbar: props => (
                                        <Container>
                                            <Row>
                                                <Col lg={12}    >
                                                    <MTableToolbar {...props} />
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="btn btn-pills btn-primary mb-3" style={{ float: 'right' }} onClick={() => setrefresh(!refresh)}> ดึงข้อมูลใหม่ </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    ),
                                }}


                                options={{
                                    tableLayout: "auto",
                                    exportButton: true,
                                    grouping: true,
                                    headerStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    searchFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    toolbarFieldStyle: {
                                        fontFamily: 'Kanit, sans-serif'
                                    },
                                    paging: true,
                                    pageSize: 20,       // make initial page size
                                    emptyRowsWhenPaging: false,   //to make page size not fix (dynamic) in case of less data rows
                                    pageSizeOptions: [5, 10, 20, 50, 100, 200, 1000, 1200, 1500, 2000]    // rows selection options
                                }}

                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} จาก {count}',
                                        labelRowsSelect: 'รายการ'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} รายการที่เลือก',
                                        searchPlaceholder: 'ค้นหา'
                                    },
                                    header: {
                                        actions: ''
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'ไม่มีรายการ',
                                        filterRow: {
                                            filterTooltip: 'ตัวกรอง'
                                        }
                                    },
                                    grouping: {
                                        placeholder: 'ลากหัวข้อมาที่นี่เพื่อแบ่งกลุ่ม',
                                        groupedBy: 'แบ่งกลุ่มโดย: '
                                    }
                                }}

                            />
                        </Col>
                        <p className="text-center my-4 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                    </Row>
                </Container>

            </section>
        </React.Fragment>

    );
}


export default ListPolicyRenew;
import React from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

const QuestionNkiVac = (props) => {

    const handleValidSubmit = (event, values) => {
        event.preventDefault();
        props.handleValidSubmit()
    }

    return (
        <React.Fragment>
            <AvForm className="login-form mt-4" onValidSubmit={handleValidSubmit}>
                <Col md={12}>
                    <h4 className="text-center mt-5 mb-5">คำถามการแถลงประวัติสุขภาพ</h4>
                </Col>
                <Col md={12}>
                    <FormGroup className="position-relative text-left my-4 mx-3">
                        <h6 className="h6">ท่านมีหรือได้ขอเอาประกันชีวิต หรือประกันภัยสุขภาพไว้กับบริษัท หรือบริษัทอื่นหรือไม่? <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></h6>
                        <Col md={12}>
                            <AvRadioGroup inline name="QUESTION_OTHER_INS_LIFE_HEALTH" onChange={props.handleChange} required errorMessage="กรุณาเลือกคำตอบ">
                                <AvRadio customInput label="ไม่เคยมี/ไม่มี" value="N" />
                                <AvRadio customInput label="เคย/มี (โปรดให้รายละเอียด)" value="Y" />
                            </AvRadioGroup>
                        </Col>
                        {props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH === "Y" && <Col md={12}>
                            <Label>ชื่อบริษัทประกันดังกล่าว <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="กรุณากรอกชื่อบริษัทประกันดังกล่าว"
                                validate={{ required: { value: true } }}
                                placeholder="ชื่อบริษัทประกันดังกล่าว"
                                name="QUESTION_OTHER_INS_LIFE_HEALTH_COM"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH_COM}>
                            </AvField>
                        </Col>}
                        {props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH === "Y" && <Col md={12}>
                            <Label>จำนวนเงินเอาประกันภัย <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="กรุณากรอกจำนวนเงินเอาประกันภัย"
                                validate={{ required: { value: true } }}
                                placeholder="จำนวนเงินเอาประกันภัย"
                                name="QUESTION_OTHER_INS_LIFE_HEALTH_SUMINS"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH_SUMINS}>
                            </AvField>
                        </Col>}
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup className="position-relative text-left my-4 mx-3">
                        <h6 className="h6"> ท่านเคยถูกปฏิเสธการขอเอาประกันชีวิต หรือประกันภัยสุขภาพ หรือปฏิเสธการต่ออายุสัญญาประกันภัย หรือถูกเรียกเก็บเบี้ยประกันภัยเพิ่มสำหรับการประกันภัยดังกล่าวหรือไม่? <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></h6>
                        <Col md={12}>
                            <AvRadioGroup inline name="QUESTION_OTHER_INS_LIFE_HEALTH_DENY" onChange={props.handleChange} required errorMessage="กรุณาเลือกคำตอบ">
                                <AvRadio customInput label="ไม่เคยมี/ไม่มี" value="N" />
                                <AvRadio customInput label="เคย/มี (โปรดให้รายละเอียด)" value="Y" />
                            </AvRadioGroup>
                        </Col>
                        {props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH_DENY === "Y" && <Col md={12}>
                            <Label>ชื่อบริษัทประกันดังกล่าว <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="กรุณากรอกชื่อบริษัทประกันดังกล่าว"
                                validate={{ required: { value: true } }}
                                placeholder="ชื่อบริษัทประกันดังกล่าว"
                                name="QUESTION_OTHER_INS_LIFE_HEALTH_DENY_COM"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH_DENY_COM}>
                            </AvField>
                        </Col>}
                        {props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH_DENY === "Y" && <Col md={12}>
                            <Label>จำนวนเงินเอาประกันภัย <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="กรุณากรอกจำนวนเงินเอาประกันภัย"
                                validate={{ required: { value: true } }}
                                placeholder="จำนวนเงินเอาประกันภัย"
                                name="QUESTION_OTHER_INS_LIFE_HEALTH_DENY_SUMINS"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_OTHER_INS_LIFE_HEALTH_DENY_SUMINS}>
                            </AvField>
                        </Col>}
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup className="position-relative text-left my-4 mx-3">
                        <h6 className="h6"> ท่านมีโรคประจำตัวหรือกินยาอะไรเป็นประจำหรือไม่? <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></h6>
                        <Col md={12}>
                            <AvRadioGroup inline name="QUESTION_CHRONIC_DISEASE" onChange={props.handleChange} required errorMessage="กรุณาเลือกคำตอบ">
                                <AvRadio customInput label="ไม่มี" value="N" />
                                <AvRadio customInput label="มี (โปรดระบุ)" value="Y" />
                            </AvRadioGroup>
                        </Col>
                        {props.questionformData.QUESTION_CHRONIC_DISEASE === "Y" && <Col md={12}>
                            <Label>โปรดระบุโรคดังกล่าว <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="โปรดระบุโรคดังกล่าว"
                                validate={{ required: { value: true } }}
                                placeholder="ระบุโรคดังกล่าว"
                                name="QUESTION_CHRONIC_DISEASE_DETAIL"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_CHRONIC_DISEASE_DETAIL}>
                            </AvField>
                        </Col>}
                    </FormGroup>
                </Col>
                {props.gender === "F" && <Col md={12}>
                    <FormGroup className="position-relative text-left my-4 mx-3">
                        <h6 className="h6"> ปัจจุบันท่านกำลังตั้งครรภ์ อยู่หรือไม่? <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></h6>
                        <Col md={12}>
                            <AvRadioGroup inline name="QUESTION_MATERNITY" onChange={props.handleChange} required errorMessage="กรุณาเลือกคำตอบ">
                                <AvRadio customInput label="ไม่" value="N" />
                                <AvRadio customInput label="ใช่ (โปรดให้รายละเอียด)" value="Y" />
                            </AvRadioGroup>
                        </Col>
                        {props.questionformData.QUESTION_MATERNITY === "Y" && <Col md={12}>
                            <Label>โปรดระบุอายุคครรภ์ (เดือน) <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="โปรดระบุอายุคครรภ์(เดือน)"
                                validate={{ required: { value: true } }}
                                placeholder="ระบุอายุคครรภ์ (เดือน)"
                                name="QUESTION_MATERNITY_DETAIL"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_MATERNITY_DETAIL}>
                            </AvField>
                        </Col>}
                    </FormGroup>
                </Col>}
                <Col md={12}>
                    <FormGroup className="position-relative text-left my-4 mx-3">
                        <h6 className="h6"> การแถลงข้อมูลการฉีดวัคซีนป้องกันโรคติดเชื้อไวรัสโคโรน่า 2019 (COVID-19) ของผู้ขอเอาประกันภัย? <span className="text-danger" style={{ paddingLeft: "0" }}></span></h6>
                        <p className="pl-3"> ท่านเคยได้รับวัคซีนป้องกันโรคติดเช้ือไวรัสโคโรน่า2019 (COVID-19) เข็มแรก หรือไม่? <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></p>
                        <Col md={12}>
                            <AvRadioGroup inline name="QUESTION_VAC_HISTORY" onChange={props.handleChange} required errorMessage="กรุณาเลือกคำตอบ">
                                <AvRadio customInput label="ไม่เคย" value="N" />
                                <AvRadio customInput label="เคย (โปรดระบุวันที่)" value="Y" />
                            </AvRadioGroup>
                        </Col>
                        {props.questionformData.QUESTION_VAC_HISTORY === "Y" && <Col md={12}>
                            <Label>โปรดระบุวันที่ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="โปรดระบุวันที่"
                                validate={{ required: { value: true } }}
                                placeholder="โปรดระบุวันที่"
                                name="QUESTION_VAC_HISTORY_DETAIL"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_VAC_HISTORY_DETAIL}>
                            </AvField>
                        </Col>}
                        {props.questionformData.QUESTION_VAC_HISTORY === "Y" && <p className="pl-3"> หากท่านเคยได้รับวัคซีนป้องกันโรคติดเช้ือไวรัสโคโรน่า2019 (COVID-19) เข็มแรก ท่านมีผลข้างเคียงหรืออาการแพ้ใดใดหรือไม่? <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></p>}
                        {props.questionformData.QUESTION_VAC_HISTORY === "Y" && <Col md={12}>
                            <AvRadioGroup inline name="QUESTION_VAC_EFFECT" onChange={props.handleChange} required errorMessage="กรุณาเลือกคำตอบ">
                                <AvRadio customInput label="ไม่มี" value="N" />
                                <AvRadio customInput label="มี (โปรดระบุอาการ)" value="Y" />
                            </AvRadioGroup>
                        </Col>}
                        {props.questionformData.QUESTION_VAC_EFFECT === "Y" && <Col md={12}>
                            <Label>โปรดระบุอาการ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="โปรดระบุอาการ"
                                validate={{ required: { value: true } }}
                                placeholder="โปรดระบุอาการ"
                                name="QUESTION_VAC_EFFECT_DETAIL"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_VAC_EFFECT_DETAIL}>
                            </AvField>
                        </Col>}
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <h4 className="text-center mt-5 mb-5">คำถามการสมัครประกันภัย</h4>
                </Col>
                <Col md={12}>
                    <FormGroup className="position-relative text-left my-4 mx-3">
                        <h6 className="h6"> ผู้ขอเอาประกันภัยประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากรหรือไม่ ? <span className="text-danger" style={{ paddingLeft: "0" }}></span></h6>
                        <Col md={12}>
                            <AvRadioGroup inline name="QUESTION_TAX_SENT" onChange={props.handleChange} required errorMessage="กรุณาเลือกคำตอบ">
                                <AvRadio customInput label="ไม่มีความประสงค์" value="N" />
                                <AvRadio customInput label="มีความประสงค์" value="Y" />
                            </AvRadioGroup>
                        </Col>
                        {props.questionformData.QUESTION_TAX_SENT === "Y" && <Col md={12}>
                            <p>มีความประสงค์และยินยอมให้บริษัทประกันวินาศภัยส่งและเปิดเผยข้อมูลเกี่ยวกับ เบี้ยประกันภัยต่อกรมสรรพากร ตามหลักเกณฑ์วิธีการที่กรมสรรพากรกำหนด และหากผู้ขอเอาประกันภัยเป็นชาวต่างชาติ (Non-Thai Residence) ซึ่งเป็นผู้มีหน้าที่เสียภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร</p>
                            <Label>โปรดระบุเลขประจำตัวผู้เสียภาษีที่ได้รับจากกรมสรรพากร <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                            <AvField
                                type="text"
                                className="form-control"
                                errorMessage="โปรดระบุเลขประจำตัวผู้เสียภาษีที่ได้รับจากกรมสรรพากร"
                                validate={{ required: { value: true } }}
                                placeholder="โปรดระบุเลขประจำตัวผู้เสียภาษีที่ได้รับจากกรมสรรพากร"
                                name="QUESTION_TAX_SENT_DETAIL"
                                onChange={props.handleChange}
                                value={props.questionformData.QUESTION_TAX_SENT_DETAIL}>
                            </AvField>
                        </Col>}
                        <Col lg={12} className="mb-0 auth-button">
                            {!props.isSubmit && <Button color="danger" type="submit" block disabled={false}>ดำเนินการต่อไป</Button>}
                            {props.isSubmit && <Button color="success" block disabled>ดำเนินการต่อไป</Button>}
                        </Col>
                    </FormGroup>
                </Col>
            </AvForm>
        </React.Fragment>
    );
}


export default QuestionNkiVac;
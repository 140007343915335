import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import axios from 'axios'
import { Container, Row, Col, Card, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Collapse } from 'reactstrap';
import { Spinner } from 'reactstrap';
import BackgroundSlider from "react-background-slider";


import { db } from '../../firebase/config'

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Import Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import seiinsurancelogo from '../../shared/images/SEI_LOGO.jpg';

// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

// Import Cards 
import VibCard from '../../shared/images/cards/TVinsure-Covid-Card-Vib.jpg';
import AsiaCard from '../../shared/images/cards/TVinsure-Covid-Card-Asia.jpg';
import DhpCard from '../../shared/images/cards/TVinsure-Covid-Card-Dhp.jpg';
import SmkCard from '../../shared/images/cards/TVinsure-Covid-Card-Smk.jpg';
import SeiCard from '../../shared/images/cards/TVinsure-Covid-Card-Sei.jpg';

// Import css
import './Product.css';

// Import components
import CovidCondition from '../components/CovidCondition';

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const timeRef = 18

function addDaysOnly(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
        copy.setDate(date.getDate() + days)
        return copy
    } else {
        return copy
    }
}

function addDaysAndOneYear(date, days) {
    const copy = new Date(Number(date))
    if (copy.getHours() >= timeRef) {
        copy.setDate(date.getDate() + days)
        copy.setYear(date.getFullYear() + 1)
        return copy
    } else {
        copy.setYear(date.getFullYear() + 1)
        return copy
    }
}

const CovidApplicationNew = () => {

    let history = useHistory();

    const appFormId = useParams().appformid;

    const [isLoading, setIsLoading] = useState(false);
    const [qrCode, setQrCode] = useState();
    const [qrCodeError, setQrCodeError] = useState();
    const [applicationData, setApplicationData] = useState();
    const [applicationId, setApplicationId] = useState();
    const [paymentAction, setPaymentAction] = useState(false);
    const [paymentStatusPaid, setPaymentStatusPaid] = useState(false);
    const [paymentStatusCount, setPaymentStatusCount] = useState(0);
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent
    useEffect(() => {
        window.scrollTo(0, 0);
        db.collection('covidApplication').where('APP_FORM_ID', '==', appFormId).where('APP_FORM_TYPE', '==', "NEW-GB").get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setApplicationId(snapshot.docs[0].id)
                setApplicationData(snapshot.docs[0].data())
                setPaymentStatusPaid(false)
                if (snapshot.docs[0].data().APP_FORM_PAYMENT_STATUS === "INITIALIZE") {
                    const sendGbQrCodeApiRequest = async () => {
                        try {
                            const params = new URLSearchParams()
                            params.append('token', 'uecHGvPymYzLAdDQF4kPKyH2OVRy95zHQODGSKW2RJOOvr0JmJ7twtc6EcBzQuQkCoqoeC8UV4KtwlLJFaoy9UgYTTDNBY7bCS96bN9yAjbUlQEf2BZhPEpzeaYiQSJMVfBRPBPmDNT6f4UEGeLx7vJK9XqHuFMC2o9P6g3WDWMwX4Ye')
                            params.append('referenceNo', snapshot.docs[0].data().APP_FORM_REF_PAYMENT_NO)
                            params.append('amount', snapshot.docs[0].data().TOTAL_PREMIUM)
                            if (snapshot.docs[0].data().PLAN_ISSUER_CODE === "SEI") {
                                params.append('backgroundUrl', 'https://webservice.tvinsure.com/api/covid-application/payment-and-covernote/webhook-sei')
                            }
                            if (snapshot.docs[0].data().PLAN_ISSUER_CODE !== "SEI") {
                                params.append('backgroundUrl', 'https://webservice.tvinsure.com/api/covid-application/payment/webhook')
                            }
                            params.append('detail', snapshot.docs[0].data().APP_FORM_ID + " - " + snapshot.docs[0].data().PLAN_DESC)
                            params.append('customerName', snapshot.docs[0].data().PRE_NAME + " " + snapshot.docs[0].data().FIRST_NAME + " " + snapshot.docs[0].data().LAST_NAME)
                            params.append('customerEmail', snapshot.docs[0].data().EMAIL_ADDRESS)
                            params.append('merchantDefined1', snapshot.docs[0].data().APP_FORM_ID)
                            params.append('merchantDefined2', snapshot.docs[0].data().PLAN_ISSUER_CODE)
                            params.append('merchantDefined3', snapshot.docs[0].data().NATIONAL_ID)
                            params.append('merchantDefined4', snapshot.docs[0].data().AGENT_CODE)

                            const url = "https://api.gbprimepay.com/gbp/gateway/qrcode"
                            const config = {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                responseType: 'arraybuffer'
                            }
                            // console.log(params)
                            setQrCodeError(false);
                            axios.post(url, params, config).then(function (response) {
                                // console.log(response.data)
                                // console.log(response)
                                // console.log(JSON.stringify(response.data));
                                // console.log(`data:image/png;base64,${Buffer.from(response.data, 'binary').toString('base64')}`);
                                setQrCode(`data:image/png;base64,${Buffer.from(response.data, 'binary').toString('base64')}`);
                            })
                                .catch(function (error) {
                                    console.log(error);
                                    setQrCodeError(true);
                                });
                            setIsLoading(false);
                        } catch (err) {
                            setIsLoading(false);
                            setError('เกิดปัญหาในการส่ง QRCODE GB Pay');
                        }
                    }
                    sendGbQrCodeApiRequest()
                }
                if (snapshot.docs[0].data().APP_FORM_PAYMENT_STATUS === "COMPLETE") {
                    setPaymentStatusPaid(true)
                    setSuccessModal(true);
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการดังกล่าวได้ กรุณาลองใหม่อีกครั้ง');
                setErrorModal(true);
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            setErrorModal(true);
        })
    }, []);

    useEffect(() => {
        db.collection('covidApplication').where('APP_FORM_ID', '==', appFormId).where('APP_FORM_TYPE', '==', "NEW-GB").where('APP_FORM_PAYMENT_STATUS', '==', "COMPLETE").get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setApplicationId(snapshot.docs[0].id)
                setApplicationData(snapshot.docs[0].data())
                setPaymentStatusPaid(true)
                setPaymentAction(false)
                setSuccessModal(true);
                setIsLoading(false);
                console.log("paid");
            } else {
                console.log("unpaid");
                setIsLoading(false);
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            setErrorModal(true);
        })
    }, [paymentStatusCount]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (applicationData && applicationData.APP_FORM_PAYMENT_STATUS === "INITIALIZE") {
                setPaymentStatusCount(paymentStatusCount => paymentStatusCount + 1);
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [paymentAction]);

    const handlePaymentButton = () => {
        setPaymentAction(true)
    }

    console.log(paymentStatusCount);

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        history.push(`/application-form/${applicationData.PLAN_ID}/detail${applicationData.AGENT_TOKEN ? `/${applicationData.AGENT_TOKEN}` : ""}`)
    };

    return (
        <React.Fragment>
            <div className="wrapper">

                <MetaTags>
                    <title>ประกันภัยไวรัสโคโรนาชำระเงิน - TVinsure</title>
                    <meta name="description" content="ประกันไวรัสโคโรนา TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:description" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureCovidInsOne} />
                </MetaTags>

                {error && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                        <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                            <p className="mb-2">{error}</p>
                            <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </div>)}

                {applicationData && !isLoading && (<div className="justify-content-center">
                    <Modal isOpen={successModal} >
                        <ModalHeader toggle={toggleSuccessModal}>สมัครแผนประกันภัยโคโรนาไวรัสสำเร็จ <i><FeatherIcon icon="check" className="fea icon-md icons text-success" /></i></ModalHeader>
                        <ModalBody className="pb-2">
                            <p className="mb-3 text-center h5">สรุปข้อมูลการสมัครทั้งหมดดังนี้</p>
                            <p className="mb-3 text-center h6">หมายเลขการสมัคร: {applicationData.APP_FORM_ID}</p>
                            {/* <p className="mb-3 text-center h6">{applicationData.PLAN_ID}</p> */}
                            <p className="mb-3 text-center h6">{applicationData.PLAN_DESC}</p>
                            <div class="container mt-3 mb-3">
                                {applicationData.PLAN_ISSUER_CODE === "SMK" && <img src={SmkCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                {applicationData.PLAN_ISSUER_CODE === "DHP" && <img src={DhpCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                {applicationData.PLAN_ISSUER_CODE === "AII" && <img src={AsiaCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                {applicationData.PLAN_ISSUER_CODE === "VIB" && <img src={VibCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                {applicationData.PLAN_ISSUER_CODE === "SEI" && <img src={SeiCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                <div class="bottom-left-plan"><small>{applicationData.PLAN_DESC.slice(0, 30)} ...</small></div>
                                <div class="bottom-left-name"><small>{applicationData.PRE_NAME} {applicationData.FIRST_NAME} {applicationData.LAST_NAME} </small></div>
                                <div class="bottom-left-idcard"><small>เลขบัตรประชาชน: {applicationData.NATIONAL_ID}</small></div>
                                <div class="bottom-left-appnum">
                                    <small> {applicationData.POLICY_NUM ? `เลขกรมธรรม์: ${applicationData.POLICY_NUM}` : applicationData.COVERNOTE_NUM ? `เลขหนังสือคุ้มครอง: ${applicationData.COVERNOTE_NUM}` : `เลขที่การสมัคร: ${applicationData.APP_FORM_ID}`}
                                        {applicationData.POLICY_NUM_FILE_URL && applicationData.PLAN_ISSUER_CODE !== "SEI" && <a href={`${applicationData.POLICY_NUM_FILE_URL}`} target="_blank"><i><FeatherIcon icon="download" className="fea icon-sm icons pl-1 text-danger covid-file-num" /></i></a>}
                                        {applicationData.PLAN_ISSUER_CODE === "SEI" && applicationData.COVERNOTE_NUM && <Link to={`/get-covid-cover-note/${applicationData.APP_FORM_ID}/${applicationData.COVERNOTE_NUM}`} target="_blank"><i><FeatherIcon icon="file-text" className="fea icon-sm icons pl-1 text-danger covid-file-num" /></i></Link>}
                                    </small>
                                </div>
                                {/* SEI Cov Date depend on payment Date  Only SEI */}
                                <div class="bottom-left-covdate"><small>วันที่คุ้มครอง: {applicationData && applicationData.GBPAYMENT_DATE_THAI && applicationData.PLAN_ISSUER_CODE === "SEI" ? `${applicationData.GBPAYMENT_DATE_THAI.split("/")[1]}/${applicationData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(applicationData.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}` : applicationData.DATE_COV_START} - {applicationData && applicationData.GBPAYMENT_DATE_THAI && applicationData.PLAN_ISSUER_CODE === "SEI" ? `${applicationData.GBPAYMENT_DATE_THAI.split("/")[1]}/${applicationData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(applicationData.GBPAYMENT_DATE_THAI.split("/")[2]) + 544}` : applicationData.DATE_COV_END}</small></div>
                            </div>
                            {applicationData.POLICY_NUM_FILE_URL && <Col lg={12} className="my-3 mb-3 auth-button">
                                <a href={`${applicationData.POLICY_NUM_FILE_URL}`} target="_blank">
                                    <Button color="danger" block>ดาวโหลดไฟล์กรมธรรม์ประกันภัย</Button>
                                </a>
                            </Col>}
                            {applicationData.PLAN_ISSUER_CODE === "SEI" && applicationData.COVERNOTE_NUM && <Col lg={12} className="my-3 mb-3 auth-button">
                                <Link to={`/get-covid-cover-note/${applicationData.APP_FORM_ID}/${applicationData.COVERNOTE_NUM}`} target="_blank">
                                    <Button color="danger" block>ดาวโหลดไฟล์หนังสือคุ้มครองจากอาคเนย์ประกันภัย</Button>
                                </Link>
                            </Col>}
                            {applicationData.PLAN_ISSUER_CODE === "SEI" && applicationData.COVERNOTE_NUM && <p className="mt-2 mb-4"> <small>* สำหรับไฟล์กรมธรรม์ทางอาคเนย์ประกันภัยจะจัดส่งทาง SMS ตามเบอร์โทรศัพท์มือถือที่ท่านลงทะเบียนไว้ หากมีข้อสงสัย ท่านสามารถติดต่อทีมงานอาคเนย์ประกันภัยได้ที่ 02-631-1311 หรือ 1726 ศูนย์ดูแลลูกค้า </small></p>}
                            <div className="row">
                                <div className="col-3 text-left">
                                    <p className="mb-2 h6"> วันที่คุ้มครอง</p>
                                </div>
                                {/* SEI Cov Date depend on payment Date  Only SEI */}
                                <div className="col-9 text-right">
                                    <p>{applicationData && applicationData.GBPAYMENT_DATE_THAI && applicationData.PLAN_ISSUER_CODE === "SEI" ? `${applicationData.GBPAYMENT_DATE_THAI.split("/")[1]}/${applicationData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(applicationData.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}` : applicationData.DATE_COV_START} - {applicationData && applicationData.GBPAYMENT_DATE_THAI && applicationData.PLAN_ISSUER_CODE === "SEI" ? `${applicationData.GBPAYMENT_DATE_THAI.split("/")[1]}/${applicationData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(applicationData.GBPAYMENT_DATE_THAI.split("/")[2]) + 544}` : applicationData.DATE_COV_END} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> ชื่อ-นามสกุล</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{applicationData.PRE_NAME} {applicationData.FIRST_NAME} {applicationData.LAST_NAME} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> วันเดือนปีเกิดผู้เอาประกันภัย</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{applicationData.BIRTH_DATE}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7 text-left">
                                    <p className="mb-2 h6"> เพศ</p>
                                </div>
                                <div className="col-5 text-right">
                                    <p>{applicationData.GENDER === 'M' ? "ชาย" : 'หญิง'} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7 text-left">
                                    <p className="mb-2 h6"> เลขที่บัตรประจำตัวประชาชน</p>
                                </div>
                                <div className="col-5 text-right">
                                    <p>{applicationData.NATIONAL_ID} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 text-left">
                                    <p className="mb-2 h6"> ที่อยู่</p>
                                </div>
                                <div className="col-10 text-right">
                                    <p>{applicationData.ADDRESS_DETAIL}</p>
                                </div>
                                <div className="col-12 text-right">
                                    <p>{applicationData.ADDRESS_SUB_DISTRICT} {applicationData.ADDRESS_DISTRICT} {applicationData.ADDRESS_PROVINCE} {applicationData.ADDRESS_ZIP_CODE}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 text-left">
                                    <p className="mb-2 h6"> ผู้รับประโยชน์</p>
                                </div>
                                <div className="col-8 text-right">
                                    <p>{applicationData.BENEFIT_TYPE} - {applicationData.BENEFIT_PRE_NAME} {applicationData.BENEFIT_FIRST_NAME} {applicationData.BENEFIT_LAST_NAME}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> เบอร์โทรศัพท์มือถือ</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{applicationData.PHONE_NUM} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-left">
                                    <p className="mb-2 h6"> อีเมล</p>
                                </div>
                                <div className="col-9 text-right">
                                    <p>{applicationData.EMAIL_ADDRESS} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> บริษัทประกันภัย</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{applicationData.PLAN_ISSUER_CODE} - {applicationData.PLAN_ISSUER}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-2 h6"> เบี้ยประกันภัย (เบี้ยรวม)</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>{applicationData.TOTAL_PREMIUM} บาท</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-left">
                                    <p className="mb-2 h6"> หมายเหตุ</p>
                                </div>
                                <div className="col-9 text-right">
                                    <p>{applicationData.AGENT_REMARK}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 text-left">
                                    <p className="mb-2 h6"> ผู้ดูแล</p>
                                </div>
                                <div className="col-9 text-right">
                                    <p>{applicationData.AGENT_CODE} - {applicationData.AGENT_NAME}</p>
                                </div>
                            </div>
                            <p className="mb-4 text-center h5">ขอขอบคุณที่ท่านสมัครแผนประกันภัยโคโรนาไวรัสกับเรา</p>
                            {applicationData && applicationData.PLAN_ISSUER_CODE !== "SEI" && <p className="mb-3 text-left"><small className="text-dark">- วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {applicationData.DATE_COV_START} - {applicationData.DATE_COV_END} การสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</small></p>}
                            <p className="mb-3 text-left"><small className="text-dark">- กรมธรรม์ประกันภัยมีระยะเวลารอคอย 14 วัน (นับตั้งแต่วันที่กรมธรรม์ประกันภัยเริ่มมีผลบังคับ)และความคุ้มครองต้องผ่านการพิจารณาจากบริษัทประกันภัย  และ ตามเงื่อนไขการรับประกันของประกันภัยนั้นๆ</small></p>
                            {applicationData && applicationData.PLAN_ISSUER_CODE !== "SEI" && <p className="mb-3 text-left"><small className="text-dark">- ไฟล์กรมธรรม์จะถูกส่งตามข้อมูลข้อมูลการติดต่อที่ลงทะเบียนไว้</small></p>}
                            <p className="mt-4 mb-3">หากเกิดข้อผิดพลาด หรือ ไม่ได้รับกรมธรรม์ประกันภัยสามารถติดต่อได้ตามช่องทางด้านล่าง</p>
                            <ul className="mt-1">
                                <li>Line: @tvinsure</li>
                                <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                            </ul>
                            <p className="text-left"># {applicationData.APP_FORM_ID}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={toggleSuccessModal}>สมัครแผนประกันเพิ่มเติม</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </div>)}


                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>


                <section className="bg-half-40 d-table w-100" >
                    <BackgroundSlider
                        images={items}
                        duration={5}
                        transition={3}
                    />
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="text-center mb-5">
                                    <a href="https://tvinsure.com" className="logo h5">
                                        <img src={tvinsurelogo} height="120" alt="" />
                                    </a>
                                </div>
                                <div className="title-heading text-center">
                                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                        ประกันภัยไวรัสโคโรนา (ประกันภัยใหม่)
                                </h4>
                                    <p className="text-center text-white h5 mt-1 mb-5">ทุกแผนประกันภัยมีระยะรอคอย 14 วัน ทั้ง ค่ารักษา / เจอ จ่าย จบ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>



                <section className="section justify-content-center" style={{ margin: "-70px 0 0" }}>
                    <Container>
                        <Row>
                            {applicationData && <Col lg={5} md={6} className=" mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="rounded shadow-lg p-4 sticky-bar">
                                    <div className="firm-logo text-center pt-2">
                                        {applicationData.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={applicationData.PLAN_ISSUER_CODE} height="70" />}
                                        {applicationData.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={applicationData.PLAN_ISSUER_CODE} height="60" />}
                                        {applicationData.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={applicationData.PLAN_ISSUER_CODE} height="70" />}
                                        {applicationData.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={applicationData.PLAN_ISSUER_CODE} height="70" />}
                                        {applicationData.PLAN_ISSUER_CODE === "SEI" && <img src={seiinsurancelogo} alt={applicationData.PLAN_ISSUER_CODE} height="60" />}
                                    </div>
                                    <div className="mb-1 mt-4 text-center">
                                        <p className="pb-2"> {applicationData.PLAN_DESC} </p>
                                    </div>

                                    <div className="table-responsive">
                                        <Table className="table-center table-padding mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="h6 border-0">เบี้ยสุทธิ</td>
                                                    <td className="text-right font-weight-bold border-0">
                                                        {Intl.NumberFormat().format(applicationData.NET_PREMIUM_AMT)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h6">อากร</td>
                                                    <td className="text-right font-weight-bold"> {Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(applicationData.STAMP_DUTY_AMT)} </td>
                                                </tr>
                                                <tr>
                                                    <td className="h6">ภาษี</td>
                                                    <td className="text-right font-weight-bold"> {Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(applicationData.VAT_AMT)} </td>
                                                </tr>
                                                <tr className="bg-light">
                                                    <td className="h6 font-weight-bold">เบี้ยประกันภัย (เบี้ยรวม)</td>
                                                    <td className="text-right text-primary h6 font-weight-bold"> {Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(applicationData.TOTAL_PREMIUM)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>}
                            {applicationData && <Col lg={7} md={6}>
                                <div className="rounded shadow-lg p-4 mt-4">
                                    <h5 className="mb-3">ตรวจสอบรายละเอียดก่อนสมัครประกันภัย:</h5>
                                    {/* <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">หมายเลขใบคำขอ</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.APP_FORM_ID} ({applicationData.APP_FORM_REF_PAYMENT_NO})</small>
                                        </Col>
                                    </Row> */}
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">วันที่คุ้มครองโดยประมาณ (ตามใบคำขอ):</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            {applicationData.PLAN_ISSUER_CODE === "SEI" && <small className="text-md-right d-block">{addDaysOnly(new Date(),1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })} - {addDaysAndOneYear(new Date(), 1).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })}</small>}
                                            {applicationData.PLAN_ISSUER_CODE !== "SEI" && <small className="text-md-right d-block">{applicationData.DATE_COV_START} - {applicationData.DATE_COV_END}</small>}
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">ชื่อผู้เอาประกัน:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.PRE_NAME} {applicationData.FIRST_NAME} {applicationData.LAST_NAME}</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">วันเดือนปีเกิดผู้เอาประกัน:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.BIRTH_DATE}</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">เพศ:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.GENDER === "M" ? "ชาย" : "หญิง"}</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">บัตรประชาชน/พาสปอร์ต:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.NATIONAL_ID}</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">ที่อยู่:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.ADDRESS_DETAIL}</small>
                                        </Col>
                                        <Col md={12} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.ADDRESS_SUB_DISTRICT} {applicationData.ADDRESS_DISTRICT} จังหวัด{applicationData.ADDRESS_PROVINCE} {applicationData.ADDRESS_ZIP_CODE}</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">ชื่อผู้รับประโยชน์:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.BENEFIT_PRE_NAME} {applicationData.BENEFIT_FIRST_NAME} {applicationData.BENEFIT_LAST_NAME} ({applicationData.BENEFIT_TYPE})</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">เบอร์โทรศัพท์:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.PHONE_NUM}</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">อีเมล:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block">{applicationData.EMAIL_ADDRESS}</small>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="rounded shadow-lg p-4 mt-4">
                                    <h5 className="mb-3">รายละเอียดผู้ดูแล:</h5>
                                    <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">ผู้ดูแล:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block"> {applicationData.AGENT_CODE} - {applicationData.AGENT_NAME}</small>
                                        </Col>
                                    </Row>
                                    {applicationData.AGENT_REMARK && <Row className="mt-2">
                                        <Col md={6} xs={12}>
                                            <small className="text-left d-block font-weight-bold">หมายเหตุการแจ้งงาน:</small>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <small className="text-md-right d-block"> {applicationData.AGENT_REMARK}</small>
                                        </Col>
                                    </Row>}
                                </div>
                                <div className="rounded shadow-lg p-4 mt-4">
                                    <h5 className="mb-3">หมายเหตุ:</h5>
                                    <CovidCondition
                                        covStartDate={applicationData.DATE_COV_START}
                                        covEndDate={applicationData.DATE_COV_END}
                                        planId={applicationData.PLAN_ID}
                                        isClosed={true}
                                    />

                                </div>
                                {!paymentAction && !paymentStatusPaid && <p className="mt-3 mb-2 pl-2 text-left">* การสมัคร<span className="text-danger"> ยังไม่สำเร็จ </span> โดยการสมัครประกันภัยจะเสร็จสิ้นหลังจากท่านชำระเงินผ่าน QR เท่านั้น</p>}
                                {!paymentAction && !paymentStatusPaid && <div className="mt-4 pt-2">
                                    {qrCode && <Button
                                        onClick={handlePaymentButton}
                                        className="btn btn-block btn-primary"
                                    >
                                        ชำระเงินผ่านการโอน QR
                                            </Button>}
                                {!qrCode && !qrCodeError && <Spinner color="danger" className="d-block mx-auto mb-2"/>}
                                {!qrCode && !qrCodeError && <p disabled outline className="text-dark text-center"> กำลังดำเนินการออก QR Code </p>}
                                {qrCodeError && <p disabled outline className="text-dark text-center"> QR มีปัญหากรุณาลองใหม่อีกครั้ง หรือหากปัญหานี้ยังเกิดขึ้นอย่างต่อเนื่องกรุณาติดต่อ  Line: @tvinsure หรือ โทรศัพท์: 0-2266-373-1 ถึง 5 </p>}
                                
                                </div>}
                                {paymentAction && <div className="rounded shadow-lg p-4 mt-4">
                                    <h5 className="mb-3 text-center">รายละเอียดการชำระเงิน</h5>
                                    <p className="text-center mb-0">ยอดที่ต้องชำระสำหรับ {applicationData.PLAN_DESC}</p>
                                    <p className="text-center mb-0">สำหรับ {applicationData.PRE_NAME} {applicationData.FIRST_NAME} {applicationData.LAST_NAME}</p>
                                    {qrCode && <img className="mt-3 mb-3" src={qrCode} alt="QR มีปัญหากรุณาลองใหม่อีกครั้ง หรือหากปัญหานี้ยังเกิดขึ้นอย่างต่อเนื่องกรุณาติดต่อ  Line: @tvinsure หรือ โทรศัพท์: 0-2266-373-1 ถึง 5" style={{ width: "100%", maxWidth: "400px", marginLeft: "auto", marginRight: "auto", display: "block" }} />}
                                    <p className="mb-4 text-center h5">ความคุ้มครองจะเกิดขึ้นหลังจากท่านชำระเงินสำเร็จเท่านั้น</p>
                                    <p className="mb-3 text-left h6">* หลังชำระเงินเสร็จสิ้นท่านจะได้รับอีเมลยืนยันการสมัครและการชำระเงิน ตามข้อมูลการติดต่อที่ให้ไว้</p>

                                    {/* SEI Cov Date depend on payment Date */}
                                    {applicationData && applicationData.PLAN_ISSUER_CODE !== "SEI" && <p className="mb-3 text-left h6">** วันที่คุ้มครองที่ระบุในกรมธรรม์โดยประมาณสำหรับการสมัครของคุณคือ {applicationData.DATE_COV_START} - {applicationData.DATE_COV_END} การสั่งซื้อและชำระเงินหลังเวลาทำการ (16.00 น.) กรมธรรม์ประกันภัยจะระบุวันที่คุ้มครองตามหน้าตารางกรมธรรม์เป็นวันทำการถัดไป</p>}

                                    <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด หรือ ไม่ได้รับกรมธรรม์ประกันภัยสามารถติดต่อได้ตามช่องทางด้านล่าง</p>
                                    <ul className="mt-1">
                                        <li>Line: @tvinsure</li>
                                        <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                                    </ul>
                                </div>}
                            </Col>}
                            <Col lg={12} md={10} sm={8}>
                                <Row>
                                    <Col lg={12}>
                                        <p className="text-center mt-5 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                    </Col>
                                    <Col lg={12}>
                                        <p className="text-center text-muted mb-5">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


export default CovidApplicationNew;
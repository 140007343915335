import firebase from 'firebase/app'
import "firebase/storage"
import 'firebase/firestore';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "tvinsure-covid-app.firebaseapp.com",
  databaseURL: "https://tvinsure-covid-app-default-rtdb.firebaseio.com",
  projectId: "tvinsure-covid-app",
  storageBucket: "tvinsure-covid-app.appspot.com",
  messagingSenderId: "1019629594446",
  appId: "1:1019629594446:web:4d46273e815efa27dfa01f",
  measurementId: "G-7RZPPMKF0L"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true })

export { storage, db, firebase as default }
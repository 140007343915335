const dhpHospitalData = [
  {
    HospitalNumber: "1",
    HospitalName: "คลินิกเวชกรรมสยามอินเตอร์เนชั่นแนล สาขาเกาะพีพี",
    HospitalAddress: "179/9 หมู่ 7",
    HospitalSubDistrict: "อ่าวนาง",
    HospitalDistrict: "เมือง",
    HospitalProvince: "กระบี่",
    HospitalTel: "075-601355",
    HospitalWebsite: "",
    HospitalLocation: "7.740769,98.773562",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "2",
    HospitalName: "คลินิกเวชกรรมสยามอินเตอร์เนชั่นแนล สาขาเกาะลันตา",
    HospitalAddress: "242/7-8 หมู่ 2",
    HospitalSubDistrict: "ศาลาด่าน",
    HospitalDistrict: "เกาะลันตา",
    HospitalProvince: "กระบี่",
    HospitalTel: "075-684747",
    HospitalWebsite: "",
    HospitalLocation: "7.598579,99.036197",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "3",
    HospitalName: "คลินิกเวชกรรมอินเตอร์เนชั่นแนล (อ่าวนาง เครือ รพ.วัฒนแพทย์ตรัง)",
    HospitalAddress: "721/1 หมู่ 2",
    HospitalSubDistrict: "อ่าวนาง",
    HospitalDistrict: "เมืองกระบี่",
    HospitalProvince: "กระบี่",
    HospitalTel: "091-8499914",
    HospitalWebsite: "",
    HospitalLocation: "8.056151,98.817365",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "4",
    HospitalName: "โรงพยาบาลกระบี่**",
    HospitalAddress: "325 ถนนอุตรกิจ",
    HospitalSubDistrict: "ปากน้ำ",
    HospitalDistrict: "เมืองกระบี่",
    HospitalProvince: "กระบี่",
    HospitalTel: "075-611-212, 075-632-166",
    HospitalWebsite: "",
    HospitalLocation: "8.0763876,98.9139398",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "5",
    HospitalName: "โรงพยาบาลกระบี่นครินทร์อินเตอร์เนชั่นแนล",
    HospitalAddress: "1 ถนนอุตรกิจ",
    HospitalSubDistrict: "ปากน้ำ",
    HospitalDistrict: "เมืองกระบี่",
    HospitalProvince: "กระบี่",
    HospitalTel: "0-7562-6555",
    HospitalWebsite: "www.krabinakharin.co.th",
    HospitalLocation: "8.0810229,98.9055999",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "6",
    HospitalName: "โรงพยาบาลจริยธรรมรวมแพทย์กระบี่",
    HospitalAddress: "529 ถนนอุตรกิจ",
    HospitalSubDistrict: "กระบี่ใหญ่",
    HospitalDistrict: "เมือง",
    HospitalProvince: "กระบี่",
    HospitalTel: "0-75664455",
    HospitalWebsite: "",
    HospitalLocation: "8.101317,98.907955",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "7",
    HospitalName: "โรงพยาบาลวัฒนแพทย์ อ่าวนาง",
    HospitalAddress: "555 ม.5",
    HospitalSubDistrict: "อ่าวนาง",
    HospitalDistrict: "เมือง",
    HospitalProvince: "กระบี่",
    HospitalTel: "075-815555",
    HospitalWebsite: "http://www.aonanghospital.com/th/home",
    HospitalLocation: "8.0564627,98.8175227",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "8",
    HospitalName: "คลินิกเซนต์คาร์ลอสเวชกรรม",
    HospitalAddress: "238/24 ซอยรัชดา 18",
    HospitalSubDistrict: "ห้วยขวาง",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2740616",
    HospitalWebsite: "",
    HospitalLocation: "13.784605,100.576767",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "9",
    HospitalName: "คลินิกทันตกรรม ทันตวันทันตแพทย์ สาขาซอยสิทธิชน",
    HospitalAddress: "97,99,101 ถนนสุทธิสารวินิจฉัย",
    HospitalSubDistrict: "ห้วยขวาง",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2753679-80, 02-6933730",
    HospitalWebsite: "",
    HospitalLocation: "13.789696,100.58",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "10",
    HospitalName: "คลินิกทันตกรรมดิไอวรี่",
    HospitalAddress: "167/16 ถนนรัชดาภิเษก",
    HospitalSubDistrict: "ดินแดง",
    HospitalDistrict: "ดินแดง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2753599",
    HospitalWebsite: "",
    HospitalLocation: "13.7766282,100.5732438",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "11",
    HospitalName: "คลินิกทันตกรรมนราธิวาส",
    HospitalAddress: "48 ถนนนราธิวาสราชนครินทร์",
    HospitalSubDistrict: "สีลม",
    HospitalDistrict: "บางรัก",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-6350995",
    HospitalWebsite: "",
    HospitalLocation: "13.724773,100.529099",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "12",
    HospitalName: "คลินิกเวชกรรมกล้วยน้ำไท สาขาชุมชน 70 ไร่",
    HospitalAddress: "215-217 ล็อค 1 ( 70ไร่ ) ถนนดำรงลัทธพิพัฒน์",
    HospitalSubDistrict: "คลองเตย",
    HospitalDistrict: "คลองเตย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2671-4053",
    HospitalWebsite: "",
    HospitalLocation: "13.7111326,100.5726115",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "13",
    HospitalName: "คลินิกเวชกรรมกล้วยน้ำไท สาขาพหลโยธิน",
    HospitalAddress: "408/23 ถ.พหลโยธิน",
    HospitalSubDistrict: "พญาไท",
    HospitalDistrict: "ราชเทวี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "",
    HospitalWebsite: "",
    HospitalLocation: "13.8862104,100.5802035",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "14",
    HospitalName: "คลินิกเวชกรรมกล้วยน้ำไท สาขาราม 2",
    HospitalAddress: "136/1-2 หมู่ 8 ถนนรามคำแหง2",
    HospitalSubDistrict: "ดอกไม้",
    HospitalDistrict: "ประเวศ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2751-6864",
    HospitalWebsite: "",
    HospitalLocation: "13.7027166,100.579837",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "15",
    HospitalName: "คลินิกเวชกรรมกล้วยน้ำไท สาขาสาทร",
    HospitalAddress: "217 สาทร 11 ถนนสาทรใต้",
    HospitalSubDistrict: "",
    HospitalDistrict: "สาทร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2676-2896-7",
    HospitalWebsite: "",
    HospitalLocation: "13.7120367,100.5325246",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "16",
    HospitalName: "คลินิกเวชกรรมกล้วยน้ำไท สาขาสุขุมวิท 56",
    HospitalAddress: "18/2 สุขุมวิท 56 ถนนสุขุมวิท",
    HospitalSubDistrict: "บางนา",
    HospitalDistrict: "พระโขนง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2741-6774",
    HospitalWebsite: "",
    HospitalLocation: "13.7000318,100.5978168",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "17",
    HospitalName: "คลินิกเวชกรรมกล้วยน้ำไท สาขาสุภาพงษ์ 3",
    HospitalAddress: "13/69 สุภาพงษ์3 ถนนศรีนครินทร์",
    HospitalSubDistrict: "หนองบอน",
    HospitalDistrict: "ประเวศ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2748-1067",
    HospitalWebsite: "",
    HospitalLocation: "13.6930309,100.6391393",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "18",
    HospitalName: "คลินิกเวชกรรมกล้วยน้ำไท สาขาอ่อนนุช",
    HospitalAddress: "227/1, 229 สุขุมวิท 77 ถนนสุขุมวิท",
    HospitalSubDistrict: "พระโขนงเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-27424398",
    HospitalWebsite: "",
    HospitalLocation: "13.7049956,100.5961834",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "19",
    HospitalName: "คลินิกเวชกรรมจุฬารัตน์ 7",
    HospitalAddress: "142/5-8 ถนนอ่อนนุช - ลาดกระบัง",
    HospitalSubDistrict: "ประเวศ",
    HospitalDistrict: "ประเวศ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2329-1559-60 , 0-2328-7653",
    HospitalWebsite: "",
    HospitalLocation: "13.7217882,100.7052343",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "20",
    HospitalName: "คลินิกเวชกรรมจุฬารัตน์ 8",
    HospitalAddress: "342/7-8 ตรงข้าม สนง.ลาดกระบัง ถนนลาดกระบัง",
    HospitalSubDistrict: "ลาดกระบัง",
    HospitalDistrict: "ลาดกระบัง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2326-7993-4 , 0-2326-7104",
    HospitalWebsite: "",
    HospitalLocation: "13.7217328,100.7839632",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "21",
    HospitalName: "คลินิกเวชกรรมเปาโล สาขาซันทาวเวอร์สบี (คลินิกเวชกรรมเมโย สาขาซันทาวเวอร์สบี)",
    HospitalAddress: "123 ชั้น 11 อาคารซันทาวเวอร์สบี ถนนวิภาวดีรังสิต",
    HospitalSubDistrict: "จอมพล",
    HospitalDistrict: "จตุจักร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0 2617 6474",
    HospitalWebsite: "",
    HospitalLocation: "13.8012638,100.5525424",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "22",
    HospitalName: "คลินิกเวชกรรมเปาโล สาขารสาทาวเวอร์อาคาร 2 (คลินิกเวชกรรมเมโย สาขารสาทาวเวอร์อาคาร 2)",
    HospitalAddress: "555 อาคาร รสา ทาวเวอร์ (อาคาร2) ถนนจตุจักร",
    HospitalSubDistrict: "จตุจักร",
    HospitalDistrict: "จตุจักร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2937-1200",
    HospitalWebsite: "",
    HospitalLocation: "13.8197679,100.5640014",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "23",
    HospitalName: "เซ็นทรัลเมดิก คลินิกเวชกรรม สาขาเกษตร",
    HospitalAddress: "2127 ถนนงามวงศ์วาน",
    HospitalSubDistrict: "ลาดยาว",
    HospitalDistrict: "จตุจักร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2941-1440",
    HospitalWebsite: "",
    HospitalLocation: "13.8398799,100.5727224",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "24",
    HospitalName: "เซ็นทรัลเมดิก คลินิกเวชกรรม สาขาเซนต์หลุยส์",
    HospitalAddress: "9/9 ถนนจันทน์",
    HospitalSubDistrict: "ทุ่งวัดดอน",
    HospitalDistrict: "สาทร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2673-1773",
    HospitalWebsite: "",
    HospitalLocation: "13.7046222,100.5236591",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "25",
    HospitalName: "เซ็นทรัลเมดิก คลินิกเวชกรรม สาขาดินแดง",
    HospitalAddress: "1026 ถนนประชาสงเคราะห์",
    HospitalSubDistrict: "ดินแดง",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2644-1644",
    HospitalWebsite: "",
    HospitalLocation: "13.7582336,100.5531973",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "26",
    HospitalName: "เซ็นทรัลเมดิก คลินิกเวชกรรม สาขาบางชัน",
    HospitalAddress: "92/16-17 ถ.สุขาภิบาล 2",
    HospitalSubDistrict: "บางชัน",
    HospitalDistrict: "คลองสามวา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-919-8933 ",
    HospitalWebsite: "",
    HospitalLocation: "13.7782961,100.6672951",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "27",
    HospitalName: "เซ็นทรัลเมดิก คลินิกเวชกรรม สาขาพระโขนง",
    HospitalAddress: "40/1 ถนนสุขุมวิท 71",
    HospitalSubDistrict: "พระโขนงเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2711-0460",
    HospitalWebsite: "",
    HospitalLocation: "13.7159451,100.5930733",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "28",
    HospitalName: "เซ็นทรัลเมดิก คลินิกเวชกรรม สาขารัชดา",
    HospitalAddress: "3062 ถนนประชาสงเคราะห์",
    HospitalSubDistrict: "ดินแดง",
    HospitalDistrict: "ดินแดง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2692-5206",
    HospitalWebsite: "",
    HospitalLocation: "13.7758153,100.5657661",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "29",
    HospitalName: "เทเลแคร์คลินิก สุขาภิบาล 3",
    HospitalAddress: "440/18-20",
    HospitalSubDistrict: "สะพานสูง",
    HospitalDistrict: "สะพานสูง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2308-7170",
    HospitalWebsite: "",
    HospitalLocation: "13.792139,100.701915",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "30",
    HospitalName: "ธรรมสุนทรคลินิกเวชกรรม",
    HospitalAddress: "39/14-15 ถนนปิ่นเกล้านครชัยศรี",
    HospitalSubDistrict: "ตลิ่งชัน",
    HospitalDistrict: "ตลิ่งชัน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8808537",
    HospitalWebsite: "",
    HospitalLocation: "13.727073, 100.502992",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "31",
    HospitalName: "ธรรมสุนทรคลินิกเวชกรรม สาขาคลองสาน",
    HospitalAddress: "534 ถนนเจริญรัถ",
    HospitalSubDistrict: "คลองต้นไทร",
    HospitalDistrict: "คลองสาน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8623323 ต่อ 111",
    HospitalWebsite: "",
    HospitalLocation: "13.718902, 100.501518",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "32",
    HospitalName: "นวมินทร์ 3 สหคลินิก",
    HospitalAddress: "82 ซอยฉลองกรุง 31",
    HospitalSubDistrict: "ลำปลาทิว",
    HospitalDistrict: "ลาดกระบัง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-7396273-4",
    HospitalWebsite: "",
    HospitalLocation: "13.758165, 100.790534",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "33",
    HospitalName: "เนชั่นคลินิกเวชกรรม สาขาบางกะปิ",
    HospitalAddress: "18 ถนนแฮปปี้แลนด์ สาย 2",
    HospitalSubDistrict: "คลองจั่น",
    HospitalDistrict: "บางกะปิ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-7343350",
    HospitalWebsite: "",
    HospitalLocation: "13.767753, 100.643198",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "34",
    HospitalName: "บางปะกอก 2 คลินิกเวชกรรม",
    HospitalAddress: "372-372/1 ถนนเอกชัย",
    HospitalSubDistrict: "บางบอน",
    HospitalDistrict: "บางบอน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0 2-899-0130-2",
    HospitalWebsite: "",
    HospitalLocation: "13.672102,100.3181226",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "35",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมแก้วเงินทอง",
    HospitalAddress: "136 ถ.แก้วเงินทอง",
    HospitalSubDistrict: "บางพรหม",
    HospitalDistrict: "ตลิ่งชัน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-060-8912",
    HospitalWebsite: "",
    HospitalLocation: "13.7551786,100.4548893",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "36",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมลาซาล",
    HospitalAddress: "454, 456, 458 ถ.ลาซาล",
    HospitalSubDistrict: "บางนา",
    HospitalDistrict: "บางนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-060-8911",
    HospitalWebsite: "",
    HospitalLocation: "13.6631865,100.6108357",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "37",
    HospitalName: "โรงพยาบาลกรุงเทพ",
    HospitalAddress: "2 ซอย ศูนย์วิจัย 7 ถนนเพชรบุรีตัดใหม่",
    HospitalSubDistrict: "บางกะปิ",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2310-3000, 0-2318-0066",
    HospitalWebsite: "http://www.bangkokhospital.com",
    HospitalLocation: "13.7484071,100.5835079",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "38",
    HospitalName: "โรงพยาบาลกรุงเทพคริสเตียน",
    HospitalAddress: "124 ถนนสีลม",
    HospitalSubDistrict: "สีลม",
    HospitalDistrict: "บางรัก",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2233-6981-9",
    HospitalWebsite: "http://www.bangkokchristianhospital.org",
    HospitalLocation: "13.728582,100.5309229",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "39",
    HospitalName: "โรงพยาบาลกล้วยน้ำไท 1",
    HospitalAddress: "80 ซอยโรงพยาบาล 2 ถนนพระราม 4",
    HospitalSubDistrict: "พระโขนง",
    HospitalDistrict: "คลองเตย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2769-2000",
    HospitalWebsite: "http://www.kluaynamthai.com",
    HospitalLocation: "13.7141819,100.5871282",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "40",
    HospitalName: "โรงพยาบาลกล้วยน้ำไท 2",
    HospitalAddress: "27 ซอยโสภณ ถนนสุขุมวิท 68",
    HospitalSubDistrict: "บางนา",
    HospitalDistrict: "บางนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2399-4260, 0-2399-4259-63",
    HospitalWebsite: "http://www.kluaynamthai.com",
    HospitalLocation: "13.714020, 100.587592",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "41",
    HospitalName: "โรงพยาบาลเกษมราษฎร์ รามคำแหง(โรงพยาบาลการุญเวช สุขาภิบาล 3)",
    HospitalAddress: "88,90,92 ถนนรามคำแหง",
    HospitalSubDistrict: "สะพานสูง",
    HospitalDistrict: "สะพานสูง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2729-3000",
    HospitalWebsite: "http://www.kasemrad.co.th",
    HospitalLocation: "13.776667, 100.674056",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "42",
    HospitalName: "โรงพยาบาลเกษมราษฎร์บางแค",
    HospitalAddress: "586,588 ถนนเพขรเกษม",
    HospitalSubDistrict: "บางแคเหนือ",
    HospitalDistrict: "บางแค",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2455-4500",
    HospitalWebsite: "http://www.kasemrad.co.th",
    HospitalLocation: "13.7105815,100.3968336",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "43",
    HospitalName: "โรงพยาบาลเกษมราษฎร์ประชาชื่น",
    HospitalAddress: "950 ถนนประชาชื่น",
    HospitalSubDistrict: "บางซื่อ",
    HospitalDistrict: "บางซื่อ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2910-1600-49",
    HospitalWebsite: "http://www.kasemrad.co.th",
    HospitalLocation: "13.8318064,100.5376146",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "44",
    HospitalName: "โรงพยาบาลคลองตัน**",
    HospitalAddress: "3284 ถนนเพชรบุรีตัดใหม่",
    HospitalSubDistrict: "บางกะปิ",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2319-2101-5",
    HospitalWebsite: "www.klongtun-hospital.com",
    HospitalLocation: "13.742556, 100.594556",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "45",
    HospitalName: "โรงพยาบาลคามิลเลียน",
    HospitalAddress: "423 ซอยทองหล่อ ถนนสุขุมวิท 55",
    HospitalSubDistrict: "คลองตันเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2185-1444",
    HospitalWebsite: "http://www.camillianhospital.org",
    HospitalLocation: "13.739722, 100.583806",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "46",
    HospitalName: "โรงพยาบาลจักษุรัตนิน",
    HospitalAddress: "80/1 ถนนสุขุมวิท 21",
    HospitalSubDistrict: "คลองเตยเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-26393399",
    HospitalWebsite: "",
    HospitalLocation: "13.746764, 100.563283",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "47",
    HospitalName: "โรงพยาบาลเจ้าพระยา",
    HospitalAddress: "113/44 ถนนบรมราชชนนี",
    HospitalSubDistrict: "อรุณอมรินทร์",
    HospitalDistrict: "บางกอกน้อย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2884-7000, 0-2434-0117,02-434-1111",
    HospitalWebsite: "http://www.chaophya.com",
    HospitalLocation: "13.780405,100.4696042",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "48",
    HospitalName: "โรงพยาบาลเซนต์หลุยส์",
    HospitalAddress: "215 ถนนสาทรใต้",
    HospitalSubDistrict: "ยานนาวา",
    HospitalDistrict: "สาทร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2675-5000",
    HospitalWebsite: "http://www.saintlouis.or.th",
    HospitalLocation: "13.7193679,100.5231695",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "49",
    HospitalName: "โรงพยาบาลเซ็นทรัลเยนเนอรัล",
    HospitalAddress: "290 ถนนพหลโยธิน",
    HospitalSubDistrict: "อนุสาวรีย์",
    HospitalDistrict: "บางเขน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2552-8777",
    HospitalWebsite: "www.cgh.co.th",
    HospitalLocation: "13.889156,100.605531",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "50",
    HospitalName: "โรงพยาบาลตา หู คอ จมูก",
    HospitalAddress: "585 ถ.สิรินธร",
    HospitalSubDistrict: "บางบำหรุ",
    HospitalDistrict: "บางพลัด",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-886-6600-16",
    HospitalWebsite: "",
    HospitalLocation: "13.787512,100.470520",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "51",
    HospitalName: "โรงพยาบาลตำรวจ**",
    HospitalAddress: "492 ถนนพระราม 1",
    HospitalSubDistrict: "ปทุมวัน",
    HospitalDistrict: "ปทุมวัน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2207-6000",
    HospitalWebsite: "www.policehospital.org",
    HospitalLocation: "13.744556, 100.539417",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "52",
    HospitalName: "โรงพยาบาลเทพธารินทร์",
    HospitalAddress: "3850 ถนนพระราม 4",
    HospitalSubDistrict: "พระโขนง",
    HospitalDistrict: "คลองเตย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2348-7000",
    HospitalWebsite: "http://www.theptarin.com",
    HospitalLocation: "13.7144252,100.5756903",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "53",
    HospitalName: "โรงพยาบาลไทยนครินทร์",
    HospitalAddress: "345 หมู่ 11 ถนนบางนา-ตราด กม3.5",
    HospitalSubDistrict: "บางนา",
    HospitalDistrict: "บางนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2361-2727, 0-2361-2828",
    HospitalWebsite: "http://www.thainakarin.co.th",
    HospitalLocation: "13.6684765,100.6367457",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "54",
    HospitalName: "โรงพยาบาลธนบุรี 1",
    HospitalAddress: "34/1 ซอยแสงศึกษา ถนนอิสระภาพ ซอย44",
    HospitalSubDistrict: "บ้านช่างหล่อ",
    HospitalDistrict: "บางกอกน้อย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2412-0020",
    HospitalWebsite: "http://www.thonburihospital.com",
    HospitalLocation: "13.753278, 100.479750",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "55",
    HospitalName: "โรงพยาบาลธนบุรี 2",
    HospitalAddress: "43/4 หมู่ 18 ถนนบรมราชชนนี",
    HospitalSubDistrict: "ศาลาธรรมสพน์",
    HospitalDistrict: "ทวีวัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2448-3845-58",
    HospitalWebsite: "http://www.thonburi2.com",
    HospitalLocation: "13.783417, 100.401000",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "56",
    HospitalName: "โรงพยาบาลนครธน",
    HospitalAddress: "1 ซอย 56 ถนนพระรามที่ 2",
    HospitalSubDistrict: "แสมดำ",
    HospitalDistrict: "บางขุนเทียน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2416-5454,0-2450-9999",
    HospitalWebsite: "http://www.nakornthon.com",
    HospitalLocation: "13.661000, 100.434222",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "57",
    HospitalName: "โรงพยาบาลนวมินทร์",
    HospitalAddress: "47/94 ถนนสีหนุธานุกิจ",
    HospitalSubDistrict: "มีนบุรี",
    HospitalDistrict: "มีนบุรี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2918-5080",
    HospitalWebsite: "http://www.navamin9.com",
    HospitalLocation: "13.811611, 100.721556",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "58",
    HospitalName: "โรงพยาบาลนวมินทร์ 9",
    HospitalAddress: "599 หมู่ 13 ถนนสีหนุธานุกิจ",
    HospitalSubDistrict: "มีนบุรี",
    HospitalDistrict: "มีนบุรี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2518-1818",
    HospitalWebsite: "http://www.navamin9.com",
    HospitalLocation: "13.811601, 100.721636",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "59",
    HospitalName: "โรงพยาบาลบางนา 1",
    HospitalAddress: "1302 หมู่ 11 ถนนบางนา-ตราด กม.3",
    HospitalSubDistrict: "บางนา",
    HospitalDistrict: "บางนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2746-8630-9",
    HospitalWebsite: "",
    HospitalLocation: "13.666944, 100.635444",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "60",
    HospitalName: "โรงพยาบาลบางปะกอก 1",
    HospitalAddress: "2 ถนนเอกชัย-บางบอน",
    HospitalSubDistrict: "บางปะกอก",
    HospitalDistrict: "ราษฎร์บูรณะ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2872-1111",
    HospitalWebsite: "http://www.bangpakokhospital.com",
    HospitalLocation: "13.6791499,100.4982838",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "61",
    HospitalName: "โรงพยาบาลบางปะกอก 8",
    HospitalAddress: "115/524 หมู่ 4 ถนนพระราม2",
    HospitalSubDistrict: "บางบอน",
    HospitalDistrict: "บางบอน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2894-4111",
    HospitalWebsite: "http://www.bangpakokhospital.com",
    HospitalLocation: "13.6631687,100.4060495",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "62",
    HospitalName: "โรงพยาบาลบางปะกอก 9 อินเตอร์เนชั่นแนล",
    HospitalAddress: "362 ซอยสุขสวัสดิ์ 25/1 ถนนสุขสวัสดิ์",
    HospitalSubDistrict: "บางมด",
    HospitalDistrict: "จอมทอง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2877-1111",
    HospitalWebsite: "http://www.bangpakokhospital.com",
    HospitalLocation: "13.6815885,100.474249",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "63",
    HospitalName: "โรงพยาบาลบางไผ่",
    HospitalAddress: "62 ถนนเพชรเกษม",
    HospitalSubDistrict: "ปากคลองภาษีเจริญ",
    HospitalDistrict: "ภาษีเจริญ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-457-0086",
    HospitalWebsite: "",
    HospitalLocation: "13.724903, 100.464938",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "64",
    HospitalName: "โรงพยาบาลบางโพ",
    HospitalAddress: "95 ซอยแดรี่เบลล์ ถนนประชาราษฎร์ สาย 2",
    HospitalSubDistrict: "บางซื่อ",
    HospitalDistrict: "บางซื่อ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2587-0144, 0-2587-0136-55",
    HospitalWebsite: "http://www.bangpo-hospital.com",
    HospitalLocation: "13.8069368,100.522401",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "65",
    HospitalName: "โรงพยาบาลบางมด",
    HospitalAddress: "747 ถนนพระราม2",
    HospitalSubDistrict: "บางมด",
    HospitalDistrict: "จอมทอง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8670606",
    HospitalWebsite: "",
    HospitalLocation: "13.672222, 100.456389",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "66",
    HospitalName: "โรงพยาบาลบำรุงราษฎร์",
    HospitalAddress: "33 ซอย3 วัฒนา ถนนสุขุมวิท",
    HospitalSubDistrict: "คลองเตยเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2667-1000",
    HospitalWebsite: "http://www.bumrungrad.com",
    HospitalLocation: "13.7465,100.5524",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "67",
    HospitalName: "โรงพยาบาลบีแคร์เมดิคอลเซ็นเตอร์",
    HospitalAddress: "29 หมู่ 6 ถนนพหลโยธิน",
    HospitalSubDistrict: "สายไหม",
    HospitalDistrict: "สายไหม",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2523-3359-71",
    HospitalWebsite: "http://www.bcaremedicalcenter.com",
    HospitalLocation: "13.9435762,100.6230712",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "68",
    HospitalName: "โรงพยาบาลบีเอ็นเอช",
    HospitalAddress: "9/1 ซอยคอนแวนต์ ถนนคอนแวนต์",
    HospitalSubDistrict: "สีลม",
    HospitalDistrict: "บางรัก",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2686-2700",
    HospitalWebsite: "http://www.bnhhospital.com",
    HospitalLocation: "13.7249672,100.5346772",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "69",
    HospitalName: "โรงพยาบาลประชาพัฒน์",
    HospitalAddress: "เลขที่ 146 ถนนสุขสวัสดิ์",
    HospitalSubDistrict: "บางปะกอก",
    HospitalDistrict: "ราษฎร์บูรณะ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-427-9966",
    HospitalWebsite: "www.prachapat.com",
    HospitalLocation: "13.6782443,100.498008",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "70",
    HospitalName: "โรงพยาบาลปิยะเวท",
    HospitalAddress: "998 อาคารปิยะเวท ถนนริมคลองสามเสน",
    HospitalSubDistrict: "บางกะปิ",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2625-6500",
    HospitalWebsite: "http://www.piyavate.com",
    HospitalLocation: "13.7536173,100.5791164",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "71",
    HospitalName: "โรงพยาบาลเปาโล เกษตร(โรงพยาบาลเมโย)",
    HospitalAddress: "2012/5-7 ถนนพหลโยธิน",
    HospitalSubDistrict: "เสนานิคม",
    HospitalDistrict: "จตุจักร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2579-1770-4",
    HospitalWebsite: "http://www.paolohealthcare.com",
    HospitalLocation: "13.8354536,100.5732081",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "72",
    HospitalName: "โรงพยาบาลเปาโล พหลโยธิน",
    HospitalAddress: "670/1 ถนนพหลโยธิน",
    HospitalSubDistrict: "สามเสนใน",
    HospitalDistrict: "พญาไท",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2279-7000",
    HospitalWebsite: "http://www.paolohealthcare.com",
    HospitalLocation: "13.7924526,100.5494472",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "73",
    HospitalName: "โรงพยาบาลเปาโล โชคชัย 4",
    HospitalAddress: "1 ถนนโชคชัย4",
    HospitalSubDistrict: "ลาดพร้าว",
    HospitalDistrict: "ลาดพร้าว",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2514-2157-9,0-2514-4140-9",
    HospitalWebsite: "http://www.paolohealthcare.com",
    HospitalLocation: "13.8022672,100.5941696",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "74",
    HospitalName: "โรงพยาบาลพญาไท 1",
    HospitalAddress: "364/1 ถนนศรีอยุธยา",
    HospitalSubDistrict: "ถนนพญาไท",
    HospitalDistrict: "ราชเทวี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2642-7373, 0-2245-2620-1,02640-11111",
    HospitalWebsite: "http://www.phyathai.com",
    HospitalLocation: "13.7564214,100.5377215",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "75",
    HospitalName: "โรงพยาบาลพญาไท 2",
    HospitalAddress: "943-943/1 ถนนพหลโยธิน",
    HospitalSubDistrict: "สามเสนใน",
    HospitalDistrict: "พญาไท",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2617-2444, 0-2617-2424",
    HospitalWebsite: "http://www.phyathai.com",
    HospitalLocation: "13.7703651,100.5405274",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "76",
    HospitalName: "โรงพยาบาลพญาไท 3",
    HospitalAddress: "111 ถนนเพชรเกษม",
    HospitalSubDistrict: "บางด้วน",
    HospitalDistrict: "ภาษีเจริญ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2869-1111,0-2869-1220-2",
    HospitalWebsite: "http://www.phyathai.com",
    HospitalLocation: "13.723421,100.4627695",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "77",
    HospitalName: "โรงพยาบาลพญาไท นวมินทร์",
    HospitalAddress: "44/505 หมู่ 10 ถนนนวมินทร์",
    HospitalSubDistrict: "คลองกุ่ม",
    HospitalDistrict: "บึงกุ่ม",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2944-8015-20, 0-2944-7111",
    HospitalWebsite: "https://www.phyathai.com/branch/PYTN/",
    HospitalLocation: "13.8257021,100.6552312",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "78",
    HospitalName: "โรงพยาบาลพระราม 2",
    HospitalAddress: "78/1 หมู่ 6 ถนนพระราม 2",
    HospitalSubDistrict: "แสมดำ",
    HospitalDistrict: "บางขุนเทียน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2451-4920-30",
    HospitalWebsite: "http://praram2hospital.com/",
    HospitalLocation: "13.651822,100.4206878",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "79",
    HospitalName: "โรงพยาบาลพระรามเก้า",
    HospitalAddress: "99 ถนนพระราม9",
    HospitalSubDistrict: "บางกะปิ",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2248-8020",
    HospitalWebsite: "http://www.praram9.com",
    HospitalLocation: "13.7532788,100.5691141",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "80",
    HospitalName: "โรงพยาบาลเพชรเกษม 2",
    HospitalAddress: "2 ถนนเพชรเกษม 3/2",
    HospitalSubDistrict: "บางแค",
    HospitalDistrict: "บางแค",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2455-5599",
    HospitalWebsite: "http://www.petkasem2hospital.com",
    HospitalLocation: "13.7120279,100.4298342",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "81",
    HospitalName: "โรงพยาบาลเพชรเวช",
    HospitalAddress: "2469/15 ถนนเพชรบุรีตัดใหม่",
    HospitalSubDistrict: "บางกะปิ",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2718-1515,0-2318-0080",
    HospitalWebsite: "http://www.petcharavejhospital.com",
    HospitalLocation: "13.7427427,100.5935935",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "82",
    HospitalName: "โรงพยาบาลแพทย์ปัญญา",
    HospitalAddress: "124-126 ซอยสมานมิตร ถนนรามคำแหง",
    HospitalSubDistrict: "สวนหลวง",
    HospitalDistrict: "สวนหลวง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2314-0726-9",
    HospitalWebsite: "www.phaetpanya.com",
    HospitalLocation: "13.7442671,100.6056966",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "83",
    HospitalName: "โรงพยาบาลมงกุฎวัฒนะ",
    HospitalAddress: "34/40 ถนนแจ้งวัฒนะ",
    HospitalSubDistrict: "ทุ่งสองห้อง",
    HospitalDistrict: "หลักสี่",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2574-5000-9",
    HospitalWebsite: "http://www.mongkutwattana.co.th",
    HospitalLocation: "13.8931886,100.5610465",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "84",
    HospitalName: "โรงพยาบาลมิชชั่น",
    HospitalAddress: "430 ถนนพิษณุโลก",
    HospitalSubDistrict: "สี่แยกมหานาค",
    HospitalDistrict: "ดุสิต",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2282-1100",
    HospitalWebsite: "http://www.mission-hospital.org",
    HospitalLocation: "13.7577704,100.5188668",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "85",
    HospitalName: "โรงพยาบาลยันฮี",
    HospitalAddress: "454 ถนนจรัญสนิทวงศ์",
    HospitalSubDistrict: "บางอ้อ",
    HospitalDistrict: "บางพลัด",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2879-0300",
    HospitalWebsite: "https://th.yanhee.net/",
    HospitalLocation: "13.800033,100.5091446",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "86",
    HospitalName: "โรงพยาบาลรามคำแหง",
    HospitalAddress: "436 ซอยรามคำแหง 34 ถนนรามคำแหง",
    HospitalSubDistrict: "หัวหมาก",
    HospitalDistrict: "บางกะปิ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2374-0200-16, 0-2732-0478-87",
    HospitalWebsite: "http://www.ram-hosp.co.th",
    HospitalLocation: "13.7614436,100.6358369",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "87",
    HospitalName: "โรงพยาบาลรามาธิบดี**",
    HospitalAddress: "เลขที่ 270 ถนนพระรามหก",
    HospitalSubDistrict: "แขวงทุ่งพญาไท",
    HospitalDistrict: "เขตราชเทวี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2011000",
    HospitalWebsite: "",
    HospitalLocation: "13.766057, 100.526701",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "88",
    HospitalName: "โรงพยาบาลราษฎร์บูรณะ",
    HospitalAddress: "377 ซอยสุขสวัสดิ์ 27 ถนนราษฎร์พัฒนา",
    HospitalSubDistrict: "บางปะกอก",
    HospitalDistrict: "ราษฎร์บูรณะ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2427-0175-9,0-2872-1001-5",
    HospitalWebsite: "http://www.rajburana.com",
    HospitalLocation: "13.6775777,100.5028093",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "89",
    HospitalName: "โรงพยาบาลลาดพร้าว",
    HospitalAddress: "2699 ซอย95 ถนนลาดพร้าว",
    HospitalSubDistrict: "วังทองหลาง",
    HospitalDistrict: "วังทองหลาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2932-2929, 0-2530-2556-69",
    HospitalWebsite: "http://www.ladpraohospital.com",
    HospitalLocation: "13.7781697,100.623149",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "90",
    HospitalName: "โรงพยาบาลวชิรพยาบาล**",
    HospitalAddress: "681 ถนนสามเสน",
    HospitalSubDistrict: "วชิรพยาบาล",
    HospitalDistrict: "ดุสิต",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2443000",
    HospitalWebsite: "",
    HospitalLocation: "13.781099, 100.508737",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "91",
    HospitalName: "โรงพยาบาลวิชัยยุทธ",
    HospitalAddress: "114/4 ถนนเศรษฐศิริ",
    HospitalSubDistrict: "สามเสนใน",
    HospitalDistrict: "พญาไท",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2265-7777, 0-2618-6200-10",
    HospitalWebsite: "http://www.vichaiyut.co.th",
    HospitalLocation: "13.7833224,100.533199",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "92",
    HospitalName: "โรงพยาบาลวิชัยเวช แยกไฟฉาย(โรงพยาบาลศรีวิชัย 1)",
    HospitalAddress: "240/1-7 ถนนจรัญสนิทวงศ์",
    HospitalSubDistrict: "บ้านช่างหล่อ",
    HospitalDistrict: "บางกอกน้อย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2412-0055-60",
    HospitalWebsite: "",
    HospitalLocation: "13.755895, 100.469560",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "93",
    HospitalName: "โรงพยาบาลวิชัยเวช อินเตอร์เนชั่นแนล หนองแขม(โรงพยาบาลศรีวิชัย 2)",
    HospitalAddress: "15/38-43 หมู่ 7 ถนนเพชรเกษม",
    HospitalSubDistrict: "หนองค้างพลู",
    HospitalDistrict: "หนองแขม",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2441-6999",
    HospitalWebsite: "https://www.vichaivej.com/nongkhaem",
    HospitalLocation: "13.7073897,100.3595953",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "94",
    HospitalName: "โรงพยาบาลวิภาราม",
    HospitalAddress: "2677 ถนนพัฒนาการ",
    HospitalSubDistrict: "พัฒนาการ",
    HospitalDistrict: "สวนหลวง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2722-2500",
    HospitalWebsite: "http://www.vibharam.com",
    HospitalLocation: "13.7346825,100.6443991",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "95",
    HospitalName: "โรงพยาบาลวิภาวดี",
    HospitalAddress: "51/3 ถนนงามวงศ์วาน",
    HospitalSubDistrict: "ลาดยาว",
    HospitalDistrict: "จตุจักร",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2561-1258-67, 0-2561-1260-7",
    HospitalWebsite: "http://www.vibhavadi.com",
    HospitalLocation: "13.8465326,100.5607634",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "96",
    HospitalName: "โรงพยาบาลเวชธานี",
    HospitalAddress: "1 ซอยลาดพร้าว 111 ถนนลาดพร้าว",
    HospitalSubDistrict: "คลองจั่น",
    HospitalDistrict: "บางกะปิ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2734-0390",
    HospitalWebsite: "http://www.vejthani.com",
    HospitalLocation: "13.7725885,100.6358946",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "97",
    HospitalName: "โรงพยาบาลเวชศาสตร์เขตร้อน",
    HospitalAddress: "อาคารราชนครินทร์ 420/6 ถ.ราชวิถี",
    HospitalSubDistrict: "ทุ่งพญาไท",
    HospitalDistrict: "ราชเทวี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-3069199",
    HospitalWebsite: "",
    HospitalLocation: "13.765750, 100.533458",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "98",
    HospitalName: "โรงพยาบาลศิครินทร์",
    HospitalAddress: "4/29 หมู่ 10 ถนนศรีนครินทร์",
    HospitalSubDistrict: "บางนา",
    HospitalDistrict: "บางนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2366-9900",
    HospitalWebsite: "http://www.sikarin.com",
    HospitalLocation: "13.6543122,100.6458672",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "99",
    HospitalName: "โรงพยาบาลศิริราชปิยมหาราชการุณย์",
    HospitalAddress: "เลขที่ 2 ถนนวังหลัง",
    HospitalSubDistrict: "ศิริราช",
    HospitalDistrict: "บางกอกน้อย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "1474",
    HospitalWebsite: "",
    HospitalLocation: "13.759992, 100.485778",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "100",
    HospitalName: "โรงพยาบาลศิริราชพยาบาล**",
    HospitalAddress: "2 ถนนพรานนก",
    HospitalSubDistrict: "ศิริราช",
    HospitalDistrict: "บางกอกน้อย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2419-7000",
    HospitalWebsite: "www.si.mahidol.ac.th/th",
    HospitalLocation: "13.7594672,100.4849691",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "101",
    HospitalName: "โรงพยาบาลสมิติเวช ไชน่าทาวน์(โรงพยาบาลกรุงเทพ ไชน่าทาวน์)",
    HospitalAddress: "624 ถนนเยาวราช",
    HospitalSubDistrict: "สัมพันธวงศ์",
    HospitalDistrict: "สัมพันธวงศ์",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2118-7888",
    HospitalWebsite: "http://www.bangkokhospital.com",
    HospitalLocation: "13.7378279,100.5126118",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "102",
    HospitalName: "โรงพยาบาลสมิติเวช ธนบุรี",
    HospitalAddress: "337 ถนนสมเด็จพระเจ้าตากสิน",
    HospitalSubDistrict: "สำเหร่",
    HospitalDistrict: "ธนบุรี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2438-0040-5",
    HospitalWebsite: "https://www.samitivejhospitals.com",
    HospitalLocation: "13.7139821,100.4877785",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "103",
    HospitalName: "โรงพยาบาลสมิติเวช ศรีนครินทร์",
    HospitalAddress: "488 ถนนศรีนครินทร์",
    HospitalSubDistrict: "สวนหลวง",
    HospitalDistrict: "สวนหลวง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2731-7000-99",
    HospitalWebsite: "https://www.samitivejhospitals.com",
    HospitalLocation: "13.7487354,100.6361727",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "104",
    HospitalName: "โรงพยาบาลสมิติเวช สุขุมวิท",
    HospitalAddress: "133 ซอยสุขุมวิท 49 ถนนสุขุมวิท 49",
    HospitalSubDistrict: "คลองตันเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2711-8000",
    HospitalWebsite: "https://www.samitivejhospitals.com",
    HospitalLocation: "13.7353284,100.5751039",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "105",
    HospitalName: "โรงพยาบาลสายไหม",
    HospitalAddress: "91 หมู่ 1 ถนนเฉลิมพงษ์",
    HospitalSubDistrict: "สายไหม",
    HospitalDistrict: "สายไหม",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2991-8999",
    HospitalWebsite: "http://www.saimai.co.th",
    HospitalLocation: "13.9242461,100.6823697",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "106",
    HospitalName: "โรงพยาบาลสินแพทย์ รามอินทรา",
    HospitalAddress: "9/99 ถนนรามอินทรา กม.9",
    HospitalSubDistrict: "คันนายาว",
    HospitalDistrict: "คันนายาว",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-7935000",
    HospitalWebsite: "http://www.synphaet.co.th",
    HospitalLocation: "13.8340815,100.6661565",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "107",
    HospitalName: "โรงพยาบาลสินแพทย์ ศรีนครินทร์",
    HospitalAddress: "19/9 ถ.เฉลิมพระเกียรติ ร.9",
    HospitalSubDistrict: "หนองบอน",
    HospitalDistrict: "ประเวศ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-0068888",
    HospitalWebsite: "",
    HospitalLocation: "13.678182,100.648594",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "108",
    HospitalName: "โรงพยาบาลสุขสวัสดิ์",
    HospitalAddress: "272 ถนนสุขสวัสดิ์",
    HospitalSubDistrict: "บางปะกอก",
    HospitalDistrict: "ราษฎร์บูรณะ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2428-5036",
    HospitalWebsite: "http://suksawathospital.com/en",
    HospitalLocation: "13.6754768,100.5012678",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "109",
    HospitalName: "โรงพยาบาลสุขุมวิท",
    HospitalAddress: "1411 ถนนสุขุมวิท",
    HospitalSubDistrict: "พระโขนงเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2391-0011, 0-2714-1300",
    HospitalWebsite: "http://www.sukumvithospital.com",
    HospitalLocation: "13.718817,100.5868606",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "110",
    HospitalName: "โรงพยาบาลเสรีรักษ์",
    HospitalAddress: "44 ถนนเสรีไทย",
    HospitalSubDistrict: "มีนบุรี",
    HospitalDistrict: "มีนบุรี",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-918-9888",
    HospitalWebsite: "http://www.seriruk.co.th",
    HospitalLocation: "13.810855,100.7163058",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "111",
    HospitalName: "โรงพยาบาลหัวเฉียว",
    HospitalAddress: "665 ถนนบำรุงเมือง",
    HospitalSubDistrict: "คลองมหานาค",
    HospitalDistrict: "ป้อมปราบศัตรูพ่าย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2223-1351",
    HospitalWebsite: "http://www.huachiewhospital.com",
    HospitalLocation: "13.7496981,100.5153797",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "112",
    HospitalName: "วีเมดิคอล คลินิกเวชกรรม",
    HospitalAddress: "88/1 ซอยอินทามระ 22 ถนนสุทธิสาร",
    HospitalSubDistrict: "ดินแดง",
    HospitalDistrict: "ดินแดง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2779115-6",
    HospitalWebsite: "",
    HospitalLocation: "13.785542, 100.563773",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "113",
    HospitalName: "ศูนย์การแพทย์อาร์เอสยู เฮลท์แคร์",
    HospitalAddress: "571 อาร์ เอส ยู ทาวเวอร์ ชั้น G, 2,11,12 ซอยสุขุมวิท 31",
    HospitalSubDistrict: "คลองตันเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-6100300",
    HospitalWebsite: "",
    HospitalLocation: "13.733445, 100.566682",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "114",
    HospitalName: "สมิติเวช ดอนเมือง คลินิกเวชกรรม",
    HospitalAddress: "222 ถนนวิภาวดีรังสิต",
    HospitalSubDistrict: "สนามบิน",
    HospitalDistrict: "ดอนเมือง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-5354880-1",
    HospitalWebsite: "",
    HospitalLocation: "13.914292, 100.603864",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "115",
    HospitalName: "สหคลินิกกล้วยน้ำไท สาขาเดอะช้อปปส์ แกรนด์ พระราม 9",
    HospitalAddress: "131/1 ถนนพระราม 9",
    HospitalSubDistrict: "ห้วยขวาง",
    HospitalDistrict: "ห้วยขวาง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-21681112",
    HospitalWebsite: "",
    HospitalLocation: "13.7581873,100.5684928",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "116",
    HospitalName: "สหคลินิกกล้วยน้ำไท สาขาตลาดยิ่งเจริญ",
    HospitalAddress: "259/99 ถนนพหลโยธิน",
    HospitalSubDistrict: "อนุเสาวรีย์",
    HospitalDistrict: "บางเขน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-29725553",
    HospitalWebsite: "",
    HospitalLocation: "13.896177,100.608066",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "117",
    HospitalName: "สหคลินิกกล้วยน้ำไท สาขาทุ่งสองห้อง",
    HospitalAddress: "183/509-510 ซ.แจ้งวัฒนะ 10 ถ.โกสุม-สรงประภา",
    HospitalSubDistrict: "ทุ่งสองห้อง",
    HospitalDistrict: "หลักสี่",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2982-3972",
    HospitalWebsite: "",
    HospitalLocation: "13.8866025,100.5177991",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "118",
    HospitalName: "สหคลินิกกล้วยน้ำไท สาขาสุขุมวิท 93",
    HospitalAddress: "21/5-7อาคารแมนดาริน สุขุมวิท 93 ถนนสุขุมวิท",
    HospitalSubDistrict: "บางจาก",
    HospitalDistrict: "พระโขนง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2742-5661-2",
    HospitalWebsite: "",
    HospitalLocation: "13.6994114,100.604487",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "119",
    HospitalName: "สหคลินิกกล้วยน้ำไท สาขาอโศก",
    HospitalAddress: "217/1 ชั้น G อาคารอโศกทาวเวอร์ส ถนนสุขุมวิท",
    HospitalSubDistrict: "คลองเตยเหนือ",
    HospitalDistrict: "พระโขนง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "0-2769-2000 ต่อ 6194-5",
    HospitalWebsite: "",
    HospitalLocation: "13.7390886,100.5483129",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "120",
    HospitalName: "สหแพทย์หลังสวนโพลีคลินิก",
    HospitalAddress: "80 ซอยหลังสวน ถนนสารสิน",
    HospitalSubDistrict: "ลุมพินี",
    HospitalDistrict: "ปทุมวัน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2523002-4",
    HospitalWebsite: "",
    HospitalLocation: "13.736020, 100.542278",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "121",
    HospitalName: "สามย่านคลินิกเวชกรรม",
    HospitalAddress: "848 ถนนพระราม 4",
    HospitalSubDistrict: "สี่พระยา",
    HospitalDistrict: "บางรัก",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2344063",
    HospitalWebsite: "",
    HospitalLocation: "13.733956, 100.525840",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "122",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขาดินแดง",
    HospitalAddress: "4835-4837 ถนนประชาสงเคราะห์",
    HospitalSubDistrict: "ดินแดง",
    HospitalDistrict: "ดินแดง",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2755003",
    HospitalWebsite: "",
    HospitalLocation: "13.772172, 100.560153",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "123",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขาบางเขน",
    HospitalAddress: "9/150-151 ถนนลาดปลาเค้า",
    HospitalSubDistrict: "อนุเสาวรีย์",
    HospitalDistrict: "บางเขน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-9717752",
    HospitalWebsite: "",
    HospitalLocation: "13.850813, 100.605297",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "124",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขาบางแค",
    HospitalAddress: "601/22-23 ถนนเพชรเกษม",
    HospitalSubDistrict: "หลักสอง",
    HospitalDistrict: "บางแค",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8093541",
    HospitalWebsite: "",
    HospitalLocation: "13.708457, 100.383390",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "125",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขาประชาอุทิศ",
    HospitalAddress: "32/745-746 ถนนประชาอุทิศ",
    HospitalSubDistrict: "ทุ่งครุ",
    HospitalDistrict: "ทุ่งครุ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8736819",
    HospitalWebsite: "",
    HospitalLocation: "13.634304, 100.502247",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "126",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขาราษฎร์บูรณะ",
    HospitalAddress: "43/2-3 ถนนราษฎร์บูรณะ",
    HospitalSubDistrict: "บางปะกอก",
    HospitalDistrict: "ราษฎร์บูรณะ",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8713232",
    HospitalWebsite: "",
    HospitalLocation: "13.676530, 100.502509",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "127",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขา เจริญกรุง",
    HospitalAddress: "985,987 ถนนเจริญกรุง",
    HospitalSubDistrict: "มหาพฤฒาราม",
    HospitalDistrict: "บางรัก",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2344063-4",
    HospitalWebsite: "",
    HospitalLocation: "13.731997,100.515549",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "128",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขา ตลิ่งชัน",
    HospitalAddress: "39/14-15 หมู่6 ถนนบรมราชชนนี",
    HospitalSubDistrict: "ตลิ่งชัน",
    HospitalDistrict: "ตลิ่งชัน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8808537",
    HospitalWebsite: "",
    HospitalLocation: "13.786375,100.462958",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "129",
    HospitalName: "อินทัชเมดิแคร์คลินิกเวชกรรม สาขา คลองสาน",
    HospitalAddress: "534 อาคารอินทัชซิตี้ ถนนเจริญรัถ",
    HospitalSubDistrict: "คลองต้นไทร",
    HospitalDistrict: "คลองสาน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-8808537",
    HospitalWebsite: "",
    HospitalLocation: "13.727031,100502963",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "130",
    HospitalName: "โรงพยาบาลจุฬาลงกรณ์**",
    HospitalAddress: "1873 ถนนพระรามที่ 4",
    HospitalSubDistrict: "ปทุมวัน",
    HospitalDistrict: "ปทุมวัน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-256-4000",
    HospitalWebsite: "https://chulalongkornhospital.go.th",
    HospitalLocation: "13.732449, 100.536821",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "131",
    HospitalName: "ชนเปี่ยมสุขคลินิกเวชกรรม",
    HospitalAddress: "21/883 ม.5 ถนนนวมินทร์",
    HospitalSubDistrict: "คลองกุ่ม",
    HospitalDistrict: "บึงกุ่ม",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2880502",
    HospitalWebsite: "",
    HospitalLocation: "13.799006,100.662876",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "132",
    HospitalName: "โรงพยาบาลจุฬาภรณ์",
    HospitalAddress: "906 ถนนกำแพงเพชร6",
    HospitalSubDistrict: "ตลาดบางเขน",
    HospitalDistrict: "หลักสี่",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-5766833-36",
    HospitalWebsite: "",
    HospitalLocation: "13.880564,100.577940",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "133",
    HospitalName: "โรงพยาบาลสหวิทยาการมะลิ",
    HospitalAddress: "771/1 ซอยเอกชัย85",
    HospitalSubDistrict: "บางบอน",
    HospitalDistrict: "บางบอน",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-0891111",
    HospitalWebsite: "",
    HospitalLocation: "13.672933,100.424221",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "134",
    HospitalName: "โรงพยาบาลเมดพาร์ค",
    HospitalAddress: "3333 ถ.พระราม4",
    HospitalSubDistrict: "คลองเตย",
    HospitalDistrict: "คลองเตย",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-0233333",
    HospitalWebsite: "",
    HospitalLocation: "13.721806,100.556260",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "135",
    HospitalName: "โกลบอลดอกเตอร์อินน์คราวน์พลาซ่า",
    HospitalAddress: "981 โรงแรมฮอลิเดย์อินน์สีลม ชั้นจี",
    HospitalSubDistrict: "สีลม",
    HospitalDistrict: "บางรัก",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2368440 กด 0",
    HospitalWebsite: "",
    HospitalLocation: "13.72499,100.57762",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "136",
    HospitalName: "คลินิกเวชกรรมแพทย์หญิงนิลรัตน์",
    HospitalAddress: "399 ตึกอินเตอร์เชน์21 ชั้นจี",
    HospitalSubDistrict: "คลองเตยเหนือ",
    HospitalDistrict: "วัฒนา",
    HospitalProvince: "กรุงเทพมหานคร",
    HospitalTel: "02-2368440 กด 0",
    HospitalWebsite: "",
    HospitalLocation: "13.73672,100.56177",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "137",
    HospitalName: "โรงพยาบาลกาญจนบุรีเมโมเรียล",
    HospitalAddress: "111 หมู่ 5 ถนนแสงชูโต",
    HospitalSubDistrict: "ท่ามะขาม",
    HospitalDistrict: "เมืองกาญจนบุรี",
    HospitalProvince: "กาญจนบุรี",
    HospitalTel: "0-3462-4184-93",
    HospitalWebsite: "http://www.kanchanaburihealthcare.com",
    HospitalLocation: "14.056425,99.4965093",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "138",
    HospitalName: "โรงพยาบาลท่าเรือ",
    HospitalAddress: "456 ถนนแสงชูโต",
    HospitalSubDistrict: "ท่าเรือ",
    HospitalDistrict: "ท่ามะกา",
    HospitalProvince: "กาญจนบุรี",
    HospitalTel: "034-561084,636792",
    HospitalWebsite: "",
    HospitalLocation: "14.56608,100.7213877",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "139",
    HospitalName: "โรงพยาบาลธนกาญจน์",
    HospitalAddress: "20/20 ถนนแสงชูโต",
    HospitalSubDistrict: "บ้านใต้",
    HospitalDistrict: "เมืองกาญจนบุรี",
    HospitalProvince: "กาญจนบุรี",
    HospitalTel: "0-3462-2366-75",
    HospitalWebsite: "",
    HospitalLocation: "14.0367688,99.4900567",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "140",
    HospitalName: "โรงพยาบาลสมเด็จพระสังฆราชองค์ที่ 19**",
    HospitalAddress: "978/1 หมู่ 3 ถนนแสงชูโต",
    HospitalSubDistrict: "ท่าม่วง",
    HospitalDistrict: "ท่าม่วง",
    HospitalProvince: "กาญจนบุรี",
    HospitalTel: "0-3461-1033 , 0-3462-6268-9",
    HospitalWebsite: "",
    HospitalLocation: "13.9760699,99.6298273",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "141",
    HospitalName: "โรงพยาบาลมะการักษ์",
    HospitalAddress: "47/12 หมู่ 4",
    HospitalSubDistrict: "ท่ามะกา",
    HospitalDistrict: "ท่ามะกา",
    HospitalProvince: "กาญจนบุรี",
    HospitalTel: "034-541859",
    HospitalWebsite: "http://www.makarak.com",
    HospitalLocation: "13.908288,99.767409",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "142",
    HospitalName: "โรงพยาบาลธีรวัฒน์",
    HospitalAddress: "269/3 ถนนธนะผล",
    HospitalSubDistrict: "กาฬสินธุ์",
    HospitalDistrict: "เมืองกาฬสินธุ์",
    HospitalProvince: "กาฬสินธุ์",
    HospitalTel: "0-4381-1757, 043-811757",
    HospitalWebsite: "",
    HospitalLocation: "16.4364908,103.5131721",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "143",
    HospitalName: "โรงพยาบาลเอกชนเมืองกำแพง",
    HospitalAddress: "678/7 ถนนราชดำเนิน 1",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองกำแพงเพชร",
    HospitalProvince: "กำแพงเพชร",
    HospitalTel: "0-5571-6701-4",
    HospitalWebsite: "http://www.akchhospital.com",
    HospitalLocation: "16.4591147,99.5363151",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "144",
    HospitalName: "โรงพยาบาลกรุงเทพ ขอนแก่น",
    HospitalAddress: "888 หมู่ 16",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองขอนแก่น",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "0-4304-2888",
    HospitalWebsite: "www.bangkokhospitalkhonkaen.com",
    HospitalLocation: "16.441602,102.819856",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "145",
    HospitalName: "โรงพยาบาลขอนแก่นราม",
    HospitalAddress: "193 ถนนศรีจันทร์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองขอนแก่น",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "0-4333-3033",
    HospitalWebsite: "www.khonkaenram.com",
    HospitalLocation: "16.4334218,102.8187835",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "146",
    HospitalName: "โรงพยาบาลราชพฤกษ์",
    HospitalAddress: "456 ม.14 ถ.มิตรภาพ",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองขอนแก่น",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "043-333555",
    HospitalWebsite: "www.rph.co.th",
    HospitalLocation: "16.44885,102.83035",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "147",
    HospitalName: "โรงพยาบาลศรีนครินทร์ มหาวิทยาลัยขอนแก่น เฉพาะหอผู้ป่วยพิเศษ ชั้น 14 อาคารสมเด็จพระศรีนครินทราบรมราชชนนีอนุสรณ์**",
    HospitalAddress: "123 ถนนมิตรภาพ",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองขอนแก่น",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "043-363595 ",
    HospitalWebsite: "www.srinagarind.md.kku.ac.th",
    HospitalLocation: "16.4682397,102.8288222",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "148",
    HospitalName: "โรงพยาบาลโคกโพธิ์ไชย",
    HospitalAddress: "",
    HospitalSubDistrict: "บ้านโคก",
    HospitalDistrict: "โคกโพธิ์ไชย",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "0-43216100",
    HospitalWebsite: "",
    HospitalLocation: "16.082542, 102.398053",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "149",
    HospitalName: "โรงพยาบาลชุมแพ",
    HospitalAddress: "82 หมู่1 ถนนมะลิวัลย์",
    HospitalSubDistrict: "ชุมแพ",
    HospitalDistrict: "ชุมแพ",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "0-43311044",
    HospitalWebsite: "www.cphos.go.th",
    HospitalLocation: "16.545749, 102.099497",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "150",
    HospitalName: "โรงพยาบาลขอนแก่น",
    HospitalAddress: "54 ถนนศรีจันทร์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองขอนแก่น",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "043-009900",
    HospitalWebsite: "www.kkh.go.th",
    HospitalLocation: "16.429224, 102.848793",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "151",
    HospitalName: "โรงพยาบาลสีชมพู",
    HospitalAddress: "311 หมู่10",
    HospitalSubDistrict: "วังเพิ่ม",
    HospitalDistrict: "สีชมพู",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "043-399176",
    HospitalWebsite: "",
    HospitalLocation: "16.797477, 102.188001",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "152",
    HospitalName: "โรงพยาบาลมัญจาคีรี",
    HospitalAddress: "316 ถนนมัญจาคีรี-แก้งคร้อ",
    HospitalSubDistrict: "กุดเค้า",
    HospitalDistrict: "มัญจาคีรี",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "043-289415-8",
    HospitalWebsite: "www.manchakirihospital.go.th",
    HospitalLocation: "16.134660, 102.534524",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "153",
    HospitalName: "ชัชชัยคลินิกเวชกรรม",
    HospitalAddress: "249/99-1 หมู่14",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองขอนแก่น",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "084-2454535",
    HospitalWebsite: "",
    HospitalLocation: "16.448069,102.828936",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "154",
    HospitalName: "แอ็บโซลูทเฮลธ์ขอนแก่น คลินิกเวชกรรม",
    HospitalAddress: "277 หมู่4",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองขอนแก่น",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "093-5629542",
    HospitalWebsite: "",
    HospitalLocation: "16.43707,102.84371",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "155",
    HospitalName: "โรงพยาบาลสมเด็จพระยุพราชกระนวน",
    HospitalAddress: "1 หมู่11",
    HospitalSubDistrict: "หนองโก",
    HospitalDistrict: "กระนวน",
    HospitalProvince: "ขอนแก่น",
    HospitalTel: "043-252326-7",
    HospitalWebsite: "",
    HospitalLocation: "16.70640,103.08512",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "156",
    HospitalName: "โรงพยาบาลกรุงเทพ จันทบุรี",
    HospitalAddress: "25/14 ถนนท่าหลวง",
    HospitalSubDistrict: "วัดใหม่",
    HospitalDistrict: "เมืองจันทบุรี",
    HospitalProvince: "จันทบุรี",
    HospitalTel: "0-3931-9888",
    HospitalWebsite: "www.chanthaburihospital.com",
    HospitalLocation: "12.615371, 102.108733",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "157",
    HospitalName: "โรงพยาบาลพระปกเกล้า",
    HospitalAddress: "38 ถนนเลียบเนิน",
    HospitalSubDistrict: "วัดใหม่",
    HospitalDistrict: "เมืองจันทบุรี",
    HospitalProvince: "จันทบุรี",
    HospitalTel: "039-319666",
    HospitalWebsite: "www.ppkhosp.go.th",
    HospitalLocation: "12.6044152,102.1009735",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "158",
    HospitalName: "โรงพยาบาลสิริเวช",
    HospitalAddress: "151 หมู่ 7 ถนนตรีรัตน์",
    HospitalSubDistrict: "จันทนิมิต",
    HospitalDistrict: "เมืองจันทบุรี",
    HospitalProvince: "จันทบุรี",
    HospitalTel: "0-3934-4244",
    HospitalWebsite: "www.sirivejhospital.com",
    HospitalLocation: "12.5983685,102.1203392",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "159",
    HospitalName: "โรงพยาบาลสอยดาว",
    HospitalAddress: "399",
    HospitalSubDistrict: "ปะตง",
    HospitalDistrict: "สอยดาว",
    HospitalProvince: "จันทบุรี",
    HospitalTel: "039-421627",
    HospitalWebsite: "",
    HospitalLocation: "13.132492,102.205266",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "160",
    HospitalName: "คลินิกเวชกรรมจุฬารัตน์ 12(เกตเวย์)",
    HospitalAddress: "215 ต.หัวสำโรง",
    HospitalSubDistrict: "หัวสำโรง",
    HospitalDistrict: "แปลงยาว",
    HospitalProvince: "ฉะเชิงเทรา",
    HospitalTel: "0-3857-5134-5",
    HospitalWebsite: "",
    HospitalLocation: "13.6138514,101.3293277",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "161",
    HospitalName: "คลินิกเวชกรรมโสธราเวช",
    HospitalAddress: "1330-1331 ถนนกบินทร์บุรี-ฉะเชิงเทรา",
    HospitalSubDistrict: "พนมสารคาม",
    HospitalDistrict: "พนมสารคาม",
    HospitalProvince: "ฉะเชิงเทรา",
    HospitalTel: "038-551519",
    HospitalWebsite: "",
    HospitalLocation: "13.748649, 101.360072",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "162",
    HospitalName: "โรงพยาบาลเกษมราษฎร์ฉะเชิงเทรา(โรงพยาบาลโสธราเวช)",
    HospitalAddress: "29 ถนนสุวินทวงศ์",
    HospitalSubDistrict: "หน้าเมือง",
    HospitalDistrict: "เมืองฉะเชิงเทรา",
    HospitalProvince: "ฉะเชิงเทรา",
    HospitalTel: "0-3881-2702-19",
    HospitalWebsite: "",
    HospitalLocation: "13.695555, 101.051555",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "163",
    HospitalName: "โรงพยาบาลจุฬารัตน์บางปะกงปิยะเวช(โรงพยาบาลจุฬารัตน์ 11)",
    HospitalAddress: "185/1 หมู่ 1 ถนนบางนา-ตราด กม.42",
    HospitalSubDistrict: "บางวัว",
    HospitalDistrict: "บางปะกง",
    HospitalProvince: "ฉะเชิงเทรา",
    HospitalTel: "038-538511-3 , 0-3884-0236",
    HospitalWebsite: "",
    HospitalLocation: "13.542719, 100.962911",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "164",
    HospitalName: "โรงพยาบาลพนมสารคาม**",
    HospitalAddress: "490 หมู่ 4",
    HospitalSubDistrict: "ท่าถ่าน",
    HospitalDistrict: "พนมสารคาม",
    HospitalProvince: "ฉะเชิงเทรา",
    HospitalTel: "038-551444",
    HospitalWebsite: "www.phanomsarakhamhospital.com",
    HospitalLocation: "13.7480552,101.3611524",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "165",
    HospitalName: "โรงพยาบาลรวมแพทย์ฉะเชิงเทรา",
    HospitalAddress: "88/122-123 หมู่ที 613 ถนนฉะเชิงเทรา-พนมสารคาม",
    HospitalSubDistrict: "บางตีนเป็ด",
    HospitalDistrict: "เมืองฉะเชิงเทรา",
    HospitalProvince: "ฉะเชิงเทรา",
    HospitalTel: "0-3305-0600",
    HospitalWebsite: "https://www.chularat.com/home.php?lang=th&gid=17",
    HospitalLocation: "13.662750, 101.105082",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "166",
    HospitalName: "โรงพยาบาลพุทธโสธร",
    HospitalAddress: "174 ถ.มรุพงษ์",
    HospitalSubDistrict: "หน้าเมือง",
    HospitalDistrict: "เมืองฉะเชิงเทรา",
    HospitalProvince: "ฉะเชิงเทรา",
    HospitalTel: "038-516790",
    HospitalWebsite: "www.bsh.go.th",
    HospitalLocation: "13.686122,101.073722",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "167",
    HospitalName: "โรงพยาบาลจอมเทียน",
    HospitalAddress: "234/1 หมู่ 11 ถนนสุขุมวิท",
    HospitalSubDistrict: "หนองปรือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3825-9977",
    HospitalWebsite: "",
    HospitalLocation: "12.9124299,100.8968361",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "168",
    HospitalName: "คลินิกกรุงเทพระยอง สาขาบ่อวิน",
    HospitalAddress: "444/21-23",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3833-7969",
    HospitalWebsite: "",
    HospitalLocation: "13.0370755,101.0861929",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "169",
    HospitalName: "คลินิกปิยะเวชช์ บ่อวิน เวชกรรม",
    HospitalAddress: "116/44 ม.3",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-337333",
    HospitalWebsite: "",
    HospitalLocation: "13.040305, 101.085403",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "170",
    HospitalName: "คลินิกพญาไทเวชกรรมบ่อวิน",
    HospitalAddress: "265/55",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3833-7929",
    HospitalWebsite: "Pyts_com2@phyathai.com",
    HospitalLocation: "13.0377763,101.0870496",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "171",
    HospitalName: "คลินิกโรงพยาบาลสมเด็จพระนางเจ้าสิริกิติ์ สาขานาจอมเทียน",
    HospitalAddress: "161/14 ถนนสุขุมวิท",
    HospitalSubDistrict: "",
    HospitalDistrict: "สัตหีบ",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3811-9077",
    HospitalWebsite: "",
    HospitalLocation: "12.688280, 100.981590",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "172",
    HospitalName: "คลินิกโรงพยาบาลสมเด็จพระนางเจ้าสิริกิติ์ สาขาบ่อวิน",
    HospitalAddress: "303/35-36 ม.3 ถนนหมายเลข 1",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3819-7955",
    HospitalWebsite: "",
    HospitalLocation: "13.028168, 101.063042",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "173",
    HospitalName: "คลินิกเวชกรรมสมิติเวช สาขาเครือสหพัฒน์",
    HospitalAddress: "399/27-28",
    HospitalSubDistrict: "หนองขาม",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "082-993-2300-1",
    HospitalWebsite: "",
    HospitalLocation: "13.0998586,100.9532005",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "174",
    HospitalName: "คลินิกเวชกรรมสมิติเวช สาขาบ้านบึง",
    HospitalAddress: "03 / 30. ถนนบ้านบึง - บ้านค่าย",
    HospitalSubDistrict: "บ้านบึง",
    HospitalDistrict: "บ้านบึง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3844-4300",
    HospitalWebsite: "",
    HospitalLocation: "13.306214, 101.122662",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "175",
    HospitalName: "คลินิกเวชกรรมสมิติเวช สาขาแหลมฉบัง",
    HospitalAddress: "49/19",
    HospitalSubDistrict: "ทุ่งสุขลา",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "082-993-2302-3",
    HospitalWebsite: "",
    HospitalLocation: "13.0770439,100.9070619",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "176",
    HospitalName: "คลินิกหมอณัฐ",
    HospitalAddress: "61/17 ม.4",
    HospitalSubDistrict: "ดอนหัวฬ่อ",
    HospitalDistrict: "เมือง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "089-7763514",
    HospitalWebsite: "",
    HospitalLocation: "13.420137, 101.041759",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "177",
    HospitalName: "ใจดีคลินิกเวชกรรม",
    HospitalAddress: "22/19 หมู่ 12",
    HospitalSubDistrict: "นาป่า",
    HospitalDistrict: "เมือง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "086-5809225, 099-8849210",
    HospitalWebsite: "",
    HospitalLocation: "13.413959, 101.023509",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "178",
    HospitalName: "บางพระ เมดิคอล เซนเตอร์",
    HospitalAddress: "238/15 หมู่ 2 ถนนสุขุมวิท",
    HospitalSubDistrict: "บางพระ",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3834-1334",
    HospitalWebsite: "",
    HospitalLocation: "13.2352601,101.0030647",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "179",
    HospitalName: "ปิ่นทองสหคลินิก",
    HospitalAddress: "8/10-11 หมู่ที่ 10 D-Complex",
    HospitalSubDistrict: "หนองขาม",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "086-1387524",
    HospitalWebsite: "",
    HospitalLocation: "13.124034, 100.989009",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "180",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมเขาตาโล",
    HospitalAddress: "209/24-25 หมู่ 10 ถ.สุขุมวิท",
    HospitalSubDistrict: "หนองปรือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "03-833-2296",
    HospitalWebsite: "",
    HospitalLocation: "12.9062969,100.9060463",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "181",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมเขาน้อย",
    HospitalAddress: "99/29-30 หมู่ 5 ถ.สุขุมวิท",
    HospitalSubDistrict: "หนองปรือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "03-806-8952",
    HospitalWebsite: "",
    HospitalLocation: "12.9246281,100.9048937",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "182",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมเนินพลับหวาน",
    HospitalAddress: "33/32-33 หมู่ 13",
    HospitalSubDistrict: "หนองปรือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "03-811-4239",
    HospitalWebsite: "",
    HospitalLocation: "12.9289067,100.9214583",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "183",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมวัดวังหิน",
    HospitalAddress: "74/8 หมู่ 1",
    HospitalSubDistrict: "สุรศักดิ์",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "03-311-2125",
    HospitalWebsite: "",
    HospitalLocation: "13.1595709,100.925586",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "184",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมหนองยายบู่",
    HospitalAddress: "344/6-7 หมู่ 2",
    HospitalSubDistrict: "สุรศักดิ์",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "03-304-7319",
    HospitalWebsite: "",
    HospitalLocation: "13.1499159,100.9426207",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "185",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมห้วยใหญ่",
    HospitalAddress: "38/26-28 หมู่ 1",
    HospitalSubDistrict: "ห้วยใหญ่",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "03-309-0366",
    HospitalWebsite: "",
    HospitalLocation: "12.8607034,100.9273059",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "186",
    HospitalName: "โรงพยาบาลกรุงเทพ พัทยา",
    HospitalAddress: "301 หมู่ 6 ถนนสุขุมวิท กม.143",
    HospitalSubDistrict: "นาเกลือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3825-9999",
    HospitalWebsite: "www.bangkokpattayahospital.com",
    HospitalLocation: "12.953973, 100.906204",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "187",
    HospitalName: "โรงพยาบาลจุฬารัตน์ ชลเวช",
    HospitalAddress: "104/15 ก ซ.หน้าวัดต้นสน ถ.อัคนิวาต",
    HospitalSubDistrict: "บางปลาสร้อย",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3828-4354-6",
    HospitalWebsite: "",
    HospitalLocation: "13.362860, 100.986301",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "188",
    HospitalName: "โรงพยาบาลชลบุรี**",
    HospitalAddress: "69 หมู่ 2 ถนนสุขุมวิท",
    HospitalSubDistrict: "บ้านสวน",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3893-2004, 0-3893-1000",
    HospitalWebsite: "http://www.cbh.moph.go.th",
    HospitalLocation: "13.3980865,100.9810272",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "189",
    HospitalName: "โรงพยาบาลบางละมุง",
    HospitalAddress: "669 หมู่ 5",
    HospitalSubDistrict: "นาเกลือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-427580",
    HospitalWebsite: "",
    HospitalLocation: "12.966736, 100.906044",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "190",
    HospitalName: "โรงพยาบาลปิยะเวชช์ บ่อวิน",
    HospitalAddress: "28/8 หมู่ 8",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "38345333",
    HospitalWebsite: "",
    HospitalLocation: "13.0630645,101.1008051",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "191",
    HospitalName: "โรงพยาบาลพญาไท ศรีราชา",
    HospitalAddress: "90 ถนนศรีราชานคร 3",
    HospitalSubDistrict: "ศรีราชา",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3877-0200-8",
    HospitalWebsite: "www.phyathai-sriracha.com",
    HospitalLocation: "13.1690227,100.9273578",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "192",
    HospitalName: "โรงพยาบาลพัทยาเมโมเรียล",
    HospitalAddress: "328/1 หมู่ 9 ถนนพัทยากลาง",
    HospitalSubDistrict: "หนองปรือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3848-8777",
    HospitalWebsite: "www.pattayamemorial.com",
    HospitalLocation: "12.9356285,100.8862795",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "193",
    HospitalName: "โรงพยาบาลพัทยาอินเตอร์เนชั่นแนล",
    HospitalAddress: "255/4 ม.9 ซอยพัทยา 4 ถนนพัทยาสาย 2",
    HospitalSubDistrict: "หนองปรือ",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-428374",
    HospitalWebsite: "",
    HospitalLocation: "12.944289, 100.887897",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "194",
    HospitalName: "โรงพยาบาลวิภาราม แหลมฉบัง",
    HospitalAddress: "107 หมู่ 10",
    HospitalSubDistrict: "ทุ่งสุขลา",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3849-1888",
    HospitalWebsite: "",
    HospitalLocation: "13.087807, 100.917924",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "195",
    HospitalName: "โรงพยาบาลวิภาราม อมตะนคร",
    HospitalAddress: "700/888 หมู่ 1",
    HospitalSubDistrict: "คลองตำหรุ",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3831-6999",
    HospitalWebsite: "",
    HospitalLocation: "13.421065, 101.002985",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "196",
    HospitalName: "โรงพยาบาลสมเด็จพระนางเจ้าสิริกิติ์**",
    HospitalAddress: "163 หมู่ 1",
    HospitalSubDistrict: "พลูตาหลวง",
    HospitalDistrict: "สัตหีบ",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3824-5735-69",
    HospitalWebsite: "",
    HospitalLocation: "12.6885608,100.9794502",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "197",
    HospitalName: "โรงพยาบาลสมเด็จพระบรมราชเทวี ณ ศรีราชา**",
    HospitalAddress: "290 ถนนเจิมจอมพล",
    HospitalSubDistrict: "ศรีราชา",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3832-0200",
    HospitalWebsite: "somdej_ss01@hotmail.co.th",
    HospitalLocation: "13.1615594,100.9180286",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "198",
    HospitalName: "โรงพยาบาลสมิติเวช ชลบุรี",
    HospitalAddress: "888 หมู่ 3",
    HospitalSubDistrict: "บ้านสวน",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3303-8888",
    HospitalWebsite: "www.samitivejhospitals.com",
    HospitalLocation: "13.3443484,100.9758018",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "199",
    HospitalName: "โรงพยาบาลสมิติเวช ศรีราชา",
    HospitalAddress: "8 ซอยแหลมเกตุ ถนนเจิมจอมพล",
    HospitalSubDistrict: "ศรีราชา",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3832-0300,0-3832-4100-20",
    HospitalWebsite: "www.samitivejhospitals.com",
    HospitalLocation: "13.1791834,100.9284317",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "200",
    HospitalName: "โรงพยาบาลเอกชล",
    HospitalAddress: "68/3 หมู่ 2 ถนนพระยาสัจจา",
    HospitalSubDistrict: "บ้านสวน",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3893-9999, 0-3827-3840-7",
    HospitalWebsite: "www.aikchol.com",
    HospitalLocation: "13.3549565,100.9754632",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "201",
    HospitalName: "โรงพยาบาลเอกชล 2",
    HospitalAddress: "31/2 หมู่ 3 ถนนอ่างศิลา",
    HospitalSubDistrict: "แสนสุข",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3893-9888",
    HospitalWebsite: "www.aikchol.com",
    HospitalLocation: "13.3180885,100.9466169",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "202",
    HospitalName: "โรงพยาบาลมหาวิทยาลัยบูรพา",
    HospitalAddress: "169 ถนนลงหาดบางแสน",
    HospitalSubDistrict: "แสนสุข",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-390580",
    HospitalWebsite: "",
    HospitalLocation: "13.2856472,100.9208191",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "203",
    HospitalName: "สถานพยาบาลอมตะเวชกรรม บ่อวิน",
    HospitalAddress: "7/11 นิคมอุตสาหกรรมอมตะซิตี้",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3834-5847-9",
    HospitalWebsite: "",
    HospitalLocation: "13.023638, 101.072961",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "204",
    HospitalName: "สหคลินิกสมิติเวช",
    HospitalAddress: "7/14-16 ถนนสุขุมวิท",
    HospitalSubDistrict: "ห้วยกะปิ",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-3827-2303,0-3827-2606",
    HospitalWebsite: "",
    HospitalLocation: "13.359344, 100.987838",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "205",
    HospitalName: "สหคลินิกสมิติเวช เจ พาร์ค",
    HospitalAddress: "445/1 ม.5",
    HospitalSubDistrict: "",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "0-8229-932302",
    HospitalWebsite: "",
    HospitalLocation: "13.1537155,100.982796",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "206",
    HospitalName: "บุญประทานคลินิกเวชกรรม สาขา บุญนคร",
    HospitalAddress: "77/12-13 หมู่1",
    HospitalSubDistrict: "บ้านเก่า",
    HospitalDistrict: "พานทอง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "082-2020699,064-6872061",
    HospitalWebsite: "",
    HospitalLocation: "13.454310, 101.021426",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "207",
    HospitalName: "บุญประทานคลินิกเวชกรรม สาขา เดอะวัน",
    HospitalAddress: "127-127/1 หมู่3",
    HospitalSubDistrict: "บ้านเก่า",
    HospitalDistrict: "พานทอง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "097-3573288",
    HospitalWebsite: "",
    HospitalLocation: "13.458306, 101.059091",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "208",
    HospitalName: "บุญประทานคลินิกเวชกรรม สาขา เครือสหพัฒน์",
    HospitalAddress: "305/11-12 หมู่ 1",
    HospitalSubDistrict: "หนองขาม",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "096-2204144",
    HospitalWebsite: "",
    HospitalLocation: "13.089305, 100.953491",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "209",
    HospitalName: "คลินิกเวชกรรมตลาดวัดศรี",
    HospitalAddress: "53/75 หมู่ 1",
    HospitalSubDistrict: "บ้านเก่า",
    HospitalDistrict: "พานทอง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "089-6223688",
    HospitalWebsite: "",
    HospitalLocation: "13.453583,101.018016",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "210",
    HospitalName: "คลินิกเวชกรรมปิ่นทองการแพทย์",
    HospitalAddress: "125/20 หมู่9",
    HospitalSubDistrict: "หนองขาม",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "065-5741155,061-3573921",
    HospitalWebsite: "",
    HospitalLocation: "13.125246,101.022510",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "211",
    HospitalName: "คลินิกแพทย์สาโรจน์ อมรรัตน์ กระดูก ข้อ และสูตินรีเวช บ้านเก่า",
    HospitalAddress: "70/8-9 หมู่1",
    HospitalSubDistrict: "บ้านเก่า",
    HospitalDistrict: "พานทอง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "086-8285514",
    HospitalWebsite: "",
    HospitalLocation: "13.454837,101.022846",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "212",
    HospitalName: "คลินิกแพทย์สาโรจน์ อมรรัตน์ กระดูก ข้อ และสูตินรีเวช พานทอง",
    HospitalAddress: "189/40-41 หมู่10",
    HospitalSubDistrict: "พานทอง",
    HospitalDistrict: "พานทอง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "086-8285514",
    HospitalWebsite: "",
    HospitalLocation: "13.454837,101.022846",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "213",
    HospitalName: "คลินิกแพทย์สาโรจน์ อมรรัตน์ กระดูก ข้อ และสูตินรีเวช ดอนหัวฬ่อ",
    HospitalAddress: "56/10 หมู่5",
    HospitalSubDistrict: "ดอนหัวฬ่อ",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "086-8285514",
    HospitalWebsite: "",
    HospitalLocation: "13.454837,101.022846",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "214",
    HospitalName: "คลินิกแพทย์นิธิศ",
    HospitalAddress: "271/133",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-364247",
    HospitalWebsite: "",
    HospitalLocation: "13.031255,101.105402",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "215",
    HospitalName: "คลินิกเวชกรรมกรุงเทพพัทยา สาขา บางเสร่",
    HospitalAddress: "88/259 หมู่9",
    HospitalSubDistrict: "บางเสร่",
    HospitalDistrict: "สัตหีบ",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-259999",
    HospitalWebsite: "",
    HospitalLocation: "12.762962,100.905485",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "216",
    HospitalName: "คลินิกเวชกรรมกรุงเทพพัทยา สาขา เกาะล้าน",
    HospitalAddress: "203/49 หมู่7",
    HospitalSubDistrict: "",
    HospitalDistrict: "บางละมุง",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-259979",
    HospitalWebsite: "",
    HospitalLocation: "12.924726,100.774861",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "217",
    HospitalName: "คลินิกเวชกรรมกรุงเทพพัทยา สาขา บ่อวิน",
    HospitalAddress: "555/16 หมู่3",
    HospitalSubDistrict: "บ่อวิน",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-259999",
    HospitalWebsite: "",
    HospitalLocation: "13.041549,101.079081",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "218",
    HospitalName: "คลินิกเวชกรรมกรุงเทพพัทยา สาขา เครือสหพัฒน์",
    HospitalAddress: "119/27-28 หมู่11",
    HospitalSubDistrict: "หนองขาม",
    HospitalDistrict: "ศรีราชา",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "038-259980",
    HospitalWebsite: "",
    HospitalLocation: "13.097843,100.952459",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "219",
    HospitalName: "สร้างสุขคลินิกเวชกรรม",
    HospitalAddress: "3/19 หมู่4 ถนนบ้านเก่า5",
    HospitalSubDistrict: "ดอนหัวฬ่อ",
    HospitalDistrict: "เมืองชลบุรี",
    HospitalProvince: "ชลบุรี",
    HospitalTel: "084-8650076",
    HospitalWebsite: "",
    HospitalLocation: "13.4208310,101.414857",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "220",
    HospitalName: "โรงพยาบาลรวมแพทย์ชัยนาท",
    HospitalAddress: "170 ถนนชัยนาท-สุพรรณบุรี",
    HospitalSubDistrict: "บ้านกล้วย",
    HospitalDistrict: "เมืองชัยนาท",
    HospitalProvince: "ชัยนาท",
    HospitalTel: "0 5641 3017-8, 056-421584-5,056-4214578-8",
    HospitalWebsite: "www.rpchainat.com",
    HospitalLocation: "15.1789589,100.1382636",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "221",
    HospitalName: "โรงพยาบาลชัยภูมิรวมแพทย์",
    HospitalAddress: "6/9 ถนนนิเวศรัตน์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองชัยภูมิ",
    HospitalProvince: "ชัยภูมิ",
    HospitalTel: "0-4481-3222",
    HospitalWebsite: "",
    HospitalLocation: "15.8055855,102.0346665",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "222",
    HospitalName: "โรงพยาบาลชัยภูมิราม",
    HospitalAddress: "290/42 หมู่ 6 บ้านหนองสังข์ ถนนชัยภูมิ-สีคิ้ว",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองชัยภูมิ",
    HospitalProvince: "ชัยภูมิ",
    HospitalTel: "044-836-888",
    HospitalWebsite: "",
    HospitalLocation: "15.7885241,102.0285464",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "223",
    HospitalName: "โรงพยาบาลชุมพรเขตรอุดมศักดิ์",
    HospitalAddress: "222 ถนนพิศิษฐ์พยาบาล",
    HospitalSubDistrict: "ท่าตะเภา",
    HospitalDistrict: "เมือง",
    HospitalProvince: "ชุมพร",
    HospitalTel: "0-7750-3672",
    HospitalWebsite: "",
    HospitalLocation: "10.497492, 99.186135",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "224",
    HospitalName: "โรงพยาบาลธนบุรีชุมพร",
    HospitalAddress: "121 หมู่ 3 ถนนชุมพร- ระนอง",
    HospitalSubDistrict: "วังไผ่",
    HospitalDistrict: "เมืองชุมพร",
    HospitalProvince: "ชุมพร",
    HospitalTel: "0-7765-8555",
    HospitalWebsite: "www.thonburi-chumphon.com",
    HospitalLocation: "13.753129,100.4788331",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "225",
    HospitalName: "โรงพยาบาลวิรัชศิลป์",
    HospitalAddress: "18 / 22 ถนนปรมินทรมรรคา",
    HospitalSubDistrict: "ท่าตะเภา",
    HospitalDistrict: "เมืองชุมพร",
    HospitalProvince: "ชุมพร",
    HospitalTel: "0-7750-3238-40, 0-7757-0737-4",
    HospitalWebsite: "www.virajsilp.com",
    HospitalLocation: "10.496128,99.1726888",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "226",
    HospitalName: "โรงพยาบาลหลังสวน",
    HospitalAddress: "82 หมู่5",
    HospitalSubDistrict: "วังตะกอ",
    HospitalDistrict: "หลังสวน",
    HospitalProvince: "ชุมพร",
    HospitalTel: "077-529999",
    HospitalWebsite: "",
    HospitalLocation: "9.983228,99.067112",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "227",
    HospitalName: "โรงพยาบาลละแม",
    HospitalAddress: "45 หมู่7 ถนนเพชรเกษม",
    HospitalSubDistrict: "ละแม",
    HospitalDistrict: "ละแม",
    HospitalProvince: "ชุมพร",
    HospitalTel: "077-559116",
    HospitalWebsite: "",
    HospitalLocation: "9.757451,99.086536",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "228",
    HospitalName: "โรงพยาบาลปากน้ำหลังสวน*",
    HospitalAddress: "138 หมู่ 4",
    HospitalSubDistrict: "ปากน้ำ",
    HospitalDistrict: "หลังสวน",
    HospitalProvince: "ชุมพร",
    HospitalTel: "077-551249",
    HospitalWebsite: "",
    HospitalLocation: "9.964347,99.149929",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "229",
    HospitalName: "โรงพยาบาลสวี",
    HospitalAddress: "120",
    HospitalSubDistrict: "นาโพธิ์",
    HospitalDistrict: "สวี",
    HospitalProvince: "ชุมพร",
    HospitalTel: "077-531206",
    HospitalWebsite: "",
    HospitalLocation: "10.230230,990109263",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "230",
    HospitalName: "คลินิกเกษมราษฎร์ศรีบุรินทร์ สาขาอำเภอเชียงแสน",
    HospitalAddress: "339 หมู่ 6 ถนนต.เวียง",
    HospitalSubDistrict: "เวียง",
    HospitalDistrict: "เชียงแสน",
    HospitalProvince: "เชียงราย",
    HospitalTel: "053-650-131",
    HospitalWebsite: "",
    HospitalLocation: "20.268574, 100.075973",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "231",
    HospitalName: "โรงพยาบาลกรุงเทพเชียงราย",
    HospitalAddress: "369 หมู่ 13 ถนนพหลโยธิน",
    HospitalSubDistrict: "นางแล",
    HospitalDistrict: "เมืองเชียงราย",
    HospitalProvince: "เชียงราย",
    HospitalTel: "05-205-1916",
    HospitalWebsite: "https://www.bangkokhospital-chiangrai.com/",
    HospitalLocation: "19.992643, 99.863670",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "232",
    HospitalName: "โรงพยาบาลเกษมราษฎร์แม่สาย",
    HospitalAddress: "952 หมู่ 1",
    HospitalSubDistrict: "เวียงพางคำ",
    HospitalDistrict: "แม่สาย",
    HospitalProvince: "เชียงราย",
    HospitalTel: "053-731391",
    HospitalWebsite: "",
    HospitalLocation: "20.434861, 99.883104",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "233",
    HospitalName: "โรงพยาบาลเกษมราษฎร์ศรีบุรินทร์",
    HospitalAddress: "115/5 หมู่ 13 ถนนเอเชีย 1",
    HospitalSubDistrict: "สันทราย",
    HospitalDistrict: "เมืองเชียงราย",
    HospitalProvince: "เชียงราย",
    HospitalTel: "0-5391-0999, 0-5371-7499",
    HospitalWebsite: "www.kasemrad.co.th",
    HospitalLocation: "19.8780392,99.8346763",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "234",
    HospitalName: "โรงพยาบาลเชียงรายอินเตอร์",
    HospitalAddress: "235/1 หมู่ 26",
    HospitalSubDistrict: "รอบเวียง",
    HospitalDistrict: "เมืองเชียงราย",
    HospitalProvince: "เชียงราย",
    HospitalTel: "0-9791-88811",
    HospitalWebsite: "http://www.chiangraiinter.com",
    HospitalLocation: "19.8953175,99.8316105",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "235",
    HospitalName: "โรงพยาบาลมหาวิทยาลัยแม่ฟ้าหลวง**",
    HospitalAddress: "333 หมู่ 1",
    HospitalSubDistrict: "ท่าสุด",
    HospitalDistrict: "เมืองเชียงราย",
    HospitalProvince: "เชียงราย",
    HospitalTel: "0-53917563",
    HospitalWebsite: "",
    HospitalLocation: "20.041754,99.8919883",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "236",
    HospitalName: "โรงพยาบาลโอเวอร์บรู๊ค",
    HospitalAddress: "17 ถนนสิงหไคล",
    HospitalSubDistrict: "เวียง",
    HospitalDistrict: "เมืองเชียงราย",
    HospitalProvince: "เชียงราย",
    HospitalTel: "0-5371-1366,053-910-100",
    HospitalWebsite: "",
    HospitalLocation: "19.911852, 99.829115",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "237",
    HospitalName: "โรงพยาบาลกรุงเทพ เชียงใหม่",
    HospitalAddress: "11 ซอย 6",
    HospitalSubDistrict: "หนองป่าครั่ง",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5208-9888",
    HospitalWebsite: "www.bangkokhospital-chiangmai.com",
    HospitalLocation: "18.788252,99.025862",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "238",
    HospitalName: "โรงพยาบาลเชียงใหม่ เมดิคอลเซ็นเตอร์",
    HospitalAddress: "21 ถนนนันทาราม",
    HospitalSubDistrict: "หายยา",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5327-0144-50",
    HospitalWebsite: "http://cmc-hospital.com",
    HospitalLocation: "18.7807761,98.9886498",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "239",
    HospitalName: "โรงพยาบาลเชียงใหม่ใกล้หมอ",
    HospitalAddress: "158 หมู่ 10 ถนนเชียงใหม่-ฮอด",
    HospitalSubDistrict: "ป่าแดด",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5320-0002",
    HospitalWebsite: "www.chiangmaiklaimor-hosp.com",
    HospitalLocation: "18.7612597,98.9714709",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "240",
    HospitalName: "โรงพยาบาลเชียงใหม่ราม",
    HospitalAddress: "8 ถนนบุญเรืองฤทธิ์",
    HospitalSubDistrict: "ศรีภูมิ",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5392-0300",
    HospitalWebsite: "www.chiangmairam.com",
    HospitalLocation: "18.7951147,98.9776321",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "241",
    HospitalName: "โรงพยาบาลเชียงใหม่ฮอสพิทอล(โรงพยาบาลสยามราษฎร์ เชียงใหม่)",
    HospitalAddress: "84/3 ถนนเชียงใหม่-ลำปาง",
    HospitalSubDistrict: "ช้างเผือก",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5321-5020-22",
    HospitalWebsite: "",
    HospitalLocation: "18.810621, 98.981387",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "242",
    HospitalName: "โรงพยาบาลเซ็นทรัลเชียงใหม่เมโมเรียล",
    HospitalAddress: "186/2 ถนนช้างคลาน",
    HospitalSubDistrict: "ช้างคลาน",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5381-9333-40",
    HospitalWebsite: "",
    HospitalLocation: "18.773050, 98.998331",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "243",
    HospitalName: "โรงพยาบาลเทพปัญญา",
    HospitalAddress: "99 หมู่ 5 ถนนเชียงใหม่-พร้าว",
    HospitalSubDistrict: "ฟ้าฮ่าม",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5385-2590-9",
    HospitalWebsite: "www.theppanya.com",
    HospitalLocation: "18.8109495,99.0091446",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "244",
    HospitalName: "โรงพยาบาลเทพปัญญา 2 (โรงพยาบาลช้างเผือก)",
    HospitalAddress: "1/7 ซอย 2 ถนนช้างเผือก",
    HospitalSubDistrict: "ศรีภูมิ",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5322-0022-31",
    HospitalWebsite: "",
    HospitalLocation: "18.798981, 98.986941",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "245",
    HospitalName: "โรงพยาบาลแมคคอร์มิค",
    HospitalAddress: "133 ถนนแก้วนวรัฐ",
    HospitalSubDistrict: "วัดเกต",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5392-1777",
    HospitalWebsite: "www.mccormick.in.th",
    HospitalLocation: "18.7985648,99.0096554",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "246",
    HospitalName: "โรงพยาบาลราชเวช เชียงใหม่",
    HospitalAddress: "316/1 ถนนเชียงใหม่- ลำพูน",
    HospitalSubDistrict: "วัดเกต",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5380-1999",
    HospitalWebsite: "",
    HospitalLocation: "18.766906, 99.004319",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "247",
    HospitalName: "โรงพยาบาลลานนา",
    HospitalAddress: "1 ถนนสุขเกษม",
    HospitalSubDistrict: "ช้างเผือก",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "053-999-777",
    HospitalWebsite: "www.lanna-hospital.com",
    HospitalLocation: "18.8123044,98.9916051",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "248",
    HospitalName: "ศูนย์ศรีพัฒน์ คณะแพทย์ศาสตร์ มหาวิทยาลัยเชียงใหม่",
    HospitalAddress: "110/392 อาคารศรีพัฒน์ ถนนอินทวโรรส",
    HospitalSubDistrict: "ศรีภูมิ",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "0-5394-6505",
    HospitalWebsite: "www.cmed.cmu.ac.th",
    HospitalLocation: "18.7895884,98.9729224",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "249",
    HospitalName: "หมอโตคลินิกเวชกรรม",
    HospitalAddress: "2/20-24 ม.11 ถ.ฝาง-ท่าตอน",
    HospitalSubDistrict: "เวียง",
    HospitalDistrict: "ฝาง",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "053-451624",
    HospitalWebsite: "",
    HospitalLocation: "19.928774, 99.223321",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "250",
    HospitalName: "คลินิกหมอรพี",
    HospitalAddress: "355 ม.4",
    HospitalSubDistrict: "หนองหาร",
    HospitalDistrict: "สันทราย",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "096-6961999",
    HospitalWebsite: "",
    HospitalLocation: "18.896188,99.006434",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "251",
    HospitalName: "โรงพยาบาลนครพิงค์",
    HospitalAddress: "159 หมู่10",
    HospitalSubDistrict: "ดอนแก้ว",
    HospitalDistrict: "แม่ริม",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "053-999200",
    HospitalWebsite: "",
    HospitalLocation: "18.852557,98.967927",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "252",
    HospitalName: "โรงพยาบาลมหาราชนครเชียงใหม่",
    HospitalAddress: "110 ถนนอินทวโรรส",
    HospitalSubDistrict: "ศรีภูมิ",
    HospitalDistrict: "เมืองเชียงใหม่",
    HospitalProvince: "เชียงใหม่",
    HospitalTel: "053-936150",
    HospitalWebsite: "",
    HospitalLocation: "18.789785,98.974619",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "253",
    HospitalName: "โรงพยาบาลตรัง**",
    HospitalAddress: "69 ถนนโคกขัน",
    HospitalSubDistrict: "ทับเที่ยง",
    HospitalDistrict: "เมืองตรัง",
    HospitalProvince: "ตรัง",
    HospitalTel: "0-75218018",
    HospitalWebsite: "www.trangruampat.com",
    HospitalLocation: "7.5689315,99.6187703",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "254",
    HospitalName: "โรงพยาบาลตรังรวมแพทย์",
    HospitalAddress: "61/39 ถนนโดกขัน",
    HospitalSubDistrict: "ทับเที่ยง",
    HospitalDistrict: "เมืองตรัง",
    HospitalProvince: "ตรัง",
    HospitalTel: "0-7521-9985-7, 0-7521-8988",
    HospitalWebsite: "www.trangruampat.com",
    HospitalLocation: "7.5689315,99.6187703",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "255",
    HospitalName: "โรงพยาบาลราชดำเนินตรัง",
    HospitalAddress: "25 ถนนไทรงาม",
    HospitalSubDistrict: "ทับเที่ยง",
    HospitalDistrict: "เมืองตรัง",
    HospitalProvince: "ตรัง",
    HospitalTel: "0-7522-3500-8",
    HospitalWebsite: "",
    HospitalLocation: "7.5574021,99.6064928",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "256",
    HospitalName: "โรงพยาบาลวัฒนแพทย์ตรัง",
    HospitalAddress: "247/2 ถนนพัทลุง",
    HospitalSubDistrict: "ทับเที่ยง",
    HospitalDistrict: "เมืองตรัง",
    HospitalProvince: "ตรัง",
    HospitalTel: "0-7520-5555",
    HospitalWebsite: "www.wattanapat.co.th",
    HospitalLocation: "7.5638943,99.6151753",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "257",
    HospitalName: "คลินิกอินเตอร์เนชั่นแนลเกาะช้าง",
    HospitalAddress: "14/16 หมู่4",
    HospitalSubDistrict: "หาดทรายขาว",
    HospitalDistrict: "เกาะช้าง",
    HospitalProvince: "ตราด",
    HospitalTel: "0-3955-1151-2",
    HospitalWebsite: "",
    HospitalLocation: "12.09247,102.2767494",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "258",
    HospitalName: "โรงพยาบาลกรุงเทพ ตราด",
    HospitalAddress: "376 หมู่ 2 ถนนสุขุมวิท",
    HospitalSubDistrict: "วังกระแจะ",
    HospitalDistrict: "เมืองตราด",
    HospitalProvince: "ตราด",
    HospitalTel: "0-3953-2735, 0-3953-2737",
    HospitalWebsite: "www.bangkoktrathospital.com",
    HospitalLocation: "12.253065, 102.508297",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "259",
    HospitalName: "โรงพยาบาลตราด**",
    HospitalAddress: "108 ถนนสุขุมวิท",
    HospitalSubDistrict: "วังกระแจะ",
    HospitalDistrict: "เมืองตราด",
    HospitalProvince: "ตราด",
    HospitalTel: "0-3951-1285, 0-3951-1040-1",
    HospitalWebsite: "www.trathospital.go.th",
    HospitalLocation: "12.2482733,102.5102854",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "260",
    HospitalName: "โรงพยาบาลแหลมงอบ",
    HospitalAddress: "5/5 หมู่ 1 ถนนตราด-แหลมงอบ",
    HospitalSubDistrict: "แหลมงอบ",
    HospitalDistrict: "แหลมงอบ",
    HospitalProvince: "ตราด",
    HospitalTel: "0-3959-7040",
    HospitalWebsite: "www.laemngophos.org",
    HospitalLocation: "12.1772247,102.4040059",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "261",
    HospitalName: "โรงพยาบาลเขาสมิง",
    HospitalAddress: "75 หมู่1",
    HospitalSubDistrict: "แสนตุ้ง",
    HospitalDistrict: "เขาสมิง",
    HospitalProvince: "ตราด",
    HospitalTel: "039-696413",
    HospitalWebsite: "",
    HospitalLocation: "12.367094,102.395072",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "262",
    HospitalName: "โรงพยาบาลคลองใหญ่",
    HospitalAddress: "1 หมู่9",
    HospitalSubDistrict: "คลองใหญ่",
    HospitalDistrict: "คลองใหญ่",
    HospitalProvince: "ตราด",
    HospitalTel: "039-581116",
    HospitalWebsite: "",
    HospitalLocation: "11.76843,102.88533",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "263",
    HospitalName: "โรงพยาบาลนครแม่สอด อินเตอร์เนชั่นแนล",
    HospitalAddress: "222 หมู่ 9 ถนนแม่ปะ",
    HospitalSubDistrict: "แม่ปะ",
    HospitalDistrict: "แม่สอด",
    HospitalProvince: "ตาก",
    HospitalTel: "0-5551-8200-22",
    HospitalWebsite: "www.msihospital.com",
    HospitalLocation: "16.7359418,98.5697751",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "264",
    HospitalName: "โรงพยาบาลแม่สอด",
    HospitalAddress: "175/16 ถนนศรีพานิช",
    HospitalSubDistrict: "แม่สอด",
    HospitalDistrict: "แม่สอด",
    HospitalProvince: "ตาก",
    HospitalTel: "0-55531224",
    HospitalWebsite: "",
    HospitalLocation: "16.709788, 98.572334",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "265",
    HospitalName: "โรงพยาบาลแม่สอด-ราม",
    HospitalAddress: "3/24 ถนนราษฎร์อุทิศ",
    HospitalSubDistrict: "แม่สอด",
    HospitalDistrict: "แม่สอด",
    HospitalProvince: "ตาก",
    HospitalTel: "0-5553-3912-4",
    HospitalWebsite: "www.maesotram.com",
    HospitalLocation: "16.7112528,98.5628417",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "266",
    HospitalName: "ศูนย์การแพทย์สมเด็จพระเทพรัตนราชสุดาฯ**",
    HospitalAddress: "62 หมู่ 7 ถนนรังสิต-นครนายก",
    HospitalSubDistrict: "องครักษ์",
    HospitalDistrict: "องครักษ์",
    HospitalProvince: "นครนายก",
    HospitalTel: "0-3739-5085-6",
    HospitalWebsite: "http://medicine.swu.ac.th/imsmc/",
    HospitalLocation: "14.1118146,100.9832687",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "267",
    HospitalName: "โรงพยาบาลกรุงเทพ สนามจันทร์",
    HospitalAddress: "1194 ถนนเพชรเกษม",
    HospitalSubDistrict: "สนามจันทร์",
    HospitalDistrict: "เมืองนครปฐม",
    HospitalProvince: "นครปฐม",
    HospitalTel: "0-3421-9600",
    HospitalWebsite: "https://www.bangkokhospital.com",
    HospitalLocation: "13.8057,100.039165",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "268",
    HospitalName: "โรงพยาบาลกรุงเทพคริสเตียน นครปฐม",
    HospitalAddress: "173 ถนน25 มกรา",
    HospitalSubDistrict: "พระปฐมเจดีย์",
    HospitalDistrict: "เมืองนครปฐม",
    HospitalProvince: "นครปฐม",
    HospitalTel: "034-270080-5",
    HospitalWebsite: "https://www.bch.in.th",
    HospitalLocation: "13.8241653,100.0629241",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "269",
    HospitalName: "โรงพยาบาลเทพากร",
    HospitalAddress: "24 ซ.1 ถนนเทศา",
    HospitalSubDistrict: "พระปฐมเจดีย์",
    HospitalDistrict: "เมืองนครปฐม",
    HospitalProvince: "นครปฐม",
    HospitalTel: "0-3421-2718-27",
    HospitalWebsite: "",
    HospitalLocation: "13.820448, 100.063501",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "270",
    HospitalName: "โรงพยาบาลศาลายา",
    HospitalAddress: "95 หมู่ 3 ถนนศาลายา-นครชัยศรี",
    HospitalSubDistrict: "ศาลายา",
    HospitalDistrict: "พุทธมณฑล",
    HospitalProvince: "นครปฐม",
    HospitalTel: "0 -2889-2601-7",
    HospitalWebsite: "www.salayahospital.co.th",
    HospitalLocation: "13.8050831,100.3024546",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "271",
    HospitalName: "คลินิกเวชกรรมกรุงเทพเขาใหญ่",
    HospitalAddress: "9/99 หมู่5 ถนนธนะรัชต์ กิโลเมตรที่ 20",
    HospitalSubDistrict: "หมูสี",
    HospitalDistrict: "ปากช่อง",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "044-429999",
    HospitalWebsite: "",
    HospitalLocation: "14.5339417,101.3707823",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "272",
    HospitalName: "คลินิกเวชกรรมกรุงเทพมวกเหล็ก",
    HospitalAddress: "1308/9 ถนนมิตรภาพ",
    HospitalSubDistrict: "มวกเหล็ก",
    HospitalDistrict: "เมืองนครราชสีมา",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "036-344055-6",
    HospitalWebsite: "",
    HospitalLocation: "14.6527255,101.1989832",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "273",
    HospitalName: "คลินิกเวชกรรมกรุงเทพสูงเนิน",
    HospitalAddress: "808/1",
    HospitalSubDistrict: "สูงเนิน",
    HospitalDistrict: "สูงเนิน",
    HospitalProvince: "โชคชัย",
    HospitalTel: "0-44417419",
    HospitalWebsite: "",
    HospitalLocation: "14.8966059,101.8164491",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "274",
    HospitalName: "คลินิกเวชกรรมกรุงเทพโชคชัย",
    HospitalAddress: "333/14 หมู่12",
    HospitalSubDistrict: "โชคชัย",
    HospitalDistrict: "โชคชัย",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "044-011226",
    HospitalWebsite: "",
    HospitalLocation: "14.741348,102.161465",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "275",
    HospitalName: "โรงพยาบาลกรุงเทพ ปากช่อง",
    HospitalAddress: "5/1 ถนนมิตรภาพ",
    HospitalSubDistrict: "หนองสาหร่าย",
    HospitalDistrict: "ปากช่อง",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-4431-6611",
    HospitalWebsite: "",
    HospitalLocation: "14.718635, 101.431664",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "276",
    HospitalName: "โรงพยาบาลกรุงเทพ ราชสีมา",
    HospitalAddress: "1308/9 ถนนมิตรภาพ",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองนครราชสีมา",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-4426-2000",
    HospitalWebsite: "www.bkh.co.th",
    HospitalLocation: "14.981151, 102.072398",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "277",
    HospitalName: "โรงพยาบาลเซนต์เมรี่",
    HospitalAddress: "307 ถนนมิตรภาพ-หนองคาย",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองนครราชสีมา",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-4424-2385, 0-4427-1626-30",
    HospitalWebsite: "www.smhkorat.com",
    HospitalLocation: "14.9871164,102.0920993",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "278",
    HospitalName: "โรงพยาบาลบัวใหญ่รวมแพทย์",
    HospitalAddress: "5/1 ถนนเทศบาล 5",
    HospitalSubDistrict: "บัวใหญ่",
    HospitalDistrict: "บัวใหญ่",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-4429-2249-53",
    HospitalWebsite: "",
    HospitalLocation: "15.5956064,102.4297359",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "279",
    HospitalName: "โรงพยาบาลป.แพทย์",
    HospitalAddress: "45-53 ถนนไชยณรงค์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองนครราชสีมา",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-4423-4999",
    HospitalWebsite: "",
    HospitalLocation: "14.973183, 102.110284",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "280",
    HospitalName: "โรงพยาบาลมหาวิทยาลัยเทคโนโลยีสุรนารี",
    HospitalAddress: "111 ถนนมหาวิทยาลัย",
    HospitalSubDistrict: "สุรนารี",
    HospitalDistrict: "เมืองนครราชสีมา",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-4422-3000",
    HospitalWebsite: "",
    HospitalLocation: "14.8652968,102.0330933",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "281",
    HospitalName: "โรงพยาบาลริม ลิฟวิ่ง",
    HospitalAddress: "256 หมู่ 3 ถนนราชสีมา-ปักธงชัย",
    HospitalSubDistrict: "หนองจะบก",
    HospitalDistrict: "เมืองนครราชสีมา",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-4431-6611",
    HospitalWebsite: "",
    HospitalLocation: "14.8886066,102.0690208",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "282",
    HospitalName: "โรงพยาบาลเดอะโกลเดนเกท",
    HospitalAddress: "34 ถนนมหาดไทย",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองนครราชสีมา",
    HospitalProvince: "นครราชสีมา",
    HospitalTel: "0-44242658",
    HospitalWebsite: "",
    HospitalLocation: "14.972981, 102.101385",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "283",
    HospitalName: "โรงพยาบาลขนาดเล็กรวมแพทย์ทุ่งสง(สถานพยาบาลรวมแพทย์ทุ่งสง)",
    HospitalAddress: "37 ถนนเวชพฤกษ์พิทักษ์",
    HospitalSubDistrict: "ปากแพรก",
    HospitalDistrict: "ทุ่งสง",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "0-7541-1330",
    HospitalWebsite: "",
    HospitalLocation: "8.164972, 99.677389",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "284",
    HospitalName: "โรงพยาบาลนครคริสเตียน",
    HospitalAddress: "1110/2 ถนนศรีปราชญ์",
    HospitalSubDistrict: "คลัง",
    HospitalDistrict: "เมืองนครศรีธรรมราช",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "0-7531-7110, 075-317109",
    HospitalWebsite: "",
    HospitalLocation: "8.4368664,99.963363",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "285",
    HospitalName: "โรงพยาบาลนครพัฒน์",
    HospitalAddress: "2/99 ถนนพัฒนาการคูขวาง",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองนครศรีธรรมราช",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "0-7530-5999",
    HospitalWebsite: "",
    HospitalLocation: "8.3933515,99.9775016",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "286",
    HospitalName: "โรงพยาบาลนครินทร์",
    HospitalAddress: "61 ถนนอ้อมค่ายวชิรวุธ",
    HospitalSubDistrict: "ท่าวัง",
    HospitalDistrict: "เมืองนครศรีธรรมราช",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "0-7531-2800",
    HospitalWebsite: "www.nakharin.co.th",
    HospitalLocation: "8.4560834,99.966243,477",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "287",
    HospitalName: "โรงพยาบาลมหาราชนครศรีธรรมราช",
    HospitalAddress: "198",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองนครศรีธรรมราช",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "075-340250",
    HospitalWebsite: "",
    HospitalLocation: "8.390042, 99.971517",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "288",
    HospitalName: "โรงพยาบาลสิชล**",
    HospitalAddress: "189 หมู่ 5 ถนนศุภโยคพัฒนา",
    HospitalSubDistrict: "สิชล",
    HospitalDistrict: "สิชล",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "0-7553-5630-4",
    HospitalWebsite: "www.sichon-hospital.com",
    HospitalLocation: "9.0081154,99.9021342",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "289",
    HospitalName: "โรงพยาบาลธนบุรี ทุ่งสง",
    HospitalAddress: "88/8 หมู่ 1",
    HospitalSubDistrict: "ชะมาย",
    HospitalDistrict: "ทุ่งสง",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "075-808888",
    HospitalWebsite: "",
    HospitalLocation: "8.165041,99.661527",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "290",
    HospitalName: "โรงพยาบาลค่ายวชิราวุธ",
    HospitalAddress: "283 ถ.ราชดำเนิน",
    HospitalSubDistrict: "ปากพูน",
    HospitalDistrict: "เมืองนครศรีธรรมราช",
    HospitalProvince: "นครศรีธรรมราช",
    HospitalTel: "075-809581",
    HospitalWebsite: "",
    HospitalLocation: "8.49828,99.95427",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "291",
    HospitalName: "โรงพยาบาลปากน้ำโพ",
    HospitalAddress: "96/12 หมู่ 9 ถนนเอเชีย",
    HospitalSubDistrict: "นครสวรรค์ตก",
    HospitalDistrict: "เมืองนครสวรรค์",
    HospitalProvince: "นครสวรรค์",
    HospitalTel: "0-5622-5501-9",
    HospitalWebsite: "",
    HospitalLocation: "15.6975135,100.1217984",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "292",
    HospitalName: "โรงพยาบาลปากน้ำโพ 2",
    HospitalAddress: "62 ถนนอรรถกวี",
    HospitalSubDistrict: "ปากน้ำโพ",
    HospitalDistrict: "เมืองนครสวรรค์",
    HospitalProvince: "นครสวรรค์",
    HospitalTel: "0-5621-2212-3",
    HospitalWebsite: "",
    HospitalLocation: "15.7087324,100.1372435",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "293",
    HospitalName: "โรงพยาบาลร่มฉัตร",
    HospitalAddress: "748/1 ถนนโกสีย์",
    HospitalSubDistrict: "ปากน้ำโพ",
    HospitalDistrict: "เมืองนครสวรรค์",
    HospitalProvince: "นครสวรรค์",
    HospitalTel: "0-5631-2481-90",
    HospitalWebsite: "",
    HospitalLocation: "15.7004774,100.1312557",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "294",
    HospitalName: "โรงพยาบาลรวมแพทย์นครสวรรค์",
    HospitalAddress: "276,276/2 ถนนสวรรค์วิถี",
    HospitalSubDistrict: "ปากน้ำโพ",
    HospitalDistrict: "เมืองนครสวรรค์",
    HospitalProvince: "นครสวรรค์",
    HospitalTel: "0-5622-3600",
    HospitalWebsite: "",
    HospitalLocation: "15.697761,100.1282422",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "295",
    HospitalName: "โรงพยาบาลศรีสวรรค์",
    HospitalAddress: "33/64 ซอยดาวดึงส์ ถนนดาวดึงส์",
    HospitalSubDistrict: "ปากน้ำโพ",
    HospitalDistrict: "เมืองนครสวรรค์",
    HospitalProvince: "นครสวรรค์",
    HospitalTel: "0-5631-1626-35",
    HospitalWebsite: "www.srisawan.com",
    HospitalLocation: "15.7063621,100.1297504",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "296",
    HospitalName: "เซ็นทรัลเมดิก คลินิกเวชกรรม สาขาปากเกร็ด",
    HospitalAddress: "121/5 ถนนแจ้งวัฒนะ",
    HospitalSubDistrict: "ปากเกร็ด",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2583-4670",
    HospitalWebsite: "",
    HospitalLocation: "13.8900608,100.5616274",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "297",
    HospitalName: "ด๊อกเตอร์ เสตถนัย คลินิกเวชกรรม",
    HospitalAddress: "359/1 ซ.สามัคคี",
    HospitalSubDistrict: "ท่าทราย",
    HospitalDistrict: "เมืองนนทบุรี",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-9976006",
    HospitalWebsite: "",
    HospitalLocation: "13.885637, 100.523217",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "298",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมกฤษดานคร",
    HospitalAddress: "53/47 หมู่ 5 หมู่บ้านกฤษดานคร ถ.แจ้งวัฒนะ",
    HospitalSubDistrict: "บางตลาด",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-982-9314",
    HospitalWebsite: "",
    HospitalLocation: "13.9010629,100.5336148",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "299",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมคลองประปา",
    HospitalAddress: "46/118-119 หมู่ 1",
    HospitalSubDistrict: "บางเขน",
    HospitalDistrict: "เมืองนนทบุรี",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-952-5545",
    HospitalWebsite: "",
    HospitalLocation: "13.8807208,100.5300606",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "300",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมเจ้าพระยา",
    HospitalAddress: "83/19 หมู่ 9 ถ.บางใหญ่-บางม่วง",
    HospitalSubDistrict: "บางเลน",
    HospitalDistrict: "บางใหญ่",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-920-0957",
    HospitalWebsite: "",
    HospitalLocation: "13.8652003,100.4357532",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "301",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมดวงแก้ว",
    HospitalAddress: "77/2 หมู่ 1 ถ.ติวานนท์",
    HospitalSubDistrict: "บ้านใหม่",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-961-3857",
    HospitalWebsite: "",
    HospitalLocation: "13.9475751,100.544032",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "302",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมท่าทราย",
    HospitalAddress: "157 ถ.ประชานิเวศน์ 3",
    HospitalSubDistrict: "ท่าทราย",
    HospitalDistrict: "เมืองนนทบุรี",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-952-9911",
    HospitalWebsite: "",
    HospitalLocation: "13.8708688,100.5328483",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "303",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมธารทอง",
    HospitalAddress: "90/9-10 หมู่ 6 ซ.หมู่บ้านธารทอง 1",
    HospitalSubDistrict: "บางรักพัฒนา",
    HospitalDistrict: "บางบัวทอง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-834-2782",
    HospitalWebsite: "",
    HospitalLocation: "13.9020216,100.3277246",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "304",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมบัวทองเคหะ",
    HospitalAddress: "5/53-54 ม.1",
    HospitalSubDistrict: "บางบัวทอง",
    HospitalDistrict: "บางบัวทอง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-925-9944",
    HospitalWebsite: "",
    HospitalLocation: "13.9349339,100.3975459",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "305",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมบางกรวย",
    HospitalAddress: "20/32-33 หมู่ 8 ซ.ภาณุรังษี",
    HospitalSubDistrict: "บางกรวย",
    HospitalDistrict: "บางกรวย-ไทรน้อย",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-886-3113",
    HospitalWebsite: "",
    HospitalLocation: "13.8054061,100.4882278",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "306",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมบางใหญ่ซิตี้",
    HospitalAddress: "61/25-26 หมู่ 3 ซ.บางใหญ่ซิตี้ ถ.กาญจนาภิเษก",
    HospitalSubDistrict: "เสาธงหิน",
    HospitalDistrict: "บางใหญ่",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-925-9944",
    HospitalWebsite: "",
    HospitalLocation: "13.876765,100.403377",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "307",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมประชาชื่น",
    HospitalAddress: "438 ถ.สามัคคี",
    HospitalSubDistrict: "ท่าทราย",
    HospitalDistrict: "เมืองนนทบุรี",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-980-1350",
    HospitalWebsite: "",
    HospitalLocation: "13.8808724,100.53821",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "308",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมประชานิเวศน์3",
    HospitalAddress: "22/8 หมู่ 2 ซ.วัดบัวขวัญ",
    HospitalSubDistrict: "บางกระสอ",
    HospitalDistrict: "เมืองนนทบุรี",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-952-8727",
    HospitalWebsite: "",
    HospitalLocation: "13.8608033,100.4948419",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "309",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมพระปิ่น3",
    HospitalAddress: "66/11-12 หมู่ 15 ถ.กาญจนาภิเษก",
    HospitalSubDistrict: "บางแม่นาง",
    HospitalDistrict: "บางใหญ่",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-903-3663",
    HospitalWebsite: "",
    HospitalLocation: "13.858632, 100.408252",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "310",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมพฤกษา3",
    HospitalAddress: "46/69-70 หมู่ที่ 8",
    HospitalSubDistrict: "บางคูรัด",
    HospitalDistrict: "บางบัวทอง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-965-4481",
    HospitalWebsite: "",
    HospitalLocation: "13.910877, 100.352843",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "311",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมพิมลราช",
    HospitalAddress: "55/187 หมู่ 3 ถ.บ้านกล้วย-ไทรน้อย",
    HospitalSubDistrict: "พิมลราช",
    HospitalDistrict: "บางบัวทอง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-923-6890",
    HospitalWebsite: "",
    HospitalLocation: "13.9161158,100.3988649",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "312",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมมหาสวัสดิ์",
    HospitalAddress: "599/9-10",
    HospitalSubDistrict: "มหาสวัสดิ์",
    HospitalDistrict: "บางกรวย",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-041-6533",
    HospitalWebsite: "",
    HospitalLocation: "13.8013626,100.4466611",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "313",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมลานทอง",
    HospitalAddress: "40/10 หมู่ 3 ถ.ติวานนท์",
    HospitalSubDistrict: "บางพูด",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-963-2338",
    HospitalWebsite: "",
    HospitalLocation: "13.9310724,100.5160507",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "314",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมสนามบินน้ำ",
    HospitalAddress: "138/4-5 ถ.นนทบุรี",
    HospitalSubDistrict: "ท่าทราย",
    HospitalDistrict: "เมือง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "02-965-4481",
    HospitalWebsite: "",
    HospitalLocation: "13.8811051,100.4968403",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "315",
    HospitalName: "โรงพยาบาลกรุงไทย",
    HospitalAddress: "56/96 หมู่ 5 ถนนติวานนท์",
    HospitalSubDistrict: "ปากเกร็ด",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2582-2299",
    HospitalWebsite: "http://www.krungthaihospital.com",
    HospitalLocation: "13.9130311,100.5078057",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "316",
    HospitalName: "โรงพยาบาลเกษมราษฎร์ รัตนาธิเบศร์(โรงพยาบาลการุญเวช รัตนาธิเบศร์)",
    HospitalAddress: "58 หมู่ 15 ถนนรัตนาธิเบศร์",
    HospitalSubDistrict: "บางรักพัฒนา",
    HospitalDistrict: "บางบัวทอง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2921-3400-9",
    HospitalWebsite: "",
    HospitalLocation: "13.875635, 100.423396",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "317",
    HospitalName: "โรงพยาบาลเกษมราษฎร์ อินเตอร์เนชั่นแนล รัตนาธิเบศร์ (โรงพยาบาลเกษมราษฎร์ รัตนาธิเบศร์)",
    HospitalAddress: "60 หมู่ 6 ถนนตลิ่งชัน-สุพรรณบุรี",
    HospitalSubDistrict: "เสาธงหิน",
    HospitalDistrict: "บางใหญ่",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2594-0020-65",
    HospitalWebsite: "http://www.kasemrad.co.th",
    HospitalLocation: "13.8808448,100.4089826",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "318",
    HospitalName: "โรงพยาบาลชลประทาน**",
    HospitalAddress: "222 หมู่ 1 ถนนติวานนท์",
    HospitalSubDistrict: "บางตลาด",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-25022345",
    HospitalWebsite: "",
    HospitalLocation: "13.899561, 100.502071",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "319",
    HospitalName: "โรงพยาบาลนนทเวช",
    HospitalAddress: "30/8 ถนนงามวงศ์วาน",
    HospitalSubDistrict: "บางเขน",
    HospitalDistrict: "เมืองนนทบุรี",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2589-0102",
    HospitalWebsite: "http://www.nonthavej.co.th",
    HospitalLocation: "13.8560755,100.5408612",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "320",
    HospitalName: "โรงพยาบาลวิภาราม ปากเกร็ด",
    HospitalAddress: "135/215 ถนนแจ้งวัฒนะ",
    HospitalSubDistrict: "ปากเกร็ด",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2960-9655-9",
    HospitalWebsite: "",
    HospitalLocation: "13.899561, 100.502071",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "321",
    HospitalName: "โรงพยาบาลเวิลด์เมดิคอลเซ็นเตอร์",
    HospitalAddress: "44 หมู่ 4 ถนนแจ้งวัฒนะ",
    HospitalSubDistrict: "ปากเกร็ด",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2836-9999",
    HospitalWebsite: "",
    HospitalLocation: "13.905109, 100.524278",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "322",
    HospitalName: "สมิติเวชเมืองทองธานี สหคลินิก",
    HospitalAddress: "47/512 ถนนป๊อปปูล่า 3",
    HospitalSubDistrict: "คลองเกลือ",
    HospitalDistrict: "ปากเกร็ด",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "0-2980-7087-8",
    HospitalWebsite: "",
    HospitalLocation: "13.911119,100.5485402",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "323",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา ปลายบาง",
    HospitalAddress: "60/10-11 ม.3",
    HospitalSubDistrict: "ต.ปลายบาง",
    HospitalDistrict: "อ.บางกรวย",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.81542, 100.39527",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "324",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา ท่าอิฐ",
    HospitalAddress: "199/15-16 ม.3",
    HospitalSubDistrict: "ต.บางรักน้อย",
    HospitalDistrict: "อ.เมือง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.88060, 100.45895",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "325",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา เรวดี",
    HospitalAddress: "519 ถ.เรวดี",
    HospitalSubDistrict: "ต.ตลาดขวัญ",
    HospitalDistrict: "อ.เมือง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.85666,100.501447",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "326",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา บางศรีเมือง",
    HospitalAddress: "50/9-10 ม.1",
    HospitalSubDistrict: "ต.บางศรีเมือง",
    HospitalDistrict: "อ.เมือง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.84028, 100.47816",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "327",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา บางเขน",
    HospitalAddress: "271/1 ถ.กรุงเทพ-นนทบุรี",
    HospitalSubDistrict: "ต.บางเขน",
    HospitalDistrict: "อ.เมือง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.83986, 100.51468",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "328",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา บางรักพัฒนา",
    HospitalAddress: "40/61 ม.3",
    HospitalSubDistrict: "ต.บางรักพัฒนา",
    HospitalDistrict: "อ.บางบัวทอง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.88637, 100.43244",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "329",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา บางกรวย-ไทรน้อย",
    HospitalAddress: "113/6-7 ม.6",
    HospitalSubDistrict: "ต.บางบัวทอง",
    HospitalDistrict: "อ.บางบัวทอง",
    HospitalProvince: "นนทบุรี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.95537, 100.36528",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "330",
    HospitalName: "สถานพยาบาลนราการแพทย์",
    HospitalAddress: "131,133 ถนนพิชิตบำรุง",
    HospitalSubDistrict: "บางนาค",
    HospitalDistrict: "เมืองนราธิวาส",
    HospitalProvince: "นราธิวาส",
    HospitalTel: "0-7351-2282",
    HospitalWebsite: "",
    HospitalLocation: "6.437306,101.822751",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "331",
    HospitalName: "โรงพยาบาลเอกชนบุรีรัมย์",
    HospitalAddress: "197 หมู่ 2 ถนนบุรีรัมย์-พุทไธสง",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองบุรีรัมย์",
    HospitalProvince: "บุรีรัมย์",
    HospitalTel: "0-4461-4100-7",
    HospitalWebsite: "OPD_RAm@buriramramhospital.com",
    HospitalLocation: "15.003928,103.1097286",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "332",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมคลอง3",
    HospitalAddress: "37/6, 41 หมู่ 4 ถ.พฤกษา 13",
    HospitalSubDistrict: "คลองสาม",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-834-0127",
    HospitalWebsite: "",
    HospitalLocation: "14.037821,100.6460435",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "333",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมคลองหนึ่ง",
    HospitalAddress: "38/212 หมู่ 1",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-901-2884",
    HospitalWebsite: "",
    HospitalLocation: "14.0058343,100.6060233",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "334",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมซอยคุณพระ",
    HospitalAddress: "5/29-30 หมู่ 6",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-012-6830",
    HospitalWebsite: "",
    HospitalLocation: "14.0482022,100.6098406",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "335",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมเทพกุญชร",
    HospitalAddress: "58/194-195 หมู่ 14",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-198-2177",
    HospitalWebsite: "",
    HospitalLocation: "14.1210707,100.6140404",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "336",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมไทยสมบูรณ์",
    HospitalAddress: "34/12-13 หมู่ 2",
    HospitalSubDistrict: "คลองสาม",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-191-2522",
    HospitalWebsite: "",
    HospitalLocation: "14.0012529,100.6328728",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "337",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมนวนคร",
    HospitalAddress: "12/27-28 หมู่ 19",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-909-4998",
    HospitalWebsite: "",
    HospitalLocation: "14.1196694,100.5746095",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "338",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมลาดสวาย",
    HospitalAddress: "29/37-39 หมู่ 5",
    HospitalSubDistrict: "ลาดสวาย",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-994-4555",
    HospitalWebsite: "",
    HospitalLocation: "13.9625017,100.6840815",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "339",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมเสมาฟ้าคราม",
    HospitalAddress: "23/19 หมู่",
    HospitalSubDistrict: "คูคต",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-987-5844",
    HospitalWebsite: "",
    HospitalLocation: "13.9703191,100.642999",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "340",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมอู่ทอง",
    HospitalAddress: "95/62-63 หมู่ 3 ถ.ประชาอุทิศ",
    HospitalSubDistrict: "คูคต",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-987-2880",
    HospitalWebsite: "",
    HospitalLocation: "13.9410985,100.659841",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "341",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมไอยรา",
    HospitalAddress: "91/2-4 หมู่ 9",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-034-0911",
    HospitalWebsite: "",
    HospitalLocation: "14.0815548,100.6287764",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "342",
    HospitalName: "โรงพยาบาลกรุงสยามเซนต์คาร์ลอส",
    HospitalAddress: "5/84 หมู่ 2 ถนนติวานนท์",
    HospitalSubDistrict: "บ้านกลาง",
    HospitalDistrict: "เมืองปทุมธานี",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2975-6700-5",
    HospitalWebsite: "http://www.stcarlos.com",
    HospitalLocation: "14.0049999,100.5518156",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "343",
    HospitalName: "โรงพยาบาลการุญเวช ปทุมธานี(โรงพยาบาลนวนคร)",
    HospitalAddress: "98 หมู่ 13 ถนนพหลโยธิน",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2529-4533-41",
    HospitalWebsite: "http:/www.princubon.com",
    HospitalLocation: "14.117224, 100.617858",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "344",
    HospitalName: "โรงพยาบาลเฉพาะทางแม่และเด็กแพทย์รังสิต",
    HospitalAddress: "733/347 หมุ่ 8 ถนนพหลโยธิน",
    HospitalSubDistrict: "คูคต",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2998-9888",
    HospitalWebsite: "www.patrangsit.com",
    HospitalLocation: "13.9637526,100.6206741",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "345",
    HospitalName: "โรงพยาบาลธรรมศาสตร์เฉลิมพระเกียรติ**",
    HospitalAddress: "เลขที่ 95 หมู่ 8 ถนนตำบลคลองหนึ่ง",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-9269455",
    HospitalWebsite: "",
    HospitalLocation: "14.073479, 100.615217",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "346",
    HospitalName: "โรงพยาบาลบางปะกอก-รังสิต 2(โรงพยาบาลเอกปทุม)",
    HospitalAddress: "757 ถนนรังสิต-นครนายก",
    HospitalSubDistrict: "ประชาธิปัตย์",
    HospitalDistrict: "ธัญบุรี",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2996-2211-15",
    HospitalWebsite: "www.bangpakokrangsit.com",
    HospitalLocation: "13.9943108,100.6506724",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "347",
    HospitalName: "โรงพยาบาลปทุมเวช",
    HospitalAddress: "1 ซอยรังสิตปทุมธานี6 ถนนรังสิต-ปทุมธานี",
    HospitalSubDistrict: "ประชาธิปัตย์",
    HospitalDistrict: "ธัญบุรี",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2567-1991-9",
    HospitalWebsite: "http://www.pathumvech.com",
    HospitalLocation: "13.9887135,100.6098246",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "348",
    HospitalName: "โรงพยาบาลเปาโล รังสิต",
    HospitalAddress: "11/1 ถนนรังสิต นครนายก",
    HospitalSubDistrict: "ประชาธิปัตย์",
    HospitalDistrict: "ธัญบุรี",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2577-8111",
    HospitalWebsite: "http://www.paolohospital.com/rangsit",
    HospitalLocation: "13.9859961,100.6189918",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "349",
    HospitalName: "โรงพยาบาลแพทย์รังสิต",
    HospitalAddress: "733/345 หมู่ 8 ถนนพหลโยธิน",
    HospitalSubDistrict: "คูคต",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2998-9999",
    HospitalWebsite: "www.patrangsit.com",
    HospitalLocation: "13.9637526,100.6206741",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "350",
    HospitalName: "โรงพยาบาลภัทรธนบุรี",
    HospitalAddress: "32/410 หมู่ 6 ถนนพหลโยธิน",
    HospitalSubDistrict: "คลองหนึ่ง",
    HospitalDistrict: "คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0 2901 8400-8",
    HospitalWebsite: "http://www.phatara-thonburi.com",
    HospitalLocation: "14.0492274,100.6157557",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "351",
    HospitalName: "โรงพยาบาลสายไหม(คลอง 8)",
    HospitalAddress: "80/77-80 หมู่ 5",
    HospitalSubDistrict: "",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "0-2150-7111",
    HospitalWebsite: "",
    HospitalLocation: "13.945384, 100.777798",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "352",
    HospitalName: "โรงพยาบาลสินแพทย์ ลำลูกกา",
    HospitalAddress: "37/29 หมู่3",
    HospitalSubDistrict: "คูคต",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "02-0069999",
    HospitalWebsite: "www.synphaet.co.th",
    HospitalLocation: "13.931757,100.660647",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "353",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา เมืองเอก ",
    HospitalAddress: "300/98-99 ม.13",
    HospitalSubDistrict: "คูคต",
    HospitalDistrict: "ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.96813, 100.61490",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "354",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา สะพานแดง",
    HospitalAddress: "2/8 ซ.รังสิต-นครนายก20",
    HospitalSubDistrict: "ประชาธิปัตย์",
    HospitalDistrict: "ธัญบุรี",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "14.03899, 100.73409",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "355",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา คลองหก",
    HospitalAddress: "42/104-105 ม.1",
    HospitalSubDistrict: "ต.คลองหก",
    HospitalDistrict: "อ.คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "14.03682,100.73391",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "356",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา คลองสาม",
    HospitalAddress: "41/3 ม.3 ถ.เลียบคลองสาม",
    HospitalSubDistrict: "ต.คลองสาม",
    HospitalDistrict: "อ.คลองหลวง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "14.03081, 100.66432",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "357",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา บางพูน",
    HospitalAddress: "72/14 ม.6",
    HospitalSubDistrict: "ต.บางพูน",
    HospitalDistrict: "อ.เมือง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.99061,100.58162",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "358",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา บางคูวัด",
    HospitalAddress: "127/11 ม.7",
    HospitalSubDistrict: "ต.บางคูวัด",
    HospitalDistrict: "อ.เมือง",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.96312,100.51318",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "359",
    HospitalName: "บ้านอบอุ่นคลิกนิกเวชกรรม สาขา ลาดสวาย",
    HospitalAddress: "64 ม.9",
    HospitalSubDistrict: "ต.ลาดสวาย",
    HospitalDistrict: "อ.ลำลูกกา",
    HospitalProvince: "ปทุมธานี",
    HospitalTel: "095-9600531",
    HospitalWebsite: "",
    HospitalLocation: "13.94728,100.66311",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "360",
    HospitalName: "โรงพยาบาลกรุงเทพ หัวหิน",
    HospitalAddress: "888 ถนนเพชรเกษม",
    HospitalSubDistrict: "หัวหิน",
    HospitalDistrict: "หัวหิน",
    HospitalProvince: "ประจวบคีรีขันธ์",
    HospitalTel: "032-616-800",
    HospitalWebsite: "http://www.bangkokhospital.com",
    HospitalLocation: "12.554098,99.960688",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "361",
    HospitalName: "โรงพยาบาลซานเปาโลหัวหิน",
    HospitalAddress: "222 ถนนเพชรเกษม",
    HospitalSubDistrict: "หัวหิน",
    HospitalDistrict: "หัวหิน",
    HospitalProvince: "ประจวบคีรีขันธ์",
    HospitalTel: "0-3253-2576-85",
    HospitalWebsite: "http://www.sanpaulo.co.th",
    HospitalLocation: "12.5644146,99.9592092",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "362",
    HospitalName: "คลินิกเวชกรรมบุญรักษาการแพทย์",
    HospitalAddress: "274/1-2 หมู่ 2",
    HospitalSubDistrict: "ท่าตูม",
    HospitalDistrict: "ศรีมหาโพธิ",
    HospitalProvince: "ปราจีนบุรี",
    HospitalTel: "092-7028484",
    HospitalWebsite: "",
    HospitalLocation: "13.917789, 101.574138",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "363",
    HospitalName: "โรงพยาบาลจุฬารัตน์ 304 อินเตอร์",
    HospitalAddress: "151 หมู่ 4",
    HospitalSubDistrict: "กรอกสมบูรณ์",
    HospitalDistrict: "ศรีมหาโพธิ",
    HospitalProvince: "ปราจีนบุรี",
    HospitalTel: "0-3721-8654-55",
    HospitalWebsite: "",
    HospitalLocation: "13.897035, 101.605033",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "364",
    HospitalName: "โรงพยาบาลเจ้าพระยาอภัยภูเบศร**",
    HospitalAddress: "เลขที่32/7 หมู่12",
    HospitalSubDistrict: "ท่างาม",
    HospitalDistrict: "เมืองปราจีนบุรี",
    HospitalProvince: "ปราจีนบุรี",
    HospitalTel: "037-211088",
    HospitalWebsite: "www.cpa.go.th",
    HospitalLocation: "14.055062,101.3936371",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "365",
    HospitalName: "โรงพยาบาลเมดิแคร์ 304(โรงพยาบาลโสธรเวช 304)",
    HospitalAddress: "201/1-3 หมู่ 12",
    HospitalSubDistrict: "ท่าตูม",
    HospitalDistrict: "ศรีมหาโพธิ",
    HospitalProvince: "ปราจีนบุรี",
    HospitalTel: "0-3720-9503-5,0-3720-9446",
    HospitalWebsite: "",
    HospitalLocation: "13.87351,101.4908882",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "366",
    HospitalName: "สถานพยาบาลอิมพีเรียล",
    HospitalAddress: "277 ถนนราษฎรดำริ",
    HospitalSubDistrict: "",
    HospitalDistrict: "เมืองปราจีนบุรี",
    HospitalProvince: "ปราจีนบุรี",
    HospitalTel: "0-3721-1587",
    HospitalWebsite: "",
    HospitalLocation: "14.059558, 101.372487",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "367",
    HospitalName: "เลิศบุญคลินิกเวชกรรม",
    HospitalAddress: "609/77 หมู่7",
    HospitalSubDistrict: "ท่าตูม",
    HospitalDistrict: "ศรีมหาโพธิ",
    HospitalProvince: "ปราจีนบุรี",
    HospitalTel: "093-5985987",
    HospitalWebsite: "",
    HospitalLocation: "13.925514,101.562697",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "368",
    HospitalName: "โรงพยาบาลสิโรรส ปัตตานี",
    HospitalAddress: "30/4 หมู่ที่ 4",
    HospitalSubDistrict: "รูสะมิแล",
    HospitalDistrict: "เมืองปัตตานี",
    HospitalProvince: "ปัตตานี",
    HospitalTel: "0-7334-8100",
    HospitalWebsite: "",
    HospitalLocation: "6.856630, 101.223682",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "369",
    HospitalName: "ศิรารักษ์คลินิกแพทย์",
    HospitalAddress: "118/43-44 ถนนนาเกลือ",
    HospitalSubDistrict: "",
    HospitalDistrict: "เมืองปัตตานี",
    HospitalProvince: "ปัตตานี",
    HospitalTel: "0-7341-4880",
    HospitalWebsite: "",
    HospitalLocation: "6.8774072,101.2592618",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "370",
    HospitalName: "คลินิกเวชกรรมแพทย์อภิชัย",
    HospitalAddress: "80/36 หมู่11 ถนนอุดมสรยุทธ",
    HospitalSubDistrict: "บ้านเลน",
    HospitalDistrict: "บางปะอิน",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "080-4492496, 0869892496",
    HospitalWebsite: "",
    HospitalLocation: "14.218470, 100.580470",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "371",
    HospitalName: "โรงพยาบาลการุญเวช อยุธยา(โรงพยาบาลนวนคร อยุธยา)",
    HospitalAddress: "61/9 ถนนสายเอเชีย กม.56",
    HospitalSubDistrict: "คลองจิก",
    HospitalDistrict: "บางปะอิน",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "035-315100-99",
    HospitalWebsite: "",
    HospitalLocation: "14.211403, 100.608156",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "372",
    HospitalName: "โรงพยาบาลพีรเวช",
    HospitalAddress: "55/5 หมู่ 6 ถนนสายเอเซีย",
    HospitalSubDistrict: "หันตรา",
    HospitalDistrict: "พระนครศรีอยุธยา",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "(035)801555",
    HospitalWebsite: "http://peravech.com/map.html",
    HospitalLocation: "14.3830647,100.6058089",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "373",
    HospitalName: "โรงพยาบาลราชธานี",
    HospitalAddress: "111 หมู่ 3 ถนนโรจนะ",
    HospitalSubDistrict: "คลองสวนพลู",
    HospitalDistrict: "พระนครศรีอยุธยา",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "0-3533-5555-71",
    HospitalWebsite: "http://www.rajthanee.com",
    HospitalLocation: "14.3304875,100.6026328",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "374",
    HospitalName: "โรงพยาบาลราชธานี โรจนะ",
    HospitalAddress: "78 หมู่ 3 ถนนโรจนะ",
    HospitalSubDistrict: "สามเรือน",
    HospitalDistrict: "บางปะอิน",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "0-3524-9249",
    HospitalWebsite: "",
    HospitalLocation: "14.300023, 100.655379",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "375",
    HospitalName: "โรงพยาบาลศุภมิตรเสนา",
    HospitalAddress: "34/4 หมู่ 5 ถนนเสนา-อยุธยา",
    HospitalSubDistrict: "บางนมโค",
    HospitalDistrict: "เสนา",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "0-3528-9572-9",
    HospitalWebsite: "http://www.supamitrhospital.com",
    HospitalLocation: "14.3163806,100.408914",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "376",
    HospitalName: "คลินิกแพทย์อภิสิทธิ์เวชกรรม",
    HospitalAddress: "55/10-12 ถ.ท่าเรือ-ท่าลาน",
    HospitalSubDistrict: "จำปา",
    HospitalDistrict: "ท่าเรือ",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "093-1429365",
    HospitalWebsite: "",
    HospitalLocation: "14.566104,100.749925",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "377",
    HospitalName: "คลินิกท่าหลวงเวชกรรม",
    HospitalAddress: "240 ถ.ท่าเรือ-ท่าลาน",
    HospitalSubDistrict: "จำปา",
    HospitalDistrict: "ท่าเรือ",
    HospitalProvince: "พระนครศรีอยุธยา",
    HospitalTel: "093-1429365",
    HospitalWebsite: "",
    HospitalLocation: "14.566610,100.733201",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "378",
    HospitalName: "โรงพยาบาลพะเยาราม",
    HospitalAddress: "660 หมู่ 3",
    HospitalSubDistrict: "ท่าวังทอง",
    HospitalDistrict: "เมืองพะเยา",
    HospitalProvince: "พะเยา",
    HospitalTel: "0-5441-1111-40",
    HospitalWebsite: "",
    HospitalLocation: "19.178242, 99.901319",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "379",
    HospitalName: "โรงพยาบาลพะเยา",
    HospitalAddress: "269",
    HospitalSubDistrict: "บ้านต๋อม",
    HospitalDistrict: "เมืองพะเยา",
    HospitalProvince: "พะเยา",
    HospitalTel: "054-409300",
    HospitalWebsite: "",
    HospitalLocation: "19.192091,99.877871",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "380",
    HospitalName: "คลินิกเวชกรรมกรุงเทพภูเก็ต สาขา เขาหลัก",
    HospitalAddress: "13/36 หมู่6",
    HospitalSubDistrict: "คึกคัก",
    HospitalDistrict: "ตะกั่วป่า",
    HospitalProvince: "พังงา",
    HospitalTel: "081-3703705",
    HospitalWebsite: "",
    HospitalLocation: "8.661882,98.253631",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "381",
    HospitalName: "คลินิกเวชกรรมสยามอินเตอร์เนชั่นแนล สาขาเขาหลัก",
    HospitalAddress: "27/5 หมู่ 7",
    HospitalSubDistrict: "คึกคัก",
    HospitalDistrict: "ตะกั่วป่า",
    HospitalProvince: "พังงา",
    HospitalTel: "099-1841968",
    HospitalWebsite: "",
    HospitalLocation: "8.645118, 98.251734",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "382",
    HospitalName: "โรงพยาบาลพังงา**",
    HospitalAddress: "436 ถนนเพชรเกษม",
    HospitalSubDistrict: "ท้ายช้าง",
    HospitalDistrict: "เมืองพังงา",
    HospitalProvince: "พังงา",
    HospitalTel: "0-7641-1616 , 0-7641-2032",
    HospitalWebsite: "",
    HospitalLocation: "8.4487582,98.5255225",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "383",
    HospitalName: "โรงพยาบาลตะกั่วป่า",
    HospitalAddress: "39/2 หมู่1 ถนนเพชรเกษม",
    HospitalSubDistrict: "บางนายสี",
    HospitalDistrict: "ตะกั่วป่า",
    HospitalProvince: "พังงา",
    HospitalTel: "076-584250",
    HospitalWebsite: "",
    HospitalLocation: "8.863885,98.334503",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "384",
    HospitalName: "โรงพยาบาลปิยะรักษ์",
    HospitalAddress: "74 ถนนอภัยบริรักษ์",
    HospitalSubDistrict: "คูหาสวรรค์",
    HospitalDistrict: "เมืองพัทลุง",
    HospitalProvince: "พัทลุง",
    HospitalTel: "0-7462-7146",
    HospitalWebsite: "",
    HospitalLocation: "7.6209467,100.0865429",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "385",
    HospitalName: "โรงพยาบาลพัทลุง",
    HospitalAddress: "421 ถนนราเมศวร์",
    HospitalSubDistrict: "คูหาสวรรค์",
    HospitalDistrict: "เมือง",
    HospitalProvince: "พัทลุง",
    HospitalTel: "074-609500-20",
    HospitalWebsite: "www.ptlhosp.go.th",
    HospitalLocation: "7.6151715,100.072297",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "386",
    HospitalName: "คลินิกพัทลุงอินเตอร์การแพทย์",
    HospitalAddress: "126 หมู่2",
    HospitalSubDistrict: "เขาเจียก",
    HospitalDistrict: "เมือง",
    HospitalProvince: "พัทลุง",
    HospitalTel: "074-621074",
    HospitalWebsite: "",
    HospitalLocation: "7.632514,100.044522",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "387",
    HospitalName: "โรงพยาบาลชัยอรุณเวชการ",
    HospitalAddress: "เลขที่31/12 ถนนสระหลวง",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองพิจิตร",
    HospitalProvince: "พิจิตร",
    HospitalTel: "0-5665-1407",
    HospitalWebsite: "www.chaiaroonhos.com",
    HospitalLocation: "16.4370777,100.3412073",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "388",
    HospitalName: "โรงพยาบาลสหเวช",
    HospitalAddress: "2/158 ถนนศรีมาลา",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองพิจิตร",
    HospitalProvince: "พิจิตร",
    HospitalTel: "0-5661-2791-3",
    HospitalWebsite: "www.sahavej.com",
    HospitalLocation: "16.4444248,100.3382529",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "389",
    HospitalName: "โรงพยาบาลกรุงเทพ พิษณุโลก",
    HospitalAddress: "69/32 ถนนพระองค์อำ",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองพิษณุโลก",
    HospitalProvince: "พิษณุโลก",
    HospitalTel: "0-5521-2222/ 0-5521-0819-28",
    HospitalWebsite: "https://bangkokhospitalphitsanulok.com",
    HospitalLocation: "16.818203, 100.271846",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "390",
    HospitalName: "โรงพยาบาลพิษณุเวช",
    HospitalAddress: "211 ถนนขุนพิเรนทรเทพ",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองพิษณุโลก",
    HospitalProvince: "พิษณุโลก",
    HospitalTel: "055-909000",
    HospitalWebsite: "",
    HospitalLocation: "16.808359, 100.259811",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "391",
    HospitalName: "โรงพยาบาลรวมแพทย์พิษณุโลก",
    HospitalAddress: "224/30 ถนนบรมไตรโลกนารถ",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองพิษณุโลก",
    HospitalProvince: "พิษณุโลก",
    HospitalTel: "0-5521-9307-16",
    HospitalWebsite: "www.ruamphat.com",
    HospitalLocation: "16.8098599,100.2574187",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "392",
    HospitalName: "โรงพยาบาลอินเตอร์เวชการ(โรงพยาบาลพิษณุโลกฮอสพิทอล)",
    HospitalAddress: "262/55 ถนนบรมไตรโลกนารถ",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองพิษณุโลก",
    HospitalProvince: "พิษณุโลก",
    HospitalTel: "0-5521-8777/ 0-5521-7800",
    HospitalWebsite: "",
    HospitalLocation: "16.805601, 100.255388",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "393",
    HospitalName: "โรงพยาบาลกรุงเทพเพชรบุรี (โรงพยาบาลเมืองเพชร จ.เพชรบุรี)",
    HospitalAddress: "150 หมู่ 6 ถนนเพชรเกษม",
    HospitalSubDistrict: "ต้นมะม่วง",
    HospitalDistrict: "เมืองเพชรบุรี",
    HospitalProvince: "เพชรบุรี",
    HospitalTel: "0-3241-5191-9",
    HospitalWebsite: "www.bangkokhospital.com",
    HospitalLocation: "13.083109, 99.945495",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "394",
    HospitalName: "โรงพยาบาลมหาชัยเพชรรัชต์(โรงพยาบาลเพชรรัชต์ เพชรบุรี)",
    HospitalAddress: "99/9 หมู่ 6 ถนนบันไดอิฐ",
    HospitalSubDistrict: "คลองกระแซง",
    HospitalDistrict: "เมืองเพชรบุรี",
    HospitalProvince: "เพชรบุรี",
    HospitalTel: "0-3241-7070-9",
    HospitalWebsite: "",
    HospitalLocation: "13.102336, 99.936675",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "395",
    HospitalName: "แพทย์พุทธิชัยคลินิกเวชกรรม",
    HospitalAddress: "1/2 หมู่4",
    HospitalSubDistrict: "สระพัง",
    HospitalDistrict: "เขาย้อย",
    HospitalProvince: "เพชรบุรี",
    HospitalTel: "087-1178423",
    HospitalWebsite: "",
    HospitalLocation: "13.268812,99.826995",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "396",
    HospitalName: "แพทย์พุทธิชัยคลินิกเวชกรรม 2",
    HospitalAddress: "40/4 หมู่2",
    HospitalSubDistrict: "หนองชุมพล",
    HospitalDistrict: "เขาย้อย",
    HospitalProvince: "เพชรบุรี",
    HospitalTel: "087-1178423",
    HospitalWebsite: "",
    HospitalLocation: "13.289740,99.821588",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "397",
    HospitalName: "คลินิกนายแพทย์นิพัทธ์",
    HospitalAddress: "235 หมู่ 7 ถนนสมอทอด-วังพิกุล",
    HospitalSubDistrict: "ซับสมอทอด",
    HospitalDistrict: "บึงสามพัน",
    HospitalProvince: "เพชรบูรณ์",
    HospitalTel: "056-732344",
    HospitalWebsite: "",
    HospitalLocation: "15.780667, 101.002620",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "398",
    HospitalName: "โรงพยาบาลเพชรรัตน์ จ.เพชรบูรณ์",
    HospitalAddress: "2/1 ถนนสามัคคีชัย",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองเพชรบูรณ์",
    HospitalProvince: "เพชรบูรณ์",
    HospitalTel: "0-5672-0680-4",
    HospitalWebsite: "",
    HospitalLocation: "16.410886, 101.148640",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "399",
    HospitalName: "สถานพยาบาลเวชกรรมนครหล่ม",
    HospitalAddress: "163/75 ถนนคชเสนีย์",
    HospitalSubDistrict: "หล่มสัก",
    HospitalDistrict: "หล่มสัก",
    HospitalProvince: "เพชรบูรณ์",
    HospitalTel: "0-5670-2015-7",
    HospitalWebsite: "",
    HospitalLocation: "16.782571, 101.240908",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "400",
    HospitalName: "โรงพยาบาลแพร่**",
    HospitalAddress: "144 ถนนช่อแฮ",
    HospitalSubDistrict: "ในเวียง",
    HospitalDistrict: "เมืองแพร่",
    HospitalProvince: "แพร่",
    HospitalTel: "0-5453-3500",
    HospitalWebsite: "www.phraechristian.com",
    HospitalLocation: "18.136052,100.1451169",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "401",
    HospitalName: "โรงพยาบาลแพร่คริสเตียน",
    HospitalAddress: "7 ถนนยันตรกิจโกศล",
    HospitalSubDistrict: "ในเวียง",
    HospitalDistrict: "เมืองแพร่",
    HospitalProvince: "แพร่",
    HospitalTel: "0-5451-1017/ 0-5451-1290",
    HospitalWebsite: "www.phraechristian.com",
    HospitalLocation: "18.136052,100.1451169",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "402",
    HospitalName: "โรงพยาบาลแพร่ราม",
    HospitalAddress: "3 ตรอกยันตรกิจโกศล1 ถนนยันตรกิจโกศล",
    HospitalSubDistrict: "ในเวียง",
    HospitalDistrict: "เมืองแพร่",
    HospitalProvince: "แพร่",
    HospitalTel: "0-5452-2911",
    HospitalWebsite: "",
    HospitalLocation: "18.1338521,100.1517771",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "403",
    HospitalName: "คลินิกเวชกรรมกรุงเทพภูเก็ต สาขาไม้ขาว",
    HospitalAddress: "61/14 ถนนเทพกระษัตรี ตำบลไม้ขาว",
    HospitalSubDistrict: "ไม้ขาว",
    HospitalDistrict: "ถลาง",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-89973-9856",
    HospitalWebsite: "",
    HospitalLocation: "8.177023, 98.298664",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "404",
    HospitalName: "คลินิกเวชกรรมสยามอินเตอร์เนชั่นแนล สาขาป่าตอง",
    HospitalAddress: "172 ถนนราษฎร์อุทิศ 200 ปี",
    HospitalSubDistrict: "ป่าตอง",
    HospitalDistrict: "กะทู้",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-7634-4190-1",
    HospitalWebsite: "",
    HospitalLocation: "7.895272, 98.298765",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "405",
    HospitalName: "โรงพยาบาลกรุงเทพภูเก็ต",
    HospitalAddress: "2/1 ถนนหงษ์หยกอุทิศ",
    HospitalSubDistrict: "ตลาดใหญ่",
    HospitalDistrict: "เมืองภูเก็ต",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-7625-4421-9",
    HospitalWebsite: "www.phukethospital.com",
    HospitalLocation: "7.904479, 98.376189",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "406",
    HospitalName: "โรงพยาบาลดีบุก",
    HospitalAddress: "89/8-9 ถนนเจ้าฟ้าตะวันตก",
    HospitalSubDistrict: "วิชิต",
    HospitalDistrict: "เมืองภูเก็ต",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-7625-4555,0-7635-4199",
    HospitalWebsite: "http://dibukhospital.com/",
    HospitalLocation: "7.871713, 98.360267",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "407",
    HospitalName: "โรงพยาบาลป่าตอง",
    HospitalAddress: "57 ถนนไสน้ำเย็น",
    HospitalSubDistrict: "ป่าตอง",
    HospitalDistrict: "กะทู้",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-7634-0444",
    HospitalWebsite: "www.patonghospital.go.th",
    HospitalLocation: "7.8964832,98.3018467",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "408",
    HospitalName: "โรงพยาบาลมิชชั่นภูเก็ต",
    HospitalAddress: "4/1 หมู่ 3 ถนนเทพกระษัตรี",
    HospitalSubDistrict: "รัษฎา",
    HospitalDistrict: "เมืองภูเก็ต",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-7623-7220-6",
    HospitalWebsite: "",
    HospitalLocation: "7.906624, 98.390791",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "409",
    HospitalName: "โรงพยาบาลวชิระภูเก็ต",
    HospitalAddress: "353 ถนนเยาวราช",
    HospitalSubDistrict: "ตลาดใหญ่",
    HospitalDistrict: "เมืองภูเก็ต",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-7636-1234",
    HospitalWebsite: "www.vachiraphuket.go.th",
    HospitalLocation: "7.8963862,98.3819917",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "410",
    HospitalName: "โรงพยาบาลกรุงเทพสิริโรจน์",
    HospitalAddress: "44 หมู่ 5 ถนนเฉลิมพระเกียรติ ร.9",
    HospitalSubDistrict: "วิชิต",
    HospitalDistrict: "เมืองภูเก็ต",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "076-361888",
    HospitalWebsite: "",
    HospitalLocation: "7.898159, 98.368142",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "411",
    HospitalName: "คลินิกเวชกรรมกรุงเทพภูเก็ต",
    HospitalAddress: "390/6,390/7 ถ.ศรีสุนทร",
    HospitalSubDistrict: "ศรีสุนทร",
    HospitalDistrict: "ถลาง",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "076-325442",
    HospitalWebsite: "",
    HospitalLocation: "8.003586,98.298764",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "412",
    HospitalName: "โรงพยาบาลองค์การบริหารส่วนจังหวัดภูเก็ต",
    HospitalAddress: "18,20 ถนนอนุภาษภูเก็ตการ",
    HospitalSubDistrict: "ตลาดใหญ่",
    HospitalDistrict: "เมืองภูเก็ต",
    HospitalProvince: "ภูเก็ต",
    HospitalTel: "0-7635-8888",
    HospitalWebsite: "http://www.phuketcityhospital.org",
    HospitalLocation: "7.8810713,98.4028023",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "413",
    HospitalName: "โรงพยาบาลมหาสารคามอินเตอร์เนชั่นแนล",
    HospitalAddress: "1113/235 ถนนเทศบาลอาชา",
    HospitalSubDistrict: "ตลาด",
    HospitalDistrict: "เมืองมหาสารคาม",
    HospitalProvince: "มหาสารคาม",
    HospitalTel: "0-4372-1770, 0-4372-3669",
    HospitalWebsite: "www.mahasarakhaminternational.com",
    HospitalLocation: "16.1753202,103.2982041",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "414",
    HospitalName: "คลินิกเวชกรรมหมอเกรียงศักดิ์",
    HospitalAddress: "150 หมู่ 19",
    HospitalSubDistrict: "เชียงยืน",
    HospitalDistrict: "เชียงยืน",
    HospitalProvince: "มหาสารคาม",
    HospitalTel: "081-7994126,081-0587755",
    HospitalWebsite: "",
    HospitalLocation: "16.408945, 103.098128",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "415",
    HospitalName: "โรงพยาบาลยางสีสุราช",
    HospitalAddress: "126 หมู่2",
    HospitalSubDistrict: "ยางสีสุราช",
    HospitalDistrict: "ยางสีสุราช",
    HospitalProvince: "มหาสารคาม",
    HospitalTel: "043-729171",
    HospitalWebsite: "",
    HospitalLocation: "15.6834029,103.1030636",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "416",
    HospitalName: "คลินิกเวชกรรมหมอธีรชาติ",
    HospitalAddress: "24 ถนนนาเชือก-บรบือ",
    HospitalSubDistrict: "นาเชือก",
    HospitalDistrict: "นาเชือก",
    HospitalProvince: "มหาสารคาม",
    HospitalTel: "082-3060509",
    HospitalWebsite: "",
    HospitalLocation: "15.8027459,103.0328573",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "417",
    HospitalName: "โรงพยาบาลมุกดาหาร**",
    HospitalAddress: "87 ถนนมุกดาหาร-ดอนตาล",
    HospitalSubDistrict: "ศรีบุญเรือง",
    HospitalDistrict: "ศรีบุญเรือง",
    HospitalProvince: "มุกดาหาร",
    HospitalTel: "0-4263-3301-9",
    HospitalWebsite: "www.mukinter.com",
    HospitalLocation: "16.5315937,104.7320705",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "418",
    HospitalName: "โรงพยาบาลมุกดาหารอินเตอร์เนชั่นแนล",
    HospitalAddress: "87 ถนนมุกดาหาร-ดอนตาล",
    HospitalSubDistrict: "ศรีบุญเรือง",
    HospitalDistrict: "เมืองมุกดาหาร",
    HospitalProvince: "มุกดาหาร",
    HospitalTel: "0-4263-3301-9",
    HospitalWebsite: "www.mukinter.com",
    HospitalLocation: "16.5315937,104.7320705",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "419",
    HospitalName: "โรงพยาบาลนายแพทย์หาญ",
    HospitalAddress: "160-164 ถนนวิทยะธำรงค์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองยโสธร",
    HospitalProvince: "ยโสธร",
    HospitalTel: "0-4571-1356",
    HospitalWebsite: "www.drhann.com",
    HospitalLocation: "15.7923488,104.1449026",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "420",
    HospitalName: "โรงพยาบาลหาญอินเตอร์เนชั่นแนล(โรงพยาบาลรวมแพทย์ ยโสธร)",
    HospitalAddress: "29 ถนนมงคลบูรพา",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองยโสธร",
    HospitalProvince: "ยโสธร",
    HospitalTel: "0-4571-2141-2",
    HospitalWebsite: "",
    HospitalLocation: "15.789284, 104.151812",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "421",
    HospitalName: "โรงพยาบาลสิโรรส",
    HospitalAddress: "247-249 ถนนสิโรรส",
    HospitalSubDistrict: "สะเตง",
    HospitalDistrict: "เมืองยะลา",
    HospitalProvince: "ยะลา",
    HospitalTel: "073-221-114-5 , 073-244-510-4",
    HospitalWebsite: "",
    HospitalLocation: "6.551269, 101.279738",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "422",
    HospitalName: "โรงพยาบาลกรุงเทพ จุรีเวช",
    HospitalAddress: "368 ถนนเทวาภิบาล",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองร้อยเอ็ด",
    HospitalProvince: "ร้อยเอ็ด",
    HospitalTel: "0-4352-7111, 0-4351-1436",
    HospitalWebsite: "",
    HospitalLocation: "16.0531673,103.6717689",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "423",
    HospitalName: "โรงพยาบาลร้อยเอ็ดธนบุรี",
    HospitalAddress: "166 ถนนปัทมานนท์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองร้อยเอ็ด",
    HospitalProvince: "ร้อยเอ็ด",
    HospitalTel: "0-4352-7191",
    HospitalWebsite: "http://roietthonburi.com",
    HospitalLocation: "16.0422277,103.6434889",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "424",
    HospitalName: "คลินิกแพทย์วุฒิชัย",
    HospitalAddress: "286 ถนนท่าเมือง(สี่แยกตลาดเก่า)",
    HospitalSubDistrict: "เขานิเวศน์",
    HospitalDistrict: "เมืองระนอง",
    HospitalProvince: "ระนอง",
    HospitalTel: "077-811639",
    HospitalWebsite: "",
    HospitalLocation: "9.957719, 98.633061",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "425",
    HospitalName: "โรงพยาบาลขนาดเล็กระนองอินเตอร์เนชั่นแนล",
    HospitalAddress: "8/9 ถนนชลระอุ",
    HospitalSubDistrict: "เขานิเวศน์",
    HospitalDistrict: "เมืองระนอง",
    HospitalProvince: "ระนอง",
    HospitalTel: "0-7781-0465",
    HospitalWebsite: "",
    HospitalLocation: "9.968063, 98.642194",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "426",
    HospitalName: "โรงพยาบาลระนอง",
    HospitalAddress: "11 ถนนกำลังทรัพย์",
    HospitalSubDistrict: "เขานิเวศน์",
    HospitalDistrict: "เมืองระนอง",
    HospitalProvince: "ระนอง",
    HospitalTel: "077-811-575",
    HospitalWebsite: "",
    HospitalLocation: "9.963605, 98.642929",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "427",
    HospitalName: "อันดามันระนองการแพทย์",
    HospitalAddress: "130 / 274-6 ถนนท่าเมือง",
    HospitalSubDistrict: "",
    HospitalDistrict: "เมืองระนอง",
    HospitalProvince: "ระนอง",
    HospitalTel: "0-7783-5960",
    HospitalWebsite: "",
    HospitalLocation: "9.959031, 98.625048",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "428",
    HospitalName: "คลินิกกรรชิตการแพทย์",
    HospitalAddress: "154/22 หมู่ 5 ถนนบ้านฉาง-พยูน",
    HospitalSubDistrict: "บ้านฉาง",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "081-842-3558",
    HospitalWebsite: "",
    HospitalLocation: "12.722126, 101.055945",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "429",
    HospitalName: "คลินิกกรุงเทพระยอง สาขาบ้านฉาง",
    HospitalAddress: "99-99/1",
    HospitalSubDistrict: "บ้านฉาง",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3860-4669",
    HospitalWebsite: "",
    HospitalLocation: "12.9184547,101.2522691",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "430",
    HospitalName: "คลินิกกรุงเทพระยอง สาขาปลวกแดง",
    HospitalAddress: "625/70 หมู่ 1",
    HospitalSubDistrict: "ปลวกแดง",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3892-1999",
    HospitalWebsite: "",
    HospitalLocation: "12.982952, 101.211229",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "431",
    HospitalName: "คลินิกจุฬาทันตแพทย์",
    HospitalAddress: "90/46 หมู่ 1",
    HospitalSubDistrict: "พลา",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-845256963",
    HospitalWebsite: "",
    HospitalLocation: "12.724918, 101.050113",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "432",
    HospitalName: "คลินิกบ้านรักฟัน",
    HospitalAddress: "48/7 หมู่ที่ 5 ถนน",
    HospitalSubDistrict: "บ้านฉาง",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-695179",
    HospitalWebsite: "",
    HospitalLocation: "12.724006, 101.053905",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "433",
    HospitalName: "คลินิกบ้านหมอมนตรี",
    HospitalAddress: "296/18 หมู่ 1",
    HospitalSubDistrict: "ปลวกแดง",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "033-650771",
    HospitalWebsite: "",
    HospitalLocation: "12.980459, 101.211752",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "434",
    HospitalName: "คลินิกแพทย์ธีรพงศ์",
    HospitalAddress: "97 ถนนสุขุมวิท",
    HospitalSubDistrict: "ท่าประดู่",
    HospitalDistrict: "เมือง",
    HospitalProvince: "ระยอง",
    HospitalTel: "081-2949057, 038-011919",
    HospitalWebsite: "",
    HospitalLocation: "12.681535, 101.277228",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "435",
    HospitalName: "คลินิกรวมแพทย์นิคม",
    HospitalAddress: "212/7 หมู่ที่2 ตลาดนิคม กม.12",
    HospitalSubDistrict: "นิคมพัฒนา",
    HospitalDistrict: "นิคมพัฒนา",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-636217",
    HospitalWebsite: "",
    HospitalLocation: "12.829601, 101.203520",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "436",
    HospitalName: "คลินิกรักสุขภาพ จ.ระยอง",
    HospitalAddress: "65/84-85 ถนนสุขุมวิท",
    HospitalSubDistrict: "บ้านฉาง",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-601520",
    HospitalWebsite: "",
    HospitalLocation: "12.721741, 101.082400",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "437",
    HospitalName: "คลินิกลูกรัก",
    HospitalAddress: "999/8 หมู่ 1 หมู่บ้าน Beyond by Sailomyen ถนนราชชุมพล",
    HospitalSubDistrict: "",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "081-842-3558",
    HospitalWebsite: "",
    HospitalLocation: "12.687399, 101.254062",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "438",
    HospitalName: "คลินิกเวชกรรมเฉพาะทางภูมิแพ้และหู คอ จมูก หมอเอกภพ",
    HospitalAddress: "274 ถนนสุขุมวิท",
    HospitalSubDistrict: "เชิงเนิน",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "099-7029746",
    HospitalWebsite: "",
    HospitalLocation: "12.682583, 101.267053",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "439",
    HospitalName: "คลินิกเวชกรรมสมิติเวช สาขาอีสเทิร์นซีบอร์ด",
    HospitalAddress: "24 หมู่ 4",
    HospitalSubDistrict: "ปลวกแดง",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3895-5437-8",
    HospitalWebsite: "",
    HospitalLocation: "13.0150684,101.147599",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "440",
    HospitalName: "คลินิกหมอคมสัน",
    HospitalAddress: "90/88-89 หมู่ 1 ถนนสุขุมวิท",
    HospitalSubDistrict: "พลา",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-603067",
    HospitalWebsite: "",
    HospitalLocation: "12.7234872,101.0494973",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "441",
    HospitalName: "คลินิกหมอวรากร คลินิกเฉพาะทางโรคภูมิแพ้และโรคเด็ก",
    HospitalAddress: "182/2 ถนนสุขุมวิท",
    HospitalSubDistrict: "เชิงเนิน",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-624275",
    HospitalWebsite: "",
    HospitalLocation: "12.682499, 101.267974",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "442",
    HospitalName: "คลินิกหัวใจระยอง",
    HospitalAddress: "310,312 ถ.สุขุมวิท",
    HospitalSubDistrict: "เชิงเนิน",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "095-5479465",
    HospitalWebsite: "",
    HospitalLocation: "12.682688, 101.266296",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "443",
    HospitalName: "บูรพาพัฒน์สหคลินิก",
    HospitalAddress: "88/1 ม.1 หมู่บ้านวริศรา 3",
    HospitalSubDistrict: "พลา",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "033-064460",
    HospitalWebsite: "",
    HospitalLocation: "12.712051, 101.045101",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "444",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมมาบยางพร",
    HospitalAddress: "111/21-23 หมู่ 4",
    HospitalSubDistrict: "มาบยางพร",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "03-806-8953",
    HospitalWebsite: "",
    HospitalLocation: "13.0182259,101.0782582",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "445",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมสะพานสี่",
    HospitalAddress: "454/12-14 หมู่ 3",
    HospitalSubDistrict: "มาบยางพร",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "033-016477",
    HospitalWebsite: "",
    HospitalLocation: "13.0071169,101.1281353",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "446",
    HospitalName: "เมืองแกลงคลินิก",
    HospitalAddress: "95 ถนนสุขุมวิท",
    HospitalSubDistrict: "ทางเกวียน",
    HospitalDistrict: "แกลง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-674555",
    HospitalWebsite: "",
    HospitalLocation: "12.778534, 101.644041",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "447",
    HospitalName: "ระยองโพลีคลินิค",
    HospitalAddress: "49-51 ถนนศูนย์การค้าสาย4",
    HospitalSubDistrict: "ท่าประดู่",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-860680",
    HospitalWebsite: "",
    HospitalLocation: "12.683820, 101.271359",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "448",
    HospitalName: "โรงพยาบาลกรุงเทพ ระยอง",
    HospitalAddress: "8 หมู่ 2 ซอยแสงจันทร์เนรมิต ถนนสุขุมวิท",
    HospitalSubDistrict: "เนินพระ",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3892-1999",
    HospitalWebsite: "www.bangkokrayong.com",
    HospitalLocation: "12.675077, 101.221290",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "449",
    HospitalName: "โรงพยาบาลจุฬารัตน์ระยอง(โรงพยาบาลรวมแพทย์ระยอง)",
    HospitalAddress: "65/28 ถนนจันทอุดม",
    HospitalSubDistrict: "เนินพระ",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3886-0890-3",
    HospitalWebsite: "",
    HospitalLocation: "12.685244, 101.274571",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "450",
    HospitalName: "โรงพยาบาลปิยะเวชช์ ระยอง",
    HospitalAddress: "288 หมู่ 3",
    HospitalSubDistrict: "มาบยางพร",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-345111",
    HospitalWebsite: "",
    HospitalLocation: "12.991389, 101.144111",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "451",
    HospitalName: "โรงพยาบาลมงกุฏระยอง",
    HospitalAddress: "149/1 ถนนมาบยา",
    HospitalSubDistrict: "มาบตาพุด",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3868-2136-9",
    HospitalWebsite: "www.mongkutrayong.com",
    HospitalLocation: "12.7337274,101.1558391",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "452",
    HospitalName: "โรงพยาบาลระยอง",
    HospitalAddress: "138 ถนนสุขุมวิท",
    HospitalSubDistrict: "ท่าประดู่",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3861-1104",
    HospitalWebsite: "www.rayonghospital.net",
    HospitalLocation: "12.6821816,101.2757965",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "453",
    HospitalName: "โรงพยาบาลศรีระยอง",
    HospitalAddress: "111 ถนนสุขุมวิท",
    HospitalSubDistrict: "เชิงเนิน",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-3899-8555",
    HospitalWebsite: "www.bangkokrayong.com",
    HospitalLocation: "12.695357, 101.292489",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "454",
    HospitalName: "สหคลินิกแพทย์สมบูรณ์-จิรภา มะลิขาว",
    HospitalAddress: "484/65-67 ถนนสุขุมวิท",
    HospitalSubDistrict: "เนินพระ",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-619450",
    HospitalWebsite: "",
    HospitalLocation: "12.683098, 101.257007",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "455",
    HospitalName: "หมอบูณย์คลินิกเวชกรรม (คลินิกบ้านฉางเวชการ(จักร์ชัย))",
    HospitalAddress: "1/1-1/2 หมู่ 3",
    HospitalSubDistrict: "บ้านฉาง",
    HospitalDistrict: "บ้านฉาง",
    HospitalProvince: "ระยอง",
    HospitalTel: "038-601028",
    HospitalWebsite: "",
    HospitalLocation: "12.725762, 101.054072",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "456",
    HospitalName: "หมอเอกฤทธิ์ คลินิกเฉพาะทางด้านเวชกรรม",
    HospitalAddress: "11/36 หมู่ 6",
    HospitalSubDistrict: "เชิงเนิน",
    HospitalDistrict: "เมืองระยอง",
    HospitalProvince: "ระยอง",
    HospitalTel: "091-8801100",
    HospitalWebsite: "",
    HospitalLocation: "12.684855, 101.300055",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "457",
    HospitalName: "เกษมเวชคลินิกเวชกรรม",
    HospitalAddress: "283 หมู่4",
    HospitalSubDistrict: "มาบยางพร",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "0-33641900",
    HospitalWebsite: "",
    HospitalLocation: "13.016614, 101.118841",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "458",
    HospitalName: "บุญประทานคลินิกเวชกรรม สาขา สยามอิสเทิร์น",
    HospitalAddress: "898/4-5 หมู่3",
    HospitalSubDistrict: "มาบยางพร",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "095-7154101",
    HospitalWebsite: "",
    HospitalLocation: "12.990851, 101.133318",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "459",
    HospitalName: "บุญประทานคลินิกเวชกรรม สาขา นิคมอุตสาหกรรม ซอย 13",
    HospitalAddress: "222/3 หมู่2",
    HospitalSubDistrict: "มะขามคู่",
    HospitalDistrict: "นิคมพัฒนา",
    HospitalProvince: "ระยอง",
    HospitalTel: "095-6365051",
    HospitalWebsite: "",
    HospitalLocation: "12.990821, 101.133311",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "460",
    HospitalName: "บุญประทานคลินิกเวชกรรม สาขา หน้าแอลจี",
    HospitalAddress: "411/8 หมู่1",
    HospitalSubDistrict: "ตาสิทธิ์",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "095-2931994",
    HospitalWebsite: "",
    HospitalLocation: "13.023552, 101.201521",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "461",
    HospitalName: "คลินิกเฉพาะทางด้านเวชกรรมแพทย์รัฐศาสตร์",
    HospitalAddress: "260 หมู่6",
    HospitalSubDistrict: "มาบยางพร",
    HospitalDistrict: "ปลวกแดง",
    HospitalProvince: "ระยอง",
    HospitalTel: "092-8913315",
    HospitalWebsite: "",
    HospitalLocation: "12.991464,101.132805",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "462",
    HospitalName: "โรงพยาบาลซานคามิลโล",
    HospitalAddress: "31 หมู่10 ถนนอุดมพิทยา",
    HospitalSubDistrict: "สวนกล้วย",
    HospitalDistrict: "บ้านโป่ง",
    HospitalProvince: "ราชบุรี",
    HospitalTel: "0-3221-1143",
    HospitalWebsite: "",
    HospitalLocation: "13.810417, 99.879308",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "463",
    HospitalName: "โรงพยาบาลดำเนินสะดวก**",
    HospitalAddress: "146 หมู่ 4",
    HospitalSubDistrict: "ท่านัด",
    HospitalDistrict: "ดำเนินสะดวก",
    HospitalProvince: "ราชบุรี",
    HospitalTel: "0-3224-6000-15",
    HospitalWebsite: "www.dnhospital.go.th",
    HospitalLocation: "13.5364809,99.9644232",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "464",
    HospitalName: "โรงพยาบาลบ้านโป่ง",
    HospitalAddress: "12 ถนนแสงชูโต",
    HospitalSubDistrict: "บ้านโป่ง",
    HospitalDistrict: "บ้านโป่ง",
    HospitalProvince: "ราชบุรี",
    HospitalTel: "032-222841-46",
    HospitalWebsite: "www.bph.moph.go.th",
    HospitalLocation: "13.8171916,99.8691394",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "465",
    HospitalName: "โรงพยาบาลมหาชัยพร้อมแพทย์(โรงพยาบาลพร้อมแพทย์)",
    HospitalAddress: "77/4 ถนนคฑาธร",
    HospitalSubDistrict: "หน้าเมือง",
    HospitalDistrict: "เมืองราชบุรี",
    HospitalProvince: "ราชบุรี",
    HospitalTel: "0-3231-5234-9, 0-32315-195, 0-32315-196",
    HospitalWebsite: "",
    HospitalLocation: "13.531242, 99.820295",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "466",
    HospitalName: "โรงพยาบาลเมืองราช",
    HospitalAddress: "59/3 ถนนเพชรเกษม สายเก่า",
    HospitalSubDistrict: "หน้าเมือง",
    HospitalDistrict: "เมืองราชบุรี",
    HospitalProvince: "ราชบุรี",
    HospitalTel: "0-3232-2274-80",
    HospitalWebsite: "",
    HospitalLocation: "13.548031, 99.812371",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "467",
    HospitalName: "โรงพยาบาลโพธาราม",
    HospitalAddress: "29 ถนนขนานทางรถไฟ",
    HospitalSubDistrict: "โพธาราม",
    HospitalDistrict: "โพธาราม",
    HospitalProvince: "ราชบุรี",
    HospitalTel: "32719400",
    HospitalWebsite: "",
    HospitalLocation: "13.694903,99.851686",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "468",
    HospitalName: "โรงพยาบาลเบญจรมย์",
    HospitalAddress: "116 หมู่ 8",
    HospitalSubDistrict: "ป่าตัน",
    HospitalDistrict: "เมืองลพบุรี",
    HospitalProvince: "ลพบุรี",
    HospitalTel: "036-412160",
    HospitalWebsite: "www.benjarom.com",
    HospitalLocation: "14.7904619,100.6302763",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "469",
    HospitalName: "โรงพยาบาลเมืองนารายณ์",
    HospitalAddress: "84 หมู่ 3 ถนนพหลโยธิน กม.150",
    HospitalSubDistrict: "ท่าศาลา",
    HospitalDistrict: "เมืองลพบุรี",
    HospitalProvince: "ลพบุรี",
    HospitalTel: "036 - 616300",
    HospitalWebsite: "www.mueangnarai.com",
    HospitalLocation: "14.7880535,100.6767979",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "470",
    HospitalName: "โรงพยาบาลเขลางค์นครราม",
    HospitalAddress: "79/12 ถนนพหลโยธิน",
    HospitalSubDistrict: "สวนดอก",
    HospitalDistrict: "เมืองลำปาง",
    HospitalProvince: "ลำปาง",
    HospitalTel: "0-5422-5100/0-5435-2422",
    HospitalWebsite: "",
    HospitalLocation: "18.281835, 99.485663",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "471",
    HospitalName: "โรงพยาบาลลำปาง**",
    HospitalAddress: "280 ป่าขาม 1",
    HospitalSubDistrict: "หัวเวียง",
    HospitalDistrict: "เมืองลำปาง",
    HospitalProvince: "ลำปาง",
    HospitalTel: "054-237400 ต่อ 8212",
    HospitalWebsite: "",
    HospitalLocation: "18.286146, 99.506039",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "472",
    HospitalName: "โรงพยาบาลลำพูน",
    HospitalAddress: "177 หมู่ 1",
    HospitalSubDistrict: "ต้นธง",
    HospitalDistrict: "เมืองลำพูน",
    HospitalProvince: "ลำพูน",
    HospitalTel: "053-569100",
    HospitalWebsite: "http://www.lpnh.go.th",
    HospitalLocation: "18.582642, 98.994694",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "473",
    HospitalName: "โรงพยาบาลศิริเวช ลำพูน",
    HospitalAddress: "7 หมู่ 5",
    HospitalSubDistrict: "เวียงยอง",
    HospitalDistrict: "เมืองลำพูน",
    HospitalProvince: "ลำพูน",
    HospitalTel: "0-5353-7597",
    HospitalWebsite: "www.sirivejlamphunhospital.com",
    HospitalLocation: "18.5618231,99.0367072",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "474",
    HospitalName: "โรงพยาบาลหริภุญชัยเมโมเรียล",
    HospitalAddress: "109/111 ถนนลำพูน-บ้านธิ",
    HospitalSubDistrict: "บ้านกลาง",
    HospitalDistrict: "เมืองลำพูน",
    HospitalProvince: "ลำพูน",
    HospitalTel: "0-5358-1600-4",
    HospitalWebsite: "www.hariphunchai.net",
    HospitalLocation: "18.5867022,99.0459981",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "475",
    HospitalName: "โรงพยาบาลเมืองเลยราม",
    HospitalAddress: "546 หมู่ 1 ถนนมลิวรรณ",
    HospitalSubDistrict: "นาอาน",
    HospitalDistrict: "เมืองเลย",
    HospitalProvince: "เลย",
    HospitalTel: "0-4283-3400-19",
    HospitalWebsite: "",
    HospitalLocation: "17.4640219,101.7258316",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "476",
    HospitalName: "โรงพยาบาลสมเด็จพระยุพราชบ้านดุง",
    HospitalAddress: "168 ม.3",
    HospitalSubDistrict: "ด่านซ้าย",
    HospitalDistrict: "ด่านซ้าย",
    HospitalProvince: "เลย",
    HospitalTel: "042-273700",
    HospitalWebsite: "",
    HospitalLocation: "17.275322, 101.158759",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "477",
    HospitalName: "โรงพยาบาลประชารักษ์เวชการ",
    HospitalAddress: "872 ถนนอุบล",
    HospitalSubDistrict: "เมืองใต้",
    HospitalDistrict: "เมืองศรีสะเกษ",
    HospitalProvince: "ศรีสะเกษ",
    HospitalTel: "0-4563-1313",
    HospitalWebsite: "",
    HospitalLocation: "15.1146441,104.3215057",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "478",
    HospitalName: "โรงพยาบาลศรีสะเกษ",
    HospitalAddress: "0859 ถนนกสิกรรม",
    HospitalSubDistrict: "เมืองใต้",
    HospitalDistrict: "เมืองศรีสะเกษ",
    HospitalProvince: "ศรีสะเกษ",
    HospitalTel: "045-612502",
    HospitalWebsite: "",
    HospitalLocation: "15.115923, 104.320275",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "479",
    HospitalName: "โรงพยาบาลรักษ์สกล",
    HospitalAddress: "1446/47 ถนนรอบเมือง",
    HospitalSubDistrict: "ธาตุเชิงชุม",
    HospitalDistrict: "เมืองสกลนคร",
    HospitalProvince: "สกลนคร",
    HospitalTel: "0-4271-2588, 0-4271-2800",
    HospitalWebsite: "https://raksakol.com",
    HospitalLocation: "17.162366,104.1437044",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "480",
    HospitalName: "โรงพยาบาลสกลนคร",
    HospitalAddress: "1041 ถนนเจริญเมือง",
    HospitalSubDistrict: "ธาตุเชิงชุม",
    HospitalDistrict: "เมืองสกลนคร",
    HospitalProvince: "สกลนคร",
    HospitalTel: "042-711615",
    HospitalWebsite: "www.sknhospital.go.th",
    HospitalLocation: "17.163830, 104.158767",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "481",
    HospitalName: "คลินิกวิทยาการแพทย์",
    HospitalAddress: "14-18 ถนนนางงาม",
    HospitalSubDistrict: "บ่อยาง",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สงขลา",
    HospitalTel: "074-312196",
    HospitalWebsite: "",
    HospitalLocation: "7.198064, 100.590473",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "482",
    HospitalName: "โรงพยาบาลกรุงเทพ หาดใหญ่",
    HospitalAddress: "75 ซอย 15 ถนนเพชรเกษม",
    HospitalSubDistrict: "หาดใหญ่",
    HospitalDistrict: "หาดใหญ่",
    HospitalProvince: "สงขลา",
    HospitalTel: "0-7436-5780-9",
    HospitalWebsite: "https://bangkokhatyai.com",
    HospitalLocation: "7.016440, 100.486081",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "483",
    HospitalName: "โรงพยาบาลราษฎร์ยินดีหาดใหญ่",
    HospitalAddress: "119 ถนนราษฎร์ยินดี",
    HospitalSubDistrict: "หาดใหญ่",
    HospitalDistrict: "หาดใหญ่",
    HospitalProvince: "สงขลา",
    HospitalTel: "0-7420-0200",
    HospitalWebsite: "www.rajyindee.com",
    HospitalLocation: "7.0010378,100.4793493",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "484",
    HospitalName: "โรงพยาบาลศิครินทร์หาดใหญ่",
    HospitalAddress: "169 ถนนนิพัทธ์สงเคราะห์ 1",
    HospitalSubDistrict: "หาดใหญ่",
    HospitalDistrict: "หาดใหญ่",
    HospitalProvince: "สงขลา",
    HospitalTel: "0-7436-6966",
    HospitalWebsite: "",
    HospitalLocation: "7.022367, 100.469776",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "485",
    HospitalName: "โรงพยาบาลหาดใหญ่**",
    HospitalAddress: "182 ถ.รัตถการ",
    HospitalSubDistrict: "หาดใหญ่",
    HospitalDistrict: "หาดใหญ่",
    HospitalProvince: "สงขลา",
    HospitalTel: "074-273100",
    HospitalWebsite: "www.hatyaihospital.go.th",
    HospitalLocation: "7.0171798,100.4661694",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "486",
    HospitalName: "โรงพยาบาลมิตรภาพสามัคคี",
    HospitalAddress: "161/27 ถ.แสงศรี",
    HospitalSubDistrict: "หาดใหญ่",
    HospitalDistrict: "หาดใหญ่",
    HospitalProvince: "สงขลา",
    HospitalTel: "074-352900-3",
    HospitalWebsite: "www.mittraparphosp.com",
    HospitalLocation: "7.009456, 100.475451",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "487",
    HospitalName: "คลินิกเวชกรรมเฉพาะทางสาขาเวชศาสตร์ครอบครัวหมอปวริศ",
    HospitalAddress: "33 ถ.พัทลุง",
    HospitalSubDistrict: "บ่อยาง",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สงขลา",
    HospitalTel: "074-800464 ,084-2511951",
    HospitalWebsite: "",
    HospitalLocation: "7.193929,100.592964",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "488",
    HospitalName: "เฉลิมพงษ์คลินิก",
    HospitalAddress: "130-132 ถนนทะเลหลวง",
    HospitalSubDistrict: "บ่อยาง",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สงขลา",
    HospitalTel: "074-314995",
    HospitalWebsite: "",
    HospitalLocation: "7.193564,100.597839",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "489",
    HospitalName: "แฟมิลี่แคร์คลินิกเวชกรรม(สงขลา)",
    HospitalAddress: "26 ถ.ปละท่า",
    HospitalSubDistrict: "บ่อยาง",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สงขลา",
    HospitalTel: "080-968202",
    HospitalWebsite: "",
    HospitalLocation: "7.204402,100.593461",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "490",
    HospitalName: "โรงพยาบาลจะนะ",
    HospitalAddress: "35 ถนนราษฎร์บำรุง",
    HospitalSubDistrict: "บ้านนา",
    HospitalDistrict: "จะนะ",
    HospitalProvince: "สงขลา",
    HospitalTel: "074-207070",
    HospitalWebsite: "www.chanahospital.go.th",
    HospitalLocation: "6.915102,100.739937",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "491",
    HospitalName: "คลินิกเวชกรรมสยามอินเตอร์เนชั่นแนล สาขาเกาะหลีเป๊ะ",
    HospitalAddress: "275 หมู่ 7",
    HospitalSubDistrict: "เกาะสาหร่าย",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สตูล",
    HospitalTel: "099-4060590",
    HospitalWebsite: "",
    HospitalLocation: "6.487216, 99.305546",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "492",
    HospitalName: "คลินิกเวชกรรมจุฬารัตน์ 2",
    HospitalAddress: "728/1-2 กม.3 ถนนเทพารักษ์ กม1",
    HospitalSubDistrict: "คลองราชาเทวะ",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2753-2876-7 , 0-2384-0410",
    HospitalWebsite: "",
    HospitalLocation: "13.6363369,100.6097173",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "493",
    HospitalName: "พริ้นซ์ ฮอสพิทอล(โรงพยาบาลปิยะมินทร์)",
    HospitalAddress: "35/2 ถนนบางนา-ตราด กม 6",
    HospitalSubDistrict: "บางแก้ว",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2316-0026-42",
    HospitalWebsite: "",
    HospitalLocation: "13.657158, 100.665805",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "494",
    HospitalName: "ฟรีโซนคลินิกเวชกรรม",
    HospitalAddress: "999 (เขตฟรีโซน) ท่าอากาศยานสุวรรณภูมิ ถนนบางนา-ตราด",
    HospitalSubDistrict: "คลองราชาเทวะ",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2134-6016",
    HospitalWebsite: "",
    HospitalLocation: "13.679185, 100.727436",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "495",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมตลาดไทยประกัน",
    HospitalAddress: "155/27-28 หมู่ 1 ถ.เทพารักษ์",
    HospitalSubDistrict: "บางเสาธง",
    HospitalDistrict: "กิ่งอำเภอบางเสาธง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-706-4050",
    HospitalWebsite: "",
    HospitalLocation: "13.5914368,100.7758134",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "496",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมบางปู",
    HospitalAddress: "888/39 หมู่ 6 ถ.แพรกษา",
    HospitalSubDistrict: "แพรกษา",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-035-7060",
    HospitalWebsite: "",
    HospitalLocation: "13.5578147,100.5744113",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "497",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมแพรกษา",
    HospitalAddress: "754/53-54 หมู่ 1",
    HospitalSubDistrict: "แพรกษาใหม่",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-174-4954",
    HospitalWebsite: "",
    HospitalLocation: "13.5963447,100.6588306",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "498",
    HospitalName: "มิตรไมตรีคลินิกเวชกรรมวัดด่านสำโรง",
    HospitalAddress: "331/6-7 หมู่ 4 ถ.ศรีนครินทร์",
    HospitalSubDistrict: "สำโรงเหนือ",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-037-1933",
    HospitalWebsite: "",
    HospitalLocation: "13.6340097,100.617429",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "499",
    HospitalName: "เมดิก้า คลินิกเวชกรรม",
    HospitalAddress: "42/6 ม.9",
    HospitalSubDistrict: "สุขสวัสดิ์",
    HospitalDistrict: "พระประแดง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2839-1178",
    HospitalWebsite: "",
    HospitalLocation: "13.7274645,100.5325424",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "500",
    HospitalName: "โรงพยาบาลจุฬารัตน์ 1 สุวรรณภูมิ(สถานพยาบาลจุฬารัตน์ 1)",
    HospitalAddress: "68/1-2 ถนนบางนา-ตราด กม. 12",
    HospitalSubDistrict: "คลองราชาเทวะ",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2316-9561-2 , 0-2316-6198-9",
    HospitalWebsite: "",
    HospitalLocation: "13.633986, 100.709709",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "501",
    HospitalName: "โรงพยาบาลจุฬารัตน์ 3",
    HospitalAddress: "88/8 หมู่ 11 ถนนเทพารักษ์ กม.14.5",
    HospitalSubDistrict: "บางปลา",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-0332900",
    HospitalWebsite: "www.chularat.com",
    HospitalLocation: "13.599859,100.7200499",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "502",
    HospitalName: "โรงพยาบาลจุฬารัตน์ 9",
    HospitalAddress: "90/5 หมู่ 13 ถนนกิ่งแก้ว",
    HospitalSubDistrict: "ราชาเทวะ",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2738-9900-9",
    HospitalWebsite: "www.chularat.com",
    HospitalLocation: "13.6778663,100.7201724",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "503",
    HospitalName: "โรงพยาบาลบางนา 2",
    HospitalAddress: "9/9 หมู่ 3 ถนนบางนา-ตราด กม.23",
    HospitalSubDistrict: "บางเสาธง",
    HospitalDistrict: "บางเสาธง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2740-1800-6, 0-2330-3030-5",
    HospitalWebsite: "www.bangna2.com",
    HospitalLocation: "13.5944103,100.8039866",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "504",
    HospitalName: "โรงพยาบาลบางนา 5",
    HospitalAddress: "55 หมู่ 4 ถนนเทพารักษ์ กม.10",
    HospitalSubDistrict: "บางพลีใหญ่",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-138-1155-65",
    HospitalWebsite: "http://bangna.co.th/home_bangna5.html",
    HospitalLocation: "13.6060146,100.6857446",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "505",
    HospitalName: "โรงพยาบาลบางปะกอก สมุทรปราการ",
    HospitalAddress: "99 หมู่ 7 ถนนสุขสวัสดิ์",
    HospitalSubDistrict: "บางครุ",
    HospitalDistrict: "พระประแดง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-109-3222",
    HospitalWebsite: "",
    HospitalLocation: "13.635287, 100.529445",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "506",
    HospitalName: "โรงพยาบาลบางปะกอก 3 พระประแดง",
    HospitalAddress: "27/14 หมู่ 10 ถนนสุขสวัสดิ์",
    HospitalSubDistrict: "บางครุ",
    HospitalDistrict: "พระประแดง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2818-7555",
    HospitalWebsite: "www.bangpakok3.com",
    HospitalLocation: "13.6345685,100.5275955",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "507",
    HospitalName: "โรงพยาบาลเปาโล พระประแดง(โรงพยาบาลกรุงเทพ พระประแดง)",
    HospitalAddress: "288 หมู่ 1 ถนนสุขสวัสดิ์",
    HospitalSubDistrict: "ปากคลองบางปลากด",
    HospitalDistrict: "พระสมุทรเจดีย์",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2818-9000, 0-2425-0029-33",
    HospitalWebsite: "http://www.paolophrapradaeng.com/",
    HospitalLocation: "13.608966, 100.551124",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "508",
    HospitalName: "โรงพยาบาลเปาโล สมุทรปราการ",
    HospitalAddress: "123 หมู่ 8 ถนนศรีนครินทร์",
    HospitalSubDistrict: "บางม่วง",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2389-2555",
    HospitalWebsite: "http://www.paolohospital.com",
    HospitalLocation: "13.5982788,100.6124804",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "509",
    HospitalName: "โรงพยาบาลเมืองสมุทรปากน้ำ",
    HospitalAddress: "456/36 ซอยเทศบาล 12 ถนนนารายณ์ปราบศึก",
    HospitalSubDistrict: "ปากน้ำ",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2173-7766-75",
    HospitalWebsite: "http://www.muangsamut.net",
    HospitalLocation: "13.6208375,100.5547133",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "510",
    HospitalName: "โรงพยาบาลเมืองสมุทรปู่เจ้าสมิงพราย",
    HospitalAddress: "83/16 หมู่ 7 ถนนปู่เจ้าสมิงพราย",
    HospitalSubDistrict: "สำโรงกลาง",
    HospitalDistrict: "พระประแดง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2754-2800-9",
    HospitalWebsite: "",
    HospitalLocation: "13.646389, 100.578360",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "511",
    HospitalName: "โรงพยาบาลรวมชัยประชารักษ์",
    HospitalAddress: "168/26 หมู่ 4 ถนนบางนา- ตราด กม.28-29",
    HospitalSubDistrict: "บางบ่อ",
    HospitalDistrict: "บางบ่อ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2708-7500-20",
    HospitalWebsite: "www.ruamchai-hos.com",
    HospitalLocation: "13.5837286,100.8531283",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "512",
    HospitalName: "โรงพยาบาลรัทรินทร์",
    HospitalAddress: "999/23-29 ถนนสุขุมวิท กม.34",
    HospitalSubDistrict: "บางปู",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2323-2991-7",
    HospitalWebsite: "",
    HospitalLocation: "13.5319052,100.6297059",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "513",
    HospitalName: "โรงพยาบาลวิภาราม ชัยปราการ",
    HospitalAddress: "555 หมู่ 3 ถนนปู่เจ้าสมิงพราย",
    HospitalSubDistrict: "สำโรงกลาง",
    HospitalDistrict: "พระประแดง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-363-9222",
    HospitalWebsite: "",
    HospitalLocation: "13.648875, 100.569870",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "514",
    HospitalName: "โรงพยาบาลสำโรงการแพทย์",
    HospitalAddress: "1748 หมู่ 1 ซอยสุขุมวิท 78 ถนนริมทางรถไฟสายเก่า",
    HospitalSubDistrict: "สำโรงเหนือ",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2361-1111",
    HospitalWebsite: "http://www.samrong-hosp.com",
    HospitalLocation: "13.650496,100.5918503",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "515",
    HospitalName: "โรงพยาบาลสินแพทย์ เทพารักษ์",
    HospitalAddress: "9/99 หมู่ที่ 6 ถนนเทพารักษ์",
    HospitalSubDistrict: "เทพารักษ์",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-761-5999",
    HospitalWebsite: "https://www.synphaet.co.th",
    HospitalLocation: "13.6206374,100.6395584",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "516",
    HospitalName: "โรงพยาบาลเดอะซีพลัส",
    HospitalAddress: "8/8 ม.6 ซอยมังกร-ขันดี ถนนเทพารักษ์",
    HospitalSubDistrict: "แพรกษา",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-3342555",
    HospitalWebsite: "",
    HospitalLocation: "13.6020558,100.6503335",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "517",
    HospitalName: "สถานพยาบาลจุฬารัตน์ 5",
    HospitalAddress: "119/7 ถนนบางเสาธง",
    HospitalSubDistrict: "บางเสาธง",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2315-1870 ,0-2705-1170-4",
    HospitalWebsite: "",
    HospitalLocation: "13.569278,100.7902779",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "518",
    HospitalName: "สถานพยาบาลจุฬาเวช",
    HospitalAddress: "1525/13-14 ถนนเทพารักษ์",
    HospitalSubDistrict: "เทพารักษ์",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2758-2501-6",
    HospitalWebsite: "",
    HospitalLocation: "13.643990, 100.599318",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "519",
    HospitalName: "สถานพยาบาลบางนา 3",
    HospitalAddress: "73/4-8 ถนนกิ่งแก้ว",
    HospitalSubDistrict: "ราชาเทวะ",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2750-1150-3",
    HospitalWebsite: "",
    HospitalLocation: "13.675027, 100.722050",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "520",
    HospitalName: "สถานพยาบาลบารมีการแพทย์",
    HospitalAddress: "1918 หมู่ 4",
    HospitalSubDistrict: "เทพารักษ์",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "02-3803360-4",
    HospitalWebsite: "",
    HospitalLocation: "13.639568, 100.605677",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "521",
    HospitalName: "สถานพยาบาลเมืองสมุทรบางปู",
    HospitalAddress: "1-1/1 ถนนสุขุมวิท",
    HospitalSubDistrict: "",
    HospitalDistrict: "เมืองสมุทรปราการ",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2323-4081-3",
    HospitalWebsite: "",
    HospitalLocation: "13.525325, 100.639428",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "522",
    HospitalName: "สมิติเวช สุวรรณภูมิ คลินิกเวชกรรม",
    HospitalAddress: "999 (ชั้น 3) ท่าอากาศยานสุวรรณภูมิ ถนนบางนา - ตราด",
    HospitalSubDistrict: "",
    HospitalDistrict: "บางพลี",
    HospitalProvince: "สมุทรปราการ",
    HospitalTel: "0-2134-2666 , 0-2134-2668",
    HospitalWebsite: "",
    HospitalLocation: "13.6919782,100.7517165",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "523",
    HospitalName: "โรงพยาบาลมหาชัยแม่กลอง(โรงพยาบาลแม่กลอง)",
    HospitalAddress: "158/1 ถนนราษฎร์ประสิทธิ์",
    HospitalSubDistrict: "แม่กลอง",
    HospitalDistrict: "เมืองสมุทรสงคราม",
    HospitalProvince: "สมุทรสงคราม",
    HospitalTel: "0-3471 5001-5",
    HospitalWebsite: "",
    HospitalLocation: "13.384965, 99.991253",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "524",
    HospitalName: "โรงพยาบาลสมเด็จพระพุทธเลิศหล้า",
    HospitalAddress: "708 ถนนต.แม่กลอง",
    HospitalSubDistrict: "แม่กลอง",
    HospitalDistrict: "เมืองสมุทรสงคราม",
    HospitalProvince: "สมุทรสงคราม",
    HospitalTel: "034-723044",
    HospitalWebsite: "yel_2132@hotmial.com",
    HospitalLocation: "13.4062916,99.9957967",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "525",
    HospitalName: "แพทย์ท่าจีนคลินิกเวชกรรม",
    HospitalAddress: "37/6 หมู่ 4",
    HospitalSubDistrict: "ท่าจีน",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "034-115324",
    HospitalWebsite: "",
    HospitalLocation: "13.539103, 100.243572",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "526",
    HospitalName: "โรงพยาบาลบ้านแพ้ว**",
    HospitalAddress: "198 หมู่ 1 ถนนพระประโทน - บ้านแพ้ว",
    HospitalSubDistrict: "บ้านแพ้ว",
    HospitalDistrict: "บ้านแพ้ว",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "034-419555",
    HospitalWebsite: "www.bphosp.or.th",
    HospitalLocation: "13.6294704,100.1687019",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "527",
    HospitalName: "โรงพยาบาลมหาชัย 2",
    HospitalAddress: "301/1 หมู่ 6 ถนนเพชรเกษม",
    HospitalSubDistrict: "อ้อมน้อย",
    HospitalDistrict: "กระทุ่มแบน",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "0-2810-3442, 0-2431-0054",
    HospitalWebsite: "www.mahachai2.com",
    HospitalLocation: "13.7070269,100.2989674",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "528",
    HospitalName: "โรงพยาบาลมหาชัย(โรงพยาบาลมหาชัย 3)",
    HospitalAddress: "927/43 ถนนเศรษฐกิจ1",
    HospitalSubDistrict: "มหาชัย",
    HospitalDistrict: "เมืองสมุทรสาคร",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "0-3442-4990",
    HospitalWebsite: "",
    HospitalLocation: "13.556678, 100.271769",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "529",
    HospitalName: "โรงพยาบาลวิชัยเวช อินเตอร์เนชั่นแนล สมุทรสาคร(โรงพยาบาลศรีวิชัย 5)",
    HospitalAddress: "93/256 หมู่ 7 ถนนเศรษฐกิจ 1",
    HospitalSubDistrict: "ท่าทราย",
    HospitalDistrict: "เมืองสมุทรสาคร",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "0-3482-6709-29",
    HospitalWebsite: "",
    HospitalLocation: "13.576061, 100.271643",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "530",
    HospitalName: "โรงพยาบาลวิชัยเวช อินเตอร์เนชั่นแนล อ้อมน้อย(โรงพยาบาลศรีวิชัย 3)",
    HospitalAddress: "74/5 หมู่ 4 ถนนเพชรเกษม",
    HospitalSubDistrict: "อ้อมน้อย",
    HospitalDistrict: "กระทุ่มแบน",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "0-2431-0070",
    HospitalWebsite: "http://www.vichaivejnongkhaem.com",
    HospitalLocation: "13.7103092,100.2892693",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "531",
    HospitalName: "โรงพยาบาลวิภาราม สมุทรสาคร",
    HospitalAddress: "9/19 หมู่ 2",
    HospitalSubDistrict: "บางกระเจ้า",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "0-34403700",
    HospitalWebsite: "",
    HospitalLocation: "13.538590, 100.229482",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "532",
    HospitalName: "โรงพยาบาลเอกชัย",
    HospitalAddress: "99/9 หมู่ 4 ถนนเอกชัย",
    HospitalSubDistrict: "โคกขาม",
    HospitalDistrict: "เมืองสมุทรสาคร",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "0-3441-7999",
    HospitalWebsite: "http://www.ekachaihospital.com",
    HospitalLocation: "13.5590471,100.2910317",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "533",
    HospitalName: "หมอวิศิษฐ์การแพทย์",
    HospitalAddress: "1314/5-6 ถนนสุคนธวิท",
    HospitalSubDistrict: "ตลาด",
    HospitalDistrict: "กระทุ่มแบน",
    HospitalProvince: "สมุทรสาคร",
    HospitalTel: "034-472481",
    HospitalWebsite: "",
    HospitalLocation: "13.653263, 100.264044",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "534",
    HospitalName: "คลินิกเวชกรรมจุฬารัตน์โรงเกลือ",
    HospitalAddress: "500/428-431 หมู่ 7",
    HospitalSubDistrict: "",
    HospitalDistrict: "อรัญประเทศ",
    HospitalProvince: "สระแก้ว",
    HospitalTel: "037-247-488",
    HospitalWebsite: "",
    HospitalLocation: "13.669419, 102.549210",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "535",
    HospitalName: "โรงพยาบาลสมเด็จพระยุพราชสระแก้ว",
    HospitalAddress: "283 ถนนสุวรรณศร",
    HospitalSubDistrict: "สระแก้ว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระแก้ว",
    HospitalTel: "037-243018-20",
    HospitalWebsite: "www.skh.go.th",
    HospitalLocation: "13.816585, 102.072005",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "536",
    HospitalName: "โรงพยาบาลเกษมราษฎร์ อินเตอร์เนชั่นแนล อรัญประเทศ",
    HospitalAddress: "885 หมู่5 ถ.สุวรรณศร",
    HospitalSubDistrict: "บ้านใหม่หนองไทร",
    HospitalDistrict: "อรัญประเทศ",
    HospitalProvince: "สระแก้ว",
    HospitalTel: "037-640000",
    HospitalWebsite: "",
    HospitalLocation: "13.71223,102.47794",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "537",
    HospitalName: "คลินิกทันตกรรมฟันสวย",
    HospitalAddress: "57 ถนนสุดบรรทัด",
    HospitalSubDistrict: "แก่งคอย",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-320295",
    HospitalWebsite: "",
    HospitalLocation: "14.589700, 101.002842",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "538",
    HospitalName: "คลินิกรักฟัน",
    HospitalAddress: "70/2 ถนนสุดบรรทัด",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-213216",
    HospitalWebsite: "",
    HospitalLocation: "14.522687, 100.914172",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "539",
    HospitalName: "คลินิกเวชกรรมหมอฉัตรชัย",
    HospitalAddress: "59/2",
    HospitalSubDistrict: "ตาลเดี่ยว",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "089-1118020",
    HospitalWebsite: "",
    HospitalLocation: "14.575392, 100.993800",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "540",
    HospitalName: "คลินิกอายุรกรรมหมอพิพัฒ",
    HospitalAddress: "50/26-28 ถ. เทศบาล 8",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-214456",
    HospitalWebsite: "",
    HospitalLocation: "14.526898, 100.918312",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "541",
    HospitalName: "คลินิกเอกชัยทันตแพทย์",
    HospitalAddress: "519/16 ถนนเลียบสันติสุข",
    HospitalSubDistrict: "แก่งคอย",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-244070",
    HospitalWebsite: "",
    HospitalLocation: "14.590252, 101.004170",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "542",
    HospitalName: "เดนทัลสไมล์ พระพุทบาท คลินิกทันตกรรม",
    HospitalAddress: "61/3 ถนนหลังโรงพยาบาลพรพุทธบาท",
    HospitalSubDistrict: "ธารเกษม",
    HospitalDistrict: "พระพุทธบาท",
    HospitalProvince: "สระบุรี",
    HospitalTel: "095-6023282",
    HospitalWebsite: "",
    HospitalLocation: "14.725814, 100.800942",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "543",
    HospitalName: "เดนทัลสไมล์คลินิกทันตกรรม",
    HospitalAddress: "177 ถนนสุดบรรทัด-ปากเพรียว",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "088-2938924",
    HospitalWebsite: "",
    HospitalLocation: "14.524219, 100.924366",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "544",
    HospitalName: "ธาราเวชคลินิกเวชกรรม(ชื่อเดิม คลินิกหมอสมบัติ-หมอวราภรณ์)",
    HospitalAddress: "56/9 ถนนสุดบรรทัด",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "089-8004843",
    HospitalWebsite: "",
    HospitalLocation: "14.525389, 100.924278",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "545",
    HospitalName: "แพทย์ผจงนิติคลินิกเวชกรรม",
    HospitalAddress: "147/2 หมู่ 1",
    HospitalSubDistrict: "ตาลเดี่ยว",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "089-1232870",
    HospitalWebsite: "",
    HospitalLocation: "14.584261, 100.973611",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "546",
    HospitalName: "โรงพยาบาลเกษมราษฎร์สระบุรี",
    HospitalAddress: "2/22 ถนนมิตรภาพ",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมืองสระบุรี",
    HospitalProvince: "สระบุรี",
    HospitalTel: "0-3631-5555-94 ,081-4666740",
    HospitalWebsite: "www.kasemrad.co.th/Saraburi/",
    HospitalLocation: "14.5241025,100.9318793",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "547",
    HospitalName: "โรงพยาบาลปภาเวช",
    HospitalAddress: "4 ซ.เศรษฐสัมพันธ์ 5 ถ.เศรษฐสัมพันธ์",
    HospitalSubDistrict: "หนองแค",
    HospitalDistrict: "หนองแค",
    HospitalProvince: "สระบุรี",
    HospitalTel: "03-637-1818",
    HospitalWebsite: "",
    HospitalLocation: "14.3426247,100.8660086",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "548",
    HospitalName: "โรงพยาบาลพระพุทธบาท",
    HospitalAddress: "86 หมู่ 8",
    HospitalSubDistrict: "ธารเกษม",
    HospitalDistrict: "พระพุทธบาท",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-266166",
    HospitalWebsite: "http://www.pbh.go.th/",
    HospitalLocation: "14.724322, 100.801940",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "549",
    HospitalName: "โรงพยาบาลมิตรภาพเมโมเรียลสระบุรี",
    HospitalAddress: "9/1 ถนนมิตรภาพ",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมืองสระบุรี",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-218900",
    HospitalWebsite: "",
    HospitalLocation: "14.520423, 100.915371",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "550",
    HospitalName: "โรงพยาบาลอภิณพเวชกรรม",
    HospitalAddress: "36 ถนนวรสวัสดิ์",
    HospitalSubDistrict: "แก่งคอย",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-246902, 036-244133",
    HospitalWebsite: "",
    HospitalLocation: "14.590595, 101.000747",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "551",
    HospitalName: "คลินิกทันตกรรมไอสมาย",
    HospitalAddress: "68/15-16 ถนนสุดบรรทัด",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-340959",
    HospitalWebsite: "",
    HospitalLocation: "14.523122, 100.918828",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "552",
    HospitalName: "คลินิกทันตกรรมยิ้มหวาน",
    HospitalAddress: "73 ถนนอุไรรัตน์",
    HospitalSubDistrict: "แก่งคอย",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "095-054 5615",
    HospitalWebsite: "",
    HospitalLocation: "14.584957, 100.995855",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "553",
    HospitalName: "คลินิกทันตกรรม ซีสไมล์",
    HospitalAddress: "173/117",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-663233",
    HospitalWebsite: "",
    HospitalLocation: "14.523496,100.919768",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "554",
    HospitalName: "คลินิกทันตกรรมไอแคร์",
    HospitalAddress: "160 หมู่ 1 ถนนสุดบรรทัด",
    HospitalSubDistrict: "ตาลเดี่ยว",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-247247",
    HospitalWebsite: "",
    HospitalLocation: "14.584206,100.972508",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกทันตกรรม"
  },
  {
    HospitalNumber: "555",
    HospitalName: "คลินิกหมอปวิธ",
    HospitalAddress: "203/2-3 ถนนเทศบาล 4",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "081-3728821",
    HospitalWebsite: "",
    HospitalLocation: "14.537326,100.918760",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "556",
    HospitalName: "กิตติวัฒน์-ศิวพร คลินิก",
    HospitalAddress: "1/35-1/36 ซอยสุดบรรทัด 13",
    HospitalSubDistrict: "ปากเพรียว",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สระบุรี",
    HospitalTel: "098-7188878",
    HospitalWebsite: "",
    HospitalLocation: "14.526492,100.924202",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "557",
    HospitalName: "คลินิกเวชกรรมนายแพทย์จาตุรนต์",
    HospitalAddress: "50/14 หมู่บ้านอยู่สบาย 1",
    HospitalSubDistrict: "โคกแย้",
    HospitalDistrict: "หนองแค",
    HospitalProvince: "สระบุรี",
    HospitalTel: "086-6655036",
    HospitalWebsite: "",
    HospitalLocation: "14.403315,100.901206",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "558",
    HospitalName: "แพทย์ประสงค์(รวมแพทย์)คลินิกเวชกรรม",
    HospitalAddress: "268-270 ถ.สุดบรรทัด",
    HospitalSubDistrict: "แก่งคอย",
    HospitalDistrict: "แก่งคอย",
    HospitalProvince: "สระบุรี",
    HospitalTel: "036-244765",
    HospitalWebsite: "",
    HospitalLocation: "14.589789,101.003333",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "559",
    HospitalName: "โรงพยาบาลสิงห์บุรีเวชชการ(หมอประเจิด)",
    HospitalAddress: "80/1 หมู่ 6 ถนนสิงห์บุรี-อ่างทอง",
    HospitalSubDistrict: "ต้นโพธิ์",
    HospitalDistrict: "เมืองสิงห์บุรี",
    HospitalProvince: "สิงห์บุรี",
    HospitalTel: "036-520517",
    HospitalWebsite: "",
    HospitalLocation: "14.888768, 100.398479",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "560",
    HospitalName: "โรงพยาบาลอินทร์บุรี",
    HospitalAddress: "เลขที่37/7 หมู่1 ถนนสิงห์บุรี-ชัยนาท",
    HospitalSubDistrict: "ทับยา",
    HospitalDistrict: "อินทร์บุรี",
    HospitalProvince: "สิงห์บุรี",
    HospitalTel: "036-581993",
    HospitalWebsite: "",
    HospitalLocation: "14.988196, 100.334229",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "561",
    HospitalName: "โรงพยาบาลพัฒนเวชสุโขทัย",
    HospitalAddress: "89/9 ถนนสิงหวัฒน์",
    HospitalSubDistrict: "ธานี",
    HospitalDistrict: "เมืองสุโขทัย",
    HospitalProvince: "สุโขทัย",
    HospitalTel: "0-5562-1502-7/0-5561-1292",
    HospitalWebsite: "",
    HospitalLocation: "17.0089436,99.8224524",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "562",
    HospitalName: "โรงพยาบาลรวมแพทย์สุโขทัย",
    HospitalAddress: "151 หมู่ 1",
    HospitalSubDistrict: "บ้านกล้วย",
    HospitalDistrict: "เมืองสุโขทัย",
    HospitalProvince: "สุโขทัย",
    HospitalTel: "0-5561-2211-12",
    HospitalWebsite: "",
    HospitalLocation: "17.0111132,99.7980172",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "563",
    HospitalName: "โรงพยาบาลสุโขทัย",
    HospitalAddress: "2/1 หมู่ 12 ถนนจรดวิถีถ่อง",
    HospitalSubDistrict: "บ้านกล้วย",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สุโขทัย",
    HospitalTel: "0-55610586",
    HospitalWebsite: "",
    HospitalLocation: "17.010852, 99.796047",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "564",
    HospitalName: "โรงพยาบาลเจ้าพระยายมราช",
    HospitalAddress: "950, ถนนม้าสีหมอก",
    HospitalSubDistrict: "ท่าพี่เลี้ยง",
    HospitalDistrict: "เมืองสุพรรณบุรี",
    HospitalProvince: "สุพรรณบุรี",
    HospitalTel: "035-521555 ต่อ 1215",
    HospitalWebsite: "",
    HospitalLocation: "14.466561, 100.116075",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "565",
    HospitalName: "โรงพยาบาลธนบุรีอู่ทอง",
    HospitalAddress: "2000/22 หมู่ 6 ถนนมาลัยแมน",
    HospitalSubDistrict: "อู่ทอง",
    HospitalDistrict: "อู่ทอง",
    HospitalProvince: "สุพรรณบุรี",
    HospitalTel: "0-3540-4053-9",
    HospitalWebsite: "http://www.thonburi-uthong.com/",
    HospitalLocation: "14.3791759,99.9039033",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "566",
    HospitalName: "โรงพยาบาลวิภาวดี ปิยราษฎร์",
    HospitalAddress: "1618/1 หมู่ 6 ถนนอู่ทอง-ด่านช้าง",
    HospitalSubDistrict: "อู่ทอง",
    HospitalDistrict: "อู่ทอง",
    HospitalProvince: "สุพรรณบุรี",
    HospitalTel: "0-3555-2724-7",
    HospitalWebsite: "https://www.piyarasd.com/",
    HospitalLocation: "14.3812746,99.8947221",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "567",
    HospitalName: "โรงพยาบาลศุภมิตรสุพรรณบุรี",
    HospitalAddress: "76 ถนนเณรแก้ว",
    HospitalSubDistrict: "ท่าพี่เลี้ยง",
    HospitalDistrict: "เมืองสุพรรณบุรี",
    HospitalProvince: "สุพรรณบุรี",
    HospitalTel: "0-3550-0283-8",
    HospitalWebsite: "www.supamitrhospital.com",
    HospitalLocation: "14.4719797,100.123318",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "568",
    HospitalName: "โรงพยาบาลพรชัย",
    HospitalAddress: "43/14,16 ถนนหมื่นหาญ",
    HospitalSubDistrict: "ท่าพี่เลี้ยง",
    HospitalDistrict: "เมืองสุพรรณบุรี",
    HospitalProvince: "สุพรรณบุรี",
    HospitalTel: "0-35514444",
    HospitalWebsite: "",
    HospitalLocation: "14.466475, 100.123197",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "569",
    HospitalName: "คลินิกไทยอินเตอร์การแพทย์(เกาะพงัน)",
    HospitalAddress: "211/8-9",
    HospitalSubDistrict: "เกาะพะงัน",
    HospitalDistrict: "เกาะพะงัน",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7723-9508",
    HospitalWebsite: "",
    HospitalLocation: "9.7325632,99.9826223",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "570",
    HospitalName: "คลินิกโรงพยาบาลกรุงเทพสมุย",
    HospitalAddress: "57 หมู่ 3 ถนนทวีราษฎร์ภักดี",
    HospitalSubDistrict: "บ่อผุด",
    HospitalDistrict: "เกาะสมุย",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7723-9599",
    HospitalWebsite: "",
    HospitalLocation: "9.5145971,100.0494821",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "571",
    HospitalName: "คลินิกเวชกรรมนายแพทย์วิรัตน์",
    HospitalAddress: "46/8 หมู่ 6",
    HospitalSubDistrict: "บ่อผุด",
    HospitalDistrict: "เกาะสมุย",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-447880",
    HospitalWebsite: "",
    HospitalLocation: "9.528358, 100.045246",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "572",
    HospitalName: "คลินิกเวชกรรมเฟิร์สเวสเทอร์น",
    HospitalAddress: "112/44 หมู่ที่ 1 ถนนตำบลเกาะพะงัน",
    HospitalSubDistrict: "บ้านใต้",
    HospitalDistrict: "เกาะพะงัน",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-375 221 , 077-375 202",
    HospitalWebsite: "",
    HospitalLocation: "9.708196, 99.997492",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "573",
    HospitalName: "คลินิกเวชกรรมสยามอินเตอร์เนชั่นแนล สาขาพะงัน 1",
    HospitalAddress: "126 หมู่ 6",
    HospitalSubDistrict: "บ้านใต้",
    HospitalDistrict: "เกาะพะงัน",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-375521",
    HospitalWebsite: "",
    HospitalLocation: "9.676505, 100.067238",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "574",
    HospitalName: "คลินิกเวชกรรมสยามอินเตอร์เนชั่นแนล สาขาพะงัน 2",
    HospitalAddress: "94 หมู่ 6",
    HospitalSubDistrict: "บ้านใต้",
    HospitalDistrict: "เกาะพะงัน",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-375372",
    HospitalWebsite: "",
    HospitalLocation: "9.676505, 100.067238",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "575",
    HospitalName: "โรงพยาบาลกรุงเทพ สมุย",
    HospitalAddress: "57 หมู่ 3 ถนนทวีราษฎร์ภักดี",
    HospitalSubDistrict: "บ่อผุด",
    HospitalDistrict: "เกาะสมุย",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7742-9500",
    HospitalWebsite: "www.bangkokhospitalsamui.com",
    HospitalLocation: "9.514745, 100.050237",
    HospitalAdditionalService: "Telemedicine",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "576",
    HospitalName: "โรงพยาบาลกรุงเทพ สุราษฎร์",
    HospitalAddress: "179, 179/1 หมู่ 1",
    HospitalSubDistrict: "วัดประดู่",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "1719",
    HospitalWebsite: "www.bangkoksurathospital.com",
    HospitalLocation: "9.12224,99.2919131",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "577",
    HospitalName: "โรงพยาบาลเกาะสมุย",
    HospitalAddress: "61 หมู่ 1 ถนนต.อ่างทอง",
    HospitalSubDistrict: "อ่างทอง",
    HospitalDistrict: "เกาะสมุย",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-421-232",
    HospitalWebsite: "https://www.samuihospital.go.th",
    HospitalLocation: "9.521845, 99.935495",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "578",
    HospitalName: "โรงพยาบาลทักษิณ",
    HospitalAddress: "309/2 ถนนตลาดใหม่",
    HospitalSubDistrict: "ตลาด",
    HospitalDistrict: "เมืองสุราษฎร์ธานี",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7728-5701-5",
    HospitalWebsite: "",
    HospitalLocation: "9.148192, 99.332914",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "579",
    HospitalName: "โรงพยาบาลทั่วไปขนาดเล็กเวียงเวช",
    HospitalAddress: "475/5",
    HospitalSubDistrict: "",
    HospitalDistrict: "เวียงสระ",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7736-1672",
    HospitalWebsite: "",
    HospitalLocation: "8.634702, 99.368641",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "580",
    HospitalName: "โรงพยาบาลท่าชนะ",
    HospitalAddress: "115 ถนนตำบลท่าชนะ",
    HospitalSubDistrict: "ท่าชนะ",
    HospitalDistrict: "ท่าชนะ",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-381-167",
    HospitalWebsite: "",
    HospitalLocation: "9.597453, 99.165946",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "581",
    HospitalName: "โรงพยาบาลไทยอินเตอร์เนชั่นแนล",
    HospitalAddress: "25/25 หมู่ 6 ถนนทวีราษฎร์ภักดี",
    HospitalSubDistrict: "บ่อผุด",
    HospitalDistrict: "เกาะสมุย",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7741-4400-9,0-7724-5720-6",
    HospitalWebsite: "thaiinterhospital@yahoo.com",
    HospitalLocation: "9.5357963,100.0410537",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "582",
    HospitalName: "โรงพยาบาลบ้านดอน อินเตอร์ เกาะสมุย",
    HospitalAddress: "123/1 หมู่1",
    HospitalSubDistrict: "บ่อผุด",
    HospitalDistrict: "เกาะสมุย",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7724-5236-9",
    HospitalWebsite: "",
    HospitalLocation: "9.544478, 100.040054",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "583",
    HospitalName: "โรงพยาบาลเฟิร์สเวสเทอร์น",
    HospitalAddress: "112/44 หมู่ที่ 1 ถนนตำบลเกาะพะงัน",
    HospitalSubDistrict: "เกาะพะงัน",
    HospitalDistrict: "เกาะพะงัน",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-377 472-4 # 400 , 093-671 3596",
    HospitalWebsite: "",
    HospitalLocation: "9.7196757,100.0459188",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "584",
    HospitalName: "โรงพยาบาลศรีวิชัยสุราษฎร์ธานี",
    HospitalAddress: "67/47-50 หมู่ 2 ถนนศรีวิชัย",
    HospitalSubDistrict: "มะขามเตี้ย",
    HospitalDistrict: "เมืองสุราษฎร์ธานี",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7728-2520-1, 0-7722-0396",
    HospitalWebsite: "",
    HospitalLocation: "9.1158774,99.308888",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "585",
    HospitalName: "โรงพยาบาลสมเด็จพระยุพราชเวียงสระ**",
    HospitalAddress: "204/16 หมู่ 10",
    HospitalSubDistrict: "บ้านส้อง",
    HospitalDistrict: "เวียงสระ",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7736-1283, 0-7736-2013, 0-7736-3775",
    HospitalWebsite: "",
    HospitalLocation: "8.637507, 99.371336",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "586",
    HospitalName: "โรงพยาบาลสมุยอินเตอร์เนชั่นแนล",
    HospitalAddress: "90/2 หมู่ 2 ถนนเลียบหาดเฉวง",
    HospitalSubDistrict: "บ่อผุด",
    HospitalDistrict: "เกาะสมุย",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "0-7742-2272 , 0-7723-0781",
    HospitalWebsite: "",
    HospitalLocation: "9.5385466,100.06783",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "587",
    HospitalName: "โรงพยาบาลคีรีรัฐนิคม",
    HospitalAddress: "41",
    HospitalSubDistrict: "ย่านยาว",
    HospitalDistrict: "คีรีรัฐนิคม",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-391117-8",
    HospitalWebsite: "",
    HospitalLocation: "9.018088,98.970142",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "588",
    HospitalName: "โรงพยาบาลเกาะพะงัน",
    HospitalAddress: "6 หมู่4",
    HospitalSubDistrict: "เกาะพะงัน",
    HospitalDistrict: "เกาะพะงัน",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-377034",
    HospitalWebsite: "www.kpho.go.th",
    HospitalLocation: "9.735938,99.991254",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "589",
    HospitalName: "โรงพยาบาลกาญจนดิษฐ์",
    HospitalAddress: "76",
    HospitalSubDistrict: "พลายวาส",
    HospitalDistrict: "กาญจนดิษฐ์",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-379131-2",
    HospitalWebsite: "",
    HospitalLocation: "9.161438,99.493237",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "590",
    HospitalName: "โรงพยาบาลพระแสง",
    HospitalAddress: "42 หมู่1",
    HospitalSubDistrict: "อิปัน",
    HospitalDistrict: "พระแสง",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-369098",
    HospitalWebsite: "",
    HospitalLocation: "8.572501,99.243148",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "591",
    HospitalName: "โรงพยาบาลเคียนซา",
    HospitalAddress: "195 หมู่2",
    HospitalSubDistrict: "เคียนซา",
    HospitalDistrict: "เคียนซา",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-387189",
    HospitalWebsite: "",
    HospitalLocation: "80834958,99.159562",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "592",
    HospitalName: "โรงพยาบาลดอนสัก",
    HospitalAddress: "11",
    HospitalSubDistrict: "ดอนสัก",
    HospitalDistrict: "ดอนสัก",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-371401",
    HospitalWebsite: "",
    HospitalLocation: "9.318293,99.694424",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "593",
    HospitalName: "โรงพยาบาลพนม",
    HospitalAddress: "60 หมู่ 3",
    HospitalSubDistrict: "พังกาญจน์",
    HospitalDistrict: "พนม",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-399084",
    HospitalWebsite: "",
    HospitalLocation: "8.865066,98.818874",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "594",
    HospitalName: "โรงพยาบาลจักษุสุราษฎร์",
    HospitalAddress: "44/1 หมู่2 ถนนศรีวิชัย",
    HospitalSubDistrict: "มะขามเตี้ย",
    HospitalDistrict: "เมืองสุราษฎร์ธานี",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-276999",
    HospitalWebsite: "wimonmas@hotmail.co.th",
    HospitalLocation: "9.128694,99.314053",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "595",
    HospitalName: "โรงพยาบาลบ้านนาเดิม",
    HospitalAddress: "358/2 หมู่2",
    HospitalSubDistrict: "บ้านนา",
    HospitalDistrict: "บ้านนาเดิม",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-359018",
    HospitalWebsite: "",
    HospitalLocation: "8.892657,99.305548",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "596",
    HospitalName: "โรงพยาบาลบ้านนาสาร",
    HospitalAddress: "83/4 ถนนนาสาร-คลองหา",
    HospitalSubDistrict: "นาสาร",
    HospitalDistrict: "บ้านนาสาร",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-341416",
    HospitalWebsite: "",
    HospitalLocation: "8.842021,99.367849",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "597",
    HospitalName: "โรงพยาบาลบ้านตาขุน",
    HospitalAddress: "74 ม.3",
    HospitalSubDistrict: "เขาวง",
    HospitalDistrict: "บ้านตาขุน",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-397374",
    HospitalWebsite: "",
    HospitalLocation: "8.91492,98.89036",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "598",
    HospitalName: "โรงพยาบาลท่าฉาง",
    HospitalAddress: "341 ม.1",
    HospitalSubDistrict: "ท่าฉาง",
    HospitalDistrict: "ท่าฉาง",
    HospitalProvince: "สุราษฎร์ธานี",
    HospitalTel: "077-389111",
    HospitalWebsite: "",
    HospitalLocation: "9.25850,99.18820",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "599",
    HospitalName: "คลินิกแพทย์ถาวร",
    HospitalAddress: "359-361 ถนนธนสาร",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมือง",
    HospitalProvince: "สุรินทร์",
    HospitalTel: "044-511-521",
    HospitalWebsite: "",
    HospitalLocation: "14.887087, 103.491871",
    HospitalAdditionalService: "",
    HospitalType: "คลินิกเอกชน"
  },
  {
    HospitalNumber: "600",
    HospitalName: "โรงพยาบาลรวมแพทย์(หมออนันต์)",
    HospitalAddress: "312/1 ถนนเทศบาล 1",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองสุรินทร์",
    HospitalProvince: "สุรินทร์",
    HospitalTel: "0-4451-5700-1, 0-4451-3638, 0-4451-3192",
    HospitalWebsite: "",
    HospitalLocation: "14.8848924,103.4965442",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "601",
    HospitalName: "โรงพยาบาลรวมแพทย์หนองคาย",
    HospitalAddress: "710 พรมดำหริห์ ถนนประจักษ์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองหนองคาย",
    HospitalProvince: "หนองคาย",
    HospitalTel: "0-4242-1412-4",
    HospitalWebsite: "",
    HospitalLocation: "17.8839113,102.7516743",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "602",
    HospitalName: "โรงพยาบาลสมเด็จพระยุพราชท่าบ่อ**",
    HospitalAddress: "161 หมู่ 4",
    HospitalSubDistrict: "ท่าบ่อ",
    HospitalDistrict: "ท่าบ่อ",
    HospitalProvince: "หนองคาย",
    HospitalTel: "042-431015",
    HospitalWebsite: "",
    HospitalLocation: "17.847646, 102.576619",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "603",
    HospitalName: "โรงพยาบาลหนองคาย",
    HospitalAddress: "1158 ถ.มีชัย",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองหนองคาย",
    HospitalProvince: "หนองคาย",
    HospitalTel: "042-413456",
    HospitalWebsite: "",
    HospitalLocation: "17.881733,102.741539",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "604",
    HospitalName: "โรงพยาบาลหนองคายวัฒนา",
    HospitalAddress: "1159/4 หมู่ 2 ถนนประจักษ์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองหนองคาย",
    HospitalProvince: "หนองคาย",
    HospitalTel: "042-465201-9",
    HospitalWebsite: "http://wattanahospital.net",
    HospitalLocation: "17.8773745,102.7359854",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "605",
    HospitalName: "โรงพยาบาลวีระพลการแพทย์",
    HospitalAddress: "12 ถนนวิจารณ์รังสรรค์",
    HospitalSubDistrict: "หนองบัว",
    HospitalDistrict: "เมืองหนองบัวลำภู",
    HospitalProvince: "หนองบัวลำภู",
    HospitalTel: "0-4231-2344-6",
    HospitalWebsite: "www.veerapolkanpathospital.com",
    HospitalLocation: "17.2036804,102.4397138",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "606",
    HospitalName: "โรงพยาบาลหนองบัวลำภู",
    HospitalAddress: "199 หมู่13 ถ.วศวงศ์",
    HospitalSubDistrict: "หนองบัว",
    HospitalDistrict: "เมืองหนองบัวลำภู",
    HospitalProvince: "หนองบัวลำภู",
    HospitalTel: "042-311996-9",
    HospitalWebsite: "",
    HospitalLocation: "17.199176,102.457330",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "607",
    HospitalName: "โรงพยาบาลอ่างทองเวชชการ 2",
    HospitalAddress: "29/9 หมู่ 2 ถนนเลี่ยงเมือง",
    HospitalSubDistrict: "ศาลาแดง",
    HospitalDistrict: "เมืองสุพรรณบุรี",
    HospitalProvince: "อ่างทอง",
    HospitalTel: "0-3561-2361-4",
    HospitalWebsite: "",
    HospitalLocation: "14.5910052,100.4398669",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "608",
    HospitalName: "โรงพยาบาลกรุงเทพ อุดร",
    HospitalAddress: "111 ถนนประจักษ์ศิลปาคม",
    HospitalSubDistrict: "หมากแข้ง",
    HospitalDistrict: "เมืองอุดรธานี",
    HospitalProvince: "อุดรธานี",
    HospitalTel: "0-4234-3111",
    HospitalWebsite: "www.bangkokhospital.com",
    HospitalLocation: "17.406349, 102.803753",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "609",
    HospitalName: "โรงพยาบาลนอร์ทอีสเทอร์นวัฒนา",
    HospitalAddress: "70/7-8 ถนนศุภกิจจรรยา",
    HospitalSubDistrict: "หมากแข้ง",
    HospitalDistrict: "เมืองอุดรธานี",
    HospitalProvince: "อุดรธานี",
    HospitalTel: "0-4232-5999, 0-4224-1031-3",
    HospitalWebsite: "http://wattanahospital.net",
    HospitalLocation: "17.4224998,102.7833966",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "610",
    HospitalName: "โรงพยาบาลเอกอุดร",
    HospitalAddress: "555/5 ถนนโพธิ์ศรี",
    HospitalSubDistrict: "หมากแข้ง",
    HospitalDistrict: "เมืองอุดรธานี",
    HospitalProvince: "อุดรธานี",
    HospitalTel: "0-4234-2555",
    HospitalWebsite: "www.aekudon.com",
    HospitalLocation: "17.398194,102.8020417",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "611",
    HospitalName: "โรงพยาบาลอุดรธานี",
    HospitalAddress: "33 ถ.เพาะนิยม",
    HospitalSubDistrict: "หมากแข้ง",
    HospitalDistrict: "เมืองอุดรธานี",
    HospitalProvince: "อุดรธานี",
    HospitalTel: "042-245555",
    HospitalWebsite: "",
    HospitalLocation: "17.415201,102.780302",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "612",
    HospitalName: "โรงพยาบาลกุมภวาปี",
    HospitalAddress: "97 หมู่7",
    HospitalSubDistrict: "กุมภวาปี",
    HospitalDistrict: "กุมภวาปี",
    HospitalProvince: "อุดรธานี",
    HospitalTel: "042-334400-2",
    HospitalWebsite: "",
    HospitalLocation: "17.105296, 103.019777",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "613",
    HospitalName: "โรงพยาบาลพิษณุเวช อุตรดิตถ์",
    HospitalAddress: "888 หมู่ 5",
    HospitalSubDistrict: "ป่าเซ่า",
    HospitalDistrict: "เมืองอุตรดิตถ์",
    HospitalProvince: "อุตรดิตถ์",
    HospitalTel: "055 409 000",
    HospitalWebsite: "",
    HospitalLocation: "17.608157,100.123766",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "614",
    HospitalName: "โรงพยาบาลอุตรดิตถ์**",
    HospitalAddress: "38 ถ.เจษฎาบดินทร์",
    HospitalSubDistrict: "ท่าอิฐ",
    HospitalDistrict: "เมืองอุตรดิตถ์",
    HospitalProvince: "อุตรดิตถ์",
    HospitalTel: "055-409999",
    HospitalWebsite: "",
    HospitalLocation: "17.619035,100.086900",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "615",
    HospitalName: "โรงพยาบาลพริ้นซ์ อุทัยธานี",
    HospitalAddress: "256",
    HospitalSubDistrict: "สะแกกรัง",
    HospitalDistrict: "เมืองอุทัยธานี",
    HospitalProvince: "อุทัยธานี",
    HospitalTel: "056-049899",
    HospitalWebsite: "",
    HospitalLocation: "15.403433,100.031341",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "616",
    HospitalName: "โรงพยาบาลราชเวช อุบลราชธานี",
    HospitalAddress: "999 ถนนชยางกูร",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองอุบลราชธานี",
    HospitalProvince: "อุบลราชธานี",
    HospitalTel: "0-4528-0040-55",
    HospitalWebsite: "www.rajavejubon.com",
    HospitalLocation: "15.2672954,104.8429805",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "617",
    HospitalName: "โรงพยาบาลสรรพสิทธิประสงค์",
    HospitalAddress: "122 ถ.สรรพสิทธิ์",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองอุบลราชธานี",
    HospitalProvince: "อุบลราชธานี",
    HospitalTel: "045-244973",
    HospitalWebsite: "www.sunpasitinter.com",
    HospitalLocation: "15.2345402,104.8650284",
    HospitalAdditionalService: "",
    HospitalType: "รัฐบาล"
  },
  {
    HospitalNumber: "618",
    HospitalName: "โรงพยาบาลสรรพสิทธิ์อินเตอร์",
    HospitalAddress: "ถนนพลแพน",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองอุบลราชธานี",
    HospitalProvince: "อุบลราชธานี",
    HospitalTel: "0-45243222",
    HospitalWebsite: "www.sunpasitinter.com",
    HospitalLocation: "15.2345402,104.8650284",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "619",
    HospitalName: "โรงพยาบาลอุบลรักษ์ธนบุรี",
    HospitalAddress: "46/4 ถนนบูรพาใน",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองอุบลราชธานี",
    HospitalProvince: "อุบลราชธานี",
    HospitalTel: "0-4526-0300-5",
    HospitalWebsite: "www.ubonrak.co.th",
    HospitalLocation: "15.231602,104.8703547",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "620",
    HospitalName: "โรงพยาบาลมะเร็งชีวามิตรา",
    HospitalAddress: "355 ม.14",
    HospitalSubDistrict: "ขามใหญ่",
    HospitalDistrict: "เมืองอุบลราชธานี",
    HospitalProvince: "อุบลราชธานี",
    HospitalTel: "045-958888",
    HospitalWebsite: "",
    HospitalLocation: "15.31901,104.83211",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  },
  {
    HospitalNumber: "621",
    HospitalName: "โรงพยาบาลพริ้นซ์ อุบลราชธานี",
    HospitalAddress: "139 ถ.พลแพน",
    HospitalSubDistrict: "ในเมือง",
    HospitalDistrict: "เมืองอุบลราชธานี",
    HospitalProvince: "อุบลราชธานี",
    HospitalTel: "045-244999",
    HospitalWebsite: "",
    HospitalLocation: "15.23475,104.86612",
    HospitalAdditionalService: "",
    HospitalType: "เอกชน"
  }
]


export { dhpHospitalData }
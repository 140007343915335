import React, { Component, useEffect, useState, useReducer } from "react";
import BackgroundSlider from "react-background-slider";
import { useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  Button,
  Badge,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap";
import { Link } from "react-router-dom";

import { db } from '../../firebase/config'

import axios from 'axios'

// //Import components
// import PageBreadcrumb from "../../../components/Shared/PageBreadcrumb";

// Import Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import covidinsuranceproduct from '../../shared/images/products/COVID_BUTTON.png';
import smkinsuranceproduct from '../../shared/images/products/SMK.png';
import asiainsuranceproduct from '../../shared/images/products/AII.png';
import dhpinsuranceproduct from '../../shared/images/products/DHP.png';
import vibinsuranceproduct from '../../shared/images/products/VIB.png';
import seiinsuranceproduct from '../../shared/images/products/SEI.png';
import seilogo from '../../shared/images/products/SEI_TRAN.png';
import smklogo from '../../shared/images/products/SMK_TRAN.png';
import asialogo from '../../shared/images/products/AII_TRAN.png';
import dhplogo from '../../shared/images/products/DHP_TRAN.png';
import viblogo from '../../shared/images/products/VIB_TRAN.png';

import { planCovidNewList } from '../../shared/masters/refData'

// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

import tvinsureCheckIdStatus from '../../shared/images/TVinsure-Covid-Test-md-long.jpg';

import './Product.css'


const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const compareReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      const SelectComparePackage = [
        ...state,
        { packageId: action.id }
      ];

      // Check whether same package is selected or not
      const SameComparePackage = SelectComparePackage.filter(id => id.packageId === action.id)

      // if package is not the same return added package but not more than 3 packages
      if (SameComparePackage.length <= 1) {
        return SelectComparePackage.slice(0, 3);
      }
      // If package is the same filter out this package
      return SelectComparePackage.filter(id => id.packageId !== action.id);

    case 'CLEAR':
      return []
    default:
      return state;
  }
};

const ShopProductList = () => {
  const agentToken = useParams().agenttoken;

  const [isLoading, setIsLoading] = useState(false);
  const [covidData, setCovidData] = useState({});
  const [agentData, setAgentData] = useState({});
  const [originalProduct, setOriginalProduct] = useState([])
  const [topProducts, setTopProducts] = useState([]);

  const [productData, setProductData] = useState({
    sort: "",
    products: []
  });

  const { products } = productData

  useEffect(() => {
    window.scrollTo(0, 0);
    const sendCovidRequest = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`https://static.easysunday.com/covid-19/getTodayCases.json`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setCovidData(response.data)
        setIsLoading(false);
      } catch (err) {
        console.log(err)
        setCovidData(null);
        setIsLoading(false);
      }
    }
    sendCovidRequest()
    db.collection("genCovidShopToken").where("tokenid", "==", agentToken).get()
      .then((snapshot) => {
        if (!snapshot.docs[0].data().shopView) {
          db.collection('genCovidShopToken').doc(snapshot.docs[0].id).update({ shopView: 1 })
            .catch((error) => {
              console.log(error)
            })
        }
        if (snapshot.docs[0].data().shopView > 0) {
          db.collection('genCovidShopToken').doc(snapshot.docs[0].id).update({ shopView: snapshot.docs[0].data().shopView + 1 })
            .catch((error) => {
              console.log(error)
            })
        }
        setAgentData(snapshot.docs[0].data());
        const planCovidNewListFilter = planCovidNewList.filter(p => p.PLAN_ID !== "TEST-SMK" && p.PLAN_ID !== "TEST-SEI" && p.PRODUCT_STATUS === "ACTIVE")
        function getPackageRearrange() {
          const output = [];
          for (let i = 0; i < planCovidNewListFilter.length; i++)
            output.push({
              id: i,
              code: planCovidNewListFilter[i].PLAN_ID,
              issuerCode: planCovidNewListFilter[i].PLAN_ISSUER_CODE,
              totalPremium: Number(planCovidNewListFilter[i].PLAN_TOTAL_PREMIUM),
              image: planCovidNewListFilter[i].PLAN_ISSUER_CODE === "SMK" ? smkinsuranceproduct : planCovidNewListFilter[i].PLAN_ISSUER_CODE === "DHP" ? dhpinsuranceproduct : planCovidNewListFilter[i].PLAN_ISSUER_CODE === "AII" ? asiainsuranceproduct : planCovidNewListFilter[i].PLAN_ISSUER_CODE === "VIB" ? vibinsuranceproduct : planCovidNewListFilter[i].PLAN_ISSUER_CODE === "SEI" ? seiinsuranceproduct : seiinsuranceproduct,
              imgOverlay: covidinsuranceproduct,
              name: planCovidNewListFilter[i].PLAN_DESC,
              price: planCovidNewListFilter[i].PLAN_TOTAL_PREMIUM,
              // oldPrice: "25.00",
              // sucessPrice: "30% off",
              // isNew: true,
              // isFeatures: true,
              // isSale: true,
              isGoodSelling: planCovidNewListFilter[i].PLAN_IS_GOOD_SELLING,
              score: planCovidNewListFilter[i].PLAN_SCORE === "4" ? [1, 2, 3, 4] : planCovidNewListFilter[i].PLAN_SCORE === "5" ? [1, 2, 3, 4, 5] : [],
              diagnostic: planCovidNewListFilter[i].PLAN_DIAGNOSTIC,
              hospital: planCovidNewListFilter[i].PLAN_HOSPITAL,
              compensation: planCovidNewListFilter[i].PLAN_COMPENSATION,
              allergyVaccine: planCovidNewListFilter[i].PLAN_ALLERGYVACCINE,
              desc: planCovidNewListFilter[i].PLAN_COV_TITLE,
              subDesc: planCovidNewListFilter[i].PLAN_COV_DETAIL,
              link: `/application-form/${planCovidNewListFilter[i].PLAN_ID}/detail/${snapshot.docs[0].data().tokenid}`
            });
          return output;
        }
        setOriginalProduct(getPackageRearrange())
        setProductData(prevValue => {
          return {
            ...prevValue,
            products: getPackageRearrange()
          }
        })
        setTopProducts([
          {
            code: "ASIA-500",
            image: asiainsuranceproduct,
            name: "เอเชีย 500",
            // oldPrice: "22.00",
            NewPrice: "500 บาท ",
            link: `/application-form/ASIA-500/detail/${snapshot.docs[0].data().tokenid}`
          },
          {
            code: "ASIA-1825",
            image: asiainsuranceproduct,
            name: "เอเชีย 1825",
            // oldPrice: "22.00",
            NewPrice: "1825 บาท ",
            link: `/application-form/ASIA-1825/detail/${snapshot.docs[0].data().tokenid}`
          },
          {
            code: "DHP-850-N4",
            image: dhpinsuranceproduct,
            name: "เจอ จ่าย แต่ไม่จบ",
            // oldPrice: "22.00",
            NewPrice: "850 บาท ",
            link: `/application-form/DHP-850-N4/detail/${snapshot.docs[0].data().tokenid}`
          },
          {
            code: "VIB-SUPER-SHIELD-399",
            image: vibinsuranceproduct,
            name: "Super Shield 1",
            // oldPrice: "22.00",
            NewPrice: "399 บาท ",
            link: `/application-form/VIB-SUPER-SHIELD-399/detail/${snapshot.docs[0].data().tokenid}`
          },
        ])
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setAgentData({
          agentCode: "1529",
          agentName: "ลูกค้าตรง"
        });
        console.log(error);
      });
  }, [])

  const handlefilterIssuer = (issuer) => {
    if (issuer === "ALL") {
      setProductData(prevValue => {
        return {
          ...prevValue,
          products: originalProduct
        }
      });
      window.scrollTo(0, 0);
    }
    else {
      const planCovidNewListFilterIssuer = originalProduct.filter(p => p.issuerCode === issuer)
      setProductData(prevValue => {
        return {
          ...prevValue,
          products: planCovidNewListFilterIssuer
        }
      });
      window.scrollTo(0, 0);
    }
  }

  const handlefilterCoverage = (coverage) => {
    if (coverage === "ALL") {
      setProductData(prevValue => {
        return {
          ...prevValue,
          products: originalProduct
        }
      });
      window.scrollTo(0, 0);
    }

    if (coverage === "DIAGNOSTIC") {
      const planCovidNewListFilterIssuer = originalProduct.filter(p => p.diagnostic === true)
      setProductData(prevValue => {
        return {
          ...prevValue,
          products: planCovidNewListFilterIssuer
        }
      });
      window.scrollTo(0, 0);
    }

    if (coverage === "HOSPITAL") {
      const planCovidNewListFilterIssuer = originalProduct.filter(p => p.hospital === true)
      setProductData(prevValue => {
        return {
          ...prevValue,
          products: planCovidNewListFilterIssuer
        }
      });
      window.scrollTo(0, 0);
    }

    if (coverage === "COMPENSATION") {
      const planCovidNewListFilterIssuer = originalProduct.filter(p => p.compensation === true)
      setProductData(prevValue => {
        return {
          ...prevValue,
          products: planCovidNewListFilterIssuer
        }
      });
      window.scrollTo(0, 0);
    }

    if (coverage === "ALLERGYVACCINE") {
      const planCovidNewListFilterIssuer = originalProduct.filter(p => p.allergyVaccine === true)
      setProductData(prevValue => {
        return {
          ...prevValue,
          products: planCovidNewListFilterIssuer
        }
      });
      window.scrollTo(0, 0);
    }
  }

  // console.log(agentData);
  // console.log(products);
  // console.log(covidData);

  const handleChange = event => {
    const { name, value } = event.target

    // console.log(value);

    if (value === "PREMIUM_HIGH_TO_LOW") {

      setProductData({
        sort: value,
        products: products.sort((a, b) => (a.totalPremium < b.totalPremium) ? 1 : -1)
      })
    }

    if (value === "PREMIUM_LOW_TO_HIGH") {
      setProductData({
        sort: value,
        products: products.sort((a, b) => (a.totalPremium > b.totalPremium) ? 1 : -1)
      })
    }
  }

  const [packageCompare, dispatch] = useReducer(compareReducer, [], () => {
    const localComparePackageData = localStorage.getItem('comparePackageId');
    return localComparePackageData ? JSON.parse(localComparePackageData) : []
  })

  useEffect(() => {
    // if package is zero selected so close all modal
    if (packageCompare.length === 0) {
      setDetailModal(false);
    }
    localStorage.setItem('comparePackageId', JSON.stringify(packageCompare));
  }, [packageCompare])

  // console.log(packageCompare)

  // Manage detailModal submit form
  const [detailModal, setDetailModal] = useState(false);

  const addDetailPackage = (packageId) => {
    dispatch({
      type: 'ADD',
      id: packageId
    });
    db.collection('covidShopAddCompare').add({
      agentToken: agentToken,
      packageSelectCompare: packageId,
      agentCode: agentData.agentCode,
      agentName: agentData.agentName,
      action: "add/remove"
    })
      .catch((error) => {
        console.log(error)
      });
    setOpenDropdown(true)
  };

  const clearDetailPackage = () => {
    dispatch({
      type: 'CLEAR'
    });
  };

  const openDetailModal = () => {
    db.collection('covidShopAddCompare').add({
      agentToken: agentToken,
      agentCode: agentData.agentCode,
      agentName: agentData.agentName,
      packageSelectCompare: packageCompare,
      action: "compare"
    })
      .catch((error) => {
        console.log(error)
      });
    setDetailModal(true);
  };

  const closeDetailModal = () => {
    setDetailModal(false);
  };


  const returnCssTopFeature = (packageCompare, ArrayIndex, Attribute) => {
    if (packageCompare.length === 3) {
      // console.log("0:" + planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()])
      // console.log("1:" + planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()])
      // console.log("2:" + planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0][Attribute.toString()])
      if (ArrayIndex === 0) {
        const compareMostAmt =
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()]) !== 0 &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()]) &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0][Attribute.toString()])
        // console.log(compareMostAmt)
        return compareMostAmt
      }
      if (ArrayIndex === 1) {
        const compareMostAmt =
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()]) !== 0 &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()]) &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0][Attribute.toString()])
        return compareMostAmt
      }
      if (ArrayIndex === 2) {
        const compareMostAmt =
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0][Attribute.toString()]) !== 0 &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()]) &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()])
        return compareMostAmt
      }
      return false
    }

    if (packageCompare.length === 2) {
      if (ArrayIndex === 0) {
        const compareMostAmt =
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()]) !== 0 &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()])
        return compareMostAmt
      }
      if (ArrayIndex === 1) {
        const compareMostAmt =
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()]) !== 0 &&
          Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0][Attribute.toString()]) >= Number(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0][Attribute.toString()])
        return compareMostAmt
      }
      return false
    }
  }

  const [openDropdown, setOpenDropdown] = useState(false);
  const toggleDropdownShop = () => setOpenDropdown(!openDropdown);

  return (
    <React.Fragment>
      <MetaTags>
        <title>ประกันภัยไวรัสโคโรนา - TVinsure</title>
        <meta name="description" content="ประกันไวรัสโคโรนา TVinsure.com - Protection Made Just For You" />
        <meta property="og:title" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
        <meta property="og:description" content="ซื้อประกันภัยไวรัสโคโรนาได้ที่นี่ TVinsure.com - Protection Made Just For You" />
        <meta property="og:image" content={tvinsureCovidInsOne} />
      </MetaTags>


      <Modal size="lg" style={{ maxWidth: '1000px', width: '90%', margin: 'auto', paddingTop: '50px' }} isOpen={detailModal && packageCompare.length > 0} >
        <ModalHeader toggle={closeDetailModal}>เปรียบเทียบแผนประกันภัย</ModalHeader>
        <ModalBody className="pb-2">
          <div class="panel panel-default">
            <div class="panel-body">
              <table responsive className="mb-0 table-center table table-striped flex-table">
                <thead >
                  <tr>
                    <td style={{ fontSize: "smaller", minWidth: "60px" }}></td>
                    {packageCompare[0] && <th>
                      <div className="row">
                        <div className="col-md-12">
                          <img
                            src={
                              planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "SMK" ? smklogo
                                : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "DHP" ? dhplogo
                                  : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "AII" ? asialogo
                                    : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "VIB" ? viblogo
                                      : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "SEI" ? seilogo
                                        : smklogo}
                            className="shadow rounded d-block mx-auto"
                            style={{ maxWidth: "64px" }}
                            alt=""
                          />
                        </div>
                        <div className="col-md-12">
                          <p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_DESC.slice(0, 30)}...</p>
                        </div>
                        <div className="col-md-12">
                          <Link
                            to={`/application-form/${planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ID}/detail/${agentData.tokenid}`}>
                            <div className="btn btn-pills btn-primary mt-2 mb-2 d-block mx-auto text-center"> ซื้อเลย </div>
                          </Link>
                        </div>
                        <div className="col-md-12 text-center">
                          <Link className="text-danger mb-0" style={{ fontSize: "smaller" }} onClick={() => addDetailPackage(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ID)}>ลบออก [X]</Link>
                        </div>
                      </div>
                    </th>}
                    {packageCompare[1] && <th>
                      <div className="row">
                        <div className="col-md-12">
                          <img
                            src={
                              planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "SMK" ? smklogo
                                : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "DHP" ? dhplogo
                                  : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "AII" ? asialogo
                                    : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "VIB" ? viblogo
                                      : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "SEI" ? seilogo
                                        : smklogo}

                            className="shadow rounded d-block mx-auto"
                            style={{ maxWidth: "64px" }}
                            alt=""
                          />
                        </div>
                        <div className="col-md-12">
                          <p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_DESC.slice(0, 30)}...</p>
                        </div>
                        <div className="col-md-12">
                          <Link
                            to={`/application-form/${planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ID}/detail/${agentData.tokenid}`}>
                            <div className="btn btn-pills btn-primary mt-2 mb-2 d-block mx-auto"> ซื้อเลย </div>
                          </Link>
                        </div>
                        <div className="col-md-12 text-center">
                          <Link className="text-danger mb-0" style={{ fontSize: "smaller" }} onClick={() => addDetailPackage(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ID)}>ลบออก [X]</Link>
                        </div>
                      </div>
                    </th>}
                    {packageCompare[2] && <th>
                      <div className="row">
                        <div className="col-md-12">
                          <img
                            src={
                              planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "SMK" ? smklogo
                                : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "DHP" ? dhplogo
                                  : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "AII" ? asialogo
                                    : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "VIB" ? viblogo
                                      : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "SEI" ? seilogo
                                        : smklogo}

                            className="shadow rounded d-block mx-auto"
                            style={{ maxWidth: "64px" }}
                            alt=""
                          />
                        </div>
                        <div className="col-md-12">
                          <p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_DESC.slice(0, 30)}...</p>
                        </div>
                        <div className="col-md-12">
                          <Link
                            to={`/application-form/${planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ID}/detail/${agentData.tokenid}`}>
                            <div className="btn btn-pills btn-primary mt-2 mb-2 d-block mx-auto"> ซื้อเลย </div>
                          </Link>
                        </div>
                        <div className="col-md-12 text-center">
                          <Link className="text-danger mb-0" style={{ fontSize: "smaller" }} onClick={() => addDetailPackage(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ID)}>ลบออก [X]</Link>
                        </div>
                      </div>
                    </th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12">
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}>เบี้ยประกันภัย</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_TOTAL_PREMIUM} บาท</p></td>}
                    {packageCompare[1] && <td><p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_TOTAL_PREMIUM} บาท</p></td>}
                    {packageCompare[2] && <td><p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_TOTAL_PREMIUM} บาท</p></td>}
                  </tr>


                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12">
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}>อายุผู้สมัคร</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_AGE_CONDITION}</p></td>}
                    {packageCompare[1] && <td><p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_AGE_CONDITION}</p></td>}
                    {packageCompare[2] && <td><p className="text-center mt-2" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_AGE_CONDITION}</p></td>}
                  </tr>
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Badge color="warning" className="mx-2"> ติดเชื้อ </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}> ตรวจพบเจอเชื้อ (เจอจ่าย)</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_DIAGNOSTIC_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_DIAGNOSTIC_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_DIAGNOSTIC_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_DIAGNOSTIC_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_DIAGNOSTIC_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_DIAGNOSTIC_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_DIAGNOSTIC_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_DIAGNOSTIC_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_DIAGNOSTIC_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                  </tr>


                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Badge color="warning" className="mx-2"> ติดเชื้อ </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}> ค่ารักษาจากการติดเชื้อ </p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_HOSPITAL_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_HOSPITAL_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_HOSPITAL_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_HOSPITAL_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_HOSPITAL_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_HOSPITAL_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_HOSPITAL_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_HOSPITAL_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_HOSPITAL_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Badge color="warning" className="mx-2"> ติดเชื้อ </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}> โคม่าจากการติดเชื้อ</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_COMA_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMA_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMA_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_COMA_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMA_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMA_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_COMA_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMA_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMA_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Badge color="warning" className="mx-2"> ติดเชื้อ </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}> เงินชดเชยจากการติดเชื้อ </p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p className={`text-center mt-2 mb-0 ${returnCssTopFeature(packageCompare, 0, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT) + " บาท/วัน" : "ไม่คุ้มครอง"}</p>
                          <p className={`text-center mt-0 mb-0 ${returnCssTopFeature(packageCompare, 0, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_DAY !== "0" ? "สูงสุด " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_DAY) + " วัน" : ""}</p>
                          <p className={`text-center mt-0 ${returnCssTopFeature(packageCompare, 0, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_TOTAL_AMT !== "0" ? "รวมไม่เกิน " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_TOTAL_AMT) + " บาท" : ""}</p>
                        </div>
                      </div>
                    </td>}
                    {packageCompare[1] && <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p className={`text-center mt-2 mb-0 ${returnCssTopFeature(packageCompare, 1, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT) + " บาท/วัน" : "ไม่คุ้มครอง"}</p>
                          <p className={`text-center mt-0 mb-0 ${returnCssTopFeature(packageCompare, 1, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_DAY !== "0" ? "สูงสุด " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_DAY) + " วัน" : ""}</p>
                          <p className={`text-center mt-0 ${returnCssTopFeature(packageCompare, 1, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_TOTAL_AMT !== "0" ? "รวมไม่เกิน " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_TOTAL_AMT) + " บาท" : ""}</p>
                        </div>
                      </div>
                    </td>}
                    {packageCompare[2] && <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p className={`text-center mt-2 mb-0 ${returnCssTopFeature(packageCompare, 2, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_PER_DAY_PAY_AMT) + " บาท/วัน" : "ไม่คุ้มครอง"}</p>
                          <p className={`text-center mt-0 mb-0 ${returnCssTopFeature(packageCompare, 2, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_DAY !== "0" ? "สูงสุด " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_DAY) + " วัน" : ""}</p>
                          <p className={`text-center mt-0 ${returnCssTopFeature(packageCompare, 2, "PLAN_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_TOTAL_AMT !== "0" ? "รวมไม่เกิน " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_TOTAL_AMT) + " บาท" : ""}</p>
                        </div>
                      </div>
                    </td>}
                  </tr>

                  <tr>

                    <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Badge color="blue" className="mx-2"> แพ้วัคซีน </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}> ค่ารักษาจากการแพ้วัคซีน </p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_VACCINE_HOSPITAL_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_VACCINE_HOSPITAL_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_VACCINE_HOSPITAL_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_HOSPITAL_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Badge color="blue" className="mx-2"> แพ้วัคซีน </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}> โคม่าจากการแพ้วัคซีน</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_VACCINE_COMA_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMA_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMA_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_VACCINE_COMA_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMA_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMA_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_VACCINE_COMA_PAY_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMA_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMA_PAY_AMT) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <Badge color="blue" className="mx-2"> แพ้วัคซีน </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px" }}> เงินชดเชยจากการแพ้วัคซีน</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p className={`text-center mt-2 mb-0 ${returnCssTopFeature(packageCompare, 0, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT) + " บาท/วัน" : "ไม่คุ้มครอง"}</p>
                          <p className={`text-center mt-0 mb-0 ${returnCssTopFeature(packageCompare, 0, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMPENSTAION_DAY !== "0" ? "สูงสุด " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMPENSTAION_DAY) + " วัน" : ""}</p>
                          <p className={`text-center mt-0 ${returnCssTopFeature(packageCompare, 0, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT !== "0" ? "รวมไม่เกิน " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT) + " บาท" : ""}</p>
                        </div>
                      </div>
                    </td>}
                    {packageCompare[1] && <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p className={`text-center mt-2 mb-0 ${returnCssTopFeature(packageCompare, 1, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT) + " บาท/วัน" : "ไม่คุ้มครอง"}</p>
                          <p className={`text-center mt-0 mb-0 ${returnCssTopFeature(packageCompare, 1, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMPENSTAION_DAY !== "0" ? "สูงสุด " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMPENSTAION_DAY) + " วัน" : ""}</p>
                          <p className={`text-center mt-0 ${returnCssTopFeature(packageCompare, 1, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT !== "0" ? "รวมไม่เกิน " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT) + " บาท" : ""}</p>
                        </div>
                      </div>
                    </td>}
                    {packageCompare[2] && <td>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p className={`text-center mt-2 mb-0 ${returnCssTopFeature(packageCompare, 2, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMPENSTAION_PER_DAY_PAY_AMT) + " บาท/วัน" : "ไม่คุ้มครอง"}</p>
                          <p className={`text-center mt-0 mb-0 ${returnCssTopFeature(packageCompare, 2, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMPENSTAION_DAY !== "0" ? "สูงสุด " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMPENSTAION_DAY) + " วัน" : ""}</p>
                          <p className={`text-center mt-0 ${returnCssTopFeature(packageCompare, 2, "PLAN_VACCINE_COMPENSTAION_TOTAL_AMT") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT !== "0" ? "รวมไม่เกิน " + Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_VACCINE_COMPENSTAION_TOTAL_AMT) + " บาท" : ""}</p>
                        </div>
                      </div>
                    </td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row" style={{ minWidth: "60px" }}>
                        <div className="col-md-12 text-center">
                          <Badge color="success" className="mx-2"> ผลประโยชน์อื่น ๆ  </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px", maxWidth: "250px" }}> ค่าใช้จ่ายในการตรวจหาโรคติดเชื้อไวรัสโคโรนา COVID-19 สำหรับบุคคลในครอบครัว และบุคคลที่อาศัยอยู่ร่วมกันกับผู้เอาประกันภัย</p>
                          <p className="ml-2 mt-2" style={{ fontSize: "small", minWidth: "60px", maxWidth: "250px" }}>(สูงสุด 3 คน (ต่อปี) จ่ายตามจริงไม่เกิน)</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_SEARCH_OTHER") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_SEARCH_OTHER !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_SEARCH_OTHER) + " บาท" : "ไม่มี"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_SEARCH_OTHER") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_SEARCH_OTHER !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_SEARCH_OTHER) + " บาท" : "ไม่มี"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_SEARCH_OTHER") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_SEARCH_OTHER !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_SEARCH_OTHER) + " บาท" : "ไม่มี"}</p></td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row" style={{ minWidth: "60px" }}>
                        <div className="col-md-12 text-center">
                          <Badge color="success" className="mx-2"> ผลประโยชน์อื่น ๆ  </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px", maxWidth: "250px" }}>เงินชดเชยรายเดือนเมื่อเข้ารักษาตัวเป็นผู้ป่วยในและพักฟื้นจากโรคติดเชื้อไวรัส COVID-19 สูงสุด 4 เดือน/ปี หรือการชดเชยรายได้รายวันระหว่างรักษาตัวในโรงพยาบาลจากการแพ้วัคซีน COVID-19 </p>
                          <p className="ml-2 mt-2" style={{ fontSize: "small", minWidth: "60px", maxWidth: "250px" }}>สูงสุด 120 วันต่อปี (เดือนละ)</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_COMPENSTAION_MONTH") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_MONTH !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_COMPENSTAION_MONTH) + " บาท" : "ไม่มี"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_COMPENSTAION_MONTH") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_MONTH !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_COMPENSTAION_MONTH) + " บาท" : "ไม่มี"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_COMPENSTAION_MONTH") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_MONTH !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_COMPENSTAION_MONTH) + " บาท" : "ไม่มี"}</p></td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row" style={{ minWidth: "60px" }}>
                        <div className="col-md-12 text-center">
                          <Badge color="success" className="mx-2"> ผลประโยชน์อื่น ๆ  </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller", minWidth: "60px", maxWidth: "250px" }}>ค่าใช้จ่ายเพิ่มเติมอื่นๆ เพื่อพบแพทย์หลังการติดเชื้อไวรัส COVID-19 </p>
                          <p className="ml-2 mt-2" style={{ fontSize: "small", minWidth: "60px", maxWidth: "250px" }}>ต่อครั้งสูงสุดไม่เกิน 5 ครั้ง/ปี</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_FOLLOW_UP_DOCTOR") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_FOLLOW_UP_DOCTOR !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_FOLLOW_UP_DOCTOR) + " บาท" : "ไม่มี"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_FOLLOW_UP_DOCTOR") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_FOLLOW_UP_DOCTOR !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_FOLLOW_UP_DOCTOR) + " บาท" : "ไม่มี"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_FOLLOW_UP_DOCTOR") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_FOLLOW_UP_DOCTOR !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_FOLLOW_UP_DOCTOR) + " บาท" : "ไม่มี"}</p></td>}
                  </tr>

                  <tr>
                    <td>
                      <div className="row" style={{ minWidth: "60px" }}>
                        <div className="col-md-12 text-center">
                          <Badge color="success" className="mx-2"> ผลประโยชน์อื่น ๆ  </Badge>
                          <p className="ml-2 mt-2" style={{ fontSize: "smaller" }}>อุบัติเหตุ (อ.บ.1)</p>
                        </div>
                      </div>
                    </td>
                    {packageCompare[0] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 0, "PLAN_PA") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_PA !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_PA) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[1] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 1, "PLAN_PA") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_PA !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_PA) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                    {packageCompare[2] && <td><p className={`text-center mt-2 ${returnCssTopFeature(packageCompare, 2, "PLAN_PA") && "text-warning font-weight-bold"}`} style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_PA !== "0" ? Intl.NumberFormat('th-TH').format(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_PA) + " บาท" : "ไม่คุ้มครอง"}</p></td>}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="component-wrapper rounded shadow">
            <div className="p-4 border-bottom">
              <h4 className="title mb-0"> ตารางความคุ้มครอง </h4>
            </div>

            <div className="p-4">
              <div className="table-responsive bg-white shadow rounded">

              </div>
            </div>
          </div> */}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={closeDetailModal}>ย้อนกลับ</Button>{' '}
        </ModalFooter>
      </Modal>

      {packageCompare.length > 0 && < Dropdown
        isOpen={openDropdown}
        toggle={toggleDropdownShop}
        id="topnav"
        className="defaultscroll sticky"
      >
        <DropdownToggle
          type="button"
          className="btn btn-primary float-right shadow-lg mt-4 mb-4 mr-4"
        >
          <small> ดู {packageCompare.length} รายการเปรียบเทียบ</small>
          {/* <i className="uil uil-shopping-cart align-middle icons"></i> */}
        </DropdownToggle>
        <DropdownMenu
          direction="left"
          className="dd-menu bg-white shadow rounded border-0 mt-3 p-4"
          style={{ width: "300px", right: "30px" }}
        >
          <div className="pb-4">
            {packageCompare[0] &&
              <div
                className="media align-items-center">
                <img
                  src={
                    planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "SMK" ? smklogo
                      : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "DHP" ? dhplogo
                        : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "AII" ? asialogo
                          : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "VIB" ? viblogo
                            : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ISSUER_CODE === "SEI" ? seilogo
                              : seilogo}

                  className="shadow rounded"
                  style={{ maxWidth: "64px" }}
                  alt=""
                />
                <div className="media-body text-left ml-3">
                  <p className="text-dark mb-0" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_DESC.slice(0, 25)}...</p>
                  <Link className="text-danger mb-0" style={{ fontSize: "smaller" }} onClick={() => addDetailPackage(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[0].packageId)[0].PLAN_ID)}>ลบออก [X]</Link>
                </div>
                {/* <h6 className="text-dark mb-0">$640</h6> */}
              </div>
            }
            {packageCompare[1] &&
              <div
                className="media align-items-center mt-4"
              >
                <img
                  src={
                    planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "SMK" ? smklogo
                      : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "DHP" ? dhplogo
                        : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "AII" ? asialogo
                          : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "VIB" ? viblogo
                            : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ISSUER_CODE === "SEI" ? seilogo
                              : seilogo}
                  className="shadow rounded"
                  alt=""
                  style={{ maxWidth: "64px" }}
                />
                <div className="media-body text-left ml-3">
                  <p className="text-dark mb-0" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_DESC.slice(0, 25)}...</p>
                  <Link className="text-danger mb-0" style={{ fontSize: "smaller" }} onClick={() => addDetailPackage(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[1].packageId)[0].PLAN_ID)}>ลบออก [X]</Link>
                  {/* <h6 className="text-dark mb-0">Bag</h6>
              <p className="text-muted mb-0">$50 X 5</p> */}
                </div>
                {/* <h6 className="text-dark mb-0">$250</h6> */}
              </div>
            }
            {packageCompare[2] &&
              <div
                className="media align-items-center mt-4"
              >
                <img
                  src={
                    planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "SMK" ? smklogo
                      : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "DHP" ? dhplogo
                        : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "AII" ? asialogo
                          : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "VIB" ? viblogo
                            : planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ISSUER_CODE === "SEI" ? seilogo
                              : seilogo}
                  className="shadow rounded"
                  style={{ maxWidth: "64px" }}
                  alt=""
                />
                <div className="media-body text-left ml-3">
                  <p className="text-dark mb-0" style={{ fontSize: "smaller" }}>{planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_DESC.slice(0, 25)}...</p>
                  <Link className="text-danger mb-0" style={{ fontSize: "smaller" }} onClick={() => addDetailPackage(planCovidNewList.filter(p => p.PLAN_ID === packageCompare[2].packageId)[0].PLAN_ID)}>ลบออก [X]</Link>
                  {/* <h6 className="text-dark mb-0">Watch (Men)</h6>
                  <p className="text-muted mb-0">$800 X 1</p> */}
                </div>
                {/* <h6 className="text-dark mb-0">$800</h6> */}
              </div>
            }
          </div>

          <div className="mt-3 text-center">
            <Button
              className="btn btn-primary mr-2"
              block
              onClick={openDetailModal}>
              ดูรายละเอียด
            </Button>
            <Button
              className="btn btn-danger mr-2"
              onClick={clearDetailPackage}
              block>
              ล้างค่า
            </Button>
          </div>
        </DropdownMenu>
      </Dropdown>
      }


      <section className="bg-half-40 d-table w-100" style={{ zIndex: -1 }}>
        <BackgroundSlider
          images={items}
          duration={5}
          transition={3}
        />
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="text-center mb-5">
                <img src={tvinsurelogo} height="120" alt="" />
              </div>
              <div className="title-heading text-center">
                <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                  ประกันภัยไวรัสโคโรนา (ประกันภัยใหม่)
                </h4>
                <p className="text-center text-white h5 mt-1 mb-5">ทุกแผนประกันภัยมีระยะรอคอย 14 วัน ทั้ง ค่ารักษา / เจอ จ่าย จบ</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section style={{ margin: "-50px 0 0" }}>
        {/* breadcrumb */}
        {/* <PageBreadcrumb title="All Products" pathItems={this.state.pathItems} /> */}

        <section className="section">
          <Container>
            <Row>
              <Col lg={3} md={4} xs={12}>
                <Card className="border-0 sidebar sticky-bar">
                  <CardBody className="p-0">
                    {/* <div className="widget">
                    <div id="search2" className="widget-search mb-0">
                      <Form
                        role="search"
                        method="get"
                        id="searchform"
                        className="searchform"
                      >
                        <div>
                          <Input
                            type="text"
                            className="border rounded"
                            name="s"
                            id="s"
                            placeholder="Search Keywords..."
                          />
                          <Input
                            type="submit"
                            id="searchsubmit"
                            value="Search"
                          />
                        </div>
                      </Form>
                    </div>
                  </div> */}


                    {agentData && <div className="widget mt-4 pt-2">
                      <h4 className="widget-title">ผู้ดูแล</h4>
                      <p>{agentData.agentCode} : {agentData.agentName}</p>
                    </div>}

                    <div className="widget mt-4 pt-2">
                      <h4 className="widget-title">ความคุ้มครองที่ต้องการ</h4>
                      <ul className="list-unstyled mt-4 mb-0">
                        <li className="list-inline-item">
                          <div
                            className="btn btn-pills btn-danger btn-sm mb-3 mr-2"
                            onClick={() => handlefilterCoverage("DIAGNOSTIC")}
                          >
                            เจอ จ่าย จบ
                          </div>
                        </li>
                        <li className="list-inline-item">
                          <div
                            className="btn btn-pills btn-success btn-sm mb-3 mr-2"
                            onClick={() => handlefilterCoverage("HOSPITAL")}
                          >
                            ค่ารักษา
                          </div>
                        </li>
                        <li className="list-inline-item">
                          <div
                            className="btn btn-pills btn-info btn-sm mb-3 mr-2"
                            onClick={() => handlefilterCoverage("COMPENSATION")}
                          >
                            ค่าชดเชย
                          </div>
                        </li>
                        <li className="list-inline-item">
                          <div
                            className="btn btn-pills btn-blue btn-sm mb-3 mr-2"
                            onClick={() => handlefilterCoverage("ALLERGYVACCINE")}
                          >
                            แพ้วัคซีน
                          </div>
                        </li>
                        <li className="list-inline-item">
                          <div
                            className="btn btn-pills btn-secondary btn-sm mb-3 mr-2"
                            onClick={() => handlefilterCoverage("ALL")}
                          >
                            ความคุ้มครองทั้งหมด
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="widget mt-4 pt-2">
                      <h4 className="widget-title">บริษัทประกันภัย</h4>
                      <ul className="list-unstyled mt-4 mb-0 blog-categories">
                        <li>
                          <div className="btn btn-pills btn-light mb-3 mr-2" onClick={() => handlefilterIssuer("SMK")}>
                            สินมั่นคงประกันภัย</div>
                        </li>
                        <li>
                          <div className="btn btn-pills btn-light mb-3 mr-2" onClick={() => handlefilterIssuer("SEI")}>
                            อาคเนย์ประกันภัย</div>
                        </li>
                        <li>
                          <div className="btn btn-pills btn-light mb-3 mr-2" onClick={() => handlefilterIssuer("DHP")}>
                            ทิพยประกันภัย</div>
                        </li>
                        <li>
                          <div className="btn btn-pills btn-light mb-3 mr-2" onClick={() => handlefilterIssuer("AII")}>
                            เอเชียประกันภัย</div>
                        </li>
                        <li>
                          <div className="btn btn-pills btn-light mb-3 mr-2" onClick={() => handlefilterIssuer("VIB")}>
                            วิริยะประกันภัย</div>
                        </li>
                        <li>
                          <div className="btn btn-pills btn-light mb-3 mr-2" onClick={() => handlefilterIssuer("ALL")}>
                            ทุกบริษัทประกันภัย</div>
                        </li>
                      </ul>
                    </div> */}

                    {/* <div className="widget mt-4 pt-2">
                      <h4 className="widget-title">สินค้าขายดี</h4>
                      <ul className="list-unstyled mt-4 mb-0">
                        {topProducts.map((product, key) => (
                          <li
                            key={key}
                            className={
                              key + 1 === 1
                                ? "media align-items-center"
                                : "media align-items-center mt-2"
                            }
                          >
                            <Link to={product.link}>
                              <img
                                src={product.image}
                                className="img-fluid avatar avatar-small rounded shadow"
                                style={{ height: "auto" }}
                                alt="Landrick"
                              />
                            </Link>
                            <div className="content ml-3">
                              <Link to={product.link} className="text-dark h6">
                                {product.name}
                              </Link>
                              <h6 className="text-muted small font-italic mb-0 mt-1">
                                {product.NewPrice}{" "}
                                <del className="text-danger ml-2">
                                  {product.oldPrice}
                                </del>{" "}
                              </h6>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>

              <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">
                <Row className="align-items-center">
                  <Col lg={8} md={7}>
                    <div className="section-title">
                      <p className="mb-0 text-muted">แสดงทั้งหมด {products.length} รายการ</p>
                    </div>
                  </Col>

                  <Col lg={4} md={5} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="d-flex justify-content-md-between align-items-center">
                      <div className="form custom-form">
                        <FormGroup className=" mb-0">
                          <select
                            className="form-control custom-select"
                            id="Sortbylist-job"
                            onChange={handleChange}
                          >
                            <option value="PREMIUM_LOW_TO_HIGH">เรียงจากเบี้ยประกันต่ำไปสูง</option>
                            <option value="PREMIUM_HIGH_TO_LOW">เรียงจากเบี้ยประกันสูงไปต่ำ</option>
                          </select>
                        </FormGroup>
                      </div>
                      <div className="mx-2">
                        <Link to="shop-grids" className="h5 text-muted">
                          <i className="uil uil-apps"></i>
                        </Link>
                      </div>

                      <div>
                        <Link to="shop-lists" className="h5 text-muted">
                          <i className="uil uil-list-ul"></i>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  {covidData && <Col xs={12} className="mt-4 pt-2" id="blockquotes">
                    <div className="component-wrapper rounded shadow">
                      <div className="p-4 border-bottom">
                        <h4 className="title mb-0"> ยอดผู้ป่วยโควิดรายวัน (อัพเดทล่าสุด {covidData.UpdateDate}) </h4>
                        <p className="my-1">ที่มา: static.easysunday.com 
                        </p>
                      </div>

                      <div className="px-4 pt-4 pb-2">
                        <blockquote className="blockquote p-3 mb-1">
                          <Badge color="warning" pill className="mr-1 mb-2"> ติดเชื้อ [ {Number(covidData.NewConfirmed) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewConfirmed)}]</Badge>
                          <p className="text-muted mb-0 font-italic">
                            ผู้ป่วยติดเชื้อสะสม {Intl.NumberFormat('th-TH').format(covidData.Confirmed)} คน <span className="text-warning">({Number(covidData.NewConfirmed) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewConfirmed)} คน)</span>
                          </p>
                        </blockquote>
                        <blockquote className="blockquote p-3 mb-1">
                          <Badge color="success" pill className="mr-1 mb-2"> หายแล้ว [ {Number(covidData.NewRecovered) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewRecovered)}]</Badge>
                          <p className="text-muted mb-0 font-italic">
                            หายแล้ว {Intl.NumberFormat('th-TH').format(covidData.Recovered)} คน <span className="text-success">({Number(covidData.NewRecovered) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewRecovered)} คน)</span>
                          </p>
                        </blockquote>
                        <blockquote className="blockquote p-3 mb-1">
                          <Badge color="info" pill className="mr-1 mb-2"> รักษาอยู่ใน รพ. [ {Number(covidData.NewHospitalized) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewHospitalized)}]</Badge>
                          <p className="text-muted mb-0 font-italic">
                            รักษาอยู่ใน รพ. {Intl.NumberFormat('th-TH').format(covidData.Hospitalized)} คน <span className="text-info">({Number(covidData.NewHospitalized) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewHospitalized)} คน)</span>
                          </p>
                        </blockquote>
                        <blockquote className="blockquote p-3 mb-1">
                          <Badge color="danger" pill className="mr-1 mb-2"> เสียชีวิต [ {Number(covidData.NewDeaths) < 0 ? "" : "+"} {Intl.NumberFormat('th-TH').format(covidData.NewDeaths)}]</Badge>
                          <p className="text-muted mb-0 font-italic">
                            เสียชีวิต {Intl.NumberFormat('th-TH').format(covidData.Deaths)} คน <span className="text-danger">({Number(covidData.NewDeaths) < 0 ? "ลดลง" : "เพิ่มขึ้น"} {Intl.NumberFormat('th-TH').format(covidData.NewDeaths)} คน)</span>
                          </p>
                        </blockquote>
                      </div>
                    </div>
                  </Col>}

                  <Col xs={12} className="mt-4 pt-2">
                    <div
                      className="py-5 rounded shadow text-center shadow-lg"
                      style={{ background: `url(${tvinsureCheckIdStatus}) top center` }}
                    >
                      <div className="p-4">
                        <h3 className="text-white">
                          เช็คความคุ้มครองประกันไวรัสโคโรนาของฉัน (สำหรับประกันสมัครใหม่)
                        </h3>
                        <Link to="/get-covid-app-by-id">
                          <Button color="danger">เช็คเลยที่นี่</Button>
                        </Link>
                      </div>
                    </div>

                  </Col>

                  {products.map((product, key) => (
                    <Col xs={12} className="mt-4 pt-2" key={key}>
                      <Card className="job-box shop-list border-0 shadow position-relative">
                        {/* <ul className="label list-unstyled mb-0">
                        {product.isNew && (
                          <li>
                            <Link
                              to="#"
                              className="badge badge-pill badge-primary"
                            >
                              New
                              </Link>
                          </li>
                        )}
                        {product.isFeatures && (
                          <li>
                            <Link
                              to="#"
                              className="badge badge-pill badge-success"
                            >
                              Featured
                              </Link>
                          </li>
                        )}

                        {product.isSale && (
                          <li>
                            <Link
                              to="#"
                              className="badge badge-pill badge-warning"
                            >
                              Sale
                              </Link>
                          </li>
                        )}
                      </ul> */}
                        <Row className="align-items-center no-gutters">
                          <Col lg={4} md={6} xs={12}>
                            <div className="shop-image position-relative overflow-hidden">
                              <Link to={product.link}>
                                <img
                                  src={product.image}
                                  className="img-fluid"
                                  alt=""
                                />
                              </Link>
                              <Link
                                to={product.link}
                                className="overlay-work"
                              >
                                <img
                                  src={product.imgOverlay}
                                  className="img-fluid"
                                  alt="Landrick"
                                />
                              </Link>
                            </div>
                          </Col>

                          <Col lg={8} md={6} xs={12}>
                            <CardBody className="content p-4">
                              {product.isGoodSelling && <ul className="list-unstyled head mb-0">
                                <li className={"badge badge-outline-danger badge-pill"}>
                                  สินค้าขายดี
                                </li>
                              </ul>}
                              <Link
                                to={product.link}
                                className="text-dark product-name h6"
                              >
                                {product.name}
                              </Link>
                              <div className="d-lg-flex align-items-center mt-2 mb-3">
                                <h6 className="text-dark font-italic mb-0 mr-3">
                                  ราคาเบี้ยประกันภัยรวม {product.price}{" "} บาท
                                  {product.oldPrice ? (
                                    <del className="text-danger ml-2">
                                      ${product.oldPrice}
                                    </del>
                                  ) : null}
                                  {product.sucessPrice ? (
                                    <span className="text-success ml-1">
                                      {product.sucessPrice}
                                    </span>
                                  ) : null}
                                </h6>
                                <ul className="list-unstyled text-danger mb-0">
                                  {product.score.map((value, index) => {
                                    return (<li key={index} className="list-inline-item">
                                      <i className="mdi mdi-star"></i>
                                    </li>)
                                  })}
                                </ul>
                              </div>
                              <ul className="list-unstyled mt-2 mb-3">
                                {product.diagnostic && <li className={"badge badge-warning badge-pill mr-1"}>
                                  เจอ จ่าย จบ
                                </li>}
                                {product.hospital && <li className={"badge badge-success badge-pill mr-1"}>
                                  ค่ารักษา
                                </li>}
                                {product.compensation && <li className={"badge badge-info  badge-pill mr-1"}>
                                  ค่าชดเชย
                                </li>}
                                {product.allergyVaccine && <li className={"badge badge-blue  badge-pill mr-1"}>
                                  แพ้วัคซีน
                                </li>}
                              </ul>
                              <p className="para-desc text-dark mb-1">
                                {product.desc}
                              </p>
                              <div>
                                <small className="para-desc text-muted mb-1">
                                  {product.subDesc}
                                </small>
                              </div>

                              <Link to={product.link}>
                                <div className="btn btn-pills btn-primary mt-3 mb-3 mr-2"> ซื้อเลย </div>
                              </Link>
                              <div
                                className={`btn btn-pills ${packageCompare.filter(selectId => selectId.packageId === product.code)[0] ? "btn-danger" : packageCompare.length === 3 ? "btn-success" : "btn-outline-success"} mt-3 mb-3 mr-2`}
                                onClick={() => addDetailPackage(product.code)}> {packageCompare.filter(selectId => selectId.packageId === product.code)[0] ? "- นำออกจากการเปรียบเทียบ" : packageCompare.length === 3 ? "เปรียบเทียบได้สูงสุด 3 แผน" : "+ เพิ่มเพื่อเปรียบเทียบ"}
                              </div>

                              {/* <ul className="list-unstyled mb-0">
                                <li className="list-inline-item ml-1">
                                  <Link
                                    to="#"
                                    className="btn btn-icon btn-pills btn-soft-primary"
                                  >
                                    <FeatherIcon
                                      icon="heart"
                                      className="icons"
                                    />
                                  </Link>
                                </li>
                                <li className="mt-2 list-inline-item ml-1">
                                  <Link
                                    to="#"
                                    data-toggle="modal"
                                    data-target="#productview"
                                    className="btn btn-icon btn-pills btn-soft-primary"
                                  >
                                    <FeatherIcon icon="eye" className="icons" />
                                  </Link>
                                </li>
                                <li className="mt-2 list-inline-item ml-1">
                                  <Link
                                    to="shop-cart"
                                    className="btn btn-icon btn-pills btn-soft-primary"
                                  >
                                    <FeatherIcon
                                      icon="shopping-cart"
                                      className="icons"
                                    />
                                  </Link>
                                </li>
                              </ul> */}
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}

                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </section>
    </React.Fragment>
  );
}

export default ShopProductList;

import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { storage, db } from '../../firebase/config'
import { HealthplanList } from '../../shared/masters/refData'


import { getResultNkiVac } from '../components/GetResultNkiVac.js'

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Import Logo
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import nkiinsurancelogo from '../../shared/images/NKI_LOGO.png';

// Import Progressbar
import progressbar1 from '../../shared/images/ProgressBar1.png';
import progressbar2 from '../../shared/images/ProgressBar2.png';
import progressbar3 from '../../shared/images/ProgressBar3.png';
import progressbar4 from '../../shared/images/ProgressBar4.png';

import './Product.css';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid2.jpg';

const HealthApplicationResult = () => {

    let history = useHistory();

    const planId = useParams().planid;
    const idCardNumber = useParams().idcardnumber;

    // filter planId from the params
    const planDetail = HealthplanList.filter(p => p.PLAN_ID === planId)[0]

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [applicationData, setApplicationData] = useState();
    const [applicationId, setApplicationId] = useState();
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent and get record detail of health application
    useEffect(() => {

        db.collection('healthApplication').where('NATIONAL_ID', '==', idCardNumber).where('PLAN_ID', '==', planId).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length === 0) {
                history.push(`/application-form-health/${planId}/${idCardNumber}/info`)
            }
            if (snapshot.docs && snapshot.docs.length > 0) {

                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 1) {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/question`)
                }

                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 2) {
                    setApplicationId(snapshot.docs[0].id)
                    setApplicationData(snapshot.docs[0].data())
                    setIsLoading(false);
                    setSuccessModal(true);
                }

                if (snapshot.docs[0].data().APP_FORM_STATUS_VALUE === 3) {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/get-policy`)
                }
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการดังกล่าวได้ กรุณาลองใหม่อีกครั้ง');
                setErrorModal(true);
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
            setErrorModal(true);
        })

        window.scrollTo(0, 0);

    }, []);

    //contain all form data
    const [submitFormData, setSubmitFormData] = useState({
        NATIONAL_ID_UPLOAD_STATUS: "unselect",
        PAYMENT_UPLOAD_STATUS: "unselect",
        APPLICATION_UPLOAD_STATUS: "unselect",
        APP_FORM_STATUS: "finishUpload",
        APP_FORM_STATUS_VALUE: 3
    }
    );

    // console.log(questionformData)

    const handleDownload = () => {
        getResultNkiVac(applicationData)
    }

    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        setSubmitFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    const handleValidSubmit = (event, values) => {
        setIsSubmit(true);
        const requestsubmitFormData = () => {
            setIsLoading(true);
            db.collection('healthApplication').doc(applicationId).update(submitFormData)
                .then(() => {
                    history.push(`/application-form-health/${planId}/${idCardNumber}/get-policy`)
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                })

        }
        requestsubmitFormData()
    }

    //Select file function
    const fileSelectedHandler = (event) => {
        const { name, files } = event.target

        const fileMaxSize = 2097152

        if (files[0].size > fileMaxSize) {
            alert("ไฟล์ของคุณมีขนาดเกิน 2 MB กรุณาเลือกไฟล์ใหม่");
            window.location.reload()
        }

        if (files[0].size < fileMaxSize) {
            if (name === "NATIONAL_ID_FILE") {
                fileIdCardUploadHandler(files[0])
            }

            if (name === "APPLICATION_FILE") {
                fileApplicationUploadHandler(files[0])
            }

            if (name === "PAYMENT_FILE") {
                filePaymentUploadHandler(files[0])
            }
        }
        // console.log(name);
        // console.log(files);
    }

    //Upload Id Card File
    const fileIdCardUploadHandler = (file) => {
        const uploadTask = storage.ref(`idcardfile/${applicationData.APP_FORM_ID + "-IDCARD"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setSubmitFormData(prevValue => {
                    return {
                        ...prevValue,
                        NATIONAL_ID_UPLOAD_STATUS: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
                // console.log(error.message);
            },
            () => {
                storage
                    .ref('idcardfile')
                    .child(applicationData.APP_FORM_ID + "-IDCARD")
                    .getDownloadURL()
                    .then(url => {
                        setSubmitFormData(prevValue => {
                            return {
                                ...prevValue,
                                NATIONAL_ID_FILE_NAME: applicationData.APP_FORM_ID + "-IDCARD",
                                NATIONAL_ID_ATTACH: url,
                                NATIONAL_ID_UPLOAD_STATUS: "uploaded"
                            }
                        });
                        // console.log(url);
                    });
            })
        // console.log(event.target.files);
    }

    const fileApplicationUploadHandler = (file) => {
        const uploadTask = storage.ref(`applicationfile/${applicationData.APP_FORM_ID + "-APPLICATION"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setSubmitFormData(prevValue => {
                    return {
                        ...prevValue,
                        APPLICATION_UPLOAD_STATUS: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
            },
            () => {
                storage
                    .ref('applicationfile')
                    .child(applicationData.APP_FORM_ID + "-APPLICATION")
                    .getDownloadURL()
                    .then(url => {
                        setSubmitFormData(prevValue => {
                            return {
                                ...prevValue,
                                APPLICATION_FILE_NAME: applicationData.APP_FORM_ID + "-APPLICATION",
                                APPLICATION_ATTACH: url,
                                APPLICATION_UPLOAD_STATUS: "uploaded"
                            }
                        });
                    });
            })
    }

    const filePaymentUploadHandler = (file) => {
        const uploadTask = storage.ref(`paymentfile/${applicationData.APP_FORM_ID + "-PAYSLIP"}`).put(file);
        uploadTask.on('state_changed',
            snapshot => {
                setSubmitFormData(prevValue => {
                    return {
                        ...prevValue,
                        PAYMENT_UPLOAD_STATUS: "uploading"
                    }
                });
            },
            error => {
                setError(error.message)
                setErrorModal(true);
            },
            () => {
                storage
                    .ref('paymentfile')
                    .child(applicationData.APP_FORM_ID + "-PAYSLIP")
                    .getDownloadURL()
                    .then(url => {
                        setSubmitFormData(prevValue => {
                            return {
                                ...prevValue,
                                PAYMENT_FILE_NAME: applicationData.APP_FORM_ID + "-PAYSLIP",
                                PAYMENT_ATTACH: url,
                                PAYMENT_UPLOAD_STATUS: "uploaded"
                            }
                        });
                    });
            })
    }

    // console.log(submitFormData)

    // Manage errorModal upload or submit form
    const [errorModal, setErrorModal] = useState(false);
    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
        setError(null);
        setIsSubmit(false);
        window.location.reload()
    };

    // Manage successModal submit form
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => {
        setSuccessModal(!successModal);
        setIsSubmit(false);
        window.location.reload()
    };

    // console.log(formData);
    // console.log(responseData);

    return (
        <React.Fragment>
            {error && !isLoading && (<div className="justify-content-center">
                <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                    <ModalHeader toggle={toggleErrorModal}>ทำรายการไม่สำเร็จ</ModalHeader>
                    <ModalBody className="pb-2">
                        <p className="mb-2">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
                        <p className="mb-2">{error}</p>
                        <p className="mt-4 mb-3">* หากเกิดข้อผิดพลาด รบกวนแจ้งทีมงานตามช่องทางด้านล่าง</p>
                        <ul className="mt-1">
                            <li>Line: @tvinsure</li>
                            <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={toggleErrorModal}>ลองใหม่อีกครั้ง</Button>
                    </ModalFooter>
                </Modal>
            </div>)}

            <section className="bg-auth bg-color" style={{ background: `url(${tvinsurecovid}) center center`, minHeight: "720px", backgroundSize: "contain", backgroundSize: "" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>

                            <Card>
                                <CardBody>
                                    <div className="text-center mb-3">
                                        <span>
                                            <img src={tvinsurelogo} alt="TVinsure" height="60" />
                                        </span>
                                    </div>
                                    <h4 className="text-center mt-5">แผนประกันจาก{planDetail.PLAN_ISSUER}</h4>
                                    <div className="text-center mb-3">
                                        <span>
                                            {planDetail.PLAN_ISSUER_CODE === "SMK" && <img src={smkinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "AII" && <img src={asiainsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="80" />}
                                            {planDetail.PLAN_ISSUER_CODE === "DHP" && <img src={dhpinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "VIB" && <img src={vibinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                            {planDetail.PLAN_ISSUER_CODE === "NKI" && <img src={nkiinsurancelogo} alt={planDetail.PLAN_ISSUER_CODE} height="100" />}
                                        </span>
                                    </div>
                                    {/* <p className="text-muted text-center mt-2"> # {planDetail.PLAN_ID}</p> */}
                                    <h4 className="text-center mt-2"> {planDetail.PLAN_DESC}</h4>
                                    <h4 className="text-center mt-2 mb-5"> {planDetail.PLAN_COV_TITLE}</h4>
                                    <div className="text-center mb-3 px-5">
                                        <ProgressBar
                                            percent={67}
                                            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                        >
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar1}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar2}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="60"
                                                        src={progressbar3}
                                                    />
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished }) => (
                                                    <img
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        width="40"
                                                        src={progressbar4}
                                                    />
                                                )}
                                            </Step>
                                        </ProgressBar>
                                    </div>

                                    <Row>
                                        <Col md={12}>
                                            <h4 className="text-center h2 mt-5 mb-1 ">ขั้นตอนที่ 3.1</h4>
                                            <p className="text-center mt-1 mb-2">ดาวโหลดใบคำขอเพื่อลงลายมือชื่อ</p>
                                        </Col>
                                        <Col md={12}>
                                            <h4 className="text-center mt-2 mb-5">รายละเอียดการสมัครประกันภัย</h4>
                                        </Col>
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">หมายเลขใบคำขอเอาประกัน</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center">{applicationData.APP_FORM_ID}</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">ชื่อผู้เอาประกันภัย</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center">{applicationData.PRE_NAME} {applicationData.FIRST_NAME} {applicationData.LAST_NAME}</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">เลขบัตรประชาชน</p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center">{applicationData.NATIONAL_ID} </p>
                                        </Col>}
                                        {applicationData && <Col md={6}>
                                            <p className="text-center font-weight-bold">ไฟล์ใบคำขอ (เพื่อลงชื่อยืนยันสมัครประกันภัย)</p>
                                        </Col>}
                                        {applicationData && <Col md={6} className="text-center font-weight-bold mb-5">
                                            <Button color="danger" type="submit" outline disabled={false} onClick={handleDownload}>ดาวโหลดใบคำขอเพื่อลงชื่อ</Button>
                                        </Col>}

                                        <Col md={12}>
                                            <h4 className="text-center mt-2 mb-5">รายละเอียดช่องทางการชำระเงิน</h4>
                                            <h4 className="text-center h5 mt-2">ชำระเบี้ยประกันภัยผ่าน บจก. ทวีทรัพย์ โบรคเกอร์</h4>
                                            <p className="text-center">บัญชีธนาคารกรุงศรี (ออมทรัพย์)</p>
                                            <p className="text-center h4"># 125-9-43316-9</p>
                                            <p className="text-center">บริษัท ทวีทรัพย์ โบรคเกอร์ จำกัด</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <h4 className="text-center h2 mt-5 mb-1">ขั้นตอนที่ 3.2</h4>
                                            <p className="text-center mt-1 mb-2">อัพโหลดใบคำขอพร้อมเอกสารการชำระเงิน</p>
                                        </Col>
                                        <Col md={12}>
                                            <AvForm className="login-form mt-4 text-center" onValidSubmit={handleValidSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <FormGroup className="position-relative">
                                                            {/* ถ้าโหลดเสร็จแล้วจะขึ้นอัพโหลดเสร็จแล้ว */}
                                                            <Label>เลือกไฟล์บัตรประชาชน <span className="text-danger">*</span>
                                                                {submitFormData.NATIONAL_ID_UPLOAD_STATUS === "uploading" && <span className="text-primary"> กำลังอัพโหลด</span>}
                                                                {submitFormData.NATIONAL_ID_UPLOAD_STATUS === "uploaded" && <span className="text-success"> อัพโหลดเสร็จสิ้น</span>}
                                                            </Label>
                                                            <i><FeatherIcon icon="clipboard" className="fea icon-sm icons" /></i>
                                                            <AvField type="file" accept="image/*" className="form-control pl-5"
                                                                errorMessage="กรุณาแนบไฟล์บัตรประชาชนแสดงความประสงค์ที่จะสมัครประกันภัย"
                                                                validate={{ required: { value: true } }}
                                                                placeholder="ไฟล์บัตรประชาชนแสดงความประสงค์ที่จะสมัครประกันภัย"
                                                                name="NATIONAL_ID_FILE"
                                                                onChange={fileSelectedHandler} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <FormGroup className="position-relative">
                                                            {/* ถ้าโหลดเสร็จแล้วจะขึ้นอัพโหลดเสร็จแล้ว */}
                                                            <Label>เลือกไฟล์ใบคำขอ <span className="text-danger">*</span>
                                                                {submitFormData.APPLICATION_UPLOAD_STATUS === "uploading" && <span className="text-primary"> กำลังอัพโหลด</span>}
                                                                {submitFormData.APPLICATION_UPLOAD_STATUS === "uploaded" && <span className="text-success"> อัพโหลดเสร็จสิ้น</span>}
                                                            </Label>
                                                            <i><FeatherIcon icon="clipboard" className="fea icon-sm icons" /></i>
                                                            <AvField type="file" accept="application/pdf" className="form-control pl-5"
                                                                errorMessage="กรุณาแนบไฟล์ใบคำขอเอาประกันที่ลงชื่อแล้ว"
                                                                validate={{ required: { value: true } }}
                                                                placeholder="กรุณาแนบไฟล์ใบคำขอเอาประกันที่ลงชื่อแล้ว"
                                                                name="APPLICATION_FILE"
                                                                onChange={fileSelectedHandler} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <FormGroup className="position-relative">
                                                            {/* ถ้าโหลดเสร็จแล้วจะขึ้นอัพโหลดเสร็จแล้ว */}
                                                            <Label>เลือกไฟล์การชำระเงิน <span className="text-danger">*</span>
                                                                {submitFormData.PAYMENT_UPLOAD_STATUS === "uploading" && <span className="text-primary"> กำลังอัพโหลด</span>}
                                                                {submitFormData.PAYMENT_UPLOAD_STATUS === "uploaded" && <span className="text-success"> อัพโหลดเสร็จสิ้น</span>}
                                                            </Label>
                                                            <i><FeatherIcon icon="clipboard" className="fea icon-sm icons" /></i>
                                                            <AvField type="file" accept="image/*" className="form-control pl-5"
                                                                errorMessage="กรุณาแนบไฟล์หลักฐานการชำระเงิน"
                                                                validate={{ required: { value: true } }}
                                                                placeholder="หลักฐานการชำระเงิน"
                                                                name="PAYMENT_FILE"
                                                                onChange={fileSelectedHandler} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup className="position-relative">
                                                            <Label>สรุปยอดเงินรวมที่ต้องชำระ(บาท)</Label>
                                                            <i><FeatherIcon icon="dollar-sign" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                placeholder="ยอดเงินรวมที่ต้องชำระ" name="paymentTotal" value={planDetail.PLAN_TOTAL_PREMIUM} disabled>{planDetail.PLAN_TOTAL_PREMIUM}</AvField>
                                                            <p className="text-center">ชำระค่าเบี้ยประกันในยอดเต็มและทางทีมงานจะทำค่านายหน้าคืนให้ทุกวันที่ 2, 10, 20 ของเดือน **</p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label>วันที่ทำรายการโอนเงิน <span className="text-danger">*</span></Label>
                                                            <AvField
                                                                type="date"
                                                                name="PAYMENT_DATE"
                                                                errorMessage="กรุณากรอกวันที่ทำรายการโอนเงิน"
                                                                validate={{ required: { value: true } }}
                                                                placeholder="วันที่ทำรายการโอนเงิน"
                                                                onChange={handleChange}
                                                                value={submitFormData.PAYMENT_DATE}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label>เวลาทำรายการโอนเงิน <span className="text-danger">*</span></Label>
                                                            <AvField
                                                                type="time"
                                                                name="PAYMENT_TIME"
                                                                errorMessage="กรุณากรอกเวลาทำรายการโอนเงิน"
                                                                validate={{ required: { value: true } }}
                                                                placeholder="เวลาที่ทำรายการโอนเงิน"
                                                                onChange={handleChange}
                                                                value={submitFormData.PAYMENT_TIME}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="position-relative">
                                                            <Label>ยอดเงินโอน <span className="text-danger">*</span></Label>
                                                            <i><FeatherIcon icon="dollar-sign" className="fea icon-sm icons" /></i>
                                                            <AvField type="text" className="form-control pl-5"
                                                                errorMessage="ยอดเงินโอนไม่ถูกต้อง"
                                                                validate={{ match: { value: 'paymentTotal' }, required: { value: true } }}
                                                                placeholder="ยอดเงินโอน"
                                                                name="PAYMENT_AMOUNT"
                                                                onChange={handleChange}
                                                                value={submitFormData.PAYMENT_AMOUNT} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={12} className="mb-0 auth-button">
                                                        {!isSubmit && <Button color="danger" type="submit" block
                                                            disabled={submitFormData.NATIONAL_ID_UPLOAD_STATUS !== "uploaded" || submitFormData.APPLICATION_UPLOAD_STATUS !== "uploaded" || submitFormData.PAYMENT_UPLOAD_STATUS !== "uploaded"}>ส่งข้อมูลสมัครประกันภัยทั้งหมด</Button>}
                                                        {isSubmit && <Button color="success" block disabled>ส่งข้อมูลสมัครประกันภัยทั้งหมด</Button>}
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default HealthApplicationResult;
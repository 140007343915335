// React Basic and Firebase
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { db } from '../../firebase/config'

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import tvinsurelogo from "../../shared/images/newbrands/tvinsure-main-logo.png";
import tvinsureSmeSabaiPackage from '../../shared/images/tvinsure-sme-sabai-package-4.png';

//Import dowloand covernote module
import { GetCovernoteSeiDiagnostic, GetCovernoteSeiPlus } from '../components/GetCovernoteSei.js'

// Import Ref Data
import { planCovidNewList } from '../../shared/masters/refData'

const AssetQuoteSmeSabaiResult = () => {
    const appid = useParams().appid;
    const covernoteid = useParams().covernoteid;

    const [isLoading, setIsLoading] = useState(false);
    const [coverNoteData, setcoverNoteData] = useState();
    const [error, setError] = useState(null);

    // Get API Province and DistrictSub and Agent and get record detail of health application
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        db.collection('covidApplication').where('APP_FORM_ID', '==', appid).where('COVERNOTE_NUM', '==', covernoteid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setcoverNoteData(snapshot.docs[0].data())
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการดังกล่าวได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setIsLoading(false);
    }, []);

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>หนังสือคุ้มครองของคุณ - TVinsure</title>
                    <meta name="description" content="ดาวโหลดหนังสือคุ้มครองของคุณ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="ดาวโหลดหนังสือคุ้มครองของคุณ" />
                    <meta property="og:description" content="ดาวโหลดหนังสือคุ้มครองของคุณ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureSmeSabaiPackage} />
                </MetaTags>
                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section
                    className="bg-home d-flex align-items-center"
                    style={{ backgroundImage: `url(${tvinsureSmeSabaiPackage})` }}
                >
                    <div className="bg-overlay"></div>
                    <Container>
                        {!isLoading && <Row className="justify-content-center">
                            <Col lg={8} md={12} className="text-center">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                                {coverNoteData && <h4 className="display-4 font-weight-bold text-white title-dark mb-3 mt-4">
                                    ไฟล์หนังสือคุ้มครองของคุณ
                                </h4>}
                                {!coverNoteData && <h4 className="h1 font-weight-bold text-white title-dark mb-3 mt-4">
                                    ไม่พบไฟล์หนังสือคุ้มครองของคุณ
                                </h4>}
                                <p className="text-center text-white h5 mt-1 ">ประกันไวรัสโคโรนา (COVID-19)</p>
                                {coverNoteData && <p className="text-center text-white h6 mt-2">เลขที่หนังสือคุ้มครอง {coverNoteData.COVERNOTE_NUM}</p>}
                                {coverNoteData && <p className="text-center text-white h6 mt-2 mb-3">ผู้เอาประกันภัย {coverNoteData.PRE_NAME} {coverNoteData.FIRST_NAME} {coverNoteData.LAST_NAME}</p>}
                            </Col>
                            {coverNoteData && planCovidNewList.filter(pf => pf.PLAN_ID === coverNoteData.PLAN_ID)[0].PRODUCT_PACKAGE === "SEI-260-519-778-1037" && < Col md={12} className="text-center mb-2">
                                <Button className="btn btn-primary mt-2" onClick={() => GetCovernoteSeiDiagnostic(coverNoteData)}>
                                    <i className="mdi mdi-download"></i> ดาวโหลดเลย (แผน เจอ จ่าย จบ)
                            </Button>
                            </Col>}
                            {coverNoteData && planCovidNewList.filter(pf => pf.PLAN_ID === coverNoteData.PLAN_ID)[0].PRODUCT_PACKAGE === "SEI-PLUS-369-639-799-1369" && < Col md={12} className="text-center mb-2">
                                <Button className="btn btn-primary mt-2" onClick={() => GetCovernoteSeiPlus(coverNoteData)}>
                                    <i className="mdi mdi-download"></i> ดาวโหลดเลย (แผน Plus)
                            </Button>
                            </Col>}
                            {!coverNoteData && <Col md={12} className="text-center mb-2">
                                <Link to="/get-covid-app-by-id">
                                    <Button className="btn btn-primary mt-2">
                                        <i className="mdi mdi-redo-variant"></i> เช็คความคุ้มครองภาพรวมของฉัน
                            </Button>
                                </Link>
                            </Col>}
                        </Row>}

                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}
export default AssetQuoteSmeSabaiResult;

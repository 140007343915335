// PDF Section
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'
import download from '../../shared/context/download'

// get FILES URL ROUTES
import { FILES_URL } from '../../shared/masters/url'

async function getResultNkiVac(data) {
    // Fetch an existing PDF document
    const url = `${FILES_URL}/pdf-template/nki-vac-application`
    const existingPdfBytes = await fetch(url,).then(res => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Embed the Helvetica font but angsana new is choose as a custom font
    const urlFont = `${FILES_URL}/pdf-fonts/angsana-new`
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const angsanaFont = await pdfDoc.embedFont(fontBytes);

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get PNG by use the browser make a fetch() call and use res.arrayBuffer()
    const urlCcheckMark = `${FILES_URL}/pdf-materials/check-mark`
    const checkMarkpngImageBytes = await fetch(urlCcheckMark).then((res) => res.arrayBuffer());

    const checkMarkpngImage = await pdfDoc.embedPng(checkMarkpngImageBytes)

    // Resize the PNG
    const checkMarkpngDims = checkMarkpngImage.scale(0.03)

    // Get the All page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const secondPage = pages[1]
    const thirdPage = pages[2]

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize()

    // Draw a string of text diagonally across the page

    // ------------------------------------------------- Page 1 ------------------------------------------------
    firstPage.drawText(`# ${data.APP_FORM_ID}-${data.CURRENT_DATE}`, {
        x: 390,
        y: height - 70,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
        //   rotate: degrees(-45),
    })

    firstPage.drawText(`${data.PRE_NAME} ${data.FIRST_NAME} ${data.LAST_NAME}`, {
        x: width - 500,
        //   y: height / 2 + 10,
        y: height - 157,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
        //   rotate: degrees(-45),
    })

    firstPage.drawText(`${data.BIRTH_DATE}`, {
        x: width - 490,
        y: height - 176,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    // firstPage.drawText('24', {
    //     x: width - 405,
    //     y: height - 176,
    //     size: 16,
    //     font: angsanaFont,
    //     color: rgb(0, 0, 0),
    // })

    // firstPage.drawText('78 กก.', {
    //     x: width - 305,
    //     y: height - 176,
    //     size: 16,
    //     font: angsanaFont,
    //     color: rgb(0, 0, 0),
    // })

    // firstPage.drawText('174 ซม.', {
    //     x: width - 265,
    //     y: height - 176,
    //     size: 16,
    //     font: angsanaFont,
    //     color: rgb(0, 0, 0),
    // })

    // firstPage.drawText('ไทย', {
    //     x: width - 145,
    //     y: height - 176,
    //     size: 16,
    //     font: angsanaFont,
    //     color: rgb(0, 0, 0),
    // })

    // firstPage.drawText('ไทย', {
    //     x: width - 105,
    //     y: height - 176,
    //     size: 16,
    //     font: angsanaFont,
    //     color: rgb(0, 0, 0),
    // })

    firstPage.drawText(`${data.NATIONAL_ID}`, {
        x: width - 415,
        y: height - 195,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.ADDRESS_DETAIL} ${data.ADDRESS_SUB_DISTRICT} ${data.ADDRESS_DISTRICT} ${data.ADDRESS_PROVINCE} ${data.ADDRESS_ZIP_CODE}`, {
        x: width - 490,
        y: height - 218,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.PHONE_NUM}`, {
        x: width - 305,
        y: height - 240,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.EMAIL_ADDRESS}`, {
        x: width - 150,
        y: height - 240,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.OCCUPATION}`, {
        x: width - 490,
        y: height - 258,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    // ลงข้อมูลผู้รับประโยชน์
    firstPage.drawText(`${data.BENEFIT_PRE_NAME} ${data.BENEFIT_FIRST_NAME} ${data.BENEFIT_LAST_NAME}`, {
        x: width - 470,
        y: height - 276,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })
    firstPage.drawText(`${data.BENEFIT_TYPE}`, {
        x: width - 160,
        y: height - 276,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    // ลงวันคุ้มครอง
    firstPage.drawText(`${data.DATE_COV_START}`, {
        x: width - 390,
        y: height - 308,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })
    firstPage.drawText(`${data.DATE_COV_END}`, {
        x: width - 210,
        y: height - 308,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    // เลือกแผน 1 หรือ แผน 2
    if (data.PLAN_ID === "NKI-VAC-199") {
        firstPage.drawImage(checkMarkpngImage, {
            x: width - 532,
            y: height - 350,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.PLAN_ID === "NKI-VAC-399") {
        firstPage.drawImage(checkMarkpngImage, {
            x: width - 532,
            y: height - 370,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }

    // ท่านมีหรือได้ขอเอาประกันชีวิต หรือประกันภัยสุขภาพไว้กับบริษัท หรือบริษัทอื่นหรือไม่?
    if (data.QUESTION_OTHER_INS_LIFE_HEALTH === "N") {
        firstPage.drawImage(checkMarkpngImage, {
            x: width - 550,
            y: height - 432,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.QUESTION_OTHER_INS_LIFE_HEALTH === "Y") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 125,
            y: height - 432,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
        if (data.QUESTION_OTHER_INS_LIFE_HEALTH_COM) {
            firstPage.drawText(`${data.QUESTION_OTHER_INS_LIFE_HEALTH_COM}`, {
                x: 160,
                y: height - 448,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
        if (data.QUESTION_OTHER_INS_LIFE_HEALTH_SUMINS) {
            firstPage.drawText(`${data.QUESTION_OTHER_INS_LIFE_HEALTH_SUMINS}`, {
                x: 380,
                y: height - 448,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
    }

    // ท่านเคยถูกปฏิเสธการขอเอาประกันชีวิต หรือประกันภัยสุขภาพ หรือปฏิเสธการต่ออายุสัญญาประกันภัย 
    // หรือถูกเรียกเก็บเบี้ยประกันภัยเพิ่มสำหรับการประกันภัยดังกล่าวหรือไม่?
    if (data.QUESTION_OTHER_INS_LIFE_HEALTH_DENY === "N") {
        firstPage.drawImage(checkMarkpngImage, {
            x: width - 550,
            y: height - 514,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.QUESTION_OTHER_INS_LIFE_HEALTH_DENY === "Y") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 125,
            y: height - 514,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
        if (data.QUESTION_OTHER_INS_LIFE_HEALTH_DENY_COM) {
            firstPage.drawText(`${data.QUESTION_OTHER_INS_LIFE_HEALTH_DENY_COM}`, {
                x: 160,
                y: height - 530,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
        if (data.QUESTION_OTHER_INS_LIFE_HEALTH_DENY_SUMINS) {
            firstPage.drawText(`${data.QUESTION_OTHER_INS_LIFE_HEALTH_DENY_SUMINS}`, {
                x: 380,
                y: height - 530,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
    }

    // ท่านมีโรคประจำตัวหรือกินยาอะไรเป็นประจำหรือไม่? 
    if (data.QUESTION_CHRONIC_DISEASE === "N") {
        firstPage.drawImage(checkMarkpngImage, {
            x: width - 550,
            y: height - 576,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.QUESTION_CHRONIC_DISEASE === "Y") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 93,
            y: height - 576,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
        if (data.QUESTION_CHRONIC_DISEASE_DETAIL) {
            firstPage.drawText(`${data.QUESTION_CHRONIC_DISEASE_DETAIL}`, {
                x: 172,
                y: height - 573,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
    }

    // ปัจจุบันท่านกำลังตั้งครรภ์ อยู่หรือไม่?
    if (data.QUESTION_MATERNITY === "N") {
        firstPage.drawImage(checkMarkpngImage, {
            x: width - 550,
            y: height - 620,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.QUESTION_MATERNITY === "Y") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 93,
            y: height - 620,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
        if (data.QUESTION_MATERNITY_DETAIL) {
            firstPage.drawText(`${data.QUESTION_MATERNITY_DETAIL}`, {
                x: 210,
                y: height - 617,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
    }

    // การแถลงข้อมูลการฉีดวัคซีนป้องกันโรคติดเชื้อไวรัสโคโรน่า 2019 (COVID-19) ของผู้ขอเอาประกันภัย?

    // ท่านเคยได้รับวัคซีนป้องกันโรคติดเช้ือไวรัสโคโรน่า2019 (COVID-19) เข็มแรก หรือไม่?
    if (data.QUESTION_VAC_HISTORY === "N") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 61,
            y: height - 683,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.QUESTION_VAC_HISTORY === "Y") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 110,
            y: height - 683,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
        if (data.QUESTION_VAC_HISTORY_DETAIL) {
            firstPage.drawText(`${data.QUESTION_VAC_HISTORY_DETAIL}`, {
                x: 190,
                y: height - 680,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
    }

    // หากท่านเคยได้รับวัคซีนป้องกันโรคติดเช้ือไวรัสโคโรน่า2019 (COVID-19) เข็มแรก ท่านมีผลข้างเคียงหรืออาการแพ้ใดใดหรือไม่?
    if (data.QUESTION_VAC_EFFECT === "N") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 61,
            y: height - 727,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.QUESTION_VAC_EFFECT === "Y") {
        firstPage.drawImage(checkMarkpngImage, {
            x: 110,
            y: height - 727,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
        if (data.QUESTION_VAC_EFFECT_DETAIL) {
            firstPage.drawText(`${data.QUESTION_VAC_EFFECT_DETAIL}`, {
                x: 190,
                y: height - 724,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
    }


    // ------------------------------------------------- Page 2 ------------------------------------------------

    secondPage.drawText(`# ${data.APP_FORM_ID}-${data.CURRENT_DATE}`, {
        x: 390,
        y: height - 70,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    secondPage.drawImage(checkMarkpngImage, {
        x: 50,
        y: height - 173,
        width: checkMarkpngDims.width,
        height: checkMarkpngDims.height,
    })

    // ผู้ขอเอาประกันภัยประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากรหรือไม่ ?
    if (data.QUESTION_TAX_SENT === "N") {
        secondPage.drawImage(checkMarkpngImage, {
            x: 50,
            y: height - 244,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
    }
    if (data.QUESTION_TAX_SENT === "Y") {
        secondPage.drawImage(checkMarkpngImage, {
            x: 50,
            y: height - 263,
            width: checkMarkpngDims.width,
            height: checkMarkpngDims.height,
        })
        if (data.QUESTION_TAX_SENT_DETAIL) {
            secondPage.drawText(`${data.QUESTION_TAX_SENT_DETAIL}`, {
                x: 374,
                y: height - 300,
                size: 14,
                font: angsanaFont,
                color: rgb(0, 0, 0),
            })
        }
    }

    secondPage.drawImage(checkMarkpngImage, {
        x: 50,
        y: height - 565,
        width: checkMarkpngDims.width,
        height: checkMarkpngDims.height,
    })

    // ------------------------------------------------- Page 3 ------------------------------------------------
    thirdPage.drawText(`# ${data.APP_FORM_ID}-${data.CURRENT_DATE}`, {
        x: 390,
        y: height - 70,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawImage(checkMarkpngImage, {
        x: 172,
        y: height - 300,
        width: checkMarkpngDims.width,
        height: checkMarkpngDims.height,
    })

    thirdPage.drawText(`บริษัท ทวีทรัพย์ โบรคเกอร์ จำกัด`, {
        x: 282,
        y: height - 285,
        size: 10,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawText(`(0475)`, {
        x: 315,
        y: height - 295,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    thirdPage.drawText(`ว00288/2534`, {
        x: 440,
        y: height - 295,
        size: 14,
        font: angsanaFont,
        color: rgb(0, 0, 0),
    })

    // SecondPage.drawText('/', {
    //     x: width - 500,
    //     y: height - 170,
    //     size: 16,
    //     font: angsanaFont,
    //     color: rgb(0, 0, 0),
    // })

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, "NKI.pdf", "application/pdf");
}

export { getResultNkiVac }
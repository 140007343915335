import { storage, db } from '../../firebase/config'

import axios from 'axios'
import { covidApi } from '../../shared/masters/url';

import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { AuthContext } from '../../shared/context/auth-context';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';

import { forwardRef } from 'react';

import tvinsurecovid from '../../assets/images/insurance/tvinsurecovid.png';

import MaterialTable, { MTableToolbar } from 'material-table';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import "./ListPolicy.css";
import Menubar from '../components/MenuBar';

// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

import BackgroundSlider from "react-background-slider";

const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const currentDate = new Date()

function addYear(date) {
    const copy = new Date(Number(date))
    copy.setYear(date.getFullYear() + 1)
    return copy
}

const newCovDateStart = currentDate.toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })
const newCovDateEnd = addYear(currentDate).toLocaleDateString('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' })

const ListPolicy = () => {

    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);;
    const [covidAppList, setCovidAppList] = useState([]);
    const [covidAppSelectId, setcovidAppSelectId] = useState();
    const [covidAppSelect, setcovidAppSelect] = useState();
    const [refresh, setrefresh] = useState(false);

    useEffect(() => {
        db.collection("covidEndorsement").get()
            .then((snapshot) => {
                let covidApp = []
                snapshot.docs.forEach(doc => {
                    const newDoc = doc.data()
                    newDoc.actionStatus = doc.data().actionStatus === "FINISH" ? "ดำเนินการเสร็จสิ้น" : "รอการดำเนินการ"
                    covidApp.push(newDoc);
                })
                setIsLoading(false);
                setCovidAppList(covidApp);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error || 'เกิดปัญหาในการส่งข้อมูลเข้าระบบได้ กรุณาลองใหม่อีกครั้ง');
            });
    }, [refresh]);

    //Model Card Detail Set
    const [cardCovidAppDetailModal, setCardCovidAppDetailModal] = useState(false);
    const openCardCovidAppDetailModal = (docid) => {
        db.collection('covidEndorsement').where('docid', '==', docid).get().then((snapshot) => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                setcovidAppSelectId(snapshot.docs[0].id)
                setcovidAppSelect(snapshot.docs[0].data())
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setError('ไม่สามารถค้นหารายการที่ถูกบันทึกลงไปได้ กรุณาลองใหม่อีกครั้ง');
            }
        }).catch((error) => {
            setIsLoading(false);
            setError(error || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
        })
        setCardCovidAppDetailModal(true);
    };

    const closeCardCovidAppDetailModal = () => {
        setCardCovidAppDetailModal(false);
        setcovidAppSelectId(null);
        setcovidAppSelect(null);
    };

    // console.log(covidAppSelectId);

    const handleFinishbutton = (status) => {
        setIsLoading(true);
        db.collection('covidEndorsement').doc(covidAppSelectId).update({
            actionStatus: status,
            actionDate: new Date(),
        })
            .then(() => {
                setIsLoading(false);
                setCardCovidAppDetailModal(false);
                setrefresh(!refresh);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message || 'เกิดปัญหาในการค้นหารายการที่ถูกบันทึก กรุณาลองใหม่อีกครั้ง');
                setCardCovidAppDetailModal(false);
            })
    }

    return (<React.Fragment>
        <section className="bg-half-100 d-table w-100" >
            <BackgroundSlider
                images={items}
                duration={5}
                transition={3}
            />
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="text-center mb-5">
                            <img src={tvinsurelogo} height="120" alt="" />
                        </div>
                    </div>
                </div>
                <div className="title-heading text-center">
                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                        ระบบบริหารงานประกันภัย
                    </h4>
                    <p className="text-center text-white h5 mt-1 mb-5">สำหรับการใช้งานของพนักงานหลังบ้านเท่านั้น</p>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                        fill="currentColor"
                    ></path>
                </svg>
            </div>
        </div>
        <section style={{ margin: "-120px 0 0" }}>
            <Container>
                <Row className="justify-content-center">
                    {covidAppSelect && !isLoading && (<div className="justify-content-center">
                        <Modal isOpen={cardCovidAppDetailModal} >
                            <ModalHeader toggle={closeCardCovidAppDetailModal}>การสลักหลัง/แก้ไขข้อมูลประกันภัย</ModalHeader>
                            <ModalBody className="pb-2">
                                <p className="mb-3 text-center h5">สรุปข้อมูลการสลักหลังดังนี้</p>
                                <p className="mb-3 text-center h6">หมายเลขการสลักหลัง: {covidAppSelect.docid}</p>
                                <div className="row">
                                    <div className="col-7 text-left">
                                        <p className="mb-2 h6"> เลขกรมธรรม์</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <p>{covidAppSelect.policyNum} </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-left">
                                        <p className="mb-2 h6"> รายละเอียดการสลักหลัง</p>
                                    </div>
                                    <div className="col-12 text-left">
                                        <p>{covidAppSelect.endorsementDetail}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-left">
                                        <p className="mb-2 h6"> เบอร์โทรศัพท์มือถือ</p>
                                    </div>
                                    <div className="col-6 text-right">
                                        <p>{covidAppSelect.tel} </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 text-left">
                                        <p className="mb-2 h6"> อีเมล</p>
                                    </div>
                                    <div className="col-9 text-right">
                                        <p>{covidAppSelect.email} </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 text-left">
                                        <p className="mb-2 h6"> สถานะ</p>
                                    </div>
                                    <div className={`col-9 text-right ${covidAppSelect.actionStatus === "FINISH" ? "text-success" : "text-danger"}`}>
                                        <p>{covidAppSelect.actionStatus === "FINISH" ? "ดำเนินการเสร็จสิ้น" : "รอการดำเนินการ"} </p>
                                    </div>
                                </div>

                            </ModalBody>
                            <ModalFooter>
                                {covidAppSelect.actionStatus !== "FINISH" && <Button color="success" onClick={
                                    () => {
                                        const check = window.confirm("ยืนยันการเปลี่ยนแปลงสถานะ")
                                        if (check === true) {
                                            handleFinishbutton("FINISH")
                                        } else {
                                            return false
                                        }
                                    }}>ดำเนินการเสร็จสิ้น</Button>}
                                {covidAppSelect.actionStatus === "FINISH" && <Button color="danger" onClick={
                                    () => {
                                        const check = window.confirm("ยืนยันการเปลี่ยนแปลงสถานะ")
                                        if (check === true) {
                                            handleFinishbutton("")
                                        } else {
                                            return false
                                        }
                                    }}>ยกเลิกรายการเสร็จสิ้น</Button>}
                                <Button color="danger" onClick={closeCardCovidAppDetailModal}>ย้อนกลับ</Button>{' '}
                            </ModalFooter>
                        </Modal>
                    </div>)}

                    <Col lg={12} md={10} sm={8}>
                        <Menubar page="endorsement-list" />
                        {isLoading && <div className="row">
                            <div className="col-xl-12 text-center mt-5">
                                <div style={{ maxWidth: '100%' }}>
                                    <p className="text-dark font-weight-bold">กำลังโหลดข้อมูล...</p>
                                </div>
                            </div>
                        </div>}
                        {error && <div className="row">
                            <div className="col-xl-12 text-center mt-5">
                                <div style={{ maxWidth: '100%' }}>
                                    <p className="text-dark font-weight-bold">{error}</p>
                                </div>
                            </div>
                        </div>}
                        <MaterialTable
                            icons={tableIcons}
                            columns={[
                                { title: 'เลขที่สลักหลัง', field: 'docid' },
                                { title: 'เลขกรมธรรม์', field: 'policyNum' },
                                { title: 'เบอร์โทรศัพท์', field: 'tel' },
                                { title: 'อีเมล', field: 'email' },
                                { title: 'รายละเอียดการสลักหลัง', field: 'endorsementDetail'},
                                { title: 'สถานะ', field: 'actionStatus', defaultGroupOrder: 0 }
                            ]}
                            data={covidAppList}
                            title={`รายการสลักสลังทั้งหมด ${covidAppList.length} รายการ`}
                            actions={[
                                {
                                    icon: () => <ChevronRightIcon />,
                                    tooltip: 'รายละเอียด',
                                    onClick: (event, rowData) => {
                                        openCardCovidAppDetailModal(rowData.docid);
                                    }
                                }
                            ]}

                            options={{
                                tableLayout: "auto",
                                exportButton: true,
                                grouping: true,
                                headerStyle: {
                                    fontFamily: 'Kanit, sans-serif'
                                },
                                searchFieldStyle: {
                                    fontFamily: 'Kanit, sans-serif'
                                },
                                toolbarFieldStyle: {
                                    fontFamily: 'Kanit, sans-serif'
                                },
                                paging: true,
                                pageSize: 20,       // make initial page size
                                emptyRowsWhenPaging: false,   //to make page size not fix (dynamic) in case of less data rows
                                pageSizeOptions: [5, 10, 20, 50, 100, 200, 1000],    // rows selection options
                            }}

                            components={{
                                Toolbar: props => (
                                    <Container>
                                        <Row>
                                            <Col lg={12}    >
                                                <MTableToolbar {...props} />
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="btn btn-pills btn-primary mb-3" style={{ float: 'right' }} onClick={() => setrefresh(!refresh)}> ดึงข้อมูลใหม่ </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                ),
                            }}

                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} จาก {count}',
                                    labelRowsSelect: 'รายการ'
                                },
                                toolbar: {
                                    nRowsSelected: '{0} รายการที่เลือก',
                                    searchPlaceholder: 'ค้นหา'
                                },
                                header: {
                                    actions: ''
                                },
                                body: {
                                    emptyDataSourceMessage: 'ไม่มีรายการ',
                                    filterRow: {
                                        filterTooltip: 'ตัวกรอง'
                                    }
                                },
                                grouping: {
                                    placeholder: 'ลากหัวข้อมาที่นี่เพื่อแบ่งกลุ่ม',
                                    groupedBy: 'แบ่งกลุ่มโดย: '
                                }
                            }}

                        />
                    </Col>
                    <p className="text-center my-4 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                </Row>
            </Container>
        </section>
    </React.Fragment>
    );
}


export default ListPolicy;
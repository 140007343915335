import { storage, db } from '../../firebase/config'
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BackgroundSlider from "react-background-slider";

//Import Icons
import FeatherIcon from 'feather-icons-react';
import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';


// Import images
import tvinsureCovidInsOne from '../../shared/images/TVinsure-Covid-1.png';
import tvinsureCovidInsTwo from '../../shared/images/TVinsure-Covid-2.png';
import tvinsureCovidInsThree from '../../shared/images/TVinsure-Covid-3.png';

// Import Cards
import VibCard from '../../shared/images/cards/TVinsure-Covid-Card-Vib.jpg';
import AsiaCard from '../../shared/images/cards/TVinsure-Covid-Card-Asia.jpg';
import DhpCard from '../../shared/images/cards/TVinsure-Covid-Card-Dhp.jpg';
import SmkCard from '../../shared/images/cards/TVinsure-Covid-Card-Smk.jpg';
import SeiCard from '../../shared/images/cards/TVinsure-Covid-Card-Sei.jpg';

import './GetCovidIdSearch.css'

import CovidClaimDoc from '../components/CovidClaimDoc'


const items = [tvinsureCovidInsOne, tvinsureCovidInsTwo, tvinsureCovidInsThree]

const GetPolicy = () => {

    const [formData, setFormData] = useState({
        appNum: "",
        appCheck: "uncheck"
    })

    const [covidAppData, setCovidAppData] = useState(null)
    const [vibCoverNoteUrl, setVibCoverNoteUrl] = useState("");
    const [policyUrlArray, setpolicyUrlArray] = useState([]);

    function onResolve(foundURL) {
        // console.log(foundURL);
        setVibCoverNoteUrl(foundURL);
    }

    function onReject(error) {
        // console.log(error);
        setVibCoverNoteUrl("");
    }

    function OnRecheck() {
        storage.ref("covernotefile").child(`${formData.idNum}.PDF`).getDownloadURL().then(onResolve, onReject);
    }

    // console.log(covidAppData)

    // console.log(formData)

    const handleChange = event => {
        const { name, value } = event.target

        setFormData(prevValue => {
            return {
                ...prevValue,
                appCheck: "uncheck",
                [name]: value.replace(/\//g, "").toUpperCase()
            }
        });
    }

    const handleClick = () => {
        db.collection("covidApplication").where("APP_FORM_ID", "==", formData.appNum).get()
            .then((snapshot) => {
                if (snapshot.docs[0].data().APP_FORM_TYPE === "NEW-GB" && snapshot.docs[0].data().APP_FORM_PAYMENT_STATUS === "INITIALIZE") {
                    setFormData(prevValue => {
                        return {
                            ...prevValue,
                            appCheck: "notFound",
                        }
                    });
                }
                if (snapshot.docs[0].data().APP_FORM_TYPE === "NEW-GB" && snapshot.docs[0].data().APP_FORM_PAYMENT_STATUS === "COMPLETE") {
                    setCovidAppData(snapshot.docs[0].data());
                    setFormData(prevValue => {
                        return {
                            ...prevValue,
                            appCheck: "check"
                        }
                    });
                    storage.ref("covernotefile").child(`${snapshot.docs[0].data().NATIONAL_ID}.pdf`).getDownloadURL().then(onResolve, OnRecheck);
                    storage.ref("policyfile").child(`${snapshot.docs[0].data().POLICY_NUM.replace(/\//g, "")}.pdf`).getDownloadURL().then((url) => {
                        const policyFoundObject = {
                            policyNum: snapshot.docs[0].data().POLICY_NUM,
                            policyUrl: url
                        }
                        setpolicyUrlArray([...policyUrlArray, policyFoundObject]);
                    }).catch((error) => {
                        const policyFoundObject = {
                            policyNum: snapshot.docs[0].data().POLICY_NUM,
                            policyUrl: ""
                        }
                        setpolicyUrlArray([...policyUrlArray, policyFoundObject]);
                    });
                }
                if (snapshot.docs[0].data().APP_FORM_TYPE === "NEW" || snapshot.docs[0].data().APP_FORM_TYPE === "RENEW" ||snapshot.docs[0].data().APP_FORM_TYPE === "NEW-PORT") {
                    setCovidAppData(snapshot.docs[0].data());
                    setFormData(prevValue => {
                        return {
                            ...prevValue,
                            appCheck: "check"
                        }
                    });
                    storage.ref("covernotefile").child(`${snapshot.docs[0].data().NATIONAL_ID}.pdf`).getDownloadURL().then(onResolve, OnRecheck);
                    storage.ref("policyfile").child(`${snapshot.docs[0].data().POLICY_NUM.replace(/\//g, "")}.pdf`).getDownloadURL().then((url) => {
                        const policyFoundObject = {
                            policyNum: snapshot.docs[0].data().POLICY_NUM,
                            policyUrl: url
                        }
                        setpolicyUrlArray([...policyUrlArray, policyFoundObject]);
                    }).catch((error) => {
                        const policyFoundObject = {
                            policyNum: snapshot.docs[0].data().POLICY_NUM,
                            policyUrl: ""
                        }
                        setpolicyUrlArray([...policyUrlArray, policyFoundObject]);
                    });
                }                
            })
            .catch((error) => {
                setFormData(prevValue => {
                    return {
                        ...prevValue,
                        appCheck: "notFound",
                    }
                });
            });
    }

    useEffect(() => {
        storage.ref("covernotefile").listAll().then(function (res) {
            console.log(res.items.length);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="back-to-home rounded d-none d-sm-block">
                <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                    <i>
                        <FeatherIcon icon="home" className="icons" />
                    </i>
                </a>
            </div>
            <section className="bg-half-90 d-table w-100" >
                <BackgroundSlider
                    images={items}
                    duration={5}
                    transition={3}
                />
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="text-center mb-5">
                                <a href="https://tvinsure.com" className="logo h5">
                                    <img src={tvinsurelogo} height="120" alt="" />
                                </a>
                            </div>
                            <div className="title-heading text-center">
                                <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                    เช็คสถานะการสมัครประกันภัยไวรัสโคโรนา
                                </h4>
                                <p className="text-center text-white h5 mt-1 mb-5">ประกันภัยไวรัสโคโรนา (ประกันภัยใหม่)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>
            <section style={{ margin: "-120px 0 0" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12} md={10} sm={8}>
                            <Card>
                                <CardBody className="shadow-lg">
                                    <h4 className="text-center mt-4 mb-3">สำหรับการสมัครประกันภัยโคโรนาไวรัสใหม่เท่านั้น</h4>
                                    <h5 className="text-left">ขั้นตอนการค้นหากรมธรรม์มีดังนี้</h5>
                                    <h6 className="text-left"> - กรอกเลขคำขอของรายการที่ได้รับหลังจากสมัคประกันภัยสำเร็จ หรือทางอีเมลที่ท่านได้ลงทะเบียนไว้ตอนสมัครประกันภัย</h6>
                                    <h6 className="text-left"> - กดปุ่มตรวจสอบเพื่อค้นหาเลขตามคำขอ</h6>
                                    <h6 className="text-left"> - ระบบจะแสดงข้อมูลรายละเอียดการสมัครประกันภัยทั้งหมดขึ้นมา</h6>
                                    <h6 className="text-left"> - หากระบบขึ้นว่าไม่พบรายการคำขอประกันภัย ลองตรวจสอบเลขคำขอประกันภัยอีกครั้ง (โดยปกติจะขึ้นต้นด้วย CV) หรือกรุณาติดต่อทีมงานเพื่อดำเนินการต่อไป</h6>
                                    <AvForm className="login-form mt-4 mb-3">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>เลขใบคำขอการสมัครประกันภัยไวรัสโคโรนาใหม่ <span className="text-danger">*</span></Label>
                                                    <i><FeatherIcon icon="hash" className="fea icon-sm icons" /></i>
                                                    <AvField type="text" className="form-control pl-5"
                                                        errorMessage="กรุณากรอกเลขคำขอการสมัครประกันภัยไวรัสโคโรนาใหม่"
                                                        validate={{ required: { value: true } }} placeholder="กรอกเลขคำขอการสมัครประกันภัยไวรัสโคโรนาใหม่" name="appNum" onChange={handleChange} value={formData.appNum} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="position-relative">
                                                    <Label>กดปุ่มเพื่อตรวจสอบ</Label>
                                                    <Button className="mb-2" color={formData.appCheck === "uncheck" ? "danger" : covidAppData && formData.appCheck === "check" ? "success" : "danger"}
                                                        outline={formData.appCheck === "uncheck"} block
                                                        disabled={!formData.appNum}
                                                        onClick={handleClick}>{formData.appCheck === "uncheck" ? "กดเพื่อตรวจสอบ" : covidAppData && formData.appCheck === "check" ? "พบข้อมูลที่ค้นหา" : "ไม่พบข้อมูลที่ค้นหา"}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                            {formData.appCheck === "notFound" && <Col xs={12} className="text-center">
                                                <p className="mb-0 mt-3"><small className="text-danger mr-2 h6 mt-2">ไม่ข้อมูลรายการคำขอประกันภัยที่ท่านค้นหา</small></p>
                                                <p className="mt-4 mb-3 text-left">* หากเกิดข้อผิดพลาด หรือ ไม่พบรายการคำขอประกันภัยที่ท่านค้นหาสามารถติดต่อทีมงานได้ตามช่องทางด้านล่างเพื่อจัดส่งทางช่องทางอื่นเพิ่มเติม</p>
                                                <ul className="mt-1 text-left">
                                                    <li>Line: @tvinsure</li>
                                                    <li>โทรศัพท์: 0-2266-373-1 ถึง 5</li>
                                                </ul>
                                            </Col>}
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            {covidAppData && formData.appCheck === "check" && <Card className="mt-5 mb-2">
                                <CardBody className="shadow-lg">
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <p className="mb-3 text-center h6">หมายเลขการสมัคร: {covidAppData.APP_FORM_ID}</p>
                                            <p className={`mb-1 text-center h6 ${covidAppData.POLICY_NUM ? "text-success" : covidAppData.COVERNOTE_NUM ? "text-danger" : covidAppData.PLAN_ISSUER_CODE === "VIB" && vibCoverNoteUrl ? "text-danger" : "text-danger"}`}>{covidAppData.POLICY_NUM ? "ได้รับกรมธรรม์แล้ว" : covidAppData.COVERNOTE_NUM ? "ได้รับหนังสือคุ้มครองแล้ว" : covidAppData.PLAN_ISSUER_CODE === "VIB" && vibCoverNoteUrl ? "ได้รับหนังสือคุ้มครองแล้ว" : "รอไฟล์กรมธรรม์"}</p>
                                            {/* <p className={`mb-1 text-center h6 ${covidAppData.POLICY_NUM ? "text-success" : covidAppData.COVERNOTE_NUM ? "text-danger" : "text-danger"}`}>{covidAppData.POLICY_NUM ? "ได้รับกรมธรรม์แล้ว" : covidAppData.COVERNOTE_NUM ? "ได้รับหนังสือคุ้มครองแล้ว" : "รอไฟล์กรมธรรม์"}</p> */}
                                            <Col lg={6} md={12} sm={12} xs={12} className="text-center mt-3 mb-5" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                                <div class="container">
                                                    {covidAppData.PLAN_ISSUER_CODE === "SMK" && <img src={SmkCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.PLAN_ISSUER_CODE === "DHP" && <img src={DhpCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.PLAN_ISSUER_CODE === "AII" && <img src={AsiaCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.PLAN_ISSUER_CODE === "VIB" && <img src={VibCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    {covidAppData.PLAN_ISSUER_CODE === "SEI" && <img src={SeiCard} alt="Snow" className="shadow-lg rounded" style={{ width: "100%" }} />}
                                                    <div class="bottom-left-plan"><small>{covidAppData.PLAN_DESC.slice(0, 30)} ...</small></div>
                                                    <div class="bottom-left-name"><small>{covidAppData.PRE_NAME} {covidAppData.FIRST_NAME} {covidAppData.LAST_NAME} </small></div>
                                                    <div class="bottom-left-idcard"><small>เลขบัตรประชาชน: {covidAppData.NATIONAL_ID}</small></div>
                                                    <div class="bottom-left-appnum">
                                                        <small> {covidAppData.POLICY_NUM ? `เลขกรมธรรม์: ${covidAppData.POLICY_NUM}` : covidAppData.COVERNOTE_NUM ? `เลขหนังสือคุ้มครอง: ${covidAppData.COVERNOTE_NUM}` : `เลขที่การสมัคร: ${covidAppData.APP_FORM_ID}`}
                                                            {covidAppData.POLICY_NUM_FILE_URL && <a href={`${covidAppData.POLICY_NUM_FILE_URL}`} target="_blank"><i><FeatherIcon icon="download" className="fea icon-sm icons pl-1 text-danger covid-file-num" /></i></a>}
                                                            {!covidAppData.POLICY_NUM_FILE_URL && policyUrlArray && policyUrlArray.filter(pf => pf.policyNum === covidAppData.POLICY_NUM && pf.policyUrl !== "")[0] &&  <a href={`${policyUrlArray.filter(pf => pf.policyNum === covidAppData.POLICY_NUM)[0].policyUrl}`} target="_blank"><i><FeatherIcon icon="file" className="fea icon-sm icons pl-1 text-danger covid-file-num" /></i></a>}
                                                            {covidAppData.PLAN_ISSUER_CODE === "SEI" && covidAppData.COVERNOTE_NUM && <Link to={`/get-covid-cover-note/${covidAppData.APP_FORM_ID}/${covidAppData.COVERNOTE_NUM}`} target="_blank"><i><FeatherIcon icon="file-text" className="fea icon-sm icons pl-1 text-danger covid-file-num" /></i></Link>}
                                                        </small>
                                                    </div>
                                                    {/* SEI Cov Date depend on payment Date  Only SEI */}
                                                    <div class="bottom-left-covdate"><small>วันที่คุ้มครอง: {covidAppData.PLAN_ISSUER_CODE === "SEI" ? `${covidAppData.GBPAYMENT_DATE_THAI.split("/")[1]}/${covidAppData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidAppData.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}` : covidAppData.DATE_COV_START} - {covidAppData.PLAN_ISSUER_CODE === "SEI" ? `${covidAppData.GBPAYMENT_DATE_THAI.split("/")[1]}/${covidAppData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidAppData.GBPAYMENT_DATE_THAI.split("/")[2]) + 544}` : covidAppData.DATE_COV_END} </small></div>
                                                </div>
                                            </Col>
                                            <p className="my-3 text-center h6">{covidAppData.PLAN_DESC}</p>
                                            {covidAppData.POLICY_NUM && covidAppData.POLICY_NUM_FILE_URL && <Col lg={12} className="my-4 auth-button">
                                                <a href={`${covidAppData.POLICY_NUM_FILE_URL}`} target="_blank">
                                                    <Button color="danger" className="d-block mx-auto">ดาวโหลดไฟล์กรมธรรม์ประกันภัย</Button>
                                                </a>
                                            </Col>}

                                            {/* Show Policy Download URL SEARCH */}
                                            {!covidAppData.POLICY_NUM_FILE_URL && policyUrlArray && policyUrlArray.filter(pf => pf.policyNum === covidAppData.POLICY_NUM && pf.policyUrl !== "")[0] && <Col lg={12} className="my-3 auth-button">
                                                <Button color="danger" outline className="d-block mx-auto"><a href={`${policyUrlArray.filter(pf => pf.policyNum === covidAppData.POLICY_NUM)[0].policyUrl}`} target="_blank" className="text-white">ดาวโหลดไฟล์กรมธรรม์ของคุณได้ที่นี่</a></Button>
                                            </Col>}

                                            {covidAppData.PLAN_ISSUER_CODE === "SEI" && covidAppData.COVERNOTE_NUM && <Col lg={12} className="my-4 auth-button">
                                                <Link to={`/get-covid-cover-note/${covidAppData.APP_FORM_ID}/${covidAppData.COVERNOTE_NUM}`} target="_blank">
                                                    <Button color="danger" className="d-block mx-auto">ดาวโหลดไฟล์หนังสือคุ้มครองจากอาคเนย์ประกันภัย</Button>
                                                </Link>
                                            </Col>}
                                            {covidAppData.PLAN_ISSUER_CODE === "VIB" && vibCoverNoteUrl && <Col lg={12} className="my-4 auth-button">
                                            <Button color="danger" outline className="d-block mx-auto"><a href={`${vibCoverNoteUrl}`} target="_blank" className="text-white">ดาวโหลดไฟล์หนังสือคุ้มครองจากวิริยะประกันภัย</a></Button>
                                            </Col>}
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> วันที่คุ้มครอง</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    {/* SEI Cov Date depend on payment Date  Only SEI */}
                                                    <small>{covidAppData.PLAN_ISSUER_CODE === "SEI" ? `${covidAppData.GBPAYMENT_DATE_THAI.split("/")[1]}/${covidAppData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidAppData.GBPAYMENT_DATE_THAI.split("/")[2]) + 543}` : covidAppData.DATE_COV_START} - {covidAppData.PLAN_ISSUER_CODE === "SEI" ? `${covidAppData.GBPAYMENT_DATE_THAI.split("/")[1]}/${covidAppData.GBPAYMENT_DATE_THAI.split("/")[0]}/${Number(covidAppData.GBPAYMENT_DATE_THAI.split("/")[2]) + 544}` : covidAppData.DATE_COV_END}  </small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> ชื่อ-นามสกุล</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.PRE_NAME} {covidAppData.FIRST_NAME} {covidAppData.LAST_NAME} </small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> วันเดือนปีเกิดผู้เอาประกันภัย</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.BIRTH_DATE}</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> เพศ</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.GENDER === 'M' ? "ชาย" : 'หญิง'} </small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> เลขที่บัตรประจำตัวประชาชน</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.NATIONAL_ID} </small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5 text-left">
                                                    <small className="mb-2 font-weight-bold"> ที่อยู่</small>
                                                </div>
                                                <div className="col-7 text-right">
                                                    <small>{covidAppData.ADDRESS_DETAIL}</small>
                                                </div>
                                                <div className="col-12 text-right">
                                                    <small>{covidAppData.ADDRESS_SUB_DISTRICT} {covidAppData.ADDRESS_DISTRICT} {covidAppData.ADDRESS_PROVINCE} {covidAppData.ADDRESS_ZIP_CODE}</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> ผู้รับประโยชน์</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.BENEFIT_TYPE} - {covidAppData.BENEFIT_PRE_NAME} {covidAppData.BENEFIT_FIRST_NAME} {covidAppData.BENEFIT_LAST_NAME}</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> เบอร์โทรศัพท์มือถือ</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.PHONE_NUM} </small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> อีเมล</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.EMAIL_ADDRESS} </small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> บริษัทประกันภัย</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small>{covidAppData.PLAN_ISSUER_CODE} - {covidAppData.PLAN_ISSUER}</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-2 font-weight-bold"> เบี้ยประกันภัย (เบี้ยรวม)</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small className="mb-2">{covidAppData.TOTAL_PREMIUM} บาท</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-4 font-weight-bold"> ผู้แจ้งงาน</small>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <small className="mb-4">{covidAppData.AGENT_CODE} - {covidAppData.AGENT_NAME} {covidAppData.AGENT_REMARK}</small>
                                                </div>
                                            </div>
                                            {covidAppData.POLICY_NUM && <div className="row">
                                                <div className="col-6 text-left">
                                                    <small className="mb-4 font-weight-bold"> เลขกรมธรรม์ประกันภัย</small>
                                                </div>
                                                <div className={`col-6 text-right`}>
                                                    <small className="mb-4">{covidAppData.POLICY_NUM}</small>
                                                </div>
                                            </div>}
                                        </Col>
                                        <CovidClaimDoc
                                            IssuerCode={covidAppData.PLAN_ISSUER_CODE}
                                            IssuerDetail={covidAppData.PLAN_ISSUER}
                                        />
                                    </Row>
                                </CardBody>
                            </Card>}

                            <Row>
                                <Col lg={12}>
                                    <p className="text-center mt-5 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                                </Col>
                                <Col lg={12}>
                                    <p className="text-center text-muted mb-5">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}


export default GetPolicy;
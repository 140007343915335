import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { db } from '../../firebase/config'

import BackgroundSlider from "react-background-slider";
import StarRatings from "react-star-ratings";

import axios from 'axios'
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { buildingType, businessAndIndustrySabuyPackage, premiumSabuyPackage } from '../../shared/masters/refData'

//Import Icons
import FeatherIcon from 'feather-icons-react';

import tvinsurelogo from '../../shared/images/newbrands/tvinsure-main-logo.png';
import smkinsurancelogo from '../../shared/images/SMK_LOGO.png';
import asiainsurancelogo from '../../shared/images/ASIA_LOGO.png';
import dhpinsurancelogo from '../../shared/images/DHP_LOGO.jpg';
import vibinsurancelogo from '../../shared/images/VIB_LOGO.jpg';
import nkiinsurancelogo from '../../shared/images/NKI_LOGO.png';
import stysrminsurancelogo from '../../shared/images/tokyo-marine-logo.png';


import './Product.css';

import tvinsurebg from '../../shared/images/bg-services.jpg';
import tvinsureSmeSabaiPackage from '../../shared/images/tvinsure-sme-sabai-package-4.png';
import tvinsureAssetInsOne from '../../shared/images/TVinsure-Benefit-of-Insurance-new.png';
import tvinsureAssetInsTwo from '../../shared/images/TVinsure-Benefit-of-Insurance-home.png';
import tvinsureAssetInsThree from '../../shared/images/TVinsure-Benefit-of-Insurance-consult.png';



const AssetQuoteAll = () => {

    const items = [tvinsureAssetInsOne, tvinsureAssetInsTwo, tvinsureAssetInsThree]
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [packageFilter, setPackageFilter] = useState();

    const buildingTypeSmeSabaiFilter = buildingType.filter(b => b.IS_SME_SABAI_OK === "Y")

    // Set to Top of the page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //contain all form data
    const [formData, setFormData] = useState({
        TOTAL_SUMINS: "",
        INDUSTRY_CODE: ""
    });

    //listen to all key stroke of formdata
    const handleChange = event => {
        const { name, value } = event.target

        if (name === "BUSINESS_CODE" && value) {
            const businessCodefilter = businessAndIndustrySabuyPackage.filter(bt => bt.BUSINESS_CODE === value)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    BUSINESS_CODE: value,
                    BUSINESS_DESC: businessCodefilter[0].BUSINESS_DESC,
                    INDUSTRY_CODE: businessCodefilter[0].INDUSTRY_CODE,
                    INDUSTRY_DESC: businessCodefilter[0].INDUSTRY_DESC
                }
            });
        }

        if (name === "BUILDING_CODE" && value) {
            const buildingTypefilter = buildingType.filter(bt => bt.BUILDING_CODE === value)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    BUILDING_CODE: value,
                    BUILDING_TYPE: buildingTypefilter[0].BUILDING_TYPE,
                    TOTAL_BUILDING_WIDTH: "",
                    TOTAL_BUILDING_LENGTH: "",
                    TOTAL_BUILDING_LEVEL: buildingTypefilter[0].BUILDING_LEVEL,
                    RATE_PRICE: "",
                    RECOMMED_BUILDING_PRICE_PER_SQM: "",
                    RECOM_BUILDING_SUMINS: "",
                    TOTAL_BUILDING_SUMINS: ""
                }
            });
        }

        if (name === "TOTAL_BUILDING_WIDTH" && value) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    TOTAL_BUILDING_WIDTH: value,
                    RATE_PRICE: "",
                    RECOMMED_BUILDING_PRICE_PER_SQM: "",
                    RECOM_BUILDING_SUMINS: "",
                    TOTAL_BUILDING_SUMINS: ""
                }
            });
        }

        if (name === "TOTAL_BUILDING_LENGTH" && value) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    TOTAL_BUILDING_LENGTH: value,
                    RATE_PRICE: "",
                    RECOMMED_BUILDING_PRICE_PER_SQM: "",
                    RECOM_BUILDING_SUMINS: "",
                    TOTAL_BUILDING_SUMINS: ""
                }
            });
        }

        if (name === "TOTAL_BUILDING_LEVEL" && value) {
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    TOTAL_BUILDING_LEVEL: value,
                    RATE_PRICE: "",
                    RECOMMED_BUILDING_PRICE_PER_SQM: "",
                    RECOM_BUILDING_SUMINS: "",
                    TOTAL_BUILDING_SUMINS: ""
                }
            });
        }

        if (name === "RATE_PRICE" && value === "LOW_PRICE") {
            const buildingTypefilter = buildingType.filter(bt => bt.BUILDING_CODE === formData.BUILDING_CODE)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    RECOMMED_BUILDING_PRICE_PER_SQM: buildingTypefilter[0].LOW_PRICE,
                    RECOM_BUILDING_SUMINS: Math.ceil(Number((buildingTypefilter[0].LOW_PRICE * formData.TOTAL_BUILDING_WIDTH * formData.TOTAL_BUILDING_LENGTH * formData.TOTAL_BUILDING_LEVEL) / 100000)) * 100000,
                    TOTAL_BUILDING_SUMINS: Math.ceil(Number((buildingTypefilter[0].LOW_PRICE * formData.TOTAL_BUILDING_WIDTH * formData.TOTAL_BUILDING_LENGTH * formData.TOTAL_BUILDING_LEVEL) / 100000)) * 100000
                }
            });
        }

        if (name === "RATE_PRICE" && value === "MID_PRICE") {
            const buildingTypefilter = buildingType.filter(bt => bt.BUILDING_CODE === formData.BUILDING_CODE)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    RECOMMED_BUILDING_PRICE_PER_SQM: buildingTypefilter[0].MID_PRICE,
                    RECOM_BUILDING_SUMINS: Math.ceil(Number((buildingTypefilter[0].MID_PRICE * formData.TOTAL_BUILDING_WIDTH * formData.TOTAL_BUILDING_LENGTH * formData.TOTAL_BUILDING_LEVEL) / 100000)) * 100000,
                    TOTAL_BUILDING_SUMINS: Math.ceil(Number((buildingTypefilter[0].MID_PRICE * formData.TOTAL_BUILDING_WIDTH * formData.TOTAL_BUILDING_LENGTH * formData.TOTAL_BUILDING_LEVEL) / 100000)) * 100000
                }
            });
        }

        if (name === "RATE_PRICE" && value === "HIGH_PRICE") {
            const buildingTypefilter = buildingType.filter(bt => bt.BUILDING_CODE === formData.BUILDING_CODE)
            setFormData(prevValue => {
                return {
                    ...prevValue,
                    RECOMMED_BUILDING_PRICE_PER_SQM: buildingTypefilter[0].HIGH_PRICE,
                    RECOM_BUILDING_SUMINS: Math.ceil(Number((buildingTypefilter[0].HIGH_PRICE * formData.TOTAL_BUILDING_WIDTH * formData.TOTAL_BUILDING_LENGTH * formData.TOTAL_BUILDING_LEVEL) / 100000)) * 100000,
                    TOTAL_BUILDING_SUMINS: Math.ceil(Number((buildingTypefilter[0].HIGH_PRICE * formData.TOTAL_BUILDING_WIDTH * formData.TOTAL_BUILDING_LENGTH * formData.TOTAL_BUILDING_LEVEL) / 100000)) * 100000
                }
            });
        }

        setFormData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        });
    }

    const handleResetClick = () => {
        setPackageFilter(null)
        setFormData({
            TOTAL_SUMINS: "",
            INDUSTRY_CODE: ""
        })
        setIsSubmit(false);
    }

    const handleValidSubmit = (event) => {
        event.preventDefault();
        window.scrollTo(0, 0);
        setIsSubmit(true);
        setIsLoading(true);
        const totalSumCal = Number(formData.TOTAL_BUILDING_SUMINS) + Number(formData.TOTAL_EQUIP_SUMINS || 0) + Number(formData.TOTAL_FUR_SUMINS || 0) + Number(formData.TOTAL_STOCK_SUMINS || 0) + Number(formData.TOTAL_OTHER_SUMINS || 0)
        setFormData(prevValue => {
            return {
                ...prevValue,
                TOTAL_BUILDING_SUMINS: formData.TOTAL_BUILDING_SUMINS || 0,
                TOTAL_EQUIP_SUMINS: formData.TOTAL_EQUIP_SUMINS || 0,
                TOTAL_FUR_SUMINS: formData.TOTAL_FUR_SUMINS || 0,
                TOTAL_STOCK_SUMINS: formData.TOTAL_STOCK_SUMINS || 0,
                TOTAL_OTHER_SUMINS: formData.TOTAL_OTHER_SUMINS || 0,
                TOTAL_SUMINS: totalSumCal
            }
        });
        const filteredPackage = premiumSabuyPackage.filter(p => Math.ceil(Number(totalSumCal)) >= Number(p.TOTAL_SUMINS_MIN) && Math.ceil(Number(totalSumCal)) <= Number(p.TOTAL_SUMINS_MAX) && p.INDUSTRY_CODE === formData.INDUSTRY_CODE)
        function getPackageRearrange() {
            const output = [];
            for (let i = 0; i < filteredPackage.length; i++)
                output.push({
                    id: i,
                    image: tvinsureSmeSabaiPackage,
                    title: "SME Sabai",
                    tag: "สำหรับผู้ประกอบการ",
                    rating: 4,
                    issuerLogo: stysrminsurancelogo,
                    issuerName: "คุ้มภัยโตเกียวมารีน",
                    planName: "SME Sabai จากคุ้มภัยโตเกียวมารีน",
                    planDesc: "ประกันภัยธุรกิจและโรงงานขนาดย่อม",
                    planIndustryType: filteredPackage[i].INDUSTRY_DESC,
                    planTotalSuminsMin: filteredPackage[i].TOTAL_SUMINS_MIN,
                    planTotalSuminsMax: filteredPackage[i].TOTAL_SUMINS_MAX,
                    planNetPrem: filteredPackage[i].PLAN_NET_PREMIUM_AMT,
                    planStampDuty: filteredPackage[i].PLAN_STAMP_DUTY_AMT,
                    planVat: filteredPackage[i].PLAN_VAT_AMT,
                    planTotalPrem: filteredPackage[i].PLAN_TOTAL_PREMIUM,
                    planRoute: `/asset-quote-all/sme-sabai-package/${formData.INDUSTRY_CODE}/BUILDING${formData.TOTAL_BUILDING_SUMINS}-EQUIP${formData.TOTAL_EQUIP_SUMINS || 0}-FUR${formData.TOTAL_FUR_SUMINS || 0}-STOCK${formData.TOTAL_STOCK_SUMINS || 0}-OTHER${formData.TOTAL_OTHER_SUMINS || 0}-TOTAL${totalSumCal}/detail`
                });
            return output;
        }
        setPackageFilter(getPackageRearrange())
        db.collection('fireQuoteSearch').add({
            ...formData,
            TOTAL_SUMINS: totalSumCal
        }
        )
            .catch((error) => {
                console.log(error);
            });
        setIsLoading(false);

    }

    // console.log(formData);
    // console.log(packageFilter);

    return (
        <React.Fragment>
            <div className="wrapper">
                <MetaTags>
                    <title>เช็คราคาประกันภัยสำหรับธุรกิจของคุณ </title>
                    <meta name="description" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:title" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ" />
                    <meta property="og:description" content="เช็คราคาประกันภัยสำหรับธุรกิจของคุณ ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้ TVinsure.com - Protection Made Just For You" />
                    <meta property="og:image" content={tvinsureAssetInsOne} />
                </MetaTags>

                <div className="back-to-home rounded d-none d-sm-block">
                    <a href="https://tvinsure.com" className="btn btn-icon btn-soft-primary">
                        <i>
                            <FeatherIcon icon="home" className="icons" />
                        </i>
                    </a>
                </div>

                <section className="bg-half-90 d-table w-100" >
                    <BackgroundSlider
                        images={items}
                        duration={5}
                        transition={3}
                    />
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="text-center mb-5">
                                    <a href="https://tvinsure.com" className="logo h5">
                                        <img src={tvinsurelogo} height="120" alt="" />
                                    </a>
                                </div>
                                <div className="title-heading text-center">
                                    <h4 className="display-4 font-weight-bold text-white title-dark mb-3">
                                        เช็คราคาประกันภัยสำหรับธุรกิจของคุณ
                                </h4>
                                    <p className="text-center text-white h5 mt-1 mb-5">ประกันภัยธุรกิจ และโรงงานขนาดย่อม ไม่ต้องโทรก็เช็คเองได้</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section className="section bg-white">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} md={10} sm={8}>
                                <Card style={{ margin: "-180px 0 0" }} className="border-0 rounded shadow-lg">
                                    <CardBody>
                                        {!packageFilter && <AvForm className="login-form mt-2" onValidSubmit={handleValidSubmit}>
                                            <Row>
                                                <Col lg={6} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ลักษณะธุรกิจ <span className="text-danger" style={{ paddingLeft: "0" }}>*</span></Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกลักษณะธุรกิจ"
                                                            validate={{ required: { value: true } }} name="BUSINESS_CODE" onChange={handleChange} value={formData.BUSINESS_CODE}>
                                                            <option value="" selected>กรุณาเลือกลักษณะธุรกิจ</option>
                                                            {businessAndIndustrySabuyPackage && businessAndIndustrySabuyPackage.map(b => (<option value={b.BUSINESS_CODE}>{b.BUSINESS_DESC}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={6} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ลักษณะอาคาร </Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกลักษณะอาคาร"
                                                            name="BUILDING_CODE" onChange={handleChange}>
                                                            <option value="" selected>กรุณาเลือกลักษณะอาคาร</option>
                                                            {buildingTypeSmeSabaiFilter && buildingTypeSmeSabaiFilter.map(b => (<option value={b.BUILDING_CODE}>{b.BUILDING_TYPE}</option>))}
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                {formData.BUILDING_CODE && <Col lg={4} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>กว้าง (เมตร)</Label>
                                                        <AvField type="text" className="form-control"
                                                            errorMessage="กรุณากรอกความกว้าง (เมตร)"
                                                            validate={{ pattern: { value: '^[0-9]+$' } }} placeholder="กว้าง (เมตร)" name="TOTAL_BUILDING_WIDTH" onChange={handleChange} value={formData.TOTAL_BUILDING_WIDTH} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.BUILDING_CODE && <Col lg={4} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ยาว (เมตร)</Label>
                                                        <AvField type="text" className="form-control"
                                                            errorMessage="กรุณากรอกความยาว (เมตร)"
                                                            validate={{ pattern: { value: '^[0-9]+$' } }} placeholder="ยาว (เมตร)" name="TOTAL_BUILDING_LENGTH" onChange={handleChange} value={formData.TOTAL_BUILDING_LENGTH} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.BUILDING_CODE && <Col lg={4} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label> จำนวนชั้น</Label>
                                                        <AvField type="text" className="form-control"
                                                            errorMessage="กรุณากรอกจำนวนชั้น"
                                                            validate={{ pattern: { value: '^[0-9]+$' } }} placeholder="จำนวนชั้น" name="TOTAL_BUILDING_LEVEL" onChange={handleChange} value={formData.TOTAL_BUILDING_LEVEL} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.TOTAL_BUILDING_WIDTH && formData.TOTAL_BUILDING_LENGTH && formData.TOTAL_BUILDING_LEVEL && <Col lg={12} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>ระดับราคา </Label>
                                                        <AvField type="select" className="form-control"
                                                            errorMessage="กรุณาเลือกประเภทระดับราคา"
                                                            name="RATE_PRICE" onChange={handleChange} value={formData.RATE_PRICE}>
                                                            <option value="" selected>กรุณาเลือกระดับราคา</option>
                                                            <option value="LOW_PRICE" selected>ราคาต่ำ</option>
                                                            <option value="MID_PRICE" selected>ราคาปานกลาง</option>
                                                            <option value="HIGH_PRICE" selected>ราคาสูง</option>
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>}
                                                <Col lg={12} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>สิ่งปลูกสร้าง (ไม่รวมฐานราก) <span className="text-danger">*</span></Label>
                                                        <i><FeatherIcon icon="home" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกมูลค่าสิ่งปลูกสร้าง (ไม่รวมฐานราก) (ตัวเลขเท่านั้น)"
                                                            validate={{ pattern: { value: '^[0-9]+$' }, required: { value: true } }} placeholder="สิ่งปลูกสร้าง (ไม่รวมฐานราก) รวมส่วนปรับปรุง ตกแต่ง ต่อเติม ส่วนควบต่างๆ และระบบสาธารณูปโภค" name="TOTAL_BUILDING_SUMINS" onChange={handleChange} value={formData.TOTAL_BUILDING_SUMINS} />
                                                        {formData.RECOM_BUILDING_SUMINS && formData.RECOMMED_BUILDING_PRICE_PER_SQM && <small>ทุนสิ่งปลูกสร้าง ไม่ควรตำกว่า <span className="text-danger">{formData.RECOM_BUILDING_SUMINS && Intl.NumberFormat().format(formData.RECOM_BUILDING_SUMINS * 0.7)}</span> บาท หรือ 70% ของราคาทุนประกันแนะนำ {formData.RECOM_BUILDING_SUMINS && Intl.NumberFormat().format(formData.RECOM_BUILDING_SUMINS)} บาท (ราคาต่อตารางเมตรโดยประมาณ {Intl.NumberFormat().format(formData.RECOMMED_BUILDING_PRICE_PER_SQM)} บาท)</small>}
                                                        {formData.TOTAL_BUILDING_SUMINS < formData.RECOM_BUILDING_SUMINS * 0.7 && <div className="mt-3"><small >การกำหนดจำนวนเงินเอาประกันภัย <span className="text-danger">ต่ำกว่า</span> มูลค่าทรัพย์สินที่แท้จริง เมื่อเกิดเพลิงไหม้ขึ้น ผู้เอาประกันภัยจะได้รับชดใช้ค่าสินไหมทดแทนตามอัตราส่วนที่ได้รับความคุ้มครองเท่านั้น สัดส่วนที่เหลือจากการคุ้มครองตามเงื่อนไขกรมธรรม์ประกันอัคคีภัย ผู้เอาประกันภัยต้องรับผิดชอบเอง</small></div>}
                                                    </FormGroup>
                                                </Col>
                                                {formData.TOTAL_BUILDING_SUMINS && <Col lg={12} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เฟอร์นิเจอร์ เครื่องตกแต่ง ติดตั้งตรึงตรา </Label>
                                                        <i><FeatherIcon icon="archive" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกมูลค่าเครื่องจักรและเครื่องมือเครื่องใช้ (ตัวเลขเท่านั้น)"
                                                            validate={{ pattern: { value: '^[0-9]+$' } }} placeholder="เฟอร์นิเจอร์ เครื่องตกแต่ง ติดตั้งตรึงตรา เครื่องใช้สำนักงาน คอมพิวเตอร์ และอุปกรณ์ส่วนควบ (เฉพาะฮาร์ดแวร์) ระบบเครื่องปรับอากาศ ระบบโทรศัพท์ " name="TOTAL_FUR_SUMINS" onChange={handleChange} value={formData.TOTAL_FUR_SUMINS} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.TOTAL_BUILDING_SUMINS && <Col lg={12} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>สต๊อกสินค้าทุกชนิด  </Label>
                                                        <i><FeatherIcon icon="package" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกมูลค่าสต๊อกสินค้าทุกชนิด (ตัวเลขเท่านั้น)"
                                                            validate={{ pattern: { value: '^[0-9]+$' } }} placeholder="สต๊อกสินค้าทุกชนิด " name="TOTAL_STOCK_SUMINS" onChange={handleChange} value={formData.TOTAL_STOCK_SUMINS} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.TOTAL_BUILDING_SUMINS && <Col lg={12} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>เครื่องจักรและเครื่องมือเครื่องใช้</Label>
                                                        <i><FeatherIcon icon="monitor" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกมูลค่าเครื่องจักรและเครื่องมือเครื่องใช้ (ตัวเลขเท่านั้น)"
                                                            validate={{ pattern: { value: '^[0-9]+$' } }} placeholder="เครื่องจักร และอุปกรณ์ส่วนควบต่างๆ เครื่องมือเครื่องใช้" name="TOTAL_EQUIP_SUMINS" onChange={handleChange} value={formData.TOTAL_EQUIP_SUMINS} />
                                                    </FormGroup>
                                                </Col>}
                                                {formData.TOTAL_BUILDING_SUMINS && <Col lg={12} md={12}>
                                                    <FormGroup className="position-relative">
                                                        <Label>อื่น ๆ  </Label>
                                                        <i><FeatherIcon icon="more-horizontal" className="fea icon-sm icons" /></i>
                                                        <AvField type="text" className="form-control pl-5"
                                                            errorMessage="กรุณากรอกมูลค่าทรัพย์สินอื่น ๆ (ตัวเลขเท่านั้น)"
                                                            validate={{ pattern: { value: '^[0-9]+$' } }} placeholder="ทรัพย์สินอื่น ๆ " name="TOTAL_OTHER_SUMINS" onChange={handleChange} value={formData.TOTAL_OTHER_SUMINS} />
                                                    </FormGroup>
                                                </Col>}

                                                <Col lg={12}>
                                                    <FormGroup className="position-relative">
                                                        {!isLoading && !isSubmit && <Button className="mb-2" color="danger" block>คำนวณเบี้ยประกันภัย</Button>}
                                                        {isLoading && isSubmit && <Button className="mb-2" color="danger" disabled block>...</Button>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </AvForm>}
                                        {packageFilter && <Row>
                                            <Col lg={12} md={12}>
                                                <p className="text-center py-2"> เราพบแบบประกันภัยที่เหมาะกับคุณจำนวน {packageFilter.length} รายการ </p>
                                            </Col>
                                            <Col lg={9} md={12}>
                                                <h5 className="text-center py-2 px-5"> ทุนประกันภัยรวมทั้งหมดของธุรกิจคุณมีมูลค่าเท่ากับ {Intl.NumberFormat().format(formData.TOTAL_SUMINS)} บาท</h5>
                                            </Col>
                                            <Col lg={3} md={12} className="px-5">
                                                <Button block color="danger" onClick={handleResetClick}>คำนวณอีกครั้ง</Button>
                                            </Col>
                                        </Row>}
                                    </CardBody>
                                </Card>
                            </Col>

                            {packageFilter && packageFilter.map((plan, key) => (
                                <Col key={key} lg={4} md={6} xs={12} className="mb-4 pb-2 mt-5">
                                    <Card className="job-box rounded shadow border-0 overflow-hidden">
                                        <div className="border-bottom">
                                            <div className="position-relative">
                                                <img src={plan.image} className="img-fluid" alt="" />
                                                <div className="job-overlay" style={{ backgroundColor: "#FFD642" }}></div>
                                            </div>
                                            <h5 className="mb-0 position">
                                                <Link
                                                    to={plan.planRoute}
                                                    className="text-white"
                                                >
                                                    {plan.title}
                                                </Link>
                                                <ul className="list-unstyled h6 mb-0 text-danger">
                                                    <StarRatings
                                                        rating={plan.rating}
                                                        starRatedColor="#F17425"
                                                        numberOfStars={5}
                                                        name="rating"
                                                        starDimension="15px"
                                                        starSpacing="2px"
                                                    />
                                                </ul>
                                            </h5>
                                            <ul className="list-unstyled head mb-0">
                                                <li className={"badge badge-danger badge-pill"}>
                                                    {plan.tag}
                                                </li>
                                            </ul>
                                        </div>

                                        <CardBody className="content position-relative">
                                            <div className="firm-logo rounded-circle shadow bg-light text-center">
                                                <img
                                                    src={plan.issuerLogo}
                                                    className="avatar avatar-h-md-sm"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="company-detail text-center mt-3">
                                                <h5 className="mb-0">
                                                    <Link
                                                        to={plan.planRoute}
                                                        className="text-dark company-name"
                                                    >
                                                        {plan.planName}
                                                    </Link>
                                                </h5>
                                                <p className="text-muted mb-0">
                                                    <i>
                                                    </i>{" "}
                                                    {plan.planDesc}
                                                </p>
                                                <p className={"text-muted mb-2 "}>
                                                    {plan.planIndustryType}
                                                </p>
                                            </div>

                                            <ul className="list-unstyled mb-4">
                                                <li className="h6">
                                                    <Row>
                                                        <Col xs={7}>
                                                            <p className="text-muted mb-0">เบี้ยสุทธิ :</p>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <p className="text-muted text-right mb-0">{Intl.NumberFormat().format(plan.planNetPrem)} บาท</p>
                                                        </Col>
                                                        <Col xs={7}>
                                                            <p className="text-muted mb-0">อากร :</p>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <p className="text-muted text-right mb-0">{Intl.NumberFormat().format(plan.planStampDuty)} บาท</p>
                                                        </Col>
                                                        <Col xs={7}>
                                                            <p className="text-muted  mb-0">ภาษี :</p>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <p className="text-muted text-right  mb-0">{Intl.NumberFormat().format(plan.planVat)}  บาท</p>
                                                        </Col>
                                                        <Col xs={7}>
                                                            <p className="text-dark h6 mb-0">เบี้ยประกันภัย (เบี้ยรวม) :</p>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <p className="text-dark text-right h6 mb-0">{Intl.NumberFormat().format(plan.planTotalPrem)}  บาท</p>
                                                        </Col>
                                                        <Col xs={7}>
                                                            <p className="text-dark h6 mb-0">ทุนเอาประกันภัย :</p>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <p className="text-dark text-right h6 mb-0">{Intl.NumberFormat().format(formData.TOTAL_SUMINS)} บาท</p>
                                                        </Col>
                                                        <Col xs={7}>
                                                            <p className="text-dark h6 mb-0">เบี้ย/ทุนประกัน :</p>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <p className="text-dark text-right h6 mb-0">{Intl.NumberFormat().format(plan.planTotalPrem / formData.TOTAL_SUMINS * 100)} %</p>
                                                        </Col>
                                                    </Row>
                                                </li>
                                            </ul>

                                            <Link
                                                to={plan.planRoute}
                                                className="btn btn-outline-primary btn-block"
                                            >
                                                ดูรายละเอียด
                                        </Link>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}

                        </Row>

                        <Row>
                            <Col lg={12}>
                                <p className="text-center mt-4 mb-2 text-muted">© 2021 - TVinsure.com - Protection Made Just For You</p>
                            </Col>
                            <Col lg={12}>
                                <p className="text-center text-muted">ใบอนุญาตเป็นนายหน้าวินาศภัยเลขที่ ว00288/2534</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
}


export default AssetQuoteAll;